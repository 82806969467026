/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const lnrpc = $root.lnrpc = (() => {

    /**
     * Namespace lnrpc.
     * @exports lnrpc
     * @namespace
     */
    const lnrpc = {};

    lnrpc.Lightning = (function() {

        /**
         * Constructs a new Lightning service.
         * @memberof lnrpc
         * @classdesc Represents a Lightning
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Lightning(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Lightning.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Lightning;

        /**
         * Creates new Lightning service using the specified rpc implementation.
         * @function create
         * @memberof lnrpc.Lightning
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Lightning} RPC service. Useful where requests and/or responses are streamed.
         */
        Lightning.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link lnrpc.Lightning#walletBalance}.
         * @memberof lnrpc.Lightning
         * @typedef WalletBalanceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.WalletBalanceResponse} [response] WalletBalanceResponse
         */

        /**
         * Calls WalletBalance.
         * @function walletBalance
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IWalletBalanceRequest} request WalletBalanceRequest message or plain object
         * @param {lnrpc.Lightning.WalletBalanceCallback} callback Node-style callback called with the error, if any, and WalletBalanceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.walletBalance = function walletBalance(request, callback) {
            return this.rpcCall(walletBalance, $root.lnrpc.WalletBalanceRequest, $root.lnrpc.WalletBalanceResponse, request, callback);
        }, "name", { value: "WalletBalance" });

        /**
         * Calls WalletBalance.
         * @function walletBalance
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IWalletBalanceRequest} request WalletBalanceRequest message or plain object
         * @returns {Promise<lnrpc.WalletBalanceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#channelBalance}.
         * @memberof lnrpc.Lightning
         * @typedef ChannelBalanceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelBalanceResponse} [response] ChannelBalanceResponse
         */

        /**
         * Calls ChannelBalance.
         * @function channelBalance
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelBalanceRequest} request ChannelBalanceRequest message or plain object
         * @param {lnrpc.Lightning.ChannelBalanceCallback} callback Node-style callback called with the error, if any, and ChannelBalanceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.channelBalance = function channelBalance(request, callback) {
            return this.rpcCall(channelBalance, $root.lnrpc.ChannelBalanceRequest, $root.lnrpc.ChannelBalanceResponse, request, callback);
        }, "name", { value: "ChannelBalance" });

        /**
         * Calls ChannelBalance.
         * @function channelBalance
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelBalanceRequest} request ChannelBalanceRequest message or plain object
         * @returns {Promise<lnrpc.ChannelBalanceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getTransactions}.
         * @memberof lnrpc.Lightning
         * @typedef GetTransactionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.TransactionDetails} [response] TransactionDetails
         */

        /**
         * Calls GetTransactions.
         * @function getTransactions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetTransactionsRequest} request GetTransactionsRequest message or plain object
         * @param {lnrpc.Lightning.GetTransactionsCallback} callback Node-style callback called with the error, if any, and TransactionDetails
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getTransactions = function getTransactions(request, callback) {
            return this.rpcCall(getTransactions, $root.lnrpc.GetTransactionsRequest, $root.lnrpc.TransactionDetails, request, callback);
        }, "name", { value: "GetTransactions" });

        /**
         * Calls GetTransactions.
         * @function getTransactions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetTransactionsRequest} request GetTransactionsRequest message or plain object
         * @returns {Promise<lnrpc.TransactionDetails>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#estimateFee}.
         * @memberof lnrpc.Lightning
         * @typedef EstimateFeeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.EstimateFeeResponse} [response] EstimateFeeResponse
         */

        /**
         * Calls EstimateFee.
         * @function estimateFee
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IEstimateFeeRequest} request EstimateFeeRequest message or plain object
         * @param {lnrpc.Lightning.EstimateFeeCallback} callback Node-style callback called with the error, if any, and EstimateFeeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.estimateFee = function estimateFee(request, callback) {
            return this.rpcCall(estimateFee, $root.lnrpc.EstimateFeeRequest, $root.lnrpc.EstimateFeeResponse, request, callback);
        }, "name", { value: "EstimateFee" });

        /**
         * Calls EstimateFee.
         * @function estimateFee
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IEstimateFeeRequest} request EstimateFeeRequest message or plain object
         * @returns {Promise<lnrpc.EstimateFeeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendCoins}.
         * @memberof lnrpc.Lightning
         * @typedef SendCoinsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendCoinsResponse} [response] SendCoinsResponse
         */

        /**
         * Calls SendCoins.
         * @function sendCoins
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendCoinsRequest} request SendCoinsRequest message or plain object
         * @param {lnrpc.Lightning.SendCoinsCallback} callback Node-style callback called with the error, if any, and SendCoinsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendCoins = function sendCoins(request, callback) {
            return this.rpcCall(sendCoins, $root.lnrpc.SendCoinsRequest, $root.lnrpc.SendCoinsResponse, request, callback);
        }, "name", { value: "SendCoins" });

        /**
         * Calls SendCoins.
         * @function sendCoins
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendCoinsRequest} request SendCoinsRequest message or plain object
         * @returns {Promise<lnrpc.SendCoinsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listUnspent}.
         * @memberof lnrpc.Lightning
         * @typedef ListUnspentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListUnspentResponse} [response] ListUnspentResponse
         */

        /**
         * Calls ListUnspent.
         * @function listUnspent
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListUnspentRequest} request ListUnspentRequest message or plain object
         * @param {lnrpc.Lightning.ListUnspentCallback} callback Node-style callback called with the error, if any, and ListUnspentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listUnspent = function listUnspent(request, callback) {
            return this.rpcCall(listUnspent, $root.lnrpc.ListUnspentRequest, $root.lnrpc.ListUnspentResponse, request, callback);
        }, "name", { value: "ListUnspent" });

        /**
         * Calls ListUnspent.
         * @function listUnspent
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListUnspentRequest} request ListUnspentRequest message or plain object
         * @returns {Promise<lnrpc.ListUnspentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribeTransactions}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribeTransactionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Transaction} [response] Transaction
         */

        /**
         * Calls SubscribeTransactions.
         * @function subscribeTransactions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetTransactionsRequest} request GetTransactionsRequest message or plain object
         * @param {lnrpc.Lightning.SubscribeTransactionsCallback} callback Node-style callback called with the error, if any, and Transaction
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribeTransactions = function subscribeTransactions(request, callback) {
            return this.rpcCall(subscribeTransactions, $root.lnrpc.GetTransactionsRequest, $root.lnrpc.Transaction, request, callback);
        }, "name", { value: "SubscribeTransactions" });

        /**
         * Calls SubscribeTransactions.
         * @function subscribeTransactions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetTransactionsRequest} request GetTransactionsRequest message or plain object
         * @returns {Promise<lnrpc.Transaction>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendMany}.
         * @memberof lnrpc.Lightning
         * @typedef SendManyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendManyResponse} [response] SendManyResponse
         */

        /**
         * Calls SendMany.
         * @function sendMany
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendManyRequest} request SendManyRequest message or plain object
         * @param {lnrpc.Lightning.SendManyCallback} callback Node-style callback called with the error, if any, and SendManyResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendMany = function sendMany(request, callback) {
            return this.rpcCall(sendMany, $root.lnrpc.SendManyRequest, $root.lnrpc.SendManyResponse, request, callback);
        }, "name", { value: "SendMany" });

        /**
         * Calls SendMany.
         * @function sendMany
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendManyRequest} request SendManyRequest message or plain object
         * @returns {Promise<lnrpc.SendManyResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#newAddress}.
         * @memberof lnrpc.Lightning
         * @typedef NewAddressCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.NewAddressResponse} [response] NewAddressResponse
         */

        /**
         * Calls NewAddress.
         * @function newAddress
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INewAddressRequest} request NewAddressRequest message or plain object
         * @param {lnrpc.Lightning.NewAddressCallback} callback Node-style callback called with the error, if any, and NewAddressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.newAddress = function newAddress(request, callback) {
            return this.rpcCall(newAddress, $root.lnrpc.NewAddressRequest, $root.lnrpc.NewAddressResponse, request, callback);
        }, "name", { value: "NewAddress" });

        /**
         * Calls NewAddress.
         * @function newAddress
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INewAddressRequest} request NewAddressRequest message or plain object
         * @returns {Promise<lnrpc.NewAddressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#signMessage}.
         * @memberof lnrpc.Lightning
         * @typedef SignMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SignMessageResponse} [response] SignMessageResponse
         */

        /**
         * Calls SignMessage.
         * @function signMessage
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISignMessageRequest} request SignMessageRequest message or plain object
         * @param {lnrpc.Lightning.SignMessageCallback} callback Node-style callback called with the error, if any, and SignMessageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.signMessage = function signMessage(request, callback) {
            return this.rpcCall(signMessage, $root.lnrpc.SignMessageRequest, $root.lnrpc.SignMessageResponse, request, callback);
        }, "name", { value: "SignMessage" });

        /**
         * Calls SignMessage.
         * @function signMessage
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISignMessageRequest} request SignMessageRequest message or plain object
         * @returns {Promise<lnrpc.SignMessageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#verifyMessage}.
         * @memberof lnrpc.Lightning
         * @typedef VerifyMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.VerifyMessageResponse} [response] VerifyMessageResponse
         */

        /**
         * Calls VerifyMessage.
         * @function verifyMessage
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IVerifyMessageRequest} request VerifyMessageRequest message or plain object
         * @param {lnrpc.Lightning.VerifyMessageCallback} callback Node-style callback called with the error, if any, and VerifyMessageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.verifyMessage = function verifyMessage(request, callback) {
            return this.rpcCall(verifyMessage, $root.lnrpc.VerifyMessageRequest, $root.lnrpc.VerifyMessageResponse, request, callback);
        }, "name", { value: "VerifyMessage" });

        /**
         * Calls VerifyMessage.
         * @function verifyMessage
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IVerifyMessageRequest} request VerifyMessageRequest message or plain object
         * @returns {Promise<lnrpc.VerifyMessageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#connectPeer}.
         * @memberof lnrpc.Lightning
         * @typedef ConnectPeerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ConnectPeerResponse} [response] ConnectPeerResponse
         */

        /**
         * Calls ConnectPeer.
         * @function connectPeer
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IConnectPeerRequest} request ConnectPeerRequest message or plain object
         * @param {lnrpc.Lightning.ConnectPeerCallback} callback Node-style callback called with the error, if any, and ConnectPeerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.connectPeer = function connectPeer(request, callback) {
            return this.rpcCall(connectPeer, $root.lnrpc.ConnectPeerRequest, $root.lnrpc.ConnectPeerResponse, request, callback);
        }, "name", { value: "ConnectPeer" });

        /**
         * Calls ConnectPeer.
         * @function connectPeer
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IConnectPeerRequest} request ConnectPeerRequest message or plain object
         * @returns {Promise<lnrpc.ConnectPeerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#disconnectPeer}.
         * @memberof lnrpc.Lightning
         * @typedef DisconnectPeerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.DisconnectPeerResponse} [response] DisconnectPeerResponse
         */

        /**
         * Calls DisconnectPeer.
         * @function disconnectPeer
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDisconnectPeerRequest} request DisconnectPeerRequest message or plain object
         * @param {lnrpc.Lightning.DisconnectPeerCallback} callback Node-style callback called with the error, if any, and DisconnectPeerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.disconnectPeer = function disconnectPeer(request, callback) {
            return this.rpcCall(disconnectPeer, $root.lnrpc.DisconnectPeerRequest, $root.lnrpc.DisconnectPeerResponse, request, callback);
        }, "name", { value: "DisconnectPeer" });

        /**
         * Calls DisconnectPeer.
         * @function disconnectPeer
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDisconnectPeerRequest} request DisconnectPeerRequest message or plain object
         * @returns {Promise<lnrpc.DisconnectPeerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listPeers}.
         * @memberof lnrpc.Lightning
         * @typedef ListPeersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListPeersResponse} [response] ListPeersResponse
         */

        /**
         * Calls ListPeers.
         * @function listPeers
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListPeersRequest} request ListPeersRequest message or plain object
         * @param {lnrpc.Lightning.ListPeersCallback} callback Node-style callback called with the error, if any, and ListPeersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listPeers = function listPeers(request, callback) {
            return this.rpcCall(listPeers, $root.lnrpc.ListPeersRequest, $root.lnrpc.ListPeersResponse, request, callback);
        }, "name", { value: "ListPeers" });

        /**
         * Calls ListPeers.
         * @function listPeers
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListPeersRequest} request ListPeersRequest message or plain object
         * @returns {Promise<lnrpc.ListPeersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribePeerEvents}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribePeerEventsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.PeerEvent} [response] PeerEvent
         */

        /**
         * Calls SubscribePeerEvents.
         * @function subscribePeerEvents
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPeerEventSubscription} request PeerEventSubscription message or plain object
         * @param {lnrpc.Lightning.SubscribePeerEventsCallback} callback Node-style callback called with the error, if any, and PeerEvent
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribePeerEvents = function subscribePeerEvents(request, callback) {
            return this.rpcCall(subscribePeerEvents, $root.lnrpc.PeerEventSubscription, $root.lnrpc.PeerEvent, request, callback);
        }, "name", { value: "SubscribePeerEvents" });

        /**
         * Calls SubscribePeerEvents.
         * @function subscribePeerEvents
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPeerEventSubscription} request PeerEventSubscription message or plain object
         * @returns {Promise<lnrpc.PeerEvent>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getInfo}.
         * @memberof lnrpc.Lightning
         * @typedef GetInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.GetInfoResponse} [response] GetInfoResponse
         */

        /**
         * Calls GetInfo.
         * @function getInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetInfoRequest} request GetInfoRequest message or plain object
         * @param {lnrpc.Lightning.GetInfoCallback} callback Node-style callback called with the error, if any, and GetInfoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getInfo = function getInfo(request, callback) {
            return this.rpcCall(getInfo, $root.lnrpc.GetInfoRequest, $root.lnrpc.GetInfoResponse, request, callback);
        }, "name", { value: "GetInfo" });

        /**
         * Calls GetInfo.
         * @function getInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetInfoRequest} request GetInfoRequest message or plain object
         * @returns {Promise<lnrpc.GetInfoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getRecoveryInfo}.
         * @memberof lnrpc.Lightning
         * @typedef GetRecoveryInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.GetRecoveryInfoResponse} [response] GetRecoveryInfoResponse
         */

        /**
         * lncli: `getrecoveryinfo`
         * GetRecoveryInfo returns information concerning the recovery mode including
         * whether it's in a recovery mode, whether the recovery is finished, and the
         * progress made so far.
         * @function getRecoveryInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetRecoveryInfoRequest} request GetRecoveryInfoRequest message or plain object
         * @param {lnrpc.Lightning.GetRecoveryInfoCallback} callback Node-style callback called with the error, if any, and GetRecoveryInfoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getRecoveryInfo = function getRecoveryInfo(request, callback) {
            return this.rpcCall(getRecoveryInfo, $root.lnrpc.GetRecoveryInfoRequest, $root.lnrpc.GetRecoveryInfoResponse, request, callback);
        }, "name", { value: "GetRecoveryInfo" });

        /**
         * lncli: `getrecoveryinfo`
         * GetRecoveryInfo returns information concerning the recovery mode including
         * whether it's in a recovery mode, whether the recovery is finished, and the
         * progress made so far.
         * @function getRecoveryInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGetRecoveryInfoRequest} request GetRecoveryInfoRequest message or plain object
         * @returns {Promise<lnrpc.GetRecoveryInfoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#pendingChannels}.
         * @memberof lnrpc.Lightning
         * @typedef PendingChannelsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.PendingChannelsResponse} [response] PendingChannelsResponse
         */

        /**
         * Calls PendingChannels.
         * @function pendingChannels
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPendingChannelsRequest} request PendingChannelsRequest message or plain object
         * @param {lnrpc.Lightning.PendingChannelsCallback} callback Node-style callback called with the error, if any, and PendingChannelsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.pendingChannels = function pendingChannels(request, callback) {
            return this.rpcCall(pendingChannels, $root.lnrpc.PendingChannelsRequest, $root.lnrpc.PendingChannelsResponse, request, callback);
        }, "name", { value: "PendingChannels" });

        /**
         * Calls PendingChannels.
         * @function pendingChannels
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPendingChannelsRequest} request PendingChannelsRequest message or plain object
         * @returns {Promise<lnrpc.PendingChannelsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listChannels}.
         * @memberof lnrpc.Lightning
         * @typedef ListChannelsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListChannelsResponse} [response] ListChannelsResponse
         */

        /**
         * Calls ListChannels.
         * @function listChannels
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListChannelsRequest} request ListChannelsRequest message or plain object
         * @param {lnrpc.Lightning.ListChannelsCallback} callback Node-style callback called with the error, if any, and ListChannelsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listChannels = function listChannels(request, callback) {
            return this.rpcCall(listChannels, $root.lnrpc.ListChannelsRequest, $root.lnrpc.ListChannelsResponse, request, callback);
        }, "name", { value: "ListChannels" });

        /**
         * Calls ListChannels.
         * @function listChannels
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListChannelsRequest} request ListChannelsRequest message or plain object
         * @returns {Promise<lnrpc.ListChannelsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribeChannelEvents}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribeChannelEventsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelEventUpdate} [response] ChannelEventUpdate
         */

        /**
         * Calls SubscribeChannelEvents.
         * @function subscribeChannelEvents
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelEventSubscription} request ChannelEventSubscription message or plain object
         * @param {lnrpc.Lightning.SubscribeChannelEventsCallback} callback Node-style callback called with the error, if any, and ChannelEventUpdate
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribeChannelEvents = function subscribeChannelEvents(request, callback) {
            return this.rpcCall(subscribeChannelEvents, $root.lnrpc.ChannelEventSubscription, $root.lnrpc.ChannelEventUpdate, request, callback);
        }, "name", { value: "SubscribeChannelEvents" });

        /**
         * Calls SubscribeChannelEvents.
         * @function subscribeChannelEvents
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelEventSubscription} request ChannelEventSubscription message or plain object
         * @returns {Promise<lnrpc.ChannelEventUpdate>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#closedChannels}.
         * @memberof lnrpc.Lightning
         * @typedef ClosedChannelsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ClosedChannelsResponse} [response] ClosedChannelsResponse
         */

        /**
         * Calls ClosedChannels.
         * @function closedChannels
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IClosedChannelsRequest} request ClosedChannelsRequest message or plain object
         * @param {lnrpc.Lightning.ClosedChannelsCallback} callback Node-style callback called with the error, if any, and ClosedChannelsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.closedChannels = function closedChannels(request, callback) {
            return this.rpcCall(closedChannels, $root.lnrpc.ClosedChannelsRequest, $root.lnrpc.ClosedChannelsResponse, request, callback);
        }, "name", { value: "ClosedChannels" });

        /**
         * Calls ClosedChannels.
         * @function closedChannels
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IClosedChannelsRequest} request ClosedChannelsRequest message or plain object
         * @returns {Promise<lnrpc.ClosedChannelsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#openChannelSync}.
         * @memberof lnrpc.Lightning
         * @typedef OpenChannelSyncCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelPoint} [response] ChannelPoint
         */

        /**
         * Calls OpenChannelSync.
         * @function openChannelSync
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IOpenChannelRequest} request OpenChannelRequest message or plain object
         * @param {lnrpc.Lightning.OpenChannelSyncCallback} callback Node-style callback called with the error, if any, and ChannelPoint
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.openChannelSync = function openChannelSync(request, callback) {
            return this.rpcCall(openChannelSync, $root.lnrpc.OpenChannelRequest, $root.lnrpc.ChannelPoint, request, callback);
        }, "name", { value: "OpenChannelSync" });

        /**
         * Calls OpenChannelSync.
         * @function openChannelSync
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IOpenChannelRequest} request OpenChannelRequest message or plain object
         * @returns {Promise<lnrpc.ChannelPoint>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#openChannel}.
         * @memberof lnrpc.Lightning
         * @typedef OpenChannelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.OpenStatusUpdate} [response] OpenStatusUpdate
         */

        /**
         * Calls OpenChannel.
         * @function openChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IOpenChannelRequest} request OpenChannelRequest message or plain object
         * @param {lnrpc.Lightning.OpenChannelCallback} callback Node-style callback called with the error, if any, and OpenStatusUpdate
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.openChannel = function openChannel(request, callback) {
            return this.rpcCall(openChannel, $root.lnrpc.OpenChannelRequest, $root.lnrpc.OpenStatusUpdate, request, callback);
        }, "name", { value: "OpenChannel" });

        /**
         * Calls OpenChannel.
         * @function openChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IOpenChannelRequest} request OpenChannelRequest message or plain object
         * @returns {Promise<lnrpc.OpenStatusUpdate>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#batchOpenChannel}.
         * @memberof lnrpc.Lightning
         * @typedef BatchOpenChannelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.BatchOpenChannelResponse} [response] BatchOpenChannelResponse
         */

        /**
         * Calls BatchOpenChannel.
         * @function batchOpenChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IBatchOpenChannelRequest} request BatchOpenChannelRequest message or plain object
         * @param {lnrpc.Lightning.BatchOpenChannelCallback} callback Node-style callback called with the error, if any, and BatchOpenChannelResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.batchOpenChannel = function batchOpenChannel(request, callback) {
            return this.rpcCall(batchOpenChannel, $root.lnrpc.BatchOpenChannelRequest, $root.lnrpc.BatchOpenChannelResponse, request, callback);
        }, "name", { value: "BatchOpenChannel" });

        /**
         * Calls BatchOpenChannel.
         * @function batchOpenChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IBatchOpenChannelRequest} request BatchOpenChannelRequest message or plain object
         * @returns {Promise<lnrpc.BatchOpenChannelResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#fundingStateStep}.
         * @memberof lnrpc.Lightning
         * @typedef FundingStateStepCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.FundingStateStepResp} [response] FundingStateStepResp
         */

        /**
         * Calls FundingStateStep.
         * @function fundingStateStep
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IFundingTransitionMsg} request FundingTransitionMsg message or plain object
         * @param {lnrpc.Lightning.FundingStateStepCallback} callback Node-style callback called with the error, if any, and FundingStateStepResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.fundingStateStep = function fundingStateStep(request, callback) {
            return this.rpcCall(fundingStateStep, $root.lnrpc.FundingTransitionMsg, $root.lnrpc.FundingStateStepResp, request, callback);
        }, "name", { value: "FundingStateStep" });

        /**
         * Calls FundingStateStep.
         * @function fundingStateStep
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IFundingTransitionMsg} request FundingTransitionMsg message or plain object
         * @returns {Promise<lnrpc.FundingStateStepResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#channelAcceptor}.
         * @memberof lnrpc.Lightning
         * @typedef ChannelAcceptorCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelAcceptRequest} [response] ChannelAcceptRequest
         */

        /**
         * Calls ChannelAcceptor.
         * @function channelAcceptor
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelAcceptResponse} request ChannelAcceptResponse message or plain object
         * @param {lnrpc.Lightning.ChannelAcceptorCallback} callback Node-style callback called with the error, if any, and ChannelAcceptRequest
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.channelAcceptor = function channelAcceptor(request, callback) {
            return this.rpcCall(channelAcceptor, $root.lnrpc.ChannelAcceptResponse, $root.lnrpc.ChannelAcceptRequest, request, callback);
        }, "name", { value: "ChannelAcceptor" });

        /**
         * Calls ChannelAcceptor.
         * @function channelAcceptor
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelAcceptResponse} request ChannelAcceptResponse message or plain object
         * @returns {Promise<lnrpc.ChannelAcceptRequest>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#closeChannel}.
         * @memberof lnrpc.Lightning
         * @typedef CloseChannelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.CloseStatusUpdate} [response] CloseStatusUpdate
         */

        /**
         * Calls CloseChannel.
         * @function closeChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ICloseChannelRequest} request CloseChannelRequest message or plain object
         * @param {lnrpc.Lightning.CloseChannelCallback} callback Node-style callback called with the error, if any, and CloseStatusUpdate
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.closeChannel = function closeChannel(request, callback) {
            return this.rpcCall(closeChannel, $root.lnrpc.CloseChannelRequest, $root.lnrpc.CloseStatusUpdate, request, callback);
        }, "name", { value: "CloseChannel" });

        /**
         * Calls CloseChannel.
         * @function closeChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ICloseChannelRequest} request CloseChannelRequest message or plain object
         * @returns {Promise<lnrpc.CloseStatusUpdate>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#abandonChannel}.
         * @memberof lnrpc.Lightning
         * @typedef AbandonChannelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.AbandonChannelResponse} [response] AbandonChannelResponse
         */

        /**
         * Calls AbandonChannel.
         * @function abandonChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IAbandonChannelRequest} request AbandonChannelRequest message or plain object
         * @param {lnrpc.Lightning.AbandonChannelCallback} callback Node-style callback called with the error, if any, and AbandonChannelResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.abandonChannel = function abandonChannel(request, callback) {
            return this.rpcCall(abandonChannel, $root.lnrpc.AbandonChannelRequest, $root.lnrpc.AbandonChannelResponse, request, callback);
        }, "name", { value: "AbandonChannel" });

        /**
         * Calls AbandonChannel.
         * @function abandonChannel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IAbandonChannelRequest} request AbandonChannelRequest message or plain object
         * @returns {Promise<lnrpc.AbandonChannelResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendPayment}.
         * @memberof lnrpc.Lightning
         * @typedef SendPaymentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendResponse} [response] SendResponse
         */

        /**
         * Calls SendPayment.
         * @function sendPayment
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendRequest} request SendRequest message or plain object
         * @param {lnrpc.Lightning.SendPaymentCallback} callback Node-style callback called with the error, if any, and SendResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendPayment = function sendPayment(request, callback) {
            return this.rpcCall(sendPayment, $root.lnrpc.SendRequest, $root.lnrpc.SendResponse, request, callback);
        }, "name", { value: "SendPayment" });

        /**
         * Calls SendPayment.
         * @function sendPayment
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendRequest} request SendRequest message or plain object
         * @returns {Promise<lnrpc.SendResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendPaymentSync}.
         * @memberof lnrpc.Lightning
         * @typedef SendPaymentSyncCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendResponse} [response] SendResponse
         */

        /**
         * Calls SendPaymentSync.
         * @function sendPaymentSync
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendRequest} request SendRequest message or plain object
         * @param {lnrpc.Lightning.SendPaymentSyncCallback} callback Node-style callback called with the error, if any, and SendResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendPaymentSync = function sendPaymentSync(request, callback) {
            return this.rpcCall(sendPaymentSync, $root.lnrpc.SendRequest, $root.lnrpc.SendResponse, request, callback);
        }, "name", { value: "SendPaymentSync" });

        /**
         * Calls SendPaymentSync.
         * @function sendPaymentSync
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendRequest} request SendRequest message or plain object
         * @returns {Promise<lnrpc.SendResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendToRoute}.
         * @memberof lnrpc.Lightning
         * @typedef SendToRouteCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendResponse} [response] SendResponse
         */

        /**
         * Calls SendToRoute.
         * @function sendToRoute
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @param {lnrpc.Lightning.SendToRouteCallback} callback Node-style callback called with the error, if any, and SendResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendToRoute = function sendToRoute(request, callback) {
            return this.rpcCall(sendToRoute, $root.lnrpc.SendToRouteRequest, $root.lnrpc.SendResponse, request, callback);
        }, "name", { value: "SendToRoute" });

        /**
         * Calls SendToRoute.
         * @function sendToRoute
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @returns {Promise<lnrpc.SendResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendToRouteSync}.
         * @memberof lnrpc.Lightning
         * @typedef SendToRouteSyncCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendResponse} [response] SendResponse
         */

        /**
         * Calls SendToRouteSync.
         * @function sendToRouteSync
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @param {lnrpc.Lightning.SendToRouteSyncCallback} callback Node-style callback called with the error, if any, and SendResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendToRouteSync = function sendToRouteSync(request, callback) {
            return this.rpcCall(sendToRouteSync, $root.lnrpc.SendToRouteRequest, $root.lnrpc.SendResponse, request, callback);
        }, "name", { value: "SendToRouteSync" });

        /**
         * Calls SendToRouteSync.
         * @function sendToRouteSync
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @returns {Promise<lnrpc.SendResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#addInvoice}.
         * @memberof lnrpc.Lightning
         * @typedef AddInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.AddInvoiceResponse} [response] AddInvoiceResponse
         */

        /**
         * Calls AddInvoice.
         * @function addInvoice
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IInvoice} request Invoice message or plain object
         * @param {lnrpc.Lightning.AddInvoiceCallback} callback Node-style callback called with the error, if any, and AddInvoiceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.addInvoice = function addInvoice(request, callback) {
            return this.rpcCall(addInvoice, $root.lnrpc.Invoice, $root.lnrpc.AddInvoiceResponse, request, callback);
        }, "name", { value: "AddInvoice" });

        /**
         * Calls AddInvoice.
         * @function addInvoice
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IInvoice} request Invoice message or plain object
         * @returns {Promise<lnrpc.AddInvoiceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listInvoices}.
         * @memberof lnrpc.Lightning
         * @typedef ListInvoicesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListInvoiceResponse} [response] ListInvoiceResponse
         */

        /**
         * Calls ListInvoices.
         * @function listInvoices
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListInvoiceRequest} request ListInvoiceRequest message or plain object
         * @param {lnrpc.Lightning.ListInvoicesCallback} callback Node-style callback called with the error, if any, and ListInvoiceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listInvoices = function listInvoices(request, callback) {
            return this.rpcCall(listInvoices, $root.lnrpc.ListInvoiceRequest, $root.lnrpc.ListInvoiceResponse, request, callback);
        }, "name", { value: "ListInvoices" });

        /**
         * Calls ListInvoices.
         * @function listInvoices
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListInvoiceRequest} request ListInvoiceRequest message or plain object
         * @returns {Promise<lnrpc.ListInvoiceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#lookupInvoice}.
         * @memberof lnrpc.Lightning
         * @typedef LookupInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Invoice} [response] Invoice
         */

        /**
         * Calls LookupInvoice.
         * @function lookupInvoice
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPaymentHash} request PaymentHash message or plain object
         * @param {lnrpc.Lightning.LookupInvoiceCallback} callback Node-style callback called with the error, if any, and Invoice
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.lookupInvoice = function lookupInvoice(request, callback) {
            return this.rpcCall(lookupInvoice, $root.lnrpc.PaymentHash, $root.lnrpc.Invoice, request, callback);
        }, "name", { value: "LookupInvoice" });

        /**
         * Calls LookupInvoice.
         * @function lookupInvoice
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPaymentHash} request PaymentHash message or plain object
         * @returns {Promise<lnrpc.Invoice>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribeInvoices}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribeInvoicesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Invoice} [response] Invoice
         */

        /**
         * Calls SubscribeInvoices.
         * @function subscribeInvoices
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IInvoiceSubscription} request InvoiceSubscription message or plain object
         * @param {lnrpc.Lightning.SubscribeInvoicesCallback} callback Node-style callback called with the error, if any, and Invoice
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribeInvoices = function subscribeInvoices(request, callback) {
            return this.rpcCall(subscribeInvoices, $root.lnrpc.InvoiceSubscription, $root.lnrpc.Invoice, request, callback);
        }, "name", { value: "SubscribeInvoices" });

        /**
         * Calls SubscribeInvoices.
         * @function subscribeInvoices
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IInvoiceSubscription} request InvoiceSubscription message or plain object
         * @returns {Promise<lnrpc.Invoice>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#decodePayReq}.
         * @memberof lnrpc.Lightning
         * @typedef DecodePayReqCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.PayReq} [response] PayReq
         */

        /**
         * Calls DecodePayReq.
         * @function decodePayReq
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPayReqString} request PayReqString message or plain object
         * @param {lnrpc.Lightning.DecodePayReqCallback} callback Node-style callback called with the error, if any, and PayReq
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.decodePayReq = function decodePayReq(request, callback) {
            return this.rpcCall(decodePayReq, $root.lnrpc.PayReqString, $root.lnrpc.PayReq, request, callback);
        }, "name", { value: "DecodePayReq" });

        /**
         * Calls DecodePayReq.
         * @function decodePayReq
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPayReqString} request PayReqString message or plain object
         * @returns {Promise<lnrpc.PayReq>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listPayments}.
         * @memberof lnrpc.Lightning
         * @typedef ListPaymentsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListPaymentsResponse} [response] ListPaymentsResponse
         */

        /**
         * Calls ListPayments.
         * @function listPayments
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListPaymentsRequest} request ListPaymentsRequest message or plain object
         * @param {lnrpc.Lightning.ListPaymentsCallback} callback Node-style callback called with the error, if any, and ListPaymentsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listPayments = function listPayments(request, callback) {
            return this.rpcCall(listPayments, $root.lnrpc.ListPaymentsRequest, $root.lnrpc.ListPaymentsResponse, request, callback);
        }, "name", { value: "ListPayments" });

        /**
         * Calls ListPayments.
         * @function listPayments
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListPaymentsRequest} request ListPaymentsRequest message or plain object
         * @returns {Promise<lnrpc.ListPaymentsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#deletePayment}.
         * @memberof lnrpc.Lightning
         * @typedef DeletePaymentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.DeletePaymentResponse} [response] DeletePaymentResponse
         */

        /**
         * Calls DeletePayment.
         * @function deletePayment
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDeletePaymentRequest} request DeletePaymentRequest message or plain object
         * @param {lnrpc.Lightning.DeletePaymentCallback} callback Node-style callback called with the error, if any, and DeletePaymentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.deletePayment = function deletePayment(request, callback) {
            return this.rpcCall(deletePayment, $root.lnrpc.DeletePaymentRequest, $root.lnrpc.DeletePaymentResponse, request, callback);
        }, "name", { value: "DeletePayment" });

        /**
         * Calls DeletePayment.
         * @function deletePayment
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDeletePaymentRequest} request DeletePaymentRequest message or plain object
         * @returns {Promise<lnrpc.DeletePaymentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#deleteAllPayments}.
         * @memberof lnrpc.Lightning
         * @typedef DeleteAllPaymentsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.DeleteAllPaymentsResponse} [response] DeleteAllPaymentsResponse
         */

        /**
         * Calls DeleteAllPayments.
         * @function deleteAllPayments
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDeleteAllPaymentsRequest} request DeleteAllPaymentsRequest message or plain object
         * @param {lnrpc.Lightning.DeleteAllPaymentsCallback} callback Node-style callback called with the error, if any, and DeleteAllPaymentsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.deleteAllPayments = function deleteAllPayments(request, callback) {
            return this.rpcCall(deleteAllPayments, $root.lnrpc.DeleteAllPaymentsRequest, $root.lnrpc.DeleteAllPaymentsResponse, request, callback);
        }, "name", { value: "DeleteAllPayments" });

        /**
         * Calls DeleteAllPayments.
         * @function deleteAllPayments
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDeleteAllPaymentsRequest} request DeleteAllPaymentsRequest message or plain object
         * @returns {Promise<lnrpc.DeleteAllPaymentsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#describeGraph}.
         * @memberof lnrpc.Lightning
         * @typedef DescribeGraphCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelGraph} [response] ChannelGraph
         */

        /**
         * Calls DescribeGraph.
         * @function describeGraph
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelGraphRequest} request ChannelGraphRequest message or plain object
         * @param {lnrpc.Lightning.DescribeGraphCallback} callback Node-style callback called with the error, if any, and ChannelGraph
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.describeGraph = function describeGraph(request, callback) {
            return this.rpcCall(describeGraph, $root.lnrpc.ChannelGraphRequest, $root.lnrpc.ChannelGraph, request, callback);
        }, "name", { value: "DescribeGraph" });

        /**
         * Calls DescribeGraph.
         * @function describeGraph
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelGraphRequest} request ChannelGraphRequest message or plain object
         * @returns {Promise<lnrpc.ChannelGraph>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getNodeMetrics}.
         * @memberof lnrpc.Lightning
         * @typedef GetNodeMetricsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.NodeMetricsResponse} [response] NodeMetricsResponse
         */

        /**
         * Calls GetNodeMetrics.
         * @function getNodeMetrics
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INodeMetricsRequest} request NodeMetricsRequest message or plain object
         * @param {lnrpc.Lightning.GetNodeMetricsCallback} callback Node-style callback called with the error, if any, and NodeMetricsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getNodeMetrics = function getNodeMetrics(request, callback) {
            return this.rpcCall(getNodeMetrics, $root.lnrpc.NodeMetricsRequest, $root.lnrpc.NodeMetricsResponse, request, callback);
        }, "name", { value: "GetNodeMetrics" });

        /**
         * Calls GetNodeMetrics.
         * @function getNodeMetrics
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INodeMetricsRequest} request NodeMetricsRequest message or plain object
         * @returns {Promise<lnrpc.NodeMetricsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getChanInfo}.
         * @memberof lnrpc.Lightning
         * @typedef GetChanInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelEdge} [response] ChannelEdge
         */

        /**
         * Calls GetChanInfo.
         * @function getChanInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChanInfoRequest} request ChanInfoRequest message or plain object
         * @param {lnrpc.Lightning.GetChanInfoCallback} callback Node-style callback called with the error, if any, and ChannelEdge
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getChanInfo = function getChanInfo(request, callback) {
            return this.rpcCall(getChanInfo, $root.lnrpc.ChanInfoRequest, $root.lnrpc.ChannelEdge, request, callback);
        }, "name", { value: "GetChanInfo" });

        /**
         * Calls GetChanInfo.
         * @function getChanInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChanInfoRequest} request ChanInfoRequest message or plain object
         * @returns {Promise<lnrpc.ChannelEdge>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getNodeInfo}.
         * @memberof lnrpc.Lightning
         * @typedef GetNodeInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.NodeInfo} [response] NodeInfo
         */

        /**
         * Calls GetNodeInfo.
         * @function getNodeInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INodeInfoRequest} request NodeInfoRequest message or plain object
         * @param {lnrpc.Lightning.GetNodeInfoCallback} callback Node-style callback called with the error, if any, and NodeInfo
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getNodeInfo = function getNodeInfo(request, callback) {
            return this.rpcCall(getNodeInfo, $root.lnrpc.NodeInfoRequest, $root.lnrpc.NodeInfo, request, callback);
        }, "name", { value: "GetNodeInfo" });

        /**
         * Calls GetNodeInfo.
         * @function getNodeInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INodeInfoRequest} request NodeInfoRequest message or plain object
         * @returns {Promise<lnrpc.NodeInfo>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#queryRoutes}.
         * @memberof lnrpc.Lightning
         * @typedef QueryRoutesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.QueryRoutesResponse} [response] QueryRoutesResponse
         */

        /**
         * Calls QueryRoutes.
         * @function queryRoutes
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IQueryRoutesRequest} request QueryRoutesRequest message or plain object
         * @param {lnrpc.Lightning.QueryRoutesCallback} callback Node-style callback called with the error, if any, and QueryRoutesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.queryRoutes = function queryRoutes(request, callback) {
            return this.rpcCall(queryRoutes, $root.lnrpc.QueryRoutesRequest, $root.lnrpc.QueryRoutesResponse, request, callback);
        }, "name", { value: "QueryRoutes" });

        /**
         * Calls QueryRoutes.
         * @function queryRoutes
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IQueryRoutesRequest} request QueryRoutesRequest message or plain object
         * @returns {Promise<lnrpc.QueryRoutesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#getNetworkInfo}.
         * @memberof lnrpc.Lightning
         * @typedef GetNetworkInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.NetworkInfo} [response] NetworkInfo
         */

        /**
         * Calls GetNetworkInfo.
         * @function getNetworkInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INetworkInfoRequest} request NetworkInfoRequest message or plain object
         * @param {lnrpc.Lightning.GetNetworkInfoCallback} callback Node-style callback called with the error, if any, and NetworkInfo
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.getNetworkInfo = function getNetworkInfo(request, callback) {
            return this.rpcCall(getNetworkInfo, $root.lnrpc.NetworkInfoRequest, $root.lnrpc.NetworkInfo, request, callback);
        }, "name", { value: "GetNetworkInfo" });

        /**
         * Calls GetNetworkInfo.
         * @function getNetworkInfo
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.INetworkInfoRequest} request NetworkInfoRequest message or plain object
         * @returns {Promise<lnrpc.NetworkInfo>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#stopDaemon}.
         * @memberof lnrpc.Lightning
         * @typedef StopDaemonCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.StopResponse} [response] StopResponse
         */

        /**
         * Calls StopDaemon.
         * @function stopDaemon
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IStopRequest} request StopRequest message or plain object
         * @param {lnrpc.Lightning.StopDaemonCallback} callback Node-style callback called with the error, if any, and StopResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.stopDaemon = function stopDaemon(request, callback) {
            return this.rpcCall(stopDaemon, $root.lnrpc.StopRequest, $root.lnrpc.StopResponse, request, callback);
        }, "name", { value: "StopDaemon" });

        /**
         * Calls StopDaemon.
         * @function stopDaemon
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IStopRequest} request StopRequest message or plain object
         * @returns {Promise<lnrpc.StopResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribeChannelGraph}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribeChannelGraphCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.GraphTopologyUpdate} [response] GraphTopologyUpdate
         */

        /**
         * Calls SubscribeChannelGraph.
         * @function subscribeChannelGraph
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGraphTopologySubscription} request GraphTopologySubscription message or plain object
         * @param {lnrpc.Lightning.SubscribeChannelGraphCallback} callback Node-style callback called with the error, if any, and GraphTopologyUpdate
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribeChannelGraph = function subscribeChannelGraph(request, callback) {
            return this.rpcCall(subscribeChannelGraph, $root.lnrpc.GraphTopologySubscription, $root.lnrpc.GraphTopologyUpdate, request, callback);
        }, "name", { value: "SubscribeChannelGraph" });

        /**
         * Calls SubscribeChannelGraph.
         * @function subscribeChannelGraph
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IGraphTopologySubscription} request GraphTopologySubscription message or plain object
         * @returns {Promise<lnrpc.GraphTopologyUpdate>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#debugLevel}.
         * @memberof lnrpc.Lightning
         * @typedef DebugLevelCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.DebugLevelResponse} [response] DebugLevelResponse
         */

        /**
         * Calls DebugLevel.
         * @function debugLevel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDebugLevelRequest} request DebugLevelRequest message or plain object
         * @param {lnrpc.Lightning.DebugLevelCallback} callback Node-style callback called with the error, if any, and DebugLevelResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.debugLevel = function debugLevel(request, callback) {
            return this.rpcCall(debugLevel, $root.lnrpc.DebugLevelRequest, $root.lnrpc.DebugLevelResponse, request, callback);
        }, "name", { value: "DebugLevel" });

        /**
         * Calls DebugLevel.
         * @function debugLevel
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDebugLevelRequest} request DebugLevelRequest message or plain object
         * @returns {Promise<lnrpc.DebugLevelResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#feeReport}.
         * @memberof lnrpc.Lightning
         * @typedef FeeReportCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.FeeReportResponse} [response] FeeReportResponse
         */

        /**
         * Calls FeeReport.
         * @function feeReport
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IFeeReportRequest} request FeeReportRequest message or plain object
         * @param {lnrpc.Lightning.FeeReportCallback} callback Node-style callback called with the error, if any, and FeeReportResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.feeReport = function feeReport(request, callback) {
            return this.rpcCall(feeReport, $root.lnrpc.FeeReportRequest, $root.lnrpc.FeeReportResponse, request, callback);
        }, "name", { value: "FeeReport" });

        /**
         * Calls FeeReport.
         * @function feeReport
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IFeeReportRequest} request FeeReportRequest message or plain object
         * @returns {Promise<lnrpc.FeeReportResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#updateChannelPolicy}.
         * @memberof lnrpc.Lightning
         * @typedef UpdateChannelPolicyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.PolicyUpdateResponse} [response] PolicyUpdateResponse
         */

        /**
         * Calls UpdateChannelPolicy.
         * @function updateChannelPolicy
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPolicyUpdateRequest} request PolicyUpdateRequest message or plain object
         * @param {lnrpc.Lightning.UpdateChannelPolicyCallback} callback Node-style callback called with the error, if any, and PolicyUpdateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.updateChannelPolicy = function updateChannelPolicy(request, callback) {
            return this.rpcCall(updateChannelPolicy, $root.lnrpc.PolicyUpdateRequest, $root.lnrpc.PolicyUpdateResponse, request, callback);
        }, "name", { value: "UpdateChannelPolicy" });

        /**
         * Calls UpdateChannelPolicy.
         * @function updateChannelPolicy
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IPolicyUpdateRequest} request PolicyUpdateRequest message or plain object
         * @returns {Promise<lnrpc.PolicyUpdateResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#forwardingHistory}.
         * @memberof lnrpc.Lightning
         * @typedef ForwardingHistoryCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ForwardingHistoryResponse} [response] ForwardingHistoryResponse
         */

        /**
         * Calls ForwardingHistory.
         * @function forwardingHistory
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IForwardingHistoryRequest} request ForwardingHistoryRequest message or plain object
         * @param {lnrpc.Lightning.ForwardingHistoryCallback} callback Node-style callback called with the error, if any, and ForwardingHistoryResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.forwardingHistory = function forwardingHistory(request, callback) {
            return this.rpcCall(forwardingHistory, $root.lnrpc.ForwardingHistoryRequest, $root.lnrpc.ForwardingHistoryResponse, request, callback);
        }, "name", { value: "ForwardingHistory" });

        /**
         * Calls ForwardingHistory.
         * @function forwardingHistory
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IForwardingHistoryRequest} request ForwardingHistoryRequest message or plain object
         * @returns {Promise<lnrpc.ForwardingHistoryResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#exportChannelBackup}.
         * @memberof lnrpc.Lightning
         * @typedef ExportChannelBackupCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChannelBackup} [response] ChannelBackup
         */

        /**
         * Calls ExportChannelBackup.
         * @function exportChannelBackup
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IExportChannelBackupRequest} request ExportChannelBackupRequest message or plain object
         * @param {lnrpc.Lightning.ExportChannelBackupCallback} callback Node-style callback called with the error, if any, and ChannelBackup
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.exportChannelBackup = function exportChannelBackup(request, callback) {
            return this.rpcCall(exportChannelBackup, $root.lnrpc.ExportChannelBackupRequest, $root.lnrpc.ChannelBackup, request, callback);
        }, "name", { value: "ExportChannelBackup" });

        /**
         * Calls ExportChannelBackup.
         * @function exportChannelBackup
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IExportChannelBackupRequest} request ExportChannelBackupRequest message or plain object
         * @returns {Promise<lnrpc.ChannelBackup>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#exportAllChannelBackups}.
         * @memberof lnrpc.Lightning
         * @typedef ExportAllChannelBackupsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChanBackupSnapshot} [response] ChanBackupSnapshot
         */

        /**
         * Calls ExportAllChannelBackups.
         * @function exportAllChannelBackups
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChanBackupExportRequest} request ChanBackupExportRequest message or plain object
         * @param {lnrpc.Lightning.ExportAllChannelBackupsCallback} callback Node-style callback called with the error, if any, and ChanBackupSnapshot
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.exportAllChannelBackups = function exportAllChannelBackups(request, callback) {
            return this.rpcCall(exportAllChannelBackups, $root.lnrpc.ChanBackupExportRequest, $root.lnrpc.ChanBackupSnapshot, request, callback);
        }, "name", { value: "ExportAllChannelBackups" });

        /**
         * Calls ExportAllChannelBackups.
         * @function exportAllChannelBackups
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChanBackupExportRequest} request ChanBackupExportRequest message or plain object
         * @returns {Promise<lnrpc.ChanBackupSnapshot>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#verifyChanBackup}.
         * @memberof lnrpc.Lightning
         * @typedef VerifyChanBackupCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.VerifyChanBackupResponse} [response] VerifyChanBackupResponse
         */

        /**
         * Calls VerifyChanBackup.
         * @function verifyChanBackup
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChanBackupSnapshot} request ChanBackupSnapshot message or plain object
         * @param {lnrpc.Lightning.VerifyChanBackupCallback} callback Node-style callback called with the error, if any, and VerifyChanBackupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.verifyChanBackup = function verifyChanBackup(request, callback) {
            return this.rpcCall(verifyChanBackup, $root.lnrpc.ChanBackupSnapshot, $root.lnrpc.VerifyChanBackupResponse, request, callback);
        }, "name", { value: "VerifyChanBackup" });

        /**
         * Calls VerifyChanBackup.
         * @function verifyChanBackup
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChanBackupSnapshot} request ChanBackupSnapshot message or plain object
         * @returns {Promise<lnrpc.VerifyChanBackupResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#restoreChannelBackups}.
         * @memberof lnrpc.Lightning
         * @typedef RestoreChannelBackupsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.RestoreBackupResponse} [response] RestoreBackupResponse
         */

        /**
         * Calls RestoreChannelBackups.
         * @function restoreChannelBackups
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IRestoreChanBackupRequest} request RestoreChanBackupRequest message or plain object
         * @param {lnrpc.Lightning.RestoreChannelBackupsCallback} callback Node-style callback called with the error, if any, and RestoreBackupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.restoreChannelBackups = function restoreChannelBackups(request, callback) {
            return this.rpcCall(restoreChannelBackups, $root.lnrpc.RestoreChanBackupRequest, $root.lnrpc.RestoreBackupResponse, request, callback);
        }, "name", { value: "RestoreChannelBackups" });

        /**
         * Calls RestoreChannelBackups.
         * @function restoreChannelBackups
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IRestoreChanBackupRequest} request RestoreChanBackupRequest message or plain object
         * @returns {Promise<lnrpc.RestoreBackupResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribeChannelBackups}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribeChannelBackupsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChanBackupSnapshot} [response] ChanBackupSnapshot
         */

        /**
         * Calls SubscribeChannelBackups.
         * @function subscribeChannelBackups
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelBackupSubscription} request ChannelBackupSubscription message or plain object
         * @param {lnrpc.Lightning.SubscribeChannelBackupsCallback} callback Node-style callback called with the error, if any, and ChanBackupSnapshot
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribeChannelBackups = function subscribeChannelBackups(request, callback) {
            return this.rpcCall(subscribeChannelBackups, $root.lnrpc.ChannelBackupSubscription, $root.lnrpc.ChanBackupSnapshot, request, callback);
        }, "name", { value: "SubscribeChannelBackups" });

        /**
         * Calls SubscribeChannelBackups.
         * @function subscribeChannelBackups
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IChannelBackupSubscription} request ChannelBackupSubscription message or plain object
         * @returns {Promise<lnrpc.ChanBackupSnapshot>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#bakeMacaroon}.
         * @memberof lnrpc.Lightning
         * @typedef BakeMacaroonCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.BakeMacaroonResponse} [response] BakeMacaroonResponse
         */

        /**
         * Calls BakeMacaroon.
         * @function bakeMacaroon
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IBakeMacaroonRequest} request BakeMacaroonRequest message or plain object
         * @param {lnrpc.Lightning.BakeMacaroonCallback} callback Node-style callback called with the error, if any, and BakeMacaroonResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.bakeMacaroon = function bakeMacaroon(request, callback) {
            return this.rpcCall(bakeMacaroon, $root.lnrpc.BakeMacaroonRequest, $root.lnrpc.BakeMacaroonResponse, request, callback);
        }, "name", { value: "BakeMacaroon" });

        /**
         * Calls BakeMacaroon.
         * @function bakeMacaroon
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IBakeMacaroonRequest} request BakeMacaroonRequest message or plain object
         * @returns {Promise<lnrpc.BakeMacaroonResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listMacaroonIDs}.
         * @memberof lnrpc.Lightning
         * @typedef ListMacaroonIDsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListMacaroonIDsResponse} [response] ListMacaroonIDsResponse
         */

        /**
         * Calls ListMacaroonIDs.
         * @function listMacaroonIDs
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListMacaroonIDsRequest} request ListMacaroonIDsRequest message or plain object
         * @param {lnrpc.Lightning.ListMacaroonIDsCallback} callback Node-style callback called with the error, if any, and ListMacaroonIDsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listMacaroonIDs = function listMacaroonIDs(request, callback) {
            return this.rpcCall(listMacaroonIDs, $root.lnrpc.ListMacaroonIDsRequest, $root.lnrpc.ListMacaroonIDsResponse, request, callback);
        }, "name", { value: "ListMacaroonIDs" });

        /**
         * Calls ListMacaroonIDs.
         * @function listMacaroonIDs
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListMacaroonIDsRequest} request ListMacaroonIDsRequest message or plain object
         * @returns {Promise<lnrpc.ListMacaroonIDsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#deleteMacaroonID}.
         * @memberof lnrpc.Lightning
         * @typedef DeleteMacaroonIDCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.DeleteMacaroonIDResponse} [response] DeleteMacaroonIDResponse
         */

        /**
         * Calls DeleteMacaroonID.
         * @function deleteMacaroonID
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDeleteMacaroonIDRequest} request DeleteMacaroonIDRequest message or plain object
         * @param {lnrpc.Lightning.DeleteMacaroonIDCallback} callback Node-style callback called with the error, if any, and DeleteMacaroonIDResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.deleteMacaroonID = function deleteMacaroonID(request, callback) {
            return this.rpcCall(deleteMacaroonID, $root.lnrpc.DeleteMacaroonIDRequest, $root.lnrpc.DeleteMacaroonIDResponse, request, callback);
        }, "name", { value: "DeleteMacaroonID" });

        /**
         * Calls DeleteMacaroonID.
         * @function deleteMacaroonID
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IDeleteMacaroonIDRequest} request DeleteMacaroonIDRequest message or plain object
         * @returns {Promise<lnrpc.DeleteMacaroonIDResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listPermissions}.
         * @memberof lnrpc.Lightning
         * @typedef ListPermissionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListPermissionsResponse} [response] ListPermissionsResponse
         */

        /**
         * Calls ListPermissions.
         * @function listPermissions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListPermissionsRequest} request ListPermissionsRequest message or plain object
         * @param {lnrpc.Lightning.ListPermissionsCallback} callback Node-style callback called with the error, if any, and ListPermissionsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listPermissions = function listPermissions(request, callback) {
            return this.rpcCall(listPermissions, $root.lnrpc.ListPermissionsRequest, $root.lnrpc.ListPermissionsResponse, request, callback);
        }, "name", { value: "ListPermissions" });

        /**
         * Calls ListPermissions.
         * @function listPermissions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListPermissionsRequest} request ListPermissionsRequest message or plain object
         * @returns {Promise<lnrpc.ListPermissionsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#checkMacaroonPermissions}.
         * @memberof lnrpc.Lightning
         * @typedef CheckMacaroonPermissionsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.CheckMacPermResponse} [response] CheckMacPermResponse
         */

        /**
         * Calls CheckMacaroonPermissions.
         * @function checkMacaroonPermissions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ICheckMacPermRequest} request CheckMacPermRequest message or plain object
         * @param {lnrpc.Lightning.CheckMacaroonPermissionsCallback} callback Node-style callback called with the error, if any, and CheckMacPermResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.checkMacaroonPermissions = function checkMacaroonPermissions(request, callback) {
            return this.rpcCall(checkMacaroonPermissions, $root.lnrpc.CheckMacPermRequest, $root.lnrpc.CheckMacPermResponse, request, callback);
        }, "name", { value: "CheckMacaroonPermissions" });

        /**
         * Calls CheckMacaroonPermissions.
         * @function checkMacaroonPermissions
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ICheckMacPermRequest} request CheckMacPermRequest message or plain object
         * @returns {Promise<lnrpc.CheckMacPermResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#registerRPCMiddleware}.
         * @memberof lnrpc.Lightning
         * @typedef RegisterRPCMiddlewareCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.RPCMiddlewareRequest} [response] RPCMiddlewareRequest
         */

        /**
         * Calls RegisterRPCMiddleware.
         * @function registerRPCMiddleware
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IRPCMiddlewareResponse} request RPCMiddlewareResponse message or plain object
         * @param {lnrpc.Lightning.RegisterRPCMiddlewareCallback} callback Node-style callback called with the error, if any, and RPCMiddlewareRequest
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.registerRPCMiddleware = function registerRPCMiddleware(request, callback) {
            return this.rpcCall(registerRPCMiddleware, $root.lnrpc.RPCMiddlewareResponse, $root.lnrpc.RPCMiddlewareRequest, request, callback);
        }, "name", { value: "RegisterRPCMiddleware" });

        /**
         * Calls RegisterRPCMiddleware.
         * @function registerRPCMiddleware
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IRPCMiddlewareResponse} request RPCMiddlewareResponse message or plain object
         * @returns {Promise<lnrpc.RPCMiddlewareRequest>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#sendCustomMessage}.
         * @memberof lnrpc.Lightning
         * @typedef SendCustomMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SendCustomMessageResponse} [response] SendCustomMessageResponse
         */

        /**
         * Calls SendCustomMessage.
         * @function sendCustomMessage
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendCustomMessageRequest} request SendCustomMessageRequest message or plain object
         * @param {lnrpc.Lightning.SendCustomMessageCallback} callback Node-style callback called with the error, if any, and SendCustomMessageResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.sendCustomMessage = function sendCustomMessage(request, callback) {
            return this.rpcCall(sendCustomMessage, $root.lnrpc.SendCustomMessageRequest, $root.lnrpc.SendCustomMessageResponse, request, callback);
        }, "name", { value: "SendCustomMessage" });

        /**
         * Calls SendCustomMessage.
         * @function sendCustomMessage
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISendCustomMessageRequest} request SendCustomMessageRequest message or plain object
         * @returns {Promise<lnrpc.SendCustomMessageResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#subscribeCustomMessages}.
         * @memberof lnrpc.Lightning
         * @typedef SubscribeCustomMessagesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.CustomMessage} [response] CustomMessage
         */

        /**
         * Calls SubscribeCustomMessages.
         * @function subscribeCustomMessages
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISubscribeCustomMessagesRequest} request SubscribeCustomMessagesRequest message or plain object
         * @param {lnrpc.Lightning.SubscribeCustomMessagesCallback} callback Node-style callback called with the error, if any, and CustomMessage
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.subscribeCustomMessages = function subscribeCustomMessages(request, callback) {
            return this.rpcCall(subscribeCustomMessages, $root.lnrpc.SubscribeCustomMessagesRequest, $root.lnrpc.CustomMessage, request, callback);
        }, "name", { value: "SubscribeCustomMessages" });

        /**
         * Calls SubscribeCustomMessages.
         * @function subscribeCustomMessages
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.ISubscribeCustomMessagesRequest} request SubscribeCustomMessagesRequest message or plain object
         * @returns {Promise<lnrpc.CustomMessage>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.Lightning#listAliases}.
         * @memberof lnrpc.Lightning
         * @typedef ListAliasesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ListAliasesResponse} [response] ListAliasesResponse
         */

        /**
         * Calls ListAliases.
         * @function listAliases
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListAliasesRequest} request ListAliasesRequest message or plain object
         * @param {lnrpc.Lightning.ListAliasesCallback} callback Node-style callback called with the error, if any, and ListAliasesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Lightning.prototype.listAliases = function listAliases(request, callback) {
            return this.rpcCall(listAliases, $root.lnrpc.ListAliasesRequest, $root.lnrpc.ListAliasesResponse, request, callback);
        }, "name", { value: "ListAliases" });

        /**
         * Calls ListAliases.
         * @function listAliases
         * @memberof lnrpc.Lightning
         * @instance
         * @param {lnrpc.IListAliasesRequest} request ListAliasesRequest message or plain object
         * @returns {Promise<lnrpc.ListAliasesResponse>} Promise
         * @variation 2
         */

        return Lightning;
    })();

    lnrpc.SubscribeCustomMessagesRequest = (function() {

        /**
         * Properties of a SubscribeCustomMessagesRequest.
         * @memberof lnrpc
         * @interface ISubscribeCustomMessagesRequest
         */

        /**
         * Constructs a new SubscribeCustomMessagesRequest.
         * @memberof lnrpc
         * @classdesc Represents a SubscribeCustomMessagesRequest.
         * @implements ISubscribeCustomMessagesRequest
         * @constructor
         * @param {lnrpc.ISubscribeCustomMessagesRequest=} [properties] Properties to set
         */
        function SubscribeCustomMessagesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SubscribeCustomMessagesRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {lnrpc.ISubscribeCustomMessagesRequest=} [properties] Properties to set
         * @returns {lnrpc.SubscribeCustomMessagesRequest} SubscribeCustomMessagesRequest instance
         */
        SubscribeCustomMessagesRequest.create = function create(properties) {
            return new SubscribeCustomMessagesRequest(properties);
        };

        /**
         * Encodes the specified SubscribeCustomMessagesRequest message. Does not implicitly {@link lnrpc.SubscribeCustomMessagesRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {lnrpc.ISubscribeCustomMessagesRequest} message SubscribeCustomMessagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeCustomMessagesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SubscribeCustomMessagesRequest message, length delimited. Does not implicitly {@link lnrpc.SubscribeCustomMessagesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {lnrpc.ISubscribeCustomMessagesRequest} message SubscribeCustomMessagesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeCustomMessagesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubscribeCustomMessagesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SubscribeCustomMessagesRequest} SubscribeCustomMessagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeCustomMessagesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SubscribeCustomMessagesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubscribeCustomMessagesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SubscribeCustomMessagesRequest} SubscribeCustomMessagesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeCustomMessagesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubscribeCustomMessagesRequest message.
         * @function verify
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubscribeCustomMessagesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SubscribeCustomMessagesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SubscribeCustomMessagesRequest} SubscribeCustomMessagesRequest
         */
        SubscribeCustomMessagesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SubscribeCustomMessagesRequest)
                return object;
            return new $root.lnrpc.SubscribeCustomMessagesRequest();
        };

        /**
         * Creates a plain object from a SubscribeCustomMessagesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @static
         * @param {lnrpc.SubscribeCustomMessagesRequest} message SubscribeCustomMessagesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscribeCustomMessagesRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SubscribeCustomMessagesRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SubscribeCustomMessagesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscribeCustomMessagesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscribeCustomMessagesRequest;
    })();

    lnrpc.CustomMessage = (function() {

        /**
         * Properties of a CustomMessage.
         * @memberof lnrpc
         * @interface ICustomMessage
         * @property {Uint8Array|null} [peer] CustomMessage peer
         * @property {number|null} [type] CustomMessage type
         * @property {Uint8Array|null} [data] CustomMessage data
         */

        /**
         * Constructs a new CustomMessage.
         * @memberof lnrpc
         * @classdesc Represents a CustomMessage.
         * @implements ICustomMessage
         * @constructor
         * @param {lnrpc.ICustomMessage=} [properties] Properties to set
         */
        function CustomMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomMessage peer.
         * @member {Uint8Array} peer
         * @memberof lnrpc.CustomMessage
         * @instance
         */
        CustomMessage.prototype.peer = $util.newBuffer([]);

        /**
         * CustomMessage type.
         * @member {number} type
         * @memberof lnrpc.CustomMessage
         * @instance
         */
        CustomMessage.prototype.type = 0;

        /**
         * CustomMessage data.
         * @member {Uint8Array} data
         * @memberof lnrpc.CustomMessage
         * @instance
         */
        CustomMessage.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new CustomMessage instance using the specified properties.
         * @function create
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {lnrpc.ICustomMessage=} [properties] Properties to set
         * @returns {lnrpc.CustomMessage} CustomMessage instance
         */
        CustomMessage.create = function create(properties) {
            return new CustomMessage(properties);
        };

        /**
         * Encodes the specified CustomMessage message. Does not implicitly {@link lnrpc.CustomMessage.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {lnrpc.ICustomMessage} message CustomMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.peer);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.type);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified CustomMessage message, length delimited. Does not implicitly {@link lnrpc.CustomMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {lnrpc.ICustomMessage} message CustomMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CustomMessage message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.CustomMessage} CustomMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.CustomMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.peer = reader.bytes();
                        break;
                    }
                case 2: {
                        message.type = reader.uint32();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CustomMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.CustomMessage} CustomMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CustomMessage message.
         * @function verify
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CustomMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.peer != null && message.hasOwnProperty("peer"))
                if (!(message.peer && typeof message.peer.length === "number" || $util.isString(message.peer)))
                    return "peer: buffer expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isInteger(message.type))
                    return "type: integer expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a CustomMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.CustomMessage} CustomMessage
         */
        CustomMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.CustomMessage)
                return object;
            let message = new $root.lnrpc.CustomMessage();
            if (object.peer != null)
                if (typeof object.peer === "string")
                    $util.base64.decode(object.peer, message.peer = $util.newBuffer($util.base64.length(object.peer)), 0);
                else if (object.peer.length)
                    message.peer = object.peer;
            if (object.type != null)
                message.type = object.type >>> 0;
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a CustomMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.CustomMessage
         * @static
         * @param {lnrpc.CustomMessage} message CustomMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CustomMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.peer = "";
                else {
                    object.peer = [];
                    if (options.bytes !== Array)
                        object.peer = $util.newBuffer(object.peer);
                }
                object.type = 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.peer != null && message.hasOwnProperty("peer"))
                object.peer = options.bytes === String ? $util.base64.encode(message.peer, 0, message.peer.length) : options.bytes === Array ? Array.prototype.slice.call(message.peer) : message.peer;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this CustomMessage to JSON.
         * @function toJSON
         * @memberof lnrpc.CustomMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CustomMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CustomMessage;
    })();

    lnrpc.SendCustomMessageRequest = (function() {

        /**
         * Properties of a SendCustomMessageRequest.
         * @memberof lnrpc
         * @interface ISendCustomMessageRequest
         * @property {Uint8Array|null} [peer] SendCustomMessageRequest peer
         * @property {number|null} [type] SendCustomMessageRequest type
         * @property {Uint8Array|null} [data] SendCustomMessageRequest data
         */

        /**
         * Constructs a new SendCustomMessageRequest.
         * @memberof lnrpc
         * @classdesc Represents a SendCustomMessageRequest.
         * @implements ISendCustomMessageRequest
         * @constructor
         * @param {lnrpc.ISendCustomMessageRequest=} [properties] Properties to set
         */
        function SendCustomMessageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendCustomMessageRequest peer.
         * @member {Uint8Array} peer
         * @memberof lnrpc.SendCustomMessageRequest
         * @instance
         */
        SendCustomMessageRequest.prototype.peer = $util.newBuffer([]);

        /**
         * SendCustomMessageRequest type.
         * @member {number} type
         * @memberof lnrpc.SendCustomMessageRequest
         * @instance
         */
        SendCustomMessageRequest.prototype.type = 0;

        /**
         * SendCustomMessageRequest data.
         * @member {Uint8Array} data
         * @memberof lnrpc.SendCustomMessageRequest
         * @instance
         */
        SendCustomMessageRequest.prototype.data = $util.newBuffer([]);

        /**
         * Creates a new SendCustomMessageRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {lnrpc.ISendCustomMessageRequest=} [properties] Properties to set
         * @returns {lnrpc.SendCustomMessageRequest} SendCustomMessageRequest instance
         */
        SendCustomMessageRequest.create = function create(properties) {
            return new SendCustomMessageRequest(properties);
        };

        /**
         * Encodes the specified SendCustomMessageRequest message. Does not implicitly {@link lnrpc.SendCustomMessageRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {lnrpc.ISendCustomMessageRequest} message SendCustomMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCustomMessageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.peer);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.type);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.data);
            return writer;
        };

        /**
         * Encodes the specified SendCustomMessageRequest message, length delimited. Does not implicitly {@link lnrpc.SendCustomMessageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {lnrpc.ISendCustomMessageRequest} message SendCustomMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCustomMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendCustomMessageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendCustomMessageRequest} SendCustomMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCustomMessageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendCustomMessageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.peer = reader.bytes();
                        break;
                    }
                case 2: {
                        message.type = reader.uint32();
                        break;
                    }
                case 3: {
                        message.data = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendCustomMessageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendCustomMessageRequest} SendCustomMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCustomMessageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendCustomMessageRequest message.
         * @function verify
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendCustomMessageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.peer != null && message.hasOwnProperty("peer"))
                if (!(message.peer && typeof message.peer.length === "number" || $util.isString(message.peer)))
                    return "peer: buffer expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isInteger(message.type))
                    return "type: integer expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            return null;
        };

        /**
         * Creates a SendCustomMessageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendCustomMessageRequest} SendCustomMessageRequest
         */
        SendCustomMessageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendCustomMessageRequest)
                return object;
            let message = new $root.lnrpc.SendCustomMessageRequest();
            if (object.peer != null)
                if (typeof object.peer === "string")
                    $util.base64.decode(object.peer, message.peer = $util.newBuffer($util.base64.length(object.peer)), 0);
                else if (object.peer.length)
                    message.peer = object.peer;
            if (object.type != null)
                message.type = object.type >>> 0;
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length)
                    message.data = object.data;
            return message;
        };

        /**
         * Creates a plain object from a SendCustomMessageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendCustomMessageRequest
         * @static
         * @param {lnrpc.SendCustomMessageRequest} message SendCustomMessageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendCustomMessageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.peer = "";
                else {
                    object.peer = [];
                    if (options.bytes !== Array)
                        object.peer = $util.newBuffer(object.peer);
                }
                object.type = 0;
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
            }
            if (message.peer != null && message.hasOwnProperty("peer"))
                object.peer = options.bytes === String ? $util.base64.encode(message.peer, 0, message.peer.length) : options.bytes === Array ? Array.prototype.slice.call(message.peer) : message.peer;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            return object;
        };

        /**
         * Converts this SendCustomMessageRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SendCustomMessageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendCustomMessageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendCustomMessageRequest;
    })();

    lnrpc.SendCustomMessageResponse = (function() {

        /**
         * Properties of a SendCustomMessageResponse.
         * @memberof lnrpc
         * @interface ISendCustomMessageResponse
         */

        /**
         * Constructs a new SendCustomMessageResponse.
         * @memberof lnrpc
         * @classdesc Represents a SendCustomMessageResponse.
         * @implements ISendCustomMessageResponse
         * @constructor
         * @param {lnrpc.ISendCustomMessageResponse=} [properties] Properties to set
         */
        function SendCustomMessageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SendCustomMessageResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {lnrpc.ISendCustomMessageResponse=} [properties] Properties to set
         * @returns {lnrpc.SendCustomMessageResponse} SendCustomMessageResponse instance
         */
        SendCustomMessageResponse.create = function create(properties) {
            return new SendCustomMessageResponse(properties);
        };

        /**
         * Encodes the specified SendCustomMessageResponse message. Does not implicitly {@link lnrpc.SendCustomMessageResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {lnrpc.ISendCustomMessageResponse} message SendCustomMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCustomMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SendCustomMessageResponse message, length delimited. Does not implicitly {@link lnrpc.SendCustomMessageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {lnrpc.ISendCustomMessageResponse} message SendCustomMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCustomMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendCustomMessageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendCustomMessageResponse} SendCustomMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCustomMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendCustomMessageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendCustomMessageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendCustomMessageResponse} SendCustomMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCustomMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendCustomMessageResponse message.
         * @function verify
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendCustomMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SendCustomMessageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendCustomMessageResponse} SendCustomMessageResponse
         */
        SendCustomMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendCustomMessageResponse)
                return object;
            return new $root.lnrpc.SendCustomMessageResponse();
        };

        /**
         * Creates a plain object from a SendCustomMessageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendCustomMessageResponse
         * @static
         * @param {lnrpc.SendCustomMessageResponse} message SendCustomMessageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendCustomMessageResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SendCustomMessageResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.SendCustomMessageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendCustomMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendCustomMessageResponse;
    })();

    lnrpc.Utxo = (function() {

        /**
         * Properties of an Utxo.
         * @memberof lnrpc
         * @interface IUtxo
         * @property {lnrpc.AddressType|null} [addressType] Utxo addressType
         * @property {string|null} [address] Utxo address
         * @property {Long|null} [amountSat] Utxo amountSat
         * @property {string|null} [pkScript] Utxo pkScript
         * @property {lnrpc.IOutPoint|null} [outpoint] Utxo outpoint
         * @property {Long|null} [confirmations] Utxo confirmations
         */

        /**
         * Constructs a new Utxo.
         * @memberof lnrpc
         * @classdesc Represents an Utxo.
         * @implements IUtxo
         * @constructor
         * @param {lnrpc.IUtxo=} [properties] Properties to set
         */
        function Utxo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Utxo addressType.
         * @member {lnrpc.AddressType} addressType
         * @memberof lnrpc.Utxo
         * @instance
         */
        Utxo.prototype.addressType = 0;

        /**
         * Utxo address.
         * @member {string} address
         * @memberof lnrpc.Utxo
         * @instance
         */
        Utxo.prototype.address = "";

        /**
         * Utxo amountSat.
         * @member {Long} amountSat
         * @memberof lnrpc.Utxo
         * @instance
         */
        Utxo.prototype.amountSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Utxo pkScript.
         * @member {string} pkScript
         * @memberof lnrpc.Utxo
         * @instance
         */
        Utxo.prototype.pkScript = "";

        /**
         * Utxo outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof lnrpc.Utxo
         * @instance
         */
        Utxo.prototype.outpoint = null;

        /**
         * Utxo confirmations.
         * @member {Long} confirmations
         * @memberof lnrpc.Utxo
         * @instance
         */
        Utxo.prototype.confirmations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Utxo instance using the specified properties.
         * @function create
         * @memberof lnrpc.Utxo
         * @static
         * @param {lnrpc.IUtxo=} [properties] Properties to set
         * @returns {lnrpc.Utxo} Utxo instance
         */
        Utxo.create = function create(properties) {
            return new Utxo(properties);
        };

        /**
         * Encodes the specified Utxo message. Does not implicitly {@link lnrpc.Utxo.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Utxo
         * @static
         * @param {lnrpc.IUtxo} message Utxo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Utxo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addressType != null && Object.hasOwnProperty.call(message, "addressType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.addressType);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
            if (message.amountSat != null && Object.hasOwnProperty.call(message, "amountSat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amountSat);
            if (message.pkScript != null && Object.hasOwnProperty.call(message, "pkScript"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.pkScript);
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.confirmations != null && Object.hasOwnProperty.call(message, "confirmations"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.confirmations);
            return writer;
        };

        /**
         * Encodes the specified Utxo message, length delimited. Does not implicitly {@link lnrpc.Utxo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Utxo
         * @static
         * @param {lnrpc.IUtxo} message Utxo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Utxo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Utxo message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Utxo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Utxo} Utxo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Utxo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Utxo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.addressType = reader.int32();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                case 3: {
                        message.amountSat = reader.int64();
                        break;
                    }
                case 4: {
                        message.pkScript = reader.string();
                        break;
                    }
                case 5: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.confirmations = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Utxo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Utxo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Utxo} Utxo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Utxo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Utxo message.
         * @function verify
         * @memberof lnrpc.Utxo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Utxo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                switch (message.addressType) {
                default:
                    return "addressType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            if (message.amountSat != null && message.hasOwnProperty("amountSat"))
                if (!$util.isInteger(message.amountSat) && !(message.amountSat && $util.isInteger(message.amountSat.low) && $util.isInteger(message.amountSat.high)))
                    return "amountSat: integer|Long expected";
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                if (!$util.isString(message.pkScript))
                    return "pkScript: string expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                if (!$util.isInteger(message.confirmations) && !(message.confirmations && $util.isInteger(message.confirmations.low) && $util.isInteger(message.confirmations.high)))
                    return "confirmations: integer|Long expected";
            return null;
        };

        /**
         * Creates an Utxo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Utxo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Utxo} Utxo
         */
        Utxo.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Utxo)
                return object;
            let message = new $root.lnrpc.Utxo();
            switch (object.addressType) {
            case "WITNESS_PUBKEY_HASH":
            case 0:
                message.addressType = 0;
                break;
            case "NESTED_PUBKEY_HASH":
            case 1:
                message.addressType = 1;
                break;
            case "UNUSED_WITNESS_PUBKEY_HASH":
            case 2:
                message.addressType = 2;
                break;
            case "UNUSED_NESTED_PUBKEY_HASH":
            case 3:
                message.addressType = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.addressType = 4;
                break;
            case "UNUSED_TAPROOT_PUBKEY":
            case 5:
                message.addressType = 5;
                break;
            }
            if (object.address != null)
                message.address = String(object.address);
            if (object.amountSat != null)
                if ($util.Long)
                    (message.amountSat = $util.Long.fromValue(object.amountSat)).unsigned = false;
                else if (typeof object.amountSat === "string")
                    message.amountSat = parseInt(object.amountSat, 10);
                else if (typeof object.amountSat === "number")
                    message.amountSat = object.amountSat;
                else if (typeof object.amountSat === "object")
                    message.amountSat = new $util.LongBits(object.amountSat.low >>> 0, object.amountSat.high >>> 0).toNumber();
            if (object.pkScript != null)
                message.pkScript = String(object.pkScript);
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".lnrpc.Utxo.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            if (object.confirmations != null)
                if ($util.Long)
                    (message.confirmations = $util.Long.fromValue(object.confirmations)).unsigned = false;
                else if (typeof object.confirmations === "string")
                    message.confirmations = parseInt(object.confirmations, 10);
                else if (typeof object.confirmations === "number")
                    message.confirmations = object.confirmations;
                else if (typeof object.confirmations === "object")
                    message.confirmations = new $util.LongBits(object.confirmations.low >>> 0, object.confirmations.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an Utxo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Utxo
         * @static
         * @param {lnrpc.Utxo} message Utxo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Utxo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.addressType = options.enums === String ? "WITNESS_PUBKEY_HASH" : 0;
                object.address = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amountSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amountSat = options.longs === String ? "0" : 0;
                object.pkScript = "";
                object.outpoint = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.confirmations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.confirmations = options.longs === String ? "0" : 0;
            }
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                object.addressType = options.enums === String ? $root.lnrpc.AddressType[message.addressType] : message.addressType;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.amountSat != null && message.hasOwnProperty("amountSat"))
                if (typeof message.amountSat === "number")
                    object.amountSat = options.longs === String ? String(message.amountSat) : message.amountSat;
                else
                    object.amountSat = options.longs === String ? $util.Long.prototype.toString.call(message.amountSat) : options.longs === Number ? new $util.LongBits(message.amountSat.low >>> 0, message.amountSat.high >>> 0).toNumber() : message.amountSat;
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                object.pkScript = message.pkScript;
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                if (typeof message.confirmations === "number")
                    object.confirmations = options.longs === String ? String(message.confirmations) : message.confirmations;
                else
                    object.confirmations = options.longs === String ? $util.Long.prototype.toString.call(message.confirmations) : options.longs === Number ? new $util.LongBits(message.confirmations.low >>> 0, message.confirmations.high >>> 0).toNumber() : message.confirmations;
            return object;
        };

        /**
         * Converts this Utxo to JSON.
         * @function toJSON
         * @memberof lnrpc.Utxo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Utxo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Utxo;
    })();

    /**
     * OutputScriptType enum.
     * @name lnrpc.OutputScriptType
     * @enum {number}
     * @property {number} SCRIPT_TYPE_PUBKEY_HASH=0 SCRIPT_TYPE_PUBKEY_HASH value
     * @property {number} SCRIPT_TYPE_SCRIPT_HASH=1 SCRIPT_TYPE_SCRIPT_HASH value
     * @property {number} SCRIPT_TYPE_WITNESS_V0_PUBKEY_HASH=2 SCRIPT_TYPE_WITNESS_V0_PUBKEY_HASH value
     * @property {number} SCRIPT_TYPE_WITNESS_V0_SCRIPT_HASH=3 SCRIPT_TYPE_WITNESS_V0_SCRIPT_HASH value
     * @property {number} SCRIPT_TYPE_PUBKEY=4 SCRIPT_TYPE_PUBKEY value
     * @property {number} SCRIPT_TYPE_MULTISIG=5 SCRIPT_TYPE_MULTISIG value
     * @property {number} SCRIPT_TYPE_NULLDATA=6 SCRIPT_TYPE_NULLDATA value
     * @property {number} SCRIPT_TYPE_NON_STANDARD=7 SCRIPT_TYPE_NON_STANDARD value
     * @property {number} SCRIPT_TYPE_WITNESS_UNKNOWN=8 SCRIPT_TYPE_WITNESS_UNKNOWN value
     */
    lnrpc.OutputScriptType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SCRIPT_TYPE_PUBKEY_HASH"] = 0;
        values[valuesById[1] = "SCRIPT_TYPE_SCRIPT_HASH"] = 1;
        values[valuesById[2] = "SCRIPT_TYPE_WITNESS_V0_PUBKEY_HASH"] = 2;
        values[valuesById[3] = "SCRIPT_TYPE_WITNESS_V0_SCRIPT_HASH"] = 3;
        values[valuesById[4] = "SCRIPT_TYPE_PUBKEY"] = 4;
        values[valuesById[5] = "SCRIPT_TYPE_MULTISIG"] = 5;
        values[valuesById[6] = "SCRIPT_TYPE_NULLDATA"] = 6;
        values[valuesById[7] = "SCRIPT_TYPE_NON_STANDARD"] = 7;
        values[valuesById[8] = "SCRIPT_TYPE_WITNESS_UNKNOWN"] = 8;
        return values;
    })();

    lnrpc.OutputDetail = (function() {

        /**
         * Properties of an OutputDetail.
         * @memberof lnrpc
         * @interface IOutputDetail
         * @property {lnrpc.OutputScriptType|null} [outputType] OutputDetail outputType
         * @property {string|null} [address] OutputDetail address
         * @property {string|null} [pkScript] OutputDetail pkScript
         * @property {Long|null} [outputIndex] OutputDetail outputIndex
         * @property {Long|null} [amount] OutputDetail amount
         * @property {boolean|null} [isOurAddress] OutputDetail isOurAddress
         */

        /**
         * Constructs a new OutputDetail.
         * @memberof lnrpc
         * @classdesc Represents an OutputDetail.
         * @implements IOutputDetail
         * @constructor
         * @param {lnrpc.IOutputDetail=} [properties] Properties to set
         */
        function OutputDetail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutputDetail outputType.
         * @member {lnrpc.OutputScriptType} outputType
         * @memberof lnrpc.OutputDetail
         * @instance
         */
        OutputDetail.prototype.outputType = 0;

        /**
         * OutputDetail address.
         * @member {string} address
         * @memberof lnrpc.OutputDetail
         * @instance
         */
        OutputDetail.prototype.address = "";

        /**
         * OutputDetail pkScript.
         * @member {string} pkScript
         * @memberof lnrpc.OutputDetail
         * @instance
         */
        OutputDetail.prototype.pkScript = "";

        /**
         * OutputDetail outputIndex.
         * @member {Long} outputIndex
         * @memberof lnrpc.OutputDetail
         * @instance
         */
        OutputDetail.prototype.outputIndex = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OutputDetail amount.
         * @member {Long} amount
         * @memberof lnrpc.OutputDetail
         * @instance
         */
        OutputDetail.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OutputDetail isOurAddress.
         * @member {boolean} isOurAddress
         * @memberof lnrpc.OutputDetail
         * @instance
         */
        OutputDetail.prototype.isOurAddress = false;

        /**
         * Creates a new OutputDetail instance using the specified properties.
         * @function create
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {lnrpc.IOutputDetail=} [properties] Properties to set
         * @returns {lnrpc.OutputDetail} OutputDetail instance
         */
        OutputDetail.create = function create(properties) {
            return new OutputDetail(properties);
        };

        /**
         * Encodes the specified OutputDetail message. Does not implicitly {@link lnrpc.OutputDetail.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {lnrpc.IOutputDetail} message OutputDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutputDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.outputType);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
            if (message.pkScript != null && Object.hasOwnProperty.call(message, "pkScript"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.pkScript);
            if (message.outputIndex != null && Object.hasOwnProperty.call(message, "outputIndex"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.outputIndex);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.amount);
            if (message.isOurAddress != null && Object.hasOwnProperty.call(message, "isOurAddress"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isOurAddress);
            return writer;
        };

        /**
         * Encodes the specified OutputDetail message, length delimited. Does not implicitly {@link lnrpc.OutputDetail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {lnrpc.IOutputDetail} message OutputDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutputDetail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutputDetail message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.OutputDetail} OutputDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutputDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.OutputDetail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.outputType = reader.int32();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                case 3: {
                        message.pkScript = reader.string();
                        break;
                    }
                case 4: {
                        message.outputIndex = reader.int64();
                        break;
                    }
                case 5: {
                        message.amount = reader.int64();
                        break;
                    }
                case 6: {
                        message.isOurAddress = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutputDetail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.OutputDetail} OutputDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutputDetail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutputDetail message.
         * @function verify
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutputDetail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outputType != null && message.hasOwnProperty("outputType"))
                switch (message.outputType) {
                default:
                    return "outputType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                if (!$util.isString(message.pkScript))
                    return "pkScript: string expected";
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                if (!$util.isInteger(message.outputIndex) && !(message.outputIndex && $util.isInteger(message.outputIndex.low) && $util.isInteger(message.outputIndex.high)))
                    return "outputIndex: integer|Long expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            if (message.isOurAddress != null && message.hasOwnProperty("isOurAddress"))
                if (typeof message.isOurAddress !== "boolean")
                    return "isOurAddress: boolean expected";
            return null;
        };

        /**
         * Creates an OutputDetail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.OutputDetail} OutputDetail
         */
        OutputDetail.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.OutputDetail)
                return object;
            let message = new $root.lnrpc.OutputDetail();
            switch (object.outputType) {
            case "SCRIPT_TYPE_PUBKEY_HASH":
            case 0:
                message.outputType = 0;
                break;
            case "SCRIPT_TYPE_SCRIPT_HASH":
            case 1:
                message.outputType = 1;
                break;
            case "SCRIPT_TYPE_WITNESS_V0_PUBKEY_HASH":
            case 2:
                message.outputType = 2;
                break;
            case "SCRIPT_TYPE_WITNESS_V0_SCRIPT_HASH":
            case 3:
                message.outputType = 3;
                break;
            case "SCRIPT_TYPE_PUBKEY":
            case 4:
                message.outputType = 4;
                break;
            case "SCRIPT_TYPE_MULTISIG":
            case 5:
                message.outputType = 5;
                break;
            case "SCRIPT_TYPE_NULLDATA":
            case 6:
                message.outputType = 6;
                break;
            case "SCRIPT_TYPE_NON_STANDARD":
            case 7:
                message.outputType = 7;
                break;
            case "SCRIPT_TYPE_WITNESS_UNKNOWN":
            case 8:
                message.outputType = 8;
                break;
            }
            if (object.address != null)
                message.address = String(object.address);
            if (object.pkScript != null)
                message.pkScript = String(object.pkScript);
            if (object.outputIndex != null)
                if ($util.Long)
                    (message.outputIndex = $util.Long.fromValue(object.outputIndex)).unsigned = false;
                else if (typeof object.outputIndex === "string")
                    message.outputIndex = parseInt(object.outputIndex, 10);
                else if (typeof object.outputIndex === "number")
                    message.outputIndex = object.outputIndex;
                else if (typeof object.outputIndex === "object")
                    message.outputIndex = new $util.LongBits(object.outputIndex.low >>> 0, object.outputIndex.high >>> 0).toNumber();
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            if (object.isOurAddress != null)
                message.isOurAddress = Boolean(object.isOurAddress);
            return message;
        };

        /**
         * Creates a plain object from an OutputDetail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.OutputDetail
         * @static
         * @param {lnrpc.OutputDetail} message OutputDetail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutputDetail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outputType = options.enums === String ? "SCRIPT_TYPE_PUBKEY_HASH" : 0;
                object.address = "";
                object.pkScript = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.outputIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outputIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
                object.isOurAddress = false;
            }
            if (message.outputType != null && message.hasOwnProperty("outputType"))
                object.outputType = options.enums === String ? $root.lnrpc.OutputScriptType[message.outputType] : message.outputType;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                object.pkScript = message.pkScript;
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                if (typeof message.outputIndex === "number")
                    object.outputIndex = options.longs === String ? String(message.outputIndex) : message.outputIndex;
                else
                    object.outputIndex = options.longs === String ? $util.Long.prototype.toString.call(message.outputIndex) : options.longs === Number ? new $util.LongBits(message.outputIndex.low >>> 0, message.outputIndex.high >>> 0).toNumber() : message.outputIndex;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            if (message.isOurAddress != null && message.hasOwnProperty("isOurAddress"))
                object.isOurAddress = message.isOurAddress;
            return object;
        };

        /**
         * Converts this OutputDetail to JSON.
         * @function toJSON
         * @memberof lnrpc.OutputDetail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutputDetail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutputDetail;
    })();

    lnrpc.Transaction = (function() {

        /**
         * Properties of a Transaction.
         * @memberof lnrpc
         * @interface ITransaction
         * @property {string|null} [txHash] Transaction txHash
         * @property {Long|null} [amount] Transaction amount
         * @property {number|null} [numConfirmations] Transaction numConfirmations
         * @property {string|null} [blockHash] Transaction blockHash
         * @property {number|null} [blockHeight] Transaction blockHeight
         * @property {Long|null} [timeStamp] Transaction timeStamp
         * @property {Long|null} [totalFees] Transaction totalFees
         * @property {Array.<string>|null} [destAddresses] Transaction destAddresses
         * @property {Array.<lnrpc.IOutputDetail>|null} [outputDetails] Transaction outputDetails
         * @property {string|null} [rawTxHex] Transaction rawTxHex
         * @property {string|null} [label] Transaction label
         * @property {Array.<lnrpc.IPreviousOutPoint>|null} [previousOutpoints] Transaction previousOutpoints
         */

        /**
         * Constructs a new Transaction.
         * @memberof lnrpc
         * @classdesc Represents a Transaction.
         * @implements ITransaction
         * @constructor
         * @param {lnrpc.ITransaction=} [properties] Properties to set
         */
        function Transaction(properties) {
            this.destAddresses = [];
            this.outputDetails = [];
            this.previousOutpoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Transaction txHash.
         * @member {string} txHash
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.txHash = "";

        /**
         * Transaction amount.
         * @member {Long} amount
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Transaction numConfirmations.
         * @member {number} numConfirmations
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.numConfirmations = 0;

        /**
         * Transaction blockHash.
         * @member {string} blockHash
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.blockHash = "";

        /**
         * Transaction blockHeight.
         * @member {number} blockHeight
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.blockHeight = 0;

        /**
         * Transaction timeStamp.
         * @member {Long} timeStamp
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.timeStamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Transaction totalFees.
         * @member {Long} totalFees
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.totalFees = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Transaction destAddresses.
         * @member {Array.<string>} destAddresses
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.destAddresses = $util.emptyArray;

        /**
         * Transaction outputDetails.
         * @member {Array.<lnrpc.IOutputDetail>} outputDetails
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.outputDetails = $util.emptyArray;

        /**
         * Transaction rawTxHex.
         * @member {string} rawTxHex
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.rawTxHex = "";

        /**
         * Transaction label.
         * @member {string} label
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.label = "";

        /**
         * Transaction previousOutpoints.
         * @member {Array.<lnrpc.IPreviousOutPoint>} previousOutpoints
         * @memberof lnrpc.Transaction
         * @instance
         */
        Transaction.prototype.previousOutpoints = $util.emptyArray;

        /**
         * Creates a new Transaction instance using the specified properties.
         * @function create
         * @memberof lnrpc.Transaction
         * @static
         * @param {lnrpc.ITransaction=} [properties] Properties to set
         * @returns {lnrpc.Transaction} Transaction instance
         */
        Transaction.create = function create(properties) {
            return new Transaction(properties);
        };

        /**
         * Encodes the specified Transaction message. Does not implicitly {@link lnrpc.Transaction.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Transaction
         * @static
         * @param {lnrpc.ITransaction} message Transaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Transaction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txHash != null && Object.hasOwnProperty.call(message, "txHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.txHash);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amount);
            if (message.numConfirmations != null && Object.hasOwnProperty.call(message, "numConfirmations"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.numConfirmations);
            if (message.blockHash != null && Object.hasOwnProperty.call(message, "blockHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.blockHash);
            if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.blockHeight);
            if (message.timeStamp != null && Object.hasOwnProperty.call(message, "timeStamp"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.timeStamp);
            if (message.totalFees != null && Object.hasOwnProperty.call(message, "totalFees"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.totalFees);
            if (message.destAddresses != null && message.destAddresses.length)
                for (let i = 0; i < message.destAddresses.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.destAddresses[i]);
            if (message.rawTxHex != null && Object.hasOwnProperty.call(message, "rawTxHex"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.rawTxHex);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.label);
            if (message.outputDetails != null && message.outputDetails.length)
                for (let i = 0; i < message.outputDetails.length; ++i)
                    $root.lnrpc.OutputDetail.encode(message.outputDetails[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.previousOutpoints != null && message.previousOutpoints.length)
                for (let i = 0; i < message.previousOutpoints.length; ++i)
                    $root.lnrpc.PreviousOutPoint.encode(message.previousOutpoints[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Transaction message, length delimited. Does not implicitly {@link lnrpc.Transaction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Transaction
         * @static
         * @param {lnrpc.ITransaction} message Transaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Transaction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Transaction message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Transaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Transaction} Transaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Transaction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Transaction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txHash = reader.string();
                        break;
                    }
                case 2: {
                        message.amount = reader.int64();
                        break;
                    }
                case 3: {
                        message.numConfirmations = reader.int32();
                        break;
                    }
                case 4: {
                        message.blockHash = reader.string();
                        break;
                    }
                case 5: {
                        message.blockHeight = reader.int32();
                        break;
                    }
                case 6: {
                        message.timeStamp = reader.int64();
                        break;
                    }
                case 7: {
                        message.totalFees = reader.int64();
                        break;
                    }
                case 8: {
                        if (!(message.destAddresses && message.destAddresses.length))
                            message.destAddresses = [];
                        message.destAddresses.push(reader.string());
                        break;
                    }
                case 11: {
                        if (!(message.outputDetails && message.outputDetails.length))
                            message.outputDetails = [];
                        message.outputDetails.push($root.lnrpc.OutputDetail.decode(reader, reader.uint32()));
                        break;
                    }
                case 9: {
                        message.rawTxHex = reader.string();
                        break;
                    }
                case 10: {
                        message.label = reader.string();
                        break;
                    }
                case 12: {
                        if (!(message.previousOutpoints && message.previousOutpoints.length))
                            message.previousOutpoints = [];
                        message.previousOutpoints.push($root.lnrpc.PreviousOutPoint.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Transaction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Transaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Transaction} Transaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Transaction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Transaction message.
         * @function verify
         * @memberof lnrpc.Transaction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Transaction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txHash != null && message.hasOwnProperty("txHash"))
                if (!$util.isString(message.txHash))
                    return "txHash: string expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            if (message.numConfirmations != null && message.hasOwnProperty("numConfirmations"))
                if (!$util.isInteger(message.numConfirmations))
                    return "numConfirmations: integer expected";
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                if (!$util.isString(message.blockHash))
                    return "blockHash: string expected";
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                if (!$util.isInteger(message.blockHeight))
                    return "blockHeight: integer expected";
            if (message.timeStamp != null && message.hasOwnProperty("timeStamp"))
                if (!$util.isInteger(message.timeStamp) && !(message.timeStamp && $util.isInteger(message.timeStamp.low) && $util.isInteger(message.timeStamp.high)))
                    return "timeStamp: integer|Long expected";
            if (message.totalFees != null && message.hasOwnProperty("totalFees"))
                if (!$util.isInteger(message.totalFees) && !(message.totalFees && $util.isInteger(message.totalFees.low) && $util.isInteger(message.totalFees.high)))
                    return "totalFees: integer|Long expected";
            if (message.destAddresses != null && message.hasOwnProperty("destAddresses")) {
                if (!Array.isArray(message.destAddresses))
                    return "destAddresses: array expected";
                for (let i = 0; i < message.destAddresses.length; ++i)
                    if (!$util.isString(message.destAddresses[i]))
                        return "destAddresses: string[] expected";
            }
            if (message.outputDetails != null && message.hasOwnProperty("outputDetails")) {
                if (!Array.isArray(message.outputDetails))
                    return "outputDetails: array expected";
                for (let i = 0; i < message.outputDetails.length; ++i) {
                    let error = $root.lnrpc.OutputDetail.verify(message.outputDetails[i]);
                    if (error)
                        return "outputDetails." + error;
                }
            }
            if (message.rawTxHex != null && message.hasOwnProperty("rawTxHex"))
                if (!$util.isString(message.rawTxHex))
                    return "rawTxHex: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.previousOutpoints != null && message.hasOwnProperty("previousOutpoints")) {
                if (!Array.isArray(message.previousOutpoints))
                    return "previousOutpoints: array expected";
                for (let i = 0; i < message.previousOutpoints.length; ++i) {
                    let error = $root.lnrpc.PreviousOutPoint.verify(message.previousOutpoints[i]);
                    if (error)
                        return "previousOutpoints." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Transaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Transaction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Transaction} Transaction
         */
        Transaction.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Transaction)
                return object;
            let message = new $root.lnrpc.Transaction();
            if (object.txHash != null)
                message.txHash = String(object.txHash);
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            if (object.numConfirmations != null)
                message.numConfirmations = object.numConfirmations | 0;
            if (object.blockHash != null)
                message.blockHash = String(object.blockHash);
            if (object.blockHeight != null)
                message.blockHeight = object.blockHeight | 0;
            if (object.timeStamp != null)
                if ($util.Long)
                    (message.timeStamp = $util.Long.fromValue(object.timeStamp)).unsigned = false;
                else if (typeof object.timeStamp === "string")
                    message.timeStamp = parseInt(object.timeStamp, 10);
                else if (typeof object.timeStamp === "number")
                    message.timeStamp = object.timeStamp;
                else if (typeof object.timeStamp === "object")
                    message.timeStamp = new $util.LongBits(object.timeStamp.low >>> 0, object.timeStamp.high >>> 0).toNumber();
            if (object.totalFees != null)
                if ($util.Long)
                    (message.totalFees = $util.Long.fromValue(object.totalFees)).unsigned = false;
                else if (typeof object.totalFees === "string")
                    message.totalFees = parseInt(object.totalFees, 10);
                else if (typeof object.totalFees === "number")
                    message.totalFees = object.totalFees;
                else if (typeof object.totalFees === "object")
                    message.totalFees = new $util.LongBits(object.totalFees.low >>> 0, object.totalFees.high >>> 0).toNumber();
            if (object.destAddresses) {
                if (!Array.isArray(object.destAddresses))
                    throw TypeError(".lnrpc.Transaction.destAddresses: array expected");
                message.destAddresses = [];
                for (let i = 0; i < object.destAddresses.length; ++i)
                    message.destAddresses[i] = String(object.destAddresses[i]);
            }
            if (object.outputDetails) {
                if (!Array.isArray(object.outputDetails))
                    throw TypeError(".lnrpc.Transaction.outputDetails: array expected");
                message.outputDetails = [];
                for (let i = 0; i < object.outputDetails.length; ++i) {
                    if (typeof object.outputDetails[i] !== "object")
                        throw TypeError(".lnrpc.Transaction.outputDetails: object expected");
                    message.outputDetails[i] = $root.lnrpc.OutputDetail.fromObject(object.outputDetails[i]);
                }
            }
            if (object.rawTxHex != null)
                message.rawTxHex = String(object.rawTxHex);
            if (object.label != null)
                message.label = String(object.label);
            if (object.previousOutpoints) {
                if (!Array.isArray(object.previousOutpoints))
                    throw TypeError(".lnrpc.Transaction.previousOutpoints: array expected");
                message.previousOutpoints = [];
                for (let i = 0; i < object.previousOutpoints.length; ++i) {
                    if (typeof object.previousOutpoints[i] !== "object")
                        throw TypeError(".lnrpc.Transaction.previousOutpoints: object expected");
                    message.previousOutpoints[i] = $root.lnrpc.PreviousOutPoint.fromObject(object.previousOutpoints[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Transaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Transaction
         * @static
         * @param {lnrpc.Transaction} message Transaction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Transaction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.destAddresses = [];
                object.outputDetails = [];
                object.previousOutpoints = [];
            }
            if (options.defaults) {
                object.txHash = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
                object.numConfirmations = 0;
                object.blockHash = "";
                object.blockHeight = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timeStamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeStamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalFees = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalFees = options.longs === String ? "0" : 0;
                object.rawTxHex = "";
                object.label = "";
            }
            if (message.txHash != null && message.hasOwnProperty("txHash"))
                object.txHash = message.txHash;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            if (message.numConfirmations != null && message.hasOwnProperty("numConfirmations"))
                object.numConfirmations = message.numConfirmations;
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                object.blockHash = message.blockHash;
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                object.blockHeight = message.blockHeight;
            if (message.timeStamp != null && message.hasOwnProperty("timeStamp"))
                if (typeof message.timeStamp === "number")
                    object.timeStamp = options.longs === String ? String(message.timeStamp) : message.timeStamp;
                else
                    object.timeStamp = options.longs === String ? $util.Long.prototype.toString.call(message.timeStamp) : options.longs === Number ? new $util.LongBits(message.timeStamp.low >>> 0, message.timeStamp.high >>> 0).toNumber() : message.timeStamp;
            if (message.totalFees != null && message.hasOwnProperty("totalFees"))
                if (typeof message.totalFees === "number")
                    object.totalFees = options.longs === String ? String(message.totalFees) : message.totalFees;
                else
                    object.totalFees = options.longs === String ? $util.Long.prototype.toString.call(message.totalFees) : options.longs === Number ? new $util.LongBits(message.totalFees.low >>> 0, message.totalFees.high >>> 0).toNumber() : message.totalFees;
            if (message.destAddresses && message.destAddresses.length) {
                object.destAddresses = [];
                for (let j = 0; j < message.destAddresses.length; ++j)
                    object.destAddresses[j] = message.destAddresses[j];
            }
            if (message.rawTxHex != null && message.hasOwnProperty("rawTxHex"))
                object.rawTxHex = message.rawTxHex;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.outputDetails && message.outputDetails.length) {
                object.outputDetails = [];
                for (let j = 0; j < message.outputDetails.length; ++j)
                    object.outputDetails[j] = $root.lnrpc.OutputDetail.toObject(message.outputDetails[j], options);
            }
            if (message.previousOutpoints && message.previousOutpoints.length) {
                object.previousOutpoints = [];
                for (let j = 0; j < message.previousOutpoints.length; ++j)
                    object.previousOutpoints[j] = $root.lnrpc.PreviousOutPoint.toObject(message.previousOutpoints[j], options);
            }
            return object;
        };

        /**
         * Converts this Transaction to JSON.
         * @function toJSON
         * @memberof lnrpc.Transaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Transaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Transaction;
    })();

    lnrpc.GetTransactionsRequest = (function() {

        /**
         * Properties of a GetTransactionsRequest.
         * @memberof lnrpc
         * @interface IGetTransactionsRequest
         * @property {number|null} [startHeight] GetTransactionsRequest startHeight
         * @property {number|null} [endHeight] GetTransactionsRequest endHeight
         * @property {string|null} [account] GetTransactionsRequest account
         */

        /**
         * Constructs a new GetTransactionsRequest.
         * @memberof lnrpc
         * @classdesc Represents a GetTransactionsRequest.
         * @implements IGetTransactionsRequest
         * @constructor
         * @param {lnrpc.IGetTransactionsRequest=} [properties] Properties to set
         */
        function GetTransactionsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTransactionsRequest startHeight.
         * @member {number} startHeight
         * @memberof lnrpc.GetTransactionsRequest
         * @instance
         */
        GetTransactionsRequest.prototype.startHeight = 0;

        /**
         * GetTransactionsRequest endHeight.
         * @member {number} endHeight
         * @memberof lnrpc.GetTransactionsRequest
         * @instance
         */
        GetTransactionsRequest.prototype.endHeight = 0;

        /**
         * GetTransactionsRequest account.
         * @member {string} account
         * @memberof lnrpc.GetTransactionsRequest
         * @instance
         */
        GetTransactionsRequest.prototype.account = "";

        /**
         * Creates a new GetTransactionsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {lnrpc.IGetTransactionsRequest=} [properties] Properties to set
         * @returns {lnrpc.GetTransactionsRequest} GetTransactionsRequest instance
         */
        GetTransactionsRequest.create = function create(properties) {
            return new GetTransactionsRequest(properties);
        };

        /**
         * Encodes the specified GetTransactionsRequest message. Does not implicitly {@link lnrpc.GetTransactionsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {lnrpc.IGetTransactionsRequest} message GetTransactionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTransactionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startHeight != null && Object.hasOwnProperty.call(message, "startHeight"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.startHeight);
            if (message.endHeight != null && Object.hasOwnProperty.call(message, "endHeight"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.endHeight);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.account);
            return writer;
        };

        /**
         * Encodes the specified GetTransactionsRequest message, length delimited. Does not implicitly {@link lnrpc.GetTransactionsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {lnrpc.IGetTransactionsRequest} message GetTransactionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTransactionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTransactionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetTransactionsRequest} GetTransactionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTransactionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetTransactionsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.startHeight = reader.int32();
                        break;
                    }
                case 2: {
                        message.endHeight = reader.int32();
                        break;
                    }
                case 3: {
                        message.account = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTransactionsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetTransactionsRequest} GetTransactionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTransactionsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTransactionsRequest message.
         * @function verify
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTransactionsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startHeight != null && message.hasOwnProperty("startHeight"))
                if (!$util.isInteger(message.startHeight))
                    return "startHeight: integer expected";
            if (message.endHeight != null && message.hasOwnProperty("endHeight"))
                if (!$util.isInteger(message.endHeight))
                    return "endHeight: integer expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            return null;
        };

        /**
         * Creates a GetTransactionsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetTransactionsRequest} GetTransactionsRequest
         */
        GetTransactionsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetTransactionsRequest)
                return object;
            let message = new $root.lnrpc.GetTransactionsRequest();
            if (object.startHeight != null)
                message.startHeight = object.startHeight | 0;
            if (object.endHeight != null)
                message.endHeight = object.endHeight | 0;
            if (object.account != null)
                message.account = String(object.account);
            return message;
        };

        /**
         * Creates a plain object from a GetTransactionsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetTransactionsRequest
         * @static
         * @param {lnrpc.GetTransactionsRequest} message GetTransactionsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTransactionsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.startHeight = 0;
                object.endHeight = 0;
                object.account = "";
            }
            if (message.startHeight != null && message.hasOwnProperty("startHeight"))
                object.startHeight = message.startHeight;
            if (message.endHeight != null && message.hasOwnProperty("endHeight"))
                object.endHeight = message.endHeight;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            return object;
        };

        /**
         * Converts this GetTransactionsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.GetTransactionsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTransactionsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTransactionsRequest;
    })();

    lnrpc.TransactionDetails = (function() {

        /**
         * Properties of a TransactionDetails.
         * @memberof lnrpc
         * @interface ITransactionDetails
         * @property {Array.<lnrpc.ITransaction>|null} [transactions] TransactionDetails transactions
         */

        /**
         * Constructs a new TransactionDetails.
         * @memberof lnrpc
         * @classdesc Represents a TransactionDetails.
         * @implements ITransactionDetails
         * @constructor
         * @param {lnrpc.ITransactionDetails=} [properties] Properties to set
         */
        function TransactionDetails(properties) {
            this.transactions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TransactionDetails transactions.
         * @member {Array.<lnrpc.ITransaction>} transactions
         * @memberof lnrpc.TransactionDetails
         * @instance
         */
        TransactionDetails.prototype.transactions = $util.emptyArray;

        /**
         * Creates a new TransactionDetails instance using the specified properties.
         * @function create
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {lnrpc.ITransactionDetails=} [properties] Properties to set
         * @returns {lnrpc.TransactionDetails} TransactionDetails instance
         */
        TransactionDetails.create = function create(properties) {
            return new TransactionDetails(properties);
        };

        /**
         * Encodes the specified TransactionDetails message. Does not implicitly {@link lnrpc.TransactionDetails.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {lnrpc.ITransactionDetails} message TransactionDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TransactionDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.transactions != null && message.transactions.length)
                for (let i = 0; i < message.transactions.length; ++i)
                    $root.lnrpc.Transaction.encode(message.transactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TransactionDetails message, length delimited. Does not implicitly {@link lnrpc.TransactionDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {lnrpc.ITransactionDetails} message TransactionDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TransactionDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TransactionDetails message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.TransactionDetails} TransactionDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TransactionDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.TransactionDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.transactions && message.transactions.length))
                            message.transactions = [];
                        message.transactions.push($root.lnrpc.Transaction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TransactionDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.TransactionDetails} TransactionDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TransactionDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TransactionDetails message.
         * @function verify
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TransactionDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.transactions != null && message.hasOwnProperty("transactions")) {
                if (!Array.isArray(message.transactions))
                    return "transactions: array expected";
                for (let i = 0; i < message.transactions.length; ++i) {
                    let error = $root.lnrpc.Transaction.verify(message.transactions[i]);
                    if (error)
                        return "transactions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TransactionDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.TransactionDetails} TransactionDetails
         */
        TransactionDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.TransactionDetails)
                return object;
            let message = new $root.lnrpc.TransactionDetails();
            if (object.transactions) {
                if (!Array.isArray(object.transactions))
                    throw TypeError(".lnrpc.TransactionDetails.transactions: array expected");
                message.transactions = [];
                for (let i = 0; i < object.transactions.length; ++i) {
                    if (typeof object.transactions[i] !== "object")
                        throw TypeError(".lnrpc.TransactionDetails.transactions: object expected");
                    message.transactions[i] = $root.lnrpc.Transaction.fromObject(object.transactions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TransactionDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.TransactionDetails
         * @static
         * @param {lnrpc.TransactionDetails} message TransactionDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TransactionDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.transactions = [];
            if (message.transactions && message.transactions.length) {
                object.transactions = [];
                for (let j = 0; j < message.transactions.length; ++j)
                    object.transactions[j] = $root.lnrpc.Transaction.toObject(message.transactions[j], options);
            }
            return object;
        };

        /**
         * Converts this TransactionDetails to JSON.
         * @function toJSON
         * @memberof lnrpc.TransactionDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TransactionDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TransactionDetails;
    })();

    lnrpc.FeeLimit = (function() {

        /**
         * Properties of a FeeLimit.
         * @memberof lnrpc
         * @interface IFeeLimit
         * @property {Long|null} [fixed] FeeLimit fixed
         * @property {Long|null} [fixedMsat] FeeLimit fixedMsat
         * @property {Long|null} [percent] FeeLimit percent
         */

        /**
         * Constructs a new FeeLimit.
         * @memberof lnrpc
         * @classdesc Represents a FeeLimit.
         * @implements IFeeLimit
         * @constructor
         * @param {lnrpc.IFeeLimit=} [properties] Properties to set
         */
        function FeeLimit(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeeLimit fixed.
         * @member {Long} fixed
         * @memberof lnrpc.FeeLimit
         * @instance
         */
        FeeLimit.prototype.fixed = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FeeLimit fixedMsat.
         * @member {Long} fixedMsat
         * @memberof lnrpc.FeeLimit
         * @instance
         */
        FeeLimit.prototype.fixedMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FeeLimit percent.
         * @member {Long} percent
         * @memberof lnrpc.FeeLimit
         * @instance
         */
        FeeLimit.prototype.percent = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * FeeLimit limit.
         * @member {"fixed"|"fixedMsat"|"percent"|undefined} limit
         * @memberof lnrpc.FeeLimit
         * @instance
         */
        Object.defineProperty(FeeLimit.prototype, "limit", {
            get: $util.oneOfGetter($oneOfFields = ["fixed", "fixedMsat", "percent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FeeLimit instance using the specified properties.
         * @function create
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {lnrpc.IFeeLimit=} [properties] Properties to set
         * @returns {lnrpc.FeeLimit} FeeLimit instance
         */
        FeeLimit.create = function create(properties) {
            return new FeeLimit(properties);
        };

        /**
         * Encodes the specified FeeLimit message. Does not implicitly {@link lnrpc.FeeLimit.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {lnrpc.IFeeLimit} message FeeLimit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeeLimit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fixed != null && Object.hasOwnProperty.call(message, "fixed"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fixed);
            if (message.percent != null && Object.hasOwnProperty.call(message, "percent"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.percent);
            if (message.fixedMsat != null && Object.hasOwnProperty.call(message, "fixedMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.fixedMsat);
            return writer;
        };

        /**
         * Encodes the specified FeeLimit message, length delimited. Does not implicitly {@link lnrpc.FeeLimit.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {lnrpc.IFeeLimit} message FeeLimit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeeLimit.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FeeLimit message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FeeLimit} FeeLimit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeeLimit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FeeLimit();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fixed = reader.int64();
                        break;
                    }
                case 3: {
                        message.fixedMsat = reader.int64();
                        break;
                    }
                case 2: {
                        message.percent = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FeeLimit message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FeeLimit} FeeLimit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeeLimit.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FeeLimit message.
         * @function verify
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FeeLimit.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.fixed != null && message.hasOwnProperty("fixed")) {
                properties.limit = 1;
                if (!$util.isInteger(message.fixed) && !(message.fixed && $util.isInteger(message.fixed.low) && $util.isInteger(message.fixed.high)))
                    return "fixed: integer|Long expected";
            }
            if (message.fixedMsat != null && message.hasOwnProperty("fixedMsat")) {
                if (properties.limit === 1)
                    return "limit: multiple values";
                properties.limit = 1;
                if (!$util.isInteger(message.fixedMsat) && !(message.fixedMsat && $util.isInteger(message.fixedMsat.low) && $util.isInteger(message.fixedMsat.high)))
                    return "fixedMsat: integer|Long expected";
            }
            if (message.percent != null && message.hasOwnProperty("percent")) {
                if (properties.limit === 1)
                    return "limit: multiple values";
                properties.limit = 1;
                if (!$util.isInteger(message.percent) && !(message.percent && $util.isInteger(message.percent.low) && $util.isInteger(message.percent.high)))
                    return "percent: integer|Long expected";
            }
            return null;
        };

        /**
         * Creates a FeeLimit message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FeeLimit} FeeLimit
         */
        FeeLimit.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FeeLimit)
                return object;
            let message = new $root.lnrpc.FeeLimit();
            if (object.fixed != null)
                if ($util.Long)
                    (message.fixed = $util.Long.fromValue(object.fixed)).unsigned = false;
                else if (typeof object.fixed === "string")
                    message.fixed = parseInt(object.fixed, 10);
                else if (typeof object.fixed === "number")
                    message.fixed = object.fixed;
                else if (typeof object.fixed === "object")
                    message.fixed = new $util.LongBits(object.fixed.low >>> 0, object.fixed.high >>> 0).toNumber();
            if (object.fixedMsat != null)
                if ($util.Long)
                    (message.fixedMsat = $util.Long.fromValue(object.fixedMsat)).unsigned = false;
                else if (typeof object.fixedMsat === "string")
                    message.fixedMsat = parseInt(object.fixedMsat, 10);
                else if (typeof object.fixedMsat === "number")
                    message.fixedMsat = object.fixedMsat;
                else if (typeof object.fixedMsat === "object")
                    message.fixedMsat = new $util.LongBits(object.fixedMsat.low >>> 0, object.fixedMsat.high >>> 0).toNumber();
            if (object.percent != null)
                if ($util.Long)
                    (message.percent = $util.Long.fromValue(object.percent)).unsigned = false;
                else if (typeof object.percent === "string")
                    message.percent = parseInt(object.percent, 10);
                else if (typeof object.percent === "number")
                    message.percent = object.percent;
                else if (typeof object.percent === "object")
                    message.percent = new $util.LongBits(object.percent.low >>> 0, object.percent.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a FeeLimit message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FeeLimit
         * @static
         * @param {lnrpc.FeeLimit} message FeeLimit
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FeeLimit.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.fixed != null && message.hasOwnProperty("fixed")) {
                if (typeof message.fixed === "number")
                    object.fixed = options.longs === String ? String(message.fixed) : message.fixed;
                else
                    object.fixed = options.longs === String ? $util.Long.prototype.toString.call(message.fixed) : options.longs === Number ? new $util.LongBits(message.fixed.low >>> 0, message.fixed.high >>> 0).toNumber() : message.fixed;
                if (options.oneofs)
                    object.limit = "fixed";
            }
            if (message.percent != null && message.hasOwnProperty("percent")) {
                if (typeof message.percent === "number")
                    object.percent = options.longs === String ? String(message.percent) : message.percent;
                else
                    object.percent = options.longs === String ? $util.Long.prototype.toString.call(message.percent) : options.longs === Number ? new $util.LongBits(message.percent.low >>> 0, message.percent.high >>> 0).toNumber() : message.percent;
                if (options.oneofs)
                    object.limit = "percent";
            }
            if (message.fixedMsat != null && message.hasOwnProperty("fixedMsat")) {
                if (typeof message.fixedMsat === "number")
                    object.fixedMsat = options.longs === String ? String(message.fixedMsat) : message.fixedMsat;
                else
                    object.fixedMsat = options.longs === String ? $util.Long.prototype.toString.call(message.fixedMsat) : options.longs === Number ? new $util.LongBits(message.fixedMsat.low >>> 0, message.fixedMsat.high >>> 0).toNumber() : message.fixedMsat;
                if (options.oneofs)
                    object.limit = "fixedMsat";
            }
            return object;
        };

        /**
         * Converts this FeeLimit to JSON.
         * @function toJSON
         * @memberof lnrpc.FeeLimit
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FeeLimit.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FeeLimit;
    })();

    lnrpc.SendRequest = (function() {

        /**
         * Properties of a SendRequest.
         * @memberof lnrpc
         * @interface ISendRequest
         * @property {Uint8Array|null} [dest] SendRequest dest
         * @property {string|null} [destString] SendRequest destString
         * @property {Long|null} [amt] SendRequest amt
         * @property {Long|null} [amtMsat] SendRequest amtMsat
         * @property {Uint8Array|null} [paymentHash] SendRequest paymentHash
         * @property {string|null} [paymentHashString] SendRequest paymentHashString
         * @property {string|null} [paymentRequest] SendRequest paymentRequest
         * @property {number|null} [finalCltvDelta] SendRequest finalCltvDelta
         * @property {lnrpc.IFeeLimit|null} [feeLimit] SendRequest feeLimit
         * @property {Long|null} [outgoingChanId] SendRequest outgoingChanId
         * @property {Uint8Array|null} [lastHopPubkey] SendRequest lastHopPubkey
         * @property {number|null} [cltvLimit] SendRequest cltvLimit
         * @property {Object.<string,Uint8Array>|null} [destCustomRecords] SendRequest destCustomRecords
         * @property {boolean|null} [allowSelfPayment] SendRequest allowSelfPayment
         * @property {Array.<lnrpc.FeatureBit>|null} [destFeatures] SendRequest destFeatures
         * @property {Uint8Array|null} [paymentAddr] SendRequest paymentAddr
         */

        /**
         * Constructs a new SendRequest.
         * @memberof lnrpc
         * @classdesc Represents a SendRequest.
         * @implements ISendRequest
         * @constructor
         * @param {lnrpc.ISendRequest=} [properties] Properties to set
         */
        function SendRequest(properties) {
            this.destCustomRecords = {};
            this.destFeatures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendRequest dest.
         * @member {Uint8Array} dest
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.dest = $util.newBuffer([]);

        /**
         * SendRequest destString.
         * @member {string} destString
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.destString = "";

        /**
         * SendRequest amt.
         * @member {Long} amt
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.amt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendRequest amtMsat.
         * @member {Long} amtMsat
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.amtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * SendRequest paymentHashString.
         * @member {string} paymentHashString
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.paymentHashString = "";

        /**
         * SendRequest paymentRequest.
         * @member {string} paymentRequest
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.paymentRequest = "";

        /**
         * SendRequest finalCltvDelta.
         * @member {number} finalCltvDelta
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.finalCltvDelta = 0;

        /**
         * SendRequest feeLimit.
         * @member {lnrpc.IFeeLimit|null|undefined} feeLimit
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.feeLimit = null;

        /**
         * SendRequest outgoingChanId.
         * @member {Long} outgoingChanId
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.outgoingChanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SendRequest lastHopPubkey.
         * @member {Uint8Array} lastHopPubkey
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.lastHopPubkey = $util.newBuffer([]);

        /**
         * SendRequest cltvLimit.
         * @member {number} cltvLimit
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.cltvLimit = 0;

        /**
         * SendRequest destCustomRecords.
         * @member {Object.<string,Uint8Array>} destCustomRecords
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.destCustomRecords = $util.emptyObject;

        /**
         * SendRequest allowSelfPayment.
         * @member {boolean} allowSelfPayment
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.allowSelfPayment = false;

        /**
         * SendRequest destFeatures.
         * @member {Array.<lnrpc.FeatureBit>} destFeatures
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.destFeatures = $util.emptyArray;

        /**
         * SendRequest paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof lnrpc.SendRequest
         * @instance
         */
        SendRequest.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * Creates a new SendRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendRequest
         * @static
         * @param {lnrpc.ISendRequest=} [properties] Properties to set
         * @returns {lnrpc.SendRequest} SendRequest instance
         */
        SendRequest.create = function create(properties) {
            return new SendRequest(properties);
        };

        /**
         * Encodes the specified SendRequest message. Does not implicitly {@link lnrpc.SendRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendRequest
         * @static
         * @param {lnrpc.ISendRequest} message SendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dest != null && Object.hasOwnProperty.call(message, "dest"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dest);
            if (message.destString != null && Object.hasOwnProperty.call(message, "destString"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.destString);
            if (message.amt != null && Object.hasOwnProperty.call(message, "amt"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amt);
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.paymentHash);
            if (message.paymentHashString != null && Object.hasOwnProperty.call(message, "paymentHashString"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.paymentHashString);
            if (message.paymentRequest != null && Object.hasOwnProperty.call(message, "paymentRequest"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.paymentRequest);
            if (message.finalCltvDelta != null && Object.hasOwnProperty.call(message, "finalCltvDelta"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.finalCltvDelta);
            if (message.feeLimit != null && Object.hasOwnProperty.call(message, "feeLimit"))
                $root.lnrpc.FeeLimit.encode(message.feeLimit, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.outgoingChanId != null && Object.hasOwnProperty.call(message, "outgoingChanId"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.outgoingChanId);
            if (message.cltvLimit != null && Object.hasOwnProperty.call(message, "cltvLimit"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.cltvLimit);
            if (message.destCustomRecords != null && Object.hasOwnProperty.call(message, "destCustomRecords"))
                for (let keys = Object.keys(message.destCustomRecords), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 0 =*/8).uint64(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.destCustomRecords[keys[i]]).ldelim();
            if (message.amtMsat != null && Object.hasOwnProperty.call(message, "amtMsat"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.amtMsat);
            if (message.lastHopPubkey != null && Object.hasOwnProperty.call(message, "lastHopPubkey"))
                writer.uint32(/* id 13, wireType 2 =*/106).bytes(message.lastHopPubkey);
            if (message.allowSelfPayment != null && Object.hasOwnProperty.call(message, "allowSelfPayment"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.allowSelfPayment);
            if (message.destFeatures != null && message.destFeatures.length) {
                writer.uint32(/* id 15, wireType 2 =*/122).fork();
                for (let i = 0; i < message.destFeatures.length; ++i)
                    writer.int32(message.destFeatures[i]);
                writer.ldelim();
            }
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 16, wireType 2 =*/130).bytes(message.paymentAddr);
            return writer;
        };

        /**
         * Encodes the specified SendRequest message, length delimited. Does not implicitly {@link lnrpc.SendRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendRequest
         * @static
         * @param {lnrpc.ISendRequest} message SendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendRequest} SendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dest = reader.bytes();
                        break;
                    }
                case 2: {
                        message.destString = reader.string();
                        break;
                    }
                case 3: {
                        message.amt = reader.int64();
                        break;
                    }
                case 12: {
                        message.amtMsat = reader.int64();
                        break;
                    }
                case 4: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 5: {
                        message.paymentHashString = reader.string();
                        break;
                    }
                case 6: {
                        message.paymentRequest = reader.string();
                        break;
                    }
                case 7: {
                        message.finalCltvDelta = reader.int32();
                        break;
                    }
                case 8: {
                        message.feeLimit = $root.lnrpc.FeeLimit.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.outgoingChanId = reader.uint64();
                        break;
                    }
                case 13: {
                        message.lastHopPubkey = reader.bytes();
                        break;
                    }
                case 10: {
                        message.cltvLimit = reader.uint32();
                        break;
                    }
                case 11: {
                        if (message.destCustomRecords === $util.emptyObject)
                            message.destCustomRecords = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint64();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.destCustomRecords[typeof key === "object" ? $util.longToHash(key) : key] = value;
                        break;
                    }
                case 14: {
                        message.allowSelfPayment = reader.bool();
                        break;
                    }
                case 15: {
                        if (!(message.destFeatures && message.destFeatures.length))
                            message.destFeatures = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.destFeatures.push(reader.int32());
                        } else
                            message.destFeatures.push(reader.int32());
                        break;
                    }
                case 16: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendRequest} SendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendRequest message.
         * @function verify
         * @memberof lnrpc.SendRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dest != null && message.hasOwnProperty("dest"))
                if (!(message.dest && typeof message.dest.length === "number" || $util.isString(message.dest)))
                    return "dest: buffer expected";
            if (message.destString != null && message.hasOwnProperty("destString"))
                if (!$util.isString(message.destString))
                    return "destString: string expected";
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (!$util.isInteger(message.amt) && !(message.amt && $util.isInteger(message.amt.low) && $util.isInteger(message.amt.high)))
                    return "amt: integer|Long expected";
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (!$util.isInteger(message.amtMsat) && !(message.amtMsat && $util.isInteger(message.amtMsat.low) && $util.isInteger(message.amtMsat.high)))
                    return "amtMsat: integer|Long expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.paymentHashString != null && message.hasOwnProperty("paymentHashString"))
                if (!$util.isString(message.paymentHashString))
                    return "paymentHashString: string expected";
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                if (!$util.isString(message.paymentRequest))
                    return "paymentRequest: string expected";
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                if (!$util.isInteger(message.finalCltvDelta))
                    return "finalCltvDelta: integer expected";
            if (message.feeLimit != null && message.hasOwnProperty("feeLimit")) {
                let error = $root.lnrpc.FeeLimit.verify(message.feeLimit);
                if (error)
                    return "feeLimit." + error;
            }
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (!$util.isInteger(message.outgoingChanId) && !(message.outgoingChanId && $util.isInteger(message.outgoingChanId.low) && $util.isInteger(message.outgoingChanId.high)))
                    return "outgoingChanId: integer|Long expected";
            if (message.lastHopPubkey != null && message.hasOwnProperty("lastHopPubkey"))
                if (!(message.lastHopPubkey && typeof message.lastHopPubkey.length === "number" || $util.isString(message.lastHopPubkey)))
                    return "lastHopPubkey: buffer expected";
            if (message.cltvLimit != null && message.hasOwnProperty("cltvLimit"))
                if (!$util.isInteger(message.cltvLimit))
                    return "cltvLimit: integer expected";
            if (message.destCustomRecords != null && message.hasOwnProperty("destCustomRecords")) {
                if (!$util.isObject(message.destCustomRecords))
                    return "destCustomRecords: object expected";
                let key = Object.keys(message.destCustomRecords);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key64Re.test(key[i]))
                        return "destCustomRecords: integer|Long key{k:uint64} expected";
                    if (!(message.destCustomRecords[key[i]] && typeof message.destCustomRecords[key[i]].length === "number" || $util.isString(message.destCustomRecords[key[i]])))
                        return "destCustomRecords: buffer{k:uint64} expected";
                }
            }
            if (message.allowSelfPayment != null && message.hasOwnProperty("allowSelfPayment"))
                if (typeof message.allowSelfPayment !== "boolean")
                    return "allowSelfPayment: boolean expected";
            if (message.destFeatures != null && message.hasOwnProperty("destFeatures")) {
                if (!Array.isArray(message.destFeatures))
                    return "destFeatures: array expected";
                for (let i = 0; i < message.destFeatures.length; ++i)
                    switch (message.destFeatures[i]) {
                    default:
                        return "destFeatures: enum value[] expected";
                    case 0:
                    case 1:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 30:
                    case 31:
                        break;
                    }
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            return null;
        };

        /**
         * Creates a SendRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendRequest} SendRequest
         */
        SendRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendRequest)
                return object;
            let message = new $root.lnrpc.SendRequest();
            if (object.dest != null)
                if (typeof object.dest === "string")
                    $util.base64.decode(object.dest, message.dest = $util.newBuffer($util.base64.length(object.dest)), 0);
                else if (object.dest.length)
                    message.dest = object.dest;
            if (object.destString != null)
                message.destString = String(object.destString);
            if (object.amt != null)
                if ($util.Long)
                    (message.amt = $util.Long.fromValue(object.amt)).unsigned = false;
                else if (typeof object.amt === "string")
                    message.amt = parseInt(object.amt, 10);
                else if (typeof object.amt === "number")
                    message.amt = object.amt;
                else if (typeof object.amt === "object")
                    message.amt = new $util.LongBits(object.amt.low >>> 0, object.amt.high >>> 0).toNumber();
            if (object.amtMsat != null)
                if ($util.Long)
                    (message.amtMsat = $util.Long.fromValue(object.amtMsat)).unsigned = false;
                else if (typeof object.amtMsat === "string")
                    message.amtMsat = parseInt(object.amtMsat, 10);
                else if (typeof object.amtMsat === "number")
                    message.amtMsat = object.amtMsat;
                else if (typeof object.amtMsat === "object")
                    message.amtMsat = new $util.LongBits(object.amtMsat.low >>> 0, object.amtMsat.high >>> 0).toNumber();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.paymentHashString != null)
                message.paymentHashString = String(object.paymentHashString);
            if (object.paymentRequest != null)
                message.paymentRequest = String(object.paymentRequest);
            if (object.finalCltvDelta != null)
                message.finalCltvDelta = object.finalCltvDelta | 0;
            if (object.feeLimit != null) {
                if (typeof object.feeLimit !== "object")
                    throw TypeError(".lnrpc.SendRequest.feeLimit: object expected");
                message.feeLimit = $root.lnrpc.FeeLimit.fromObject(object.feeLimit);
            }
            if (object.outgoingChanId != null)
                if ($util.Long)
                    (message.outgoingChanId = $util.Long.fromValue(object.outgoingChanId)).unsigned = true;
                else if (typeof object.outgoingChanId === "string")
                    message.outgoingChanId = parseInt(object.outgoingChanId, 10);
                else if (typeof object.outgoingChanId === "number")
                    message.outgoingChanId = object.outgoingChanId;
                else if (typeof object.outgoingChanId === "object")
                    message.outgoingChanId = new $util.LongBits(object.outgoingChanId.low >>> 0, object.outgoingChanId.high >>> 0).toNumber(true);
            if (object.lastHopPubkey != null)
                if (typeof object.lastHopPubkey === "string")
                    $util.base64.decode(object.lastHopPubkey, message.lastHopPubkey = $util.newBuffer($util.base64.length(object.lastHopPubkey)), 0);
                else if (object.lastHopPubkey.length)
                    message.lastHopPubkey = object.lastHopPubkey;
            if (object.cltvLimit != null)
                message.cltvLimit = object.cltvLimit >>> 0;
            if (object.destCustomRecords) {
                if (typeof object.destCustomRecords !== "object")
                    throw TypeError(".lnrpc.SendRequest.destCustomRecords: object expected");
                message.destCustomRecords = {};
                for (let keys = Object.keys(object.destCustomRecords), i = 0; i < keys.length; ++i)
                    if (typeof object.destCustomRecords[keys[i]] === "string")
                        $util.base64.decode(object.destCustomRecords[keys[i]], message.destCustomRecords[keys[i]] = $util.newBuffer($util.base64.length(object.destCustomRecords[keys[i]])), 0);
                    else if (object.destCustomRecords[keys[i]].length)
                        message.destCustomRecords[keys[i]] = object.destCustomRecords[keys[i]];
            }
            if (object.allowSelfPayment != null)
                message.allowSelfPayment = Boolean(object.allowSelfPayment);
            if (object.destFeatures) {
                if (!Array.isArray(object.destFeatures))
                    throw TypeError(".lnrpc.SendRequest.destFeatures: array expected");
                message.destFeatures = [];
                for (let i = 0; i < object.destFeatures.length; ++i)
                    switch (object.destFeatures[i]) {
                    default:
                    case "DATALOSS_PROTECT_REQ":
                    case 0:
                        message.destFeatures[i] = 0;
                        break;
                    case "DATALOSS_PROTECT_OPT":
                    case 1:
                        message.destFeatures[i] = 1;
                        break;
                    case "INITIAL_ROUING_SYNC":
                    case 3:
                        message.destFeatures[i] = 3;
                        break;
                    case "UPFRONT_SHUTDOWN_SCRIPT_REQ":
                    case 4:
                        message.destFeatures[i] = 4;
                        break;
                    case "UPFRONT_SHUTDOWN_SCRIPT_OPT":
                    case 5:
                        message.destFeatures[i] = 5;
                        break;
                    case "GOSSIP_QUERIES_REQ":
                    case 6:
                        message.destFeatures[i] = 6;
                        break;
                    case "GOSSIP_QUERIES_OPT":
                    case 7:
                        message.destFeatures[i] = 7;
                        break;
                    case "TLV_ONION_REQ":
                    case 8:
                        message.destFeatures[i] = 8;
                        break;
                    case "TLV_ONION_OPT":
                    case 9:
                        message.destFeatures[i] = 9;
                        break;
                    case "EXT_GOSSIP_QUERIES_REQ":
                    case 10:
                        message.destFeatures[i] = 10;
                        break;
                    case "EXT_GOSSIP_QUERIES_OPT":
                    case 11:
                        message.destFeatures[i] = 11;
                        break;
                    case "STATIC_REMOTE_KEY_REQ":
                    case 12:
                        message.destFeatures[i] = 12;
                        break;
                    case "STATIC_REMOTE_KEY_OPT":
                    case 13:
                        message.destFeatures[i] = 13;
                        break;
                    case "PAYMENT_ADDR_REQ":
                    case 14:
                        message.destFeatures[i] = 14;
                        break;
                    case "PAYMENT_ADDR_OPT":
                    case 15:
                        message.destFeatures[i] = 15;
                        break;
                    case "MPP_REQ":
                    case 16:
                        message.destFeatures[i] = 16;
                        break;
                    case "MPP_OPT":
                    case 17:
                        message.destFeatures[i] = 17;
                        break;
                    case "WUMBO_CHANNELS_REQ":
                    case 18:
                        message.destFeatures[i] = 18;
                        break;
                    case "WUMBO_CHANNELS_OPT":
                    case 19:
                        message.destFeatures[i] = 19;
                        break;
                    case "ANCHORS_REQ":
                    case 20:
                        message.destFeatures[i] = 20;
                        break;
                    case "ANCHORS_OPT":
                    case 21:
                        message.destFeatures[i] = 21;
                        break;
                    case "ANCHORS_ZERO_FEE_HTLC_REQ":
                    case 22:
                        message.destFeatures[i] = 22;
                        break;
                    case "ANCHORS_ZERO_FEE_HTLC_OPT":
                    case 23:
                        message.destFeatures[i] = 23;
                        break;
                    case "AMP_REQ":
                    case 30:
                        message.destFeatures[i] = 30;
                        break;
                    case "AMP_OPT":
                    case 31:
                        message.destFeatures[i] = 31;
                        break;
                    }
            }
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            return message;
        };

        /**
         * Creates a plain object from a SendRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendRequest
         * @static
         * @param {lnrpc.SendRequest} message SendRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.destFeatures = [];
            if (options.objects || options.defaults)
                object.destCustomRecords = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dest = "";
                else {
                    object.dest = [];
                    if (options.bytes !== Array)
                        object.dest = $util.newBuffer(object.dest);
                }
                object.destString = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amt = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                object.paymentHashString = "";
                object.paymentRequest = "";
                object.finalCltvDelta = 0;
                object.feeLimit = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingChanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingChanId = options.longs === String ? "0" : 0;
                object.cltvLimit = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtMsat = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.lastHopPubkey = "";
                else {
                    object.lastHopPubkey = [];
                    if (options.bytes !== Array)
                        object.lastHopPubkey = $util.newBuffer(object.lastHopPubkey);
                }
                object.allowSelfPayment = false;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
            }
            if (message.dest != null && message.hasOwnProperty("dest"))
                object.dest = options.bytes === String ? $util.base64.encode(message.dest, 0, message.dest.length) : options.bytes === Array ? Array.prototype.slice.call(message.dest) : message.dest;
            if (message.destString != null && message.hasOwnProperty("destString"))
                object.destString = message.destString;
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (typeof message.amt === "number")
                    object.amt = options.longs === String ? String(message.amt) : message.amt;
                else
                    object.amt = options.longs === String ? $util.Long.prototype.toString.call(message.amt) : options.longs === Number ? new $util.LongBits(message.amt.low >>> 0, message.amt.high >>> 0).toNumber() : message.amt;
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.paymentHashString != null && message.hasOwnProperty("paymentHashString"))
                object.paymentHashString = message.paymentHashString;
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                object.paymentRequest = message.paymentRequest;
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                object.finalCltvDelta = message.finalCltvDelta;
            if (message.feeLimit != null && message.hasOwnProperty("feeLimit"))
                object.feeLimit = $root.lnrpc.FeeLimit.toObject(message.feeLimit, options);
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (typeof message.outgoingChanId === "number")
                    object.outgoingChanId = options.longs === String ? String(message.outgoingChanId) : message.outgoingChanId;
                else
                    object.outgoingChanId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingChanId) : options.longs === Number ? new $util.LongBits(message.outgoingChanId.low >>> 0, message.outgoingChanId.high >>> 0).toNumber(true) : message.outgoingChanId;
            if (message.cltvLimit != null && message.hasOwnProperty("cltvLimit"))
                object.cltvLimit = message.cltvLimit;
            let keys2;
            if (message.destCustomRecords && (keys2 = Object.keys(message.destCustomRecords)).length) {
                object.destCustomRecords = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.destCustomRecords[keys2[j]] = options.bytes === String ? $util.base64.encode(message.destCustomRecords[keys2[j]], 0, message.destCustomRecords[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.destCustomRecords[keys2[j]]) : message.destCustomRecords[keys2[j]];
            }
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (typeof message.amtMsat === "number")
                    object.amtMsat = options.longs === String ? String(message.amtMsat) : message.amtMsat;
                else
                    object.amtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtMsat) : options.longs === Number ? new $util.LongBits(message.amtMsat.low >>> 0, message.amtMsat.high >>> 0).toNumber() : message.amtMsat;
            if (message.lastHopPubkey != null && message.hasOwnProperty("lastHopPubkey"))
                object.lastHopPubkey = options.bytes === String ? $util.base64.encode(message.lastHopPubkey, 0, message.lastHopPubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.lastHopPubkey) : message.lastHopPubkey;
            if (message.allowSelfPayment != null && message.hasOwnProperty("allowSelfPayment"))
                object.allowSelfPayment = message.allowSelfPayment;
            if (message.destFeatures && message.destFeatures.length) {
                object.destFeatures = [];
                for (let j = 0; j < message.destFeatures.length; ++j)
                    object.destFeatures[j] = options.enums === String ? $root.lnrpc.FeatureBit[message.destFeatures[j]] : message.destFeatures[j];
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            return object;
        };

        /**
         * Converts this SendRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SendRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendRequest;
    })();

    lnrpc.SendResponse = (function() {

        /**
         * Properties of a SendResponse.
         * @memberof lnrpc
         * @interface ISendResponse
         * @property {string|null} [paymentError] SendResponse paymentError
         * @property {Uint8Array|null} [paymentPreimage] SendResponse paymentPreimage
         * @property {lnrpc.IRoute|null} [paymentRoute] SendResponse paymentRoute
         * @property {Uint8Array|null} [paymentHash] SendResponse paymentHash
         */

        /**
         * Constructs a new SendResponse.
         * @memberof lnrpc
         * @classdesc Represents a SendResponse.
         * @implements ISendResponse
         * @constructor
         * @param {lnrpc.ISendResponse=} [properties] Properties to set
         */
        function SendResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendResponse paymentError.
         * @member {string} paymentError
         * @memberof lnrpc.SendResponse
         * @instance
         */
        SendResponse.prototype.paymentError = "";

        /**
         * SendResponse paymentPreimage.
         * @member {Uint8Array} paymentPreimage
         * @memberof lnrpc.SendResponse
         * @instance
         */
        SendResponse.prototype.paymentPreimage = $util.newBuffer([]);

        /**
         * SendResponse paymentRoute.
         * @member {lnrpc.IRoute|null|undefined} paymentRoute
         * @memberof lnrpc.SendResponse
         * @instance
         */
        SendResponse.prototype.paymentRoute = null;

        /**
         * SendResponse paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof lnrpc.SendResponse
         * @instance
         */
        SendResponse.prototype.paymentHash = $util.newBuffer([]);

        /**
         * Creates a new SendResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendResponse
         * @static
         * @param {lnrpc.ISendResponse=} [properties] Properties to set
         * @returns {lnrpc.SendResponse} SendResponse instance
         */
        SendResponse.create = function create(properties) {
            return new SendResponse(properties);
        };

        /**
         * Encodes the specified SendResponse message. Does not implicitly {@link lnrpc.SendResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendResponse
         * @static
         * @param {lnrpc.ISendResponse} message SendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentError != null && Object.hasOwnProperty.call(message, "paymentError"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentError);
            if (message.paymentPreimage != null && Object.hasOwnProperty.call(message, "paymentPreimage"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.paymentPreimage);
            if (message.paymentRoute != null && Object.hasOwnProperty.call(message, "paymentRoute"))
                $root.lnrpc.Route.encode(message.paymentRoute, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.paymentHash);
            return writer;
        };

        /**
         * Encodes the specified SendResponse message, length delimited. Does not implicitly {@link lnrpc.SendResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendResponse
         * @static
         * @param {lnrpc.ISendResponse} message SendResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendResponse} SendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentError = reader.string();
                        break;
                    }
                case 2: {
                        message.paymentPreimage = reader.bytes();
                        break;
                    }
                case 3: {
                        message.paymentRoute = $root.lnrpc.Route.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendResponse} SendResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendResponse message.
         * @function verify
         * @memberof lnrpc.SendResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentError != null && message.hasOwnProperty("paymentError"))
                if (!$util.isString(message.paymentError))
                    return "paymentError: string expected";
            if (message.paymentPreimage != null && message.hasOwnProperty("paymentPreimage"))
                if (!(message.paymentPreimage && typeof message.paymentPreimage.length === "number" || $util.isString(message.paymentPreimage)))
                    return "paymentPreimage: buffer expected";
            if (message.paymentRoute != null && message.hasOwnProperty("paymentRoute")) {
                let error = $root.lnrpc.Route.verify(message.paymentRoute);
                if (error)
                    return "paymentRoute." + error;
            }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            return null;
        };

        /**
         * Creates a SendResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendResponse} SendResponse
         */
        SendResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendResponse)
                return object;
            let message = new $root.lnrpc.SendResponse();
            if (object.paymentError != null)
                message.paymentError = String(object.paymentError);
            if (object.paymentPreimage != null)
                if (typeof object.paymentPreimage === "string")
                    $util.base64.decode(object.paymentPreimage, message.paymentPreimage = $util.newBuffer($util.base64.length(object.paymentPreimage)), 0);
                else if (object.paymentPreimage.length)
                    message.paymentPreimage = object.paymentPreimage;
            if (object.paymentRoute != null) {
                if (typeof object.paymentRoute !== "object")
                    throw TypeError(".lnrpc.SendResponse.paymentRoute: object expected");
                message.paymentRoute = $root.lnrpc.Route.fromObject(object.paymentRoute);
            }
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            return message;
        };

        /**
         * Creates a plain object from a SendResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendResponse
         * @static
         * @param {lnrpc.SendResponse} message SendResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.paymentError = "";
                if (options.bytes === String)
                    object.paymentPreimage = "";
                else {
                    object.paymentPreimage = [];
                    if (options.bytes !== Array)
                        object.paymentPreimage = $util.newBuffer(object.paymentPreimage);
                }
                object.paymentRoute = null;
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
            }
            if (message.paymentError != null && message.hasOwnProperty("paymentError"))
                object.paymentError = message.paymentError;
            if (message.paymentPreimage != null && message.hasOwnProperty("paymentPreimage"))
                object.paymentPreimage = options.bytes === String ? $util.base64.encode(message.paymentPreimage, 0, message.paymentPreimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentPreimage) : message.paymentPreimage;
            if (message.paymentRoute != null && message.hasOwnProperty("paymentRoute"))
                object.paymentRoute = $root.lnrpc.Route.toObject(message.paymentRoute, options);
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            return object;
        };

        /**
         * Converts this SendResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.SendResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendResponse;
    })();

    lnrpc.SendToRouteRequest = (function() {

        /**
         * Properties of a SendToRouteRequest.
         * @memberof lnrpc
         * @interface ISendToRouteRequest
         * @property {Uint8Array|null} [paymentHash] SendToRouteRequest paymentHash
         * @property {string|null} [paymentHashString] SendToRouteRequest paymentHashString
         * @property {lnrpc.IRoute|null} [route] SendToRouteRequest route
         */

        /**
         * Constructs a new SendToRouteRequest.
         * @memberof lnrpc
         * @classdesc Represents a SendToRouteRequest.
         * @implements ISendToRouteRequest
         * @constructor
         * @param {lnrpc.ISendToRouteRequest=} [properties] Properties to set
         */
        function SendToRouteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendToRouteRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof lnrpc.SendToRouteRequest
         * @instance
         */
        SendToRouteRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * SendToRouteRequest paymentHashString.
         * @member {string} paymentHashString
         * @memberof lnrpc.SendToRouteRequest
         * @instance
         */
        SendToRouteRequest.prototype.paymentHashString = "";

        /**
         * SendToRouteRequest route.
         * @member {lnrpc.IRoute|null|undefined} route
         * @memberof lnrpc.SendToRouteRequest
         * @instance
         */
        SendToRouteRequest.prototype.route = null;

        /**
         * Creates a new SendToRouteRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {lnrpc.ISendToRouteRequest=} [properties] Properties to set
         * @returns {lnrpc.SendToRouteRequest} SendToRouteRequest instance
         */
        SendToRouteRequest.create = function create(properties) {
            return new SendToRouteRequest(properties);
        };

        /**
         * Encodes the specified SendToRouteRequest message. Does not implicitly {@link lnrpc.SendToRouteRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {lnrpc.ISendToRouteRequest} message SendToRouteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendToRouteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.paymentHash);
            if (message.paymentHashString != null && Object.hasOwnProperty.call(message, "paymentHashString"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentHashString);
            if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                $root.lnrpc.Route.encode(message.route, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SendToRouteRequest message, length delimited. Does not implicitly {@link lnrpc.SendToRouteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {lnrpc.ISendToRouteRequest} message SendToRouteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendToRouteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendToRouteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendToRouteRequest} SendToRouteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendToRouteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendToRouteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.paymentHashString = reader.string();
                        break;
                    }
                case 4: {
                        message.route = $root.lnrpc.Route.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendToRouteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendToRouteRequest} SendToRouteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendToRouteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendToRouteRequest message.
         * @function verify
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendToRouteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.paymentHashString != null && message.hasOwnProperty("paymentHashString"))
                if (!$util.isString(message.paymentHashString))
                    return "paymentHashString: string expected";
            if (message.route != null && message.hasOwnProperty("route")) {
                let error = $root.lnrpc.Route.verify(message.route);
                if (error)
                    return "route." + error;
            }
            return null;
        };

        /**
         * Creates a SendToRouteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendToRouteRequest} SendToRouteRequest
         */
        SendToRouteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendToRouteRequest)
                return object;
            let message = new $root.lnrpc.SendToRouteRequest();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.paymentHashString != null)
                message.paymentHashString = String(object.paymentHashString);
            if (object.route != null) {
                if (typeof object.route !== "object")
                    throw TypeError(".lnrpc.SendToRouteRequest.route: object expected");
                message.route = $root.lnrpc.Route.fromObject(object.route);
            }
            return message;
        };

        /**
         * Creates a plain object from a SendToRouteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendToRouteRequest
         * @static
         * @param {lnrpc.SendToRouteRequest} message SendToRouteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendToRouteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                object.paymentHashString = "";
                object.route = null;
            }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.paymentHashString != null && message.hasOwnProperty("paymentHashString"))
                object.paymentHashString = message.paymentHashString;
            if (message.route != null && message.hasOwnProperty("route"))
                object.route = $root.lnrpc.Route.toObject(message.route, options);
            return object;
        };

        /**
         * Converts this SendToRouteRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SendToRouteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendToRouteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendToRouteRequest;
    })();

    lnrpc.ChannelAcceptRequest = (function() {

        /**
         * Properties of a ChannelAcceptRequest.
         * @memberof lnrpc
         * @interface IChannelAcceptRequest
         * @property {Uint8Array|null} [nodePubkey] ChannelAcceptRequest nodePubkey
         * @property {Uint8Array|null} [chainHash] ChannelAcceptRequest chainHash
         * @property {Uint8Array|null} [pendingChanId] ChannelAcceptRequest pendingChanId
         * @property {Long|null} [fundingAmt] ChannelAcceptRequest fundingAmt
         * @property {Long|null} [pushAmt] ChannelAcceptRequest pushAmt
         * @property {Long|null} [dustLimit] ChannelAcceptRequest dustLimit
         * @property {Long|null} [maxValueInFlight] ChannelAcceptRequest maxValueInFlight
         * @property {Long|null} [channelReserve] ChannelAcceptRequest channelReserve
         * @property {Long|null} [minHtlc] ChannelAcceptRequest minHtlc
         * @property {Long|null} [feePerKw] ChannelAcceptRequest feePerKw
         * @property {number|null} [csvDelay] ChannelAcceptRequest csvDelay
         * @property {number|null} [maxAcceptedHtlcs] ChannelAcceptRequest maxAcceptedHtlcs
         * @property {number|null} [channelFlags] ChannelAcceptRequest channelFlags
         * @property {lnrpc.CommitmentType|null} [commitmentType] ChannelAcceptRequest commitmentType
         * @property {boolean|null} [wantsZeroConf] ChannelAcceptRequest wantsZeroConf
         * @property {boolean|null} [wantsScidAlias] ChannelAcceptRequest wantsScidAlias
         */

        /**
         * Constructs a new ChannelAcceptRequest.
         * @memberof lnrpc
         * @classdesc Represents a ChannelAcceptRequest.
         * @implements IChannelAcceptRequest
         * @constructor
         * @param {lnrpc.IChannelAcceptRequest=} [properties] Properties to set
         */
        function ChannelAcceptRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelAcceptRequest nodePubkey.
         * @member {Uint8Array} nodePubkey
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.nodePubkey = $util.newBuffer([]);

        /**
         * ChannelAcceptRequest chainHash.
         * @member {Uint8Array} chainHash
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.chainHash = $util.newBuffer([]);

        /**
         * ChannelAcceptRequest pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * ChannelAcceptRequest fundingAmt.
         * @member {Long} fundingAmt
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.fundingAmt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest pushAmt.
         * @member {Long} pushAmt
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.pushAmt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest dustLimit.
         * @member {Long} dustLimit
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.dustLimit = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest maxValueInFlight.
         * @member {Long} maxValueInFlight
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.maxValueInFlight = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest channelReserve.
         * @member {Long} channelReserve
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.channelReserve = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest minHtlc.
         * @member {Long} minHtlc
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.minHtlc = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest feePerKw.
         * @member {Long} feePerKw
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.feePerKw = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptRequest csvDelay.
         * @member {number} csvDelay
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.csvDelay = 0;

        /**
         * ChannelAcceptRequest maxAcceptedHtlcs.
         * @member {number} maxAcceptedHtlcs
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.maxAcceptedHtlcs = 0;

        /**
         * ChannelAcceptRequest channelFlags.
         * @member {number} channelFlags
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.channelFlags = 0;

        /**
         * ChannelAcceptRequest commitmentType.
         * @member {lnrpc.CommitmentType} commitmentType
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.commitmentType = 0;

        /**
         * ChannelAcceptRequest wantsZeroConf.
         * @member {boolean} wantsZeroConf
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.wantsZeroConf = false;

        /**
         * ChannelAcceptRequest wantsScidAlias.
         * @member {boolean} wantsScidAlias
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         */
        ChannelAcceptRequest.prototype.wantsScidAlias = false;

        /**
         * Creates a new ChannelAcceptRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {lnrpc.IChannelAcceptRequest=} [properties] Properties to set
         * @returns {lnrpc.ChannelAcceptRequest} ChannelAcceptRequest instance
         */
        ChannelAcceptRequest.create = function create(properties) {
            return new ChannelAcceptRequest(properties);
        };

        /**
         * Encodes the specified ChannelAcceptRequest message. Does not implicitly {@link lnrpc.ChannelAcceptRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {lnrpc.IChannelAcceptRequest} message ChannelAcceptRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAcceptRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodePubkey != null && Object.hasOwnProperty.call(message, "nodePubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nodePubkey);
            if (message.chainHash != null && Object.hasOwnProperty.call(message, "chainHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.chainHash);
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.pendingChanId);
            if (message.fundingAmt != null && Object.hasOwnProperty.call(message, "fundingAmt"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.fundingAmt);
            if (message.pushAmt != null && Object.hasOwnProperty.call(message, "pushAmt"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.pushAmt);
            if (message.dustLimit != null && Object.hasOwnProperty.call(message, "dustLimit"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.dustLimit);
            if (message.maxValueInFlight != null && Object.hasOwnProperty.call(message, "maxValueInFlight"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.maxValueInFlight);
            if (message.channelReserve != null && Object.hasOwnProperty.call(message, "channelReserve"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.channelReserve);
            if (message.minHtlc != null && Object.hasOwnProperty.call(message, "minHtlc"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.minHtlc);
            if (message.feePerKw != null && Object.hasOwnProperty.call(message, "feePerKw"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.feePerKw);
            if (message.csvDelay != null && Object.hasOwnProperty.call(message, "csvDelay"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.csvDelay);
            if (message.maxAcceptedHtlcs != null && Object.hasOwnProperty.call(message, "maxAcceptedHtlcs"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.maxAcceptedHtlcs);
            if (message.channelFlags != null && Object.hasOwnProperty.call(message, "channelFlags"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.channelFlags);
            if (message.commitmentType != null && Object.hasOwnProperty.call(message, "commitmentType"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.commitmentType);
            if (message.wantsZeroConf != null && Object.hasOwnProperty.call(message, "wantsZeroConf"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.wantsZeroConf);
            if (message.wantsScidAlias != null && Object.hasOwnProperty.call(message, "wantsScidAlias"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.wantsScidAlias);
            return writer;
        };

        /**
         * Encodes the specified ChannelAcceptRequest message, length delimited. Does not implicitly {@link lnrpc.ChannelAcceptRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {lnrpc.IChannelAcceptRequest} message ChannelAcceptRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAcceptRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelAcceptRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelAcceptRequest} ChannelAcceptRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAcceptRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelAcceptRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodePubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.chainHash = reader.bytes();
                        break;
                    }
                case 3: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 4: {
                        message.fundingAmt = reader.uint64();
                        break;
                    }
                case 5: {
                        message.pushAmt = reader.uint64();
                        break;
                    }
                case 6: {
                        message.dustLimit = reader.uint64();
                        break;
                    }
                case 7: {
                        message.maxValueInFlight = reader.uint64();
                        break;
                    }
                case 8: {
                        message.channelReserve = reader.uint64();
                        break;
                    }
                case 9: {
                        message.minHtlc = reader.uint64();
                        break;
                    }
                case 10: {
                        message.feePerKw = reader.uint64();
                        break;
                    }
                case 11: {
                        message.csvDelay = reader.uint32();
                        break;
                    }
                case 12: {
                        message.maxAcceptedHtlcs = reader.uint32();
                        break;
                    }
                case 13: {
                        message.channelFlags = reader.uint32();
                        break;
                    }
                case 14: {
                        message.commitmentType = reader.int32();
                        break;
                    }
                case 15: {
                        message.wantsZeroConf = reader.bool();
                        break;
                    }
                case 16: {
                        message.wantsScidAlias = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelAcceptRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelAcceptRequest} ChannelAcceptRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAcceptRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelAcceptRequest message.
         * @function verify
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelAcceptRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodePubkey != null && message.hasOwnProperty("nodePubkey"))
                if (!(message.nodePubkey && typeof message.nodePubkey.length === "number" || $util.isString(message.nodePubkey)))
                    return "nodePubkey: buffer expected";
            if (message.chainHash != null && message.hasOwnProperty("chainHash"))
                if (!(message.chainHash && typeof message.chainHash.length === "number" || $util.isString(message.chainHash)))
                    return "chainHash: buffer expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.fundingAmt != null && message.hasOwnProperty("fundingAmt"))
                if (!$util.isInteger(message.fundingAmt) && !(message.fundingAmt && $util.isInteger(message.fundingAmt.low) && $util.isInteger(message.fundingAmt.high)))
                    return "fundingAmt: integer|Long expected";
            if (message.pushAmt != null && message.hasOwnProperty("pushAmt"))
                if (!$util.isInteger(message.pushAmt) && !(message.pushAmt && $util.isInteger(message.pushAmt.low) && $util.isInteger(message.pushAmt.high)))
                    return "pushAmt: integer|Long expected";
            if (message.dustLimit != null && message.hasOwnProperty("dustLimit"))
                if (!$util.isInteger(message.dustLimit) && !(message.dustLimit && $util.isInteger(message.dustLimit.low) && $util.isInteger(message.dustLimit.high)))
                    return "dustLimit: integer|Long expected";
            if (message.maxValueInFlight != null && message.hasOwnProperty("maxValueInFlight"))
                if (!$util.isInteger(message.maxValueInFlight) && !(message.maxValueInFlight && $util.isInteger(message.maxValueInFlight.low) && $util.isInteger(message.maxValueInFlight.high)))
                    return "maxValueInFlight: integer|Long expected";
            if (message.channelReserve != null && message.hasOwnProperty("channelReserve"))
                if (!$util.isInteger(message.channelReserve) && !(message.channelReserve && $util.isInteger(message.channelReserve.low) && $util.isInteger(message.channelReserve.high)))
                    return "channelReserve: integer|Long expected";
            if (message.minHtlc != null && message.hasOwnProperty("minHtlc"))
                if (!$util.isInteger(message.minHtlc) && !(message.minHtlc && $util.isInteger(message.minHtlc.low) && $util.isInteger(message.minHtlc.high)))
                    return "minHtlc: integer|Long expected";
            if (message.feePerKw != null && message.hasOwnProperty("feePerKw"))
                if (!$util.isInteger(message.feePerKw) && !(message.feePerKw && $util.isInteger(message.feePerKw.low) && $util.isInteger(message.feePerKw.high)))
                    return "feePerKw: integer|Long expected";
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                if (!$util.isInteger(message.csvDelay))
                    return "csvDelay: integer expected";
            if (message.maxAcceptedHtlcs != null && message.hasOwnProperty("maxAcceptedHtlcs"))
                if (!$util.isInteger(message.maxAcceptedHtlcs))
                    return "maxAcceptedHtlcs: integer expected";
            if (message.channelFlags != null && message.hasOwnProperty("channelFlags"))
                if (!$util.isInteger(message.channelFlags))
                    return "channelFlags: integer expected";
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                switch (message.commitmentType) {
                default:
                    return "commitmentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.wantsZeroConf != null && message.hasOwnProperty("wantsZeroConf"))
                if (typeof message.wantsZeroConf !== "boolean")
                    return "wantsZeroConf: boolean expected";
            if (message.wantsScidAlias != null && message.hasOwnProperty("wantsScidAlias"))
                if (typeof message.wantsScidAlias !== "boolean")
                    return "wantsScidAlias: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelAcceptRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelAcceptRequest} ChannelAcceptRequest
         */
        ChannelAcceptRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelAcceptRequest)
                return object;
            let message = new $root.lnrpc.ChannelAcceptRequest();
            if (object.nodePubkey != null)
                if (typeof object.nodePubkey === "string")
                    $util.base64.decode(object.nodePubkey, message.nodePubkey = $util.newBuffer($util.base64.length(object.nodePubkey)), 0);
                else if (object.nodePubkey.length)
                    message.nodePubkey = object.nodePubkey;
            if (object.chainHash != null)
                if (typeof object.chainHash === "string")
                    $util.base64.decode(object.chainHash, message.chainHash = $util.newBuffer($util.base64.length(object.chainHash)), 0);
                else if (object.chainHash.length)
                    message.chainHash = object.chainHash;
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            if (object.fundingAmt != null)
                if ($util.Long)
                    (message.fundingAmt = $util.Long.fromValue(object.fundingAmt)).unsigned = true;
                else if (typeof object.fundingAmt === "string")
                    message.fundingAmt = parseInt(object.fundingAmt, 10);
                else if (typeof object.fundingAmt === "number")
                    message.fundingAmt = object.fundingAmt;
                else if (typeof object.fundingAmt === "object")
                    message.fundingAmt = new $util.LongBits(object.fundingAmt.low >>> 0, object.fundingAmt.high >>> 0).toNumber(true);
            if (object.pushAmt != null)
                if ($util.Long)
                    (message.pushAmt = $util.Long.fromValue(object.pushAmt)).unsigned = true;
                else if (typeof object.pushAmt === "string")
                    message.pushAmt = parseInt(object.pushAmt, 10);
                else if (typeof object.pushAmt === "number")
                    message.pushAmt = object.pushAmt;
                else if (typeof object.pushAmt === "object")
                    message.pushAmt = new $util.LongBits(object.pushAmt.low >>> 0, object.pushAmt.high >>> 0).toNumber(true);
            if (object.dustLimit != null)
                if ($util.Long)
                    (message.dustLimit = $util.Long.fromValue(object.dustLimit)).unsigned = true;
                else if (typeof object.dustLimit === "string")
                    message.dustLimit = parseInt(object.dustLimit, 10);
                else if (typeof object.dustLimit === "number")
                    message.dustLimit = object.dustLimit;
                else if (typeof object.dustLimit === "object")
                    message.dustLimit = new $util.LongBits(object.dustLimit.low >>> 0, object.dustLimit.high >>> 0).toNumber(true);
            if (object.maxValueInFlight != null)
                if ($util.Long)
                    (message.maxValueInFlight = $util.Long.fromValue(object.maxValueInFlight)).unsigned = true;
                else if (typeof object.maxValueInFlight === "string")
                    message.maxValueInFlight = parseInt(object.maxValueInFlight, 10);
                else if (typeof object.maxValueInFlight === "number")
                    message.maxValueInFlight = object.maxValueInFlight;
                else if (typeof object.maxValueInFlight === "object")
                    message.maxValueInFlight = new $util.LongBits(object.maxValueInFlight.low >>> 0, object.maxValueInFlight.high >>> 0).toNumber(true);
            if (object.channelReserve != null)
                if ($util.Long)
                    (message.channelReserve = $util.Long.fromValue(object.channelReserve)).unsigned = true;
                else if (typeof object.channelReserve === "string")
                    message.channelReserve = parseInt(object.channelReserve, 10);
                else if (typeof object.channelReserve === "number")
                    message.channelReserve = object.channelReserve;
                else if (typeof object.channelReserve === "object")
                    message.channelReserve = new $util.LongBits(object.channelReserve.low >>> 0, object.channelReserve.high >>> 0).toNumber(true);
            if (object.minHtlc != null)
                if ($util.Long)
                    (message.minHtlc = $util.Long.fromValue(object.minHtlc)).unsigned = true;
                else if (typeof object.minHtlc === "string")
                    message.minHtlc = parseInt(object.minHtlc, 10);
                else if (typeof object.minHtlc === "number")
                    message.minHtlc = object.minHtlc;
                else if (typeof object.minHtlc === "object")
                    message.minHtlc = new $util.LongBits(object.minHtlc.low >>> 0, object.minHtlc.high >>> 0).toNumber(true);
            if (object.feePerKw != null)
                if ($util.Long)
                    (message.feePerKw = $util.Long.fromValue(object.feePerKw)).unsigned = true;
                else if (typeof object.feePerKw === "string")
                    message.feePerKw = parseInt(object.feePerKw, 10);
                else if (typeof object.feePerKw === "number")
                    message.feePerKw = object.feePerKw;
                else if (typeof object.feePerKw === "object")
                    message.feePerKw = new $util.LongBits(object.feePerKw.low >>> 0, object.feePerKw.high >>> 0).toNumber(true);
            if (object.csvDelay != null)
                message.csvDelay = object.csvDelay >>> 0;
            if (object.maxAcceptedHtlcs != null)
                message.maxAcceptedHtlcs = object.maxAcceptedHtlcs >>> 0;
            if (object.channelFlags != null)
                message.channelFlags = object.channelFlags >>> 0;
            switch (object.commitmentType) {
            case "UNKNOWN_COMMITMENT_TYPE":
            case 0:
                message.commitmentType = 0;
                break;
            case "LEGACY":
            case 1:
                message.commitmentType = 1;
                break;
            case "STATIC_REMOTE_KEY":
            case 2:
                message.commitmentType = 2;
                break;
            case "ANCHORS":
            case 3:
                message.commitmentType = 3;
                break;
            case "SCRIPT_ENFORCED_LEASE":
            case 4:
                message.commitmentType = 4;
                break;
            }
            if (object.wantsZeroConf != null)
                message.wantsZeroConf = Boolean(object.wantsZeroConf);
            if (object.wantsScidAlias != null)
                message.wantsScidAlias = Boolean(object.wantsScidAlias);
            return message;
        };

        /**
         * Creates a plain object from a ChannelAcceptRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelAcceptRequest
         * @static
         * @param {lnrpc.ChannelAcceptRequest} message ChannelAcceptRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelAcceptRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.nodePubkey = "";
                else {
                    object.nodePubkey = [];
                    if (options.bytes !== Array)
                        object.nodePubkey = $util.newBuffer(object.nodePubkey);
                }
                if (options.bytes === String)
                    object.chainHash = "";
                else {
                    object.chainHash = [];
                    if (options.bytes !== Array)
                        object.chainHash = $util.newBuffer(object.chainHash);
                }
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.fundingAmt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fundingAmt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.pushAmt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pushAmt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.dustLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.dustLimit = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxValueInFlight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxValueInFlight = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.channelReserve = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.channelReserve = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minHtlc = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlc = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.feePerKw = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feePerKw = options.longs === String ? "0" : 0;
                object.csvDelay = 0;
                object.maxAcceptedHtlcs = 0;
                object.channelFlags = 0;
                object.commitmentType = options.enums === String ? "UNKNOWN_COMMITMENT_TYPE" : 0;
                object.wantsZeroConf = false;
                object.wantsScidAlias = false;
            }
            if (message.nodePubkey != null && message.hasOwnProperty("nodePubkey"))
                object.nodePubkey = options.bytes === String ? $util.base64.encode(message.nodePubkey, 0, message.nodePubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.nodePubkey) : message.nodePubkey;
            if (message.chainHash != null && message.hasOwnProperty("chainHash"))
                object.chainHash = options.bytes === String ? $util.base64.encode(message.chainHash, 0, message.chainHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.chainHash) : message.chainHash;
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.fundingAmt != null && message.hasOwnProperty("fundingAmt"))
                if (typeof message.fundingAmt === "number")
                    object.fundingAmt = options.longs === String ? String(message.fundingAmt) : message.fundingAmt;
                else
                    object.fundingAmt = options.longs === String ? $util.Long.prototype.toString.call(message.fundingAmt) : options.longs === Number ? new $util.LongBits(message.fundingAmt.low >>> 0, message.fundingAmt.high >>> 0).toNumber(true) : message.fundingAmt;
            if (message.pushAmt != null && message.hasOwnProperty("pushAmt"))
                if (typeof message.pushAmt === "number")
                    object.pushAmt = options.longs === String ? String(message.pushAmt) : message.pushAmt;
                else
                    object.pushAmt = options.longs === String ? $util.Long.prototype.toString.call(message.pushAmt) : options.longs === Number ? new $util.LongBits(message.pushAmt.low >>> 0, message.pushAmt.high >>> 0).toNumber(true) : message.pushAmt;
            if (message.dustLimit != null && message.hasOwnProperty("dustLimit"))
                if (typeof message.dustLimit === "number")
                    object.dustLimit = options.longs === String ? String(message.dustLimit) : message.dustLimit;
                else
                    object.dustLimit = options.longs === String ? $util.Long.prototype.toString.call(message.dustLimit) : options.longs === Number ? new $util.LongBits(message.dustLimit.low >>> 0, message.dustLimit.high >>> 0).toNumber(true) : message.dustLimit;
            if (message.maxValueInFlight != null && message.hasOwnProperty("maxValueInFlight"))
                if (typeof message.maxValueInFlight === "number")
                    object.maxValueInFlight = options.longs === String ? String(message.maxValueInFlight) : message.maxValueInFlight;
                else
                    object.maxValueInFlight = options.longs === String ? $util.Long.prototype.toString.call(message.maxValueInFlight) : options.longs === Number ? new $util.LongBits(message.maxValueInFlight.low >>> 0, message.maxValueInFlight.high >>> 0).toNumber(true) : message.maxValueInFlight;
            if (message.channelReserve != null && message.hasOwnProperty("channelReserve"))
                if (typeof message.channelReserve === "number")
                    object.channelReserve = options.longs === String ? String(message.channelReserve) : message.channelReserve;
                else
                    object.channelReserve = options.longs === String ? $util.Long.prototype.toString.call(message.channelReserve) : options.longs === Number ? new $util.LongBits(message.channelReserve.low >>> 0, message.channelReserve.high >>> 0).toNumber(true) : message.channelReserve;
            if (message.minHtlc != null && message.hasOwnProperty("minHtlc"))
                if (typeof message.minHtlc === "number")
                    object.minHtlc = options.longs === String ? String(message.minHtlc) : message.minHtlc;
                else
                    object.minHtlc = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlc) : options.longs === Number ? new $util.LongBits(message.minHtlc.low >>> 0, message.minHtlc.high >>> 0).toNumber(true) : message.minHtlc;
            if (message.feePerKw != null && message.hasOwnProperty("feePerKw"))
                if (typeof message.feePerKw === "number")
                    object.feePerKw = options.longs === String ? String(message.feePerKw) : message.feePerKw;
                else
                    object.feePerKw = options.longs === String ? $util.Long.prototype.toString.call(message.feePerKw) : options.longs === Number ? new $util.LongBits(message.feePerKw.low >>> 0, message.feePerKw.high >>> 0).toNumber(true) : message.feePerKw;
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                object.csvDelay = message.csvDelay;
            if (message.maxAcceptedHtlcs != null && message.hasOwnProperty("maxAcceptedHtlcs"))
                object.maxAcceptedHtlcs = message.maxAcceptedHtlcs;
            if (message.channelFlags != null && message.hasOwnProperty("channelFlags"))
                object.channelFlags = message.channelFlags;
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                object.commitmentType = options.enums === String ? $root.lnrpc.CommitmentType[message.commitmentType] : message.commitmentType;
            if (message.wantsZeroConf != null && message.hasOwnProperty("wantsZeroConf"))
                object.wantsZeroConf = message.wantsZeroConf;
            if (message.wantsScidAlias != null && message.hasOwnProperty("wantsScidAlias"))
                object.wantsScidAlias = message.wantsScidAlias;
            return object;
        };

        /**
         * Converts this ChannelAcceptRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelAcceptRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelAcceptRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelAcceptRequest;
    })();

    lnrpc.ChannelAcceptResponse = (function() {

        /**
         * Properties of a ChannelAcceptResponse.
         * @memberof lnrpc
         * @interface IChannelAcceptResponse
         * @property {boolean|null} [accept] ChannelAcceptResponse accept
         * @property {Uint8Array|null} [pendingChanId] ChannelAcceptResponse pendingChanId
         * @property {string|null} [error] ChannelAcceptResponse error
         * @property {string|null} [upfrontShutdown] ChannelAcceptResponse upfrontShutdown
         * @property {number|null} [csvDelay] ChannelAcceptResponse csvDelay
         * @property {Long|null} [reserveSat] ChannelAcceptResponse reserveSat
         * @property {Long|null} [inFlightMaxMsat] ChannelAcceptResponse inFlightMaxMsat
         * @property {number|null} [maxHtlcCount] ChannelAcceptResponse maxHtlcCount
         * @property {Long|null} [minHtlcIn] ChannelAcceptResponse minHtlcIn
         * @property {number|null} [minAcceptDepth] ChannelAcceptResponse minAcceptDepth
         * @property {boolean|null} [zeroConf] ChannelAcceptResponse zeroConf
         */

        /**
         * Constructs a new ChannelAcceptResponse.
         * @memberof lnrpc
         * @classdesc Represents a ChannelAcceptResponse.
         * @implements IChannelAcceptResponse
         * @constructor
         * @param {lnrpc.IChannelAcceptResponse=} [properties] Properties to set
         */
        function ChannelAcceptResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelAcceptResponse accept.
         * @member {boolean} accept
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.accept = false;

        /**
         * ChannelAcceptResponse pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * ChannelAcceptResponse error.
         * @member {string} error
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.error = "";

        /**
         * ChannelAcceptResponse upfrontShutdown.
         * @member {string} upfrontShutdown
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.upfrontShutdown = "";

        /**
         * ChannelAcceptResponse csvDelay.
         * @member {number} csvDelay
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.csvDelay = 0;

        /**
         * ChannelAcceptResponse reserveSat.
         * @member {Long} reserveSat
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.reserveSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptResponse inFlightMaxMsat.
         * @member {Long} inFlightMaxMsat
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.inFlightMaxMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptResponse maxHtlcCount.
         * @member {number} maxHtlcCount
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.maxHtlcCount = 0;

        /**
         * ChannelAcceptResponse minHtlcIn.
         * @member {Long} minHtlcIn
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.minHtlcIn = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelAcceptResponse minAcceptDepth.
         * @member {number} minAcceptDepth
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.minAcceptDepth = 0;

        /**
         * ChannelAcceptResponse zeroConf.
         * @member {boolean} zeroConf
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         */
        ChannelAcceptResponse.prototype.zeroConf = false;

        /**
         * Creates a new ChannelAcceptResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {lnrpc.IChannelAcceptResponse=} [properties] Properties to set
         * @returns {lnrpc.ChannelAcceptResponse} ChannelAcceptResponse instance
         */
        ChannelAcceptResponse.create = function create(properties) {
            return new ChannelAcceptResponse(properties);
        };

        /**
         * Encodes the specified ChannelAcceptResponse message. Does not implicitly {@link lnrpc.ChannelAcceptResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {lnrpc.IChannelAcceptResponse} message ChannelAcceptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAcceptResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accept != null && Object.hasOwnProperty.call(message, "accept"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.accept);
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.pendingChanId);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.error);
            if (message.upfrontShutdown != null && Object.hasOwnProperty.call(message, "upfrontShutdown"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.upfrontShutdown);
            if (message.csvDelay != null && Object.hasOwnProperty.call(message, "csvDelay"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.csvDelay);
            if (message.reserveSat != null && Object.hasOwnProperty.call(message, "reserveSat"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.reserveSat);
            if (message.inFlightMaxMsat != null && Object.hasOwnProperty.call(message, "inFlightMaxMsat"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.inFlightMaxMsat);
            if (message.maxHtlcCount != null && Object.hasOwnProperty.call(message, "maxHtlcCount"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.maxHtlcCount);
            if (message.minHtlcIn != null && Object.hasOwnProperty.call(message, "minHtlcIn"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.minHtlcIn);
            if (message.minAcceptDepth != null && Object.hasOwnProperty.call(message, "minAcceptDepth"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.minAcceptDepth);
            if (message.zeroConf != null && Object.hasOwnProperty.call(message, "zeroConf"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.zeroConf);
            return writer;
        };

        /**
         * Encodes the specified ChannelAcceptResponse message, length delimited. Does not implicitly {@link lnrpc.ChannelAcceptResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {lnrpc.IChannelAcceptResponse} message ChannelAcceptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelAcceptResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelAcceptResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelAcceptResponse} ChannelAcceptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAcceptResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelAcceptResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.accept = reader.bool();
                        break;
                    }
                case 2: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.error = reader.string();
                        break;
                    }
                case 4: {
                        message.upfrontShutdown = reader.string();
                        break;
                    }
                case 5: {
                        message.csvDelay = reader.uint32();
                        break;
                    }
                case 6: {
                        message.reserveSat = reader.uint64();
                        break;
                    }
                case 7: {
                        message.inFlightMaxMsat = reader.uint64();
                        break;
                    }
                case 8: {
                        message.maxHtlcCount = reader.uint32();
                        break;
                    }
                case 9: {
                        message.minHtlcIn = reader.uint64();
                        break;
                    }
                case 10: {
                        message.minAcceptDepth = reader.uint32();
                        break;
                    }
                case 11: {
                        message.zeroConf = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelAcceptResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelAcceptResponse} ChannelAcceptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelAcceptResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelAcceptResponse message.
         * @function verify
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelAcceptResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accept != null && message.hasOwnProperty("accept"))
                if (typeof message.accept !== "boolean")
                    return "accept: boolean expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.upfrontShutdown != null && message.hasOwnProperty("upfrontShutdown"))
                if (!$util.isString(message.upfrontShutdown))
                    return "upfrontShutdown: string expected";
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                if (!$util.isInteger(message.csvDelay))
                    return "csvDelay: integer expected";
            if (message.reserveSat != null && message.hasOwnProperty("reserveSat"))
                if (!$util.isInteger(message.reserveSat) && !(message.reserveSat && $util.isInteger(message.reserveSat.low) && $util.isInteger(message.reserveSat.high)))
                    return "reserveSat: integer|Long expected";
            if (message.inFlightMaxMsat != null && message.hasOwnProperty("inFlightMaxMsat"))
                if (!$util.isInteger(message.inFlightMaxMsat) && !(message.inFlightMaxMsat && $util.isInteger(message.inFlightMaxMsat.low) && $util.isInteger(message.inFlightMaxMsat.high)))
                    return "inFlightMaxMsat: integer|Long expected";
            if (message.maxHtlcCount != null && message.hasOwnProperty("maxHtlcCount"))
                if (!$util.isInteger(message.maxHtlcCount))
                    return "maxHtlcCount: integer expected";
            if (message.minHtlcIn != null && message.hasOwnProperty("minHtlcIn"))
                if (!$util.isInteger(message.minHtlcIn) && !(message.minHtlcIn && $util.isInteger(message.minHtlcIn.low) && $util.isInteger(message.minHtlcIn.high)))
                    return "minHtlcIn: integer|Long expected";
            if (message.minAcceptDepth != null && message.hasOwnProperty("minAcceptDepth"))
                if (!$util.isInteger(message.minAcceptDepth))
                    return "minAcceptDepth: integer expected";
            if (message.zeroConf != null && message.hasOwnProperty("zeroConf"))
                if (typeof message.zeroConf !== "boolean")
                    return "zeroConf: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelAcceptResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelAcceptResponse} ChannelAcceptResponse
         */
        ChannelAcceptResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelAcceptResponse)
                return object;
            let message = new $root.lnrpc.ChannelAcceptResponse();
            if (object.accept != null)
                message.accept = Boolean(object.accept);
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            if (object.error != null)
                message.error = String(object.error);
            if (object.upfrontShutdown != null)
                message.upfrontShutdown = String(object.upfrontShutdown);
            if (object.csvDelay != null)
                message.csvDelay = object.csvDelay >>> 0;
            if (object.reserveSat != null)
                if ($util.Long)
                    (message.reserveSat = $util.Long.fromValue(object.reserveSat)).unsigned = true;
                else if (typeof object.reserveSat === "string")
                    message.reserveSat = parseInt(object.reserveSat, 10);
                else if (typeof object.reserveSat === "number")
                    message.reserveSat = object.reserveSat;
                else if (typeof object.reserveSat === "object")
                    message.reserveSat = new $util.LongBits(object.reserveSat.low >>> 0, object.reserveSat.high >>> 0).toNumber(true);
            if (object.inFlightMaxMsat != null)
                if ($util.Long)
                    (message.inFlightMaxMsat = $util.Long.fromValue(object.inFlightMaxMsat)).unsigned = true;
                else if (typeof object.inFlightMaxMsat === "string")
                    message.inFlightMaxMsat = parseInt(object.inFlightMaxMsat, 10);
                else if (typeof object.inFlightMaxMsat === "number")
                    message.inFlightMaxMsat = object.inFlightMaxMsat;
                else if (typeof object.inFlightMaxMsat === "object")
                    message.inFlightMaxMsat = new $util.LongBits(object.inFlightMaxMsat.low >>> 0, object.inFlightMaxMsat.high >>> 0).toNumber(true);
            if (object.maxHtlcCount != null)
                message.maxHtlcCount = object.maxHtlcCount >>> 0;
            if (object.minHtlcIn != null)
                if ($util.Long)
                    (message.minHtlcIn = $util.Long.fromValue(object.minHtlcIn)).unsigned = true;
                else if (typeof object.minHtlcIn === "string")
                    message.minHtlcIn = parseInt(object.minHtlcIn, 10);
                else if (typeof object.minHtlcIn === "number")
                    message.minHtlcIn = object.minHtlcIn;
                else if (typeof object.minHtlcIn === "object")
                    message.minHtlcIn = new $util.LongBits(object.minHtlcIn.low >>> 0, object.minHtlcIn.high >>> 0).toNumber(true);
            if (object.minAcceptDepth != null)
                message.minAcceptDepth = object.minAcceptDepth >>> 0;
            if (object.zeroConf != null)
                message.zeroConf = Boolean(object.zeroConf);
            return message;
        };

        /**
         * Creates a plain object from a ChannelAcceptResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelAcceptResponse
         * @static
         * @param {lnrpc.ChannelAcceptResponse} message ChannelAcceptResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelAcceptResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.accept = false;
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                object.error = "";
                object.upfrontShutdown = "";
                object.csvDelay = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.reserveSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reserveSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.inFlightMaxMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.inFlightMaxMsat = options.longs === String ? "0" : 0;
                object.maxHtlcCount = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minHtlcIn = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlcIn = options.longs === String ? "0" : 0;
                object.minAcceptDepth = 0;
                object.zeroConf = false;
            }
            if (message.accept != null && message.hasOwnProperty("accept"))
                object.accept = message.accept;
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.upfrontShutdown != null && message.hasOwnProperty("upfrontShutdown"))
                object.upfrontShutdown = message.upfrontShutdown;
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                object.csvDelay = message.csvDelay;
            if (message.reserveSat != null && message.hasOwnProperty("reserveSat"))
                if (typeof message.reserveSat === "number")
                    object.reserveSat = options.longs === String ? String(message.reserveSat) : message.reserveSat;
                else
                    object.reserveSat = options.longs === String ? $util.Long.prototype.toString.call(message.reserveSat) : options.longs === Number ? new $util.LongBits(message.reserveSat.low >>> 0, message.reserveSat.high >>> 0).toNumber(true) : message.reserveSat;
            if (message.inFlightMaxMsat != null && message.hasOwnProperty("inFlightMaxMsat"))
                if (typeof message.inFlightMaxMsat === "number")
                    object.inFlightMaxMsat = options.longs === String ? String(message.inFlightMaxMsat) : message.inFlightMaxMsat;
                else
                    object.inFlightMaxMsat = options.longs === String ? $util.Long.prototype.toString.call(message.inFlightMaxMsat) : options.longs === Number ? new $util.LongBits(message.inFlightMaxMsat.low >>> 0, message.inFlightMaxMsat.high >>> 0).toNumber(true) : message.inFlightMaxMsat;
            if (message.maxHtlcCount != null && message.hasOwnProperty("maxHtlcCount"))
                object.maxHtlcCount = message.maxHtlcCount;
            if (message.minHtlcIn != null && message.hasOwnProperty("minHtlcIn"))
                if (typeof message.minHtlcIn === "number")
                    object.minHtlcIn = options.longs === String ? String(message.minHtlcIn) : message.minHtlcIn;
                else
                    object.minHtlcIn = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlcIn) : options.longs === Number ? new $util.LongBits(message.minHtlcIn.low >>> 0, message.minHtlcIn.high >>> 0).toNumber(true) : message.minHtlcIn;
            if (message.minAcceptDepth != null && message.hasOwnProperty("minAcceptDepth"))
                object.minAcceptDepth = message.minAcceptDepth;
            if (message.zeroConf != null && message.hasOwnProperty("zeroConf"))
                object.zeroConf = message.zeroConf;
            return object;
        };

        /**
         * Converts this ChannelAcceptResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelAcceptResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelAcceptResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelAcceptResponse;
    })();

    lnrpc.ChannelPoint = (function() {

        /**
         * Properties of a ChannelPoint.
         * @memberof lnrpc
         * @interface IChannelPoint
         * @property {Uint8Array|null} [fundingTxidBytes] ChannelPoint fundingTxidBytes
         * @property {string|null} [fundingTxidStr] ChannelPoint fundingTxidStr
         * @property {number|null} [outputIndex] ChannelPoint outputIndex
         */

        /**
         * Constructs a new ChannelPoint.
         * @memberof lnrpc
         * @classdesc Represents a ChannelPoint.
         * @implements IChannelPoint
         * @constructor
         * @param {lnrpc.IChannelPoint=} [properties] Properties to set
         */
        function ChannelPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelPoint fundingTxidBytes.
         * @member {Uint8Array} fundingTxidBytes
         * @memberof lnrpc.ChannelPoint
         * @instance
         */
        ChannelPoint.prototype.fundingTxidBytes = $util.newBuffer([]);

        /**
         * ChannelPoint fundingTxidStr.
         * @member {string} fundingTxidStr
         * @memberof lnrpc.ChannelPoint
         * @instance
         */
        ChannelPoint.prototype.fundingTxidStr = "";

        /**
         * ChannelPoint outputIndex.
         * @member {number} outputIndex
         * @memberof lnrpc.ChannelPoint
         * @instance
         */
        ChannelPoint.prototype.outputIndex = 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ChannelPoint fundingTxid.
         * @member {"fundingTxidBytes"|"fundingTxidStr"|undefined} fundingTxid
         * @memberof lnrpc.ChannelPoint
         * @instance
         */
        Object.defineProperty(ChannelPoint.prototype, "fundingTxid", {
            get: $util.oneOfGetter($oneOfFields = ["fundingTxidBytes", "fundingTxidStr"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ChannelPoint instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {lnrpc.IChannelPoint=} [properties] Properties to set
         * @returns {lnrpc.ChannelPoint} ChannelPoint instance
         */
        ChannelPoint.create = function create(properties) {
            return new ChannelPoint(properties);
        };

        /**
         * Encodes the specified ChannelPoint message. Does not implicitly {@link lnrpc.ChannelPoint.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {lnrpc.IChannelPoint} message ChannelPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelPoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fundingTxidBytes != null && Object.hasOwnProperty.call(message, "fundingTxidBytes"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fundingTxidBytes);
            if (message.fundingTxidStr != null && Object.hasOwnProperty.call(message, "fundingTxidStr"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fundingTxidStr);
            if (message.outputIndex != null && Object.hasOwnProperty.call(message, "outputIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.outputIndex);
            return writer;
        };

        /**
         * Encodes the specified ChannelPoint message, length delimited. Does not implicitly {@link lnrpc.ChannelPoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {lnrpc.IChannelPoint} message ChannelPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelPoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelPoint message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelPoint} ChannelPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelPoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelPoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fundingTxidBytes = reader.bytes();
                        break;
                    }
                case 2: {
                        message.fundingTxidStr = reader.string();
                        break;
                    }
                case 3: {
                        message.outputIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelPoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelPoint} ChannelPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelPoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelPoint message.
         * @function verify
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelPoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.fundingTxidBytes != null && message.hasOwnProperty("fundingTxidBytes")) {
                properties.fundingTxid = 1;
                if (!(message.fundingTxidBytes && typeof message.fundingTxidBytes.length === "number" || $util.isString(message.fundingTxidBytes)))
                    return "fundingTxidBytes: buffer expected";
            }
            if (message.fundingTxidStr != null && message.hasOwnProperty("fundingTxidStr")) {
                if (properties.fundingTxid === 1)
                    return "fundingTxid: multiple values";
                properties.fundingTxid = 1;
                if (!$util.isString(message.fundingTxidStr))
                    return "fundingTxidStr: string expected";
            }
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                if (!$util.isInteger(message.outputIndex))
                    return "outputIndex: integer expected";
            return null;
        };

        /**
         * Creates a ChannelPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelPoint} ChannelPoint
         */
        ChannelPoint.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelPoint)
                return object;
            let message = new $root.lnrpc.ChannelPoint();
            if (object.fundingTxidBytes != null)
                if (typeof object.fundingTxidBytes === "string")
                    $util.base64.decode(object.fundingTxidBytes, message.fundingTxidBytes = $util.newBuffer($util.base64.length(object.fundingTxidBytes)), 0);
                else if (object.fundingTxidBytes.length)
                    message.fundingTxidBytes = object.fundingTxidBytes;
            if (object.fundingTxidStr != null)
                message.fundingTxidStr = String(object.fundingTxidStr);
            if (object.outputIndex != null)
                message.outputIndex = object.outputIndex >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ChannelPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelPoint
         * @static
         * @param {lnrpc.ChannelPoint} message ChannelPoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelPoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.outputIndex = 0;
            if (message.fundingTxidBytes != null && message.hasOwnProperty("fundingTxidBytes")) {
                object.fundingTxidBytes = options.bytes === String ? $util.base64.encode(message.fundingTxidBytes, 0, message.fundingTxidBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.fundingTxidBytes) : message.fundingTxidBytes;
                if (options.oneofs)
                    object.fundingTxid = "fundingTxidBytes";
            }
            if (message.fundingTxidStr != null && message.hasOwnProperty("fundingTxidStr")) {
                object.fundingTxidStr = message.fundingTxidStr;
                if (options.oneofs)
                    object.fundingTxid = "fundingTxidStr";
            }
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                object.outputIndex = message.outputIndex;
            return object;
        };

        /**
         * Converts this ChannelPoint to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelPoint;
    })();

    lnrpc.OutPoint = (function() {

        /**
         * Properties of an OutPoint.
         * @memberof lnrpc
         * @interface IOutPoint
         * @property {Uint8Array|null} [txidBytes] OutPoint txidBytes
         * @property {string|null} [txidStr] OutPoint txidStr
         * @property {number|null} [outputIndex] OutPoint outputIndex
         */

        /**
         * Constructs a new OutPoint.
         * @memberof lnrpc
         * @classdesc Represents an OutPoint.
         * @implements IOutPoint
         * @constructor
         * @param {lnrpc.IOutPoint=} [properties] Properties to set
         */
        function OutPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutPoint txidBytes.
         * @member {Uint8Array} txidBytes
         * @memberof lnrpc.OutPoint
         * @instance
         */
        OutPoint.prototype.txidBytes = $util.newBuffer([]);

        /**
         * OutPoint txidStr.
         * @member {string} txidStr
         * @memberof lnrpc.OutPoint
         * @instance
         */
        OutPoint.prototype.txidStr = "";

        /**
         * OutPoint outputIndex.
         * @member {number} outputIndex
         * @memberof lnrpc.OutPoint
         * @instance
         */
        OutPoint.prototype.outputIndex = 0;

        /**
         * Creates a new OutPoint instance using the specified properties.
         * @function create
         * @memberof lnrpc.OutPoint
         * @static
         * @param {lnrpc.IOutPoint=} [properties] Properties to set
         * @returns {lnrpc.OutPoint} OutPoint instance
         */
        OutPoint.create = function create(properties) {
            return new OutPoint(properties);
        };

        /**
         * Encodes the specified OutPoint message. Does not implicitly {@link lnrpc.OutPoint.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.OutPoint
         * @static
         * @param {lnrpc.IOutPoint} message OutPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutPoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txidBytes != null && Object.hasOwnProperty.call(message, "txidBytes"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.txidBytes);
            if (message.txidStr != null && Object.hasOwnProperty.call(message, "txidStr"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.txidStr);
            if (message.outputIndex != null && Object.hasOwnProperty.call(message, "outputIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.outputIndex);
            return writer;
        };

        /**
         * Encodes the specified OutPoint message, length delimited. Does not implicitly {@link lnrpc.OutPoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.OutPoint
         * @static
         * @param {lnrpc.IOutPoint} message OutPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutPoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutPoint message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.OutPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.OutPoint} OutPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutPoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.OutPoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txidBytes = reader.bytes();
                        break;
                    }
                case 2: {
                        message.txidStr = reader.string();
                        break;
                    }
                case 3: {
                        message.outputIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutPoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.OutPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.OutPoint} OutPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutPoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutPoint message.
         * @function verify
         * @memberof lnrpc.OutPoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutPoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txidBytes != null && message.hasOwnProperty("txidBytes"))
                if (!(message.txidBytes && typeof message.txidBytes.length === "number" || $util.isString(message.txidBytes)))
                    return "txidBytes: buffer expected";
            if (message.txidStr != null && message.hasOwnProperty("txidStr"))
                if (!$util.isString(message.txidStr))
                    return "txidStr: string expected";
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                if (!$util.isInteger(message.outputIndex))
                    return "outputIndex: integer expected";
            return null;
        };

        /**
         * Creates an OutPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.OutPoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.OutPoint} OutPoint
         */
        OutPoint.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.OutPoint)
                return object;
            let message = new $root.lnrpc.OutPoint();
            if (object.txidBytes != null)
                if (typeof object.txidBytes === "string")
                    $util.base64.decode(object.txidBytes, message.txidBytes = $util.newBuffer($util.base64.length(object.txidBytes)), 0);
                else if (object.txidBytes.length)
                    message.txidBytes = object.txidBytes;
            if (object.txidStr != null)
                message.txidStr = String(object.txidStr);
            if (object.outputIndex != null)
                message.outputIndex = object.outputIndex >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an OutPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.OutPoint
         * @static
         * @param {lnrpc.OutPoint} message OutPoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutPoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.txidBytes = "";
                else {
                    object.txidBytes = [];
                    if (options.bytes !== Array)
                        object.txidBytes = $util.newBuffer(object.txidBytes);
                }
                object.txidStr = "";
                object.outputIndex = 0;
            }
            if (message.txidBytes != null && message.hasOwnProperty("txidBytes"))
                object.txidBytes = options.bytes === String ? $util.base64.encode(message.txidBytes, 0, message.txidBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.txidBytes) : message.txidBytes;
            if (message.txidStr != null && message.hasOwnProperty("txidStr"))
                object.txidStr = message.txidStr;
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                object.outputIndex = message.outputIndex;
            return object;
        };

        /**
         * Converts this OutPoint to JSON.
         * @function toJSON
         * @memberof lnrpc.OutPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutPoint;
    })();

    lnrpc.PreviousOutPoint = (function() {

        /**
         * Properties of a PreviousOutPoint.
         * @memberof lnrpc
         * @interface IPreviousOutPoint
         * @property {string|null} [outpoint] PreviousOutPoint outpoint
         * @property {boolean|null} [isOurOutput] PreviousOutPoint isOurOutput
         */

        /**
         * Constructs a new PreviousOutPoint.
         * @memberof lnrpc
         * @classdesc Represents a PreviousOutPoint.
         * @implements IPreviousOutPoint
         * @constructor
         * @param {lnrpc.IPreviousOutPoint=} [properties] Properties to set
         */
        function PreviousOutPoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PreviousOutPoint outpoint.
         * @member {string} outpoint
         * @memberof lnrpc.PreviousOutPoint
         * @instance
         */
        PreviousOutPoint.prototype.outpoint = "";

        /**
         * PreviousOutPoint isOurOutput.
         * @member {boolean} isOurOutput
         * @memberof lnrpc.PreviousOutPoint
         * @instance
         */
        PreviousOutPoint.prototype.isOurOutput = false;

        /**
         * Creates a new PreviousOutPoint instance using the specified properties.
         * @function create
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {lnrpc.IPreviousOutPoint=} [properties] Properties to set
         * @returns {lnrpc.PreviousOutPoint} PreviousOutPoint instance
         */
        PreviousOutPoint.create = function create(properties) {
            return new PreviousOutPoint(properties);
        };

        /**
         * Encodes the specified PreviousOutPoint message. Does not implicitly {@link lnrpc.PreviousOutPoint.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {lnrpc.IPreviousOutPoint} message PreviousOutPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PreviousOutPoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.outpoint);
            if (message.isOurOutput != null && Object.hasOwnProperty.call(message, "isOurOutput"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isOurOutput);
            return writer;
        };

        /**
         * Encodes the specified PreviousOutPoint message, length delimited. Does not implicitly {@link lnrpc.PreviousOutPoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {lnrpc.IPreviousOutPoint} message PreviousOutPoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PreviousOutPoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PreviousOutPoint message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PreviousOutPoint} PreviousOutPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PreviousOutPoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PreviousOutPoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.outpoint = reader.string();
                        break;
                    }
                case 2: {
                        message.isOurOutput = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PreviousOutPoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PreviousOutPoint} PreviousOutPoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PreviousOutPoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PreviousOutPoint message.
         * @function verify
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PreviousOutPoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                if (!$util.isString(message.outpoint))
                    return "outpoint: string expected";
            if (message.isOurOutput != null && message.hasOwnProperty("isOurOutput"))
                if (typeof message.isOurOutput !== "boolean")
                    return "isOurOutput: boolean expected";
            return null;
        };

        /**
         * Creates a PreviousOutPoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PreviousOutPoint} PreviousOutPoint
         */
        PreviousOutPoint.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PreviousOutPoint)
                return object;
            let message = new $root.lnrpc.PreviousOutPoint();
            if (object.outpoint != null)
                message.outpoint = String(object.outpoint);
            if (object.isOurOutput != null)
                message.isOurOutput = Boolean(object.isOurOutput);
            return message;
        };

        /**
         * Creates a plain object from a PreviousOutPoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PreviousOutPoint
         * @static
         * @param {lnrpc.PreviousOutPoint} message PreviousOutPoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PreviousOutPoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outpoint = "";
                object.isOurOutput = false;
            }
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = message.outpoint;
            if (message.isOurOutput != null && message.hasOwnProperty("isOurOutput"))
                object.isOurOutput = message.isOurOutput;
            return object;
        };

        /**
         * Converts this PreviousOutPoint to JSON.
         * @function toJSON
         * @memberof lnrpc.PreviousOutPoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PreviousOutPoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PreviousOutPoint;
    })();

    lnrpc.LightningAddress = (function() {

        /**
         * Properties of a LightningAddress.
         * @memberof lnrpc
         * @interface ILightningAddress
         * @property {string|null} [pubkey] LightningAddress pubkey
         * @property {string|null} [host] LightningAddress host
         */

        /**
         * Constructs a new LightningAddress.
         * @memberof lnrpc
         * @classdesc Represents a LightningAddress.
         * @implements ILightningAddress
         * @constructor
         * @param {lnrpc.ILightningAddress=} [properties] Properties to set
         */
        function LightningAddress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LightningAddress pubkey.
         * @member {string} pubkey
         * @memberof lnrpc.LightningAddress
         * @instance
         */
        LightningAddress.prototype.pubkey = "";

        /**
         * LightningAddress host.
         * @member {string} host
         * @memberof lnrpc.LightningAddress
         * @instance
         */
        LightningAddress.prototype.host = "";

        /**
         * Creates a new LightningAddress instance using the specified properties.
         * @function create
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {lnrpc.ILightningAddress=} [properties] Properties to set
         * @returns {lnrpc.LightningAddress} LightningAddress instance
         */
        LightningAddress.create = function create(properties) {
            return new LightningAddress(properties);
        };

        /**
         * Encodes the specified LightningAddress message. Does not implicitly {@link lnrpc.LightningAddress.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {lnrpc.ILightningAddress} message LightningAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LightningAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubkey);
            if (message.host != null && Object.hasOwnProperty.call(message, "host"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.host);
            return writer;
        };

        /**
         * Encodes the specified LightningAddress message, length delimited. Does not implicitly {@link lnrpc.LightningAddress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {lnrpc.ILightningAddress} message LightningAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LightningAddress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LightningAddress message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.LightningAddress} LightningAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LightningAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.LightningAddress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubkey = reader.string();
                        break;
                    }
                case 2: {
                        message.host = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LightningAddress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.LightningAddress} LightningAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LightningAddress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LightningAddress message.
         * @function verify
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LightningAddress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!$util.isString(message.pubkey))
                    return "pubkey: string expected";
            if (message.host != null && message.hasOwnProperty("host"))
                if (!$util.isString(message.host))
                    return "host: string expected";
            return null;
        };

        /**
         * Creates a LightningAddress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.LightningAddress} LightningAddress
         */
        LightningAddress.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.LightningAddress)
                return object;
            let message = new $root.lnrpc.LightningAddress();
            if (object.pubkey != null)
                message.pubkey = String(object.pubkey);
            if (object.host != null)
                message.host = String(object.host);
            return message;
        };

        /**
         * Creates a plain object from a LightningAddress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.LightningAddress
         * @static
         * @param {lnrpc.LightningAddress} message LightningAddress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LightningAddress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.pubkey = "";
                object.host = "";
            }
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = message.pubkey;
            if (message.host != null && message.hasOwnProperty("host"))
                object.host = message.host;
            return object;
        };

        /**
         * Converts this LightningAddress to JSON.
         * @function toJSON
         * @memberof lnrpc.LightningAddress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LightningAddress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LightningAddress;
    })();

    lnrpc.EstimateFeeRequest = (function() {

        /**
         * Properties of an EstimateFeeRequest.
         * @memberof lnrpc
         * @interface IEstimateFeeRequest
         * @property {Object.<string,Long>|null} [AddrToAmount] EstimateFeeRequest AddrToAmount
         * @property {number|null} [targetConf] EstimateFeeRequest targetConf
         * @property {number|null} [minConfs] EstimateFeeRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] EstimateFeeRequest spendUnconfirmed
         */

        /**
         * Constructs a new EstimateFeeRequest.
         * @memberof lnrpc
         * @classdesc Represents an EstimateFeeRequest.
         * @implements IEstimateFeeRequest
         * @constructor
         * @param {lnrpc.IEstimateFeeRequest=} [properties] Properties to set
         */
        function EstimateFeeRequest(properties) {
            this.AddrToAmount = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EstimateFeeRequest AddrToAmount.
         * @member {Object.<string,Long>} AddrToAmount
         * @memberof lnrpc.EstimateFeeRequest
         * @instance
         */
        EstimateFeeRequest.prototype.AddrToAmount = $util.emptyObject;

        /**
         * EstimateFeeRequest targetConf.
         * @member {number} targetConf
         * @memberof lnrpc.EstimateFeeRequest
         * @instance
         */
        EstimateFeeRequest.prototype.targetConf = 0;

        /**
         * EstimateFeeRequest minConfs.
         * @member {number} minConfs
         * @memberof lnrpc.EstimateFeeRequest
         * @instance
         */
        EstimateFeeRequest.prototype.minConfs = 0;

        /**
         * EstimateFeeRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof lnrpc.EstimateFeeRequest
         * @instance
         */
        EstimateFeeRequest.prototype.spendUnconfirmed = false;

        /**
         * Creates a new EstimateFeeRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {lnrpc.IEstimateFeeRequest=} [properties] Properties to set
         * @returns {lnrpc.EstimateFeeRequest} EstimateFeeRequest instance
         */
        EstimateFeeRequest.create = function create(properties) {
            return new EstimateFeeRequest(properties);
        };

        /**
         * Encodes the specified EstimateFeeRequest message. Does not implicitly {@link lnrpc.EstimateFeeRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {lnrpc.IEstimateFeeRequest} message EstimateFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.AddrToAmount != null && Object.hasOwnProperty.call(message, "AddrToAmount"))
                for (let keys = Object.keys(message.AddrToAmount), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int64(message.AddrToAmount[keys[i]]).ldelim();
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.targetConf);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.spendUnconfirmed);
            return writer;
        };

        /**
         * Encodes the specified EstimateFeeRequest message, length delimited. Does not implicitly {@link lnrpc.EstimateFeeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {lnrpc.IEstimateFeeRequest} message EstimateFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EstimateFeeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.EstimateFeeRequest} EstimateFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.EstimateFeeRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.AddrToAmount === $util.emptyObject)
                            message.AddrToAmount = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.int64();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.AddrToAmount[key] = value;
                        break;
                    }
                case 2: {
                        message.targetConf = reader.int32();
                        break;
                    }
                case 3: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 4: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EstimateFeeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.EstimateFeeRequest} EstimateFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EstimateFeeRequest message.
         * @function verify
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EstimateFeeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.AddrToAmount != null && message.hasOwnProperty("AddrToAmount")) {
                if (!$util.isObject(message.AddrToAmount))
                    return "AddrToAmount: object expected";
                let key = Object.keys(message.AddrToAmount);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isInteger(message.AddrToAmount[key[i]]) && !(message.AddrToAmount[key[i]] && $util.isInteger(message.AddrToAmount[key[i]].low) && $util.isInteger(message.AddrToAmount[key[i]].high)))
                        return "AddrToAmount: integer|Long{k:string} expected";
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            return null;
        };

        /**
         * Creates an EstimateFeeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.EstimateFeeRequest} EstimateFeeRequest
         */
        EstimateFeeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.EstimateFeeRequest)
                return object;
            let message = new $root.lnrpc.EstimateFeeRequest();
            if (object.AddrToAmount) {
                if (typeof object.AddrToAmount !== "object")
                    throw TypeError(".lnrpc.EstimateFeeRequest.AddrToAmount: object expected");
                message.AddrToAmount = {};
                for (let keys = Object.keys(object.AddrToAmount), i = 0; i < keys.length; ++i)
                    if ($util.Long)
                        (message.AddrToAmount[keys[i]] = $util.Long.fromValue(object.AddrToAmount[keys[i]])).unsigned = false;
                    else if (typeof object.AddrToAmount[keys[i]] === "string")
                        message.AddrToAmount[keys[i]] = parseInt(object.AddrToAmount[keys[i]], 10);
                    else if (typeof object.AddrToAmount[keys[i]] === "number")
                        message.AddrToAmount[keys[i]] = object.AddrToAmount[keys[i]];
                    else if (typeof object.AddrToAmount[keys[i]] === "object")
                        message.AddrToAmount[keys[i]] = new $util.LongBits(object.AddrToAmount[keys[i]].low >>> 0, object.AddrToAmount[keys[i]].high >>> 0).toNumber();
            }
            if (object.targetConf != null)
                message.targetConf = object.targetConf | 0;
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            return message;
        };

        /**
         * Creates a plain object from an EstimateFeeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.EstimateFeeRequest
         * @static
         * @param {lnrpc.EstimateFeeRequest} message EstimateFeeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EstimateFeeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.AddrToAmount = {};
            if (options.defaults) {
                object.targetConf = 0;
                object.minConfs = 0;
                object.spendUnconfirmed = false;
            }
            let keys2;
            if (message.AddrToAmount && (keys2 = Object.keys(message.AddrToAmount)).length) {
                object.AddrToAmount = {};
                for (let j = 0; j < keys2.length; ++j)
                    if (typeof message.AddrToAmount[keys2[j]] === "number")
                        object.AddrToAmount[keys2[j]] = options.longs === String ? String(message.AddrToAmount[keys2[j]]) : message.AddrToAmount[keys2[j]];
                    else
                        object.AddrToAmount[keys2[j]] = options.longs === String ? $util.Long.prototype.toString.call(message.AddrToAmount[keys2[j]]) : options.longs === Number ? new $util.LongBits(message.AddrToAmount[keys2[j]].low >>> 0, message.AddrToAmount[keys2[j]].high >>> 0).toNumber() : message.AddrToAmount[keys2[j]];
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            return object;
        };

        /**
         * Converts this EstimateFeeRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.EstimateFeeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EstimateFeeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EstimateFeeRequest;
    })();

    lnrpc.EstimateFeeResponse = (function() {

        /**
         * Properties of an EstimateFeeResponse.
         * @memberof lnrpc
         * @interface IEstimateFeeResponse
         * @property {Long|null} [feeSat] EstimateFeeResponse feeSat
         * @property {Long|null} [feerateSatPerByte] EstimateFeeResponse feerateSatPerByte
         * @property {Long|null} [satPerVbyte] EstimateFeeResponse satPerVbyte
         */

        /**
         * Constructs a new EstimateFeeResponse.
         * @memberof lnrpc
         * @classdesc Represents an EstimateFeeResponse.
         * @implements IEstimateFeeResponse
         * @constructor
         * @param {lnrpc.IEstimateFeeResponse=} [properties] Properties to set
         */
        function EstimateFeeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EstimateFeeResponse feeSat.
         * @member {Long} feeSat
         * @memberof lnrpc.EstimateFeeResponse
         * @instance
         */
        EstimateFeeResponse.prototype.feeSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * EstimateFeeResponse feerateSatPerByte.
         * @member {Long} feerateSatPerByte
         * @memberof lnrpc.EstimateFeeResponse
         * @instance
         */
        EstimateFeeResponse.prototype.feerateSatPerByte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * EstimateFeeResponse satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof lnrpc.EstimateFeeResponse
         * @instance
         */
        EstimateFeeResponse.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new EstimateFeeResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {lnrpc.IEstimateFeeResponse=} [properties] Properties to set
         * @returns {lnrpc.EstimateFeeResponse} EstimateFeeResponse instance
         */
        EstimateFeeResponse.create = function create(properties) {
            return new EstimateFeeResponse(properties);
        };

        /**
         * Encodes the specified EstimateFeeResponse message. Does not implicitly {@link lnrpc.EstimateFeeResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {lnrpc.IEstimateFeeResponse} message EstimateFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.feeSat != null && Object.hasOwnProperty.call(message, "feeSat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.feeSat);
            if (message.feerateSatPerByte != null && Object.hasOwnProperty.call(message, "feerateSatPerByte"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.feerateSatPerByte);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.satPerVbyte);
            return writer;
        };

        /**
         * Encodes the specified EstimateFeeResponse message, length delimited. Does not implicitly {@link lnrpc.EstimateFeeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {lnrpc.IEstimateFeeResponse} message EstimateFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EstimateFeeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.EstimateFeeResponse} EstimateFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.EstimateFeeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.feeSat = reader.int64();
                        break;
                    }
                case 2: {
                        message.feerateSatPerByte = reader.int64();
                        break;
                    }
                case 3: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EstimateFeeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.EstimateFeeResponse} EstimateFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EstimateFeeResponse message.
         * @function verify
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EstimateFeeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.feeSat != null && message.hasOwnProperty("feeSat"))
                if (!$util.isInteger(message.feeSat) && !(message.feeSat && $util.isInteger(message.feeSat.low) && $util.isInteger(message.feeSat.high)))
                    return "feeSat: integer|Long expected";
            if (message.feerateSatPerByte != null && message.hasOwnProperty("feerateSatPerByte"))
                if (!$util.isInteger(message.feerateSatPerByte) && !(message.feerateSatPerByte && $util.isInteger(message.feerateSatPerByte.low) && $util.isInteger(message.feerateSatPerByte.high)))
                    return "feerateSatPerByte: integer|Long expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            return null;
        };

        /**
         * Creates an EstimateFeeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.EstimateFeeResponse} EstimateFeeResponse
         */
        EstimateFeeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.EstimateFeeResponse)
                return object;
            let message = new $root.lnrpc.EstimateFeeResponse();
            if (object.feeSat != null)
                if ($util.Long)
                    (message.feeSat = $util.Long.fromValue(object.feeSat)).unsigned = false;
                else if (typeof object.feeSat === "string")
                    message.feeSat = parseInt(object.feeSat, 10);
                else if (typeof object.feeSat === "number")
                    message.feeSat = object.feeSat;
                else if (typeof object.feeSat === "object")
                    message.feeSat = new $util.LongBits(object.feeSat.low >>> 0, object.feeSat.high >>> 0).toNumber();
            if (object.feerateSatPerByte != null)
                if ($util.Long)
                    (message.feerateSatPerByte = $util.Long.fromValue(object.feerateSatPerByte)).unsigned = false;
                else if (typeof object.feerateSatPerByte === "string")
                    message.feerateSatPerByte = parseInt(object.feerateSatPerByte, 10);
                else if (typeof object.feerateSatPerByte === "number")
                    message.feerateSatPerByte = object.feerateSatPerByte;
                else if (typeof object.feerateSatPerByte === "object")
                    message.feerateSatPerByte = new $util.LongBits(object.feerateSatPerByte.low >>> 0, object.feerateSatPerByte.high >>> 0).toNumber();
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an EstimateFeeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.EstimateFeeResponse
         * @static
         * @param {lnrpc.EstimateFeeResponse} message EstimateFeeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EstimateFeeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feerateSatPerByte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feerateSatPerByte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
            }
            if (message.feeSat != null && message.hasOwnProperty("feeSat"))
                if (typeof message.feeSat === "number")
                    object.feeSat = options.longs === String ? String(message.feeSat) : message.feeSat;
                else
                    object.feeSat = options.longs === String ? $util.Long.prototype.toString.call(message.feeSat) : options.longs === Number ? new $util.LongBits(message.feeSat.low >>> 0, message.feeSat.high >>> 0).toNumber() : message.feeSat;
            if (message.feerateSatPerByte != null && message.hasOwnProperty("feerateSatPerByte"))
                if (typeof message.feerateSatPerByte === "number")
                    object.feerateSatPerByte = options.longs === String ? String(message.feerateSatPerByte) : message.feerateSatPerByte;
                else
                    object.feerateSatPerByte = options.longs === String ? $util.Long.prototype.toString.call(message.feerateSatPerByte) : options.longs === Number ? new $util.LongBits(message.feerateSatPerByte.low >>> 0, message.feerateSatPerByte.high >>> 0).toNumber() : message.feerateSatPerByte;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            return object;
        };

        /**
         * Converts this EstimateFeeResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.EstimateFeeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EstimateFeeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EstimateFeeResponse;
    })();

    lnrpc.SendManyRequest = (function() {

        /**
         * Properties of a SendManyRequest.
         * @memberof lnrpc
         * @interface ISendManyRequest
         * @property {Object.<string,Long>|null} [AddrToAmount] SendManyRequest AddrToAmount
         * @property {number|null} [targetConf] SendManyRequest targetConf
         * @property {Long|null} [satPerVbyte] SendManyRequest satPerVbyte
         * @property {Long|null} [satPerByte] SendManyRequest satPerByte
         * @property {string|null} [label] SendManyRequest label
         * @property {number|null} [minConfs] SendManyRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] SendManyRequest spendUnconfirmed
         */

        /**
         * Constructs a new SendManyRequest.
         * @memberof lnrpc
         * @classdesc Represents a SendManyRequest.
         * @implements ISendManyRequest
         * @constructor
         * @param {lnrpc.ISendManyRequest=} [properties] Properties to set
         */
        function SendManyRequest(properties) {
            this.AddrToAmount = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendManyRequest AddrToAmount.
         * @member {Object.<string,Long>} AddrToAmount
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.AddrToAmount = $util.emptyObject;

        /**
         * SendManyRequest targetConf.
         * @member {number} targetConf
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.targetConf = 0;

        /**
         * SendManyRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SendManyRequest satPerByte.
         * @member {Long} satPerByte
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.satPerByte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendManyRequest label.
         * @member {string} label
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.label = "";

        /**
         * SendManyRequest minConfs.
         * @member {number} minConfs
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.minConfs = 0;

        /**
         * SendManyRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof lnrpc.SendManyRequest
         * @instance
         */
        SendManyRequest.prototype.spendUnconfirmed = false;

        /**
         * Creates a new SendManyRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {lnrpc.ISendManyRequest=} [properties] Properties to set
         * @returns {lnrpc.SendManyRequest} SendManyRequest instance
         */
        SendManyRequest.create = function create(properties) {
            return new SendManyRequest(properties);
        };

        /**
         * Encodes the specified SendManyRequest message. Does not implicitly {@link lnrpc.SendManyRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {lnrpc.ISendManyRequest} message SendManyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendManyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.AddrToAmount != null && Object.hasOwnProperty.call(message, "AddrToAmount"))
                for (let keys = Object.keys(message.AddrToAmount), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).int64(message.AddrToAmount[keys[i]]).ldelim();
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.targetConf);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.satPerVbyte);
            if (message.satPerByte != null && Object.hasOwnProperty.call(message, "satPerByte"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.satPerByte);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.label);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.spendUnconfirmed);
            return writer;
        };

        /**
         * Encodes the specified SendManyRequest message, length delimited. Does not implicitly {@link lnrpc.SendManyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {lnrpc.ISendManyRequest} message SendManyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendManyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendManyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendManyRequest} SendManyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendManyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendManyRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.AddrToAmount === $util.emptyObject)
                            message.AddrToAmount = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.int64();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.AddrToAmount[key] = value;
                        break;
                    }
                case 3: {
                        message.targetConf = reader.int32();
                        break;
                    }
                case 4: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                case 5: {
                        message.satPerByte = reader.int64();
                        break;
                    }
                case 6: {
                        message.label = reader.string();
                        break;
                    }
                case 7: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 8: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendManyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendManyRequest} SendManyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendManyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendManyRequest message.
         * @function verify
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendManyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.AddrToAmount != null && message.hasOwnProperty("AddrToAmount")) {
                if (!$util.isObject(message.AddrToAmount))
                    return "AddrToAmount: object expected";
                let key = Object.keys(message.AddrToAmount);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isInteger(message.AddrToAmount[key[i]]) && !(message.AddrToAmount[key[i]] && $util.isInteger(message.AddrToAmount[key[i]].low) && $util.isInteger(message.AddrToAmount[key[i]].high)))
                        return "AddrToAmount: integer|Long{k:string} expected";
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (!$util.isInteger(message.satPerByte) && !(message.satPerByte && $util.isInteger(message.satPerByte.low) && $util.isInteger(message.satPerByte.high)))
                    return "satPerByte: integer|Long expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            return null;
        };

        /**
         * Creates a SendManyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendManyRequest} SendManyRequest
         */
        SendManyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendManyRequest)
                return object;
            let message = new $root.lnrpc.SendManyRequest();
            if (object.AddrToAmount) {
                if (typeof object.AddrToAmount !== "object")
                    throw TypeError(".lnrpc.SendManyRequest.AddrToAmount: object expected");
                message.AddrToAmount = {};
                for (let keys = Object.keys(object.AddrToAmount), i = 0; i < keys.length; ++i)
                    if ($util.Long)
                        (message.AddrToAmount[keys[i]] = $util.Long.fromValue(object.AddrToAmount[keys[i]])).unsigned = false;
                    else if (typeof object.AddrToAmount[keys[i]] === "string")
                        message.AddrToAmount[keys[i]] = parseInt(object.AddrToAmount[keys[i]], 10);
                    else if (typeof object.AddrToAmount[keys[i]] === "number")
                        message.AddrToAmount[keys[i]] = object.AddrToAmount[keys[i]];
                    else if (typeof object.AddrToAmount[keys[i]] === "object")
                        message.AddrToAmount[keys[i]] = new $util.LongBits(object.AddrToAmount[keys[i]].low >>> 0, object.AddrToAmount[keys[i]].high >>> 0).toNumber();
            }
            if (object.targetConf != null)
                message.targetConf = object.targetConf | 0;
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            if (object.satPerByte != null)
                if ($util.Long)
                    (message.satPerByte = $util.Long.fromValue(object.satPerByte)).unsigned = false;
                else if (typeof object.satPerByte === "string")
                    message.satPerByte = parseInt(object.satPerByte, 10);
                else if (typeof object.satPerByte === "number")
                    message.satPerByte = object.satPerByte;
                else if (typeof object.satPerByte === "object")
                    message.satPerByte = new $util.LongBits(object.satPerByte.low >>> 0, object.satPerByte.high >>> 0).toNumber();
            if (object.label != null)
                message.label = String(object.label);
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            return message;
        };

        /**
         * Creates a plain object from a SendManyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendManyRequest
         * @static
         * @param {lnrpc.SendManyRequest} message SendManyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendManyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.AddrToAmount = {};
            if (options.defaults) {
                object.targetConf = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerByte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerByte = options.longs === String ? "0" : 0;
                object.label = "";
                object.minConfs = 0;
                object.spendUnconfirmed = false;
            }
            let keys2;
            if (message.AddrToAmount && (keys2 = Object.keys(message.AddrToAmount)).length) {
                object.AddrToAmount = {};
                for (let j = 0; j < keys2.length; ++j)
                    if (typeof message.AddrToAmount[keys2[j]] === "number")
                        object.AddrToAmount[keys2[j]] = options.longs === String ? String(message.AddrToAmount[keys2[j]]) : message.AddrToAmount[keys2[j]];
                    else
                        object.AddrToAmount[keys2[j]] = options.longs === String ? $util.Long.prototype.toString.call(message.AddrToAmount[keys2[j]]) : options.longs === Number ? new $util.LongBits(message.AddrToAmount[keys2[j]].low >>> 0, message.AddrToAmount[keys2[j]].high >>> 0).toNumber() : message.AddrToAmount[keys2[j]];
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (typeof message.satPerByte === "number")
                    object.satPerByte = options.longs === String ? String(message.satPerByte) : message.satPerByte;
                else
                    object.satPerByte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerByte) : options.longs === Number ? new $util.LongBits(message.satPerByte.low >>> 0, message.satPerByte.high >>> 0).toNumber() : message.satPerByte;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            return object;
        };

        /**
         * Converts this SendManyRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SendManyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendManyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendManyRequest;
    })();

    lnrpc.SendManyResponse = (function() {

        /**
         * Properties of a SendManyResponse.
         * @memberof lnrpc
         * @interface ISendManyResponse
         * @property {string|null} [txid] SendManyResponse txid
         */

        /**
         * Constructs a new SendManyResponse.
         * @memberof lnrpc
         * @classdesc Represents a SendManyResponse.
         * @implements ISendManyResponse
         * @constructor
         * @param {lnrpc.ISendManyResponse=} [properties] Properties to set
         */
        function SendManyResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendManyResponse txid.
         * @member {string} txid
         * @memberof lnrpc.SendManyResponse
         * @instance
         */
        SendManyResponse.prototype.txid = "";

        /**
         * Creates a new SendManyResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {lnrpc.ISendManyResponse=} [properties] Properties to set
         * @returns {lnrpc.SendManyResponse} SendManyResponse instance
         */
        SendManyResponse.create = function create(properties) {
            return new SendManyResponse(properties);
        };

        /**
         * Encodes the specified SendManyResponse message. Does not implicitly {@link lnrpc.SendManyResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {lnrpc.ISendManyResponse} message SendManyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendManyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txid != null && Object.hasOwnProperty.call(message, "txid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.txid);
            return writer;
        };

        /**
         * Encodes the specified SendManyResponse message, length delimited. Does not implicitly {@link lnrpc.SendManyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {lnrpc.ISendManyResponse} message SendManyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendManyResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendManyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendManyResponse} SendManyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendManyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendManyResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendManyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendManyResponse} SendManyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendManyResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendManyResponse message.
         * @function verify
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendManyResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txid != null && message.hasOwnProperty("txid"))
                if (!$util.isString(message.txid))
                    return "txid: string expected";
            return null;
        };

        /**
         * Creates a SendManyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendManyResponse} SendManyResponse
         */
        SendManyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendManyResponse)
                return object;
            let message = new $root.lnrpc.SendManyResponse();
            if (object.txid != null)
                message.txid = String(object.txid);
            return message;
        };

        /**
         * Creates a plain object from a SendManyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendManyResponse
         * @static
         * @param {lnrpc.SendManyResponse} message SendManyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendManyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.txid = "";
            if (message.txid != null && message.hasOwnProperty("txid"))
                object.txid = message.txid;
            return object;
        };

        /**
         * Converts this SendManyResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.SendManyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendManyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendManyResponse;
    })();

    lnrpc.SendCoinsRequest = (function() {

        /**
         * Properties of a SendCoinsRequest.
         * @memberof lnrpc
         * @interface ISendCoinsRequest
         * @property {string|null} [addr] SendCoinsRequest addr
         * @property {Long|null} [amount] SendCoinsRequest amount
         * @property {number|null} [targetConf] SendCoinsRequest targetConf
         * @property {Long|null} [satPerVbyte] SendCoinsRequest satPerVbyte
         * @property {Long|null} [satPerByte] SendCoinsRequest satPerByte
         * @property {boolean|null} [sendAll] SendCoinsRequest sendAll
         * @property {string|null} [label] SendCoinsRequest label
         * @property {number|null} [minConfs] SendCoinsRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] SendCoinsRequest spendUnconfirmed
         */

        /**
         * Constructs a new SendCoinsRequest.
         * @memberof lnrpc
         * @classdesc Represents a SendCoinsRequest.
         * @implements ISendCoinsRequest
         * @constructor
         * @param {lnrpc.ISendCoinsRequest=} [properties] Properties to set
         */
        function SendCoinsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendCoinsRequest addr.
         * @member {string} addr
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.addr = "";

        /**
         * SendCoinsRequest amount.
         * @member {Long} amount
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendCoinsRequest targetConf.
         * @member {number} targetConf
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.targetConf = 0;

        /**
         * SendCoinsRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SendCoinsRequest satPerByte.
         * @member {Long} satPerByte
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.satPerByte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendCoinsRequest sendAll.
         * @member {boolean} sendAll
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.sendAll = false;

        /**
         * SendCoinsRequest label.
         * @member {string} label
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.label = "";

        /**
         * SendCoinsRequest minConfs.
         * @member {number} minConfs
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.minConfs = 0;

        /**
         * SendCoinsRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         */
        SendCoinsRequest.prototype.spendUnconfirmed = false;

        /**
         * Creates a new SendCoinsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {lnrpc.ISendCoinsRequest=} [properties] Properties to set
         * @returns {lnrpc.SendCoinsRequest} SendCoinsRequest instance
         */
        SendCoinsRequest.create = function create(properties) {
            return new SendCoinsRequest(properties);
        };

        /**
         * Encodes the specified SendCoinsRequest message. Does not implicitly {@link lnrpc.SendCoinsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {lnrpc.ISendCoinsRequest} message SendCoinsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCoinsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addr != null && Object.hasOwnProperty.call(message, "addr"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.addr);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amount);
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.targetConf);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.satPerVbyte);
            if (message.satPerByte != null && Object.hasOwnProperty.call(message, "satPerByte"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.satPerByte);
            if (message.sendAll != null && Object.hasOwnProperty.call(message, "sendAll"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.sendAll);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.label);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.spendUnconfirmed);
            return writer;
        };

        /**
         * Encodes the specified SendCoinsRequest message, length delimited. Does not implicitly {@link lnrpc.SendCoinsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {lnrpc.ISendCoinsRequest} message SendCoinsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCoinsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendCoinsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendCoinsRequest} SendCoinsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCoinsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendCoinsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.addr = reader.string();
                        break;
                    }
                case 2: {
                        message.amount = reader.int64();
                        break;
                    }
                case 3: {
                        message.targetConf = reader.int32();
                        break;
                    }
                case 4: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                case 5: {
                        message.satPerByte = reader.int64();
                        break;
                    }
                case 6: {
                        message.sendAll = reader.bool();
                        break;
                    }
                case 7: {
                        message.label = reader.string();
                        break;
                    }
                case 8: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 9: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendCoinsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendCoinsRequest} SendCoinsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCoinsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendCoinsRequest message.
         * @function verify
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendCoinsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.addr != null && message.hasOwnProperty("addr"))
                if (!$util.isString(message.addr))
                    return "addr: string expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (!$util.isInteger(message.satPerByte) && !(message.satPerByte && $util.isInteger(message.satPerByte.low) && $util.isInteger(message.satPerByte.high)))
                    return "satPerByte: integer|Long expected";
            if (message.sendAll != null && message.hasOwnProperty("sendAll"))
                if (typeof message.sendAll !== "boolean")
                    return "sendAll: boolean expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            return null;
        };

        /**
         * Creates a SendCoinsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendCoinsRequest} SendCoinsRequest
         */
        SendCoinsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendCoinsRequest)
                return object;
            let message = new $root.lnrpc.SendCoinsRequest();
            if (object.addr != null)
                message.addr = String(object.addr);
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            if (object.targetConf != null)
                message.targetConf = object.targetConf | 0;
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            if (object.satPerByte != null)
                if ($util.Long)
                    (message.satPerByte = $util.Long.fromValue(object.satPerByte)).unsigned = false;
                else if (typeof object.satPerByte === "string")
                    message.satPerByte = parseInt(object.satPerByte, 10);
                else if (typeof object.satPerByte === "number")
                    message.satPerByte = object.satPerByte;
                else if (typeof object.satPerByte === "object")
                    message.satPerByte = new $util.LongBits(object.satPerByte.low >>> 0, object.satPerByte.high >>> 0).toNumber();
            if (object.sendAll != null)
                message.sendAll = Boolean(object.sendAll);
            if (object.label != null)
                message.label = String(object.label);
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            return message;
        };

        /**
         * Creates a plain object from a SendCoinsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendCoinsRequest
         * @static
         * @param {lnrpc.SendCoinsRequest} message SendCoinsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendCoinsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.addr = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
                object.targetConf = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerByte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerByte = options.longs === String ? "0" : 0;
                object.sendAll = false;
                object.label = "";
                object.minConfs = 0;
                object.spendUnconfirmed = false;
            }
            if (message.addr != null && message.hasOwnProperty("addr"))
                object.addr = message.addr;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (typeof message.satPerByte === "number")
                    object.satPerByte = options.longs === String ? String(message.satPerByte) : message.satPerByte;
                else
                    object.satPerByte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerByte) : options.longs === Number ? new $util.LongBits(message.satPerByte.low >>> 0, message.satPerByte.high >>> 0).toNumber() : message.satPerByte;
            if (message.sendAll != null && message.hasOwnProperty("sendAll"))
                object.sendAll = message.sendAll;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            return object;
        };

        /**
         * Converts this SendCoinsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SendCoinsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendCoinsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendCoinsRequest;
    })();

    lnrpc.SendCoinsResponse = (function() {

        /**
         * Properties of a SendCoinsResponse.
         * @memberof lnrpc
         * @interface ISendCoinsResponse
         * @property {string|null} [txid] SendCoinsResponse txid
         */

        /**
         * Constructs a new SendCoinsResponse.
         * @memberof lnrpc
         * @classdesc Represents a SendCoinsResponse.
         * @implements ISendCoinsResponse
         * @constructor
         * @param {lnrpc.ISendCoinsResponse=} [properties] Properties to set
         */
        function SendCoinsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendCoinsResponse txid.
         * @member {string} txid
         * @memberof lnrpc.SendCoinsResponse
         * @instance
         */
        SendCoinsResponse.prototype.txid = "";

        /**
         * Creates a new SendCoinsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {lnrpc.ISendCoinsResponse=} [properties] Properties to set
         * @returns {lnrpc.SendCoinsResponse} SendCoinsResponse instance
         */
        SendCoinsResponse.create = function create(properties) {
            return new SendCoinsResponse(properties);
        };

        /**
         * Encodes the specified SendCoinsResponse message. Does not implicitly {@link lnrpc.SendCoinsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {lnrpc.ISendCoinsResponse} message SendCoinsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCoinsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txid != null && Object.hasOwnProperty.call(message, "txid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.txid);
            return writer;
        };

        /**
         * Encodes the specified SendCoinsResponse message, length delimited. Does not implicitly {@link lnrpc.SendCoinsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {lnrpc.ISendCoinsResponse} message SendCoinsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendCoinsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendCoinsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SendCoinsResponse} SendCoinsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCoinsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SendCoinsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendCoinsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SendCoinsResponse} SendCoinsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendCoinsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendCoinsResponse message.
         * @function verify
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendCoinsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txid != null && message.hasOwnProperty("txid"))
                if (!$util.isString(message.txid))
                    return "txid: string expected";
            return null;
        };

        /**
         * Creates a SendCoinsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SendCoinsResponse} SendCoinsResponse
         */
        SendCoinsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SendCoinsResponse)
                return object;
            let message = new $root.lnrpc.SendCoinsResponse();
            if (object.txid != null)
                message.txid = String(object.txid);
            return message;
        };

        /**
         * Creates a plain object from a SendCoinsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SendCoinsResponse
         * @static
         * @param {lnrpc.SendCoinsResponse} message SendCoinsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendCoinsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.txid = "";
            if (message.txid != null && message.hasOwnProperty("txid"))
                object.txid = message.txid;
            return object;
        };

        /**
         * Converts this SendCoinsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.SendCoinsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendCoinsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendCoinsResponse;
    })();

    lnrpc.ListUnspentRequest = (function() {

        /**
         * Properties of a ListUnspentRequest.
         * @memberof lnrpc
         * @interface IListUnspentRequest
         * @property {number|null} [minConfs] ListUnspentRequest minConfs
         * @property {number|null} [maxConfs] ListUnspentRequest maxConfs
         * @property {string|null} [account] ListUnspentRequest account
         */

        /**
         * Constructs a new ListUnspentRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListUnspentRequest.
         * @implements IListUnspentRequest
         * @constructor
         * @param {lnrpc.IListUnspentRequest=} [properties] Properties to set
         */
        function ListUnspentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListUnspentRequest minConfs.
         * @member {number} minConfs
         * @memberof lnrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.minConfs = 0;

        /**
         * ListUnspentRequest maxConfs.
         * @member {number} maxConfs
         * @memberof lnrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.maxConfs = 0;

        /**
         * ListUnspentRequest account.
         * @member {string} account
         * @memberof lnrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.account = "";

        /**
         * Creates a new ListUnspentRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {lnrpc.IListUnspentRequest=} [properties] Properties to set
         * @returns {lnrpc.ListUnspentRequest} ListUnspentRequest instance
         */
        ListUnspentRequest.create = function create(properties) {
            return new ListUnspentRequest(properties);
        };

        /**
         * Encodes the specified ListUnspentRequest message. Does not implicitly {@link lnrpc.ListUnspentRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {lnrpc.IListUnspentRequest} message ListUnspentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minConfs);
            if (message.maxConfs != null && Object.hasOwnProperty.call(message, "maxConfs"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxConfs);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.account);
            return writer;
        };

        /**
         * Encodes the specified ListUnspentRequest message, length delimited. Does not implicitly {@link lnrpc.ListUnspentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {lnrpc.IListUnspentRequest} message ListUnspentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListUnspentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListUnspentRequest} ListUnspentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListUnspentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 2: {
                        message.maxConfs = reader.int32();
                        break;
                    }
                case 3: {
                        message.account = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListUnspentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListUnspentRequest} ListUnspentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListUnspentRequest message.
         * @function verify
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListUnspentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.maxConfs != null && message.hasOwnProperty("maxConfs"))
                if (!$util.isInteger(message.maxConfs))
                    return "maxConfs: integer expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            return null;
        };

        /**
         * Creates a ListUnspentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListUnspentRequest} ListUnspentRequest
         */
        ListUnspentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListUnspentRequest)
                return object;
            let message = new $root.lnrpc.ListUnspentRequest();
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.maxConfs != null)
                message.maxConfs = object.maxConfs | 0;
            if (object.account != null)
                message.account = String(object.account);
            return message;
        };

        /**
         * Creates a plain object from a ListUnspentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListUnspentRequest
         * @static
         * @param {lnrpc.ListUnspentRequest} message ListUnspentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListUnspentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.minConfs = 0;
                object.maxConfs = 0;
                object.account = "";
            }
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.maxConfs != null && message.hasOwnProperty("maxConfs"))
                object.maxConfs = message.maxConfs;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            return object;
        };

        /**
         * Converts this ListUnspentRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListUnspentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListUnspentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListUnspentRequest;
    })();

    lnrpc.ListUnspentResponse = (function() {

        /**
         * Properties of a ListUnspentResponse.
         * @memberof lnrpc
         * @interface IListUnspentResponse
         * @property {Array.<lnrpc.IUtxo>|null} [utxos] ListUnspentResponse utxos
         */

        /**
         * Constructs a new ListUnspentResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListUnspentResponse.
         * @implements IListUnspentResponse
         * @constructor
         * @param {lnrpc.IListUnspentResponse=} [properties] Properties to set
         */
        function ListUnspentResponse(properties) {
            this.utxos = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListUnspentResponse utxos.
         * @member {Array.<lnrpc.IUtxo>} utxos
         * @memberof lnrpc.ListUnspentResponse
         * @instance
         */
        ListUnspentResponse.prototype.utxos = $util.emptyArray;

        /**
         * Creates a new ListUnspentResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {lnrpc.IListUnspentResponse=} [properties] Properties to set
         * @returns {lnrpc.ListUnspentResponse} ListUnspentResponse instance
         */
        ListUnspentResponse.create = function create(properties) {
            return new ListUnspentResponse(properties);
        };

        /**
         * Encodes the specified ListUnspentResponse message. Does not implicitly {@link lnrpc.ListUnspentResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {lnrpc.IListUnspentResponse} message ListUnspentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.utxos != null && message.utxos.length)
                for (let i = 0; i < message.utxos.length; ++i)
                    $root.lnrpc.Utxo.encode(message.utxos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListUnspentResponse message, length delimited. Does not implicitly {@link lnrpc.ListUnspentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {lnrpc.IListUnspentResponse} message ListUnspentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListUnspentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListUnspentResponse} ListUnspentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListUnspentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.utxos && message.utxos.length))
                            message.utxos = [];
                        message.utxos.push($root.lnrpc.Utxo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListUnspentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListUnspentResponse} ListUnspentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListUnspentResponse message.
         * @function verify
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListUnspentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.utxos != null && message.hasOwnProperty("utxos")) {
                if (!Array.isArray(message.utxos))
                    return "utxos: array expected";
                for (let i = 0; i < message.utxos.length; ++i) {
                    let error = $root.lnrpc.Utxo.verify(message.utxos[i]);
                    if (error)
                        return "utxos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListUnspentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListUnspentResponse} ListUnspentResponse
         */
        ListUnspentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListUnspentResponse)
                return object;
            let message = new $root.lnrpc.ListUnspentResponse();
            if (object.utxos) {
                if (!Array.isArray(object.utxos))
                    throw TypeError(".lnrpc.ListUnspentResponse.utxos: array expected");
                message.utxos = [];
                for (let i = 0; i < object.utxos.length; ++i) {
                    if (typeof object.utxos[i] !== "object")
                        throw TypeError(".lnrpc.ListUnspentResponse.utxos: object expected");
                    message.utxos[i] = $root.lnrpc.Utxo.fromObject(object.utxos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListUnspentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListUnspentResponse
         * @static
         * @param {lnrpc.ListUnspentResponse} message ListUnspentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListUnspentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.utxos = [];
            if (message.utxos && message.utxos.length) {
                object.utxos = [];
                for (let j = 0; j < message.utxos.length; ++j)
                    object.utxos[j] = $root.lnrpc.Utxo.toObject(message.utxos[j], options);
            }
            return object;
        };

        /**
         * Converts this ListUnspentResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListUnspentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListUnspentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListUnspentResponse;
    })();

    /**
     * AddressType enum.
     * @name lnrpc.AddressType
     * @enum {number}
     * @property {number} WITNESS_PUBKEY_HASH=0 WITNESS_PUBKEY_HASH value
     * @property {number} NESTED_PUBKEY_HASH=1 NESTED_PUBKEY_HASH value
     * @property {number} UNUSED_WITNESS_PUBKEY_HASH=2 UNUSED_WITNESS_PUBKEY_HASH value
     * @property {number} UNUSED_NESTED_PUBKEY_HASH=3 UNUSED_NESTED_PUBKEY_HASH value
     * @property {number} TAPROOT_PUBKEY=4 TAPROOT_PUBKEY value
     * @property {number} UNUSED_TAPROOT_PUBKEY=5 UNUSED_TAPROOT_PUBKEY value
     */
    lnrpc.AddressType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "WITNESS_PUBKEY_HASH"] = 0;
        values[valuesById[1] = "NESTED_PUBKEY_HASH"] = 1;
        values[valuesById[2] = "UNUSED_WITNESS_PUBKEY_HASH"] = 2;
        values[valuesById[3] = "UNUSED_NESTED_PUBKEY_HASH"] = 3;
        values[valuesById[4] = "TAPROOT_PUBKEY"] = 4;
        values[valuesById[5] = "UNUSED_TAPROOT_PUBKEY"] = 5;
        return values;
    })();

    lnrpc.NewAddressRequest = (function() {

        /**
         * Properties of a NewAddressRequest.
         * @memberof lnrpc
         * @interface INewAddressRequest
         * @property {lnrpc.AddressType|null} [type] NewAddressRequest type
         * @property {string|null} [account] NewAddressRequest account
         */

        /**
         * Constructs a new NewAddressRequest.
         * @memberof lnrpc
         * @classdesc Represents a NewAddressRequest.
         * @implements INewAddressRequest
         * @constructor
         * @param {lnrpc.INewAddressRequest=} [properties] Properties to set
         */
        function NewAddressRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewAddressRequest type.
         * @member {lnrpc.AddressType} type
         * @memberof lnrpc.NewAddressRequest
         * @instance
         */
        NewAddressRequest.prototype.type = 0;

        /**
         * NewAddressRequest account.
         * @member {string} account
         * @memberof lnrpc.NewAddressRequest
         * @instance
         */
        NewAddressRequest.prototype.account = "";

        /**
         * Creates a new NewAddressRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {lnrpc.INewAddressRequest=} [properties] Properties to set
         * @returns {lnrpc.NewAddressRequest} NewAddressRequest instance
         */
        NewAddressRequest.create = function create(properties) {
            return new NewAddressRequest(properties);
        };

        /**
         * Encodes the specified NewAddressRequest message. Does not implicitly {@link lnrpc.NewAddressRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {lnrpc.INewAddressRequest} message NewAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewAddressRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.account);
            return writer;
        };

        /**
         * Encodes the specified NewAddressRequest message, length delimited. Does not implicitly {@link lnrpc.NewAddressRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {lnrpc.INewAddressRequest} message NewAddressRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewAddressRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NewAddressRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NewAddressRequest} NewAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewAddressRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NewAddressRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.account = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NewAddressRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NewAddressRequest} NewAddressRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewAddressRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NewAddressRequest message.
         * @function verify
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NewAddressRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            return null;
        };

        /**
         * Creates a NewAddressRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NewAddressRequest} NewAddressRequest
         */
        NewAddressRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NewAddressRequest)
                return object;
            let message = new $root.lnrpc.NewAddressRequest();
            switch (object.type) {
            case "WITNESS_PUBKEY_HASH":
            case 0:
                message.type = 0;
                break;
            case "NESTED_PUBKEY_HASH":
            case 1:
                message.type = 1;
                break;
            case "UNUSED_WITNESS_PUBKEY_HASH":
            case 2:
                message.type = 2;
                break;
            case "UNUSED_NESTED_PUBKEY_HASH":
            case 3:
                message.type = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.type = 4;
                break;
            case "UNUSED_TAPROOT_PUBKEY":
            case 5:
                message.type = 5;
                break;
            }
            if (object.account != null)
                message.account = String(object.account);
            return message;
        };

        /**
         * Creates a plain object from a NewAddressRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NewAddressRequest
         * @static
         * @param {lnrpc.NewAddressRequest} message NewAddressRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NewAddressRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "WITNESS_PUBKEY_HASH" : 0;
                object.account = "";
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.lnrpc.AddressType[message.type] : message.type;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            return object;
        };

        /**
         * Converts this NewAddressRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.NewAddressRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NewAddressRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NewAddressRequest;
    })();

    lnrpc.NewAddressResponse = (function() {

        /**
         * Properties of a NewAddressResponse.
         * @memberof lnrpc
         * @interface INewAddressResponse
         * @property {string|null} [address] NewAddressResponse address
         */

        /**
         * Constructs a new NewAddressResponse.
         * @memberof lnrpc
         * @classdesc Represents a NewAddressResponse.
         * @implements INewAddressResponse
         * @constructor
         * @param {lnrpc.INewAddressResponse=} [properties] Properties to set
         */
        function NewAddressResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewAddressResponse address.
         * @member {string} address
         * @memberof lnrpc.NewAddressResponse
         * @instance
         */
        NewAddressResponse.prototype.address = "";

        /**
         * Creates a new NewAddressResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {lnrpc.INewAddressResponse=} [properties] Properties to set
         * @returns {lnrpc.NewAddressResponse} NewAddressResponse instance
         */
        NewAddressResponse.create = function create(properties) {
            return new NewAddressResponse(properties);
        };

        /**
         * Encodes the specified NewAddressResponse message. Does not implicitly {@link lnrpc.NewAddressResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {lnrpc.INewAddressResponse} message NewAddressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewAddressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
            return writer;
        };

        /**
         * Encodes the specified NewAddressResponse message, length delimited. Does not implicitly {@link lnrpc.NewAddressResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {lnrpc.INewAddressResponse} message NewAddressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewAddressResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NewAddressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NewAddressResponse} NewAddressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewAddressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NewAddressResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NewAddressResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NewAddressResponse} NewAddressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewAddressResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NewAddressResponse message.
         * @function verify
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NewAddressResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            return null;
        };

        /**
         * Creates a NewAddressResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NewAddressResponse} NewAddressResponse
         */
        NewAddressResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NewAddressResponse)
                return object;
            let message = new $root.lnrpc.NewAddressResponse();
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        /**
         * Creates a plain object from a NewAddressResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NewAddressResponse
         * @static
         * @param {lnrpc.NewAddressResponse} message NewAddressResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NewAddressResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.address = "";
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            return object;
        };

        /**
         * Converts this NewAddressResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.NewAddressResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NewAddressResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NewAddressResponse;
    })();

    lnrpc.SignMessageRequest = (function() {

        /**
         * Properties of a SignMessageRequest.
         * @memberof lnrpc
         * @interface ISignMessageRequest
         * @property {Uint8Array|null} [msg] SignMessageRequest msg
         * @property {boolean|null} [singleHash] SignMessageRequest singleHash
         */

        /**
         * Constructs a new SignMessageRequest.
         * @memberof lnrpc
         * @classdesc Represents a SignMessageRequest.
         * @implements ISignMessageRequest
         * @constructor
         * @param {lnrpc.ISignMessageRequest=} [properties] Properties to set
         */
        function SignMessageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignMessageRequest msg.
         * @member {Uint8Array} msg
         * @memberof lnrpc.SignMessageRequest
         * @instance
         */
        SignMessageRequest.prototype.msg = $util.newBuffer([]);

        /**
         * SignMessageRequest singleHash.
         * @member {boolean} singleHash
         * @memberof lnrpc.SignMessageRequest
         * @instance
         */
        SignMessageRequest.prototype.singleHash = false;

        /**
         * Creates a new SignMessageRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {lnrpc.ISignMessageRequest=} [properties] Properties to set
         * @returns {lnrpc.SignMessageRequest} SignMessageRequest instance
         */
        SignMessageRequest.create = function create(properties) {
            return new SignMessageRequest(properties);
        };

        /**
         * Encodes the specified SignMessageRequest message. Does not implicitly {@link lnrpc.SignMessageRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {lnrpc.ISignMessageRequest} message SignMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.msg);
            if (message.singleHash != null && Object.hasOwnProperty.call(message, "singleHash"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.singleHash);
            return writer;
        };

        /**
         * Encodes the specified SignMessageRequest message, length delimited. Does not implicitly {@link lnrpc.SignMessageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {lnrpc.ISignMessageRequest} message SignMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignMessageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SignMessageRequest} SignMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SignMessageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = reader.bytes();
                        break;
                    }
                case 2: {
                        message.singleHash = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignMessageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SignMessageRequest} SignMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignMessageRequest message.
         * @function verify
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignMessageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!(message.msg && typeof message.msg.length === "number" || $util.isString(message.msg)))
                    return "msg: buffer expected";
            if (message.singleHash != null && message.hasOwnProperty("singleHash"))
                if (typeof message.singleHash !== "boolean")
                    return "singleHash: boolean expected";
            return null;
        };

        /**
         * Creates a SignMessageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SignMessageRequest} SignMessageRequest
         */
        SignMessageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SignMessageRequest)
                return object;
            let message = new $root.lnrpc.SignMessageRequest();
            if (object.msg != null)
                if (typeof object.msg === "string")
                    $util.base64.decode(object.msg, message.msg = $util.newBuffer($util.base64.length(object.msg)), 0);
                else if (object.msg.length)
                    message.msg = object.msg;
            if (object.singleHash != null)
                message.singleHash = Boolean(object.singleHash);
            return message;
        };

        /**
         * Creates a plain object from a SignMessageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SignMessageRequest
         * @static
         * @param {lnrpc.SignMessageRequest} message SignMessageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignMessageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.msg = "";
                else {
                    object.msg = [];
                    if (options.bytes !== Array)
                        object.msg = $util.newBuffer(object.msg);
                }
                object.singleHash = false;
            }
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.bytes === String ? $util.base64.encode(message.msg, 0, message.msg.length) : options.bytes === Array ? Array.prototype.slice.call(message.msg) : message.msg;
            if (message.singleHash != null && message.hasOwnProperty("singleHash"))
                object.singleHash = message.singleHash;
            return object;
        };

        /**
         * Converts this SignMessageRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SignMessageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignMessageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignMessageRequest;
    })();

    lnrpc.SignMessageResponse = (function() {

        /**
         * Properties of a SignMessageResponse.
         * @memberof lnrpc
         * @interface ISignMessageResponse
         * @property {string|null} [signature] SignMessageResponse signature
         */

        /**
         * Constructs a new SignMessageResponse.
         * @memberof lnrpc
         * @classdesc Represents a SignMessageResponse.
         * @implements ISignMessageResponse
         * @constructor
         * @param {lnrpc.ISignMessageResponse=} [properties] Properties to set
         */
        function SignMessageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignMessageResponse signature.
         * @member {string} signature
         * @memberof lnrpc.SignMessageResponse
         * @instance
         */
        SignMessageResponse.prototype.signature = "";

        /**
         * Creates a new SignMessageResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {lnrpc.ISignMessageResponse=} [properties] Properties to set
         * @returns {lnrpc.SignMessageResponse} SignMessageResponse instance
         */
        SignMessageResponse.create = function create(properties) {
            return new SignMessageResponse(properties);
        };

        /**
         * Encodes the specified SignMessageResponse message. Does not implicitly {@link lnrpc.SignMessageResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {lnrpc.ISignMessageResponse} message SignMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.signature);
            return writer;
        };

        /**
         * Encodes the specified SignMessageResponse message, length delimited. Does not implicitly {@link lnrpc.SignMessageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {lnrpc.ISignMessageResponse} message SignMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignMessageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SignMessageResponse} SignMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SignMessageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signature = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignMessageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SignMessageResponse} SignMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignMessageResponse message.
         * @function verify
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!$util.isString(message.signature))
                    return "signature: string expected";
            return null;
        };

        /**
         * Creates a SignMessageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SignMessageResponse} SignMessageResponse
         */
        SignMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SignMessageResponse)
                return object;
            let message = new $root.lnrpc.SignMessageResponse();
            if (object.signature != null)
                message.signature = String(object.signature);
            return message;
        };

        /**
         * Creates a plain object from a SignMessageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SignMessageResponse
         * @static
         * @param {lnrpc.SignMessageResponse} message SignMessageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignMessageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.signature = "";
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = message.signature;
            return object;
        };

        /**
         * Converts this SignMessageResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.SignMessageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignMessageResponse;
    })();

    lnrpc.VerifyMessageRequest = (function() {

        /**
         * Properties of a VerifyMessageRequest.
         * @memberof lnrpc
         * @interface IVerifyMessageRequest
         * @property {Uint8Array|null} [msg] VerifyMessageRequest msg
         * @property {string|null} [signature] VerifyMessageRequest signature
         */

        /**
         * Constructs a new VerifyMessageRequest.
         * @memberof lnrpc
         * @classdesc Represents a VerifyMessageRequest.
         * @implements IVerifyMessageRequest
         * @constructor
         * @param {lnrpc.IVerifyMessageRequest=} [properties] Properties to set
         */
        function VerifyMessageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VerifyMessageRequest msg.
         * @member {Uint8Array} msg
         * @memberof lnrpc.VerifyMessageRequest
         * @instance
         */
        VerifyMessageRequest.prototype.msg = $util.newBuffer([]);

        /**
         * VerifyMessageRequest signature.
         * @member {string} signature
         * @memberof lnrpc.VerifyMessageRequest
         * @instance
         */
        VerifyMessageRequest.prototype.signature = "";

        /**
         * Creates a new VerifyMessageRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {lnrpc.IVerifyMessageRequest=} [properties] Properties to set
         * @returns {lnrpc.VerifyMessageRequest} VerifyMessageRequest instance
         */
        VerifyMessageRequest.create = function create(properties) {
            return new VerifyMessageRequest(properties);
        };

        /**
         * Encodes the specified VerifyMessageRequest message. Does not implicitly {@link lnrpc.VerifyMessageRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {lnrpc.IVerifyMessageRequest} message VerifyMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.msg);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.signature);
            return writer;
        };

        /**
         * Encodes the specified VerifyMessageRequest message, length delimited. Does not implicitly {@link lnrpc.VerifyMessageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {lnrpc.IVerifyMessageRequest} message VerifyMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyMessageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.VerifyMessageRequest} VerifyMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.VerifyMessageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = reader.bytes();
                        break;
                    }
                case 2: {
                        message.signature = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyMessageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.VerifyMessageRequest} VerifyMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyMessageRequest message.
         * @function verify
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyMessageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!(message.msg && typeof message.msg.length === "number" || $util.isString(message.msg)))
                    return "msg: buffer expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!$util.isString(message.signature))
                    return "signature: string expected";
            return null;
        };

        /**
         * Creates a VerifyMessageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.VerifyMessageRequest} VerifyMessageRequest
         */
        VerifyMessageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.VerifyMessageRequest)
                return object;
            let message = new $root.lnrpc.VerifyMessageRequest();
            if (object.msg != null)
                if (typeof object.msg === "string")
                    $util.base64.decode(object.msg, message.msg = $util.newBuffer($util.base64.length(object.msg)), 0);
                else if (object.msg.length)
                    message.msg = object.msg;
            if (object.signature != null)
                message.signature = String(object.signature);
            return message;
        };

        /**
         * Creates a plain object from a VerifyMessageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.VerifyMessageRequest
         * @static
         * @param {lnrpc.VerifyMessageRequest} message VerifyMessageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyMessageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.msg = "";
                else {
                    object.msg = [];
                    if (options.bytes !== Array)
                        object.msg = $util.newBuffer(object.msg);
                }
                object.signature = "";
            }
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.bytes === String ? $util.base64.encode(message.msg, 0, message.msg.length) : options.bytes === Array ? Array.prototype.slice.call(message.msg) : message.msg;
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = message.signature;
            return object;
        };

        /**
         * Converts this VerifyMessageRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.VerifyMessageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyMessageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyMessageRequest;
    })();

    lnrpc.VerifyMessageResponse = (function() {

        /**
         * Properties of a VerifyMessageResponse.
         * @memberof lnrpc
         * @interface IVerifyMessageResponse
         * @property {boolean|null} [valid] VerifyMessageResponse valid
         * @property {string|null} [pubkey] VerifyMessageResponse pubkey
         */

        /**
         * Constructs a new VerifyMessageResponse.
         * @memberof lnrpc
         * @classdesc Represents a VerifyMessageResponse.
         * @implements IVerifyMessageResponse
         * @constructor
         * @param {lnrpc.IVerifyMessageResponse=} [properties] Properties to set
         */
        function VerifyMessageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VerifyMessageResponse valid.
         * @member {boolean} valid
         * @memberof lnrpc.VerifyMessageResponse
         * @instance
         */
        VerifyMessageResponse.prototype.valid = false;

        /**
         * VerifyMessageResponse pubkey.
         * @member {string} pubkey
         * @memberof lnrpc.VerifyMessageResponse
         * @instance
         */
        VerifyMessageResponse.prototype.pubkey = "";

        /**
         * Creates a new VerifyMessageResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {lnrpc.IVerifyMessageResponse=} [properties] Properties to set
         * @returns {lnrpc.VerifyMessageResponse} VerifyMessageResponse instance
         */
        VerifyMessageResponse.create = function create(properties) {
            return new VerifyMessageResponse(properties);
        };

        /**
         * Encodes the specified VerifyMessageResponse message. Does not implicitly {@link lnrpc.VerifyMessageResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {lnrpc.IVerifyMessageResponse} message VerifyMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.valid);
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.pubkey);
            return writer;
        };

        /**
         * Encodes the specified VerifyMessageResponse message, length delimited. Does not implicitly {@link lnrpc.VerifyMessageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {lnrpc.IVerifyMessageResponse} message VerifyMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyMessageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.VerifyMessageResponse} VerifyMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.VerifyMessageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.valid = reader.bool();
                        break;
                    }
                case 2: {
                        message.pubkey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyMessageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.VerifyMessageResponse} VerifyMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyMessageResponse message.
         * @function verify
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.valid != null && message.hasOwnProperty("valid"))
                if (typeof message.valid !== "boolean")
                    return "valid: boolean expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!$util.isString(message.pubkey))
                    return "pubkey: string expected";
            return null;
        };

        /**
         * Creates a VerifyMessageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.VerifyMessageResponse} VerifyMessageResponse
         */
        VerifyMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.VerifyMessageResponse)
                return object;
            let message = new $root.lnrpc.VerifyMessageResponse();
            if (object.valid != null)
                message.valid = Boolean(object.valid);
            if (object.pubkey != null)
                message.pubkey = String(object.pubkey);
            return message;
        };

        /**
         * Creates a plain object from a VerifyMessageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.VerifyMessageResponse
         * @static
         * @param {lnrpc.VerifyMessageResponse} message VerifyMessageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyMessageResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.valid = false;
                object.pubkey = "";
            }
            if (message.valid != null && message.hasOwnProperty("valid"))
                object.valid = message.valid;
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = message.pubkey;
            return object;
        };

        /**
         * Converts this VerifyMessageResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.VerifyMessageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyMessageResponse;
    })();

    lnrpc.ConnectPeerRequest = (function() {

        /**
         * Properties of a ConnectPeerRequest.
         * @memberof lnrpc
         * @interface IConnectPeerRequest
         * @property {lnrpc.ILightningAddress|null} [addr] ConnectPeerRequest addr
         * @property {boolean|null} [perm] ConnectPeerRequest perm
         * @property {Long|null} [timeout] ConnectPeerRequest timeout
         */

        /**
         * Constructs a new ConnectPeerRequest.
         * @memberof lnrpc
         * @classdesc Represents a ConnectPeerRequest.
         * @implements IConnectPeerRequest
         * @constructor
         * @param {lnrpc.IConnectPeerRequest=} [properties] Properties to set
         */
        function ConnectPeerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConnectPeerRequest addr.
         * @member {lnrpc.ILightningAddress|null|undefined} addr
         * @memberof lnrpc.ConnectPeerRequest
         * @instance
         */
        ConnectPeerRequest.prototype.addr = null;

        /**
         * ConnectPeerRequest perm.
         * @member {boolean} perm
         * @memberof lnrpc.ConnectPeerRequest
         * @instance
         */
        ConnectPeerRequest.prototype.perm = false;

        /**
         * ConnectPeerRequest timeout.
         * @member {Long} timeout
         * @memberof lnrpc.ConnectPeerRequest
         * @instance
         */
        ConnectPeerRequest.prototype.timeout = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ConnectPeerRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {lnrpc.IConnectPeerRequest=} [properties] Properties to set
         * @returns {lnrpc.ConnectPeerRequest} ConnectPeerRequest instance
         */
        ConnectPeerRequest.create = function create(properties) {
            return new ConnectPeerRequest(properties);
        };

        /**
         * Encodes the specified ConnectPeerRequest message. Does not implicitly {@link lnrpc.ConnectPeerRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {lnrpc.IConnectPeerRequest} message ConnectPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectPeerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addr != null && Object.hasOwnProperty.call(message, "addr"))
                $root.lnrpc.LightningAddress.encode(message.addr, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.perm != null && Object.hasOwnProperty.call(message, "perm"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.perm);
            if (message.timeout != null && Object.hasOwnProperty.call(message, "timeout"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.timeout);
            return writer;
        };

        /**
         * Encodes the specified ConnectPeerRequest message, length delimited. Does not implicitly {@link lnrpc.ConnectPeerRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {lnrpc.IConnectPeerRequest} message ConnectPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectPeerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectPeerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ConnectPeerRequest} ConnectPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectPeerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ConnectPeerRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.addr = $root.lnrpc.LightningAddress.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.perm = reader.bool();
                        break;
                    }
                case 3: {
                        message.timeout = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectPeerRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ConnectPeerRequest} ConnectPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectPeerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectPeerRequest message.
         * @function verify
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectPeerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.addr != null && message.hasOwnProperty("addr")) {
                let error = $root.lnrpc.LightningAddress.verify(message.addr);
                if (error)
                    return "addr." + error;
            }
            if (message.perm != null && message.hasOwnProperty("perm"))
                if (typeof message.perm !== "boolean")
                    return "perm: boolean expected";
            if (message.timeout != null && message.hasOwnProperty("timeout"))
                if (!$util.isInteger(message.timeout) && !(message.timeout && $util.isInteger(message.timeout.low) && $util.isInteger(message.timeout.high)))
                    return "timeout: integer|Long expected";
            return null;
        };

        /**
         * Creates a ConnectPeerRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ConnectPeerRequest} ConnectPeerRequest
         */
        ConnectPeerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ConnectPeerRequest)
                return object;
            let message = new $root.lnrpc.ConnectPeerRequest();
            if (object.addr != null) {
                if (typeof object.addr !== "object")
                    throw TypeError(".lnrpc.ConnectPeerRequest.addr: object expected");
                message.addr = $root.lnrpc.LightningAddress.fromObject(object.addr);
            }
            if (object.perm != null)
                message.perm = Boolean(object.perm);
            if (object.timeout != null)
                if ($util.Long)
                    (message.timeout = $util.Long.fromValue(object.timeout)).unsigned = true;
                else if (typeof object.timeout === "string")
                    message.timeout = parseInt(object.timeout, 10);
                else if (typeof object.timeout === "number")
                    message.timeout = object.timeout;
                else if (typeof object.timeout === "object")
                    message.timeout = new $util.LongBits(object.timeout.low >>> 0, object.timeout.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ConnectPeerRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ConnectPeerRequest
         * @static
         * @param {lnrpc.ConnectPeerRequest} message ConnectPeerRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectPeerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.addr = null;
                object.perm = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timeout = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeout = options.longs === String ? "0" : 0;
            }
            if (message.addr != null && message.hasOwnProperty("addr"))
                object.addr = $root.lnrpc.LightningAddress.toObject(message.addr, options);
            if (message.perm != null && message.hasOwnProperty("perm"))
                object.perm = message.perm;
            if (message.timeout != null && message.hasOwnProperty("timeout"))
                if (typeof message.timeout === "number")
                    object.timeout = options.longs === String ? String(message.timeout) : message.timeout;
                else
                    object.timeout = options.longs === String ? $util.Long.prototype.toString.call(message.timeout) : options.longs === Number ? new $util.LongBits(message.timeout.low >>> 0, message.timeout.high >>> 0).toNumber(true) : message.timeout;
            return object;
        };

        /**
         * Converts this ConnectPeerRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ConnectPeerRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectPeerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConnectPeerRequest;
    })();

    lnrpc.ConnectPeerResponse = (function() {

        /**
         * Properties of a ConnectPeerResponse.
         * @memberof lnrpc
         * @interface IConnectPeerResponse
         */

        /**
         * Constructs a new ConnectPeerResponse.
         * @memberof lnrpc
         * @classdesc Represents a ConnectPeerResponse.
         * @implements IConnectPeerResponse
         * @constructor
         * @param {lnrpc.IConnectPeerResponse=} [properties] Properties to set
         */
        function ConnectPeerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ConnectPeerResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {lnrpc.IConnectPeerResponse=} [properties] Properties to set
         * @returns {lnrpc.ConnectPeerResponse} ConnectPeerResponse instance
         */
        ConnectPeerResponse.create = function create(properties) {
            return new ConnectPeerResponse(properties);
        };

        /**
         * Encodes the specified ConnectPeerResponse message. Does not implicitly {@link lnrpc.ConnectPeerResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {lnrpc.IConnectPeerResponse} message ConnectPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectPeerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ConnectPeerResponse message, length delimited. Does not implicitly {@link lnrpc.ConnectPeerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {lnrpc.IConnectPeerResponse} message ConnectPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConnectPeerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConnectPeerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ConnectPeerResponse} ConnectPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectPeerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ConnectPeerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConnectPeerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ConnectPeerResponse} ConnectPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConnectPeerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConnectPeerResponse message.
         * @function verify
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConnectPeerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ConnectPeerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ConnectPeerResponse} ConnectPeerResponse
         */
        ConnectPeerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ConnectPeerResponse)
                return object;
            return new $root.lnrpc.ConnectPeerResponse();
        };

        /**
         * Creates a plain object from a ConnectPeerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ConnectPeerResponse
         * @static
         * @param {lnrpc.ConnectPeerResponse} message ConnectPeerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConnectPeerResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ConnectPeerResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ConnectPeerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConnectPeerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConnectPeerResponse;
    })();

    lnrpc.DisconnectPeerRequest = (function() {

        /**
         * Properties of a DisconnectPeerRequest.
         * @memberof lnrpc
         * @interface IDisconnectPeerRequest
         * @property {string|null} [pubKey] DisconnectPeerRequest pubKey
         */

        /**
         * Constructs a new DisconnectPeerRequest.
         * @memberof lnrpc
         * @classdesc Represents a DisconnectPeerRequest.
         * @implements IDisconnectPeerRequest
         * @constructor
         * @param {lnrpc.IDisconnectPeerRequest=} [properties] Properties to set
         */
        function DisconnectPeerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DisconnectPeerRequest pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.DisconnectPeerRequest
         * @instance
         */
        DisconnectPeerRequest.prototype.pubKey = "";

        /**
         * Creates a new DisconnectPeerRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {lnrpc.IDisconnectPeerRequest=} [properties] Properties to set
         * @returns {lnrpc.DisconnectPeerRequest} DisconnectPeerRequest instance
         */
        DisconnectPeerRequest.create = function create(properties) {
            return new DisconnectPeerRequest(properties);
        };

        /**
         * Encodes the specified DisconnectPeerRequest message. Does not implicitly {@link lnrpc.DisconnectPeerRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {lnrpc.IDisconnectPeerRequest} message DisconnectPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubKey);
            return writer;
        };

        /**
         * Encodes the specified DisconnectPeerRequest message, length delimited. Does not implicitly {@link lnrpc.DisconnectPeerRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {lnrpc.IDisconnectPeerRequest} message DisconnectPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DisconnectPeerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DisconnectPeerRequest} DisconnectPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DisconnectPeerRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DisconnectPeerRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DisconnectPeerRequest} DisconnectPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DisconnectPeerRequest message.
         * @function verify
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DisconnectPeerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            return null;
        };

        /**
         * Creates a DisconnectPeerRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DisconnectPeerRequest} DisconnectPeerRequest
         */
        DisconnectPeerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DisconnectPeerRequest)
                return object;
            let message = new $root.lnrpc.DisconnectPeerRequest();
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            return message;
        };

        /**
         * Creates a plain object from a DisconnectPeerRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DisconnectPeerRequest
         * @static
         * @param {lnrpc.DisconnectPeerRequest} message DisconnectPeerRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DisconnectPeerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.pubKey = "";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            return object;
        };

        /**
         * Converts this DisconnectPeerRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.DisconnectPeerRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DisconnectPeerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DisconnectPeerRequest;
    })();

    lnrpc.DisconnectPeerResponse = (function() {

        /**
         * Properties of a DisconnectPeerResponse.
         * @memberof lnrpc
         * @interface IDisconnectPeerResponse
         */

        /**
         * Constructs a new DisconnectPeerResponse.
         * @memberof lnrpc
         * @classdesc Represents a DisconnectPeerResponse.
         * @implements IDisconnectPeerResponse
         * @constructor
         * @param {lnrpc.IDisconnectPeerResponse=} [properties] Properties to set
         */
        function DisconnectPeerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DisconnectPeerResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {lnrpc.IDisconnectPeerResponse=} [properties] Properties to set
         * @returns {lnrpc.DisconnectPeerResponse} DisconnectPeerResponse instance
         */
        DisconnectPeerResponse.create = function create(properties) {
            return new DisconnectPeerResponse(properties);
        };

        /**
         * Encodes the specified DisconnectPeerResponse message. Does not implicitly {@link lnrpc.DisconnectPeerResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {lnrpc.IDisconnectPeerResponse} message DisconnectPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DisconnectPeerResponse message, length delimited. Does not implicitly {@link lnrpc.DisconnectPeerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {lnrpc.IDisconnectPeerResponse} message DisconnectPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DisconnectPeerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DisconnectPeerResponse} DisconnectPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DisconnectPeerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DisconnectPeerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DisconnectPeerResponse} DisconnectPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DisconnectPeerResponse message.
         * @function verify
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DisconnectPeerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DisconnectPeerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DisconnectPeerResponse} DisconnectPeerResponse
         */
        DisconnectPeerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DisconnectPeerResponse)
                return object;
            return new $root.lnrpc.DisconnectPeerResponse();
        };

        /**
         * Creates a plain object from a DisconnectPeerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DisconnectPeerResponse
         * @static
         * @param {lnrpc.DisconnectPeerResponse} message DisconnectPeerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DisconnectPeerResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DisconnectPeerResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.DisconnectPeerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DisconnectPeerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DisconnectPeerResponse;
    })();

    lnrpc.HTLC = (function() {

        /**
         * Properties of a HTLC.
         * @memberof lnrpc
         * @interface IHTLC
         * @property {boolean|null} [incoming] HTLC incoming
         * @property {Long|null} [amount] HTLC amount
         * @property {Uint8Array|null} [hashLock] HTLC hashLock
         * @property {number|null} [expirationHeight] HTLC expirationHeight
         * @property {Long|null} [htlcIndex] HTLC htlcIndex
         * @property {Long|null} [forwardingChannel] HTLC forwardingChannel
         * @property {Long|null} [forwardingHtlcIndex] HTLC forwardingHtlcIndex
         */

        /**
         * Constructs a new HTLC.
         * @memberof lnrpc
         * @classdesc Represents a HTLC.
         * @implements IHTLC
         * @constructor
         * @param {lnrpc.IHTLC=} [properties] Properties to set
         */
        function HTLC(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HTLC incoming.
         * @member {boolean} incoming
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.incoming = false;

        /**
         * HTLC amount.
         * @member {Long} amount
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HTLC hashLock.
         * @member {Uint8Array} hashLock
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.hashLock = $util.newBuffer([]);

        /**
         * HTLC expirationHeight.
         * @member {number} expirationHeight
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.expirationHeight = 0;

        /**
         * HTLC htlcIndex.
         * @member {Long} htlcIndex
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.htlcIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HTLC forwardingChannel.
         * @member {Long} forwardingChannel
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.forwardingChannel = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HTLC forwardingHtlcIndex.
         * @member {Long} forwardingHtlcIndex
         * @memberof lnrpc.HTLC
         * @instance
         */
        HTLC.prototype.forwardingHtlcIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new HTLC instance using the specified properties.
         * @function create
         * @memberof lnrpc.HTLC
         * @static
         * @param {lnrpc.IHTLC=} [properties] Properties to set
         * @returns {lnrpc.HTLC} HTLC instance
         */
        HTLC.create = function create(properties) {
            return new HTLC(properties);
        };

        /**
         * Encodes the specified HTLC message. Does not implicitly {@link lnrpc.HTLC.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.HTLC
         * @static
         * @param {lnrpc.IHTLC} message HTLC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTLC.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incoming != null && Object.hasOwnProperty.call(message, "incoming"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.incoming);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amount);
            if (message.hashLock != null && Object.hasOwnProperty.call(message, "hashLock"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.hashLock);
            if (message.expirationHeight != null && Object.hasOwnProperty.call(message, "expirationHeight"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.expirationHeight);
            if (message.htlcIndex != null && Object.hasOwnProperty.call(message, "htlcIndex"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.htlcIndex);
            if (message.forwardingChannel != null && Object.hasOwnProperty.call(message, "forwardingChannel"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.forwardingChannel);
            if (message.forwardingHtlcIndex != null && Object.hasOwnProperty.call(message, "forwardingHtlcIndex"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.forwardingHtlcIndex);
            return writer;
        };

        /**
         * Encodes the specified HTLC message, length delimited. Does not implicitly {@link lnrpc.HTLC.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.HTLC
         * @static
         * @param {lnrpc.IHTLC} message HTLC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTLC.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTLC message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.HTLC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.HTLC} HTLC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTLC.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.HTLC();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incoming = reader.bool();
                        break;
                    }
                case 2: {
                        message.amount = reader.int64();
                        break;
                    }
                case 3: {
                        message.hashLock = reader.bytes();
                        break;
                    }
                case 4: {
                        message.expirationHeight = reader.uint32();
                        break;
                    }
                case 5: {
                        message.htlcIndex = reader.uint64();
                        break;
                    }
                case 6: {
                        message.forwardingChannel = reader.uint64();
                        break;
                    }
                case 7: {
                        message.forwardingHtlcIndex = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HTLC message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.HTLC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.HTLC} HTLC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTLC.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTLC message.
         * @function verify
         * @memberof lnrpc.HTLC
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTLC.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.incoming != null && message.hasOwnProperty("incoming"))
                if (typeof message.incoming !== "boolean")
                    return "incoming: boolean expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            if (message.hashLock != null && message.hasOwnProperty("hashLock"))
                if (!(message.hashLock && typeof message.hashLock.length === "number" || $util.isString(message.hashLock)))
                    return "hashLock: buffer expected";
            if (message.expirationHeight != null && message.hasOwnProperty("expirationHeight"))
                if (!$util.isInteger(message.expirationHeight))
                    return "expirationHeight: integer expected";
            if (message.htlcIndex != null && message.hasOwnProperty("htlcIndex"))
                if (!$util.isInteger(message.htlcIndex) && !(message.htlcIndex && $util.isInteger(message.htlcIndex.low) && $util.isInteger(message.htlcIndex.high)))
                    return "htlcIndex: integer|Long expected";
            if (message.forwardingChannel != null && message.hasOwnProperty("forwardingChannel"))
                if (!$util.isInteger(message.forwardingChannel) && !(message.forwardingChannel && $util.isInteger(message.forwardingChannel.low) && $util.isInteger(message.forwardingChannel.high)))
                    return "forwardingChannel: integer|Long expected";
            if (message.forwardingHtlcIndex != null && message.hasOwnProperty("forwardingHtlcIndex"))
                if (!$util.isInteger(message.forwardingHtlcIndex) && !(message.forwardingHtlcIndex && $util.isInteger(message.forwardingHtlcIndex.low) && $util.isInteger(message.forwardingHtlcIndex.high)))
                    return "forwardingHtlcIndex: integer|Long expected";
            return null;
        };

        /**
         * Creates a HTLC message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.HTLC
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.HTLC} HTLC
         */
        HTLC.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.HTLC)
                return object;
            let message = new $root.lnrpc.HTLC();
            if (object.incoming != null)
                message.incoming = Boolean(object.incoming);
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            if (object.hashLock != null)
                if (typeof object.hashLock === "string")
                    $util.base64.decode(object.hashLock, message.hashLock = $util.newBuffer($util.base64.length(object.hashLock)), 0);
                else if (object.hashLock.length)
                    message.hashLock = object.hashLock;
            if (object.expirationHeight != null)
                message.expirationHeight = object.expirationHeight >>> 0;
            if (object.htlcIndex != null)
                if ($util.Long)
                    (message.htlcIndex = $util.Long.fromValue(object.htlcIndex)).unsigned = true;
                else if (typeof object.htlcIndex === "string")
                    message.htlcIndex = parseInt(object.htlcIndex, 10);
                else if (typeof object.htlcIndex === "number")
                    message.htlcIndex = object.htlcIndex;
                else if (typeof object.htlcIndex === "object")
                    message.htlcIndex = new $util.LongBits(object.htlcIndex.low >>> 0, object.htlcIndex.high >>> 0).toNumber(true);
            if (object.forwardingChannel != null)
                if ($util.Long)
                    (message.forwardingChannel = $util.Long.fromValue(object.forwardingChannel)).unsigned = true;
                else if (typeof object.forwardingChannel === "string")
                    message.forwardingChannel = parseInt(object.forwardingChannel, 10);
                else if (typeof object.forwardingChannel === "number")
                    message.forwardingChannel = object.forwardingChannel;
                else if (typeof object.forwardingChannel === "object")
                    message.forwardingChannel = new $util.LongBits(object.forwardingChannel.low >>> 0, object.forwardingChannel.high >>> 0).toNumber(true);
            if (object.forwardingHtlcIndex != null)
                if ($util.Long)
                    (message.forwardingHtlcIndex = $util.Long.fromValue(object.forwardingHtlcIndex)).unsigned = true;
                else if (typeof object.forwardingHtlcIndex === "string")
                    message.forwardingHtlcIndex = parseInt(object.forwardingHtlcIndex, 10);
                else if (typeof object.forwardingHtlcIndex === "number")
                    message.forwardingHtlcIndex = object.forwardingHtlcIndex;
                else if (typeof object.forwardingHtlcIndex === "object")
                    message.forwardingHtlcIndex = new $util.LongBits(object.forwardingHtlcIndex.low >>> 0, object.forwardingHtlcIndex.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a HTLC message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.HTLC
         * @static
         * @param {lnrpc.HTLC} message HTLC
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTLC.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.incoming = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.hashLock = "";
                else {
                    object.hashLock = [];
                    if (options.bytes !== Array)
                        object.hashLock = $util.newBuffer(object.hashLock);
                }
                object.expirationHeight = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.htlcIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.htlcIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.forwardingChannel = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.forwardingChannel = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.forwardingHtlcIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.forwardingHtlcIndex = options.longs === String ? "0" : 0;
            }
            if (message.incoming != null && message.hasOwnProperty("incoming"))
                object.incoming = message.incoming;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            if (message.hashLock != null && message.hasOwnProperty("hashLock"))
                object.hashLock = options.bytes === String ? $util.base64.encode(message.hashLock, 0, message.hashLock.length) : options.bytes === Array ? Array.prototype.slice.call(message.hashLock) : message.hashLock;
            if (message.expirationHeight != null && message.hasOwnProperty("expirationHeight"))
                object.expirationHeight = message.expirationHeight;
            if (message.htlcIndex != null && message.hasOwnProperty("htlcIndex"))
                if (typeof message.htlcIndex === "number")
                    object.htlcIndex = options.longs === String ? String(message.htlcIndex) : message.htlcIndex;
                else
                    object.htlcIndex = options.longs === String ? $util.Long.prototype.toString.call(message.htlcIndex) : options.longs === Number ? new $util.LongBits(message.htlcIndex.low >>> 0, message.htlcIndex.high >>> 0).toNumber(true) : message.htlcIndex;
            if (message.forwardingChannel != null && message.hasOwnProperty("forwardingChannel"))
                if (typeof message.forwardingChannel === "number")
                    object.forwardingChannel = options.longs === String ? String(message.forwardingChannel) : message.forwardingChannel;
                else
                    object.forwardingChannel = options.longs === String ? $util.Long.prototype.toString.call(message.forwardingChannel) : options.longs === Number ? new $util.LongBits(message.forwardingChannel.low >>> 0, message.forwardingChannel.high >>> 0).toNumber(true) : message.forwardingChannel;
            if (message.forwardingHtlcIndex != null && message.hasOwnProperty("forwardingHtlcIndex"))
                if (typeof message.forwardingHtlcIndex === "number")
                    object.forwardingHtlcIndex = options.longs === String ? String(message.forwardingHtlcIndex) : message.forwardingHtlcIndex;
                else
                    object.forwardingHtlcIndex = options.longs === String ? $util.Long.prototype.toString.call(message.forwardingHtlcIndex) : options.longs === Number ? new $util.LongBits(message.forwardingHtlcIndex.low >>> 0, message.forwardingHtlcIndex.high >>> 0).toNumber(true) : message.forwardingHtlcIndex;
            return object;
        };

        /**
         * Converts this HTLC to JSON.
         * @function toJSON
         * @memberof lnrpc.HTLC
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTLC.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HTLC;
    })();

    /**
     * CommitmentType enum.
     * @name lnrpc.CommitmentType
     * @enum {number}
     * @property {number} UNKNOWN_COMMITMENT_TYPE=0 UNKNOWN_COMMITMENT_TYPE value
     * @property {number} LEGACY=1 LEGACY value
     * @property {number} STATIC_REMOTE_KEY=2 STATIC_REMOTE_KEY value
     * @property {number} ANCHORS=3 ANCHORS value
     * @property {number} SCRIPT_ENFORCED_LEASE=4 SCRIPT_ENFORCED_LEASE value
     */
    lnrpc.CommitmentType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_COMMITMENT_TYPE"] = 0;
        values[valuesById[1] = "LEGACY"] = 1;
        values[valuesById[2] = "STATIC_REMOTE_KEY"] = 2;
        values[valuesById[3] = "ANCHORS"] = 3;
        values[valuesById[4] = "SCRIPT_ENFORCED_LEASE"] = 4;
        return values;
    })();

    lnrpc.ChannelConstraints = (function() {

        /**
         * Properties of a ChannelConstraints.
         * @memberof lnrpc
         * @interface IChannelConstraints
         * @property {number|null} [csvDelay] ChannelConstraints csvDelay
         * @property {Long|null} [chanReserveSat] ChannelConstraints chanReserveSat
         * @property {Long|null} [dustLimitSat] ChannelConstraints dustLimitSat
         * @property {Long|null} [maxPendingAmtMsat] ChannelConstraints maxPendingAmtMsat
         * @property {Long|null} [minHtlcMsat] ChannelConstraints minHtlcMsat
         * @property {number|null} [maxAcceptedHtlcs] ChannelConstraints maxAcceptedHtlcs
         */

        /**
         * Constructs a new ChannelConstraints.
         * @memberof lnrpc
         * @classdesc Represents a ChannelConstraints.
         * @implements IChannelConstraints
         * @constructor
         * @param {lnrpc.IChannelConstraints=} [properties] Properties to set
         */
        function ChannelConstraints(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelConstraints csvDelay.
         * @member {number} csvDelay
         * @memberof lnrpc.ChannelConstraints
         * @instance
         */
        ChannelConstraints.prototype.csvDelay = 0;

        /**
         * ChannelConstraints chanReserveSat.
         * @member {Long} chanReserveSat
         * @memberof lnrpc.ChannelConstraints
         * @instance
         */
        ChannelConstraints.prototype.chanReserveSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelConstraints dustLimitSat.
         * @member {Long} dustLimitSat
         * @memberof lnrpc.ChannelConstraints
         * @instance
         */
        ChannelConstraints.prototype.dustLimitSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelConstraints maxPendingAmtMsat.
         * @member {Long} maxPendingAmtMsat
         * @memberof lnrpc.ChannelConstraints
         * @instance
         */
        ChannelConstraints.prototype.maxPendingAmtMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelConstraints minHtlcMsat.
         * @member {Long} minHtlcMsat
         * @memberof lnrpc.ChannelConstraints
         * @instance
         */
        ChannelConstraints.prototype.minHtlcMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelConstraints maxAcceptedHtlcs.
         * @member {number} maxAcceptedHtlcs
         * @memberof lnrpc.ChannelConstraints
         * @instance
         */
        ChannelConstraints.prototype.maxAcceptedHtlcs = 0;

        /**
         * Creates a new ChannelConstraints instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {lnrpc.IChannelConstraints=} [properties] Properties to set
         * @returns {lnrpc.ChannelConstraints} ChannelConstraints instance
         */
        ChannelConstraints.create = function create(properties) {
            return new ChannelConstraints(properties);
        };

        /**
         * Encodes the specified ChannelConstraints message. Does not implicitly {@link lnrpc.ChannelConstraints.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {lnrpc.IChannelConstraints} message ChannelConstraints message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelConstraints.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.csvDelay != null && Object.hasOwnProperty.call(message, "csvDelay"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.csvDelay);
            if (message.chanReserveSat != null && Object.hasOwnProperty.call(message, "chanReserveSat"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chanReserveSat);
            if (message.dustLimitSat != null && Object.hasOwnProperty.call(message, "dustLimitSat"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.dustLimitSat);
            if (message.maxPendingAmtMsat != null && Object.hasOwnProperty.call(message, "maxPendingAmtMsat"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.maxPendingAmtMsat);
            if (message.minHtlcMsat != null && Object.hasOwnProperty.call(message, "minHtlcMsat"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.minHtlcMsat);
            if (message.maxAcceptedHtlcs != null && Object.hasOwnProperty.call(message, "maxAcceptedHtlcs"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.maxAcceptedHtlcs);
            return writer;
        };

        /**
         * Encodes the specified ChannelConstraints message, length delimited. Does not implicitly {@link lnrpc.ChannelConstraints.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {lnrpc.IChannelConstraints} message ChannelConstraints message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelConstraints.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelConstraints message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelConstraints} ChannelConstraints
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelConstraints.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelConstraints();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.csvDelay = reader.uint32();
                        break;
                    }
                case 2: {
                        message.chanReserveSat = reader.uint64();
                        break;
                    }
                case 3: {
                        message.dustLimitSat = reader.uint64();
                        break;
                    }
                case 4: {
                        message.maxPendingAmtMsat = reader.uint64();
                        break;
                    }
                case 5: {
                        message.minHtlcMsat = reader.uint64();
                        break;
                    }
                case 6: {
                        message.maxAcceptedHtlcs = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelConstraints message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelConstraints} ChannelConstraints
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelConstraints.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelConstraints message.
         * @function verify
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelConstraints.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                if (!$util.isInteger(message.csvDelay))
                    return "csvDelay: integer expected";
            if (message.chanReserveSat != null && message.hasOwnProperty("chanReserveSat"))
                if (!$util.isInteger(message.chanReserveSat) && !(message.chanReserveSat && $util.isInteger(message.chanReserveSat.low) && $util.isInteger(message.chanReserveSat.high)))
                    return "chanReserveSat: integer|Long expected";
            if (message.dustLimitSat != null && message.hasOwnProperty("dustLimitSat"))
                if (!$util.isInteger(message.dustLimitSat) && !(message.dustLimitSat && $util.isInteger(message.dustLimitSat.low) && $util.isInteger(message.dustLimitSat.high)))
                    return "dustLimitSat: integer|Long expected";
            if (message.maxPendingAmtMsat != null && message.hasOwnProperty("maxPendingAmtMsat"))
                if (!$util.isInteger(message.maxPendingAmtMsat) && !(message.maxPendingAmtMsat && $util.isInteger(message.maxPendingAmtMsat.low) && $util.isInteger(message.maxPendingAmtMsat.high)))
                    return "maxPendingAmtMsat: integer|Long expected";
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (!$util.isInteger(message.minHtlcMsat) && !(message.minHtlcMsat && $util.isInteger(message.minHtlcMsat.low) && $util.isInteger(message.minHtlcMsat.high)))
                    return "minHtlcMsat: integer|Long expected";
            if (message.maxAcceptedHtlcs != null && message.hasOwnProperty("maxAcceptedHtlcs"))
                if (!$util.isInteger(message.maxAcceptedHtlcs))
                    return "maxAcceptedHtlcs: integer expected";
            return null;
        };

        /**
         * Creates a ChannelConstraints message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelConstraints} ChannelConstraints
         */
        ChannelConstraints.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelConstraints)
                return object;
            let message = new $root.lnrpc.ChannelConstraints();
            if (object.csvDelay != null)
                message.csvDelay = object.csvDelay >>> 0;
            if (object.chanReserveSat != null)
                if ($util.Long)
                    (message.chanReserveSat = $util.Long.fromValue(object.chanReserveSat)).unsigned = true;
                else if (typeof object.chanReserveSat === "string")
                    message.chanReserveSat = parseInt(object.chanReserveSat, 10);
                else if (typeof object.chanReserveSat === "number")
                    message.chanReserveSat = object.chanReserveSat;
                else if (typeof object.chanReserveSat === "object")
                    message.chanReserveSat = new $util.LongBits(object.chanReserveSat.low >>> 0, object.chanReserveSat.high >>> 0).toNumber(true);
            if (object.dustLimitSat != null)
                if ($util.Long)
                    (message.dustLimitSat = $util.Long.fromValue(object.dustLimitSat)).unsigned = true;
                else if (typeof object.dustLimitSat === "string")
                    message.dustLimitSat = parseInt(object.dustLimitSat, 10);
                else if (typeof object.dustLimitSat === "number")
                    message.dustLimitSat = object.dustLimitSat;
                else if (typeof object.dustLimitSat === "object")
                    message.dustLimitSat = new $util.LongBits(object.dustLimitSat.low >>> 0, object.dustLimitSat.high >>> 0).toNumber(true);
            if (object.maxPendingAmtMsat != null)
                if ($util.Long)
                    (message.maxPendingAmtMsat = $util.Long.fromValue(object.maxPendingAmtMsat)).unsigned = true;
                else if (typeof object.maxPendingAmtMsat === "string")
                    message.maxPendingAmtMsat = parseInt(object.maxPendingAmtMsat, 10);
                else if (typeof object.maxPendingAmtMsat === "number")
                    message.maxPendingAmtMsat = object.maxPendingAmtMsat;
                else if (typeof object.maxPendingAmtMsat === "object")
                    message.maxPendingAmtMsat = new $util.LongBits(object.maxPendingAmtMsat.low >>> 0, object.maxPendingAmtMsat.high >>> 0).toNumber(true);
            if (object.minHtlcMsat != null)
                if ($util.Long)
                    (message.minHtlcMsat = $util.Long.fromValue(object.minHtlcMsat)).unsigned = true;
                else if (typeof object.minHtlcMsat === "string")
                    message.minHtlcMsat = parseInt(object.minHtlcMsat, 10);
                else if (typeof object.minHtlcMsat === "number")
                    message.minHtlcMsat = object.minHtlcMsat;
                else if (typeof object.minHtlcMsat === "object")
                    message.minHtlcMsat = new $util.LongBits(object.minHtlcMsat.low >>> 0, object.minHtlcMsat.high >>> 0).toNumber(true);
            if (object.maxAcceptedHtlcs != null)
                message.maxAcceptedHtlcs = object.maxAcceptedHtlcs >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ChannelConstraints message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelConstraints
         * @static
         * @param {lnrpc.ChannelConstraints} message ChannelConstraints
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelConstraints.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.csvDelay = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanReserveSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanReserveSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.dustLimitSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.dustLimitSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxPendingAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxPendingAmtMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minHtlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlcMsat = options.longs === String ? "0" : 0;
                object.maxAcceptedHtlcs = 0;
            }
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                object.csvDelay = message.csvDelay;
            if (message.chanReserveSat != null && message.hasOwnProperty("chanReserveSat"))
                if (typeof message.chanReserveSat === "number")
                    object.chanReserveSat = options.longs === String ? String(message.chanReserveSat) : message.chanReserveSat;
                else
                    object.chanReserveSat = options.longs === String ? $util.Long.prototype.toString.call(message.chanReserveSat) : options.longs === Number ? new $util.LongBits(message.chanReserveSat.low >>> 0, message.chanReserveSat.high >>> 0).toNumber(true) : message.chanReserveSat;
            if (message.dustLimitSat != null && message.hasOwnProperty("dustLimitSat"))
                if (typeof message.dustLimitSat === "number")
                    object.dustLimitSat = options.longs === String ? String(message.dustLimitSat) : message.dustLimitSat;
                else
                    object.dustLimitSat = options.longs === String ? $util.Long.prototype.toString.call(message.dustLimitSat) : options.longs === Number ? new $util.LongBits(message.dustLimitSat.low >>> 0, message.dustLimitSat.high >>> 0).toNumber(true) : message.dustLimitSat;
            if (message.maxPendingAmtMsat != null && message.hasOwnProperty("maxPendingAmtMsat"))
                if (typeof message.maxPendingAmtMsat === "number")
                    object.maxPendingAmtMsat = options.longs === String ? String(message.maxPendingAmtMsat) : message.maxPendingAmtMsat;
                else
                    object.maxPendingAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.maxPendingAmtMsat) : options.longs === Number ? new $util.LongBits(message.maxPendingAmtMsat.low >>> 0, message.maxPendingAmtMsat.high >>> 0).toNumber(true) : message.maxPendingAmtMsat;
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (typeof message.minHtlcMsat === "number")
                    object.minHtlcMsat = options.longs === String ? String(message.minHtlcMsat) : message.minHtlcMsat;
                else
                    object.minHtlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlcMsat) : options.longs === Number ? new $util.LongBits(message.minHtlcMsat.low >>> 0, message.minHtlcMsat.high >>> 0).toNumber(true) : message.minHtlcMsat;
            if (message.maxAcceptedHtlcs != null && message.hasOwnProperty("maxAcceptedHtlcs"))
                object.maxAcceptedHtlcs = message.maxAcceptedHtlcs;
            return object;
        };

        /**
         * Converts this ChannelConstraints to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelConstraints
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelConstraints.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelConstraints;
    })();

    lnrpc.Channel = (function() {

        /**
         * Properties of a Channel.
         * @memberof lnrpc
         * @interface IChannel
         * @property {boolean|null} [active] Channel active
         * @property {string|null} [remotePubkey] Channel remotePubkey
         * @property {string|null} [channelPoint] Channel channelPoint
         * @property {Long|null} [chanId] Channel chanId
         * @property {Long|null} [capacity] Channel capacity
         * @property {Long|null} [localBalance] Channel localBalance
         * @property {Long|null} [remoteBalance] Channel remoteBalance
         * @property {Long|null} [commitFee] Channel commitFee
         * @property {Long|null} [commitWeight] Channel commitWeight
         * @property {Long|null} [feePerKw] Channel feePerKw
         * @property {Long|null} [unsettledBalance] Channel unsettledBalance
         * @property {Long|null} [totalSatoshisSent] Channel totalSatoshisSent
         * @property {Long|null} [totalSatoshisReceived] Channel totalSatoshisReceived
         * @property {Long|null} [numUpdates] Channel numUpdates
         * @property {Array.<lnrpc.IHTLC>|null} [pendingHtlcs] Channel pendingHtlcs
         * @property {number|null} [csvDelay] Channel csvDelay
         * @property {boolean|null} ["private"] Channel private
         * @property {boolean|null} [initiator] Channel initiator
         * @property {string|null} [chanStatusFlags] Channel chanStatusFlags
         * @property {Long|null} [localChanReserveSat] Channel localChanReserveSat
         * @property {Long|null} [remoteChanReserveSat] Channel remoteChanReserveSat
         * @property {boolean|null} [staticRemoteKey] Channel staticRemoteKey
         * @property {lnrpc.CommitmentType|null} [commitmentType] Channel commitmentType
         * @property {Long|null} [lifetime] Channel lifetime
         * @property {Long|null} [uptime] Channel uptime
         * @property {string|null} [closeAddress] Channel closeAddress
         * @property {Long|null} [pushAmountSat] Channel pushAmountSat
         * @property {number|null} [thawHeight] Channel thawHeight
         * @property {lnrpc.IChannelConstraints|null} [localConstraints] Channel localConstraints
         * @property {lnrpc.IChannelConstraints|null} [remoteConstraints] Channel remoteConstraints
         * @property {Array.<Long>|null} [aliasScids] Channel aliasScids
         * @property {boolean|null} [zeroConf] Channel zeroConf
         * @property {Long|null} [zeroConfConfirmedScid] Channel zeroConfConfirmedScid
         */

        /**
         * Constructs a new Channel.
         * @memberof lnrpc
         * @classdesc Represents a Channel.
         * @implements IChannel
         * @constructor
         * @param {lnrpc.IChannel=} [properties] Properties to set
         */
        function Channel(properties) {
            this.pendingHtlcs = [];
            this.aliasScids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Channel active.
         * @member {boolean} active
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.active = false;

        /**
         * Channel remotePubkey.
         * @member {string} remotePubkey
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.remotePubkey = "";

        /**
         * Channel channelPoint.
         * @member {string} channelPoint
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.channelPoint = "";

        /**
         * Channel chanId.
         * @member {Long} chanId
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Channel capacity.
         * @member {Long} capacity
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel localBalance.
         * @member {Long} localBalance
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.localBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel remoteBalance.
         * @member {Long} remoteBalance
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.remoteBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel commitFee.
         * @member {Long} commitFee
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.commitFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel commitWeight.
         * @member {Long} commitWeight
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.commitWeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel feePerKw.
         * @member {Long} feePerKw
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.feePerKw = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel unsettledBalance.
         * @member {Long} unsettledBalance
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.unsettledBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel totalSatoshisSent.
         * @member {Long} totalSatoshisSent
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.totalSatoshisSent = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel totalSatoshisReceived.
         * @member {Long} totalSatoshisReceived
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.totalSatoshisReceived = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel numUpdates.
         * @member {Long} numUpdates
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.numUpdates = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Channel pendingHtlcs.
         * @member {Array.<lnrpc.IHTLC>} pendingHtlcs
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.pendingHtlcs = $util.emptyArray;

        /**
         * Channel csvDelay.
         * @member {number} csvDelay
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.csvDelay = 0;

        /**
         * Channel private.
         * @member {boolean} private
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype["private"] = false;

        /**
         * Channel initiator.
         * @member {boolean} initiator
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.initiator = false;

        /**
         * Channel chanStatusFlags.
         * @member {string} chanStatusFlags
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.chanStatusFlags = "";

        /**
         * Channel localChanReserveSat.
         * @member {Long} localChanReserveSat
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.localChanReserveSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel remoteChanReserveSat.
         * @member {Long} remoteChanReserveSat
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.remoteChanReserveSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel staticRemoteKey.
         * @member {boolean} staticRemoteKey
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.staticRemoteKey = false;

        /**
         * Channel commitmentType.
         * @member {lnrpc.CommitmentType} commitmentType
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.commitmentType = 0;

        /**
         * Channel lifetime.
         * @member {Long} lifetime
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.lifetime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel uptime.
         * @member {Long} uptime
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.uptime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Channel closeAddress.
         * @member {string} closeAddress
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.closeAddress = "";

        /**
         * Channel pushAmountSat.
         * @member {Long} pushAmountSat
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.pushAmountSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Channel thawHeight.
         * @member {number} thawHeight
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.thawHeight = 0;

        /**
         * Channel localConstraints.
         * @member {lnrpc.IChannelConstraints|null|undefined} localConstraints
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.localConstraints = null;

        /**
         * Channel remoteConstraints.
         * @member {lnrpc.IChannelConstraints|null|undefined} remoteConstraints
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.remoteConstraints = null;

        /**
         * Channel aliasScids.
         * @member {Array.<Long>} aliasScids
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.aliasScids = $util.emptyArray;

        /**
         * Channel zeroConf.
         * @member {boolean} zeroConf
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.zeroConf = false;

        /**
         * Channel zeroConfConfirmedScid.
         * @member {Long} zeroConfConfirmedScid
         * @memberof lnrpc.Channel
         * @instance
         */
        Channel.prototype.zeroConfConfirmedScid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new Channel instance using the specified properties.
         * @function create
         * @memberof lnrpc.Channel
         * @static
         * @param {lnrpc.IChannel=} [properties] Properties to set
         * @returns {lnrpc.Channel} Channel instance
         */
        Channel.create = function create(properties) {
            return new Channel(properties);
        };

        /**
         * Encodes the specified Channel message. Does not implicitly {@link lnrpc.Channel.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Channel
         * @static
         * @param {lnrpc.IChannel} message Channel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Channel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.active);
            if (message.remotePubkey != null && Object.hasOwnProperty.call(message, "remotePubkey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.remotePubkey);
            if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.channelPoint);
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.chanId);
            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.capacity);
            if (message.localBalance != null && Object.hasOwnProperty.call(message, "localBalance"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.localBalance);
            if (message.remoteBalance != null && Object.hasOwnProperty.call(message, "remoteBalance"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.remoteBalance);
            if (message.commitFee != null && Object.hasOwnProperty.call(message, "commitFee"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.commitFee);
            if (message.commitWeight != null && Object.hasOwnProperty.call(message, "commitWeight"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.commitWeight);
            if (message.feePerKw != null && Object.hasOwnProperty.call(message, "feePerKw"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.feePerKw);
            if (message.unsettledBalance != null && Object.hasOwnProperty.call(message, "unsettledBalance"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.unsettledBalance);
            if (message.totalSatoshisSent != null && Object.hasOwnProperty.call(message, "totalSatoshisSent"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.totalSatoshisSent);
            if (message.totalSatoshisReceived != null && Object.hasOwnProperty.call(message, "totalSatoshisReceived"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.totalSatoshisReceived);
            if (message.numUpdates != null && Object.hasOwnProperty.call(message, "numUpdates"))
                writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.numUpdates);
            if (message.pendingHtlcs != null && message.pendingHtlcs.length)
                for (let i = 0; i < message.pendingHtlcs.length; ++i)
                    $root.lnrpc.HTLC.encode(message.pendingHtlcs[i], writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.csvDelay != null && Object.hasOwnProperty.call(message, "csvDelay"))
                writer.uint32(/* id 16, wireType 0 =*/128).uint32(message.csvDelay);
            if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                writer.uint32(/* id 17, wireType 0 =*/136).bool(message["private"]);
            if (message.initiator != null && Object.hasOwnProperty.call(message, "initiator"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.initiator);
            if (message.chanStatusFlags != null && Object.hasOwnProperty.call(message, "chanStatusFlags"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.chanStatusFlags);
            if (message.localChanReserveSat != null && Object.hasOwnProperty.call(message, "localChanReserveSat"))
                writer.uint32(/* id 20, wireType 0 =*/160).int64(message.localChanReserveSat);
            if (message.remoteChanReserveSat != null && Object.hasOwnProperty.call(message, "remoteChanReserveSat"))
                writer.uint32(/* id 21, wireType 0 =*/168).int64(message.remoteChanReserveSat);
            if (message.staticRemoteKey != null && Object.hasOwnProperty.call(message, "staticRemoteKey"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.staticRemoteKey);
            if (message.lifetime != null && Object.hasOwnProperty.call(message, "lifetime"))
                writer.uint32(/* id 23, wireType 0 =*/184).int64(message.lifetime);
            if (message.uptime != null && Object.hasOwnProperty.call(message, "uptime"))
                writer.uint32(/* id 24, wireType 0 =*/192).int64(message.uptime);
            if (message.closeAddress != null && Object.hasOwnProperty.call(message, "closeAddress"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.closeAddress);
            if (message.commitmentType != null && Object.hasOwnProperty.call(message, "commitmentType"))
                writer.uint32(/* id 26, wireType 0 =*/208).int32(message.commitmentType);
            if (message.pushAmountSat != null && Object.hasOwnProperty.call(message, "pushAmountSat"))
                writer.uint32(/* id 27, wireType 0 =*/216).uint64(message.pushAmountSat);
            if (message.thawHeight != null && Object.hasOwnProperty.call(message, "thawHeight"))
                writer.uint32(/* id 28, wireType 0 =*/224).uint32(message.thawHeight);
            if (message.localConstraints != null && Object.hasOwnProperty.call(message, "localConstraints"))
                $root.lnrpc.ChannelConstraints.encode(message.localConstraints, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.remoteConstraints != null && Object.hasOwnProperty.call(message, "remoteConstraints"))
                $root.lnrpc.ChannelConstraints.encode(message.remoteConstraints, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.aliasScids != null && message.aliasScids.length) {
                writer.uint32(/* id 31, wireType 2 =*/250).fork();
                for (let i = 0; i < message.aliasScids.length; ++i)
                    writer.uint64(message.aliasScids[i]);
                writer.ldelim();
            }
            if (message.zeroConf != null && Object.hasOwnProperty.call(message, "zeroConf"))
                writer.uint32(/* id 32, wireType 0 =*/256).bool(message.zeroConf);
            if (message.zeroConfConfirmedScid != null && Object.hasOwnProperty.call(message, "zeroConfConfirmedScid"))
                writer.uint32(/* id 33, wireType 0 =*/264).uint64(message.zeroConfConfirmedScid);
            return writer;
        };

        /**
         * Encodes the specified Channel message, length delimited. Does not implicitly {@link lnrpc.Channel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Channel
         * @static
         * @param {lnrpc.IChannel} message Channel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Channel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Channel message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Channel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Channel} Channel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Channel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Channel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.active = reader.bool();
                        break;
                    }
                case 2: {
                        message.remotePubkey = reader.string();
                        break;
                    }
                case 3: {
                        message.channelPoint = reader.string();
                        break;
                    }
                case 4: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 5: {
                        message.capacity = reader.int64();
                        break;
                    }
                case 6: {
                        message.localBalance = reader.int64();
                        break;
                    }
                case 7: {
                        message.remoteBalance = reader.int64();
                        break;
                    }
                case 8: {
                        message.commitFee = reader.int64();
                        break;
                    }
                case 9: {
                        message.commitWeight = reader.int64();
                        break;
                    }
                case 10: {
                        message.feePerKw = reader.int64();
                        break;
                    }
                case 11: {
                        message.unsettledBalance = reader.int64();
                        break;
                    }
                case 12: {
                        message.totalSatoshisSent = reader.int64();
                        break;
                    }
                case 13: {
                        message.totalSatoshisReceived = reader.int64();
                        break;
                    }
                case 14: {
                        message.numUpdates = reader.uint64();
                        break;
                    }
                case 15: {
                        if (!(message.pendingHtlcs && message.pendingHtlcs.length))
                            message.pendingHtlcs = [];
                        message.pendingHtlcs.push($root.lnrpc.HTLC.decode(reader, reader.uint32()));
                        break;
                    }
                case 16: {
                        message.csvDelay = reader.uint32();
                        break;
                    }
                case 17: {
                        message["private"] = reader.bool();
                        break;
                    }
                case 18: {
                        message.initiator = reader.bool();
                        break;
                    }
                case 19: {
                        message.chanStatusFlags = reader.string();
                        break;
                    }
                case 20: {
                        message.localChanReserveSat = reader.int64();
                        break;
                    }
                case 21: {
                        message.remoteChanReserveSat = reader.int64();
                        break;
                    }
                case 22: {
                        message.staticRemoteKey = reader.bool();
                        break;
                    }
                case 26: {
                        message.commitmentType = reader.int32();
                        break;
                    }
                case 23: {
                        message.lifetime = reader.int64();
                        break;
                    }
                case 24: {
                        message.uptime = reader.int64();
                        break;
                    }
                case 25: {
                        message.closeAddress = reader.string();
                        break;
                    }
                case 27: {
                        message.pushAmountSat = reader.uint64();
                        break;
                    }
                case 28: {
                        message.thawHeight = reader.uint32();
                        break;
                    }
                case 29: {
                        message.localConstraints = $root.lnrpc.ChannelConstraints.decode(reader, reader.uint32());
                        break;
                    }
                case 30: {
                        message.remoteConstraints = $root.lnrpc.ChannelConstraints.decode(reader, reader.uint32());
                        break;
                    }
                case 31: {
                        if (!(message.aliasScids && message.aliasScids.length))
                            message.aliasScids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.aliasScids.push(reader.uint64());
                        } else
                            message.aliasScids.push(reader.uint64());
                        break;
                    }
                case 32: {
                        message.zeroConf = reader.bool();
                        break;
                    }
                case 33: {
                        message.zeroConfConfirmedScid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Channel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Channel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Channel} Channel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Channel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Channel message.
         * @function verify
         * @memberof lnrpc.Channel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Channel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.active != null && message.hasOwnProperty("active"))
                if (typeof message.active !== "boolean")
                    return "active: boolean expected";
            if (message.remotePubkey != null && message.hasOwnProperty("remotePubkey"))
                if (!$util.isString(message.remotePubkey))
                    return "remotePubkey: string expected";
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                if (!$util.isString(message.channelPoint))
                    return "channelPoint: string expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (!$util.isInteger(message.capacity) && !(message.capacity && $util.isInteger(message.capacity.low) && $util.isInteger(message.capacity.high)))
                    return "capacity: integer|Long expected";
            if (message.localBalance != null && message.hasOwnProperty("localBalance"))
                if (!$util.isInteger(message.localBalance) && !(message.localBalance && $util.isInteger(message.localBalance.low) && $util.isInteger(message.localBalance.high)))
                    return "localBalance: integer|Long expected";
            if (message.remoteBalance != null && message.hasOwnProperty("remoteBalance"))
                if (!$util.isInteger(message.remoteBalance) && !(message.remoteBalance && $util.isInteger(message.remoteBalance.low) && $util.isInteger(message.remoteBalance.high)))
                    return "remoteBalance: integer|Long expected";
            if (message.commitFee != null && message.hasOwnProperty("commitFee"))
                if (!$util.isInteger(message.commitFee) && !(message.commitFee && $util.isInteger(message.commitFee.low) && $util.isInteger(message.commitFee.high)))
                    return "commitFee: integer|Long expected";
            if (message.commitWeight != null && message.hasOwnProperty("commitWeight"))
                if (!$util.isInteger(message.commitWeight) && !(message.commitWeight && $util.isInteger(message.commitWeight.low) && $util.isInteger(message.commitWeight.high)))
                    return "commitWeight: integer|Long expected";
            if (message.feePerKw != null && message.hasOwnProperty("feePerKw"))
                if (!$util.isInteger(message.feePerKw) && !(message.feePerKw && $util.isInteger(message.feePerKw.low) && $util.isInteger(message.feePerKw.high)))
                    return "feePerKw: integer|Long expected";
            if (message.unsettledBalance != null && message.hasOwnProperty("unsettledBalance"))
                if (!$util.isInteger(message.unsettledBalance) && !(message.unsettledBalance && $util.isInteger(message.unsettledBalance.low) && $util.isInteger(message.unsettledBalance.high)))
                    return "unsettledBalance: integer|Long expected";
            if (message.totalSatoshisSent != null && message.hasOwnProperty("totalSatoshisSent"))
                if (!$util.isInteger(message.totalSatoshisSent) && !(message.totalSatoshisSent && $util.isInteger(message.totalSatoshisSent.low) && $util.isInteger(message.totalSatoshisSent.high)))
                    return "totalSatoshisSent: integer|Long expected";
            if (message.totalSatoshisReceived != null && message.hasOwnProperty("totalSatoshisReceived"))
                if (!$util.isInteger(message.totalSatoshisReceived) && !(message.totalSatoshisReceived && $util.isInteger(message.totalSatoshisReceived.low) && $util.isInteger(message.totalSatoshisReceived.high)))
                    return "totalSatoshisReceived: integer|Long expected";
            if (message.numUpdates != null && message.hasOwnProperty("numUpdates"))
                if (!$util.isInteger(message.numUpdates) && !(message.numUpdates && $util.isInteger(message.numUpdates.low) && $util.isInteger(message.numUpdates.high)))
                    return "numUpdates: integer|Long expected";
            if (message.pendingHtlcs != null && message.hasOwnProperty("pendingHtlcs")) {
                if (!Array.isArray(message.pendingHtlcs))
                    return "pendingHtlcs: array expected";
                for (let i = 0; i < message.pendingHtlcs.length; ++i) {
                    let error = $root.lnrpc.HTLC.verify(message.pendingHtlcs[i]);
                    if (error)
                        return "pendingHtlcs." + error;
                }
            }
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                if (!$util.isInteger(message.csvDelay))
                    return "csvDelay: integer expected";
            if (message["private"] != null && message.hasOwnProperty("private"))
                if (typeof message["private"] !== "boolean")
                    return "private: boolean expected";
            if (message.initiator != null && message.hasOwnProperty("initiator"))
                if (typeof message.initiator !== "boolean")
                    return "initiator: boolean expected";
            if (message.chanStatusFlags != null && message.hasOwnProperty("chanStatusFlags"))
                if (!$util.isString(message.chanStatusFlags))
                    return "chanStatusFlags: string expected";
            if (message.localChanReserveSat != null && message.hasOwnProperty("localChanReserveSat"))
                if (!$util.isInteger(message.localChanReserveSat) && !(message.localChanReserveSat && $util.isInteger(message.localChanReserveSat.low) && $util.isInteger(message.localChanReserveSat.high)))
                    return "localChanReserveSat: integer|Long expected";
            if (message.remoteChanReserveSat != null && message.hasOwnProperty("remoteChanReserveSat"))
                if (!$util.isInteger(message.remoteChanReserveSat) && !(message.remoteChanReserveSat && $util.isInteger(message.remoteChanReserveSat.low) && $util.isInteger(message.remoteChanReserveSat.high)))
                    return "remoteChanReserveSat: integer|Long expected";
            if (message.staticRemoteKey != null && message.hasOwnProperty("staticRemoteKey"))
                if (typeof message.staticRemoteKey !== "boolean")
                    return "staticRemoteKey: boolean expected";
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                switch (message.commitmentType) {
                default:
                    return "commitmentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.lifetime != null && message.hasOwnProperty("lifetime"))
                if (!$util.isInteger(message.lifetime) && !(message.lifetime && $util.isInteger(message.lifetime.low) && $util.isInteger(message.lifetime.high)))
                    return "lifetime: integer|Long expected";
            if (message.uptime != null && message.hasOwnProperty("uptime"))
                if (!$util.isInteger(message.uptime) && !(message.uptime && $util.isInteger(message.uptime.low) && $util.isInteger(message.uptime.high)))
                    return "uptime: integer|Long expected";
            if (message.closeAddress != null && message.hasOwnProperty("closeAddress"))
                if (!$util.isString(message.closeAddress))
                    return "closeAddress: string expected";
            if (message.pushAmountSat != null && message.hasOwnProperty("pushAmountSat"))
                if (!$util.isInteger(message.pushAmountSat) && !(message.pushAmountSat && $util.isInteger(message.pushAmountSat.low) && $util.isInteger(message.pushAmountSat.high)))
                    return "pushAmountSat: integer|Long expected";
            if (message.thawHeight != null && message.hasOwnProperty("thawHeight"))
                if (!$util.isInteger(message.thawHeight))
                    return "thawHeight: integer expected";
            if (message.localConstraints != null && message.hasOwnProperty("localConstraints")) {
                let error = $root.lnrpc.ChannelConstraints.verify(message.localConstraints);
                if (error)
                    return "localConstraints." + error;
            }
            if (message.remoteConstraints != null && message.hasOwnProperty("remoteConstraints")) {
                let error = $root.lnrpc.ChannelConstraints.verify(message.remoteConstraints);
                if (error)
                    return "remoteConstraints." + error;
            }
            if (message.aliasScids != null && message.hasOwnProperty("aliasScids")) {
                if (!Array.isArray(message.aliasScids))
                    return "aliasScids: array expected";
                for (let i = 0; i < message.aliasScids.length; ++i)
                    if (!$util.isInteger(message.aliasScids[i]) && !(message.aliasScids[i] && $util.isInteger(message.aliasScids[i].low) && $util.isInteger(message.aliasScids[i].high)))
                        return "aliasScids: integer|Long[] expected";
            }
            if (message.zeroConf != null && message.hasOwnProperty("zeroConf"))
                if (typeof message.zeroConf !== "boolean")
                    return "zeroConf: boolean expected";
            if (message.zeroConfConfirmedScid != null && message.hasOwnProperty("zeroConfConfirmedScid"))
                if (!$util.isInteger(message.zeroConfConfirmedScid) && !(message.zeroConfConfirmedScid && $util.isInteger(message.zeroConfConfirmedScid.low) && $util.isInteger(message.zeroConfConfirmedScid.high)))
                    return "zeroConfConfirmedScid: integer|Long expected";
            return null;
        };

        /**
         * Creates a Channel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Channel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Channel} Channel
         */
        Channel.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Channel)
                return object;
            let message = new $root.lnrpc.Channel();
            if (object.active != null)
                message.active = Boolean(object.active);
            if (object.remotePubkey != null)
                message.remotePubkey = String(object.remotePubkey);
            if (object.channelPoint != null)
                message.channelPoint = String(object.channelPoint);
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.capacity != null)
                if ($util.Long)
                    (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false;
                else if (typeof object.capacity === "string")
                    message.capacity = parseInt(object.capacity, 10);
                else if (typeof object.capacity === "number")
                    message.capacity = object.capacity;
                else if (typeof object.capacity === "object")
                    message.capacity = new $util.LongBits(object.capacity.low >>> 0, object.capacity.high >>> 0).toNumber();
            if (object.localBalance != null)
                if ($util.Long)
                    (message.localBalance = $util.Long.fromValue(object.localBalance)).unsigned = false;
                else if (typeof object.localBalance === "string")
                    message.localBalance = parseInt(object.localBalance, 10);
                else if (typeof object.localBalance === "number")
                    message.localBalance = object.localBalance;
                else if (typeof object.localBalance === "object")
                    message.localBalance = new $util.LongBits(object.localBalance.low >>> 0, object.localBalance.high >>> 0).toNumber();
            if (object.remoteBalance != null)
                if ($util.Long)
                    (message.remoteBalance = $util.Long.fromValue(object.remoteBalance)).unsigned = false;
                else if (typeof object.remoteBalance === "string")
                    message.remoteBalance = parseInt(object.remoteBalance, 10);
                else if (typeof object.remoteBalance === "number")
                    message.remoteBalance = object.remoteBalance;
                else if (typeof object.remoteBalance === "object")
                    message.remoteBalance = new $util.LongBits(object.remoteBalance.low >>> 0, object.remoteBalance.high >>> 0).toNumber();
            if (object.commitFee != null)
                if ($util.Long)
                    (message.commitFee = $util.Long.fromValue(object.commitFee)).unsigned = false;
                else if (typeof object.commitFee === "string")
                    message.commitFee = parseInt(object.commitFee, 10);
                else if (typeof object.commitFee === "number")
                    message.commitFee = object.commitFee;
                else if (typeof object.commitFee === "object")
                    message.commitFee = new $util.LongBits(object.commitFee.low >>> 0, object.commitFee.high >>> 0).toNumber();
            if (object.commitWeight != null)
                if ($util.Long)
                    (message.commitWeight = $util.Long.fromValue(object.commitWeight)).unsigned = false;
                else if (typeof object.commitWeight === "string")
                    message.commitWeight = parseInt(object.commitWeight, 10);
                else if (typeof object.commitWeight === "number")
                    message.commitWeight = object.commitWeight;
                else if (typeof object.commitWeight === "object")
                    message.commitWeight = new $util.LongBits(object.commitWeight.low >>> 0, object.commitWeight.high >>> 0).toNumber();
            if (object.feePerKw != null)
                if ($util.Long)
                    (message.feePerKw = $util.Long.fromValue(object.feePerKw)).unsigned = false;
                else if (typeof object.feePerKw === "string")
                    message.feePerKw = parseInt(object.feePerKw, 10);
                else if (typeof object.feePerKw === "number")
                    message.feePerKw = object.feePerKw;
                else if (typeof object.feePerKw === "object")
                    message.feePerKw = new $util.LongBits(object.feePerKw.low >>> 0, object.feePerKw.high >>> 0).toNumber();
            if (object.unsettledBalance != null)
                if ($util.Long)
                    (message.unsettledBalance = $util.Long.fromValue(object.unsettledBalance)).unsigned = false;
                else if (typeof object.unsettledBalance === "string")
                    message.unsettledBalance = parseInt(object.unsettledBalance, 10);
                else if (typeof object.unsettledBalance === "number")
                    message.unsettledBalance = object.unsettledBalance;
                else if (typeof object.unsettledBalance === "object")
                    message.unsettledBalance = new $util.LongBits(object.unsettledBalance.low >>> 0, object.unsettledBalance.high >>> 0).toNumber();
            if (object.totalSatoshisSent != null)
                if ($util.Long)
                    (message.totalSatoshisSent = $util.Long.fromValue(object.totalSatoshisSent)).unsigned = false;
                else if (typeof object.totalSatoshisSent === "string")
                    message.totalSatoshisSent = parseInt(object.totalSatoshisSent, 10);
                else if (typeof object.totalSatoshisSent === "number")
                    message.totalSatoshisSent = object.totalSatoshisSent;
                else if (typeof object.totalSatoshisSent === "object")
                    message.totalSatoshisSent = new $util.LongBits(object.totalSatoshisSent.low >>> 0, object.totalSatoshisSent.high >>> 0).toNumber();
            if (object.totalSatoshisReceived != null)
                if ($util.Long)
                    (message.totalSatoshisReceived = $util.Long.fromValue(object.totalSatoshisReceived)).unsigned = false;
                else if (typeof object.totalSatoshisReceived === "string")
                    message.totalSatoshisReceived = parseInt(object.totalSatoshisReceived, 10);
                else if (typeof object.totalSatoshisReceived === "number")
                    message.totalSatoshisReceived = object.totalSatoshisReceived;
                else if (typeof object.totalSatoshisReceived === "object")
                    message.totalSatoshisReceived = new $util.LongBits(object.totalSatoshisReceived.low >>> 0, object.totalSatoshisReceived.high >>> 0).toNumber();
            if (object.numUpdates != null)
                if ($util.Long)
                    (message.numUpdates = $util.Long.fromValue(object.numUpdates)).unsigned = true;
                else if (typeof object.numUpdates === "string")
                    message.numUpdates = parseInt(object.numUpdates, 10);
                else if (typeof object.numUpdates === "number")
                    message.numUpdates = object.numUpdates;
                else if (typeof object.numUpdates === "object")
                    message.numUpdates = new $util.LongBits(object.numUpdates.low >>> 0, object.numUpdates.high >>> 0).toNumber(true);
            if (object.pendingHtlcs) {
                if (!Array.isArray(object.pendingHtlcs))
                    throw TypeError(".lnrpc.Channel.pendingHtlcs: array expected");
                message.pendingHtlcs = [];
                for (let i = 0; i < object.pendingHtlcs.length; ++i) {
                    if (typeof object.pendingHtlcs[i] !== "object")
                        throw TypeError(".lnrpc.Channel.pendingHtlcs: object expected");
                    message.pendingHtlcs[i] = $root.lnrpc.HTLC.fromObject(object.pendingHtlcs[i]);
                }
            }
            if (object.csvDelay != null)
                message.csvDelay = object.csvDelay >>> 0;
            if (object["private"] != null)
                message["private"] = Boolean(object["private"]);
            if (object.initiator != null)
                message.initiator = Boolean(object.initiator);
            if (object.chanStatusFlags != null)
                message.chanStatusFlags = String(object.chanStatusFlags);
            if (object.localChanReserveSat != null)
                if ($util.Long)
                    (message.localChanReserveSat = $util.Long.fromValue(object.localChanReserveSat)).unsigned = false;
                else if (typeof object.localChanReserveSat === "string")
                    message.localChanReserveSat = parseInt(object.localChanReserveSat, 10);
                else if (typeof object.localChanReserveSat === "number")
                    message.localChanReserveSat = object.localChanReserveSat;
                else if (typeof object.localChanReserveSat === "object")
                    message.localChanReserveSat = new $util.LongBits(object.localChanReserveSat.low >>> 0, object.localChanReserveSat.high >>> 0).toNumber();
            if (object.remoteChanReserveSat != null)
                if ($util.Long)
                    (message.remoteChanReserveSat = $util.Long.fromValue(object.remoteChanReserveSat)).unsigned = false;
                else if (typeof object.remoteChanReserveSat === "string")
                    message.remoteChanReserveSat = parseInt(object.remoteChanReserveSat, 10);
                else if (typeof object.remoteChanReserveSat === "number")
                    message.remoteChanReserveSat = object.remoteChanReserveSat;
                else if (typeof object.remoteChanReserveSat === "object")
                    message.remoteChanReserveSat = new $util.LongBits(object.remoteChanReserveSat.low >>> 0, object.remoteChanReserveSat.high >>> 0).toNumber();
            if (object.staticRemoteKey != null)
                message.staticRemoteKey = Boolean(object.staticRemoteKey);
            switch (object.commitmentType) {
            case "UNKNOWN_COMMITMENT_TYPE":
            case 0:
                message.commitmentType = 0;
                break;
            case "LEGACY":
            case 1:
                message.commitmentType = 1;
                break;
            case "STATIC_REMOTE_KEY":
            case 2:
                message.commitmentType = 2;
                break;
            case "ANCHORS":
            case 3:
                message.commitmentType = 3;
                break;
            case "SCRIPT_ENFORCED_LEASE":
            case 4:
                message.commitmentType = 4;
                break;
            }
            if (object.lifetime != null)
                if ($util.Long)
                    (message.lifetime = $util.Long.fromValue(object.lifetime)).unsigned = false;
                else if (typeof object.lifetime === "string")
                    message.lifetime = parseInt(object.lifetime, 10);
                else if (typeof object.lifetime === "number")
                    message.lifetime = object.lifetime;
                else if (typeof object.lifetime === "object")
                    message.lifetime = new $util.LongBits(object.lifetime.low >>> 0, object.lifetime.high >>> 0).toNumber();
            if (object.uptime != null)
                if ($util.Long)
                    (message.uptime = $util.Long.fromValue(object.uptime)).unsigned = false;
                else if (typeof object.uptime === "string")
                    message.uptime = parseInt(object.uptime, 10);
                else if (typeof object.uptime === "number")
                    message.uptime = object.uptime;
                else if (typeof object.uptime === "object")
                    message.uptime = new $util.LongBits(object.uptime.low >>> 0, object.uptime.high >>> 0).toNumber();
            if (object.closeAddress != null)
                message.closeAddress = String(object.closeAddress);
            if (object.pushAmountSat != null)
                if ($util.Long)
                    (message.pushAmountSat = $util.Long.fromValue(object.pushAmountSat)).unsigned = true;
                else if (typeof object.pushAmountSat === "string")
                    message.pushAmountSat = parseInt(object.pushAmountSat, 10);
                else if (typeof object.pushAmountSat === "number")
                    message.pushAmountSat = object.pushAmountSat;
                else if (typeof object.pushAmountSat === "object")
                    message.pushAmountSat = new $util.LongBits(object.pushAmountSat.low >>> 0, object.pushAmountSat.high >>> 0).toNumber(true);
            if (object.thawHeight != null)
                message.thawHeight = object.thawHeight >>> 0;
            if (object.localConstraints != null) {
                if (typeof object.localConstraints !== "object")
                    throw TypeError(".lnrpc.Channel.localConstraints: object expected");
                message.localConstraints = $root.lnrpc.ChannelConstraints.fromObject(object.localConstraints);
            }
            if (object.remoteConstraints != null) {
                if (typeof object.remoteConstraints !== "object")
                    throw TypeError(".lnrpc.Channel.remoteConstraints: object expected");
                message.remoteConstraints = $root.lnrpc.ChannelConstraints.fromObject(object.remoteConstraints);
            }
            if (object.aliasScids) {
                if (!Array.isArray(object.aliasScids))
                    throw TypeError(".lnrpc.Channel.aliasScids: array expected");
                message.aliasScids = [];
                for (let i = 0; i < object.aliasScids.length; ++i)
                    if ($util.Long)
                        (message.aliasScids[i] = $util.Long.fromValue(object.aliasScids[i])).unsigned = true;
                    else if (typeof object.aliasScids[i] === "string")
                        message.aliasScids[i] = parseInt(object.aliasScids[i], 10);
                    else if (typeof object.aliasScids[i] === "number")
                        message.aliasScids[i] = object.aliasScids[i];
                    else if (typeof object.aliasScids[i] === "object")
                        message.aliasScids[i] = new $util.LongBits(object.aliasScids[i].low >>> 0, object.aliasScids[i].high >>> 0).toNumber(true);
            }
            if (object.zeroConf != null)
                message.zeroConf = Boolean(object.zeroConf);
            if (object.zeroConfConfirmedScid != null)
                if ($util.Long)
                    (message.zeroConfConfirmedScid = $util.Long.fromValue(object.zeroConfConfirmedScid)).unsigned = true;
                else if (typeof object.zeroConfConfirmedScid === "string")
                    message.zeroConfConfirmedScid = parseInt(object.zeroConfConfirmedScid, 10);
                else if (typeof object.zeroConfConfirmedScid === "number")
                    message.zeroConfConfirmedScid = object.zeroConfConfirmedScid;
                else if (typeof object.zeroConfConfirmedScid === "object")
                    message.zeroConfConfirmedScid = new $util.LongBits(object.zeroConfConfirmedScid.low >>> 0, object.zeroConfConfirmedScid.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a Channel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Channel
         * @static
         * @param {lnrpc.Channel} message Channel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Channel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.pendingHtlcs = [];
                object.aliasScids = [];
            }
            if (options.defaults) {
                object.active = false;
                object.remotePubkey = "";
                object.channelPoint = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.capacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.capacity = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.localBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.remoteBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.remoteBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.commitFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.commitFee = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.commitWeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.commitWeight = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feePerKw = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feePerKw = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.unsettledBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.unsettledBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalSatoshisSent = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalSatoshisSent = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalSatoshisReceived = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalSatoshisReceived = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.numUpdates = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.numUpdates = options.longs === String ? "0" : 0;
                object.csvDelay = 0;
                object["private"] = false;
                object.initiator = false;
                object.chanStatusFlags = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.localChanReserveSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localChanReserveSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.remoteChanReserveSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.remoteChanReserveSat = options.longs === String ? "0" : 0;
                object.staticRemoteKey = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lifetime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lifetime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.uptime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.uptime = options.longs === String ? "0" : 0;
                object.closeAddress = "";
                object.commitmentType = options.enums === String ? "UNKNOWN_COMMITMENT_TYPE" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.pushAmountSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pushAmountSat = options.longs === String ? "0" : 0;
                object.thawHeight = 0;
                object.localConstraints = null;
                object.remoteConstraints = null;
                object.zeroConf = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.zeroConfConfirmedScid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.zeroConfConfirmedScid = options.longs === String ? "0" : 0;
            }
            if (message.active != null && message.hasOwnProperty("active"))
                object.active = message.active;
            if (message.remotePubkey != null && message.hasOwnProperty("remotePubkey"))
                object.remotePubkey = message.remotePubkey;
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                object.channelPoint = message.channelPoint;
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (typeof message.capacity === "number")
                    object.capacity = options.longs === String ? String(message.capacity) : message.capacity;
                else
                    object.capacity = options.longs === String ? $util.Long.prototype.toString.call(message.capacity) : options.longs === Number ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber() : message.capacity;
            if (message.localBalance != null && message.hasOwnProperty("localBalance"))
                if (typeof message.localBalance === "number")
                    object.localBalance = options.longs === String ? String(message.localBalance) : message.localBalance;
                else
                    object.localBalance = options.longs === String ? $util.Long.prototype.toString.call(message.localBalance) : options.longs === Number ? new $util.LongBits(message.localBalance.low >>> 0, message.localBalance.high >>> 0).toNumber() : message.localBalance;
            if (message.remoteBalance != null && message.hasOwnProperty("remoteBalance"))
                if (typeof message.remoteBalance === "number")
                    object.remoteBalance = options.longs === String ? String(message.remoteBalance) : message.remoteBalance;
                else
                    object.remoteBalance = options.longs === String ? $util.Long.prototype.toString.call(message.remoteBalance) : options.longs === Number ? new $util.LongBits(message.remoteBalance.low >>> 0, message.remoteBalance.high >>> 0).toNumber() : message.remoteBalance;
            if (message.commitFee != null && message.hasOwnProperty("commitFee"))
                if (typeof message.commitFee === "number")
                    object.commitFee = options.longs === String ? String(message.commitFee) : message.commitFee;
                else
                    object.commitFee = options.longs === String ? $util.Long.prototype.toString.call(message.commitFee) : options.longs === Number ? new $util.LongBits(message.commitFee.low >>> 0, message.commitFee.high >>> 0).toNumber() : message.commitFee;
            if (message.commitWeight != null && message.hasOwnProperty("commitWeight"))
                if (typeof message.commitWeight === "number")
                    object.commitWeight = options.longs === String ? String(message.commitWeight) : message.commitWeight;
                else
                    object.commitWeight = options.longs === String ? $util.Long.prototype.toString.call(message.commitWeight) : options.longs === Number ? new $util.LongBits(message.commitWeight.low >>> 0, message.commitWeight.high >>> 0).toNumber() : message.commitWeight;
            if (message.feePerKw != null && message.hasOwnProperty("feePerKw"))
                if (typeof message.feePerKw === "number")
                    object.feePerKw = options.longs === String ? String(message.feePerKw) : message.feePerKw;
                else
                    object.feePerKw = options.longs === String ? $util.Long.prototype.toString.call(message.feePerKw) : options.longs === Number ? new $util.LongBits(message.feePerKw.low >>> 0, message.feePerKw.high >>> 0).toNumber() : message.feePerKw;
            if (message.unsettledBalance != null && message.hasOwnProperty("unsettledBalance"))
                if (typeof message.unsettledBalance === "number")
                    object.unsettledBalance = options.longs === String ? String(message.unsettledBalance) : message.unsettledBalance;
                else
                    object.unsettledBalance = options.longs === String ? $util.Long.prototype.toString.call(message.unsettledBalance) : options.longs === Number ? new $util.LongBits(message.unsettledBalance.low >>> 0, message.unsettledBalance.high >>> 0).toNumber() : message.unsettledBalance;
            if (message.totalSatoshisSent != null && message.hasOwnProperty("totalSatoshisSent"))
                if (typeof message.totalSatoshisSent === "number")
                    object.totalSatoshisSent = options.longs === String ? String(message.totalSatoshisSent) : message.totalSatoshisSent;
                else
                    object.totalSatoshisSent = options.longs === String ? $util.Long.prototype.toString.call(message.totalSatoshisSent) : options.longs === Number ? new $util.LongBits(message.totalSatoshisSent.low >>> 0, message.totalSatoshisSent.high >>> 0).toNumber() : message.totalSatoshisSent;
            if (message.totalSatoshisReceived != null && message.hasOwnProperty("totalSatoshisReceived"))
                if (typeof message.totalSatoshisReceived === "number")
                    object.totalSatoshisReceived = options.longs === String ? String(message.totalSatoshisReceived) : message.totalSatoshisReceived;
                else
                    object.totalSatoshisReceived = options.longs === String ? $util.Long.prototype.toString.call(message.totalSatoshisReceived) : options.longs === Number ? new $util.LongBits(message.totalSatoshisReceived.low >>> 0, message.totalSatoshisReceived.high >>> 0).toNumber() : message.totalSatoshisReceived;
            if (message.numUpdates != null && message.hasOwnProperty("numUpdates"))
                if (typeof message.numUpdates === "number")
                    object.numUpdates = options.longs === String ? String(message.numUpdates) : message.numUpdates;
                else
                    object.numUpdates = options.longs === String ? $util.Long.prototype.toString.call(message.numUpdates) : options.longs === Number ? new $util.LongBits(message.numUpdates.low >>> 0, message.numUpdates.high >>> 0).toNumber(true) : message.numUpdates;
            if (message.pendingHtlcs && message.pendingHtlcs.length) {
                object.pendingHtlcs = [];
                for (let j = 0; j < message.pendingHtlcs.length; ++j)
                    object.pendingHtlcs[j] = $root.lnrpc.HTLC.toObject(message.pendingHtlcs[j], options);
            }
            if (message.csvDelay != null && message.hasOwnProperty("csvDelay"))
                object.csvDelay = message.csvDelay;
            if (message["private"] != null && message.hasOwnProperty("private"))
                object["private"] = message["private"];
            if (message.initiator != null && message.hasOwnProperty("initiator"))
                object.initiator = message.initiator;
            if (message.chanStatusFlags != null && message.hasOwnProperty("chanStatusFlags"))
                object.chanStatusFlags = message.chanStatusFlags;
            if (message.localChanReserveSat != null && message.hasOwnProperty("localChanReserveSat"))
                if (typeof message.localChanReserveSat === "number")
                    object.localChanReserveSat = options.longs === String ? String(message.localChanReserveSat) : message.localChanReserveSat;
                else
                    object.localChanReserveSat = options.longs === String ? $util.Long.prototype.toString.call(message.localChanReserveSat) : options.longs === Number ? new $util.LongBits(message.localChanReserveSat.low >>> 0, message.localChanReserveSat.high >>> 0).toNumber() : message.localChanReserveSat;
            if (message.remoteChanReserveSat != null && message.hasOwnProperty("remoteChanReserveSat"))
                if (typeof message.remoteChanReserveSat === "number")
                    object.remoteChanReserveSat = options.longs === String ? String(message.remoteChanReserveSat) : message.remoteChanReserveSat;
                else
                    object.remoteChanReserveSat = options.longs === String ? $util.Long.prototype.toString.call(message.remoteChanReserveSat) : options.longs === Number ? new $util.LongBits(message.remoteChanReserveSat.low >>> 0, message.remoteChanReserveSat.high >>> 0).toNumber() : message.remoteChanReserveSat;
            if (message.staticRemoteKey != null && message.hasOwnProperty("staticRemoteKey"))
                object.staticRemoteKey = message.staticRemoteKey;
            if (message.lifetime != null && message.hasOwnProperty("lifetime"))
                if (typeof message.lifetime === "number")
                    object.lifetime = options.longs === String ? String(message.lifetime) : message.lifetime;
                else
                    object.lifetime = options.longs === String ? $util.Long.prototype.toString.call(message.lifetime) : options.longs === Number ? new $util.LongBits(message.lifetime.low >>> 0, message.lifetime.high >>> 0).toNumber() : message.lifetime;
            if (message.uptime != null && message.hasOwnProperty("uptime"))
                if (typeof message.uptime === "number")
                    object.uptime = options.longs === String ? String(message.uptime) : message.uptime;
                else
                    object.uptime = options.longs === String ? $util.Long.prototype.toString.call(message.uptime) : options.longs === Number ? new $util.LongBits(message.uptime.low >>> 0, message.uptime.high >>> 0).toNumber() : message.uptime;
            if (message.closeAddress != null && message.hasOwnProperty("closeAddress"))
                object.closeAddress = message.closeAddress;
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                object.commitmentType = options.enums === String ? $root.lnrpc.CommitmentType[message.commitmentType] : message.commitmentType;
            if (message.pushAmountSat != null && message.hasOwnProperty("pushAmountSat"))
                if (typeof message.pushAmountSat === "number")
                    object.pushAmountSat = options.longs === String ? String(message.pushAmountSat) : message.pushAmountSat;
                else
                    object.pushAmountSat = options.longs === String ? $util.Long.prototype.toString.call(message.pushAmountSat) : options.longs === Number ? new $util.LongBits(message.pushAmountSat.low >>> 0, message.pushAmountSat.high >>> 0).toNumber(true) : message.pushAmountSat;
            if (message.thawHeight != null && message.hasOwnProperty("thawHeight"))
                object.thawHeight = message.thawHeight;
            if (message.localConstraints != null && message.hasOwnProperty("localConstraints"))
                object.localConstraints = $root.lnrpc.ChannelConstraints.toObject(message.localConstraints, options);
            if (message.remoteConstraints != null && message.hasOwnProperty("remoteConstraints"))
                object.remoteConstraints = $root.lnrpc.ChannelConstraints.toObject(message.remoteConstraints, options);
            if (message.aliasScids && message.aliasScids.length) {
                object.aliasScids = [];
                for (let j = 0; j < message.aliasScids.length; ++j)
                    if (typeof message.aliasScids[j] === "number")
                        object.aliasScids[j] = options.longs === String ? String(message.aliasScids[j]) : message.aliasScids[j];
                    else
                        object.aliasScids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.aliasScids[j]) : options.longs === Number ? new $util.LongBits(message.aliasScids[j].low >>> 0, message.aliasScids[j].high >>> 0).toNumber(true) : message.aliasScids[j];
            }
            if (message.zeroConf != null && message.hasOwnProperty("zeroConf"))
                object.zeroConf = message.zeroConf;
            if (message.zeroConfConfirmedScid != null && message.hasOwnProperty("zeroConfConfirmedScid"))
                if (typeof message.zeroConfConfirmedScid === "number")
                    object.zeroConfConfirmedScid = options.longs === String ? String(message.zeroConfConfirmedScid) : message.zeroConfConfirmedScid;
                else
                    object.zeroConfConfirmedScid = options.longs === String ? $util.Long.prototype.toString.call(message.zeroConfConfirmedScid) : options.longs === Number ? new $util.LongBits(message.zeroConfConfirmedScid.low >>> 0, message.zeroConfConfirmedScid.high >>> 0).toNumber(true) : message.zeroConfConfirmedScid;
            return object;
        };

        /**
         * Converts this Channel to JSON.
         * @function toJSON
         * @memberof lnrpc.Channel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Channel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Channel;
    })();

    lnrpc.ListChannelsRequest = (function() {

        /**
         * Properties of a ListChannelsRequest.
         * @memberof lnrpc
         * @interface IListChannelsRequest
         * @property {boolean|null} [activeOnly] ListChannelsRequest activeOnly
         * @property {boolean|null} [inactiveOnly] ListChannelsRequest inactiveOnly
         * @property {boolean|null} [publicOnly] ListChannelsRequest publicOnly
         * @property {boolean|null} [privateOnly] ListChannelsRequest privateOnly
         * @property {Uint8Array|null} [peer] ListChannelsRequest peer
         */

        /**
         * Constructs a new ListChannelsRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListChannelsRequest.
         * @implements IListChannelsRequest
         * @constructor
         * @param {lnrpc.IListChannelsRequest=} [properties] Properties to set
         */
        function ListChannelsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListChannelsRequest activeOnly.
         * @member {boolean} activeOnly
         * @memberof lnrpc.ListChannelsRequest
         * @instance
         */
        ListChannelsRequest.prototype.activeOnly = false;

        /**
         * ListChannelsRequest inactiveOnly.
         * @member {boolean} inactiveOnly
         * @memberof lnrpc.ListChannelsRequest
         * @instance
         */
        ListChannelsRequest.prototype.inactiveOnly = false;

        /**
         * ListChannelsRequest publicOnly.
         * @member {boolean} publicOnly
         * @memberof lnrpc.ListChannelsRequest
         * @instance
         */
        ListChannelsRequest.prototype.publicOnly = false;

        /**
         * ListChannelsRequest privateOnly.
         * @member {boolean} privateOnly
         * @memberof lnrpc.ListChannelsRequest
         * @instance
         */
        ListChannelsRequest.prototype.privateOnly = false;

        /**
         * ListChannelsRequest peer.
         * @member {Uint8Array} peer
         * @memberof lnrpc.ListChannelsRequest
         * @instance
         */
        ListChannelsRequest.prototype.peer = $util.newBuffer([]);

        /**
         * Creates a new ListChannelsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {lnrpc.IListChannelsRequest=} [properties] Properties to set
         * @returns {lnrpc.ListChannelsRequest} ListChannelsRequest instance
         */
        ListChannelsRequest.create = function create(properties) {
            return new ListChannelsRequest(properties);
        };

        /**
         * Encodes the specified ListChannelsRequest message. Does not implicitly {@link lnrpc.ListChannelsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {lnrpc.IListChannelsRequest} message ListChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListChannelsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.activeOnly != null && Object.hasOwnProperty.call(message, "activeOnly"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.activeOnly);
            if (message.inactiveOnly != null && Object.hasOwnProperty.call(message, "inactiveOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.inactiveOnly);
            if (message.publicOnly != null && Object.hasOwnProperty.call(message, "publicOnly"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.publicOnly);
            if (message.privateOnly != null && Object.hasOwnProperty.call(message, "privateOnly"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.privateOnly);
            if (message.peer != null && Object.hasOwnProperty.call(message, "peer"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.peer);
            return writer;
        };

        /**
         * Encodes the specified ListChannelsRequest message, length delimited. Does not implicitly {@link lnrpc.ListChannelsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {lnrpc.IListChannelsRequest} message ListChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListChannelsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListChannelsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListChannelsRequest} ListChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListChannelsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListChannelsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.activeOnly = reader.bool();
                        break;
                    }
                case 2: {
                        message.inactiveOnly = reader.bool();
                        break;
                    }
                case 3: {
                        message.publicOnly = reader.bool();
                        break;
                    }
                case 4: {
                        message.privateOnly = reader.bool();
                        break;
                    }
                case 5: {
                        message.peer = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListChannelsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListChannelsRequest} ListChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListChannelsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListChannelsRequest message.
         * @function verify
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListChannelsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.activeOnly != null && message.hasOwnProperty("activeOnly"))
                if (typeof message.activeOnly !== "boolean")
                    return "activeOnly: boolean expected";
            if (message.inactiveOnly != null && message.hasOwnProperty("inactiveOnly"))
                if (typeof message.inactiveOnly !== "boolean")
                    return "inactiveOnly: boolean expected";
            if (message.publicOnly != null && message.hasOwnProperty("publicOnly"))
                if (typeof message.publicOnly !== "boolean")
                    return "publicOnly: boolean expected";
            if (message.privateOnly != null && message.hasOwnProperty("privateOnly"))
                if (typeof message.privateOnly !== "boolean")
                    return "privateOnly: boolean expected";
            if (message.peer != null && message.hasOwnProperty("peer"))
                if (!(message.peer && typeof message.peer.length === "number" || $util.isString(message.peer)))
                    return "peer: buffer expected";
            return null;
        };

        /**
         * Creates a ListChannelsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListChannelsRequest} ListChannelsRequest
         */
        ListChannelsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListChannelsRequest)
                return object;
            let message = new $root.lnrpc.ListChannelsRequest();
            if (object.activeOnly != null)
                message.activeOnly = Boolean(object.activeOnly);
            if (object.inactiveOnly != null)
                message.inactiveOnly = Boolean(object.inactiveOnly);
            if (object.publicOnly != null)
                message.publicOnly = Boolean(object.publicOnly);
            if (object.privateOnly != null)
                message.privateOnly = Boolean(object.privateOnly);
            if (object.peer != null)
                if (typeof object.peer === "string")
                    $util.base64.decode(object.peer, message.peer = $util.newBuffer($util.base64.length(object.peer)), 0);
                else if (object.peer.length)
                    message.peer = object.peer;
            return message;
        };

        /**
         * Creates a plain object from a ListChannelsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListChannelsRequest
         * @static
         * @param {lnrpc.ListChannelsRequest} message ListChannelsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListChannelsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.activeOnly = false;
                object.inactiveOnly = false;
                object.publicOnly = false;
                object.privateOnly = false;
                if (options.bytes === String)
                    object.peer = "";
                else {
                    object.peer = [];
                    if (options.bytes !== Array)
                        object.peer = $util.newBuffer(object.peer);
                }
            }
            if (message.activeOnly != null && message.hasOwnProperty("activeOnly"))
                object.activeOnly = message.activeOnly;
            if (message.inactiveOnly != null && message.hasOwnProperty("inactiveOnly"))
                object.inactiveOnly = message.inactiveOnly;
            if (message.publicOnly != null && message.hasOwnProperty("publicOnly"))
                object.publicOnly = message.publicOnly;
            if (message.privateOnly != null && message.hasOwnProperty("privateOnly"))
                object.privateOnly = message.privateOnly;
            if (message.peer != null && message.hasOwnProperty("peer"))
                object.peer = options.bytes === String ? $util.base64.encode(message.peer, 0, message.peer.length) : options.bytes === Array ? Array.prototype.slice.call(message.peer) : message.peer;
            return object;
        };

        /**
         * Converts this ListChannelsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListChannelsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListChannelsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListChannelsRequest;
    })();

    lnrpc.ListChannelsResponse = (function() {

        /**
         * Properties of a ListChannelsResponse.
         * @memberof lnrpc
         * @interface IListChannelsResponse
         * @property {Array.<lnrpc.IChannel>|null} [channels] ListChannelsResponse channels
         */

        /**
         * Constructs a new ListChannelsResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListChannelsResponse.
         * @implements IListChannelsResponse
         * @constructor
         * @param {lnrpc.IListChannelsResponse=} [properties] Properties to set
         */
        function ListChannelsResponse(properties) {
            this.channels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListChannelsResponse channels.
         * @member {Array.<lnrpc.IChannel>} channels
         * @memberof lnrpc.ListChannelsResponse
         * @instance
         */
        ListChannelsResponse.prototype.channels = $util.emptyArray;

        /**
         * Creates a new ListChannelsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {lnrpc.IListChannelsResponse=} [properties] Properties to set
         * @returns {lnrpc.ListChannelsResponse} ListChannelsResponse instance
         */
        ListChannelsResponse.create = function create(properties) {
            return new ListChannelsResponse(properties);
        };

        /**
         * Encodes the specified ListChannelsResponse message. Does not implicitly {@link lnrpc.ListChannelsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {lnrpc.IListChannelsResponse} message ListChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListChannelsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channels != null && message.channels.length)
                for (let i = 0; i < message.channels.length; ++i)
                    $root.lnrpc.Channel.encode(message.channels[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListChannelsResponse message, length delimited. Does not implicitly {@link lnrpc.ListChannelsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {lnrpc.IListChannelsResponse} message ListChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListChannelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListChannelsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListChannelsResponse} ListChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListChannelsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListChannelsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 11: {
                        if (!(message.channels && message.channels.length))
                            message.channels = [];
                        message.channels.push($root.lnrpc.Channel.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListChannelsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListChannelsResponse} ListChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListChannelsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListChannelsResponse message.
         * @function verify
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListChannelsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channels != null && message.hasOwnProperty("channels")) {
                if (!Array.isArray(message.channels))
                    return "channels: array expected";
                for (let i = 0; i < message.channels.length; ++i) {
                    let error = $root.lnrpc.Channel.verify(message.channels[i]);
                    if (error)
                        return "channels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListChannelsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListChannelsResponse} ListChannelsResponse
         */
        ListChannelsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListChannelsResponse)
                return object;
            let message = new $root.lnrpc.ListChannelsResponse();
            if (object.channels) {
                if (!Array.isArray(object.channels))
                    throw TypeError(".lnrpc.ListChannelsResponse.channels: array expected");
                message.channels = [];
                for (let i = 0; i < object.channels.length; ++i) {
                    if (typeof object.channels[i] !== "object")
                        throw TypeError(".lnrpc.ListChannelsResponse.channels: object expected");
                    message.channels[i] = $root.lnrpc.Channel.fromObject(object.channels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListChannelsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListChannelsResponse
         * @static
         * @param {lnrpc.ListChannelsResponse} message ListChannelsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListChannelsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.channels = [];
            if (message.channels && message.channels.length) {
                object.channels = [];
                for (let j = 0; j < message.channels.length; ++j)
                    object.channels[j] = $root.lnrpc.Channel.toObject(message.channels[j], options);
            }
            return object;
        };

        /**
         * Converts this ListChannelsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListChannelsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListChannelsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListChannelsResponse;
    })();

    lnrpc.AliasMap = (function() {

        /**
         * Properties of an AliasMap.
         * @memberof lnrpc
         * @interface IAliasMap
         * @property {Long|null} [baseScid] AliasMap baseScid
         * @property {Array.<Long>|null} [aliases] AliasMap aliases
         */

        /**
         * Constructs a new AliasMap.
         * @memberof lnrpc
         * @classdesc Represents an AliasMap.
         * @implements IAliasMap
         * @constructor
         * @param {lnrpc.IAliasMap=} [properties] Properties to set
         */
        function AliasMap(properties) {
            this.aliases = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AliasMap baseScid.
         * @member {Long} baseScid
         * @memberof lnrpc.AliasMap
         * @instance
         */
        AliasMap.prototype.baseScid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AliasMap aliases.
         * @member {Array.<Long>} aliases
         * @memberof lnrpc.AliasMap
         * @instance
         */
        AliasMap.prototype.aliases = $util.emptyArray;

        /**
         * Creates a new AliasMap instance using the specified properties.
         * @function create
         * @memberof lnrpc.AliasMap
         * @static
         * @param {lnrpc.IAliasMap=} [properties] Properties to set
         * @returns {lnrpc.AliasMap} AliasMap instance
         */
        AliasMap.create = function create(properties) {
            return new AliasMap(properties);
        };

        /**
         * Encodes the specified AliasMap message. Does not implicitly {@link lnrpc.AliasMap.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AliasMap
         * @static
         * @param {lnrpc.IAliasMap} message AliasMap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AliasMap.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.baseScid != null && Object.hasOwnProperty.call(message, "baseScid"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.baseScid);
            if (message.aliases != null && message.aliases.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.aliases.length; ++i)
                    writer.uint64(message.aliases[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified AliasMap message, length delimited. Does not implicitly {@link lnrpc.AliasMap.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AliasMap
         * @static
         * @param {lnrpc.IAliasMap} message AliasMap message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AliasMap.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AliasMap message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AliasMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AliasMap} AliasMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AliasMap.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AliasMap();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.baseScid = reader.uint64();
                        break;
                    }
                case 2: {
                        if (!(message.aliases && message.aliases.length))
                            message.aliases = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.aliases.push(reader.uint64());
                        } else
                            message.aliases.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AliasMap message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AliasMap
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AliasMap} AliasMap
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AliasMap.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AliasMap message.
         * @function verify
         * @memberof lnrpc.AliasMap
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AliasMap.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.baseScid != null && message.hasOwnProperty("baseScid"))
                if (!$util.isInteger(message.baseScid) && !(message.baseScid && $util.isInteger(message.baseScid.low) && $util.isInteger(message.baseScid.high)))
                    return "baseScid: integer|Long expected";
            if (message.aliases != null && message.hasOwnProperty("aliases")) {
                if (!Array.isArray(message.aliases))
                    return "aliases: array expected";
                for (let i = 0; i < message.aliases.length; ++i)
                    if (!$util.isInteger(message.aliases[i]) && !(message.aliases[i] && $util.isInteger(message.aliases[i].low) && $util.isInteger(message.aliases[i].high)))
                        return "aliases: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates an AliasMap message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AliasMap
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AliasMap} AliasMap
         */
        AliasMap.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AliasMap)
                return object;
            let message = new $root.lnrpc.AliasMap();
            if (object.baseScid != null)
                if ($util.Long)
                    (message.baseScid = $util.Long.fromValue(object.baseScid)).unsigned = true;
                else if (typeof object.baseScid === "string")
                    message.baseScid = parseInt(object.baseScid, 10);
                else if (typeof object.baseScid === "number")
                    message.baseScid = object.baseScid;
                else if (typeof object.baseScid === "object")
                    message.baseScid = new $util.LongBits(object.baseScid.low >>> 0, object.baseScid.high >>> 0).toNumber(true);
            if (object.aliases) {
                if (!Array.isArray(object.aliases))
                    throw TypeError(".lnrpc.AliasMap.aliases: array expected");
                message.aliases = [];
                for (let i = 0; i < object.aliases.length; ++i)
                    if ($util.Long)
                        (message.aliases[i] = $util.Long.fromValue(object.aliases[i])).unsigned = true;
                    else if (typeof object.aliases[i] === "string")
                        message.aliases[i] = parseInt(object.aliases[i], 10);
                    else if (typeof object.aliases[i] === "number")
                        message.aliases[i] = object.aliases[i];
                    else if (typeof object.aliases[i] === "object")
                        message.aliases[i] = new $util.LongBits(object.aliases[i].low >>> 0, object.aliases[i].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from an AliasMap message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AliasMap
         * @static
         * @param {lnrpc.AliasMap} message AliasMap
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AliasMap.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.aliases = [];
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.baseScid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.baseScid = options.longs === String ? "0" : 0;
            if (message.baseScid != null && message.hasOwnProperty("baseScid"))
                if (typeof message.baseScid === "number")
                    object.baseScid = options.longs === String ? String(message.baseScid) : message.baseScid;
                else
                    object.baseScid = options.longs === String ? $util.Long.prototype.toString.call(message.baseScid) : options.longs === Number ? new $util.LongBits(message.baseScid.low >>> 0, message.baseScid.high >>> 0).toNumber(true) : message.baseScid;
            if (message.aliases && message.aliases.length) {
                object.aliases = [];
                for (let j = 0; j < message.aliases.length; ++j)
                    if (typeof message.aliases[j] === "number")
                        object.aliases[j] = options.longs === String ? String(message.aliases[j]) : message.aliases[j];
                    else
                        object.aliases[j] = options.longs === String ? $util.Long.prototype.toString.call(message.aliases[j]) : options.longs === Number ? new $util.LongBits(message.aliases[j].low >>> 0, message.aliases[j].high >>> 0).toNumber(true) : message.aliases[j];
            }
            return object;
        };

        /**
         * Converts this AliasMap to JSON.
         * @function toJSON
         * @memberof lnrpc.AliasMap
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AliasMap.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AliasMap;
    })();

    lnrpc.ListAliasesRequest = (function() {

        /**
         * Properties of a ListAliasesRequest.
         * @memberof lnrpc
         * @interface IListAliasesRequest
         */

        /**
         * Constructs a new ListAliasesRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListAliasesRequest.
         * @implements IListAliasesRequest
         * @constructor
         * @param {lnrpc.IListAliasesRequest=} [properties] Properties to set
         */
        function ListAliasesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ListAliasesRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {lnrpc.IListAliasesRequest=} [properties] Properties to set
         * @returns {lnrpc.ListAliasesRequest} ListAliasesRequest instance
         */
        ListAliasesRequest.create = function create(properties) {
            return new ListAliasesRequest(properties);
        };

        /**
         * Encodes the specified ListAliasesRequest message. Does not implicitly {@link lnrpc.ListAliasesRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {lnrpc.IListAliasesRequest} message ListAliasesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAliasesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ListAliasesRequest message, length delimited. Does not implicitly {@link lnrpc.ListAliasesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {lnrpc.IListAliasesRequest} message ListAliasesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAliasesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListAliasesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListAliasesRequest} ListAliasesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAliasesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListAliasesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListAliasesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListAliasesRequest} ListAliasesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAliasesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListAliasesRequest message.
         * @function verify
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListAliasesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ListAliasesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListAliasesRequest} ListAliasesRequest
         */
        ListAliasesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListAliasesRequest)
                return object;
            return new $root.lnrpc.ListAliasesRequest();
        };

        /**
         * Creates a plain object from a ListAliasesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListAliasesRequest
         * @static
         * @param {lnrpc.ListAliasesRequest} message ListAliasesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListAliasesRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ListAliasesRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListAliasesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListAliasesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListAliasesRequest;
    })();

    lnrpc.ListAliasesResponse = (function() {

        /**
         * Properties of a ListAliasesResponse.
         * @memberof lnrpc
         * @interface IListAliasesResponse
         * @property {Array.<lnrpc.IAliasMap>|null} [aliasMaps] ListAliasesResponse aliasMaps
         */

        /**
         * Constructs a new ListAliasesResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListAliasesResponse.
         * @implements IListAliasesResponse
         * @constructor
         * @param {lnrpc.IListAliasesResponse=} [properties] Properties to set
         */
        function ListAliasesResponse(properties) {
            this.aliasMaps = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListAliasesResponse aliasMaps.
         * @member {Array.<lnrpc.IAliasMap>} aliasMaps
         * @memberof lnrpc.ListAliasesResponse
         * @instance
         */
        ListAliasesResponse.prototype.aliasMaps = $util.emptyArray;

        /**
         * Creates a new ListAliasesResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {lnrpc.IListAliasesResponse=} [properties] Properties to set
         * @returns {lnrpc.ListAliasesResponse} ListAliasesResponse instance
         */
        ListAliasesResponse.create = function create(properties) {
            return new ListAliasesResponse(properties);
        };

        /**
         * Encodes the specified ListAliasesResponse message. Does not implicitly {@link lnrpc.ListAliasesResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {lnrpc.IListAliasesResponse} message ListAliasesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAliasesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.aliasMaps != null && message.aliasMaps.length)
                for (let i = 0; i < message.aliasMaps.length; ++i)
                    $root.lnrpc.AliasMap.encode(message.aliasMaps[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListAliasesResponse message, length delimited. Does not implicitly {@link lnrpc.ListAliasesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {lnrpc.IListAliasesResponse} message ListAliasesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAliasesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListAliasesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListAliasesResponse} ListAliasesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAliasesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListAliasesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.aliasMaps && message.aliasMaps.length))
                            message.aliasMaps = [];
                        message.aliasMaps.push($root.lnrpc.AliasMap.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListAliasesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListAliasesResponse} ListAliasesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAliasesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListAliasesResponse message.
         * @function verify
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListAliasesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.aliasMaps != null && message.hasOwnProperty("aliasMaps")) {
                if (!Array.isArray(message.aliasMaps))
                    return "aliasMaps: array expected";
                for (let i = 0; i < message.aliasMaps.length; ++i) {
                    let error = $root.lnrpc.AliasMap.verify(message.aliasMaps[i]);
                    if (error)
                        return "aliasMaps." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListAliasesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListAliasesResponse} ListAliasesResponse
         */
        ListAliasesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListAliasesResponse)
                return object;
            let message = new $root.lnrpc.ListAliasesResponse();
            if (object.aliasMaps) {
                if (!Array.isArray(object.aliasMaps))
                    throw TypeError(".lnrpc.ListAliasesResponse.aliasMaps: array expected");
                message.aliasMaps = [];
                for (let i = 0; i < object.aliasMaps.length; ++i) {
                    if (typeof object.aliasMaps[i] !== "object")
                        throw TypeError(".lnrpc.ListAliasesResponse.aliasMaps: object expected");
                    message.aliasMaps[i] = $root.lnrpc.AliasMap.fromObject(object.aliasMaps[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListAliasesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListAliasesResponse
         * @static
         * @param {lnrpc.ListAliasesResponse} message ListAliasesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListAliasesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.aliasMaps = [];
            if (message.aliasMaps && message.aliasMaps.length) {
                object.aliasMaps = [];
                for (let j = 0; j < message.aliasMaps.length; ++j)
                    object.aliasMaps[j] = $root.lnrpc.AliasMap.toObject(message.aliasMaps[j], options);
            }
            return object;
        };

        /**
         * Converts this ListAliasesResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListAliasesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListAliasesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListAliasesResponse;
    })();

    /**
     * Initiator enum.
     * @name lnrpc.Initiator
     * @enum {number}
     * @property {number} INITIATOR_UNKNOWN=0 INITIATOR_UNKNOWN value
     * @property {number} INITIATOR_LOCAL=1 INITIATOR_LOCAL value
     * @property {number} INITIATOR_REMOTE=2 INITIATOR_REMOTE value
     * @property {number} INITIATOR_BOTH=3 INITIATOR_BOTH value
     */
    lnrpc.Initiator = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "INITIATOR_UNKNOWN"] = 0;
        values[valuesById[1] = "INITIATOR_LOCAL"] = 1;
        values[valuesById[2] = "INITIATOR_REMOTE"] = 2;
        values[valuesById[3] = "INITIATOR_BOTH"] = 3;
        return values;
    })();

    lnrpc.ChannelCloseSummary = (function() {

        /**
         * Properties of a ChannelCloseSummary.
         * @memberof lnrpc
         * @interface IChannelCloseSummary
         * @property {string|null} [channelPoint] ChannelCloseSummary channelPoint
         * @property {Long|null} [chanId] ChannelCloseSummary chanId
         * @property {string|null} [chainHash] ChannelCloseSummary chainHash
         * @property {string|null} [closingTxHash] ChannelCloseSummary closingTxHash
         * @property {string|null} [remotePubkey] ChannelCloseSummary remotePubkey
         * @property {Long|null} [capacity] ChannelCloseSummary capacity
         * @property {number|null} [closeHeight] ChannelCloseSummary closeHeight
         * @property {Long|null} [settledBalance] ChannelCloseSummary settledBalance
         * @property {Long|null} [timeLockedBalance] ChannelCloseSummary timeLockedBalance
         * @property {lnrpc.ChannelCloseSummary.ClosureType|null} [closeType] ChannelCloseSummary closeType
         * @property {lnrpc.Initiator|null} [openInitiator] ChannelCloseSummary openInitiator
         * @property {lnrpc.Initiator|null} [closeInitiator] ChannelCloseSummary closeInitiator
         * @property {Array.<lnrpc.IResolution>|null} [resolutions] ChannelCloseSummary resolutions
         * @property {Array.<Long>|null} [aliasScids] ChannelCloseSummary aliasScids
         * @property {Long|null} [zeroConfConfirmedScid] ChannelCloseSummary zeroConfConfirmedScid
         */

        /**
         * Constructs a new ChannelCloseSummary.
         * @memberof lnrpc
         * @classdesc Represents a ChannelCloseSummary.
         * @implements IChannelCloseSummary
         * @constructor
         * @param {lnrpc.IChannelCloseSummary=} [properties] Properties to set
         */
        function ChannelCloseSummary(properties) {
            this.resolutions = [];
            this.aliasScids = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelCloseSummary channelPoint.
         * @member {string} channelPoint
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.channelPoint = "";

        /**
         * ChannelCloseSummary chanId.
         * @member {Long} chanId
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelCloseSummary chainHash.
         * @member {string} chainHash
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.chainHash = "";

        /**
         * ChannelCloseSummary closingTxHash.
         * @member {string} closingTxHash
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.closingTxHash = "";

        /**
         * ChannelCloseSummary remotePubkey.
         * @member {string} remotePubkey
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.remotePubkey = "";

        /**
         * ChannelCloseSummary capacity.
         * @member {Long} capacity
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelCloseSummary closeHeight.
         * @member {number} closeHeight
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.closeHeight = 0;

        /**
         * ChannelCloseSummary settledBalance.
         * @member {Long} settledBalance
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.settledBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelCloseSummary timeLockedBalance.
         * @member {Long} timeLockedBalance
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.timeLockedBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelCloseSummary closeType.
         * @member {lnrpc.ChannelCloseSummary.ClosureType} closeType
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.closeType = 0;

        /**
         * ChannelCloseSummary openInitiator.
         * @member {lnrpc.Initiator} openInitiator
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.openInitiator = 0;

        /**
         * ChannelCloseSummary closeInitiator.
         * @member {lnrpc.Initiator} closeInitiator
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.closeInitiator = 0;

        /**
         * ChannelCloseSummary resolutions.
         * @member {Array.<lnrpc.IResolution>} resolutions
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.resolutions = $util.emptyArray;

        /**
         * ChannelCloseSummary aliasScids.
         * @member {Array.<Long>} aliasScids
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.aliasScids = $util.emptyArray;

        /**
         * ChannelCloseSummary zeroConfConfirmedScid.
         * @member {Long} zeroConfConfirmedScid
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         */
        ChannelCloseSummary.prototype.zeroConfConfirmedScid = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ChannelCloseSummary instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {lnrpc.IChannelCloseSummary=} [properties] Properties to set
         * @returns {lnrpc.ChannelCloseSummary} ChannelCloseSummary instance
         */
        ChannelCloseSummary.create = function create(properties) {
            return new ChannelCloseSummary(properties);
        };

        /**
         * Encodes the specified ChannelCloseSummary message. Does not implicitly {@link lnrpc.ChannelCloseSummary.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {lnrpc.IChannelCloseSummary} message ChannelCloseSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelCloseSummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelPoint);
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chanId);
            if (message.chainHash != null && Object.hasOwnProperty.call(message, "chainHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.chainHash);
            if (message.closingTxHash != null && Object.hasOwnProperty.call(message, "closingTxHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.closingTxHash);
            if (message.remotePubkey != null && Object.hasOwnProperty.call(message, "remotePubkey"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.remotePubkey);
            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.capacity);
            if (message.closeHeight != null && Object.hasOwnProperty.call(message, "closeHeight"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.closeHeight);
            if (message.settledBalance != null && Object.hasOwnProperty.call(message, "settledBalance"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.settledBalance);
            if (message.timeLockedBalance != null && Object.hasOwnProperty.call(message, "timeLockedBalance"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.timeLockedBalance);
            if (message.closeType != null && Object.hasOwnProperty.call(message, "closeType"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.closeType);
            if (message.openInitiator != null && Object.hasOwnProperty.call(message, "openInitiator"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.openInitiator);
            if (message.closeInitiator != null && Object.hasOwnProperty.call(message, "closeInitiator"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.closeInitiator);
            if (message.resolutions != null && message.resolutions.length)
                for (let i = 0; i < message.resolutions.length; ++i)
                    $root.lnrpc.Resolution.encode(message.resolutions[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.aliasScids != null && message.aliasScids.length) {
                writer.uint32(/* id 14, wireType 2 =*/114).fork();
                for (let i = 0; i < message.aliasScids.length; ++i)
                    writer.uint64(message.aliasScids[i]);
                writer.ldelim();
            }
            if (message.zeroConfConfirmedScid != null && Object.hasOwnProperty.call(message, "zeroConfConfirmedScid"))
                writer.uint32(/* id 15, wireType 0 =*/120).uint64(message.zeroConfConfirmedScid);
            return writer;
        };

        /**
         * Encodes the specified ChannelCloseSummary message, length delimited. Does not implicitly {@link lnrpc.ChannelCloseSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {lnrpc.IChannelCloseSummary} message ChannelCloseSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelCloseSummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelCloseSummary message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelCloseSummary} ChannelCloseSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelCloseSummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelCloseSummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelPoint = reader.string();
                        break;
                    }
                case 2: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 3: {
                        message.chainHash = reader.string();
                        break;
                    }
                case 4: {
                        message.closingTxHash = reader.string();
                        break;
                    }
                case 5: {
                        message.remotePubkey = reader.string();
                        break;
                    }
                case 6: {
                        message.capacity = reader.int64();
                        break;
                    }
                case 7: {
                        message.closeHeight = reader.uint32();
                        break;
                    }
                case 8: {
                        message.settledBalance = reader.int64();
                        break;
                    }
                case 9: {
                        message.timeLockedBalance = reader.int64();
                        break;
                    }
                case 10: {
                        message.closeType = reader.int32();
                        break;
                    }
                case 11: {
                        message.openInitiator = reader.int32();
                        break;
                    }
                case 12: {
                        message.closeInitiator = reader.int32();
                        break;
                    }
                case 13: {
                        if (!(message.resolutions && message.resolutions.length))
                            message.resolutions = [];
                        message.resolutions.push($root.lnrpc.Resolution.decode(reader, reader.uint32()));
                        break;
                    }
                case 14: {
                        if (!(message.aliasScids && message.aliasScids.length))
                            message.aliasScids = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.aliasScids.push(reader.uint64());
                        } else
                            message.aliasScids.push(reader.uint64());
                        break;
                    }
                case 15: {
                        message.zeroConfConfirmedScid = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelCloseSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelCloseSummary} ChannelCloseSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelCloseSummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelCloseSummary message.
         * @function verify
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelCloseSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                if (!$util.isString(message.channelPoint))
                    return "channelPoint: string expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.chainHash != null && message.hasOwnProperty("chainHash"))
                if (!$util.isString(message.chainHash))
                    return "chainHash: string expected";
            if (message.closingTxHash != null && message.hasOwnProperty("closingTxHash"))
                if (!$util.isString(message.closingTxHash))
                    return "closingTxHash: string expected";
            if (message.remotePubkey != null && message.hasOwnProperty("remotePubkey"))
                if (!$util.isString(message.remotePubkey))
                    return "remotePubkey: string expected";
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (!$util.isInteger(message.capacity) && !(message.capacity && $util.isInteger(message.capacity.low) && $util.isInteger(message.capacity.high)))
                    return "capacity: integer|Long expected";
            if (message.closeHeight != null && message.hasOwnProperty("closeHeight"))
                if (!$util.isInteger(message.closeHeight))
                    return "closeHeight: integer expected";
            if (message.settledBalance != null && message.hasOwnProperty("settledBalance"))
                if (!$util.isInteger(message.settledBalance) && !(message.settledBalance && $util.isInteger(message.settledBalance.low) && $util.isInteger(message.settledBalance.high)))
                    return "settledBalance: integer|Long expected";
            if (message.timeLockedBalance != null && message.hasOwnProperty("timeLockedBalance"))
                if (!$util.isInteger(message.timeLockedBalance) && !(message.timeLockedBalance && $util.isInteger(message.timeLockedBalance.low) && $util.isInteger(message.timeLockedBalance.high)))
                    return "timeLockedBalance: integer|Long expected";
            if (message.closeType != null && message.hasOwnProperty("closeType"))
                switch (message.closeType) {
                default:
                    return "closeType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.openInitiator != null && message.hasOwnProperty("openInitiator"))
                switch (message.openInitiator) {
                default:
                    return "openInitiator: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.closeInitiator != null && message.hasOwnProperty("closeInitiator"))
                switch (message.closeInitiator) {
                default:
                    return "closeInitiator: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.resolutions != null && message.hasOwnProperty("resolutions")) {
                if (!Array.isArray(message.resolutions))
                    return "resolutions: array expected";
                for (let i = 0; i < message.resolutions.length; ++i) {
                    let error = $root.lnrpc.Resolution.verify(message.resolutions[i]);
                    if (error)
                        return "resolutions." + error;
                }
            }
            if (message.aliasScids != null && message.hasOwnProperty("aliasScids")) {
                if (!Array.isArray(message.aliasScids))
                    return "aliasScids: array expected";
                for (let i = 0; i < message.aliasScids.length; ++i)
                    if (!$util.isInteger(message.aliasScids[i]) && !(message.aliasScids[i] && $util.isInteger(message.aliasScids[i].low) && $util.isInteger(message.aliasScids[i].high)))
                        return "aliasScids: integer|Long[] expected";
            }
            if (message.zeroConfConfirmedScid != null && message.hasOwnProperty("zeroConfConfirmedScid"))
                if (!$util.isInteger(message.zeroConfConfirmedScid) && !(message.zeroConfConfirmedScid && $util.isInteger(message.zeroConfConfirmedScid.low) && $util.isInteger(message.zeroConfConfirmedScid.high)))
                    return "zeroConfConfirmedScid: integer|Long expected";
            return null;
        };

        /**
         * Creates a ChannelCloseSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelCloseSummary} ChannelCloseSummary
         */
        ChannelCloseSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelCloseSummary)
                return object;
            let message = new $root.lnrpc.ChannelCloseSummary();
            if (object.channelPoint != null)
                message.channelPoint = String(object.channelPoint);
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.chainHash != null)
                message.chainHash = String(object.chainHash);
            if (object.closingTxHash != null)
                message.closingTxHash = String(object.closingTxHash);
            if (object.remotePubkey != null)
                message.remotePubkey = String(object.remotePubkey);
            if (object.capacity != null)
                if ($util.Long)
                    (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false;
                else if (typeof object.capacity === "string")
                    message.capacity = parseInt(object.capacity, 10);
                else if (typeof object.capacity === "number")
                    message.capacity = object.capacity;
                else if (typeof object.capacity === "object")
                    message.capacity = new $util.LongBits(object.capacity.low >>> 0, object.capacity.high >>> 0).toNumber();
            if (object.closeHeight != null)
                message.closeHeight = object.closeHeight >>> 0;
            if (object.settledBalance != null)
                if ($util.Long)
                    (message.settledBalance = $util.Long.fromValue(object.settledBalance)).unsigned = false;
                else if (typeof object.settledBalance === "string")
                    message.settledBalance = parseInt(object.settledBalance, 10);
                else if (typeof object.settledBalance === "number")
                    message.settledBalance = object.settledBalance;
                else if (typeof object.settledBalance === "object")
                    message.settledBalance = new $util.LongBits(object.settledBalance.low >>> 0, object.settledBalance.high >>> 0).toNumber();
            if (object.timeLockedBalance != null)
                if ($util.Long)
                    (message.timeLockedBalance = $util.Long.fromValue(object.timeLockedBalance)).unsigned = false;
                else if (typeof object.timeLockedBalance === "string")
                    message.timeLockedBalance = parseInt(object.timeLockedBalance, 10);
                else if (typeof object.timeLockedBalance === "number")
                    message.timeLockedBalance = object.timeLockedBalance;
                else if (typeof object.timeLockedBalance === "object")
                    message.timeLockedBalance = new $util.LongBits(object.timeLockedBalance.low >>> 0, object.timeLockedBalance.high >>> 0).toNumber();
            switch (object.closeType) {
            case "COOPERATIVE_CLOSE":
            case 0:
                message.closeType = 0;
                break;
            case "LOCAL_FORCE_CLOSE":
            case 1:
                message.closeType = 1;
                break;
            case "REMOTE_FORCE_CLOSE":
            case 2:
                message.closeType = 2;
                break;
            case "BREACH_CLOSE":
            case 3:
                message.closeType = 3;
                break;
            case "FUNDING_CANCELED":
            case 4:
                message.closeType = 4;
                break;
            case "ABANDONED":
            case 5:
                message.closeType = 5;
                break;
            }
            switch (object.openInitiator) {
            case "INITIATOR_UNKNOWN":
            case 0:
                message.openInitiator = 0;
                break;
            case "INITIATOR_LOCAL":
            case 1:
                message.openInitiator = 1;
                break;
            case "INITIATOR_REMOTE":
            case 2:
                message.openInitiator = 2;
                break;
            case "INITIATOR_BOTH":
            case 3:
                message.openInitiator = 3;
                break;
            }
            switch (object.closeInitiator) {
            case "INITIATOR_UNKNOWN":
            case 0:
                message.closeInitiator = 0;
                break;
            case "INITIATOR_LOCAL":
            case 1:
                message.closeInitiator = 1;
                break;
            case "INITIATOR_REMOTE":
            case 2:
                message.closeInitiator = 2;
                break;
            case "INITIATOR_BOTH":
            case 3:
                message.closeInitiator = 3;
                break;
            }
            if (object.resolutions) {
                if (!Array.isArray(object.resolutions))
                    throw TypeError(".lnrpc.ChannelCloseSummary.resolutions: array expected");
                message.resolutions = [];
                for (let i = 0; i < object.resolutions.length; ++i) {
                    if (typeof object.resolutions[i] !== "object")
                        throw TypeError(".lnrpc.ChannelCloseSummary.resolutions: object expected");
                    message.resolutions[i] = $root.lnrpc.Resolution.fromObject(object.resolutions[i]);
                }
            }
            if (object.aliasScids) {
                if (!Array.isArray(object.aliasScids))
                    throw TypeError(".lnrpc.ChannelCloseSummary.aliasScids: array expected");
                message.aliasScids = [];
                for (let i = 0; i < object.aliasScids.length; ++i)
                    if ($util.Long)
                        (message.aliasScids[i] = $util.Long.fromValue(object.aliasScids[i])).unsigned = true;
                    else if (typeof object.aliasScids[i] === "string")
                        message.aliasScids[i] = parseInt(object.aliasScids[i], 10);
                    else if (typeof object.aliasScids[i] === "number")
                        message.aliasScids[i] = object.aliasScids[i];
                    else if (typeof object.aliasScids[i] === "object")
                        message.aliasScids[i] = new $util.LongBits(object.aliasScids[i].low >>> 0, object.aliasScids[i].high >>> 0).toNumber(true);
            }
            if (object.zeroConfConfirmedScid != null)
                if ($util.Long)
                    (message.zeroConfConfirmedScid = $util.Long.fromValue(object.zeroConfConfirmedScid)).unsigned = true;
                else if (typeof object.zeroConfConfirmedScid === "string")
                    message.zeroConfConfirmedScid = parseInt(object.zeroConfConfirmedScid, 10);
                else if (typeof object.zeroConfConfirmedScid === "number")
                    message.zeroConfConfirmedScid = object.zeroConfConfirmedScid;
                else if (typeof object.zeroConfConfirmedScid === "object")
                    message.zeroConfConfirmedScid = new $util.LongBits(object.zeroConfConfirmedScid.low >>> 0, object.zeroConfConfirmedScid.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ChannelCloseSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelCloseSummary
         * @static
         * @param {lnrpc.ChannelCloseSummary} message ChannelCloseSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelCloseSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.resolutions = [];
                object.aliasScids = [];
            }
            if (options.defaults) {
                object.channelPoint = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                object.chainHash = "";
                object.closingTxHash = "";
                object.remotePubkey = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.capacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.capacity = options.longs === String ? "0" : 0;
                object.closeHeight = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.settledBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.settledBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timeLockedBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeLockedBalance = options.longs === String ? "0" : 0;
                object.closeType = options.enums === String ? "COOPERATIVE_CLOSE" : 0;
                object.openInitiator = options.enums === String ? "INITIATOR_UNKNOWN" : 0;
                object.closeInitiator = options.enums === String ? "INITIATOR_UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.zeroConfConfirmedScid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.zeroConfConfirmedScid = options.longs === String ? "0" : 0;
            }
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                object.channelPoint = message.channelPoint;
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.chainHash != null && message.hasOwnProperty("chainHash"))
                object.chainHash = message.chainHash;
            if (message.closingTxHash != null && message.hasOwnProperty("closingTxHash"))
                object.closingTxHash = message.closingTxHash;
            if (message.remotePubkey != null && message.hasOwnProperty("remotePubkey"))
                object.remotePubkey = message.remotePubkey;
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (typeof message.capacity === "number")
                    object.capacity = options.longs === String ? String(message.capacity) : message.capacity;
                else
                    object.capacity = options.longs === String ? $util.Long.prototype.toString.call(message.capacity) : options.longs === Number ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber() : message.capacity;
            if (message.closeHeight != null && message.hasOwnProperty("closeHeight"))
                object.closeHeight = message.closeHeight;
            if (message.settledBalance != null && message.hasOwnProperty("settledBalance"))
                if (typeof message.settledBalance === "number")
                    object.settledBalance = options.longs === String ? String(message.settledBalance) : message.settledBalance;
                else
                    object.settledBalance = options.longs === String ? $util.Long.prototype.toString.call(message.settledBalance) : options.longs === Number ? new $util.LongBits(message.settledBalance.low >>> 0, message.settledBalance.high >>> 0).toNumber() : message.settledBalance;
            if (message.timeLockedBalance != null && message.hasOwnProperty("timeLockedBalance"))
                if (typeof message.timeLockedBalance === "number")
                    object.timeLockedBalance = options.longs === String ? String(message.timeLockedBalance) : message.timeLockedBalance;
                else
                    object.timeLockedBalance = options.longs === String ? $util.Long.prototype.toString.call(message.timeLockedBalance) : options.longs === Number ? new $util.LongBits(message.timeLockedBalance.low >>> 0, message.timeLockedBalance.high >>> 0).toNumber() : message.timeLockedBalance;
            if (message.closeType != null && message.hasOwnProperty("closeType"))
                object.closeType = options.enums === String ? $root.lnrpc.ChannelCloseSummary.ClosureType[message.closeType] : message.closeType;
            if (message.openInitiator != null && message.hasOwnProperty("openInitiator"))
                object.openInitiator = options.enums === String ? $root.lnrpc.Initiator[message.openInitiator] : message.openInitiator;
            if (message.closeInitiator != null && message.hasOwnProperty("closeInitiator"))
                object.closeInitiator = options.enums === String ? $root.lnrpc.Initiator[message.closeInitiator] : message.closeInitiator;
            if (message.resolutions && message.resolutions.length) {
                object.resolutions = [];
                for (let j = 0; j < message.resolutions.length; ++j)
                    object.resolutions[j] = $root.lnrpc.Resolution.toObject(message.resolutions[j], options);
            }
            if (message.aliasScids && message.aliasScids.length) {
                object.aliasScids = [];
                for (let j = 0; j < message.aliasScids.length; ++j)
                    if (typeof message.aliasScids[j] === "number")
                        object.aliasScids[j] = options.longs === String ? String(message.aliasScids[j]) : message.aliasScids[j];
                    else
                        object.aliasScids[j] = options.longs === String ? $util.Long.prototype.toString.call(message.aliasScids[j]) : options.longs === Number ? new $util.LongBits(message.aliasScids[j].low >>> 0, message.aliasScids[j].high >>> 0).toNumber(true) : message.aliasScids[j];
            }
            if (message.zeroConfConfirmedScid != null && message.hasOwnProperty("zeroConfConfirmedScid"))
                if (typeof message.zeroConfConfirmedScid === "number")
                    object.zeroConfConfirmedScid = options.longs === String ? String(message.zeroConfConfirmedScid) : message.zeroConfConfirmedScid;
                else
                    object.zeroConfConfirmedScid = options.longs === String ? $util.Long.prototype.toString.call(message.zeroConfConfirmedScid) : options.longs === Number ? new $util.LongBits(message.zeroConfConfirmedScid.low >>> 0, message.zeroConfConfirmedScid.high >>> 0).toNumber(true) : message.zeroConfConfirmedScid;
            return object;
        };

        /**
         * Converts this ChannelCloseSummary to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelCloseSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelCloseSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * ClosureType enum.
         * @name lnrpc.ChannelCloseSummary.ClosureType
         * @enum {number}
         * @property {number} COOPERATIVE_CLOSE=0 COOPERATIVE_CLOSE value
         * @property {number} LOCAL_FORCE_CLOSE=1 LOCAL_FORCE_CLOSE value
         * @property {number} REMOTE_FORCE_CLOSE=2 REMOTE_FORCE_CLOSE value
         * @property {number} BREACH_CLOSE=3 BREACH_CLOSE value
         * @property {number} FUNDING_CANCELED=4 FUNDING_CANCELED value
         * @property {number} ABANDONED=5 ABANDONED value
         */
        ChannelCloseSummary.ClosureType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "COOPERATIVE_CLOSE"] = 0;
            values[valuesById[1] = "LOCAL_FORCE_CLOSE"] = 1;
            values[valuesById[2] = "REMOTE_FORCE_CLOSE"] = 2;
            values[valuesById[3] = "BREACH_CLOSE"] = 3;
            values[valuesById[4] = "FUNDING_CANCELED"] = 4;
            values[valuesById[5] = "ABANDONED"] = 5;
            return values;
        })();

        return ChannelCloseSummary;
    })();

    /**
     * ResolutionType enum.
     * @name lnrpc.ResolutionType
     * @enum {number}
     * @property {number} TYPE_UNKNOWN=0 TYPE_UNKNOWN value
     * @property {number} ANCHOR=1 ANCHOR value
     * @property {number} INCOMING_HTLC=2 INCOMING_HTLC value
     * @property {number} OUTGOING_HTLC=3 OUTGOING_HTLC value
     * @property {number} COMMIT=4 COMMIT value
     */
    lnrpc.ResolutionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TYPE_UNKNOWN"] = 0;
        values[valuesById[1] = "ANCHOR"] = 1;
        values[valuesById[2] = "INCOMING_HTLC"] = 2;
        values[valuesById[3] = "OUTGOING_HTLC"] = 3;
        values[valuesById[4] = "COMMIT"] = 4;
        return values;
    })();

    /**
     * ResolutionOutcome enum.
     * @name lnrpc.ResolutionOutcome
     * @enum {number}
     * @property {number} OUTCOME_UNKNOWN=0 OUTCOME_UNKNOWN value
     * @property {number} CLAIMED=1 CLAIMED value
     * @property {number} UNCLAIMED=2 UNCLAIMED value
     * @property {number} ABANDONED=3 ABANDONED value
     * @property {number} FIRST_STAGE=4 FIRST_STAGE value
     * @property {number} TIMEOUT=5 TIMEOUT value
     */
    lnrpc.ResolutionOutcome = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "OUTCOME_UNKNOWN"] = 0;
        values[valuesById[1] = "CLAIMED"] = 1;
        values[valuesById[2] = "UNCLAIMED"] = 2;
        values[valuesById[3] = "ABANDONED"] = 3;
        values[valuesById[4] = "FIRST_STAGE"] = 4;
        values[valuesById[5] = "TIMEOUT"] = 5;
        return values;
    })();

    lnrpc.Resolution = (function() {

        /**
         * Properties of a Resolution.
         * @memberof lnrpc
         * @interface IResolution
         * @property {lnrpc.ResolutionType|null} [resolutionType] Resolution resolutionType
         * @property {lnrpc.ResolutionOutcome|null} [outcome] Resolution outcome
         * @property {lnrpc.IOutPoint|null} [outpoint] Resolution outpoint
         * @property {Long|null} [amountSat] Resolution amountSat
         * @property {string|null} [sweepTxid] Resolution sweepTxid
         */

        /**
         * Constructs a new Resolution.
         * @memberof lnrpc
         * @classdesc Represents a Resolution.
         * @implements IResolution
         * @constructor
         * @param {lnrpc.IResolution=} [properties] Properties to set
         */
        function Resolution(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Resolution resolutionType.
         * @member {lnrpc.ResolutionType} resolutionType
         * @memberof lnrpc.Resolution
         * @instance
         */
        Resolution.prototype.resolutionType = 0;

        /**
         * Resolution outcome.
         * @member {lnrpc.ResolutionOutcome} outcome
         * @memberof lnrpc.Resolution
         * @instance
         */
        Resolution.prototype.outcome = 0;

        /**
         * Resolution outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof lnrpc.Resolution
         * @instance
         */
        Resolution.prototype.outpoint = null;

        /**
         * Resolution amountSat.
         * @member {Long} amountSat
         * @memberof lnrpc.Resolution
         * @instance
         */
        Resolution.prototype.amountSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Resolution sweepTxid.
         * @member {string} sweepTxid
         * @memberof lnrpc.Resolution
         * @instance
         */
        Resolution.prototype.sweepTxid = "";

        /**
         * Creates a new Resolution instance using the specified properties.
         * @function create
         * @memberof lnrpc.Resolution
         * @static
         * @param {lnrpc.IResolution=} [properties] Properties to set
         * @returns {lnrpc.Resolution} Resolution instance
         */
        Resolution.create = function create(properties) {
            return new Resolution(properties);
        };

        /**
         * Encodes the specified Resolution message. Does not implicitly {@link lnrpc.Resolution.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Resolution
         * @static
         * @param {lnrpc.IResolution} message Resolution message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Resolution.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.resolutionType != null && Object.hasOwnProperty.call(message, "resolutionType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.resolutionType);
            if (message.outcome != null && Object.hasOwnProperty.call(message, "outcome"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.outcome);
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.amountSat != null && Object.hasOwnProperty.call(message, "amountSat"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.amountSat);
            if (message.sweepTxid != null && Object.hasOwnProperty.call(message, "sweepTxid"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.sweepTxid);
            return writer;
        };

        /**
         * Encodes the specified Resolution message, length delimited. Does not implicitly {@link lnrpc.Resolution.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Resolution
         * @static
         * @param {lnrpc.IResolution} message Resolution message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Resolution.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Resolution message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Resolution
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Resolution} Resolution
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Resolution.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Resolution();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.resolutionType = reader.int32();
                        break;
                    }
                case 2: {
                        message.outcome = reader.int32();
                        break;
                    }
                case 3: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.amountSat = reader.uint64();
                        break;
                    }
                case 5: {
                        message.sweepTxid = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Resolution message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Resolution
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Resolution} Resolution
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Resolution.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Resolution message.
         * @function verify
         * @memberof lnrpc.Resolution
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Resolution.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.resolutionType != null && message.hasOwnProperty("resolutionType"))
                switch (message.resolutionType) {
                default:
                    return "resolutionType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.outcome != null && message.hasOwnProperty("outcome"))
                switch (message.outcome) {
                default:
                    return "outcome: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.amountSat != null && message.hasOwnProperty("amountSat"))
                if (!$util.isInteger(message.amountSat) && !(message.amountSat && $util.isInteger(message.amountSat.low) && $util.isInteger(message.amountSat.high)))
                    return "amountSat: integer|Long expected";
            if (message.sweepTxid != null && message.hasOwnProperty("sweepTxid"))
                if (!$util.isString(message.sweepTxid))
                    return "sweepTxid: string expected";
            return null;
        };

        /**
         * Creates a Resolution message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Resolution
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Resolution} Resolution
         */
        Resolution.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Resolution)
                return object;
            let message = new $root.lnrpc.Resolution();
            switch (object.resolutionType) {
            case "TYPE_UNKNOWN":
            case 0:
                message.resolutionType = 0;
                break;
            case "ANCHOR":
            case 1:
                message.resolutionType = 1;
                break;
            case "INCOMING_HTLC":
            case 2:
                message.resolutionType = 2;
                break;
            case "OUTGOING_HTLC":
            case 3:
                message.resolutionType = 3;
                break;
            case "COMMIT":
            case 4:
                message.resolutionType = 4;
                break;
            }
            switch (object.outcome) {
            case "OUTCOME_UNKNOWN":
            case 0:
                message.outcome = 0;
                break;
            case "CLAIMED":
            case 1:
                message.outcome = 1;
                break;
            case "UNCLAIMED":
            case 2:
                message.outcome = 2;
                break;
            case "ABANDONED":
            case 3:
                message.outcome = 3;
                break;
            case "FIRST_STAGE":
            case 4:
                message.outcome = 4;
                break;
            case "TIMEOUT":
            case 5:
                message.outcome = 5;
                break;
            }
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".lnrpc.Resolution.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            if (object.amountSat != null)
                if ($util.Long)
                    (message.amountSat = $util.Long.fromValue(object.amountSat)).unsigned = true;
                else if (typeof object.amountSat === "string")
                    message.amountSat = parseInt(object.amountSat, 10);
                else if (typeof object.amountSat === "number")
                    message.amountSat = object.amountSat;
                else if (typeof object.amountSat === "object")
                    message.amountSat = new $util.LongBits(object.amountSat.low >>> 0, object.amountSat.high >>> 0).toNumber(true);
            if (object.sweepTxid != null)
                message.sweepTxid = String(object.sweepTxid);
            return message;
        };

        /**
         * Creates a plain object from a Resolution message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Resolution
         * @static
         * @param {lnrpc.Resolution} message Resolution
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Resolution.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.resolutionType = options.enums === String ? "TYPE_UNKNOWN" : 0;
                object.outcome = options.enums === String ? "OUTCOME_UNKNOWN" : 0;
                object.outpoint = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.amountSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amountSat = options.longs === String ? "0" : 0;
                object.sweepTxid = "";
            }
            if (message.resolutionType != null && message.hasOwnProperty("resolutionType"))
                object.resolutionType = options.enums === String ? $root.lnrpc.ResolutionType[message.resolutionType] : message.resolutionType;
            if (message.outcome != null && message.hasOwnProperty("outcome"))
                object.outcome = options.enums === String ? $root.lnrpc.ResolutionOutcome[message.outcome] : message.outcome;
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.amountSat != null && message.hasOwnProperty("amountSat"))
                if (typeof message.amountSat === "number")
                    object.amountSat = options.longs === String ? String(message.amountSat) : message.amountSat;
                else
                    object.amountSat = options.longs === String ? $util.Long.prototype.toString.call(message.amountSat) : options.longs === Number ? new $util.LongBits(message.amountSat.low >>> 0, message.amountSat.high >>> 0).toNumber(true) : message.amountSat;
            if (message.sweepTxid != null && message.hasOwnProperty("sweepTxid"))
                object.sweepTxid = message.sweepTxid;
            return object;
        };

        /**
         * Converts this Resolution to JSON.
         * @function toJSON
         * @memberof lnrpc.Resolution
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Resolution.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Resolution;
    })();

    lnrpc.ClosedChannelsRequest = (function() {

        /**
         * Properties of a ClosedChannelsRequest.
         * @memberof lnrpc
         * @interface IClosedChannelsRequest
         * @property {boolean|null} [cooperative] ClosedChannelsRequest cooperative
         * @property {boolean|null} [localForce] ClosedChannelsRequest localForce
         * @property {boolean|null} [remoteForce] ClosedChannelsRequest remoteForce
         * @property {boolean|null} [breach] ClosedChannelsRequest breach
         * @property {boolean|null} [fundingCanceled] ClosedChannelsRequest fundingCanceled
         * @property {boolean|null} [abandoned] ClosedChannelsRequest abandoned
         */

        /**
         * Constructs a new ClosedChannelsRequest.
         * @memberof lnrpc
         * @classdesc Represents a ClosedChannelsRequest.
         * @implements IClosedChannelsRequest
         * @constructor
         * @param {lnrpc.IClosedChannelsRequest=} [properties] Properties to set
         */
        function ClosedChannelsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClosedChannelsRequest cooperative.
         * @member {boolean} cooperative
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         */
        ClosedChannelsRequest.prototype.cooperative = false;

        /**
         * ClosedChannelsRequest localForce.
         * @member {boolean} localForce
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         */
        ClosedChannelsRequest.prototype.localForce = false;

        /**
         * ClosedChannelsRequest remoteForce.
         * @member {boolean} remoteForce
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         */
        ClosedChannelsRequest.prototype.remoteForce = false;

        /**
         * ClosedChannelsRequest breach.
         * @member {boolean} breach
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         */
        ClosedChannelsRequest.prototype.breach = false;

        /**
         * ClosedChannelsRequest fundingCanceled.
         * @member {boolean} fundingCanceled
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         */
        ClosedChannelsRequest.prototype.fundingCanceled = false;

        /**
         * ClosedChannelsRequest abandoned.
         * @member {boolean} abandoned
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         */
        ClosedChannelsRequest.prototype.abandoned = false;

        /**
         * Creates a new ClosedChannelsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {lnrpc.IClosedChannelsRequest=} [properties] Properties to set
         * @returns {lnrpc.ClosedChannelsRequest} ClosedChannelsRequest instance
         */
        ClosedChannelsRequest.create = function create(properties) {
            return new ClosedChannelsRequest(properties);
        };

        /**
         * Encodes the specified ClosedChannelsRequest message. Does not implicitly {@link lnrpc.ClosedChannelsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {lnrpc.IClosedChannelsRequest} message ClosedChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClosedChannelsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cooperative != null && Object.hasOwnProperty.call(message, "cooperative"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.cooperative);
            if (message.localForce != null && Object.hasOwnProperty.call(message, "localForce"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.localForce);
            if (message.remoteForce != null && Object.hasOwnProperty.call(message, "remoteForce"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.remoteForce);
            if (message.breach != null && Object.hasOwnProperty.call(message, "breach"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.breach);
            if (message.fundingCanceled != null && Object.hasOwnProperty.call(message, "fundingCanceled"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.fundingCanceled);
            if (message.abandoned != null && Object.hasOwnProperty.call(message, "abandoned"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.abandoned);
            return writer;
        };

        /**
         * Encodes the specified ClosedChannelsRequest message, length delimited. Does not implicitly {@link lnrpc.ClosedChannelsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {lnrpc.IClosedChannelsRequest} message ClosedChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClosedChannelsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClosedChannelsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ClosedChannelsRequest} ClosedChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClosedChannelsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ClosedChannelsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.cooperative = reader.bool();
                        break;
                    }
                case 2: {
                        message.localForce = reader.bool();
                        break;
                    }
                case 3: {
                        message.remoteForce = reader.bool();
                        break;
                    }
                case 4: {
                        message.breach = reader.bool();
                        break;
                    }
                case 5: {
                        message.fundingCanceled = reader.bool();
                        break;
                    }
                case 6: {
                        message.abandoned = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClosedChannelsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ClosedChannelsRequest} ClosedChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClosedChannelsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClosedChannelsRequest message.
         * @function verify
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClosedChannelsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cooperative != null && message.hasOwnProperty("cooperative"))
                if (typeof message.cooperative !== "boolean")
                    return "cooperative: boolean expected";
            if (message.localForce != null && message.hasOwnProperty("localForce"))
                if (typeof message.localForce !== "boolean")
                    return "localForce: boolean expected";
            if (message.remoteForce != null && message.hasOwnProperty("remoteForce"))
                if (typeof message.remoteForce !== "boolean")
                    return "remoteForce: boolean expected";
            if (message.breach != null && message.hasOwnProperty("breach"))
                if (typeof message.breach !== "boolean")
                    return "breach: boolean expected";
            if (message.fundingCanceled != null && message.hasOwnProperty("fundingCanceled"))
                if (typeof message.fundingCanceled !== "boolean")
                    return "fundingCanceled: boolean expected";
            if (message.abandoned != null && message.hasOwnProperty("abandoned"))
                if (typeof message.abandoned !== "boolean")
                    return "abandoned: boolean expected";
            return null;
        };

        /**
         * Creates a ClosedChannelsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ClosedChannelsRequest} ClosedChannelsRequest
         */
        ClosedChannelsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ClosedChannelsRequest)
                return object;
            let message = new $root.lnrpc.ClosedChannelsRequest();
            if (object.cooperative != null)
                message.cooperative = Boolean(object.cooperative);
            if (object.localForce != null)
                message.localForce = Boolean(object.localForce);
            if (object.remoteForce != null)
                message.remoteForce = Boolean(object.remoteForce);
            if (object.breach != null)
                message.breach = Boolean(object.breach);
            if (object.fundingCanceled != null)
                message.fundingCanceled = Boolean(object.fundingCanceled);
            if (object.abandoned != null)
                message.abandoned = Boolean(object.abandoned);
            return message;
        };

        /**
         * Creates a plain object from a ClosedChannelsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ClosedChannelsRequest
         * @static
         * @param {lnrpc.ClosedChannelsRequest} message ClosedChannelsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClosedChannelsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.cooperative = false;
                object.localForce = false;
                object.remoteForce = false;
                object.breach = false;
                object.fundingCanceled = false;
                object.abandoned = false;
            }
            if (message.cooperative != null && message.hasOwnProperty("cooperative"))
                object.cooperative = message.cooperative;
            if (message.localForce != null && message.hasOwnProperty("localForce"))
                object.localForce = message.localForce;
            if (message.remoteForce != null && message.hasOwnProperty("remoteForce"))
                object.remoteForce = message.remoteForce;
            if (message.breach != null && message.hasOwnProperty("breach"))
                object.breach = message.breach;
            if (message.fundingCanceled != null && message.hasOwnProperty("fundingCanceled"))
                object.fundingCanceled = message.fundingCanceled;
            if (message.abandoned != null && message.hasOwnProperty("abandoned"))
                object.abandoned = message.abandoned;
            return object;
        };

        /**
         * Converts this ClosedChannelsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ClosedChannelsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClosedChannelsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClosedChannelsRequest;
    })();

    lnrpc.ClosedChannelsResponse = (function() {

        /**
         * Properties of a ClosedChannelsResponse.
         * @memberof lnrpc
         * @interface IClosedChannelsResponse
         * @property {Array.<lnrpc.IChannelCloseSummary>|null} [channels] ClosedChannelsResponse channels
         */

        /**
         * Constructs a new ClosedChannelsResponse.
         * @memberof lnrpc
         * @classdesc Represents a ClosedChannelsResponse.
         * @implements IClosedChannelsResponse
         * @constructor
         * @param {lnrpc.IClosedChannelsResponse=} [properties] Properties to set
         */
        function ClosedChannelsResponse(properties) {
            this.channels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClosedChannelsResponse channels.
         * @member {Array.<lnrpc.IChannelCloseSummary>} channels
         * @memberof lnrpc.ClosedChannelsResponse
         * @instance
         */
        ClosedChannelsResponse.prototype.channels = $util.emptyArray;

        /**
         * Creates a new ClosedChannelsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {lnrpc.IClosedChannelsResponse=} [properties] Properties to set
         * @returns {lnrpc.ClosedChannelsResponse} ClosedChannelsResponse instance
         */
        ClosedChannelsResponse.create = function create(properties) {
            return new ClosedChannelsResponse(properties);
        };

        /**
         * Encodes the specified ClosedChannelsResponse message. Does not implicitly {@link lnrpc.ClosedChannelsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {lnrpc.IClosedChannelsResponse} message ClosedChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClosedChannelsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channels != null && message.channels.length)
                for (let i = 0; i < message.channels.length; ++i)
                    $root.lnrpc.ChannelCloseSummary.encode(message.channels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClosedChannelsResponse message, length delimited. Does not implicitly {@link lnrpc.ClosedChannelsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {lnrpc.IClosedChannelsResponse} message ClosedChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClosedChannelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClosedChannelsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ClosedChannelsResponse} ClosedChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClosedChannelsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ClosedChannelsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.channels && message.channels.length))
                            message.channels = [];
                        message.channels.push($root.lnrpc.ChannelCloseSummary.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClosedChannelsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ClosedChannelsResponse} ClosedChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClosedChannelsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClosedChannelsResponse message.
         * @function verify
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClosedChannelsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channels != null && message.hasOwnProperty("channels")) {
                if (!Array.isArray(message.channels))
                    return "channels: array expected";
                for (let i = 0; i < message.channels.length; ++i) {
                    let error = $root.lnrpc.ChannelCloseSummary.verify(message.channels[i]);
                    if (error)
                        return "channels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ClosedChannelsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ClosedChannelsResponse} ClosedChannelsResponse
         */
        ClosedChannelsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ClosedChannelsResponse)
                return object;
            let message = new $root.lnrpc.ClosedChannelsResponse();
            if (object.channels) {
                if (!Array.isArray(object.channels))
                    throw TypeError(".lnrpc.ClosedChannelsResponse.channels: array expected");
                message.channels = [];
                for (let i = 0; i < object.channels.length; ++i) {
                    if (typeof object.channels[i] !== "object")
                        throw TypeError(".lnrpc.ClosedChannelsResponse.channels: object expected");
                    message.channels[i] = $root.lnrpc.ChannelCloseSummary.fromObject(object.channels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ClosedChannelsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ClosedChannelsResponse
         * @static
         * @param {lnrpc.ClosedChannelsResponse} message ClosedChannelsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClosedChannelsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.channels = [];
            if (message.channels && message.channels.length) {
                object.channels = [];
                for (let j = 0; j < message.channels.length; ++j)
                    object.channels[j] = $root.lnrpc.ChannelCloseSummary.toObject(message.channels[j], options);
            }
            return object;
        };

        /**
         * Converts this ClosedChannelsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ClosedChannelsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClosedChannelsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClosedChannelsResponse;
    })();

    lnrpc.Peer = (function() {

        /**
         * Properties of a Peer.
         * @memberof lnrpc
         * @interface IPeer
         * @property {string|null} [pubKey] Peer pubKey
         * @property {string|null} [address] Peer address
         * @property {Long|null} [bytesSent] Peer bytesSent
         * @property {Long|null} [bytesRecv] Peer bytesRecv
         * @property {Long|null} [satSent] Peer satSent
         * @property {Long|null} [satRecv] Peer satRecv
         * @property {boolean|null} [inbound] Peer inbound
         * @property {Long|null} [pingTime] Peer pingTime
         * @property {lnrpc.Peer.SyncType|null} [syncType] Peer syncType
         * @property {Object.<string,lnrpc.IFeature>|null} [features] Peer features
         * @property {Array.<lnrpc.ITimestampedError>|null} [errors] Peer errors
         * @property {number|null} [flapCount] Peer flapCount
         * @property {Long|null} [lastFlapNs] Peer lastFlapNs
         * @property {Uint8Array|null} [lastPingPayload] Peer lastPingPayload
         */

        /**
         * Constructs a new Peer.
         * @memberof lnrpc
         * @classdesc Represents a Peer.
         * @implements IPeer
         * @constructor
         * @param {lnrpc.IPeer=} [properties] Properties to set
         */
        function Peer(properties) {
            this.features = {};
            this.errors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Peer pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.pubKey = "";

        /**
         * Peer address.
         * @member {string} address
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.address = "";

        /**
         * Peer bytesSent.
         * @member {Long} bytesSent
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.bytesSent = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Peer bytesRecv.
         * @member {Long} bytesRecv
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.bytesRecv = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Peer satSent.
         * @member {Long} satSent
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.satSent = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Peer satRecv.
         * @member {Long} satRecv
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.satRecv = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Peer inbound.
         * @member {boolean} inbound
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.inbound = false;

        /**
         * Peer pingTime.
         * @member {Long} pingTime
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.pingTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Peer syncType.
         * @member {lnrpc.Peer.SyncType} syncType
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.syncType = 0;

        /**
         * Peer features.
         * @member {Object.<string,lnrpc.IFeature>} features
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.features = $util.emptyObject;

        /**
         * Peer errors.
         * @member {Array.<lnrpc.ITimestampedError>} errors
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.errors = $util.emptyArray;

        /**
         * Peer flapCount.
         * @member {number} flapCount
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.flapCount = 0;

        /**
         * Peer lastFlapNs.
         * @member {Long} lastFlapNs
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.lastFlapNs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Peer lastPingPayload.
         * @member {Uint8Array} lastPingPayload
         * @memberof lnrpc.Peer
         * @instance
         */
        Peer.prototype.lastPingPayload = $util.newBuffer([]);

        /**
         * Creates a new Peer instance using the specified properties.
         * @function create
         * @memberof lnrpc.Peer
         * @static
         * @param {lnrpc.IPeer=} [properties] Properties to set
         * @returns {lnrpc.Peer} Peer instance
         */
        Peer.create = function create(properties) {
            return new Peer(properties);
        };

        /**
         * Encodes the specified Peer message. Does not implicitly {@link lnrpc.Peer.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Peer
         * @static
         * @param {lnrpc.IPeer} message Peer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Peer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubKey);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.address);
            if (message.bytesSent != null && Object.hasOwnProperty.call(message, "bytesSent"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.bytesSent);
            if (message.bytesRecv != null && Object.hasOwnProperty.call(message, "bytesRecv"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.bytesRecv);
            if (message.satSent != null && Object.hasOwnProperty.call(message, "satSent"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.satSent);
            if (message.satRecv != null && Object.hasOwnProperty.call(message, "satRecv"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.satRecv);
            if (message.inbound != null && Object.hasOwnProperty.call(message, "inbound"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.inbound);
            if (message.pingTime != null && Object.hasOwnProperty.call(message, "pingTime"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.pingTime);
            if (message.syncType != null && Object.hasOwnProperty.call(message, "syncType"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.syncType);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                for (let keys = Object.keys(message.features), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.lnrpc.Feature.encode(message.features[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.errors != null && message.errors.length)
                for (let i = 0; i < message.errors.length; ++i)
                    $root.lnrpc.TimestampedError.encode(message.errors[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.flapCount != null && Object.hasOwnProperty.call(message, "flapCount"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.flapCount);
            if (message.lastFlapNs != null && Object.hasOwnProperty.call(message, "lastFlapNs"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.lastFlapNs);
            if (message.lastPingPayload != null && Object.hasOwnProperty.call(message, "lastPingPayload"))
                writer.uint32(/* id 15, wireType 2 =*/122).bytes(message.lastPingPayload);
            return writer;
        };

        /**
         * Encodes the specified Peer message, length delimited. Does not implicitly {@link lnrpc.Peer.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Peer
         * @static
         * @param {lnrpc.IPeer} message Peer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Peer.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Peer message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Peer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Peer} Peer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Peer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Peer(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubKey = reader.string();
                        break;
                    }
                case 3: {
                        message.address = reader.string();
                        break;
                    }
                case 4: {
                        message.bytesSent = reader.uint64();
                        break;
                    }
                case 5: {
                        message.bytesRecv = reader.uint64();
                        break;
                    }
                case 6: {
                        message.satSent = reader.int64();
                        break;
                    }
                case 7: {
                        message.satRecv = reader.int64();
                        break;
                    }
                case 8: {
                        message.inbound = reader.bool();
                        break;
                    }
                case 9: {
                        message.pingTime = reader.int64();
                        break;
                    }
                case 10: {
                        message.syncType = reader.int32();
                        break;
                    }
                case 11: {
                        if (message.features === $util.emptyObject)
                            message.features = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.lnrpc.Feature.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.features[key] = value;
                        break;
                    }
                case 12: {
                        if (!(message.errors && message.errors.length))
                            message.errors = [];
                        message.errors.push($root.lnrpc.TimestampedError.decode(reader, reader.uint32()));
                        break;
                    }
                case 13: {
                        message.flapCount = reader.int32();
                        break;
                    }
                case 14: {
                        message.lastFlapNs = reader.int64();
                        break;
                    }
                case 15: {
                        message.lastPingPayload = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Peer message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Peer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Peer} Peer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Peer.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Peer message.
         * @function verify
         * @memberof lnrpc.Peer
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Peer.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            if (message.bytesSent != null && message.hasOwnProperty("bytesSent"))
                if (!$util.isInteger(message.bytesSent) && !(message.bytesSent && $util.isInteger(message.bytesSent.low) && $util.isInteger(message.bytesSent.high)))
                    return "bytesSent: integer|Long expected";
            if (message.bytesRecv != null && message.hasOwnProperty("bytesRecv"))
                if (!$util.isInteger(message.bytesRecv) && !(message.bytesRecv && $util.isInteger(message.bytesRecv.low) && $util.isInteger(message.bytesRecv.high)))
                    return "bytesRecv: integer|Long expected";
            if (message.satSent != null && message.hasOwnProperty("satSent"))
                if (!$util.isInteger(message.satSent) && !(message.satSent && $util.isInteger(message.satSent.low) && $util.isInteger(message.satSent.high)))
                    return "satSent: integer|Long expected";
            if (message.satRecv != null && message.hasOwnProperty("satRecv"))
                if (!$util.isInteger(message.satRecv) && !(message.satRecv && $util.isInteger(message.satRecv.low) && $util.isInteger(message.satRecv.high)))
                    return "satRecv: integer|Long expected";
            if (message.inbound != null && message.hasOwnProperty("inbound"))
                if (typeof message.inbound !== "boolean")
                    return "inbound: boolean expected";
            if (message.pingTime != null && message.hasOwnProperty("pingTime"))
                if (!$util.isInteger(message.pingTime) && !(message.pingTime && $util.isInteger(message.pingTime.low) && $util.isInteger(message.pingTime.high)))
                    return "pingTime: integer|Long expected";
            if (message.syncType != null && message.hasOwnProperty("syncType"))
                switch (message.syncType) {
                default:
                    return "syncType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!$util.isObject(message.features))
                    return "features: object expected";
                let key = Object.keys(message.features);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "features: integer key{k:uint32} expected";
                    {
                        let error = $root.lnrpc.Feature.verify(message.features[key[i]]);
                        if (error)
                            return "features." + error;
                    }
                }
            }
            if (message.errors != null && message.hasOwnProperty("errors")) {
                if (!Array.isArray(message.errors))
                    return "errors: array expected";
                for (let i = 0; i < message.errors.length; ++i) {
                    let error = $root.lnrpc.TimestampedError.verify(message.errors[i]);
                    if (error)
                        return "errors." + error;
                }
            }
            if (message.flapCount != null && message.hasOwnProperty("flapCount"))
                if (!$util.isInteger(message.flapCount))
                    return "flapCount: integer expected";
            if (message.lastFlapNs != null && message.hasOwnProperty("lastFlapNs"))
                if (!$util.isInteger(message.lastFlapNs) && !(message.lastFlapNs && $util.isInteger(message.lastFlapNs.low) && $util.isInteger(message.lastFlapNs.high)))
                    return "lastFlapNs: integer|Long expected";
            if (message.lastPingPayload != null && message.hasOwnProperty("lastPingPayload"))
                if (!(message.lastPingPayload && typeof message.lastPingPayload.length === "number" || $util.isString(message.lastPingPayload)))
                    return "lastPingPayload: buffer expected";
            return null;
        };

        /**
         * Creates a Peer message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Peer
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Peer} Peer
         */
        Peer.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Peer)
                return object;
            let message = new $root.lnrpc.Peer();
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            if (object.address != null)
                message.address = String(object.address);
            if (object.bytesSent != null)
                if ($util.Long)
                    (message.bytesSent = $util.Long.fromValue(object.bytesSent)).unsigned = true;
                else if (typeof object.bytesSent === "string")
                    message.bytesSent = parseInt(object.bytesSent, 10);
                else if (typeof object.bytesSent === "number")
                    message.bytesSent = object.bytesSent;
                else if (typeof object.bytesSent === "object")
                    message.bytesSent = new $util.LongBits(object.bytesSent.low >>> 0, object.bytesSent.high >>> 0).toNumber(true);
            if (object.bytesRecv != null)
                if ($util.Long)
                    (message.bytesRecv = $util.Long.fromValue(object.bytesRecv)).unsigned = true;
                else if (typeof object.bytesRecv === "string")
                    message.bytesRecv = parseInt(object.bytesRecv, 10);
                else if (typeof object.bytesRecv === "number")
                    message.bytesRecv = object.bytesRecv;
                else if (typeof object.bytesRecv === "object")
                    message.bytesRecv = new $util.LongBits(object.bytesRecv.low >>> 0, object.bytesRecv.high >>> 0).toNumber(true);
            if (object.satSent != null)
                if ($util.Long)
                    (message.satSent = $util.Long.fromValue(object.satSent)).unsigned = false;
                else if (typeof object.satSent === "string")
                    message.satSent = parseInt(object.satSent, 10);
                else if (typeof object.satSent === "number")
                    message.satSent = object.satSent;
                else if (typeof object.satSent === "object")
                    message.satSent = new $util.LongBits(object.satSent.low >>> 0, object.satSent.high >>> 0).toNumber();
            if (object.satRecv != null)
                if ($util.Long)
                    (message.satRecv = $util.Long.fromValue(object.satRecv)).unsigned = false;
                else if (typeof object.satRecv === "string")
                    message.satRecv = parseInt(object.satRecv, 10);
                else if (typeof object.satRecv === "number")
                    message.satRecv = object.satRecv;
                else if (typeof object.satRecv === "object")
                    message.satRecv = new $util.LongBits(object.satRecv.low >>> 0, object.satRecv.high >>> 0).toNumber();
            if (object.inbound != null)
                message.inbound = Boolean(object.inbound);
            if (object.pingTime != null)
                if ($util.Long)
                    (message.pingTime = $util.Long.fromValue(object.pingTime)).unsigned = false;
                else if (typeof object.pingTime === "string")
                    message.pingTime = parseInt(object.pingTime, 10);
                else if (typeof object.pingTime === "number")
                    message.pingTime = object.pingTime;
                else if (typeof object.pingTime === "object")
                    message.pingTime = new $util.LongBits(object.pingTime.low >>> 0, object.pingTime.high >>> 0).toNumber();
            switch (object.syncType) {
            case "UNKNOWN_SYNC":
            case 0:
                message.syncType = 0;
                break;
            case "ACTIVE_SYNC":
            case 1:
                message.syncType = 1;
                break;
            case "PASSIVE_SYNC":
            case 2:
                message.syncType = 2;
                break;
            case "PINNED_SYNC":
            case 3:
                message.syncType = 3;
                break;
            }
            if (object.features) {
                if (typeof object.features !== "object")
                    throw TypeError(".lnrpc.Peer.features: object expected");
                message.features = {};
                for (let keys = Object.keys(object.features), i = 0; i < keys.length; ++i) {
                    if (typeof object.features[keys[i]] !== "object")
                        throw TypeError(".lnrpc.Peer.features: object expected");
                    message.features[keys[i]] = $root.lnrpc.Feature.fromObject(object.features[keys[i]]);
                }
            }
            if (object.errors) {
                if (!Array.isArray(object.errors))
                    throw TypeError(".lnrpc.Peer.errors: array expected");
                message.errors = [];
                for (let i = 0; i < object.errors.length; ++i) {
                    if (typeof object.errors[i] !== "object")
                        throw TypeError(".lnrpc.Peer.errors: object expected");
                    message.errors[i] = $root.lnrpc.TimestampedError.fromObject(object.errors[i]);
                }
            }
            if (object.flapCount != null)
                message.flapCount = object.flapCount | 0;
            if (object.lastFlapNs != null)
                if ($util.Long)
                    (message.lastFlapNs = $util.Long.fromValue(object.lastFlapNs)).unsigned = false;
                else if (typeof object.lastFlapNs === "string")
                    message.lastFlapNs = parseInt(object.lastFlapNs, 10);
                else if (typeof object.lastFlapNs === "number")
                    message.lastFlapNs = object.lastFlapNs;
                else if (typeof object.lastFlapNs === "object")
                    message.lastFlapNs = new $util.LongBits(object.lastFlapNs.low >>> 0, object.lastFlapNs.high >>> 0).toNumber();
            if (object.lastPingPayload != null)
                if (typeof object.lastPingPayload === "string")
                    $util.base64.decode(object.lastPingPayload, message.lastPingPayload = $util.newBuffer($util.base64.length(object.lastPingPayload)), 0);
                else if (object.lastPingPayload.length)
                    message.lastPingPayload = object.lastPingPayload;
            return message;
        };

        /**
         * Creates a plain object from a Peer message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Peer
         * @static
         * @param {lnrpc.Peer} message Peer
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Peer.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.errors = [];
            if (options.objects || options.defaults)
                object.features = {};
            if (options.defaults) {
                object.pubKey = "";
                object.address = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.bytesSent = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bytesSent = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.bytesRecv = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bytesRecv = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satSent = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satSent = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satRecv = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satRecv = options.longs === String ? "0" : 0;
                object.inbound = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.pingTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pingTime = options.longs === String ? "0" : 0;
                object.syncType = options.enums === String ? "UNKNOWN_SYNC" : 0;
                object.flapCount = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lastFlapNs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lastFlapNs = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.lastPingPayload = "";
                else {
                    object.lastPingPayload = [];
                    if (options.bytes !== Array)
                        object.lastPingPayload = $util.newBuffer(object.lastPingPayload);
                }
            }
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.bytesSent != null && message.hasOwnProperty("bytesSent"))
                if (typeof message.bytesSent === "number")
                    object.bytesSent = options.longs === String ? String(message.bytesSent) : message.bytesSent;
                else
                    object.bytesSent = options.longs === String ? $util.Long.prototype.toString.call(message.bytesSent) : options.longs === Number ? new $util.LongBits(message.bytesSent.low >>> 0, message.bytesSent.high >>> 0).toNumber(true) : message.bytesSent;
            if (message.bytesRecv != null && message.hasOwnProperty("bytesRecv"))
                if (typeof message.bytesRecv === "number")
                    object.bytesRecv = options.longs === String ? String(message.bytesRecv) : message.bytesRecv;
                else
                    object.bytesRecv = options.longs === String ? $util.Long.prototype.toString.call(message.bytesRecv) : options.longs === Number ? new $util.LongBits(message.bytesRecv.low >>> 0, message.bytesRecv.high >>> 0).toNumber(true) : message.bytesRecv;
            if (message.satSent != null && message.hasOwnProperty("satSent"))
                if (typeof message.satSent === "number")
                    object.satSent = options.longs === String ? String(message.satSent) : message.satSent;
                else
                    object.satSent = options.longs === String ? $util.Long.prototype.toString.call(message.satSent) : options.longs === Number ? new $util.LongBits(message.satSent.low >>> 0, message.satSent.high >>> 0).toNumber() : message.satSent;
            if (message.satRecv != null && message.hasOwnProperty("satRecv"))
                if (typeof message.satRecv === "number")
                    object.satRecv = options.longs === String ? String(message.satRecv) : message.satRecv;
                else
                    object.satRecv = options.longs === String ? $util.Long.prototype.toString.call(message.satRecv) : options.longs === Number ? new $util.LongBits(message.satRecv.low >>> 0, message.satRecv.high >>> 0).toNumber() : message.satRecv;
            if (message.inbound != null && message.hasOwnProperty("inbound"))
                object.inbound = message.inbound;
            if (message.pingTime != null && message.hasOwnProperty("pingTime"))
                if (typeof message.pingTime === "number")
                    object.pingTime = options.longs === String ? String(message.pingTime) : message.pingTime;
                else
                    object.pingTime = options.longs === String ? $util.Long.prototype.toString.call(message.pingTime) : options.longs === Number ? new $util.LongBits(message.pingTime.low >>> 0, message.pingTime.high >>> 0).toNumber() : message.pingTime;
            if (message.syncType != null && message.hasOwnProperty("syncType"))
                object.syncType = options.enums === String ? $root.lnrpc.Peer.SyncType[message.syncType] : message.syncType;
            let keys2;
            if (message.features && (keys2 = Object.keys(message.features)).length) {
                object.features = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.features[keys2[j]] = $root.lnrpc.Feature.toObject(message.features[keys2[j]], options);
            }
            if (message.errors && message.errors.length) {
                object.errors = [];
                for (let j = 0; j < message.errors.length; ++j)
                    object.errors[j] = $root.lnrpc.TimestampedError.toObject(message.errors[j], options);
            }
            if (message.flapCount != null && message.hasOwnProperty("flapCount"))
                object.flapCount = message.flapCount;
            if (message.lastFlapNs != null && message.hasOwnProperty("lastFlapNs"))
                if (typeof message.lastFlapNs === "number")
                    object.lastFlapNs = options.longs === String ? String(message.lastFlapNs) : message.lastFlapNs;
                else
                    object.lastFlapNs = options.longs === String ? $util.Long.prototype.toString.call(message.lastFlapNs) : options.longs === Number ? new $util.LongBits(message.lastFlapNs.low >>> 0, message.lastFlapNs.high >>> 0).toNumber() : message.lastFlapNs;
            if (message.lastPingPayload != null && message.hasOwnProperty("lastPingPayload"))
                object.lastPingPayload = options.bytes === String ? $util.base64.encode(message.lastPingPayload, 0, message.lastPingPayload.length) : options.bytes === Array ? Array.prototype.slice.call(message.lastPingPayload) : message.lastPingPayload;
            return object;
        };

        /**
         * Converts this Peer to JSON.
         * @function toJSON
         * @memberof lnrpc.Peer
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Peer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * SyncType enum.
         * @name lnrpc.Peer.SyncType
         * @enum {number}
         * @property {number} UNKNOWN_SYNC=0 UNKNOWN_SYNC value
         * @property {number} ACTIVE_SYNC=1 ACTIVE_SYNC value
         * @property {number} PASSIVE_SYNC=2 PASSIVE_SYNC value
         * @property {number} PINNED_SYNC=3 PINNED_SYNC value
         */
        Peer.SyncType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_SYNC"] = 0;
            values[valuesById[1] = "ACTIVE_SYNC"] = 1;
            values[valuesById[2] = "PASSIVE_SYNC"] = 2;
            values[valuesById[3] = "PINNED_SYNC"] = 3;
            return values;
        })();

        return Peer;
    })();

    lnrpc.TimestampedError = (function() {

        /**
         * Properties of a TimestampedError.
         * @memberof lnrpc
         * @interface ITimestampedError
         * @property {Long|null} [timestamp] TimestampedError timestamp
         * @property {string|null} [error] TimestampedError error
         */

        /**
         * Constructs a new TimestampedError.
         * @memberof lnrpc
         * @classdesc Represents a TimestampedError.
         * @implements ITimestampedError
         * @constructor
         * @param {lnrpc.ITimestampedError=} [properties] Properties to set
         */
        function TimestampedError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimestampedError timestamp.
         * @member {Long} timestamp
         * @memberof lnrpc.TimestampedError
         * @instance
         */
        TimestampedError.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * TimestampedError error.
         * @member {string} error
         * @memberof lnrpc.TimestampedError
         * @instance
         */
        TimestampedError.prototype.error = "";

        /**
         * Creates a new TimestampedError instance using the specified properties.
         * @function create
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {lnrpc.ITimestampedError=} [properties] Properties to set
         * @returns {lnrpc.TimestampedError} TimestampedError instance
         */
        TimestampedError.create = function create(properties) {
            return new TimestampedError(properties);
        };

        /**
         * Encodes the specified TimestampedError message. Does not implicitly {@link lnrpc.TimestampedError.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {lnrpc.ITimestampedError} message TimestampedError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimestampedError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.timestamp);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            return writer;
        };

        /**
         * Encodes the specified TimestampedError message, length delimited. Does not implicitly {@link lnrpc.TimestampedError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {lnrpc.ITimestampedError} message TimestampedError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimestampedError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimestampedError message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.TimestampedError} TimestampedError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimestampedError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.TimestampedError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.timestamp = reader.uint64();
                        break;
                    }
                case 2: {
                        message.error = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimestampedError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.TimestampedError} TimestampedError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimestampedError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimestampedError message.
         * @function verify
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimestampedError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            return null;
        };

        /**
         * Creates a TimestampedError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.TimestampedError} TimestampedError
         */
        TimestampedError.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.TimestampedError)
                return object;
            let message = new $root.lnrpc.TimestampedError();
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
            if (object.error != null)
                message.error = String(object.error);
            return message;
        };

        /**
         * Creates a plain object from a TimestampedError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.TimestampedError
         * @static
         * @param {lnrpc.TimestampedError} message TimestampedError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimestampedError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                object.error = "";
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this TimestampedError to JSON.
         * @function toJSON
         * @memberof lnrpc.TimestampedError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimestampedError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TimestampedError;
    })();

    lnrpc.ListPeersRequest = (function() {

        /**
         * Properties of a ListPeersRequest.
         * @memberof lnrpc
         * @interface IListPeersRequest
         * @property {boolean|null} [latestError] ListPeersRequest latestError
         */

        /**
         * Constructs a new ListPeersRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListPeersRequest.
         * @implements IListPeersRequest
         * @constructor
         * @param {lnrpc.IListPeersRequest=} [properties] Properties to set
         */
        function ListPeersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListPeersRequest latestError.
         * @member {boolean} latestError
         * @memberof lnrpc.ListPeersRequest
         * @instance
         */
        ListPeersRequest.prototype.latestError = false;

        /**
         * Creates a new ListPeersRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {lnrpc.IListPeersRequest=} [properties] Properties to set
         * @returns {lnrpc.ListPeersRequest} ListPeersRequest instance
         */
        ListPeersRequest.create = function create(properties) {
            return new ListPeersRequest(properties);
        };

        /**
         * Encodes the specified ListPeersRequest message. Does not implicitly {@link lnrpc.ListPeersRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {lnrpc.IListPeersRequest} message ListPeersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPeersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latestError != null && Object.hasOwnProperty.call(message, "latestError"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.latestError);
            return writer;
        };

        /**
         * Encodes the specified ListPeersRequest message, length delimited. Does not implicitly {@link lnrpc.ListPeersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {lnrpc.IListPeersRequest} message ListPeersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPeersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListPeersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListPeersRequest} ListPeersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPeersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListPeersRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.latestError = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListPeersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListPeersRequest} ListPeersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPeersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListPeersRequest message.
         * @function verify
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListPeersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latestError != null && message.hasOwnProperty("latestError"))
                if (typeof message.latestError !== "boolean")
                    return "latestError: boolean expected";
            return null;
        };

        /**
         * Creates a ListPeersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListPeersRequest} ListPeersRequest
         */
        ListPeersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListPeersRequest)
                return object;
            let message = new $root.lnrpc.ListPeersRequest();
            if (object.latestError != null)
                message.latestError = Boolean(object.latestError);
            return message;
        };

        /**
         * Creates a plain object from a ListPeersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListPeersRequest
         * @static
         * @param {lnrpc.ListPeersRequest} message ListPeersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListPeersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latestError = false;
            if (message.latestError != null && message.hasOwnProperty("latestError"))
                object.latestError = message.latestError;
            return object;
        };

        /**
         * Converts this ListPeersRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListPeersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListPeersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListPeersRequest;
    })();

    lnrpc.ListPeersResponse = (function() {

        /**
         * Properties of a ListPeersResponse.
         * @memberof lnrpc
         * @interface IListPeersResponse
         * @property {Array.<lnrpc.IPeer>|null} [peers] ListPeersResponse peers
         */

        /**
         * Constructs a new ListPeersResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListPeersResponse.
         * @implements IListPeersResponse
         * @constructor
         * @param {lnrpc.IListPeersResponse=} [properties] Properties to set
         */
        function ListPeersResponse(properties) {
            this.peers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListPeersResponse peers.
         * @member {Array.<lnrpc.IPeer>} peers
         * @memberof lnrpc.ListPeersResponse
         * @instance
         */
        ListPeersResponse.prototype.peers = $util.emptyArray;

        /**
         * Creates a new ListPeersResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {lnrpc.IListPeersResponse=} [properties] Properties to set
         * @returns {lnrpc.ListPeersResponse} ListPeersResponse instance
         */
        ListPeersResponse.create = function create(properties) {
            return new ListPeersResponse(properties);
        };

        /**
         * Encodes the specified ListPeersResponse message. Does not implicitly {@link lnrpc.ListPeersResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {lnrpc.IListPeersResponse} message ListPeersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPeersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.peers != null && message.peers.length)
                for (let i = 0; i < message.peers.length; ++i)
                    $root.lnrpc.Peer.encode(message.peers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListPeersResponse message, length delimited. Does not implicitly {@link lnrpc.ListPeersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {lnrpc.IListPeersResponse} message ListPeersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPeersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListPeersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListPeersResponse} ListPeersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPeersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListPeersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.peers && message.peers.length))
                            message.peers = [];
                        message.peers.push($root.lnrpc.Peer.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListPeersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListPeersResponse} ListPeersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPeersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListPeersResponse message.
         * @function verify
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListPeersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.peers != null && message.hasOwnProperty("peers")) {
                if (!Array.isArray(message.peers))
                    return "peers: array expected";
                for (let i = 0; i < message.peers.length; ++i) {
                    let error = $root.lnrpc.Peer.verify(message.peers[i]);
                    if (error)
                        return "peers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListPeersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListPeersResponse} ListPeersResponse
         */
        ListPeersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListPeersResponse)
                return object;
            let message = new $root.lnrpc.ListPeersResponse();
            if (object.peers) {
                if (!Array.isArray(object.peers))
                    throw TypeError(".lnrpc.ListPeersResponse.peers: array expected");
                message.peers = [];
                for (let i = 0; i < object.peers.length; ++i) {
                    if (typeof object.peers[i] !== "object")
                        throw TypeError(".lnrpc.ListPeersResponse.peers: object expected");
                    message.peers[i] = $root.lnrpc.Peer.fromObject(object.peers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListPeersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListPeersResponse
         * @static
         * @param {lnrpc.ListPeersResponse} message ListPeersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListPeersResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.peers = [];
            if (message.peers && message.peers.length) {
                object.peers = [];
                for (let j = 0; j < message.peers.length; ++j)
                    object.peers[j] = $root.lnrpc.Peer.toObject(message.peers[j], options);
            }
            return object;
        };

        /**
         * Converts this ListPeersResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListPeersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListPeersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListPeersResponse;
    })();

    lnrpc.PeerEventSubscription = (function() {

        /**
         * Properties of a PeerEventSubscription.
         * @memberof lnrpc
         * @interface IPeerEventSubscription
         */

        /**
         * Constructs a new PeerEventSubscription.
         * @memberof lnrpc
         * @classdesc Represents a PeerEventSubscription.
         * @implements IPeerEventSubscription
         * @constructor
         * @param {lnrpc.IPeerEventSubscription=} [properties] Properties to set
         */
        function PeerEventSubscription(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PeerEventSubscription instance using the specified properties.
         * @function create
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {lnrpc.IPeerEventSubscription=} [properties] Properties to set
         * @returns {lnrpc.PeerEventSubscription} PeerEventSubscription instance
         */
        PeerEventSubscription.create = function create(properties) {
            return new PeerEventSubscription(properties);
        };

        /**
         * Encodes the specified PeerEventSubscription message. Does not implicitly {@link lnrpc.PeerEventSubscription.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {lnrpc.IPeerEventSubscription} message PeerEventSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerEventSubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PeerEventSubscription message, length delimited. Does not implicitly {@link lnrpc.PeerEventSubscription.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {lnrpc.IPeerEventSubscription} message PeerEventSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerEventSubscription.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeerEventSubscription message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PeerEventSubscription} PeerEventSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerEventSubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PeerEventSubscription();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeerEventSubscription message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PeerEventSubscription} PeerEventSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerEventSubscription.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeerEventSubscription message.
         * @function verify
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeerEventSubscription.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PeerEventSubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PeerEventSubscription} PeerEventSubscription
         */
        PeerEventSubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PeerEventSubscription)
                return object;
            return new $root.lnrpc.PeerEventSubscription();
        };

        /**
         * Creates a plain object from a PeerEventSubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PeerEventSubscription
         * @static
         * @param {lnrpc.PeerEventSubscription} message PeerEventSubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeerEventSubscription.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PeerEventSubscription to JSON.
         * @function toJSON
         * @memberof lnrpc.PeerEventSubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeerEventSubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PeerEventSubscription;
    })();

    lnrpc.PeerEvent = (function() {

        /**
         * Properties of a PeerEvent.
         * @memberof lnrpc
         * @interface IPeerEvent
         * @property {string|null} [pubKey] PeerEvent pubKey
         * @property {lnrpc.PeerEvent.EventType|null} [type] PeerEvent type
         */

        /**
         * Constructs a new PeerEvent.
         * @memberof lnrpc
         * @classdesc Represents a PeerEvent.
         * @implements IPeerEvent
         * @constructor
         * @param {lnrpc.IPeerEvent=} [properties] Properties to set
         */
        function PeerEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PeerEvent pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.PeerEvent
         * @instance
         */
        PeerEvent.prototype.pubKey = "";

        /**
         * PeerEvent type.
         * @member {lnrpc.PeerEvent.EventType} type
         * @memberof lnrpc.PeerEvent
         * @instance
         */
        PeerEvent.prototype.type = 0;

        /**
         * Creates a new PeerEvent instance using the specified properties.
         * @function create
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {lnrpc.IPeerEvent=} [properties] Properties to set
         * @returns {lnrpc.PeerEvent} PeerEvent instance
         */
        PeerEvent.create = function create(properties) {
            return new PeerEvent(properties);
        };

        /**
         * Encodes the specified PeerEvent message. Does not implicitly {@link lnrpc.PeerEvent.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {lnrpc.IPeerEvent} message PeerEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubKey);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            return writer;
        };

        /**
         * Encodes the specified PeerEvent message, length delimited. Does not implicitly {@link lnrpc.PeerEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {lnrpc.IPeerEvent} message PeerEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PeerEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PeerEvent message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PeerEvent} PeerEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PeerEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubKey = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PeerEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PeerEvent} PeerEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PeerEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PeerEvent message.
         * @function verify
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PeerEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a PeerEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PeerEvent} PeerEvent
         */
        PeerEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PeerEvent)
                return object;
            let message = new $root.lnrpc.PeerEvent();
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            switch (object.type) {
            case "PEER_ONLINE":
            case 0:
                message.type = 0;
                break;
            case "PEER_OFFLINE":
            case 1:
                message.type = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PeerEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PeerEvent
         * @static
         * @param {lnrpc.PeerEvent} message PeerEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PeerEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.pubKey = "";
                object.type = options.enums === String ? "PEER_ONLINE" : 0;
            }
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.lnrpc.PeerEvent.EventType[message.type] : message.type;
            return object;
        };

        /**
         * Converts this PeerEvent to JSON.
         * @function toJSON
         * @memberof lnrpc.PeerEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PeerEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * EventType enum.
         * @name lnrpc.PeerEvent.EventType
         * @enum {number}
         * @property {number} PEER_ONLINE=0 PEER_ONLINE value
         * @property {number} PEER_OFFLINE=1 PEER_OFFLINE value
         */
        PeerEvent.EventType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PEER_ONLINE"] = 0;
            values[valuesById[1] = "PEER_OFFLINE"] = 1;
            return values;
        })();

        return PeerEvent;
    })();

    lnrpc.GetInfoRequest = (function() {

        /**
         * Properties of a GetInfoRequest.
         * @memberof lnrpc
         * @interface IGetInfoRequest
         */

        /**
         * Constructs a new GetInfoRequest.
         * @memberof lnrpc
         * @classdesc Represents a GetInfoRequest.
         * @implements IGetInfoRequest
         * @constructor
         * @param {lnrpc.IGetInfoRequest=} [properties] Properties to set
         */
        function GetInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetInfoRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {lnrpc.IGetInfoRequest=} [properties] Properties to set
         * @returns {lnrpc.GetInfoRequest} GetInfoRequest instance
         */
        GetInfoRequest.create = function create(properties) {
            return new GetInfoRequest(properties);
        };

        /**
         * Encodes the specified GetInfoRequest message. Does not implicitly {@link lnrpc.GetInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {lnrpc.IGetInfoRequest} message GetInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetInfoRequest message, length delimited. Does not implicitly {@link lnrpc.GetInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {lnrpc.IGetInfoRequest} message GetInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetInfoRequest} GetInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetInfoRequest} GetInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInfoRequest message.
         * @function verify
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetInfoRequest} GetInfoRequest
         */
        GetInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetInfoRequest)
                return object;
            return new $root.lnrpc.GetInfoRequest();
        };

        /**
         * Creates a plain object from a GetInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetInfoRequest
         * @static
         * @param {lnrpc.GetInfoRequest} message GetInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInfoRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetInfoRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.GetInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInfoRequest;
    })();

    lnrpc.GetInfoResponse = (function() {

        /**
         * Properties of a GetInfoResponse.
         * @memberof lnrpc
         * @interface IGetInfoResponse
         * @property {string|null} [version] GetInfoResponse version
         * @property {string|null} [commitHash] GetInfoResponse commitHash
         * @property {string|null} [identityPubkey] GetInfoResponse identityPubkey
         * @property {string|null} [alias] GetInfoResponse alias
         * @property {string|null} [color] GetInfoResponse color
         * @property {number|null} [numPendingChannels] GetInfoResponse numPendingChannels
         * @property {number|null} [numActiveChannels] GetInfoResponse numActiveChannels
         * @property {number|null} [numInactiveChannels] GetInfoResponse numInactiveChannels
         * @property {number|null} [numPeers] GetInfoResponse numPeers
         * @property {number|null} [blockHeight] GetInfoResponse blockHeight
         * @property {string|null} [blockHash] GetInfoResponse blockHash
         * @property {Long|null} [bestHeaderTimestamp] GetInfoResponse bestHeaderTimestamp
         * @property {boolean|null} [syncedToChain] GetInfoResponse syncedToChain
         * @property {boolean|null} [syncedToGraph] GetInfoResponse syncedToGraph
         * @property {boolean|null} [testnet] GetInfoResponse testnet
         * @property {Array.<lnrpc.IChain>|null} [chains] GetInfoResponse chains
         * @property {Array.<string>|null} [uris] GetInfoResponse uris
         * @property {Object.<string,lnrpc.IFeature>|null} [features] GetInfoResponse features
         * @property {boolean|null} [requireHtlcInterceptor] GetInfoResponse requireHtlcInterceptor
         */

        /**
         * Constructs a new GetInfoResponse.
         * @memberof lnrpc
         * @classdesc Represents a GetInfoResponse.
         * @implements IGetInfoResponse
         * @constructor
         * @param {lnrpc.IGetInfoResponse=} [properties] Properties to set
         */
        function GetInfoResponse(properties) {
            this.chains = [];
            this.uris = [];
            this.features = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetInfoResponse version.
         * @member {string} version
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.version = "";

        /**
         * GetInfoResponse commitHash.
         * @member {string} commitHash
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.commitHash = "";

        /**
         * GetInfoResponse identityPubkey.
         * @member {string} identityPubkey
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.identityPubkey = "";

        /**
         * GetInfoResponse alias.
         * @member {string} alias
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.alias = "";

        /**
         * GetInfoResponse color.
         * @member {string} color
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.color = "";

        /**
         * GetInfoResponse numPendingChannels.
         * @member {number} numPendingChannels
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.numPendingChannels = 0;

        /**
         * GetInfoResponse numActiveChannels.
         * @member {number} numActiveChannels
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.numActiveChannels = 0;

        /**
         * GetInfoResponse numInactiveChannels.
         * @member {number} numInactiveChannels
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.numInactiveChannels = 0;

        /**
         * GetInfoResponse numPeers.
         * @member {number} numPeers
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.numPeers = 0;

        /**
         * GetInfoResponse blockHeight.
         * @member {number} blockHeight
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.blockHeight = 0;

        /**
         * GetInfoResponse blockHash.
         * @member {string} blockHash
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.blockHash = "";

        /**
         * GetInfoResponse bestHeaderTimestamp.
         * @member {Long} bestHeaderTimestamp
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.bestHeaderTimestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetInfoResponse syncedToChain.
         * @member {boolean} syncedToChain
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.syncedToChain = false;

        /**
         * GetInfoResponse syncedToGraph.
         * @member {boolean} syncedToGraph
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.syncedToGraph = false;

        /**
         * GetInfoResponse testnet.
         * @member {boolean} testnet
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.testnet = false;

        /**
         * GetInfoResponse chains.
         * @member {Array.<lnrpc.IChain>} chains
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.chains = $util.emptyArray;

        /**
         * GetInfoResponse uris.
         * @member {Array.<string>} uris
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.uris = $util.emptyArray;

        /**
         * GetInfoResponse features.
         * @member {Object.<string,lnrpc.IFeature>} features
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.features = $util.emptyObject;

        /**
         * GetInfoResponse requireHtlcInterceptor.
         * @member {boolean} requireHtlcInterceptor
         * @memberof lnrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.requireHtlcInterceptor = false;

        /**
         * Creates a new GetInfoResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {lnrpc.IGetInfoResponse=} [properties] Properties to set
         * @returns {lnrpc.GetInfoResponse} GetInfoResponse instance
         */
        GetInfoResponse.create = function create(properties) {
            return new GetInfoResponse(properties);
        };

        /**
         * Encodes the specified GetInfoResponse message. Does not implicitly {@link lnrpc.GetInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {lnrpc.IGetInfoResponse} message GetInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.identityPubkey != null && Object.hasOwnProperty.call(message, "identityPubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.identityPubkey);
            if (message.alias != null && Object.hasOwnProperty.call(message, "alias"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.alias);
            if (message.numPendingChannels != null && Object.hasOwnProperty.call(message, "numPendingChannels"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numPendingChannels);
            if (message.numActiveChannels != null && Object.hasOwnProperty.call(message, "numActiveChannels"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numActiveChannels);
            if (message.numPeers != null && Object.hasOwnProperty.call(message, "numPeers"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.numPeers);
            if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.blockHeight);
            if (message.blockHash != null && Object.hasOwnProperty.call(message, "blockHash"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.blockHash);
            if (message.syncedToChain != null && Object.hasOwnProperty.call(message, "syncedToChain"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.syncedToChain);
            if (message.testnet != null && Object.hasOwnProperty.call(message, "testnet"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.testnet);
            if (message.uris != null && message.uris.length)
                for (let i = 0; i < message.uris.length; ++i)
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.uris[i]);
            if (message.bestHeaderTimestamp != null && Object.hasOwnProperty.call(message, "bestHeaderTimestamp"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.bestHeaderTimestamp);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.version);
            if (message.numInactiveChannels != null && Object.hasOwnProperty.call(message, "numInactiveChannels"))
                writer.uint32(/* id 15, wireType 0 =*/120).uint32(message.numInactiveChannels);
            if (message.chains != null && message.chains.length)
                for (let i = 0; i < message.chains.length; ++i)
                    $root.lnrpc.Chain.encode(message.chains[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.color);
            if (message.syncedToGraph != null && Object.hasOwnProperty.call(message, "syncedToGraph"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.syncedToGraph);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                for (let keys = Object.keys(message.features), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 19, wireType 2 =*/154).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.lnrpc.Feature.encode(message.features[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.commitHash != null && Object.hasOwnProperty.call(message, "commitHash"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.commitHash);
            if (message.requireHtlcInterceptor != null && Object.hasOwnProperty.call(message, "requireHtlcInterceptor"))
                writer.uint32(/* id 21, wireType 0 =*/168).bool(message.requireHtlcInterceptor);
            return writer;
        };

        /**
         * Encodes the specified GetInfoResponse message, length delimited. Does not implicitly {@link lnrpc.GetInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {lnrpc.IGetInfoResponse} message GetInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetInfoResponse} GetInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetInfoResponse(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 14: {
                        message.version = reader.string();
                        break;
                    }
                case 20: {
                        message.commitHash = reader.string();
                        break;
                    }
                case 1: {
                        message.identityPubkey = reader.string();
                        break;
                    }
                case 2: {
                        message.alias = reader.string();
                        break;
                    }
                case 17: {
                        message.color = reader.string();
                        break;
                    }
                case 3: {
                        message.numPendingChannels = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numActiveChannels = reader.uint32();
                        break;
                    }
                case 15: {
                        message.numInactiveChannels = reader.uint32();
                        break;
                    }
                case 5: {
                        message.numPeers = reader.uint32();
                        break;
                    }
                case 6: {
                        message.blockHeight = reader.uint32();
                        break;
                    }
                case 8: {
                        message.blockHash = reader.string();
                        break;
                    }
                case 13: {
                        message.bestHeaderTimestamp = reader.int64();
                        break;
                    }
                case 9: {
                        message.syncedToChain = reader.bool();
                        break;
                    }
                case 18: {
                        message.syncedToGraph = reader.bool();
                        break;
                    }
                case 10: {
                        message.testnet = reader.bool();
                        break;
                    }
                case 16: {
                        if (!(message.chains && message.chains.length))
                            message.chains = [];
                        message.chains.push($root.lnrpc.Chain.decode(reader, reader.uint32()));
                        break;
                    }
                case 12: {
                        if (!(message.uris && message.uris.length))
                            message.uris = [];
                        message.uris.push(reader.string());
                        break;
                    }
                case 19: {
                        if (message.features === $util.emptyObject)
                            message.features = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.lnrpc.Feature.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.features[key] = value;
                        break;
                    }
                case 21: {
                        message.requireHtlcInterceptor = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetInfoResponse} GetInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInfoResponse message.
         * @function verify
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.commitHash != null && message.hasOwnProperty("commitHash"))
                if (!$util.isString(message.commitHash))
                    return "commitHash: string expected";
            if (message.identityPubkey != null && message.hasOwnProperty("identityPubkey"))
                if (!$util.isString(message.identityPubkey))
                    return "identityPubkey: string expected";
            if (message.alias != null && message.hasOwnProperty("alias"))
                if (!$util.isString(message.alias))
                    return "alias: string expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isString(message.color))
                    return "color: string expected";
            if (message.numPendingChannels != null && message.hasOwnProperty("numPendingChannels"))
                if (!$util.isInteger(message.numPendingChannels))
                    return "numPendingChannels: integer expected";
            if (message.numActiveChannels != null && message.hasOwnProperty("numActiveChannels"))
                if (!$util.isInteger(message.numActiveChannels))
                    return "numActiveChannels: integer expected";
            if (message.numInactiveChannels != null && message.hasOwnProperty("numInactiveChannels"))
                if (!$util.isInteger(message.numInactiveChannels))
                    return "numInactiveChannels: integer expected";
            if (message.numPeers != null && message.hasOwnProperty("numPeers"))
                if (!$util.isInteger(message.numPeers))
                    return "numPeers: integer expected";
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                if (!$util.isInteger(message.blockHeight))
                    return "blockHeight: integer expected";
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                if (!$util.isString(message.blockHash))
                    return "blockHash: string expected";
            if (message.bestHeaderTimestamp != null && message.hasOwnProperty("bestHeaderTimestamp"))
                if (!$util.isInteger(message.bestHeaderTimestamp) && !(message.bestHeaderTimestamp && $util.isInteger(message.bestHeaderTimestamp.low) && $util.isInteger(message.bestHeaderTimestamp.high)))
                    return "bestHeaderTimestamp: integer|Long expected";
            if (message.syncedToChain != null && message.hasOwnProperty("syncedToChain"))
                if (typeof message.syncedToChain !== "boolean")
                    return "syncedToChain: boolean expected";
            if (message.syncedToGraph != null && message.hasOwnProperty("syncedToGraph"))
                if (typeof message.syncedToGraph !== "boolean")
                    return "syncedToGraph: boolean expected";
            if (message.testnet != null && message.hasOwnProperty("testnet"))
                if (typeof message.testnet !== "boolean")
                    return "testnet: boolean expected";
            if (message.chains != null && message.hasOwnProperty("chains")) {
                if (!Array.isArray(message.chains))
                    return "chains: array expected";
                for (let i = 0; i < message.chains.length; ++i) {
                    let error = $root.lnrpc.Chain.verify(message.chains[i]);
                    if (error)
                        return "chains." + error;
                }
            }
            if (message.uris != null && message.hasOwnProperty("uris")) {
                if (!Array.isArray(message.uris))
                    return "uris: array expected";
                for (let i = 0; i < message.uris.length; ++i)
                    if (!$util.isString(message.uris[i]))
                        return "uris: string[] expected";
            }
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!$util.isObject(message.features))
                    return "features: object expected";
                let key = Object.keys(message.features);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "features: integer key{k:uint32} expected";
                    {
                        let error = $root.lnrpc.Feature.verify(message.features[key[i]]);
                        if (error)
                            return "features." + error;
                    }
                }
            }
            if (message.requireHtlcInterceptor != null && message.hasOwnProperty("requireHtlcInterceptor"))
                if (typeof message.requireHtlcInterceptor !== "boolean")
                    return "requireHtlcInterceptor: boolean expected";
            return null;
        };

        /**
         * Creates a GetInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetInfoResponse} GetInfoResponse
         */
        GetInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetInfoResponse)
                return object;
            let message = new $root.lnrpc.GetInfoResponse();
            if (object.version != null)
                message.version = String(object.version);
            if (object.commitHash != null)
                message.commitHash = String(object.commitHash);
            if (object.identityPubkey != null)
                message.identityPubkey = String(object.identityPubkey);
            if (object.alias != null)
                message.alias = String(object.alias);
            if (object.color != null)
                message.color = String(object.color);
            if (object.numPendingChannels != null)
                message.numPendingChannels = object.numPendingChannels >>> 0;
            if (object.numActiveChannels != null)
                message.numActiveChannels = object.numActiveChannels >>> 0;
            if (object.numInactiveChannels != null)
                message.numInactiveChannels = object.numInactiveChannels >>> 0;
            if (object.numPeers != null)
                message.numPeers = object.numPeers >>> 0;
            if (object.blockHeight != null)
                message.blockHeight = object.blockHeight >>> 0;
            if (object.blockHash != null)
                message.blockHash = String(object.blockHash);
            if (object.bestHeaderTimestamp != null)
                if ($util.Long)
                    (message.bestHeaderTimestamp = $util.Long.fromValue(object.bestHeaderTimestamp)).unsigned = false;
                else if (typeof object.bestHeaderTimestamp === "string")
                    message.bestHeaderTimestamp = parseInt(object.bestHeaderTimestamp, 10);
                else if (typeof object.bestHeaderTimestamp === "number")
                    message.bestHeaderTimestamp = object.bestHeaderTimestamp;
                else if (typeof object.bestHeaderTimestamp === "object")
                    message.bestHeaderTimestamp = new $util.LongBits(object.bestHeaderTimestamp.low >>> 0, object.bestHeaderTimestamp.high >>> 0).toNumber();
            if (object.syncedToChain != null)
                message.syncedToChain = Boolean(object.syncedToChain);
            if (object.syncedToGraph != null)
                message.syncedToGraph = Boolean(object.syncedToGraph);
            if (object.testnet != null)
                message.testnet = Boolean(object.testnet);
            if (object.chains) {
                if (!Array.isArray(object.chains))
                    throw TypeError(".lnrpc.GetInfoResponse.chains: array expected");
                message.chains = [];
                for (let i = 0; i < object.chains.length; ++i) {
                    if (typeof object.chains[i] !== "object")
                        throw TypeError(".lnrpc.GetInfoResponse.chains: object expected");
                    message.chains[i] = $root.lnrpc.Chain.fromObject(object.chains[i]);
                }
            }
            if (object.uris) {
                if (!Array.isArray(object.uris))
                    throw TypeError(".lnrpc.GetInfoResponse.uris: array expected");
                message.uris = [];
                for (let i = 0; i < object.uris.length; ++i)
                    message.uris[i] = String(object.uris[i]);
            }
            if (object.features) {
                if (typeof object.features !== "object")
                    throw TypeError(".lnrpc.GetInfoResponse.features: object expected");
                message.features = {};
                for (let keys = Object.keys(object.features), i = 0; i < keys.length; ++i) {
                    if (typeof object.features[keys[i]] !== "object")
                        throw TypeError(".lnrpc.GetInfoResponse.features: object expected");
                    message.features[keys[i]] = $root.lnrpc.Feature.fromObject(object.features[keys[i]]);
                }
            }
            if (object.requireHtlcInterceptor != null)
                message.requireHtlcInterceptor = Boolean(object.requireHtlcInterceptor);
            return message;
        };

        /**
         * Creates a plain object from a GetInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetInfoResponse
         * @static
         * @param {lnrpc.GetInfoResponse} message GetInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.uris = [];
                object.chains = [];
            }
            if (options.objects || options.defaults)
                object.features = {};
            if (options.defaults) {
                object.identityPubkey = "";
                object.alias = "";
                object.numPendingChannels = 0;
                object.numActiveChannels = 0;
                object.numPeers = 0;
                object.blockHeight = 0;
                object.blockHash = "";
                object.syncedToChain = false;
                object.testnet = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.bestHeaderTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.bestHeaderTimestamp = options.longs === String ? "0" : 0;
                object.version = "";
                object.numInactiveChannels = 0;
                object.color = "";
                object.syncedToGraph = false;
                object.commitHash = "";
                object.requireHtlcInterceptor = false;
            }
            if (message.identityPubkey != null && message.hasOwnProperty("identityPubkey"))
                object.identityPubkey = message.identityPubkey;
            if (message.alias != null && message.hasOwnProperty("alias"))
                object.alias = message.alias;
            if (message.numPendingChannels != null && message.hasOwnProperty("numPendingChannels"))
                object.numPendingChannels = message.numPendingChannels;
            if (message.numActiveChannels != null && message.hasOwnProperty("numActiveChannels"))
                object.numActiveChannels = message.numActiveChannels;
            if (message.numPeers != null && message.hasOwnProperty("numPeers"))
                object.numPeers = message.numPeers;
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                object.blockHeight = message.blockHeight;
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                object.blockHash = message.blockHash;
            if (message.syncedToChain != null && message.hasOwnProperty("syncedToChain"))
                object.syncedToChain = message.syncedToChain;
            if (message.testnet != null && message.hasOwnProperty("testnet"))
                object.testnet = message.testnet;
            if (message.uris && message.uris.length) {
                object.uris = [];
                for (let j = 0; j < message.uris.length; ++j)
                    object.uris[j] = message.uris[j];
            }
            if (message.bestHeaderTimestamp != null && message.hasOwnProperty("bestHeaderTimestamp"))
                if (typeof message.bestHeaderTimestamp === "number")
                    object.bestHeaderTimestamp = options.longs === String ? String(message.bestHeaderTimestamp) : message.bestHeaderTimestamp;
                else
                    object.bestHeaderTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.bestHeaderTimestamp) : options.longs === Number ? new $util.LongBits(message.bestHeaderTimestamp.low >>> 0, message.bestHeaderTimestamp.high >>> 0).toNumber() : message.bestHeaderTimestamp;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.numInactiveChannels != null && message.hasOwnProperty("numInactiveChannels"))
                object.numInactiveChannels = message.numInactiveChannels;
            if (message.chains && message.chains.length) {
                object.chains = [];
                for (let j = 0; j < message.chains.length; ++j)
                    object.chains[j] = $root.lnrpc.Chain.toObject(message.chains[j], options);
            }
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = message.color;
            if (message.syncedToGraph != null && message.hasOwnProperty("syncedToGraph"))
                object.syncedToGraph = message.syncedToGraph;
            let keys2;
            if (message.features && (keys2 = Object.keys(message.features)).length) {
                object.features = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.features[keys2[j]] = $root.lnrpc.Feature.toObject(message.features[keys2[j]], options);
            }
            if (message.commitHash != null && message.hasOwnProperty("commitHash"))
                object.commitHash = message.commitHash;
            if (message.requireHtlcInterceptor != null && message.hasOwnProperty("requireHtlcInterceptor"))
                object.requireHtlcInterceptor = message.requireHtlcInterceptor;
            return object;
        };

        /**
         * Converts this GetInfoResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.GetInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInfoResponse;
    })();

    lnrpc.GetRecoveryInfoRequest = (function() {

        /**
         * Properties of a GetRecoveryInfoRequest.
         * @memberof lnrpc
         * @interface IGetRecoveryInfoRequest
         */

        /**
         * Constructs a new GetRecoveryInfoRequest.
         * @memberof lnrpc
         * @classdesc Represents a GetRecoveryInfoRequest.
         * @implements IGetRecoveryInfoRequest
         * @constructor
         * @param {lnrpc.IGetRecoveryInfoRequest=} [properties] Properties to set
         */
        function GetRecoveryInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetRecoveryInfoRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {lnrpc.IGetRecoveryInfoRequest=} [properties] Properties to set
         * @returns {lnrpc.GetRecoveryInfoRequest} GetRecoveryInfoRequest instance
         */
        GetRecoveryInfoRequest.create = function create(properties) {
            return new GetRecoveryInfoRequest(properties);
        };

        /**
         * Encodes the specified GetRecoveryInfoRequest message. Does not implicitly {@link lnrpc.GetRecoveryInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {lnrpc.IGetRecoveryInfoRequest} message GetRecoveryInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRecoveryInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetRecoveryInfoRequest message, length delimited. Does not implicitly {@link lnrpc.GetRecoveryInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {lnrpc.IGetRecoveryInfoRequest} message GetRecoveryInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRecoveryInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetRecoveryInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetRecoveryInfoRequest} GetRecoveryInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRecoveryInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetRecoveryInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetRecoveryInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetRecoveryInfoRequest} GetRecoveryInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRecoveryInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetRecoveryInfoRequest message.
         * @function verify
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetRecoveryInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetRecoveryInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetRecoveryInfoRequest} GetRecoveryInfoRequest
         */
        GetRecoveryInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetRecoveryInfoRequest)
                return object;
            return new $root.lnrpc.GetRecoveryInfoRequest();
        };

        /**
         * Creates a plain object from a GetRecoveryInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @static
         * @param {lnrpc.GetRecoveryInfoRequest} message GetRecoveryInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetRecoveryInfoRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetRecoveryInfoRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.GetRecoveryInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetRecoveryInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetRecoveryInfoRequest;
    })();

    lnrpc.GetRecoveryInfoResponse = (function() {

        /**
         * Properties of a GetRecoveryInfoResponse.
         * @memberof lnrpc
         * @interface IGetRecoveryInfoResponse
         * @property {boolean|null} [recoveryMode] GetRecoveryInfoResponse recoveryMode
         * @property {boolean|null} [recoveryFinished] GetRecoveryInfoResponse recoveryFinished
         * @property {number|null} [progress] GetRecoveryInfoResponse progress
         */

        /**
         * Constructs a new GetRecoveryInfoResponse.
         * @memberof lnrpc
         * @classdesc Represents a GetRecoveryInfoResponse.
         * @implements IGetRecoveryInfoResponse
         * @constructor
         * @param {lnrpc.IGetRecoveryInfoResponse=} [properties] Properties to set
         */
        function GetRecoveryInfoResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetRecoveryInfoResponse recoveryMode.
         * @member {boolean} recoveryMode
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @instance
         */
        GetRecoveryInfoResponse.prototype.recoveryMode = false;

        /**
         * GetRecoveryInfoResponse recoveryFinished.
         * @member {boolean} recoveryFinished
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @instance
         */
        GetRecoveryInfoResponse.prototype.recoveryFinished = false;

        /**
         * GetRecoveryInfoResponse progress.
         * @member {number} progress
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @instance
         */
        GetRecoveryInfoResponse.prototype.progress = 0;

        /**
         * Creates a new GetRecoveryInfoResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {lnrpc.IGetRecoveryInfoResponse=} [properties] Properties to set
         * @returns {lnrpc.GetRecoveryInfoResponse} GetRecoveryInfoResponse instance
         */
        GetRecoveryInfoResponse.create = function create(properties) {
            return new GetRecoveryInfoResponse(properties);
        };

        /**
         * Encodes the specified GetRecoveryInfoResponse message. Does not implicitly {@link lnrpc.GetRecoveryInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {lnrpc.IGetRecoveryInfoResponse} message GetRecoveryInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRecoveryInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.recoveryMode != null && Object.hasOwnProperty.call(message, "recoveryMode"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.recoveryMode);
            if (message.recoveryFinished != null && Object.hasOwnProperty.call(message, "recoveryFinished"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.recoveryFinished);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.progress);
            return writer;
        };

        /**
         * Encodes the specified GetRecoveryInfoResponse message, length delimited. Does not implicitly {@link lnrpc.GetRecoveryInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {lnrpc.IGetRecoveryInfoResponse} message GetRecoveryInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRecoveryInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetRecoveryInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetRecoveryInfoResponse} GetRecoveryInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRecoveryInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetRecoveryInfoResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.recoveryMode = reader.bool();
                        break;
                    }
                case 2: {
                        message.recoveryFinished = reader.bool();
                        break;
                    }
                case 3: {
                        message.progress = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetRecoveryInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetRecoveryInfoResponse} GetRecoveryInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRecoveryInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetRecoveryInfoResponse message.
         * @function verify
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetRecoveryInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.recoveryMode != null && message.hasOwnProperty("recoveryMode"))
                if (typeof message.recoveryMode !== "boolean")
                    return "recoveryMode: boolean expected";
            if (message.recoveryFinished != null && message.hasOwnProperty("recoveryFinished"))
                if (typeof message.recoveryFinished !== "boolean")
                    return "recoveryFinished: boolean expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            return null;
        };

        /**
         * Creates a GetRecoveryInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetRecoveryInfoResponse} GetRecoveryInfoResponse
         */
        GetRecoveryInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetRecoveryInfoResponse)
                return object;
            let message = new $root.lnrpc.GetRecoveryInfoResponse();
            if (object.recoveryMode != null)
                message.recoveryMode = Boolean(object.recoveryMode);
            if (object.recoveryFinished != null)
                message.recoveryFinished = Boolean(object.recoveryFinished);
            if (object.progress != null)
                message.progress = Number(object.progress);
            return message;
        };

        /**
         * Creates a plain object from a GetRecoveryInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @static
         * @param {lnrpc.GetRecoveryInfoResponse} message GetRecoveryInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetRecoveryInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.recoveryMode = false;
                object.recoveryFinished = false;
                object.progress = 0;
            }
            if (message.recoveryMode != null && message.hasOwnProperty("recoveryMode"))
                object.recoveryMode = message.recoveryMode;
            if (message.recoveryFinished != null && message.hasOwnProperty("recoveryFinished"))
                object.recoveryFinished = message.recoveryFinished;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            return object;
        };

        /**
         * Converts this GetRecoveryInfoResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.GetRecoveryInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetRecoveryInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetRecoveryInfoResponse;
    })();

    lnrpc.Chain = (function() {

        /**
         * Properties of a Chain.
         * @memberof lnrpc
         * @interface IChain
         * @property {string|null} [chain] Chain chain
         * @property {string|null} [network] Chain network
         */

        /**
         * Constructs a new Chain.
         * @memberof lnrpc
         * @classdesc Represents a Chain.
         * @implements IChain
         * @constructor
         * @param {lnrpc.IChain=} [properties] Properties to set
         */
        function Chain(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Chain chain.
         * @member {string} chain
         * @memberof lnrpc.Chain
         * @instance
         */
        Chain.prototype.chain = "";

        /**
         * Chain network.
         * @member {string} network
         * @memberof lnrpc.Chain
         * @instance
         */
        Chain.prototype.network = "";

        /**
         * Creates a new Chain instance using the specified properties.
         * @function create
         * @memberof lnrpc.Chain
         * @static
         * @param {lnrpc.IChain=} [properties] Properties to set
         * @returns {lnrpc.Chain} Chain instance
         */
        Chain.create = function create(properties) {
            return new Chain(properties);
        };

        /**
         * Encodes the specified Chain message. Does not implicitly {@link lnrpc.Chain.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Chain
         * @static
         * @param {lnrpc.IChain} message Chain message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Chain.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chain != null && Object.hasOwnProperty.call(message, "chain"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.chain);
            if (message.network != null && Object.hasOwnProperty.call(message, "network"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.network);
            return writer;
        };

        /**
         * Encodes the specified Chain message, length delimited. Does not implicitly {@link lnrpc.Chain.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Chain
         * @static
         * @param {lnrpc.IChain} message Chain message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Chain.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Chain message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Chain
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Chain} Chain
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Chain.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Chain();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chain = reader.string();
                        break;
                    }
                case 2: {
                        message.network = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Chain message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Chain
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Chain} Chain
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Chain.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Chain message.
         * @function verify
         * @memberof lnrpc.Chain
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Chain.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chain != null && message.hasOwnProperty("chain"))
                if (!$util.isString(message.chain))
                    return "chain: string expected";
            if (message.network != null && message.hasOwnProperty("network"))
                if (!$util.isString(message.network))
                    return "network: string expected";
            return null;
        };

        /**
         * Creates a Chain message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Chain
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Chain} Chain
         */
        Chain.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Chain)
                return object;
            let message = new $root.lnrpc.Chain();
            if (object.chain != null)
                message.chain = String(object.chain);
            if (object.network != null)
                message.network = String(object.network);
            return message;
        };

        /**
         * Creates a plain object from a Chain message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Chain
         * @static
         * @param {lnrpc.Chain} message Chain
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Chain.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.chain = "";
                object.network = "";
            }
            if (message.chain != null && message.hasOwnProperty("chain"))
                object.chain = message.chain;
            if (message.network != null && message.hasOwnProperty("network"))
                object.network = message.network;
            return object;
        };

        /**
         * Converts this Chain to JSON.
         * @function toJSON
         * @memberof lnrpc.Chain
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Chain.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Chain;
    })();

    lnrpc.ConfirmationUpdate = (function() {

        /**
         * Properties of a ConfirmationUpdate.
         * @memberof lnrpc
         * @interface IConfirmationUpdate
         * @property {Uint8Array|null} [blockSha] ConfirmationUpdate blockSha
         * @property {number|null} [blockHeight] ConfirmationUpdate blockHeight
         * @property {number|null} [numConfsLeft] ConfirmationUpdate numConfsLeft
         */

        /**
         * Constructs a new ConfirmationUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ConfirmationUpdate.
         * @implements IConfirmationUpdate
         * @constructor
         * @param {lnrpc.IConfirmationUpdate=} [properties] Properties to set
         */
        function ConfirmationUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfirmationUpdate blockSha.
         * @member {Uint8Array} blockSha
         * @memberof lnrpc.ConfirmationUpdate
         * @instance
         */
        ConfirmationUpdate.prototype.blockSha = $util.newBuffer([]);

        /**
         * ConfirmationUpdate blockHeight.
         * @member {number} blockHeight
         * @memberof lnrpc.ConfirmationUpdate
         * @instance
         */
        ConfirmationUpdate.prototype.blockHeight = 0;

        /**
         * ConfirmationUpdate numConfsLeft.
         * @member {number} numConfsLeft
         * @memberof lnrpc.ConfirmationUpdate
         * @instance
         */
        ConfirmationUpdate.prototype.numConfsLeft = 0;

        /**
         * Creates a new ConfirmationUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {lnrpc.IConfirmationUpdate=} [properties] Properties to set
         * @returns {lnrpc.ConfirmationUpdate} ConfirmationUpdate instance
         */
        ConfirmationUpdate.create = function create(properties) {
            return new ConfirmationUpdate(properties);
        };

        /**
         * Encodes the specified ConfirmationUpdate message. Does not implicitly {@link lnrpc.ConfirmationUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {lnrpc.IConfirmationUpdate} message ConfirmationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmationUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.blockSha != null && Object.hasOwnProperty.call(message, "blockSha"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.blockSha);
            if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.blockHeight);
            if (message.numConfsLeft != null && Object.hasOwnProperty.call(message, "numConfsLeft"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numConfsLeft);
            return writer;
        };

        /**
         * Encodes the specified ConfirmationUpdate message, length delimited. Does not implicitly {@link lnrpc.ConfirmationUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {lnrpc.IConfirmationUpdate} message ConfirmationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfirmationUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfirmationUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ConfirmationUpdate} ConfirmationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmationUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ConfirmationUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.blockSha = reader.bytes();
                        break;
                    }
                case 2: {
                        message.blockHeight = reader.int32();
                        break;
                    }
                case 3: {
                        message.numConfsLeft = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfirmationUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ConfirmationUpdate} ConfirmationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfirmationUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfirmationUpdate message.
         * @function verify
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfirmationUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.blockSha != null && message.hasOwnProperty("blockSha"))
                if (!(message.blockSha && typeof message.blockSha.length === "number" || $util.isString(message.blockSha)))
                    return "blockSha: buffer expected";
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                if (!$util.isInteger(message.blockHeight))
                    return "blockHeight: integer expected";
            if (message.numConfsLeft != null && message.hasOwnProperty("numConfsLeft"))
                if (!$util.isInteger(message.numConfsLeft))
                    return "numConfsLeft: integer expected";
            return null;
        };

        /**
         * Creates a ConfirmationUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ConfirmationUpdate} ConfirmationUpdate
         */
        ConfirmationUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ConfirmationUpdate)
                return object;
            let message = new $root.lnrpc.ConfirmationUpdate();
            if (object.blockSha != null)
                if (typeof object.blockSha === "string")
                    $util.base64.decode(object.blockSha, message.blockSha = $util.newBuffer($util.base64.length(object.blockSha)), 0);
                else if (object.blockSha.length)
                    message.blockSha = object.blockSha;
            if (object.blockHeight != null)
                message.blockHeight = object.blockHeight | 0;
            if (object.numConfsLeft != null)
                message.numConfsLeft = object.numConfsLeft >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ConfirmationUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ConfirmationUpdate
         * @static
         * @param {lnrpc.ConfirmationUpdate} message ConfirmationUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfirmationUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.blockSha = "";
                else {
                    object.blockSha = [];
                    if (options.bytes !== Array)
                        object.blockSha = $util.newBuffer(object.blockSha);
                }
                object.blockHeight = 0;
                object.numConfsLeft = 0;
            }
            if (message.blockSha != null && message.hasOwnProperty("blockSha"))
                object.blockSha = options.bytes === String ? $util.base64.encode(message.blockSha, 0, message.blockSha.length) : options.bytes === Array ? Array.prototype.slice.call(message.blockSha) : message.blockSha;
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                object.blockHeight = message.blockHeight;
            if (message.numConfsLeft != null && message.hasOwnProperty("numConfsLeft"))
                object.numConfsLeft = message.numConfsLeft;
            return object;
        };

        /**
         * Converts this ConfirmationUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ConfirmationUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfirmationUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConfirmationUpdate;
    })();

    lnrpc.ChannelOpenUpdate = (function() {

        /**
         * Properties of a ChannelOpenUpdate.
         * @memberof lnrpc
         * @interface IChannelOpenUpdate
         * @property {lnrpc.IChannelPoint|null} [channelPoint] ChannelOpenUpdate channelPoint
         */

        /**
         * Constructs a new ChannelOpenUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ChannelOpenUpdate.
         * @implements IChannelOpenUpdate
         * @constructor
         * @param {lnrpc.IChannelOpenUpdate=} [properties] Properties to set
         */
        function ChannelOpenUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelOpenUpdate channelPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} channelPoint
         * @memberof lnrpc.ChannelOpenUpdate
         * @instance
         */
        ChannelOpenUpdate.prototype.channelPoint = null;

        /**
         * Creates a new ChannelOpenUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {lnrpc.IChannelOpenUpdate=} [properties] Properties to set
         * @returns {lnrpc.ChannelOpenUpdate} ChannelOpenUpdate instance
         */
        ChannelOpenUpdate.create = function create(properties) {
            return new ChannelOpenUpdate(properties);
        };

        /**
         * Encodes the specified ChannelOpenUpdate message. Does not implicitly {@link lnrpc.ChannelOpenUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {lnrpc.IChannelOpenUpdate} message ChannelOpenUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelOpenUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                $root.lnrpc.ChannelPoint.encode(message.channelPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelOpenUpdate message, length delimited. Does not implicitly {@link lnrpc.ChannelOpenUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {lnrpc.IChannelOpenUpdate} message ChannelOpenUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelOpenUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelOpenUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelOpenUpdate} ChannelOpenUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelOpenUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelOpenUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelOpenUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelOpenUpdate} ChannelOpenUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelOpenUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelOpenUpdate message.
         * @function verify
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelOpenUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.channelPoint);
                if (error)
                    return "channelPoint." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelOpenUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelOpenUpdate} ChannelOpenUpdate
         */
        ChannelOpenUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelOpenUpdate)
                return object;
            let message = new $root.lnrpc.ChannelOpenUpdate();
            if (object.channelPoint != null) {
                if (typeof object.channelPoint !== "object")
                    throw TypeError(".lnrpc.ChannelOpenUpdate.channelPoint: object expected");
                message.channelPoint = $root.lnrpc.ChannelPoint.fromObject(object.channelPoint);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelOpenUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelOpenUpdate
         * @static
         * @param {lnrpc.ChannelOpenUpdate} message ChannelOpenUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelOpenUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.channelPoint = null;
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                object.channelPoint = $root.lnrpc.ChannelPoint.toObject(message.channelPoint, options);
            return object;
        };

        /**
         * Converts this ChannelOpenUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelOpenUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelOpenUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelOpenUpdate;
    })();

    lnrpc.ChannelCloseUpdate = (function() {

        /**
         * Properties of a ChannelCloseUpdate.
         * @memberof lnrpc
         * @interface IChannelCloseUpdate
         * @property {Uint8Array|null} [closingTxid] ChannelCloseUpdate closingTxid
         * @property {boolean|null} [success] ChannelCloseUpdate success
         */

        /**
         * Constructs a new ChannelCloseUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ChannelCloseUpdate.
         * @implements IChannelCloseUpdate
         * @constructor
         * @param {lnrpc.IChannelCloseUpdate=} [properties] Properties to set
         */
        function ChannelCloseUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelCloseUpdate closingTxid.
         * @member {Uint8Array} closingTxid
         * @memberof lnrpc.ChannelCloseUpdate
         * @instance
         */
        ChannelCloseUpdate.prototype.closingTxid = $util.newBuffer([]);

        /**
         * ChannelCloseUpdate success.
         * @member {boolean} success
         * @memberof lnrpc.ChannelCloseUpdate
         * @instance
         */
        ChannelCloseUpdate.prototype.success = false;

        /**
         * Creates a new ChannelCloseUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {lnrpc.IChannelCloseUpdate=} [properties] Properties to set
         * @returns {lnrpc.ChannelCloseUpdate} ChannelCloseUpdate instance
         */
        ChannelCloseUpdate.create = function create(properties) {
            return new ChannelCloseUpdate(properties);
        };

        /**
         * Encodes the specified ChannelCloseUpdate message. Does not implicitly {@link lnrpc.ChannelCloseUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {lnrpc.IChannelCloseUpdate} message ChannelCloseUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelCloseUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.closingTxid != null && Object.hasOwnProperty.call(message, "closingTxid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.closingTxid);
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.success);
            return writer;
        };

        /**
         * Encodes the specified ChannelCloseUpdate message, length delimited. Does not implicitly {@link lnrpc.ChannelCloseUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {lnrpc.IChannelCloseUpdate} message ChannelCloseUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelCloseUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelCloseUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelCloseUpdate} ChannelCloseUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelCloseUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelCloseUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.closingTxid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.success = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelCloseUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelCloseUpdate} ChannelCloseUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelCloseUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelCloseUpdate message.
         * @function verify
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelCloseUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                if (!(message.closingTxid && typeof message.closingTxid.length === "number" || $util.isString(message.closingTxid)))
                    return "closingTxid: buffer expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelCloseUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelCloseUpdate} ChannelCloseUpdate
         */
        ChannelCloseUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelCloseUpdate)
                return object;
            let message = new $root.lnrpc.ChannelCloseUpdate();
            if (object.closingTxid != null)
                if (typeof object.closingTxid === "string")
                    $util.base64.decode(object.closingTxid, message.closingTxid = $util.newBuffer($util.base64.length(object.closingTxid)), 0);
                else if (object.closingTxid.length)
                    message.closingTxid = object.closingTxid;
            if (object.success != null)
                message.success = Boolean(object.success);
            return message;
        };

        /**
         * Creates a plain object from a ChannelCloseUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelCloseUpdate
         * @static
         * @param {lnrpc.ChannelCloseUpdate} message ChannelCloseUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelCloseUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.closingTxid = "";
                else {
                    object.closingTxid = [];
                    if (options.bytes !== Array)
                        object.closingTxid = $util.newBuffer(object.closingTxid);
                }
                object.success = false;
            }
            if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                object.closingTxid = options.bytes === String ? $util.base64.encode(message.closingTxid, 0, message.closingTxid.length) : options.bytes === Array ? Array.prototype.slice.call(message.closingTxid) : message.closingTxid;
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            return object;
        };

        /**
         * Converts this ChannelCloseUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelCloseUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelCloseUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelCloseUpdate;
    })();

    lnrpc.CloseChannelRequest = (function() {

        /**
         * Properties of a CloseChannelRequest.
         * @memberof lnrpc
         * @interface ICloseChannelRequest
         * @property {lnrpc.IChannelPoint|null} [channelPoint] CloseChannelRequest channelPoint
         * @property {boolean|null} [force] CloseChannelRequest force
         * @property {number|null} [targetConf] CloseChannelRequest targetConf
         * @property {Long|null} [satPerByte] CloseChannelRequest satPerByte
         * @property {string|null} [deliveryAddress] CloseChannelRequest deliveryAddress
         * @property {Long|null} [satPerVbyte] CloseChannelRequest satPerVbyte
         * @property {Long|null} [maxFeePerVbyte] CloseChannelRequest maxFeePerVbyte
         */

        /**
         * Constructs a new CloseChannelRequest.
         * @memberof lnrpc
         * @classdesc Represents a CloseChannelRequest.
         * @implements ICloseChannelRequest
         * @constructor
         * @param {lnrpc.ICloseChannelRequest=} [properties] Properties to set
         */
        function CloseChannelRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CloseChannelRequest channelPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} channelPoint
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.channelPoint = null;

        /**
         * CloseChannelRequest force.
         * @member {boolean} force
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.force = false;

        /**
         * CloseChannelRequest targetConf.
         * @member {number} targetConf
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.targetConf = 0;

        /**
         * CloseChannelRequest satPerByte.
         * @member {Long} satPerByte
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.satPerByte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CloseChannelRequest deliveryAddress.
         * @member {string} deliveryAddress
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.deliveryAddress = "";

        /**
         * CloseChannelRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * CloseChannelRequest maxFeePerVbyte.
         * @member {Long} maxFeePerVbyte
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         */
        CloseChannelRequest.prototype.maxFeePerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new CloseChannelRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {lnrpc.ICloseChannelRequest=} [properties] Properties to set
         * @returns {lnrpc.CloseChannelRequest} CloseChannelRequest instance
         */
        CloseChannelRequest.create = function create(properties) {
            return new CloseChannelRequest(properties);
        };

        /**
         * Encodes the specified CloseChannelRequest message. Does not implicitly {@link lnrpc.CloseChannelRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {lnrpc.ICloseChannelRequest} message CloseChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloseChannelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                $root.lnrpc.ChannelPoint.encode(message.channelPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.targetConf);
            if (message.satPerByte != null && Object.hasOwnProperty.call(message, "satPerByte"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.satPerByte);
            if (message.deliveryAddress != null && Object.hasOwnProperty.call(message, "deliveryAddress"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deliveryAddress);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.satPerVbyte);
            if (message.maxFeePerVbyte != null && Object.hasOwnProperty.call(message, "maxFeePerVbyte"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.maxFeePerVbyte);
            return writer;
        };

        /**
         * Encodes the specified CloseChannelRequest message, length delimited. Does not implicitly {@link lnrpc.CloseChannelRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {lnrpc.ICloseChannelRequest} message CloseChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloseChannelRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CloseChannelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.CloseChannelRequest} CloseChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloseChannelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.CloseChannelRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.force = reader.bool();
                        break;
                    }
                case 3: {
                        message.targetConf = reader.int32();
                        break;
                    }
                case 4: {
                        message.satPerByte = reader.int64();
                        break;
                    }
                case 5: {
                        message.deliveryAddress = reader.string();
                        break;
                    }
                case 6: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                case 7: {
                        message.maxFeePerVbyte = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CloseChannelRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.CloseChannelRequest} CloseChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloseChannelRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CloseChannelRequest message.
         * @function verify
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CloseChannelRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.channelPoint);
                if (error)
                    return "channelPoint." + error;
            }
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (!$util.isInteger(message.satPerByte) && !(message.satPerByte && $util.isInteger(message.satPerByte.low) && $util.isInteger(message.satPerByte.high)))
                    return "satPerByte: integer|Long expected";
            if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress"))
                if (!$util.isString(message.deliveryAddress))
                    return "deliveryAddress: string expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            if (message.maxFeePerVbyte != null && message.hasOwnProperty("maxFeePerVbyte"))
                if (!$util.isInteger(message.maxFeePerVbyte) && !(message.maxFeePerVbyte && $util.isInteger(message.maxFeePerVbyte.low) && $util.isInteger(message.maxFeePerVbyte.high)))
                    return "maxFeePerVbyte: integer|Long expected";
            return null;
        };

        /**
         * Creates a CloseChannelRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.CloseChannelRequest} CloseChannelRequest
         */
        CloseChannelRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.CloseChannelRequest)
                return object;
            let message = new $root.lnrpc.CloseChannelRequest();
            if (object.channelPoint != null) {
                if (typeof object.channelPoint !== "object")
                    throw TypeError(".lnrpc.CloseChannelRequest.channelPoint: object expected");
                message.channelPoint = $root.lnrpc.ChannelPoint.fromObject(object.channelPoint);
            }
            if (object.force != null)
                message.force = Boolean(object.force);
            if (object.targetConf != null)
                message.targetConf = object.targetConf | 0;
            if (object.satPerByte != null)
                if ($util.Long)
                    (message.satPerByte = $util.Long.fromValue(object.satPerByte)).unsigned = false;
                else if (typeof object.satPerByte === "string")
                    message.satPerByte = parseInt(object.satPerByte, 10);
                else if (typeof object.satPerByte === "number")
                    message.satPerByte = object.satPerByte;
                else if (typeof object.satPerByte === "object")
                    message.satPerByte = new $util.LongBits(object.satPerByte.low >>> 0, object.satPerByte.high >>> 0).toNumber();
            if (object.deliveryAddress != null)
                message.deliveryAddress = String(object.deliveryAddress);
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            if (object.maxFeePerVbyte != null)
                if ($util.Long)
                    (message.maxFeePerVbyte = $util.Long.fromValue(object.maxFeePerVbyte)).unsigned = true;
                else if (typeof object.maxFeePerVbyte === "string")
                    message.maxFeePerVbyte = parseInt(object.maxFeePerVbyte, 10);
                else if (typeof object.maxFeePerVbyte === "number")
                    message.maxFeePerVbyte = object.maxFeePerVbyte;
                else if (typeof object.maxFeePerVbyte === "object")
                    message.maxFeePerVbyte = new $util.LongBits(object.maxFeePerVbyte.low >>> 0, object.maxFeePerVbyte.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a CloseChannelRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.CloseChannelRequest
         * @static
         * @param {lnrpc.CloseChannelRequest} message CloseChannelRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloseChannelRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.channelPoint = null;
                object.force = false;
                object.targetConf = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerByte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerByte = options.longs === String ? "0" : 0;
                object.deliveryAddress = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxFeePerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxFeePerVbyte = options.longs === String ? "0" : 0;
            }
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                object.channelPoint = $root.lnrpc.ChannelPoint.toObject(message.channelPoint, options);
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (typeof message.satPerByte === "number")
                    object.satPerByte = options.longs === String ? String(message.satPerByte) : message.satPerByte;
                else
                    object.satPerByte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerByte) : options.longs === Number ? new $util.LongBits(message.satPerByte.low >>> 0, message.satPerByte.high >>> 0).toNumber() : message.satPerByte;
            if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress"))
                object.deliveryAddress = message.deliveryAddress;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            if (message.maxFeePerVbyte != null && message.hasOwnProperty("maxFeePerVbyte"))
                if (typeof message.maxFeePerVbyte === "number")
                    object.maxFeePerVbyte = options.longs === String ? String(message.maxFeePerVbyte) : message.maxFeePerVbyte;
                else
                    object.maxFeePerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.maxFeePerVbyte) : options.longs === Number ? new $util.LongBits(message.maxFeePerVbyte.low >>> 0, message.maxFeePerVbyte.high >>> 0).toNumber(true) : message.maxFeePerVbyte;
            return object;
        };

        /**
         * Converts this CloseChannelRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.CloseChannelRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloseChannelRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CloseChannelRequest;
    })();

    lnrpc.CloseStatusUpdate = (function() {

        /**
         * Properties of a CloseStatusUpdate.
         * @memberof lnrpc
         * @interface ICloseStatusUpdate
         * @property {lnrpc.IPendingUpdate|null} [closePending] CloseStatusUpdate closePending
         * @property {lnrpc.IChannelCloseUpdate|null} [chanClose] CloseStatusUpdate chanClose
         */

        /**
         * Constructs a new CloseStatusUpdate.
         * @memberof lnrpc
         * @classdesc Represents a CloseStatusUpdate.
         * @implements ICloseStatusUpdate
         * @constructor
         * @param {lnrpc.ICloseStatusUpdate=} [properties] Properties to set
         */
        function CloseStatusUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CloseStatusUpdate closePending.
         * @member {lnrpc.IPendingUpdate|null|undefined} closePending
         * @memberof lnrpc.CloseStatusUpdate
         * @instance
         */
        CloseStatusUpdate.prototype.closePending = null;

        /**
         * CloseStatusUpdate chanClose.
         * @member {lnrpc.IChannelCloseUpdate|null|undefined} chanClose
         * @memberof lnrpc.CloseStatusUpdate
         * @instance
         */
        CloseStatusUpdate.prototype.chanClose = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CloseStatusUpdate update.
         * @member {"closePending"|"chanClose"|undefined} update
         * @memberof lnrpc.CloseStatusUpdate
         * @instance
         */
        Object.defineProperty(CloseStatusUpdate.prototype, "update", {
            get: $util.oneOfGetter($oneOfFields = ["closePending", "chanClose"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CloseStatusUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {lnrpc.ICloseStatusUpdate=} [properties] Properties to set
         * @returns {lnrpc.CloseStatusUpdate} CloseStatusUpdate instance
         */
        CloseStatusUpdate.create = function create(properties) {
            return new CloseStatusUpdate(properties);
        };

        /**
         * Encodes the specified CloseStatusUpdate message. Does not implicitly {@link lnrpc.CloseStatusUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {lnrpc.ICloseStatusUpdate} message CloseStatusUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloseStatusUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.closePending != null && Object.hasOwnProperty.call(message, "closePending"))
                $root.lnrpc.PendingUpdate.encode(message.closePending, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.chanClose != null && Object.hasOwnProperty.call(message, "chanClose"))
                $root.lnrpc.ChannelCloseUpdate.encode(message.chanClose, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CloseStatusUpdate message, length delimited. Does not implicitly {@link lnrpc.CloseStatusUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {lnrpc.ICloseStatusUpdate} message CloseStatusUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CloseStatusUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CloseStatusUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.CloseStatusUpdate} CloseStatusUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloseStatusUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.CloseStatusUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.closePending = $root.lnrpc.PendingUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.chanClose = $root.lnrpc.ChannelCloseUpdate.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CloseStatusUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.CloseStatusUpdate} CloseStatusUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CloseStatusUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CloseStatusUpdate message.
         * @function verify
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CloseStatusUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.closePending != null && message.hasOwnProperty("closePending")) {
                properties.update = 1;
                {
                    let error = $root.lnrpc.PendingUpdate.verify(message.closePending);
                    if (error)
                        return "closePending." + error;
                }
            }
            if (message.chanClose != null && message.hasOwnProperty("chanClose")) {
                if (properties.update === 1)
                    return "update: multiple values";
                properties.update = 1;
                {
                    let error = $root.lnrpc.ChannelCloseUpdate.verify(message.chanClose);
                    if (error)
                        return "chanClose." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CloseStatusUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.CloseStatusUpdate} CloseStatusUpdate
         */
        CloseStatusUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.CloseStatusUpdate)
                return object;
            let message = new $root.lnrpc.CloseStatusUpdate();
            if (object.closePending != null) {
                if (typeof object.closePending !== "object")
                    throw TypeError(".lnrpc.CloseStatusUpdate.closePending: object expected");
                message.closePending = $root.lnrpc.PendingUpdate.fromObject(object.closePending);
            }
            if (object.chanClose != null) {
                if (typeof object.chanClose !== "object")
                    throw TypeError(".lnrpc.CloseStatusUpdate.chanClose: object expected");
                message.chanClose = $root.lnrpc.ChannelCloseUpdate.fromObject(object.chanClose);
            }
            return message;
        };

        /**
         * Creates a plain object from a CloseStatusUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.CloseStatusUpdate
         * @static
         * @param {lnrpc.CloseStatusUpdate} message CloseStatusUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CloseStatusUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.closePending != null && message.hasOwnProperty("closePending")) {
                object.closePending = $root.lnrpc.PendingUpdate.toObject(message.closePending, options);
                if (options.oneofs)
                    object.update = "closePending";
            }
            if (message.chanClose != null && message.hasOwnProperty("chanClose")) {
                object.chanClose = $root.lnrpc.ChannelCloseUpdate.toObject(message.chanClose, options);
                if (options.oneofs)
                    object.update = "chanClose";
            }
            return object;
        };

        /**
         * Converts this CloseStatusUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.CloseStatusUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CloseStatusUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CloseStatusUpdate;
    })();

    lnrpc.PendingUpdate = (function() {

        /**
         * Properties of a PendingUpdate.
         * @memberof lnrpc
         * @interface IPendingUpdate
         * @property {Uint8Array|null} [txid] PendingUpdate txid
         * @property {number|null} [outputIndex] PendingUpdate outputIndex
         */

        /**
         * Constructs a new PendingUpdate.
         * @memberof lnrpc
         * @classdesc Represents a PendingUpdate.
         * @implements IPendingUpdate
         * @constructor
         * @param {lnrpc.IPendingUpdate=} [properties] Properties to set
         */
        function PendingUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingUpdate txid.
         * @member {Uint8Array} txid
         * @memberof lnrpc.PendingUpdate
         * @instance
         */
        PendingUpdate.prototype.txid = $util.newBuffer([]);

        /**
         * PendingUpdate outputIndex.
         * @member {number} outputIndex
         * @memberof lnrpc.PendingUpdate
         * @instance
         */
        PendingUpdate.prototype.outputIndex = 0;

        /**
         * Creates a new PendingUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {lnrpc.IPendingUpdate=} [properties] Properties to set
         * @returns {lnrpc.PendingUpdate} PendingUpdate instance
         */
        PendingUpdate.create = function create(properties) {
            return new PendingUpdate(properties);
        };

        /**
         * Encodes the specified PendingUpdate message. Does not implicitly {@link lnrpc.PendingUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {lnrpc.IPendingUpdate} message PendingUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txid != null && Object.hasOwnProperty.call(message, "txid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.txid);
            if (message.outputIndex != null && Object.hasOwnProperty.call(message, "outputIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.outputIndex);
            return writer;
        };

        /**
         * Encodes the specified PendingUpdate message, length delimited. Does not implicitly {@link lnrpc.PendingUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {lnrpc.IPendingUpdate} message PendingUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PendingUpdate} PendingUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.outputIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PendingUpdate} PendingUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingUpdate message.
         * @function verify
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txid != null && message.hasOwnProperty("txid"))
                if (!(message.txid && typeof message.txid.length === "number" || $util.isString(message.txid)))
                    return "txid: buffer expected";
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                if (!$util.isInteger(message.outputIndex))
                    return "outputIndex: integer expected";
            return null;
        };

        /**
         * Creates a PendingUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PendingUpdate} PendingUpdate
         */
        PendingUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PendingUpdate)
                return object;
            let message = new $root.lnrpc.PendingUpdate();
            if (object.txid != null)
                if (typeof object.txid === "string")
                    $util.base64.decode(object.txid, message.txid = $util.newBuffer($util.base64.length(object.txid)), 0);
                else if (object.txid.length)
                    message.txid = object.txid;
            if (object.outputIndex != null)
                message.outputIndex = object.outputIndex >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a PendingUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PendingUpdate
         * @static
         * @param {lnrpc.PendingUpdate} message PendingUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.txid = "";
                else {
                    object.txid = [];
                    if (options.bytes !== Array)
                        object.txid = $util.newBuffer(object.txid);
                }
                object.outputIndex = 0;
            }
            if (message.txid != null && message.hasOwnProperty("txid"))
                object.txid = options.bytes === String ? $util.base64.encode(message.txid, 0, message.txid.length) : options.bytes === Array ? Array.prototype.slice.call(message.txid) : message.txid;
            if (message.outputIndex != null && message.hasOwnProperty("outputIndex"))
                object.outputIndex = message.outputIndex;
            return object;
        };

        /**
         * Converts this PendingUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.PendingUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingUpdate;
    })();

    lnrpc.ReadyForPsbtFunding = (function() {

        /**
         * Properties of a ReadyForPsbtFunding.
         * @memberof lnrpc
         * @interface IReadyForPsbtFunding
         * @property {string|null} [fundingAddress] ReadyForPsbtFunding fundingAddress
         * @property {Long|null} [fundingAmount] ReadyForPsbtFunding fundingAmount
         * @property {Uint8Array|null} [psbt] ReadyForPsbtFunding psbt
         */

        /**
         * Constructs a new ReadyForPsbtFunding.
         * @memberof lnrpc
         * @classdesc Represents a ReadyForPsbtFunding.
         * @implements IReadyForPsbtFunding
         * @constructor
         * @param {lnrpc.IReadyForPsbtFunding=} [properties] Properties to set
         */
        function ReadyForPsbtFunding(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReadyForPsbtFunding fundingAddress.
         * @member {string} fundingAddress
         * @memberof lnrpc.ReadyForPsbtFunding
         * @instance
         */
        ReadyForPsbtFunding.prototype.fundingAddress = "";

        /**
         * ReadyForPsbtFunding fundingAmount.
         * @member {Long} fundingAmount
         * @memberof lnrpc.ReadyForPsbtFunding
         * @instance
         */
        ReadyForPsbtFunding.prototype.fundingAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ReadyForPsbtFunding psbt.
         * @member {Uint8Array} psbt
         * @memberof lnrpc.ReadyForPsbtFunding
         * @instance
         */
        ReadyForPsbtFunding.prototype.psbt = $util.newBuffer([]);

        /**
         * Creates a new ReadyForPsbtFunding instance using the specified properties.
         * @function create
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {lnrpc.IReadyForPsbtFunding=} [properties] Properties to set
         * @returns {lnrpc.ReadyForPsbtFunding} ReadyForPsbtFunding instance
         */
        ReadyForPsbtFunding.create = function create(properties) {
            return new ReadyForPsbtFunding(properties);
        };

        /**
         * Encodes the specified ReadyForPsbtFunding message. Does not implicitly {@link lnrpc.ReadyForPsbtFunding.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {lnrpc.IReadyForPsbtFunding} message ReadyForPsbtFunding message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadyForPsbtFunding.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fundingAddress != null && Object.hasOwnProperty.call(message, "fundingAddress"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fundingAddress);
            if (message.fundingAmount != null && Object.hasOwnProperty.call(message, "fundingAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fundingAmount);
            if (message.psbt != null && Object.hasOwnProperty.call(message, "psbt"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.psbt);
            return writer;
        };

        /**
         * Encodes the specified ReadyForPsbtFunding message, length delimited. Does not implicitly {@link lnrpc.ReadyForPsbtFunding.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {lnrpc.IReadyForPsbtFunding} message ReadyForPsbtFunding message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReadyForPsbtFunding.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReadyForPsbtFunding message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ReadyForPsbtFunding} ReadyForPsbtFunding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadyForPsbtFunding.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ReadyForPsbtFunding();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fundingAddress = reader.string();
                        break;
                    }
                case 2: {
                        message.fundingAmount = reader.int64();
                        break;
                    }
                case 3: {
                        message.psbt = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReadyForPsbtFunding message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ReadyForPsbtFunding} ReadyForPsbtFunding
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReadyForPsbtFunding.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReadyForPsbtFunding message.
         * @function verify
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReadyForPsbtFunding.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fundingAddress != null && message.hasOwnProperty("fundingAddress"))
                if (!$util.isString(message.fundingAddress))
                    return "fundingAddress: string expected";
            if (message.fundingAmount != null && message.hasOwnProperty("fundingAmount"))
                if (!$util.isInteger(message.fundingAmount) && !(message.fundingAmount && $util.isInteger(message.fundingAmount.low) && $util.isInteger(message.fundingAmount.high)))
                    return "fundingAmount: integer|Long expected";
            if (message.psbt != null && message.hasOwnProperty("psbt"))
                if (!(message.psbt && typeof message.psbt.length === "number" || $util.isString(message.psbt)))
                    return "psbt: buffer expected";
            return null;
        };

        /**
         * Creates a ReadyForPsbtFunding message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ReadyForPsbtFunding} ReadyForPsbtFunding
         */
        ReadyForPsbtFunding.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ReadyForPsbtFunding)
                return object;
            let message = new $root.lnrpc.ReadyForPsbtFunding();
            if (object.fundingAddress != null)
                message.fundingAddress = String(object.fundingAddress);
            if (object.fundingAmount != null)
                if ($util.Long)
                    (message.fundingAmount = $util.Long.fromValue(object.fundingAmount)).unsigned = false;
                else if (typeof object.fundingAmount === "string")
                    message.fundingAmount = parseInt(object.fundingAmount, 10);
                else if (typeof object.fundingAmount === "number")
                    message.fundingAmount = object.fundingAmount;
                else if (typeof object.fundingAmount === "object")
                    message.fundingAmount = new $util.LongBits(object.fundingAmount.low >>> 0, object.fundingAmount.high >>> 0).toNumber();
            if (object.psbt != null)
                if (typeof object.psbt === "string")
                    $util.base64.decode(object.psbt, message.psbt = $util.newBuffer($util.base64.length(object.psbt)), 0);
                else if (object.psbt.length)
                    message.psbt = object.psbt;
            return message;
        };

        /**
         * Creates a plain object from a ReadyForPsbtFunding message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ReadyForPsbtFunding
         * @static
         * @param {lnrpc.ReadyForPsbtFunding} message ReadyForPsbtFunding
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReadyForPsbtFunding.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fundingAddress = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.fundingAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fundingAmount = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.psbt = "";
                else {
                    object.psbt = [];
                    if (options.bytes !== Array)
                        object.psbt = $util.newBuffer(object.psbt);
                }
            }
            if (message.fundingAddress != null && message.hasOwnProperty("fundingAddress"))
                object.fundingAddress = message.fundingAddress;
            if (message.fundingAmount != null && message.hasOwnProperty("fundingAmount"))
                if (typeof message.fundingAmount === "number")
                    object.fundingAmount = options.longs === String ? String(message.fundingAmount) : message.fundingAmount;
                else
                    object.fundingAmount = options.longs === String ? $util.Long.prototype.toString.call(message.fundingAmount) : options.longs === Number ? new $util.LongBits(message.fundingAmount.low >>> 0, message.fundingAmount.high >>> 0).toNumber() : message.fundingAmount;
            if (message.psbt != null && message.hasOwnProperty("psbt"))
                object.psbt = options.bytes === String ? $util.base64.encode(message.psbt, 0, message.psbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.psbt) : message.psbt;
            return object;
        };

        /**
         * Converts this ReadyForPsbtFunding to JSON.
         * @function toJSON
         * @memberof lnrpc.ReadyForPsbtFunding
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReadyForPsbtFunding.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReadyForPsbtFunding;
    })();

    lnrpc.BatchOpenChannelRequest = (function() {

        /**
         * Properties of a BatchOpenChannelRequest.
         * @memberof lnrpc
         * @interface IBatchOpenChannelRequest
         * @property {Array.<lnrpc.IBatchOpenChannel>|null} [channels] BatchOpenChannelRequest channels
         * @property {number|null} [targetConf] BatchOpenChannelRequest targetConf
         * @property {Long|null} [satPerVbyte] BatchOpenChannelRequest satPerVbyte
         * @property {number|null} [minConfs] BatchOpenChannelRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] BatchOpenChannelRequest spendUnconfirmed
         * @property {string|null} [label] BatchOpenChannelRequest label
         */

        /**
         * Constructs a new BatchOpenChannelRequest.
         * @memberof lnrpc
         * @classdesc Represents a BatchOpenChannelRequest.
         * @implements IBatchOpenChannelRequest
         * @constructor
         * @param {lnrpc.IBatchOpenChannelRequest=} [properties] Properties to set
         */
        function BatchOpenChannelRequest(properties) {
            this.channels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BatchOpenChannelRequest channels.
         * @member {Array.<lnrpc.IBatchOpenChannel>} channels
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         */
        BatchOpenChannelRequest.prototype.channels = $util.emptyArray;

        /**
         * BatchOpenChannelRequest targetConf.
         * @member {number} targetConf
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         */
        BatchOpenChannelRequest.prototype.targetConf = 0;

        /**
         * BatchOpenChannelRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         */
        BatchOpenChannelRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BatchOpenChannelRequest minConfs.
         * @member {number} minConfs
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         */
        BatchOpenChannelRequest.prototype.minConfs = 0;

        /**
         * BatchOpenChannelRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         */
        BatchOpenChannelRequest.prototype.spendUnconfirmed = false;

        /**
         * BatchOpenChannelRequest label.
         * @member {string} label
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         */
        BatchOpenChannelRequest.prototype.label = "";

        /**
         * Creates a new BatchOpenChannelRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {lnrpc.IBatchOpenChannelRequest=} [properties] Properties to set
         * @returns {lnrpc.BatchOpenChannelRequest} BatchOpenChannelRequest instance
         */
        BatchOpenChannelRequest.create = function create(properties) {
            return new BatchOpenChannelRequest(properties);
        };

        /**
         * Encodes the specified BatchOpenChannelRequest message. Does not implicitly {@link lnrpc.BatchOpenChannelRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {lnrpc.IBatchOpenChannelRequest} message BatchOpenChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatchOpenChannelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channels != null && message.channels.length)
                for (let i = 0; i < message.channels.length; ++i)
                    $root.lnrpc.BatchOpenChannel.encode(message.channels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.targetConf);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.satPerVbyte);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.spendUnconfirmed);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.label);
            return writer;
        };

        /**
         * Encodes the specified BatchOpenChannelRequest message, length delimited. Does not implicitly {@link lnrpc.BatchOpenChannelRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {lnrpc.IBatchOpenChannelRequest} message BatchOpenChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatchOpenChannelRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BatchOpenChannelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.BatchOpenChannelRequest} BatchOpenChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatchOpenChannelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.BatchOpenChannelRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.channels && message.channels.length))
                            message.channels = [];
                        message.channels.push($root.lnrpc.BatchOpenChannel.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.targetConf = reader.int32();
                        break;
                    }
                case 3: {
                        message.satPerVbyte = reader.int64();
                        break;
                    }
                case 4: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 5: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                case 6: {
                        message.label = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BatchOpenChannelRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.BatchOpenChannelRequest} BatchOpenChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatchOpenChannelRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BatchOpenChannelRequest message.
         * @function verify
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BatchOpenChannelRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channels != null && message.hasOwnProperty("channels")) {
                if (!Array.isArray(message.channels))
                    return "channels: array expected";
                for (let i = 0; i < message.channels.length; ++i) {
                    let error = $root.lnrpc.BatchOpenChannel.verify(message.channels[i]);
                    if (error)
                        return "channels." + error;
                }
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            return null;
        };

        /**
         * Creates a BatchOpenChannelRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.BatchOpenChannelRequest} BatchOpenChannelRequest
         */
        BatchOpenChannelRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.BatchOpenChannelRequest)
                return object;
            let message = new $root.lnrpc.BatchOpenChannelRequest();
            if (object.channels) {
                if (!Array.isArray(object.channels))
                    throw TypeError(".lnrpc.BatchOpenChannelRequest.channels: array expected");
                message.channels = [];
                for (let i = 0; i < object.channels.length; ++i) {
                    if (typeof object.channels[i] !== "object")
                        throw TypeError(".lnrpc.BatchOpenChannelRequest.channels: object expected");
                    message.channels[i] = $root.lnrpc.BatchOpenChannel.fromObject(object.channels[i]);
                }
            }
            if (object.targetConf != null)
                message.targetConf = object.targetConf | 0;
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = false;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber();
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            if (object.label != null)
                message.label = String(object.label);
            return message;
        };

        /**
         * Creates a plain object from a BatchOpenChannelRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.BatchOpenChannelRequest
         * @static
         * @param {lnrpc.BatchOpenChannelRequest} message BatchOpenChannelRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BatchOpenChannelRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.channels = [];
            if (options.defaults) {
                object.targetConf = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
                object.minConfs = 0;
                object.spendUnconfirmed = false;
                object.label = "";
            }
            if (message.channels && message.channels.length) {
                object.channels = [];
                for (let j = 0; j < message.channels.length; ++j)
                    object.channels[j] = $root.lnrpc.BatchOpenChannel.toObject(message.channels[j], options);
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber() : message.satPerVbyte;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            return object;
        };

        /**
         * Converts this BatchOpenChannelRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.BatchOpenChannelRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BatchOpenChannelRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BatchOpenChannelRequest;
    })();

    lnrpc.BatchOpenChannel = (function() {

        /**
         * Properties of a BatchOpenChannel.
         * @memberof lnrpc
         * @interface IBatchOpenChannel
         * @property {Uint8Array|null} [nodePubkey] BatchOpenChannel nodePubkey
         * @property {Long|null} [localFundingAmount] BatchOpenChannel localFundingAmount
         * @property {Long|null} [pushSat] BatchOpenChannel pushSat
         * @property {boolean|null} ["private"] BatchOpenChannel private
         * @property {Long|null} [minHtlcMsat] BatchOpenChannel minHtlcMsat
         * @property {number|null} [remoteCsvDelay] BatchOpenChannel remoteCsvDelay
         * @property {string|null} [closeAddress] BatchOpenChannel closeAddress
         * @property {Uint8Array|null} [pendingChanId] BatchOpenChannel pendingChanId
         * @property {lnrpc.CommitmentType|null} [commitmentType] BatchOpenChannel commitmentType
         */

        /**
         * Constructs a new BatchOpenChannel.
         * @memberof lnrpc
         * @classdesc Represents a BatchOpenChannel.
         * @implements IBatchOpenChannel
         * @constructor
         * @param {lnrpc.IBatchOpenChannel=} [properties] Properties to set
         */
        function BatchOpenChannel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BatchOpenChannel nodePubkey.
         * @member {Uint8Array} nodePubkey
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.nodePubkey = $util.newBuffer([]);

        /**
         * BatchOpenChannel localFundingAmount.
         * @member {Long} localFundingAmount
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.localFundingAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BatchOpenChannel pushSat.
         * @member {Long} pushSat
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.pushSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BatchOpenChannel private.
         * @member {boolean} private
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype["private"] = false;

        /**
         * BatchOpenChannel minHtlcMsat.
         * @member {Long} minHtlcMsat
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.minHtlcMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BatchOpenChannel remoteCsvDelay.
         * @member {number} remoteCsvDelay
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.remoteCsvDelay = 0;

        /**
         * BatchOpenChannel closeAddress.
         * @member {string} closeAddress
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.closeAddress = "";

        /**
         * BatchOpenChannel pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * BatchOpenChannel commitmentType.
         * @member {lnrpc.CommitmentType} commitmentType
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         */
        BatchOpenChannel.prototype.commitmentType = 0;

        /**
         * Creates a new BatchOpenChannel instance using the specified properties.
         * @function create
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {lnrpc.IBatchOpenChannel=} [properties] Properties to set
         * @returns {lnrpc.BatchOpenChannel} BatchOpenChannel instance
         */
        BatchOpenChannel.create = function create(properties) {
            return new BatchOpenChannel(properties);
        };

        /**
         * Encodes the specified BatchOpenChannel message. Does not implicitly {@link lnrpc.BatchOpenChannel.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {lnrpc.IBatchOpenChannel} message BatchOpenChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatchOpenChannel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodePubkey != null && Object.hasOwnProperty.call(message, "nodePubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nodePubkey);
            if (message.localFundingAmount != null && Object.hasOwnProperty.call(message, "localFundingAmount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.localFundingAmount);
            if (message.pushSat != null && Object.hasOwnProperty.call(message, "pushSat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.pushSat);
            if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message["private"]);
            if (message.minHtlcMsat != null && Object.hasOwnProperty.call(message, "minHtlcMsat"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.minHtlcMsat);
            if (message.remoteCsvDelay != null && Object.hasOwnProperty.call(message, "remoteCsvDelay"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.remoteCsvDelay);
            if (message.closeAddress != null && Object.hasOwnProperty.call(message, "closeAddress"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.closeAddress);
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 8, wireType 2 =*/66).bytes(message.pendingChanId);
            if (message.commitmentType != null && Object.hasOwnProperty.call(message, "commitmentType"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.commitmentType);
            return writer;
        };

        /**
         * Encodes the specified BatchOpenChannel message, length delimited. Does not implicitly {@link lnrpc.BatchOpenChannel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {lnrpc.IBatchOpenChannel} message BatchOpenChannel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatchOpenChannel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BatchOpenChannel message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.BatchOpenChannel} BatchOpenChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatchOpenChannel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.BatchOpenChannel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodePubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.localFundingAmount = reader.int64();
                        break;
                    }
                case 3: {
                        message.pushSat = reader.int64();
                        break;
                    }
                case 4: {
                        message["private"] = reader.bool();
                        break;
                    }
                case 5: {
                        message.minHtlcMsat = reader.int64();
                        break;
                    }
                case 6: {
                        message.remoteCsvDelay = reader.uint32();
                        break;
                    }
                case 7: {
                        message.closeAddress = reader.string();
                        break;
                    }
                case 8: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 9: {
                        message.commitmentType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BatchOpenChannel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.BatchOpenChannel} BatchOpenChannel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatchOpenChannel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BatchOpenChannel message.
         * @function verify
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BatchOpenChannel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodePubkey != null && message.hasOwnProperty("nodePubkey"))
                if (!(message.nodePubkey && typeof message.nodePubkey.length === "number" || $util.isString(message.nodePubkey)))
                    return "nodePubkey: buffer expected";
            if (message.localFundingAmount != null && message.hasOwnProperty("localFundingAmount"))
                if (!$util.isInteger(message.localFundingAmount) && !(message.localFundingAmount && $util.isInteger(message.localFundingAmount.low) && $util.isInteger(message.localFundingAmount.high)))
                    return "localFundingAmount: integer|Long expected";
            if (message.pushSat != null && message.hasOwnProperty("pushSat"))
                if (!$util.isInteger(message.pushSat) && !(message.pushSat && $util.isInteger(message.pushSat.low) && $util.isInteger(message.pushSat.high)))
                    return "pushSat: integer|Long expected";
            if (message["private"] != null && message.hasOwnProperty("private"))
                if (typeof message["private"] !== "boolean")
                    return "private: boolean expected";
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (!$util.isInteger(message.minHtlcMsat) && !(message.minHtlcMsat && $util.isInteger(message.minHtlcMsat.low) && $util.isInteger(message.minHtlcMsat.high)))
                    return "minHtlcMsat: integer|Long expected";
            if (message.remoteCsvDelay != null && message.hasOwnProperty("remoteCsvDelay"))
                if (!$util.isInteger(message.remoteCsvDelay))
                    return "remoteCsvDelay: integer expected";
            if (message.closeAddress != null && message.hasOwnProperty("closeAddress"))
                if (!$util.isString(message.closeAddress))
                    return "closeAddress: string expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                switch (message.commitmentType) {
                default:
                    return "commitmentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a BatchOpenChannel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.BatchOpenChannel} BatchOpenChannel
         */
        BatchOpenChannel.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.BatchOpenChannel)
                return object;
            let message = new $root.lnrpc.BatchOpenChannel();
            if (object.nodePubkey != null)
                if (typeof object.nodePubkey === "string")
                    $util.base64.decode(object.nodePubkey, message.nodePubkey = $util.newBuffer($util.base64.length(object.nodePubkey)), 0);
                else if (object.nodePubkey.length)
                    message.nodePubkey = object.nodePubkey;
            if (object.localFundingAmount != null)
                if ($util.Long)
                    (message.localFundingAmount = $util.Long.fromValue(object.localFundingAmount)).unsigned = false;
                else if (typeof object.localFundingAmount === "string")
                    message.localFundingAmount = parseInt(object.localFundingAmount, 10);
                else if (typeof object.localFundingAmount === "number")
                    message.localFundingAmount = object.localFundingAmount;
                else if (typeof object.localFundingAmount === "object")
                    message.localFundingAmount = new $util.LongBits(object.localFundingAmount.low >>> 0, object.localFundingAmount.high >>> 0).toNumber();
            if (object.pushSat != null)
                if ($util.Long)
                    (message.pushSat = $util.Long.fromValue(object.pushSat)).unsigned = false;
                else if (typeof object.pushSat === "string")
                    message.pushSat = parseInt(object.pushSat, 10);
                else if (typeof object.pushSat === "number")
                    message.pushSat = object.pushSat;
                else if (typeof object.pushSat === "object")
                    message.pushSat = new $util.LongBits(object.pushSat.low >>> 0, object.pushSat.high >>> 0).toNumber();
            if (object["private"] != null)
                message["private"] = Boolean(object["private"]);
            if (object.minHtlcMsat != null)
                if ($util.Long)
                    (message.minHtlcMsat = $util.Long.fromValue(object.minHtlcMsat)).unsigned = false;
                else if (typeof object.minHtlcMsat === "string")
                    message.minHtlcMsat = parseInt(object.minHtlcMsat, 10);
                else if (typeof object.minHtlcMsat === "number")
                    message.minHtlcMsat = object.minHtlcMsat;
                else if (typeof object.minHtlcMsat === "object")
                    message.minHtlcMsat = new $util.LongBits(object.minHtlcMsat.low >>> 0, object.minHtlcMsat.high >>> 0).toNumber();
            if (object.remoteCsvDelay != null)
                message.remoteCsvDelay = object.remoteCsvDelay >>> 0;
            if (object.closeAddress != null)
                message.closeAddress = String(object.closeAddress);
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            switch (object.commitmentType) {
            case "UNKNOWN_COMMITMENT_TYPE":
            case 0:
                message.commitmentType = 0;
                break;
            case "LEGACY":
            case 1:
                message.commitmentType = 1;
                break;
            case "STATIC_REMOTE_KEY":
            case 2:
                message.commitmentType = 2;
                break;
            case "ANCHORS":
            case 3:
                message.commitmentType = 3;
                break;
            case "SCRIPT_ENFORCED_LEASE":
            case 4:
                message.commitmentType = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a BatchOpenChannel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.BatchOpenChannel
         * @static
         * @param {lnrpc.BatchOpenChannel} message BatchOpenChannel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BatchOpenChannel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.nodePubkey = "";
                else {
                    object.nodePubkey = [];
                    if (options.bytes !== Array)
                        object.nodePubkey = $util.newBuffer(object.nodePubkey);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.localFundingAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localFundingAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.pushSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pushSat = options.longs === String ? "0" : 0;
                object["private"] = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minHtlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlcMsat = options.longs === String ? "0" : 0;
                object.remoteCsvDelay = 0;
                object.closeAddress = "";
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                object.commitmentType = options.enums === String ? "UNKNOWN_COMMITMENT_TYPE" : 0;
            }
            if (message.nodePubkey != null && message.hasOwnProperty("nodePubkey"))
                object.nodePubkey = options.bytes === String ? $util.base64.encode(message.nodePubkey, 0, message.nodePubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.nodePubkey) : message.nodePubkey;
            if (message.localFundingAmount != null && message.hasOwnProperty("localFundingAmount"))
                if (typeof message.localFundingAmount === "number")
                    object.localFundingAmount = options.longs === String ? String(message.localFundingAmount) : message.localFundingAmount;
                else
                    object.localFundingAmount = options.longs === String ? $util.Long.prototype.toString.call(message.localFundingAmount) : options.longs === Number ? new $util.LongBits(message.localFundingAmount.low >>> 0, message.localFundingAmount.high >>> 0).toNumber() : message.localFundingAmount;
            if (message.pushSat != null && message.hasOwnProperty("pushSat"))
                if (typeof message.pushSat === "number")
                    object.pushSat = options.longs === String ? String(message.pushSat) : message.pushSat;
                else
                    object.pushSat = options.longs === String ? $util.Long.prototype.toString.call(message.pushSat) : options.longs === Number ? new $util.LongBits(message.pushSat.low >>> 0, message.pushSat.high >>> 0).toNumber() : message.pushSat;
            if (message["private"] != null && message.hasOwnProperty("private"))
                object["private"] = message["private"];
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (typeof message.minHtlcMsat === "number")
                    object.minHtlcMsat = options.longs === String ? String(message.minHtlcMsat) : message.minHtlcMsat;
                else
                    object.minHtlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlcMsat) : options.longs === Number ? new $util.LongBits(message.minHtlcMsat.low >>> 0, message.minHtlcMsat.high >>> 0).toNumber() : message.minHtlcMsat;
            if (message.remoteCsvDelay != null && message.hasOwnProperty("remoteCsvDelay"))
                object.remoteCsvDelay = message.remoteCsvDelay;
            if (message.closeAddress != null && message.hasOwnProperty("closeAddress"))
                object.closeAddress = message.closeAddress;
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                object.commitmentType = options.enums === String ? $root.lnrpc.CommitmentType[message.commitmentType] : message.commitmentType;
            return object;
        };

        /**
         * Converts this BatchOpenChannel to JSON.
         * @function toJSON
         * @memberof lnrpc.BatchOpenChannel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BatchOpenChannel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BatchOpenChannel;
    })();

    lnrpc.BatchOpenChannelResponse = (function() {

        /**
         * Properties of a BatchOpenChannelResponse.
         * @memberof lnrpc
         * @interface IBatchOpenChannelResponse
         * @property {Array.<lnrpc.IPendingUpdate>|null} [pendingChannels] BatchOpenChannelResponse pendingChannels
         */

        /**
         * Constructs a new BatchOpenChannelResponse.
         * @memberof lnrpc
         * @classdesc Represents a BatchOpenChannelResponse.
         * @implements IBatchOpenChannelResponse
         * @constructor
         * @param {lnrpc.IBatchOpenChannelResponse=} [properties] Properties to set
         */
        function BatchOpenChannelResponse(properties) {
            this.pendingChannels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BatchOpenChannelResponse pendingChannels.
         * @member {Array.<lnrpc.IPendingUpdate>} pendingChannels
         * @memberof lnrpc.BatchOpenChannelResponse
         * @instance
         */
        BatchOpenChannelResponse.prototype.pendingChannels = $util.emptyArray;

        /**
         * Creates a new BatchOpenChannelResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {lnrpc.IBatchOpenChannelResponse=} [properties] Properties to set
         * @returns {lnrpc.BatchOpenChannelResponse} BatchOpenChannelResponse instance
         */
        BatchOpenChannelResponse.create = function create(properties) {
            return new BatchOpenChannelResponse(properties);
        };

        /**
         * Encodes the specified BatchOpenChannelResponse message. Does not implicitly {@link lnrpc.BatchOpenChannelResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {lnrpc.IBatchOpenChannelResponse} message BatchOpenChannelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatchOpenChannelResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pendingChannels != null && message.pendingChannels.length)
                for (let i = 0; i < message.pendingChannels.length; ++i)
                    $root.lnrpc.PendingUpdate.encode(message.pendingChannels[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BatchOpenChannelResponse message, length delimited. Does not implicitly {@link lnrpc.BatchOpenChannelResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {lnrpc.IBatchOpenChannelResponse} message BatchOpenChannelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BatchOpenChannelResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BatchOpenChannelResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.BatchOpenChannelResponse} BatchOpenChannelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatchOpenChannelResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.BatchOpenChannelResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.pendingChannels && message.pendingChannels.length))
                            message.pendingChannels = [];
                        message.pendingChannels.push($root.lnrpc.PendingUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BatchOpenChannelResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.BatchOpenChannelResponse} BatchOpenChannelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BatchOpenChannelResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BatchOpenChannelResponse message.
         * @function verify
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BatchOpenChannelResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pendingChannels != null && message.hasOwnProperty("pendingChannels")) {
                if (!Array.isArray(message.pendingChannels))
                    return "pendingChannels: array expected";
                for (let i = 0; i < message.pendingChannels.length; ++i) {
                    let error = $root.lnrpc.PendingUpdate.verify(message.pendingChannels[i]);
                    if (error)
                        return "pendingChannels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a BatchOpenChannelResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.BatchOpenChannelResponse} BatchOpenChannelResponse
         */
        BatchOpenChannelResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.BatchOpenChannelResponse)
                return object;
            let message = new $root.lnrpc.BatchOpenChannelResponse();
            if (object.pendingChannels) {
                if (!Array.isArray(object.pendingChannels))
                    throw TypeError(".lnrpc.BatchOpenChannelResponse.pendingChannels: array expected");
                message.pendingChannels = [];
                for (let i = 0; i < object.pendingChannels.length; ++i) {
                    if (typeof object.pendingChannels[i] !== "object")
                        throw TypeError(".lnrpc.BatchOpenChannelResponse.pendingChannels: object expected");
                    message.pendingChannels[i] = $root.lnrpc.PendingUpdate.fromObject(object.pendingChannels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a BatchOpenChannelResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.BatchOpenChannelResponse
         * @static
         * @param {lnrpc.BatchOpenChannelResponse} message BatchOpenChannelResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BatchOpenChannelResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.pendingChannels = [];
            if (message.pendingChannels && message.pendingChannels.length) {
                object.pendingChannels = [];
                for (let j = 0; j < message.pendingChannels.length; ++j)
                    object.pendingChannels[j] = $root.lnrpc.PendingUpdate.toObject(message.pendingChannels[j], options);
            }
            return object;
        };

        /**
         * Converts this BatchOpenChannelResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.BatchOpenChannelResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BatchOpenChannelResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BatchOpenChannelResponse;
    })();

    lnrpc.OpenChannelRequest = (function() {

        /**
         * Properties of an OpenChannelRequest.
         * @memberof lnrpc
         * @interface IOpenChannelRequest
         * @property {Long|null} [satPerVbyte] OpenChannelRequest satPerVbyte
         * @property {Uint8Array|null} [nodePubkey] OpenChannelRequest nodePubkey
         * @property {string|null} [nodePubkeyString] OpenChannelRequest nodePubkeyString
         * @property {Long|null} [localFundingAmount] OpenChannelRequest localFundingAmount
         * @property {Long|null} [pushSat] OpenChannelRequest pushSat
         * @property {number|null} [targetConf] OpenChannelRequest targetConf
         * @property {Long|null} [satPerByte] OpenChannelRequest satPerByte
         * @property {boolean|null} ["private"] OpenChannelRequest private
         * @property {Long|null} [minHtlcMsat] OpenChannelRequest minHtlcMsat
         * @property {number|null} [remoteCsvDelay] OpenChannelRequest remoteCsvDelay
         * @property {number|null} [minConfs] OpenChannelRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] OpenChannelRequest spendUnconfirmed
         * @property {string|null} [closeAddress] OpenChannelRequest closeAddress
         * @property {lnrpc.IFundingShim|null} [fundingShim] OpenChannelRequest fundingShim
         * @property {Long|null} [remoteMaxValueInFlightMsat] OpenChannelRequest remoteMaxValueInFlightMsat
         * @property {number|null} [remoteMaxHtlcs] OpenChannelRequest remoteMaxHtlcs
         * @property {number|null} [maxLocalCsv] OpenChannelRequest maxLocalCsv
         * @property {lnrpc.CommitmentType|null} [commitmentType] OpenChannelRequest commitmentType
         * @property {boolean|null} [zeroConf] OpenChannelRequest zeroConf
         * @property {boolean|null} [scidAlias] OpenChannelRequest scidAlias
         */

        /**
         * Constructs a new OpenChannelRequest.
         * @memberof lnrpc
         * @classdesc Represents an OpenChannelRequest.
         * @implements IOpenChannelRequest
         * @constructor
         * @param {lnrpc.IOpenChannelRequest=} [properties] Properties to set
         */
        function OpenChannelRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OpenChannelRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * OpenChannelRequest nodePubkey.
         * @member {Uint8Array} nodePubkey
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.nodePubkey = $util.newBuffer([]);

        /**
         * OpenChannelRequest nodePubkeyString.
         * @member {string} nodePubkeyString
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.nodePubkeyString = "";

        /**
         * OpenChannelRequest localFundingAmount.
         * @member {Long} localFundingAmount
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.localFundingAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OpenChannelRequest pushSat.
         * @member {Long} pushSat
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.pushSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OpenChannelRequest targetConf.
         * @member {number} targetConf
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.targetConf = 0;

        /**
         * OpenChannelRequest satPerByte.
         * @member {Long} satPerByte
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.satPerByte = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OpenChannelRequest private.
         * @member {boolean} private
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype["private"] = false;

        /**
         * OpenChannelRequest minHtlcMsat.
         * @member {Long} minHtlcMsat
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.minHtlcMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * OpenChannelRequest remoteCsvDelay.
         * @member {number} remoteCsvDelay
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.remoteCsvDelay = 0;

        /**
         * OpenChannelRequest minConfs.
         * @member {number} minConfs
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.minConfs = 0;

        /**
         * OpenChannelRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.spendUnconfirmed = false;

        /**
         * OpenChannelRequest closeAddress.
         * @member {string} closeAddress
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.closeAddress = "";

        /**
         * OpenChannelRequest fundingShim.
         * @member {lnrpc.IFundingShim|null|undefined} fundingShim
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.fundingShim = null;

        /**
         * OpenChannelRequest remoteMaxValueInFlightMsat.
         * @member {Long} remoteMaxValueInFlightMsat
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.remoteMaxValueInFlightMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * OpenChannelRequest remoteMaxHtlcs.
         * @member {number} remoteMaxHtlcs
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.remoteMaxHtlcs = 0;

        /**
         * OpenChannelRequest maxLocalCsv.
         * @member {number} maxLocalCsv
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.maxLocalCsv = 0;

        /**
         * OpenChannelRequest commitmentType.
         * @member {lnrpc.CommitmentType} commitmentType
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.commitmentType = 0;

        /**
         * OpenChannelRequest zeroConf.
         * @member {boolean} zeroConf
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.zeroConf = false;

        /**
         * OpenChannelRequest scidAlias.
         * @member {boolean} scidAlias
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         */
        OpenChannelRequest.prototype.scidAlias = false;

        /**
         * Creates a new OpenChannelRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {lnrpc.IOpenChannelRequest=} [properties] Properties to set
         * @returns {lnrpc.OpenChannelRequest} OpenChannelRequest instance
         */
        OpenChannelRequest.create = function create(properties) {
            return new OpenChannelRequest(properties);
        };

        /**
         * Encodes the specified OpenChannelRequest message. Does not implicitly {@link lnrpc.OpenChannelRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {lnrpc.IOpenChannelRequest} message OpenChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenChannelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.satPerVbyte);
            if (message.nodePubkey != null && Object.hasOwnProperty.call(message, "nodePubkey"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nodePubkey);
            if (message.nodePubkeyString != null && Object.hasOwnProperty.call(message, "nodePubkeyString"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nodePubkeyString);
            if (message.localFundingAmount != null && Object.hasOwnProperty.call(message, "localFundingAmount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.localFundingAmount);
            if (message.pushSat != null && Object.hasOwnProperty.call(message, "pushSat"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.pushSat);
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.targetConf);
            if (message.satPerByte != null && Object.hasOwnProperty.call(message, "satPerByte"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.satPerByte);
            if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message["private"]);
            if (message.minHtlcMsat != null && Object.hasOwnProperty.call(message, "minHtlcMsat"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.minHtlcMsat);
            if (message.remoteCsvDelay != null && Object.hasOwnProperty.call(message, "remoteCsvDelay"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.remoteCsvDelay);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.spendUnconfirmed);
            if (message.closeAddress != null && Object.hasOwnProperty.call(message, "closeAddress"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.closeAddress);
            if (message.fundingShim != null && Object.hasOwnProperty.call(message, "fundingShim"))
                $root.lnrpc.FundingShim.encode(message.fundingShim, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.remoteMaxValueInFlightMsat != null && Object.hasOwnProperty.call(message, "remoteMaxValueInFlightMsat"))
                writer.uint32(/* id 15, wireType 0 =*/120).uint64(message.remoteMaxValueInFlightMsat);
            if (message.remoteMaxHtlcs != null && Object.hasOwnProperty.call(message, "remoteMaxHtlcs"))
                writer.uint32(/* id 16, wireType 0 =*/128).uint32(message.remoteMaxHtlcs);
            if (message.maxLocalCsv != null && Object.hasOwnProperty.call(message, "maxLocalCsv"))
                writer.uint32(/* id 17, wireType 0 =*/136).uint32(message.maxLocalCsv);
            if (message.commitmentType != null && Object.hasOwnProperty.call(message, "commitmentType"))
                writer.uint32(/* id 18, wireType 0 =*/144).int32(message.commitmentType);
            if (message.zeroConf != null && Object.hasOwnProperty.call(message, "zeroConf"))
                writer.uint32(/* id 19, wireType 0 =*/152).bool(message.zeroConf);
            if (message.scidAlias != null && Object.hasOwnProperty.call(message, "scidAlias"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.scidAlias);
            return writer;
        };

        /**
         * Encodes the specified OpenChannelRequest message, length delimited. Does not implicitly {@link lnrpc.OpenChannelRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {lnrpc.IOpenChannelRequest} message OpenChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenChannelRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OpenChannelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.OpenChannelRequest} OpenChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenChannelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.OpenChannelRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                case 2: {
                        message.nodePubkey = reader.bytes();
                        break;
                    }
                case 3: {
                        message.nodePubkeyString = reader.string();
                        break;
                    }
                case 4: {
                        message.localFundingAmount = reader.int64();
                        break;
                    }
                case 5: {
                        message.pushSat = reader.int64();
                        break;
                    }
                case 6: {
                        message.targetConf = reader.int32();
                        break;
                    }
                case 7: {
                        message.satPerByte = reader.int64();
                        break;
                    }
                case 8: {
                        message["private"] = reader.bool();
                        break;
                    }
                case 9: {
                        message.minHtlcMsat = reader.int64();
                        break;
                    }
                case 10: {
                        message.remoteCsvDelay = reader.uint32();
                        break;
                    }
                case 11: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 12: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                case 13: {
                        message.closeAddress = reader.string();
                        break;
                    }
                case 14: {
                        message.fundingShim = $root.lnrpc.FundingShim.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.remoteMaxValueInFlightMsat = reader.uint64();
                        break;
                    }
                case 16: {
                        message.remoteMaxHtlcs = reader.uint32();
                        break;
                    }
                case 17: {
                        message.maxLocalCsv = reader.uint32();
                        break;
                    }
                case 18: {
                        message.commitmentType = reader.int32();
                        break;
                    }
                case 19: {
                        message.zeroConf = reader.bool();
                        break;
                    }
                case 20: {
                        message.scidAlias = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OpenChannelRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.OpenChannelRequest} OpenChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenChannelRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OpenChannelRequest message.
         * @function verify
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OpenChannelRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            if (message.nodePubkey != null && message.hasOwnProperty("nodePubkey"))
                if (!(message.nodePubkey && typeof message.nodePubkey.length === "number" || $util.isString(message.nodePubkey)))
                    return "nodePubkey: buffer expected";
            if (message.nodePubkeyString != null && message.hasOwnProperty("nodePubkeyString"))
                if (!$util.isString(message.nodePubkeyString))
                    return "nodePubkeyString: string expected";
            if (message.localFundingAmount != null && message.hasOwnProperty("localFundingAmount"))
                if (!$util.isInteger(message.localFundingAmount) && !(message.localFundingAmount && $util.isInteger(message.localFundingAmount.low) && $util.isInteger(message.localFundingAmount.high)))
                    return "localFundingAmount: integer|Long expected";
            if (message.pushSat != null && message.hasOwnProperty("pushSat"))
                if (!$util.isInteger(message.pushSat) && !(message.pushSat && $util.isInteger(message.pushSat.low) && $util.isInteger(message.pushSat.high)))
                    return "pushSat: integer|Long expected";
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (!$util.isInteger(message.satPerByte) && !(message.satPerByte && $util.isInteger(message.satPerByte.low) && $util.isInteger(message.satPerByte.high)))
                    return "satPerByte: integer|Long expected";
            if (message["private"] != null && message.hasOwnProperty("private"))
                if (typeof message["private"] !== "boolean")
                    return "private: boolean expected";
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (!$util.isInteger(message.minHtlcMsat) && !(message.minHtlcMsat && $util.isInteger(message.minHtlcMsat.low) && $util.isInteger(message.minHtlcMsat.high)))
                    return "minHtlcMsat: integer|Long expected";
            if (message.remoteCsvDelay != null && message.hasOwnProperty("remoteCsvDelay"))
                if (!$util.isInteger(message.remoteCsvDelay))
                    return "remoteCsvDelay: integer expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            if (message.closeAddress != null && message.hasOwnProperty("closeAddress"))
                if (!$util.isString(message.closeAddress))
                    return "closeAddress: string expected";
            if (message.fundingShim != null && message.hasOwnProperty("fundingShim")) {
                let error = $root.lnrpc.FundingShim.verify(message.fundingShim);
                if (error)
                    return "fundingShim." + error;
            }
            if (message.remoteMaxValueInFlightMsat != null && message.hasOwnProperty("remoteMaxValueInFlightMsat"))
                if (!$util.isInteger(message.remoteMaxValueInFlightMsat) && !(message.remoteMaxValueInFlightMsat && $util.isInteger(message.remoteMaxValueInFlightMsat.low) && $util.isInteger(message.remoteMaxValueInFlightMsat.high)))
                    return "remoteMaxValueInFlightMsat: integer|Long expected";
            if (message.remoteMaxHtlcs != null && message.hasOwnProperty("remoteMaxHtlcs"))
                if (!$util.isInteger(message.remoteMaxHtlcs))
                    return "remoteMaxHtlcs: integer expected";
            if (message.maxLocalCsv != null && message.hasOwnProperty("maxLocalCsv"))
                if (!$util.isInteger(message.maxLocalCsv))
                    return "maxLocalCsv: integer expected";
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                switch (message.commitmentType) {
                default:
                    return "commitmentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.zeroConf != null && message.hasOwnProperty("zeroConf"))
                if (typeof message.zeroConf !== "boolean")
                    return "zeroConf: boolean expected";
            if (message.scidAlias != null && message.hasOwnProperty("scidAlias"))
                if (typeof message.scidAlias !== "boolean")
                    return "scidAlias: boolean expected";
            return null;
        };

        /**
         * Creates an OpenChannelRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.OpenChannelRequest} OpenChannelRequest
         */
        OpenChannelRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.OpenChannelRequest)
                return object;
            let message = new $root.lnrpc.OpenChannelRequest();
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            if (object.nodePubkey != null)
                if (typeof object.nodePubkey === "string")
                    $util.base64.decode(object.nodePubkey, message.nodePubkey = $util.newBuffer($util.base64.length(object.nodePubkey)), 0);
                else if (object.nodePubkey.length)
                    message.nodePubkey = object.nodePubkey;
            if (object.nodePubkeyString != null)
                message.nodePubkeyString = String(object.nodePubkeyString);
            if (object.localFundingAmount != null)
                if ($util.Long)
                    (message.localFundingAmount = $util.Long.fromValue(object.localFundingAmount)).unsigned = false;
                else if (typeof object.localFundingAmount === "string")
                    message.localFundingAmount = parseInt(object.localFundingAmount, 10);
                else if (typeof object.localFundingAmount === "number")
                    message.localFundingAmount = object.localFundingAmount;
                else if (typeof object.localFundingAmount === "object")
                    message.localFundingAmount = new $util.LongBits(object.localFundingAmount.low >>> 0, object.localFundingAmount.high >>> 0).toNumber();
            if (object.pushSat != null)
                if ($util.Long)
                    (message.pushSat = $util.Long.fromValue(object.pushSat)).unsigned = false;
                else if (typeof object.pushSat === "string")
                    message.pushSat = parseInt(object.pushSat, 10);
                else if (typeof object.pushSat === "number")
                    message.pushSat = object.pushSat;
                else if (typeof object.pushSat === "object")
                    message.pushSat = new $util.LongBits(object.pushSat.low >>> 0, object.pushSat.high >>> 0).toNumber();
            if (object.targetConf != null)
                message.targetConf = object.targetConf | 0;
            if (object.satPerByte != null)
                if ($util.Long)
                    (message.satPerByte = $util.Long.fromValue(object.satPerByte)).unsigned = false;
                else if (typeof object.satPerByte === "string")
                    message.satPerByte = parseInt(object.satPerByte, 10);
                else if (typeof object.satPerByte === "number")
                    message.satPerByte = object.satPerByte;
                else if (typeof object.satPerByte === "object")
                    message.satPerByte = new $util.LongBits(object.satPerByte.low >>> 0, object.satPerByte.high >>> 0).toNumber();
            if (object["private"] != null)
                message["private"] = Boolean(object["private"]);
            if (object.minHtlcMsat != null)
                if ($util.Long)
                    (message.minHtlcMsat = $util.Long.fromValue(object.minHtlcMsat)).unsigned = false;
                else if (typeof object.minHtlcMsat === "string")
                    message.minHtlcMsat = parseInt(object.minHtlcMsat, 10);
                else if (typeof object.minHtlcMsat === "number")
                    message.minHtlcMsat = object.minHtlcMsat;
                else if (typeof object.minHtlcMsat === "object")
                    message.minHtlcMsat = new $util.LongBits(object.minHtlcMsat.low >>> 0, object.minHtlcMsat.high >>> 0).toNumber();
            if (object.remoteCsvDelay != null)
                message.remoteCsvDelay = object.remoteCsvDelay >>> 0;
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            if (object.closeAddress != null)
                message.closeAddress = String(object.closeAddress);
            if (object.fundingShim != null) {
                if (typeof object.fundingShim !== "object")
                    throw TypeError(".lnrpc.OpenChannelRequest.fundingShim: object expected");
                message.fundingShim = $root.lnrpc.FundingShim.fromObject(object.fundingShim);
            }
            if (object.remoteMaxValueInFlightMsat != null)
                if ($util.Long)
                    (message.remoteMaxValueInFlightMsat = $util.Long.fromValue(object.remoteMaxValueInFlightMsat)).unsigned = true;
                else if (typeof object.remoteMaxValueInFlightMsat === "string")
                    message.remoteMaxValueInFlightMsat = parseInt(object.remoteMaxValueInFlightMsat, 10);
                else if (typeof object.remoteMaxValueInFlightMsat === "number")
                    message.remoteMaxValueInFlightMsat = object.remoteMaxValueInFlightMsat;
                else if (typeof object.remoteMaxValueInFlightMsat === "object")
                    message.remoteMaxValueInFlightMsat = new $util.LongBits(object.remoteMaxValueInFlightMsat.low >>> 0, object.remoteMaxValueInFlightMsat.high >>> 0).toNumber(true);
            if (object.remoteMaxHtlcs != null)
                message.remoteMaxHtlcs = object.remoteMaxHtlcs >>> 0;
            if (object.maxLocalCsv != null)
                message.maxLocalCsv = object.maxLocalCsv >>> 0;
            switch (object.commitmentType) {
            case "UNKNOWN_COMMITMENT_TYPE":
            case 0:
                message.commitmentType = 0;
                break;
            case "LEGACY":
            case 1:
                message.commitmentType = 1;
                break;
            case "STATIC_REMOTE_KEY":
            case 2:
                message.commitmentType = 2;
                break;
            case "ANCHORS":
            case 3:
                message.commitmentType = 3;
                break;
            case "SCRIPT_ENFORCED_LEASE":
            case 4:
                message.commitmentType = 4;
                break;
            }
            if (object.zeroConf != null)
                message.zeroConf = Boolean(object.zeroConf);
            if (object.scidAlias != null)
                message.scidAlias = Boolean(object.scidAlias);
            return message;
        };

        /**
         * Creates a plain object from an OpenChannelRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.OpenChannelRequest
         * @static
         * @param {lnrpc.OpenChannelRequest} message OpenChannelRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OpenChannelRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.nodePubkey = "";
                else {
                    object.nodePubkey = [];
                    if (options.bytes !== Array)
                        object.nodePubkey = $util.newBuffer(object.nodePubkey);
                }
                object.nodePubkeyString = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.localFundingAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.localFundingAmount = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.pushSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pushSat = options.longs === String ? "0" : 0;
                object.targetConf = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerByte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerByte = options.longs === String ? "0" : 0;
                object["private"] = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minHtlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlcMsat = options.longs === String ? "0" : 0;
                object.remoteCsvDelay = 0;
                object.minConfs = 0;
                object.spendUnconfirmed = false;
                object.closeAddress = "";
                object.fundingShim = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.remoteMaxValueInFlightMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.remoteMaxValueInFlightMsat = options.longs === String ? "0" : 0;
                object.remoteMaxHtlcs = 0;
                object.maxLocalCsv = 0;
                object.commitmentType = options.enums === String ? "UNKNOWN_COMMITMENT_TYPE" : 0;
                object.zeroConf = false;
                object.scidAlias = false;
            }
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            if (message.nodePubkey != null && message.hasOwnProperty("nodePubkey"))
                object.nodePubkey = options.bytes === String ? $util.base64.encode(message.nodePubkey, 0, message.nodePubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.nodePubkey) : message.nodePubkey;
            if (message.nodePubkeyString != null && message.hasOwnProperty("nodePubkeyString"))
                object.nodePubkeyString = message.nodePubkeyString;
            if (message.localFundingAmount != null && message.hasOwnProperty("localFundingAmount"))
                if (typeof message.localFundingAmount === "number")
                    object.localFundingAmount = options.longs === String ? String(message.localFundingAmount) : message.localFundingAmount;
                else
                    object.localFundingAmount = options.longs === String ? $util.Long.prototype.toString.call(message.localFundingAmount) : options.longs === Number ? new $util.LongBits(message.localFundingAmount.low >>> 0, message.localFundingAmount.high >>> 0).toNumber() : message.localFundingAmount;
            if (message.pushSat != null && message.hasOwnProperty("pushSat"))
                if (typeof message.pushSat === "number")
                    object.pushSat = options.longs === String ? String(message.pushSat) : message.pushSat;
                else
                    object.pushSat = options.longs === String ? $util.Long.prototype.toString.call(message.pushSat) : options.longs === Number ? new $util.LongBits(message.pushSat.low >>> 0, message.pushSat.high >>> 0).toNumber() : message.pushSat;
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (typeof message.satPerByte === "number")
                    object.satPerByte = options.longs === String ? String(message.satPerByte) : message.satPerByte;
                else
                    object.satPerByte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerByte) : options.longs === Number ? new $util.LongBits(message.satPerByte.low >>> 0, message.satPerByte.high >>> 0).toNumber() : message.satPerByte;
            if (message["private"] != null && message.hasOwnProperty("private"))
                object["private"] = message["private"];
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (typeof message.minHtlcMsat === "number")
                    object.minHtlcMsat = options.longs === String ? String(message.minHtlcMsat) : message.minHtlcMsat;
                else
                    object.minHtlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlcMsat) : options.longs === Number ? new $util.LongBits(message.minHtlcMsat.low >>> 0, message.minHtlcMsat.high >>> 0).toNumber() : message.minHtlcMsat;
            if (message.remoteCsvDelay != null && message.hasOwnProperty("remoteCsvDelay"))
                object.remoteCsvDelay = message.remoteCsvDelay;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            if (message.closeAddress != null && message.hasOwnProperty("closeAddress"))
                object.closeAddress = message.closeAddress;
            if (message.fundingShim != null && message.hasOwnProperty("fundingShim"))
                object.fundingShim = $root.lnrpc.FundingShim.toObject(message.fundingShim, options);
            if (message.remoteMaxValueInFlightMsat != null && message.hasOwnProperty("remoteMaxValueInFlightMsat"))
                if (typeof message.remoteMaxValueInFlightMsat === "number")
                    object.remoteMaxValueInFlightMsat = options.longs === String ? String(message.remoteMaxValueInFlightMsat) : message.remoteMaxValueInFlightMsat;
                else
                    object.remoteMaxValueInFlightMsat = options.longs === String ? $util.Long.prototype.toString.call(message.remoteMaxValueInFlightMsat) : options.longs === Number ? new $util.LongBits(message.remoteMaxValueInFlightMsat.low >>> 0, message.remoteMaxValueInFlightMsat.high >>> 0).toNumber(true) : message.remoteMaxValueInFlightMsat;
            if (message.remoteMaxHtlcs != null && message.hasOwnProperty("remoteMaxHtlcs"))
                object.remoteMaxHtlcs = message.remoteMaxHtlcs;
            if (message.maxLocalCsv != null && message.hasOwnProperty("maxLocalCsv"))
                object.maxLocalCsv = message.maxLocalCsv;
            if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                object.commitmentType = options.enums === String ? $root.lnrpc.CommitmentType[message.commitmentType] : message.commitmentType;
            if (message.zeroConf != null && message.hasOwnProperty("zeroConf"))
                object.zeroConf = message.zeroConf;
            if (message.scidAlias != null && message.hasOwnProperty("scidAlias"))
                object.scidAlias = message.scidAlias;
            return object;
        };

        /**
         * Converts this OpenChannelRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.OpenChannelRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OpenChannelRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OpenChannelRequest;
    })();

    lnrpc.OpenStatusUpdate = (function() {

        /**
         * Properties of an OpenStatusUpdate.
         * @memberof lnrpc
         * @interface IOpenStatusUpdate
         * @property {lnrpc.IPendingUpdate|null} [chanPending] OpenStatusUpdate chanPending
         * @property {lnrpc.IChannelOpenUpdate|null} [chanOpen] OpenStatusUpdate chanOpen
         * @property {lnrpc.IReadyForPsbtFunding|null} [psbtFund] OpenStatusUpdate psbtFund
         * @property {Uint8Array|null} [pendingChanId] OpenStatusUpdate pendingChanId
         */

        /**
         * Constructs a new OpenStatusUpdate.
         * @memberof lnrpc
         * @classdesc Represents an OpenStatusUpdate.
         * @implements IOpenStatusUpdate
         * @constructor
         * @param {lnrpc.IOpenStatusUpdate=} [properties] Properties to set
         */
        function OpenStatusUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OpenStatusUpdate chanPending.
         * @member {lnrpc.IPendingUpdate|null|undefined} chanPending
         * @memberof lnrpc.OpenStatusUpdate
         * @instance
         */
        OpenStatusUpdate.prototype.chanPending = null;

        /**
         * OpenStatusUpdate chanOpen.
         * @member {lnrpc.IChannelOpenUpdate|null|undefined} chanOpen
         * @memberof lnrpc.OpenStatusUpdate
         * @instance
         */
        OpenStatusUpdate.prototype.chanOpen = null;

        /**
         * OpenStatusUpdate psbtFund.
         * @member {lnrpc.IReadyForPsbtFunding|null|undefined} psbtFund
         * @memberof lnrpc.OpenStatusUpdate
         * @instance
         */
        OpenStatusUpdate.prototype.psbtFund = null;

        /**
         * OpenStatusUpdate pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.OpenStatusUpdate
         * @instance
         */
        OpenStatusUpdate.prototype.pendingChanId = $util.newBuffer([]);

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * OpenStatusUpdate update.
         * @member {"chanPending"|"chanOpen"|"psbtFund"|undefined} update
         * @memberof lnrpc.OpenStatusUpdate
         * @instance
         */
        Object.defineProperty(OpenStatusUpdate.prototype, "update", {
            get: $util.oneOfGetter($oneOfFields = ["chanPending", "chanOpen", "psbtFund"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new OpenStatusUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {lnrpc.IOpenStatusUpdate=} [properties] Properties to set
         * @returns {lnrpc.OpenStatusUpdate} OpenStatusUpdate instance
         */
        OpenStatusUpdate.create = function create(properties) {
            return new OpenStatusUpdate(properties);
        };

        /**
         * Encodes the specified OpenStatusUpdate message. Does not implicitly {@link lnrpc.OpenStatusUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {lnrpc.IOpenStatusUpdate} message OpenStatusUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenStatusUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanPending != null && Object.hasOwnProperty.call(message, "chanPending"))
                $root.lnrpc.PendingUpdate.encode(message.chanPending, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.chanOpen != null && Object.hasOwnProperty.call(message, "chanOpen"))
                $root.lnrpc.ChannelOpenUpdate.encode(message.chanOpen, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.pendingChanId);
            if (message.psbtFund != null && Object.hasOwnProperty.call(message, "psbtFund"))
                $root.lnrpc.ReadyForPsbtFunding.encode(message.psbtFund, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified OpenStatusUpdate message, length delimited. Does not implicitly {@link lnrpc.OpenStatusUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {lnrpc.IOpenStatusUpdate} message OpenStatusUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OpenStatusUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OpenStatusUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.OpenStatusUpdate} OpenStatusUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenStatusUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.OpenStatusUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanPending = $root.lnrpc.PendingUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.chanOpen = $root.lnrpc.ChannelOpenUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.psbtFund = $root.lnrpc.ReadyForPsbtFunding.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OpenStatusUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.OpenStatusUpdate} OpenStatusUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OpenStatusUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OpenStatusUpdate message.
         * @function verify
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OpenStatusUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.chanPending != null && message.hasOwnProperty("chanPending")) {
                properties.update = 1;
                {
                    let error = $root.lnrpc.PendingUpdate.verify(message.chanPending);
                    if (error)
                        return "chanPending." + error;
                }
            }
            if (message.chanOpen != null && message.hasOwnProperty("chanOpen")) {
                if (properties.update === 1)
                    return "update: multiple values";
                properties.update = 1;
                {
                    let error = $root.lnrpc.ChannelOpenUpdate.verify(message.chanOpen);
                    if (error)
                        return "chanOpen." + error;
                }
            }
            if (message.psbtFund != null && message.hasOwnProperty("psbtFund")) {
                if (properties.update === 1)
                    return "update: multiple values";
                properties.update = 1;
                {
                    let error = $root.lnrpc.ReadyForPsbtFunding.verify(message.psbtFund);
                    if (error)
                        return "psbtFund." + error;
                }
            }
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            return null;
        };

        /**
         * Creates an OpenStatusUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.OpenStatusUpdate} OpenStatusUpdate
         */
        OpenStatusUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.OpenStatusUpdate)
                return object;
            let message = new $root.lnrpc.OpenStatusUpdate();
            if (object.chanPending != null) {
                if (typeof object.chanPending !== "object")
                    throw TypeError(".lnrpc.OpenStatusUpdate.chanPending: object expected");
                message.chanPending = $root.lnrpc.PendingUpdate.fromObject(object.chanPending);
            }
            if (object.chanOpen != null) {
                if (typeof object.chanOpen !== "object")
                    throw TypeError(".lnrpc.OpenStatusUpdate.chanOpen: object expected");
                message.chanOpen = $root.lnrpc.ChannelOpenUpdate.fromObject(object.chanOpen);
            }
            if (object.psbtFund != null) {
                if (typeof object.psbtFund !== "object")
                    throw TypeError(".lnrpc.OpenStatusUpdate.psbtFund: object expected");
                message.psbtFund = $root.lnrpc.ReadyForPsbtFunding.fromObject(object.psbtFund);
            }
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            return message;
        };

        /**
         * Creates a plain object from an OpenStatusUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.OpenStatusUpdate
         * @static
         * @param {lnrpc.OpenStatusUpdate} message OpenStatusUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OpenStatusUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
            if (message.chanPending != null && message.hasOwnProperty("chanPending")) {
                object.chanPending = $root.lnrpc.PendingUpdate.toObject(message.chanPending, options);
                if (options.oneofs)
                    object.update = "chanPending";
            }
            if (message.chanOpen != null && message.hasOwnProperty("chanOpen")) {
                object.chanOpen = $root.lnrpc.ChannelOpenUpdate.toObject(message.chanOpen, options);
                if (options.oneofs)
                    object.update = "chanOpen";
            }
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.psbtFund != null && message.hasOwnProperty("psbtFund")) {
                object.psbtFund = $root.lnrpc.ReadyForPsbtFunding.toObject(message.psbtFund, options);
                if (options.oneofs)
                    object.update = "psbtFund";
            }
            return object;
        };

        /**
         * Converts this OpenStatusUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.OpenStatusUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OpenStatusUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OpenStatusUpdate;
    })();

    lnrpc.KeyLocator = (function() {

        /**
         * Properties of a KeyLocator.
         * @memberof lnrpc
         * @interface IKeyLocator
         * @property {number|null} [keyFamily] KeyLocator keyFamily
         * @property {number|null} [keyIndex] KeyLocator keyIndex
         */

        /**
         * Constructs a new KeyLocator.
         * @memberof lnrpc
         * @classdesc Represents a KeyLocator.
         * @implements IKeyLocator
         * @constructor
         * @param {lnrpc.IKeyLocator=} [properties] Properties to set
         */
        function KeyLocator(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyLocator keyFamily.
         * @member {number} keyFamily
         * @memberof lnrpc.KeyLocator
         * @instance
         */
        KeyLocator.prototype.keyFamily = 0;

        /**
         * KeyLocator keyIndex.
         * @member {number} keyIndex
         * @memberof lnrpc.KeyLocator
         * @instance
         */
        KeyLocator.prototype.keyIndex = 0;

        /**
         * Creates a new KeyLocator instance using the specified properties.
         * @function create
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {lnrpc.IKeyLocator=} [properties] Properties to set
         * @returns {lnrpc.KeyLocator} KeyLocator instance
         */
        KeyLocator.create = function create(properties) {
            return new KeyLocator(properties);
        };

        /**
         * Encodes the specified KeyLocator message. Does not implicitly {@link lnrpc.KeyLocator.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {lnrpc.IKeyLocator} message KeyLocator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyLocator.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keyFamily != null && Object.hasOwnProperty.call(message, "keyFamily"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.keyFamily);
            if (message.keyIndex != null && Object.hasOwnProperty.call(message, "keyIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.keyIndex);
            return writer;
        };

        /**
         * Encodes the specified KeyLocator message, length delimited. Does not implicitly {@link lnrpc.KeyLocator.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {lnrpc.IKeyLocator} message KeyLocator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyLocator.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeyLocator message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.KeyLocator} KeyLocator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyLocator.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.KeyLocator();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.keyFamily = reader.int32();
                        break;
                    }
                case 2: {
                        message.keyIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeyLocator message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.KeyLocator} KeyLocator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyLocator.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeyLocator message.
         * @function verify
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeyLocator.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.keyFamily != null && message.hasOwnProperty("keyFamily"))
                if (!$util.isInteger(message.keyFamily))
                    return "keyFamily: integer expected";
            if (message.keyIndex != null && message.hasOwnProperty("keyIndex"))
                if (!$util.isInteger(message.keyIndex))
                    return "keyIndex: integer expected";
            return null;
        };

        /**
         * Creates a KeyLocator message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.KeyLocator} KeyLocator
         */
        KeyLocator.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.KeyLocator)
                return object;
            let message = new $root.lnrpc.KeyLocator();
            if (object.keyFamily != null)
                message.keyFamily = object.keyFamily | 0;
            if (object.keyIndex != null)
                message.keyIndex = object.keyIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from a KeyLocator message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.KeyLocator
         * @static
         * @param {lnrpc.KeyLocator} message KeyLocator
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeyLocator.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.keyFamily = 0;
                object.keyIndex = 0;
            }
            if (message.keyFamily != null && message.hasOwnProperty("keyFamily"))
                object.keyFamily = message.keyFamily;
            if (message.keyIndex != null && message.hasOwnProperty("keyIndex"))
                object.keyIndex = message.keyIndex;
            return object;
        };

        /**
         * Converts this KeyLocator to JSON.
         * @function toJSON
         * @memberof lnrpc.KeyLocator
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeyLocator.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KeyLocator;
    })();

    lnrpc.KeyDescriptor = (function() {

        /**
         * Properties of a KeyDescriptor.
         * @memberof lnrpc
         * @interface IKeyDescriptor
         * @property {Uint8Array|null} [rawKeyBytes] KeyDescriptor rawKeyBytes
         * @property {lnrpc.IKeyLocator|null} [keyLoc] KeyDescriptor keyLoc
         */

        /**
         * Constructs a new KeyDescriptor.
         * @memberof lnrpc
         * @classdesc Represents a KeyDescriptor.
         * @implements IKeyDescriptor
         * @constructor
         * @param {lnrpc.IKeyDescriptor=} [properties] Properties to set
         */
        function KeyDescriptor(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyDescriptor rawKeyBytes.
         * @member {Uint8Array} rawKeyBytes
         * @memberof lnrpc.KeyDescriptor
         * @instance
         */
        KeyDescriptor.prototype.rawKeyBytes = $util.newBuffer([]);

        /**
         * KeyDescriptor keyLoc.
         * @member {lnrpc.IKeyLocator|null|undefined} keyLoc
         * @memberof lnrpc.KeyDescriptor
         * @instance
         */
        KeyDescriptor.prototype.keyLoc = null;

        /**
         * Creates a new KeyDescriptor instance using the specified properties.
         * @function create
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {lnrpc.IKeyDescriptor=} [properties] Properties to set
         * @returns {lnrpc.KeyDescriptor} KeyDescriptor instance
         */
        KeyDescriptor.create = function create(properties) {
            return new KeyDescriptor(properties);
        };

        /**
         * Encodes the specified KeyDescriptor message. Does not implicitly {@link lnrpc.KeyDescriptor.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {lnrpc.IKeyDescriptor} message KeyDescriptor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyDescriptor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawKeyBytes != null && Object.hasOwnProperty.call(message, "rawKeyBytes"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rawKeyBytes);
            if (message.keyLoc != null && Object.hasOwnProperty.call(message, "keyLoc"))
                $root.lnrpc.KeyLocator.encode(message.keyLoc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified KeyDescriptor message, length delimited. Does not implicitly {@link lnrpc.KeyDescriptor.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {lnrpc.IKeyDescriptor} message KeyDescriptor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyDescriptor.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeyDescriptor message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.KeyDescriptor} KeyDescriptor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyDescriptor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.KeyDescriptor();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rawKeyBytes = reader.bytes();
                        break;
                    }
                case 2: {
                        message.keyLoc = $root.lnrpc.KeyLocator.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeyDescriptor message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.KeyDescriptor} KeyDescriptor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyDescriptor.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeyDescriptor message.
         * @function verify
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeyDescriptor.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawKeyBytes != null && message.hasOwnProperty("rawKeyBytes"))
                if (!(message.rawKeyBytes && typeof message.rawKeyBytes.length === "number" || $util.isString(message.rawKeyBytes)))
                    return "rawKeyBytes: buffer expected";
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc")) {
                let error = $root.lnrpc.KeyLocator.verify(message.keyLoc);
                if (error)
                    return "keyLoc." + error;
            }
            return null;
        };

        /**
         * Creates a KeyDescriptor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.KeyDescriptor} KeyDescriptor
         */
        KeyDescriptor.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.KeyDescriptor)
                return object;
            let message = new $root.lnrpc.KeyDescriptor();
            if (object.rawKeyBytes != null)
                if (typeof object.rawKeyBytes === "string")
                    $util.base64.decode(object.rawKeyBytes, message.rawKeyBytes = $util.newBuffer($util.base64.length(object.rawKeyBytes)), 0);
                else if (object.rawKeyBytes.length)
                    message.rawKeyBytes = object.rawKeyBytes;
            if (object.keyLoc != null) {
                if (typeof object.keyLoc !== "object")
                    throw TypeError(".lnrpc.KeyDescriptor.keyLoc: object expected");
                message.keyLoc = $root.lnrpc.KeyLocator.fromObject(object.keyLoc);
            }
            return message;
        };

        /**
         * Creates a plain object from a KeyDescriptor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.KeyDescriptor
         * @static
         * @param {lnrpc.KeyDescriptor} message KeyDescriptor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeyDescriptor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.rawKeyBytes = "";
                else {
                    object.rawKeyBytes = [];
                    if (options.bytes !== Array)
                        object.rawKeyBytes = $util.newBuffer(object.rawKeyBytes);
                }
                object.keyLoc = null;
            }
            if (message.rawKeyBytes != null && message.hasOwnProperty("rawKeyBytes"))
                object.rawKeyBytes = options.bytes === String ? $util.base64.encode(message.rawKeyBytes, 0, message.rawKeyBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawKeyBytes) : message.rawKeyBytes;
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc"))
                object.keyLoc = $root.lnrpc.KeyLocator.toObject(message.keyLoc, options);
            return object;
        };

        /**
         * Converts this KeyDescriptor to JSON.
         * @function toJSON
         * @memberof lnrpc.KeyDescriptor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeyDescriptor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KeyDescriptor;
    })();

    lnrpc.ChanPointShim = (function() {

        /**
         * Properties of a ChanPointShim.
         * @memberof lnrpc
         * @interface IChanPointShim
         * @property {Long|null} [amt] ChanPointShim amt
         * @property {lnrpc.IChannelPoint|null} [chanPoint] ChanPointShim chanPoint
         * @property {lnrpc.IKeyDescriptor|null} [localKey] ChanPointShim localKey
         * @property {Uint8Array|null} [remoteKey] ChanPointShim remoteKey
         * @property {Uint8Array|null} [pendingChanId] ChanPointShim pendingChanId
         * @property {number|null} [thawHeight] ChanPointShim thawHeight
         */

        /**
         * Constructs a new ChanPointShim.
         * @memberof lnrpc
         * @classdesc Represents a ChanPointShim.
         * @implements IChanPointShim
         * @constructor
         * @param {lnrpc.IChanPointShim=} [properties] Properties to set
         */
        function ChanPointShim(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChanPointShim amt.
         * @member {Long} amt
         * @memberof lnrpc.ChanPointShim
         * @instance
         */
        ChanPointShim.prototype.amt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChanPointShim chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof lnrpc.ChanPointShim
         * @instance
         */
        ChanPointShim.prototype.chanPoint = null;

        /**
         * ChanPointShim localKey.
         * @member {lnrpc.IKeyDescriptor|null|undefined} localKey
         * @memberof lnrpc.ChanPointShim
         * @instance
         */
        ChanPointShim.prototype.localKey = null;

        /**
         * ChanPointShim remoteKey.
         * @member {Uint8Array} remoteKey
         * @memberof lnrpc.ChanPointShim
         * @instance
         */
        ChanPointShim.prototype.remoteKey = $util.newBuffer([]);

        /**
         * ChanPointShim pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.ChanPointShim
         * @instance
         */
        ChanPointShim.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * ChanPointShim thawHeight.
         * @member {number} thawHeight
         * @memberof lnrpc.ChanPointShim
         * @instance
         */
        ChanPointShim.prototype.thawHeight = 0;

        /**
         * Creates a new ChanPointShim instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {lnrpc.IChanPointShim=} [properties] Properties to set
         * @returns {lnrpc.ChanPointShim} ChanPointShim instance
         */
        ChanPointShim.create = function create(properties) {
            return new ChanPointShim(properties);
        };

        /**
         * Encodes the specified ChanPointShim message. Does not implicitly {@link lnrpc.ChanPointShim.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {lnrpc.IChanPointShim} message ChanPointShim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanPointShim.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amt != null && Object.hasOwnProperty.call(message, "amt"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.amt);
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                $root.lnrpc.KeyDescriptor.encode(message.localKey, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.remoteKey != null && Object.hasOwnProperty.call(message, "remoteKey"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.remoteKey);
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.pendingChanId);
            if (message.thawHeight != null && Object.hasOwnProperty.call(message, "thawHeight"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.thawHeight);
            return writer;
        };

        /**
         * Encodes the specified ChanPointShim message, length delimited. Does not implicitly {@link lnrpc.ChanPointShim.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {lnrpc.IChanPointShim} message ChanPointShim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanPointShim.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChanPointShim message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChanPointShim} ChanPointShim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanPointShim.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChanPointShim();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.amt = reader.int64();
                        break;
                    }
                case 2: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.localKey = $root.lnrpc.KeyDescriptor.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.remoteKey = reader.bytes();
                        break;
                    }
                case 5: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 6: {
                        message.thawHeight = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChanPointShim message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChanPointShim} ChanPointShim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanPointShim.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChanPointShim message.
         * @function verify
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChanPointShim.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (!$util.isInteger(message.amt) && !(message.amt && $util.isInteger(message.amt.low) && $util.isInteger(message.amt.high)))
                    return "amt: integer|Long expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                if (error)
                    return "chanPoint." + error;
            }
            if (message.localKey != null && message.hasOwnProperty("localKey")) {
                let error = $root.lnrpc.KeyDescriptor.verify(message.localKey);
                if (error)
                    return "localKey." + error;
            }
            if (message.remoteKey != null && message.hasOwnProperty("remoteKey"))
                if (!(message.remoteKey && typeof message.remoteKey.length === "number" || $util.isString(message.remoteKey)))
                    return "remoteKey: buffer expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.thawHeight != null && message.hasOwnProperty("thawHeight"))
                if (!$util.isInteger(message.thawHeight))
                    return "thawHeight: integer expected";
            return null;
        };

        /**
         * Creates a ChanPointShim message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChanPointShim} ChanPointShim
         */
        ChanPointShim.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChanPointShim)
                return object;
            let message = new $root.lnrpc.ChanPointShim();
            if (object.amt != null)
                if ($util.Long)
                    (message.amt = $util.Long.fromValue(object.amt)).unsigned = false;
                else if (typeof object.amt === "string")
                    message.amt = parseInt(object.amt, 10);
                else if (typeof object.amt === "number")
                    message.amt = object.amt;
                else if (typeof object.amt === "object")
                    message.amt = new $util.LongBits(object.amt.low >>> 0, object.amt.high >>> 0).toNumber();
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".lnrpc.ChanPointShim.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            if (object.localKey != null) {
                if (typeof object.localKey !== "object")
                    throw TypeError(".lnrpc.ChanPointShim.localKey: object expected");
                message.localKey = $root.lnrpc.KeyDescriptor.fromObject(object.localKey);
            }
            if (object.remoteKey != null)
                if (typeof object.remoteKey === "string")
                    $util.base64.decode(object.remoteKey, message.remoteKey = $util.newBuffer($util.base64.length(object.remoteKey)), 0);
                else if (object.remoteKey.length)
                    message.remoteKey = object.remoteKey;
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            if (object.thawHeight != null)
                message.thawHeight = object.thawHeight >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ChanPointShim message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChanPointShim
         * @static
         * @param {lnrpc.ChanPointShim} message ChanPointShim
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChanPointShim.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amt = options.longs === String ? "0" : 0;
                object.chanPoint = null;
                object.localKey = null;
                if (options.bytes === String)
                    object.remoteKey = "";
                else {
                    object.remoteKey = [];
                    if (options.bytes !== Array)
                        object.remoteKey = $util.newBuffer(object.remoteKey);
                }
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                object.thawHeight = 0;
            }
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (typeof message.amt === "number")
                    object.amt = options.longs === String ? String(message.amt) : message.amt;
                else
                    object.amt = options.longs === String ? $util.Long.prototype.toString.call(message.amt) : options.longs === Number ? new $util.LongBits(message.amt.low >>> 0, message.amt.high >>> 0).toNumber() : message.amt;
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = $root.lnrpc.KeyDescriptor.toObject(message.localKey, options);
            if (message.remoteKey != null && message.hasOwnProperty("remoteKey"))
                object.remoteKey = options.bytes === String ? $util.base64.encode(message.remoteKey, 0, message.remoteKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.remoteKey) : message.remoteKey;
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.thawHeight != null && message.hasOwnProperty("thawHeight"))
                object.thawHeight = message.thawHeight;
            return object;
        };

        /**
         * Converts this ChanPointShim to JSON.
         * @function toJSON
         * @memberof lnrpc.ChanPointShim
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChanPointShim.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChanPointShim;
    })();

    lnrpc.PsbtShim = (function() {

        /**
         * Properties of a PsbtShim.
         * @memberof lnrpc
         * @interface IPsbtShim
         * @property {Uint8Array|null} [pendingChanId] PsbtShim pendingChanId
         * @property {Uint8Array|null} [basePsbt] PsbtShim basePsbt
         * @property {boolean|null} [noPublish] PsbtShim noPublish
         */

        /**
         * Constructs a new PsbtShim.
         * @memberof lnrpc
         * @classdesc Represents a PsbtShim.
         * @implements IPsbtShim
         * @constructor
         * @param {lnrpc.IPsbtShim=} [properties] Properties to set
         */
        function PsbtShim(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PsbtShim pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.PsbtShim
         * @instance
         */
        PsbtShim.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * PsbtShim basePsbt.
         * @member {Uint8Array} basePsbt
         * @memberof lnrpc.PsbtShim
         * @instance
         */
        PsbtShim.prototype.basePsbt = $util.newBuffer([]);

        /**
         * PsbtShim noPublish.
         * @member {boolean} noPublish
         * @memberof lnrpc.PsbtShim
         * @instance
         */
        PsbtShim.prototype.noPublish = false;

        /**
         * Creates a new PsbtShim instance using the specified properties.
         * @function create
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {lnrpc.IPsbtShim=} [properties] Properties to set
         * @returns {lnrpc.PsbtShim} PsbtShim instance
         */
        PsbtShim.create = function create(properties) {
            return new PsbtShim(properties);
        };

        /**
         * Encodes the specified PsbtShim message. Does not implicitly {@link lnrpc.PsbtShim.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {lnrpc.IPsbtShim} message PsbtShim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PsbtShim.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pendingChanId);
            if (message.basePsbt != null && Object.hasOwnProperty.call(message, "basePsbt"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.basePsbt);
            if (message.noPublish != null && Object.hasOwnProperty.call(message, "noPublish"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.noPublish);
            return writer;
        };

        /**
         * Encodes the specified PsbtShim message, length delimited. Does not implicitly {@link lnrpc.PsbtShim.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {lnrpc.IPsbtShim} message PsbtShim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PsbtShim.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PsbtShim message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PsbtShim} PsbtShim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PsbtShim.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PsbtShim();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.basePsbt = reader.bytes();
                        break;
                    }
                case 3: {
                        message.noPublish = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PsbtShim message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PsbtShim} PsbtShim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PsbtShim.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PsbtShim message.
         * @function verify
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PsbtShim.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.basePsbt != null && message.hasOwnProperty("basePsbt"))
                if (!(message.basePsbt && typeof message.basePsbt.length === "number" || $util.isString(message.basePsbt)))
                    return "basePsbt: buffer expected";
            if (message.noPublish != null && message.hasOwnProperty("noPublish"))
                if (typeof message.noPublish !== "boolean")
                    return "noPublish: boolean expected";
            return null;
        };

        /**
         * Creates a PsbtShim message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PsbtShim} PsbtShim
         */
        PsbtShim.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PsbtShim)
                return object;
            let message = new $root.lnrpc.PsbtShim();
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            if (object.basePsbt != null)
                if (typeof object.basePsbt === "string")
                    $util.base64.decode(object.basePsbt, message.basePsbt = $util.newBuffer($util.base64.length(object.basePsbt)), 0);
                else if (object.basePsbt.length)
                    message.basePsbt = object.basePsbt;
            if (object.noPublish != null)
                message.noPublish = Boolean(object.noPublish);
            return message;
        };

        /**
         * Creates a plain object from a PsbtShim message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PsbtShim
         * @static
         * @param {lnrpc.PsbtShim} message PsbtShim
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PsbtShim.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                if (options.bytes === String)
                    object.basePsbt = "";
                else {
                    object.basePsbt = [];
                    if (options.bytes !== Array)
                        object.basePsbt = $util.newBuffer(object.basePsbt);
                }
                object.noPublish = false;
            }
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.basePsbt != null && message.hasOwnProperty("basePsbt"))
                object.basePsbt = options.bytes === String ? $util.base64.encode(message.basePsbt, 0, message.basePsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.basePsbt) : message.basePsbt;
            if (message.noPublish != null && message.hasOwnProperty("noPublish"))
                object.noPublish = message.noPublish;
            return object;
        };

        /**
         * Converts this PsbtShim to JSON.
         * @function toJSON
         * @memberof lnrpc.PsbtShim
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PsbtShim.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PsbtShim;
    })();

    lnrpc.FundingShim = (function() {

        /**
         * Properties of a FundingShim.
         * @memberof lnrpc
         * @interface IFundingShim
         * @property {lnrpc.IChanPointShim|null} [chanPointShim] FundingShim chanPointShim
         * @property {lnrpc.IPsbtShim|null} [psbtShim] FundingShim psbtShim
         */

        /**
         * Constructs a new FundingShim.
         * @memberof lnrpc
         * @classdesc Represents a FundingShim.
         * @implements IFundingShim
         * @constructor
         * @param {lnrpc.IFundingShim=} [properties] Properties to set
         */
        function FundingShim(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundingShim chanPointShim.
         * @member {lnrpc.IChanPointShim|null|undefined} chanPointShim
         * @memberof lnrpc.FundingShim
         * @instance
         */
        FundingShim.prototype.chanPointShim = null;

        /**
         * FundingShim psbtShim.
         * @member {lnrpc.IPsbtShim|null|undefined} psbtShim
         * @memberof lnrpc.FundingShim
         * @instance
         */
        FundingShim.prototype.psbtShim = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * FundingShim shim.
         * @member {"chanPointShim"|"psbtShim"|undefined} shim
         * @memberof lnrpc.FundingShim
         * @instance
         */
        Object.defineProperty(FundingShim.prototype, "shim", {
            get: $util.oneOfGetter($oneOfFields = ["chanPointShim", "psbtShim"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FundingShim instance using the specified properties.
         * @function create
         * @memberof lnrpc.FundingShim
         * @static
         * @param {lnrpc.IFundingShim=} [properties] Properties to set
         * @returns {lnrpc.FundingShim} FundingShim instance
         */
        FundingShim.create = function create(properties) {
            return new FundingShim(properties);
        };

        /**
         * Encodes the specified FundingShim message. Does not implicitly {@link lnrpc.FundingShim.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FundingShim
         * @static
         * @param {lnrpc.IFundingShim} message FundingShim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingShim.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanPointShim != null && Object.hasOwnProperty.call(message, "chanPointShim"))
                $root.lnrpc.ChanPointShim.encode(message.chanPointShim, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.psbtShim != null && Object.hasOwnProperty.call(message, "psbtShim"))
                $root.lnrpc.PsbtShim.encode(message.psbtShim, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FundingShim message, length delimited. Does not implicitly {@link lnrpc.FundingShim.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FundingShim
         * @static
         * @param {lnrpc.IFundingShim} message FundingShim message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingShim.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundingShim message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FundingShim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FundingShim} FundingShim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingShim.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FundingShim();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanPointShim = $root.lnrpc.ChanPointShim.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.psbtShim = $root.lnrpc.PsbtShim.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundingShim message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FundingShim
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FundingShim} FundingShim
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingShim.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundingShim message.
         * @function verify
         * @memberof lnrpc.FundingShim
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundingShim.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.chanPointShim != null && message.hasOwnProperty("chanPointShim")) {
                properties.shim = 1;
                {
                    let error = $root.lnrpc.ChanPointShim.verify(message.chanPointShim);
                    if (error)
                        return "chanPointShim." + error;
                }
            }
            if (message.psbtShim != null && message.hasOwnProperty("psbtShim")) {
                if (properties.shim === 1)
                    return "shim: multiple values";
                properties.shim = 1;
                {
                    let error = $root.lnrpc.PsbtShim.verify(message.psbtShim);
                    if (error)
                        return "psbtShim." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FundingShim message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FundingShim
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FundingShim} FundingShim
         */
        FundingShim.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FundingShim)
                return object;
            let message = new $root.lnrpc.FundingShim();
            if (object.chanPointShim != null) {
                if (typeof object.chanPointShim !== "object")
                    throw TypeError(".lnrpc.FundingShim.chanPointShim: object expected");
                message.chanPointShim = $root.lnrpc.ChanPointShim.fromObject(object.chanPointShim);
            }
            if (object.psbtShim != null) {
                if (typeof object.psbtShim !== "object")
                    throw TypeError(".lnrpc.FundingShim.psbtShim: object expected");
                message.psbtShim = $root.lnrpc.PsbtShim.fromObject(object.psbtShim);
            }
            return message;
        };

        /**
         * Creates a plain object from a FundingShim message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FundingShim
         * @static
         * @param {lnrpc.FundingShim} message FundingShim
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingShim.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.chanPointShim != null && message.hasOwnProperty("chanPointShim")) {
                object.chanPointShim = $root.lnrpc.ChanPointShim.toObject(message.chanPointShim, options);
                if (options.oneofs)
                    object.shim = "chanPointShim";
            }
            if (message.psbtShim != null && message.hasOwnProperty("psbtShim")) {
                object.psbtShim = $root.lnrpc.PsbtShim.toObject(message.psbtShim, options);
                if (options.oneofs)
                    object.shim = "psbtShim";
            }
            return object;
        };

        /**
         * Converts this FundingShim to JSON.
         * @function toJSON
         * @memberof lnrpc.FundingShim
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingShim.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingShim;
    })();

    lnrpc.FundingShimCancel = (function() {

        /**
         * Properties of a FundingShimCancel.
         * @memberof lnrpc
         * @interface IFundingShimCancel
         * @property {Uint8Array|null} [pendingChanId] FundingShimCancel pendingChanId
         */

        /**
         * Constructs a new FundingShimCancel.
         * @memberof lnrpc
         * @classdesc Represents a FundingShimCancel.
         * @implements IFundingShimCancel
         * @constructor
         * @param {lnrpc.IFundingShimCancel=} [properties] Properties to set
         */
        function FundingShimCancel(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundingShimCancel pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.FundingShimCancel
         * @instance
         */
        FundingShimCancel.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * Creates a new FundingShimCancel instance using the specified properties.
         * @function create
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {lnrpc.IFundingShimCancel=} [properties] Properties to set
         * @returns {lnrpc.FundingShimCancel} FundingShimCancel instance
         */
        FundingShimCancel.create = function create(properties) {
            return new FundingShimCancel(properties);
        };

        /**
         * Encodes the specified FundingShimCancel message. Does not implicitly {@link lnrpc.FundingShimCancel.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {lnrpc.IFundingShimCancel} message FundingShimCancel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingShimCancel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pendingChanId);
            return writer;
        };

        /**
         * Encodes the specified FundingShimCancel message, length delimited. Does not implicitly {@link lnrpc.FundingShimCancel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {lnrpc.IFundingShimCancel} message FundingShimCancel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingShimCancel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundingShimCancel message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FundingShimCancel} FundingShimCancel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingShimCancel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FundingShimCancel();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundingShimCancel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FundingShimCancel} FundingShimCancel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingShimCancel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundingShimCancel message.
         * @function verify
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundingShimCancel.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            return null;
        };

        /**
         * Creates a FundingShimCancel message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FundingShimCancel} FundingShimCancel
         */
        FundingShimCancel.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FundingShimCancel)
                return object;
            let message = new $root.lnrpc.FundingShimCancel();
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            return message;
        };

        /**
         * Creates a plain object from a FundingShimCancel message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FundingShimCancel
         * @static
         * @param {lnrpc.FundingShimCancel} message FundingShimCancel
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingShimCancel.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            return object;
        };

        /**
         * Converts this FundingShimCancel to JSON.
         * @function toJSON
         * @memberof lnrpc.FundingShimCancel
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingShimCancel.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingShimCancel;
    })();

    lnrpc.FundingPsbtVerify = (function() {

        /**
         * Properties of a FundingPsbtVerify.
         * @memberof lnrpc
         * @interface IFundingPsbtVerify
         * @property {Uint8Array|null} [fundedPsbt] FundingPsbtVerify fundedPsbt
         * @property {Uint8Array|null} [pendingChanId] FundingPsbtVerify pendingChanId
         * @property {boolean|null} [skipFinalize] FundingPsbtVerify skipFinalize
         */

        /**
         * Constructs a new FundingPsbtVerify.
         * @memberof lnrpc
         * @classdesc Represents a FundingPsbtVerify.
         * @implements IFundingPsbtVerify
         * @constructor
         * @param {lnrpc.IFundingPsbtVerify=} [properties] Properties to set
         */
        function FundingPsbtVerify(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundingPsbtVerify fundedPsbt.
         * @member {Uint8Array} fundedPsbt
         * @memberof lnrpc.FundingPsbtVerify
         * @instance
         */
        FundingPsbtVerify.prototype.fundedPsbt = $util.newBuffer([]);

        /**
         * FundingPsbtVerify pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.FundingPsbtVerify
         * @instance
         */
        FundingPsbtVerify.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * FundingPsbtVerify skipFinalize.
         * @member {boolean} skipFinalize
         * @memberof lnrpc.FundingPsbtVerify
         * @instance
         */
        FundingPsbtVerify.prototype.skipFinalize = false;

        /**
         * Creates a new FundingPsbtVerify instance using the specified properties.
         * @function create
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {lnrpc.IFundingPsbtVerify=} [properties] Properties to set
         * @returns {lnrpc.FundingPsbtVerify} FundingPsbtVerify instance
         */
        FundingPsbtVerify.create = function create(properties) {
            return new FundingPsbtVerify(properties);
        };

        /**
         * Encodes the specified FundingPsbtVerify message. Does not implicitly {@link lnrpc.FundingPsbtVerify.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {lnrpc.IFundingPsbtVerify} message FundingPsbtVerify message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingPsbtVerify.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fundedPsbt != null && Object.hasOwnProperty.call(message, "fundedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fundedPsbt);
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.pendingChanId);
            if (message.skipFinalize != null && Object.hasOwnProperty.call(message, "skipFinalize"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipFinalize);
            return writer;
        };

        /**
         * Encodes the specified FundingPsbtVerify message, length delimited. Does not implicitly {@link lnrpc.FundingPsbtVerify.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {lnrpc.IFundingPsbtVerify} message FundingPsbtVerify message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingPsbtVerify.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundingPsbtVerify message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FundingPsbtVerify} FundingPsbtVerify
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingPsbtVerify.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FundingPsbtVerify();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fundedPsbt = reader.bytes();
                        break;
                    }
                case 2: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.skipFinalize = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundingPsbtVerify message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FundingPsbtVerify} FundingPsbtVerify
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingPsbtVerify.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundingPsbtVerify message.
         * @function verify
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundingPsbtVerify.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                if (!(message.fundedPsbt && typeof message.fundedPsbt.length === "number" || $util.isString(message.fundedPsbt)))
                    return "fundedPsbt: buffer expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.skipFinalize != null && message.hasOwnProperty("skipFinalize"))
                if (typeof message.skipFinalize !== "boolean")
                    return "skipFinalize: boolean expected";
            return null;
        };

        /**
         * Creates a FundingPsbtVerify message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FundingPsbtVerify} FundingPsbtVerify
         */
        FundingPsbtVerify.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FundingPsbtVerify)
                return object;
            let message = new $root.lnrpc.FundingPsbtVerify();
            if (object.fundedPsbt != null)
                if (typeof object.fundedPsbt === "string")
                    $util.base64.decode(object.fundedPsbt, message.fundedPsbt = $util.newBuffer($util.base64.length(object.fundedPsbt)), 0);
                else if (object.fundedPsbt.length)
                    message.fundedPsbt = object.fundedPsbt;
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            if (object.skipFinalize != null)
                message.skipFinalize = Boolean(object.skipFinalize);
            return message;
        };

        /**
         * Creates a plain object from a FundingPsbtVerify message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FundingPsbtVerify
         * @static
         * @param {lnrpc.FundingPsbtVerify} message FundingPsbtVerify
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingPsbtVerify.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.fundedPsbt = "";
                else {
                    object.fundedPsbt = [];
                    if (options.bytes !== Array)
                        object.fundedPsbt = $util.newBuffer(object.fundedPsbt);
                }
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                object.skipFinalize = false;
            }
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                object.fundedPsbt = options.bytes === String ? $util.base64.encode(message.fundedPsbt, 0, message.fundedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.fundedPsbt) : message.fundedPsbt;
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.skipFinalize != null && message.hasOwnProperty("skipFinalize"))
                object.skipFinalize = message.skipFinalize;
            return object;
        };

        /**
         * Converts this FundingPsbtVerify to JSON.
         * @function toJSON
         * @memberof lnrpc.FundingPsbtVerify
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingPsbtVerify.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingPsbtVerify;
    })();

    lnrpc.FundingPsbtFinalize = (function() {

        /**
         * Properties of a FundingPsbtFinalize.
         * @memberof lnrpc
         * @interface IFundingPsbtFinalize
         * @property {Uint8Array|null} [signedPsbt] FundingPsbtFinalize signedPsbt
         * @property {Uint8Array|null} [pendingChanId] FundingPsbtFinalize pendingChanId
         * @property {Uint8Array|null} [finalRawTx] FundingPsbtFinalize finalRawTx
         */

        /**
         * Constructs a new FundingPsbtFinalize.
         * @memberof lnrpc
         * @classdesc Represents a FundingPsbtFinalize.
         * @implements IFundingPsbtFinalize
         * @constructor
         * @param {lnrpc.IFundingPsbtFinalize=} [properties] Properties to set
         */
        function FundingPsbtFinalize(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundingPsbtFinalize signedPsbt.
         * @member {Uint8Array} signedPsbt
         * @memberof lnrpc.FundingPsbtFinalize
         * @instance
         */
        FundingPsbtFinalize.prototype.signedPsbt = $util.newBuffer([]);

        /**
         * FundingPsbtFinalize pendingChanId.
         * @member {Uint8Array} pendingChanId
         * @memberof lnrpc.FundingPsbtFinalize
         * @instance
         */
        FundingPsbtFinalize.prototype.pendingChanId = $util.newBuffer([]);

        /**
         * FundingPsbtFinalize finalRawTx.
         * @member {Uint8Array} finalRawTx
         * @memberof lnrpc.FundingPsbtFinalize
         * @instance
         */
        FundingPsbtFinalize.prototype.finalRawTx = $util.newBuffer([]);

        /**
         * Creates a new FundingPsbtFinalize instance using the specified properties.
         * @function create
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {lnrpc.IFundingPsbtFinalize=} [properties] Properties to set
         * @returns {lnrpc.FundingPsbtFinalize} FundingPsbtFinalize instance
         */
        FundingPsbtFinalize.create = function create(properties) {
            return new FundingPsbtFinalize(properties);
        };

        /**
         * Encodes the specified FundingPsbtFinalize message. Does not implicitly {@link lnrpc.FundingPsbtFinalize.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {lnrpc.IFundingPsbtFinalize} message FundingPsbtFinalize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingPsbtFinalize.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signedPsbt != null && Object.hasOwnProperty.call(message, "signedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.signedPsbt);
            if (message.pendingChanId != null && Object.hasOwnProperty.call(message, "pendingChanId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.pendingChanId);
            if (message.finalRawTx != null && Object.hasOwnProperty.call(message, "finalRawTx"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.finalRawTx);
            return writer;
        };

        /**
         * Encodes the specified FundingPsbtFinalize message, length delimited. Does not implicitly {@link lnrpc.FundingPsbtFinalize.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {lnrpc.IFundingPsbtFinalize} message FundingPsbtFinalize message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingPsbtFinalize.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundingPsbtFinalize message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FundingPsbtFinalize} FundingPsbtFinalize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingPsbtFinalize.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FundingPsbtFinalize();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signedPsbt = reader.bytes();
                        break;
                    }
                case 2: {
                        message.pendingChanId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.finalRawTx = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundingPsbtFinalize message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FundingPsbtFinalize} FundingPsbtFinalize
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingPsbtFinalize.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundingPsbtFinalize message.
         * @function verify
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundingPsbtFinalize.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signedPsbt != null && message.hasOwnProperty("signedPsbt"))
                if (!(message.signedPsbt && typeof message.signedPsbt.length === "number" || $util.isString(message.signedPsbt)))
                    return "signedPsbt: buffer expected";
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                if (!(message.pendingChanId && typeof message.pendingChanId.length === "number" || $util.isString(message.pendingChanId)))
                    return "pendingChanId: buffer expected";
            if (message.finalRawTx != null && message.hasOwnProperty("finalRawTx"))
                if (!(message.finalRawTx && typeof message.finalRawTx.length === "number" || $util.isString(message.finalRawTx)))
                    return "finalRawTx: buffer expected";
            return null;
        };

        /**
         * Creates a FundingPsbtFinalize message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FundingPsbtFinalize} FundingPsbtFinalize
         */
        FundingPsbtFinalize.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FundingPsbtFinalize)
                return object;
            let message = new $root.lnrpc.FundingPsbtFinalize();
            if (object.signedPsbt != null)
                if (typeof object.signedPsbt === "string")
                    $util.base64.decode(object.signedPsbt, message.signedPsbt = $util.newBuffer($util.base64.length(object.signedPsbt)), 0);
                else if (object.signedPsbt.length)
                    message.signedPsbt = object.signedPsbt;
            if (object.pendingChanId != null)
                if (typeof object.pendingChanId === "string")
                    $util.base64.decode(object.pendingChanId, message.pendingChanId = $util.newBuffer($util.base64.length(object.pendingChanId)), 0);
                else if (object.pendingChanId.length)
                    message.pendingChanId = object.pendingChanId;
            if (object.finalRawTx != null)
                if (typeof object.finalRawTx === "string")
                    $util.base64.decode(object.finalRawTx, message.finalRawTx = $util.newBuffer($util.base64.length(object.finalRawTx)), 0);
                else if (object.finalRawTx.length)
                    message.finalRawTx = object.finalRawTx;
            return message;
        };

        /**
         * Creates a plain object from a FundingPsbtFinalize message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FundingPsbtFinalize
         * @static
         * @param {lnrpc.FundingPsbtFinalize} message FundingPsbtFinalize
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingPsbtFinalize.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.signedPsbt = "";
                else {
                    object.signedPsbt = [];
                    if (options.bytes !== Array)
                        object.signedPsbt = $util.newBuffer(object.signedPsbt);
                }
                if (options.bytes === String)
                    object.pendingChanId = "";
                else {
                    object.pendingChanId = [];
                    if (options.bytes !== Array)
                        object.pendingChanId = $util.newBuffer(object.pendingChanId);
                }
                if (options.bytes === String)
                    object.finalRawTx = "";
                else {
                    object.finalRawTx = [];
                    if (options.bytes !== Array)
                        object.finalRawTx = $util.newBuffer(object.finalRawTx);
                }
            }
            if (message.signedPsbt != null && message.hasOwnProperty("signedPsbt"))
                object.signedPsbt = options.bytes === String ? $util.base64.encode(message.signedPsbt, 0, message.signedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.signedPsbt) : message.signedPsbt;
            if (message.pendingChanId != null && message.hasOwnProperty("pendingChanId"))
                object.pendingChanId = options.bytes === String ? $util.base64.encode(message.pendingChanId, 0, message.pendingChanId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pendingChanId) : message.pendingChanId;
            if (message.finalRawTx != null && message.hasOwnProperty("finalRawTx"))
                object.finalRawTx = options.bytes === String ? $util.base64.encode(message.finalRawTx, 0, message.finalRawTx.length) : options.bytes === Array ? Array.prototype.slice.call(message.finalRawTx) : message.finalRawTx;
            return object;
        };

        /**
         * Converts this FundingPsbtFinalize to JSON.
         * @function toJSON
         * @memberof lnrpc.FundingPsbtFinalize
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingPsbtFinalize.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingPsbtFinalize;
    })();

    lnrpc.FundingTransitionMsg = (function() {

        /**
         * Properties of a FundingTransitionMsg.
         * @memberof lnrpc
         * @interface IFundingTransitionMsg
         * @property {lnrpc.IFundingShim|null} [shimRegister] FundingTransitionMsg shimRegister
         * @property {lnrpc.IFundingShimCancel|null} [shimCancel] FundingTransitionMsg shimCancel
         * @property {lnrpc.IFundingPsbtVerify|null} [psbtVerify] FundingTransitionMsg psbtVerify
         * @property {lnrpc.IFundingPsbtFinalize|null} [psbtFinalize] FundingTransitionMsg psbtFinalize
         */

        /**
         * Constructs a new FundingTransitionMsg.
         * @memberof lnrpc
         * @classdesc Represents a FundingTransitionMsg.
         * @implements IFundingTransitionMsg
         * @constructor
         * @param {lnrpc.IFundingTransitionMsg=} [properties] Properties to set
         */
        function FundingTransitionMsg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundingTransitionMsg shimRegister.
         * @member {lnrpc.IFundingShim|null|undefined} shimRegister
         * @memberof lnrpc.FundingTransitionMsg
         * @instance
         */
        FundingTransitionMsg.prototype.shimRegister = null;

        /**
         * FundingTransitionMsg shimCancel.
         * @member {lnrpc.IFundingShimCancel|null|undefined} shimCancel
         * @memberof lnrpc.FundingTransitionMsg
         * @instance
         */
        FundingTransitionMsg.prototype.shimCancel = null;

        /**
         * FundingTransitionMsg psbtVerify.
         * @member {lnrpc.IFundingPsbtVerify|null|undefined} psbtVerify
         * @memberof lnrpc.FundingTransitionMsg
         * @instance
         */
        FundingTransitionMsg.prototype.psbtVerify = null;

        /**
         * FundingTransitionMsg psbtFinalize.
         * @member {lnrpc.IFundingPsbtFinalize|null|undefined} psbtFinalize
         * @memberof lnrpc.FundingTransitionMsg
         * @instance
         */
        FundingTransitionMsg.prototype.psbtFinalize = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * FundingTransitionMsg trigger.
         * @member {"shimRegister"|"shimCancel"|"psbtVerify"|"psbtFinalize"|undefined} trigger
         * @memberof lnrpc.FundingTransitionMsg
         * @instance
         */
        Object.defineProperty(FundingTransitionMsg.prototype, "trigger", {
            get: $util.oneOfGetter($oneOfFields = ["shimRegister", "shimCancel", "psbtVerify", "psbtFinalize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FundingTransitionMsg instance using the specified properties.
         * @function create
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {lnrpc.IFundingTransitionMsg=} [properties] Properties to set
         * @returns {lnrpc.FundingTransitionMsg} FundingTransitionMsg instance
         */
        FundingTransitionMsg.create = function create(properties) {
            return new FundingTransitionMsg(properties);
        };

        /**
         * Encodes the specified FundingTransitionMsg message. Does not implicitly {@link lnrpc.FundingTransitionMsg.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {lnrpc.IFundingTransitionMsg} message FundingTransitionMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingTransitionMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.shimRegister != null && Object.hasOwnProperty.call(message, "shimRegister"))
                $root.lnrpc.FundingShim.encode(message.shimRegister, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.shimCancel != null && Object.hasOwnProperty.call(message, "shimCancel"))
                $root.lnrpc.FundingShimCancel.encode(message.shimCancel, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.psbtVerify != null && Object.hasOwnProperty.call(message, "psbtVerify"))
                $root.lnrpc.FundingPsbtVerify.encode(message.psbtVerify, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.psbtFinalize != null && Object.hasOwnProperty.call(message, "psbtFinalize"))
                $root.lnrpc.FundingPsbtFinalize.encode(message.psbtFinalize, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FundingTransitionMsg message, length delimited. Does not implicitly {@link lnrpc.FundingTransitionMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {lnrpc.IFundingTransitionMsg} message FundingTransitionMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingTransitionMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundingTransitionMsg message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FundingTransitionMsg} FundingTransitionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingTransitionMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FundingTransitionMsg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.shimRegister = $root.lnrpc.FundingShim.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.shimCancel = $root.lnrpc.FundingShimCancel.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.psbtVerify = $root.lnrpc.FundingPsbtVerify.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.psbtFinalize = $root.lnrpc.FundingPsbtFinalize.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundingTransitionMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FundingTransitionMsg} FundingTransitionMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingTransitionMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundingTransitionMsg message.
         * @function verify
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundingTransitionMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.shimRegister != null && message.hasOwnProperty("shimRegister")) {
                properties.trigger = 1;
                {
                    let error = $root.lnrpc.FundingShim.verify(message.shimRegister);
                    if (error)
                        return "shimRegister." + error;
                }
            }
            if (message.shimCancel != null && message.hasOwnProperty("shimCancel")) {
                if (properties.trigger === 1)
                    return "trigger: multiple values";
                properties.trigger = 1;
                {
                    let error = $root.lnrpc.FundingShimCancel.verify(message.shimCancel);
                    if (error)
                        return "shimCancel." + error;
                }
            }
            if (message.psbtVerify != null && message.hasOwnProperty("psbtVerify")) {
                if (properties.trigger === 1)
                    return "trigger: multiple values";
                properties.trigger = 1;
                {
                    let error = $root.lnrpc.FundingPsbtVerify.verify(message.psbtVerify);
                    if (error)
                        return "psbtVerify." + error;
                }
            }
            if (message.psbtFinalize != null && message.hasOwnProperty("psbtFinalize")) {
                if (properties.trigger === 1)
                    return "trigger: multiple values";
                properties.trigger = 1;
                {
                    let error = $root.lnrpc.FundingPsbtFinalize.verify(message.psbtFinalize);
                    if (error)
                        return "psbtFinalize." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FundingTransitionMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FundingTransitionMsg} FundingTransitionMsg
         */
        FundingTransitionMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FundingTransitionMsg)
                return object;
            let message = new $root.lnrpc.FundingTransitionMsg();
            if (object.shimRegister != null) {
                if (typeof object.shimRegister !== "object")
                    throw TypeError(".lnrpc.FundingTransitionMsg.shimRegister: object expected");
                message.shimRegister = $root.lnrpc.FundingShim.fromObject(object.shimRegister);
            }
            if (object.shimCancel != null) {
                if (typeof object.shimCancel !== "object")
                    throw TypeError(".lnrpc.FundingTransitionMsg.shimCancel: object expected");
                message.shimCancel = $root.lnrpc.FundingShimCancel.fromObject(object.shimCancel);
            }
            if (object.psbtVerify != null) {
                if (typeof object.psbtVerify !== "object")
                    throw TypeError(".lnrpc.FundingTransitionMsg.psbtVerify: object expected");
                message.psbtVerify = $root.lnrpc.FundingPsbtVerify.fromObject(object.psbtVerify);
            }
            if (object.psbtFinalize != null) {
                if (typeof object.psbtFinalize !== "object")
                    throw TypeError(".lnrpc.FundingTransitionMsg.psbtFinalize: object expected");
                message.psbtFinalize = $root.lnrpc.FundingPsbtFinalize.fromObject(object.psbtFinalize);
            }
            return message;
        };

        /**
         * Creates a plain object from a FundingTransitionMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FundingTransitionMsg
         * @static
         * @param {lnrpc.FundingTransitionMsg} message FundingTransitionMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingTransitionMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.shimRegister != null && message.hasOwnProperty("shimRegister")) {
                object.shimRegister = $root.lnrpc.FundingShim.toObject(message.shimRegister, options);
                if (options.oneofs)
                    object.trigger = "shimRegister";
            }
            if (message.shimCancel != null && message.hasOwnProperty("shimCancel")) {
                object.shimCancel = $root.lnrpc.FundingShimCancel.toObject(message.shimCancel, options);
                if (options.oneofs)
                    object.trigger = "shimCancel";
            }
            if (message.psbtVerify != null && message.hasOwnProperty("psbtVerify")) {
                object.psbtVerify = $root.lnrpc.FundingPsbtVerify.toObject(message.psbtVerify, options);
                if (options.oneofs)
                    object.trigger = "psbtVerify";
            }
            if (message.psbtFinalize != null && message.hasOwnProperty("psbtFinalize")) {
                object.psbtFinalize = $root.lnrpc.FundingPsbtFinalize.toObject(message.psbtFinalize, options);
                if (options.oneofs)
                    object.trigger = "psbtFinalize";
            }
            return object;
        };

        /**
         * Converts this FundingTransitionMsg to JSON.
         * @function toJSON
         * @memberof lnrpc.FundingTransitionMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingTransitionMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingTransitionMsg;
    })();

    lnrpc.FundingStateStepResp = (function() {

        /**
         * Properties of a FundingStateStepResp.
         * @memberof lnrpc
         * @interface IFundingStateStepResp
         */

        /**
         * Constructs a new FundingStateStepResp.
         * @memberof lnrpc
         * @classdesc Represents a FundingStateStepResp.
         * @implements IFundingStateStepResp
         * @constructor
         * @param {lnrpc.IFundingStateStepResp=} [properties] Properties to set
         */
        function FundingStateStepResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FundingStateStepResp instance using the specified properties.
         * @function create
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {lnrpc.IFundingStateStepResp=} [properties] Properties to set
         * @returns {lnrpc.FundingStateStepResp} FundingStateStepResp instance
         */
        FundingStateStepResp.create = function create(properties) {
            return new FundingStateStepResp(properties);
        };

        /**
         * Encodes the specified FundingStateStepResp message. Does not implicitly {@link lnrpc.FundingStateStepResp.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {lnrpc.IFundingStateStepResp} message FundingStateStepResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingStateStepResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FundingStateStepResp message, length delimited. Does not implicitly {@link lnrpc.FundingStateStepResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {lnrpc.IFundingStateStepResp} message FundingStateStepResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundingStateStepResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundingStateStepResp message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FundingStateStepResp} FundingStateStepResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingStateStepResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FundingStateStepResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundingStateStepResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FundingStateStepResp} FundingStateStepResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundingStateStepResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundingStateStepResp message.
         * @function verify
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundingStateStepResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FundingStateStepResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FundingStateStepResp} FundingStateStepResp
         */
        FundingStateStepResp.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FundingStateStepResp)
                return object;
            return new $root.lnrpc.FundingStateStepResp();
        };

        /**
         * Creates a plain object from a FundingStateStepResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FundingStateStepResp
         * @static
         * @param {lnrpc.FundingStateStepResp} message FundingStateStepResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundingStateStepResp.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FundingStateStepResp to JSON.
         * @function toJSON
         * @memberof lnrpc.FundingStateStepResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundingStateStepResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundingStateStepResp;
    })();

    lnrpc.PendingHTLC = (function() {

        /**
         * Properties of a PendingHTLC.
         * @memberof lnrpc
         * @interface IPendingHTLC
         * @property {boolean|null} [incoming] PendingHTLC incoming
         * @property {Long|null} [amount] PendingHTLC amount
         * @property {string|null} [outpoint] PendingHTLC outpoint
         * @property {number|null} [maturityHeight] PendingHTLC maturityHeight
         * @property {number|null} [blocksTilMaturity] PendingHTLC blocksTilMaturity
         * @property {number|null} [stage] PendingHTLC stage
         */

        /**
         * Constructs a new PendingHTLC.
         * @memberof lnrpc
         * @classdesc Represents a PendingHTLC.
         * @implements IPendingHTLC
         * @constructor
         * @param {lnrpc.IPendingHTLC=} [properties] Properties to set
         */
        function PendingHTLC(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingHTLC incoming.
         * @member {boolean} incoming
         * @memberof lnrpc.PendingHTLC
         * @instance
         */
        PendingHTLC.prototype.incoming = false;

        /**
         * PendingHTLC amount.
         * @member {Long} amount
         * @memberof lnrpc.PendingHTLC
         * @instance
         */
        PendingHTLC.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PendingHTLC outpoint.
         * @member {string} outpoint
         * @memberof lnrpc.PendingHTLC
         * @instance
         */
        PendingHTLC.prototype.outpoint = "";

        /**
         * PendingHTLC maturityHeight.
         * @member {number} maturityHeight
         * @memberof lnrpc.PendingHTLC
         * @instance
         */
        PendingHTLC.prototype.maturityHeight = 0;

        /**
         * PendingHTLC blocksTilMaturity.
         * @member {number} blocksTilMaturity
         * @memberof lnrpc.PendingHTLC
         * @instance
         */
        PendingHTLC.prototype.blocksTilMaturity = 0;

        /**
         * PendingHTLC stage.
         * @member {number} stage
         * @memberof lnrpc.PendingHTLC
         * @instance
         */
        PendingHTLC.prototype.stage = 0;

        /**
         * Creates a new PendingHTLC instance using the specified properties.
         * @function create
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {lnrpc.IPendingHTLC=} [properties] Properties to set
         * @returns {lnrpc.PendingHTLC} PendingHTLC instance
         */
        PendingHTLC.create = function create(properties) {
            return new PendingHTLC(properties);
        };

        /**
         * Encodes the specified PendingHTLC message. Does not implicitly {@link lnrpc.PendingHTLC.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {lnrpc.IPendingHTLC} message PendingHTLC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingHTLC.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incoming != null && Object.hasOwnProperty.call(message, "incoming"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.incoming);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amount);
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.outpoint);
            if (message.maturityHeight != null && Object.hasOwnProperty.call(message, "maturityHeight"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maturityHeight);
            if (message.blocksTilMaturity != null && Object.hasOwnProperty.call(message, "blocksTilMaturity"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.blocksTilMaturity);
            if (message.stage != null && Object.hasOwnProperty.call(message, "stage"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.stage);
            return writer;
        };

        /**
         * Encodes the specified PendingHTLC message, length delimited. Does not implicitly {@link lnrpc.PendingHTLC.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {lnrpc.IPendingHTLC} message PendingHTLC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingHTLC.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingHTLC message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PendingHTLC} PendingHTLC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingHTLC.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingHTLC();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incoming = reader.bool();
                        break;
                    }
                case 2: {
                        message.amount = reader.int64();
                        break;
                    }
                case 3: {
                        message.outpoint = reader.string();
                        break;
                    }
                case 4: {
                        message.maturityHeight = reader.uint32();
                        break;
                    }
                case 5: {
                        message.blocksTilMaturity = reader.int32();
                        break;
                    }
                case 6: {
                        message.stage = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingHTLC message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PendingHTLC} PendingHTLC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingHTLC.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingHTLC message.
         * @function verify
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingHTLC.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.incoming != null && message.hasOwnProperty("incoming"))
                if (typeof message.incoming !== "boolean")
                    return "incoming: boolean expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                    return "amount: integer|Long expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                if (!$util.isString(message.outpoint))
                    return "outpoint: string expected";
            if (message.maturityHeight != null && message.hasOwnProperty("maturityHeight"))
                if (!$util.isInteger(message.maturityHeight))
                    return "maturityHeight: integer expected";
            if (message.blocksTilMaturity != null && message.hasOwnProperty("blocksTilMaturity"))
                if (!$util.isInteger(message.blocksTilMaturity))
                    return "blocksTilMaturity: integer expected";
            if (message.stage != null && message.hasOwnProperty("stage"))
                if (!$util.isInteger(message.stage))
                    return "stage: integer expected";
            return null;
        };

        /**
         * Creates a PendingHTLC message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PendingHTLC} PendingHTLC
         */
        PendingHTLC.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PendingHTLC)
                return object;
            let message = new $root.lnrpc.PendingHTLC();
            if (object.incoming != null)
                message.incoming = Boolean(object.incoming);
            if (object.amount != null)
                if ($util.Long)
                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                else if (typeof object.amount === "string")
                    message.amount = parseInt(object.amount, 10);
                else if (typeof object.amount === "number")
                    message.amount = object.amount;
                else if (typeof object.amount === "object")
                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
            if (object.outpoint != null)
                message.outpoint = String(object.outpoint);
            if (object.maturityHeight != null)
                message.maturityHeight = object.maturityHeight >>> 0;
            if (object.blocksTilMaturity != null)
                message.blocksTilMaturity = object.blocksTilMaturity | 0;
            if (object.stage != null)
                message.stage = object.stage >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a PendingHTLC message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PendingHTLC
         * @static
         * @param {lnrpc.PendingHTLC} message PendingHTLC
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingHTLC.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.incoming = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amount = options.longs === String ? "0" : 0;
                object.outpoint = "";
                object.maturityHeight = 0;
                object.blocksTilMaturity = 0;
                object.stage = 0;
            }
            if (message.incoming != null && message.hasOwnProperty("incoming"))
                object.incoming = message.incoming;
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount === "number")
                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                else
                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = message.outpoint;
            if (message.maturityHeight != null && message.hasOwnProperty("maturityHeight"))
                object.maturityHeight = message.maturityHeight;
            if (message.blocksTilMaturity != null && message.hasOwnProperty("blocksTilMaturity"))
                object.blocksTilMaturity = message.blocksTilMaturity;
            if (message.stage != null && message.hasOwnProperty("stage"))
                object.stage = message.stage;
            return object;
        };

        /**
         * Converts this PendingHTLC to JSON.
         * @function toJSON
         * @memberof lnrpc.PendingHTLC
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingHTLC.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingHTLC;
    })();

    lnrpc.PendingChannelsRequest = (function() {

        /**
         * Properties of a PendingChannelsRequest.
         * @memberof lnrpc
         * @interface IPendingChannelsRequest
         */

        /**
         * Constructs a new PendingChannelsRequest.
         * @memberof lnrpc
         * @classdesc Represents a PendingChannelsRequest.
         * @implements IPendingChannelsRequest
         * @constructor
         * @param {lnrpc.IPendingChannelsRequest=} [properties] Properties to set
         */
        function PendingChannelsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PendingChannelsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {lnrpc.IPendingChannelsRequest=} [properties] Properties to set
         * @returns {lnrpc.PendingChannelsRequest} PendingChannelsRequest instance
         */
        PendingChannelsRequest.create = function create(properties) {
            return new PendingChannelsRequest(properties);
        };

        /**
         * Encodes the specified PendingChannelsRequest message. Does not implicitly {@link lnrpc.PendingChannelsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {lnrpc.IPendingChannelsRequest} message PendingChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingChannelsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PendingChannelsRequest message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {lnrpc.IPendingChannelsRequest} message PendingChannelsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingChannelsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingChannelsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PendingChannelsRequest} PendingChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingChannelsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingChannelsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PendingChannelsRequest} PendingChannelsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingChannelsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingChannelsRequest message.
         * @function verify
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingChannelsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PendingChannelsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PendingChannelsRequest} PendingChannelsRequest
         */
        PendingChannelsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PendingChannelsRequest)
                return object;
            return new $root.lnrpc.PendingChannelsRequest();
        };

        /**
         * Creates a plain object from a PendingChannelsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PendingChannelsRequest
         * @static
         * @param {lnrpc.PendingChannelsRequest} message PendingChannelsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingChannelsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PendingChannelsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.PendingChannelsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingChannelsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingChannelsRequest;
    })();

    lnrpc.PendingChannelsResponse = (function() {

        /**
         * Properties of a PendingChannelsResponse.
         * @memberof lnrpc
         * @interface IPendingChannelsResponse
         * @property {Long|null} [totalLimboBalance] PendingChannelsResponse totalLimboBalance
         * @property {Array.<lnrpc.PendingChannelsResponse.IPendingOpenChannel>|null} [pendingOpenChannels] PendingChannelsResponse pendingOpenChannels
         * @property {Array.<lnrpc.PendingChannelsResponse.IClosedChannel>|null} [pendingClosingChannels] PendingChannelsResponse pendingClosingChannels
         * @property {Array.<lnrpc.PendingChannelsResponse.IForceClosedChannel>|null} [pendingForceClosingChannels] PendingChannelsResponse pendingForceClosingChannels
         * @property {Array.<lnrpc.PendingChannelsResponse.IWaitingCloseChannel>|null} [waitingCloseChannels] PendingChannelsResponse waitingCloseChannels
         */

        /**
         * Constructs a new PendingChannelsResponse.
         * @memberof lnrpc
         * @classdesc Represents a PendingChannelsResponse.
         * @implements IPendingChannelsResponse
         * @constructor
         * @param {lnrpc.IPendingChannelsResponse=} [properties] Properties to set
         */
        function PendingChannelsResponse(properties) {
            this.pendingOpenChannels = [];
            this.pendingClosingChannels = [];
            this.pendingForceClosingChannels = [];
            this.waitingCloseChannels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingChannelsResponse totalLimboBalance.
         * @member {Long} totalLimboBalance
         * @memberof lnrpc.PendingChannelsResponse
         * @instance
         */
        PendingChannelsResponse.prototype.totalLimboBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PendingChannelsResponse pendingOpenChannels.
         * @member {Array.<lnrpc.PendingChannelsResponse.IPendingOpenChannel>} pendingOpenChannels
         * @memberof lnrpc.PendingChannelsResponse
         * @instance
         */
        PendingChannelsResponse.prototype.pendingOpenChannels = $util.emptyArray;

        /**
         * PendingChannelsResponse pendingClosingChannels.
         * @member {Array.<lnrpc.PendingChannelsResponse.IClosedChannel>} pendingClosingChannels
         * @memberof lnrpc.PendingChannelsResponse
         * @instance
         */
        PendingChannelsResponse.prototype.pendingClosingChannels = $util.emptyArray;

        /**
         * PendingChannelsResponse pendingForceClosingChannels.
         * @member {Array.<lnrpc.PendingChannelsResponse.IForceClosedChannel>} pendingForceClosingChannels
         * @memberof lnrpc.PendingChannelsResponse
         * @instance
         */
        PendingChannelsResponse.prototype.pendingForceClosingChannels = $util.emptyArray;

        /**
         * PendingChannelsResponse waitingCloseChannels.
         * @member {Array.<lnrpc.PendingChannelsResponse.IWaitingCloseChannel>} waitingCloseChannels
         * @memberof lnrpc.PendingChannelsResponse
         * @instance
         */
        PendingChannelsResponse.prototype.waitingCloseChannels = $util.emptyArray;

        /**
         * Creates a new PendingChannelsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {lnrpc.IPendingChannelsResponse=} [properties] Properties to set
         * @returns {lnrpc.PendingChannelsResponse} PendingChannelsResponse instance
         */
        PendingChannelsResponse.create = function create(properties) {
            return new PendingChannelsResponse(properties);
        };

        /**
         * Encodes the specified PendingChannelsResponse message. Does not implicitly {@link lnrpc.PendingChannelsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {lnrpc.IPendingChannelsResponse} message PendingChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingChannelsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.totalLimboBalance != null && Object.hasOwnProperty.call(message, "totalLimboBalance"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalLimboBalance);
            if (message.pendingOpenChannels != null && message.pendingOpenChannels.length)
                for (let i = 0; i < message.pendingOpenChannels.length; ++i)
                    $root.lnrpc.PendingChannelsResponse.PendingOpenChannel.encode(message.pendingOpenChannels[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.pendingClosingChannels != null && message.pendingClosingChannels.length)
                for (let i = 0; i < message.pendingClosingChannels.length; ++i)
                    $root.lnrpc.PendingChannelsResponse.ClosedChannel.encode(message.pendingClosingChannels[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.pendingForceClosingChannels != null && message.pendingForceClosingChannels.length)
                for (let i = 0; i < message.pendingForceClosingChannels.length; ++i)
                    $root.lnrpc.PendingChannelsResponse.ForceClosedChannel.encode(message.pendingForceClosingChannels[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.waitingCloseChannels != null && message.waitingCloseChannels.length)
                for (let i = 0; i < message.waitingCloseChannels.length; ++i)
                    $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel.encode(message.waitingCloseChannels[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PendingChannelsResponse message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {lnrpc.IPendingChannelsResponse} message PendingChannelsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingChannelsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingChannelsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PendingChannelsResponse} PendingChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingChannelsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.totalLimboBalance = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.pendingOpenChannels && message.pendingOpenChannels.length))
                            message.pendingOpenChannels = [];
                        message.pendingOpenChannels.push($root.lnrpc.PendingChannelsResponse.PendingOpenChannel.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.pendingClosingChannels && message.pendingClosingChannels.length))
                            message.pendingClosingChannels = [];
                        message.pendingClosingChannels.push($root.lnrpc.PendingChannelsResponse.ClosedChannel.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        if (!(message.pendingForceClosingChannels && message.pendingForceClosingChannels.length))
                            message.pendingForceClosingChannels = [];
                        message.pendingForceClosingChannels.push($root.lnrpc.PendingChannelsResponse.ForceClosedChannel.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        if (!(message.waitingCloseChannels && message.waitingCloseChannels.length))
                            message.waitingCloseChannels = [];
                        message.waitingCloseChannels.push($root.lnrpc.PendingChannelsResponse.WaitingCloseChannel.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingChannelsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PendingChannelsResponse} PendingChannelsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingChannelsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingChannelsResponse message.
         * @function verify
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingChannelsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.totalLimboBalance != null && message.hasOwnProperty("totalLimboBalance"))
                if (!$util.isInteger(message.totalLimboBalance) && !(message.totalLimboBalance && $util.isInteger(message.totalLimboBalance.low) && $util.isInteger(message.totalLimboBalance.high)))
                    return "totalLimboBalance: integer|Long expected";
            if (message.pendingOpenChannels != null && message.hasOwnProperty("pendingOpenChannels")) {
                if (!Array.isArray(message.pendingOpenChannels))
                    return "pendingOpenChannels: array expected";
                for (let i = 0; i < message.pendingOpenChannels.length; ++i) {
                    let error = $root.lnrpc.PendingChannelsResponse.PendingOpenChannel.verify(message.pendingOpenChannels[i]);
                    if (error)
                        return "pendingOpenChannels." + error;
                }
            }
            if (message.pendingClosingChannels != null && message.hasOwnProperty("pendingClosingChannels")) {
                if (!Array.isArray(message.pendingClosingChannels))
                    return "pendingClosingChannels: array expected";
                for (let i = 0; i < message.pendingClosingChannels.length; ++i) {
                    let error = $root.lnrpc.PendingChannelsResponse.ClosedChannel.verify(message.pendingClosingChannels[i]);
                    if (error)
                        return "pendingClosingChannels." + error;
                }
            }
            if (message.pendingForceClosingChannels != null && message.hasOwnProperty("pendingForceClosingChannels")) {
                if (!Array.isArray(message.pendingForceClosingChannels))
                    return "pendingForceClosingChannels: array expected";
                for (let i = 0; i < message.pendingForceClosingChannels.length; ++i) {
                    let error = $root.lnrpc.PendingChannelsResponse.ForceClosedChannel.verify(message.pendingForceClosingChannels[i]);
                    if (error)
                        return "pendingForceClosingChannels." + error;
                }
            }
            if (message.waitingCloseChannels != null && message.hasOwnProperty("waitingCloseChannels")) {
                if (!Array.isArray(message.waitingCloseChannels))
                    return "waitingCloseChannels: array expected";
                for (let i = 0; i < message.waitingCloseChannels.length; ++i) {
                    let error = $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel.verify(message.waitingCloseChannels[i]);
                    if (error)
                        return "waitingCloseChannels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PendingChannelsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PendingChannelsResponse} PendingChannelsResponse
         */
        PendingChannelsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PendingChannelsResponse)
                return object;
            let message = new $root.lnrpc.PendingChannelsResponse();
            if (object.totalLimboBalance != null)
                if ($util.Long)
                    (message.totalLimboBalance = $util.Long.fromValue(object.totalLimboBalance)).unsigned = false;
                else if (typeof object.totalLimboBalance === "string")
                    message.totalLimboBalance = parseInt(object.totalLimboBalance, 10);
                else if (typeof object.totalLimboBalance === "number")
                    message.totalLimboBalance = object.totalLimboBalance;
                else if (typeof object.totalLimboBalance === "object")
                    message.totalLimboBalance = new $util.LongBits(object.totalLimboBalance.low >>> 0, object.totalLimboBalance.high >>> 0).toNumber();
            if (object.pendingOpenChannels) {
                if (!Array.isArray(object.pendingOpenChannels))
                    throw TypeError(".lnrpc.PendingChannelsResponse.pendingOpenChannels: array expected");
                message.pendingOpenChannels = [];
                for (let i = 0; i < object.pendingOpenChannels.length; ++i) {
                    if (typeof object.pendingOpenChannels[i] !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.pendingOpenChannels: object expected");
                    message.pendingOpenChannels[i] = $root.lnrpc.PendingChannelsResponse.PendingOpenChannel.fromObject(object.pendingOpenChannels[i]);
                }
            }
            if (object.pendingClosingChannels) {
                if (!Array.isArray(object.pendingClosingChannels))
                    throw TypeError(".lnrpc.PendingChannelsResponse.pendingClosingChannels: array expected");
                message.pendingClosingChannels = [];
                for (let i = 0; i < object.pendingClosingChannels.length; ++i) {
                    if (typeof object.pendingClosingChannels[i] !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.pendingClosingChannels: object expected");
                    message.pendingClosingChannels[i] = $root.lnrpc.PendingChannelsResponse.ClosedChannel.fromObject(object.pendingClosingChannels[i]);
                }
            }
            if (object.pendingForceClosingChannels) {
                if (!Array.isArray(object.pendingForceClosingChannels))
                    throw TypeError(".lnrpc.PendingChannelsResponse.pendingForceClosingChannels: array expected");
                message.pendingForceClosingChannels = [];
                for (let i = 0; i < object.pendingForceClosingChannels.length; ++i) {
                    if (typeof object.pendingForceClosingChannels[i] !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.pendingForceClosingChannels: object expected");
                    message.pendingForceClosingChannels[i] = $root.lnrpc.PendingChannelsResponse.ForceClosedChannel.fromObject(object.pendingForceClosingChannels[i]);
                }
            }
            if (object.waitingCloseChannels) {
                if (!Array.isArray(object.waitingCloseChannels))
                    throw TypeError(".lnrpc.PendingChannelsResponse.waitingCloseChannels: array expected");
                message.waitingCloseChannels = [];
                for (let i = 0; i < object.waitingCloseChannels.length; ++i) {
                    if (typeof object.waitingCloseChannels[i] !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.waitingCloseChannels: object expected");
                    message.waitingCloseChannels[i] = $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel.fromObject(object.waitingCloseChannels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PendingChannelsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PendingChannelsResponse
         * @static
         * @param {lnrpc.PendingChannelsResponse} message PendingChannelsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingChannelsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.pendingOpenChannels = [];
                object.pendingClosingChannels = [];
                object.pendingForceClosingChannels = [];
                object.waitingCloseChannels = [];
            }
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalLimboBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalLimboBalance = options.longs === String ? "0" : 0;
            if (message.totalLimboBalance != null && message.hasOwnProperty("totalLimboBalance"))
                if (typeof message.totalLimboBalance === "number")
                    object.totalLimboBalance = options.longs === String ? String(message.totalLimboBalance) : message.totalLimboBalance;
                else
                    object.totalLimboBalance = options.longs === String ? $util.Long.prototype.toString.call(message.totalLimboBalance) : options.longs === Number ? new $util.LongBits(message.totalLimboBalance.low >>> 0, message.totalLimboBalance.high >>> 0).toNumber() : message.totalLimboBalance;
            if (message.pendingOpenChannels && message.pendingOpenChannels.length) {
                object.pendingOpenChannels = [];
                for (let j = 0; j < message.pendingOpenChannels.length; ++j)
                    object.pendingOpenChannels[j] = $root.lnrpc.PendingChannelsResponse.PendingOpenChannel.toObject(message.pendingOpenChannels[j], options);
            }
            if (message.pendingClosingChannels && message.pendingClosingChannels.length) {
                object.pendingClosingChannels = [];
                for (let j = 0; j < message.pendingClosingChannels.length; ++j)
                    object.pendingClosingChannels[j] = $root.lnrpc.PendingChannelsResponse.ClosedChannel.toObject(message.pendingClosingChannels[j], options);
            }
            if (message.pendingForceClosingChannels && message.pendingForceClosingChannels.length) {
                object.pendingForceClosingChannels = [];
                for (let j = 0; j < message.pendingForceClosingChannels.length; ++j)
                    object.pendingForceClosingChannels[j] = $root.lnrpc.PendingChannelsResponse.ForceClosedChannel.toObject(message.pendingForceClosingChannels[j], options);
            }
            if (message.waitingCloseChannels && message.waitingCloseChannels.length) {
                object.waitingCloseChannels = [];
                for (let j = 0; j < message.waitingCloseChannels.length; ++j)
                    object.waitingCloseChannels[j] = $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel.toObject(message.waitingCloseChannels[j], options);
            }
            return object;
        };

        /**
         * Converts this PendingChannelsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.PendingChannelsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingChannelsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PendingChannelsResponse.PendingChannel = (function() {

            /**
             * Properties of a PendingChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @interface IPendingChannel
             * @property {string|null} [remoteNodePub] PendingChannel remoteNodePub
             * @property {string|null} [channelPoint] PendingChannel channelPoint
             * @property {Long|null} [capacity] PendingChannel capacity
             * @property {Long|null} [localBalance] PendingChannel localBalance
             * @property {Long|null} [remoteBalance] PendingChannel remoteBalance
             * @property {Long|null} [localChanReserveSat] PendingChannel localChanReserveSat
             * @property {Long|null} [remoteChanReserveSat] PendingChannel remoteChanReserveSat
             * @property {lnrpc.Initiator|null} [initiator] PendingChannel initiator
             * @property {lnrpc.CommitmentType|null} [commitmentType] PendingChannel commitmentType
             * @property {Long|null} [numForwardingPackages] PendingChannel numForwardingPackages
             * @property {string|null} [chanStatusFlags] PendingChannel chanStatusFlags
             * @property {boolean|null} ["private"] PendingChannel private
             */

            /**
             * Constructs a new PendingChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @classdesc Represents a PendingChannel.
             * @implements IPendingChannel
             * @constructor
             * @param {lnrpc.PendingChannelsResponse.IPendingChannel=} [properties] Properties to set
             */
            function PendingChannel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PendingChannel remoteNodePub.
             * @member {string} remoteNodePub
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.remoteNodePub = "";

            /**
             * PendingChannel channelPoint.
             * @member {string} channelPoint
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.channelPoint = "";

            /**
             * PendingChannel capacity.
             * @member {Long} capacity
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingChannel localBalance.
             * @member {Long} localBalance
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.localBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingChannel remoteBalance.
             * @member {Long} remoteBalance
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.remoteBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingChannel localChanReserveSat.
             * @member {Long} localChanReserveSat
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.localChanReserveSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingChannel remoteChanReserveSat.
             * @member {Long} remoteChanReserveSat
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.remoteChanReserveSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingChannel initiator.
             * @member {lnrpc.Initiator} initiator
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.initiator = 0;

            /**
             * PendingChannel commitmentType.
             * @member {lnrpc.CommitmentType} commitmentType
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.commitmentType = 0;

            /**
             * PendingChannel numForwardingPackages.
             * @member {Long} numForwardingPackages
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.numForwardingPackages = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingChannel chanStatusFlags.
             * @member {string} chanStatusFlags
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype.chanStatusFlags = "";

            /**
             * PendingChannel private.
             * @member {boolean} private
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             */
            PendingChannel.prototype["private"] = false;

            /**
             * Creates a new PendingChannel instance using the specified properties.
             * @function create
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IPendingChannel=} [properties] Properties to set
             * @returns {lnrpc.PendingChannelsResponse.PendingChannel} PendingChannel instance
             */
            PendingChannel.create = function create(properties) {
                return new PendingChannel(properties);
            };

            /**
             * Encodes the specified PendingChannel message. Does not implicitly {@link lnrpc.PendingChannelsResponse.PendingChannel.verify|verify} messages.
             * @function encode
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IPendingChannel} message PendingChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PendingChannel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.remoteNodePub != null && Object.hasOwnProperty.call(message, "remoteNodePub"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.remoteNodePub);
                if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.channelPoint);
                if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.capacity);
                if (message.localBalance != null && Object.hasOwnProperty.call(message, "localBalance"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.localBalance);
                if (message.remoteBalance != null && Object.hasOwnProperty.call(message, "remoteBalance"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.remoteBalance);
                if (message.localChanReserveSat != null && Object.hasOwnProperty.call(message, "localChanReserveSat"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.localChanReserveSat);
                if (message.remoteChanReserveSat != null && Object.hasOwnProperty.call(message, "remoteChanReserveSat"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.remoteChanReserveSat);
                if (message.initiator != null && Object.hasOwnProperty.call(message, "initiator"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.initiator);
                if (message.commitmentType != null && Object.hasOwnProperty.call(message, "commitmentType"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.commitmentType);
                if (message.numForwardingPackages != null && Object.hasOwnProperty.call(message, "numForwardingPackages"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.numForwardingPackages);
                if (message.chanStatusFlags != null && Object.hasOwnProperty.call(message, "chanStatusFlags"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.chanStatusFlags);
                if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                    writer.uint32(/* id 12, wireType 0 =*/96).bool(message["private"]);
                return writer;
            };

            /**
             * Encodes the specified PendingChannel message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.PendingChannel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IPendingChannel} message PendingChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PendingChannel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PendingChannel message from the specified reader or buffer.
             * @function decode
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {lnrpc.PendingChannelsResponse.PendingChannel} PendingChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PendingChannel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse.PendingChannel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.remoteNodePub = reader.string();
                            break;
                        }
                    case 2: {
                            message.channelPoint = reader.string();
                            break;
                        }
                    case 3: {
                            message.capacity = reader.int64();
                            break;
                        }
                    case 4: {
                            message.localBalance = reader.int64();
                            break;
                        }
                    case 5: {
                            message.remoteBalance = reader.int64();
                            break;
                        }
                    case 6: {
                            message.localChanReserveSat = reader.int64();
                            break;
                        }
                    case 7: {
                            message.remoteChanReserveSat = reader.int64();
                            break;
                        }
                    case 8: {
                            message.initiator = reader.int32();
                            break;
                        }
                    case 9: {
                            message.commitmentType = reader.int32();
                            break;
                        }
                    case 10: {
                            message.numForwardingPackages = reader.int64();
                            break;
                        }
                    case 11: {
                            message.chanStatusFlags = reader.string();
                            break;
                        }
                    case 12: {
                            message["private"] = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PendingChannel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {lnrpc.PendingChannelsResponse.PendingChannel} PendingChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PendingChannel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PendingChannel message.
             * @function verify
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PendingChannel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.remoteNodePub != null && message.hasOwnProperty("remoteNodePub"))
                    if (!$util.isString(message.remoteNodePub))
                        return "remoteNodePub: string expected";
                if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                    if (!$util.isString(message.channelPoint))
                        return "channelPoint: string expected";
                if (message.capacity != null && message.hasOwnProperty("capacity"))
                    if (!$util.isInteger(message.capacity) && !(message.capacity && $util.isInteger(message.capacity.low) && $util.isInteger(message.capacity.high)))
                        return "capacity: integer|Long expected";
                if (message.localBalance != null && message.hasOwnProperty("localBalance"))
                    if (!$util.isInteger(message.localBalance) && !(message.localBalance && $util.isInteger(message.localBalance.low) && $util.isInteger(message.localBalance.high)))
                        return "localBalance: integer|Long expected";
                if (message.remoteBalance != null && message.hasOwnProperty("remoteBalance"))
                    if (!$util.isInteger(message.remoteBalance) && !(message.remoteBalance && $util.isInteger(message.remoteBalance.low) && $util.isInteger(message.remoteBalance.high)))
                        return "remoteBalance: integer|Long expected";
                if (message.localChanReserveSat != null && message.hasOwnProperty("localChanReserveSat"))
                    if (!$util.isInteger(message.localChanReserveSat) && !(message.localChanReserveSat && $util.isInteger(message.localChanReserveSat.low) && $util.isInteger(message.localChanReserveSat.high)))
                        return "localChanReserveSat: integer|Long expected";
                if (message.remoteChanReserveSat != null && message.hasOwnProperty("remoteChanReserveSat"))
                    if (!$util.isInteger(message.remoteChanReserveSat) && !(message.remoteChanReserveSat && $util.isInteger(message.remoteChanReserveSat.low) && $util.isInteger(message.remoteChanReserveSat.high)))
                        return "remoteChanReserveSat: integer|Long expected";
                if (message.initiator != null && message.hasOwnProperty("initiator"))
                    switch (message.initiator) {
                    default:
                        return "initiator: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                    switch (message.commitmentType) {
                    default:
                        return "commitmentType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.numForwardingPackages != null && message.hasOwnProperty("numForwardingPackages"))
                    if (!$util.isInteger(message.numForwardingPackages) && !(message.numForwardingPackages && $util.isInteger(message.numForwardingPackages.low) && $util.isInteger(message.numForwardingPackages.high)))
                        return "numForwardingPackages: integer|Long expected";
                if (message.chanStatusFlags != null && message.hasOwnProperty("chanStatusFlags"))
                    if (!$util.isString(message.chanStatusFlags))
                        return "chanStatusFlags: string expected";
                if (message["private"] != null && message.hasOwnProperty("private"))
                    if (typeof message["private"] !== "boolean")
                        return "private: boolean expected";
                return null;
            };

            /**
             * Creates a PendingChannel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {lnrpc.PendingChannelsResponse.PendingChannel} PendingChannel
             */
            PendingChannel.fromObject = function fromObject(object) {
                if (object instanceof $root.lnrpc.PendingChannelsResponse.PendingChannel)
                    return object;
                let message = new $root.lnrpc.PendingChannelsResponse.PendingChannel();
                if (object.remoteNodePub != null)
                    message.remoteNodePub = String(object.remoteNodePub);
                if (object.channelPoint != null)
                    message.channelPoint = String(object.channelPoint);
                if (object.capacity != null)
                    if ($util.Long)
                        (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false;
                    else if (typeof object.capacity === "string")
                        message.capacity = parseInt(object.capacity, 10);
                    else if (typeof object.capacity === "number")
                        message.capacity = object.capacity;
                    else if (typeof object.capacity === "object")
                        message.capacity = new $util.LongBits(object.capacity.low >>> 0, object.capacity.high >>> 0).toNumber();
                if (object.localBalance != null)
                    if ($util.Long)
                        (message.localBalance = $util.Long.fromValue(object.localBalance)).unsigned = false;
                    else if (typeof object.localBalance === "string")
                        message.localBalance = parseInt(object.localBalance, 10);
                    else if (typeof object.localBalance === "number")
                        message.localBalance = object.localBalance;
                    else if (typeof object.localBalance === "object")
                        message.localBalance = new $util.LongBits(object.localBalance.low >>> 0, object.localBalance.high >>> 0).toNumber();
                if (object.remoteBalance != null)
                    if ($util.Long)
                        (message.remoteBalance = $util.Long.fromValue(object.remoteBalance)).unsigned = false;
                    else if (typeof object.remoteBalance === "string")
                        message.remoteBalance = parseInt(object.remoteBalance, 10);
                    else if (typeof object.remoteBalance === "number")
                        message.remoteBalance = object.remoteBalance;
                    else if (typeof object.remoteBalance === "object")
                        message.remoteBalance = new $util.LongBits(object.remoteBalance.low >>> 0, object.remoteBalance.high >>> 0).toNumber();
                if (object.localChanReserveSat != null)
                    if ($util.Long)
                        (message.localChanReserveSat = $util.Long.fromValue(object.localChanReserveSat)).unsigned = false;
                    else if (typeof object.localChanReserveSat === "string")
                        message.localChanReserveSat = parseInt(object.localChanReserveSat, 10);
                    else if (typeof object.localChanReserveSat === "number")
                        message.localChanReserveSat = object.localChanReserveSat;
                    else if (typeof object.localChanReserveSat === "object")
                        message.localChanReserveSat = new $util.LongBits(object.localChanReserveSat.low >>> 0, object.localChanReserveSat.high >>> 0).toNumber();
                if (object.remoteChanReserveSat != null)
                    if ($util.Long)
                        (message.remoteChanReserveSat = $util.Long.fromValue(object.remoteChanReserveSat)).unsigned = false;
                    else if (typeof object.remoteChanReserveSat === "string")
                        message.remoteChanReserveSat = parseInt(object.remoteChanReserveSat, 10);
                    else if (typeof object.remoteChanReserveSat === "number")
                        message.remoteChanReserveSat = object.remoteChanReserveSat;
                    else if (typeof object.remoteChanReserveSat === "object")
                        message.remoteChanReserveSat = new $util.LongBits(object.remoteChanReserveSat.low >>> 0, object.remoteChanReserveSat.high >>> 0).toNumber();
                switch (object.initiator) {
                case "INITIATOR_UNKNOWN":
                case 0:
                    message.initiator = 0;
                    break;
                case "INITIATOR_LOCAL":
                case 1:
                    message.initiator = 1;
                    break;
                case "INITIATOR_REMOTE":
                case 2:
                    message.initiator = 2;
                    break;
                case "INITIATOR_BOTH":
                case 3:
                    message.initiator = 3;
                    break;
                }
                switch (object.commitmentType) {
                case "UNKNOWN_COMMITMENT_TYPE":
                case 0:
                    message.commitmentType = 0;
                    break;
                case "LEGACY":
                case 1:
                    message.commitmentType = 1;
                    break;
                case "STATIC_REMOTE_KEY":
                case 2:
                    message.commitmentType = 2;
                    break;
                case "ANCHORS":
                case 3:
                    message.commitmentType = 3;
                    break;
                case "SCRIPT_ENFORCED_LEASE":
                case 4:
                    message.commitmentType = 4;
                    break;
                }
                if (object.numForwardingPackages != null)
                    if ($util.Long)
                        (message.numForwardingPackages = $util.Long.fromValue(object.numForwardingPackages)).unsigned = false;
                    else if (typeof object.numForwardingPackages === "string")
                        message.numForwardingPackages = parseInt(object.numForwardingPackages, 10);
                    else if (typeof object.numForwardingPackages === "number")
                        message.numForwardingPackages = object.numForwardingPackages;
                    else if (typeof object.numForwardingPackages === "object")
                        message.numForwardingPackages = new $util.LongBits(object.numForwardingPackages.low >>> 0, object.numForwardingPackages.high >>> 0).toNumber();
                if (object.chanStatusFlags != null)
                    message.chanStatusFlags = String(object.chanStatusFlags);
                if (object["private"] != null)
                    message["private"] = Boolean(object["private"]);
                return message;
            };

            /**
             * Creates a plain object from a PendingChannel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.PendingChannel} message PendingChannel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PendingChannel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.remoteNodePub = "";
                    object.channelPoint = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.capacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.capacity = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.localBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.localBalance = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.remoteBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.remoteBalance = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.localChanReserveSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.localChanReserveSat = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.remoteChanReserveSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.remoteChanReserveSat = options.longs === String ? "0" : 0;
                    object.initiator = options.enums === String ? "INITIATOR_UNKNOWN" : 0;
                    object.commitmentType = options.enums === String ? "UNKNOWN_COMMITMENT_TYPE" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.numForwardingPackages = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.numForwardingPackages = options.longs === String ? "0" : 0;
                    object.chanStatusFlags = "";
                    object["private"] = false;
                }
                if (message.remoteNodePub != null && message.hasOwnProperty("remoteNodePub"))
                    object.remoteNodePub = message.remoteNodePub;
                if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                    object.channelPoint = message.channelPoint;
                if (message.capacity != null && message.hasOwnProperty("capacity"))
                    if (typeof message.capacity === "number")
                        object.capacity = options.longs === String ? String(message.capacity) : message.capacity;
                    else
                        object.capacity = options.longs === String ? $util.Long.prototype.toString.call(message.capacity) : options.longs === Number ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber() : message.capacity;
                if (message.localBalance != null && message.hasOwnProperty("localBalance"))
                    if (typeof message.localBalance === "number")
                        object.localBalance = options.longs === String ? String(message.localBalance) : message.localBalance;
                    else
                        object.localBalance = options.longs === String ? $util.Long.prototype.toString.call(message.localBalance) : options.longs === Number ? new $util.LongBits(message.localBalance.low >>> 0, message.localBalance.high >>> 0).toNumber() : message.localBalance;
                if (message.remoteBalance != null && message.hasOwnProperty("remoteBalance"))
                    if (typeof message.remoteBalance === "number")
                        object.remoteBalance = options.longs === String ? String(message.remoteBalance) : message.remoteBalance;
                    else
                        object.remoteBalance = options.longs === String ? $util.Long.prototype.toString.call(message.remoteBalance) : options.longs === Number ? new $util.LongBits(message.remoteBalance.low >>> 0, message.remoteBalance.high >>> 0).toNumber() : message.remoteBalance;
                if (message.localChanReserveSat != null && message.hasOwnProperty("localChanReserveSat"))
                    if (typeof message.localChanReserveSat === "number")
                        object.localChanReserveSat = options.longs === String ? String(message.localChanReserveSat) : message.localChanReserveSat;
                    else
                        object.localChanReserveSat = options.longs === String ? $util.Long.prototype.toString.call(message.localChanReserveSat) : options.longs === Number ? new $util.LongBits(message.localChanReserveSat.low >>> 0, message.localChanReserveSat.high >>> 0).toNumber() : message.localChanReserveSat;
                if (message.remoteChanReserveSat != null && message.hasOwnProperty("remoteChanReserveSat"))
                    if (typeof message.remoteChanReserveSat === "number")
                        object.remoteChanReserveSat = options.longs === String ? String(message.remoteChanReserveSat) : message.remoteChanReserveSat;
                    else
                        object.remoteChanReserveSat = options.longs === String ? $util.Long.prototype.toString.call(message.remoteChanReserveSat) : options.longs === Number ? new $util.LongBits(message.remoteChanReserveSat.low >>> 0, message.remoteChanReserveSat.high >>> 0).toNumber() : message.remoteChanReserveSat;
                if (message.initiator != null && message.hasOwnProperty("initiator"))
                    object.initiator = options.enums === String ? $root.lnrpc.Initiator[message.initiator] : message.initiator;
                if (message.commitmentType != null && message.hasOwnProperty("commitmentType"))
                    object.commitmentType = options.enums === String ? $root.lnrpc.CommitmentType[message.commitmentType] : message.commitmentType;
                if (message.numForwardingPackages != null && message.hasOwnProperty("numForwardingPackages"))
                    if (typeof message.numForwardingPackages === "number")
                        object.numForwardingPackages = options.longs === String ? String(message.numForwardingPackages) : message.numForwardingPackages;
                    else
                        object.numForwardingPackages = options.longs === String ? $util.Long.prototype.toString.call(message.numForwardingPackages) : options.longs === Number ? new $util.LongBits(message.numForwardingPackages.low >>> 0, message.numForwardingPackages.high >>> 0).toNumber() : message.numForwardingPackages;
                if (message.chanStatusFlags != null && message.hasOwnProperty("chanStatusFlags"))
                    object.chanStatusFlags = message.chanStatusFlags;
                if (message["private"] != null && message.hasOwnProperty("private"))
                    object["private"] = message["private"];
                return object;
            };

            /**
             * Converts this PendingChannel to JSON.
             * @function toJSON
             * @memberof lnrpc.PendingChannelsResponse.PendingChannel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PendingChannel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PendingChannel;
        })();

        PendingChannelsResponse.PendingOpenChannel = (function() {

            /**
             * Properties of a PendingOpenChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @interface IPendingOpenChannel
             * @property {lnrpc.PendingChannelsResponse.IPendingChannel|null} [channel] PendingOpenChannel channel
             * @property {Long|null} [commitFee] PendingOpenChannel commitFee
             * @property {Long|null} [commitWeight] PendingOpenChannel commitWeight
             * @property {Long|null} [feePerKw] PendingOpenChannel feePerKw
             */

            /**
             * Constructs a new PendingOpenChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @classdesc Represents a PendingOpenChannel.
             * @implements IPendingOpenChannel
             * @constructor
             * @param {lnrpc.PendingChannelsResponse.IPendingOpenChannel=} [properties] Properties to set
             */
            function PendingOpenChannel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PendingOpenChannel channel.
             * @member {lnrpc.PendingChannelsResponse.IPendingChannel|null|undefined} channel
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @instance
             */
            PendingOpenChannel.prototype.channel = null;

            /**
             * PendingOpenChannel commitFee.
             * @member {Long} commitFee
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @instance
             */
            PendingOpenChannel.prototype.commitFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingOpenChannel commitWeight.
             * @member {Long} commitWeight
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @instance
             */
            PendingOpenChannel.prototype.commitWeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * PendingOpenChannel feePerKw.
             * @member {Long} feePerKw
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @instance
             */
            PendingOpenChannel.prototype.feePerKw = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new PendingOpenChannel instance using the specified properties.
             * @function create
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IPendingOpenChannel=} [properties] Properties to set
             * @returns {lnrpc.PendingChannelsResponse.PendingOpenChannel} PendingOpenChannel instance
             */
            PendingOpenChannel.create = function create(properties) {
                return new PendingOpenChannel(properties);
            };

            /**
             * Encodes the specified PendingOpenChannel message. Does not implicitly {@link lnrpc.PendingChannelsResponse.PendingOpenChannel.verify|verify} messages.
             * @function encode
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IPendingOpenChannel} message PendingOpenChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PendingOpenChannel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    $root.lnrpc.PendingChannelsResponse.PendingChannel.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.commitFee != null && Object.hasOwnProperty.call(message, "commitFee"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.commitFee);
                if (message.commitWeight != null && Object.hasOwnProperty.call(message, "commitWeight"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.commitWeight);
                if (message.feePerKw != null && Object.hasOwnProperty.call(message, "feePerKw"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.feePerKw);
                return writer;
            };

            /**
             * Encodes the specified PendingOpenChannel message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.PendingOpenChannel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IPendingOpenChannel} message PendingOpenChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PendingOpenChannel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PendingOpenChannel message from the specified reader or buffer.
             * @function decode
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {lnrpc.PendingChannelsResponse.PendingOpenChannel} PendingOpenChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PendingOpenChannel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse.PendingOpenChannel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.commitFee = reader.int64();
                            break;
                        }
                    case 5: {
                            message.commitWeight = reader.int64();
                            break;
                        }
                    case 6: {
                            message.feePerKw = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PendingOpenChannel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {lnrpc.PendingChannelsResponse.PendingOpenChannel} PendingOpenChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PendingOpenChannel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PendingOpenChannel message.
             * @function verify
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PendingOpenChannel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.channel != null && message.hasOwnProperty("channel")) {
                    let error = $root.lnrpc.PendingChannelsResponse.PendingChannel.verify(message.channel);
                    if (error)
                        return "channel." + error;
                }
                if (message.commitFee != null && message.hasOwnProperty("commitFee"))
                    if (!$util.isInteger(message.commitFee) && !(message.commitFee && $util.isInteger(message.commitFee.low) && $util.isInteger(message.commitFee.high)))
                        return "commitFee: integer|Long expected";
                if (message.commitWeight != null && message.hasOwnProperty("commitWeight"))
                    if (!$util.isInteger(message.commitWeight) && !(message.commitWeight && $util.isInteger(message.commitWeight.low) && $util.isInteger(message.commitWeight.high)))
                        return "commitWeight: integer|Long expected";
                if (message.feePerKw != null && message.hasOwnProperty("feePerKw"))
                    if (!$util.isInteger(message.feePerKw) && !(message.feePerKw && $util.isInteger(message.feePerKw.low) && $util.isInteger(message.feePerKw.high)))
                        return "feePerKw: integer|Long expected";
                return null;
            };

            /**
             * Creates a PendingOpenChannel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {lnrpc.PendingChannelsResponse.PendingOpenChannel} PendingOpenChannel
             */
            PendingOpenChannel.fromObject = function fromObject(object) {
                if (object instanceof $root.lnrpc.PendingChannelsResponse.PendingOpenChannel)
                    return object;
                let message = new $root.lnrpc.PendingChannelsResponse.PendingOpenChannel();
                if (object.channel != null) {
                    if (typeof object.channel !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.PendingOpenChannel.channel: object expected");
                    message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.fromObject(object.channel);
                }
                if (object.commitFee != null)
                    if ($util.Long)
                        (message.commitFee = $util.Long.fromValue(object.commitFee)).unsigned = false;
                    else if (typeof object.commitFee === "string")
                        message.commitFee = parseInt(object.commitFee, 10);
                    else if (typeof object.commitFee === "number")
                        message.commitFee = object.commitFee;
                    else if (typeof object.commitFee === "object")
                        message.commitFee = new $util.LongBits(object.commitFee.low >>> 0, object.commitFee.high >>> 0).toNumber();
                if (object.commitWeight != null)
                    if ($util.Long)
                        (message.commitWeight = $util.Long.fromValue(object.commitWeight)).unsigned = false;
                    else if (typeof object.commitWeight === "string")
                        message.commitWeight = parseInt(object.commitWeight, 10);
                    else if (typeof object.commitWeight === "number")
                        message.commitWeight = object.commitWeight;
                    else if (typeof object.commitWeight === "object")
                        message.commitWeight = new $util.LongBits(object.commitWeight.low >>> 0, object.commitWeight.high >>> 0).toNumber();
                if (object.feePerKw != null)
                    if ($util.Long)
                        (message.feePerKw = $util.Long.fromValue(object.feePerKw)).unsigned = false;
                    else if (typeof object.feePerKw === "string")
                        message.feePerKw = parseInt(object.feePerKw, 10);
                    else if (typeof object.feePerKw === "number")
                        message.feePerKw = object.feePerKw;
                    else if (typeof object.feePerKw === "object")
                        message.feePerKw = new $util.LongBits(object.feePerKw.low >>> 0, object.feePerKw.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a PendingOpenChannel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.PendingOpenChannel} message PendingOpenChannel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PendingOpenChannel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.channel = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.commitFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.commitFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.commitWeight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.commitWeight = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.feePerKw = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.feePerKw = options.longs === String ? "0" : 0;
                }
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.toObject(message.channel, options);
                if (message.commitFee != null && message.hasOwnProperty("commitFee"))
                    if (typeof message.commitFee === "number")
                        object.commitFee = options.longs === String ? String(message.commitFee) : message.commitFee;
                    else
                        object.commitFee = options.longs === String ? $util.Long.prototype.toString.call(message.commitFee) : options.longs === Number ? new $util.LongBits(message.commitFee.low >>> 0, message.commitFee.high >>> 0).toNumber() : message.commitFee;
                if (message.commitWeight != null && message.hasOwnProperty("commitWeight"))
                    if (typeof message.commitWeight === "number")
                        object.commitWeight = options.longs === String ? String(message.commitWeight) : message.commitWeight;
                    else
                        object.commitWeight = options.longs === String ? $util.Long.prototype.toString.call(message.commitWeight) : options.longs === Number ? new $util.LongBits(message.commitWeight.low >>> 0, message.commitWeight.high >>> 0).toNumber() : message.commitWeight;
                if (message.feePerKw != null && message.hasOwnProperty("feePerKw"))
                    if (typeof message.feePerKw === "number")
                        object.feePerKw = options.longs === String ? String(message.feePerKw) : message.feePerKw;
                    else
                        object.feePerKw = options.longs === String ? $util.Long.prototype.toString.call(message.feePerKw) : options.longs === Number ? new $util.LongBits(message.feePerKw.low >>> 0, message.feePerKw.high >>> 0).toNumber() : message.feePerKw;
                return object;
            };

            /**
             * Converts this PendingOpenChannel to JSON.
             * @function toJSON
             * @memberof lnrpc.PendingChannelsResponse.PendingOpenChannel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PendingOpenChannel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PendingOpenChannel;
        })();

        PendingChannelsResponse.WaitingCloseChannel = (function() {

            /**
             * Properties of a WaitingCloseChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @interface IWaitingCloseChannel
             * @property {lnrpc.PendingChannelsResponse.IPendingChannel|null} [channel] WaitingCloseChannel channel
             * @property {Long|null} [limboBalance] WaitingCloseChannel limboBalance
             * @property {lnrpc.PendingChannelsResponse.ICommitments|null} [commitments] WaitingCloseChannel commitments
             * @property {string|null} [closingTxid] WaitingCloseChannel closingTxid
             */

            /**
             * Constructs a new WaitingCloseChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @classdesc Represents a WaitingCloseChannel.
             * @implements IWaitingCloseChannel
             * @constructor
             * @param {lnrpc.PendingChannelsResponse.IWaitingCloseChannel=} [properties] Properties to set
             */
            function WaitingCloseChannel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WaitingCloseChannel channel.
             * @member {lnrpc.PendingChannelsResponse.IPendingChannel|null|undefined} channel
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @instance
             */
            WaitingCloseChannel.prototype.channel = null;

            /**
             * WaitingCloseChannel limboBalance.
             * @member {Long} limboBalance
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @instance
             */
            WaitingCloseChannel.prototype.limboBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * WaitingCloseChannel commitments.
             * @member {lnrpc.PendingChannelsResponse.ICommitments|null|undefined} commitments
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @instance
             */
            WaitingCloseChannel.prototype.commitments = null;

            /**
             * WaitingCloseChannel closingTxid.
             * @member {string} closingTxid
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @instance
             */
            WaitingCloseChannel.prototype.closingTxid = "";

            /**
             * Creates a new WaitingCloseChannel instance using the specified properties.
             * @function create
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IWaitingCloseChannel=} [properties] Properties to set
             * @returns {lnrpc.PendingChannelsResponse.WaitingCloseChannel} WaitingCloseChannel instance
             */
            WaitingCloseChannel.create = function create(properties) {
                return new WaitingCloseChannel(properties);
            };

            /**
             * Encodes the specified WaitingCloseChannel message. Does not implicitly {@link lnrpc.PendingChannelsResponse.WaitingCloseChannel.verify|verify} messages.
             * @function encode
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IWaitingCloseChannel} message WaitingCloseChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WaitingCloseChannel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    $root.lnrpc.PendingChannelsResponse.PendingChannel.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.limboBalance != null && Object.hasOwnProperty.call(message, "limboBalance"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.limboBalance);
                if (message.commitments != null && Object.hasOwnProperty.call(message, "commitments"))
                    $root.lnrpc.PendingChannelsResponse.Commitments.encode(message.commitments, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.closingTxid != null && Object.hasOwnProperty.call(message, "closingTxid"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.closingTxid);
                return writer;
            };

            /**
             * Encodes the specified WaitingCloseChannel message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.WaitingCloseChannel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IWaitingCloseChannel} message WaitingCloseChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WaitingCloseChannel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WaitingCloseChannel message from the specified reader or buffer.
             * @function decode
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {lnrpc.PendingChannelsResponse.WaitingCloseChannel} WaitingCloseChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WaitingCloseChannel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.limboBalance = reader.int64();
                            break;
                        }
                    case 3: {
                            message.commitments = $root.lnrpc.PendingChannelsResponse.Commitments.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.closingTxid = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WaitingCloseChannel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {lnrpc.PendingChannelsResponse.WaitingCloseChannel} WaitingCloseChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WaitingCloseChannel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WaitingCloseChannel message.
             * @function verify
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WaitingCloseChannel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.channel != null && message.hasOwnProperty("channel")) {
                    let error = $root.lnrpc.PendingChannelsResponse.PendingChannel.verify(message.channel);
                    if (error)
                        return "channel." + error;
                }
                if (message.limboBalance != null && message.hasOwnProperty("limboBalance"))
                    if (!$util.isInteger(message.limboBalance) && !(message.limboBalance && $util.isInteger(message.limboBalance.low) && $util.isInteger(message.limboBalance.high)))
                        return "limboBalance: integer|Long expected";
                if (message.commitments != null && message.hasOwnProperty("commitments")) {
                    let error = $root.lnrpc.PendingChannelsResponse.Commitments.verify(message.commitments);
                    if (error)
                        return "commitments." + error;
                }
                if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                    if (!$util.isString(message.closingTxid))
                        return "closingTxid: string expected";
                return null;
            };

            /**
             * Creates a WaitingCloseChannel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {lnrpc.PendingChannelsResponse.WaitingCloseChannel} WaitingCloseChannel
             */
            WaitingCloseChannel.fromObject = function fromObject(object) {
                if (object instanceof $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel)
                    return object;
                let message = new $root.lnrpc.PendingChannelsResponse.WaitingCloseChannel();
                if (object.channel != null) {
                    if (typeof object.channel !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.WaitingCloseChannel.channel: object expected");
                    message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.fromObject(object.channel);
                }
                if (object.limboBalance != null)
                    if ($util.Long)
                        (message.limboBalance = $util.Long.fromValue(object.limboBalance)).unsigned = false;
                    else if (typeof object.limboBalance === "string")
                        message.limboBalance = parseInt(object.limboBalance, 10);
                    else if (typeof object.limboBalance === "number")
                        message.limboBalance = object.limboBalance;
                    else if (typeof object.limboBalance === "object")
                        message.limboBalance = new $util.LongBits(object.limboBalance.low >>> 0, object.limboBalance.high >>> 0).toNumber();
                if (object.commitments != null) {
                    if (typeof object.commitments !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.WaitingCloseChannel.commitments: object expected");
                    message.commitments = $root.lnrpc.PendingChannelsResponse.Commitments.fromObject(object.commitments);
                }
                if (object.closingTxid != null)
                    message.closingTxid = String(object.closingTxid);
                return message;
            };

            /**
             * Creates a plain object from a WaitingCloseChannel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.WaitingCloseChannel} message WaitingCloseChannel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WaitingCloseChannel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.channel = null;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.limboBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limboBalance = options.longs === String ? "0" : 0;
                    object.commitments = null;
                    object.closingTxid = "";
                }
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.toObject(message.channel, options);
                if (message.limboBalance != null && message.hasOwnProperty("limboBalance"))
                    if (typeof message.limboBalance === "number")
                        object.limboBalance = options.longs === String ? String(message.limboBalance) : message.limboBalance;
                    else
                        object.limboBalance = options.longs === String ? $util.Long.prototype.toString.call(message.limboBalance) : options.longs === Number ? new $util.LongBits(message.limboBalance.low >>> 0, message.limboBalance.high >>> 0).toNumber() : message.limboBalance;
                if (message.commitments != null && message.hasOwnProperty("commitments"))
                    object.commitments = $root.lnrpc.PendingChannelsResponse.Commitments.toObject(message.commitments, options);
                if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                    object.closingTxid = message.closingTxid;
                return object;
            };

            /**
             * Converts this WaitingCloseChannel to JSON.
             * @function toJSON
             * @memberof lnrpc.PendingChannelsResponse.WaitingCloseChannel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WaitingCloseChannel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return WaitingCloseChannel;
        })();

        PendingChannelsResponse.Commitments = (function() {

            /**
             * Properties of a Commitments.
             * @memberof lnrpc.PendingChannelsResponse
             * @interface ICommitments
             * @property {string|null} [localTxid] Commitments localTxid
             * @property {string|null} [remoteTxid] Commitments remoteTxid
             * @property {string|null} [remotePendingTxid] Commitments remotePendingTxid
             * @property {Long|null} [localCommitFeeSat] Commitments localCommitFeeSat
             * @property {Long|null} [remoteCommitFeeSat] Commitments remoteCommitFeeSat
             * @property {Long|null} [remotePendingCommitFeeSat] Commitments remotePendingCommitFeeSat
             */

            /**
             * Constructs a new Commitments.
             * @memberof lnrpc.PendingChannelsResponse
             * @classdesc Represents a Commitments.
             * @implements ICommitments
             * @constructor
             * @param {lnrpc.PendingChannelsResponse.ICommitments=} [properties] Properties to set
             */
            function Commitments(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Commitments localTxid.
             * @member {string} localTxid
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             */
            Commitments.prototype.localTxid = "";

            /**
             * Commitments remoteTxid.
             * @member {string} remoteTxid
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             */
            Commitments.prototype.remoteTxid = "";

            /**
             * Commitments remotePendingTxid.
             * @member {string} remotePendingTxid
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             */
            Commitments.prototype.remotePendingTxid = "";

            /**
             * Commitments localCommitFeeSat.
             * @member {Long} localCommitFeeSat
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             */
            Commitments.prototype.localCommitFeeSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Commitments remoteCommitFeeSat.
             * @member {Long} remoteCommitFeeSat
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             */
            Commitments.prototype.remoteCommitFeeSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Commitments remotePendingCommitFeeSat.
             * @member {Long} remotePendingCommitFeeSat
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             */
            Commitments.prototype.remotePendingCommitFeeSat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Commitments instance using the specified properties.
             * @function create
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {lnrpc.PendingChannelsResponse.ICommitments=} [properties] Properties to set
             * @returns {lnrpc.PendingChannelsResponse.Commitments} Commitments instance
             */
            Commitments.create = function create(properties) {
                return new Commitments(properties);
            };

            /**
             * Encodes the specified Commitments message. Does not implicitly {@link lnrpc.PendingChannelsResponse.Commitments.verify|verify} messages.
             * @function encode
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {lnrpc.PendingChannelsResponse.ICommitments} message Commitments message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Commitments.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.localTxid != null && Object.hasOwnProperty.call(message, "localTxid"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.localTxid);
                if (message.remoteTxid != null && Object.hasOwnProperty.call(message, "remoteTxid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.remoteTxid);
                if (message.remotePendingTxid != null && Object.hasOwnProperty.call(message, "remotePendingTxid"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.remotePendingTxid);
                if (message.localCommitFeeSat != null && Object.hasOwnProperty.call(message, "localCommitFeeSat"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.localCommitFeeSat);
                if (message.remoteCommitFeeSat != null && Object.hasOwnProperty.call(message, "remoteCommitFeeSat"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.remoteCommitFeeSat);
                if (message.remotePendingCommitFeeSat != null && Object.hasOwnProperty.call(message, "remotePendingCommitFeeSat"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.remotePendingCommitFeeSat);
                return writer;
            };

            /**
             * Encodes the specified Commitments message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.Commitments.verify|verify} messages.
             * @function encodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {lnrpc.PendingChannelsResponse.ICommitments} message Commitments message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Commitments.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Commitments message from the specified reader or buffer.
             * @function decode
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {lnrpc.PendingChannelsResponse.Commitments} Commitments
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Commitments.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse.Commitments();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.localTxid = reader.string();
                            break;
                        }
                    case 2: {
                            message.remoteTxid = reader.string();
                            break;
                        }
                    case 3: {
                            message.remotePendingTxid = reader.string();
                            break;
                        }
                    case 4: {
                            message.localCommitFeeSat = reader.uint64();
                            break;
                        }
                    case 5: {
                            message.remoteCommitFeeSat = reader.uint64();
                            break;
                        }
                    case 6: {
                            message.remotePendingCommitFeeSat = reader.uint64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Commitments message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {lnrpc.PendingChannelsResponse.Commitments} Commitments
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Commitments.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Commitments message.
             * @function verify
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Commitments.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.localTxid != null && message.hasOwnProperty("localTxid"))
                    if (!$util.isString(message.localTxid))
                        return "localTxid: string expected";
                if (message.remoteTxid != null && message.hasOwnProperty("remoteTxid"))
                    if (!$util.isString(message.remoteTxid))
                        return "remoteTxid: string expected";
                if (message.remotePendingTxid != null && message.hasOwnProperty("remotePendingTxid"))
                    if (!$util.isString(message.remotePendingTxid))
                        return "remotePendingTxid: string expected";
                if (message.localCommitFeeSat != null && message.hasOwnProperty("localCommitFeeSat"))
                    if (!$util.isInteger(message.localCommitFeeSat) && !(message.localCommitFeeSat && $util.isInteger(message.localCommitFeeSat.low) && $util.isInteger(message.localCommitFeeSat.high)))
                        return "localCommitFeeSat: integer|Long expected";
                if (message.remoteCommitFeeSat != null && message.hasOwnProperty("remoteCommitFeeSat"))
                    if (!$util.isInteger(message.remoteCommitFeeSat) && !(message.remoteCommitFeeSat && $util.isInteger(message.remoteCommitFeeSat.low) && $util.isInteger(message.remoteCommitFeeSat.high)))
                        return "remoteCommitFeeSat: integer|Long expected";
                if (message.remotePendingCommitFeeSat != null && message.hasOwnProperty("remotePendingCommitFeeSat"))
                    if (!$util.isInteger(message.remotePendingCommitFeeSat) && !(message.remotePendingCommitFeeSat && $util.isInteger(message.remotePendingCommitFeeSat.low) && $util.isInteger(message.remotePendingCommitFeeSat.high)))
                        return "remotePendingCommitFeeSat: integer|Long expected";
                return null;
            };

            /**
             * Creates a Commitments message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {lnrpc.PendingChannelsResponse.Commitments} Commitments
             */
            Commitments.fromObject = function fromObject(object) {
                if (object instanceof $root.lnrpc.PendingChannelsResponse.Commitments)
                    return object;
                let message = new $root.lnrpc.PendingChannelsResponse.Commitments();
                if (object.localTxid != null)
                    message.localTxid = String(object.localTxid);
                if (object.remoteTxid != null)
                    message.remoteTxid = String(object.remoteTxid);
                if (object.remotePendingTxid != null)
                    message.remotePendingTxid = String(object.remotePendingTxid);
                if (object.localCommitFeeSat != null)
                    if ($util.Long)
                        (message.localCommitFeeSat = $util.Long.fromValue(object.localCommitFeeSat)).unsigned = true;
                    else if (typeof object.localCommitFeeSat === "string")
                        message.localCommitFeeSat = parseInt(object.localCommitFeeSat, 10);
                    else if (typeof object.localCommitFeeSat === "number")
                        message.localCommitFeeSat = object.localCommitFeeSat;
                    else if (typeof object.localCommitFeeSat === "object")
                        message.localCommitFeeSat = new $util.LongBits(object.localCommitFeeSat.low >>> 0, object.localCommitFeeSat.high >>> 0).toNumber(true);
                if (object.remoteCommitFeeSat != null)
                    if ($util.Long)
                        (message.remoteCommitFeeSat = $util.Long.fromValue(object.remoteCommitFeeSat)).unsigned = true;
                    else if (typeof object.remoteCommitFeeSat === "string")
                        message.remoteCommitFeeSat = parseInt(object.remoteCommitFeeSat, 10);
                    else if (typeof object.remoteCommitFeeSat === "number")
                        message.remoteCommitFeeSat = object.remoteCommitFeeSat;
                    else if (typeof object.remoteCommitFeeSat === "object")
                        message.remoteCommitFeeSat = new $util.LongBits(object.remoteCommitFeeSat.low >>> 0, object.remoteCommitFeeSat.high >>> 0).toNumber(true);
                if (object.remotePendingCommitFeeSat != null)
                    if ($util.Long)
                        (message.remotePendingCommitFeeSat = $util.Long.fromValue(object.remotePendingCommitFeeSat)).unsigned = true;
                    else if (typeof object.remotePendingCommitFeeSat === "string")
                        message.remotePendingCommitFeeSat = parseInt(object.remotePendingCommitFeeSat, 10);
                    else if (typeof object.remotePendingCommitFeeSat === "number")
                        message.remotePendingCommitFeeSat = object.remotePendingCommitFeeSat;
                    else if (typeof object.remotePendingCommitFeeSat === "object")
                        message.remotePendingCommitFeeSat = new $util.LongBits(object.remotePendingCommitFeeSat.low >>> 0, object.remotePendingCommitFeeSat.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Commitments message. Also converts values to other types if specified.
             * @function toObject
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @static
             * @param {lnrpc.PendingChannelsResponse.Commitments} message Commitments
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Commitments.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.localTxid = "";
                    object.remoteTxid = "";
                    object.remotePendingTxid = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.localCommitFeeSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.localCommitFeeSat = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.remoteCommitFeeSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.remoteCommitFeeSat = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, true);
                        object.remotePendingCommitFeeSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.remotePendingCommitFeeSat = options.longs === String ? "0" : 0;
                }
                if (message.localTxid != null && message.hasOwnProperty("localTxid"))
                    object.localTxid = message.localTxid;
                if (message.remoteTxid != null && message.hasOwnProperty("remoteTxid"))
                    object.remoteTxid = message.remoteTxid;
                if (message.remotePendingTxid != null && message.hasOwnProperty("remotePendingTxid"))
                    object.remotePendingTxid = message.remotePendingTxid;
                if (message.localCommitFeeSat != null && message.hasOwnProperty("localCommitFeeSat"))
                    if (typeof message.localCommitFeeSat === "number")
                        object.localCommitFeeSat = options.longs === String ? String(message.localCommitFeeSat) : message.localCommitFeeSat;
                    else
                        object.localCommitFeeSat = options.longs === String ? $util.Long.prototype.toString.call(message.localCommitFeeSat) : options.longs === Number ? new $util.LongBits(message.localCommitFeeSat.low >>> 0, message.localCommitFeeSat.high >>> 0).toNumber(true) : message.localCommitFeeSat;
                if (message.remoteCommitFeeSat != null && message.hasOwnProperty("remoteCommitFeeSat"))
                    if (typeof message.remoteCommitFeeSat === "number")
                        object.remoteCommitFeeSat = options.longs === String ? String(message.remoteCommitFeeSat) : message.remoteCommitFeeSat;
                    else
                        object.remoteCommitFeeSat = options.longs === String ? $util.Long.prototype.toString.call(message.remoteCommitFeeSat) : options.longs === Number ? new $util.LongBits(message.remoteCommitFeeSat.low >>> 0, message.remoteCommitFeeSat.high >>> 0).toNumber(true) : message.remoteCommitFeeSat;
                if (message.remotePendingCommitFeeSat != null && message.hasOwnProperty("remotePendingCommitFeeSat"))
                    if (typeof message.remotePendingCommitFeeSat === "number")
                        object.remotePendingCommitFeeSat = options.longs === String ? String(message.remotePendingCommitFeeSat) : message.remotePendingCommitFeeSat;
                    else
                        object.remotePendingCommitFeeSat = options.longs === String ? $util.Long.prototype.toString.call(message.remotePendingCommitFeeSat) : options.longs === Number ? new $util.LongBits(message.remotePendingCommitFeeSat.low >>> 0, message.remotePendingCommitFeeSat.high >>> 0).toNumber(true) : message.remotePendingCommitFeeSat;
                return object;
            };

            /**
             * Converts this Commitments to JSON.
             * @function toJSON
             * @memberof lnrpc.PendingChannelsResponse.Commitments
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Commitments.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Commitments;
        })();

        PendingChannelsResponse.ClosedChannel = (function() {

            /**
             * Properties of a ClosedChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @interface IClosedChannel
             * @property {lnrpc.PendingChannelsResponse.IPendingChannel|null} [channel] ClosedChannel channel
             * @property {string|null} [closingTxid] ClosedChannel closingTxid
             */

            /**
             * Constructs a new ClosedChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @classdesc Represents a ClosedChannel.
             * @implements IClosedChannel
             * @constructor
             * @param {lnrpc.PendingChannelsResponse.IClosedChannel=} [properties] Properties to set
             */
            function ClosedChannel(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClosedChannel channel.
             * @member {lnrpc.PendingChannelsResponse.IPendingChannel|null|undefined} channel
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @instance
             */
            ClosedChannel.prototype.channel = null;

            /**
             * ClosedChannel closingTxid.
             * @member {string} closingTxid
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @instance
             */
            ClosedChannel.prototype.closingTxid = "";

            /**
             * Creates a new ClosedChannel instance using the specified properties.
             * @function create
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IClosedChannel=} [properties] Properties to set
             * @returns {lnrpc.PendingChannelsResponse.ClosedChannel} ClosedChannel instance
             */
            ClosedChannel.create = function create(properties) {
                return new ClosedChannel(properties);
            };

            /**
             * Encodes the specified ClosedChannel message. Does not implicitly {@link lnrpc.PendingChannelsResponse.ClosedChannel.verify|verify} messages.
             * @function encode
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IClosedChannel} message ClosedChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClosedChannel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    $root.lnrpc.PendingChannelsResponse.PendingChannel.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.closingTxid != null && Object.hasOwnProperty.call(message, "closingTxid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.closingTxid);
                return writer;
            };

            /**
             * Encodes the specified ClosedChannel message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.ClosedChannel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IClosedChannel} message ClosedChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClosedChannel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ClosedChannel message from the specified reader or buffer.
             * @function decode
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {lnrpc.PendingChannelsResponse.ClosedChannel} ClosedChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClosedChannel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse.ClosedChannel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.closingTxid = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ClosedChannel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {lnrpc.PendingChannelsResponse.ClosedChannel} ClosedChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClosedChannel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ClosedChannel message.
             * @function verify
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClosedChannel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.channel != null && message.hasOwnProperty("channel")) {
                    let error = $root.lnrpc.PendingChannelsResponse.PendingChannel.verify(message.channel);
                    if (error)
                        return "channel." + error;
                }
                if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                    if (!$util.isString(message.closingTxid))
                        return "closingTxid: string expected";
                return null;
            };

            /**
             * Creates a ClosedChannel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {lnrpc.PendingChannelsResponse.ClosedChannel} ClosedChannel
             */
            ClosedChannel.fromObject = function fromObject(object) {
                if (object instanceof $root.lnrpc.PendingChannelsResponse.ClosedChannel)
                    return object;
                let message = new $root.lnrpc.PendingChannelsResponse.ClosedChannel();
                if (object.channel != null) {
                    if (typeof object.channel !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.ClosedChannel.channel: object expected");
                    message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.fromObject(object.channel);
                }
                if (object.closingTxid != null)
                    message.closingTxid = String(object.closingTxid);
                return message;
            };

            /**
             * Creates a plain object from a ClosedChannel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.ClosedChannel} message ClosedChannel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClosedChannel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.channel = null;
                    object.closingTxid = "";
                }
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.toObject(message.channel, options);
                if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                    object.closingTxid = message.closingTxid;
                return object;
            };

            /**
             * Converts this ClosedChannel to JSON.
             * @function toJSON
             * @memberof lnrpc.PendingChannelsResponse.ClosedChannel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClosedChannel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ClosedChannel;
        })();

        PendingChannelsResponse.ForceClosedChannel = (function() {

            /**
             * Properties of a ForceClosedChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @interface IForceClosedChannel
             * @property {lnrpc.PendingChannelsResponse.IPendingChannel|null} [channel] ForceClosedChannel channel
             * @property {string|null} [closingTxid] ForceClosedChannel closingTxid
             * @property {Long|null} [limboBalance] ForceClosedChannel limboBalance
             * @property {number|null} [maturityHeight] ForceClosedChannel maturityHeight
             * @property {number|null} [blocksTilMaturity] ForceClosedChannel blocksTilMaturity
             * @property {Long|null} [recoveredBalance] ForceClosedChannel recoveredBalance
             * @property {Array.<lnrpc.IPendingHTLC>|null} [pendingHtlcs] ForceClosedChannel pendingHtlcs
             * @property {lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState|null} [anchor] ForceClosedChannel anchor
             */

            /**
             * Constructs a new ForceClosedChannel.
             * @memberof lnrpc.PendingChannelsResponse
             * @classdesc Represents a ForceClosedChannel.
             * @implements IForceClosedChannel
             * @constructor
             * @param {lnrpc.PendingChannelsResponse.IForceClosedChannel=} [properties] Properties to set
             */
            function ForceClosedChannel(properties) {
                this.pendingHtlcs = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ForceClosedChannel channel.
             * @member {lnrpc.PendingChannelsResponse.IPendingChannel|null|undefined} channel
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.channel = null;

            /**
             * ForceClosedChannel closingTxid.
             * @member {string} closingTxid
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.closingTxid = "";

            /**
             * ForceClosedChannel limboBalance.
             * @member {Long} limboBalance
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.limboBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ForceClosedChannel maturityHeight.
             * @member {number} maturityHeight
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.maturityHeight = 0;

            /**
             * ForceClosedChannel blocksTilMaturity.
             * @member {number} blocksTilMaturity
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.blocksTilMaturity = 0;

            /**
             * ForceClosedChannel recoveredBalance.
             * @member {Long} recoveredBalance
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.recoveredBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ForceClosedChannel pendingHtlcs.
             * @member {Array.<lnrpc.IPendingHTLC>} pendingHtlcs
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.pendingHtlcs = $util.emptyArray;

            /**
             * ForceClosedChannel anchor.
             * @member {lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState} anchor
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             */
            ForceClosedChannel.prototype.anchor = 0;

            /**
             * Creates a new ForceClosedChannel instance using the specified properties.
             * @function create
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IForceClosedChannel=} [properties] Properties to set
             * @returns {lnrpc.PendingChannelsResponse.ForceClosedChannel} ForceClosedChannel instance
             */
            ForceClosedChannel.create = function create(properties) {
                return new ForceClosedChannel(properties);
            };

            /**
             * Encodes the specified ForceClosedChannel message. Does not implicitly {@link lnrpc.PendingChannelsResponse.ForceClosedChannel.verify|verify} messages.
             * @function encode
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IForceClosedChannel} message ForceClosedChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ForceClosedChannel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                    $root.lnrpc.PendingChannelsResponse.PendingChannel.encode(message.channel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.closingTxid != null && Object.hasOwnProperty.call(message, "closingTxid"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.closingTxid);
                if (message.limboBalance != null && Object.hasOwnProperty.call(message, "limboBalance"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.limboBalance);
                if (message.maturityHeight != null && Object.hasOwnProperty.call(message, "maturityHeight"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maturityHeight);
                if (message.blocksTilMaturity != null && Object.hasOwnProperty.call(message, "blocksTilMaturity"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.blocksTilMaturity);
                if (message.recoveredBalance != null && Object.hasOwnProperty.call(message, "recoveredBalance"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.recoveredBalance);
                if (message.pendingHtlcs != null && message.pendingHtlcs.length)
                    for (let i = 0; i < message.pendingHtlcs.length; ++i)
                        $root.lnrpc.PendingHTLC.encode(message.pendingHtlcs[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.anchor != null && Object.hasOwnProperty.call(message, "anchor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.anchor);
                return writer;
            };

            /**
             * Encodes the specified ForceClosedChannel message, length delimited. Does not implicitly {@link lnrpc.PendingChannelsResponse.ForceClosedChannel.verify|verify} messages.
             * @function encodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.IForceClosedChannel} message ForceClosedChannel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ForceClosedChannel.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ForceClosedChannel message from the specified reader or buffer.
             * @function decode
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {lnrpc.PendingChannelsResponse.ForceClosedChannel} ForceClosedChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ForceClosedChannel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PendingChannelsResponse.ForceClosedChannel();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.closingTxid = reader.string();
                            break;
                        }
                    case 3: {
                            message.limboBalance = reader.int64();
                            break;
                        }
                    case 4: {
                            message.maturityHeight = reader.uint32();
                            break;
                        }
                    case 5: {
                            message.blocksTilMaturity = reader.int32();
                            break;
                        }
                    case 6: {
                            message.recoveredBalance = reader.int64();
                            break;
                        }
                    case 8: {
                            if (!(message.pendingHtlcs && message.pendingHtlcs.length))
                                message.pendingHtlcs = [];
                            message.pendingHtlcs.push($root.lnrpc.PendingHTLC.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            message.anchor = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ForceClosedChannel message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {lnrpc.PendingChannelsResponse.ForceClosedChannel} ForceClosedChannel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ForceClosedChannel.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ForceClosedChannel message.
             * @function verify
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ForceClosedChannel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.channel != null && message.hasOwnProperty("channel")) {
                    let error = $root.lnrpc.PendingChannelsResponse.PendingChannel.verify(message.channel);
                    if (error)
                        return "channel." + error;
                }
                if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                    if (!$util.isString(message.closingTxid))
                        return "closingTxid: string expected";
                if (message.limboBalance != null && message.hasOwnProperty("limboBalance"))
                    if (!$util.isInteger(message.limboBalance) && !(message.limboBalance && $util.isInteger(message.limboBalance.low) && $util.isInteger(message.limboBalance.high)))
                        return "limboBalance: integer|Long expected";
                if (message.maturityHeight != null && message.hasOwnProperty("maturityHeight"))
                    if (!$util.isInteger(message.maturityHeight))
                        return "maturityHeight: integer expected";
                if (message.blocksTilMaturity != null && message.hasOwnProperty("blocksTilMaturity"))
                    if (!$util.isInteger(message.blocksTilMaturity))
                        return "blocksTilMaturity: integer expected";
                if (message.recoveredBalance != null && message.hasOwnProperty("recoveredBalance"))
                    if (!$util.isInteger(message.recoveredBalance) && !(message.recoveredBalance && $util.isInteger(message.recoveredBalance.low) && $util.isInteger(message.recoveredBalance.high)))
                        return "recoveredBalance: integer|Long expected";
                if (message.pendingHtlcs != null && message.hasOwnProperty("pendingHtlcs")) {
                    if (!Array.isArray(message.pendingHtlcs))
                        return "pendingHtlcs: array expected";
                    for (let i = 0; i < message.pendingHtlcs.length; ++i) {
                        let error = $root.lnrpc.PendingHTLC.verify(message.pendingHtlcs[i]);
                        if (error)
                            return "pendingHtlcs." + error;
                    }
                }
                if (message.anchor != null && message.hasOwnProperty("anchor"))
                    switch (message.anchor) {
                    default:
                        return "anchor: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a ForceClosedChannel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {lnrpc.PendingChannelsResponse.ForceClosedChannel} ForceClosedChannel
             */
            ForceClosedChannel.fromObject = function fromObject(object) {
                if (object instanceof $root.lnrpc.PendingChannelsResponse.ForceClosedChannel)
                    return object;
                let message = new $root.lnrpc.PendingChannelsResponse.ForceClosedChannel();
                if (object.channel != null) {
                    if (typeof object.channel !== "object")
                        throw TypeError(".lnrpc.PendingChannelsResponse.ForceClosedChannel.channel: object expected");
                    message.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.fromObject(object.channel);
                }
                if (object.closingTxid != null)
                    message.closingTxid = String(object.closingTxid);
                if (object.limboBalance != null)
                    if ($util.Long)
                        (message.limboBalance = $util.Long.fromValue(object.limboBalance)).unsigned = false;
                    else if (typeof object.limboBalance === "string")
                        message.limboBalance = parseInt(object.limboBalance, 10);
                    else if (typeof object.limboBalance === "number")
                        message.limboBalance = object.limboBalance;
                    else if (typeof object.limboBalance === "object")
                        message.limboBalance = new $util.LongBits(object.limboBalance.low >>> 0, object.limboBalance.high >>> 0).toNumber();
                if (object.maturityHeight != null)
                    message.maturityHeight = object.maturityHeight >>> 0;
                if (object.blocksTilMaturity != null)
                    message.blocksTilMaturity = object.blocksTilMaturity | 0;
                if (object.recoveredBalance != null)
                    if ($util.Long)
                        (message.recoveredBalance = $util.Long.fromValue(object.recoveredBalance)).unsigned = false;
                    else if (typeof object.recoveredBalance === "string")
                        message.recoveredBalance = parseInt(object.recoveredBalance, 10);
                    else if (typeof object.recoveredBalance === "number")
                        message.recoveredBalance = object.recoveredBalance;
                    else if (typeof object.recoveredBalance === "object")
                        message.recoveredBalance = new $util.LongBits(object.recoveredBalance.low >>> 0, object.recoveredBalance.high >>> 0).toNumber();
                if (object.pendingHtlcs) {
                    if (!Array.isArray(object.pendingHtlcs))
                        throw TypeError(".lnrpc.PendingChannelsResponse.ForceClosedChannel.pendingHtlcs: array expected");
                    message.pendingHtlcs = [];
                    for (let i = 0; i < object.pendingHtlcs.length; ++i) {
                        if (typeof object.pendingHtlcs[i] !== "object")
                            throw TypeError(".lnrpc.PendingChannelsResponse.ForceClosedChannel.pendingHtlcs: object expected");
                        message.pendingHtlcs[i] = $root.lnrpc.PendingHTLC.fromObject(object.pendingHtlcs[i]);
                    }
                }
                switch (object.anchor) {
                case "LIMBO":
                case 0:
                    message.anchor = 0;
                    break;
                case "RECOVERED":
                case 1:
                    message.anchor = 1;
                    break;
                case "LOST":
                case 2:
                    message.anchor = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a ForceClosedChannel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @static
             * @param {lnrpc.PendingChannelsResponse.ForceClosedChannel} message ForceClosedChannel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ForceClosedChannel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.pendingHtlcs = [];
                if (options.defaults) {
                    object.channel = null;
                    object.closingTxid = "";
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.limboBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.limboBalance = options.longs === String ? "0" : 0;
                    object.maturityHeight = 0;
                    object.blocksTilMaturity = 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.recoveredBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.recoveredBalance = options.longs === String ? "0" : 0;
                    object.anchor = options.enums === String ? "LIMBO" : 0;
                }
                if (message.channel != null && message.hasOwnProperty("channel"))
                    object.channel = $root.lnrpc.PendingChannelsResponse.PendingChannel.toObject(message.channel, options);
                if (message.closingTxid != null && message.hasOwnProperty("closingTxid"))
                    object.closingTxid = message.closingTxid;
                if (message.limboBalance != null && message.hasOwnProperty("limboBalance"))
                    if (typeof message.limboBalance === "number")
                        object.limboBalance = options.longs === String ? String(message.limboBalance) : message.limboBalance;
                    else
                        object.limboBalance = options.longs === String ? $util.Long.prototype.toString.call(message.limboBalance) : options.longs === Number ? new $util.LongBits(message.limboBalance.low >>> 0, message.limboBalance.high >>> 0).toNumber() : message.limboBalance;
                if (message.maturityHeight != null && message.hasOwnProperty("maturityHeight"))
                    object.maturityHeight = message.maturityHeight;
                if (message.blocksTilMaturity != null && message.hasOwnProperty("blocksTilMaturity"))
                    object.blocksTilMaturity = message.blocksTilMaturity;
                if (message.recoveredBalance != null && message.hasOwnProperty("recoveredBalance"))
                    if (typeof message.recoveredBalance === "number")
                        object.recoveredBalance = options.longs === String ? String(message.recoveredBalance) : message.recoveredBalance;
                    else
                        object.recoveredBalance = options.longs === String ? $util.Long.prototype.toString.call(message.recoveredBalance) : options.longs === Number ? new $util.LongBits(message.recoveredBalance.low >>> 0, message.recoveredBalance.high >>> 0).toNumber() : message.recoveredBalance;
                if (message.pendingHtlcs && message.pendingHtlcs.length) {
                    object.pendingHtlcs = [];
                    for (let j = 0; j < message.pendingHtlcs.length; ++j)
                        object.pendingHtlcs[j] = $root.lnrpc.PendingHTLC.toObject(message.pendingHtlcs[j], options);
                }
                if (message.anchor != null && message.hasOwnProperty("anchor"))
                    object.anchor = options.enums === String ? $root.lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState[message.anchor] : message.anchor;
                return object;
            };

            /**
             * Converts this ForceClosedChannel to JSON.
             * @function toJSON
             * @memberof lnrpc.PendingChannelsResponse.ForceClosedChannel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ForceClosedChannel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * AnchorState enum.
             * @name lnrpc.PendingChannelsResponse.ForceClosedChannel.AnchorState
             * @enum {number}
             * @property {number} LIMBO=0 LIMBO value
             * @property {number} RECOVERED=1 RECOVERED value
             * @property {number} LOST=2 LOST value
             */
            ForceClosedChannel.AnchorState = (function() {
                const valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "LIMBO"] = 0;
                values[valuesById[1] = "RECOVERED"] = 1;
                values[valuesById[2] = "LOST"] = 2;
                return values;
            })();

            return ForceClosedChannel;
        })();

        return PendingChannelsResponse;
    })();

    lnrpc.ChannelEventSubscription = (function() {

        /**
         * Properties of a ChannelEventSubscription.
         * @memberof lnrpc
         * @interface IChannelEventSubscription
         */

        /**
         * Constructs a new ChannelEventSubscription.
         * @memberof lnrpc
         * @classdesc Represents a ChannelEventSubscription.
         * @implements IChannelEventSubscription
         * @constructor
         * @param {lnrpc.IChannelEventSubscription=} [properties] Properties to set
         */
        function ChannelEventSubscription(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ChannelEventSubscription instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {lnrpc.IChannelEventSubscription=} [properties] Properties to set
         * @returns {lnrpc.ChannelEventSubscription} ChannelEventSubscription instance
         */
        ChannelEventSubscription.create = function create(properties) {
            return new ChannelEventSubscription(properties);
        };

        /**
         * Encodes the specified ChannelEventSubscription message. Does not implicitly {@link lnrpc.ChannelEventSubscription.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {lnrpc.IChannelEventSubscription} message ChannelEventSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEventSubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ChannelEventSubscription message, length delimited. Does not implicitly {@link lnrpc.ChannelEventSubscription.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {lnrpc.IChannelEventSubscription} message ChannelEventSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEventSubscription.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelEventSubscription message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelEventSubscription} ChannelEventSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEventSubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelEventSubscription();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelEventSubscription message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelEventSubscription} ChannelEventSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEventSubscription.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelEventSubscription message.
         * @function verify
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelEventSubscription.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ChannelEventSubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelEventSubscription} ChannelEventSubscription
         */
        ChannelEventSubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelEventSubscription)
                return object;
            return new $root.lnrpc.ChannelEventSubscription();
        };

        /**
         * Creates a plain object from a ChannelEventSubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelEventSubscription
         * @static
         * @param {lnrpc.ChannelEventSubscription} message ChannelEventSubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelEventSubscription.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ChannelEventSubscription to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelEventSubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelEventSubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelEventSubscription;
    })();

    lnrpc.ChannelEventUpdate = (function() {

        /**
         * Properties of a ChannelEventUpdate.
         * @memberof lnrpc
         * @interface IChannelEventUpdate
         * @property {lnrpc.IChannel|null} [openChannel] ChannelEventUpdate openChannel
         * @property {lnrpc.IChannelCloseSummary|null} [closedChannel] ChannelEventUpdate closedChannel
         * @property {lnrpc.IChannelPoint|null} [activeChannel] ChannelEventUpdate activeChannel
         * @property {lnrpc.IChannelPoint|null} [inactiveChannel] ChannelEventUpdate inactiveChannel
         * @property {lnrpc.IPendingUpdate|null} [pendingOpenChannel] ChannelEventUpdate pendingOpenChannel
         * @property {lnrpc.IChannelPoint|null} [fullyResolvedChannel] ChannelEventUpdate fullyResolvedChannel
         * @property {lnrpc.ChannelEventUpdate.UpdateType|null} [type] ChannelEventUpdate type
         */

        /**
         * Constructs a new ChannelEventUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ChannelEventUpdate.
         * @implements IChannelEventUpdate
         * @constructor
         * @param {lnrpc.IChannelEventUpdate=} [properties] Properties to set
         */
        function ChannelEventUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelEventUpdate openChannel.
         * @member {lnrpc.IChannel|null|undefined} openChannel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.openChannel = null;

        /**
         * ChannelEventUpdate closedChannel.
         * @member {lnrpc.IChannelCloseSummary|null|undefined} closedChannel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.closedChannel = null;

        /**
         * ChannelEventUpdate activeChannel.
         * @member {lnrpc.IChannelPoint|null|undefined} activeChannel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.activeChannel = null;

        /**
         * ChannelEventUpdate inactiveChannel.
         * @member {lnrpc.IChannelPoint|null|undefined} inactiveChannel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.inactiveChannel = null;

        /**
         * ChannelEventUpdate pendingOpenChannel.
         * @member {lnrpc.IPendingUpdate|null|undefined} pendingOpenChannel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.pendingOpenChannel = null;

        /**
         * ChannelEventUpdate fullyResolvedChannel.
         * @member {lnrpc.IChannelPoint|null|undefined} fullyResolvedChannel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.fullyResolvedChannel = null;

        /**
         * ChannelEventUpdate type.
         * @member {lnrpc.ChannelEventUpdate.UpdateType} type
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        ChannelEventUpdate.prototype.type = 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ChannelEventUpdate channel.
         * @member {"openChannel"|"closedChannel"|"activeChannel"|"inactiveChannel"|"pendingOpenChannel"|"fullyResolvedChannel"|undefined} channel
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         */
        Object.defineProperty(ChannelEventUpdate.prototype, "channel", {
            get: $util.oneOfGetter($oneOfFields = ["openChannel", "closedChannel", "activeChannel", "inactiveChannel", "pendingOpenChannel", "fullyResolvedChannel"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ChannelEventUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {lnrpc.IChannelEventUpdate=} [properties] Properties to set
         * @returns {lnrpc.ChannelEventUpdate} ChannelEventUpdate instance
         */
        ChannelEventUpdate.create = function create(properties) {
            return new ChannelEventUpdate(properties);
        };

        /**
         * Encodes the specified ChannelEventUpdate message. Does not implicitly {@link lnrpc.ChannelEventUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {lnrpc.IChannelEventUpdate} message ChannelEventUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEventUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.openChannel != null && Object.hasOwnProperty.call(message, "openChannel"))
                $root.lnrpc.Channel.encode(message.openChannel, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.closedChannel != null && Object.hasOwnProperty.call(message, "closedChannel"))
                $root.lnrpc.ChannelCloseSummary.encode(message.closedChannel, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.activeChannel != null && Object.hasOwnProperty.call(message, "activeChannel"))
                $root.lnrpc.ChannelPoint.encode(message.activeChannel, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.inactiveChannel != null && Object.hasOwnProperty.call(message, "inactiveChannel"))
                $root.lnrpc.ChannelPoint.encode(message.inactiveChannel, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
            if (message.pendingOpenChannel != null && Object.hasOwnProperty.call(message, "pendingOpenChannel"))
                $root.lnrpc.PendingUpdate.encode(message.pendingOpenChannel, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.fullyResolvedChannel != null && Object.hasOwnProperty.call(message, "fullyResolvedChannel"))
                $root.lnrpc.ChannelPoint.encode(message.fullyResolvedChannel, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelEventUpdate message, length delimited. Does not implicitly {@link lnrpc.ChannelEventUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {lnrpc.IChannelEventUpdate} message ChannelEventUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEventUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelEventUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelEventUpdate} ChannelEventUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEventUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelEventUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.openChannel = $root.lnrpc.Channel.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.closedChannel = $root.lnrpc.ChannelCloseSummary.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.activeChannel = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.inactiveChannel = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.pendingOpenChannel = $root.lnrpc.PendingUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.fullyResolvedChannel = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.type = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelEventUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelEventUpdate} ChannelEventUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEventUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelEventUpdate message.
         * @function verify
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelEventUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.openChannel != null && message.hasOwnProperty("openChannel")) {
                properties.channel = 1;
                {
                    let error = $root.lnrpc.Channel.verify(message.openChannel);
                    if (error)
                        return "openChannel." + error;
                }
            }
            if (message.closedChannel != null && message.hasOwnProperty("closedChannel")) {
                if (properties.channel === 1)
                    return "channel: multiple values";
                properties.channel = 1;
                {
                    let error = $root.lnrpc.ChannelCloseSummary.verify(message.closedChannel);
                    if (error)
                        return "closedChannel." + error;
                }
            }
            if (message.activeChannel != null && message.hasOwnProperty("activeChannel")) {
                if (properties.channel === 1)
                    return "channel: multiple values";
                properties.channel = 1;
                {
                    let error = $root.lnrpc.ChannelPoint.verify(message.activeChannel);
                    if (error)
                        return "activeChannel." + error;
                }
            }
            if (message.inactiveChannel != null && message.hasOwnProperty("inactiveChannel")) {
                if (properties.channel === 1)
                    return "channel: multiple values";
                properties.channel = 1;
                {
                    let error = $root.lnrpc.ChannelPoint.verify(message.inactiveChannel);
                    if (error)
                        return "inactiveChannel." + error;
                }
            }
            if (message.pendingOpenChannel != null && message.hasOwnProperty("pendingOpenChannel")) {
                if (properties.channel === 1)
                    return "channel: multiple values";
                properties.channel = 1;
                {
                    let error = $root.lnrpc.PendingUpdate.verify(message.pendingOpenChannel);
                    if (error)
                        return "pendingOpenChannel." + error;
                }
            }
            if (message.fullyResolvedChannel != null && message.hasOwnProperty("fullyResolvedChannel")) {
                if (properties.channel === 1)
                    return "channel: multiple values";
                properties.channel = 1;
                {
                    let error = $root.lnrpc.ChannelPoint.verify(message.fullyResolvedChannel);
                    if (error)
                        return "fullyResolvedChannel." + error;
                }
            }
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a ChannelEventUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelEventUpdate} ChannelEventUpdate
         */
        ChannelEventUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelEventUpdate)
                return object;
            let message = new $root.lnrpc.ChannelEventUpdate();
            if (object.openChannel != null) {
                if (typeof object.openChannel !== "object")
                    throw TypeError(".lnrpc.ChannelEventUpdate.openChannel: object expected");
                message.openChannel = $root.lnrpc.Channel.fromObject(object.openChannel);
            }
            if (object.closedChannel != null) {
                if (typeof object.closedChannel !== "object")
                    throw TypeError(".lnrpc.ChannelEventUpdate.closedChannel: object expected");
                message.closedChannel = $root.lnrpc.ChannelCloseSummary.fromObject(object.closedChannel);
            }
            if (object.activeChannel != null) {
                if (typeof object.activeChannel !== "object")
                    throw TypeError(".lnrpc.ChannelEventUpdate.activeChannel: object expected");
                message.activeChannel = $root.lnrpc.ChannelPoint.fromObject(object.activeChannel);
            }
            if (object.inactiveChannel != null) {
                if (typeof object.inactiveChannel !== "object")
                    throw TypeError(".lnrpc.ChannelEventUpdate.inactiveChannel: object expected");
                message.inactiveChannel = $root.lnrpc.ChannelPoint.fromObject(object.inactiveChannel);
            }
            if (object.pendingOpenChannel != null) {
                if (typeof object.pendingOpenChannel !== "object")
                    throw TypeError(".lnrpc.ChannelEventUpdate.pendingOpenChannel: object expected");
                message.pendingOpenChannel = $root.lnrpc.PendingUpdate.fromObject(object.pendingOpenChannel);
            }
            if (object.fullyResolvedChannel != null) {
                if (typeof object.fullyResolvedChannel !== "object")
                    throw TypeError(".lnrpc.ChannelEventUpdate.fullyResolvedChannel: object expected");
                message.fullyResolvedChannel = $root.lnrpc.ChannelPoint.fromObject(object.fullyResolvedChannel);
            }
            switch (object.type) {
            case "OPEN_CHANNEL":
            case 0:
                message.type = 0;
                break;
            case "CLOSED_CHANNEL":
            case 1:
                message.type = 1;
                break;
            case "ACTIVE_CHANNEL":
            case 2:
                message.type = 2;
                break;
            case "INACTIVE_CHANNEL":
            case 3:
                message.type = 3;
                break;
            case "PENDING_OPEN_CHANNEL":
            case 4:
                message.type = 4;
                break;
            case "FULLY_RESOLVED_CHANNEL":
            case 5:
                message.type = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelEventUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelEventUpdate
         * @static
         * @param {lnrpc.ChannelEventUpdate} message ChannelEventUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelEventUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.type = options.enums === String ? "OPEN_CHANNEL" : 0;
            if (message.openChannel != null && message.hasOwnProperty("openChannel")) {
                object.openChannel = $root.lnrpc.Channel.toObject(message.openChannel, options);
                if (options.oneofs)
                    object.channel = "openChannel";
            }
            if (message.closedChannel != null && message.hasOwnProperty("closedChannel")) {
                object.closedChannel = $root.lnrpc.ChannelCloseSummary.toObject(message.closedChannel, options);
                if (options.oneofs)
                    object.channel = "closedChannel";
            }
            if (message.activeChannel != null && message.hasOwnProperty("activeChannel")) {
                object.activeChannel = $root.lnrpc.ChannelPoint.toObject(message.activeChannel, options);
                if (options.oneofs)
                    object.channel = "activeChannel";
            }
            if (message.inactiveChannel != null && message.hasOwnProperty("inactiveChannel")) {
                object.inactiveChannel = $root.lnrpc.ChannelPoint.toObject(message.inactiveChannel, options);
                if (options.oneofs)
                    object.channel = "inactiveChannel";
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.lnrpc.ChannelEventUpdate.UpdateType[message.type] : message.type;
            if (message.pendingOpenChannel != null && message.hasOwnProperty("pendingOpenChannel")) {
                object.pendingOpenChannel = $root.lnrpc.PendingUpdate.toObject(message.pendingOpenChannel, options);
                if (options.oneofs)
                    object.channel = "pendingOpenChannel";
            }
            if (message.fullyResolvedChannel != null && message.hasOwnProperty("fullyResolvedChannel")) {
                object.fullyResolvedChannel = $root.lnrpc.ChannelPoint.toObject(message.fullyResolvedChannel, options);
                if (options.oneofs)
                    object.channel = "fullyResolvedChannel";
            }
            return object;
        };

        /**
         * Converts this ChannelEventUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelEventUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelEventUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * UpdateType enum.
         * @name lnrpc.ChannelEventUpdate.UpdateType
         * @enum {number}
         * @property {number} OPEN_CHANNEL=0 OPEN_CHANNEL value
         * @property {number} CLOSED_CHANNEL=1 CLOSED_CHANNEL value
         * @property {number} ACTIVE_CHANNEL=2 ACTIVE_CHANNEL value
         * @property {number} INACTIVE_CHANNEL=3 INACTIVE_CHANNEL value
         * @property {number} PENDING_OPEN_CHANNEL=4 PENDING_OPEN_CHANNEL value
         * @property {number} FULLY_RESOLVED_CHANNEL=5 FULLY_RESOLVED_CHANNEL value
         */
        ChannelEventUpdate.UpdateType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OPEN_CHANNEL"] = 0;
            values[valuesById[1] = "CLOSED_CHANNEL"] = 1;
            values[valuesById[2] = "ACTIVE_CHANNEL"] = 2;
            values[valuesById[3] = "INACTIVE_CHANNEL"] = 3;
            values[valuesById[4] = "PENDING_OPEN_CHANNEL"] = 4;
            values[valuesById[5] = "FULLY_RESOLVED_CHANNEL"] = 5;
            return values;
        })();

        return ChannelEventUpdate;
    })();

    lnrpc.WalletAccountBalance = (function() {

        /**
         * Properties of a WalletAccountBalance.
         * @memberof lnrpc
         * @interface IWalletAccountBalance
         * @property {Long|null} [confirmedBalance] WalletAccountBalance confirmedBalance
         * @property {Long|null} [unconfirmedBalance] WalletAccountBalance unconfirmedBalance
         */

        /**
         * Constructs a new WalletAccountBalance.
         * @memberof lnrpc
         * @classdesc Represents a WalletAccountBalance.
         * @implements IWalletAccountBalance
         * @constructor
         * @param {lnrpc.IWalletAccountBalance=} [properties] Properties to set
         */
        function WalletAccountBalance(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WalletAccountBalance confirmedBalance.
         * @member {Long} confirmedBalance
         * @memberof lnrpc.WalletAccountBalance
         * @instance
         */
        WalletAccountBalance.prototype.confirmedBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WalletAccountBalance unconfirmedBalance.
         * @member {Long} unconfirmedBalance
         * @memberof lnrpc.WalletAccountBalance
         * @instance
         */
        WalletAccountBalance.prototype.unconfirmedBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new WalletAccountBalance instance using the specified properties.
         * @function create
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {lnrpc.IWalletAccountBalance=} [properties] Properties to set
         * @returns {lnrpc.WalletAccountBalance} WalletAccountBalance instance
         */
        WalletAccountBalance.create = function create(properties) {
            return new WalletAccountBalance(properties);
        };

        /**
         * Encodes the specified WalletAccountBalance message. Does not implicitly {@link lnrpc.WalletAccountBalance.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {lnrpc.IWalletAccountBalance} message WalletAccountBalance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletAccountBalance.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.confirmedBalance != null && Object.hasOwnProperty.call(message, "confirmedBalance"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.confirmedBalance);
            if (message.unconfirmedBalance != null && Object.hasOwnProperty.call(message, "unconfirmedBalance"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.unconfirmedBalance);
            return writer;
        };

        /**
         * Encodes the specified WalletAccountBalance message, length delimited. Does not implicitly {@link lnrpc.WalletAccountBalance.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {lnrpc.IWalletAccountBalance} message WalletAccountBalance message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletAccountBalance.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WalletAccountBalance message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.WalletAccountBalance} WalletAccountBalance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletAccountBalance.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.WalletAccountBalance();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.confirmedBalance = reader.int64();
                        break;
                    }
                case 2: {
                        message.unconfirmedBalance = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WalletAccountBalance message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.WalletAccountBalance} WalletAccountBalance
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletAccountBalance.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WalletAccountBalance message.
         * @function verify
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WalletAccountBalance.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.confirmedBalance != null && message.hasOwnProperty("confirmedBalance"))
                if (!$util.isInteger(message.confirmedBalance) && !(message.confirmedBalance && $util.isInteger(message.confirmedBalance.low) && $util.isInteger(message.confirmedBalance.high)))
                    return "confirmedBalance: integer|Long expected";
            if (message.unconfirmedBalance != null && message.hasOwnProperty("unconfirmedBalance"))
                if (!$util.isInteger(message.unconfirmedBalance) && !(message.unconfirmedBalance && $util.isInteger(message.unconfirmedBalance.low) && $util.isInteger(message.unconfirmedBalance.high)))
                    return "unconfirmedBalance: integer|Long expected";
            return null;
        };

        /**
         * Creates a WalletAccountBalance message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.WalletAccountBalance} WalletAccountBalance
         */
        WalletAccountBalance.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.WalletAccountBalance)
                return object;
            let message = new $root.lnrpc.WalletAccountBalance();
            if (object.confirmedBalance != null)
                if ($util.Long)
                    (message.confirmedBalance = $util.Long.fromValue(object.confirmedBalance)).unsigned = false;
                else if (typeof object.confirmedBalance === "string")
                    message.confirmedBalance = parseInt(object.confirmedBalance, 10);
                else if (typeof object.confirmedBalance === "number")
                    message.confirmedBalance = object.confirmedBalance;
                else if (typeof object.confirmedBalance === "object")
                    message.confirmedBalance = new $util.LongBits(object.confirmedBalance.low >>> 0, object.confirmedBalance.high >>> 0).toNumber();
            if (object.unconfirmedBalance != null)
                if ($util.Long)
                    (message.unconfirmedBalance = $util.Long.fromValue(object.unconfirmedBalance)).unsigned = false;
                else if (typeof object.unconfirmedBalance === "string")
                    message.unconfirmedBalance = parseInt(object.unconfirmedBalance, 10);
                else if (typeof object.unconfirmedBalance === "number")
                    message.unconfirmedBalance = object.unconfirmedBalance;
                else if (typeof object.unconfirmedBalance === "object")
                    message.unconfirmedBalance = new $util.LongBits(object.unconfirmedBalance.low >>> 0, object.unconfirmedBalance.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a WalletAccountBalance message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.WalletAccountBalance
         * @static
         * @param {lnrpc.WalletAccountBalance} message WalletAccountBalance
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WalletAccountBalance.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.confirmedBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.confirmedBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.unconfirmedBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.unconfirmedBalance = options.longs === String ? "0" : 0;
            }
            if (message.confirmedBalance != null && message.hasOwnProperty("confirmedBalance"))
                if (typeof message.confirmedBalance === "number")
                    object.confirmedBalance = options.longs === String ? String(message.confirmedBalance) : message.confirmedBalance;
                else
                    object.confirmedBalance = options.longs === String ? $util.Long.prototype.toString.call(message.confirmedBalance) : options.longs === Number ? new $util.LongBits(message.confirmedBalance.low >>> 0, message.confirmedBalance.high >>> 0).toNumber() : message.confirmedBalance;
            if (message.unconfirmedBalance != null && message.hasOwnProperty("unconfirmedBalance"))
                if (typeof message.unconfirmedBalance === "number")
                    object.unconfirmedBalance = options.longs === String ? String(message.unconfirmedBalance) : message.unconfirmedBalance;
                else
                    object.unconfirmedBalance = options.longs === String ? $util.Long.prototype.toString.call(message.unconfirmedBalance) : options.longs === Number ? new $util.LongBits(message.unconfirmedBalance.low >>> 0, message.unconfirmedBalance.high >>> 0).toNumber() : message.unconfirmedBalance;
            return object;
        };

        /**
         * Converts this WalletAccountBalance to JSON.
         * @function toJSON
         * @memberof lnrpc.WalletAccountBalance
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WalletAccountBalance.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WalletAccountBalance;
    })();

    lnrpc.WalletBalanceRequest = (function() {

        /**
         * Properties of a WalletBalanceRequest.
         * @memberof lnrpc
         * @interface IWalletBalanceRequest
         */

        /**
         * Constructs a new WalletBalanceRequest.
         * @memberof lnrpc
         * @classdesc Represents a WalletBalanceRequest.
         * @implements IWalletBalanceRequest
         * @constructor
         * @param {lnrpc.IWalletBalanceRequest=} [properties] Properties to set
         */
        function WalletBalanceRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new WalletBalanceRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {lnrpc.IWalletBalanceRequest=} [properties] Properties to set
         * @returns {lnrpc.WalletBalanceRequest} WalletBalanceRequest instance
         */
        WalletBalanceRequest.create = function create(properties) {
            return new WalletBalanceRequest(properties);
        };

        /**
         * Encodes the specified WalletBalanceRequest message. Does not implicitly {@link lnrpc.WalletBalanceRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {lnrpc.IWalletBalanceRequest} message WalletBalanceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletBalanceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified WalletBalanceRequest message, length delimited. Does not implicitly {@link lnrpc.WalletBalanceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {lnrpc.IWalletBalanceRequest} message WalletBalanceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletBalanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WalletBalanceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.WalletBalanceRequest} WalletBalanceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletBalanceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.WalletBalanceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WalletBalanceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.WalletBalanceRequest} WalletBalanceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletBalanceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WalletBalanceRequest message.
         * @function verify
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WalletBalanceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a WalletBalanceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.WalletBalanceRequest} WalletBalanceRequest
         */
        WalletBalanceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.WalletBalanceRequest)
                return object;
            return new $root.lnrpc.WalletBalanceRequest();
        };

        /**
         * Creates a plain object from a WalletBalanceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.WalletBalanceRequest
         * @static
         * @param {lnrpc.WalletBalanceRequest} message WalletBalanceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WalletBalanceRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this WalletBalanceRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.WalletBalanceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WalletBalanceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WalletBalanceRequest;
    })();

    lnrpc.WalletBalanceResponse = (function() {

        /**
         * Properties of a WalletBalanceResponse.
         * @memberof lnrpc
         * @interface IWalletBalanceResponse
         * @property {Long|null} [totalBalance] WalletBalanceResponse totalBalance
         * @property {Long|null} [confirmedBalance] WalletBalanceResponse confirmedBalance
         * @property {Long|null} [unconfirmedBalance] WalletBalanceResponse unconfirmedBalance
         * @property {Long|null} [lockedBalance] WalletBalanceResponse lockedBalance
         * @property {Long|null} [reservedBalanceAnchorChan] WalletBalanceResponse reservedBalanceAnchorChan
         * @property {Object.<string,lnrpc.IWalletAccountBalance>|null} [accountBalance] WalletBalanceResponse accountBalance
         */

        /**
         * Constructs a new WalletBalanceResponse.
         * @memberof lnrpc
         * @classdesc Represents a WalletBalanceResponse.
         * @implements IWalletBalanceResponse
         * @constructor
         * @param {lnrpc.IWalletBalanceResponse=} [properties] Properties to set
         */
        function WalletBalanceResponse(properties) {
            this.accountBalance = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WalletBalanceResponse totalBalance.
         * @member {Long} totalBalance
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         */
        WalletBalanceResponse.prototype.totalBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WalletBalanceResponse confirmedBalance.
         * @member {Long} confirmedBalance
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         */
        WalletBalanceResponse.prototype.confirmedBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WalletBalanceResponse unconfirmedBalance.
         * @member {Long} unconfirmedBalance
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         */
        WalletBalanceResponse.prototype.unconfirmedBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WalletBalanceResponse lockedBalance.
         * @member {Long} lockedBalance
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         */
        WalletBalanceResponse.prototype.lockedBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WalletBalanceResponse reservedBalanceAnchorChan.
         * @member {Long} reservedBalanceAnchorChan
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         */
        WalletBalanceResponse.prototype.reservedBalanceAnchorChan = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * WalletBalanceResponse accountBalance.
         * @member {Object.<string,lnrpc.IWalletAccountBalance>} accountBalance
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         */
        WalletBalanceResponse.prototype.accountBalance = $util.emptyObject;

        /**
         * Creates a new WalletBalanceResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {lnrpc.IWalletBalanceResponse=} [properties] Properties to set
         * @returns {lnrpc.WalletBalanceResponse} WalletBalanceResponse instance
         */
        WalletBalanceResponse.create = function create(properties) {
            return new WalletBalanceResponse(properties);
        };

        /**
         * Encodes the specified WalletBalanceResponse message. Does not implicitly {@link lnrpc.WalletBalanceResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {lnrpc.IWalletBalanceResponse} message WalletBalanceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletBalanceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.totalBalance != null && Object.hasOwnProperty.call(message, "totalBalance"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalBalance);
            if (message.confirmedBalance != null && Object.hasOwnProperty.call(message, "confirmedBalance"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.confirmedBalance);
            if (message.unconfirmedBalance != null && Object.hasOwnProperty.call(message, "unconfirmedBalance"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.unconfirmedBalance);
            if (message.accountBalance != null && Object.hasOwnProperty.call(message, "accountBalance"))
                for (let keys = Object.keys(message.accountBalance), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.lnrpc.WalletAccountBalance.encode(message.accountBalance[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.lockedBalance != null && Object.hasOwnProperty.call(message, "lockedBalance"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.lockedBalance);
            if (message.reservedBalanceAnchorChan != null && Object.hasOwnProperty.call(message, "reservedBalanceAnchorChan"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.reservedBalanceAnchorChan);
            return writer;
        };

        /**
         * Encodes the specified WalletBalanceResponse message, length delimited. Does not implicitly {@link lnrpc.WalletBalanceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {lnrpc.IWalletBalanceResponse} message WalletBalanceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WalletBalanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WalletBalanceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.WalletBalanceResponse} WalletBalanceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletBalanceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.WalletBalanceResponse(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.totalBalance = reader.int64();
                        break;
                    }
                case 2: {
                        message.confirmedBalance = reader.int64();
                        break;
                    }
                case 3: {
                        message.unconfirmedBalance = reader.int64();
                        break;
                    }
                case 5: {
                        message.lockedBalance = reader.int64();
                        break;
                    }
                case 6: {
                        message.reservedBalanceAnchorChan = reader.int64();
                        break;
                    }
                case 4: {
                        if (message.accountBalance === $util.emptyObject)
                            message.accountBalance = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.lnrpc.WalletAccountBalance.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.accountBalance[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WalletBalanceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.WalletBalanceResponse} WalletBalanceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WalletBalanceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WalletBalanceResponse message.
         * @function verify
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WalletBalanceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.totalBalance != null && message.hasOwnProperty("totalBalance"))
                if (!$util.isInteger(message.totalBalance) && !(message.totalBalance && $util.isInteger(message.totalBalance.low) && $util.isInteger(message.totalBalance.high)))
                    return "totalBalance: integer|Long expected";
            if (message.confirmedBalance != null && message.hasOwnProperty("confirmedBalance"))
                if (!$util.isInteger(message.confirmedBalance) && !(message.confirmedBalance && $util.isInteger(message.confirmedBalance.low) && $util.isInteger(message.confirmedBalance.high)))
                    return "confirmedBalance: integer|Long expected";
            if (message.unconfirmedBalance != null && message.hasOwnProperty("unconfirmedBalance"))
                if (!$util.isInteger(message.unconfirmedBalance) && !(message.unconfirmedBalance && $util.isInteger(message.unconfirmedBalance.low) && $util.isInteger(message.unconfirmedBalance.high)))
                    return "unconfirmedBalance: integer|Long expected";
            if (message.lockedBalance != null && message.hasOwnProperty("lockedBalance"))
                if (!$util.isInteger(message.lockedBalance) && !(message.lockedBalance && $util.isInteger(message.lockedBalance.low) && $util.isInteger(message.lockedBalance.high)))
                    return "lockedBalance: integer|Long expected";
            if (message.reservedBalanceAnchorChan != null && message.hasOwnProperty("reservedBalanceAnchorChan"))
                if (!$util.isInteger(message.reservedBalanceAnchorChan) && !(message.reservedBalanceAnchorChan && $util.isInteger(message.reservedBalanceAnchorChan.low) && $util.isInteger(message.reservedBalanceAnchorChan.high)))
                    return "reservedBalanceAnchorChan: integer|Long expected";
            if (message.accountBalance != null && message.hasOwnProperty("accountBalance")) {
                if (!$util.isObject(message.accountBalance))
                    return "accountBalance: object expected";
                let key = Object.keys(message.accountBalance);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.lnrpc.WalletAccountBalance.verify(message.accountBalance[key[i]]);
                    if (error)
                        return "accountBalance." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WalletBalanceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.WalletBalanceResponse} WalletBalanceResponse
         */
        WalletBalanceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.WalletBalanceResponse)
                return object;
            let message = new $root.lnrpc.WalletBalanceResponse();
            if (object.totalBalance != null)
                if ($util.Long)
                    (message.totalBalance = $util.Long.fromValue(object.totalBalance)).unsigned = false;
                else if (typeof object.totalBalance === "string")
                    message.totalBalance = parseInt(object.totalBalance, 10);
                else if (typeof object.totalBalance === "number")
                    message.totalBalance = object.totalBalance;
                else if (typeof object.totalBalance === "object")
                    message.totalBalance = new $util.LongBits(object.totalBalance.low >>> 0, object.totalBalance.high >>> 0).toNumber();
            if (object.confirmedBalance != null)
                if ($util.Long)
                    (message.confirmedBalance = $util.Long.fromValue(object.confirmedBalance)).unsigned = false;
                else if (typeof object.confirmedBalance === "string")
                    message.confirmedBalance = parseInt(object.confirmedBalance, 10);
                else if (typeof object.confirmedBalance === "number")
                    message.confirmedBalance = object.confirmedBalance;
                else if (typeof object.confirmedBalance === "object")
                    message.confirmedBalance = new $util.LongBits(object.confirmedBalance.low >>> 0, object.confirmedBalance.high >>> 0).toNumber();
            if (object.unconfirmedBalance != null)
                if ($util.Long)
                    (message.unconfirmedBalance = $util.Long.fromValue(object.unconfirmedBalance)).unsigned = false;
                else if (typeof object.unconfirmedBalance === "string")
                    message.unconfirmedBalance = parseInt(object.unconfirmedBalance, 10);
                else if (typeof object.unconfirmedBalance === "number")
                    message.unconfirmedBalance = object.unconfirmedBalance;
                else if (typeof object.unconfirmedBalance === "object")
                    message.unconfirmedBalance = new $util.LongBits(object.unconfirmedBalance.low >>> 0, object.unconfirmedBalance.high >>> 0).toNumber();
            if (object.lockedBalance != null)
                if ($util.Long)
                    (message.lockedBalance = $util.Long.fromValue(object.lockedBalance)).unsigned = false;
                else if (typeof object.lockedBalance === "string")
                    message.lockedBalance = parseInt(object.lockedBalance, 10);
                else if (typeof object.lockedBalance === "number")
                    message.lockedBalance = object.lockedBalance;
                else if (typeof object.lockedBalance === "object")
                    message.lockedBalance = new $util.LongBits(object.lockedBalance.low >>> 0, object.lockedBalance.high >>> 0).toNumber();
            if (object.reservedBalanceAnchorChan != null)
                if ($util.Long)
                    (message.reservedBalanceAnchorChan = $util.Long.fromValue(object.reservedBalanceAnchorChan)).unsigned = false;
                else if (typeof object.reservedBalanceAnchorChan === "string")
                    message.reservedBalanceAnchorChan = parseInt(object.reservedBalanceAnchorChan, 10);
                else if (typeof object.reservedBalanceAnchorChan === "number")
                    message.reservedBalanceAnchorChan = object.reservedBalanceAnchorChan;
                else if (typeof object.reservedBalanceAnchorChan === "object")
                    message.reservedBalanceAnchorChan = new $util.LongBits(object.reservedBalanceAnchorChan.low >>> 0, object.reservedBalanceAnchorChan.high >>> 0).toNumber();
            if (object.accountBalance) {
                if (typeof object.accountBalance !== "object")
                    throw TypeError(".lnrpc.WalletBalanceResponse.accountBalance: object expected");
                message.accountBalance = {};
                for (let keys = Object.keys(object.accountBalance), i = 0; i < keys.length; ++i) {
                    if (typeof object.accountBalance[keys[i]] !== "object")
                        throw TypeError(".lnrpc.WalletBalanceResponse.accountBalance: object expected");
                    message.accountBalance[keys[i]] = $root.lnrpc.WalletAccountBalance.fromObject(object.accountBalance[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WalletBalanceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.WalletBalanceResponse
         * @static
         * @param {lnrpc.WalletBalanceResponse} message WalletBalanceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WalletBalanceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.accountBalance = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.confirmedBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.confirmedBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.unconfirmedBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.unconfirmedBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lockedBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lockedBalance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.reservedBalanceAnchorChan = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reservedBalanceAnchorChan = options.longs === String ? "0" : 0;
            }
            if (message.totalBalance != null && message.hasOwnProperty("totalBalance"))
                if (typeof message.totalBalance === "number")
                    object.totalBalance = options.longs === String ? String(message.totalBalance) : message.totalBalance;
                else
                    object.totalBalance = options.longs === String ? $util.Long.prototype.toString.call(message.totalBalance) : options.longs === Number ? new $util.LongBits(message.totalBalance.low >>> 0, message.totalBalance.high >>> 0).toNumber() : message.totalBalance;
            if (message.confirmedBalance != null && message.hasOwnProperty("confirmedBalance"))
                if (typeof message.confirmedBalance === "number")
                    object.confirmedBalance = options.longs === String ? String(message.confirmedBalance) : message.confirmedBalance;
                else
                    object.confirmedBalance = options.longs === String ? $util.Long.prototype.toString.call(message.confirmedBalance) : options.longs === Number ? new $util.LongBits(message.confirmedBalance.low >>> 0, message.confirmedBalance.high >>> 0).toNumber() : message.confirmedBalance;
            if (message.unconfirmedBalance != null && message.hasOwnProperty("unconfirmedBalance"))
                if (typeof message.unconfirmedBalance === "number")
                    object.unconfirmedBalance = options.longs === String ? String(message.unconfirmedBalance) : message.unconfirmedBalance;
                else
                    object.unconfirmedBalance = options.longs === String ? $util.Long.prototype.toString.call(message.unconfirmedBalance) : options.longs === Number ? new $util.LongBits(message.unconfirmedBalance.low >>> 0, message.unconfirmedBalance.high >>> 0).toNumber() : message.unconfirmedBalance;
            let keys2;
            if (message.accountBalance && (keys2 = Object.keys(message.accountBalance)).length) {
                object.accountBalance = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.accountBalance[keys2[j]] = $root.lnrpc.WalletAccountBalance.toObject(message.accountBalance[keys2[j]], options);
            }
            if (message.lockedBalance != null && message.hasOwnProperty("lockedBalance"))
                if (typeof message.lockedBalance === "number")
                    object.lockedBalance = options.longs === String ? String(message.lockedBalance) : message.lockedBalance;
                else
                    object.lockedBalance = options.longs === String ? $util.Long.prototype.toString.call(message.lockedBalance) : options.longs === Number ? new $util.LongBits(message.lockedBalance.low >>> 0, message.lockedBalance.high >>> 0).toNumber() : message.lockedBalance;
            if (message.reservedBalanceAnchorChan != null && message.hasOwnProperty("reservedBalanceAnchorChan"))
                if (typeof message.reservedBalanceAnchorChan === "number")
                    object.reservedBalanceAnchorChan = options.longs === String ? String(message.reservedBalanceAnchorChan) : message.reservedBalanceAnchorChan;
                else
                    object.reservedBalanceAnchorChan = options.longs === String ? $util.Long.prototype.toString.call(message.reservedBalanceAnchorChan) : options.longs === Number ? new $util.LongBits(message.reservedBalanceAnchorChan.low >>> 0, message.reservedBalanceAnchorChan.high >>> 0).toNumber() : message.reservedBalanceAnchorChan;
            return object;
        };

        /**
         * Converts this WalletBalanceResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.WalletBalanceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WalletBalanceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WalletBalanceResponse;
    })();

    lnrpc.Amount = (function() {

        /**
         * Properties of an Amount.
         * @memberof lnrpc
         * @interface IAmount
         * @property {Long|null} [sat] Amount sat
         * @property {Long|null} [msat] Amount msat
         */

        /**
         * Constructs a new Amount.
         * @memberof lnrpc
         * @classdesc Represents an Amount.
         * @implements IAmount
         * @constructor
         * @param {lnrpc.IAmount=} [properties] Properties to set
         */
        function Amount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Amount sat.
         * @member {Long} sat
         * @memberof lnrpc.Amount
         * @instance
         */
        Amount.prototype.sat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Amount msat.
         * @member {Long} msat
         * @memberof lnrpc.Amount
         * @instance
         */
        Amount.prototype.msat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new Amount instance using the specified properties.
         * @function create
         * @memberof lnrpc.Amount
         * @static
         * @param {lnrpc.IAmount=} [properties] Properties to set
         * @returns {lnrpc.Amount} Amount instance
         */
        Amount.create = function create(properties) {
            return new Amount(properties);
        };

        /**
         * Encodes the specified Amount message. Does not implicitly {@link lnrpc.Amount.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Amount
         * @static
         * @param {lnrpc.IAmount} message Amount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Amount.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sat != null && Object.hasOwnProperty.call(message, "sat"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.sat);
            if (message.msat != null && Object.hasOwnProperty.call(message, "msat"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.msat);
            return writer;
        };

        /**
         * Encodes the specified Amount message, length delimited. Does not implicitly {@link lnrpc.Amount.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Amount
         * @static
         * @param {lnrpc.IAmount} message Amount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Amount.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Amount message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Amount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Amount} Amount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Amount.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Amount();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sat = reader.uint64();
                        break;
                    }
                case 2: {
                        message.msat = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Amount message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Amount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Amount} Amount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Amount.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Amount message.
         * @function verify
         * @memberof lnrpc.Amount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Amount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sat != null && message.hasOwnProperty("sat"))
                if (!$util.isInteger(message.sat) && !(message.sat && $util.isInteger(message.sat.low) && $util.isInteger(message.sat.high)))
                    return "sat: integer|Long expected";
            if (message.msat != null && message.hasOwnProperty("msat"))
                if (!$util.isInteger(message.msat) && !(message.msat && $util.isInteger(message.msat.low) && $util.isInteger(message.msat.high)))
                    return "msat: integer|Long expected";
            return null;
        };

        /**
         * Creates an Amount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Amount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Amount} Amount
         */
        Amount.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Amount)
                return object;
            let message = new $root.lnrpc.Amount();
            if (object.sat != null)
                if ($util.Long)
                    (message.sat = $util.Long.fromValue(object.sat)).unsigned = true;
                else if (typeof object.sat === "string")
                    message.sat = parseInt(object.sat, 10);
                else if (typeof object.sat === "number")
                    message.sat = object.sat;
                else if (typeof object.sat === "object")
                    message.sat = new $util.LongBits(object.sat.low >>> 0, object.sat.high >>> 0).toNumber(true);
            if (object.msat != null)
                if ($util.Long)
                    (message.msat = $util.Long.fromValue(object.msat)).unsigned = true;
                else if (typeof object.msat === "string")
                    message.msat = parseInt(object.msat, 10);
                else if (typeof object.msat === "number")
                    message.msat = object.msat;
                else if (typeof object.msat === "object")
                    message.msat = new $util.LongBits(object.msat.low >>> 0, object.msat.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an Amount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Amount
         * @static
         * @param {lnrpc.Amount} message Amount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Amount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.sat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.msat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.msat = options.longs === String ? "0" : 0;
            }
            if (message.sat != null && message.hasOwnProperty("sat"))
                if (typeof message.sat === "number")
                    object.sat = options.longs === String ? String(message.sat) : message.sat;
                else
                    object.sat = options.longs === String ? $util.Long.prototype.toString.call(message.sat) : options.longs === Number ? new $util.LongBits(message.sat.low >>> 0, message.sat.high >>> 0).toNumber(true) : message.sat;
            if (message.msat != null && message.hasOwnProperty("msat"))
                if (typeof message.msat === "number")
                    object.msat = options.longs === String ? String(message.msat) : message.msat;
                else
                    object.msat = options.longs === String ? $util.Long.prototype.toString.call(message.msat) : options.longs === Number ? new $util.LongBits(message.msat.low >>> 0, message.msat.high >>> 0).toNumber(true) : message.msat;
            return object;
        };

        /**
         * Converts this Amount to JSON.
         * @function toJSON
         * @memberof lnrpc.Amount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Amount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Amount;
    })();

    lnrpc.ChannelBalanceRequest = (function() {

        /**
         * Properties of a ChannelBalanceRequest.
         * @memberof lnrpc
         * @interface IChannelBalanceRequest
         */

        /**
         * Constructs a new ChannelBalanceRequest.
         * @memberof lnrpc
         * @classdesc Represents a ChannelBalanceRequest.
         * @implements IChannelBalanceRequest
         * @constructor
         * @param {lnrpc.IChannelBalanceRequest=} [properties] Properties to set
         */
        function ChannelBalanceRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ChannelBalanceRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {lnrpc.IChannelBalanceRequest=} [properties] Properties to set
         * @returns {lnrpc.ChannelBalanceRequest} ChannelBalanceRequest instance
         */
        ChannelBalanceRequest.create = function create(properties) {
            return new ChannelBalanceRequest(properties);
        };

        /**
         * Encodes the specified ChannelBalanceRequest message. Does not implicitly {@link lnrpc.ChannelBalanceRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {lnrpc.IChannelBalanceRequest} message ChannelBalanceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBalanceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ChannelBalanceRequest message, length delimited. Does not implicitly {@link lnrpc.ChannelBalanceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {lnrpc.IChannelBalanceRequest} message ChannelBalanceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBalanceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelBalanceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelBalanceRequest} ChannelBalanceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBalanceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelBalanceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelBalanceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelBalanceRequest} ChannelBalanceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBalanceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelBalanceRequest message.
         * @function verify
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelBalanceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ChannelBalanceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelBalanceRequest} ChannelBalanceRequest
         */
        ChannelBalanceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelBalanceRequest)
                return object;
            return new $root.lnrpc.ChannelBalanceRequest();
        };

        /**
         * Creates a plain object from a ChannelBalanceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelBalanceRequest
         * @static
         * @param {lnrpc.ChannelBalanceRequest} message ChannelBalanceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelBalanceRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ChannelBalanceRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelBalanceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelBalanceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelBalanceRequest;
    })();

    lnrpc.ChannelBalanceResponse = (function() {

        /**
         * Properties of a ChannelBalanceResponse.
         * @memberof lnrpc
         * @interface IChannelBalanceResponse
         * @property {Long|null} [balance] ChannelBalanceResponse balance
         * @property {Long|null} [pendingOpenBalance] ChannelBalanceResponse pendingOpenBalance
         * @property {lnrpc.IAmount|null} [localBalance] ChannelBalanceResponse localBalance
         * @property {lnrpc.IAmount|null} [remoteBalance] ChannelBalanceResponse remoteBalance
         * @property {lnrpc.IAmount|null} [unsettledLocalBalance] ChannelBalanceResponse unsettledLocalBalance
         * @property {lnrpc.IAmount|null} [unsettledRemoteBalance] ChannelBalanceResponse unsettledRemoteBalance
         * @property {lnrpc.IAmount|null} [pendingOpenLocalBalance] ChannelBalanceResponse pendingOpenLocalBalance
         * @property {lnrpc.IAmount|null} [pendingOpenRemoteBalance] ChannelBalanceResponse pendingOpenRemoteBalance
         */

        /**
         * Constructs a new ChannelBalanceResponse.
         * @memberof lnrpc
         * @classdesc Represents a ChannelBalanceResponse.
         * @implements IChannelBalanceResponse
         * @constructor
         * @param {lnrpc.IChannelBalanceResponse=} [properties] Properties to set
         */
        function ChannelBalanceResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelBalanceResponse balance.
         * @member {Long} balance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelBalanceResponse pendingOpenBalance.
         * @member {Long} pendingOpenBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.pendingOpenBalance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelBalanceResponse localBalance.
         * @member {lnrpc.IAmount|null|undefined} localBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.localBalance = null;

        /**
         * ChannelBalanceResponse remoteBalance.
         * @member {lnrpc.IAmount|null|undefined} remoteBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.remoteBalance = null;

        /**
         * ChannelBalanceResponse unsettledLocalBalance.
         * @member {lnrpc.IAmount|null|undefined} unsettledLocalBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.unsettledLocalBalance = null;

        /**
         * ChannelBalanceResponse unsettledRemoteBalance.
         * @member {lnrpc.IAmount|null|undefined} unsettledRemoteBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.unsettledRemoteBalance = null;

        /**
         * ChannelBalanceResponse pendingOpenLocalBalance.
         * @member {lnrpc.IAmount|null|undefined} pendingOpenLocalBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.pendingOpenLocalBalance = null;

        /**
         * ChannelBalanceResponse pendingOpenRemoteBalance.
         * @member {lnrpc.IAmount|null|undefined} pendingOpenRemoteBalance
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         */
        ChannelBalanceResponse.prototype.pendingOpenRemoteBalance = null;

        /**
         * Creates a new ChannelBalanceResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {lnrpc.IChannelBalanceResponse=} [properties] Properties to set
         * @returns {lnrpc.ChannelBalanceResponse} ChannelBalanceResponse instance
         */
        ChannelBalanceResponse.create = function create(properties) {
            return new ChannelBalanceResponse(properties);
        };

        /**
         * Encodes the specified ChannelBalanceResponse message. Does not implicitly {@link lnrpc.ChannelBalanceResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {lnrpc.IChannelBalanceResponse} message ChannelBalanceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBalanceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.balance);
            if (message.pendingOpenBalance != null && Object.hasOwnProperty.call(message, "pendingOpenBalance"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.pendingOpenBalance);
            if (message.localBalance != null && Object.hasOwnProperty.call(message, "localBalance"))
                $root.lnrpc.Amount.encode(message.localBalance, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.remoteBalance != null && Object.hasOwnProperty.call(message, "remoteBalance"))
                $root.lnrpc.Amount.encode(message.remoteBalance, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.unsettledLocalBalance != null && Object.hasOwnProperty.call(message, "unsettledLocalBalance"))
                $root.lnrpc.Amount.encode(message.unsettledLocalBalance, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.unsettledRemoteBalance != null && Object.hasOwnProperty.call(message, "unsettledRemoteBalance"))
                $root.lnrpc.Amount.encode(message.unsettledRemoteBalance, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.pendingOpenLocalBalance != null && Object.hasOwnProperty.call(message, "pendingOpenLocalBalance"))
                $root.lnrpc.Amount.encode(message.pendingOpenLocalBalance, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.pendingOpenRemoteBalance != null && Object.hasOwnProperty.call(message, "pendingOpenRemoteBalance"))
                $root.lnrpc.Amount.encode(message.pendingOpenRemoteBalance, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelBalanceResponse message, length delimited. Does not implicitly {@link lnrpc.ChannelBalanceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {lnrpc.IChannelBalanceResponse} message ChannelBalanceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBalanceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelBalanceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelBalanceResponse} ChannelBalanceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBalanceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelBalanceResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.balance = reader.int64();
                        break;
                    }
                case 2: {
                        message.pendingOpenBalance = reader.int64();
                        break;
                    }
                case 3: {
                        message.localBalance = $root.lnrpc.Amount.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.remoteBalance = $root.lnrpc.Amount.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.unsettledLocalBalance = $root.lnrpc.Amount.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.unsettledRemoteBalance = $root.lnrpc.Amount.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.pendingOpenLocalBalance = $root.lnrpc.Amount.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.pendingOpenRemoteBalance = $root.lnrpc.Amount.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelBalanceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelBalanceResponse} ChannelBalanceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBalanceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelBalanceResponse message.
         * @function verify
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelBalanceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                    return "balance: integer|Long expected";
            if (message.pendingOpenBalance != null && message.hasOwnProperty("pendingOpenBalance"))
                if (!$util.isInteger(message.pendingOpenBalance) && !(message.pendingOpenBalance && $util.isInteger(message.pendingOpenBalance.low) && $util.isInteger(message.pendingOpenBalance.high)))
                    return "pendingOpenBalance: integer|Long expected";
            if (message.localBalance != null && message.hasOwnProperty("localBalance")) {
                let error = $root.lnrpc.Amount.verify(message.localBalance);
                if (error)
                    return "localBalance." + error;
            }
            if (message.remoteBalance != null && message.hasOwnProperty("remoteBalance")) {
                let error = $root.lnrpc.Amount.verify(message.remoteBalance);
                if (error)
                    return "remoteBalance." + error;
            }
            if (message.unsettledLocalBalance != null && message.hasOwnProperty("unsettledLocalBalance")) {
                let error = $root.lnrpc.Amount.verify(message.unsettledLocalBalance);
                if (error)
                    return "unsettledLocalBalance." + error;
            }
            if (message.unsettledRemoteBalance != null && message.hasOwnProperty("unsettledRemoteBalance")) {
                let error = $root.lnrpc.Amount.verify(message.unsettledRemoteBalance);
                if (error)
                    return "unsettledRemoteBalance." + error;
            }
            if (message.pendingOpenLocalBalance != null && message.hasOwnProperty("pendingOpenLocalBalance")) {
                let error = $root.lnrpc.Amount.verify(message.pendingOpenLocalBalance);
                if (error)
                    return "pendingOpenLocalBalance." + error;
            }
            if (message.pendingOpenRemoteBalance != null && message.hasOwnProperty("pendingOpenRemoteBalance")) {
                let error = $root.lnrpc.Amount.verify(message.pendingOpenRemoteBalance);
                if (error)
                    return "pendingOpenRemoteBalance." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelBalanceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelBalanceResponse} ChannelBalanceResponse
         */
        ChannelBalanceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelBalanceResponse)
                return object;
            let message = new $root.lnrpc.ChannelBalanceResponse();
            if (object.balance != null)
                if ($util.Long)
                    (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                else if (typeof object.balance === "string")
                    message.balance = parseInt(object.balance, 10);
                else if (typeof object.balance === "number")
                    message.balance = object.balance;
                else if (typeof object.balance === "object")
                    message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
            if (object.pendingOpenBalance != null)
                if ($util.Long)
                    (message.pendingOpenBalance = $util.Long.fromValue(object.pendingOpenBalance)).unsigned = false;
                else if (typeof object.pendingOpenBalance === "string")
                    message.pendingOpenBalance = parseInt(object.pendingOpenBalance, 10);
                else if (typeof object.pendingOpenBalance === "number")
                    message.pendingOpenBalance = object.pendingOpenBalance;
                else if (typeof object.pendingOpenBalance === "object")
                    message.pendingOpenBalance = new $util.LongBits(object.pendingOpenBalance.low >>> 0, object.pendingOpenBalance.high >>> 0).toNumber();
            if (object.localBalance != null) {
                if (typeof object.localBalance !== "object")
                    throw TypeError(".lnrpc.ChannelBalanceResponse.localBalance: object expected");
                message.localBalance = $root.lnrpc.Amount.fromObject(object.localBalance);
            }
            if (object.remoteBalance != null) {
                if (typeof object.remoteBalance !== "object")
                    throw TypeError(".lnrpc.ChannelBalanceResponse.remoteBalance: object expected");
                message.remoteBalance = $root.lnrpc.Amount.fromObject(object.remoteBalance);
            }
            if (object.unsettledLocalBalance != null) {
                if (typeof object.unsettledLocalBalance !== "object")
                    throw TypeError(".lnrpc.ChannelBalanceResponse.unsettledLocalBalance: object expected");
                message.unsettledLocalBalance = $root.lnrpc.Amount.fromObject(object.unsettledLocalBalance);
            }
            if (object.unsettledRemoteBalance != null) {
                if (typeof object.unsettledRemoteBalance !== "object")
                    throw TypeError(".lnrpc.ChannelBalanceResponse.unsettledRemoteBalance: object expected");
                message.unsettledRemoteBalance = $root.lnrpc.Amount.fromObject(object.unsettledRemoteBalance);
            }
            if (object.pendingOpenLocalBalance != null) {
                if (typeof object.pendingOpenLocalBalance !== "object")
                    throw TypeError(".lnrpc.ChannelBalanceResponse.pendingOpenLocalBalance: object expected");
                message.pendingOpenLocalBalance = $root.lnrpc.Amount.fromObject(object.pendingOpenLocalBalance);
            }
            if (object.pendingOpenRemoteBalance != null) {
                if (typeof object.pendingOpenRemoteBalance !== "object")
                    throw TypeError(".lnrpc.ChannelBalanceResponse.pendingOpenRemoteBalance: object expected");
                message.pendingOpenRemoteBalance = $root.lnrpc.Amount.fromObject(object.pendingOpenRemoteBalance);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelBalanceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelBalanceResponse
         * @static
         * @param {lnrpc.ChannelBalanceResponse} message ChannelBalanceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelBalanceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.balance = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.pendingOpenBalance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.pendingOpenBalance = options.longs === String ? "0" : 0;
                object.localBalance = null;
                object.remoteBalance = null;
                object.unsettledLocalBalance = null;
                object.unsettledRemoteBalance = null;
                object.pendingOpenLocalBalance = null;
                object.pendingOpenRemoteBalance = null;
            }
            if (message.balance != null && message.hasOwnProperty("balance"))
                if (typeof message.balance === "number")
                    object.balance = options.longs === String ? String(message.balance) : message.balance;
                else
                    object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
            if (message.pendingOpenBalance != null && message.hasOwnProperty("pendingOpenBalance"))
                if (typeof message.pendingOpenBalance === "number")
                    object.pendingOpenBalance = options.longs === String ? String(message.pendingOpenBalance) : message.pendingOpenBalance;
                else
                    object.pendingOpenBalance = options.longs === String ? $util.Long.prototype.toString.call(message.pendingOpenBalance) : options.longs === Number ? new $util.LongBits(message.pendingOpenBalance.low >>> 0, message.pendingOpenBalance.high >>> 0).toNumber() : message.pendingOpenBalance;
            if (message.localBalance != null && message.hasOwnProperty("localBalance"))
                object.localBalance = $root.lnrpc.Amount.toObject(message.localBalance, options);
            if (message.remoteBalance != null && message.hasOwnProperty("remoteBalance"))
                object.remoteBalance = $root.lnrpc.Amount.toObject(message.remoteBalance, options);
            if (message.unsettledLocalBalance != null && message.hasOwnProperty("unsettledLocalBalance"))
                object.unsettledLocalBalance = $root.lnrpc.Amount.toObject(message.unsettledLocalBalance, options);
            if (message.unsettledRemoteBalance != null && message.hasOwnProperty("unsettledRemoteBalance"))
                object.unsettledRemoteBalance = $root.lnrpc.Amount.toObject(message.unsettledRemoteBalance, options);
            if (message.pendingOpenLocalBalance != null && message.hasOwnProperty("pendingOpenLocalBalance"))
                object.pendingOpenLocalBalance = $root.lnrpc.Amount.toObject(message.pendingOpenLocalBalance, options);
            if (message.pendingOpenRemoteBalance != null && message.hasOwnProperty("pendingOpenRemoteBalance"))
                object.pendingOpenRemoteBalance = $root.lnrpc.Amount.toObject(message.pendingOpenRemoteBalance, options);
            return object;
        };

        /**
         * Converts this ChannelBalanceResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelBalanceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelBalanceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelBalanceResponse;
    })();

    lnrpc.QueryRoutesRequest = (function() {

        /**
         * Properties of a QueryRoutesRequest.
         * @memberof lnrpc
         * @interface IQueryRoutesRequest
         * @property {string|null} [pubKey] QueryRoutesRequest pubKey
         * @property {Long|null} [amt] QueryRoutesRequest amt
         * @property {Long|null} [amtMsat] QueryRoutesRequest amtMsat
         * @property {number|null} [finalCltvDelta] QueryRoutesRequest finalCltvDelta
         * @property {lnrpc.IFeeLimit|null} [feeLimit] QueryRoutesRequest feeLimit
         * @property {Array.<Uint8Array>|null} [ignoredNodes] QueryRoutesRequest ignoredNodes
         * @property {Array.<lnrpc.IEdgeLocator>|null} [ignoredEdges] QueryRoutesRequest ignoredEdges
         * @property {string|null} [sourcePubKey] QueryRoutesRequest sourcePubKey
         * @property {boolean|null} [useMissionControl] QueryRoutesRequest useMissionControl
         * @property {Array.<lnrpc.INodePair>|null} [ignoredPairs] QueryRoutesRequest ignoredPairs
         * @property {number|null} [cltvLimit] QueryRoutesRequest cltvLimit
         * @property {Object.<string,Uint8Array>|null} [destCustomRecords] QueryRoutesRequest destCustomRecords
         * @property {Long|null} [outgoingChanId] QueryRoutesRequest outgoingChanId
         * @property {Uint8Array|null} [lastHopPubkey] QueryRoutesRequest lastHopPubkey
         * @property {Array.<lnrpc.IRouteHint>|null} [routeHints] QueryRoutesRequest routeHints
         * @property {Array.<lnrpc.FeatureBit>|null} [destFeatures] QueryRoutesRequest destFeatures
         * @property {number|null} [timePref] QueryRoutesRequest timePref
         */

        /**
         * Constructs a new QueryRoutesRequest.
         * @memberof lnrpc
         * @classdesc Represents a QueryRoutesRequest.
         * @implements IQueryRoutesRequest
         * @constructor
         * @param {lnrpc.IQueryRoutesRequest=} [properties] Properties to set
         */
        function QueryRoutesRequest(properties) {
            this.ignoredNodes = [];
            this.ignoredEdges = [];
            this.ignoredPairs = [];
            this.destCustomRecords = {};
            this.routeHints = [];
            this.destFeatures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryRoutesRequest pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.pubKey = "";

        /**
         * QueryRoutesRequest amt.
         * @member {Long} amt
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.amt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QueryRoutesRequest amtMsat.
         * @member {Long} amtMsat
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.amtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * QueryRoutesRequest finalCltvDelta.
         * @member {number} finalCltvDelta
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.finalCltvDelta = 0;

        /**
         * QueryRoutesRequest feeLimit.
         * @member {lnrpc.IFeeLimit|null|undefined} feeLimit
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.feeLimit = null;

        /**
         * QueryRoutesRequest ignoredNodes.
         * @member {Array.<Uint8Array>} ignoredNodes
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.ignoredNodes = $util.emptyArray;

        /**
         * QueryRoutesRequest ignoredEdges.
         * @member {Array.<lnrpc.IEdgeLocator>} ignoredEdges
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.ignoredEdges = $util.emptyArray;

        /**
         * QueryRoutesRequest sourcePubKey.
         * @member {string} sourcePubKey
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.sourcePubKey = "";

        /**
         * QueryRoutesRequest useMissionControl.
         * @member {boolean} useMissionControl
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.useMissionControl = false;

        /**
         * QueryRoutesRequest ignoredPairs.
         * @member {Array.<lnrpc.INodePair>} ignoredPairs
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.ignoredPairs = $util.emptyArray;

        /**
         * QueryRoutesRequest cltvLimit.
         * @member {number} cltvLimit
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.cltvLimit = 0;

        /**
         * QueryRoutesRequest destCustomRecords.
         * @member {Object.<string,Uint8Array>} destCustomRecords
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.destCustomRecords = $util.emptyObject;

        /**
         * QueryRoutesRequest outgoingChanId.
         * @member {Long} outgoingChanId
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.outgoingChanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * QueryRoutesRequest lastHopPubkey.
         * @member {Uint8Array} lastHopPubkey
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.lastHopPubkey = $util.newBuffer([]);

        /**
         * QueryRoutesRequest routeHints.
         * @member {Array.<lnrpc.IRouteHint>} routeHints
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.routeHints = $util.emptyArray;

        /**
         * QueryRoutesRequest destFeatures.
         * @member {Array.<lnrpc.FeatureBit>} destFeatures
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.destFeatures = $util.emptyArray;

        /**
         * QueryRoutesRequest timePref.
         * @member {number} timePref
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         */
        QueryRoutesRequest.prototype.timePref = 0;

        /**
         * Creates a new QueryRoutesRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {lnrpc.IQueryRoutesRequest=} [properties] Properties to set
         * @returns {lnrpc.QueryRoutesRequest} QueryRoutesRequest instance
         */
        QueryRoutesRequest.create = function create(properties) {
            return new QueryRoutesRequest(properties);
        };

        /**
         * Encodes the specified QueryRoutesRequest message. Does not implicitly {@link lnrpc.QueryRoutesRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {lnrpc.IQueryRoutesRequest} message QueryRoutesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRoutesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubKey);
            if (message.amt != null && Object.hasOwnProperty.call(message, "amt"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amt);
            if (message.finalCltvDelta != null && Object.hasOwnProperty.call(message, "finalCltvDelta"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.finalCltvDelta);
            if (message.feeLimit != null && Object.hasOwnProperty.call(message, "feeLimit"))
                $root.lnrpc.FeeLimit.encode(message.feeLimit, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.ignoredNodes != null && message.ignoredNodes.length)
                for (let i = 0; i < message.ignoredNodes.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.ignoredNodes[i]);
            if (message.ignoredEdges != null && message.ignoredEdges.length)
                for (let i = 0; i < message.ignoredEdges.length; ++i)
                    $root.lnrpc.EdgeLocator.encode(message.ignoredEdges[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.sourcePubKey != null && Object.hasOwnProperty.call(message, "sourcePubKey"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.sourcePubKey);
            if (message.useMissionControl != null && Object.hasOwnProperty.call(message, "useMissionControl"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.useMissionControl);
            if (message.ignoredPairs != null && message.ignoredPairs.length)
                for (let i = 0; i < message.ignoredPairs.length; ++i)
                    $root.lnrpc.NodePair.encode(message.ignoredPairs[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.cltvLimit != null && Object.hasOwnProperty.call(message, "cltvLimit"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.cltvLimit);
            if (message.amtMsat != null && Object.hasOwnProperty.call(message, "amtMsat"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.amtMsat);
            if (message.destCustomRecords != null && Object.hasOwnProperty.call(message, "destCustomRecords"))
                for (let keys = Object.keys(message.destCustomRecords), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 13, wireType 2 =*/106).fork().uint32(/* id 1, wireType 0 =*/8).uint64(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.destCustomRecords[keys[i]]).ldelim();
            if (message.outgoingChanId != null && Object.hasOwnProperty.call(message, "outgoingChanId"))
                writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.outgoingChanId);
            if (message.lastHopPubkey != null && Object.hasOwnProperty.call(message, "lastHopPubkey"))
                writer.uint32(/* id 15, wireType 2 =*/122).bytes(message.lastHopPubkey);
            if (message.routeHints != null && message.routeHints.length)
                for (let i = 0; i < message.routeHints.length; ++i)
                    $root.lnrpc.RouteHint.encode(message.routeHints[i], writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.destFeatures != null && message.destFeatures.length) {
                writer.uint32(/* id 17, wireType 2 =*/138).fork();
                for (let i = 0; i < message.destFeatures.length; ++i)
                    writer.int32(message.destFeatures[i]);
                writer.ldelim();
            }
            if (message.timePref != null && Object.hasOwnProperty.call(message, "timePref"))
                writer.uint32(/* id 18, wireType 1 =*/145).double(message.timePref);
            return writer;
        };

        /**
         * Encodes the specified QueryRoutesRequest message, length delimited. Does not implicitly {@link lnrpc.QueryRoutesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {lnrpc.IQueryRoutesRequest} message QueryRoutesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRoutesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryRoutesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.QueryRoutesRequest} QueryRoutesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRoutesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.QueryRoutesRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubKey = reader.string();
                        break;
                    }
                case 2: {
                        message.amt = reader.int64();
                        break;
                    }
                case 12: {
                        message.amtMsat = reader.int64();
                        break;
                    }
                case 4: {
                        message.finalCltvDelta = reader.int32();
                        break;
                    }
                case 5: {
                        message.feeLimit = $root.lnrpc.FeeLimit.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        if (!(message.ignoredNodes && message.ignoredNodes.length))
                            message.ignoredNodes = [];
                        message.ignoredNodes.push(reader.bytes());
                        break;
                    }
                case 7: {
                        if (!(message.ignoredEdges && message.ignoredEdges.length))
                            message.ignoredEdges = [];
                        message.ignoredEdges.push($root.lnrpc.EdgeLocator.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.sourcePubKey = reader.string();
                        break;
                    }
                case 9: {
                        message.useMissionControl = reader.bool();
                        break;
                    }
                case 10: {
                        if (!(message.ignoredPairs && message.ignoredPairs.length))
                            message.ignoredPairs = [];
                        message.ignoredPairs.push($root.lnrpc.NodePair.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        message.cltvLimit = reader.uint32();
                        break;
                    }
                case 13: {
                        if (message.destCustomRecords === $util.emptyObject)
                            message.destCustomRecords = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint64();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.destCustomRecords[typeof key === "object" ? $util.longToHash(key) : key] = value;
                        break;
                    }
                case 14: {
                        message.outgoingChanId = reader.uint64();
                        break;
                    }
                case 15: {
                        message.lastHopPubkey = reader.bytes();
                        break;
                    }
                case 16: {
                        if (!(message.routeHints && message.routeHints.length))
                            message.routeHints = [];
                        message.routeHints.push($root.lnrpc.RouteHint.decode(reader, reader.uint32()));
                        break;
                    }
                case 17: {
                        if (!(message.destFeatures && message.destFeatures.length))
                            message.destFeatures = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.destFeatures.push(reader.int32());
                        } else
                            message.destFeatures.push(reader.int32());
                        break;
                    }
                case 18: {
                        message.timePref = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryRoutesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.QueryRoutesRequest} QueryRoutesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRoutesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryRoutesRequest message.
         * @function verify
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryRoutesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (!$util.isInteger(message.amt) && !(message.amt && $util.isInteger(message.amt.low) && $util.isInteger(message.amt.high)))
                    return "amt: integer|Long expected";
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (!$util.isInteger(message.amtMsat) && !(message.amtMsat && $util.isInteger(message.amtMsat.low) && $util.isInteger(message.amtMsat.high)))
                    return "amtMsat: integer|Long expected";
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                if (!$util.isInteger(message.finalCltvDelta))
                    return "finalCltvDelta: integer expected";
            if (message.feeLimit != null && message.hasOwnProperty("feeLimit")) {
                let error = $root.lnrpc.FeeLimit.verify(message.feeLimit);
                if (error)
                    return "feeLimit." + error;
            }
            if (message.ignoredNodes != null && message.hasOwnProperty("ignoredNodes")) {
                if (!Array.isArray(message.ignoredNodes))
                    return "ignoredNodes: array expected";
                for (let i = 0; i < message.ignoredNodes.length; ++i)
                    if (!(message.ignoredNodes[i] && typeof message.ignoredNodes[i].length === "number" || $util.isString(message.ignoredNodes[i])))
                        return "ignoredNodes: buffer[] expected";
            }
            if (message.ignoredEdges != null && message.hasOwnProperty("ignoredEdges")) {
                if (!Array.isArray(message.ignoredEdges))
                    return "ignoredEdges: array expected";
                for (let i = 0; i < message.ignoredEdges.length; ++i) {
                    let error = $root.lnrpc.EdgeLocator.verify(message.ignoredEdges[i]);
                    if (error)
                        return "ignoredEdges." + error;
                }
            }
            if (message.sourcePubKey != null && message.hasOwnProperty("sourcePubKey"))
                if (!$util.isString(message.sourcePubKey))
                    return "sourcePubKey: string expected";
            if (message.useMissionControl != null && message.hasOwnProperty("useMissionControl"))
                if (typeof message.useMissionControl !== "boolean")
                    return "useMissionControl: boolean expected";
            if (message.ignoredPairs != null && message.hasOwnProperty("ignoredPairs")) {
                if (!Array.isArray(message.ignoredPairs))
                    return "ignoredPairs: array expected";
                for (let i = 0; i < message.ignoredPairs.length; ++i) {
                    let error = $root.lnrpc.NodePair.verify(message.ignoredPairs[i]);
                    if (error)
                        return "ignoredPairs." + error;
                }
            }
            if (message.cltvLimit != null && message.hasOwnProperty("cltvLimit"))
                if (!$util.isInteger(message.cltvLimit))
                    return "cltvLimit: integer expected";
            if (message.destCustomRecords != null && message.hasOwnProperty("destCustomRecords")) {
                if (!$util.isObject(message.destCustomRecords))
                    return "destCustomRecords: object expected";
                let key = Object.keys(message.destCustomRecords);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key64Re.test(key[i]))
                        return "destCustomRecords: integer|Long key{k:uint64} expected";
                    if (!(message.destCustomRecords[key[i]] && typeof message.destCustomRecords[key[i]].length === "number" || $util.isString(message.destCustomRecords[key[i]])))
                        return "destCustomRecords: buffer{k:uint64} expected";
                }
            }
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (!$util.isInteger(message.outgoingChanId) && !(message.outgoingChanId && $util.isInteger(message.outgoingChanId.low) && $util.isInteger(message.outgoingChanId.high)))
                    return "outgoingChanId: integer|Long expected";
            if (message.lastHopPubkey != null && message.hasOwnProperty("lastHopPubkey"))
                if (!(message.lastHopPubkey && typeof message.lastHopPubkey.length === "number" || $util.isString(message.lastHopPubkey)))
                    return "lastHopPubkey: buffer expected";
            if (message.routeHints != null && message.hasOwnProperty("routeHints")) {
                if (!Array.isArray(message.routeHints))
                    return "routeHints: array expected";
                for (let i = 0; i < message.routeHints.length; ++i) {
                    let error = $root.lnrpc.RouteHint.verify(message.routeHints[i]);
                    if (error)
                        return "routeHints." + error;
                }
            }
            if (message.destFeatures != null && message.hasOwnProperty("destFeatures")) {
                if (!Array.isArray(message.destFeatures))
                    return "destFeatures: array expected";
                for (let i = 0; i < message.destFeatures.length; ++i)
                    switch (message.destFeatures[i]) {
                    default:
                        return "destFeatures: enum value[] expected";
                    case 0:
                    case 1:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 30:
                    case 31:
                        break;
                    }
            }
            if (message.timePref != null && message.hasOwnProperty("timePref"))
                if (typeof message.timePref !== "number")
                    return "timePref: number expected";
            return null;
        };

        /**
         * Creates a QueryRoutesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.QueryRoutesRequest} QueryRoutesRequest
         */
        QueryRoutesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.QueryRoutesRequest)
                return object;
            let message = new $root.lnrpc.QueryRoutesRequest();
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            if (object.amt != null)
                if ($util.Long)
                    (message.amt = $util.Long.fromValue(object.amt)).unsigned = false;
                else if (typeof object.amt === "string")
                    message.amt = parseInt(object.amt, 10);
                else if (typeof object.amt === "number")
                    message.amt = object.amt;
                else if (typeof object.amt === "object")
                    message.amt = new $util.LongBits(object.amt.low >>> 0, object.amt.high >>> 0).toNumber();
            if (object.amtMsat != null)
                if ($util.Long)
                    (message.amtMsat = $util.Long.fromValue(object.amtMsat)).unsigned = false;
                else if (typeof object.amtMsat === "string")
                    message.amtMsat = parseInt(object.amtMsat, 10);
                else if (typeof object.amtMsat === "number")
                    message.amtMsat = object.amtMsat;
                else if (typeof object.amtMsat === "object")
                    message.amtMsat = new $util.LongBits(object.amtMsat.low >>> 0, object.amtMsat.high >>> 0).toNumber();
            if (object.finalCltvDelta != null)
                message.finalCltvDelta = object.finalCltvDelta | 0;
            if (object.feeLimit != null) {
                if (typeof object.feeLimit !== "object")
                    throw TypeError(".lnrpc.QueryRoutesRequest.feeLimit: object expected");
                message.feeLimit = $root.lnrpc.FeeLimit.fromObject(object.feeLimit);
            }
            if (object.ignoredNodes) {
                if (!Array.isArray(object.ignoredNodes))
                    throw TypeError(".lnrpc.QueryRoutesRequest.ignoredNodes: array expected");
                message.ignoredNodes = [];
                for (let i = 0; i < object.ignoredNodes.length; ++i)
                    if (typeof object.ignoredNodes[i] === "string")
                        $util.base64.decode(object.ignoredNodes[i], message.ignoredNodes[i] = $util.newBuffer($util.base64.length(object.ignoredNodes[i])), 0);
                    else if (object.ignoredNodes[i].length)
                        message.ignoredNodes[i] = object.ignoredNodes[i];
            }
            if (object.ignoredEdges) {
                if (!Array.isArray(object.ignoredEdges))
                    throw TypeError(".lnrpc.QueryRoutesRequest.ignoredEdges: array expected");
                message.ignoredEdges = [];
                for (let i = 0; i < object.ignoredEdges.length; ++i) {
                    if (typeof object.ignoredEdges[i] !== "object")
                        throw TypeError(".lnrpc.QueryRoutesRequest.ignoredEdges: object expected");
                    message.ignoredEdges[i] = $root.lnrpc.EdgeLocator.fromObject(object.ignoredEdges[i]);
                }
            }
            if (object.sourcePubKey != null)
                message.sourcePubKey = String(object.sourcePubKey);
            if (object.useMissionControl != null)
                message.useMissionControl = Boolean(object.useMissionControl);
            if (object.ignoredPairs) {
                if (!Array.isArray(object.ignoredPairs))
                    throw TypeError(".lnrpc.QueryRoutesRequest.ignoredPairs: array expected");
                message.ignoredPairs = [];
                for (let i = 0; i < object.ignoredPairs.length; ++i) {
                    if (typeof object.ignoredPairs[i] !== "object")
                        throw TypeError(".lnrpc.QueryRoutesRequest.ignoredPairs: object expected");
                    message.ignoredPairs[i] = $root.lnrpc.NodePair.fromObject(object.ignoredPairs[i]);
                }
            }
            if (object.cltvLimit != null)
                message.cltvLimit = object.cltvLimit >>> 0;
            if (object.destCustomRecords) {
                if (typeof object.destCustomRecords !== "object")
                    throw TypeError(".lnrpc.QueryRoutesRequest.destCustomRecords: object expected");
                message.destCustomRecords = {};
                for (let keys = Object.keys(object.destCustomRecords), i = 0; i < keys.length; ++i)
                    if (typeof object.destCustomRecords[keys[i]] === "string")
                        $util.base64.decode(object.destCustomRecords[keys[i]], message.destCustomRecords[keys[i]] = $util.newBuffer($util.base64.length(object.destCustomRecords[keys[i]])), 0);
                    else if (object.destCustomRecords[keys[i]].length)
                        message.destCustomRecords[keys[i]] = object.destCustomRecords[keys[i]];
            }
            if (object.outgoingChanId != null)
                if ($util.Long)
                    (message.outgoingChanId = $util.Long.fromValue(object.outgoingChanId)).unsigned = true;
                else if (typeof object.outgoingChanId === "string")
                    message.outgoingChanId = parseInt(object.outgoingChanId, 10);
                else if (typeof object.outgoingChanId === "number")
                    message.outgoingChanId = object.outgoingChanId;
                else if (typeof object.outgoingChanId === "object")
                    message.outgoingChanId = new $util.LongBits(object.outgoingChanId.low >>> 0, object.outgoingChanId.high >>> 0).toNumber(true);
            if (object.lastHopPubkey != null)
                if (typeof object.lastHopPubkey === "string")
                    $util.base64.decode(object.lastHopPubkey, message.lastHopPubkey = $util.newBuffer($util.base64.length(object.lastHopPubkey)), 0);
                else if (object.lastHopPubkey.length)
                    message.lastHopPubkey = object.lastHopPubkey;
            if (object.routeHints) {
                if (!Array.isArray(object.routeHints))
                    throw TypeError(".lnrpc.QueryRoutesRequest.routeHints: array expected");
                message.routeHints = [];
                for (let i = 0; i < object.routeHints.length; ++i) {
                    if (typeof object.routeHints[i] !== "object")
                        throw TypeError(".lnrpc.QueryRoutesRequest.routeHints: object expected");
                    message.routeHints[i] = $root.lnrpc.RouteHint.fromObject(object.routeHints[i]);
                }
            }
            if (object.destFeatures) {
                if (!Array.isArray(object.destFeatures))
                    throw TypeError(".lnrpc.QueryRoutesRequest.destFeatures: array expected");
                message.destFeatures = [];
                for (let i = 0; i < object.destFeatures.length; ++i)
                    switch (object.destFeatures[i]) {
                    default:
                    case "DATALOSS_PROTECT_REQ":
                    case 0:
                        message.destFeatures[i] = 0;
                        break;
                    case "DATALOSS_PROTECT_OPT":
                    case 1:
                        message.destFeatures[i] = 1;
                        break;
                    case "INITIAL_ROUING_SYNC":
                    case 3:
                        message.destFeatures[i] = 3;
                        break;
                    case "UPFRONT_SHUTDOWN_SCRIPT_REQ":
                    case 4:
                        message.destFeatures[i] = 4;
                        break;
                    case "UPFRONT_SHUTDOWN_SCRIPT_OPT":
                    case 5:
                        message.destFeatures[i] = 5;
                        break;
                    case "GOSSIP_QUERIES_REQ":
                    case 6:
                        message.destFeatures[i] = 6;
                        break;
                    case "GOSSIP_QUERIES_OPT":
                    case 7:
                        message.destFeatures[i] = 7;
                        break;
                    case "TLV_ONION_REQ":
                    case 8:
                        message.destFeatures[i] = 8;
                        break;
                    case "TLV_ONION_OPT":
                    case 9:
                        message.destFeatures[i] = 9;
                        break;
                    case "EXT_GOSSIP_QUERIES_REQ":
                    case 10:
                        message.destFeatures[i] = 10;
                        break;
                    case "EXT_GOSSIP_QUERIES_OPT":
                    case 11:
                        message.destFeatures[i] = 11;
                        break;
                    case "STATIC_REMOTE_KEY_REQ":
                    case 12:
                        message.destFeatures[i] = 12;
                        break;
                    case "STATIC_REMOTE_KEY_OPT":
                    case 13:
                        message.destFeatures[i] = 13;
                        break;
                    case "PAYMENT_ADDR_REQ":
                    case 14:
                        message.destFeatures[i] = 14;
                        break;
                    case "PAYMENT_ADDR_OPT":
                    case 15:
                        message.destFeatures[i] = 15;
                        break;
                    case "MPP_REQ":
                    case 16:
                        message.destFeatures[i] = 16;
                        break;
                    case "MPP_OPT":
                    case 17:
                        message.destFeatures[i] = 17;
                        break;
                    case "WUMBO_CHANNELS_REQ":
                    case 18:
                        message.destFeatures[i] = 18;
                        break;
                    case "WUMBO_CHANNELS_OPT":
                    case 19:
                        message.destFeatures[i] = 19;
                        break;
                    case "ANCHORS_REQ":
                    case 20:
                        message.destFeatures[i] = 20;
                        break;
                    case "ANCHORS_OPT":
                    case 21:
                        message.destFeatures[i] = 21;
                        break;
                    case "ANCHORS_ZERO_FEE_HTLC_REQ":
                    case 22:
                        message.destFeatures[i] = 22;
                        break;
                    case "ANCHORS_ZERO_FEE_HTLC_OPT":
                    case 23:
                        message.destFeatures[i] = 23;
                        break;
                    case "AMP_REQ":
                    case 30:
                        message.destFeatures[i] = 30;
                        break;
                    case "AMP_OPT":
                    case 31:
                        message.destFeatures[i] = 31;
                        break;
                    }
            }
            if (object.timePref != null)
                message.timePref = Number(object.timePref);
            return message;
        };

        /**
         * Creates a plain object from a QueryRoutesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.QueryRoutesRequest
         * @static
         * @param {lnrpc.QueryRoutesRequest} message QueryRoutesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryRoutesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.ignoredNodes = [];
                object.ignoredEdges = [];
                object.ignoredPairs = [];
                object.routeHints = [];
                object.destFeatures = [];
            }
            if (options.objects || options.defaults)
                object.destCustomRecords = {};
            if (options.defaults) {
                object.pubKey = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amt = options.longs === String ? "0" : 0;
                object.finalCltvDelta = 0;
                object.feeLimit = null;
                object.sourcePubKey = "";
                object.useMissionControl = false;
                object.cltvLimit = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingChanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingChanId = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.lastHopPubkey = "";
                else {
                    object.lastHopPubkey = [];
                    if (options.bytes !== Array)
                        object.lastHopPubkey = $util.newBuffer(object.lastHopPubkey);
                }
                object.timePref = 0;
            }
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (typeof message.amt === "number")
                    object.amt = options.longs === String ? String(message.amt) : message.amt;
                else
                    object.amt = options.longs === String ? $util.Long.prototype.toString.call(message.amt) : options.longs === Number ? new $util.LongBits(message.amt.low >>> 0, message.amt.high >>> 0).toNumber() : message.amt;
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                object.finalCltvDelta = message.finalCltvDelta;
            if (message.feeLimit != null && message.hasOwnProperty("feeLimit"))
                object.feeLimit = $root.lnrpc.FeeLimit.toObject(message.feeLimit, options);
            if (message.ignoredNodes && message.ignoredNodes.length) {
                object.ignoredNodes = [];
                for (let j = 0; j < message.ignoredNodes.length; ++j)
                    object.ignoredNodes[j] = options.bytes === String ? $util.base64.encode(message.ignoredNodes[j], 0, message.ignoredNodes[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.ignoredNodes[j]) : message.ignoredNodes[j];
            }
            if (message.ignoredEdges && message.ignoredEdges.length) {
                object.ignoredEdges = [];
                for (let j = 0; j < message.ignoredEdges.length; ++j)
                    object.ignoredEdges[j] = $root.lnrpc.EdgeLocator.toObject(message.ignoredEdges[j], options);
            }
            if (message.sourcePubKey != null && message.hasOwnProperty("sourcePubKey"))
                object.sourcePubKey = message.sourcePubKey;
            if (message.useMissionControl != null && message.hasOwnProperty("useMissionControl"))
                object.useMissionControl = message.useMissionControl;
            if (message.ignoredPairs && message.ignoredPairs.length) {
                object.ignoredPairs = [];
                for (let j = 0; j < message.ignoredPairs.length; ++j)
                    object.ignoredPairs[j] = $root.lnrpc.NodePair.toObject(message.ignoredPairs[j], options);
            }
            if (message.cltvLimit != null && message.hasOwnProperty("cltvLimit"))
                object.cltvLimit = message.cltvLimit;
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (typeof message.amtMsat === "number")
                    object.amtMsat = options.longs === String ? String(message.amtMsat) : message.amtMsat;
                else
                    object.amtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtMsat) : options.longs === Number ? new $util.LongBits(message.amtMsat.low >>> 0, message.amtMsat.high >>> 0).toNumber() : message.amtMsat;
            let keys2;
            if (message.destCustomRecords && (keys2 = Object.keys(message.destCustomRecords)).length) {
                object.destCustomRecords = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.destCustomRecords[keys2[j]] = options.bytes === String ? $util.base64.encode(message.destCustomRecords[keys2[j]], 0, message.destCustomRecords[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.destCustomRecords[keys2[j]]) : message.destCustomRecords[keys2[j]];
            }
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (typeof message.outgoingChanId === "number")
                    object.outgoingChanId = options.longs === String ? String(message.outgoingChanId) : message.outgoingChanId;
                else
                    object.outgoingChanId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingChanId) : options.longs === Number ? new $util.LongBits(message.outgoingChanId.low >>> 0, message.outgoingChanId.high >>> 0).toNumber(true) : message.outgoingChanId;
            if (message.lastHopPubkey != null && message.hasOwnProperty("lastHopPubkey"))
                object.lastHopPubkey = options.bytes === String ? $util.base64.encode(message.lastHopPubkey, 0, message.lastHopPubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.lastHopPubkey) : message.lastHopPubkey;
            if (message.routeHints && message.routeHints.length) {
                object.routeHints = [];
                for (let j = 0; j < message.routeHints.length; ++j)
                    object.routeHints[j] = $root.lnrpc.RouteHint.toObject(message.routeHints[j], options);
            }
            if (message.destFeatures && message.destFeatures.length) {
                object.destFeatures = [];
                for (let j = 0; j < message.destFeatures.length; ++j)
                    object.destFeatures[j] = options.enums === String ? $root.lnrpc.FeatureBit[message.destFeatures[j]] : message.destFeatures[j];
            }
            if (message.timePref != null && message.hasOwnProperty("timePref"))
                object.timePref = options.json && !isFinite(message.timePref) ? String(message.timePref) : message.timePref;
            return object;
        };

        /**
         * Converts this QueryRoutesRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.QueryRoutesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryRoutesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryRoutesRequest;
    })();

    lnrpc.NodePair = (function() {

        /**
         * Properties of a NodePair.
         * @memberof lnrpc
         * @interface INodePair
         * @property {Uint8Array|null} [from] NodePair from
         * @property {Uint8Array|null} [to] NodePair to
         */

        /**
         * Constructs a new NodePair.
         * @memberof lnrpc
         * @classdesc Represents a NodePair.
         * @implements INodePair
         * @constructor
         * @param {lnrpc.INodePair=} [properties] Properties to set
         */
        function NodePair(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodePair from.
         * @member {Uint8Array} from
         * @memberof lnrpc.NodePair
         * @instance
         */
        NodePair.prototype.from = $util.newBuffer([]);

        /**
         * NodePair to.
         * @member {Uint8Array} to
         * @memberof lnrpc.NodePair
         * @instance
         */
        NodePair.prototype.to = $util.newBuffer([]);

        /**
         * Creates a new NodePair instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodePair
         * @static
         * @param {lnrpc.INodePair=} [properties] Properties to set
         * @returns {lnrpc.NodePair} NodePair instance
         */
        NodePair.create = function create(properties) {
            return new NodePair(properties);
        };

        /**
         * Encodes the specified NodePair message. Does not implicitly {@link lnrpc.NodePair.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodePair
         * @static
         * @param {lnrpc.INodePair} message NodePair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodePair.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.from);
            if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.to);
            return writer;
        };

        /**
         * Encodes the specified NodePair message, length delimited. Does not implicitly {@link lnrpc.NodePair.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodePair
         * @static
         * @param {lnrpc.INodePair} message NodePair message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodePair.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodePair message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodePair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodePair} NodePair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodePair.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodePair();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.from = reader.bytes();
                        break;
                    }
                case 2: {
                        message.to = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodePair message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodePair
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodePair} NodePair
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodePair.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodePair message.
         * @function verify
         * @memberof lnrpc.NodePair
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodePair.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.from != null && message.hasOwnProperty("from"))
                if (!(message.from && typeof message.from.length === "number" || $util.isString(message.from)))
                    return "from: buffer expected";
            if (message.to != null && message.hasOwnProperty("to"))
                if (!(message.to && typeof message.to.length === "number" || $util.isString(message.to)))
                    return "to: buffer expected";
            return null;
        };

        /**
         * Creates a NodePair message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodePair
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodePair} NodePair
         */
        NodePair.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodePair)
                return object;
            let message = new $root.lnrpc.NodePair();
            if (object.from != null)
                if (typeof object.from === "string")
                    $util.base64.decode(object.from, message.from = $util.newBuffer($util.base64.length(object.from)), 0);
                else if (object.from.length)
                    message.from = object.from;
            if (object.to != null)
                if (typeof object.to === "string")
                    $util.base64.decode(object.to, message.to = $util.newBuffer($util.base64.length(object.to)), 0);
                else if (object.to.length)
                    message.to = object.to;
            return message;
        };

        /**
         * Creates a plain object from a NodePair message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodePair
         * @static
         * @param {lnrpc.NodePair} message NodePair
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodePair.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.from = "";
                else {
                    object.from = [];
                    if (options.bytes !== Array)
                        object.from = $util.newBuffer(object.from);
                }
                if (options.bytes === String)
                    object.to = "";
                else {
                    object.to = [];
                    if (options.bytes !== Array)
                        object.to = $util.newBuffer(object.to);
                }
            }
            if (message.from != null && message.hasOwnProperty("from"))
                object.from = options.bytes === String ? $util.base64.encode(message.from, 0, message.from.length) : options.bytes === Array ? Array.prototype.slice.call(message.from) : message.from;
            if (message.to != null && message.hasOwnProperty("to"))
                object.to = options.bytes === String ? $util.base64.encode(message.to, 0, message.to.length) : options.bytes === Array ? Array.prototype.slice.call(message.to) : message.to;
            return object;
        };

        /**
         * Converts this NodePair to JSON.
         * @function toJSON
         * @memberof lnrpc.NodePair
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodePair.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodePair;
    })();

    lnrpc.EdgeLocator = (function() {

        /**
         * Properties of an EdgeLocator.
         * @memberof lnrpc
         * @interface IEdgeLocator
         * @property {Long|null} [channelId] EdgeLocator channelId
         * @property {boolean|null} [directionReverse] EdgeLocator directionReverse
         */

        /**
         * Constructs a new EdgeLocator.
         * @memberof lnrpc
         * @classdesc Represents an EdgeLocator.
         * @implements IEdgeLocator
         * @constructor
         * @param {lnrpc.IEdgeLocator=} [properties] Properties to set
         */
        function EdgeLocator(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EdgeLocator channelId.
         * @member {Long} channelId
         * @memberof lnrpc.EdgeLocator
         * @instance
         */
        EdgeLocator.prototype.channelId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * EdgeLocator directionReverse.
         * @member {boolean} directionReverse
         * @memberof lnrpc.EdgeLocator
         * @instance
         */
        EdgeLocator.prototype.directionReverse = false;

        /**
         * Creates a new EdgeLocator instance using the specified properties.
         * @function create
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {lnrpc.IEdgeLocator=} [properties] Properties to set
         * @returns {lnrpc.EdgeLocator} EdgeLocator instance
         */
        EdgeLocator.create = function create(properties) {
            return new EdgeLocator(properties);
        };

        /**
         * Encodes the specified EdgeLocator message. Does not implicitly {@link lnrpc.EdgeLocator.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {lnrpc.IEdgeLocator} message EdgeLocator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EdgeLocator.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelId != null && Object.hasOwnProperty.call(message, "channelId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.channelId);
            if (message.directionReverse != null && Object.hasOwnProperty.call(message, "directionReverse"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.directionReverse);
            return writer;
        };

        /**
         * Encodes the specified EdgeLocator message, length delimited. Does not implicitly {@link lnrpc.EdgeLocator.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {lnrpc.IEdgeLocator} message EdgeLocator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EdgeLocator.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EdgeLocator message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.EdgeLocator} EdgeLocator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EdgeLocator.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.EdgeLocator();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.directionReverse = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EdgeLocator message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.EdgeLocator} EdgeLocator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EdgeLocator.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EdgeLocator message.
         * @function verify
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EdgeLocator.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (!$util.isInteger(message.channelId) && !(message.channelId && $util.isInteger(message.channelId.low) && $util.isInteger(message.channelId.high)))
                    return "channelId: integer|Long expected";
            if (message.directionReverse != null && message.hasOwnProperty("directionReverse"))
                if (typeof message.directionReverse !== "boolean")
                    return "directionReverse: boolean expected";
            return null;
        };

        /**
         * Creates an EdgeLocator message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.EdgeLocator} EdgeLocator
         */
        EdgeLocator.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.EdgeLocator)
                return object;
            let message = new $root.lnrpc.EdgeLocator();
            if (object.channelId != null)
                if ($util.Long)
                    (message.channelId = $util.Long.fromValue(object.channelId)).unsigned = true;
                else if (typeof object.channelId === "string")
                    message.channelId = parseInt(object.channelId, 10);
                else if (typeof object.channelId === "number")
                    message.channelId = object.channelId;
                else if (typeof object.channelId === "object")
                    message.channelId = new $util.LongBits(object.channelId.low >>> 0, object.channelId.high >>> 0).toNumber(true);
            if (object.directionReverse != null)
                message.directionReverse = Boolean(object.directionReverse);
            return message;
        };

        /**
         * Creates a plain object from an EdgeLocator message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.EdgeLocator
         * @static
         * @param {lnrpc.EdgeLocator} message EdgeLocator
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EdgeLocator.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.channelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.channelId = options.longs === String ? "0" : 0;
                object.directionReverse = false;
            }
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (typeof message.channelId === "number")
                    object.channelId = options.longs === String ? String(message.channelId) : message.channelId;
                else
                    object.channelId = options.longs === String ? $util.Long.prototype.toString.call(message.channelId) : options.longs === Number ? new $util.LongBits(message.channelId.low >>> 0, message.channelId.high >>> 0).toNumber(true) : message.channelId;
            if (message.directionReverse != null && message.hasOwnProperty("directionReverse"))
                object.directionReverse = message.directionReverse;
            return object;
        };

        /**
         * Converts this EdgeLocator to JSON.
         * @function toJSON
         * @memberof lnrpc.EdgeLocator
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EdgeLocator.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EdgeLocator;
    })();

    lnrpc.QueryRoutesResponse = (function() {

        /**
         * Properties of a QueryRoutesResponse.
         * @memberof lnrpc
         * @interface IQueryRoutesResponse
         * @property {Array.<lnrpc.IRoute>|null} [routes] QueryRoutesResponse routes
         * @property {number|null} [successProb] QueryRoutesResponse successProb
         */

        /**
         * Constructs a new QueryRoutesResponse.
         * @memberof lnrpc
         * @classdesc Represents a QueryRoutesResponse.
         * @implements IQueryRoutesResponse
         * @constructor
         * @param {lnrpc.IQueryRoutesResponse=} [properties] Properties to set
         */
        function QueryRoutesResponse(properties) {
            this.routes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryRoutesResponse routes.
         * @member {Array.<lnrpc.IRoute>} routes
         * @memberof lnrpc.QueryRoutesResponse
         * @instance
         */
        QueryRoutesResponse.prototype.routes = $util.emptyArray;

        /**
         * QueryRoutesResponse successProb.
         * @member {number} successProb
         * @memberof lnrpc.QueryRoutesResponse
         * @instance
         */
        QueryRoutesResponse.prototype.successProb = 0;

        /**
         * Creates a new QueryRoutesResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {lnrpc.IQueryRoutesResponse=} [properties] Properties to set
         * @returns {lnrpc.QueryRoutesResponse} QueryRoutesResponse instance
         */
        QueryRoutesResponse.create = function create(properties) {
            return new QueryRoutesResponse(properties);
        };

        /**
         * Encodes the specified QueryRoutesResponse message. Does not implicitly {@link lnrpc.QueryRoutesResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {lnrpc.IQueryRoutesResponse} message QueryRoutesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRoutesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.routes != null && message.routes.length)
                for (let i = 0; i < message.routes.length; ++i)
                    $root.lnrpc.Route.encode(message.routes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.successProb != null && Object.hasOwnProperty.call(message, "successProb"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.successProb);
            return writer;
        };

        /**
         * Encodes the specified QueryRoutesResponse message, length delimited. Does not implicitly {@link lnrpc.QueryRoutesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {lnrpc.IQueryRoutesResponse} message QueryRoutesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryRoutesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryRoutesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.QueryRoutesResponse} QueryRoutesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRoutesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.QueryRoutesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.routes && message.routes.length))
                            message.routes = [];
                        message.routes.push($root.lnrpc.Route.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.successProb = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryRoutesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.QueryRoutesResponse} QueryRoutesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryRoutesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryRoutesResponse message.
         * @function verify
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryRoutesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.routes != null && message.hasOwnProperty("routes")) {
                if (!Array.isArray(message.routes))
                    return "routes: array expected";
                for (let i = 0; i < message.routes.length; ++i) {
                    let error = $root.lnrpc.Route.verify(message.routes[i]);
                    if (error)
                        return "routes." + error;
                }
            }
            if (message.successProb != null && message.hasOwnProperty("successProb"))
                if (typeof message.successProb !== "number")
                    return "successProb: number expected";
            return null;
        };

        /**
         * Creates a QueryRoutesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.QueryRoutesResponse} QueryRoutesResponse
         */
        QueryRoutesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.QueryRoutesResponse)
                return object;
            let message = new $root.lnrpc.QueryRoutesResponse();
            if (object.routes) {
                if (!Array.isArray(object.routes))
                    throw TypeError(".lnrpc.QueryRoutesResponse.routes: array expected");
                message.routes = [];
                for (let i = 0; i < object.routes.length; ++i) {
                    if (typeof object.routes[i] !== "object")
                        throw TypeError(".lnrpc.QueryRoutesResponse.routes: object expected");
                    message.routes[i] = $root.lnrpc.Route.fromObject(object.routes[i]);
                }
            }
            if (object.successProb != null)
                message.successProb = Number(object.successProb);
            return message;
        };

        /**
         * Creates a plain object from a QueryRoutesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.QueryRoutesResponse
         * @static
         * @param {lnrpc.QueryRoutesResponse} message QueryRoutesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryRoutesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.routes = [];
            if (options.defaults)
                object.successProb = 0;
            if (message.routes && message.routes.length) {
                object.routes = [];
                for (let j = 0; j < message.routes.length; ++j)
                    object.routes[j] = $root.lnrpc.Route.toObject(message.routes[j], options);
            }
            if (message.successProb != null && message.hasOwnProperty("successProb"))
                object.successProb = options.json && !isFinite(message.successProb) ? String(message.successProb) : message.successProb;
            return object;
        };

        /**
         * Converts this QueryRoutesResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.QueryRoutesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryRoutesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryRoutesResponse;
    })();

    lnrpc.Hop = (function() {

        /**
         * Properties of a Hop.
         * @memberof lnrpc
         * @interface IHop
         * @property {Long|null} [chanId] Hop chanId
         * @property {Long|null} [chanCapacity] Hop chanCapacity
         * @property {Long|null} [amtToForward] Hop amtToForward
         * @property {Long|null} [fee] Hop fee
         * @property {number|null} [expiry] Hop expiry
         * @property {Long|null} [amtToForwardMsat] Hop amtToForwardMsat
         * @property {Long|null} [feeMsat] Hop feeMsat
         * @property {string|null} [pubKey] Hop pubKey
         * @property {boolean|null} [tlvPayload] Hop tlvPayload
         * @property {lnrpc.IMPPRecord|null} [mppRecord] Hop mppRecord
         * @property {lnrpc.IAMPRecord|null} [ampRecord] Hop ampRecord
         * @property {Object.<string,Uint8Array>|null} [customRecords] Hop customRecords
         * @property {Uint8Array|null} [metadata] Hop metadata
         */

        /**
         * Constructs a new Hop.
         * @memberof lnrpc
         * @classdesc Represents a Hop.
         * @implements IHop
         * @constructor
         * @param {lnrpc.IHop=} [properties] Properties to set
         */
        function Hop(properties) {
            this.customRecords = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Hop chanId.
         * @member {Long} chanId
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Hop chanCapacity.
         * @member {Long} chanCapacity
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.chanCapacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Hop amtToForward.
         * @member {Long} amtToForward
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.amtToForward = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Hop fee.
         * @member {Long} fee
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Hop expiry.
         * @member {number} expiry
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.expiry = 0;

        /**
         * Hop amtToForwardMsat.
         * @member {Long} amtToForwardMsat
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.amtToForwardMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Hop feeMsat.
         * @member {Long} feeMsat
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.feeMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Hop pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.pubKey = "";

        /**
         * Hop tlvPayload.
         * @member {boolean} tlvPayload
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.tlvPayload = false;

        /**
         * Hop mppRecord.
         * @member {lnrpc.IMPPRecord|null|undefined} mppRecord
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.mppRecord = null;

        /**
         * Hop ampRecord.
         * @member {lnrpc.IAMPRecord|null|undefined} ampRecord
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.ampRecord = null;

        /**
         * Hop customRecords.
         * @member {Object.<string,Uint8Array>} customRecords
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.customRecords = $util.emptyObject;

        /**
         * Hop metadata.
         * @member {Uint8Array} metadata
         * @memberof lnrpc.Hop
         * @instance
         */
        Hop.prototype.metadata = $util.newBuffer([]);

        /**
         * Creates a new Hop instance using the specified properties.
         * @function create
         * @memberof lnrpc.Hop
         * @static
         * @param {lnrpc.IHop=} [properties] Properties to set
         * @returns {lnrpc.Hop} Hop instance
         */
        Hop.create = function create(properties) {
            return new Hop(properties);
        };

        /**
         * Encodes the specified Hop message. Does not implicitly {@link lnrpc.Hop.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Hop
         * @static
         * @param {lnrpc.IHop} message Hop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Hop.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chanId);
            if (message.chanCapacity != null && Object.hasOwnProperty.call(message, "chanCapacity"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.chanCapacity);
            if (message.amtToForward != null && Object.hasOwnProperty.call(message, "amtToForward"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amtToForward);
            if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.fee);
            if (message.expiry != null && Object.hasOwnProperty.call(message, "expiry"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.expiry);
            if (message.amtToForwardMsat != null && Object.hasOwnProperty.call(message, "amtToForwardMsat"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.amtToForwardMsat);
            if (message.feeMsat != null && Object.hasOwnProperty.call(message, "feeMsat"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.feeMsat);
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.pubKey);
            if (message.tlvPayload != null && Object.hasOwnProperty.call(message, "tlvPayload"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.tlvPayload);
            if (message.mppRecord != null && Object.hasOwnProperty.call(message, "mppRecord"))
                $root.lnrpc.MPPRecord.encode(message.mppRecord, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.customRecords != null && Object.hasOwnProperty.call(message, "customRecords"))
                for (let keys = Object.keys(message.customRecords), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 0 =*/8).uint64(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.customRecords[keys[i]]).ldelim();
            if (message.ampRecord != null && Object.hasOwnProperty.call(message, "ampRecord"))
                $root.lnrpc.AMPRecord.encode(message.ampRecord, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                writer.uint32(/* id 13, wireType 2 =*/106).bytes(message.metadata);
            return writer;
        };

        /**
         * Encodes the specified Hop message, length delimited. Does not implicitly {@link lnrpc.Hop.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Hop
         * @static
         * @param {lnrpc.IHop} message Hop message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Hop.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Hop message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Hop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Hop} Hop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Hop.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Hop(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.chanCapacity = reader.int64();
                        break;
                    }
                case 3: {
                        message.amtToForward = reader.int64();
                        break;
                    }
                case 4: {
                        message.fee = reader.int64();
                        break;
                    }
                case 5: {
                        message.expiry = reader.uint32();
                        break;
                    }
                case 6: {
                        message.amtToForwardMsat = reader.int64();
                        break;
                    }
                case 7: {
                        message.feeMsat = reader.int64();
                        break;
                    }
                case 8: {
                        message.pubKey = reader.string();
                        break;
                    }
                case 9: {
                        message.tlvPayload = reader.bool();
                        break;
                    }
                case 10: {
                        message.mppRecord = $root.lnrpc.MPPRecord.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.ampRecord = $root.lnrpc.AMPRecord.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        if (message.customRecords === $util.emptyObject)
                            message.customRecords = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint64();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.customRecords[typeof key === "object" ? $util.longToHash(key) : key] = value;
                        break;
                    }
                case 13: {
                        message.metadata = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Hop message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Hop
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Hop} Hop
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Hop.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Hop message.
         * @function verify
         * @memberof lnrpc.Hop
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Hop.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.chanCapacity != null && message.hasOwnProperty("chanCapacity"))
                if (!$util.isInteger(message.chanCapacity) && !(message.chanCapacity && $util.isInteger(message.chanCapacity.low) && $util.isInteger(message.chanCapacity.high)))
                    return "chanCapacity: integer|Long expected";
            if (message.amtToForward != null && message.hasOwnProperty("amtToForward"))
                if (!$util.isInteger(message.amtToForward) && !(message.amtToForward && $util.isInteger(message.amtToForward.low) && $util.isInteger(message.amtToForward.high)))
                    return "amtToForward: integer|Long expected";
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                    return "fee: integer|Long expected";
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (!$util.isInteger(message.expiry))
                    return "expiry: integer expected";
            if (message.amtToForwardMsat != null && message.hasOwnProperty("amtToForwardMsat"))
                if (!$util.isInteger(message.amtToForwardMsat) && !(message.amtToForwardMsat && $util.isInteger(message.amtToForwardMsat.low) && $util.isInteger(message.amtToForwardMsat.high)))
                    return "amtToForwardMsat: integer|Long expected";
            if (message.feeMsat != null && message.hasOwnProperty("feeMsat"))
                if (!$util.isInteger(message.feeMsat) && !(message.feeMsat && $util.isInteger(message.feeMsat.low) && $util.isInteger(message.feeMsat.high)))
                    return "feeMsat: integer|Long expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            if (message.tlvPayload != null && message.hasOwnProperty("tlvPayload"))
                if (typeof message.tlvPayload !== "boolean")
                    return "tlvPayload: boolean expected";
            if (message.mppRecord != null && message.hasOwnProperty("mppRecord")) {
                let error = $root.lnrpc.MPPRecord.verify(message.mppRecord);
                if (error)
                    return "mppRecord." + error;
            }
            if (message.ampRecord != null && message.hasOwnProperty("ampRecord")) {
                let error = $root.lnrpc.AMPRecord.verify(message.ampRecord);
                if (error)
                    return "ampRecord." + error;
            }
            if (message.customRecords != null && message.hasOwnProperty("customRecords")) {
                if (!$util.isObject(message.customRecords))
                    return "customRecords: object expected";
                let key = Object.keys(message.customRecords);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key64Re.test(key[i]))
                        return "customRecords: integer|Long key{k:uint64} expected";
                    if (!(message.customRecords[key[i]] && typeof message.customRecords[key[i]].length === "number" || $util.isString(message.customRecords[key[i]])))
                        return "customRecords: buffer{k:uint64} expected";
                }
            }
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                if (!(message.metadata && typeof message.metadata.length === "number" || $util.isString(message.metadata)))
                    return "metadata: buffer expected";
            return null;
        };

        /**
         * Creates a Hop message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Hop
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Hop} Hop
         */
        Hop.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Hop)
                return object;
            let message = new $root.lnrpc.Hop();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.chanCapacity != null)
                if ($util.Long)
                    (message.chanCapacity = $util.Long.fromValue(object.chanCapacity)).unsigned = false;
                else if (typeof object.chanCapacity === "string")
                    message.chanCapacity = parseInt(object.chanCapacity, 10);
                else if (typeof object.chanCapacity === "number")
                    message.chanCapacity = object.chanCapacity;
                else if (typeof object.chanCapacity === "object")
                    message.chanCapacity = new $util.LongBits(object.chanCapacity.low >>> 0, object.chanCapacity.high >>> 0).toNumber();
            if (object.amtToForward != null)
                if ($util.Long)
                    (message.amtToForward = $util.Long.fromValue(object.amtToForward)).unsigned = false;
                else if (typeof object.amtToForward === "string")
                    message.amtToForward = parseInt(object.amtToForward, 10);
                else if (typeof object.amtToForward === "number")
                    message.amtToForward = object.amtToForward;
                else if (typeof object.amtToForward === "object")
                    message.amtToForward = new $util.LongBits(object.amtToForward.low >>> 0, object.amtToForward.high >>> 0).toNumber();
            if (object.fee != null)
                if ($util.Long)
                    (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
                else if (typeof object.fee === "string")
                    message.fee = parseInt(object.fee, 10);
                else if (typeof object.fee === "number")
                    message.fee = object.fee;
                else if (typeof object.fee === "object")
                    message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
            if (object.expiry != null)
                message.expiry = object.expiry >>> 0;
            if (object.amtToForwardMsat != null)
                if ($util.Long)
                    (message.amtToForwardMsat = $util.Long.fromValue(object.amtToForwardMsat)).unsigned = false;
                else if (typeof object.amtToForwardMsat === "string")
                    message.amtToForwardMsat = parseInt(object.amtToForwardMsat, 10);
                else if (typeof object.amtToForwardMsat === "number")
                    message.amtToForwardMsat = object.amtToForwardMsat;
                else if (typeof object.amtToForwardMsat === "object")
                    message.amtToForwardMsat = new $util.LongBits(object.amtToForwardMsat.low >>> 0, object.amtToForwardMsat.high >>> 0).toNumber();
            if (object.feeMsat != null)
                if ($util.Long)
                    (message.feeMsat = $util.Long.fromValue(object.feeMsat)).unsigned = false;
                else if (typeof object.feeMsat === "string")
                    message.feeMsat = parseInt(object.feeMsat, 10);
                else if (typeof object.feeMsat === "number")
                    message.feeMsat = object.feeMsat;
                else if (typeof object.feeMsat === "object")
                    message.feeMsat = new $util.LongBits(object.feeMsat.low >>> 0, object.feeMsat.high >>> 0).toNumber();
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            if (object.tlvPayload != null)
                message.tlvPayload = Boolean(object.tlvPayload);
            if (object.mppRecord != null) {
                if (typeof object.mppRecord !== "object")
                    throw TypeError(".lnrpc.Hop.mppRecord: object expected");
                message.mppRecord = $root.lnrpc.MPPRecord.fromObject(object.mppRecord);
            }
            if (object.ampRecord != null) {
                if (typeof object.ampRecord !== "object")
                    throw TypeError(".lnrpc.Hop.ampRecord: object expected");
                message.ampRecord = $root.lnrpc.AMPRecord.fromObject(object.ampRecord);
            }
            if (object.customRecords) {
                if (typeof object.customRecords !== "object")
                    throw TypeError(".lnrpc.Hop.customRecords: object expected");
                message.customRecords = {};
                for (let keys = Object.keys(object.customRecords), i = 0; i < keys.length; ++i)
                    if (typeof object.customRecords[keys[i]] === "string")
                        $util.base64.decode(object.customRecords[keys[i]], message.customRecords[keys[i]] = $util.newBuffer($util.base64.length(object.customRecords[keys[i]])), 0);
                    else if (object.customRecords[keys[i]].length)
                        message.customRecords[keys[i]] = object.customRecords[keys[i]];
            }
            if (object.metadata != null)
                if (typeof object.metadata === "string")
                    $util.base64.decode(object.metadata, message.metadata = $util.newBuffer($util.base64.length(object.metadata)), 0);
                else if (object.metadata.length)
                    message.metadata = object.metadata;
            return message;
        };

        /**
         * Creates a plain object from a Hop message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Hop
         * @static
         * @param {lnrpc.Hop} message Hop
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Hop.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.customRecords = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.chanCapacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanCapacity = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtToForward = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtToForward = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fee = options.longs === String ? "0" : 0;
                object.expiry = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtToForwardMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtToForwardMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeMsat = options.longs === String ? "0" : 0;
                object.pubKey = "";
                object.tlvPayload = false;
                object.mppRecord = null;
                object.ampRecord = null;
                if (options.bytes === String)
                    object.metadata = "";
                else {
                    object.metadata = [];
                    if (options.bytes !== Array)
                        object.metadata = $util.newBuffer(object.metadata);
                }
            }
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.chanCapacity != null && message.hasOwnProperty("chanCapacity"))
                if (typeof message.chanCapacity === "number")
                    object.chanCapacity = options.longs === String ? String(message.chanCapacity) : message.chanCapacity;
                else
                    object.chanCapacity = options.longs === String ? $util.Long.prototype.toString.call(message.chanCapacity) : options.longs === Number ? new $util.LongBits(message.chanCapacity.low >>> 0, message.chanCapacity.high >>> 0).toNumber() : message.chanCapacity;
            if (message.amtToForward != null && message.hasOwnProperty("amtToForward"))
                if (typeof message.amtToForward === "number")
                    object.amtToForward = options.longs === String ? String(message.amtToForward) : message.amtToForward;
                else
                    object.amtToForward = options.longs === String ? $util.Long.prototype.toString.call(message.amtToForward) : options.longs === Number ? new $util.LongBits(message.amtToForward.low >>> 0, message.amtToForward.high >>> 0).toNumber() : message.amtToForward;
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (typeof message.fee === "number")
                    object.fee = options.longs === String ? String(message.fee) : message.fee;
                else
                    object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber() : message.fee;
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                object.expiry = message.expiry;
            if (message.amtToForwardMsat != null && message.hasOwnProperty("amtToForwardMsat"))
                if (typeof message.amtToForwardMsat === "number")
                    object.amtToForwardMsat = options.longs === String ? String(message.amtToForwardMsat) : message.amtToForwardMsat;
                else
                    object.amtToForwardMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtToForwardMsat) : options.longs === Number ? new $util.LongBits(message.amtToForwardMsat.low >>> 0, message.amtToForwardMsat.high >>> 0).toNumber() : message.amtToForwardMsat;
            if (message.feeMsat != null && message.hasOwnProperty("feeMsat"))
                if (typeof message.feeMsat === "number")
                    object.feeMsat = options.longs === String ? String(message.feeMsat) : message.feeMsat;
                else
                    object.feeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.feeMsat) : options.longs === Number ? new $util.LongBits(message.feeMsat.low >>> 0, message.feeMsat.high >>> 0).toNumber() : message.feeMsat;
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            if (message.tlvPayload != null && message.hasOwnProperty("tlvPayload"))
                object.tlvPayload = message.tlvPayload;
            if (message.mppRecord != null && message.hasOwnProperty("mppRecord"))
                object.mppRecord = $root.lnrpc.MPPRecord.toObject(message.mppRecord, options);
            let keys2;
            if (message.customRecords && (keys2 = Object.keys(message.customRecords)).length) {
                object.customRecords = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.customRecords[keys2[j]] = options.bytes === String ? $util.base64.encode(message.customRecords[keys2[j]], 0, message.customRecords[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.customRecords[keys2[j]]) : message.customRecords[keys2[j]];
            }
            if (message.ampRecord != null && message.hasOwnProperty("ampRecord"))
                object.ampRecord = $root.lnrpc.AMPRecord.toObject(message.ampRecord, options);
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                object.metadata = options.bytes === String ? $util.base64.encode(message.metadata, 0, message.metadata.length) : options.bytes === Array ? Array.prototype.slice.call(message.metadata) : message.metadata;
            return object;
        };

        /**
         * Converts this Hop to JSON.
         * @function toJSON
         * @memberof lnrpc.Hop
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Hop.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Hop;
    })();

    lnrpc.MPPRecord = (function() {

        /**
         * Properties of a MPPRecord.
         * @memberof lnrpc
         * @interface IMPPRecord
         * @property {Uint8Array|null} [paymentAddr] MPPRecord paymentAddr
         * @property {Long|null} [totalAmtMsat] MPPRecord totalAmtMsat
         */

        /**
         * Constructs a new MPPRecord.
         * @memberof lnrpc
         * @classdesc Represents a MPPRecord.
         * @implements IMPPRecord
         * @constructor
         * @param {lnrpc.IMPPRecord=} [properties] Properties to set
         */
        function MPPRecord(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MPPRecord paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof lnrpc.MPPRecord
         * @instance
         */
        MPPRecord.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * MPPRecord totalAmtMsat.
         * @member {Long} totalAmtMsat
         * @memberof lnrpc.MPPRecord
         * @instance
         */
        MPPRecord.prototype.totalAmtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new MPPRecord instance using the specified properties.
         * @function create
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {lnrpc.IMPPRecord=} [properties] Properties to set
         * @returns {lnrpc.MPPRecord} MPPRecord instance
         */
        MPPRecord.create = function create(properties) {
            return new MPPRecord(properties);
        };

        /**
         * Encodes the specified MPPRecord message. Does not implicitly {@link lnrpc.MPPRecord.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {lnrpc.IMPPRecord} message MPPRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MPPRecord.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.totalAmtMsat != null && Object.hasOwnProperty.call(message, "totalAmtMsat"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.totalAmtMsat);
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.paymentAddr);
            return writer;
        };

        /**
         * Encodes the specified MPPRecord message, length delimited. Does not implicitly {@link lnrpc.MPPRecord.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {lnrpc.IMPPRecord} message MPPRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MPPRecord.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MPPRecord message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.MPPRecord} MPPRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MPPRecord.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.MPPRecord();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 11: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                case 10: {
                        message.totalAmtMsat = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MPPRecord message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.MPPRecord} MPPRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MPPRecord.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MPPRecord message.
         * @function verify
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MPPRecord.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            if (message.totalAmtMsat != null && message.hasOwnProperty("totalAmtMsat"))
                if (!$util.isInteger(message.totalAmtMsat) && !(message.totalAmtMsat && $util.isInteger(message.totalAmtMsat.low) && $util.isInteger(message.totalAmtMsat.high)))
                    return "totalAmtMsat: integer|Long expected";
            return null;
        };

        /**
         * Creates a MPPRecord message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.MPPRecord} MPPRecord
         */
        MPPRecord.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.MPPRecord)
                return object;
            let message = new $root.lnrpc.MPPRecord();
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            if (object.totalAmtMsat != null)
                if ($util.Long)
                    (message.totalAmtMsat = $util.Long.fromValue(object.totalAmtMsat)).unsigned = false;
                else if (typeof object.totalAmtMsat === "string")
                    message.totalAmtMsat = parseInt(object.totalAmtMsat, 10);
                else if (typeof object.totalAmtMsat === "number")
                    message.totalAmtMsat = object.totalAmtMsat;
                else if (typeof object.totalAmtMsat === "object")
                    message.totalAmtMsat = new $util.LongBits(object.totalAmtMsat.low >>> 0, object.totalAmtMsat.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a MPPRecord message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.MPPRecord
         * @static
         * @param {lnrpc.MPPRecord} message MPPRecord
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MPPRecord.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalAmtMsat = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
            }
            if (message.totalAmtMsat != null && message.hasOwnProperty("totalAmtMsat"))
                if (typeof message.totalAmtMsat === "number")
                    object.totalAmtMsat = options.longs === String ? String(message.totalAmtMsat) : message.totalAmtMsat;
                else
                    object.totalAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.totalAmtMsat) : options.longs === Number ? new $util.LongBits(message.totalAmtMsat.low >>> 0, message.totalAmtMsat.high >>> 0).toNumber() : message.totalAmtMsat;
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            return object;
        };

        /**
         * Converts this MPPRecord to JSON.
         * @function toJSON
         * @memberof lnrpc.MPPRecord
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MPPRecord.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MPPRecord;
    })();

    lnrpc.AMPRecord = (function() {

        /**
         * Properties of a AMPRecord.
         * @memberof lnrpc
         * @interface IAMPRecord
         * @property {Uint8Array|null} [rootShare] AMPRecord rootShare
         * @property {Uint8Array|null} [setId] AMPRecord setId
         * @property {number|null} [childIndex] AMPRecord childIndex
         */

        /**
         * Constructs a new AMPRecord.
         * @memberof lnrpc
         * @classdesc Represents a AMPRecord.
         * @implements IAMPRecord
         * @constructor
         * @param {lnrpc.IAMPRecord=} [properties] Properties to set
         */
        function AMPRecord(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AMPRecord rootShare.
         * @member {Uint8Array} rootShare
         * @memberof lnrpc.AMPRecord
         * @instance
         */
        AMPRecord.prototype.rootShare = $util.newBuffer([]);

        /**
         * AMPRecord setId.
         * @member {Uint8Array} setId
         * @memberof lnrpc.AMPRecord
         * @instance
         */
        AMPRecord.prototype.setId = $util.newBuffer([]);

        /**
         * AMPRecord childIndex.
         * @member {number} childIndex
         * @memberof lnrpc.AMPRecord
         * @instance
         */
        AMPRecord.prototype.childIndex = 0;

        /**
         * Creates a new AMPRecord instance using the specified properties.
         * @function create
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {lnrpc.IAMPRecord=} [properties] Properties to set
         * @returns {lnrpc.AMPRecord} AMPRecord instance
         */
        AMPRecord.create = function create(properties) {
            return new AMPRecord(properties);
        };

        /**
         * Encodes the specified AMPRecord message. Does not implicitly {@link lnrpc.AMPRecord.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {lnrpc.IAMPRecord} message AMPRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AMPRecord.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rootShare != null && Object.hasOwnProperty.call(message, "rootShare"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rootShare);
            if (message.setId != null && Object.hasOwnProperty.call(message, "setId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.setId);
            if (message.childIndex != null && Object.hasOwnProperty.call(message, "childIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.childIndex);
            return writer;
        };

        /**
         * Encodes the specified AMPRecord message, length delimited. Does not implicitly {@link lnrpc.AMPRecord.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {lnrpc.IAMPRecord} message AMPRecord message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AMPRecord.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a AMPRecord message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AMPRecord} AMPRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AMPRecord.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AMPRecord();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rootShare = reader.bytes();
                        break;
                    }
                case 2: {
                        message.setId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.childIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a AMPRecord message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AMPRecord} AMPRecord
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AMPRecord.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a AMPRecord message.
         * @function verify
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AMPRecord.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rootShare != null && message.hasOwnProperty("rootShare"))
                if (!(message.rootShare && typeof message.rootShare.length === "number" || $util.isString(message.rootShare)))
                    return "rootShare: buffer expected";
            if (message.setId != null && message.hasOwnProperty("setId"))
                if (!(message.setId && typeof message.setId.length === "number" || $util.isString(message.setId)))
                    return "setId: buffer expected";
            if (message.childIndex != null && message.hasOwnProperty("childIndex"))
                if (!$util.isInteger(message.childIndex))
                    return "childIndex: integer expected";
            return null;
        };

        /**
         * Creates a AMPRecord message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AMPRecord} AMPRecord
         */
        AMPRecord.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AMPRecord)
                return object;
            let message = new $root.lnrpc.AMPRecord();
            if (object.rootShare != null)
                if (typeof object.rootShare === "string")
                    $util.base64.decode(object.rootShare, message.rootShare = $util.newBuffer($util.base64.length(object.rootShare)), 0);
                else if (object.rootShare.length)
                    message.rootShare = object.rootShare;
            if (object.setId != null)
                if (typeof object.setId === "string")
                    $util.base64.decode(object.setId, message.setId = $util.newBuffer($util.base64.length(object.setId)), 0);
                else if (object.setId.length)
                    message.setId = object.setId;
            if (object.childIndex != null)
                message.childIndex = object.childIndex >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a AMPRecord message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AMPRecord
         * @static
         * @param {lnrpc.AMPRecord} message AMPRecord
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AMPRecord.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.rootShare = "";
                else {
                    object.rootShare = [];
                    if (options.bytes !== Array)
                        object.rootShare = $util.newBuffer(object.rootShare);
                }
                if (options.bytes === String)
                    object.setId = "";
                else {
                    object.setId = [];
                    if (options.bytes !== Array)
                        object.setId = $util.newBuffer(object.setId);
                }
                object.childIndex = 0;
            }
            if (message.rootShare != null && message.hasOwnProperty("rootShare"))
                object.rootShare = options.bytes === String ? $util.base64.encode(message.rootShare, 0, message.rootShare.length) : options.bytes === Array ? Array.prototype.slice.call(message.rootShare) : message.rootShare;
            if (message.setId != null && message.hasOwnProperty("setId"))
                object.setId = options.bytes === String ? $util.base64.encode(message.setId, 0, message.setId.length) : options.bytes === Array ? Array.prototype.slice.call(message.setId) : message.setId;
            if (message.childIndex != null && message.hasOwnProperty("childIndex"))
                object.childIndex = message.childIndex;
            return object;
        };

        /**
         * Converts this AMPRecord to JSON.
         * @function toJSON
         * @memberof lnrpc.AMPRecord
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AMPRecord.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AMPRecord;
    })();

    lnrpc.Route = (function() {

        /**
         * Properties of a Route.
         * @memberof lnrpc
         * @interface IRoute
         * @property {number|null} [totalTimeLock] Route totalTimeLock
         * @property {Long|null} [totalFees] Route totalFees
         * @property {Long|null} [totalAmt] Route totalAmt
         * @property {Array.<lnrpc.IHop>|null} [hops] Route hops
         * @property {Long|null} [totalFeesMsat] Route totalFeesMsat
         * @property {Long|null} [totalAmtMsat] Route totalAmtMsat
         */

        /**
         * Constructs a new Route.
         * @memberof lnrpc
         * @classdesc Represents a Route.
         * @implements IRoute
         * @constructor
         * @param {lnrpc.IRoute=} [properties] Properties to set
         */
        function Route(properties) {
            this.hops = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Route totalTimeLock.
         * @member {number} totalTimeLock
         * @memberof lnrpc.Route
         * @instance
         */
        Route.prototype.totalTimeLock = 0;

        /**
         * Route totalFees.
         * @member {Long} totalFees
         * @memberof lnrpc.Route
         * @instance
         */
        Route.prototype.totalFees = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Route totalAmt.
         * @member {Long} totalAmt
         * @memberof lnrpc.Route
         * @instance
         */
        Route.prototype.totalAmt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Route hops.
         * @member {Array.<lnrpc.IHop>} hops
         * @memberof lnrpc.Route
         * @instance
         */
        Route.prototype.hops = $util.emptyArray;

        /**
         * Route totalFeesMsat.
         * @member {Long} totalFeesMsat
         * @memberof lnrpc.Route
         * @instance
         */
        Route.prototype.totalFeesMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Route totalAmtMsat.
         * @member {Long} totalAmtMsat
         * @memberof lnrpc.Route
         * @instance
         */
        Route.prototype.totalAmtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Route instance using the specified properties.
         * @function create
         * @memberof lnrpc.Route
         * @static
         * @param {lnrpc.IRoute=} [properties] Properties to set
         * @returns {lnrpc.Route} Route instance
         */
        Route.create = function create(properties) {
            return new Route(properties);
        };

        /**
         * Encodes the specified Route message. Does not implicitly {@link lnrpc.Route.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Route
         * @static
         * @param {lnrpc.IRoute} message Route message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Route.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.totalTimeLock != null && Object.hasOwnProperty.call(message, "totalTimeLock"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.totalTimeLock);
            if (message.totalFees != null && Object.hasOwnProperty.call(message, "totalFees"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalFees);
            if (message.totalAmt != null && Object.hasOwnProperty.call(message, "totalAmt"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalAmt);
            if (message.hops != null && message.hops.length)
                for (let i = 0; i < message.hops.length; ++i)
                    $root.lnrpc.Hop.encode(message.hops[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.totalFeesMsat != null && Object.hasOwnProperty.call(message, "totalFeesMsat"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.totalFeesMsat);
            if (message.totalAmtMsat != null && Object.hasOwnProperty.call(message, "totalAmtMsat"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.totalAmtMsat);
            return writer;
        };

        /**
         * Encodes the specified Route message, length delimited. Does not implicitly {@link lnrpc.Route.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Route
         * @static
         * @param {lnrpc.IRoute} message Route message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Route.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Route message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Route
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Route} Route
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Route.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Route();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.totalTimeLock = reader.uint32();
                        break;
                    }
                case 2: {
                        message.totalFees = reader.int64();
                        break;
                    }
                case 3: {
                        message.totalAmt = reader.int64();
                        break;
                    }
                case 4: {
                        if (!(message.hops && message.hops.length))
                            message.hops = [];
                        message.hops.push($root.lnrpc.Hop.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.totalFeesMsat = reader.int64();
                        break;
                    }
                case 6: {
                        message.totalAmtMsat = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Route message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Route
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Route} Route
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Route.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Route message.
         * @function verify
         * @memberof lnrpc.Route
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Route.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.totalTimeLock != null && message.hasOwnProperty("totalTimeLock"))
                if (!$util.isInteger(message.totalTimeLock))
                    return "totalTimeLock: integer expected";
            if (message.totalFees != null && message.hasOwnProperty("totalFees"))
                if (!$util.isInteger(message.totalFees) && !(message.totalFees && $util.isInteger(message.totalFees.low) && $util.isInteger(message.totalFees.high)))
                    return "totalFees: integer|Long expected";
            if (message.totalAmt != null && message.hasOwnProperty("totalAmt"))
                if (!$util.isInteger(message.totalAmt) && !(message.totalAmt && $util.isInteger(message.totalAmt.low) && $util.isInteger(message.totalAmt.high)))
                    return "totalAmt: integer|Long expected";
            if (message.hops != null && message.hasOwnProperty("hops")) {
                if (!Array.isArray(message.hops))
                    return "hops: array expected";
                for (let i = 0; i < message.hops.length; ++i) {
                    let error = $root.lnrpc.Hop.verify(message.hops[i]);
                    if (error)
                        return "hops." + error;
                }
            }
            if (message.totalFeesMsat != null && message.hasOwnProperty("totalFeesMsat"))
                if (!$util.isInteger(message.totalFeesMsat) && !(message.totalFeesMsat && $util.isInteger(message.totalFeesMsat.low) && $util.isInteger(message.totalFeesMsat.high)))
                    return "totalFeesMsat: integer|Long expected";
            if (message.totalAmtMsat != null && message.hasOwnProperty("totalAmtMsat"))
                if (!$util.isInteger(message.totalAmtMsat) && !(message.totalAmtMsat && $util.isInteger(message.totalAmtMsat.low) && $util.isInteger(message.totalAmtMsat.high)))
                    return "totalAmtMsat: integer|Long expected";
            return null;
        };

        /**
         * Creates a Route message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Route
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Route} Route
         */
        Route.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Route)
                return object;
            let message = new $root.lnrpc.Route();
            if (object.totalTimeLock != null)
                message.totalTimeLock = object.totalTimeLock >>> 0;
            if (object.totalFees != null)
                if ($util.Long)
                    (message.totalFees = $util.Long.fromValue(object.totalFees)).unsigned = false;
                else if (typeof object.totalFees === "string")
                    message.totalFees = parseInt(object.totalFees, 10);
                else if (typeof object.totalFees === "number")
                    message.totalFees = object.totalFees;
                else if (typeof object.totalFees === "object")
                    message.totalFees = new $util.LongBits(object.totalFees.low >>> 0, object.totalFees.high >>> 0).toNumber();
            if (object.totalAmt != null)
                if ($util.Long)
                    (message.totalAmt = $util.Long.fromValue(object.totalAmt)).unsigned = false;
                else if (typeof object.totalAmt === "string")
                    message.totalAmt = parseInt(object.totalAmt, 10);
                else if (typeof object.totalAmt === "number")
                    message.totalAmt = object.totalAmt;
                else if (typeof object.totalAmt === "object")
                    message.totalAmt = new $util.LongBits(object.totalAmt.low >>> 0, object.totalAmt.high >>> 0).toNumber();
            if (object.hops) {
                if (!Array.isArray(object.hops))
                    throw TypeError(".lnrpc.Route.hops: array expected");
                message.hops = [];
                for (let i = 0; i < object.hops.length; ++i) {
                    if (typeof object.hops[i] !== "object")
                        throw TypeError(".lnrpc.Route.hops: object expected");
                    message.hops[i] = $root.lnrpc.Hop.fromObject(object.hops[i]);
                }
            }
            if (object.totalFeesMsat != null)
                if ($util.Long)
                    (message.totalFeesMsat = $util.Long.fromValue(object.totalFeesMsat)).unsigned = false;
                else if (typeof object.totalFeesMsat === "string")
                    message.totalFeesMsat = parseInt(object.totalFeesMsat, 10);
                else if (typeof object.totalFeesMsat === "number")
                    message.totalFeesMsat = object.totalFeesMsat;
                else if (typeof object.totalFeesMsat === "object")
                    message.totalFeesMsat = new $util.LongBits(object.totalFeesMsat.low >>> 0, object.totalFeesMsat.high >>> 0).toNumber();
            if (object.totalAmtMsat != null)
                if ($util.Long)
                    (message.totalAmtMsat = $util.Long.fromValue(object.totalAmtMsat)).unsigned = false;
                else if (typeof object.totalAmtMsat === "string")
                    message.totalAmtMsat = parseInt(object.totalAmtMsat, 10);
                else if (typeof object.totalAmtMsat === "number")
                    message.totalAmtMsat = object.totalAmtMsat;
                else if (typeof object.totalAmtMsat === "object")
                    message.totalAmtMsat = new $util.LongBits(object.totalAmtMsat.low >>> 0, object.totalAmtMsat.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Route message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Route
         * @static
         * @param {lnrpc.Route} message Route
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Route.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.hops = [];
            if (options.defaults) {
                object.totalTimeLock = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalFees = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalFees = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalAmt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalAmt = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalFeesMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalFeesMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalAmtMsat = options.longs === String ? "0" : 0;
            }
            if (message.totalTimeLock != null && message.hasOwnProperty("totalTimeLock"))
                object.totalTimeLock = message.totalTimeLock;
            if (message.totalFees != null && message.hasOwnProperty("totalFees"))
                if (typeof message.totalFees === "number")
                    object.totalFees = options.longs === String ? String(message.totalFees) : message.totalFees;
                else
                    object.totalFees = options.longs === String ? $util.Long.prototype.toString.call(message.totalFees) : options.longs === Number ? new $util.LongBits(message.totalFees.low >>> 0, message.totalFees.high >>> 0).toNumber() : message.totalFees;
            if (message.totalAmt != null && message.hasOwnProperty("totalAmt"))
                if (typeof message.totalAmt === "number")
                    object.totalAmt = options.longs === String ? String(message.totalAmt) : message.totalAmt;
                else
                    object.totalAmt = options.longs === String ? $util.Long.prototype.toString.call(message.totalAmt) : options.longs === Number ? new $util.LongBits(message.totalAmt.low >>> 0, message.totalAmt.high >>> 0).toNumber() : message.totalAmt;
            if (message.hops && message.hops.length) {
                object.hops = [];
                for (let j = 0; j < message.hops.length; ++j)
                    object.hops[j] = $root.lnrpc.Hop.toObject(message.hops[j], options);
            }
            if (message.totalFeesMsat != null && message.hasOwnProperty("totalFeesMsat"))
                if (typeof message.totalFeesMsat === "number")
                    object.totalFeesMsat = options.longs === String ? String(message.totalFeesMsat) : message.totalFeesMsat;
                else
                    object.totalFeesMsat = options.longs === String ? $util.Long.prototype.toString.call(message.totalFeesMsat) : options.longs === Number ? new $util.LongBits(message.totalFeesMsat.low >>> 0, message.totalFeesMsat.high >>> 0).toNumber() : message.totalFeesMsat;
            if (message.totalAmtMsat != null && message.hasOwnProperty("totalAmtMsat"))
                if (typeof message.totalAmtMsat === "number")
                    object.totalAmtMsat = options.longs === String ? String(message.totalAmtMsat) : message.totalAmtMsat;
                else
                    object.totalAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.totalAmtMsat) : options.longs === Number ? new $util.LongBits(message.totalAmtMsat.low >>> 0, message.totalAmtMsat.high >>> 0).toNumber() : message.totalAmtMsat;
            return object;
        };

        /**
         * Converts this Route to JSON.
         * @function toJSON
         * @memberof lnrpc.Route
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Route.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Route;
    })();

    lnrpc.NodeInfoRequest = (function() {

        /**
         * Properties of a NodeInfoRequest.
         * @memberof lnrpc
         * @interface INodeInfoRequest
         * @property {string|null} [pubKey] NodeInfoRequest pubKey
         * @property {boolean|null} [includeChannels] NodeInfoRequest includeChannels
         */

        /**
         * Constructs a new NodeInfoRequest.
         * @memberof lnrpc
         * @classdesc Represents a NodeInfoRequest.
         * @implements INodeInfoRequest
         * @constructor
         * @param {lnrpc.INodeInfoRequest=} [properties] Properties to set
         */
        function NodeInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeInfoRequest pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.NodeInfoRequest
         * @instance
         */
        NodeInfoRequest.prototype.pubKey = "";

        /**
         * NodeInfoRequest includeChannels.
         * @member {boolean} includeChannels
         * @memberof lnrpc.NodeInfoRequest
         * @instance
         */
        NodeInfoRequest.prototype.includeChannels = false;

        /**
         * Creates a new NodeInfoRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {lnrpc.INodeInfoRequest=} [properties] Properties to set
         * @returns {lnrpc.NodeInfoRequest} NodeInfoRequest instance
         */
        NodeInfoRequest.create = function create(properties) {
            return new NodeInfoRequest(properties);
        };

        /**
         * Encodes the specified NodeInfoRequest message. Does not implicitly {@link lnrpc.NodeInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {lnrpc.INodeInfoRequest} message NodeInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubKey);
            if (message.includeChannels != null && Object.hasOwnProperty.call(message, "includeChannels"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeChannels);
            return writer;
        };

        /**
         * Encodes the specified NodeInfoRequest message, length delimited. Does not implicitly {@link lnrpc.NodeInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {lnrpc.INodeInfoRequest} message NodeInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodeInfoRequest} NodeInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodeInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubKey = reader.string();
                        break;
                    }
                case 2: {
                        message.includeChannels = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodeInfoRequest} NodeInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeInfoRequest message.
         * @function verify
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            if (message.includeChannels != null && message.hasOwnProperty("includeChannels"))
                if (typeof message.includeChannels !== "boolean")
                    return "includeChannels: boolean expected";
            return null;
        };

        /**
         * Creates a NodeInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodeInfoRequest} NodeInfoRequest
         */
        NodeInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodeInfoRequest)
                return object;
            let message = new $root.lnrpc.NodeInfoRequest();
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            if (object.includeChannels != null)
                message.includeChannels = Boolean(object.includeChannels);
            return message;
        };

        /**
         * Creates a plain object from a NodeInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodeInfoRequest
         * @static
         * @param {lnrpc.NodeInfoRequest} message NodeInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeInfoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.pubKey = "";
                object.includeChannels = false;
            }
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            if (message.includeChannels != null && message.hasOwnProperty("includeChannels"))
                object.includeChannels = message.includeChannels;
            return object;
        };

        /**
         * Converts this NodeInfoRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.NodeInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeInfoRequest;
    })();

    lnrpc.NodeInfo = (function() {

        /**
         * Properties of a NodeInfo.
         * @memberof lnrpc
         * @interface INodeInfo
         * @property {lnrpc.ILightningNode|null} [node] NodeInfo node
         * @property {number|null} [numChannels] NodeInfo numChannels
         * @property {Long|null} [totalCapacity] NodeInfo totalCapacity
         * @property {Array.<lnrpc.IChannelEdge>|null} [channels] NodeInfo channels
         */

        /**
         * Constructs a new NodeInfo.
         * @memberof lnrpc
         * @classdesc Represents a NodeInfo.
         * @implements INodeInfo
         * @constructor
         * @param {lnrpc.INodeInfo=} [properties] Properties to set
         */
        function NodeInfo(properties) {
            this.channels = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeInfo node.
         * @member {lnrpc.ILightningNode|null|undefined} node
         * @memberof lnrpc.NodeInfo
         * @instance
         */
        NodeInfo.prototype.node = null;

        /**
         * NodeInfo numChannels.
         * @member {number} numChannels
         * @memberof lnrpc.NodeInfo
         * @instance
         */
        NodeInfo.prototype.numChannels = 0;

        /**
         * NodeInfo totalCapacity.
         * @member {Long} totalCapacity
         * @memberof lnrpc.NodeInfo
         * @instance
         */
        NodeInfo.prototype.totalCapacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NodeInfo channels.
         * @member {Array.<lnrpc.IChannelEdge>} channels
         * @memberof lnrpc.NodeInfo
         * @instance
         */
        NodeInfo.prototype.channels = $util.emptyArray;

        /**
         * Creates a new NodeInfo instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {lnrpc.INodeInfo=} [properties] Properties to set
         * @returns {lnrpc.NodeInfo} NodeInfo instance
         */
        NodeInfo.create = function create(properties) {
            return new NodeInfo(properties);
        };

        /**
         * Encodes the specified NodeInfo message. Does not implicitly {@link lnrpc.NodeInfo.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {lnrpc.INodeInfo} message NodeInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.node != null && Object.hasOwnProperty.call(message, "node"))
                $root.lnrpc.LightningNode.encode(message.node, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.numChannels != null && Object.hasOwnProperty.call(message, "numChannels"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.numChannels);
            if (message.totalCapacity != null && Object.hasOwnProperty.call(message, "totalCapacity"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalCapacity);
            if (message.channels != null && message.channels.length)
                for (let i = 0; i < message.channels.length; ++i)
                    $root.lnrpc.ChannelEdge.encode(message.channels[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NodeInfo message, length delimited. Does not implicitly {@link lnrpc.NodeInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {lnrpc.INodeInfo} message NodeInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeInfo message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodeInfo} NodeInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodeInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.node = $root.lnrpc.LightningNode.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.numChannels = reader.uint32();
                        break;
                    }
                case 3: {
                        message.totalCapacity = reader.int64();
                        break;
                    }
                case 4: {
                        if (!(message.channels && message.channels.length))
                            message.channels = [];
                        message.channels.push($root.lnrpc.ChannelEdge.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodeInfo} NodeInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeInfo message.
         * @function verify
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.node != null && message.hasOwnProperty("node")) {
                let error = $root.lnrpc.LightningNode.verify(message.node);
                if (error)
                    return "node." + error;
            }
            if (message.numChannels != null && message.hasOwnProperty("numChannels"))
                if (!$util.isInteger(message.numChannels))
                    return "numChannels: integer expected";
            if (message.totalCapacity != null && message.hasOwnProperty("totalCapacity"))
                if (!$util.isInteger(message.totalCapacity) && !(message.totalCapacity && $util.isInteger(message.totalCapacity.low) && $util.isInteger(message.totalCapacity.high)))
                    return "totalCapacity: integer|Long expected";
            if (message.channels != null && message.hasOwnProperty("channels")) {
                if (!Array.isArray(message.channels))
                    return "channels: array expected";
                for (let i = 0; i < message.channels.length; ++i) {
                    let error = $root.lnrpc.ChannelEdge.verify(message.channels[i]);
                    if (error)
                        return "channels." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NodeInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodeInfo} NodeInfo
         */
        NodeInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodeInfo)
                return object;
            let message = new $root.lnrpc.NodeInfo();
            if (object.node != null) {
                if (typeof object.node !== "object")
                    throw TypeError(".lnrpc.NodeInfo.node: object expected");
                message.node = $root.lnrpc.LightningNode.fromObject(object.node);
            }
            if (object.numChannels != null)
                message.numChannels = object.numChannels >>> 0;
            if (object.totalCapacity != null)
                if ($util.Long)
                    (message.totalCapacity = $util.Long.fromValue(object.totalCapacity)).unsigned = false;
                else if (typeof object.totalCapacity === "string")
                    message.totalCapacity = parseInt(object.totalCapacity, 10);
                else if (typeof object.totalCapacity === "number")
                    message.totalCapacity = object.totalCapacity;
                else if (typeof object.totalCapacity === "object")
                    message.totalCapacity = new $util.LongBits(object.totalCapacity.low >>> 0, object.totalCapacity.high >>> 0).toNumber();
            if (object.channels) {
                if (!Array.isArray(object.channels))
                    throw TypeError(".lnrpc.NodeInfo.channels: array expected");
                message.channels = [];
                for (let i = 0; i < object.channels.length; ++i) {
                    if (typeof object.channels[i] !== "object")
                        throw TypeError(".lnrpc.NodeInfo.channels: object expected");
                    message.channels[i] = $root.lnrpc.ChannelEdge.fromObject(object.channels[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NodeInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodeInfo
         * @static
         * @param {lnrpc.NodeInfo} message NodeInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.channels = [];
            if (options.defaults) {
                object.node = null;
                object.numChannels = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalCapacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalCapacity = options.longs === String ? "0" : 0;
            }
            if (message.node != null && message.hasOwnProperty("node"))
                object.node = $root.lnrpc.LightningNode.toObject(message.node, options);
            if (message.numChannels != null && message.hasOwnProperty("numChannels"))
                object.numChannels = message.numChannels;
            if (message.totalCapacity != null && message.hasOwnProperty("totalCapacity"))
                if (typeof message.totalCapacity === "number")
                    object.totalCapacity = options.longs === String ? String(message.totalCapacity) : message.totalCapacity;
                else
                    object.totalCapacity = options.longs === String ? $util.Long.prototype.toString.call(message.totalCapacity) : options.longs === Number ? new $util.LongBits(message.totalCapacity.low >>> 0, message.totalCapacity.high >>> 0).toNumber() : message.totalCapacity;
            if (message.channels && message.channels.length) {
                object.channels = [];
                for (let j = 0; j < message.channels.length; ++j)
                    object.channels[j] = $root.lnrpc.ChannelEdge.toObject(message.channels[j], options);
            }
            return object;
        };

        /**
         * Converts this NodeInfo to JSON.
         * @function toJSON
         * @memberof lnrpc.NodeInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeInfo;
    })();

    lnrpc.LightningNode = (function() {

        /**
         * Properties of a LightningNode.
         * @memberof lnrpc
         * @interface ILightningNode
         * @property {number|null} [lastUpdate] LightningNode lastUpdate
         * @property {string|null} [pubKey] LightningNode pubKey
         * @property {string|null} [alias] LightningNode alias
         * @property {Array.<lnrpc.INodeAddress>|null} [addresses] LightningNode addresses
         * @property {string|null} [color] LightningNode color
         * @property {Object.<string,lnrpc.IFeature>|null} [features] LightningNode features
         */

        /**
         * Constructs a new LightningNode.
         * @memberof lnrpc
         * @classdesc Represents a LightningNode.
         * @implements ILightningNode
         * @constructor
         * @param {lnrpc.ILightningNode=} [properties] Properties to set
         */
        function LightningNode(properties) {
            this.addresses = [];
            this.features = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LightningNode lastUpdate.
         * @member {number} lastUpdate
         * @memberof lnrpc.LightningNode
         * @instance
         */
        LightningNode.prototype.lastUpdate = 0;

        /**
         * LightningNode pubKey.
         * @member {string} pubKey
         * @memberof lnrpc.LightningNode
         * @instance
         */
        LightningNode.prototype.pubKey = "";

        /**
         * LightningNode alias.
         * @member {string} alias
         * @memberof lnrpc.LightningNode
         * @instance
         */
        LightningNode.prototype.alias = "";

        /**
         * LightningNode addresses.
         * @member {Array.<lnrpc.INodeAddress>} addresses
         * @memberof lnrpc.LightningNode
         * @instance
         */
        LightningNode.prototype.addresses = $util.emptyArray;

        /**
         * LightningNode color.
         * @member {string} color
         * @memberof lnrpc.LightningNode
         * @instance
         */
        LightningNode.prototype.color = "";

        /**
         * LightningNode features.
         * @member {Object.<string,lnrpc.IFeature>} features
         * @memberof lnrpc.LightningNode
         * @instance
         */
        LightningNode.prototype.features = $util.emptyObject;

        /**
         * Creates a new LightningNode instance using the specified properties.
         * @function create
         * @memberof lnrpc.LightningNode
         * @static
         * @param {lnrpc.ILightningNode=} [properties] Properties to set
         * @returns {lnrpc.LightningNode} LightningNode instance
         */
        LightningNode.create = function create(properties) {
            return new LightningNode(properties);
        };

        /**
         * Encodes the specified LightningNode message. Does not implicitly {@link lnrpc.LightningNode.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.LightningNode
         * @static
         * @param {lnrpc.ILightningNode} message LightningNode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LightningNode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lastUpdate != null && Object.hasOwnProperty.call(message, "lastUpdate"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.lastUpdate);
            if (message.pubKey != null && Object.hasOwnProperty.call(message, "pubKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.pubKey);
            if (message.alias != null && Object.hasOwnProperty.call(message, "alias"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.alias);
            if (message.addresses != null && message.addresses.length)
                for (let i = 0; i < message.addresses.length; ++i)
                    $root.lnrpc.NodeAddress.encode(message.addresses[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                for (let keys = Object.keys(message.features), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.lnrpc.Feature.encode(message.features[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified LightningNode message, length delimited. Does not implicitly {@link lnrpc.LightningNode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.LightningNode
         * @static
         * @param {lnrpc.ILightningNode} message LightningNode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LightningNode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LightningNode message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.LightningNode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.LightningNode} LightningNode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LightningNode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.LightningNode(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.lastUpdate = reader.uint32();
                        break;
                    }
                case 2: {
                        message.pubKey = reader.string();
                        break;
                    }
                case 3: {
                        message.alias = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push($root.lnrpc.NodeAddress.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.color = reader.string();
                        break;
                    }
                case 6: {
                        if (message.features === $util.emptyObject)
                            message.features = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.lnrpc.Feature.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.features[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LightningNode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.LightningNode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.LightningNode} LightningNode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LightningNode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LightningNode message.
         * @function verify
         * @memberof lnrpc.LightningNode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LightningNode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.lastUpdate != null && message.hasOwnProperty("lastUpdate"))
                if (!$util.isInteger(message.lastUpdate))
                    return "lastUpdate: integer expected";
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                if (!$util.isString(message.pubKey))
                    return "pubKey: string expected";
            if (message.alias != null && message.hasOwnProperty("alias"))
                if (!$util.isString(message.alias))
                    return "alias: string expected";
            if (message.addresses != null && message.hasOwnProperty("addresses")) {
                if (!Array.isArray(message.addresses))
                    return "addresses: array expected";
                for (let i = 0; i < message.addresses.length; ++i) {
                    let error = $root.lnrpc.NodeAddress.verify(message.addresses[i]);
                    if (error)
                        return "addresses." + error;
                }
            }
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isString(message.color))
                    return "color: string expected";
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!$util.isObject(message.features))
                    return "features: object expected";
                let key = Object.keys(message.features);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "features: integer key{k:uint32} expected";
                    {
                        let error = $root.lnrpc.Feature.verify(message.features[key[i]]);
                        if (error)
                            return "features." + error;
                    }
                }
            }
            return null;
        };

        /**
         * Creates a LightningNode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.LightningNode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.LightningNode} LightningNode
         */
        LightningNode.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.LightningNode)
                return object;
            let message = new $root.lnrpc.LightningNode();
            if (object.lastUpdate != null)
                message.lastUpdate = object.lastUpdate >>> 0;
            if (object.pubKey != null)
                message.pubKey = String(object.pubKey);
            if (object.alias != null)
                message.alias = String(object.alias);
            if (object.addresses) {
                if (!Array.isArray(object.addresses))
                    throw TypeError(".lnrpc.LightningNode.addresses: array expected");
                message.addresses = [];
                for (let i = 0; i < object.addresses.length; ++i) {
                    if (typeof object.addresses[i] !== "object")
                        throw TypeError(".lnrpc.LightningNode.addresses: object expected");
                    message.addresses[i] = $root.lnrpc.NodeAddress.fromObject(object.addresses[i]);
                }
            }
            if (object.color != null)
                message.color = String(object.color);
            if (object.features) {
                if (typeof object.features !== "object")
                    throw TypeError(".lnrpc.LightningNode.features: object expected");
                message.features = {};
                for (let keys = Object.keys(object.features), i = 0; i < keys.length; ++i) {
                    if (typeof object.features[keys[i]] !== "object")
                        throw TypeError(".lnrpc.LightningNode.features: object expected");
                    message.features[keys[i]] = $root.lnrpc.Feature.fromObject(object.features[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a LightningNode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.LightningNode
         * @static
         * @param {lnrpc.LightningNode} message LightningNode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LightningNode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.addresses = [];
            if (options.objects || options.defaults)
                object.features = {};
            if (options.defaults) {
                object.lastUpdate = 0;
                object.pubKey = "";
                object.alias = "";
                object.color = "";
            }
            if (message.lastUpdate != null && message.hasOwnProperty("lastUpdate"))
                object.lastUpdate = message.lastUpdate;
            if (message.pubKey != null && message.hasOwnProperty("pubKey"))
                object.pubKey = message.pubKey;
            if (message.alias != null && message.hasOwnProperty("alias"))
                object.alias = message.alias;
            if (message.addresses && message.addresses.length) {
                object.addresses = [];
                for (let j = 0; j < message.addresses.length; ++j)
                    object.addresses[j] = $root.lnrpc.NodeAddress.toObject(message.addresses[j], options);
            }
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = message.color;
            let keys2;
            if (message.features && (keys2 = Object.keys(message.features)).length) {
                object.features = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.features[keys2[j]] = $root.lnrpc.Feature.toObject(message.features[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this LightningNode to JSON.
         * @function toJSON
         * @memberof lnrpc.LightningNode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LightningNode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LightningNode;
    })();

    lnrpc.NodeAddress = (function() {

        /**
         * Properties of a NodeAddress.
         * @memberof lnrpc
         * @interface INodeAddress
         * @property {string|null} [network] NodeAddress network
         * @property {string|null} [addr] NodeAddress addr
         */

        /**
         * Constructs a new NodeAddress.
         * @memberof lnrpc
         * @classdesc Represents a NodeAddress.
         * @implements INodeAddress
         * @constructor
         * @param {lnrpc.INodeAddress=} [properties] Properties to set
         */
        function NodeAddress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeAddress network.
         * @member {string} network
         * @memberof lnrpc.NodeAddress
         * @instance
         */
        NodeAddress.prototype.network = "";

        /**
         * NodeAddress addr.
         * @member {string} addr
         * @memberof lnrpc.NodeAddress
         * @instance
         */
        NodeAddress.prototype.addr = "";

        /**
         * Creates a new NodeAddress instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {lnrpc.INodeAddress=} [properties] Properties to set
         * @returns {lnrpc.NodeAddress} NodeAddress instance
         */
        NodeAddress.create = function create(properties) {
            return new NodeAddress(properties);
        };

        /**
         * Encodes the specified NodeAddress message. Does not implicitly {@link lnrpc.NodeAddress.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {lnrpc.INodeAddress} message NodeAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeAddress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.network != null && Object.hasOwnProperty.call(message, "network"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.network);
            if (message.addr != null && Object.hasOwnProperty.call(message, "addr"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.addr);
            return writer;
        };

        /**
         * Encodes the specified NodeAddress message, length delimited. Does not implicitly {@link lnrpc.NodeAddress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {lnrpc.INodeAddress} message NodeAddress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeAddress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeAddress message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodeAddress} NodeAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeAddress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodeAddress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.network = reader.string();
                        break;
                    }
                case 2: {
                        message.addr = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeAddress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodeAddress} NodeAddress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeAddress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeAddress message.
         * @function verify
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeAddress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.network != null && message.hasOwnProperty("network"))
                if (!$util.isString(message.network))
                    return "network: string expected";
            if (message.addr != null && message.hasOwnProperty("addr"))
                if (!$util.isString(message.addr))
                    return "addr: string expected";
            return null;
        };

        /**
         * Creates a NodeAddress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodeAddress} NodeAddress
         */
        NodeAddress.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodeAddress)
                return object;
            let message = new $root.lnrpc.NodeAddress();
            if (object.network != null)
                message.network = String(object.network);
            if (object.addr != null)
                message.addr = String(object.addr);
            return message;
        };

        /**
         * Creates a plain object from a NodeAddress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodeAddress
         * @static
         * @param {lnrpc.NodeAddress} message NodeAddress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeAddress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.network = "";
                object.addr = "";
            }
            if (message.network != null && message.hasOwnProperty("network"))
                object.network = message.network;
            if (message.addr != null && message.hasOwnProperty("addr"))
                object.addr = message.addr;
            return object;
        };

        /**
         * Converts this NodeAddress to JSON.
         * @function toJSON
         * @memberof lnrpc.NodeAddress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeAddress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeAddress;
    })();

    lnrpc.RoutingPolicy = (function() {

        /**
         * Properties of a RoutingPolicy.
         * @memberof lnrpc
         * @interface IRoutingPolicy
         * @property {number|null} [timeLockDelta] RoutingPolicy timeLockDelta
         * @property {Long|null} [minHtlc] RoutingPolicy minHtlc
         * @property {Long|null} [feeBaseMsat] RoutingPolicy feeBaseMsat
         * @property {Long|null} [feeRateMilliMsat] RoutingPolicy feeRateMilliMsat
         * @property {boolean|null} [disabled] RoutingPolicy disabled
         * @property {Long|null} [maxHtlcMsat] RoutingPolicy maxHtlcMsat
         * @property {number|null} [lastUpdate] RoutingPolicy lastUpdate
         */

        /**
         * Constructs a new RoutingPolicy.
         * @memberof lnrpc
         * @classdesc Represents a RoutingPolicy.
         * @implements IRoutingPolicy
         * @constructor
         * @param {lnrpc.IRoutingPolicy=} [properties] Properties to set
         */
        function RoutingPolicy(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoutingPolicy timeLockDelta.
         * @member {number} timeLockDelta
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.timeLockDelta = 0;

        /**
         * RoutingPolicy minHtlc.
         * @member {Long} minHtlc
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.minHtlc = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RoutingPolicy feeBaseMsat.
         * @member {Long} feeBaseMsat
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.feeBaseMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RoutingPolicy feeRateMilliMsat.
         * @member {Long} feeRateMilliMsat
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.feeRateMilliMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RoutingPolicy disabled.
         * @member {boolean} disabled
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.disabled = false;

        /**
         * RoutingPolicy maxHtlcMsat.
         * @member {Long} maxHtlcMsat
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.maxHtlcMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RoutingPolicy lastUpdate.
         * @member {number} lastUpdate
         * @memberof lnrpc.RoutingPolicy
         * @instance
         */
        RoutingPolicy.prototype.lastUpdate = 0;

        /**
         * Creates a new RoutingPolicy instance using the specified properties.
         * @function create
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {lnrpc.IRoutingPolicy=} [properties] Properties to set
         * @returns {lnrpc.RoutingPolicy} RoutingPolicy instance
         */
        RoutingPolicy.create = function create(properties) {
            return new RoutingPolicy(properties);
        };

        /**
         * Encodes the specified RoutingPolicy message. Does not implicitly {@link lnrpc.RoutingPolicy.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {lnrpc.IRoutingPolicy} message RoutingPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoutingPolicy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeLockDelta != null && Object.hasOwnProperty.call(message, "timeLockDelta"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.timeLockDelta);
            if (message.minHtlc != null && Object.hasOwnProperty.call(message, "minHtlc"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.minHtlc);
            if (message.feeBaseMsat != null && Object.hasOwnProperty.call(message, "feeBaseMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.feeBaseMsat);
            if (message.feeRateMilliMsat != null && Object.hasOwnProperty.call(message, "feeRateMilliMsat"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.feeRateMilliMsat);
            if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.disabled);
            if (message.maxHtlcMsat != null && Object.hasOwnProperty.call(message, "maxHtlcMsat"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.maxHtlcMsat);
            if (message.lastUpdate != null && Object.hasOwnProperty.call(message, "lastUpdate"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.lastUpdate);
            return writer;
        };

        /**
         * Encodes the specified RoutingPolicy message, length delimited. Does not implicitly {@link lnrpc.RoutingPolicy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {lnrpc.IRoutingPolicy} message RoutingPolicy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoutingPolicy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoutingPolicy message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RoutingPolicy} RoutingPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoutingPolicy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RoutingPolicy();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.timeLockDelta = reader.uint32();
                        break;
                    }
                case 2: {
                        message.minHtlc = reader.int64();
                        break;
                    }
                case 3: {
                        message.feeBaseMsat = reader.int64();
                        break;
                    }
                case 4: {
                        message.feeRateMilliMsat = reader.int64();
                        break;
                    }
                case 5: {
                        message.disabled = reader.bool();
                        break;
                    }
                case 6: {
                        message.maxHtlcMsat = reader.uint64();
                        break;
                    }
                case 7: {
                        message.lastUpdate = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoutingPolicy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RoutingPolicy} RoutingPolicy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoutingPolicy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoutingPolicy message.
         * @function verify
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoutingPolicy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeLockDelta != null && message.hasOwnProperty("timeLockDelta"))
                if (!$util.isInteger(message.timeLockDelta))
                    return "timeLockDelta: integer expected";
            if (message.minHtlc != null && message.hasOwnProperty("minHtlc"))
                if (!$util.isInteger(message.minHtlc) && !(message.minHtlc && $util.isInteger(message.minHtlc.low) && $util.isInteger(message.minHtlc.high)))
                    return "minHtlc: integer|Long expected";
            if (message.feeBaseMsat != null && message.hasOwnProperty("feeBaseMsat"))
                if (!$util.isInteger(message.feeBaseMsat) && !(message.feeBaseMsat && $util.isInteger(message.feeBaseMsat.low) && $util.isInteger(message.feeBaseMsat.high)))
                    return "feeBaseMsat: integer|Long expected";
            if (message.feeRateMilliMsat != null && message.hasOwnProperty("feeRateMilliMsat"))
                if (!$util.isInteger(message.feeRateMilliMsat) && !(message.feeRateMilliMsat && $util.isInteger(message.feeRateMilliMsat.low) && $util.isInteger(message.feeRateMilliMsat.high)))
                    return "feeRateMilliMsat: integer|Long expected";
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                if (typeof message.disabled !== "boolean")
                    return "disabled: boolean expected";
            if (message.maxHtlcMsat != null && message.hasOwnProperty("maxHtlcMsat"))
                if (!$util.isInteger(message.maxHtlcMsat) && !(message.maxHtlcMsat && $util.isInteger(message.maxHtlcMsat.low) && $util.isInteger(message.maxHtlcMsat.high)))
                    return "maxHtlcMsat: integer|Long expected";
            if (message.lastUpdate != null && message.hasOwnProperty("lastUpdate"))
                if (!$util.isInteger(message.lastUpdate))
                    return "lastUpdate: integer expected";
            return null;
        };

        /**
         * Creates a RoutingPolicy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RoutingPolicy} RoutingPolicy
         */
        RoutingPolicy.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RoutingPolicy)
                return object;
            let message = new $root.lnrpc.RoutingPolicy();
            if (object.timeLockDelta != null)
                message.timeLockDelta = object.timeLockDelta >>> 0;
            if (object.minHtlc != null)
                if ($util.Long)
                    (message.minHtlc = $util.Long.fromValue(object.minHtlc)).unsigned = false;
                else if (typeof object.minHtlc === "string")
                    message.minHtlc = parseInt(object.minHtlc, 10);
                else if (typeof object.minHtlc === "number")
                    message.minHtlc = object.minHtlc;
                else if (typeof object.minHtlc === "object")
                    message.minHtlc = new $util.LongBits(object.minHtlc.low >>> 0, object.minHtlc.high >>> 0).toNumber();
            if (object.feeBaseMsat != null)
                if ($util.Long)
                    (message.feeBaseMsat = $util.Long.fromValue(object.feeBaseMsat)).unsigned = false;
                else if (typeof object.feeBaseMsat === "string")
                    message.feeBaseMsat = parseInt(object.feeBaseMsat, 10);
                else if (typeof object.feeBaseMsat === "number")
                    message.feeBaseMsat = object.feeBaseMsat;
                else if (typeof object.feeBaseMsat === "object")
                    message.feeBaseMsat = new $util.LongBits(object.feeBaseMsat.low >>> 0, object.feeBaseMsat.high >>> 0).toNumber();
            if (object.feeRateMilliMsat != null)
                if ($util.Long)
                    (message.feeRateMilliMsat = $util.Long.fromValue(object.feeRateMilliMsat)).unsigned = false;
                else if (typeof object.feeRateMilliMsat === "string")
                    message.feeRateMilliMsat = parseInt(object.feeRateMilliMsat, 10);
                else if (typeof object.feeRateMilliMsat === "number")
                    message.feeRateMilliMsat = object.feeRateMilliMsat;
                else if (typeof object.feeRateMilliMsat === "object")
                    message.feeRateMilliMsat = new $util.LongBits(object.feeRateMilliMsat.low >>> 0, object.feeRateMilliMsat.high >>> 0).toNumber();
            if (object.disabled != null)
                message.disabled = Boolean(object.disabled);
            if (object.maxHtlcMsat != null)
                if ($util.Long)
                    (message.maxHtlcMsat = $util.Long.fromValue(object.maxHtlcMsat)).unsigned = true;
                else if (typeof object.maxHtlcMsat === "string")
                    message.maxHtlcMsat = parseInt(object.maxHtlcMsat, 10);
                else if (typeof object.maxHtlcMsat === "number")
                    message.maxHtlcMsat = object.maxHtlcMsat;
                else if (typeof object.maxHtlcMsat === "object")
                    message.maxHtlcMsat = new $util.LongBits(object.maxHtlcMsat.low >>> 0, object.maxHtlcMsat.high >>> 0).toNumber(true);
            if (object.lastUpdate != null)
                message.lastUpdate = object.lastUpdate >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RoutingPolicy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RoutingPolicy
         * @static
         * @param {lnrpc.RoutingPolicy} message RoutingPolicy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoutingPolicy.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.timeLockDelta = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minHtlc = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlc = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeBaseMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeBaseMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeRateMilliMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeRateMilliMsat = options.longs === String ? "0" : 0;
                object.disabled = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxHtlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxHtlcMsat = options.longs === String ? "0" : 0;
                object.lastUpdate = 0;
            }
            if (message.timeLockDelta != null && message.hasOwnProperty("timeLockDelta"))
                object.timeLockDelta = message.timeLockDelta;
            if (message.minHtlc != null && message.hasOwnProperty("minHtlc"))
                if (typeof message.minHtlc === "number")
                    object.minHtlc = options.longs === String ? String(message.minHtlc) : message.minHtlc;
                else
                    object.minHtlc = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlc) : options.longs === Number ? new $util.LongBits(message.minHtlc.low >>> 0, message.minHtlc.high >>> 0).toNumber() : message.minHtlc;
            if (message.feeBaseMsat != null && message.hasOwnProperty("feeBaseMsat"))
                if (typeof message.feeBaseMsat === "number")
                    object.feeBaseMsat = options.longs === String ? String(message.feeBaseMsat) : message.feeBaseMsat;
                else
                    object.feeBaseMsat = options.longs === String ? $util.Long.prototype.toString.call(message.feeBaseMsat) : options.longs === Number ? new $util.LongBits(message.feeBaseMsat.low >>> 0, message.feeBaseMsat.high >>> 0).toNumber() : message.feeBaseMsat;
            if (message.feeRateMilliMsat != null && message.hasOwnProperty("feeRateMilliMsat"))
                if (typeof message.feeRateMilliMsat === "number")
                    object.feeRateMilliMsat = options.longs === String ? String(message.feeRateMilliMsat) : message.feeRateMilliMsat;
                else
                    object.feeRateMilliMsat = options.longs === String ? $util.Long.prototype.toString.call(message.feeRateMilliMsat) : options.longs === Number ? new $util.LongBits(message.feeRateMilliMsat.low >>> 0, message.feeRateMilliMsat.high >>> 0).toNumber() : message.feeRateMilliMsat;
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                object.disabled = message.disabled;
            if (message.maxHtlcMsat != null && message.hasOwnProperty("maxHtlcMsat"))
                if (typeof message.maxHtlcMsat === "number")
                    object.maxHtlcMsat = options.longs === String ? String(message.maxHtlcMsat) : message.maxHtlcMsat;
                else
                    object.maxHtlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.maxHtlcMsat) : options.longs === Number ? new $util.LongBits(message.maxHtlcMsat.low >>> 0, message.maxHtlcMsat.high >>> 0).toNumber(true) : message.maxHtlcMsat;
            if (message.lastUpdate != null && message.hasOwnProperty("lastUpdate"))
                object.lastUpdate = message.lastUpdate;
            return object;
        };

        /**
         * Converts this RoutingPolicy to JSON.
         * @function toJSON
         * @memberof lnrpc.RoutingPolicy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoutingPolicy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoutingPolicy;
    })();

    lnrpc.ChannelEdge = (function() {

        /**
         * Properties of a ChannelEdge.
         * @memberof lnrpc
         * @interface IChannelEdge
         * @property {Long|null} [channelId] ChannelEdge channelId
         * @property {string|null} [chanPoint] ChannelEdge chanPoint
         * @property {number|null} [lastUpdate] ChannelEdge lastUpdate
         * @property {string|null} [node1Pub] ChannelEdge node1Pub
         * @property {string|null} [node2Pub] ChannelEdge node2Pub
         * @property {Long|null} [capacity] ChannelEdge capacity
         * @property {lnrpc.IRoutingPolicy|null} [node1Policy] ChannelEdge node1Policy
         * @property {lnrpc.IRoutingPolicy|null} [node2Policy] ChannelEdge node2Policy
         */

        /**
         * Constructs a new ChannelEdge.
         * @memberof lnrpc
         * @classdesc Represents a ChannelEdge.
         * @implements IChannelEdge
         * @constructor
         * @param {lnrpc.IChannelEdge=} [properties] Properties to set
         */
        function ChannelEdge(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelEdge channelId.
         * @member {Long} channelId
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.channelId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelEdge chanPoint.
         * @member {string} chanPoint
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.chanPoint = "";

        /**
         * ChannelEdge lastUpdate.
         * @member {number} lastUpdate
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.lastUpdate = 0;

        /**
         * ChannelEdge node1Pub.
         * @member {string} node1Pub
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.node1Pub = "";

        /**
         * ChannelEdge node2Pub.
         * @member {string} node2Pub
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.node2Pub = "";

        /**
         * ChannelEdge capacity.
         * @member {Long} capacity
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelEdge node1Policy.
         * @member {lnrpc.IRoutingPolicy|null|undefined} node1Policy
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.node1Policy = null;

        /**
         * ChannelEdge node2Policy.
         * @member {lnrpc.IRoutingPolicy|null|undefined} node2Policy
         * @memberof lnrpc.ChannelEdge
         * @instance
         */
        ChannelEdge.prototype.node2Policy = null;

        /**
         * Creates a new ChannelEdge instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {lnrpc.IChannelEdge=} [properties] Properties to set
         * @returns {lnrpc.ChannelEdge} ChannelEdge instance
         */
        ChannelEdge.create = function create(properties) {
            return new ChannelEdge(properties);
        };

        /**
         * Encodes the specified ChannelEdge message. Does not implicitly {@link lnrpc.ChannelEdge.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {lnrpc.IChannelEdge} message ChannelEdge message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEdge.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelId != null && Object.hasOwnProperty.call(message, "channelId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.channelId);
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.chanPoint);
            if (message.lastUpdate != null && Object.hasOwnProperty.call(message, "lastUpdate"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.lastUpdate);
            if (message.node1Pub != null && Object.hasOwnProperty.call(message, "node1Pub"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.node1Pub);
            if (message.node2Pub != null && Object.hasOwnProperty.call(message, "node2Pub"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.node2Pub);
            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.capacity);
            if (message.node1Policy != null && Object.hasOwnProperty.call(message, "node1Policy"))
                $root.lnrpc.RoutingPolicy.encode(message.node1Policy, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.node2Policy != null && Object.hasOwnProperty.call(message, "node2Policy"))
                $root.lnrpc.RoutingPolicy.encode(message.node2Policy, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelEdge message, length delimited. Does not implicitly {@link lnrpc.ChannelEdge.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {lnrpc.IChannelEdge} message ChannelEdge message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEdge.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelEdge message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelEdge} ChannelEdge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEdge.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelEdge();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.chanPoint = reader.string();
                        break;
                    }
                case 3: {
                        message.lastUpdate = reader.uint32();
                        break;
                    }
                case 4: {
                        message.node1Pub = reader.string();
                        break;
                    }
                case 5: {
                        message.node2Pub = reader.string();
                        break;
                    }
                case 6: {
                        message.capacity = reader.int64();
                        break;
                    }
                case 7: {
                        message.node1Policy = $root.lnrpc.RoutingPolicy.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.node2Policy = $root.lnrpc.RoutingPolicy.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelEdge message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelEdge} ChannelEdge
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEdge.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelEdge message.
         * @function verify
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelEdge.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (!$util.isInteger(message.channelId) && !(message.channelId && $util.isInteger(message.channelId.low) && $util.isInteger(message.channelId.high)))
                    return "channelId: integer|Long expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                if (!$util.isString(message.chanPoint))
                    return "chanPoint: string expected";
            if (message.lastUpdate != null && message.hasOwnProperty("lastUpdate"))
                if (!$util.isInteger(message.lastUpdate))
                    return "lastUpdate: integer expected";
            if (message.node1Pub != null && message.hasOwnProperty("node1Pub"))
                if (!$util.isString(message.node1Pub))
                    return "node1Pub: string expected";
            if (message.node2Pub != null && message.hasOwnProperty("node2Pub"))
                if (!$util.isString(message.node2Pub))
                    return "node2Pub: string expected";
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (!$util.isInteger(message.capacity) && !(message.capacity && $util.isInteger(message.capacity.low) && $util.isInteger(message.capacity.high)))
                    return "capacity: integer|Long expected";
            if (message.node1Policy != null && message.hasOwnProperty("node1Policy")) {
                let error = $root.lnrpc.RoutingPolicy.verify(message.node1Policy);
                if (error)
                    return "node1Policy." + error;
            }
            if (message.node2Policy != null && message.hasOwnProperty("node2Policy")) {
                let error = $root.lnrpc.RoutingPolicy.verify(message.node2Policy);
                if (error)
                    return "node2Policy." + error;
            }
            return null;
        };

        /**
         * Creates a ChannelEdge message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelEdge} ChannelEdge
         */
        ChannelEdge.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelEdge)
                return object;
            let message = new $root.lnrpc.ChannelEdge();
            if (object.channelId != null)
                if ($util.Long)
                    (message.channelId = $util.Long.fromValue(object.channelId)).unsigned = true;
                else if (typeof object.channelId === "string")
                    message.channelId = parseInt(object.channelId, 10);
                else if (typeof object.channelId === "number")
                    message.channelId = object.channelId;
                else if (typeof object.channelId === "object")
                    message.channelId = new $util.LongBits(object.channelId.low >>> 0, object.channelId.high >>> 0).toNumber(true);
            if (object.chanPoint != null)
                message.chanPoint = String(object.chanPoint);
            if (object.lastUpdate != null)
                message.lastUpdate = object.lastUpdate >>> 0;
            if (object.node1Pub != null)
                message.node1Pub = String(object.node1Pub);
            if (object.node2Pub != null)
                message.node2Pub = String(object.node2Pub);
            if (object.capacity != null)
                if ($util.Long)
                    (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false;
                else if (typeof object.capacity === "string")
                    message.capacity = parseInt(object.capacity, 10);
                else if (typeof object.capacity === "number")
                    message.capacity = object.capacity;
                else if (typeof object.capacity === "object")
                    message.capacity = new $util.LongBits(object.capacity.low >>> 0, object.capacity.high >>> 0).toNumber();
            if (object.node1Policy != null) {
                if (typeof object.node1Policy !== "object")
                    throw TypeError(".lnrpc.ChannelEdge.node1Policy: object expected");
                message.node1Policy = $root.lnrpc.RoutingPolicy.fromObject(object.node1Policy);
            }
            if (object.node2Policy != null) {
                if (typeof object.node2Policy !== "object")
                    throw TypeError(".lnrpc.ChannelEdge.node2Policy: object expected");
                message.node2Policy = $root.lnrpc.RoutingPolicy.fromObject(object.node2Policy);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelEdge message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelEdge
         * @static
         * @param {lnrpc.ChannelEdge} message ChannelEdge
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelEdge.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.channelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.channelId = options.longs === String ? "0" : 0;
                object.chanPoint = "";
                object.lastUpdate = 0;
                object.node1Pub = "";
                object.node2Pub = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.capacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.capacity = options.longs === String ? "0" : 0;
                object.node1Policy = null;
                object.node2Policy = null;
            }
            if (message.channelId != null && message.hasOwnProperty("channelId"))
                if (typeof message.channelId === "number")
                    object.channelId = options.longs === String ? String(message.channelId) : message.channelId;
                else
                    object.channelId = options.longs === String ? $util.Long.prototype.toString.call(message.channelId) : options.longs === Number ? new $util.LongBits(message.channelId.low >>> 0, message.channelId.high >>> 0).toNumber(true) : message.channelId;
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = message.chanPoint;
            if (message.lastUpdate != null && message.hasOwnProperty("lastUpdate"))
                object.lastUpdate = message.lastUpdate;
            if (message.node1Pub != null && message.hasOwnProperty("node1Pub"))
                object.node1Pub = message.node1Pub;
            if (message.node2Pub != null && message.hasOwnProperty("node2Pub"))
                object.node2Pub = message.node2Pub;
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (typeof message.capacity === "number")
                    object.capacity = options.longs === String ? String(message.capacity) : message.capacity;
                else
                    object.capacity = options.longs === String ? $util.Long.prototype.toString.call(message.capacity) : options.longs === Number ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber() : message.capacity;
            if (message.node1Policy != null && message.hasOwnProperty("node1Policy"))
                object.node1Policy = $root.lnrpc.RoutingPolicy.toObject(message.node1Policy, options);
            if (message.node2Policy != null && message.hasOwnProperty("node2Policy"))
                object.node2Policy = $root.lnrpc.RoutingPolicy.toObject(message.node2Policy, options);
            return object;
        };

        /**
         * Converts this ChannelEdge to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelEdge
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelEdge.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelEdge;
    })();

    lnrpc.ChannelGraphRequest = (function() {

        /**
         * Properties of a ChannelGraphRequest.
         * @memberof lnrpc
         * @interface IChannelGraphRequest
         * @property {boolean|null} [includeUnannounced] ChannelGraphRequest includeUnannounced
         */

        /**
         * Constructs a new ChannelGraphRequest.
         * @memberof lnrpc
         * @classdesc Represents a ChannelGraphRequest.
         * @implements IChannelGraphRequest
         * @constructor
         * @param {lnrpc.IChannelGraphRequest=} [properties] Properties to set
         */
        function ChannelGraphRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelGraphRequest includeUnannounced.
         * @member {boolean} includeUnannounced
         * @memberof lnrpc.ChannelGraphRequest
         * @instance
         */
        ChannelGraphRequest.prototype.includeUnannounced = false;

        /**
         * Creates a new ChannelGraphRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {lnrpc.IChannelGraphRequest=} [properties] Properties to set
         * @returns {lnrpc.ChannelGraphRequest} ChannelGraphRequest instance
         */
        ChannelGraphRequest.create = function create(properties) {
            return new ChannelGraphRequest(properties);
        };

        /**
         * Encodes the specified ChannelGraphRequest message. Does not implicitly {@link lnrpc.ChannelGraphRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {lnrpc.IChannelGraphRequest} message ChannelGraphRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelGraphRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeUnannounced != null && Object.hasOwnProperty.call(message, "includeUnannounced"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeUnannounced);
            return writer;
        };

        /**
         * Encodes the specified ChannelGraphRequest message, length delimited. Does not implicitly {@link lnrpc.ChannelGraphRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {lnrpc.IChannelGraphRequest} message ChannelGraphRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelGraphRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelGraphRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelGraphRequest} ChannelGraphRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelGraphRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelGraphRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.includeUnannounced = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelGraphRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelGraphRequest} ChannelGraphRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelGraphRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelGraphRequest message.
         * @function verify
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelGraphRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeUnannounced != null && message.hasOwnProperty("includeUnannounced"))
                if (typeof message.includeUnannounced !== "boolean")
                    return "includeUnannounced: boolean expected";
            return null;
        };

        /**
         * Creates a ChannelGraphRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelGraphRequest} ChannelGraphRequest
         */
        ChannelGraphRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelGraphRequest)
                return object;
            let message = new $root.lnrpc.ChannelGraphRequest();
            if (object.includeUnannounced != null)
                message.includeUnannounced = Boolean(object.includeUnannounced);
            return message;
        };

        /**
         * Creates a plain object from a ChannelGraphRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelGraphRequest
         * @static
         * @param {lnrpc.ChannelGraphRequest} message ChannelGraphRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelGraphRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.includeUnannounced = false;
            if (message.includeUnannounced != null && message.hasOwnProperty("includeUnannounced"))
                object.includeUnannounced = message.includeUnannounced;
            return object;
        };

        /**
         * Converts this ChannelGraphRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelGraphRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelGraphRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelGraphRequest;
    })();

    lnrpc.ChannelGraph = (function() {

        /**
         * Properties of a ChannelGraph.
         * @memberof lnrpc
         * @interface IChannelGraph
         * @property {Array.<lnrpc.ILightningNode>|null} [nodes] ChannelGraph nodes
         * @property {Array.<lnrpc.IChannelEdge>|null} [edges] ChannelGraph edges
         */

        /**
         * Constructs a new ChannelGraph.
         * @memberof lnrpc
         * @classdesc Represents a ChannelGraph.
         * @implements IChannelGraph
         * @constructor
         * @param {lnrpc.IChannelGraph=} [properties] Properties to set
         */
        function ChannelGraph(properties) {
            this.nodes = [];
            this.edges = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelGraph nodes.
         * @member {Array.<lnrpc.ILightningNode>} nodes
         * @memberof lnrpc.ChannelGraph
         * @instance
         */
        ChannelGraph.prototype.nodes = $util.emptyArray;

        /**
         * ChannelGraph edges.
         * @member {Array.<lnrpc.IChannelEdge>} edges
         * @memberof lnrpc.ChannelGraph
         * @instance
         */
        ChannelGraph.prototype.edges = $util.emptyArray;

        /**
         * Creates a new ChannelGraph instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {lnrpc.IChannelGraph=} [properties] Properties to set
         * @returns {lnrpc.ChannelGraph} ChannelGraph instance
         */
        ChannelGraph.create = function create(properties) {
            return new ChannelGraph(properties);
        };

        /**
         * Encodes the specified ChannelGraph message. Does not implicitly {@link lnrpc.ChannelGraph.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {lnrpc.IChannelGraph} message ChannelGraph message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelGraph.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodes != null && message.nodes.length)
                for (let i = 0; i < message.nodes.length; ++i)
                    $root.lnrpc.LightningNode.encode(message.nodes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.edges != null && message.edges.length)
                for (let i = 0; i < message.edges.length; ++i)
                    $root.lnrpc.ChannelEdge.encode(message.edges[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelGraph message, length delimited. Does not implicitly {@link lnrpc.ChannelGraph.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {lnrpc.IChannelGraph} message ChannelGraph message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelGraph.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelGraph message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelGraph} ChannelGraph
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelGraph.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelGraph();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.nodes && message.nodes.length))
                            message.nodes = [];
                        message.nodes.push($root.lnrpc.LightningNode.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.edges && message.edges.length))
                            message.edges = [];
                        message.edges.push($root.lnrpc.ChannelEdge.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelGraph message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelGraph} ChannelGraph
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelGraph.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelGraph message.
         * @function verify
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelGraph.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodes != null && message.hasOwnProperty("nodes")) {
                if (!Array.isArray(message.nodes))
                    return "nodes: array expected";
                for (let i = 0; i < message.nodes.length; ++i) {
                    let error = $root.lnrpc.LightningNode.verify(message.nodes[i]);
                    if (error)
                        return "nodes." + error;
                }
            }
            if (message.edges != null && message.hasOwnProperty("edges")) {
                if (!Array.isArray(message.edges))
                    return "edges: array expected";
                for (let i = 0; i < message.edges.length; ++i) {
                    let error = $root.lnrpc.ChannelEdge.verify(message.edges[i]);
                    if (error)
                        return "edges." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ChannelGraph message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelGraph} ChannelGraph
         */
        ChannelGraph.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelGraph)
                return object;
            let message = new $root.lnrpc.ChannelGraph();
            if (object.nodes) {
                if (!Array.isArray(object.nodes))
                    throw TypeError(".lnrpc.ChannelGraph.nodes: array expected");
                message.nodes = [];
                for (let i = 0; i < object.nodes.length; ++i) {
                    if (typeof object.nodes[i] !== "object")
                        throw TypeError(".lnrpc.ChannelGraph.nodes: object expected");
                    message.nodes[i] = $root.lnrpc.LightningNode.fromObject(object.nodes[i]);
                }
            }
            if (object.edges) {
                if (!Array.isArray(object.edges))
                    throw TypeError(".lnrpc.ChannelGraph.edges: array expected");
                message.edges = [];
                for (let i = 0; i < object.edges.length; ++i) {
                    if (typeof object.edges[i] !== "object")
                        throw TypeError(".lnrpc.ChannelGraph.edges: object expected");
                    message.edges[i] = $root.lnrpc.ChannelEdge.fromObject(object.edges[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelGraph message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelGraph
         * @static
         * @param {lnrpc.ChannelGraph} message ChannelGraph
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelGraph.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.nodes = [];
                object.edges = [];
            }
            if (message.nodes && message.nodes.length) {
                object.nodes = [];
                for (let j = 0; j < message.nodes.length; ++j)
                    object.nodes[j] = $root.lnrpc.LightningNode.toObject(message.nodes[j], options);
            }
            if (message.edges && message.edges.length) {
                object.edges = [];
                for (let j = 0; j < message.edges.length; ++j)
                    object.edges[j] = $root.lnrpc.ChannelEdge.toObject(message.edges[j], options);
            }
            return object;
        };

        /**
         * Converts this ChannelGraph to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelGraph
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelGraph.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelGraph;
    })();

    /**
     * NodeMetricType enum.
     * @name lnrpc.NodeMetricType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} BETWEENNESS_CENTRALITY=1 BETWEENNESS_CENTRALITY value
     */
    lnrpc.NodeMetricType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "BETWEENNESS_CENTRALITY"] = 1;
        return values;
    })();

    lnrpc.NodeMetricsRequest = (function() {

        /**
         * Properties of a NodeMetricsRequest.
         * @memberof lnrpc
         * @interface INodeMetricsRequest
         * @property {Array.<lnrpc.NodeMetricType>|null} [types] NodeMetricsRequest types
         */

        /**
         * Constructs a new NodeMetricsRequest.
         * @memberof lnrpc
         * @classdesc Represents a NodeMetricsRequest.
         * @implements INodeMetricsRequest
         * @constructor
         * @param {lnrpc.INodeMetricsRequest=} [properties] Properties to set
         */
        function NodeMetricsRequest(properties) {
            this.types = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeMetricsRequest types.
         * @member {Array.<lnrpc.NodeMetricType>} types
         * @memberof lnrpc.NodeMetricsRequest
         * @instance
         */
        NodeMetricsRequest.prototype.types = $util.emptyArray;

        /**
         * Creates a new NodeMetricsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {lnrpc.INodeMetricsRequest=} [properties] Properties to set
         * @returns {lnrpc.NodeMetricsRequest} NodeMetricsRequest instance
         */
        NodeMetricsRequest.create = function create(properties) {
            return new NodeMetricsRequest(properties);
        };

        /**
         * Encodes the specified NodeMetricsRequest message. Does not implicitly {@link lnrpc.NodeMetricsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {lnrpc.INodeMetricsRequest} message NodeMetricsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeMetricsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.types != null && message.types.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.types.length; ++i)
                    writer.int32(message.types[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified NodeMetricsRequest message, length delimited. Does not implicitly {@link lnrpc.NodeMetricsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {lnrpc.INodeMetricsRequest} message NodeMetricsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeMetricsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeMetricsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodeMetricsRequest} NodeMetricsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeMetricsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodeMetricsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.types && message.types.length))
                            message.types = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.types.push(reader.int32());
                        } else
                            message.types.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeMetricsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodeMetricsRequest} NodeMetricsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeMetricsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeMetricsRequest message.
         * @function verify
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeMetricsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.types != null && message.hasOwnProperty("types")) {
                if (!Array.isArray(message.types))
                    return "types: array expected";
                for (let i = 0; i < message.types.length; ++i)
                    switch (message.types[i]) {
                    default:
                        return "types: enum value[] expected";
                    case 0:
                    case 1:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates a NodeMetricsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodeMetricsRequest} NodeMetricsRequest
         */
        NodeMetricsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodeMetricsRequest)
                return object;
            let message = new $root.lnrpc.NodeMetricsRequest();
            if (object.types) {
                if (!Array.isArray(object.types))
                    throw TypeError(".lnrpc.NodeMetricsRequest.types: array expected");
                message.types = [];
                for (let i = 0; i < object.types.length; ++i)
                    switch (object.types[i]) {
                    default:
                    case "UNKNOWN":
                    case 0:
                        message.types[i] = 0;
                        break;
                    case "BETWEENNESS_CENTRALITY":
                    case 1:
                        message.types[i] = 1;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from a NodeMetricsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodeMetricsRequest
         * @static
         * @param {lnrpc.NodeMetricsRequest} message NodeMetricsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeMetricsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.types = [];
            if (message.types && message.types.length) {
                object.types = [];
                for (let j = 0; j < message.types.length; ++j)
                    object.types[j] = options.enums === String ? $root.lnrpc.NodeMetricType[message.types[j]] : message.types[j];
            }
            return object;
        };

        /**
         * Converts this NodeMetricsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.NodeMetricsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeMetricsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeMetricsRequest;
    })();

    lnrpc.NodeMetricsResponse = (function() {

        /**
         * Properties of a NodeMetricsResponse.
         * @memberof lnrpc
         * @interface INodeMetricsResponse
         * @property {Object.<string,lnrpc.IFloatMetric>|null} [betweennessCentrality] NodeMetricsResponse betweennessCentrality
         */

        /**
         * Constructs a new NodeMetricsResponse.
         * @memberof lnrpc
         * @classdesc Represents a NodeMetricsResponse.
         * @implements INodeMetricsResponse
         * @constructor
         * @param {lnrpc.INodeMetricsResponse=} [properties] Properties to set
         */
        function NodeMetricsResponse(properties) {
            this.betweennessCentrality = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeMetricsResponse betweennessCentrality.
         * @member {Object.<string,lnrpc.IFloatMetric>} betweennessCentrality
         * @memberof lnrpc.NodeMetricsResponse
         * @instance
         */
        NodeMetricsResponse.prototype.betweennessCentrality = $util.emptyObject;

        /**
         * Creates a new NodeMetricsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {lnrpc.INodeMetricsResponse=} [properties] Properties to set
         * @returns {lnrpc.NodeMetricsResponse} NodeMetricsResponse instance
         */
        NodeMetricsResponse.create = function create(properties) {
            return new NodeMetricsResponse(properties);
        };

        /**
         * Encodes the specified NodeMetricsResponse message. Does not implicitly {@link lnrpc.NodeMetricsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {lnrpc.INodeMetricsResponse} message NodeMetricsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeMetricsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.betweennessCentrality != null && Object.hasOwnProperty.call(message, "betweennessCentrality"))
                for (let keys = Object.keys(message.betweennessCentrality), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.lnrpc.FloatMetric.encode(message.betweennessCentrality[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified NodeMetricsResponse message, length delimited. Does not implicitly {@link lnrpc.NodeMetricsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {lnrpc.INodeMetricsResponse} message NodeMetricsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeMetricsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeMetricsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodeMetricsResponse} NodeMetricsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeMetricsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodeMetricsResponse(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.betweennessCentrality === $util.emptyObject)
                            message.betweennessCentrality = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.lnrpc.FloatMetric.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.betweennessCentrality[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeMetricsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodeMetricsResponse} NodeMetricsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeMetricsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeMetricsResponse message.
         * @function verify
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeMetricsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.betweennessCentrality != null && message.hasOwnProperty("betweennessCentrality")) {
                if (!$util.isObject(message.betweennessCentrality))
                    return "betweennessCentrality: object expected";
                let key = Object.keys(message.betweennessCentrality);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.lnrpc.FloatMetric.verify(message.betweennessCentrality[key[i]]);
                    if (error)
                        return "betweennessCentrality." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NodeMetricsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodeMetricsResponse} NodeMetricsResponse
         */
        NodeMetricsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodeMetricsResponse)
                return object;
            let message = new $root.lnrpc.NodeMetricsResponse();
            if (object.betweennessCentrality) {
                if (typeof object.betweennessCentrality !== "object")
                    throw TypeError(".lnrpc.NodeMetricsResponse.betweennessCentrality: object expected");
                message.betweennessCentrality = {};
                for (let keys = Object.keys(object.betweennessCentrality), i = 0; i < keys.length; ++i) {
                    if (typeof object.betweennessCentrality[keys[i]] !== "object")
                        throw TypeError(".lnrpc.NodeMetricsResponse.betweennessCentrality: object expected");
                    message.betweennessCentrality[keys[i]] = $root.lnrpc.FloatMetric.fromObject(object.betweennessCentrality[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NodeMetricsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodeMetricsResponse
         * @static
         * @param {lnrpc.NodeMetricsResponse} message NodeMetricsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeMetricsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.betweennessCentrality = {};
            let keys2;
            if (message.betweennessCentrality && (keys2 = Object.keys(message.betweennessCentrality)).length) {
                object.betweennessCentrality = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.betweennessCentrality[keys2[j]] = $root.lnrpc.FloatMetric.toObject(message.betweennessCentrality[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this NodeMetricsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.NodeMetricsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeMetricsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeMetricsResponse;
    })();

    lnrpc.FloatMetric = (function() {

        /**
         * Properties of a FloatMetric.
         * @memberof lnrpc
         * @interface IFloatMetric
         * @property {number|null} [value] FloatMetric value
         * @property {number|null} [normalizedValue] FloatMetric normalizedValue
         */

        /**
         * Constructs a new FloatMetric.
         * @memberof lnrpc
         * @classdesc Represents a FloatMetric.
         * @implements IFloatMetric
         * @constructor
         * @param {lnrpc.IFloatMetric=} [properties] Properties to set
         */
        function FloatMetric(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FloatMetric value.
         * @member {number} value
         * @memberof lnrpc.FloatMetric
         * @instance
         */
        FloatMetric.prototype.value = 0;

        /**
         * FloatMetric normalizedValue.
         * @member {number} normalizedValue
         * @memberof lnrpc.FloatMetric
         * @instance
         */
        FloatMetric.prototype.normalizedValue = 0;

        /**
         * Creates a new FloatMetric instance using the specified properties.
         * @function create
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {lnrpc.IFloatMetric=} [properties] Properties to set
         * @returns {lnrpc.FloatMetric} FloatMetric instance
         */
        FloatMetric.create = function create(properties) {
            return new FloatMetric(properties);
        };

        /**
         * Encodes the specified FloatMetric message. Does not implicitly {@link lnrpc.FloatMetric.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {lnrpc.IFloatMetric} message FloatMetric message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FloatMetric.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.value);
            if (message.normalizedValue != null && Object.hasOwnProperty.call(message, "normalizedValue"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.normalizedValue);
            return writer;
        };

        /**
         * Encodes the specified FloatMetric message, length delimited. Does not implicitly {@link lnrpc.FloatMetric.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {lnrpc.IFloatMetric} message FloatMetric message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FloatMetric.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FloatMetric message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FloatMetric} FloatMetric
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FloatMetric.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FloatMetric();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.double();
                        break;
                    }
                case 2: {
                        message.normalizedValue = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FloatMetric message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FloatMetric} FloatMetric
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FloatMetric.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FloatMetric message.
         * @function verify
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FloatMetric.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value !== "number")
                    return "value: number expected";
            if (message.normalizedValue != null && message.hasOwnProperty("normalizedValue"))
                if (typeof message.normalizedValue !== "number")
                    return "normalizedValue: number expected";
            return null;
        };

        /**
         * Creates a FloatMetric message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FloatMetric} FloatMetric
         */
        FloatMetric.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FloatMetric)
                return object;
            let message = new $root.lnrpc.FloatMetric();
            if (object.value != null)
                message.value = Number(object.value);
            if (object.normalizedValue != null)
                message.normalizedValue = Number(object.normalizedValue);
            return message;
        };

        /**
         * Creates a plain object from a FloatMetric message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FloatMetric
         * @static
         * @param {lnrpc.FloatMetric} message FloatMetric
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FloatMetric.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.value = 0;
                object.normalizedValue = 0;
            }
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = options.json && !isFinite(message.value) ? String(message.value) : message.value;
            if (message.normalizedValue != null && message.hasOwnProperty("normalizedValue"))
                object.normalizedValue = options.json && !isFinite(message.normalizedValue) ? String(message.normalizedValue) : message.normalizedValue;
            return object;
        };

        /**
         * Converts this FloatMetric to JSON.
         * @function toJSON
         * @memberof lnrpc.FloatMetric
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FloatMetric.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FloatMetric;
    })();

    lnrpc.ChanInfoRequest = (function() {

        /**
         * Properties of a ChanInfoRequest.
         * @memberof lnrpc
         * @interface IChanInfoRequest
         * @property {Long|null} [chanId] ChanInfoRequest chanId
         */

        /**
         * Constructs a new ChanInfoRequest.
         * @memberof lnrpc
         * @classdesc Represents a ChanInfoRequest.
         * @implements IChanInfoRequest
         * @constructor
         * @param {lnrpc.IChanInfoRequest=} [properties] Properties to set
         */
        function ChanInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChanInfoRequest chanId.
         * @member {Long} chanId
         * @memberof lnrpc.ChanInfoRequest
         * @instance
         */
        ChanInfoRequest.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ChanInfoRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {lnrpc.IChanInfoRequest=} [properties] Properties to set
         * @returns {lnrpc.ChanInfoRequest} ChanInfoRequest instance
         */
        ChanInfoRequest.create = function create(properties) {
            return new ChanInfoRequest(properties);
        };

        /**
         * Encodes the specified ChanInfoRequest message. Does not implicitly {@link lnrpc.ChanInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {lnrpc.IChanInfoRequest} message ChanInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chanId);
            return writer;
        };

        /**
         * Encodes the specified ChanInfoRequest message, length delimited. Does not implicitly {@link lnrpc.ChanInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {lnrpc.IChanInfoRequest} message ChanInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChanInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChanInfoRequest} ChanInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChanInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanId = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChanInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChanInfoRequest} ChanInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChanInfoRequest message.
         * @function verify
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChanInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            return null;
        };

        /**
         * Creates a ChanInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChanInfoRequest} ChanInfoRequest
         */
        ChanInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChanInfoRequest)
                return object;
            let message = new $root.lnrpc.ChanInfoRequest();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ChanInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChanInfoRequest
         * @static
         * @param {lnrpc.ChanInfoRequest} message ChanInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChanInfoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            return object;
        };

        /**
         * Converts this ChanInfoRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ChanInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChanInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChanInfoRequest;
    })();

    lnrpc.NetworkInfoRequest = (function() {

        /**
         * Properties of a NetworkInfoRequest.
         * @memberof lnrpc
         * @interface INetworkInfoRequest
         */

        /**
         * Constructs a new NetworkInfoRequest.
         * @memberof lnrpc
         * @classdesc Represents a NetworkInfoRequest.
         * @implements INetworkInfoRequest
         * @constructor
         * @param {lnrpc.INetworkInfoRequest=} [properties] Properties to set
         */
        function NetworkInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new NetworkInfoRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {lnrpc.INetworkInfoRequest=} [properties] Properties to set
         * @returns {lnrpc.NetworkInfoRequest} NetworkInfoRequest instance
         */
        NetworkInfoRequest.create = function create(properties) {
            return new NetworkInfoRequest(properties);
        };

        /**
         * Encodes the specified NetworkInfoRequest message. Does not implicitly {@link lnrpc.NetworkInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {lnrpc.INetworkInfoRequest} message NetworkInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NetworkInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified NetworkInfoRequest message, length delimited. Does not implicitly {@link lnrpc.NetworkInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {lnrpc.INetworkInfoRequest} message NetworkInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NetworkInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NetworkInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NetworkInfoRequest} NetworkInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NetworkInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NetworkInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NetworkInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NetworkInfoRequest} NetworkInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NetworkInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NetworkInfoRequest message.
         * @function verify
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NetworkInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a NetworkInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NetworkInfoRequest} NetworkInfoRequest
         */
        NetworkInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NetworkInfoRequest)
                return object;
            return new $root.lnrpc.NetworkInfoRequest();
        };

        /**
         * Creates a plain object from a NetworkInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NetworkInfoRequest
         * @static
         * @param {lnrpc.NetworkInfoRequest} message NetworkInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NetworkInfoRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this NetworkInfoRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.NetworkInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NetworkInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NetworkInfoRequest;
    })();

    lnrpc.NetworkInfo = (function() {

        /**
         * Properties of a NetworkInfo.
         * @memberof lnrpc
         * @interface INetworkInfo
         * @property {number|null} [graphDiameter] NetworkInfo graphDiameter
         * @property {number|null} [avgOutDegree] NetworkInfo avgOutDegree
         * @property {number|null} [maxOutDegree] NetworkInfo maxOutDegree
         * @property {number|null} [numNodes] NetworkInfo numNodes
         * @property {number|null} [numChannels] NetworkInfo numChannels
         * @property {Long|null} [totalNetworkCapacity] NetworkInfo totalNetworkCapacity
         * @property {number|null} [avgChannelSize] NetworkInfo avgChannelSize
         * @property {Long|null} [minChannelSize] NetworkInfo minChannelSize
         * @property {Long|null} [maxChannelSize] NetworkInfo maxChannelSize
         * @property {Long|null} [medianChannelSizeSat] NetworkInfo medianChannelSizeSat
         * @property {Long|null} [numZombieChans] NetworkInfo numZombieChans
         */

        /**
         * Constructs a new NetworkInfo.
         * @memberof lnrpc
         * @classdesc Represents a NetworkInfo.
         * @implements INetworkInfo
         * @constructor
         * @param {lnrpc.INetworkInfo=} [properties] Properties to set
         */
        function NetworkInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NetworkInfo graphDiameter.
         * @member {number} graphDiameter
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.graphDiameter = 0;

        /**
         * NetworkInfo avgOutDegree.
         * @member {number} avgOutDegree
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.avgOutDegree = 0;

        /**
         * NetworkInfo maxOutDegree.
         * @member {number} maxOutDegree
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.maxOutDegree = 0;

        /**
         * NetworkInfo numNodes.
         * @member {number} numNodes
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.numNodes = 0;

        /**
         * NetworkInfo numChannels.
         * @member {number} numChannels
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.numChannels = 0;

        /**
         * NetworkInfo totalNetworkCapacity.
         * @member {Long} totalNetworkCapacity
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.totalNetworkCapacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NetworkInfo avgChannelSize.
         * @member {number} avgChannelSize
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.avgChannelSize = 0;

        /**
         * NetworkInfo minChannelSize.
         * @member {Long} minChannelSize
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.minChannelSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NetworkInfo maxChannelSize.
         * @member {Long} maxChannelSize
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.maxChannelSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NetworkInfo medianChannelSizeSat.
         * @member {Long} medianChannelSizeSat
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.medianChannelSizeSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * NetworkInfo numZombieChans.
         * @member {Long} numZombieChans
         * @memberof lnrpc.NetworkInfo
         * @instance
         */
        NetworkInfo.prototype.numZombieChans = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new NetworkInfo instance using the specified properties.
         * @function create
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {lnrpc.INetworkInfo=} [properties] Properties to set
         * @returns {lnrpc.NetworkInfo} NetworkInfo instance
         */
        NetworkInfo.create = function create(properties) {
            return new NetworkInfo(properties);
        };

        /**
         * Encodes the specified NetworkInfo message. Does not implicitly {@link lnrpc.NetworkInfo.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {lnrpc.INetworkInfo} message NetworkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NetworkInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.graphDiameter != null && Object.hasOwnProperty.call(message, "graphDiameter"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.graphDiameter);
            if (message.avgOutDegree != null && Object.hasOwnProperty.call(message, "avgOutDegree"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.avgOutDegree);
            if (message.maxOutDegree != null && Object.hasOwnProperty.call(message, "maxOutDegree"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.maxOutDegree);
            if (message.numNodes != null && Object.hasOwnProperty.call(message, "numNodes"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numNodes);
            if (message.numChannels != null && Object.hasOwnProperty.call(message, "numChannels"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.numChannels);
            if (message.totalNetworkCapacity != null && Object.hasOwnProperty.call(message, "totalNetworkCapacity"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.totalNetworkCapacity);
            if (message.avgChannelSize != null && Object.hasOwnProperty.call(message, "avgChannelSize"))
                writer.uint32(/* id 7, wireType 1 =*/57).double(message.avgChannelSize);
            if (message.minChannelSize != null && Object.hasOwnProperty.call(message, "minChannelSize"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.minChannelSize);
            if (message.maxChannelSize != null && Object.hasOwnProperty.call(message, "maxChannelSize"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.maxChannelSize);
            if (message.medianChannelSizeSat != null && Object.hasOwnProperty.call(message, "medianChannelSizeSat"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.medianChannelSizeSat);
            if (message.numZombieChans != null && Object.hasOwnProperty.call(message, "numZombieChans"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.numZombieChans);
            return writer;
        };

        /**
         * Encodes the specified NetworkInfo message, length delimited. Does not implicitly {@link lnrpc.NetworkInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {lnrpc.INetworkInfo} message NetworkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NetworkInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NetworkInfo message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NetworkInfo} NetworkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NetworkInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NetworkInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.graphDiameter = reader.uint32();
                        break;
                    }
                case 2: {
                        message.avgOutDegree = reader.double();
                        break;
                    }
                case 3: {
                        message.maxOutDegree = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numNodes = reader.uint32();
                        break;
                    }
                case 5: {
                        message.numChannels = reader.uint32();
                        break;
                    }
                case 6: {
                        message.totalNetworkCapacity = reader.int64();
                        break;
                    }
                case 7: {
                        message.avgChannelSize = reader.double();
                        break;
                    }
                case 8: {
                        message.minChannelSize = reader.int64();
                        break;
                    }
                case 9: {
                        message.maxChannelSize = reader.int64();
                        break;
                    }
                case 10: {
                        message.medianChannelSizeSat = reader.int64();
                        break;
                    }
                case 11: {
                        message.numZombieChans = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NetworkInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NetworkInfo} NetworkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NetworkInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NetworkInfo message.
         * @function verify
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NetworkInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.graphDiameter != null && message.hasOwnProperty("graphDiameter"))
                if (!$util.isInteger(message.graphDiameter))
                    return "graphDiameter: integer expected";
            if (message.avgOutDegree != null && message.hasOwnProperty("avgOutDegree"))
                if (typeof message.avgOutDegree !== "number")
                    return "avgOutDegree: number expected";
            if (message.maxOutDegree != null && message.hasOwnProperty("maxOutDegree"))
                if (!$util.isInteger(message.maxOutDegree))
                    return "maxOutDegree: integer expected";
            if (message.numNodes != null && message.hasOwnProperty("numNodes"))
                if (!$util.isInteger(message.numNodes))
                    return "numNodes: integer expected";
            if (message.numChannels != null && message.hasOwnProperty("numChannels"))
                if (!$util.isInteger(message.numChannels))
                    return "numChannels: integer expected";
            if (message.totalNetworkCapacity != null && message.hasOwnProperty("totalNetworkCapacity"))
                if (!$util.isInteger(message.totalNetworkCapacity) && !(message.totalNetworkCapacity && $util.isInteger(message.totalNetworkCapacity.low) && $util.isInteger(message.totalNetworkCapacity.high)))
                    return "totalNetworkCapacity: integer|Long expected";
            if (message.avgChannelSize != null && message.hasOwnProperty("avgChannelSize"))
                if (typeof message.avgChannelSize !== "number")
                    return "avgChannelSize: number expected";
            if (message.minChannelSize != null && message.hasOwnProperty("minChannelSize"))
                if (!$util.isInteger(message.minChannelSize) && !(message.minChannelSize && $util.isInteger(message.minChannelSize.low) && $util.isInteger(message.minChannelSize.high)))
                    return "minChannelSize: integer|Long expected";
            if (message.maxChannelSize != null && message.hasOwnProperty("maxChannelSize"))
                if (!$util.isInteger(message.maxChannelSize) && !(message.maxChannelSize && $util.isInteger(message.maxChannelSize.low) && $util.isInteger(message.maxChannelSize.high)))
                    return "maxChannelSize: integer|Long expected";
            if (message.medianChannelSizeSat != null && message.hasOwnProperty("medianChannelSizeSat"))
                if (!$util.isInteger(message.medianChannelSizeSat) && !(message.medianChannelSizeSat && $util.isInteger(message.medianChannelSizeSat.low) && $util.isInteger(message.medianChannelSizeSat.high)))
                    return "medianChannelSizeSat: integer|Long expected";
            if (message.numZombieChans != null && message.hasOwnProperty("numZombieChans"))
                if (!$util.isInteger(message.numZombieChans) && !(message.numZombieChans && $util.isInteger(message.numZombieChans.low) && $util.isInteger(message.numZombieChans.high)))
                    return "numZombieChans: integer|Long expected";
            return null;
        };

        /**
         * Creates a NetworkInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NetworkInfo} NetworkInfo
         */
        NetworkInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NetworkInfo)
                return object;
            let message = new $root.lnrpc.NetworkInfo();
            if (object.graphDiameter != null)
                message.graphDiameter = object.graphDiameter >>> 0;
            if (object.avgOutDegree != null)
                message.avgOutDegree = Number(object.avgOutDegree);
            if (object.maxOutDegree != null)
                message.maxOutDegree = object.maxOutDegree >>> 0;
            if (object.numNodes != null)
                message.numNodes = object.numNodes >>> 0;
            if (object.numChannels != null)
                message.numChannels = object.numChannels >>> 0;
            if (object.totalNetworkCapacity != null)
                if ($util.Long)
                    (message.totalNetworkCapacity = $util.Long.fromValue(object.totalNetworkCapacity)).unsigned = false;
                else if (typeof object.totalNetworkCapacity === "string")
                    message.totalNetworkCapacity = parseInt(object.totalNetworkCapacity, 10);
                else if (typeof object.totalNetworkCapacity === "number")
                    message.totalNetworkCapacity = object.totalNetworkCapacity;
                else if (typeof object.totalNetworkCapacity === "object")
                    message.totalNetworkCapacity = new $util.LongBits(object.totalNetworkCapacity.low >>> 0, object.totalNetworkCapacity.high >>> 0).toNumber();
            if (object.avgChannelSize != null)
                message.avgChannelSize = Number(object.avgChannelSize);
            if (object.minChannelSize != null)
                if ($util.Long)
                    (message.minChannelSize = $util.Long.fromValue(object.minChannelSize)).unsigned = false;
                else if (typeof object.minChannelSize === "string")
                    message.minChannelSize = parseInt(object.minChannelSize, 10);
                else if (typeof object.minChannelSize === "number")
                    message.minChannelSize = object.minChannelSize;
                else if (typeof object.minChannelSize === "object")
                    message.minChannelSize = new $util.LongBits(object.minChannelSize.low >>> 0, object.minChannelSize.high >>> 0).toNumber();
            if (object.maxChannelSize != null)
                if ($util.Long)
                    (message.maxChannelSize = $util.Long.fromValue(object.maxChannelSize)).unsigned = false;
                else if (typeof object.maxChannelSize === "string")
                    message.maxChannelSize = parseInt(object.maxChannelSize, 10);
                else if (typeof object.maxChannelSize === "number")
                    message.maxChannelSize = object.maxChannelSize;
                else if (typeof object.maxChannelSize === "object")
                    message.maxChannelSize = new $util.LongBits(object.maxChannelSize.low >>> 0, object.maxChannelSize.high >>> 0).toNumber();
            if (object.medianChannelSizeSat != null)
                if ($util.Long)
                    (message.medianChannelSizeSat = $util.Long.fromValue(object.medianChannelSizeSat)).unsigned = false;
                else if (typeof object.medianChannelSizeSat === "string")
                    message.medianChannelSizeSat = parseInt(object.medianChannelSizeSat, 10);
                else if (typeof object.medianChannelSizeSat === "number")
                    message.medianChannelSizeSat = object.medianChannelSizeSat;
                else if (typeof object.medianChannelSizeSat === "object")
                    message.medianChannelSizeSat = new $util.LongBits(object.medianChannelSizeSat.low >>> 0, object.medianChannelSizeSat.high >>> 0).toNumber();
            if (object.numZombieChans != null)
                if ($util.Long)
                    (message.numZombieChans = $util.Long.fromValue(object.numZombieChans)).unsigned = true;
                else if (typeof object.numZombieChans === "string")
                    message.numZombieChans = parseInt(object.numZombieChans, 10);
                else if (typeof object.numZombieChans === "number")
                    message.numZombieChans = object.numZombieChans;
                else if (typeof object.numZombieChans === "object")
                    message.numZombieChans = new $util.LongBits(object.numZombieChans.low >>> 0, object.numZombieChans.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a NetworkInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NetworkInfo
         * @static
         * @param {lnrpc.NetworkInfo} message NetworkInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NetworkInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.graphDiameter = 0;
                object.avgOutDegree = 0;
                object.maxOutDegree = 0;
                object.numNodes = 0;
                object.numChannels = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalNetworkCapacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalNetworkCapacity = options.longs === String ? "0" : 0;
                object.avgChannelSize = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minChannelSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minChannelSize = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.maxChannelSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxChannelSize = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.medianChannelSizeSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.medianChannelSizeSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.numZombieChans = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.numZombieChans = options.longs === String ? "0" : 0;
            }
            if (message.graphDiameter != null && message.hasOwnProperty("graphDiameter"))
                object.graphDiameter = message.graphDiameter;
            if (message.avgOutDegree != null && message.hasOwnProperty("avgOutDegree"))
                object.avgOutDegree = options.json && !isFinite(message.avgOutDegree) ? String(message.avgOutDegree) : message.avgOutDegree;
            if (message.maxOutDegree != null && message.hasOwnProperty("maxOutDegree"))
                object.maxOutDegree = message.maxOutDegree;
            if (message.numNodes != null && message.hasOwnProperty("numNodes"))
                object.numNodes = message.numNodes;
            if (message.numChannels != null && message.hasOwnProperty("numChannels"))
                object.numChannels = message.numChannels;
            if (message.totalNetworkCapacity != null && message.hasOwnProperty("totalNetworkCapacity"))
                if (typeof message.totalNetworkCapacity === "number")
                    object.totalNetworkCapacity = options.longs === String ? String(message.totalNetworkCapacity) : message.totalNetworkCapacity;
                else
                    object.totalNetworkCapacity = options.longs === String ? $util.Long.prototype.toString.call(message.totalNetworkCapacity) : options.longs === Number ? new $util.LongBits(message.totalNetworkCapacity.low >>> 0, message.totalNetworkCapacity.high >>> 0).toNumber() : message.totalNetworkCapacity;
            if (message.avgChannelSize != null && message.hasOwnProperty("avgChannelSize"))
                object.avgChannelSize = options.json && !isFinite(message.avgChannelSize) ? String(message.avgChannelSize) : message.avgChannelSize;
            if (message.minChannelSize != null && message.hasOwnProperty("minChannelSize"))
                if (typeof message.minChannelSize === "number")
                    object.minChannelSize = options.longs === String ? String(message.minChannelSize) : message.minChannelSize;
                else
                    object.minChannelSize = options.longs === String ? $util.Long.prototype.toString.call(message.minChannelSize) : options.longs === Number ? new $util.LongBits(message.minChannelSize.low >>> 0, message.minChannelSize.high >>> 0).toNumber() : message.minChannelSize;
            if (message.maxChannelSize != null && message.hasOwnProperty("maxChannelSize"))
                if (typeof message.maxChannelSize === "number")
                    object.maxChannelSize = options.longs === String ? String(message.maxChannelSize) : message.maxChannelSize;
                else
                    object.maxChannelSize = options.longs === String ? $util.Long.prototype.toString.call(message.maxChannelSize) : options.longs === Number ? new $util.LongBits(message.maxChannelSize.low >>> 0, message.maxChannelSize.high >>> 0).toNumber() : message.maxChannelSize;
            if (message.medianChannelSizeSat != null && message.hasOwnProperty("medianChannelSizeSat"))
                if (typeof message.medianChannelSizeSat === "number")
                    object.medianChannelSizeSat = options.longs === String ? String(message.medianChannelSizeSat) : message.medianChannelSizeSat;
                else
                    object.medianChannelSizeSat = options.longs === String ? $util.Long.prototype.toString.call(message.medianChannelSizeSat) : options.longs === Number ? new $util.LongBits(message.medianChannelSizeSat.low >>> 0, message.medianChannelSizeSat.high >>> 0).toNumber() : message.medianChannelSizeSat;
            if (message.numZombieChans != null && message.hasOwnProperty("numZombieChans"))
                if (typeof message.numZombieChans === "number")
                    object.numZombieChans = options.longs === String ? String(message.numZombieChans) : message.numZombieChans;
                else
                    object.numZombieChans = options.longs === String ? $util.Long.prototype.toString.call(message.numZombieChans) : options.longs === Number ? new $util.LongBits(message.numZombieChans.low >>> 0, message.numZombieChans.high >>> 0).toNumber(true) : message.numZombieChans;
            return object;
        };

        /**
         * Converts this NetworkInfo to JSON.
         * @function toJSON
         * @memberof lnrpc.NetworkInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NetworkInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NetworkInfo;
    })();

    lnrpc.StopRequest = (function() {

        /**
         * Properties of a StopRequest.
         * @memberof lnrpc
         * @interface IStopRequest
         */

        /**
         * Constructs a new StopRequest.
         * @memberof lnrpc
         * @classdesc Represents a StopRequest.
         * @implements IStopRequest
         * @constructor
         * @param {lnrpc.IStopRequest=} [properties] Properties to set
         */
        function StopRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StopRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.StopRequest
         * @static
         * @param {lnrpc.IStopRequest=} [properties] Properties to set
         * @returns {lnrpc.StopRequest} StopRequest instance
         */
        StopRequest.create = function create(properties) {
            return new StopRequest(properties);
        };

        /**
         * Encodes the specified StopRequest message. Does not implicitly {@link lnrpc.StopRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.StopRequest
         * @static
         * @param {lnrpc.IStopRequest} message StopRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StopRequest message, length delimited. Does not implicitly {@link lnrpc.StopRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.StopRequest
         * @static
         * @param {lnrpc.IStopRequest} message StopRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.StopRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.StopRequest} StopRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.StopRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.StopRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.StopRequest} StopRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopRequest message.
         * @function verify
         * @memberof lnrpc.StopRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StopRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.StopRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.StopRequest} StopRequest
         */
        StopRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.StopRequest)
                return object;
            return new $root.lnrpc.StopRequest();
        };

        /**
         * Creates a plain object from a StopRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.StopRequest
         * @static
         * @param {lnrpc.StopRequest} message StopRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StopRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.StopRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StopRequest;
    })();

    lnrpc.StopResponse = (function() {

        /**
         * Properties of a StopResponse.
         * @memberof lnrpc
         * @interface IStopResponse
         */

        /**
         * Constructs a new StopResponse.
         * @memberof lnrpc
         * @classdesc Represents a StopResponse.
         * @implements IStopResponse
         * @constructor
         * @param {lnrpc.IStopResponse=} [properties] Properties to set
         */
        function StopResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StopResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.StopResponse
         * @static
         * @param {lnrpc.IStopResponse=} [properties] Properties to set
         * @returns {lnrpc.StopResponse} StopResponse instance
         */
        StopResponse.create = function create(properties) {
            return new StopResponse(properties);
        };

        /**
         * Encodes the specified StopResponse message. Does not implicitly {@link lnrpc.StopResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.StopResponse
         * @static
         * @param {lnrpc.IStopResponse} message StopResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StopResponse message, length delimited. Does not implicitly {@link lnrpc.StopResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.StopResponse
         * @static
         * @param {lnrpc.IStopResponse} message StopResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StopResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StopResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.StopResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.StopResponse} StopResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.StopResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StopResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.StopResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.StopResponse} StopResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StopResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StopResponse message.
         * @function verify
         * @memberof lnrpc.StopResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StopResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StopResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.StopResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.StopResponse} StopResponse
         */
        StopResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.StopResponse)
                return object;
            return new $root.lnrpc.StopResponse();
        };

        /**
         * Creates a plain object from a StopResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.StopResponse
         * @static
         * @param {lnrpc.StopResponse} message StopResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StopResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StopResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.StopResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StopResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StopResponse;
    })();

    lnrpc.GraphTopologySubscription = (function() {

        /**
         * Properties of a GraphTopologySubscription.
         * @memberof lnrpc
         * @interface IGraphTopologySubscription
         */

        /**
         * Constructs a new GraphTopologySubscription.
         * @memberof lnrpc
         * @classdesc Represents a GraphTopologySubscription.
         * @implements IGraphTopologySubscription
         * @constructor
         * @param {lnrpc.IGraphTopologySubscription=} [properties] Properties to set
         */
        function GraphTopologySubscription(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GraphTopologySubscription instance using the specified properties.
         * @function create
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {lnrpc.IGraphTopologySubscription=} [properties] Properties to set
         * @returns {lnrpc.GraphTopologySubscription} GraphTopologySubscription instance
         */
        GraphTopologySubscription.create = function create(properties) {
            return new GraphTopologySubscription(properties);
        };

        /**
         * Encodes the specified GraphTopologySubscription message. Does not implicitly {@link lnrpc.GraphTopologySubscription.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {lnrpc.IGraphTopologySubscription} message GraphTopologySubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GraphTopologySubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GraphTopologySubscription message, length delimited. Does not implicitly {@link lnrpc.GraphTopologySubscription.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {lnrpc.IGraphTopologySubscription} message GraphTopologySubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GraphTopologySubscription.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GraphTopologySubscription message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GraphTopologySubscription} GraphTopologySubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GraphTopologySubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GraphTopologySubscription();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GraphTopologySubscription message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GraphTopologySubscription} GraphTopologySubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GraphTopologySubscription.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GraphTopologySubscription message.
         * @function verify
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GraphTopologySubscription.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GraphTopologySubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GraphTopologySubscription} GraphTopologySubscription
         */
        GraphTopologySubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GraphTopologySubscription)
                return object;
            return new $root.lnrpc.GraphTopologySubscription();
        };

        /**
         * Creates a plain object from a GraphTopologySubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GraphTopologySubscription
         * @static
         * @param {lnrpc.GraphTopologySubscription} message GraphTopologySubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GraphTopologySubscription.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GraphTopologySubscription to JSON.
         * @function toJSON
         * @memberof lnrpc.GraphTopologySubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GraphTopologySubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GraphTopologySubscription;
    })();

    lnrpc.GraphTopologyUpdate = (function() {

        /**
         * Properties of a GraphTopologyUpdate.
         * @memberof lnrpc
         * @interface IGraphTopologyUpdate
         * @property {Array.<lnrpc.INodeUpdate>|null} [nodeUpdates] GraphTopologyUpdate nodeUpdates
         * @property {Array.<lnrpc.IChannelEdgeUpdate>|null} [channelUpdates] GraphTopologyUpdate channelUpdates
         * @property {Array.<lnrpc.IClosedChannelUpdate>|null} [closedChans] GraphTopologyUpdate closedChans
         */

        /**
         * Constructs a new GraphTopologyUpdate.
         * @memberof lnrpc
         * @classdesc Represents a GraphTopologyUpdate.
         * @implements IGraphTopologyUpdate
         * @constructor
         * @param {lnrpc.IGraphTopologyUpdate=} [properties] Properties to set
         */
        function GraphTopologyUpdate(properties) {
            this.nodeUpdates = [];
            this.channelUpdates = [];
            this.closedChans = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GraphTopologyUpdate nodeUpdates.
         * @member {Array.<lnrpc.INodeUpdate>} nodeUpdates
         * @memberof lnrpc.GraphTopologyUpdate
         * @instance
         */
        GraphTopologyUpdate.prototype.nodeUpdates = $util.emptyArray;

        /**
         * GraphTopologyUpdate channelUpdates.
         * @member {Array.<lnrpc.IChannelEdgeUpdate>} channelUpdates
         * @memberof lnrpc.GraphTopologyUpdate
         * @instance
         */
        GraphTopologyUpdate.prototype.channelUpdates = $util.emptyArray;

        /**
         * GraphTopologyUpdate closedChans.
         * @member {Array.<lnrpc.IClosedChannelUpdate>} closedChans
         * @memberof lnrpc.GraphTopologyUpdate
         * @instance
         */
        GraphTopologyUpdate.prototype.closedChans = $util.emptyArray;

        /**
         * Creates a new GraphTopologyUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {lnrpc.IGraphTopologyUpdate=} [properties] Properties to set
         * @returns {lnrpc.GraphTopologyUpdate} GraphTopologyUpdate instance
         */
        GraphTopologyUpdate.create = function create(properties) {
            return new GraphTopologyUpdate(properties);
        };

        /**
         * Encodes the specified GraphTopologyUpdate message. Does not implicitly {@link lnrpc.GraphTopologyUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {lnrpc.IGraphTopologyUpdate} message GraphTopologyUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GraphTopologyUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodeUpdates != null && message.nodeUpdates.length)
                for (let i = 0; i < message.nodeUpdates.length; ++i)
                    $root.lnrpc.NodeUpdate.encode(message.nodeUpdates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.channelUpdates != null && message.channelUpdates.length)
                for (let i = 0; i < message.channelUpdates.length; ++i)
                    $root.lnrpc.ChannelEdgeUpdate.encode(message.channelUpdates[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.closedChans != null && message.closedChans.length)
                for (let i = 0; i < message.closedChans.length; ++i)
                    $root.lnrpc.ClosedChannelUpdate.encode(message.closedChans[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GraphTopologyUpdate message, length delimited. Does not implicitly {@link lnrpc.GraphTopologyUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {lnrpc.IGraphTopologyUpdate} message GraphTopologyUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GraphTopologyUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GraphTopologyUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GraphTopologyUpdate} GraphTopologyUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GraphTopologyUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GraphTopologyUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.nodeUpdates && message.nodeUpdates.length))
                            message.nodeUpdates = [];
                        message.nodeUpdates.push($root.lnrpc.NodeUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (!(message.channelUpdates && message.channelUpdates.length))
                            message.channelUpdates = [];
                        message.channelUpdates.push($root.lnrpc.ChannelEdgeUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.closedChans && message.closedChans.length))
                            message.closedChans = [];
                        message.closedChans.push($root.lnrpc.ClosedChannelUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GraphTopologyUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GraphTopologyUpdate} GraphTopologyUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GraphTopologyUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GraphTopologyUpdate message.
         * @function verify
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GraphTopologyUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodeUpdates != null && message.hasOwnProperty("nodeUpdates")) {
                if (!Array.isArray(message.nodeUpdates))
                    return "nodeUpdates: array expected";
                for (let i = 0; i < message.nodeUpdates.length; ++i) {
                    let error = $root.lnrpc.NodeUpdate.verify(message.nodeUpdates[i]);
                    if (error)
                        return "nodeUpdates." + error;
                }
            }
            if (message.channelUpdates != null && message.hasOwnProperty("channelUpdates")) {
                if (!Array.isArray(message.channelUpdates))
                    return "channelUpdates: array expected";
                for (let i = 0; i < message.channelUpdates.length; ++i) {
                    let error = $root.lnrpc.ChannelEdgeUpdate.verify(message.channelUpdates[i]);
                    if (error)
                        return "channelUpdates." + error;
                }
            }
            if (message.closedChans != null && message.hasOwnProperty("closedChans")) {
                if (!Array.isArray(message.closedChans))
                    return "closedChans: array expected";
                for (let i = 0; i < message.closedChans.length; ++i) {
                    let error = $root.lnrpc.ClosedChannelUpdate.verify(message.closedChans[i]);
                    if (error)
                        return "closedChans." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GraphTopologyUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GraphTopologyUpdate} GraphTopologyUpdate
         */
        GraphTopologyUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GraphTopologyUpdate)
                return object;
            let message = new $root.lnrpc.GraphTopologyUpdate();
            if (object.nodeUpdates) {
                if (!Array.isArray(object.nodeUpdates))
                    throw TypeError(".lnrpc.GraphTopologyUpdate.nodeUpdates: array expected");
                message.nodeUpdates = [];
                for (let i = 0; i < object.nodeUpdates.length; ++i) {
                    if (typeof object.nodeUpdates[i] !== "object")
                        throw TypeError(".lnrpc.GraphTopologyUpdate.nodeUpdates: object expected");
                    message.nodeUpdates[i] = $root.lnrpc.NodeUpdate.fromObject(object.nodeUpdates[i]);
                }
            }
            if (object.channelUpdates) {
                if (!Array.isArray(object.channelUpdates))
                    throw TypeError(".lnrpc.GraphTopologyUpdate.channelUpdates: array expected");
                message.channelUpdates = [];
                for (let i = 0; i < object.channelUpdates.length; ++i) {
                    if (typeof object.channelUpdates[i] !== "object")
                        throw TypeError(".lnrpc.GraphTopologyUpdate.channelUpdates: object expected");
                    message.channelUpdates[i] = $root.lnrpc.ChannelEdgeUpdate.fromObject(object.channelUpdates[i]);
                }
            }
            if (object.closedChans) {
                if (!Array.isArray(object.closedChans))
                    throw TypeError(".lnrpc.GraphTopologyUpdate.closedChans: array expected");
                message.closedChans = [];
                for (let i = 0; i < object.closedChans.length; ++i) {
                    if (typeof object.closedChans[i] !== "object")
                        throw TypeError(".lnrpc.GraphTopologyUpdate.closedChans: object expected");
                    message.closedChans[i] = $root.lnrpc.ClosedChannelUpdate.fromObject(object.closedChans[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GraphTopologyUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GraphTopologyUpdate
         * @static
         * @param {lnrpc.GraphTopologyUpdate} message GraphTopologyUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GraphTopologyUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.nodeUpdates = [];
                object.channelUpdates = [];
                object.closedChans = [];
            }
            if (message.nodeUpdates && message.nodeUpdates.length) {
                object.nodeUpdates = [];
                for (let j = 0; j < message.nodeUpdates.length; ++j)
                    object.nodeUpdates[j] = $root.lnrpc.NodeUpdate.toObject(message.nodeUpdates[j], options);
            }
            if (message.channelUpdates && message.channelUpdates.length) {
                object.channelUpdates = [];
                for (let j = 0; j < message.channelUpdates.length; ++j)
                    object.channelUpdates[j] = $root.lnrpc.ChannelEdgeUpdate.toObject(message.channelUpdates[j], options);
            }
            if (message.closedChans && message.closedChans.length) {
                object.closedChans = [];
                for (let j = 0; j < message.closedChans.length; ++j)
                    object.closedChans[j] = $root.lnrpc.ClosedChannelUpdate.toObject(message.closedChans[j], options);
            }
            return object;
        };

        /**
         * Converts this GraphTopologyUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.GraphTopologyUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GraphTopologyUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GraphTopologyUpdate;
    })();

    lnrpc.NodeUpdate = (function() {

        /**
         * Properties of a NodeUpdate.
         * @memberof lnrpc
         * @interface INodeUpdate
         * @property {Array.<string>|null} [addresses] NodeUpdate addresses
         * @property {string|null} [identityKey] NodeUpdate identityKey
         * @property {Uint8Array|null} [globalFeatures] NodeUpdate globalFeatures
         * @property {string|null} [alias] NodeUpdate alias
         * @property {string|null} [color] NodeUpdate color
         * @property {Array.<lnrpc.INodeAddress>|null} [nodeAddresses] NodeUpdate nodeAddresses
         * @property {Object.<string,lnrpc.IFeature>|null} [features] NodeUpdate features
         */

        /**
         * Constructs a new NodeUpdate.
         * @memberof lnrpc
         * @classdesc Represents a NodeUpdate.
         * @implements INodeUpdate
         * @constructor
         * @param {lnrpc.INodeUpdate=} [properties] Properties to set
         */
        function NodeUpdate(properties) {
            this.addresses = [];
            this.nodeAddresses = [];
            this.features = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeUpdate addresses.
         * @member {Array.<string>} addresses
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.addresses = $util.emptyArray;

        /**
         * NodeUpdate identityKey.
         * @member {string} identityKey
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.identityKey = "";

        /**
         * NodeUpdate globalFeatures.
         * @member {Uint8Array} globalFeatures
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.globalFeatures = $util.newBuffer([]);

        /**
         * NodeUpdate alias.
         * @member {string} alias
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.alias = "";

        /**
         * NodeUpdate color.
         * @member {string} color
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.color = "";

        /**
         * NodeUpdate nodeAddresses.
         * @member {Array.<lnrpc.INodeAddress>} nodeAddresses
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.nodeAddresses = $util.emptyArray;

        /**
         * NodeUpdate features.
         * @member {Object.<string,lnrpc.IFeature>} features
         * @memberof lnrpc.NodeUpdate
         * @instance
         */
        NodeUpdate.prototype.features = $util.emptyObject;

        /**
         * Creates a new NodeUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {lnrpc.INodeUpdate=} [properties] Properties to set
         * @returns {lnrpc.NodeUpdate} NodeUpdate instance
         */
        NodeUpdate.create = function create(properties) {
            return new NodeUpdate(properties);
        };

        /**
         * Encodes the specified NodeUpdate message. Does not implicitly {@link lnrpc.NodeUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {lnrpc.INodeUpdate} message NodeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (let i = 0; i < message.addresses.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.addresses[i]);
            if (message.identityKey != null && Object.hasOwnProperty.call(message, "identityKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.identityKey);
            if (message.globalFeatures != null && Object.hasOwnProperty.call(message, "globalFeatures"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.globalFeatures);
            if (message.alias != null && Object.hasOwnProperty.call(message, "alias"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.alias);
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.color);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                for (let keys = Object.keys(message.features), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 6, wireType 2 =*/50).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.lnrpc.Feature.encode(message.features[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.nodeAddresses != null && message.nodeAddresses.length)
                for (let i = 0; i < message.nodeAddresses.length; ++i)
                    $root.lnrpc.NodeAddress.encode(message.nodeAddresses[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NodeUpdate message, length delimited. Does not implicitly {@link lnrpc.NodeUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {lnrpc.INodeUpdate} message NodeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.NodeUpdate} NodeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.NodeUpdate(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push(reader.string());
                        break;
                    }
                case 2: {
                        message.identityKey = reader.string();
                        break;
                    }
                case 3: {
                        message.globalFeatures = reader.bytes();
                        break;
                    }
                case 4: {
                        message.alias = reader.string();
                        break;
                    }
                case 5: {
                        message.color = reader.string();
                        break;
                    }
                case 7: {
                        if (!(message.nodeAddresses && message.nodeAddresses.length))
                            message.nodeAddresses = [];
                        message.nodeAddresses.push($root.lnrpc.NodeAddress.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        if (message.features === $util.emptyObject)
                            message.features = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.lnrpc.Feature.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.features[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.NodeUpdate} NodeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeUpdate message.
         * @function verify
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.addresses != null && message.hasOwnProperty("addresses")) {
                if (!Array.isArray(message.addresses))
                    return "addresses: array expected";
                for (let i = 0; i < message.addresses.length; ++i)
                    if (!$util.isString(message.addresses[i]))
                        return "addresses: string[] expected";
            }
            if (message.identityKey != null && message.hasOwnProperty("identityKey"))
                if (!$util.isString(message.identityKey))
                    return "identityKey: string expected";
            if (message.globalFeatures != null && message.hasOwnProperty("globalFeatures"))
                if (!(message.globalFeatures && typeof message.globalFeatures.length === "number" || $util.isString(message.globalFeatures)))
                    return "globalFeatures: buffer expected";
            if (message.alias != null && message.hasOwnProperty("alias"))
                if (!$util.isString(message.alias))
                    return "alias: string expected";
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isString(message.color))
                    return "color: string expected";
            if (message.nodeAddresses != null && message.hasOwnProperty("nodeAddresses")) {
                if (!Array.isArray(message.nodeAddresses))
                    return "nodeAddresses: array expected";
                for (let i = 0; i < message.nodeAddresses.length; ++i) {
                    let error = $root.lnrpc.NodeAddress.verify(message.nodeAddresses[i]);
                    if (error)
                        return "nodeAddresses." + error;
                }
            }
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!$util.isObject(message.features))
                    return "features: object expected";
                let key = Object.keys(message.features);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "features: integer key{k:uint32} expected";
                    {
                        let error = $root.lnrpc.Feature.verify(message.features[key[i]]);
                        if (error)
                            return "features." + error;
                    }
                }
            }
            return null;
        };

        /**
         * Creates a NodeUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.NodeUpdate} NodeUpdate
         */
        NodeUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.NodeUpdate)
                return object;
            let message = new $root.lnrpc.NodeUpdate();
            if (object.addresses) {
                if (!Array.isArray(object.addresses))
                    throw TypeError(".lnrpc.NodeUpdate.addresses: array expected");
                message.addresses = [];
                for (let i = 0; i < object.addresses.length; ++i)
                    message.addresses[i] = String(object.addresses[i]);
            }
            if (object.identityKey != null)
                message.identityKey = String(object.identityKey);
            if (object.globalFeatures != null)
                if (typeof object.globalFeatures === "string")
                    $util.base64.decode(object.globalFeatures, message.globalFeatures = $util.newBuffer($util.base64.length(object.globalFeatures)), 0);
                else if (object.globalFeatures.length)
                    message.globalFeatures = object.globalFeatures;
            if (object.alias != null)
                message.alias = String(object.alias);
            if (object.color != null)
                message.color = String(object.color);
            if (object.nodeAddresses) {
                if (!Array.isArray(object.nodeAddresses))
                    throw TypeError(".lnrpc.NodeUpdate.nodeAddresses: array expected");
                message.nodeAddresses = [];
                for (let i = 0; i < object.nodeAddresses.length; ++i) {
                    if (typeof object.nodeAddresses[i] !== "object")
                        throw TypeError(".lnrpc.NodeUpdate.nodeAddresses: object expected");
                    message.nodeAddresses[i] = $root.lnrpc.NodeAddress.fromObject(object.nodeAddresses[i]);
                }
            }
            if (object.features) {
                if (typeof object.features !== "object")
                    throw TypeError(".lnrpc.NodeUpdate.features: object expected");
                message.features = {};
                for (let keys = Object.keys(object.features), i = 0; i < keys.length; ++i) {
                    if (typeof object.features[keys[i]] !== "object")
                        throw TypeError(".lnrpc.NodeUpdate.features: object expected");
                    message.features[keys[i]] = $root.lnrpc.Feature.fromObject(object.features[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NodeUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.NodeUpdate
         * @static
         * @param {lnrpc.NodeUpdate} message NodeUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.addresses = [];
                object.nodeAddresses = [];
            }
            if (options.objects || options.defaults)
                object.features = {};
            if (options.defaults) {
                object.identityKey = "";
                if (options.bytes === String)
                    object.globalFeatures = "";
                else {
                    object.globalFeatures = [];
                    if (options.bytes !== Array)
                        object.globalFeatures = $util.newBuffer(object.globalFeatures);
                }
                object.alias = "";
                object.color = "";
            }
            if (message.addresses && message.addresses.length) {
                object.addresses = [];
                for (let j = 0; j < message.addresses.length; ++j)
                    object.addresses[j] = message.addresses[j];
            }
            if (message.identityKey != null && message.hasOwnProperty("identityKey"))
                object.identityKey = message.identityKey;
            if (message.globalFeatures != null && message.hasOwnProperty("globalFeatures"))
                object.globalFeatures = options.bytes === String ? $util.base64.encode(message.globalFeatures, 0, message.globalFeatures.length) : options.bytes === Array ? Array.prototype.slice.call(message.globalFeatures) : message.globalFeatures;
            if (message.alias != null && message.hasOwnProperty("alias"))
                object.alias = message.alias;
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = message.color;
            let keys2;
            if (message.features && (keys2 = Object.keys(message.features)).length) {
                object.features = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.features[keys2[j]] = $root.lnrpc.Feature.toObject(message.features[keys2[j]], options);
            }
            if (message.nodeAddresses && message.nodeAddresses.length) {
                object.nodeAddresses = [];
                for (let j = 0; j < message.nodeAddresses.length; ++j)
                    object.nodeAddresses[j] = $root.lnrpc.NodeAddress.toObject(message.nodeAddresses[j], options);
            }
            return object;
        };

        /**
         * Converts this NodeUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.NodeUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeUpdate;
    })();

    lnrpc.ChannelEdgeUpdate = (function() {

        /**
         * Properties of a ChannelEdgeUpdate.
         * @memberof lnrpc
         * @interface IChannelEdgeUpdate
         * @property {Long|null} [chanId] ChannelEdgeUpdate chanId
         * @property {lnrpc.IChannelPoint|null} [chanPoint] ChannelEdgeUpdate chanPoint
         * @property {Long|null} [capacity] ChannelEdgeUpdate capacity
         * @property {lnrpc.IRoutingPolicy|null} [routingPolicy] ChannelEdgeUpdate routingPolicy
         * @property {string|null} [advertisingNode] ChannelEdgeUpdate advertisingNode
         * @property {string|null} [connectingNode] ChannelEdgeUpdate connectingNode
         */

        /**
         * Constructs a new ChannelEdgeUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ChannelEdgeUpdate.
         * @implements IChannelEdgeUpdate
         * @constructor
         * @param {lnrpc.IChannelEdgeUpdate=} [properties] Properties to set
         */
        function ChannelEdgeUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelEdgeUpdate chanId.
         * @member {Long} chanId
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         */
        ChannelEdgeUpdate.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelEdgeUpdate chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         */
        ChannelEdgeUpdate.prototype.chanPoint = null;

        /**
         * ChannelEdgeUpdate capacity.
         * @member {Long} capacity
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         */
        ChannelEdgeUpdate.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelEdgeUpdate routingPolicy.
         * @member {lnrpc.IRoutingPolicy|null|undefined} routingPolicy
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         */
        ChannelEdgeUpdate.prototype.routingPolicy = null;

        /**
         * ChannelEdgeUpdate advertisingNode.
         * @member {string} advertisingNode
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         */
        ChannelEdgeUpdate.prototype.advertisingNode = "";

        /**
         * ChannelEdgeUpdate connectingNode.
         * @member {string} connectingNode
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         */
        ChannelEdgeUpdate.prototype.connectingNode = "";

        /**
         * Creates a new ChannelEdgeUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {lnrpc.IChannelEdgeUpdate=} [properties] Properties to set
         * @returns {lnrpc.ChannelEdgeUpdate} ChannelEdgeUpdate instance
         */
        ChannelEdgeUpdate.create = function create(properties) {
            return new ChannelEdgeUpdate(properties);
        };

        /**
         * Encodes the specified ChannelEdgeUpdate message. Does not implicitly {@link lnrpc.ChannelEdgeUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {lnrpc.IChannelEdgeUpdate} message ChannelEdgeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEdgeUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chanId);
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.capacity);
            if (message.routingPolicy != null && Object.hasOwnProperty.call(message, "routingPolicy"))
                $root.lnrpc.RoutingPolicy.encode(message.routingPolicy, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.advertisingNode != null && Object.hasOwnProperty.call(message, "advertisingNode"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.advertisingNode);
            if (message.connectingNode != null && Object.hasOwnProperty.call(message, "connectingNode"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.connectingNode);
            return writer;
        };

        /**
         * Encodes the specified ChannelEdgeUpdate message, length delimited. Does not implicitly {@link lnrpc.ChannelEdgeUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {lnrpc.IChannelEdgeUpdate} message ChannelEdgeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelEdgeUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelEdgeUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelEdgeUpdate} ChannelEdgeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEdgeUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelEdgeUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.capacity = reader.int64();
                        break;
                    }
                case 4: {
                        message.routingPolicy = $root.lnrpc.RoutingPolicy.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.advertisingNode = reader.string();
                        break;
                    }
                case 6: {
                        message.connectingNode = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelEdgeUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelEdgeUpdate} ChannelEdgeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelEdgeUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelEdgeUpdate message.
         * @function verify
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelEdgeUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                if (error)
                    return "chanPoint." + error;
            }
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (!$util.isInteger(message.capacity) && !(message.capacity && $util.isInteger(message.capacity.low) && $util.isInteger(message.capacity.high)))
                    return "capacity: integer|Long expected";
            if (message.routingPolicy != null && message.hasOwnProperty("routingPolicy")) {
                let error = $root.lnrpc.RoutingPolicy.verify(message.routingPolicy);
                if (error)
                    return "routingPolicy." + error;
            }
            if (message.advertisingNode != null && message.hasOwnProperty("advertisingNode"))
                if (!$util.isString(message.advertisingNode))
                    return "advertisingNode: string expected";
            if (message.connectingNode != null && message.hasOwnProperty("connectingNode"))
                if (!$util.isString(message.connectingNode))
                    return "connectingNode: string expected";
            return null;
        };

        /**
         * Creates a ChannelEdgeUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelEdgeUpdate} ChannelEdgeUpdate
         */
        ChannelEdgeUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelEdgeUpdate)
                return object;
            let message = new $root.lnrpc.ChannelEdgeUpdate();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".lnrpc.ChannelEdgeUpdate.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            if (object.capacity != null)
                if ($util.Long)
                    (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false;
                else if (typeof object.capacity === "string")
                    message.capacity = parseInt(object.capacity, 10);
                else if (typeof object.capacity === "number")
                    message.capacity = object.capacity;
                else if (typeof object.capacity === "object")
                    message.capacity = new $util.LongBits(object.capacity.low >>> 0, object.capacity.high >>> 0).toNumber();
            if (object.routingPolicy != null) {
                if (typeof object.routingPolicy !== "object")
                    throw TypeError(".lnrpc.ChannelEdgeUpdate.routingPolicy: object expected");
                message.routingPolicy = $root.lnrpc.RoutingPolicy.fromObject(object.routingPolicy);
            }
            if (object.advertisingNode != null)
                message.advertisingNode = String(object.advertisingNode);
            if (object.connectingNode != null)
                message.connectingNode = String(object.connectingNode);
            return message;
        };

        /**
         * Creates a plain object from a ChannelEdgeUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelEdgeUpdate
         * @static
         * @param {lnrpc.ChannelEdgeUpdate} message ChannelEdgeUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelEdgeUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                object.chanPoint = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.capacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.capacity = options.longs === String ? "0" : 0;
                object.routingPolicy = null;
                object.advertisingNode = "";
                object.connectingNode = "";
            }
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (typeof message.capacity === "number")
                    object.capacity = options.longs === String ? String(message.capacity) : message.capacity;
                else
                    object.capacity = options.longs === String ? $util.Long.prototype.toString.call(message.capacity) : options.longs === Number ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber() : message.capacity;
            if (message.routingPolicy != null && message.hasOwnProperty("routingPolicy"))
                object.routingPolicy = $root.lnrpc.RoutingPolicy.toObject(message.routingPolicy, options);
            if (message.advertisingNode != null && message.hasOwnProperty("advertisingNode"))
                object.advertisingNode = message.advertisingNode;
            if (message.connectingNode != null && message.hasOwnProperty("connectingNode"))
                object.connectingNode = message.connectingNode;
            return object;
        };

        /**
         * Converts this ChannelEdgeUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelEdgeUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelEdgeUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelEdgeUpdate;
    })();

    lnrpc.ClosedChannelUpdate = (function() {

        /**
         * Properties of a ClosedChannelUpdate.
         * @memberof lnrpc
         * @interface IClosedChannelUpdate
         * @property {Long|null} [chanId] ClosedChannelUpdate chanId
         * @property {Long|null} [capacity] ClosedChannelUpdate capacity
         * @property {number|null} [closedHeight] ClosedChannelUpdate closedHeight
         * @property {lnrpc.IChannelPoint|null} [chanPoint] ClosedChannelUpdate chanPoint
         */

        /**
         * Constructs a new ClosedChannelUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ClosedChannelUpdate.
         * @implements IClosedChannelUpdate
         * @constructor
         * @param {lnrpc.IClosedChannelUpdate=} [properties] Properties to set
         */
        function ClosedChannelUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClosedChannelUpdate chanId.
         * @member {Long} chanId
         * @memberof lnrpc.ClosedChannelUpdate
         * @instance
         */
        ClosedChannelUpdate.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ClosedChannelUpdate capacity.
         * @member {Long} capacity
         * @memberof lnrpc.ClosedChannelUpdate
         * @instance
         */
        ClosedChannelUpdate.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ClosedChannelUpdate closedHeight.
         * @member {number} closedHeight
         * @memberof lnrpc.ClosedChannelUpdate
         * @instance
         */
        ClosedChannelUpdate.prototype.closedHeight = 0;

        /**
         * ClosedChannelUpdate chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof lnrpc.ClosedChannelUpdate
         * @instance
         */
        ClosedChannelUpdate.prototype.chanPoint = null;

        /**
         * Creates a new ClosedChannelUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {lnrpc.IClosedChannelUpdate=} [properties] Properties to set
         * @returns {lnrpc.ClosedChannelUpdate} ClosedChannelUpdate instance
         */
        ClosedChannelUpdate.create = function create(properties) {
            return new ClosedChannelUpdate(properties);
        };

        /**
         * Encodes the specified ClosedChannelUpdate message. Does not implicitly {@link lnrpc.ClosedChannelUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {lnrpc.IClosedChannelUpdate} message ClosedChannelUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClosedChannelUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chanId);
            if (message.capacity != null && Object.hasOwnProperty.call(message, "capacity"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.capacity);
            if (message.closedHeight != null && Object.hasOwnProperty.call(message, "closedHeight"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.closedHeight);
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ClosedChannelUpdate message, length delimited. Does not implicitly {@link lnrpc.ClosedChannelUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {lnrpc.IClosedChannelUpdate} message ClosedChannelUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClosedChannelUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ClosedChannelUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ClosedChannelUpdate} ClosedChannelUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClosedChannelUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ClosedChannelUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.capacity = reader.int64();
                        break;
                    }
                case 3: {
                        message.closedHeight = reader.uint32();
                        break;
                    }
                case 4: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ClosedChannelUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ClosedChannelUpdate} ClosedChannelUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClosedChannelUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ClosedChannelUpdate message.
         * @function verify
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClosedChannelUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (!$util.isInteger(message.capacity) && !(message.capacity && $util.isInteger(message.capacity.low) && $util.isInteger(message.capacity.high)))
                    return "capacity: integer|Long expected";
            if (message.closedHeight != null && message.hasOwnProperty("closedHeight"))
                if (!$util.isInteger(message.closedHeight))
                    return "closedHeight: integer expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                if (error)
                    return "chanPoint." + error;
            }
            return null;
        };

        /**
         * Creates a ClosedChannelUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ClosedChannelUpdate} ClosedChannelUpdate
         */
        ClosedChannelUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ClosedChannelUpdate)
                return object;
            let message = new $root.lnrpc.ClosedChannelUpdate();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.capacity != null)
                if ($util.Long)
                    (message.capacity = $util.Long.fromValue(object.capacity)).unsigned = false;
                else if (typeof object.capacity === "string")
                    message.capacity = parseInt(object.capacity, 10);
                else if (typeof object.capacity === "number")
                    message.capacity = object.capacity;
                else if (typeof object.capacity === "object")
                    message.capacity = new $util.LongBits(object.capacity.low >>> 0, object.capacity.high >>> 0).toNumber();
            if (object.closedHeight != null)
                message.closedHeight = object.closedHeight >>> 0;
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".lnrpc.ClosedChannelUpdate.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            return message;
        };

        /**
         * Creates a plain object from a ClosedChannelUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ClosedChannelUpdate
         * @static
         * @param {lnrpc.ClosedChannelUpdate} message ClosedChannelUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClosedChannelUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.capacity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.capacity = options.longs === String ? "0" : 0;
                object.closedHeight = 0;
                object.chanPoint = null;
            }
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.capacity != null && message.hasOwnProperty("capacity"))
                if (typeof message.capacity === "number")
                    object.capacity = options.longs === String ? String(message.capacity) : message.capacity;
                else
                    object.capacity = options.longs === String ? $util.Long.prototype.toString.call(message.capacity) : options.longs === Number ? new $util.LongBits(message.capacity.low >>> 0, message.capacity.high >>> 0).toNumber() : message.capacity;
            if (message.closedHeight != null && message.hasOwnProperty("closedHeight"))
                object.closedHeight = message.closedHeight;
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
            return object;
        };

        /**
         * Converts this ClosedChannelUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ClosedChannelUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClosedChannelUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClosedChannelUpdate;
    })();

    lnrpc.HopHint = (function() {

        /**
         * Properties of a HopHint.
         * @memberof lnrpc
         * @interface IHopHint
         * @property {string|null} [nodeId] HopHint nodeId
         * @property {Long|null} [chanId] HopHint chanId
         * @property {number|null} [feeBaseMsat] HopHint feeBaseMsat
         * @property {number|null} [feeProportionalMillionths] HopHint feeProportionalMillionths
         * @property {number|null} [cltvExpiryDelta] HopHint cltvExpiryDelta
         */

        /**
         * Constructs a new HopHint.
         * @memberof lnrpc
         * @classdesc Represents a HopHint.
         * @implements IHopHint
         * @constructor
         * @param {lnrpc.IHopHint=} [properties] Properties to set
         */
        function HopHint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HopHint nodeId.
         * @member {string} nodeId
         * @memberof lnrpc.HopHint
         * @instance
         */
        HopHint.prototype.nodeId = "";

        /**
         * HopHint chanId.
         * @member {Long} chanId
         * @memberof lnrpc.HopHint
         * @instance
         */
        HopHint.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HopHint feeBaseMsat.
         * @member {number} feeBaseMsat
         * @memberof lnrpc.HopHint
         * @instance
         */
        HopHint.prototype.feeBaseMsat = 0;

        /**
         * HopHint feeProportionalMillionths.
         * @member {number} feeProportionalMillionths
         * @memberof lnrpc.HopHint
         * @instance
         */
        HopHint.prototype.feeProportionalMillionths = 0;

        /**
         * HopHint cltvExpiryDelta.
         * @member {number} cltvExpiryDelta
         * @memberof lnrpc.HopHint
         * @instance
         */
        HopHint.prototype.cltvExpiryDelta = 0;

        /**
         * Creates a new HopHint instance using the specified properties.
         * @function create
         * @memberof lnrpc.HopHint
         * @static
         * @param {lnrpc.IHopHint=} [properties] Properties to set
         * @returns {lnrpc.HopHint} HopHint instance
         */
        HopHint.create = function create(properties) {
            return new HopHint(properties);
        };

        /**
         * Encodes the specified HopHint message. Does not implicitly {@link lnrpc.HopHint.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.HopHint
         * @static
         * @param {lnrpc.IHopHint} message HopHint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HopHint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodeId != null && Object.hasOwnProperty.call(message, "nodeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nodeId);
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chanId);
            if (message.feeBaseMsat != null && Object.hasOwnProperty.call(message, "feeBaseMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.feeBaseMsat);
            if (message.feeProportionalMillionths != null && Object.hasOwnProperty.call(message, "feeProportionalMillionths"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.feeProportionalMillionths);
            if (message.cltvExpiryDelta != null && Object.hasOwnProperty.call(message, "cltvExpiryDelta"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.cltvExpiryDelta);
            return writer;
        };

        /**
         * Encodes the specified HopHint message, length delimited. Does not implicitly {@link lnrpc.HopHint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.HopHint
         * @static
         * @param {lnrpc.IHopHint} message HopHint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HopHint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HopHint message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.HopHint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.HopHint} HopHint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HopHint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.HopHint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodeId = reader.string();
                        break;
                    }
                case 2: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 3: {
                        message.feeBaseMsat = reader.uint32();
                        break;
                    }
                case 4: {
                        message.feeProportionalMillionths = reader.uint32();
                        break;
                    }
                case 5: {
                        message.cltvExpiryDelta = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HopHint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.HopHint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.HopHint} HopHint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HopHint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HopHint message.
         * @function verify
         * @memberof lnrpc.HopHint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HopHint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodeId != null && message.hasOwnProperty("nodeId"))
                if (!$util.isString(message.nodeId))
                    return "nodeId: string expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.feeBaseMsat != null && message.hasOwnProperty("feeBaseMsat"))
                if (!$util.isInteger(message.feeBaseMsat))
                    return "feeBaseMsat: integer expected";
            if (message.feeProportionalMillionths != null && message.hasOwnProperty("feeProportionalMillionths"))
                if (!$util.isInteger(message.feeProportionalMillionths))
                    return "feeProportionalMillionths: integer expected";
            if (message.cltvExpiryDelta != null && message.hasOwnProperty("cltvExpiryDelta"))
                if (!$util.isInteger(message.cltvExpiryDelta))
                    return "cltvExpiryDelta: integer expected";
            return null;
        };

        /**
         * Creates a HopHint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.HopHint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.HopHint} HopHint
         */
        HopHint.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.HopHint)
                return object;
            let message = new $root.lnrpc.HopHint();
            if (object.nodeId != null)
                message.nodeId = String(object.nodeId);
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.feeBaseMsat != null)
                message.feeBaseMsat = object.feeBaseMsat >>> 0;
            if (object.feeProportionalMillionths != null)
                message.feeProportionalMillionths = object.feeProportionalMillionths >>> 0;
            if (object.cltvExpiryDelta != null)
                message.cltvExpiryDelta = object.cltvExpiryDelta >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a HopHint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.HopHint
         * @static
         * @param {lnrpc.HopHint} message HopHint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HopHint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.nodeId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                object.feeBaseMsat = 0;
                object.feeProportionalMillionths = 0;
                object.cltvExpiryDelta = 0;
            }
            if (message.nodeId != null && message.hasOwnProperty("nodeId"))
                object.nodeId = message.nodeId;
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.feeBaseMsat != null && message.hasOwnProperty("feeBaseMsat"))
                object.feeBaseMsat = message.feeBaseMsat;
            if (message.feeProportionalMillionths != null && message.hasOwnProperty("feeProportionalMillionths"))
                object.feeProportionalMillionths = message.feeProportionalMillionths;
            if (message.cltvExpiryDelta != null && message.hasOwnProperty("cltvExpiryDelta"))
                object.cltvExpiryDelta = message.cltvExpiryDelta;
            return object;
        };

        /**
         * Converts this HopHint to JSON.
         * @function toJSON
         * @memberof lnrpc.HopHint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HopHint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HopHint;
    })();

    lnrpc.SetID = (function() {

        /**
         * Properties of a SetID.
         * @memberof lnrpc
         * @interface ISetID
         * @property {Uint8Array|null} [setId] SetID setId
         */

        /**
         * Constructs a new SetID.
         * @memberof lnrpc
         * @classdesc Represents a SetID.
         * @implements ISetID
         * @constructor
         * @param {lnrpc.ISetID=} [properties] Properties to set
         */
        function SetID(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetID setId.
         * @member {Uint8Array} setId
         * @memberof lnrpc.SetID
         * @instance
         */
        SetID.prototype.setId = $util.newBuffer([]);

        /**
         * Creates a new SetID instance using the specified properties.
         * @function create
         * @memberof lnrpc.SetID
         * @static
         * @param {lnrpc.ISetID=} [properties] Properties to set
         * @returns {lnrpc.SetID} SetID instance
         */
        SetID.create = function create(properties) {
            return new SetID(properties);
        };

        /**
         * Encodes the specified SetID message. Does not implicitly {@link lnrpc.SetID.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SetID
         * @static
         * @param {lnrpc.ISetID} message SetID message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetID.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.setId != null && Object.hasOwnProperty.call(message, "setId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.setId);
            return writer;
        };

        /**
         * Encodes the specified SetID message, length delimited. Does not implicitly {@link lnrpc.SetID.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SetID
         * @static
         * @param {lnrpc.ISetID} message SetID message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetID.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetID message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SetID
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SetID} SetID
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetID.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SetID();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.setId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetID message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SetID
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SetID} SetID
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetID.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetID message.
         * @function verify
         * @memberof lnrpc.SetID
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetID.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.setId != null && message.hasOwnProperty("setId"))
                if (!(message.setId && typeof message.setId.length === "number" || $util.isString(message.setId)))
                    return "setId: buffer expected";
            return null;
        };

        /**
         * Creates a SetID message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SetID
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SetID} SetID
         */
        SetID.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SetID)
                return object;
            let message = new $root.lnrpc.SetID();
            if (object.setId != null)
                if (typeof object.setId === "string")
                    $util.base64.decode(object.setId, message.setId = $util.newBuffer($util.base64.length(object.setId)), 0);
                else if (object.setId.length)
                    message.setId = object.setId;
            return message;
        };

        /**
         * Creates a plain object from a SetID message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SetID
         * @static
         * @param {lnrpc.SetID} message SetID
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetID.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.setId = "";
                else {
                    object.setId = [];
                    if (options.bytes !== Array)
                        object.setId = $util.newBuffer(object.setId);
                }
            if (message.setId != null && message.hasOwnProperty("setId"))
                object.setId = options.bytes === String ? $util.base64.encode(message.setId, 0, message.setId.length) : options.bytes === Array ? Array.prototype.slice.call(message.setId) : message.setId;
            return object;
        };

        /**
         * Converts this SetID to JSON.
         * @function toJSON
         * @memberof lnrpc.SetID
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetID.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetID;
    })();

    lnrpc.RouteHint = (function() {

        /**
         * Properties of a RouteHint.
         * @memberof lnrpc
         * @interface IRouteHint
         * @property {Array.<lnrpc.IHopHint>|null} [hopHints] RouteHint hopHints
         */

        /**
         * Constructs a new RouteHint.
         * @memberof lnrpc
         * @classdesc Represents a RouteHint.
         * @implements IRouteHint
         * @constructor
         * @param {lnrpc.IRouteHint=} [properties] Properties to set
         */
        function RouteHint(properties) {
            this.hopHints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RouteHint hopHints.
         * @member {Array.<lnrpc.IHopHint>} hopHints
         * @memberof lnrpc.RouteHint
         * @instance
         */
        RouteHint.prototype.hopHints = $util.emptyArray;

        /**
         * Creates a new RouteHint instance using the specified properties.
         * @function create
         * @memberof lnrpc.RouteHint
         * @static
         * @param {lnrpc.IRouteHint=} [properties] Properties to set
         * @returns {lnrpc.RouteHint} RouteHint instance
         */
        RouteHint.create = function create(properties) {
            return new RouteHint(properties);
        };

        /**
         * Encodes the specified RouteHint message. Does not implicitly {@link lnrpc.RouteHint.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RouteHint
         * @static
         * @param {lnrpc.IRouteHint} message RouteHint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RouteHint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hopHints != null && message.hopHints.length)
                for (let i = 0; i < message.hopHints.length; ++i)
                    $root.lnrpc.HopHint.encode(message.hopHints[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RouteHint message, length delimited. Does not implicitly {@link lnrpc.RouteHint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RouteHint
         * @static
         * @param {lnrpc.IRouteHint} message RouteHint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RouteHint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RouteHint message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RouteHint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RouteHint} RouteHint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RouteHint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RouteHint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.hopHints && message.hopHints.length))
                            message.hopHints = [];
                        message.hopHints.push($root.lnrpc.HopHint.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RouteHint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RouteHint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RouteHint} RouteHint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RouteHint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RouteHint message.
         * @function verify
         * @memberof lnrpc.RouteHint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RouteHint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hopHints != null && message.hasOwnProperty("hopHints")) {
                if (!Array.isArray(message.hopHints))
                    return "hopHints: array expected";
                for (let i = 0; i < message.hopHints.length; ++i) {
                    let error = $root.lnrpc.HopHint.verify(message.hopHints[i]);
                    if (error)
                        return "hopHints." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RouteHint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RouteHint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RouteHint} RouteHint
         */
        RouteHint.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RouteHint)
                return object;
            let message = new $root.lnrpc.RouteHint();
            if (object.hopHints) {
                if (!Array.isArray(object.hopHints))
                    throw TypeError(".lnrpc.RouteHint.hopHints: array expected");
                message.hopHints = [];
                for (let i = 0; i < object.hopHints.length; ++i) {
                    if (typeof object.hopHints[i] !== "object")
                        throw TypeError(".lnrpc.RouteHint.hopHints: object expected");
                    message.hopHints[i] = $root.lnrpc.HopHint.fromObject(object.hopHints[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a RouteHint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RouteHint
         * @static
         * @param {lnrpc.RouteHint} message RouteHint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RouteHint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.hopHints = [];
            if (message.hopHints && message.hopHints.length) {
                object.hopHints = [];
                for (let j = 0; j < message.hopHints.length; ++j)
                    object.hopHints[j] = $root.lnrpc.HopHint.toObject(message.hopHints[j], options);
            }
            return object;
        };

        /**
         * Converts this RouteHint to JSON.
         * @function toJSON
         * @memberof lnrpc.RouteHint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RouteHint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RouteHint;
    })();

    lnrpc.AMPInvoiceState = (function() {

        /**
         * Properties of a AMPInvoiceState.
         * @memberof lnrpc
         * @interface IAMPInvoiceState
         * @property {lnrpc.InvoiceHTLCState|null} [state] AMPInvoiceState state
         * @property {Long|null} [settleIndex] AMPInvoiceState settleIndex
         * @property {Long|null} [settleTime] AMPInvoiceState settleTime
         * @property {Long|null} [amtPaidMsat] AMPInvoiceState amtPaidMsat
         */

        /**
         * Constructs a new AMPInvoiceState.
         * @memberof lnrpc
         * @classdesc Represents a AMPInvoiceState.
         * @implements IAMPInvoiceState
         * @constructor
         * @param {lnrpc.IAMPInvoiceState=} [properties] Properties to set
         */
        function AMPInvoiceState(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AMPInvoiceState state.
         * @member {lnrpc.InvoiceHTLCState} state
         * @memberof lnrpc.AMPInvoiceState
         * @instance
         */
        AMPInvoiceState.prototype.state = 0;

        /**
         * AMPInvoiceState settleIndex.
         * @member {Long} settleIndex
         * @memberof lnrpc.AMPInvoiceState
         * @instance
         */
        AMPInvoiceState.prototype.settleIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AMPInvoiceState settleTime.
         * @member {Long} settleTime
         * @memberof lnrpc.AMPInvoiceState
         * @instance
         */
        AMPInvoiceState.prototype.settleTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AMPInvoiceState amtPaidMsat.
         * @member {Long} amtPaidMsat
         * @memberof lnrpc.AMPInvoiceState
         * @instance
         */
        AMPInvoiceState.prototype.amtPaidMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new AMPInvoiceState instance using the specified properties.
         * @function create
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {lnrpc.IAMPInvoiceState=} [properties] Properties to set
         * @returns {lnrpc.AMPInvoiceState} AMPInvoiceState instance
         */
        AMPInvoiceState.create = function create(properties) {
            return new AMPInvoiceState(properties);
        };

        /**
         * Encodes the specified AMPInvoiceState message. Does not implicitly {@link lnrpc.AMPInvoiceState.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {lnrpc.IAMPInvoiceState} message AMPInvoiceState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AMPInvoiceState.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
            if (message.settleIndex != null && Object.hasOwnProperty.call(message, "settleIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.settleIndex);
            if (message.settleTime != null && Object.hasOwnProperty.call(message, "settleTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.settleTime);
            if (message.amtPaidMsat != null && Object.hasOwnProperty.call(message, "amtPaidMsat"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.amtPaidMsat);
            return writer;
        };

        /**
         * Encodes the specified AMPInvoiceState message, length delimited. Does not implicitly {@link lnrpc.AMPInvoiceState.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {lnrpc.IAMPInvoiceState} message AMPInvoiceState message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AMPInvoiceState.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a AMPInvoiceState message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AMPInvoiceState} AMPInvoiceState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AMPInvoiceState.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AMPInvoiceState();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.int32();
                        break;
                    }
                case 2: {
                        message.settleIndex = reader.uint64();
                        break;
                    }
                case 3: {
                        message.settleTime = reader.int64();
                        break;
                    }
                case 5: {
                        message.amtPaidMsat = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a AMPInvoiceState message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AMPInvoiceState} AMPInvoiceState
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AMPInvoiceState.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a AMPInvoiceState message.
         * @function verify
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AMPInvoiceState.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.settleIndex != null && message.hasOwnProperty("settleIndex"))
                if (!$util.isInteger(message.settleIndex) && !(message.settleIndex && $util.isInteger(message.settleIndex.low) && $util.isInteger(message.settleIndex.high)))
                    return "settleIndex: integer|Long expected";
            if (message.settleTime != null && message.hasOwnProperty("settleTime"))
                if (!$util.isInteger(message.settleTime) && !(message.settleTime && $util.isInteger(message.settleTime.low) && $util.isInteger(message.settleTime.high)))
                    return "settleTime: integer|Long expected";
            if (message.amtPaidMsat != null && message.hasOwnProperty("amtPaidMsat"))
                if (!$util.isInteger(message.amtPaidMsat) && !(message.amtPaidMsat && $util.isInteger(message.amtPaidMsat.low) && $util.isInteger(message.amtPaidMsat.high)))
                    return "amtPaidMsat: integer|Long expected";
            return null;
        };

        /**
         * Creates a AMPInvoiceState message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AMPInvoiceState} AMPInvoiceState
         */
        AMPInvoiceState.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AMPInvoiceState)
                return object;
            let message = new $root.lnrpc.AMPInvoiceState();
            switch (object.state) {
            case "ACCEPTED":
            case 0:
                message.state = 0;
                break;
            case "SETTLED":
            case 1:
                message.state = 1;
                break;
            case "CANCELED":
            case 2:
                message.state = 2;
                break;
            }
            if (object.settleIndex != null)
                if ($util.Long)
                    (message.settleIndex = $util.Long.fromValue(object.settleIndex)).unsigned = true;
                else if (typeof object.settleIndex === "string")
                    message.settleIndex = parseInt(object.settleIndex, 10);
                else if (typeof object.settleIndex === "number")
                    message.settleIndex = object.settleIndex;
                else if (typeof object.settleIndex === "object")
                    message.settleIndex = new $util.LongBits(object.settleIndex.low >>> 0, object.settleIndex.high >>> 0).toNumber(true);
            if (object.settleTime != null)
                if ($util.Long)
                    (message.settleTime = $util.Long.fromValue(object.settleTime)).unsigned = false;
                else if (typeof object.settleTime === "string")
                    message.settleTime = parseInt(object.settleTime, 10);
                else if (typeof object.settleTime === "number")
                    message.settleTime = object.settleTime;
                else if (typeof object.settleTime === "object")
                    message.settleTime = new $util.LongBits(object.settleTime.low >>> 0, object.settleTime.high >>> 0).toNumber();
            if (object.amtPaidMsat != null)
                if ($util.Long)
                    (message.amtPaidMsat = $util.Long.fromValue(object.amtPaidMsat)).unsigned = false;
                else if (typeof object.amtPaidMsat === "string")
                    message.amtPaidMsat = parseInt(object.amtPaidMsat, 10);
                else if (typeof object.amtPaidMsat === "number")
                    message.amtPaidMsat = object.amtPaidMsat;
                else if (typeof object.amtPaidMsat === "object")
                    message.amtPaidMsat = new $util.LongBits(object.amtPaidMsat.low >>> 0, object.amtPaidMsat.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a AMPInvoiceState message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AMPInvoiceState
         * @static
         * @param {lnrpc.AMPInvoiceState} message AMPInvoiceState
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AMPInvoiceState.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.state = options.enums === String ? "ACCEPTED" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.settleIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.settleIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.settleTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.settleTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtPaidMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtPaidMsat = options.longs === String ? "0" : 0;
            }
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.lnrpc.InvoiceHTLCState[message.state] : message.state;
            if (message.settleIndex != null && message.hasOwnProperty("settleIndex"))
                if (typeof message.settleIndex === "number")
                    object.settleIndex = options.longs === String ? String(message.settleIndex) : message.settleIndex;
                else
                    object.settleIndex = options.longs === String ? $util.Long.prototype.toString.call(message.settleIndex) : options.longs === Number ? new $util.LongBits(message.settleIndex.low >>> 0, message.settleIndex.high >>> 0).toNumber(true) : message.settleIndex;
            if (message.settleTime != null && message.hasOwnProperty("settleTime"))
                if (typeof message.settleTime === "number")
                    object.settleTime = options.longs === String ? String(message.settleTime) : message.settleTime;
                else
                    object.settleTime = options.longs === String ? $util.Long.prototype.toString.call(message.settleTime) : options.longs === Number ? new $util.LongBits(message.settleTime.low >>> 0, message.settleTime.high >>> 0).toNumber() : message.settleTime;
            if (message.amtPaidMsat != null && message.hasOwnProperty("amtPaidMsat"))
                if (typeof message.amtPaidMsat === "number")
                    object.amtPaidMsat = options.longs === String ? String(message.amtPaidMsat) : message.amtPaidMsat;
                else
                    object.amtPaidMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtPaidMsat) : options.longs === Number ? new $util.LongBits(message.amtPaidMsat.low >>> 0, message.amtPaidMsat.high >>> 0).toNumber() : message.amtPaidMsat;
            return object;
        };

        /**
         * Converts this AMPInvoiceState to JSON.
         * @function toJSON
         * @memberof lnrpc.AMPInvoiceState
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AMPInvoiceState.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AMPInvoiceState;
    })();

    lnrpc.Invoice = (function() {

        /**
         * Properties of an Invoice.
         * @memberof lnrpc
         * @interface IInvoice
         * @property {string|null} [memo] Invoice memo
         * @property {Uint8Array|null} [rPreimage] Invoice rPreimage
         * @property {Uint8Array|null} [rHash] Invoice rHash
         * @property {Long|null} [value] Invoice value
         * @property {Long|null} [valueMsat] Invoice valueMsat
         * @property {boolean|null} [settled] Invoice settled
         * @property {Long|null} [creationDate] Invoice creationDate
         * @property {Long|null} [settleDate] Invoice settleDate
         * @property {string|null} [paymentRequest] Invoice paymentRequest
         * @property {Uint8Array|null} [descriptionHash] Invoice descriptionHash
         * @property {Long|null} [expiry] Invoice expiry
         * @property {string|null} [fallbackAddr] Invoice fallbackAddr
         * @property {Long|null} [cltvExpiry] Invoice cltvExpiry
         * @property {Array.<lnrpc.IRouteHint>|null} [routeHints] Invoice routeHints
         * @property {boolean|null} ["private"] Invoice private
         * @property {Long|null} [addIndex] Invoice addIndex
         * @property {Long|null} [settleIndex] Invoice settleIndex
         * @property {Long|null} [amtPaid] Invoice amtPaid
         * @property {Long|null} [amtPaidSat] Invoice amtPaidSat
         * @property {Long|null} [amtPaidMsat] Invoice amtPaidMsat
         * @property {lnrpc.Invoice.InvoiceState|null} [state] Invoice state
         * @property {Array.<lnrpc.IInvoiceHTLC>|null} [htlcs] Invoice htlcs
         * @property {Object.<string,lnrpc.IFeature>|null} [features] Invoice features
         * @property {boolean|null} [isKeysend] Invoice isKeysend
         * @property {Uint8Array|null} [paymentAddr] Invoice paymentAddr
         * @property {boolean|null} [isAmp] Invoice isAmp
         * @property {Object.<string,lnrpc.IAMPInvoiceState>|null} [ampInvoiceState] Invoice ampInvoiceState
         */

        /**
         * Constructs a new Invoice.
         * @memberof lnrpc
         * @classdesc Represents an Invoice.
         * @implements IInvoice
         * @constructor
         * @param {lnrpc.IInvoice=} [properties] Properties to set
         */
        function Invoice(properties) {
            this.routeHints = [];
            this.htlcs = [];
            this.features = {};
            this.ampInvoiceState = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Invoice memo.
         * @member {string} memo
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.memo = "";

        /**
         * Invoice rPreimage.
         * @member {Uint8Array} rPreimage
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.rPreimage = $util.newBuffer([]);

        /**
         * Invoice rHash.
         * @member {Uint8Array} rHash
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.rHash = $util.newBuffer([]);

        /**
         * Invoice value.
         * @member {Long} value
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice valueMsat.
         * @member {Long} valueMsat
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.valueMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice settled.
         * @member {boolean} settled
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.settled = false;

        /**
         * Invoice creationDate.
         * @member {Long} creationDate
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.creationDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice settleDate.
         * @member {Long} settleDate
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.settleDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice paymentRequest.
         * @member {string} paymentRequest
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.paymentRequest = "";

        /**
         * Invoice descriptionHash.
         * @member {Uint8Array} descriptionHash
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.descriptionHash = $util.newBuffer([]);

        /**
         * Invoice expiry.
         * @member {Long} expiry
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.expiry = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice fallbackAddr.
         * @member {string} fallbackAddr
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.fallbackAddr = "";

        /**
         * Invoice cltvExpiry.
         * @member {Long} cltvExpiry
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.cltvExpiry = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invoice routeHints.
         * @member {Array.<lnrpc.IRouteHint>} routeHints
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.routeHints = $util.emptyArray;

        /**
         * Invoice private.
         * @member {boolean} private
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype["private"] = false;

        /**
         * Invoice addIndex.
         * @member {Long} addIndex
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.addIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invoice settleIndex.
         * @member {Long} settleIndex
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.settleIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Invoice amtPaid.
         * @member {Long} amtPaid
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.amtPaid = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice amtPaidSat.
         * @member {Long} amtPaidSat
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.amtPaidSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice amtPaidMsat.
         * @member {Long} amtPaidMsat
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.amtPaidMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invoice state.
         * @member {lnrpc.Invoice.InvoiceState} state
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.state = 0;

        /**
         * Invoice htlcs.
         * @member {Array.<lnrpc.IInvoiceHTLC>} htlcs
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.htlcs = $util.emptyArray;

        /**
         * Invoice features.
         * @member {Object.<string,lnrpc.IFeature>} features
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.features = $util.emptyObject;

        /**
         * Invoice isKeysend.
         * @member {boolean} isKeysend
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.isKeysend = false;

        /**
         * Invoice paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * Invoice isAmp.
         * @member {boolean} isAmp
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.isAmp = false;

        /**
         * Invoice ampInvoiceState.
         * @member {Object.<string,lnrpc.IAMPInvoiceState>} ampInvoiceState
         * @memberof lnrpc.Invoice
         * @instance
         */
        Invoice.prototype.ampInvoiceState = $util.emptyObject;

        /**
         * Creates a new Invoice instance using the specified properties.
         * @function create
         * @memberof lnrpc.Invoice
         * @static
         * @param {lnrpc.IInvoice=} [properties] Properties to set
         * @returns {lnrpc.Invoice} Invoice instance
         */
        Invoice.create = function create(properties) {
            return new Invoice(properties);
        };

        /**
         * Encodes the specified Invoice message. Does not implicitly {@link lnrpc.Invoice.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Invoice
         * @static
         * @param {lnrpc.IInvoice} message Invoice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Invoice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memo != null && Object.hasOwnProperty.call(message, "memo"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.memo);
            if (message.rPreimage != null && Object.hasOwnProperty.call(message, "rPreimage"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.rPreimage);
            if (message.rHash != null && Object.hasOwnProperty.call(message, "rHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.rHash);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.value);
            if (message.settled != null && Object.hasOwnProperty.call(message, "settled"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.settled);
            if (message.creationDate != null && Object.hasOwnProperty.call(message, "creationDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.creationDate);
            if (message.settleDate != null && Object.hasOwnProperty.call(message, "settleDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.settleDate);
            if (message.paymentRequest != null && Object.hasOwnProperty.call(message, "paymentRequest"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.paymentRequest);
            if (message.descriptionHash != null && Object.hasOwnProperty.call(message, "descriptionHash"))
                writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.descriptionHash);
            if (message.expiry != null && Object.hasOwnProperty.call(message, "expiry"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.expiry);
            if (message.fallbackAddr != null && Object.hasOwnProperty.call(message, "fallbackAddr"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.fallbackAddr);
            if (message.cltvExpiry != null && Object.hasOwnProperty.call(message, "cltvExpiry"))
                writer.uint32(/* id 13, wireType 0 =*/104).uint64(message.cltvExpiry);
            if (message.routeHints != null && message.routeHints.length)
                for (let i = 0; i < message.routeHints.length; ++i)
                    $root.lnrpc.RouteHint.encode(message.routeHints[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message["private"]);
            if (message.addIndex != null && Object.hasOwnProperty.call(message, "addIndex"))
                writer.uint32(/* id 16, wireType 0 =*/128).uint64(message.addIndex);
            if (message.settleIndex != null && Object.hasOwnProperty.call(message, "settleIndex"))
                writer.uint32(/* id 17, wireType 0 =*/136).uint64(message.settleIndex);
            if (message.amtPaid != null && Object.hasOwnProperty.call(message, "amtPaid"))
                writer.uint32(/* id 18, wireType 0 =*/144).int64(message.amtPaid);
            if (message.amtPaidSat != null && Object.hasOwnProperty.call(message, "amtPaidSat"))
                writer.uint32(/* id 19, wireType 0 =*/152).int64(message.amtPaidSat);
            if (message.amtPaidMsat != null && Object.hasOwnProperty.call(message, "amtPaidMsat"))
                writer.uint32(/* id 20, wireType 0 =*/160).int64(message.amtPaidMsat);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.state);
            if (message.htlcs != null && message.htlcs.length)
                for (let i = 0; i < message.htlcs.length; ++i)
                    $root.lnrpc.InvoiceHTLC.encode(message.htlcs[i], writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.valueMsat != null && Object.hasOwnProperty.call(message, "valueMsat"))
                writer.uint32(/* id 23, wireType 0 =*/184).int64(message.valueMsat);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                for (let keys = Object.keys(message.features), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 24, wireType 2 =*/194).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.lnrpc.Feature.encode(message.features[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.isKeysend != null && Object.hasOwnProperty.call(message, "isKeysend"))
                writer.uint32(/* id 25, wireType 0 =*/200).bool(message.isKeysend);
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 26, wireType 2 =*/210).bytes(message.paymentAddr);
            if (message.isAmp != null && Object.hasOwnProperty.call(message, "isAmp"))
                writer.uint32(/* id 27, wireType 0 =*/216).bool(message.isAmp);
            if (message.ampInvoiceState != null && Object.hasOwnProperty.call(message, "ampInvoiceState"))
                for (let keys = Object.keys(message.ampInvoiceState), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 28, wireType 2 =*/226).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.lnrpc.AMPInvoiceState.encode(message.ampInvoiceState[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified Invoice message, length delimited. Does not implicitly {@link lnrpc.Invoice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Invoice
         * @static
         * @param {lnrpc.IInvoice} message Invoice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Invoice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Invoice message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Invoice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Invoice} Invoice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Invoice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Invoice(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memo = reader.string();
                        break;
                    }
                case 3: {
                        message.rPreimage = reader.bytes();
                        break;
                    }
                case 4: {
                        message.rHash = reader.bytes();
                        break;
                    }
                case 5: {
                        message.value = reader.int64();
                        break;
                    }
                case 23: {
                        message.valueMsat = reader.int64();
                        break;
                    }
                case 6: {
                        message.settled = reader.bool();
                        break;
                    }
                case 7: {
                        message.creationDate = reader.int64();
                        break;
                    }
                case 8: {
                        message.settleDate = reader.int64();
                        break;
                    }
                case 9: {
                        message.paymentRequest = reader.string();
                        break;
                    }
                case 10: {
                        message.descriptionHash = reader.bytes();
                        break;
                    }
                case 11: {
                        message.expiry = reader.int64();
                        break;
                    }
                case 12: {
                        message.fallbackAddr = reader.string();
                        break;
                    }
                case 13: {
                        message.cltvExpiry = reader.uint64();
                        break;
                    }
                case 14: {
                        if (!(message.routeHints && message.routeHints.length))
                            message.routeHints = [];
                        message.routeHints.push($root.lnrpc.RouteHint.decode(reader, reader.uint32()));
                        break;
                    }
                case 15: {
                        message["private"] = reader.bool();
                        break;
                    }
                case 16: {
                        message.addIndex = reader.uint64();
                        break;
                    }
                case 17: {
                        message.settleIndex = reader.uint64();
                        break;
                    }
                case 18: {
                        message.amtPaid = reader.int64();
                        break;
                    }
                case 19: {
                        message.amtPaidSat = reader.int64();
                        break;
                    }
                case 20: {
                        message.amtPaidMsat = reader.int64();
                        break;
                    }
                case 21: {
                        message.state = reader.int32();
                        break;
                    }
                case 22: {
                        if (!(message.htlcs && message.htlcs.length))
                            message.htlcs = [];
                        message.htlcs.push($root.lnrpc.InvoiceHTLC.decode(reader, reader.uint32()));
                        break;
                    }
                case 24: {
                        if (message.features === $util.emptyObject)
                            message.features = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.lnrpc.Feature.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.features[key] = value;
                        break;
                    }
                case 25: {
                        message.isKeysend = reader.bool();
                        break;
                    }
                case 26: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                case 27: {
                        message.isAmp = reader.bool();
                        break;
                    }
                case 28: {
                        if (message.ampInvoiceState === $util.emptyObject)
                            message.ampInvoiceState = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.lnrpc.AMPInvoiceState.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.ampInvoiceState[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Invoice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Invoice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Invoice} Invoice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Invoice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Invoice message.
         * @function verify
         * @memberof lnrpc.Invoice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Invoice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memo != null && message.hasOwnProperty("memo"))
                if (!$util.isString(message.memo))
                    return "memo: string expected";
            if (message.rPreimage != null && message.hasOwnProperty("rPreimage"))
                if (!(message.rPreimage && typeof message.rPreimage.length === "number" || $util.isString(message.rPreimage)))
                    return "rPreimage: buffer expected";
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                if (!(message.rHash && typeof message.rHash.length === "number" || $util.isString(message.rHash)))
                    return "rHash: buffer expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                    return "value: integer|Long expected";
            if (message.valueMsat != null && message.hasOwnProperty("valueMsat"))
                if (!$util.isInteger(message.valueMsat) && !(message.valueMsat && $util.isInteger(message.valueMsat.low) && $util.isInteger(message.valueMsat.high)))
                    return "valueMsat: integer|Long expected";
            if (message.settled != null && message.hasOwnProperty("settled"))
                if (typeof message.settled !== "boolean")
                    return "settled: boolean expected";
            if (message.creationDate != null && message.hasOwnProperty("creationDate"))
                if (!$util.isInteger(message.creationDate) && !(message.creationDate && $util.isInteger(message.creationDate.low) && $util.isInteger(message.creationDate.high)))
                    return "creationDate: integer|Long expected";
            if (message.settleDate != null && message.hasOwnProperty("settleDate"))
                if (!$util.isInteger(message.settleDate) && !(message.settleDate && $util.isInteger(message.settleDate.low) && $util.isInteger(message.settleDate.high)))
                    return "settleDate: integer|Long expected";
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                if (!$util.isString(message.paymentRequest))
                    return "paymentRequest: string expected";
            if (message.descriptionHash != null && message.hasOwnProperty("descriptionHash"))
                if (!(message.descriptionHash && typeof message.descriptionHash.length === "number" || $util.isString(message.descriptionHash)))
                    return "descriptionHash: buffer expected";
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (!$util.isInteger(message.expiry) && !(message.expiry && $util.isInteger(message.expiry.low) && $util.isInteger(message.expiry.high)))
                    return "expiry: integer|Long expected";
            if (message.fallbackAddr != null && message.hasOwnProperty("fallbackAddr"))
                if (!$util.isString(message.fallbackAddr))
                    return "fallbackAddr: string expected";
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (!$util.isInteger(message.cltvExpiry) && !(message.cltvExpiry && $util.isInteger(message.cltvExpiry.low) && $util.isInteger(message.cltvExpiry.high)))
                    return "cltvExpiry: integer|Long expected";
            if (message.routeHints != null && message.hasOwnProperty("routeHints")) {
                if (!Array.isArray(message.routeHints))
                    return "routeHints: array expected";
                for (let i = 0; i < message.routeHints.length; ++i) {
                    let error = $root.lnrpc.RouteHint.verify(message.routeHints[i]);
                    if (error)
                        return "routeHints." + error;
                }
            }
            if (message["private"] != null && message.hasOwnProperty("private"))
                if (typeof message["private"] !== "boolean")
                    return "private: boolean expected";
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (!$util.isInteger(message.addIndex) && !(message.addIndex && $util.isInteger(message.addIndex.low) && $util.isInteger(message.addIndex.high)))
                    return "addIndex: integer|Long expected";
            if (message.settleIndex != null && message.hasOwnProperty("settleIndex"))
                if (!$util.isInteger(message.settleIndex) && !(message.settleIndex && $util.isInteger(message.settleIndex.low) && $util.isInteger(message.settleIndex.high)))
                    return "settleIndex: integer|Long expected";
            if (message.amtPaid != null && message.hasOwnProperty("amtPaid"))
                if (!$util.isInteger(message.amtPaid) && !(message.amtPaid && $util.isInteger(message.amtPaid.low) && $util.isInteger(message.amtPaid.high)))
                    return "amtPaid: integer|Long expected";
            if (message.amtPaidSat != null && message.hasOwnProperty("amtPaidSat"))
                if (!$util.isInteger(message.amtPaidSat) && !(message.amtPaidSat && $util.isInteger(message.amtPaidSat.low) && $util.isInteger(message.amtPaidSat.high)))
                    return "amtPaidSat: integer|Long expected";
            if (message.amtPaidMsat != null && message.hasOwnProperty("amtPaidMsat"))
                if (!$util.isInteger(message.amtPaidMsat) && !(message.amtPaidMsat && $util.isInteger(message.amtPaidMsat.low) && $util.isInteger(message.amtPaidMsat.high)))
                    return "amtPaidMsat: integer|Long expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.htlcs != null && message.hasOwnProperty("htlcs")) {
                if (!Array.isArray(message.htlcs))
                    return "htlcs: array expected";
                for (let i = 0; i < message.htlcs.length; ++i) {
                    let error = $root.lnrpc.InvoiceHTLC.verify(message.htlcs[i]);
                    if (error)
                        return "htlcs." + error;
                }
            }
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!$util.isObject(message.features))
                    return "features: object expected";
                let key = Object.keys(message.features);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "features: integer key{k:uint32} expected";
                    {
                        let error = $root.lnrpc.Feature.verify(message.features[key[i]]);
                        if (error)
                            return "features." + error;
                    }
                }
            }
            if (message.isKeysend != null && message.hasOwnProperty("isKeysend"))
                if (typeof message.isKeysend !== "boolean")
                    return "isKeysend: boolean expected";
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            if (message.isAmp != null && message.hasOwnProperty("isAmp"))
                if (typeof message.isAmp !== "boolean")
                    return "isAmp: boolean expected";
            if (message.ampInvoiceState != null && message.hasOwnProperty("ampInvoiceState")) {
                if (!$util.isObject(message.ampInvoiceState))
                    return "ampInvoiceState: object expected";
                let key = Object.keys(message.ampInvoiceState);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.lnrpc.AMPInvoiceState.verify(message.ampInvoiceState[key[i]]);
                    if (error)
                        return "ampInvoiceState." + error;
                }
            }
            return null;
        };

        /**
         * Creates an Invoice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Invoice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Invoice} Invoice
         */
        Invoice.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Invoice)
                return object;
            let message = new $root.lnrpc.Invoice();
            if (object.memo != null)
                message.memo = String(object.memo);
            if (object.rPreimage != null)
                if (typeof object.rPreimage === "string")
                    $util.base64.decode(object.rPreimage, message.rPreimage = $util.newBuffer($util.base64.length(object.rPreimage)), 0);
                else if (object.rPreimage.length)
                    message.rPreimage = object.rPreimage;
            if (object.rHash != null)
                if (typeof object.rHash === "string")
                    $util.base64.decode(object.rHash, message.rHash = $util.newBuffer($util.base64.length(object.rHash)), 0);
                else if (object.rHash.length)
                    message.rHash = object.rHash;
            if (object.value != null)
                if ($util.Long)
                    (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                else if (typeof object.value === "string")
                    message.value = parseInt(object.value, 10);
                else if (typeof object.value === "number")
                    message.value = object.value;
                else if (typeof object.value === "object")
                    message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
            if (object.valueMsat != null)
                if ($util.Long)
                    (message.valueMsat = $util.Long.fromValue(object.valueMsat)).unsigned = false;
                else if (typeof object.valueMsat === "string")
                    message.valueMsat = parseInt(object.valueMsat, 10);
                else if (typeof object.valueMsat === "number")
                    message.valueMsat = object.valueMsat;
                else if (typeof object.valueMsat === "object")
                    message.valueMsat = new $util.LongBits(object.valueMsat.low >>> 0, object.valueMsat.high >>> 0).toNumber();
            if (object.settled != null)
                message.settled = Boolean(object.settled);
            if (object.creationDate != null)
                if ($util.Long)
                    (message.creationDate = $util.Long.fromValue(object.creationDate)).unsigned = false;
                else if (typeof object.creationDate === "string")
                    message.creationDate = parseInt(object.creationDate, 10);
                else if (typeof object.creationDate === "number")
                    message.creationDate = object.creationDate;
                else if (typeof object.creationDate === "object")
                    message.creationDate = new $util.LongBits(object.creationDate.low >>> 0, object.creationDate.high >>> 0).toNumber();
            if (object.settleDate != null)
                if ($util.Long)
                    (message.settleDate = $util.Long.fromValue(object.settleDate)).unsigned = false;
                else if (typeof object.settleDate === "string")
                    message.settleDate = parseInt(object.settleDate, 10);
                else if (typeof object.settleDate === "number")
                    message.settleDate = object.settleDate;
                else if (typeof object.settleDate === "object")
                    message.settleDate = new $util.LongBits(object.settleDate.low >>> 0, object.settleDate.high >>> 0).toNumber();
            if (object.paymentRequest != null)
                message.paymentRequest = String(object.paymentRequest);
            if (object.descriptionHash != null)
                if (typeof object.descriptionHash === "string")
                    $util.base64.decode(object.descriptionHash, message.descriptionHash = $util.newBuffer($util.base64.length(object.descriptionHash)), 0);
                else if (object.descriptionHash.length)
                    message.descriptionHash = object.descriptionHash;
            if (object.expiry != null)
                if ($util.Long)
                    (message.expiry = $util.Long.fromValue(object.expiry)).unsigned = false;
                else if (typeof object.expiry === "string")
                    message.expiry = parseInt(object.expiry, 10);
                else if (typeof object.expiry === "number")
                    message.expiry = object.expiry;
                else if (typeof object.expiry === "object")
                    message.expiry = new $util.LongBits(object.expiry.low >>> 0, object.expiry.high >>> 0).toNumber();
            if (object.fallbackAddr != null)
                message.fallbackAddr = String(object.fallbackAddr);
            if (object.cltvExpiry != null)
                if ($util.Long)
                    (message.cltvExpiry = $util.Long.fromValue(object.cltvExpiry)).unsigned = true;
                else if (typeof object.cltvExpiry === "string")
                    message.cltvExpiry = parseInt(object.cltvExpiry, 10);
                else if (typeof object.cltvExpiry === "number")
                    message.cltvExpiry = object.cltvExpiry;
                else if (typeof object.cltvExpiry === "object")
                    message.cltvExpiry = new $util.LongBits(object.cltvExpiry.low >>> 0, object.cltvExpiry.high >>> 0).toNumber(true);
            if (object.routeHints) {
                if (!Array.isArray(object.routeHints))
                    throw TypeError(".lnrpc.Invoice.routeHints: array expected");
                message.routeHints = [];
                for (let i = 0; i < object.routeHints.length; ++i) {
                    if (typeof object.routeHints[i] !== "object")
                        throw TypeError(".lnrpc.Invoice.routeHints: object expected");
                    message.routeHints[i] = $root.lnrpc.RouteHint.fromObject(object.routeHints[i]);
                }
            }
            if (object["private"] != null)
                message["private"] = Boolean(object["private"]);
            if (object.addIndex != null)
                if ($util.Long)
                    (message.addIndex = $util.Long.fromValue(object.addIndex)).unsigned = true;
                else if (typeof object.addIndex === "string")
                    message.addIndex = parseInt(object.addIndex, 10);
                else if (typeof object.addIndex === "number")
                    message.addIndex = object.addIndex;
                else if (typeof object.addIndex === "object")
                    message.addIndex = new $util.LongBits(object.addIndex.low >>> 0, object.addIndex.high >>> 0).toNumber(true);
            if (object.settleIndex != null)
                if ($util.Long)
                    (message.settleIndex = $util.Long.fromValue(object.settleIndex)).unsigned = true;
                else if (typeof object.settleIndex === "string")
                    message.settleIndex = parseInt(object.settleIndex, 10);
                else if (typeof object.settleIndex === "number")
                    message.settleIndex = object.settleIndex;
                else if (typeof object.settleIndex === "object")
                    message.settleIndex = new $util.LongBits(object.settleIndex.low >>> 0, object.settleIndex.high >>> 0).toNumber(true);
            if (object.amtPaid != null)
                if ($util.Long)
                    (message.amtPaid = $util.Long.fromValue(object.amtPaid)).unsigned = false;
                else if (typeof object.amtPaid === "string")
                    message.amtPaid = parseInt(object.amtPaid, 10);
                else if (typeof object.amtPaid === "number")
                    message.amtPaid = object.amtPaid;
                else if (typeof object.amtPaid === "object")
                    message.amtPaid = new $util.LongBits(object.amtPaid.low >>> 0, object.amtPaid.high >>> 0).toNumber();
            if (object.amtPaidSat != null)
                if ($util.Long)
                    (message.amtPaidSat = $util.Long.fromValue(object.amtPaidSat)).unsigned = false;
                else if (typeof object.amtPaidSat === "string")
                    message.amtPaidSat = parseInt(object.amtPaidSat, 10);
                else if (typeof object.amtPaidSat === "number")
                    message.amtPaidSat = object.amtPaidSat;
                else if (typeof object.amtPaidSat === "object")
                    message.amtPaidSat = new $util.LongBits(object.amtPaidSat.low >>> 0, object.amtPaidSat.high >>> 0).toNumber();
            if (object.amtPaidMsat != null)
                if ($util.Long)
                    (message.amtPaidMsat = $util.Long.fromValue(object.amtPaidMsat)).unsigned = false;
                else if (typeof object.amtPaidMsat === "string")
                    message.amtPaidMsat = parseInt(object.amtPaidMsat, 10);
                else if (typeof object.amtPaidMsat === "number")
                    message.amtPaidMsat = object.amtPaidMsat;
                else if (typeof object.amtPaidMsat === "object")
                    message.amtPaidMsat = new $util.LongBits(object.amtPaidMsat.low >>> 0, object.amtPaidMsat.high >>> 0).toNumber();
            switch (object.state) {
            case "OPEN":
            case 0:
                message.state = 0;
                break;
            case "SETTLED":
            case 1:
                message.state = 1;
                break;
            case "CANCELED":
            case 2:
                message.state = 2;
                break;
            case "ACCEPTED":
            case 3:
                message.state = 3;
                break;
            }
            if (object.htlcs) {
                if (!Array.isArray(object.htlcs))
                    throw TypeError(".lnrpc.Invoice.htlcs: array expected");
                message.htlcs = [];
                for (let i = 0; i < object.htlcs.length; ++i) {
                    if (typeof object.htlcs[i] !== "object")
                        throw TypeError(".lnrpc.Invoice.htlcs: object expected");
                    message.htlcs[i] = $root.lnrpc.InvoiceHTLC.fromObject(object.htlcs[i]);
                }
            }
            if (object.features) {
                if (typeof object.features !== "object")
                    throw TypeError(".lnrpc.Invoice.features: object expected");
                message.features = {};
                for (let keys = Object.keys(object.features), i = 0; i < keys.length; ++i) {
                    if (typeof object.features[keys[i]] !== "object")
                        throw TypeError(".lnrpc.Invoice.features: object expected");
                    message.features[keys[i]] = $root.lnrpc.Feature.fromObject(object.features[keys[i]]);
                }
            }
            if (object.isKeysend != null)
                message.isKeysend = Boolean(object.isKeysend);
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            if (object.isAmp != null)
                message.isAmp = Boolean(object.isAmp);
            if (object.ampInvoiceState) {
                if (typeof object.ampInvoiceState !== "object")
                    throw TypeError(".lnrpc.Invoice.ampInvoiceState: object expected");
                message.ampInvoiceState = {};
                for (let keys = Object.keys(object.ampInvoiceState), i = 0; i < keys.length; ++i) {
                    if (typeof object.ampInvoiceState[keys[i]] !== "object")
                        throw TypeError(".lnrpc.Invoice.ampInvoiceState: object expected");
                    message.ampInvoiceState[keys[i]] = $root.lnrpc.AMPInvoiceState.fromObject(object.ampInvoiceState[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an Invoice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Invoice
         * @static
         * @param {lnrpc.Invoice} message Invoice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Invoice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.routeHints = [];
                object.htlcs = [];
            }
            if (options.objects || options.defaults) {
                object.features = {};
                object.ampInvoiceState = {};
            }
            if (options.defaults) {
                object.memo = "";
                if (options.bytes === String)
                    object.rPreimage = "";
                else {
                    object.rPreimage = [];
                    if (options.bytes !== Array)
                        object.rPreimage = $util.newBuffer(object.rPreimage);
                }
                if (options.bytes === String)
                    object.rHash = "";
                else {
                    object.rHash = [];
                    if (options.bytes !== Array)
                        object.rHash = $util.newBuffer(object.rHash);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.value = options.longs === String ? "0" : 0;
                object.settled = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creationDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creationDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.settleDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.settleDate = options.longs === String ? "0" : 0;
                object.paymentRequest = "";
                if (options.bytes === String)
                    object.descriptionHash = "";
                else {
                    object.descriptionHash = [];
                    if (options.bytes !== Array)
                        object.descriptionHash = $util.newBuffer(object.descriptionHash);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expiry = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiry = options.longs === String ? "0" : 0;
                object.fallbackAddr = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.cltvExpiry = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cltvExpiry = options.longs === String ? "0" : 0;
                object["private"] = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.addIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.addIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.settleIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.settleIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtPaid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtPaid = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtPaidSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtPaidSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtPaidMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtPaidMsat = options.longs === String ? "0" : 0;
                object.state = options.enums === String ? "OPEN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.valueMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.valueMsat = options.longs === String ? "0" : 0;
                object.isKeysend = false;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
                object.isAmp = false;
            }
            if (message.memo != null && message.hasOwnProperty("memo"))
                object.memo = message.memo;
            if (message.rPreimage != null && message.hasOwnProperty("rPreimage"))
                object.rPreimage = options.bytes === String ? $util.base64.encode(message.rPreimage, 0, message.rPreimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.rPreimage) : message.rPreimage;
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                object.rHash = options.bytes === String ? $util.base64.encode(message.rHash, 0, message.rHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.rHash) : message.rHash;
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value === "number")
                    object.value = options.longs === String ? String(message.value) : message.value;
                else
                    object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
            if (message.settled != null && message.hasOwnProperty("settled"))
                object.settled = message.settled;
            if (message.creationDate != null && message.hasOwnProperty("creationDate"))
                if (typeof message.creationDate === "number")
                    object.creationDate = options.longs === String ? String(message.creationDate) : message.creationDate;
                else
                    object.creationDate = options.longs === String ? $util.Long.prototype.toString.call(message.creationDate) : options.longs === Number ? new $util.LongBits(message.creationDate.low >>> 0, message.creationDate.high >>> 0).toNumber() : message.creationDate;
            if (message.settleDate != null && message.hasOwnProperty("settleDate"))
                if (typeof message.settleDate === "number")
                    object.settleDate = options.longs === String ? String(message.settleDate) : message.settleDate;
                else
                    object.settleDate = options.longs === String ? $util.Long.prototype.toString.call(message.settleDate) : options.longs === Number ? new $util.LongBits(message.settleDate.low >>> 0, message.settleDate.high >>> 0).toNumber() : message.settleDate;
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                object.paymentRequest = message.paymentRequest;
            if (message.descriptionHash != null && message.hasOwnProperty("descriptionHash"))
                object.descriptionHash = options.bytes === String ? $util.base64.encode(message.descriptionHash, 0, message.descriptionHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.descriptionHash) : message.descriptionHash;
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (typeof message.expiry === "number")
                    object.expiry = options.longs === String ? String(message.expiry) : message.expiry;
                else
                    object.expiry = options.longs === String ? $util.Long.prototype.toString.call(message.expiry) : options.longs === Number ? new $util.LongBits(message.expiry.low >>> 0, message.expiry.high >>> 0).toNumber() : message.expiry;
            if (message.fallbackAddr != null && message.hasOwnProperty("fallbackAddr"))
                object.fallbackAddr = message.fallbackAddr;
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (typeof message.cltvExpiry === "number")
                    object.cltvExpiry = options.longs === String ? String(message.cltvExpiry) : message.cltvExpiry;
                else
                    object.cltvExpiry = options.longs === String ? $util.Long.prototype.toString.call(message.cltvExpiry) : options.longs === Number ? new $util.LongBits(message.cltvExpiry.low >>> 0, message.cltvExpiry.high >>> 0).toNumber(true) : message.cltvExpiry;
            if (message.routeHints && message.routeHints.length) {
                object.routeHints = [];
                for (let j = 0; j < message.routeHints.length; ++j)
                    object.routeHints[j] = $root.lnrpc.RouteHint.toObject(message.routeHints[j], options);
            }
            if (message["private"] != null && message.hasOwnProperty("private"))
                object["private"] = message["private"];
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (typeof message.addIndex === "number")
                    object.addIndex = options.longs === String ? String(message.addIndex) : message.addIndex;
                else
                    object.addIndex = options.longs === String ? $util.Long.prototype.toString.call(message.addIndex) : options.longs === Number ? new $util.LongBits(message.addIndex.low >>> 0, message.addIndex.high >>> 0).toNumber(true) : message.addIndex;
            if (message.settleIndex != null && message.hasOwnProperty("settleIndex"))
                if (typeof message.settleIndex === "number")
                    object.settleIndex = options.longs === String ? String(message.settleIndex) : message.settleIndex;
                else
                    object.settleIndex = options.longs === String ? $util.Long.prototype.toString.call(message.settleIndex) : options.longs === Number ? new $util.LongBits(message.settleIndex.low >>> 0, message.settleIndex.high >>> 0).toNumber(true) : message.settleIndex;
            if (message.amtPaid != null && message.hasOwnProperty("amtPaid"))
                if (typeof message.amtPaid === "number")
                    object.amtPaid = options.longs === String ? String(message.amtPaid) : message.amtPaid;
                else
                    object.amtPaid = options.longs === String ? $util.Long.prototype.toString.call(message.amtPaid) : options.longs === Number ? new $util.LongBits(message.amtPaid.low >>> 0, message.amtPaid.high >>> 0).toNumber() : message.amtPaid;
            if (message.amtPaidSat != null && message.hasOwnProperty("amtPaidSat"))
                if (typeof message.amtPaidSat === "number")
                    object.amtPaidSat = options.longs === String ? String(message.amtPaidSat) : message.amtPaidSat;
                else
                    object.amtPaidSat = options.longs === String ? $util.Long.prototype.toString.call(message.amtPaidSat) : options.longs === Number ? new $util.LongBits(message.amtPaidSat.low >>> 0, message.amtPaidSat.high >>> 0).toNumber() : message.amtPaidSat;
            if (message.amtPaidMsat != null && message.hasOwnProperty("amtPaidMsat"))
                if (typeof message.amtPaidMsat === "number")
                    object.amtPaidMsat = options.longs === String ? String(message.amtPaidMsat) : message.amtPaidMsat;
                else
                    object.amtPaidMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtPaidMsat) : options.longs === Number ? new $util.LongBits(message.amtPaidMsat.low >>> 0, message.amtPaidMsat.high >>> 0).toNumber() : message.amtPaidMsat;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.lnrpc.Invoice.InvoiceState[message.state] : message.state;
            if (message.htlcs && message.htlcs.length) {
                object.htlcs = [];
                for (let j = 0; j < message.htlcs.length; ++j)
                    object.htlcs[j] = $root.lnrpc.InvoiceHTLC.toObject(message.htlcs[j], options);
            }
            if (message.valueMsat != null && message.hasOwnProperty("valueMsat"))
                if (typeof message.valueMsat === "number")
                    object.valueMsat = options.longs === String ? String(message.valueMsat) : message.valueMsat;
                else
                    object.valueMsat = options.longs === String ? $util.Long.prototype.toString.call(message.valueMsat) : options.longs === Number ? new $util.LongBits(message.valueMsat.low >>> 0, message.valueMsat.high >>> 0).toNumber() : message.valueMsat;
            let keys2;
            if (message.features && (keys2 = Object.keys(message.features)).length) {
                object.features = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.features[keys2[j]] = $root.lnrpc.Feature.toObject(message.features[keys2[j]], options);
            }
            if (message.isKeysend != null && message.hasOwnProperty("isKeysend"))
                object.isKeysend = message.isKeysend;
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            if (message.isAmp != null && message.hasOwnProperty("isAmp"))
                object.isAmp = message.isAmp;
            if (message.ampInvoiceState && (keys2 = Object.keys(message.ampInvoiceState)).length) {
                object.ampInvoiceState = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.ampInvoiceState[keys2[j]] = $root.lnrpc.AMPInvoiceState.toObject(message.ampInvoiceState[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this Invoice to JSON.
         * @function toJSON
         * @memberof lnrpc.Invoice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Invoice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * InvoiceState enum.
         * @name lnrpc.Invoice.InvoiceState
         * @enum {number}
         * @property {number} OPEN=0 OPEN value
         * @property {number} SETTLED=1 SETTLED value
         * @property {number} CANCELED=2 CANCELED value
         * @property {number} ACCEPTED=3 ACCEPTED value
         */
        Invoice.InvoiceState = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "OPEN"] = 0;
            values[valuesById[1] = "SETTLED"] = 1;
            values[valuesById[2] = "CANCELED"] = 2;
            values[valuesById[3] = "ACCEPTED"] = 3;
            return values;
        })();

        return Invoice;
    })();

    /**
     * InvoiceHTLCState enum.
     * @name lnrpc.InvoiceHTLCState
     * @enum {number}
     * @property {number} ACCEPTED=0 ACCEPTED value
     * @property {number} SETTLED=1 SETTLED value
     * @property {number} CANCELED=2 CANCELED value
     */
    lnrpc.InvoiceHTLCState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ACCEPTED"] = 0;
        values[valuesById[1] = "SETTLED"] = 1;
        values[valuesById[2] = "CANCELED"] = 2;
        return values;
    })();

    lnrpc.InvoiceHTLC = (function() {

        /**
         * Properties of an InvoiceHTLC.
         * @memberof lnrpc
         * @interface IInvoiceHTLC
         * @property {Long|null} [chanId] InvoiceHTLC chanId
         * @property {Long|null} [htlcIndex] InvoiceHTLC htlcIndex
         * @property {Long|null} [amtMsat] InvoiceHTLC amtMsat
         * @property {number|null} [acceptHeight] InvoiceHTLC acceptHeight
         * @property {Long|null} [acceptTime] InvoiceHTLC acceptTime
         * @property {Long|null} [resolveTime] InvoiceHTLC resolveTime
         * @property {number|null} [expiryHeight] InvoiceHTLC expiryHeight
         * @property {lnrpc.InvoiceHTLCState|null} [state] InvoiceHTLC state
         * @property {Object.<string,Uint8Array>|null} [customRecords] InvoiceHTLC customRecords
         * @property {Long|null} [mppTotalAmtMsat] InvoiceHTLC mppTotalAmtMsat
         * @property {lnrpc.IAMP|null} [amp] InvoiceHTLC amp
         */

        /**
         * Constructs a new InvoiceHTLC.
         * @memberof lnrpc
         * @classdesc Represents an InvoiceHTLC.
         * @implements IInvoiceHTLC
         * @constructor
         * @param {lnrpc.IInvoiceHTLC=} [properties] Properties to set
         */
        function InvoiceHTLC(properties) {
            this.customRecords = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvoiceHTLC chanId.
         * @member {Long} chanId
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * InvoiceHTLC htlcIndex.
         * @member {Long} htlcIndex
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.htlcIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * InvoiceHTLC amtMsat.
         * @member {Long} amtMsat
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.amtMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * InvoiceHTLC acceptHeight.
         * @member {number} acceptHeight
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.acceptHeight = 0;

        /**
         * InvoiceHTLC acceptTime.
         * @member {Long} acceptTime
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.acceptTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InvoiceHTLC resolveTime.
         * @member {Long} resolveTime
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.resolveTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InvoiceHTLC expiryHeight.
         * @member {number} expiryHeight
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.expiryHeight = 0;

        /**
         * InvoiceHTLC state.
         * @member {lnrpc.InvoiceHTLCState} state
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.state = 0;

        /**
         * InvoiceHTLC customRecords.
         * @member {Object.<string,Uint8Array>} customRecords
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.customRecords = $util.emptyObject;

        /**
         * InvoiceHTLC mppTotalAmtMsat.
         * @member {Long} mppTotalAmtMsat
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.mppTotalAmtMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * InvoiceHTLC amp.
         * @member {lnrpc.IAMP|null|undefined} amp
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         */
        InvoiceHTLC.prototype.amp = null;

        /**
         * Creates a new InvoiceHTLC instance using the specified properties.
         * @function create
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {lnrpc.IInvoiceHTLC=} [properties] Properties to set
         * @returns {lnrpc.InvoiceHTLC} InvoiceHTLC instance
         */
        InvoiceHTLC.create = function create(properties) {
            return new InvoiceHTLC(properties);
        };

        /**
         * Encodes the specified InvoiceHTLC message. Does not implicitly {@link lnrpc.InvoiceHTLC.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {lnrpc.IInvoiceHTLC} message InvoiceHTLC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvoiceHTLC.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chanId);
            if (message.htlcIndex != null && Object.hasOwnProperty.call(message, "htlcIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.htlcIndex);
            if (message.amtMsat != null && Object.hasOwnProperty.call(message, "amtMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.amtMsat);
            if (message.acceptHeight != null && Object.hasOwnProperty.call(message, "acceptHeight"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.acceptHeight);
            if (message.acceptTime != null && Object.hasOwnProperty.call(message, "acceptTime"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.acceptTime);
            if (message.resolveTime != null && Object.hasOwnProperty.call(message, "resolveTime"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.resolveTime);
            if (message.expiryHeight != null && Object.hasOwnProperty.call(message, "expiryHeight"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.expiryHeight);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.state);
            if (message.customRecords != null && Object.hasOwnProperty.call(message, "customRecords"))
                for (let keys = Object.keys(message.customRecords), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).fork().uint32(/* id 1, wireType 0 =*/8).uint64(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.customRecords[keys[i]]).ldelim();
            if (message.mppTotalAmtMsat != null && Object.hasOwnProperty.call(message, "mppTotalAmtMsat"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.mppTotalAmtMsat);
            if (message.amp != null && Object.hasOwnProperty.call(message, "amp"))
                $root.lnrpc.AMP.encode(message.amp, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified InvoiceHTLC message, length delimited. Does not implicitly {@link lnrpc.InvoiceHTLC.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {lnrpc.IInvoiceHTLC} message InvoiceHTLC message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvoiceHTLC.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvoiceHTLC message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.InvoiceHTLC} InvoiceHTLC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvoiceHTLC.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.InvoiceHTLC(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.htlcIndex = reader.uint64();
                        break;
                    }
                case 3: {
                        message.amtMsat = reader.uint64();
                        break;
                    }
                case 4: {
                        message.acceptHeight = reader.int32();
                        break;
                    }
                case 5: {
                        message.acceptTime = reader.int64();
                        break;
                    }
                case 6: {
                        message.resolveTime = reader.int64();
                        break;
                    }
                case 7: {
                        message.expiryHeight = reader.int32();
                        break;
                    }
                case 8: {
                        message.state = reader.int32();
                        break;
                    }
                case 9: {
                        if (message.customRecords === $util.emptyObject)
                            message.customRecords = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint64();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.customRecords[typeof key === "object" ? $util.longToHash(key) : key] = value;
                        break;
                    }
                case 10: {
                        message.mppTotalAmtMsat = reader.uint64();
                        break;
                    }
                case 11: {
                        message.amp = $root.lnrpc.AMP.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvoiceHTLC message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.InvoiceHTLC} InvoiceHTLC
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvoiceHTLC.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvoiceHTLC message.
         * @function verify
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvoiceHTLC.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.htlcIndex != null && message.hasOwnProperty("htlcIndex"))
                if (!$util.isInteger(message.htlcIndex) && !(message.htlcIndex && $util.isInteger(message.htlcIndex.low) && $util.isInteger(message.htlcIndex.high)))
                    return "htlcIndex: integer|Long expected";
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (!$util.isInteger(message.amtMsat) && !(message.amtMsat && $util.isInteger(message.amtMsat.low) && $util.isInteger(message.amtMsat.high)))
                    return "amtMsat: integer|Long expected";
            if (message.acceptHeight != null && message.hasOwnProperty("acceptHeight"))
                if (!$util.isInteger(message.acceptHeight))
                    return "acceptHeight: integer expected";
            if (message.acceptTime != null && message.hasOwnProperty("acceptTime"))
                if (!$util.isInteger(message.acceptTime) && !(message.acceptTime && $util.isInteger(message.acceptTime.low) && $util.isInteger(message.acceptTime.high)))
                    return "acceptTime: integer|Long expected";
            if (message.resolveTime != null && message.hasOwnProperty("resolveTime"))
                if (!$util.isInteger(message.resolveTime) && !(message.resolveTime && $util.isInteger(message.resolveTime.low) && $util.isInteger(message.resolveTime.high)))
                    return "resolveTime: integer|Long expected";
            if (message.expiryHeight != null && message.hasOwnProperty("expiryHeight"))
                if (!$util.isInteger(message.expiryHeight))
                    return "expiryHeight: integer expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.customRecords != null && message.hasOwnProperty("customRecords")) {
                if (!$util.isObject(message.customRecords))
                    return "customRecords: object expected";
                let key = Object.keys(message.customRecords);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key64Re.test(key[i]))
                        return "customRecords: integer|Long key{k:uint64} expected";
                    if (!(message.customRecords[key[i]] && typeof message.customRecords[key[i]].length === "number" || $util.isString(message.customRecords[key[i]])))
                        return "customRecords: buffer{k:uint64} expected";
                }
            }
            if (message.mppTotalAmtMsat != null && message.hasOwnProperty("mppTotalAmtMsat"))
                if (!$util.isInteger(message.mppTotalAmtMsat) && !(message.mppTotalAmtMsat && $util.isInteger(message.mppTotalAmtMsat.low) && $util.isInteger(message.mppTotalAmtMsat.high)))
                    return "mppTotalAmtMsat: integer|Long expected";
            if (message.amp != null && message.hasOwnProperty("amp")) {
                let error = $root.lnrpc.AMP.verify(message.amp);
                if (error)
                    return "amp." + error;
            }
            return null;
        };

        /**
         * Creates an InvoiceHTLC message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.InvoiceHTLC} InvoiceHTLC
         */
        InvoiceHTLC.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.InvoiceHTLC)
                return object;
            let message = new $root.lnrpc.InvoiceHTLC();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.htlcIndex != null)
                if ($util.Long)
                    (message.htlcIndex = $util.Long.fromValue(object.htlcIndex)).unsigned = true;
                else if (typeof object.htlcIndex === "string")
                    message.htlcIndex = parseInt(object.htlcIndex, 10);
                else if (typeof object.htlcIndex === "number")
                    message.htlcIndex = object.htlcIndex;
                else if (typeof object.htlcIndex === "object")
                    message.htlcIndex = new $util.LongBits(object.htlcIndex.low >>> 0, object.htlcIndex.high >>> 0).toNumber(true);
            if (object.amtMsat != null)
                if ($util.Long)
                    (message.amtMsat = $util.Long.fromValue(object.amtMsat)).unsigned = true;
                else if (typeof object.amtMsat === "string")
                    message.amtMsat = parseInt(object.amtMsat, 10);
                else if (typeof object.amtMsat === "number")
                    message.amtMsat = object.amtMsat;
                else if (typeof object.amtMsat === "object")
                    message.amtMsat = new $util.LongBits(object.amtMsat.low >>> 0, object.amtMsat.high >>> 0).toNumber(true);
            if (object.acceptHeight != null)
                message.acceptHeight = object.acceptHeight | 0;
            if (object.acceptTime != null)
                if ($util.Long)
                    (message.acceptTime = $util.Long.fromValue(object.acceptTime)).unsigned = false;
                else if (typeof object.acceptTime === "string")
                    message.acceptTime = parseInt(object.acceptTime, 10);
                else if (typeof object.acceptTime === "number")
                    message.acceptTime = object.acceptTime;
                else if (typeof object.acceptTime === "object")
                    message.acceptTime = new $util.LongBits(object.acceptTime.low >>> 0, object.acceptTime.high >>> 0).toNumber();
            if (object.resolveTime != null)
                if ($util.Long)
                    (message.resolveTime = $util.Long.fromValue(object.resolveTime)).unsigned = false;
                else if (typeof object.resolveTime === "string")
                    message.resolveTime = parseInt(object.resolveTime, 10);
                else if (typeof object.resolveTime === "number")
                    message.resolveTime = object.resolveTime;
                else if (typeof object.resolveTime === "object")
                    message.resolveTime = new $util.LongBits(object.resolveTime.low >>> 0, object.resolveTime.high >>> 0).toNumber();
            if (object.expiryHeight != null)
                message.expiryHeight = object.expiryHeight | 0;
            switch (object.state) {
            case "ACCEPTED":
            case 0:
                message.state = 0;
                break;
            case "SETTLED":
            case 1:
                message.state = 1;
                break;
            case "CANCELED":
            case 2:
                message.state = 2;
                break;
            }
            if (object.customRecords) {
                if (typeof object.customRecords !== "object")
                    throw TypeError(".lnrpc.InvoiceHTLC.customRecords: object expected");
                message.customRecords = {};
                for (let keys = Object.keys(object.customRecords), i = 0; i < keys.length; ++i)
                    if (typeof object.customRecords[keys[i]] === "string")
                        $util.base64.decode(object.customRecords[keys[i]], message.customRecords[keys[i]] = $util.newBuffer($util.base64.length(object.customRecords[keys[i]])), 0);
                    else if (object.customRecords[keys[i]].length)
                        message.customRecords[keys[i]] = object.customRecords[keys[i]];
            }
            if (object.mppTotalAmtMsat != null)
                if ($util.Long)
                    (message.mppTotalAmtMsat = $util.Long.fromValue(object.mppTotalAmtMsat)).unsigned = true;
                else if (typeof object.mppTotalAmtMsat === "string")
                    message.mppTotalAmtMsat = parseInt(object.mppTotalAmtMsat, 10);
                else if (typeof object.mppTotalAmtMsat === "number")
                    message.mppTotalAmtMsat = object.mppTotalAmtMsat;
                else if (typeof object.mppTotalAmtMsat === "object")
                    message.mppTotalAmtMsat = new $util.LongBits(object.mppTotalAmtMsat.low >>> 0, object.mppTotalAmtMsat.high >>> 0).toNumber(true);
            if (object.amp != null) {
                if (typeof object.amp !== "object")
                    throw TypeError(".lnrpc.InvoiceHTLC.amp: object expected");
                message.amp = $root.lnrpc.AMP.fromObject(object.amp);
            }
            return message;
        };

        /**
         * Creates a plain object from an InvoiceHTLC message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.InvoiceHTLC
         * @static
         * @param {lnrpc.InvoiceHTLC} message InvoiceHTLC
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvoiceHTLC.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.customRecords = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.htlcIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.htlcIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.amtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtMsat = options.longs === String ? "0" : 0;
                object.acceptHeight = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.acceptTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.acceptTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.resolveTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.resolveTime = options.longs === String ? "0" : 0;
                object.expiryHeight = 0;
                object.state = options.enums === String ? "ACCEPTED" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.mppTotalAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.mppTotalAmtMsat = options.longs === String ? "0" : 0;
                object.amp = null;
            }
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.htlcIndex != null && message.hasOwnProperty("htlcIndex"))
                if (typeof message.htlcIndex === "number")
                    object.htlcIndex = options.longs === String ? String(message.htlcIndex) : message.htlcIndex;
                else
                    object.htlcIndex = options.longs === String ? $util.Long.prototype.toString.call(message.htlcIndex) : options.longs === Number ? new $util.LongBits(message.htlcIndex.low >>> 0, message.htlcIndex.high >>> 0).toNumber(true) : message.htlcIndex;
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (typeof message.amtMsat === "number")
                    object.amtMsat = options.longs === String ? String(message.amtMsat) : message.amtMsat;
                else
                    object.amtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtMsat) : options.longs === Number ? new $util.LongBits(message.amtMsat.low >>> 0, message.amtMsat.high >>> 0).toNumber(true) : message.amtMsat;
            if (message.acceptHeight != null && message.hasOwnProperty("acceptHeight"))
                object.acceptHeight = message.acceptHeight;
            if (message.acceptTime != null && message.hasOwnProperty("acceptTime"))
                if (typeof message.acceptTime === "number")
                    object.acceptTime = options.longs === String ? String(message.acceptTime) : message.acceptTime;
                else
                    object.acceptTime = options.longs === String ? $util.Long.prototype.toString.call(message.acceptTime) : options.longs === Number ? new $util.LongBits(message.acceptTime.low >>> 0, message.acceptTime.high >>> 0).toNumber() : message.acceptTime;
            if (message.resolveTime != null && message.hasOwnProperty("resolveTime"))
                if (typeof message.resolveTime === "number")
                    object.resolveTime = options.longs === String ? String(message.resolveTime) : message.resolveTime;
                else
                    object.resolveTime = options.longs === String ? $util.Long.prototype.toString.call(message.resolveTime) : options.longs === Number ? new $util.LongBits(message.resolveTime.low >>> 0, message.resolveTime.high >>> 0).toNumber() : message.resolveTime;
            if (message.expiryHeight != null && message.hasOwnProperty("expiryHeight"))
                object.expiryHeight = message.expiryHeight;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.lnrpc.InvoiceHTLCState[message.state] : message.state;
            let keys2;
            if (message.customRecords && (keys2 = Object.keys(message.customRecords)).length) {
                object.customRecords = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.customRecords[keys2[j]] = options.bytes === String ? $util.base64.encode(message.customRecords[keys2[j]], 0, message.customRecords[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.customRecords[keys2[j]]) : message.customRecords[keys2[j]];
            }
            if (message.mppTotalAmtMsat != null && message.hasOwnProperty("mppTotalAmtMsat"))
                if (typeof message.mppTotalAmtMsat === "number")
                    object.mppTotalAmtMsat = options.longs === String ? String(message.mppTotalAmtMsat) : message.mppTotalAmtMsat;
                else
                    object.mppTotalAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.mppTotalAmtMsat) : options.longs === Number ? new $util.LongBits(message.mppTotalAmtMsat.low >>> 0, message.mppTotalAmtMsat.high >>> 0).toNumber(true) : message.mppTotalAmtMsat;
            if (message.amp != null && message.hasOwnProperty("amp"))
                object.amp = $root.lnrpc.AMP.toObject(message.amp, options);
            return object;
        };

        /**
         * Converts this InvoiceHTLC to JSON.
         * @function toJSON
         * @memberof lnrpc.InvoiceHTLC
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvoiceHTLC.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InvoiceHTLC;
    })();

    lnrpc.AMP = (function() {

        /**
         * Properties of a AMP.
         * @memberof lnrpc
         * @interface IAMP
         * @property {Uint8Array|null} [rootShare] AMP rootShare
         * @property {Uint8Array|null} [setId] AMP setId
         * @property {number|null} [childIndex] AMP childIndex
         * @property {Uint8Array|null} [hash] AMP hash
         * @property {Uint8Array|null} [preimage] AMP preimage
         */

        /**
         * Constructs a new AMP.
         * @memberof lnrpc
         * @classdesc Represents a AMP.
         * @implements IAMP
         * @constructor
         * @param {lnrpc.IAMP=} [properties] Properties to set
         */
        function AMP(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AMP rootShare.
         * @member {Uint8Array} rootShare
         * @memberof lnrpc.AMP
         * @instance
         */
        AMP.prototype.rootShare = $util.newBuffer([]);

        /**
         * AMP setId.
         * @member {Uint8Array} setId
         * @memberof lnrpc.AMP
         * @instance
         */
        AMP.prototype.setId = $util.newBuffer([]);

        /**
         * AMP childIndex.
         * @member {number} childIndex
         * @memberof lnrpc.AMP
         * @instance
         */
        AMP.prototype.childIndex = 0;

        /**
         * AMP hash.
         * @member {Uint8Array} hash
         * @memberof lnrpc.AMP
         * @instance
         */
        AMP.prototype.hash = $util.newBuffer([]);

        /**
         * AMP preimage.
         * @member {Uint8Array} preimage
         * @memberof lnrpc.AMP
         * @instance
         */
        AMP.prototype.preimage = $util.newBuffer([]);

        /**
         * Creates a new AMP instance using the specified properties.
         * @function create
         * @memberof lnrpc.AMP
         * @static
         * @param {lnrpc.IAMP=} [properties] Properties to set
         * @returns {lnrpc.AMP} AMP instance
         */
        AMP.create = function create(properties) {
            return new AMP(properties);
        };

        /**
         * Encodes the specified AMP message. Does not implicitly {@link lnrpc.AMP.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AMP
         * @static
         * @param {lnrpc.IAMP} message AMP message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AMP.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rootShare != null && Object.hasOwnProperty.call(message, "rootShare"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rootShare);
            if (message.setId != null && Object.hasOwnProperty.call(message, "setId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.setId);
            if (message.childIndex != null && Object.hasOwnProperty.call(message, "childIndex"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.childIndex);
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.hash);
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.preimage);
            return writer;
        };

        /**
         * Encodes the specified AMP message, length delimited. Does not implicitly {@link lnrpc.AMP.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AMP
         * @static
         * @param {lnrpc.IAMP} message AMP message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AMP.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a AMP message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AMP
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AMP} AMP
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AMP.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AMP();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rootShare = reader.bytes();
                        break;
                    }
                case 2: {
                        message.setId = reader.bytes();
                        break;
                    }
                case 3: {
                        message.childIndex = reader.uint32();
                        break;
                    }
                case 4: {
                        message.hash = reader.bytes();
                        break;
                    }
                case 5: {
                        message.preimage = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a AMP message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AMP
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AMP} AMP
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AMP.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a AMP message.
         * @function verify
         * @memberof lnrpc.AMP
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AMP.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rootShare != null && message.hasOwnProperty("rootShare"))
                if (!(message.rootShare && typeof message.rootShare.length === "number" || $util.isString(message.rootShare)))
                    return "rootShare: buffer expected";
            if (message.setId != null && message.hasOwnProperty("setId"))
                if (!(message.setId && typeof message.setId.length === "number" || $util.isString(message.setId)))
                    return "setId: buffer expected";
            if (message.childIndex != null && message.hasOwnProperty("childIndex"))
                if (!$util.isInteger(message.childIndex))
                    return "childIndex: integer expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!(message.hash && typeof message.hash.length === "number" || $util.isString(message.hash)))
                    return "hash: buffer expected";
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            return null;
        };

        /**
         * Creates a AMP message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AMP
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AMP} AMP
         */
        AMP.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AMP)
                return object;
            let message = new $root.lnrpc.AMP();
            if (object.rootShare != null)
                if (typeof object.rootShare === "string")
                    $util.base64.decode(object.rootShare, message.rootShare = $util.newBuffer($util.base64.length(object.rootShare)), 0);
                else if (object.rootShare.length)
                    message.rootShare = object.rootShare;
            if (object.setId != null)
                if (typeof object.setId === "string")
                    $util.base64.decode(object.setId, message.setId = $util.newBuffer($util.base64.length(object.setId)), 0);
                else if (object.setId.length)
                    message.setId = object.setId;
            if (object.childIndex != null)
                message.childIndex = object.childIndex >>> 0;
            if (object.hash != null)
                if (typeof object.hash === "string")
                    $util.base64.decode(object.hash, message.hash = $util.newBuffer($util.base64.length(object.hash)), 0);
                else if (object.hash.length)
                    message.hash = object.hash;
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            return message;
        };

        /**
         * Creates a plain object from a AMP message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AMP
         * @static
         * @param {lnrpc.AMP} message AMP
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AMP.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.rootShare = "";
                else {
                    object.rootShare = [];
                    if (options.bytes !== Array)
                        object.rootShare = $util.newBuffer(object.rootShare);
                }
                if (options.bytes === String)
                    object.setId = "";
                else {
                    object.setId = [];
                    if (options.bytes !== Array)
                        object.setId = $util.newBuffer(object.setId);
                }
                object.childIndex = 0;
                if (options.bytes === String)
                    object.hash = "";
                else {
                    object.hash = [];
                    if (options.bytes !== Array)
                        object.hash = $util.newBuffer(object.hash);
                }
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
            }
            if (message.rootShare != null && message.hasOwnProperty("rootShare"))
                object.rootShare = options.bytes === String ? $util.base64.encode(message.rootShare, 0, message.rootShare.length) : options.bytes === Array ? Array.prototype.slice.call(message.rootShare) : message.rootShare;
            if (message.setId != null && message.hasOwnProperty("setId"))
                object.setId = options.bytes === String ? $util.base64.encode(message.setId, 0, message.setId.length) : options.bytes === Array ? Array.prototype.slice.call(message.setId) : message.setId;
            if (message.childIndex != null && message.hasOwnProperty("childIndex"))
                object.childIndex = message.childIndex;
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = options.bytes === String ? $util.base64.encode(message.hash, 0, message.hash.length) : options.bytes === Array ? Array.prototype.slice.call(message.hash) : message.hash;
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            return object;
        };

        /**
         * Converts this AMP to JSON.
         * @function toJSON
         * @memberof lnrpc.AMP
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AMP.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AMP;
    })();

    lnrpc.AddInvoiceResponse = (function() {

        /**
         * Properties of an AddInvoiceResponse.
         * @memberof lnrpc
         * @interface IAddInvoiceResponse
         * @property {Uint8Array|null} [rHash] AddInvoiceResponse rHash
         * @property {string|null} [paymentRequest] AddInvoiceResponse paymentRequest
         * @property {Long|null} [addIndex] AddInvoiceResponse addIndex
         * @property {Uint8Array|null} [paymentAddr] AddInvoiceResponse paymentAddr
         */

        /**
         * Constructs a new AddInvoiceResponse.
         * @memberof lnrpc
         * @classdesc Represents an AddInvoiceResponse.
         * @implements IAddInvoiceResponse
         * @constructor
         * @param {lnrpc.IAddInvoiceResponse=} [properties] Properties to set
         */
        function AddInvoiceResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddInvoiceResponse rHash.
         * @member {Uint8Array} rHash
         * @memberof lnrpc.AddInvoiceResponse
         * @instance
         */
        AddInvoiceResponse.prototype.rHash = $util.newBuffer([]);

        /**
         * AddInvoiceResponse paymentRequest.
         * @member {string} paymentRequest
         * @memberof lnrpc.AddInvoiceResponse
         * @instance
         */
        AddInvoiceResponse.prototype.paymentRequest = "";

        /**
         * AddInvoiceResponse addIndex.
         * @member {Long} addIndex
         * @memberof lnrpc.AddInvoiceResponse
         * @instance
         */
        AddInvoiceResponse.prototype.addIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AddInvoiceResponse paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof lnrpc.AddInvoiceResponse
         * @instance
         */
        AddInvoiceResponse.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * Creates a new AddInvoiceResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {lnrpc.IAddInvoiceResponse=} [properties] Properties to set
         * @returns {lnrpc.AddInvoiceResponse} AddInvoiceResponse instance
         */
        AddInvoiceResponse.create = function create(properties) {
            return new AddInvoiceResponse(properties);
        };

        /**
         * Encodes the specified AddInvoiceResponse message. Does not implicitly {@link lnrpc.AddInvoiceResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {lnrpc.IAddInvoiceResponse} message AddInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddInvoiceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rHash != null && Object.hasOwnProperty.call(message, "rHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rHash);
            if (message.paymentRequest != null && Object.hasOwnProperty.call(message, "paymentRequest"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentRequest);
            if (message.addIndex != null && Object.hasOwnProperty.call(message, "addIndex"))
                writer.uint32(/* id 16, wireType 0 =*/128).uint64(message.addIndex);
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 17, wireType 2 =*/138).bytes(message.paymentAddr);
            return writer;
        };

        /**
         * Encodes the specified AddInvoiceResponse message, length delimited. Does not implicitly {@link lnrpc.AddInvoiceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {lnrpc.IAddInvoiceResponse} message AddInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddInvoiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddInvoiceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AddInvoiceResponse} AddInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddInvoiceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AddInvoiceResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.paymentRequest = reader.string();
                        break;
                    }
                case 16: {
                        message.addIndex = reader.uint64();
                        break;
                    }
                case 17: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddInvoiceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AddInvoiceResponse} AddInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddInvoiceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddInvoiceResponse message.
         * @function verify
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddInvoiceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                if (!(message.rHash && typeof message.rHash.length === "number" || $util.isString(message.rHash)))
                    return "rHash: buffer expected";
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                if (!$util.isString(message.paymentRequest))
                    return "paymentRequest: string expected";
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (!$util.isInteger(message.addIndex) && !(message.addIndex && $util.isInteger(message.addIndex.low) && $util.isInteger(message.addIndex.high)))
                    return "addIndex: integer|Long expected";
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            return null;
        };

        /**
         * Creates an AddInvoiceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AddInvoiceResponse} AddInvoiceResponse
         */
        AddInvoiceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AddInvoiceResponse)
                return object;
            let message = new $root.lnrpc.AddInvoiceResponse();
            if (object.rHash != null)
                if (typeof object.rHash === "string")
                    $util.base64.decode(object.rHash, message.rHash = $util.newBuffer($util.base64.length(object.rHash)), 0);
                else if (object.rHash.length)
                    message.rHash = object.rHash;
            if (object.paymentRequest != null)
                message.paymentRequest = String(object.paymentRequest);
            if (object.addIndex != null)
                if ($util.Long)
                    (message.addIndex = $util.Long.fromValue(object.addIndex)).unsigned = true;
                else if (typeof object.addIndex === "string")
                    message.addIndex = parseInt(object.addIndex, 10);
                else if (typeof object.addIndex === "number")
                    message.addIndex = object.addIndex;
                else if (typeof object.addIndex === "object")
                    message.addIndex = new $util.LongBits(object.addIndex.low >>> 0, object.addIndex.high >>> 0).toNumber(true);
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            return message;
        };

        /**
         * Creates a plain object from an AddInvoiceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AddInvoiceResponse
         * @static
         * @param {lnrpc.AddInvoiceResponse} message AddInvoiceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddInvoiceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.rHash = "";
                else {
                    object.rHash = [];
                    if (options.bytes !== Array)
                        object.rHash = $util.newBuffer(object.rHash);
                }
                object.paymentRequest = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.addIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.addIndex = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
            }
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                object.rHash = options.bytes === String ? $util.base64.encode(message.rHash, 0, message.rHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.rHash) : message.rHash;
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                object.paymentRequest = message.paymentRequest;
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (typeof message.addIndex === "number")
                    object.addIndex = options.longs === String ? String(message.addIndex) : message.addIndex;
                else
                    object.addIndex = options.longs === String ? $util.Long.prototype.toString.call(message.addIndex) : options.longs === Number ? new $util.LongBits(message.addIndex.low >>> 0, message.addIndex.high >>> 0).toNumber(true) : message.addIndex;
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            return object;
        };

        /**
         * Converts this AddInvoiceResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.AddInvoiceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddInvoiceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddInvoiceResponse;
    })();

    lnrpc.PaymentHash = (function() {

        /**
         * Properties of a PaymentHash.
         * @memberof lnrpc
         * @interface IPaymentHash
         * @property {string|null} [rHashStr] PaymentHash rHashStr
         * @property {Uint8Array|null} [rHash] PaymentHash rHash
         */

        /**
         * Constructs a new PaymentHash.
         * @memberof lnrpc
         * @classdesc Represents a PaymentHash.
         * @implements IPaymentHash
         * @constructor
         * @param {lnrpc.IPaymentHash=} [properties] Properties to set
         */
        function PaymentHash(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentHash rHashStr.
         * @member {string} rHashStr
         * @memberof lnrpc.PaymentHash
         * @instance
         */
        PaymentHash.prototype.rHashStr = "";

        /**
         * PaymentHash rHash.
         * @member {Uint8Array} rHash
         * @memberof lnrpc.PaymentHash
         * @instance
         */
        PaymentHash.prototype.rHash = $util.newBuffer([]);

        /**
         * Creates a new PaymentHash instance using the specified properties.
         * @function create
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {lnrpc.IPaymentHash=} [properties] Properties to set
         * @returns {lnrpc.PaymentHash} PaymentHash instance
         */
        PaymentHash.create = function create(properties) {
            return new PaymentHash(properties);
        };

        /**
         * Encodes the specified PaymentHash message. Does not implicitly {@link lnrpc.PaymentHash.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {lnrpc.IPaymentHash} message PaymentHash message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentHash.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rHashStr != null && Object.hasOwnProperty.call(message, "rHashStr"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.rHashStr);
            if (message.rHash != null && Object.hasOwnProperty.call(message, "rHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rHash);
            return writer;
        };

        /**
         * Encodes the specified PaymentHash message, length delimited. Does not implicitly {@link lnrpc.PaymentHash.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {lnrpc.IPaymentHash} message PaymentHash message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentHash.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaymentHash message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PaymentHash} PaymentHash
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentHash.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PaymentHash();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rHashStr = reader.string();
                        break;
                    }
                case 2: {
                        message.rHash = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PaymentHash message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PaymentHash} PaymentHash
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentHash.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PaymentHash message.
         * @function verify
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PaymentHash.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rHashStr != null && message.hasOwnProperty("rHashStr"))
                if (!$util.isString(message.rHashStr))
                    return "rHashStr: string expected";
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                if (!(message.rHash && typeof message.rHash.length === "number" || $util.isString(message.rHash)))
                    return "rHash: buffer expected";
            return null;
        };

        /**
         * Creates a PaymentHash message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PaymentHash} PaymentHash
         */
        PaymentHash.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PaymentHash)
                return object;
            let message = new $root.lnrpc.PaymentHash();
            if (object.rHashStr != null)
                message.rHashStr = String(object.rHashStr);
            if (object.rHash != null)
                if (typeof object.rHash === "string")
                    $util.base64.decode(object.rHash, message.rHash = $util.newBuffer($util.base64.length(object.rHash)), 0);
                else if (object.rHash.length)
                    message.rHash = object.rHash;
            return message;
        };

        /**
         * Creates a plain object from a PaymentHash message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PaymentHash
         * @static
         * @param {lnrpc.PaymentHash} message PaymentHash
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentHash.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.rHashStr = "";
                if (options.bytes === String)
                    object.rHash = "";
                else {
                    object.rHash = [];
                    if (options.bytes !== Array)
                        object.rHash = $util.newBuffer(object.rHash);
                }
            }
            if (message.rHashStr != null && message.hasOwnProperty("rHashStr"))
                object.rHashStr = message.rHashStr;
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                object.rHash = options.bytes === String ? $util.base64.encode(message.rHash, 0, message.rHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.rHash) : message.rHash;
            return object;
        };

        /**
         * Converts this PaymentHash to JSON.
         * @function toJSON
         * @memberof lnrpc.PaymentHash
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentHash.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PaymentHash;
    })();

    lnrpc.ListInvoiceRequest = (function() {

        /**
         * Properties of a ListInvoiceRequest.
         * @memberof lnrpc
         * @interface IListInvoiceRequest
         * @property {boolean|null} [pendingOnly] ListInvoiceRequest pendingOnly
         * @property {Long|null} [indexOffset] ListInvoiceRequest indexOffset
         * @property {Long|null} [numMaxInvoices] ListInvoiceRequest numMaxInvoices
         * @property {boolean|null} [reversed] ListInvoiceRequest reversed
         */

        /**
         * Constructs a new ListInvoiceRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListInvoiceRequest.
         * @implements IListInvoiceRequest
         * @constructor
         * @param {lnrpc.IListInvoiceRequest=} [properties] Properties to set
         */
        function ListInvoiceRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListInvoiceRequest pendingOnly.
         * @member {boolean} pendingOnly
         * @memberof lnrpc.ListInvoiceRequest
         * @instance
         */
        ListInvoiceRequest.prototype.pendingOnly = false;

        /**
         * ListInvoiceRequest indexOffset.
         * @member {Long} indexOffset
         * @memberof lnrpc.ListInvoiceRequest
         * @instance
         */
        ListInvoiceRequest.prototype.indexOffset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListInvoiceRequest numMaxInvoices.
         * @member {Long} numMaxInvoices
         * @memberof lnrpc.ListInvoiceRequest
         * @instance
         */
        ListInvoiceRequest.prototype.numMaxInvoices = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListInvoiceRequest reversed.
         * @member {boolean} reversed
         * @memberof lnrpc.ListInvoiceRequest
         * @instance
         */
        ListInvoiceRequest.prototype.reversed = false;

        /**
         * Creates a new ListInvoiceRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {lnrpc.IListInvoiceRequest=} [properties] Properties to set
         * @returns {lnrpc.ListInvoiceRequest} ListInvoiceRequest instance
         */
        ListInvoiceRequest.create = function create(properties) {
            return new ListInvoiceRequest(properties);
        };

        /**
         * Encodes the specified ListInvoiceRequest message. Does not implicitly {@link lnrpc.ListInvoiceRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {lnrpc.IListInvoiceRequest} message ListInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListInvoiceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pendingOnly != null && Object.hasOwnProperty.call(message, "pendingOnly"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.pendingOnly);
            if (message.indexOffset != null && Object.hasOwnProperty.call(message, "indexOffset"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.indexOffset);
            if (message.numMaxInvoices != null && Object.hasOwnProperty.call(message, "numMaxInvoices"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.numMaxInvoices);
            if (message.reversed != null && Object.hasOwnProperty.call(message, "reversed"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.reversed);
            return writer;
        };

        /**
         * Encodes the specified ListInvoiceRequest message, length delimited. Does not implicitly {@link lnrpc.ListInvoiceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {lnrpc.IListInvoiceRequest} message ListInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListInvoiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListInvoiceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListInvoiceRequest} ListInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListInvoiceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListInvoiceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pendingOnly = reader.bool();
                        break;
                    }
                case 4: {
                        message.indexOffset = reader.uint64();
                        break;
                    }
                case 5: {
                        message.numMaxInvoices = reader.uint64();
                        break;
                    }
                case 6: {
                        message.reversed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListInvoiceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListInvoiceRequest} ListInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListInvoiceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListInvoiceRequest message.
         * @function verify
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListInvoiceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pendingOnly != null && message.hasOwnProperty("pendingOnly"))
                if (typeof message.pendingOnly !== "boolean")
                    return "pendingOnly: boolean expected";
            if (message.indexOffset != null && message.hasOwnProperty("indexOffset"))
                if (!$util.isInteger(message.indexOffset) && !(message.indexOffset && $util.isInteger(message.indexOffset.low) && $util.isInteger(message.indexOffset.high)))
                    return "indexOffset: integer|Long expected";
            if (message.numMaxInvoices != null && message.hasOwnProperty("numMaxInvoices"))
                if (!$util.isInteger(message.numMaxInvoices) && !(message.numMaxInvoices && $util.isInteger(message.numMaxInvoices.low) && $util.isInteger(message.numMaxInvoices.high)))
                    return "numMaxInvoices: integer|Long expected";
            if (message.reversed != null && message.hasOwnProperty("reversed"))
                if (typeof message.reversed !== "boolean")
                    return "reversed: boolean expected";
            return null;
        };

        /**
         * Creates a ListInvoiceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListInvoiceRequest} ListInvoiceRequest
         */
        ListInvoiceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListInvoiceRequest)
                return object;
            let message = new $root.lnrpc.ListInvoiceRequest();
            if (object.pendingOnly != null)
                message.pendingOnly = Boolean(object.pendingOnly);
            if (object.indexOffset != null)
                if ($util.Long)
                    (message.indexOffset = $util.Long.fromValue(object.indexOffset)).unsigned = true;
                else if (typeof object.indexOffset === "string")
                    message.indexOffset = parseInt(object.indexOffset, 10);
                else if (typeof object.indexOffset === "number")
                    message.indexOffset = object.indexOffset;
                else if (typeof object.indexOffset === "object")
                    message.indexOffset = new $util.LongBits(object.indexOffset.low >>> 0, object.indexOffset.high >>> 0).toNumber(true);
            if (object.numMaxInvoices != null)
                if ($util.Long)
                    (message.numMaxInvoices = $util.Long.fromValue(object.numMaxInvoices)).unsigned = true;
                else if (typeof object.numMaxInvoices === "string")
                    message.numMaxInvoices = parseInt(object.numMaxInvoices, 10);
                else if (typeof object.numMaxInvoices === "number")
                    message.numMaxInvoices = object.numMaxInvoices;
                else if (typeof object.numMaxInvoices === "object")
                    message.numMaxInvoices = new $util.LongBits(object.numMaxInvoices.low >>> 0, object.numMaxInvoices.high >>> 0).toNumber(true);
            if (object.reversed != null)
                message.reversed = Boolean(object.reversed);
            return message;
        };

        /**
         * Creates a plain object from a ListInvoiceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListInvoiceRequest
         * @static
         * @param {lnrpc.ListInvoiceRequest} message ListInvoiceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListInvoiceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.pendingOnly = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.indexOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.indexOffset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.numMaxInvoices = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.numMaxInvoices = options.longs === String ? "0" : 0;
                object.reversed = false;
            }
            if (message.pendingOnly != null && message.hasOwnProperty("pendingOnly"))
                object.pendingOnly = message.pendingOnly;
            if (message.indexOffset != null && message.hasOwnProperty("indexOffset"))
                if (typeof message.indexOffset === "number")
                    object.indexOffset = options.longs === String ? String(message.indexOffset) : message.indexOffset;
                else
                    object.indexOffset = options.longs === String ? $util.Long.prototype.toString.call(message.indexOffset) : options.longs === Number ? new $util.LongBits(message.indexOffset.low >>> 0, message.indexOffset.high >>> 0).toNumber(true) : message.indexOffset;
            if (message.numMaxInvoices != null && message.hasOwnProperty("numMaxInvoices"))
                if (typeof message.numMaxInvoices === "number")
                    object.numMaxInvoices = options.longs === String ? String(message.numMaxInvoices) : message.numMaxInvoices;
                else
                    object.numMaxInvoices = options.longs === String ? $util.Long.prototype.toString.call(message.numMaxInvoices) : options.longs === Number ? new $util.LongBits(message.numMaxInvoices.low >>> 0, message.numMaxInvoices.high >>> 0).toNumber(true) : message.numMaxInvoices;
            if (message.reversed != null && message.hasOwnProperty("reversed"))
                object.reversed = message.reversed;
            return object;
        };

        /**
         * Converts this ListInvoiceRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListInvoiceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListInvoiceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListInvoiceRequest;
    })();

    lnrpc.ListInvoiceResponse = (function() {

        /**
         * Properties of a ListInvoiceResponse.
         * @memberof lnrpc
         * @interface IListInvoiceResponse
         * @property {Array.<lnrpc.IInvoice>|null} [invoices] ListInvoiceResponse invoices
         * @property {Long|null} [lastIndexOffset] ListInvoiceResponse lastIndexOffset
         * @property {Long|null} [firstIndexOffset] ListInvoiceResponse firstIndexOffset
         */

        /**
         * Constructs a new ListInvoiceResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListInvoiceResponse.
         * @implements IListInvoiceResponse
         * @constructor
         * @param {lnrpc.IListInvoiceResponse=} [properties] Properties to set
         */
        function ListInvoiceResponse(properties) {
            this.invoices = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListInvoiceResponse invoices.
         * @member {Array.<lnrpc.IInvoice>} invoices
         * @memberof lnrpc.ListInvoiceResponse
         * @instance
         */
        ListInvoiceResponse.prototype.invoices = $util.emptyArray;

        /**
         * ListInvoiceResponse lastIndexOffset.
         * @member {Long} lastIndexOffset
         * @memberof lnrpc.ListInvoiceResponse
         * @instance
         */
        ListInvoiceResponse.prototype.lastIndexOffset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListInvoiceResponse firstIndexOffset.
         * @member {Long} firstIndexOffset
         * @memberof lnrpc.ListInvoiceResponse
         * @instance
         */
        ListInvoiceResponse.prototype.firstIndexOffset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ListInvoiceResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {lnrpc.IListInvoiceResponse=} [properties] Properties to set
         * @returns {lnrpc.ListInvoiceResponse} ListInvoiceResponse instance
         */
        ListInvoiceResponse.create = function create(properties) {
            return new ListInvoiceResponse(properties);
        };

        /**
         * Encodes the specified ListInvoiceResponse message. Does not implicitly {@link lnrpc.ListInvoiceResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {lnrpc.IListInvoiceResponse} message ListInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListInvoiceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invoices != null && message.invoices.length)
                for (let i = 0; i < message.invoices.length; ++i)
                    $root.lnrpc.Invoice.encode(message.invoices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lastIndexOffset != null && Object.hasOwnProperty.call(message, "lastIndexOffset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.lastIndexOffset);
            if (message.firstIndexOffset != null && Object.hasOwnProperty.call(message, "firstIndexOffset"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.firstIndexOffset);
            return writer;
        };

        /**
         * Encodes the specified ListInvoiceResponse message, length delimited. Does not implicitly {@link lnrpc.ListInvoiceResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {lnrpc.IListInvoiceResponse} message ListInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListInvoiceResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListInvoiceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListInvoiceResponse} ListInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListInvoiceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListInvoiceResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.invoices && message.invoices.length))
                            message.invoices = [];
                        message.invoices.push($root.lnrpc.Invoice.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.lastIndexOffset = reader.uint64();
                        break;
                    }
                case 3: {
                        message.firstIndexOffset = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListInvoiceResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListInvoiceResponse} ListInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListInvoiceResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListInvoiceResponse message.
         * @function verify
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListInvoiceResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.invoices != null && message.hasOwnProperty("invoices")) {
                if (!Array.isArray(message.invoices))
                    return "invoices: array expected";
                for (let i = 0; i < message.invoices.length; ++i) {
                    let error = $root.lnrpc.Invoice.verify(message.invoices[i]);
                    if (error)
                        return "invoices." + error;
                }
            }
            if (message.lastIndexOffset != null && message.hasOwnProperty("lastIndexOffset"))
                if (!$util.isInteger(message.lastIndexOffset) && !(message.lastIndexOffset && $util.isInteger(message.lastIndexOffset.low) && $util.isInteger(message.lastIndexOffset.high)))
                    return "lastIndexOffset: integer|Long expected";
            if (message.firstIndexOffset != null && message.hasOwnProperty("firstIndexOffset"))
                if (!$util.isInteger(message.firstIndexOffset) && !(message.firstIndexOffset && $util.isInteger(message.firstIndexOffset.low) && $util.isInteger(message.firstIndexOffset.high)))
                    return "firstIndexOffset: integer|Long expected";
            return null;
        };

        /**
         * Creates a ListInvoiceResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListInvoiceResponse} ListInvoiceResponse
         */
        ListInvoiceResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListInvoiceResponse)
                return object;
            let message = new $root.lnrpc.ListInvoiceResponse();
            if (object.invoices) {
                if (!Array.isArray(object.invoices))
                    throw TypeError(".lnrpc.ListInvoiceResponse.invoices: array expected");
                message.invoices = [];
                for (let i = 0; i < object.invoices.length; ++i) {
                    if (typeof object.invoices[i] !== "object")
                        throw TypeError(".lnrpc.ListInvoiceResponse.invoices: object expected");
                    message.invoices[i] = $root.lnrpc.Invoice.fromObject(object.invoices[i]);
                }
            }
            if (object.lastIndexOffset != null)
                if ($util.Long)
                    (message.lastIndexOffset = $util.Long.fromValue(object.lastIndexOffset)).unsigned = true;
                else if (typeof object.lastIndexOffset === "string")
                    message.lastIndexOffset = parseInt(object.lastIndexOffset, 10);
                else if (typeof object.lastIndexOffset === "number")
                    message.lastIndexOffset = object.lastIndexOffset;
                else if (typeof object.lastIndexOffset === "object")
                    message.lastIndexOffset = new $util.LongBits(object.lastIndexOffset.low >>> 0, object.lastIndexOffset.high >>> 0).toNumber(true);
            if (object.firstIndexOffset != null)
                if ($util.Long)
                    (message.firstIndexOffset = $util.Long.fromValue(object.firstIndexOffset)).unsigned = true;
                else if (typeof object.firstIndexOffset === "string")
                    message.firstIndexOffset = parseInt(object.firstIndexOffset, 10);
                else if (typeof object.firstIndexOffset === "number")
                    message.firstIndexOffset = object.firstIndexOffset;
                else if (typeof object.firstIndexOffset === "object")
                    message.firstIndexOffset = new $util.LongBits(object.firstIndexOffset.low >>> 0, object.firstIndexOffset.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ListInvoiceResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListInvoiceResponse
         * @static
         * @param {lnrpc.ListInvoiceResponse} message ListInvoiceResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListInvoiceResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.invoices = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.lastIndexOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lastIndexOffset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.firstIndexOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.firstIndexOffset = options.longs === String ? "0" : 0;
            }
            if (message.invoices && message.invoices.length) {
                object.invoices = [];
                for (let j = 0; j < message.invoices.length; ++j)
                    object.invoices[j] = $root.lnrpc.Invoice.toObject(message.invoices[j], options);
            }
            if (message.lastIndexOffset != null && message.hasOwnProperty("lastIndexOffset"))
                if (typeof message.lastIndexOffset === "number")
                    object.lastIndexOffset = options.longs === String ? String(message.lastIndexOffset) : message.lastIndexOffset;
                else
                    object.lastIndexOffset = options.longs === String ? $util.Long.prototype.toString.call(message.lastIndexOffset) : options.longs === Number ? new $util.LongBits(message.lastIndexOffset.low >>> 0, message.lastIndexOffset.high >>> 0).toNumber(true) : message.lastIndexOffset;
            if (message.firstIndexOffset != null && message.hasOwnProperty("firstIndexOffset"))
                if (typeof message.firstIndexOffset === "number")
                    object.firstIndexOffset = options.longs === String ? String(message.firstIndexOffset) : message.firstIndexOffset;
                else
                    object.firstIndexOffset = options.longs === String ? $util.Long.prototype.toString.call(message.firstIndexOffset) : options.longs === Number ? new $util.LongBits(message.firstIndexOffset.low >>> 0, message.firstIndexOffset.high >>> 0).toNumber(true) : message.firstIndexOffset;
            return object;
        };

        /**
         * Converts this ListInvoiceResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListInvoiceResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListInvoiceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListInvoiceResponse;
    })();

    lnrpc.InvoiceSubscription = (function() {

        /**
         * Properties of an InvoiceSubscription.
         * @memberof lnrpc
         * @interface IInvoiceSubscription
         * @property {Long|null} [addIndex] InvoiceSubscription addIndex
         * @property {Long|null} [settleIndex] InvoiceSubscription settleIndex
         */

        /**
         * Constructs a new InvoiceSubscription.
         * @memberof lnrpc
         * @classdesc Represents an InvoiceSubscription.
         * @implements IInvoiceSubscription
         * @constructor
         * @param {lnrpc.IInvoiceSubscription=} [properties] Properties to set
         */
        function InvoiceSubscription(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvoiceSubscription addIndex.
         * @member {Long} addIndex
         * @memberof lnrpc.InvoiceSubscription
         * @instance
         */
        InvoiceSubscription.prototype.addIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * InvoiceSubscription settleIndex.
         * @member {Long} settleIndex
         * @memberof lnrpc.InvoiceSubscription
         * @instance
         */
        InvoiceSubscription.prototype.settleIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new InvoiceSubscription instance using the specified properties.
         * @function create
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {lnrpc.IInvoiceSubscription=} [properties] Properties to set
         * @returns {lnrpc.InvoiceSubscription} InvoiceSubscription instance
         */
        InvoiceSubscription.create = function create(properties) {
            return new InvoiceSubscription(properties);
        };

        /**
         * Encodes the specified InvoiceSubscription message. Does not implicitly {@link lnrpc.InvoiceSubscription.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {lnrpc.IInvoiceSubscription} message InvoiceSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvoiceSubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addIndex != null && Object.hasOwnProperty.call(message, "addIndex"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.addIndex);
            if (message.settleIndex != null && Object.hasOwnProperty.call(message, "settleIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.settleIndex);
            return writer;
        };

        /**
         * Encodes the specified InvoiceSubscription message, length delimited. Does not implicitly {@link lnrpc.InvoiceSubscription.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {lnrpc.IInvoiceSubscription} message InvoiceSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvoiceSubscription.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvoiceSubscription message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.InvoiceSubscription} InvoiceSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvoiceSubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.InvoiceSubscription();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.addIndex = reader.uint64();
                        break;
                    }
                case 2: {
                        message.settleIndex = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvoiceSubscription message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.InvoiceSubscription} InvoiceSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvoiceSubscription.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvoiceSubscription message.
         * @function verify
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvoiceSubscription.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (!$util.isInteger(message.addIndex) && !(message.addIndex && $util.isInteger(message.addIndex.low) && $util.isInteger(message.addIndex.high)))
                    return "addIndex: integer|Long expected";
            if (message.settleIndex != null && message.hasOwnProperty("settleIndex"))
                if (!$util.isInteger(message.settleIndex) && !(message.settleIndex && $util.isInteger(message.settleIndex.low) && $util.isInteger(message.settleIndex.high)))
                    return "settleIndex: integer|Long expected";
            return null;
        };

        /**
         * Creates an InvoiceSubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.InvoiceSubscription} InvoiceSubscription
         */
        InvoiceSubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.InvoiceSubscription)
                return object;
            let message = new $root.lnrpc.InvoiceSubscription();
            if (object.addIndex != null)
                if ($util.Long)
                    (message.addIndex = $util.Long.fromValue(object.addIndex)).unsigned = true;
                else if (typeof object.addIndex === "string")
                    message.addIndex = parseInt(object.addIndex, 10);
                else if (typeof object.addIndex === "number")
                    message.addIndex = object.addIndex;
                else if (typeof object.addIndex === "object")
                    message.addIndex = new $util.LongBits(object.addIndex.low >>> 0, object.addIndex.high >>> 0).toNumber(true);
            if (object.settleIndex != null)
                if ($util.Long)
                    (message.settleIndex = $util.Long.fromValue(object.settleIndex)).unsigned = true;
                else if (typeof object.settleIndex === "string")
                    message.settleIndex = parseInt(object.settleIndex, 10);
                else if (typeof object.settleIndex === "number")
                    message.settleIndex = object.settleIndex;
                else if (typeof object.settleIndex === "object")
                    message.settleIndex = new $util.LongBits(object.settleIndex.low >>> 0, object.settleIndex.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an InvoiceSubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.InvoiceSubscription
         * @static
         * @param {lnrpc.InvoiceSubscription} message InvoiceSubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvoiceSubscription.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.addIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.addIndex = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.settleIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.settleIndex = options.longs === String ? "0" : 0;
            }
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (typeof message.addIndex === "number")
                    object.addIndex = options.longs === String ? String(message.addIndex) : message.addIndex;
                else
                    object.addIndex = options.longs === String ? $util.Long.prototype.toString.call(message.addIndex) : options.longs === Number ? new $util.LongBits(message.addIndex.low >>> 0, message.addIndex.high >>> 0).toNumber(true) : message.addIndex;
            if (message.settleIndex != null && message.hasOwnProperty("settleIndex"))
                if (typeof message.settleIndex === "number")
                    object.settleIndex = options.longs === String ? String(message.settleIndex) : message.settleIndex;
                else
                    object.settleIndex = options.longs === String ? $util.Long.prototype.toString.call(message.settleIndex) : options.longs === Number ? new $util.LongBits(message.settleIndex.low >>> 0, message.settleIndex.high >>> 0).toNumber(true) : message.settleIndex;
            return object;
        };

        /**
         * Converts this InvoiceSubscription to JSON.
         * @function toJSON
         * @memberof lnrpc.InvoiceSubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvoiceSubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InvoiceSubscription;
    })();

    /**
     * PaymentFailureReason enum.
     * @name lnrpc.PaymentFailureReason
     * @enum {number}
     * @property {number} FAILURE_REASON_NONE=0 FAILURE_REASON_NONE value
     * @property {number} FAILURE_REASON_TIMEOUT=1 FAILURE_REASON_TIMEOUT value
     * @property {number} FAILURE_REASON_NO_ROUTE=2 FAILURE_REASON_NO_ROUTE value
     * @property {number} FAILURE_REASON_ERROR=3 FAILURE_REASON_ERROR value
     * @property {number} FAILURE_REASON_INCORRECT_PAYMENT_DETAILS=4 FAILURE_REASON_INCORRECT_PAYMENT_DETAILS value
     * @property {number} FAILURE_REASON_INSUFFICIENT_BALANCE=5 FAILURE_REASON_INSUFFICIENT_BALANCE value
     */
    lnrpc.PaymentFailureReason = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "FAILURE_REASON_NONE"] = 0;
        values[valuesById[1] = "FAILURE_REASON_TIMEOUT"] = 1;
        values[valuesById[2] = "FAILURE_REASON_NO_ROUTE"] = 2;
        values[valuesById[3] = "FAILURE_REASON_ERROR"] = 3;
        values[valuesById[4] = "FAILURE_REASON_INCORRECT_PAYMENT_DETAILS"] = 4;
        values[valuesById[5] = "FAILURE_REASON_INSUFFICIENT_BALANCE"] = 5;
        return values;
    })();

    lnrpc.Payment = (function() {

        /**
         * Properties of a Payment.
         * @memberof lnrpc
         * @interface IPayment
         * @property {string|null} [paymentHash] Payment paymentHash
         * @property {Long|null} [value] Payment value
         * @property {Long|null} [creationDate] Payment creationDate
         * @property {Long|null} [fee] Payment fee
         * @property {string|null} [paymentPreimage] Payment paymentPreimage
         * @property {Long|null} [valueSat] Payment valueSat
         * @property {Long|null} [valueMsat] Payment valueMsat
         * @property {string|null} [paymentRequest] Payment paymentRequest
         * @property {lnrpc.Payment.PaymentStatus|null} [status] Payment status
         * @property {Long|null} [feeSat] Payment feeSat
         * @property {Long|null} [feeMsat] Payment feeMsat
         * @property {Long|null} [creationTimeNs] Payment creationTimeNs
         * @property {Array.<lnrpc.IHTLCAttempt>|null} [htlcs] Payment htlcs
         * @property {Long|null} [paymentIndex] Payment paymentIndex
         * @property {lnrpc.PaymentFailureReason|null} [failureReason] Payment failureReason
         */

        /**
         * Constructs a new Payment.
         * @memberof lnrpc
         * @classdesc Represents a Payment.
         * @implements IPayment
         * @constructor
         * @param {lnrpc.IPayment=} [properties] Properties to set
         */
        function Payment(properties) {
            this.htlcs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Payment paymentHash.
         * @member {string} paymentHash
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.paymentHash = "";

        /**
         * Payment value.
         * @member {Long} value
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment creationDate.
         * @member {Long} creationDate
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.creationDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment fee.
         * @member {Long} fee
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment paymentPreimage.
         * @member {string} paymentPreimage
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.paymentPreimage = "";

        /**
         * Payment valueSat.
         * @member {Long} valueSat
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.valueSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment valueMsat.
         * @member {Long} valueMsat
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.valueMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment paymentRequest.
         * @member {string} paymentRequest
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.paymentRequest = "";

        /**
         * Payment status.
         * @member {lnrpc.Payment.PaymentStatus} status
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.status = 0;

        /**
         * Payment feeSat.
         * @member {Long} feeSat
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.feeSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment feeMsat.
         * @member {Long} feeMsat
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.feeMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment creationTimeNs.
         * @member {Long} creationTimeNs
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.creationTimeNs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Payment htlcs.
         * @member {Array.<lnrpc.IHTLCAttempt>} htlcs
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.htlcs = $util.emptyArray;

        /**
         * Payment paymentIndex.
         * @member {Long} paymentIndex
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.paymentIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Payment failureReason.
         * @member {lnrpc.PaymentFailureReason} failureReason
         * @memberof lnrpc.Payment
         * @instance
         */
        Payment.prototype.failureReason = 0;

        /**
         * Creates a new Payment instance using the specified properties.
         * @function create
         * @memberof lnrpc.Payment
         * @static
         * @param {lnrpc.IPayment=} [properties] Properties to set
         * @returns {lnrpc.Payment} Payment instance
         */
        Payment.create = function create(properties) {
            return new Payment(properties);
        };

        /**
         * Encodes the specified Payment message. Does not implicitly {@link lnrpc.Payment.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Payment
         * @static
         * @param {lnrpc.IPayment} message Payment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentHash);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.value);
            if (message.creationDate != null && Object.hasOwnProperty.call(message, "creationDate"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.creationDate);
            if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.fee);
            if (message.paymentPreimage != null && Object.hasOwnProperty.call(message, "paymentPreimage"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.paymentPreimage);
            if (message.valueSat != null && Object.hasOwnProperty.call(message, "valueSat"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.valueSat);
            if (message.valueMsat != null && Object.hasOwnProperty.call(message, "valueMsat"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.valueMsat);
            if (message.paymentRequest != null && Object.hasOwnProperty.call(message, "paymentRequest"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.paymentRequest);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.status);
            if (message.feeSat != null && Object.hasOwnProperty.call(message, "feeSat"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.feeSat);
            if (message.feeMsat != null && Object.hasOwnProperty.call(message, "feeMsat"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.feeMsat);
            if (message.creationTimeNs != null && Object.hasOwnProperty.call(message, "creationTimeNs"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.creationTimeNs);
            if (message.htlcs != null && message.htlcs.length)
                for (let i = 0; i < message.htlcs.length; ++i)
                    $root.lnrpc.HTLCAttempt.encode(message.htlcs[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.paymentIndex != null && Object.hasOwnProperty.call(message, "paymentIndex"))
                writer.uint32(/* id 15, wireType 0 =*/120).uint64(message.paymentIndex);
            if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.failureReason);
            return writer;
        };

        /**
         * Encodes the specified Payment message, length delimited. Does not implicitly {@link lnrpc.Payment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Payment
         * @static
         * @param {lnrpc.IPayment} message Payment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Payment message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Payment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Payment} Payment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Payment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.string();
                        break;
                    }
                case 2: {
                        message.value = reader.int64();
                        break;
                    }
                case 3: {
                        message.creationDate = reader.int64();
                        break;
                    }
                case 5: {
                        message.fee = reader.int64();
                        break;
                    }
                case 6: {
                        message.paymentPreimage = reader.string();
                        break;
                    }
                case 7: {
                        message.valueSat = reader.int64();
                        break;
                    }
                case 8: {
                        message.valueMsat = reader.int64();
                        break;
                    }
                case 9: {
                        message.paymentRequest = reader.string();
                        break;
                    }
                case 10: {
                        message.status = reader.int32();
                        break;
                    }
                case 11: {
                        message.feeSat = reader.int64();
                        break;
                    }
                case 12: {
                        message.feeMsat = reader.int64();
                        break;
                    }
                case 13: {
                        message.creationTimeNs = reader.int64();
                        break;
                    }
                case 14: {
                        if (!(message.htlcs && message.htlcs.length))
                            message.htlcs = [];
                        message.htlcs.push($root.lnrpc.HTLCAttempt.decode(reader, reader.uint32()));
                        break;
                    }
                case 15: {
                        message.paymentIndex = reader.uint64();
                        break;
                    }
                case 16: {
                        message.failureReason = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Payment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Payment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Payment} Payment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Payment message.
         * @function verify
         * @memberof lnrpc.Payment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Payment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!$util.isString(message.paymentHash))
                    return "paymentHash: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                    return "value: integer|Long expected";
            if (message.creationDate != null && message.hasOwnProperty("creationDate"))
                if (!$util.isInteger(message.creationDate) && !(message.creationDate && $util.isInteger(message.creationDate.low) && $util.isInteger(message.creationDate.high)))
                    return "creationDate: integer|Long expected";
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                    return "fee: integer|Long expected";
            if (message.paymentPreimage != null && message.hasOwnProperty("paymentPreimage"))
                if (!$util.isString(message.paymentPreimage))
                    return "paymentPreimage: string expected";
            if (message.valueSat != null && message.hasOwnProperty("valueSat"))
                if (!$util.isInteger(message.valueSat) && !(message.valueSat && $util.isInteger(message.valueSat.low) && $util.isInteger(message.valueSat.high)))
                    return "valueSat: integer|Long expected";
            if (message.valueMsat != null && message.hasOwnProperty("valueMsat"))
                if (!$util.isInteger(message.valueMsat) && !(message.valueMsat && $util.isInteger(message.valueMsat.low) && $util.isInteger(message.valueMsat.high)))
                    return "valueMsat: integer|Long expected";
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                if (!$util.isString(message.paymentRequest))
                    return "paymentRequest: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.feeSat != null && message.hasOwnProperty("feeSat"))
                if (!$util.isInteger(message.feeSat) && !(message.feeSat && $util.isInteger(message.feeSat.low) && $util.isInteger(message.feeSat.high)))
                    return "feeSat: integer|Long expected";
            if (message.feeMsat != null && message.hasOwnProperty("feeMsat"))
                if (!$util.isInteger(message.feeMsat) && !(message.feeMsat && $util.isInteger(message.feeMsat.low) && $util.isInteger(message.feeMsat.high)))
                    return "feeMsat: integer|Long expected";
            if (message.creationTimeNs != null && message.hasOwnProperty("creationTimeNs"))
                if (!$util.isInteger(message.creationTimeNs) && !(message.creationTimeNs && $util.isInteger(message.creationTimeNs.low) && $util.isInteger(message.creationTimeNs.high)))
                    return "creationTimeNs: integer|Long expected";
            if (message.htlcs != null && message.hasOwnProperty("htlcs")) {
                if (!Array.isArray(message.htlcs))
                    return "htlcs: array expected";
                for (let i = 0; i < message.htlcs.length; ++i) {
                    let error = $root.lnrpc.HTLCAttempt.verify(message.htlcs[i]);
                    if (error)
                        return "htlcs." + error;
                }
            }
            if (message.paymentIndex != null && message.hasOwnProperty("paymentIndex"))
                if (!$util.isInteger(message.paymentIndex) && !(message.paymentIndex && $util.isInteger(message.paymentIndex.low) && $util.isInteger(message.paymentIndex.high)))
                    return "paymentIndex: integer|Long expected";
            if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                switch (message.failureReason) {
                default:
                    return "failureReason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
                }
            return null;
        };

        /**
         * Creates a Payment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Payment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Payment} Payment
         */
        Payment.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Payment)
                return object;
            let message = new $root.lnrpc.Payment();
            if (object.paymentHash != null)
                message.paymentHash = String(object.paymentHash);
            if (object.value != null)
                if ($util.Long)
                    (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                else if (typeof object.value === "string")
                    message.value = parseInt(object.value, 10);
                else if (typeof object.value === "number")
                    message.value = object.value;
                else if (typeof object.value === "object")
                    message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
            if (object.creationDate != null)
                if ($util.Long)
                    (message.creationDate = $util.Long.fromValue(object.creationDate)).unsigned = false;
                else if (typeof object.creationDate === "string")
                    message.creationDate = parseInt(object.creationDate, 10);
                else if (typeof object.creationDate === "number")
                    message.creationDate = object.creationDate;
                else if (typeof object.creationDate === "object")
                    message.creationDate = new $util.LongBits(object.creationDate.low >>> 0, object.creationDate.high >>> 0).toNumber();
            if (object.fee != null)
                if ($util.Long)
                    (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
                else if (typeof object.fee === "string")
                    message.fee = parseInt(object.fee, 10);
                else if (typeof object.fee === "number")
                    message.fee = object.fee;
                else if (typeof object.fee === "object")
                    message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
            if (object.paymentPreimage != null)
                message.paymentPreimage = String(object.paymentPreimage);
            if (object.valueSat != null)
                if ($util.Long)
                    (message.valueSat = $util.Long.fromValue(object.valueSat)).unsigned = false;
                else if (typeof object.valueSat === "string")
                    message.valueSat = parseInt(object.valueSat, 10);
                else if (typeof object.valueSat === "number")
                    message.valueSat = object.valueSat;
                else if (typeof object.valueSat === "object")
                    message.valueSat = new $util.LongBits(object.valueSat.low >>> 0, object.valueSat.high >>> 0).toNumber();
            if (object.valueMsat != null)
                if ($util.Long)
                    (message.valueMsat = $util.Long.fromValue(object.valueMsat)).unsigned = false;
                else if (typeof object.valueMsat === "string")
                    message.valueMsat = parseInt(object.valueMsat, 10);
                else if (typeof object.valueMsat === "number")
                    message.valueMsat = object.valueMsat;
                else if (typeof object.valueMsat === "object")
                    message.valueMsat = new $util.LongBits(object.valueMsat.low >>> 0, object.valueMsat.high >>> 0).toNumber();
            if (object.paymentRequest != null)
                message.paymentRequest = String(object.paymentRequest);
            switch (object.status) {
            case "UNKNOWN":
            case 0:
                message.status = 0;
                break;
            case "IN_FLIGHT":
            case 1:
                message.status = 1;
                break;
            case "SUCCEEDED":
            case 2:
                message.status = 2;
                break;
            case "FAILED":
            case 3:
                message.status = 3;
                break;
            }
            if (object.feeSat != null)
                if ($util.Long)
                    (message.feeSat = $util.Long.fromValue(object.feeSat)).unsigned = false;
                else if (typeof object.feeSat === "string")
                    message.feeSat = parseInt(object.feeSat, 10);
                else if (typeof object.feeSat === "number")
                    message.feeSat = object.feeSat;
                else if (typeof object.feeSat === "object")
                    message.feeSat = new $util.LongBits(object.feeSat.low >>> 0, object.feeSat.high >>> 0).toNumber();
            if (object.feeMsat != null)
                if ($util.Long)
                    (message.feeMsat = $util.Long.fromValue(object.feeMsat)).unsigned = false;
                else if (typeof object.feeMsat === "string")
                    message.feeMsat = parseInt(object.feeMsat, 10);
                else if (typeof object.feeMsat === "number")
                    message.feeMsat = object.feeMsat;
                else if (typeof object.feeMsat === "object")
                    message.feeMsat = new $util.LongBits(object.feeMsat.low >>> 0, object.feeMsat.high >>> 0).toNumber();
            if (object.creationTimeNs != null)
                if ($util.Long)
                    (message.creationTimeNs = $util.Long.fromValue(object.creationTimeNs)).unsigned = false;
                else if (typeof object.creationTimeNs === "string")
                    message.creationTimeNs = parseInt(object.creationTimeNs, 10);
                else if (typeof object.creationTimeNs === "number")
                    message.creationTimeNs = object.creationTimeNs;
                else if (typeof object.creationTimeNs === "object")
                    message.creationTimeNs = new $util.LongBits(object.creationTimeNs.low >>> 0, object.creationTimeNs.high >>> 0).toNumber();
            if (object.htlcs) {
                if (!Array.isArray(object.htlcs))
                    throw TypeError(".lnrpc.Payment.htlcs: array expected");
                message.htlcs = [];
                for (let i = 0; i < object.htlcs.length; ++i) {
                    if (typeof object.htlcs[i] !== "object")
                        throw TypeError(".lnrpc.Payment.htlcs: object expected");
                    message.htlcs[i] = $root.lnrpc.HTLCAttempt.fromObject(object.htlcs[i]);
                }
            }
            if (object.paymentIndex != null)
                if ($util.Long)
                    (message.paymentIndex = $util.Long.fromValue(object.paymentIndex)).unsigned = true;
                else if (typeof object.paymentIndex === "string")
                    message.paymentIndex = parseInt(object.paymentIndex, 10);
                else if (typeof object.paymentIndex === "number")
                    message.paymentIndex = object.paymentIndex;
                else if (typeof object.paymentIndex === "object")
                    message.paymentIndex = new $util.LongBits(object.paymentIndex.low >>> 0, object.paymentIndex.high >>> 0).toNumber(true);
            switch (object.failureReason) {
            case "FAILURE_REASON_NONE":
            case 0:
                message.failureReason = 0;
                break;
            case "FAILURE_REASON_TIMEOUT":
            case 1:
                message.failureReason = 1;
                break;
            case "FAILURE_REASON_NO_ROUTE":
            case 2:
                message.failureReason = 2;
                break;
            case "FAILURE_REASON_ERROR":
            case 3:
                message.failureReason = 3;
                break;
            case "FAILURE_REASON_INCORRECT_PAYMENT_DETAILS":
            case 4:
                message.failureReason = 4;
                break;
            case "FAILURE_REASON_INSUFFICIENT_BALANCE":
            case 5:
                message.failureReason = 5;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Payment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Payment
         * @static
         * @param {lnrpc.Payment} message Payment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Payment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.htlcs = [];
            if (options.defaults) {
                object.paymentHash = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.value = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creationDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creationDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fee = options.longs === String ? "0" : 0;
                object.paymentPreimage = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.valueSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.valueSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.valueMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.valueMsat = options.longs === String ? "0" : 0;
                object.paymentRequest = "";
                object.status = options.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.creationTimeNs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.creationTimeNs = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.paymentIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.paymentIndex = options.longs === String ? "0" : 0;
                object.failureReason = options.enums === String ? "FAILURE_REASON_NONE" : 0;
            }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = message.paymentHash;
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value === "number")
                    object.value = options.longs === String ? String(message.value) : message.value;
                else
                    object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
            if (message.creationDate != null && message.hasOwnProperty("creationDate"))
                if (typeof message.creationDate === "number")
                    object.creationDate = options.longs === String ? String(message.creationDate) : message.creationDate;
                else
                    object.creationDate = options.longs === String ? $util.Long.prototype.toString.call(message.creationDate) : options.longs === Number ? new $util.LongBits(message.creationDate.low >>> 0, message.creationDate.high >>> 0).toNumber() : message.creationDate;
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (typeof message.fee === "number")
                    object.fee = options.longs === String ? String(message.fee) : message.fee;
                else
                    object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber() : message.fee;
            if (message.paymentPreimage != null && message.hasOwnProperty("paymentPreimage"))
                object.paymentPreimage = message.paymentPreimage;
            if (message.valueSat != null && message.hasOwnProperty("valueSat"))
                if (typeof message.valueSat === "number")
                    object.valueSat = options.longs === String ? String(message.valueSat) : message.valueSat;
                else
                    object.valueSat = options.longs === String ? $util.Long.prototype.toString.call(message.valueSat) : options.longs === Number ? new $util.LongBits(message.valueSat.low >>> 0, message.valueSat.high >>> 0).toNumber() : message.valueSat;
            if (message.valueMsat != null && message.hasOwnProperty("valueMsat"))
                if (typeof message.valueMsat === "number")
                    object.valueMsat = options.longs === String ? String(message.valueMsat) : message.valueMsat;
                else
                    object.valueMsat = options.longs === String ? $util.Long.prototype.toString.call(message.valueMsat) : options.longs === Number ? new $util.LongBits(message.valueMsat.low >>> 0, message.valueMsat.high >>> 0).toNumber() : message.valueMsat;
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                object.paymentRequest = message.paymentRequest;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.lnrpc.Payment.PaymentStatus[message.status] : message.status;
            if (message.feeSat != null && message.hasOwnProperty("feeSat"))
                if (typeof message.feeSat === "number")
                    object.feeSat = options.longs === String ? String(message.feeSat) : message.feeSat;
                else
                    object.feeSat = options.longs === String ? $util.Long.prototype.toString.call(message.feeSat) : options.longs === Number ? new $util.LongBits(message.feeSat.low >>> 0, message.feeSat.high >>> 0).toNumber() : message.feeSat;
            if (message.feeMsat != null && message.hasOwnProperty("feeMsat"))
                if (typeof message.feeMsat === "number")
                    object.feeMsat = options.longs === String ? String(message.feeMsat) : message.feeMsat;
                else
                    object.feeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.feeMsat) : options.longs === Number ? new $util.LongBits(message.feeMsat.low >>> 0, message.feeMsat.high >>> 0).toNumber() : message.feeMsat;
            if (message.creationTimeNs != null && message.hasOwnProperty("creationTimeNs"))
                if (typeof message.creationTimeNs === "number")
                    object.creationTimeNs = options.longs === String ? String(message.creationTimeNs) : message.creationTimeNs;
                else
                    object.creationTimeNs = options.longs === String ? $util.Long.prototype.toString.call(message.creationTimeNs) : options.longs === Number ? new $util.LongBits(message.creationTimeNs.low >>> 0, message.creationTimeNs.high >>> 0).toNumber() : message.creationTimeNs;
            if (message.htlcs && message.htlcs.length) {
                object.htlcs = [];
                for (let j = 0; j < message.htlcs.length; ++j)
                    object.htlcs[j] = $root.lnrpc.HTLCAttempt.toObject(message.htlcs[j], options);
            }
            if (message.paymentIndex != null && message.hasOwnProperty("paymentIndex"))
                if (typeof message.paymentIndex === "number")
                    object.paymentIndex = options.longs === String ? String(message.paymentIndex) : message.paymentIndex;
                else
                    object.paymentIndex = options.longs === String ? $util.Long.prototype.toString.call(message.paymentIndex) : options.longs === Number ? new $util.LongBits(message.paymentIndex.low >>> 0, message.paymentIndex.high >>> 0).toNumber(true) : message.paymentIndex;
            if (message.failureReason != null && message.hasOwnProperty("failureReason"))
                object.failureReason = options.enums === String ? $root.lnrpc.PaymentFailureReason[message.failureReason] : message.failureReason;
            return object;
        };

        /**
         * Converts this Payment to JSON.
         * @function toJSON
         * @memberof lnrpc.Payment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Payment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * PaymentStatus enum.
         * @name lnrpc.Payment.PaymentStatus
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} IN_FLIGHT=1 IN_FLIGHT value
         * @property {number} SUCCEEDED=2 SUCCEEDED value
         * @property {number} FAILED=3 FAILED value
         */
        Payment.PaymentStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "IN_FLIGHT"] = 1;
            values[valuesById[2] = "SUCCEEDED"] = 2;
            values[valuesById[3] = "FAILED"] = 3;
            return values;
        })();

        return Payment;
    })();

    lnrpc.HTLCAttempt = (function() {

        /**
         * Properties of a HTLCAttempt.
         * @memberof lnrpc
         * @interface IHTLCAttempt
         * @property {Long|null} [attemptId] HTLCAttempt attemptId
         * @property {lnrpc.HTLCAttempt.HTLCStatus|null} [status] HTLCAttempt status
         * @property {lnrpc.IRoute|null} [route] HTLCAttempt route
         * @property {Long|null} [attemptTimeNs] HTLCAttempt attemptTimeNs
         * @property {Long|null} [resolveTimeNs] HTLCAttempt resolveTimeNs
         * @property {lnrpc.IFailure|null} [failure] HTLCAttempt failure
         * @property {Uint8Array|null} [preimage] HTLCAttempt preimage
         */

        /**
         * Constructs a new HTLCAttempt.
         * @memberof lnrpc
         * @classdesc Represents a HTLCAttempt.
         * @implements IHTLCAttempt
         * @constructor
         * @param {lnrpc.IHTLCAttempt=} [properties] Properties to set
         */
        function HTLCAttempt(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HTLCAttempt attemptId.
         * @member {Long} attemptId
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.attemptId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HTLCAttempt status.
         * @member {lnrpc.HTLCAttempt.HTLCStatus} status
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.status = 0;

        /**
         * HTLCAttempt route.
         * @member {lnrpc.IRoute|null|undefined} route
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.route = null;

        /**
         * HTLCAttempt attemptTimeNs.
         * @member {Long} attemptTimeNs
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.attemptTimeNs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HTLCAttempt resolveTimeNs.
         * @member {Long} resolveTimeNs
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.resolveTimeNs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * HTLCAttempt failure.
         * @member {lnrpc.IFailure|null|undefined} failure
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.failure = null;

        /**
         * HTLCAttempt preimage.
         * @member {Uint8Array} preimage
         * @memberof lnrpc.HTLCAttempt
         * @instance
         */
        HTLCAttempt.prototype.preimage = $util.newBuffer([]);

        /**
         * Creates a new HTLCAttempt instance using the specified properties.
         * @function create
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {lnrpc.IHTLCAttempt=} [properties] Properties to set
         * @returns {lnrpc.HTLCAttempt} HTLCAttempt instance
         */
        HTLCAttempt.create = function create(properties) {
            return new HTLCAttempt(properties);
        };

        /**
         * Encodes the specified HTLCAttempt message. Does not implicitly {@link lnrpc.HTLCAttempt.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {lnrpc.IHTLCAttempt} message HTLCAttempt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTLCAttempt.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
            if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                $root.lnrpc.Route.encode(message.route, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.attemptTimeNs != null && Object.hasOwnProperty.call(message, "attemptTimeNs"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.attemptTimeNs);
            if (message.resolveTimeNs != null && Object.hasOwnProperty.call(message, "resolveTimeNs"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.resolveTimeNs);
            if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                $root.lnrpc.Failure.encode(message.failure, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.preimage);
            if (message.attemptId != null && Object.hasOwnProperty.call(message, "attemptId"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.attemptId);
            return writer;
        };

        /**
         * Encodes the specified HTLCAttempt message, length delimited. Does not implicitly {@link lnrpc.HTLCAttempt.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {lnrpc.IHTLCAttempt} message HTLCAttempt message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HTLCAttempt.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HTLCAttempt message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.HTLCAttempt} HTLCAttempt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTLCAttempt.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.HTLCAttempt();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 7: {
                        message.attemptId = reader.uint64();
                        break;
                    }
                case 1: {
                        message.status = reader.int32();
                        break;
                    }
                case 2: {
                        message.route = $root.lnrpc.Route.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.attemptTimeNs = reader.int64();
                        break;
                    }
                case 4: {
                        message.resolveTimeNs = reader.int64();
                        break;
                    }
                case 5: {
                        message.failure = $root.lnrpc.Failure.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.preimage = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HTLCAttempt message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.HTLCAttempt} HTLCAttempt
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HTLCAttempt.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HTLCAttempt message.
         * @function verify
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HTLCAttempt.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.attemptId != null && message.hasOwnProperty("attemptId"))
                if (!$util.isInteger(message.attemptId) && !(message.attemptId && $util.isInteger(message.attemptId.low) && $util.isInteger(message.attemptId.high)))
                    return "attemptId: integer|Long expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.route != null && message.hasOwnProperty("route")) {
                let error = $root.lnrpc.Route.verify(message.route);
                if (error)
                    return "route." + error;
            }
            if (message.attemptTimeNs != null && message.hasOwnProperty("attemptTimeNs"))
                if (!$util.isInteger(message.attemptTimeNs) && !(message.attemptTimeNs && $util.isInteger(message.attemptTimeNs.low) && $util.isInteger(message.attemptTimeNs.high)))
                    return "attemptTimeNs: integer|Long expected";
            if (message.resolveTimeNs != null && message.hasOwnProperty("resolveTimeNs"))
                if (!$util.isInteger(message.resolveTimeNs) && !(message.resolveTimeNs && $util.isInteger(message.resolveTimeNs.low) && $util.isInteger(message.resolveTimeNs.high)))
                    return "resolveTimeNs: integer|Long expected";
            if (message.failure != null && message.hasOwnProperty("failure")) {
                let error = $root.lnrpc.Failure.verify(message.failure);
                if (error)
                    return "failure." + error;
            }
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            return null;
        };

        /**
         * Creates a HTLCAttempt message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.HTLCAttempt} HTLCAttempt
         */
        HTLCAttempt.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.HTLCAttempt)
                return object;
            let message = new $root.lnrpc.HTLCAttempt();
            if (object.attemptId != null)
                if ($util.Long)
                    (message.attemptId = $util.Long.fromValue(object.attemptId)).unsigned = true;
                else if (typeof object.attemptId === "string")
                    message.attemptId = parseInt(object.attemptId, 10);
                else if (typeof object.attemptId === "number")
                    message.attemptId = object.attemptId;
                else if (typeof object.attemptId === "object")
                    message.attemptId = new $util.LongBits(object.attemptId.low >>> 0, object.attemptId.high >>> 0).toNumber(true);
            switch (object.status) {
            case "IN_FLIGHT":
            case 0:
                message.status = 0;
                break;
            case "SUCCEEDED":
            case 1:
                message.status = 1;
                break;
            case "FAILED":
            case 2:
                message.status = 2;
                break;
            }
            if (object.route != null) {
                if (typeof object.route !== "object")
                    throw TypeError(".lnrpc.HTLCAttempt.route: object expected");
                message.route = $root.lnrpc.Route.fromObject(object.route);
            }
            if (object.attemptTimeNs != null)
                if ($util.Long)
                    (message.attemptTimeNs = $util.Long.fromValue(object.attemptTimeNs)).unsigned = false;
                else if (typeof object.attemptTimeNs === "string")
                    message.attemptTimeNs = parseInt(object.attemptTimeNs, 10);
                else if (typeof object.attemptTimeNs === "number")
                    message.attemptTimeNs = object.attemptTimeNs;
                else if (typeof object.attemptTimeNs === "object")
                    message.attemptTimeNs = new $util.LongBits(object.attemptTimeNs.low >>> 0, object.attemptTimeNs.high >>> 0).toNumber();
            if (object.resolveTimeNs != null)
                if ($util.Long)
                    (message.resolveTimeNs = $util.Long.fromValue(object.resolveTimeNs)).unsigned = false;
                else if (typeof object.resolveTimeNs === "string")
                    message.resolveTimeNs = parseInt(object.resolveTimeNs, 10);
                else if (typeof object.resolveTimeNs === "number")
                    message.resolveTimeNs = object.resolveTimeNs;
                else if (typeof object.resolveTimeNs === "object")
                    message.resolveTimeNs = new $util.LongBits(object.resolveTimeNs.low >>> 0, object.resolveTimeNs.high >>> 0).toNumber();
            if (object.failure != null) {
                if (typeof object.failure !== "object")
                    throw TypeError(".lnrpc.HTLCAttempt.failure: object expected");
                message.failure = $root.lnrpc.Failure.fromObject(object.failure);
            }
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            return message;
        };

        /**
         * Creates a plain object from a HTLCAttempt message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.HTLCAttempt
         * @static
         * @param {lnrpc.HTLCAttempt} message HTLCAttempt
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HTLCAttempt.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = options.enums === String ? "IN_FLIGHT" : 0;
                object.route = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.attemptTimeNs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.attemptTimeNs = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.resolveTimeNs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.resolveTimeNs = options.longs === String ? "0" : 0;
                object.failure = null;
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.attemptId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.attemptId = options.longs === String ? "0" : 0;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.lnrpc.HTLCAttempt.HTLCStatus[message.status] : message.status;
            if (message.route != null && message.hasOwnProperty("route"))
                object.route = $root.lnrpc.Route.toObject(message.route, options);
            if (message.attemptTimeNs != null && message.hasOwnProperty("attemptTimeNs"))
                if (typeof message.attemptTimeNs === "number")
                    object.attemptTimeNs = options.longs === String ? String(message.attemptTimeNs) : message.attemptTimeNs;
                else
                    object.attemptTimeNs = options.longs === String ? $util.Long.prototype.toString.call(message.attemptTimeNs) : options.longs === Number ? new $util.LongBits(message.attemptTimeNs.low >>> 0, message.attemptTimeNs.high >>> 0).toNumber() : message.attemptTimeNs;
            if (message.resolveTimeNs != null && message.hasOwnProperty("resolveTimeNs"))
                if (typeof message.resolveTimeNs === "number")
                    object.resolveTimeNs = options.longs === String ? String(message.resolveTimeNs) : message.resolveTimeNs;
                else
                    object.resolveTimeNs = options.longs === String ? $util.Long.prototype.toString.call(message.resolveTimeNs) : options.longs === Number ? new $util.LongBits(message.resolveTimeNs.low >>> 0, message.resolveTimeNs.high >>> 0).toNumber() : message.resolveTimeNs;
            if (message.failure != null && message.hasOwnProperty("failure"))
                object.failure = $root.lnrpc.Failure.toObject(message.failure, options);
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            if (message.attemptId != null && message.hasOwnProperty("attemptId"))
                if (typeof message.attemptId === "number")
                    object.attemptId = options.longs === String ? String(message.attemptId) : message.attemptId;
                else
                    object.attemptId = options.longs === String ? $util.Long.prototype.toString.call(message.attemptId) : options.longs === Number ? new $util.LongBits(message.attemptId.low >>> 0, message.attemptId.high >>> 0).toNumber(true) : message.attemptId;
            return object;
        };

        /**
         * Converts this HTLCAttempt to JSON.
         * @function toJSON
         * @memberof lnrpc.HTLCAttempt
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HTLCAttempt.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * HTLCStatus enum.
         * @name lnrpc.HTLCAttempt.HTLCStatus
         * @enum {number}
         * @property {number} IN_FLIGHT=0 IN_FLIGHT value
         * @property {number} SUCCEEDED=1 SUCCEEDED value
         * @property {number} FAILED=2 FAILED value
         */
        HTLCAttempt.HTLCStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "IN_FLIGHT"] = 0;
            values[valuesById[1] = "SUCCEEDED"] = 1;
            values[valuesById[2] = "FAILED"] = 2;
            return values;
        })();

        return HTLCAttempt;
    })();

    lnrpc.ListPaymentsRequest = (function() {

        /**
         * Properties of a ListPaymentsRequest.
         * @memberof lnrpc
         * @interface IListPaymentsRequest
         * @property {boolean|null} [includeIncomplete] ListPaymentsRequest includeIncomplete
         * @property {Long|null} [indexOffset] ListPaymentsRequest indexOffset
         * @property {Long|null} [maxPayments] ListPaymentsRequest maxPayments
         * @property {boolean|null} [reversed] ListPaymentsRequest reversed
         * @property {boolean|null} [countTotalPayments] ListPaymentsRequest countTotalPayments
         */

        /**
         * Constructs a new ListPaymentsRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListPaymentsRequest.
         * @implements IListPaymentsRequest
         * @constructor
         * @param {lnrpc.IListPaymentsRequest=} [properties] Properties to set
         */
        function ListPaymentsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListPaymentsRequest includeIncomplete.
         * @member {boolean} includeIncomplete
         * @memberof lnrpc.ListPaymentsRequest
         * @instance
         */
        ListPaymentsRequest.prototype.includeIncomplete = false;

        /**
         * ListPaymentsRequest indexOffset.
         * @member {Long} indexOffset
         * @memberof lnrpc.ListPaymentsRequest
         * @instance
         */
        ListPaymentsRequest.prototype.indexOffset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListPaymentsRequest maxPayments.
         * @member {Long} maxPayments
         * @memberof lnrpc.ListPaymentsRequest
         * @instance
         */
        ListPaymentsRequest.prototype.maxPayments = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListPaymentsRequest reversed.
         * @member {boolean} reversed
         * @memberof lnrpc.ListPaymentsRequest
         * @instance
         */
        ListPaymentsRequest.prototype.reversed = false;

        /**
         * ListPaymentsRequest countTotalPayments.
         * @member {boolean} countTotalPayments
         * @memberof lnrpc.ListPaymentsRequest
         * @instance
         */
        ListPaymentsRequest.prototype.countTotalPayments = false;

        /**
         * Creates a new ListPaymentsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {lnrpc.IListPaymentsRequest=} [properties] Properties to set
         * @returns {lnrpc.ListPaymentsRequest} ListPaymentsRequest instance
         */
        ListPaymentsRequest.create = function create(properties) {
            return new ListPaymentsRequest(properties);
        };

        /**
         * Encodes the specified ListPaymentsRequest message. Does not implicitly {@link lnrpc.ListPaymentsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {lnrpc.IListPaymentsRequest} message ListPaymentsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPaymentsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeIncomplete != null && Object.hasOwnProperty.call(message, "includeIncomplete"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeIncomplete);
            if (message.indexOffset != null && Object.hasOwnProperty.call(message, "indexOffset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.indexOffset);
            if (message.maxPayments != null && Object.hasOwnProperty.call(message, "maxPayments"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.maxPayments);
            if (message.reversed != null && Object.hasOwnProperty.call(message, "reversed"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.reversed);
            if (message.countTotalPayments != null && Object.hasOwnProperty.call(message, "countTotalPayments"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.countTotalPayments);
            return writer;
        };

        /**
         * Encodes the specified ListPaymentsRequest message, length delimited. Does not implicitly {@link lnrpc.ListPaymentsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {lnrpc.IListPaymentsRequest} message ListPaymentsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPaymentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListPaymentsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListPaymentsRequest} ListPaymentsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPaymentsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListPaymentsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.includeIncomplete = reader.bool();
                        break;
                    }
                case 2: {
                        message.indexOffset = reader.uint64();
                        break;
                    }
                case 3: {
                        message.maxPayments = reader.uint64();
                        break;
                    }
                case 4: {
                        message.reversed = reader.bool();
                        break;
                    }
                case 5: {
                        message.countTotalPayments = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListPaymentsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListPaymentsRequest} ListPaymentsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPaymentsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListPaymentsRequest message.
         * @function verify
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListPaymentsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeIncomplete != null && message.hasOwnProperty("includeIncomplete"))
                if (typeof message.includeIncomplete !== "boolean")
                    return "includeIncomplete: boolean expected";
            if (message.indexOffset != null && message.hasOwnProperty("indexOffset"))
                if (!$util.isInteger(message.indexOffset) && !(message.indexOffset && $util.isInteger(message.indexOffset.low) && $util.isInteger(message.indexOffset.high)))
                    return "indexOffset: integer|Long expected";
            if (message.maxPayments != null && message.hasOwnProperty("maxPayments"))
                if (!$util.isInteger(message.maxPayments) && !(message.maxPayments && $util.isInteger(message.maxPayments.low) && $util.isInteger(message.maxPayments.high)))
                    return "maxPayments: integer|Long expected";
            if (message.reversed != null && message.hasOwnProperty("reversed"))
                if (typeof message.reversed !== "boolean")
                    return "reversed: boolean expected";
            if (message.countTotalPayments != null && message.hasOwnProperty("countTotalPayments"))
                if (typeof message.countTotalPayments !== "boolean")
                    return "countTotalPayments: boolean expected";
            return null;
        };

        /**
         * Creates a ListPaymentsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListPaymentsRequest} ListPaymentsRequest
         */
        ListPaymentsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListPaymentsRequest)
                return object;
            let message = new $root.lnrpc.ListPaymentsRequest();
            if (object.includeIncomplete != null)
                message.includeIncomplete = Boolean(object.includeIncomplete);
            if (object.indexOffset != null)
                if ($util.Long)
                    (message.indexOffset = $util.Long.fromValue(object.indexOffset)).unsigned = true;
                else if (typeof object.indexOffset === "string")
                    message.indexOffset = parseInt(object.indexOffset, 10);
                else if (typeof object.indexOffset === "number")
                    message.indexOffset = object.indexOffset;
                else if (typeof object.indexOffset === "object")
                    message.indexOffset = new $util.LongBits(object.indexOffset.low >>> 0, object.indexOffset.high >>> 0).toNumber(true);
            if (object.maxPayments != null)
                if ($util.Long)
                    (message.maxPayments = $util.Long.fromValue(object.maxPayments)).unsigned = true;
                else if (typeof object.maxPayments === "string")
                    message.maxPayments = parseInt(object.maxPayments, 10);
                else if (typeof object.maxPayments === "number")
                    message.maxPayments = object.maxPayments;
                else if (typeof object.maxPayments === "object")
                    message.maxPayments = new $util.LongBits(object.maxPayments.low >>> 0, object.maxPayments.high >>> 0).toNumber(true);
            if (object.reversed != null)
                message.reversed = Boolean(object.reversed);
            if (object.countTotalPayments != null)
                message.countTotalPayments = Boolean(object.countTotalPayments);
            return message;
        };

        /**
         * Creates a plain object from a ListPaymentsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListPaymentsRequest
         * @static
         * @param {lnrpc.ListPaymentsRequest} message ListPaymentsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListPaymentsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.includeIncomplete = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.indexOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.indexOffset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxPayments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxPayments = options.longs === String ? "0" : 0;
                object.reversed = false;
                object.countTotalPayments = false;
            }
            if (message.includeIncomplete != null && message.hasOwnProperty("includeIncomplete"))
                object.includeIncomplete = message.includeIncomplete;
            if (message.indexOffset != null && message.hasOwnProperty("indexOffset"))
                if (typeof message.indexOffset === "number")
                    object.indexOffset = options.longs === String ? String(message.indexOffset) : message.indexOffset;
                else
                    object.indexOffset = options.longs === String ? $util.Long.prototype.toString.call(message.indexOffset) : options.longs === Number ? new $util.LongBits(message.indexOffset.low >>> 0, message.indexOffset.high >>> 0).toNumber(true) : message.indexOffset;
            if (message.maxPayments != null && message.hasOwnProperty("maxPayments"))
                if (typeof message.maxPayments === "number")
                    object.maxPayments = options.longs === String ? String(message.maxPayments) : message.maxPayments;
                else
                    object.maxPayments = options.longs === String ? $util.Long.prototype.toString.call(message.maxPayments) : options.longs === Number ? new $util.LongBits(message.maxPayments.low >>> 0, message.maxPayments.high >>> 0).toNumber(true) : message.maxPayments;
            if (message.reversed != null && message.hasOwnProperty("reversed"))
                object.reversed = message.reversed;
            if (message.countTotalPayments != null && message.hasOwnProperty("countTotalPayments"))
                object.countTotalPayments = message.countTotalPayments;
            return object;
        };

        /**
         * Converts this ListPaymentsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListPaymentsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListPaymentsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListPaymentsRequest;
    })();

    lnrpc.ListPaymentsResponse = (function() {

        /**
         * Properties of a ListPaymentsResponse.
         * @memberof lnrpc
         * @interface IListPaymentsResponse
         * @property {Array.<lnrpc.IPayment>|null} [payments] ListPaymentsResponse payments
         * @property {Long|null} [firstIndexOffset] ListPaymentsResponse firstIndexOffset
         * @property {Long|null} [lastIndexOffset] ListPaymentsResponse lastIndexOffset
         * @property {Long|null} [totalNumPayments] ListPaymentsResponse totalNumPayments
         */

        /**
         * Constructs a new ListPaymentsResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListPaymentsResponse.
         * @implements IListPaymentsResponse
         * @constructor
         * @param {lnrpc.IListPaymentsResponse=} [properties] Properties to set
         */
        function ListPaymentsResponse(properties) {
            this.payments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListPaymentsResponse payments.
         * @member {Array.<lnrpc.IPayment>} payments
         * @memberof lnrpc.ListPaymentsResponse
         * @instance
         */
        ListPaymentsResponse.prototype.payments = $util.emptyArray;

        /**
         * ListPaymentsResponse firstIndexOffset.
         * @member {Long} firstIndexOffset
         * @memberof lnrpc.ListPaymentsResponse
         * @instance
         */
        ListPaymentsResponse.prototype.firstIndexOffset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListPaymentsResponse lastIndexOffset.
         * @member {Long} lastIndexOffset
         * @memberof lnrpc.ListPaymentsResponse
         * @instance
         */
        ListPaymentsResponse.prototype.lastIndexOffset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ListPaymentsResponse totalNumPayments.
         * @member {Long} totalNumPayments
         * @memberof lnrpc.ListPaymentsResponse
         * @instance
         */
        ListPaymentsResponse.prototype.totalNumPayments = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ListPaymentsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {lnrpc.IListPaymentsResponse=} [properties] Properties to set
         * @returns {lnrpc.ListPaymentsResponse} ListPaymentsResponse instance
         */
        ListPaymentsResponse.create = function create(properties) {
            return new ListPaymentsResponse(properties);
        };

        /**
         * Encodes the specified ListPaymentsResponse message. Does not implicitly {@link lnrpc.ListPaymentsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {lnrpc.IListPaymentsResponse} message ListPaymentsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPaymentsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.payments != null && message.payments.length)
                for (let i = 0; i < message.payments.length; ++i)
                    $root.lnrpc.Payment.encode(message.payments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.firstIndexOffset != null && Object.hasOwnProperty.call(message, "firstIndexOffset"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.firstIndexOffset);
            if (message.lastIndexOffset != null && Object.hasOwnProperty.call(message, "lastIndexOffset"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.lastIndexOffset);
            if (message.totalNumPayments != null && Object.hasOwnProperty.call(message, "totalNumPayments"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.totalNumPayments);
            return writer;
        };

        /**
         * Encodes the specified ListPaymentsResponse message, length delimited. Does not implicitly {@link lnrpc.ListPaymentsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {lnrpc.IListPaymentsResponse} message ListPaymentsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPaymentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListPaymentsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListPaymentsResponse} ListPaymentsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPaymentsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListPaymentsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.payments && message.payments.length))
                            message.payments = [];
                        message.payments.push($root.lnrpc.Payment.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.firstIndexOffset = reader.uint64();
                        break;
                    }
                case 3: {
                        message.lastIndexOffset = reader.uint64();
                        break;
                    }
                case 4: {
                        message.totalNumPayments = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListPaymentsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListPaymentsResponse} ListPaymentsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPaymentsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListPaymentsResponse message.
         * @function verify
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListPaymentsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.payments != null && message.hasOwnProperty("payments")) {
                if (!Array.isArray(message.payments))
                    return "payments: array expected";
                for (let i = 0; i < message.payments.length; ++i) {
                    let error = $root.lnrpc.Payment.verify(message.payments[i]);
                    if (error)
                        return "payments." + error;
                }
            }
            if (message.firstIndexOffset != null && message.hasOwnProperty("firstIndexOffset"))
                if (!$util.isInteger(message.firstIndexOffset) && !(message.firstIndexOffset && $util.isInteger(message.firstIndexOffset.low) && $util.isInteger(message.firstIndexOffset.high)))
                    return "firstIndexOffset: integer|Long expected";
            if (message.lastIndexOffset != null && message.hasOwnProperty("lastIndexOffset"))
                if (!$util.isInteger(message.lastIndexOffset) && !(message.lastIndexOffset && $util.isInteger(message.lastIndexOffset.low) && $util.isInteger(message.lastIndexOffset.high)))
                    return "lastIndexOffset: integer|Long expected";
            if (message.totalNumPayments != null && message.hasOwnProperty("totalNumPayments"))
                if (!$util.isInteger(message.totalNumPayments) && !(message.totalNumPayments && $util.isInteger(message.totalNumPayments.low) && $util.isInteger(message.totalNumPayments.high)))
                    return "totalNumPayments: integer|Long expected";
            return null;
        };

        /**
         * Creates a ListPaymentsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListPaymentsResponse} ListPaymentsResponse
         */
        ListPaymentsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListPaymentsResponse)
                return object;
            let message = new $root.lnrpc.ListPaymentsResponse();
            if (object.payments) {
                if (!Array.isArray(object.payments))
                    throw TypeError(".lnrpc.ListPaymentsResponse.payments: array expected");
                message.payments = [];
                for (let i = 0; i < object.payments.length; ++i) {
                    if (typeof object.payments[i] !== "object")
                        throw TypeError(".lnrpc.ListPaymentsResponse.payments: object expected");
                    message.payments[i] = $root.lnrpc.Payment.fromObject(object.payments[i]);
                }
            }
            if (object.firstIndexOffset != null)
                if ($util.Long)
                    (message.firstIndexOffset = $util.Long.fromValue(object.firstIndexOffset)).unsigned = true;
                else if (typeof object.firstIndexOffset === "string")
                    message.firstIndexOffset = parseInt(object.firstIndexOffset, 10);
                else if (typeof object.firstIndexOffset === "number")
                    message.firstIndexOffset = object.firstIndexOffset;
                else if (typeof object.firstIndexOffset === "object")
                    message.firstIndexOffset = new $util.LongBits(object.firstIndexOffset.low >>> 0, object.firstIndexOffset.high >>> 0).toNumber(true);
            if (object.lastIndexOffset != null)
                if ($util.Long)
                    (message.lastIndexOffset = $util.Long.fromValue(object.lastIndexOffset)).unsigned = true;
                else if (typeof object.lastIndexOffset === "string")
                    message.lastIndexOffset = parseInt(object.lastIndexOffset, 10);
                else if (typeof object.lastIndexOffset === "number")
                    message.lastIndexOffset = object.lastIndexOffset;
                else if (typeof object.lastIndexOffset === "object")
                    message.lastIndexOffset = new $util.LongBits(object.lastIndexOffset.low >>> 0, object.lastIndexOffset.high >>> 0).toNumber(true);
            if (object.totalNumPayments != null)
                if ($util.Long)
                    (message.totalNumPayments = $util.Long.fromValue(object.totalNumPayments)).unsigned = true;
                else if (typeof object.totalNumPayments === "string")
                    message.totalNumPayments = parseInt(object.totalNumPayments, 10);
                else if (typeof object.totalNumPayments === "number")
                    message.totalNumPayments = object.totalNumPayments;
                else if (typeof object.totalNumPayments === "object")
                    message.totalNumPayments = new $util.LongBits(object.totalNumPayments.low >>> 0, object.totalNumPayments.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ListPaymentsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListPaymentsResponse
         * @static
         * @param {lnrpc.ListPaymentsResponse} message ListPaymentsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListPaymentsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.payments = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.firstIndexOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.firstIndexOffset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.lastIndexOffset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lastIndexOffset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.totalNumPayments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalNumPayments = options.longs === String ? "0" : 0;
            }
            if (message.payments && message.payments.length) {
                object.payments = [];
                for (let j = 0; j < message.payments.length; ++j)
                    object.payments[j] = $root.lnrpc.Payment.toObject(message.payments[j], options);
            }
            if (message.firstIndexOffset != null && message.hasOwnProperty("firstIndexOffset"))
                if (typeof message.firstIndexOffset === "number")
                    object.firstIndexOffset = options.longs === String ? String(message.firstIndexOffset) : message.firstIndexOffset;
                else
                    object.firstIndexOffset = options.longs === String ? $util.Long.prototype.toString.call(message.firstIndexOffset) : options.longs === Number ? new $util.LongBits(message.firstIndexOffset.low >>> 0, message.firstIndexOffset.high >>> 0).toNumber(true) : message.firstIndexOffset;
            if (message.lastIndexOffset != null && message.hasOwnProperty("lastIndexOffset"))
                if (typeof message.lastIndexOffset === "number")
                    object.lastIndexOffset = options.longs === String ? String(message.lastIndexOffset) : message.lastIndexOffset;
                else
                    object.lastIndexOffset = options.longs === String ? $util.Long.prototype.toString.call(message.lastIndexOffset) : options.longs === Number ? new $util.LongBits(message.lastIndexOffset.low >>> 0, message.lastIndexOffset.high >>> 0).toNumber(true) : message.lastIndexOffset;
            if (message.totalNumPayments != null && message.hasOwnProperty("totalNumPayments"))
                if (typeof message.totalNumPayments === "number")
                    object.totalNumPayments = options.longs === String ? String(message.totalNumPayments) : message.totalNumPayments;
                else
                    object.totalNumPayments = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumPayments) : options.longs === Number ? new $util.LongBits(message.totalNumPayments.low >>> 0, message.totalNumPayments.high >>> 0).toNumber(true) : message.totalNumPayments;
            return object;
        };

        /**
         * Converts this ListPaymentsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListPaymentsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListPaymentsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListPaymentsResponse;
    })();

    lnrpc.DeletePaymentRequest = (function() {

        /**
         * Properties of a DeletePaymentRequest.
         * @memberof lnrpc
         * @interface IDeletePaymentRequest
         * @property {Uint8Array|null} [paymentHash] DeletePaymentRequest paymentHash
         * @property {boolean|null} [failedHtlcsOnly] DeletePaymentRequest failedHtlcsOnly
         */

        /**
         * Constructs a new DeletePaymentRequest.
         * @memberof lnrpc
         * @classdesc Represents a DeletePaymentRequest.
         * @implements IDeletePaymentRequest
         * @constructor
         * @param {lnrpc.IDeletePaymentRequest=} [properties] Properties to set
         */
        function DeletePaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeletePaymentRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof lnrpc.DeletePaymentRequest
         * @instance
         */
        DeletePaymentRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * DeletePaymentRequest failedHtlcsOnly.
         * @member {boolean} failedHtlcsOnly
         * @memberof lnrpc.DeletePaymentRequest
         * @instance
         */
        DeletePaymentRequest.prototype.failedHtlcsOnly = false;

        /**
         * Creates a new DeletePaymentRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {lnrpc.IDeletePaymentRequest=} [properties] Properties to set
         * @returns {lnrpc.DeletePaymentRequest} DeletePaymentRequest instance
         */
        DeletePaymentRequest.create = function create(properties) {
            return new DeletePaymentRequest(properties);
        };

        /**
         * Encodes the specified DeletePaymentRequest message. Does not implicitly {@link lnrpc.DeletePaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {lnrpc.IDeletePaymentRequest} message DeletePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.paymentHash);
            if (message.failedHtlcsOnly != null && Object.hasOwnProperty.call(message, "failedHtlcsOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.failedHtlcsOnly);
            return writer;
        };

        /**
         * Encodes the specified DeletePaymentRequest message, length delimited. Does not implicitly {@link lnrpc.DeletePaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {lnrpc.IDeletePaymentRequest} message DeletePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeletePaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DeletePaymentRequest} DeletePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DeletePaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.failedHtlcsOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeletePaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DeletePaymentRequest} DeletePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeletePaymentRequest message.
         * @function verify
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeletePaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.failedHtlcsOnly != null && message.hasOwnProperty("failedHtlcsOnly"))
                if (typeof message.failedHtlcsOnly !== "boolean")
                    return "failedHtlcsOnly: boolean expected";
            return null;
        };

        /**
         * Creates a DeletePaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DeletePaymentRequest} DeletePaymentRequest
         */
        DeletePaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DeletePaymentRequest)
                return object;
            let message = new $root.lnrpc.DeletePaymentRequest();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.failedHtlcsOnly != null)
                message.failedHtlcsOnly = Boolean(object.failedHtlcsOnly);
            return message;
        };

        /**
         * Creates a plain object from a DeletePaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DeletePaymentRequest
         * @static
         * @param {lnrpc.DeletePaymentRequest} message DeletePaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeletePaymentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                object.failedHtlcsOnly = false;
            }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.failedHtlcsOnly != null && message.hasOwnProperty("failedHtlcsOnly"))
                object.failedHtlcsOnly = message.failedHtlcsOnly;
            return object;
        };

        /**
         * Converts this DeletePaymentRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.DeletePaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeletePaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeletePaymentRequest;
    })();

    lnrpc.DeleteAllPaymentsRequest = (function() {

        /**
         * Properties of a DeleteAllPaymentsRequest.
         * @memberof lnrpc
         * @interface IDeleteAllPaymentsRequest
         * @property {boolean|null} [failedPaymentsOnly] DeleteAllPaymentsRequest failedPaymentsOnly
         * @property {boolean|null} [failedHtlcsOnly] DeleteAllPaymentsRequest failedHtlcsOnly
         */

        /**
         * Constructs a new DeleteAllPaymentsRequest.
         * @memberof lnrpc
         * @classdesc Represents a DeleteAllPaymentsRequest.
         * @implements IDeleteAllPaymentsRequest
         * @constructor
         * @param {lnrpc.IDeleteAllPaymentsRequest=} [properties] Properties to set
         */
        function DeleteAllPaymentsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteAllPaymentsRequest failedPaymentsOnly.
         * @member {boolean} failedPaymentsOnly
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @instance
         */
        DeleteAllPaymentsRequest.prototype.failedPaymentsOnly = false;

        /**
         * DeleteAllPaymentsRequest failedHtlcsOnly.
         * @member {boolean} failedHtlcsOnly
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @instance
         */
        DeleteAllPaymentsRequest.prototype.failedHtlcsOnly = false;

        /**
         * Creates a new DeleteAllPaymentsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {lnrpc.IDeleteAllPaymentsRequest=} [properties] Properties to set
         * @returns {lnrpc.DeleteAllPaymentsRequest} DeleteAllPaymentsRequest instance
         */
        DeleteAllPaymentsRequest.create = function create(properties) {
            return new DeleteAllPaymentsRequest(properties);
        };

        /**
         * Encodes the specified DeleteAllPaymentsRequest message. Does not implicitly {@link lnrpc.DeleteAllPaymentsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {lnrpc.IDeleteAllPaymentsRequest} message DeleteAllPaymentsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAllPaymentsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failedPaymentsOnly != null && Object.hasOwnProperty.call(message, "failedPaymentsOnly"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.failedPaymentsOnly);
            if (message.failedHtlcsOnly != null && Object.hasOwnProperty.call(message, "failedHtlcsOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.failedHtlcsOnly);
            return writer;
        };

        /**
         * Encodes the specified DeleteAllPaymentsRequest message, length delimited. Does not implicitly {@link lnrpc.DeleteAllPaymentsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {lnrpc.IDeleteAllPaymentsRequest} message DeleteAllPaymentsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAllPaymentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteAllPaymentsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DeleteAllPaymentsRequest} DeleteAllPaymentsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAllPaymentsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DeleteAllPaymentsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.failedPaymentsOnly = reader.bool();
                        break;
                    }
                case 2: {
                        message.failedHtlcsOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteAllPaymentsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DeleteAllPaymentsRequest} DeleteAllPaymentsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAllPaymentsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteAllPaymentsRequest message.
         * @function verify
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteAllPaymentsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.failedPaymentsOnly != null && message.hasOwnProperty("failedPaymentsOnly"))
                if (typeof message.failedPaymentsOnly !== "boolean")
                    return "failedPaymentsOnly: boolean expected";
            if (message.failedHtlcsOnly != null && message.hasOwnProperty("failedHtlcsOnly"))
                if (typeof message.failedHtlcsOnly !== "boolean")
                    return "failedHtlcsOnly: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteAllPaymentsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DeleteAllPaymentsRequest} DeleteAllPaymentsRequest
         */
        DeleteAllPaymentsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DeleteAllPaymentsRequest)
                return object;
            let message = new $root.lnrpc.DeleteAllPaymentsRequest();
            if (object.failedPaymentsOnly != null)
                message.failedPaymentsOnly = Boolean(object.failedPaymentsOnly);
            if (object.failedHtlcsOnly != null)
                message.failedHtlcsOnly = Boolean(object.failedHtlcsOnly);
            return message;
        };

        /**
         * Creates a plain object from a DeleteAllPaymentsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @static
         * @param {lnrpc.DeleteAllPaymentsRequest} message DeleteAllPaymentsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteAllPaymentsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.failedPaymentsOnly = false;
                object.failedHtlcsOnly = false;
            }
            if (message.failedPaymentsOnly != null && message.hasOwnProperty("failedPaymentsOnly"))
                object.failedPaymentsOnly = message.failedPaymentsOnly;
            if (message.failedHtlcsOnly != null && message.hasOwnProperty("failedHtlcsOnly"))
                object.failedHtlcsOnly = message.failedHtlcsOnly;
            return object;
        };

        /**
         * Converts this DeleteAllPaymentsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.DeleteAllPaymentsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteAllPaymentsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteAllPaymentsRequest;
    })();

    lnrpc.DeletePaymentResponse = (function() {

        /**
         * Properties of a DeletePaymentResponse.
         * @memberof lnrpc
         * @interface IDeletePaymentResponse
         */

        /**
         * Constructs a new DeletePaymentResponse.
         * @memberof lnrpc
         * @classdesc Represents a DeletePaymentResponse.
         * @implements IDeletePaymentResponse
         * @constructor
         * @param {lnrpc.IDeletePaymentResponse=} [properties] Properties to set
         */
        function DeletePaymentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeletePaymentResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {lnrpc.IDeletePaymentResponse=} [properties] Properties to set
         * @returns {lnrpc.DeletePaymentResponse} DeletePaymentResponse instance
         */
        DeletePaymentResponse.create = function create(properties) {
            return new DeletePaymentResponse(properties);
        };

        /**
         * Encodes the specified DeletePaymentResponse message. Does not implicitly {@link lnrpc.DeletePaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {lnrpc.IDeletePaymentResponse} message DeletePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeletePaymentResponse message, length delimited. Does not implicitly {@link lnrpc.DeletePaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {lnrpc.IDeletePaymentResponse} message DeletePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeletePaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeletePaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DeletePaymentResponse} DeletePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DeletePaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeletePaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DeletePaymentResponse} DeletePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeletePaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeletePaymentResponse message.
         * @function verify
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeletePaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeletePaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DeletePaymentResponse} DeletePaymentResponse
         */
        DeletePaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DeletePaymentResponse)
                return object;
            return new $root.lnrpc.DeletePaymentResponse();
        };

        /**
         * Creates a plain object from a DeletePaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DeletePaymentResponse
         * @static
         * @param {lnrpc.DeletePaymentResponse} message DeletePaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeletePaymentResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeletePaymentResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.DeletePaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeletePaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeletePaymentResponse;
    })();

    lnrpc.DeleteAllPaymentsResponse = (function() {

        /**
         * Properties of a DeleteAllPaymentsResponse.
         * @memberof lnrpc
         * @interface IDeleteAllPaymentsResponse
         */

        /**
         * Constructs a new DeleteAllPaymentsResponse.
         * @memberof lnrpc
         * @classdesc Represents a DeleteAllPaymentsResponse.
         * @implements IDeleteAllPaymentsResponse
         * @constructor
         * @param {lnrpc.IDeleteAllPaymentsResponse=} [properties] Properties to set
         */
        function DeleteAllPaymentsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteAllPaymentsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {lnrpc.IDeleteAllPaymentsResponse=} [properties] Properties to set
         * @returns {lnrpc.DeleteAllPaymentsResponse} DeleteAllPaymentsResponse instance
         */
        DeleteAllPaymentsResponse.create = function create(properties) {
            return new DeleteAllPaymentsResponse(properties);
        };

        /**
         * Encodes the specified DeleteAllPaymentsResponse message. Does not implicitly {@link lnrpc.DeleteAllPaymentsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {lnrpc.IDeleteAllPaymentsResponse} message DeleteAllPaymentsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAllPaymentsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteAllPaymentsResponse message, length delimited. Does not implicitly {@link lnrpc.DeleteAllPaymentsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {lnrpc.IDeleteAllPaymentsResponse} message DeleteAllPaymentsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAllPaymentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteAllPaymentsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DeleteAllPaymentsResponse} DeleteAllPaymentsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAllPaymentsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DeleteAllPaymentsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteAllPaymentsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DeleteAllPaymentsResponse} DeleteAllPaymentsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAllPaymentsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteAllPaymentsResponse message.
         * @function verify
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteAllPaymentsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteAllPaymentsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DeleteAllPaymentsResponse} DeleteAllPaymentsResponse
         */
        DeleteAllPaymentsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DeleteAllPaymentsResponse)
                return object;
            return new $root.lnrpc.DeleteAllPaymentsResponse();
        };

        /**
         * Creates a plain object from a DeleteAllPaymentsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @static
         * @param {lnrpc.DeleteAllPaymentsResponse} message DeleteAllPaymentsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteAllPaymentsResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteAllPaymentsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.DeleteAllPaymentsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteAllPaymentsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteAllPaymentsResponse;
    })();

    lnrpc.AbandonChannelRequest = (function() {

        /**
         * Properties of an AbandonChannelRequest.
         * @memberof lnrpc
         * @interface IAbandonChannelRequest
         * @property {lnrpc.IChannelPoint|null} [channelPoint] AbandonChannelRequest channelPoint
         * @property {boolean|null} [pendingFundingShimOnly] AbandonChannelRequest pendingFundingShimOnly
         * @property {boolean|null} [iKnowWhatIAmDoing] AbandonChannelRequest iKnowWhatIAmDoing
         */

        /**
         * Constructs a new AbandonChannelRequest.
         * @memberof lnrpc
         * @classdesc Represents an AbandonChannelRequest.
         * @implements IAbandonChannelRequest
         * @constructor
         * @param {lnrpc.IAbandonChannelRequest=} [properties] Properties to set
         */
        function AbandonChannelRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AbandonChannelRequest channelPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} channelPoint
         * @memberof lnrpc.AbandonChannelRequest
         * @instance
         */
        AbandonChannelRequest.prototype.channelPoint = null;

        /**
         * AbandonChannelRequest pendingFundingShimOnly.
         * @member {boolean} pendingFundingShimOnly
         * @memberof lnrpc.AbandonChannelRequest
         * @instance
         */
        AbandonChannelRequest.prototype.pendingFundingShimOnly = false;

        /**
         * AbandonChannelRequest iKnowWhatIAmDoing.
         * @member {boolean} iKnowWhatIAmDoing
         * @memberof lnrpc.AbandonChannelRequest
         * @instance
         */
        AbandonChannelRequest.prototype.iKnowWhatIAmDoing = false;

        /**
         * Creates a new AbandonChannelRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {lnrpc.IAbandonChannelRequest=} [properties] Properties to set
         * @returns {lnrpc.AbandonChannelRequest} AbandonChannelRequest instance
         */
        AbandonChannelRequest.create = function create(properties) {
            return new AbandonChannelRequest(properties);
        };

        /**
         * Encodes the specified AbandonChannelRequest message. Does not implicitly {@link lnrpc.AbandonChannelRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {lnrpc.IAbandonChannelRequest} message AbandonChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AbandonChannelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                $root.lnrpc.ChannelPoint.encode(message.channelPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pendingFundingShimOnly != null && Object.hasOwnProperty.call(message, "pendingFundingShimOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.pendingFundingShimOnly);
            if (message.iKnowWhatIAmDoing != null && Object.hasOwnProperty.call(message, "iKnowWhatIAmDoing"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.iKnowWhatIAmDoing);
            return writer;
        };

        /**
         * Encodes the specified AbandonChannelRequest message, length delimited. Does not implicitly {@link lnrpc.AbandonChannelRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {lnrpc.IAbandonChannelRequest} message AbandonChannelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AbandonChannelRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AbandonChannelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AbandonChannelRequest} AbandonChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AbandonChannelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AbandonChannelRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.channelPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.pendingFundingShimOnly = reader.bool();
                        break;
                    }
                case 3: {
                        message.iKnowWhatIAmDoing = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AbandonChannelRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AbandonChannelRequest} AbandonChannelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AbandonChannelRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AbandonChannelRequest message.
         * @function verify
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AbandonChannelRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.channelPoint);
                if (error)
                    return "channelPoint." + error;
            }
            if (message.pendingFundingShimOnly != null && message.hasOwnProperty("pendingFundingShimOnly"))
                if (typeof message.pendingFundingShimOnly !== "boolean")
                    return "pendingFundingShimOnly: boolean expected";
            if (message.iKnowWhatIAmDoing != null && message.hasOwnProperty("iKnowWhatIAmDoing"))
                if (typeof message.iKnowWhatIAmDoing !== "boolean")
                    return "iKnowWhatIAmDoing: boolean expected";
            return null;
        };

        /**
         * Creates an AbandonChannelRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AbandonChannelRequest} AbandonChannelRequest
         */
        AbandonChannelRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AbandonChannelRequest)
                return object;
            let message = new $root.lnrpc.AbandonChannelRequest();
            if (object.channelPoint != null) {
                if (typeof object.channelPoint !== "object")
                    throw TypeError(".lnrpc.AbandonChannelRequest.channelPoint: object expected");
                message.channelPoint = $root.lnrpc.ChannelPoint.fromObject(object.channelPoint);
            }
            if (object.pendingFundingShimOnly != null)
                message.pendingFundingShimOnly = Boolean(object.pendingFundingShimOnly);
            if (object.iKnowWhatIAmDoing != null)
                message.iKnowWhatIAmDoing = Boolean(object.iKnowWhatIAmDoing);
            return message;
        };

        /**
         * Creates a plain object from an AbandonChannelRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AbandonChannelRequest
         * @static
         * @param {lnrpc.AbandonChannelRequest} message AbandonChannelRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AbandonChannelRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.channelPoint = null;
                object.pendingFundingShimOnly = false;
                object.iKnowWhatIAmDoing = false;
            }
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                object.channelPoint = $root.lnrpc.ChannelPoint.toObject(message.channelPoint, options);
            if (message.pendingFundingShimOnly != null && message.hasOwnProperty("pendingFundingShimOnly"))
                object.pendingFundingShimOnly = message.pendingFundingShimOnly;
            if (message.iKnowWhatIAmDoing != null && message.hasOwnProperty("iKnowWhatIAmDoing"))
                object.iKnowWhatIAmDoing = message.iKnowWhatIAmDoing;
            return object;
        };

        /**
         * Converts this AbandonChannelRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.AbandonChannelRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AbandonChannelRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AbandonChannelRequest;
    })();

    lnrpc.AbandonChannelResponse = (function() {

        /**
         * Properties of an AbandonChannelResponse.
         * @memberof lnrpc
         * @interface IAbandonChannelResponse
         */

        /**
         * Constructs a new AbandonChannelResponse.
         * @memberof lnrpc
         * @classdesc Represents an AbandonChannelResponse.
         * @implements IAbandonChannelResponse
         * @constructor
         * @param {lnrpc.IAbandonChannelResponse=} [properties] Properties to set
         */
        function AbandonChannelResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AbandonChannelResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {lnrpc.IAbandonChannelResponse=} [properties] Properties to set
         * @returns {lnrpc.AbandonChannelResponse} AbandonChannelResponse instance
         */
        AbandonChannelResponse.create = function create(properties) {
            return new AbandonChannelResponse(properties);
        };

        /**
         * Encodes the specified AbandonChannelResponse message. Does not implicitly {@link lnrpc.AbandonChannelResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {lnrpc.IAbandonChannelResponse} message AbandonChannelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AbandonChannelResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AbandonChannelResponse message, length delimited. Does not implicitly {@link lnrpc.AbandonChannelResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {lnrpc.IAbandonChannelResponse} message AbandonChannelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AbandonChannelResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AbandonChannelResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.AbandonChannelResponse} AbandonChannelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AbandonChannelResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.AbandonChannelResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AbandonChannelResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.AbandonChannelResponse} AbandonChannelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AbandonChannelResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AbandonChannelResponse message.
         * @function verify
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AbandonChannelResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AbandonChannelResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.AbandonChannelResponse} AbandonChannelResponse
         */
        AbandonChannelResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.AbandonChannelResponse)
                return object;
            return new $root.lnrpc.AbandonChannelResponse();
        };

        /**
         * Creates a plain object from an AbandonChannelResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.AbandonChannelResponse
         * @static
         * @param {lnrpc.AbandonChannelResponse} message AbandonChannelResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AbandonChannelResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AbandonChannelResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.AbandonChannelResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AbandonChannelResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AbandonChannelResponse;
    })();

    lnrpc.DebugLevelRequest = (function() {

        /**
         * Properties of a DebugLevelRequest.
         * @memberof lnrpc
         * @interface IDebugLevelRequest
         * @property {boolean|null} [show] DebugLevelRequest show
         * @property {string|null} [levelSpec] DebugLevelRequest levelSpec
         */

        /**
         * Constructs a new DebugLevelRequest.
         * @memberof lnrpc
         * @classdesc Represents a DebugLevelRequest.
         * @implements IDebugLevelRequest
         * @constructor
         * @param {lnrpc.IDebugLevelRequest=} [properties] Properties to set
         */
        function DebugLevelRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DebugLevelRequest show.
         * @member {boolean} show
         * @memberof lnrpc.DebugLevelRequest
         * @instance
         */
        DebugLevelRequest.prototype.show = false;

        /**
         * DebugLevelRequest levelSpec.
         * @member {string} levelSpec
         * @memberof lnrpc.DebugLevelRequest
         * @instance
         */
        DebugLevelRequest.prototype.levelSpec = "";

        /**
         * Creates a new DebugLevelRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {lnrpc.IDebugLevelRequest=} [properties] Properties to set
         * @returns {lnrpc.DebugLevelRequest} DebugLevelRequest instance
         */
        DebugLevelRequest.create = function create(properties) {
            return new DebugLevelRequest(properties);
        };

        /**
         * Encodes the specified DebugLevelRequest message. Does not implicitly {@link lnrpc.DebugLevelRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {lnrpc.IDebugLevelRequest} message DebugLevelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebugLevelRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.show != null && Object.hasOwnProperty.call(message, "show"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.show);
            if (message.levelSpec != null && Object.hasOwnProperty.call(message, "levelSpec"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.levelSpec);
            return writer;
        };

        /**
         * Encodes the specified DebugLevelRequest message, length delimited. Does not implicitly {@link lnrpc.DebugLevelRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {lnrpc.IDebugLevelRequest} message DebugLevelRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebugLevelRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DebugLevelRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DebugLevelRequest} DebugLevelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebugLevelRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DebugLevelRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.show = reader.bool();
                        break;
                    }
                case 2: {
                        message.levelSpec = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DebugLevelRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DebugLevelRequest} DebugLevelRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebugLevelRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DebugLevelRequest message.
         * @function verify
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DebugLevelRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.show != null && message.hasOwnProperty("show"))
                if (typeof message.show !== "boolean")
                    return "show: boolean expected";
            if (message.levelSpec != null && message.hasOwnProperty("levelSpec"))
                if (!$util.isString(message.levelSpec))
                    return "levelSpec: string expected";
            return null;
        };

        /**
         * Creates a DebugLevelRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DebugLevelRequest} DebugLevelRequest
         */
        DebugLevelRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DebugLevelRequest)
                return object;
            let message = new $root.lnrpc.DebugLevelRequest();
            if (object.show != null)
                message.show = Boolean(object.show);
            if (object.levelSpec != null)
                message.levelSpec = String(object.levelSpec);
            return message;
        };

        /**
         * Creates a plain object from a DebugLevelRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DebugLevelRequest
         * @static
         * @param {lnrpc.DebugLevelRequest} message DebugLevelRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DebugLevelRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.show = false;
                object.levelSpec = "";
            }
            if (message.show != null && message.hasOwnProperty("show"))
                object.show = message.show;
            if (message.levelSpec != null && message.hasOwnProperty("levelSpec"))
                object.levelSpec = message.levelSpec;
            return object;
        };

        /**
         * Converts this DebugLevelRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.DebugLevelRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DebugLevelRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DebugLevelRequest;
    })();

    lnrpc.DebugLevelResponse = (function() {

        /**
         * Properties of a DebugLevelResponse.
         * @memberof lnrpc
         * @interface IDebugLevelResponse
         * @property {string|null} [subSystems] DebugLevelResponse subSystems
         */

        /**
         * Constructs a new DebugLevelResponse.
         * @memberof lnrpc
         * @classdesc Represents a DebugLevelResponse.
         * @implements IDebugLevelResponse
         * @constructor
         * @param {lnrpc.IDebugLevelResponse=} [properties] Properties to set
         */
        function DebugLevelResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DebugLevelResponse subSystems.
         * @member {string} subSystems
         * @memberof lnrpc.DebugLevelResponse
         * @instance
         */
        DebugLevelResponse.prototype.subSystems = "";

        /**
         * Creates a new DebugLevelResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {lnrpc.IDebugLevelResponse=} [properties] Properties to set
         * @returns {lnrpc.DebugLevelResponse} DebugLevelResponse instance
         */
        DebugLevelResponse.create = function create(properties) {
            return new DebugLevelResponse(properties);
        };

        /**
         * Encodes the specified DebugLevelResponse message. Does not implicitly {@link lnrpc.DebugLevelResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {lnrpc.IDebugLevelResponse} message DebugLevelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebugLevelResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subSystems != null && Object.hasOwnProperty.call(message, "subSystems"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.subSystems);
            return writer;
        };

        /**
         * Encodes the specified DebugLevelResponse message, length delimited. Does not implicitly {@link lnrpc.DebugLevelResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {lnrpc.IDebugLevelResponse} message DebugLevelResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DebugLevelResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DebugLevelResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DebugLevelResponse} DebugLevelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebugLevelResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DebugLevelResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.subSystems = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DebugLevelResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DebugLevelResponse} DebugLevelResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DebugLevelResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DebugLevelResponse message.
         * @function verify
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DebugLevelResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.subSystems != null && message.hasOwnProperty("subSystems"))
                if (!$util.isString(message.subSystems))
                    return "subSystems: string expected";
            return null;
        };

        /**
         * Creates a DebugLevelResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DebugLevelResponse} DebugLevelResponse
         */
        DebugLevelResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DebugLevelResponse)
                return object;
            let message = new $root.lnrpc.DebugLevelResponse();
            if (object.subSystems != null)
                message.subSystems = String(object.subSystems);
            return message;
        };

        /**
         * Creates a plain object from a DebugLevelResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DebugLevelResponse
         * @static
         * @param {lnrpc.DebugLevelResponse} message DebugLevelResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DebugLevelResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.subSystems = "";
            if (message.subSystems != null && message.hasOwnProperty("subSystems"))
                object.subSystems = message.subSystems;
            return object;
        };

        /**
         * Converts this DebugLevelResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.DebugLevelResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DebugLevelResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DebugLevelResponse;
    })();

    lnrpc.PayReqString = (function() {

        /**
         * Properties of a PayReqString.
         * @memberof lnrpc
         * @interface IPayReqString
         * @property {string|null} [payReq] PayReqString payReq
         */

        /**
         * Constructs a new PayReqString.
         * @memberof lnrpc
         * @classdesc Represents a PayReqString.
         * @implements IPayReqString
         * @constructor
         * @param {lnrpc.IPayReqString=} [properties] Properties to set
         */
        function PayReqString(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PayReqString payReq.
         * @member {string} payReq
         * @memberof lnrpc.PayReqString
         * @instance
         */
        PayReqString.prototype.payReq = "";

        /**
         * Creates a new PayReqString instance using the specified properties.
         * @function create
         * @memberof lnrpc.PayReqString
         * @static
         * @param {lnrpc.IPayReqString=} [properties] Properties to set
         * @returns {lnrpc.PayReqString} PayReqString instance
         */
        PayReqString.create = function create(properties) {
            return new PayReqString(properties);
        };

        /**
         * Encodes the specified PayReqString message. Does not implicitly {@link lnrpc.PayReqString.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PayReqString
         * @static
         * @param {lnrpc.IPayReqString} message PayReqString message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PayReqString.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.payReq != null && Object.hasOwnProperty.call(message, "payReq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.payReq);
            return writer;
        };

        /**
         * Encodes the specified PayReqString message, length delimited. Does not implicitly {@link lnrpc.PayReqString.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PayReqString
         * @static
         * @param {lnrpc.IPayReqString} message PayReqString message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PayReqString.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PayReqString message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PayReqString
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PayReqString} PayReqString
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PayReqString.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PayReqString();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.payReq = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PayReqString message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PayReqString
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PayReqString} PayReqString
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PayReqString.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PayReqString message.
         * @function verify
         * @memberof lnrpc.PayReqString
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PayReqString.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.payReq != null && message.hasOwnProperty("payReq"))
                if (!$util.isString(message.payReq))
                    return "payReq: string expected";
            return null;
        };

        /**
         * Creates a PayReqString message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PayReqString
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PayReqString} PayReqString
         */
        PayReqString.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PayReqString)
                return object;
            let message = new $root.lnrpc.PayReqString();
            if (object.payReq != null)
                message.payReq = String(object.payReq);
            return message;
        };

        /**
         * Creates a plain object from a PayReqString message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PayReqString
         * @static
         * @param {lnrpc.PayReqString} message PayReqString
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PayReqString.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.payReq = "";
            if (message.payReq != null && message.hasOwnProperty("payReq"))
                object.payReq = message.payReq;
            return object;
        };

        /**
         * Converts this PayReqString to JSON.
         * @function toJSON
         * @memberof lnrpc.PayReqString
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PayReqString.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PayReqString;
    })();

    lnrpc.PayReq = (function() {

        /**
         * Properties of a PayReq.
         * @memberof lnrpc
         * @interface IPayReq
         * @property {string|null} [destination] PayReq destination
         * @property {string|null} [paymentHash] PayReq paymentHash
         * @property {Long|null} [numSatoshis] PayReq numSatoshis
         * @property {Long|null} [timestamp] PayReq timestamp
         * @property {Long|null} [expiry] PayReq expiry
         * @property {string|null} [description] PayReq description
         * @property {string|null} [descriptionHash] PayReq descriptionHash
         * @property {string|null} [fallbackAddr] PayReq fallbackAddr
         * @property {Long|null} [cltvExpiry] PayReq cltvExpiry
         * @property {Array.<lnrpc.IRouteHint>|null} [routeHints] PayReq routeHints
         * @property {Uint8Array|null} [paymentAddr] PayReq paymentAddr
         * @property {Long|null} [numMsat] PayReq numMsat
         * @property {Object.<string,lnrpc.IFeature>|null} [features] PayReq features
         */

        /**
         * Constructs a new PayReq.
         * @memberof lnrpc
         * @classdesc Represents a PayReq.
         * @implements IPayReq
         * @constructor
         * @param {lnrpc.IPayReq=} [properties] Properties to set
         */
        function PayReq(properties) {
            this.routeHints = [];
            this.features = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PayReq destination.
         * @member {string} destination
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.destination = "";

        /**
         * PayReq paymentHash.
         * @member {string} paymentHash
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.paymentHash = "";

        /**
         * PayReq numSatoshis.
         * @member {Long} numSatoshis
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.numSatoshis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PayReq timestamp.
         * @member {Long} timestamp
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PayReq expiry.
         * @member {Long} expiry
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.expiry = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PayReq description.
         * @member {string} description
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.description = "";

        /**
         * PayReq descriptionHash.
         * @member {string} descriptionHash
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.descriptionHash = "";

        /**
         * PayReq fallbackAddr.
         * @member {string} fallbackAddr
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.fallbackAddr = "";

        /**
         * PayReq cltvExpiry.
         * @member {Long} cltvExpiry
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.cltvExpiry = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PayReq routeHints.
         * @member {Array.<lnrpc.IRouteHint>} routeHints
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.routeHints = $util.emptyArray;

        /**
         * PayReq paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * PayReq numMsat.
         * @member {Long} numMsat
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.numMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PayReq features.
         * @member {Object.<string,lnrpc.IFeature>} features
         * @memberof lnrpc.PayReq
         * @instance
         */
        PayReq.prototype.features = $util.emptyObject;

        /**
         * Creates a new PayReq instance using the specified properties.
         * @function create
         * @memberof lnrpc.PayReq
         * @static
         * @param {lnrpc.IPayReq=} [properties] Properties to set
         * @returns {lnrpc.PayReq} PayReq instance
         */
        PayReq.create = function create(properties) {
            return new PayReq(properties);
        };

        /**
         * Encodes the specified PayReq message. Does not implicitly {@link lnrpc.PayReq.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PayReq
         * @static
         * @param {lnrpc.IPayReq} message PayReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PayReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.destination != null && Object.hasOwnProperty.call(message, "destination"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.destination);
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentHash);
            if (message.numSatoshis != null && Object.hasOwnProperty.call(message, "numSatoshis"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.numSatoshis);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.timestamp);
            if (message.expiry != null && Object.hasOwnProperty.call(message, "expiry"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.expiry);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.description);
            if (message.descriptionHash != null && Object.hasOwnProperty.call(message, "descriptionHash"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.descriptionHash);
            if (message.fallbackAddr != null && Object.hasOwnProperty.call(message, "fallbackAddr"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fallbackAddr);
            if (message.cltvExpiry != null && Object.hasOwnProperty.call(message, "cltvExpiry"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.cltvExpiry);
            if (message.routeHints != null && message.routeHints.length)
                for (let i = 0; i < message.routeHints.length; ++i)
                    $root.lnrpc.RouteHint.encode(message.routeHints[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.paymentAddr);
            if (message.numMsat != null && Object.hasOwnProperty.call(message, "numMsat"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.numMsat);
            if (message.features != null && Object.hasOwnProperty.call(message, "features"))
                for (let keys = Object.keys(message.features), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 13, wireType 2 =*/106).fork().uint32(/* id 1, wireType 0 =*/8).uint32(keys[i]);
                    $root.lnrpc.Feature.encode(message.features[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified PayReq message, length delimited. Does not implicitly {@link lnrpc.PayReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PayReq
         * @static
         * @param {lnrpc.IPayReq} message PayReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PayReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PayReq message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PayReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PayReq} PayReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PayReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PayReq(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.destination = reader.string();
                        break;
                    }
                case 2: {
                        message.paymentHash = reader.string();
                        break;
                    }
                case 3: {
                        message.numSatoshis = reader.int64();
                        break;
                    }
                case 4: {
                        message.timestamp = reader.int64();
                        break;
                    }
                case 5: {
                        message.expiry = reader.int64();
                        break;
                    }
                case 6: {
                        message.description = reader.string();
                        break;
                    }
                case 7: {
                        message.descriptionHash = reader.string();
                        break;
                    }
                case 8: {
                        message.fallbackAddr = reader.string();
                        break;
                    }
                case 9: {
                        message.cltvExpiry = reader.int64();
                        break;
                    }
                case 10: {
                        if (!(message.routeHints && message.routeHints.length))
                            message.routeHints = [];
                        message.routeHints.push($root.lnrpc.RouteHint.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                case 12: {
                        message.numMsat = reader.int64();
                        break;
                    }
                case 13: {
                        if (message.features === $util.emptyObject)
                            message.features = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint32();
                                break;
                            case 2:
                                value = $root.lnrpc.Feature.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.features[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PayReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PayReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PayReq} PayReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PayReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PayReq message.
         * @function verify
         * @memberof lnrpc.PayReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PayReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.destination != null && message.hasOwnProperty("destination"))
                if (!$util.isString(message.destination))
                    return "destination: string expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!$util.isString(message.paymentHash))
                    return "paymentHash: string expected";
            if (message.numSatoshis != null && message.hasOwnProperty("numSatoshis"))
                if (!$util.isInteger(message.numSatoshis) && !(message.numSatoshis && $util.isInteger(message.numSatoshis.low) && $util.isInteger(message.numSatoshis.high)))
                    return "numSatoshis: integer|Long expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (!$util.isInteger(message.expiry) && !(message.expiry && $util.isInteger(message.expiry.low) && $util.isInteger(message.expiry.high)))
                    return "expiry: integer|Long expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.descriptionHash != null && message.hasOwnProperty("descriptionHash"))
                if (!$util.isString(message.descriptionHash))
                    return "descriptionHash: string expected";
            if (message.fallbackAddr != null && message.hasOwnProperty("fallbackAddr"))
                if (!$util.isString(message.fallbackAddr))
                    return "fallbackAddr: string expected";
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (!$util.isInteger(message.cltvExpiry) && !(message.cltvExpiry && $util.isInteger(message.cltvExpiry.low) && $util.isInteger(message.cltvExpiry.high)))
                    return "cltvExpiry: integer|Long expected";
            if (message.routeHints != null && message.hasOwnProperty("routeHints")) {
                if (!Array.isArray(message.routeHints))
                    return "routeHints: array expected";
                for (let i = 0; i < message.routeHints.length; ++i) {
                    let error = $root.lnrpc.RouteHint.verify(message.routeHints[i]);
                    if (error)
                        return "routeHints." + error;
                }
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            if (message.numMsat != null && message.hasOwnProperty("numMsat"))
                if (!$util.isInteger(message.numMsat) && !(message.numMsat && $util.isInteger(message.numMsat.low) && $util.isInteger(message.numMsat.high)))
                    return "numMsat: integer|Long expected";
            if (message.features != null && message.hasOwnProperty("features")) {
                if (!$util.isObject(message.features))
                    return "features: object expected";
                let key = Object.keys(message.features);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key32Re.test(key[i]))
                        return "features: integer key{k:uint32} expected";
                    {
                        let error = $root.lnrpc.Feature.verify(message.features[key[i]]);
                        if (error)
                            return "features." + error;
                    }
                }
            }
            return null;
        };

        /**
         * Creates a PayReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PayReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PayReq} PayReq
         */
        PayReq.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PayReq)
                return object;
            let message = new $root.lnrpc.PayReq();
            if (object.destination != null)
                message.destination = String(object.destination);
            if (object.paymentHash != null)
                message.paymentHash = String(object.paymentHash);
            if (object.numSatoshis != null)
                if ($util.Long)
                    (message.numSatoshis = $util.Long.fromValue(object.numSatoshis)).unsigned = false;
                else if (typeof object.numSatoshis === "string")
                    message.numSatoshis = parseInt(object.numSatoshis, 10);
                else if (typeof object.numSatoshis === "number")
                    message.numSatoshis = object.numSatoshis;
                else if (typeof object.numSatoshis === "object")
                    message.numSatoshis = new $util.LongBits(object.numSatoshis.low >>> 0, object.numSatoshis.high >>> 0).toNumber();
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
            if (object.expiry != null)
                if ($util.Long)
                    (message.expiry = $util.Long.fromValue(object.expiry)).unsigned = false;
                else if (typeof object.expiry === "string")
                    message.expiry = parseInt(object.expiry, 10);
                else if (typeof object.expiry === "number")
                    message.expiry = object.expiry;
                else if (typeof object.expiry === "object")
                    message.expiry = new $util.LongBits(object.expiry.low >>> 0, object.expiry.high >>> 0).toNumber();
            if (object.description != null)
                message.description = String(object.description);
            if (object.descriptionHash != null)
                message.descriptionHash = String(object.descriptionHash);
            if (object.fallbackAddr != null)
                message.fallbackAddr = String(object.fallbackAddr);
            if (object.cltvExpiry != null)
                if ($util.Long)
                    (message.cltvExpiry = $util.Long.fromValue(object.cltvExpiry)).unsigned = false;
                else if (typeof object.cltvExpiry === "string")
                    message.cltvExpiry = parseInt(object.cltvExpiry, 10);
                else if (typeof object.cltvExpiry === "number")
                    message.cltvExpiry = object.cltvExpiry;
                else if (typeof object.cltvExpiry === "object")
                    message.cltvExpiry = new $util.LongBits(object.cltvExpiry.low >>> 0, object.cltvExpiry.high >>> 0).toNumber();
            if (object.routeHints) {
                if (!Array.isArray(object.routeHints))
                    throw TypeError(".lnrpc.PayReq.routeHints: array expected");
                message.routeHints = [];
                for (let i = 0; i < object.routeHints.length; ++i) {
                    if (typeof object.routeHints[i] !== "object")
                        throw TypeError(".lnrpc.PayReq.routeHints: object expected");
                    message.routeHints[i] = $root.lnrpc.RouteHint.fromObject(object.routeHints[i]);
                }
            }
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            if (object.numMsat != null)
                if ($util.Long)
                    (message.numMsat = $util.Long.fromValue(object.numMsat)).unsigned = false;
                else if (typeof object.numMsat === "string")
                    message.numMsat = parseInt(object.numMsat, 10);
                else if (typeof object.numMsat === "number")
                    message.numMsat = object.numMsat;
                else if (typeof object.numMsat === "object")
                    message.numMsat = new $util.LongBits(object.numMsat.low >>> 0, object.numMsat.high >>> 0).toNumber();
            if (object.features) {
                if (typeof object.features !== "object")
                    throw TypeError(".lnrpc.PayReq.features: object expected");
                message.features = {};
                for (let keys = Object.keys(object.features), i = 0; i < keys.length; ++i) {
                    if (typeof object.features[keys[i]] !== "object")
                        throw TypeError(".lnrpc.PayReq.features: object expected");
                    message.features[keys[i]] = $root.lnrpc.Feature.fromObject(object.features[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PayReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PayReq
         * @static
         * @param {lnrpc.PayReq} message PayReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PayReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.routeHints = [];
            if (options.objects || options.defaults)
                object.features = {};
            if (options.defaults) {
                object.destination = "";
                object.paymentHash = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.numSatoshis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.numSatoshis = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expiry = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiry = options.longs === String ? "0" : 0;
                object.description = "";
                object.descriptionHash = "";
                object.fallbackAddr = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.cltvExpiry = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cltvExpiry = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.numMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.numMsat = options.longs === String ? "0" : 0;
            }
            if (message.destination != null && message.hasOwnProperty("destination"))
                object.destination = message.destination;
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = message.paymentHash;
            if (message.numSatoshis != null && message.hasOwnProperty("numSatoshis"))
                if (typeof message.numSatoshis === "number")
                    object.numSatoshis = options.longs === String ? String(message.numSatoshis) : message.numSatoshis;
                else
                    object.numSatoshis = options.longs === String ? $util.Long.prototype.toString.call(message.numSatoshis) : options.longs === Number ? new $util.LongBits(message.numSatoshis.low >>> 0, message.numSatoshis.high >>> 0).toNumber() : message.numSatoshis;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (typeof message.expiry === "number")
                    object.expiry = options.longs === String ? String(message.expiry) : message.expiry;
                else
                    object.expiry = options.longs === String ? $util.Long.prototype.toString.call(message.expiry) : options.longs === Number ? new $util.LongBits(message.expiry.low >>> 0, message.expiry.high >>> 0).toNumber() : message.expiry;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.descriptionHash != null && message.hasOwnProperty("descriptionHash"))
                object.descriptionHash = message.descriptionHash;
            if (message.fallbackAddr != null && message.hasOwnProperty("fallbackAddr"))
                object.fallbackAddr = message.fallbackAddr;
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (typeof message.cltvExpiry === "number")
                    object.cltvExpiry = options.longs === String ? String(message.cltvExpiry) : message.cltvExpiry;
                else
                    object.cltvExpiry = options.longs === String ? $util.Long.prototype.toString.call(message.cltvExpiry) : options.longs === Number ? new $util.LongBits(message.cltvExpiry.low >>> 0, message.cltvExpiry.high >>> 0).toNumber() : message.cltvExpiry;
            if (message.routeHints && message.routeHints.length) {
                object.routeHints = [];
                for (let j = 0; j < message.routeHints.length; ++j)
                    object.routeHints[j] = $root.lnrpc.RouteHint.toObject(message.routeHints[j], options);
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            if (message.numMsat != null && message.hasOwnProperty("numMsat"))
                if (typeof message.numMsat === "number")
                    object.numMsat = options.longs === String ? String(message.numMsat) : message.numMsat;
                else
                    object.numMsat = options.longs === String ? $util.Long.prototype.toString.call(message.numMsat) : options.longs === Number ? new $util.LongBits(message.numMsat.low >>> 0, message.numMsat.high >>> 0).toNumber() : message.numMsat;
            let keys2;
            if (message.features && (keys2 = Object.keys(message.features)).length) {
                object.features = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.features[keys2[j]] = $root.lnrpc.Feature.toObject(message.features[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this PayReq to JSON.
         * @function toJSON
         * @memberof lnrpc.PayReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PayReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PayReq;
    })();

    /**
     * FeatureBit enum.
     * @name lnrpc.FeatureBit
     * @enum {number}
     * @property {number} DATALOSS_PROTECT_REQ=0 DATALOSS_PROTECT_REQ value
     * @property {number} DATALOSS_PROTECT_OPT=1 DATALOSS_PROTECT_OPT value
     * @property {number} INITIAL_ROUING_SYNC=3 INITIAL_ROUING_SYNC value
     * @property {number} UPFRONT_SHUTDOWN_SCRIPT_REQ=4 UPFRONT_SHUTDOWN_SCRIPT_REQ value
     * @property {number} UPFRONT_SHUTDOWN_SCRIPT_OPT=5 UPFRONT_SHUTDOWN_SCRIPT_OPT value
     * @property {number} GOSSIP_QUERIES_REQ=6 GOSSIP_QUERIES_REQ value
     * @property {number} GOSSIP_QUERIES_OPT=7 GOSSIP_QUERIES_OPT value
     * @property {number} TLV_ONION_REQ=8 TLV_ONION_REQ value
     * @property {number} TLV_ONION_OPT=9 TLV_ONION_OPT value
     * @property {number} EXT_GOSSIP_QUERIES_REQ=10 EXT_GOSSIP_QUERIES_REQ value
     * @property {number} EXT_GOSSIP_QUERIES_OPT=11 EXT_GOSSIP_QUERIES_OPT value
     * @property {number} STATIC_REMOTE_KEY_REQ=12 STATIC_REMOTE_KEY_REQ value
     * @property {number} STATIC_REMOTE_KEY_OPT=13 STATIC_REMOTE_KEY_OPT value
     * @property {number} PAYMENT_ADDR_REQ=14 PAYMENT_ADDR_REQ value
     * @property {number} PAYMENT_ADDR_OPT=15 PAYMENT_ADDR_OPT value
     * @property {number} MPP_REQ=16 MPP_REQ value
     * @property {number} MPP_OPT=17 MPP_OPT value
     * @property {number} WUMBO_CHANNELS_REQ=18 WUMBO_CHANNELS_REQ value
     * @property {number} WUMBO_CHANNELS_OPT=19 WUMBO_CHANNELS_OPT value
     * @property {number} ANCHORS_REQ=20 ANCHORS_REQ value
     * @property {number} ANCHORS_OPT=21 ANCHORS_OPT value
     * @property {number} ANCHORS_ZERO_FEE_HTLC_REQ=22 ANCHORS_ZERO_FEE_HTLC_REQ value
     * @property {number} ANCHORS_ZERO_FEE_HTLC_OPT=23 ANCHORS_ZERO_FEE_HTLC_OPT value
     * @property {number} AMP_REQ=30 AMP_REQ value
     * @property {number} AMP_OPT=31 AMP_OPT value
     */
    lnrpc.FeatureBit = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DATALOSS_PROTECT_REQ"] = 0;
        values[valuesById[1] = "DATALOSS_PROTECT_OPT"] = 1;
        values[valuesById[3] = "INITIAL_ROUING_SYNC"] = 3;
        values[valuesById[4] = "UPFRONT_SHUTDOWN_SCRIPT_REQ"] = 4;
        values[valuesById[5] = "UPFRONT_SHUTDOWN_SCRIPT_OPT"] = 5;
        values[valuesById[6] = "GOSSIP_QUERIES_REQ"] = 6;
        values[valuesById[7] = "GOSSIP_QUERIES_OPT"] = 7;
        values[valuesById[8] = "TLV_ONION_REQ"] = 8;
        values[valuesById[9] = "TLV_ONION_OPT"] = 9;
        values[valuesById[10] = "EXT_GOSSIP_QUERIES_REQ"] = 10;
        values[valuesById[11] = "EXT_GOSSIP_QUERIES_OPT"] = 11;
        values[valuesById[12] = "STATIC_REMOTE_KEY_REQ"] = 12;
        values[valuesById[13] = "STATIC_REMOTE_KEY_OPT"] = 13;
        values[valuesById[14] = "PAYMENT_ADDR_REQ"] = 14;
        values[valuesById[15] = "PAYMENT_ADDR_OPT"] = 15;
        values[valuesById[16] = "MPP_REQ"] = 16;
        values[valuesById[17] = "MPP_OPT"] = 17;
        values[valuesById[18] = "WUMBO_CHANNELS_REQ"] = 18;
        values[valuesById[19] = "WUMBO_CHANNELS_OPT"] = 19;
        values[valuesById[20] = "ANCHORS_REQ"] = 20;
        values[valuesById[21] = "ANCHORS_OPT"] = 21;
        values[valuesById[22] = "ANCHORS_ZERO_FEE_HTLC_REQ"] = 22;
        values[valuesById[23] = "ANCHORS_ZERO_FEE_HTLC_OPT"] = 23;
        values[valuesById[30] = "AMP_REQ"] = 30;
        values[valuesById[31] = "AMP_OPT"] = 31;
        return values;
    })();

    lnrpc.Feature = (function() {

        /**
         * Properties of a Feature.
         * @memberof lnrpc
         * @interface IFeature
         * @property {string|null} [name] Feature name
         * @property {boolean|null} [isRequired] Feature isRequired
         * @property {boolean|null} [isKnown] Feature isKnown
         */

        /**
         * Constructs a new Feature.
         * @memberof lnrpc
         * @classdesc Represents a Feature.
         * @implements IFeature
         * @constructor
         * @param {lnrpc.IFeature=} [properties] Properties to set
         */
        function Feature(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Feature name.
         * @member {string} name
         * @memberof lnrpc.Feature
         * @instance
         */
        Feature.prototype.name = "";

        /**
         * Feature isRequired.
         * @member {boolean} isRequired
         * @memberof lnrpc.Feature
         * @instance
         */
        Feature.prototype.isRequired = false;

        /**
         * Feature isKnown.
         * @member {boolean} isKnown
         * @memberof lnrpc.Feature
         * @instance
         */
        Feature.prototype.isKnown = false;

        /**
         * Creates a new Feature instance using the specified properties.
         * @function create
         * @memberof lnrpc.Feature
         * @static
         * @param {lnrpc.IFeature=} [properties] Properties to set
         * @returns {lnrpc.Feature} Feature instance
         */
        Feature.create = function create(properties) {
            return new Feature(properties);
        };

        /**
         * Encodes the specified Feature message. Does not implicitly {@link lnrpc.Feature.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Feature
         * @static
         * @param {lnrpc.IFeature} message Feature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Feature.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isRequired);
            if (message.isKnown != null && Object.hasOwnProperty.call(message, "isKnown"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isKnown);
            return writer;
        };

        /**
         * Encodes the specified Feature message, length delimited. Does not implicitly {@link lnrpc.Feature.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Feature
         * @static
         * @param {lnrpc.IFeature} message Feature message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Feature.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Feature message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Feature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Feature} Feature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Feature.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Feature();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.isRequired = reader.bool();
                        break;
                    }
                case 4: {
                        message.isKnown = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Feature message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Feature
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Feature} Feature
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Feature.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Feature message.
         * @function verify
         * @memberof lnrpc.Feature
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Feature.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                if (typeof message.isRequired !== "boolean")
                    return "isRequired: boolean expected";
            if (message.isKnown != null && message.hasOwnProperty("isKnown"))
                if (typeof message.isKnown !== "boolean")
                    return "isKnown: boolean expected";
            return null;
        };

        /**
         * Creates a Feature message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Feature
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Feature} Feature
         */
        Feature.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Feature)
                return object;
            let message = new $root.lnrpc.Feature();
            if (object.name != null)
                message.name = String(object.name);
            if (object.isRequired != null)
                message.isRequired = Boolean(object.isRequired);
            if (object.isKnown != null)
                message.isKnown = Boolean(object.isKnown);
            return message;
        };

        /**
         * Creates a plain object from a Feature message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Feature
         * @static
         * @param {lnrpc.Feature} message Feature
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Feature.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.isRequired = false;
                object.isKnown = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                object.isRequired = message.isRequired;
            if (message.isKnown != null && message.hasOwnProperty("isKnown"))
                object.isKnown = message.isKnown;
            return object;
        };

        /**
         * Converts this Feature to JSON.
         * @function toJSON
         * @memberof lnrpc.Feature
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Feature.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Feature;
    })();

    lnrpc.FeeReportRequest = (function() {

        /**
         * Properties of a FeeReportRequest.
         * @memberof lnrpc
         * @interface IFeeReportRequest
         */

        /**
         * Constructs a new FeeReportRequest.
         * @memberof lnrpc
         * @classdesc Represents a FeeReportRequest.
         * @implements IFeeReportRequest
         * @constructor
         * @param {lnrpc.IFeeReportRequest=} [properties] Properties to set
         */
        function FeeReportRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new FeeReportRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {lnrpc.IFeeReportRequest=} [properties] Properties to set
         * @returns {lnrpc.FeeReportRequest} FeeReportRequest instance
         */
        FeeReportRequest.create = function create(properties) {
            return new FeeReportRequest(properties);
        };

        /**
         * Encodes the specified FeeReportRequest message. Does not implicitly {@link lnrpc.FeeReportRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {lnrpc.IFeeReportRequest} message FeeReportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeeReportRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified FeeReportRequest message, length delimited. Does not implicitly {@link lnrpc.FeeReportRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {lnrpc.IFeeReportRequest} message FeeReportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeeReportRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FeeReportRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FeeReportRequest} FeeReportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeeReportRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FeeReportRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FeeReportRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FeeReportRequest} FeeReportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeeReportRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FeeReportRequest message.
         * @function verify
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FeeReportRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a FeeReportRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FeeReportRequest} FeeReportRequest
         */
        FeeReportRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FeeReportRequest)
                return object;
            return new $root.lnrpc.FeeReportRequest();
        };

        /**
         * Creates a plain object from a FeeReportRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FeeReportRequest
         * @static
         * @param {lnrpc.FeeReportRequest} message FeeReportRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FeeReportRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this FeeReportRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.FeeReportRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FeeReportRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FeeReportRequest;
    })();

    lnrpc.ChannelFeeReport = (function() {

        /**
         * Properties of a ChannelFeeReport.
         * @memberof lnrpc
         * @interface IChannelFeeReport
         * @property {Long|null} [chanId] ChannelFeeReport chanId
         * @property {string|null} [channelPoint] ChannelFeeReport channelPoint
         * @property {Long|null} [baseFeeMsat] ChannelFeeReport baseFeeMsat
         * @property {Long|null} [feePerMil] ChannelFeeReport feePerMil
         * @property {number|null} [feeRate] ChannelFeeReport feeRate
         */

        /**
         * Constructs a new ChannelFeeReport.
         * @memberof lnrpc
         * @classdesc Represents a ChannelFeeReport.
         * @implements IChannelFeeReport
         * @constructor
         * @param {lnrpc.IChannelFeeReport=} [properties] Properties to set
         */
        function ChannelFeeReport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelFeeReport chanId.
         * @member {Long} chanId
         * @memberof lnrpc.ChannelFeeReport
         * @instance
         */
        ChannelFeeReport.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelFeeReport channelPoint.
         * @member {string} channelPoint
         * @memberof lnrpc.ChannelFeeReport
         * @instance
         */
        ChannelFeeReport.prototype.channelPoint = "";

        /**
         * ChannelFeeReport baseFeeMsat.
         * @member {Long} baseFeeMsat
         * @memberof lnrpc.ChannelFeeReport
         * @instance
         */
        ChannelFeeReport.prototype.baseFeeMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelFeeReport feePerMil.
         * @member {Long} feePerMil
         * @memberof lnrpc.ChannelFeeReport
         * @instance
         */
        ChannelFeeReport.prototype.feePerMil = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ChannelFeeReport feeRate.
         * @member {number} feeRate
         * @memberof lnrpc.ChannelFeeReport
         * @instance
         */
        ChannelFeeReport.prototype.feeRate = 0;

        /**
         * Creates a new ChannelFeeReport instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {lnrpc.IChannelFeeReport=} [properties] Properties to set
         * @returns {lnrpc.ChannelFeeReport} ChannelFeeReport instance
         */
        ChannelFeeReport.create = function create(properties) {
            return new ChannelFeeReport(properties);
        };

        /**
         * Encodes the specified ChannelFeeReport message. Does not implicitly {@link lnrpc.ChannelFeeReport.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {lnrpc.IChannelFeeReport} message ChannelFeeReport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelFeeReport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelPoint != null && Object.hasOwnProperty.call(message, "channelPoint"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelPoint);
            if (message.baseFeeMsat != null && Object.hasOwnProperty.call(message, "baseFeeMsat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.baseFeeMsat);
            if (message.feePerMil != null && Object.hasOwnProperty.call(message, "feePerMil"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.feePerMil);
            if (message.feeRate != null && Object.hasOwnProperty.call(message, "feeRate"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.feeRate);
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.chanId);
            return writer;
        };

        /**
         * Encodes the specified ChannelFeeReport message, length delimited. Does not implicitly {@link lnrpc.ChannelFeeReport.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {lnrpc.IChannelFeeReport} message ChannelFeeReport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelFeeReport.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelFeeReport message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelFeeReport} ChannelFeeReport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelFeeReport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelFeeReport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 1: {
                        message.channelPoint = reader.string();
                        break;
                    }
                case 2: {
                        message.baseFeeMsat = reader.int64();
                        break;
                    }
                case 3: {
                        message.feePerMil = reader.int64();
                        break;
                    }
                case 4: {
                        message.feeRate = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelFeeReport message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelFeeReport} ChannelFeeReport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelFeeReport.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelFeeReport message.
         * @function verify
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelFeeReport.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                if (!$util.isString(message.channelPoint))
                    return "channelPoint: string expected";
            if (message.baseFeeMsat != null && message.hasOwnProperty("baseFeeMsat"))
                if (!$util.isInteger(message.baseFeeMsat) && !(message.baseFeeMsat && $util.isInteger(message.baseFeeMsat.low) && $util.isInteger(message.baseFeeMsat.high)))
                    return "baseFeeMsat: integer|Long expected";
            if (message.feePerMil != null && message.hasOwnProperty("feePerMil"))
                if (!$util.isInteger(message.feePerMil) && !(message.feePerMil && $util.isInteger(message.feePerMil.low) && $util.isInteger(message.feePerMil.high)))
                    return "feePerMil: integer|Long expected";
            if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                if (typeof message.feeRate !== "number")
                    return "feeRate: number expected";
            return null;
        };

        /**
         * Creates a ChannelFeeReport message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelFeeReport} ChannelFeeReport
         */
        ChannelFeeReport.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelFeeReport)
                return object;
            let message = new $root.lnrpc.ChannelFeeReport();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.channelPoint != null)
                message.channelPoint = String(object.channelPoint);
            if (object.baseFeeMsat != null)
                if ($util.Long)
                    (message.baseFeeMsat = $util.Long.fromValue(object.baseFeeMsat)).unsigned = false;
                else if (typeof object.baseFeeMsat === "string")
                    message.baseFeeMsat = parseInt(object.baseFeeMsat, 10);
                else if (typeof object.baseFeeMsat === "number")
                    message.baseFeeMsat = object.baseFeeMsat;
                else if (typeof object.baseFeeMsat === "object")
                    message.baseFeeMsat = new $util.LongBits(object.baseFeeMsat.low >>> 0, object.baseFeeMsat.high >>> 0).toNumber();
            if (object.feePerMil != null)
                if ($util.Long)
                    (message.feePerMil = $util.Long.fromValue(object.feePerMil)).unsigned = false;
                else if (typeof object.feePerMil === "string")
                    message.feePerMil = parseInt(object.feePerMil, 10);
                else if (typeof object.feePerMil === "number")
                    message.feePerMil = object.feePerMil;
                else if (typeof object.feePerMil === "object")
                    message.feePerMil = new $util.LongBits(object.feePerMil.low >>> 0, object.feePerMil.high >>> 0).toNumber();
            if (object.feeRate != null)
                message.feeRate = Number(object.feeRate);
            return message;
        };

        /**
         * Creates a plain object from a ChannelFeeReport message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelFeeReport
         * @static
         * @param {lnrpc.ChannelFeeReport} message ChannelFeeReport
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelFeeReport.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.channelPoint = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.baseFeeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.baseFeeMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feePerMil = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feePerMil = options.longs === String ? "0" : 0;
                object.feeRate = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
            }
            if (message.channelPoint != null && message.hasOwnProperty("channelPoint"))
                object.channelPoint = message.channelPoint;
            if (message.baseFeeMsat != null && message.hasOwnProperty("baseFeeMsat"))
                if (typeof message.baseFeeMsat === "number")
                    object.baseFeeMsat = options.longs === String ? String(message.baseFeeMsat) : message.baseFeeMsat;
                else
                    object.baseFeeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.baseFeeMsat) : options.longs === Number ? new $util.LongBits(message.baseFeeMsat.low >>> 0, message.baseFeeMsat.high >>> 0).toNumber() : message.baseFeeMsat;
            if (message.feePerMil != null && message.hasOwnProperty("feePerMil"))
                if (typeof message.feePerMil === "number")
                    object.feePerMil = options.longs === String ? String(message.feePerMil) : message.feePerMil;
                else
                    object.feePerMil = options.longs === String ? $util.Long.prototype.toString.call(message.feePerMil) : options.longs === Number ? new $util.LongBits(message.feePerMil.low >>> 0, message.feePerMil.high >>> 0).toNumber() : message.feePerMil;
            if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                object.feeRate = options.json && !isFinite(message.feeRate) ? String(message.feeRate) : message.feeRate;
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            return object;
        };

        /**
         * Converts this ChannelFeeReport to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelFeeReport
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelFeeReport.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelFeeReport;
    })();

    lnrpc.FeeReportResponse = (function() {

        /**
         * Properties of a FeeReportResponse.
         * @memberof lnrpc
         * @interface IFeeReportResponse
         * @property {Array.<lnrpc.IChannelFeeReport>|null} [channelFees] FeeReportResponse channelFees
         * @property {Long|null} [dayFeeSum] FeeReportResponse dayFeeSum
         * @property {Long|null} [weekFeeSum] FeeReportResponse weekFeeSum
         * @property {Long|null} [monthFeeSum] FeeReportResponse monthFeeSum
         */

        /**
         * Constructs a new FeeReportResponse.
         * @memberof lnrpc
         * @classdesc Represents a FeeReportResponse.
         * @implements IFeeReportResponse
         * @constructor
         * @param {lnrpc.IFeeReportResponse=} [properties] Properties to set
         */
        function FeeReportResponse(properties) {
            this.channelFees = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FeeReportResponse channelFees.
         * @member {Array.<lnrpc.IChannelFeeReport>} channelFees
         * @memberof lnrpc.FeeReportResponse
         * @instance
         */
        FeeReportResponse.prototype.channelFees = $util.emptyArray;

        /**
         * FeeReportResponse dayFeeSum.
         * @member {Long} dayFeeSum
         * @memberof lnrpc.FeeReportResponse
         * @instance
         */
        FeeReportResponse.prototype.dayFeeSum = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * FeeReportResponse weekFeeSum.
         * @member {Long} weekFeeSum
         * @memberof lnrpc.FeeReportResponse
         * @instance
         */
        FeeReportResponse.prototype.weekFeeSum = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * FeeReportResponse monthFeeSum.
         * @member {Long} monthFeeSum
         * @memberof lnrpc.FeeReportResponse
         * @instance
         */
        FeeReportResponse.prototype.monthFeeSum = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new FeeReportResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {lnrpc.IFeeReportResponse=} [properties] Properties to set
         * @returns {lnrpc.FeeReportResponse} FeeReportResponse instance
         */
        FeeReportResponse.create = function create(properties) {
            return new FeeReportResponse(properties);
        };

        /**
         * Encodes the specified FeeReportResponse message. Does not implicitly {@link lnrpc.FeeReportResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {lnrpc.IFeeReportResponse} message FeeReportResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeeReportResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.channelFees != null && message.channelFees.length)
                for (let i = 0; i < message.channelFees.length; ++i)
                    $root.lnrpc.ChannelFeeReport.encode(message.channelFees[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dayFeeSum != null && Object.hasOwnProperty.call(message, "dayFeeSum"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.dayFeeSum);
            if (message.weekFeeSum != null && Object.hasOwnProperty.call(message, "weekFeeSum"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.weekFeeSum);
            if (message.monthFeeSum != null && Object.hasOwnProperty.call(message, "monthFeeSum"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.monthFeeSum);
            return writer;
        };

        /**
         * Encodes the specified FeeReportResponse message, length delimited. Does not implicitly {@link lnrpc.FeeReportResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {lnrpc.IFeeReportResponse} message FeeReportResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FeeReportResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FeeReportResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FeeReportResponse} FeeReportResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeeReportResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FeeReportResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.channelFees && message.channelFees.length))
                            message.channelFees = [];
                        message.channelFees.push($root.lnrpc.ChannelFeeReport.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.dayFeeSum = reader.uint64();
                        break;
                    }
                case 3: {
                        message.weekFeeSum = reader.uint64();
                        break;
                    }
                case 4: {
                        message.monthFeeSum = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FeeReportResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FeeReportResponse} FeeReportResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FeeReportResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FeeReportResponse message.
         * @function verify
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FeeReportResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.channelFees != null && message.hasOwnProperty("channelFees")) {
                if (!Array.isArray(message.channelFees))
                    return "channelFees: array expected";
                for (let i = 0; i < message.channelFees.length; ++i) {
                    let error = $root.lnrpc.ChannelFeeReport.verify(message.channelFees[i]);
                    if (error)
                        return "channelFees." + error;
                }
            }
            if (message.dayFeeSum != null && message.hasOwnProperty("dayFeeSum"))
                if (!$util.isInteger(message.dayFeeSum) && !(message.dayFeeSum && $util.isInteger(message.dayFeeSum.low) && $util.isInteger(message.dayFeeSum.high)))
                    return "dayFeeSum: integer|Long expected";
            if (message.weekFeeSum != null && message.hasOwnProperty("weekFeeSum"))
                if (!$util.isInteger(message.weekFeeSum) && !(message.weekFeeSum && $util.isInteger(message.weekFeeSum.low) && $util.isInteger(message.weekFeeSum.high)))
                    return "weekFeeSum: integer|Long expected";
            if (message.monthFeeSum != null && message.hasOwnProperty("monthFeeSum"))
                if (!$util.isInteger(message.monthFeeSum) && !(message.monthFeeSum && $util.isInteger(message.monthFeeSum.low) && $util.isInteger(message.monthFeeSum.high)))
                    return "monthFeeSum: integer|Long expected";
            return null;
        };

        /**
         * Creates a FeeReportResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FeeReportResponse} FeeReportResponse
         */
        FeeReportResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FeeReportResponse)
                return object;
            let message = new $root.lnrpc.FeeReportResponse();
            if (object.channelFees) {
                if (!Array.isArray(object.channelFees))
                    throw TypeError(".lnrpc.FeeReportResponse.channelFees: array expected");
                message.channelFees = [];
                for (let i = 0; i < object.channelFees.length; ++i) {
                    if (typeof object.channelFees[i] !== "object")
                        throw TypeError(".lnrpc.FeeReportResponse.channelFees: object expected");
                    message.channelFees[i] = $root.lnrpc.ChannelFeeReport.fromObject(object.channelFees[i]);
                }
            }
            if (object.dayFeeSum != null)
                if ($util.Long)
                    (message.dayFeeSum = $util.Long.fromValue(object.dayFeeSum)).unsigned = true;
                else if (typeof object.dayFeeSum === "string")
                    message.dayFeeSum = parseInt(object.dayFeeSum, 10);
                else if (typeof object.dayFeeSum === "number")
                    message.dayFeeSum = object.dayFeeSum;
                else if (typeof object.dayFeeSum === "object")
                    message.dayFeeSum = new $util.LongBits(object.dayFeeSum.low >>> 0, object.dayFeeSum.high >>> 0).toNumber(true);
            if (object.weekFeeSum != null)
                if ($util.Long)
                    (message.weekFeeSum = $util.Long.fromValue(object.weekFeeSum)).unsigned = true;
                else if (typeof object.weekFeeSum === "string")
                    message.weekFeeSum = parseInt(object.weekFeeSum, 10);
                else if (typeof object.weekFeeSum === "number")
                    message.weekFeeSum = object.weekFeeSum;
                else if (typeof object.weekFeeSum === "object")
                    message.weekFeeSum = new $util.LongBits(object.weekFeeSum.low >>> 0, object.weekFeeSum.high >>> 0).toNumber(true);
            if (object.monthFeeSum != null)
                if ($util.Long)
                    (message.monthFeeSum = $util.Long.fromValue(object.monthFeeSum)).unsigned = true;
                else if (typeof object.monthFeeSum === "string")
                    message.monthFeeSum = parseInt(object.monthFeeSum, 10);
                else if (typeof object.monthFeeSum === "number")
                    message.monthFeeSum = object.monthFeeSum;
                else if (typeof object.monthFeeSum === "object")
                    message.monthFeeSum = new $util.LongBits(object.monthFeeSum.low >>> 0, object.monthFeeSum.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a FeeReportResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FeeReportResponse
         * @static
         * @param {lnrpc.FeeReportResponse} message FeeReportResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FeeReportResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.channelFees = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.dayFeeSum = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.dayFeeSum = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.weekFeeSum = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.weekFeeSum = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.monthFeeSum = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.monthFeeSum = options.longs === String ? "0" : 0;
            }
            if (message.channelFees && message.channelFees.length) {
                object.channelFees = [];
                for (let j = 0; j < message.channelFees.length; ++j)
                    object.channelFees[j] = $root.lnrpc.ChannelFeeReport.toObject(message.channelFees[j], options);
            }
            if (message.dayFeeSum != null && message.hasOwnProperty("dayFeeSum"))
                if (typeof message.dayFeeSum === "number")
                    object.dayFeeSum = options.longs === String ? String(message.dayFeeSum) : message.dayFeeSum;
                else
                    object.dayFeeSum = options.longs === String ? $util.Long.prototype.toString.call(message.dayFeeSum) : options.longs === Number ? new $util.LongBits(message.dayFeeSum.low >>> 0, message.dayFeeSum.high >>> 0).toNumber(true) : message.dayFeeSum;
            if (message.weekFeeSum != null && message.hasOwnProperty("weekFeeSum"))
                if (typeof message.weekFeeSum === "number")
                    object.weekFeeSum = options.longs === String ? String(message.weekFeeSum) : message.weekFeeSum;
                else
                    object.weekFeeSum = options.longs === String ? $util.Long.prototype.toString.call(message.weekFeeSum) : options.longs === Number ? new $util.LongBits(message.weekFeeSum.low >>> 0, message.weekFeeSum.high >>> 0).toNumber(true) : message.weekFeeSum;
            if (message.monthFeeSum != null && message.hasOwnProperty("monthFeeSum"))
                if (typeof message.monthFeeSum === "number")
                    object.monthFeeSum = options.longs === String ? String(message.monthFeeSum) : message.monthFeeSum;
                else
                    object.monthFeeSum = options.longs === String ? $util.Long.prototype.toString.call(message.monthFeeSum) : options.longs === Number ? new $util.LongBits(message.monthFeeSum.low >>> 0, message.monthFeeSum.high >>> 0).toNumber(true) : message.monthFeeSum;
            return object;
        };

        /**
         * Converts this FeeReportResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.FeeReportResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FeeReportResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FeeReportResponse;
    })();

    lnrpc.PolicyUpdateRequest = (function() {

        /**
         * Properties of a PolicyUpdateRequest.
         * @memberof lnrpc
         * @interface IPolicyUpdateRequest
         * @property {boolean|null} [global] PolicyUpdateRequest global
         * @property {lnrpc.IChannelPoint|null} [chanPoint] PolicyUpdateRequest chanPoint
         * @property {Long|null} [baseFeeMsat] PolicyUpdateRequest baseFeeMsat
         * @property {number|null} [feeRate] PolicyUpdateRequest feeRate
         * @property {number|null} [feeRatePpm] PolicyUpdateRequest feeRatePpm
         * @property {number|null} [timeLockDelta] PolicyUpdateRequest timeLockDelta
         * @property {Long|null} [maxHtlcMsat] PolicyUpdateRequest maxHtlcMsat
         * @property {Long|null} [minHtlcMsat] PolicyUpdateRequest minHtlcMsat
         * @property {boolean|null} [minHtlcMsatSpecified] PolicyUpdateRequest minHtlcMsatSpecified
         */

        /**
         * Constructs a new PolicyUpdateRequest.
         * @memberof lnrpc
         * @classdesc Represents a PolicyUpdateRequest.
         * @implements IPolicyUpdateRequest
         * @constructor
         * @param {lnrpc.IPolicyUpdateRequest=} [properties] Properties to set
         */
        function PolicyUpdateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PolicyUpdateRequest global.
         * @member {boolean} global
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.global = false;

        /**
         * PolicyUpdateRequest chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.chanPoint = null;

        /**
         * PolicyUpdateRequest baseFeeMsat.
         * @member {Long} baseFeeMsat
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.baseFeeMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PolicyUpdateRequest feeRate.
         * @member {number} feeRate
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.feeRate = 0;

        /**
         * PolicyUpdateRequest feeRatePpm.
         * @member {number} feeRatePpm
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.feeRatePpm = 0;

        /**
         * PolicyUpdateRequest timeLockDelta.
         * @member {number} timeLockDelta
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.timeLockDelta = 0;

        /**
         * PolicyUpdateRequest maxHtlcMsat.
         * @member {Long} maxHtlcMsat
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.maxHtlcMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PolicyUpdateRequest minHtlcMsat.
         * @member {Long} minHtlcMsat
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.minHtlcMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PolicyUpdateRequest minHtlcMsatSpecified.
         * @member {boolean} minHtlcMsatSpecified
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        PolicyUpdateRequest.prototype.minHtlcMsatSpecified = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * PolicyUpdateRequest scope.
         * @member {"global"|"chanPoint"|undefined} scope
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         */
        Object.defineProperty(PolicyUpdateRequest.prototype, "scope", {
            get: $util.oneOfGetter($oneOfFields = ["global", "chanPoint"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new PolicyUpdateRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {lnrpc.IPolicyUpdateRequest=} [properties] Properties to set
         * @returns {lnrpc.PolicyUpdateRequest} PolicyUpdateRequest instance
         */
        PolicyUpdateRequest.create = function create(properties) {
            return new PolicyUpdateRequest(properties);
        };

        /**
         * Encodes the specified PolicyUpdateRequest message. Does not implicitly {@link lnrpc.PolicyUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {lnrpc.IPolicyUpdateRequest} message PolicyUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.global != null && Object.hasOwnProperty.call(message, "global"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.global);
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.baseFeeMsat != null && Object.hasOwnProperty.call(message, "baseFeeMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.baseFeeMsat);
            if (message.feeRate != null && Object.hasOwnProperty.call(message, "feeRate"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.feeRate);
            if (message.timeLockDelta != null && Object.hasOwnProperty.call(message, "timeLockDelta"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.timeLockDelta);
            if (message.maxHtlcMsat != null && Object.hasOwnProperty.call(message, "maxHtlcMsat"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.maxHtlcMsat);
            if (message.minHtlcMsat != null && Object.hasOwnProperty.call(message, "minHtlcMsat"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.minHtlcMsat);
            if (message.minHtlcMsatSpecified != null && Object.hasOwnProperty.call(message, "minHtlcMsatSpecified"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.minHtlcMsatSpecified);
            if (message.feeRatePpm != null && Object.hasOwnProperty.call(message, "feeRatePpm"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.feeRatePpm);
            return writer;
        };

        /**
         * Encodes the specified PolicyUpdateRequest message, length delimited. Does not implicitly {@link lnrpc.PolicyUpdateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {lnrpc.IPolicyUpdateRequest} message PolicyUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PolicyUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PolicyUpdateRequest} PolicyUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PolicyUpdateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.global = reader.bool();
                        break;
                    }
                case 2: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.baseFeeMsat = reader.int64();
                        break;
                    }
                case 4: {
                        message.feeRate = reader.double();
                        break;
                    }
                case 9: {
                        message.feeRatePpm = reader.uint32();
                        break;
                    }
                case 5: {
                        message.timeLockDelta = reader.uint32();
                        break;
                    }
                case 6: {
                        message.maxHtlcMsat = reader.uint64();
                        break;
                    }
                case 7: {
                        message.minHtlcMsat = reader.uint64();
                        break;
                    }
                case 8: {
                        message.minHtlcMsatSpecified = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PolicyUpdateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PolicyUpdateRequest} PolicyUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PolicyUpdateRequest message.
         * @function verify
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PolicyUpdateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.global != null && message.hasOwnProperty("global")) {
                properties.scope = 1;
                if (typeof message.global !== "boolean")
                    return "global: boolean expected";
            }
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                if (properties.scope === 1)
                    return "scope: multiple values";
                properties.scope = 1;
                {
                    let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                    if (error)
                        return "chanPoint." + error;
                }
            }
            if (message.baseFeeMsat != null && message.hasOwnProperty("baseFeeMsat"))
                if (!$util.isInteger(message.baseFeeMsat) && !(message.baseFeeMsat && $util.isInteger(message.baseFeeMsat.low) && $util.isInteger(message.baseFeeMsat.high)))
                    return "baseFeeMsat: integer|Long expected";
            if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                if (typeof message.feeRate !== "number")
                    return "feeRate: number expected";
            if (message.feeRatePpm != null && message.hasOwnProperty("feeRatePpm"))
                if (!$util.isInteger(message.feeRatePpm))
                    return "feeRatePpm: integer expected";
            if (message.timeLockDelta != null && message.hasOwnProperty("timeLockDelta"))
                if (!$util.isInteger(message.timeLockDelta))
                    return "timeLockDelta: integer expected";
            if (message.maxHtlcMsat != null && message.hasOwnProperty("maxHtlcMsat"))
                if (!$util.isInteger(message.maxHtlcMsat) && !(message.maxHtlcMsat && $util.isInteger(message.maxHtlcMsat.low) && $util.isInteger(message.maxHtlcMsat.high)))
                    return "maxHtlcMsat: integer|Long expected";
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (!$util.isInteger(message.minHtlcMsat) && !(message.minHtlcMsat && $util.isInteger(message.minHtlcMsat.low) && $util.isInteger(message.minHtlcMsat.high)))
                    return "minHtlcMsat: integer|Long expected";
            if (message.minHtlcMsatSpecified != null && message.hasOwnProperty("minHtlcMsatSpecified"))
                if (typeof message.minHtlcMsatSpecified !== "boolean")
                    return "minHtlcMsatSpecified: boolean expected";
            return null;
        };

        /**
         * Creates a PolicyUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PolicyUpdateRequest} PolicyUpdateRequest
         */
        PolicyUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PolicyUpdateRequest)
                return object;
            let message = new $root.lnrpc.PolicyUpdateRequest();
            if (object.global != null)
                message.global = Boolean(object.global);
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".lnrpc.PolicyUpdateRequest.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            if (object.baseFeeMsat != null)
                if ($util.Long)
                    (message.baseFeeMsat = $util.Long.fromValue(object.baseFeeMsat)).unsigned = false;
                else if (typeof object.baseFeeMsat === "string")
                    message.baseFeeMsat = parseInt(object.baseFeeMsat, 10);
                else if (typeof object.baseFeeMsat === "number")
                    message.baseFeeMsat = object.baseFeeMsat;
                else if (typeof object.baseFeeMsat === "object")
                    message.baseFeeMsat = new $util.LongBits(object.baseFeeMsat.low >>> 0, object.baseFeeMsat.high >>> 0).toNumber();
            if (object.feeRate != null)
                message.feeRate = Number(object.feeRate);
            if (object.feeRatePpm != null)
                message.feeRatePpm = object.feeRatePpm >>> 0;
            if (object.timeLockDelta != null)
                message.timeLockDelta = object.timeLockDelta >>> 0;
            if (object.maxHtlcMsat != null)
                if ($util.Long)
                    (message.maxHtlcMsat = $util.Long.fromValue(object.maxHtlcMsat)).unsigned = true;
                else if (typeof object.maxHtlcMsat === "string")
                    message.maxHtlcMsat = parseInt(object.maxHtlcMsat, 10);
                else if (typeof object.maxHtlcMsat === "number")
                    message.maxHtlcMsat = object.maxHtlcMsat;
                else if (typeof object.maxHtlcMsat === "object")
                    message.maxHtlcMsat = new $util.LongBits(object.maxHtlcMsat.low >>> 0, object.maxHtlcMsat.high >>> 0).toNumber(true);
            if (object.minHtlcMsat != null)
                if ($util.Long)
                    (message.minHtlcMsat = $util.Long.fromValue(object.minHtlcMsat)).unsigned = true;
                else if (typeof object.minHtlcMsat === "string")
                    message.minHtlcMsat = parseInt(object.minHtlcMsat, 10);
                else if (typeof object.minHtlcMsat === "number")
                    message.minHtlcMsat = object.minHtlcMsat;
                else if (typeof object.minHtlcMsat === "object")
                    message.minHtlcMsat = new $util.LongBits(object.minHtlcMsat.low >>> 0, object.minHtlcMsat.high >>> 0).toNumber(true);
            if (object.minHtlcMsatSpecified != null)
                message.minHtlcMsatSpecified = Boolean(object.minHtlcMsatSpecified);
            return message;
        };

        /**
         * Creates a plain object from a PolicyUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PolicyUpdateRequest
         * @static
         * @param {lnrpc.PolicyUpdateRequest} message PolicyUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PolicyUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.baseFeeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.baseFeeMsat = options.longs === String ? "0" : 0;
                object.feeRate = 0;
                object.timeLockDelta = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxHtlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxHtlcMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minHtlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minHtlcMsat = options.longs === String ? "0" : 0;
                object.minHtlcMsatSpecified = false;
                object.feeRatePpm = 0;
            }
            if (message.global != null && message.hasOwnProperty("global")) {
                object.global = message.global;
                if (options.oneofs)
                    object.scope = "global";
            }
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
                if (options.oneofs)
                    object.scope = "chanPoint";
            }
            if (message.baseFeeMsat != null && message.hasOwnProperty("baseFeeMsat"))
                if (typeof message.baseFeeMsat === "number")
                    object.baseFeeMsat = options.longs === String ? String(message.baseFeeMsat) : message.baseFeeMsat;
                else
                    object.baseFeeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.baseFeeMsat) : options.longs === Number ? new $util.LongBits(message.baseFeeMsat.low >>> 0, message.baseFeeMsat.high >>> 0).toNumber() : message.baseFeeMsat;
            if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                object.feeRate = options.json && !isFinite(message.feeRate) ? String(message.feeRate) : message.feeRate;
            if (message.timeLockDelta != null && message.hasOwnProperty("timeLockDelta"))
                object.timeLockDelta = message.timeLockDelta;
            if (message.maxHtlcMsat != null && message.hasOwnProperty("maxHtlcMsat"))
                if (typeof message.maxHtlcMsat === "number")
                    object.maxHtlcMsat = options.longs === String ? String(message.maxHtlcMsat) : message.maxHtlcMsat;
                else
                    object.maxHtlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.maxHtlcMsat) : options.longs === Number ? new $util.LongBits(message.maxHtlcMsat.low >>> 0, message.maxHtlcMsat.high >>> 0).toNumber(true) : message.maxHtlcMsat;
            if (message.minHtlcMsat != null && message.hasOwnProperty("minHtlcMsat"))
                if (typeof message.minHtlcMsat === "number")
                    object.minHtlcMsat = options.longs === String ? String(message.minHtlcMsat) : message.minHtlcMsat;
                else
                    object.minHtlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.minHtlcMsat) : options.longs === Number ? new $util.LongBits(message.minHtlcMsat.low >>> 0, message.minHtlcMsat.high >>> 0).toNumber(true) : message.minHtlcMsat;
            if (message.minHtlcMsatSpecified != null && message.hasOwnProperty("minHtlcMsatSpecified"))
                object.minHtlcMsatSpecified = message.minHtlcMsatSpecified;
            if (message.feeRatePpm != null && message.hasOwnProperty("feeRatePpm"))
                object.feeRatePpm = message.feeRatePpm;
            return object;
        };

        /**
         * Converts this PolicyUpdateRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.PolicyUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PolicyUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PolicyUpdateRequest;
    })();

    /**
     * UpdateFailure enum.
     * @name lnrpc.UpdateFailure
     * @enum {number}
     * @property {number} UPDATE_FAILURE_UNKNOWN=0 UPDATE_FAILURE_UNKNOWN value
     * @property {number} UPDATE_FAILURE_PENDING=1 UPDATE_FAILURE_PENDING value
     * @property {number} UPDATE_FAILURE_NOT_FOUND=2 UPDATE_FAILURE_NOT_FOUND value
     * @property {number} UPDATE_FAILURE_INTERNAL_ERR=3 UPDATE_FAILURE_INTERNAL_ERR value
     * @property {number} UPDATE_FAILURE_INVALID_PARAMETER=4 UPDATE_FAILURE_INVALID_PARAMETER value
     */
    lnrpc.UpdateFailure = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UPDATE_FAILURE_UNKNOWN"] = 0;
        values[valuesById[1] = "UPDATE_FAILURE_PENDING"] = 1;
        values[valuesById[2] = "UPDATE_FAILURE_NOT_FOUND"] = 2;
        values[valuesById[3] = "UPDATE_FAILURE_INTERNAL_ERR"] = 3;
        values[valuesById[4] = "UPDATE_FAILURE_INVALID_PARAMETER"] = 4;
        return values;
    })();

    lnrpc.FailedUpdate = (function() {

        /**
         * Properties of a FailedUpdate.
         * @memberof lnrpc
         * @interface IFailedUpdate
         * @property {lnrpc.IOutPoint|null} [outpoint] FailedUpdate outpoint
         * @property {lnrpc.UpdateFailure|null} [reason] FailedUpdate reason
         * @property {string|null} [updateError] FailedUpdate updateError
         */

        /**
         * Constructs a new FailedUpdate.
         * @memberof lnrpc
         * @classdesc Represents a FailedUpdate.
         * @implements IFailedUpdate
         * @constructor
         * @param {lnrpc.IFailedUpdate=} [properties] Properties to set
         */
        function FailedUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FailedUpdate outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof lnrpc.FailedUpdate
         * @instance
         */
        FailedUpdate.prototype.outpoint = null;

        /**
         * FailedUpdate reason.
         * @member {lnrpc.UpdateFailure} reason
         * @memberof lnrpc.FailedUpdate
         * @instance
         */
        FailedUpdate.prototype.reason = 0;

        /**
         * FailedUpdate updateError.
         * @member {string} updateError
         * @memberof lnrpc.FailedUpdate
         * @instance
         */
        FailedUpdate.prototype.updateError = "";

        /**
         * Creates a new FailedUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {lnrpc.IFailedUpdate=} [properties] Properties to set
         * @returns {lnrpc.FailedUpdate} FailedUpdate instance
         */
        FailedUpdate.create = function create(properties) {
            return new FailedUpdate(properties);
        };

        /**
         * Encodes the specified FailedUpdate message. Does not implicitly {@link lnrpc.FailedUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {lnrpc.IFailedUpdate} message FailedUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FailedUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.reason);
            if (message.updateError != null && Object.hasOwnProperty.call(message, "updateError"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updateError);
            return writer;
        };

        /**
         * Encodes the specified FailedUpdate message, length delimited. Does not implicitly {@link lnrpc.FailedUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {lnrpc.IFailedUpdate} message FailedUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FailedUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FailedUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.FailedUpdate} FailedUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FailedUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.FailedUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.reason = reader.int32();
                        break;
                    }
                case 3: {
                        message.updateError = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FailedUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.FailedUpdate} FailedUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FailedUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FailedUpdate message.
         * @function verify
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FailedUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.updateError != null && message.hasOwnProperty("updateError"))
                if (!$util.isString(message.updateError))
                    return "updateError: string expected";
            return null;
        };

        /**
         * Creates a FailedUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.FailedUpdate} FailedUpdate
         */
        FailedUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.FailedUpdate)
                return object;
            let message = new $root.lnrpc.FailedUpdate();
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".lnrpc.FailedUpdate.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            switch (object.reason) {
            case "UPDATE_FAILURE_UNKNOWN":
            case 0:
                message.reason = 0;
                break;
            case "UPDATE_FAILURE_PENDING":
            case 1:
                message.reason = 1;
                break;
            case "UPDATE_FAILURE_NOT_FOUND":
            case 2:
                message.reason = 2;
                break;
            case "UPDATE_FAILURE_INTERNAL_ERR":
            case 3:
                message.reason = 3;
                break;
            case "UPDATE_FAILURE_INVALID_PARAMETER":
            case 4:
                message.reason = 4;
                break;
            }
            if (object.updateError != null)
                message.updateError = String(object.updateError);
            return message;
        };

        /**
         * Creates a plain object from a FailedUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.FailedUpdate
         * @static
         * @param {lnrpc.FailedUpdate} message FailedUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FailedUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outpoint = null;
                object.reason = options.enums === String ? "UPDATE_FAILURE_UNKNOWN" : 0;
                object.updateError = "";
            }
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.lnrpc.UpdateFailure[message.reason] : message.reason;
            if (message.updateError != null && message.hasOwnProperty("updateError"))
                object.updateError = message.updateError;
            return object;
        };

        /**
         * Converts this FailedUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.FailedUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FailedUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FailedUpdate;
    })();

    lnrpc.PolicyUpdateResponse = (function() {

        /**
         * Properties of a PolicyUpdateResponse.
         * @memberof lnrpc
         * @interface IPolicyUpdateResponse
         * @property {Array.<lnrpc.IFailedUpdate>|null} [failedUpdates] PolicyUpdateResponse failedUpdates
         */

        /**
         * Constructs a new PolicyUpdateResponse.
         * @memberof lnrpc
         * @classdesc Represents a PolicyUpdateResponse.
         * @implements IPolicyUpdateResponse
         * @constructor
         * @param {lnrpc.IPolicyUpdateResponse=} [properties] Properties to set
         */
        function PolicyUpdateResponse(properties) {
            this.failedUpdates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PolicyUpdateResponse failedUpdates.
         * @member {Array.<lnrpc.IFailedUpdate>} failedUpdates
         * @memberof lnrpc.PolicyUpdateResponse
         * @instance
         */
        PolicyUpdateResponse.prototype.failedUpdates = $util.emptyArray;

        /**
         * Creates a new PolicyUpdateResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {lnrpc.IPolicyUpdateResponse=} [properties] Properties to set
         * @returns {lnrpc.PolicyUpdateResponse} PolicyUpdateResponse instance
         */
        PolicyUpdateResponse.create = function create(properties) {
            return new PolicyUpdateResponse(properties);
        };

        /**
         * Encodes the specified PolicyUpdateResponse message. Does not implicitly {@link lnrpc.PolicyUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {lnrpc.IPolicyUpdateResponse} message PolicyUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failedUpdates != null && message.failedUpdates.length)
                for (let i = 0; i < message.failedUpdates.length; ++i)
                    $root.lnrpc.FailedUpdate.encode(message.failedUpdates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PolicyUpdateResponse message, length delimited. Does not implicitly {@link lnrpc.PolicyUpdateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {lnrpc.IPolicyUpdateResponse} message PolicyUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PolicyUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.PolicyUpdateResponse} PolicyUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.PolicyUpdateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.failedUpdates && message.failedUpdates.length))
                            message.failedUpdates = [];
                        message.failedUpdates.push($root.lnrpc.FailedUpdate.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PolicyUpdateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.PolicyUpdateResponse} PolicyUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PolicyUpdateResponse message.
         * @function verify
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PolicyUpdateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.failedUpdates != null && message.hasOwnProperty("failedUpdates")) {
                if (!Array.isArray(message.failedUpdates))
                    return "failedUpdates: array expected";
                for (let i = 0; i < message.failedUpdates.length; ++i) {
                    let error = $root.lnrpc.FailedUpdate.verify(message.failedUpdates[i]);
                    if (error)
                        return "failedUpdates." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PolicyUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.PolicyUpdateResponse} PolicyUpdateResponse
         */
        PolicyUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.PolicyUpdateResponse)
                return object;
            let message = new $root.lnrpc.PolicyUpdateResponse();
            if (object.failedUpdates) {
                if (!Array.isArray(object.failedUpdates))
                    throw TypeError(".lnrpc.PolicyUpdateResponse.failedUpdates: array expected");
                message.failedUpdates = [];
                for (let i = 0; i < object.failedUpdates.length; ++i) {
                    if (typeof object.failedUpdates[i] !== "object")
                        throw TypeError(".lnrpc.PolicyUpdateResponse.failedUpdates: object expected");
                    message.failedUpdates[i] = $root.lnrpc.FailedUpdate.fromObject(object.failedUpdates[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PolicyUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.PolicyUpdateResponse
         * @static
         * @param {lnrpc.PolicyUpdateResponse} message PolicyUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PolicyUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.failedUpdates = [];
            if (message.failedUpdates && message.failedUpdates.length) {
                object.failedUpdates = [];
                for (let j = 0; j < message.failedUpdates.length; ++j)
                    object.failedUpdates[j] = $root.lnrpc.FailedUpdate.toObject(message.failedUpdates[j], options);
            }
            return object;
        };

        /**
         * Converts this PolicyUpdateResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.PolicyUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PolicyUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PolicyUpdateResponse;
    })();

    lnrpc.ForwardingHistoryRequest = (function() {

        /**
         * Properties of a ForwardingHistoryRequest.
         * @memberof lnrpc
         * @interface IForwardingHistoryRequest
         * @property {Long|null} [startTime] ForwardingHistoryRequest startTime
         * @property {Long|null} [endTime] ForwardingHistoryRequest endTime
         * @property {number|null} [indexOffset] ForwardingHistoryRequest indexOffset
         * @property {number|null} [numMaxEvents] ForwardingHistoryRequest numMaxEvents
         */

        /**
         * Constructs a new ForwardingHistoryRequest.
         * @memberof lnrpc
         * @classdesc Represents a ForwardingHistoryRequest.
         * @implements IForwardingHistoryRequest
         * @constructor
         * @param {lnrpc.IForwardingHistoryRequest=} [properties] Properties to set
         */
        function ForwardingHistoryRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForwardingHistoryRequest startTime.
         * @member {Long} startTime
         * @memberof lnrpc.ForwardingHistoryRequest
         * @instance
         */
        ForwardingHistoryRequest.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingHistoryRequest endTime.
         * @member {Long} endTime
         * @memberof lnrpc.ForwardingHistoryRequest
         * @instance
         */
        ForwardingHistoryRequest.prototype.endTime = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingHistoryRequest indexOffset.
         * @member {number} indexOffset
         * @memberof lnrpc.ForwardingHistoryRequest
         * @instance
         */
        ForwardingHistoryRequest.prototype.indexOffset = 0;

        /**
         * ForwardingHistoryRequest numMaxEvents.
         * @member {number} numMaxEvents
         * @memberof lnrpc.ForwardingHistoryRequest
         * @instance
         */
        ForwardingHistoryRequest.prototype.numMaxEvents = 0;

        /**
         * Creates a new ForwardingHistoryRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {lnrpc.IForwardingHistoryRequest=} [properties] Properties to set
         * @returns {lnrpc.ForwardingHistoryRequest} ForwardingHistoryRequest instance
         */
        ForwardingHistoryRequest.create = function create(properties) {
            return new ForwardingHistoryRequest(properties);
        };

        /**
         * Encodes the specified ForwardingHistoryRequest message. Does not implicitly {@link lnrpc.ForwardingHistoryRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {lnrpc.IForwardingHistoryRequest} message ForwardingHistoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardingHistoryRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.startTime);
            if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.endTime);
            if (message.indexOffset != null && Object.hasOwnProperty.call(message, "indexOffset"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.indexOffset);
            if (message.numMaxEvents != null && Object.hasOwnProperty.call(message, "numMaxEvents"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numMaxEvents);
            return writer;
        };

        /**
         * Encodes the specified ForwardingHistoryRequest message, length delimited. Does not implicitly {@link lnrpc.ForwardingHistoryRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {lnrpc.IForwardingHistoryRequest} message ForwardingHistoryRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardingHistoryRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardingHistoryRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ForwardingHistoryRequest} ForwardingHistoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardingHistoryRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ForwardingHistoryRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.startTime = reader.uint64();
                        break;
                    }
                case 2: {
                        message.endTime = reader.uint64();
                        break;
                    }
                case 3: {
                        message.indexOffset = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numMaxEvents = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardingHistoryRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ForwardingHistoryRequest} ForwardingHistoryRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardingHistoryRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardingHistoryRequest message.
         * @function verify
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardingHistoryRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (!$util.isInteger(message.endTime) && !(message.endTime && $util.isInteger(message.endTime.low) && $util.isInteger(message.endTime.high)))
                    return "endTime: integer|Long expected";
            if (message.indexOffset != null && message.hasOwnProperty("indexOffset"))
                if (!$util.isInteger(message.indexOffset))
                    return "indexOffset: integer expected";
            if (message.numMaxEvents != null && message.hasOwnProperty("numMaxEvents"))
                if (!$util.isInteger(message.numMaxEvents))
                    return "numMaxEvents: integer expected";
            return null;
        };

        /**
         * Creates a ForwardingHistoryRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ForwardingHistoryRequest} ForwardingHistoryRequest
         */
        ForwardingHistoryRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ForwardingHistoryRequest)
                return object;
            let message = new $root.lnrpc.ForwardingHistoryRequest();
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = true;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber(true);
            if (object.endTime != null)
                if ($util.Long)
                    (message.endTime = $util.Long.fromValue(object.endTime)).unsigned = true;
                else if (typeof object.endTime === "string")
                    message.endTime = parseInt(object.endTime, 10);
                else if (typeof object.endTime === "number")
                    message.endTime = object.endTime;
                else if (typeof object.endTime === "object")
                    message.endTime = new $util.LongBits(object.endTime.low >>> 0, object.endTime.high >>> 0).toNumber(true);
            if (object.indexOffset != null)
                message.indexOffset = object.indexOffset >>> 0;
            if (object.numMaxEvents != null)
                message.numMaxEvents = object.numMaxEvents >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ForwardingHistoryRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ForwardingHistoryRequest
         * @static
         * @param {lnrpc.ForwardingHistoryRequest} message ForwardingHistoryRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardingHistoryRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.endTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.endTime = options.longs === String ? "0" : 0;
                object.indexOffset = 0;
                object.numMaxEvents = 0;
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber(true) : message.startTime;
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (typeof message.endTime === "number")
                    object.endTime = options.longs === String ? String(message.endTime) : message.endTime;
                else
                    object.endTime = options.longs === String ? $util.Long.prototype.toString.call(message.endTime) : options.longs === Number ? new $util.LongBits(message.endTime.low >>> 0, message.endTime.high >>> 0).toNumber(true) : message.endTime;
            if (message.indexOffset != null && message.hasOwnProperty("indexOffset"))
                object.indexOffset = message.indexOffset;
            if (message.numMaxEvents != null && message.hasOwnProperty("numMaxEvents"))
                object.numMaxEvents = message.numMaxEvents;
            return object;
        };

        /**
         * Converts this ForwardingHistoryRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ForwardingHistoryRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardingHistoryRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardingHistoryRequest;
    })();

    lnrpc.ForwardingEvent = (function() {

        /**
         * Properties of a ForwardingEvent.
         * @memberof lnrpc
         * @interface IForwardingEvent
         * @property {Long|null} [timestamp] ForwardingEvent timestamp
         * @property {Long|null} [chanIdIn] ForwardingEvent chanIdIn
         * @property {Long|null} [chanIdOut] ForwardingEvent chanIdOut
         * @property {Long|null} [amtIn] ForwardingEvent amtIn
         * @property {Long|null} [amtOut] ForwardingEvent amtOut
         * @property {Long|null} [fee] ForwardingEvent fee
         * @property {Long|null} [feeMsat] ForwardingEvent feeMsat
         * @property {Long|null} [amtInMsat] ForwardingEvent amtInMsat
         * @property {Long|null} [amtOutMsat] ForwardingEvent amtOutMsat
         * @property {Long|null} [timestampNs] ForwardingEvent timestampNs
         */

        /**
         * Constructs a new ForwardingEvent.
         * @memberof lnrpc
         * @classdesc Represents a ForwardingEvent.
         * @implements IForwardingEvent
         * @constructor
         * @param {lnrpc.IForwardingEvent=} [properties] Properties to set
         */
        function ForwardingEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForwardingEvent timestamp.
         * @member {Long} timestamp
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent chanIdIn.
         * @member {Long} chanIdIn
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.chanIdIn = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent chanIdOut.
         * @member {Long} chanIdOut
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.chanIdOut = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent amtIn.
         * @member {Long} amtIn
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.amtIn = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent amtOut.
         * @member {Long} amtOut
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.amtOut = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent fee.
         * @member {Long} fee
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.fee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent feeMsat.
         * @member {Long} feeMsat
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.feeMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent amtInMsat.
         * @member {Long} amtInMsat
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.amtInMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent amtOutMsat.
         * @member {Long} amtOutMsat
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.amtOutMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardingEvent timestampNs.
         * @member {Long} timestampNs
         * @memberof lnrpc.ForwardingEvent
         * @instance
         */
        ForwardingEvent.prototype.timestampNs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new ForwardingEvent instance using the specified properties.
         * @function create
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {lnrpc.IForwardingEvent=} [properties] Properties to set
         * @returns {lnrpc.ForwardingEvent} ForwardingEvent instance
         */
        ForwardingEvent.create = function create(properties) {
            return new ForwardingEvent(properties);
        };

        /**
         * Encodes the specified ForwardingEvent message. Does not implicitly {@link lnrpc.ForwardingEvent.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {lnrpc.IForwardingEvent} message ForwardingEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardingEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.timestamp);
            if (message.chanIdIn != null && Object.hasOwnProperty.call(message, "chanIdIn"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.chanIdIn);
            if (message.chanIdOut != null && Object.hasOwnProperty.call(message, "chanIdOut"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.chanIdOut);
            if (message.amtIn != null && Object.hasOwnProperty.call(message, "amtIn"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.amtIn);
            if (message.amtOut != null && Object.hasOwnProperty.call(message, "amtOut"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.amtOut);
            if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.fee);
            if (message.feeMsat != null && Object.hasOwnProperty.call(message, "feeMsat"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.feeMsat);
            if (message.amtInMsat != null && Object.hasOwnProperty.call(message, "amtInMsat"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.amtInMsat);
            if (message.amtOutMsat != null && Object.hasOwnProperty.call(message, "amtOutMsat"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.amtOutMsat);
            if (message.timestampNs != null && Object.hasOwnProperty.call(message, "timestampNs"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.timestampNs);
            return writer;
        };

        /**
         * Encodes the specified ForwardingEvent message, length delimited. Does not implicitly {@link lnrpc.ForwardingEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {lnrpc.IForwardingEvent} message ForwardingEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardingEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardingEvent message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ForwardingEvent} ForwardingEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardingEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ForwardingEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.timestamp = reader.uint64();
                        break;
                    }
                case 2: {
                        message.chanIdIn = reader.uint64();
                        break;
                    }
                case 4: {
                        message.chanIdOut = reader.uint64();
                        break;
                    }
                case 5: {
                        message.amtIn = reader.uint64();
                        break;
                    }
                case 6: {
                        message.amtOut = reader.uint64();
                        break;
                    }
                case 7: {
                        message.fee = reader.uint64();
                        break;
                    }
                case 8: {
                        message.feeMsat = reader.uint64();
                        break;
                    }
                case 9: {
                        message.amtInMsat = reader.uint64();
                        break;
                    }
                case 10: {
                        message.amtOutMsat = reader.uint64();
                        break;
                    }
                case 11: {
                        message.timestampNs = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardingEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ForwardingEvent} ForwardingEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardingEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardingEvent message.
         * @function verify
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardingEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp) && !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high)))
                    return "timestamp: integer|Long expected";
            if (message.chanIdIn != null && message.hasOwnProperty("chanIdIn"))
                if (!$util.isInteger(message.chanIdIn) && !(message.chanIdIn && $util.isInteger(message.chanIdIn.low) && $util.isInteger(message.chanIdIn.high)))
                    return "chanIdIn: integer|Long expected";
            if (message.chanIdOut != null && message.hasOwnProperty("chanIdOut"))
                if (!$util.isInteger(message.chanIdOut) && !(message.chanIdOut && $util.isInteger(message.chanIdOut.low) && $util.isInteger(message.chanIdOut.high)))
                    return "chanIdOut: integer|Long expected";
            if (message.amtIn != null && message.hasOwnProperty("amtIn"))
                if (!$util.isInteger(message.amtIn) && !(message.amtIn && $util.isInteger(message.amtIn.low) && $util.isInteger(message.amtIn.high)))
                    return "amtIn: integer|Long expected";
            if (message.amtOut != null && message.hasOwnProperty("amtOut"))
                if (!$util.isInteger(message.amtOut) && !(message.amtOut && $util.isInteger(message.amtOut.low) && $util.isInteger(message.amtOut.high)))
                    return "amtOut: integer|Long expected";
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (!$util.isInteger(message.fee) && !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high)))
                    return "fee: integer|Long expected";
            if (message.feeMsat != null && message.hasOwnProperty("feeMsat"))
                if (!$util.isInteger(message.feeMsat) && !(message.feeMsat && $util.isInteger(message.feeMsat.low) && $util.isInteger(message.feeMsat.high)))
                    return "feeMsat: integer|Long expected";
            if (message.amtInMsat != null && message.hasOwnProperty("amtInMsat"))
                if (!$util.isInteger(message.amtInMsat) && !(message.amtInMsat && $util.isInteger(message.amtInMsat.low) && $util.isInteger(message.amtInMsat.high)))
                    return "amtInMsat: integer|Long expected";
            if (message.amtOutMsat != null && message.hasOwnProperty("amtOutMsat"))
                if (!$util.isInteger(message.amtOutMsat) && !(message.amtOutMsat && $util.isInteger(message.amtOutMsat.low) && $util.isInteger(message.amtOutMsat.high)))
                    return "amtOutMsat: integer|Long expected";
            if (message.timestampNs != null && message.hasOwnProperty("timestampNs"))
                if (!$util.isInteger(message.timestampNs) && !(message.timestampNs && $util.isInteger(message.timestampNs.low) && $util.isInteger(message.timestampNs.high)))
                    return "timestampNs: integer|Long expected";
            return null;
        };

        /**
         * Creates a ForwardingEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ForwardingEvent} ForwardingEvent
         */
        ForwardingEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ForwardingEvent)
                return object;
            let message = new $root.lnrpc.ForwardingEvent();
            if (object.timestamp != null)
                if ($util.Long)
                    (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = true;
                else if (typeof object.timestamp === "string")
                    message.timestamp = parseInt(object.timestamp, 10);
                else if (typeof object.timestamp === "number")
                    message.timestamp = object.timestamp;
                else if (typeof object.timestamp === "object")
                    message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber(true);
            if (object.chanIdIn != null)
                if ($util.Long)
                    (message.chanIdIn = $util.Long.fromValue(object.chanIdIn)).unsigned = true;
                else if (typeof object.chanIdIn === "string")
                    message.chanIdIn = parseInt(object.chanIdIn, 10);
                else if (typeof object.chanIdIn === "number")
                    message.chanIdIn = object.chanIdIn;
                else if (typeof object.chanIdIn === "object")
                    message.chanIdIn = new $util.LongBits(object.chanIdIn.low >>> 0, object.chanIdIn.high >>> 0).toNumber(true);
            if (object.chanIdOut != null)
                if ($util.Long)
                    (message.chanIdOut = $util.Long.fromValue(object.chanIdOut)).unsigned = true;
                else if (typeof object.chanIdOut === "string")
                    message.chanIdOut = parseInt(object.chanIdOut, 10);
                else if (typeof object.chanIdOut === "number")
                    message.chanIdOut = object.chanIdOut;
                else if (typeof object.chanIdOut === "object")
                    message.chanIdOut = new $util.LongBits(object.chanIdOut.low >>> 0, object.chanIdOut.high >>> 0).toNumber(true);
            if (object.amtIn != null)
                if ($util.Long)
                    (message.amtIn = $util.Long.fromValue(object.amtIn)).unsigned = true;
                else if (typeof object.amtIn === "string")
                    message.amtIn = parseInt(object.amtIn, 10);
                else if (typeof object.amtIn === "number")
                    message.amtIn = object.amtIn;
                else if (typeof object.amtIn === "object")
                    message.amtIn = new $util.LongBits(object.amtIn.low >>> 0, object.amtIn.high >>> 0).toNumber(true);
            if (object.amtOut != null)
                if ($util.Long)
                    (message.amtOut = $util.Long.fromValue(object.amtOut)).unsigned = true;
                else if (typeof object.amtOut === "string")
                    message.amtOut = parseInt(object.amtOut, 10);
                else if (typeof object.amtOut === "number")
                    message.amtOut = object.amtOut;
                else if (typeof object.amtOut === "object")
                    message.amtOut = new $util.LongBits(object.amtOut.low >>> 0, object.amtOut.high >>> 0).toNumber(true);
            if (object.fee != null)
                if ($util.Long)
                    (message.fee = $util.Long.fromValue(object.fee)).unsigned = true;
                else if (typeof object.fee === "string")
                    message.fee = parseInt(object.fee, 10);
                else if (typeof object.fee === "number")
                    message.fee = object.fee;
                else if (typeof object.fee === "object")
                    message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber(true);
            if (object.feeMsat != null)
                if ($util.Long)
                    (message.feeMsat = $util.Long.fromValue(object.feeMsat)).unsigned = true;
                else if (typeof object.feeMsat === "string")
                    message.feeMsat = parseInt(object.feeMsat, 10);
                else if (typeof object.feeMsat === "number")
                    message.feeMsat = object.feeMsat;
                else if (typeof object.feeMsat === "object")
                    message.feeMsat = new $util.LongBits(object.feeMsat.low >>> 0, object.feeMsat.high >>> 0).toNumber(true);
            if (object.amtInMsat != null)
                if ($util.Long)
                    (message.amtInMsat = $util.Long.fromValue(object.amtInMsat)).unsigned = true;
                else if (typeof object.amtInMsat === "string")
                    message.amtInMsat = parseInt(object.amtInMsat, 10);
                else if (typeof object.amtInMsat === "number")
                    message.amtInMsat = object.amtInMsat;
                else if (typeof object.amtInMsat === "object")
                    message.amtInMsat = new $util.LongBits(object.amtInMsat.low >>> 0, object.amtInMsat.high >>> 0).toNumber(true);
            if (object.amtOutMsat != null)
                if ($util.Long)
                    (message.amtOutMsat = $util.Long.fromValue(object.amtOutMsat)).unsigned = true;
                else if (typeof object.amtOutMsat === "string")
                    message.amtOutMsat = parseInt(object.amtOutMsat, 10);
                else if (typeof object.amtOutMsat === "number")
                    message.amtOutMsat = object.amtOutMsat;
                else if (typeof object.amtOutMsat === "object")
                    message.amtOutMsat = new $util.LongBits(object.amtOutMsat.low >>> 0, object.amtOutMsat.high >>> 0).toNumber(true);
            if (object.timestampNs != null)
                if ($util.Long)
                    (message.timestampNs = $util.Long.fromValue(object.timestampNs)).unsigned = true;
                else if (typeof object.timestampNs === "string")
                    message.timestampNs = parseInt(object.timestampNs, 10);
                else if (typeof object.timestampNs === "number")
                    message.timestampNs = object.timestampNs;
                else if (typeof object.timestampNs === "object")
                    message.timestampNs = new $util.LongBits(object.timestampNs.low >>> 0, object.timestampNs.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a ForwardingEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ForwardingEvent
         * @static
         * @param {lnrpc.ForwardingEvent} message ForwardingEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardingEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestamp = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanIdIn = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanIdIn = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanIdOut = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanIdOut = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.amtIn = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtIn = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.amtOut = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtOut = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.fee = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.feeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.amtInMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtInMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.amtOutMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtOutMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestampNs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestampNs = options.longs === String ? "0" : 0;
            }
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (typeof message.timestamp === "number")
                    object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
                else
                    object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber(true) : message.timestamp;
            if (message.chanIdIn != null && message.hasOwnProperty("chanIdIn"))
                if (typeof message.chanIdIn === "number")
                    object.chanIdIn = options.longs === String ? String(message.chanIdIn) : message.chanIdIn;
                else
                    object.chanIdIn = options.longs === String ? $util.Long.prototype.toString.call(message.chanIdIn) : options.longs === Number ? new $util.LongBits(message.chanIdIn.low >>> 0, message.chanIdIn.high >>> 0).toNumber(true) : message.chanIdIn;
            if (message.chanIdOut != null && message.hasOwnProperty("chanIdOut"))
                if (typeof message.chanIdOut === "number")
                    object.chanIdOut = options.longs === String ? String(message.chanIdOut) : message.chanIdOut;
                else
                    object.chanIdOut = options.longs === String ? $util.Long.prototype.toString.call(message.chanIdOut) : options.longs === Number ? new $util.LongBits(message.chanIdOut.low >>> 0, message.chanIdOut.high >>> 0).toNumber(true) : message.chanIdOut;
            if (message.amtIn != null && message.hasOwnProperty("amtIn"))
                if (typeof message.amtIn === "number")
                    object.amtIn = options.longs === String ? String(message.amtIn) : message.amtIn;
                else
                    object.amtIn = options.longs === String ? $util.Long.prototype.toString.call(message.amtIn) : options.longs === Number ? new $util.LongBits(message.amtIn.low >>> 0, message.amtIn.high >>> 0).toNumber(true) : message.amtIn;
            if (message.amtOut != null && message.hasOwnProperty("amtOut"))
                if (typeof message.amtOut === "number")
                    object.amtOut = options.longs === String ? String(message.amtOut) : message.amtOut;
                else
                    object.amtOut = options.longs === String ? $util.Long.prototype.toString.call(message.amtOut) : options.longs === Number ? new $util.LongBits(message.amtOut.low >>> 0, message.amtOut.high >>> 0).toNumber(true) : message.amtOut;
            if (message.fee != null && message.hasOwnProperty("fee"))
                if (typeof message.fee === "number")
                    object.fee = options.longs === String ? String(message.fee) : message.fee;
                else
                    object.fee = options.longs === String ? $util.Long.prototype.toString.call(message.fee) : options.longs === Number ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber(true) : message.fee;
            if (message.feeMsat != null && message.hasOwnProperty("feeMsat"))
                if (typeof message.feeMsat === "number")
                    object.feeMsat = options.longs === String ? String(message.feeMsat) : message.feeMsat;
                else
                    object.feeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.feeMsat) : options.longs === Number ? new $util.LongBits(message.feeMsat.low >>> 0, message.feeMsat.high >>> 0).toNumber(true) : message.feeMsat;
            if (message.amtInMsat != null && message.hasOwnProperty("amtInMsat"))
                if (typeof message.amtInMsat === "number")
                    object.amtInMsat = options.longs === String ? String(message.amtInMsat) : message.amtInMsat;
                else
                    object.amtInMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtInMsat) : options.longs === Number ? new $util.LongBits(message.amtInMsat.low >>> 0, message.amtInMsat.high >>> 0).toNumber(true) : message.amtInMsat;
            if (message.amtOutMsat != null && message.hasOwnProperty("amtOutMsat"))
                if (typeof message.amtOutMsat === "number")
                    object.amtOutMsat = options.longs === String ? String(message.amtOutMsat) : message.amtOutMsat;
                else
                    object.amtOutMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtOutMsat) : options.longs === Number ? new $util.LongBits(message.amtOutMsat.low >>> 0, message.amtOutMsat.high >>> 0).toNumber(true) : message.amtOutMsat;
            if (message.timestampNs != null && message.hasOwnProperty("timestampNs"))
                if (typeof message.timestampNs === "number")
                    object.timestampNs = options.longs === String ? String(message.timestampNs) : message.timestampNs;
                else
                    object.timestampNs = options.longs === String ? $util.Long.prototype.toString.call(message.timestampNs) : options.longs === Number ? new $util.LongBits(message.timestampNs.low >>> 0, message.timestampNs.high >>> 0).toNumber(true) : message.timestampNs;
            return object;
        };

        /**
         * Converts this ForwardingEvent to JSON.
         * @function toJSON
         * @memberof lnrpc.ForwardingEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardingEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardingEvent;
    })();

    lnrpc.ForwardingHistoryResponse = (function() {

        /**
         * Properties of a ForwardingHistoryResponse.
         * @memberof lnrpc
         * @interface IForwardingHistoryResponse
         * @property {Array.<lnrpc.IForwardingEvent>|null} [forwardingEvents] ForwardingHistoryResponse forwardingEvents
         * @property {number|null} [lastOffsetIndex] ForwardingHistoryResponse lastOffsetIndex
         */

        /**
         * Constructs a new ForwardingHistoryResponse.
         * @memberof lnrpc
         * @classdesc Represents a ForwardingHistoryResponse.
         * @implements IForwardingHistoryResponse
         * @constructor
         * @param {lnrpc.IForwardingHistoryResponse=} [properties] Properties to set
         */
        function ForwardingHistoryResponse(properties) {
            this.forwardingEvents = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForwardingHistoryResponse forwardingEvents.
         * @member {Array.<lnrpc.IForwardingEvent>} forwardingEvents
         * @memberof lnrpc.ForwardingHistoryResponse
         * @instance
         */
        ForwardingHistoryResponse.prototype.forwardingEvents = $util.emptyArray;

        /**
         * ForwardingHistoryResponse lastOffsetIndex.
         * @member {number} lastOffsetIndex
         * @memberof lnrpc.ForwardingHistoryResponse
         * @instance
         */
        ForwardingHistoryResponse.prototype.lastOffsetIndex = 0;

        /**
         * Creates a new ForwardingHistoryResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {lnrpc.IForwardingHistoryResponse=} [properties] Properties to set
         * @returns {lnrpc.ForwardingHistoryResponse} ForwardingHistoryResponse instance
         */
        ForwardingHistoryResponse.create = function create(properties) {
            return new ForwardingHistoryResponse(properties);
        };

        /**
         * Encodes the specified ForwardingHistoryResponse message. Does not implicitly {@link lnrpc.ForwardingHistoryResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {lnrpc.IForwardingHistoryResponse} message ForwardingHistoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardingHistoryResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.forwardingEvents != null && message.forwardingEvents.length)
                for (let i = 0; i < message.forwardingEvents.length; ++i)
                    $root.lnrpc.ForwardingEvent.encode(message.forwardingEvents[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lastOffsetIndex != null && Object.hasOwnProperty.call(message, "lastOffsetIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.lastOffsetIndex);
            return writer;
        };

        /**
         * Encodes the specified ForwardingHistoryResponse message, length delimited. Does not implicitly {@link lnrpc.ForwardingHistoryResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {lnrpc.IForwardingHistoryResponse} message ForwardingHistoryResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardingHistoryResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardingHistoryResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ForwardingHistoryResponse} ForwardingHistoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardingHistoryResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ForwardingHistoryResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.forwardingEvents && message.forwardingEvents.length))
                            message.forwardingEvents = [];
                        message.forwardingEvents.push($root.lnrpc.ForwardingEvent.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.lastOffsetIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardingHistoryResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ForwardingHistoryResponse} ForwardingHistoryResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardingHistoryResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardingHistoryResponse message.
         * @function verify
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardingHistoryResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.forwardingEvents != null && message.hasOwnProperty("forwardingEvents")) {
                if (!Array.isArray(message.forwardingEvents))
                    return "forwardingEvents: array expected";
                for (let i = 0; i < message.forwardingEvents.length; ++i) {
                    let error = $root.lnrpc.ForwardingEvent.verify(message.forwardingEvents[i]);
                    if (error)
                        return "forwardingEvents." + error;
                }
            }
            if (message.lastOffsetIndex != null && message.hasOwnProperty("lastOffsetIndex"))
                if (!$util.isInteger(message.lastOffsetIndex))
                    return "lastOffsetIndex: integer expected";
            return null;
        };

        /**
         * Creates a ForwardingHistoryResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ForwardingHistoryResponse} ForwardingHistoryResponse
         */
        ForwardingHistoryResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ForwardingHistoryResponse)
                return object;
            let message = new $root.lnrpc.ForwardingHistoryResponse();
            if (object.forwardingEvents) {
                if (!Array.isArray(object.forwardingEvents))
                    throw TypeError(".lnrpc.ForwardingHistoryResponse.forwardingEvents: array expected");
                message.forwardingEvents = [];
                for (let i = 0; i < object.forwardingEvents.length; ++i) {
                    if (typeof object.forwardingEvents[i] !== "object")
                        throw TypeError(".lnrpc.ForwardingHistoryResponse.forwardingEvents: object expected");
                    message.forwardingEvents[i] = $root.lnrpc.ForwardingEvent.fromObject(object.forwardingEvents[i]);
                }
            }
            if (object.lastOffsetIndex != null)
                message.lastOffsetIndex = object.lastOffsetIndex >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ForwardingHistoryResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ForwardingHistoryResponse
         * @static
         * @param {lnrpc.ForwardingHistoryResponse} message ForwardingHistoryResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardingHistoryResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.forwardingEvents = [];
            if (options.defaults)
                object.lastOffsetIndex = 0;
            if (message.forwardingEvents && message.forwardingEvents.length) {
                object.forwardingEvents = [];
                for (let j = 0; j < message.forwardingEvents.length; ++j)
                    object.forwardingEvents[j] = $root.lnrpc.ForwardingEvent.toObject(message.forwardingEvents[j], options);
            }
            if (message.lastOffsetIndex != null && message.hasOwnProperty("lastOffsetIndex"))
                object.lastOffsetIndex = message.lastOffsetIndex;
            return object;
        };

        /**
         * Converts this ForwardingHistoryResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ForwardingHistoryResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardingHistoryResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardingHistoryResponse;
    })();

    lnrpc.ExportChannelBackupRequest = (function() {

        /**
         * Properties of an ExportChannelBackupRequest.
         * @memberof lnrpc
         * @interface IExportChannelBackupRequest
         * @property {lnrpc.IChannelPoint|null} [chanPoint] ExportChannelBackupRequest chanPoint
         */

        /**
         * Constructs a new ExportChannelBackupRequest.
         * @memberof lnrpc
         * @classdesc Represents an ExportChannelBackupRequest.
         * @implements IExportChannelBackupRequest
         * @constructor
         * @param {lnrpc.IExportChannelBackupRequest=} [properties] Properties to set
         */
        function ExportChannelBackupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportChannelBackupRequest chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof lnrpc.ExportChannelBackupRequest
         * @instance
         */
        ExportChannelBackupRequest.prototype.chanPoint = null;

        /**
         * Creates a new ExportChannelBackupRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {lnrpc.IExportChannelBackupRequest=} [properties] Properties to set
         * @returns {lnrpc.ExportChannelBackupRequest} ExportChannelBackupRequest instance
         */
        ExportChannelBackupRequest.create = function create(properties) {
            return new ExportChannelBackupRequest(properties);
        };

        /**
         * Encodes the specified ExportChannelBackupRequest message. Does not implicitly {@link lnrpc.ExportChannelBackupRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {lnrpc.IExportChannelBackupRequest} message ExportChannelBackupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportChannelBackupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ExportChannelBackupRequest message, length delimited. Does not implicitly {@link lnrpc.ExportChannelBackupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {lnrpc.IExportChannelBackupRequest} message ExportChannelBackupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportChannelBackupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportChannelBackupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ExportChannelBackupRequest} ExportChannelBackupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportChannelBackupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ExportChannelBackupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportChannelBackupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ExportChannelBackupRequest} ExportChannelBackupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportChannelBackupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportChannelBackupRequest message.
         * @function verify
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportChannelBackupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                if (error)
                    return "chanPoint." + error;
            }
            return null;
        };

        /**
         * Creates an ExportChannelBackupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ExportChannelBackupRequest} ExportChannelBackupRequest
         */
        ExportChannelBackupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ExportChannelBackupRequest)
                return object;
            let message = new $root.lnrpc.ExportChannelBackupRequest();
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".lnrpc.ExportChannelBackupRequest.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            return message;
        };

        /**
         * Creates a plain object from an ExportChannelBackupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ExportChannelBackupRequest
         * @static
         * @param {lnrpc.ExportChannelBackupRequest} message ExportChannelBackupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportChannelBackupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.chanPoint = null;
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
            return object;
        };

        /**
         * Converts this ExportChannelBackupRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ExportChannelBackupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportChannelBackupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExportChannelBackupRequest;
    })();

    lnrpc.ChannelBackup = (function() {

        /**
         * Properties of a ChannelBackup.
         * @memberof lnrpc
         * @interface IChannelBackup
         * @property {lnrpc.IChannelPoint|null} [chanPoint] ChannelBackup chanPoint
         * @property {Uint8Array|null} [chanBackup] ChannelBackup chanBackup
         */

        /**
         * Constructs a new ChannelBackup.
         * @memberof lnrpc
         * @classdesc Represents a ChannelBackup.
         * @implements IChannelBackup
         * @constructor
         * @param {lnrpc.IChannelBackup=} [properties] Properties to set
         */
        function ChannelBackup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelBackup chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof lnrpc.ChannelBackup
         * @instance
         */
        ChannelBackup.prototype.chanPoint = null;

        /**
         * ChannelBackup chanBackup.
         * @member {Uint8Array} chanBackup
         * @memberof lnrpc.ChannelBackup
         * @instance
         */
        ChannelBackup.prototype.chanBackup = $util.newBuffer([]);

        /**
         * Creates a new ChannelBackup instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {lnrpc.IChannelBackup=} [properties] Properties to set
         * @returns {lnrpc.ChannelBackup} ChannelBackup instance
         */
        ChannelBackup.create = function create(properties) {
            return new ChannelBackup(properties);
        };

        /**
         * Encodes the specified ChannelBackup message. Does not implicitly {@link lnrpc.ChannelBackup.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {lnrpc.IChannelBackup} message ChannelBackup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBackup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.chanBackup != null && Object.hasOwnProperty.call(message, "chanBackup"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.chanBackup);
            return writer;
        };

        /**
         * Encodes the specified ChannelBackup message, length delimited. Does not implicitly {@link lnrpc.ChannelBackup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {lnrpc.IChannelBackup} message ChannelBackup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBackup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelBackup message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelBackup} ChannelBackup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBackup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelBackup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.chanBackup = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelBackup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelBackup} ChannelBackup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBackup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelBackup message.
         * @function verify
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelBackup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                if (error)
                    return "chanPoint." + error;
            }
            if (message.chanBackup != null && message.hasOwnProperty("chanBackup"))
                if (!(message.chanBackup && typeof message.chanBackup.length === "number" || $util.isString(message.chanBackup)))
                    return "chanBackup: buffer expected";
            return null;
        };

        /**
         * Creates a ChannelBackup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelBackup} ChannelBackup
         */
        ChannelBackup.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelBackup)
                return object;
            let message = new $root.lnrpc.ChannelBackup();
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".lnrpc.ChannelBackup.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            if (object.chanBackup != null)
                if (typeof object.chanBackup === "string")
                    $util.base64.decode(object.chanBackup, message.chanBackup = $util.newBuffer($util.base64.length(object.chanBackup)), 0);
                else if (object.chanBackup.length)
                    message.chanBackup = object.chanBackup;
            return message;
        };

        /**
         * Creates a plain object from a ChannelBackup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelBackup
         * @static
         * @param {lnrpc.ChannelBackup} message ChannelBackup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelBackup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.chanPoint = null;
                if (options.bytes === String)
                    object.chanBackup = "";
                else {
                    object.chanBackup = [];
                    if (options.bytes !== Array)
                        object.chanBackup = $util.newBuffer(object.chanBackup);
                }
            }
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
            if (message.chanBackup != null && message.hasOwnProperty("chanBackup"))
                object.chanBackup = options.bytes === String ? $util.base64.encode(message.chanBackup, 0, message.chanBackup.length) : options.bytes === Array ? Array.prototype.slice.call(message.chanBackup) : message.chanBackup;
            return object;
        };

        /**
         * Converts this ChannelBackup to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelBackup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelBackup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelBackup;
    })();

    lnrpc.MultiChanBackup = (function() {

        /**
         * Properties of a MultiChanBackup.
         * @memberof lnrpc
         * @interface IMultiChanBackup
         * @property {Array.<lnrpc.IChannelPoint>|null} [chanPoints] MultiChanBackup chanPoints
         * @property {Uint8Array|null} [multiChanBackup] MultiChanBackup multiChanBackup
         */

        /**
         * Constructs a new MultiChanBackup.
         * @memberof lnrpc
         * @classdesc Represents a MultiChanBackup.
         * @implements IMultiChanBackup
         * @constructor
         * @param {lnrpc.IMultiChanBackup=} [properties] Properties to set
         */
        function MultiChanBackup(properties) {
            this.chanPoints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MultiChanBackup chanPoints.
         * @member {Array.<lnrpc.IChannelPoint>} chanPoints
         * @memberof lnrpc.MultiChanBackup
         * @instance
         */
        MultiChanBackup.prototype.chanPoints = $util.emptyArray;

        /**
         * MultiChanBackup multiChanBackup.
         * @member {Uint8Array} multiChanBackup
         * @memberof lnrpc.MultiChanBackup
         * @instance
         */
        MultiChanBackup.prototype.multiChanBackup = $util.newBuffer([]);

        /**
         * Creates a new MultiChanBackup instance using the specified properties.
         * @function create
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {lnrpc.IMultiChanBackup=} [properties] Properties to set
         * @returns {lnrpc.MultiChanBackup} MultiChanBackup instance
         */
        MultiChanBackup.create = function create(properties) {
            return new MultiChanBackup(properties);
        };

        /**
         * Encodes the specified MultiChanBackup message. Does not implicitly {@link lnrpc.MultiChanBackup.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {lnrpc.IMultiChanBackup} message MultiChanBackup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MultiChanBackup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanPoints != null && message.chanPoints.length)
                for (let i = 0; i < message.chanPoints.length; ++i)
                    $root.lnrpc.ChannelPoint.encode(message.chanPoints[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.multiChanBackup != null && Object.hasOwnProperty.call(message, "multiChanBackup"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.multiChanBackup);
            return writer;
        };

        /**
         * Encodes the specified MultiChanBackup message, length delimited. Does not implicitly {@link lnrpc.MultiChanBackup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {lnrpc.IMultiChanBackup} message MultiChanBackup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MultiChanBackup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MultiChanBackup message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.MultiChanBackup} MultiChanBackup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MultiChanBackup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.MultiChanBackup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.chanPoints && message.chanPoints.length))
                            message.chanPoints = [];
                        message.chanPoints.push($root.lnrpc.ChannelPoint.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.multiChanBackup = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MultiChanBackup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.MultiChanBackup} MultiChanBackup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MultiChanBackup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MultiChanBackup message.
         * @function verify
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MultiChanBackup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanPoints != null && message.hasOwnProperty("chanPoints")) {
                if (!Array.isArray(message.chanPoints))
                    return "chanPoints: array expected";
                for (let i = 0; i < message.chanPoints.length; ++i) {
                    let error = $root.lnrpc.ChannelPoint.verify(message.chanPoints[i]);
                    if (error)
                        return "chanPoints." + error;
                }
            }
            if (message.multiChanBackup != null && message.hasOwnProperty("multiChanBackup"))
                if (!(message.multiChanBackup && typeof message.multiChanBackup.length === "number" || $util.isString(message.multiChanBackup)))
                    return "multiChanBackup: buffer expected";
            return null;
        };

        /**
         * Creates a MultiChanBackup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.MultiChanBackup} MultiChanBackup
         */
        MultiChanBackup.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.MultiChanBackup)
                return object;
            let message = new $root.lnrpc.MultiChanBackup();
            if (object.chanPoints) {
                if (!Array.isArray(object.chanPoints))
                    throw TypeError(".lnrpc.MultiChanBackup.chanPoints: array expected");
                message.chanPoints = [];
                for (let i = 0; i < object.chanPoints.length; ++i) {
                    if (typeof object.chanPoints[i] !== "object")
                        throw TypeError(".lnrpc.MultiChanBackup.chanPoints: object expected");
                    message.chanPoints[i] = $root.lnrpc.ChannelPoint.fromObject(object.chanPoints[i]);
                }
            }
            if (object.multiChanBackup != null)
                if (typeof object.multiChanBackup === "string")
                    $util.base64.decode(object.multiChanBackup, message.multiChanBackup = $util.newBuffer($util.base64.length(object.multiChanBackup)), 0);
                else if (object.multiChanBackup.length)
                    message.multiChanBackup = object.multiChanBackup;
            return message;
        };

        /**
         * Creates a plain object from a MultiChanBackup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.MultiChanBackup
         * @static
         * @param {lnrpc.MultiChanBackup} message MultiChanBackup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MultiChanBackup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.chanPoints = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.multiChanBackup = "";
                else {
                    object.multiChanBackup = [];
                    if (options.bytes !== Array)
                        object.multiChanBackup = $util.newBuffer(object.multiChanBackup);
                }
            if (message.chanPoints && message.chanPoints.length) {
                object.chanPoints = [];
                for (let j = 0; j < message.chanPoints.length; ++j)
                    object.chanPoints[j] = $root.lnrpc.ChannelPoint.toObject(message.chanPoints[j], options);
            }
            if (message.multiChanBackup != null && message.hasOwnProperty("multiChanBackup"))
                object.multiChanBackup = options.bytes === String ? $util.base64.encode(message.multiChanBackup, 0, message.multiChanBackup.length) : options.bytes === Array ? Array.prototype.slice.call(message.multiChanBackup) : message.multiChanBackup;
            return object;
        };

        /**
         * Converts this MultiChanBackup to JSON.
         * @function toJSON
         * @memberof lnrpc.MultiChanBackup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MultiChanBackup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MultiChanBackup;
    })();

    lnrpc.ChanBackupExportRequest = (function() {

        /**
         * Properties of a ChanBackupExportRequest.
         * @memberof lnrpc
         * @interface IChanBackupExportRequest
         */

        /**
         * Constructs a new ChanBackupExportRequest.
         * @memberof lnrpc
         * @classdesc Represents a ChanBackupExportRequest.
         * @implements IChanBackupExportRequest
         * @constructor
         * @param {lnrpc.IChanBackupExportRequest=} [properties] Properties to set
         */
        function ChanBackupExportRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ChanBackupExportRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {lnrpc.IChanBackupExportRequest=} [properties] Properties to set
         * @returns {lnrpc.ChanBackupExportRequest} ChanBackupExportRequest instance
         */
        ChanBackupExportRequest.create = function create(properties) {
            return new ChanBackupExportRequest(properties);
        };

        /**
         * Encodes the specified ChanBackupExportRequest message. Does not implicitly {@link lnrpc.ChanBackupExportRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {lnrpc.IChanBackupExportRequest} message ChanBackupExportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanBackupExportRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ChanBackupExportRequest message, length delimited. Does not implicitly {@link lnrpc.ChanBackupExportRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {lnrpc.IChanBackupExportRequest} message ChanBackupExportRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanBackupExportRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChanBackupExportRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChanBackupExportRequest} ChanBackupExportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanBackupExportRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChanBackupExportRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChanBackupExportRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChanBackupExportRequest} ChanBackupExportRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanBackupExportRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChanBackupExportRequest message.
         * @function verify
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChanBackupExportRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ChanBackupExportRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChanBackupExportRequest} ChanBackupExportRequest
         */
        ChanBackupExportRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChanBackupExportRequest)
                return object;
            return new $root.lnrpc.ChanBackupExportRequest();
        };

        /**
         * Creates a plain object from a ChanBackupExportRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChanBackupExportRequest
         * @static
         * @param {lnrpc.ChanBackupExportRequest} message ChanBackupExportRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChanBackupExportRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ChanBackupExportRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ChanBackupExportRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChanBackupExportRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChanBackupExportRequest;
    })();

    lnrpc.ChanBackupSnapshot = (function() {

        /**
         * Properties of a ChanBackupSnapshot.
         * @memberof lnrpc
         * @interface IChanBackupSnapshot
         * @property {lnrpc.IChannelBackups|null} [singleChanBackups] ChanBackupSnapshot singleChanBackups
         * @property {lnrpc.IMultiChanBackup|null} [multiChanBackup] ChanBackupSnapshot multiChanBackup
         */

        /**
         * Constructs a new ChanBackupSnapshot.
         * @memberof lnrpc
         * @classdesc Represents a ChanBackupSnapshot.
         * @implements IChanBackupSnapshot
         * @constructor
         * @param {lnrpc.IChanBackupSnapshot=} [properties] Properties to set
         */
        function ChanBackupSnapshot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChanBackupSnapshot singleChanBackups.
         * @member {lnrpc.IChannelBackups|null|undefined} singleChanBackups
         * @memberof lnrpc.ChanBackupSnapshot
         * @instance
         */
        ChanBackupSnapshot.prototype.singleChanBackups = null;

        /**
         * ChanBackupSnapshot multiChanBackup.
         * @member {lnrpc.IMultiChanBackup|null|undefined} multiChanBackup
         * @memberof lnrpc.ChanBackupSnapshot
         * @instance
         */
        ChanBackupSnapshot.prototype.multiChanBackup = null;

        /**
         * Creates a new ChanBackupSnapshot instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {lnrpc.IChanBackupSnapshot=} [properties] Properties to set
         * @returns {lnrpc.ChanBackupSnapshot} ChanBackupSnapshot instance
         */
        ChanBackupSnapshot.create = function create(properties) {
            return new ChanBackupSnapshot(properties);
        };

        /**
         * Encodes the specified ChanBackupSnapshot message. Does not implicitly {@link lnrpc.ChanBackupSnapshot.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {lnrpc.IChanBackupSnapshot} message ChanBackupSnapshot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanBackupSnapshot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.singleChanBackups != null && Object.hasOwnProperty.call(message, "singleChanBackups"))
                $root.lnrpc.ChannelBackups.encode(message.singleChanBackups, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.multiChanBackup != null && Object.hasOwnProperty.call(message, "multiChanBackup"))
                $root.lnrpc.MultiChanBackup.encode(message.multiChanBackup, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChanBackupSnapshot message, length delimited. Does not implicitly {@link lnrpc.ChanBackupSnapshot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {lnrpc.IChanBackupSnapshot} message ChanBackupSnapshot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChanBackupSnapshot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChanBackupSnapshot message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChanBackupSnapshot} ChanBackupSnapshot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanBackupSnapshot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChanBackupSnapshot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.singleChanBackups = $root.lnrpc.ChannelBackups.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.multiChanBackup = $root.lnrpc.MultiChanBackup.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChanBackupSnapshot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChanBackupSnapshot} ChanBackupSnapshot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChanBackupSnapshot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChanBackupSnapshot message.
         * @function verify
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChanBackupSnapshot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.singleChanBackups != null && message.hasOwnProperty("singleChanBackups")) {
                let error = $root.lnrpc.ChannelBackups.verify(message.singleChanBackups);
                if (error)
                    return "singleChanBackups." + error;
            }
            if (message.multiChanBackup != null && message.hasOwnProperty("multiChanBackup")) {
                let error = $root.lnrpc.MultiChanBackup.verify(message.multiChanBackup);
                if (error)
                    return "multiChanBackup." + error;
            }
            return null;
        };

        /**
         * Creates a ChanBackupSnapshot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChanBackupSnapshot} ChanBackupSnapshot
         */
        ChanBackupSnapshot.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChanBackupSnapshot)
                return object;
            let message = new $root.lnrpc.ChanBackupSnapshot();
            if (object.singleChanBackups != null) {
                if (typeof object.singleChanBackups !== "object")
                    throw TypeError(".lnrpc.ChanBackupSnapshot.singleChanBackups: object expected");
                message.singleChanBackups = $root.lnrpc.ChannelBackups.fromObject(object.singleChanBackups);
            }
            if (object.multiChanBackup != null) {
                if (typeof object.multiChanBackup !== "object")
                    throw TypeError(".lnrpc.ChanBackupSnapshot.multiChanBackup: object expected");
                message.multiChanBackup = $root.lnrpc.MultiChanBackup.fromObject(object.multiChanBackup);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChanBackupSnapshot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChanBackupSnapshot
         * @static
         * @param {lnrpc.ChanBackupSnapshot} message ChanBackupSnapshot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChanBackupSnapshot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.singleChanBackups = null;
                object.multiChanBackup = null;
            }
            if (message.singleChanBackups != null && message.hasOwnProperty("singleChanBackups"))
                object.singleChanBackups = $root.lnrpc.ChannelBackups.toObject(message.singleChanBackups, options);
            if (message.multiChanBackup != null && message.hasOwnProperty("multiChanBackup"))
                object.multiChanBackup = $root.lnrpc.MultiChanBackup.toObject(message.multiChanBackup, options);
            return object;
        };

        /**
         * Converts this ChanBackupSnapshot to JSON.
         * @function toJSON
         * @memberof lnrpc.ChanBackupSnapshot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChanBackupSnapshot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChanBackupSnapshot;
    })();

    lnrpc.ChannelBackups = (function() {

        /**
         * Properties of a ChannelBackups.
         * @memberof lnrpc
         * @interface IChannelBackups
         * @property {Array.<lnrpc.IChannelBackup>|null} [chanBackups] ChannelBackups chanBackups
         */

        /**
         * Constructs a new ChannelBackups.
         * @memberof lnrpc
         * @classdesc Represents a ChannelBackups.
         * @implements IChannelBackups
         * @constructor
         * @param {lnrpc.IChannelBackups=} [properties] Properties to set
         */
        function ChannelBackups(properties) {
            this.chanBackups = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelBackups chanBackups.
         * @member {Array.<lnrpc.IChannelBackup>} chanBackups
         * @memberof lnrpc.ChannelBackups
         * @instance
         */
        ChannelBackups.prototype.chanBackups = $util.emptyArray;

        /**
         * Creates a new ChannelBackups instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {lnrpc.IChannelBackups=} [properties] Properties to set
         * @returns {lnrpc.ChannelBackups} ChannelBackups instance
         */
        ChannelBackups.create = function create(properties) {
            return new ChannelBackups(properties);
        };

        /**
         * Encodes the specified ChannelBackups message. Does not implicitly {@link lnrpc.ChannelBackups.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {lnrpc.IChannelBackups} message ChannelBackups message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBackups.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanBackups != null && message.chanBackups.length)
                for (let i = 0; i < message.chanBackups.length; ++i)
                    $root.lnrpc.ChannelBackup.encode(message.chanBackups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ChannelBackups message, length delimited. Does not implicitly {@link lnrpc.ChannelBackups.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {lnrpc.IChannelBackups} message ChannelBackups message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBackups.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelBackups message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelBackups} ChannelBackups
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBackups.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelBackups();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.chanBackups && message.chanBackups.length))
                            message.chanBackups = [];
                        message.chanBackups.push($root.lnrpc.ChannelBackup.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelBackups message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelBackups} ChannelBackups
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBackups.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelBackups message.
         * @function verify
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelBackups.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanBackups != null && message.hasOwnProperty("chanBackups")) {
                if (!Array.isArray(message.chanBackups))
                    return "chanBackups: array expected";
                for (let i = 0; i < message.chanBackups.length; ++i) {
                    let error = $root.lnrpc.ChannelBackup.verify(message.chanBackups[i]);
                    if (error)
                        return "chanBackups." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ChannelBackups message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelBackups} ChannelBackups
         */
        ChannelBackups.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelBackups)
                return object;
            let message = new $root.lnrpc.ChannelBackups();
            if (object.chanBackups) {
                if (!Array.isArray(object.chanBackups))
                    throw TypeError(".lnrpc.ChannelBackups.chanBackups: array expected");
                message.chanBackups = [];
                for (let i = 0; i < object.chanBackups.length; ++i) {
                    if (typeof object.chanBackups[i] !== "object")
                        throw TypeError(".lnrpc.ChannelBackups.chanBackups: object expected");
                    message.chanBackups[i] = $root.lnrpc.ChannelBackup.fromObject(object.chanBackups[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ChannelBackups message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelBackups
         * @static
         * @param {lnrpc.ChannelBackups} message ChannelBackups
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelBackups.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.chanBackups = [];
            if (message.chanBackups && message.chanBackups.length) {
                object.chanBackups = [];
                for (let j = 0; j < message.chanBackups.length; ++j)
                    object.chanBackups[j] = $root.lnrpc.ChannelBackup.toObject(message.chanBackups[j], options);
            }
            return object;
        };

        /**
         * Converts this ChannelBackups to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelBackups
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelBackups.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelBackups;
    })();

    lnrpc.RestoreChanBackupRequest = (function() {

        /**
         * Properties of a RestoreChanBackupRequest.
         * @memberof lnrpc
         * @interface IRestoreChanBackupRequest
         * @property {lnrpc.IChannelBackups|null} [chanBackups] RestoreChanBackupRequest chanBackups
         * @property {Uint8Array|null} [multiChanBackup] RestoreChanBackupRequest multiChanBackup
         */

        /**
         * Constructs a new RestoreChanBackupRequest.
         * @memberof lnrpc
         * @classdesc Represents a RestoreChanBackupRequest.
         * @implements IRestoreChanBackupRequest
         * @constructor
         * @param {lnrpc.IRestoreChanBackupRequest=} [properties] Properties to set
         */
        function RestoreChanBackupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RestoreChanBackupRequest chanBackups.
         * @member {lnrpc.IChannelBackups|null|undefined} chanBackups
         * @memberof lnrpc.RestoreChanBackupRequest
         * @instance
         */
        RestoreChanBackupRequest.prototype.chanBackups = null;

        /**
         * RestoreChanBackupRequest multiChanBackup.
         * @member {Uint8Array} multiChanBackup
         * @memberof lnrpc.RestoreChanBackupRequest
         * @instance
         */
        RestoreChanBackupRequest.prototype.multiChanBackup = $util.newBuffer([]);

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RestoreChanBackupRequest backup.
         * @member {"chanBackups"|"multiChanBackup"|undefined} backup
         * @memberof lnrpc.RestoreChanBackupRequest
         * @instance
         */
        Object.defineProperty(RestoreChanBackupRequest.prototype, "backup", {
            get: $util.oneOfGetter($oneOfFields = ["chanBackups", "multiChanBackup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RestoreChanBackupRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {lnrpc.IRestoreChanBackupRequest=} [properties] Properties to set
         * @returns {lnrpc.RestoreChanBackupRequest} RestoreChanBackupRequest instance
         */
        RestoreChanBackupRequest.create = function create(properties) {
            return new RestoreChanBackupRequest(properties);
        };

        /**
         * Encodes the specified RestoreChanBackupRequest message. Does not implicitly {@link lnrpc.RestoreChanBackupRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {lnrpc.IRestoreChanBackupRequest} message RestoreChanBackupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RestoreChanBackupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanBackups != null && Object.hasOwnProperty.call(message, "chanBackups"))
                $root.lnrpc.ChannelBackups.encode(message.chanBackups, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.multiChanBackup != null && Object.hasOwnProperty.call(message, "multiChanBackup"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.multiChanBackup);
            return writer;
        };

        /**
         * Encodes the specified RestoreChanBackupRequest message, length delimited. Does not implicitly {@link lnrpc.RestoreChanBackupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {lnrpc.IRestoreChanBackupRequest} message RestoreChanBackupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RestoreChanBackupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RestoreChanBackupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RestoreChanBackupRequest} RestoreChanBackupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RestoreChanBackupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RestoreChanBackupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanBackups = $root.lnrpc.ChannelBackups.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.multiChanBackup = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RestoreChanBackupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RestoreChanBackupRequest} RestoreChanBackupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RestoreChanBackupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RestoreChanBackupRequest message.
         * @function verify
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RestoreChanBackupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.chanBackups != null && message.hasOwnProperty("chanBackups")) {
                properties.backup = 1;
                {
                    let error = $root.lnrpc.ChannelBackups.verify(message.chanBackups);
                    if (error)
                        return "chanBackups." + error;
                }
            }
            if (message.multiChanBackup != null && message.hasOwnProperty("multiChanBackup")) {
                if (properties.backup === 1)
                    return "backup: multiple values";
                properties.backup = 1;
                if (!(message.multiChanBackup && typeof message.multiChanBackup.length === "number" || $util.isString(message.multiChanBackup)))
                    return "multiChanBackup: buffer expected";
            }
            return null;
        };

        /**
         * Creates a RestoreChanBackupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RestoreChanBackupRequest} RestoreChanBackupRequest
         */
        RestoreChanBackupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RestoreChanBackupRequest)
                return object;
            let message = new $root.lnrpc.RestoreChanBackupRequest();
            if (object.chanBackups != null) {
                if (typeof object.chanBackups !== "object")
                    throw TypeError(".lnrpc.RestoreChanBackupRequest.chanBackups: object expected");
                message.chanBackups = $root.lnrpc.ChannelBackups.fromObject(object.chanBackups);
            }
            if (object.multiChanBackup != null)
                if (typeof object.multiChanBackup === "string")
                    $util.base64.decode(object.multiChanBackup, message.multiChanBackup = $util.newBuffer($util.base64.length(object.multiChanBackup)), 0);
                else if (object.multiChanBackup.length)
                    message.multiChanBackup = object.multiChanBackup;
            return message;
        };

        /**
         * Creates a plain object from a RestoreChanBackupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RestoreChanBackupRequest
         * @static
         * @param {lnrpc.RestoreChanBackupRequest} message RestoreChanBackupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RestoreChanBackupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.chanBackups != null && message.hasOwnProperty("chanBackups")) {
                object.chanBackups = $root.lnrpc.ChannelBackups.toObject(message.chanBackups, options);
                if (options.oneofs)
                    object.backup = "chanBackups";
            }
            if (message.multiChanBackup != null && message.hasOwnProperty("multiChanBackup")) {
                object.multiChanBackup = options.bytes === String ? $util.base64.encode(message.multiChanBackup, 0, message.multiChanBackup.length) : options.bytes === Array ? Array.prototype.slice.call(message.multiChanBackup) : message.multiChanBackup;
                if (options.oneofs)
                    object.backup = "multiChanBackup";
            }
            return object;
        };

        /**
         * Converts this RestoreChanBackupRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.RestoreChanBackupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RestoreChanBackupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RestoreChanBackupRequest;
    })();

    lnrpc.RestoreBackupResponse = (function() {

        /**
         * Properties of a RestoreBackupResponse.
         * @memberof lnrpc
         * @interface IRestoreBackupResponse
         */

        /**
         * Constructs a new RestoreBackupResponse.
         * @memberof lnrpc
         * @classdesc Represents a RestoreBackupResponse.
         * @implements IRestoreBackupResponse
         * @constructor
         * @param {lnrpc.IRestoreBackupResponse=} [properties] Properties to set
         */
        function RestoreBackupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RestoreBackupResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {lnrpc.IRestoreBackupResponse=} [properties] Properties to set
         * @returns {lnrpc.RestoreBackupResponse} RestoreBackupResponse instance
         */
        RestoreBackupResponse.create = function create(properties) {
            return new RestoreBackupResponse(properties);
        };

        /**
         * Encodes the specified RestoreBackupResponse message. Does not implicitly {@link lnrpc.RestoreBackupResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {lnrpc.IRestoreBackupResponse} message RestoreBackupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RestoreBackupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RestoreBackupResponse message, length delimited. Does not implicitly {@link lnrpc.RestoreBackupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {lnrpc.IRestoreBackupResponse} message RestoreBackupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RestoreBackupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RestoreBackupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RestoreBackupResponse} RestoreBackupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RestoreBackupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RestoreBackupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RestoreBackupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RestoreBackupResponse} RestoreBackupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RestoreBackupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RestoreBackupResponse message.
         * @function verify
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RestoreBackupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RestoreBackupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RestoreBackupResponse} RestoreBackupResponse
         */
        RestoreBackupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RestoreBackupResponse)
                return object;
            return new $root.lnrpc.RestoreBackupResponse();
        };

        /**
         * Creates a plain object from a RestoreBackupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RestoreBackupResponse
         * @static
         * @param {lnrpc.RestoreBackupResponse} message RestoreBackupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RestoreBackupResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RestoreBackupResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.RestoreBackupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RestoreBackupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RestoreBackupResponse;
    })();

    lnrpc.ChannelBackupSubscription = (function() {

        /**
         * Properties of a ChannelBackupSubscription.
         * @memberof lnrpc
         * @interface IChannelBackupSubscription
         */

        /**
         * Constructs a new ChannelBackupSubscription.
         * @memberof lnrpc
         * @classdesc Represents a ChannelBackupSubscription.
         * @implements IChannelBackupSubscription
         * @constructor
         * @param {lnrpc.IChannelBackupSubscription=} [properties] Properties to set
         */
        function ChannelBackupSubscription(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ChannelBackupSubscription instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {lnrpc.IChannelBackupSubscription=} [properties] Properties to set
         * @returns {lnrpc.ChannelBackupSubscription} ChannelBackupSubscription instance
         */
        ChannelBackupSubscription.create = function create(properties) {
            return new ChannelBackupSubscription(properties);
        };

        /**
         * Encodes the specified ChannelBackupSubscription message. Does not implicitly {@link lnrpc.ChannelBackupSubscription.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {lnrpc.IChannelBackupSubscription} message ChannelBackupSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBackupSubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ChannelBackupSubscription message, length delimited. Does not implicitly {@link lnrpc.ChannelBackupSubscription.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {lnrpc.IChannelBackupSubscription} message ChannelBackupSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelBackupSubscription.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelBackupSubscription message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelBackupSubscription} ChannelBackupSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBackupSubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelBackupSubscription();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelBackupSubscription message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelBackupSubscription} ChannelBackupSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelBackupSubscription.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelBackupSubscription message.
         * @function verify
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelBackupSubscription.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ChannelBackupSubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelBackupSubscription} ChannelBackupSubscription
         */
        ChannelBackupSubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelBackupSubscription)
                return object;
            return new $root.lnrpc.ChannelBackupSubscription();
        };

        /**
         * Creates a plain object from a ChannelBackupSubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelBackupSubscription
         * @static
         * @param {lnrpc.ChannelBackupSubscription} message ChannelBackupSubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelBackupSubscription.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ChannelBackupSubscription to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelBackupSubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelBackupSubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelBackupSubscription;
    })();

    lnrpc.VerifyChanBackupResponse = (function() {

        /**
         * Properties of a VerifyChanBackupResponse.
         * @memberof lnrpc
         * @interface IVerifyChanBackupResponse
         */

        /**
         * Constructs a new VerifyChanBackupResponse.
         * @memberof lnrpc
         * @classdesc Represents a VerifyChanBackupResponse.
         * @implements IVerifyChanBackupResponse
         * @constructor
         * @param {lnrpc.IVerifyChanBackupResponse=} [properties] Properties to set
         */
        function VerifyChanBackupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyChanBackupResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {lnrpc.IVerifyChanBackupResponse=} [properties] Properties to set
         * @returns {lnrpc.VerifyChanBackupResponse} VerifyChanBackupResponse instance
         */
        VerifyChanBackupResponse.create = function create(properties) {
            return new VerifyChanBackupResponse(properties);
        };

        /**
         * Encodes the specified VerifyChanBackupResponse message. Does not implicitly {@link lnrpc.VerifyChanBackupResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {lnrpc.IVerifyChanBackupResponse} message VerifyChanBackupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyChanBackupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyChanBackupResponse message, length delimited. Does not implicitly {@link lnrpc.VerifyChanBackupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {lnrpc.IVerifyChanBackupResponse} message VerifyChanBackupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyChanBackupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyChanBackupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.VerifyChanBackupResponse} VerifyChanBackupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyChanBackupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.VerifyChanBackupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyChanBackupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.VerifyChanBackupResponse} VerifyChanBackupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyChanBackupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyChanBackupResponse message.
         * @function verify
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyChanBackupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyChanBackupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.VerifyChanBackupResponse} VerifyChanBackupResponse
         */
        VerifyChanBackupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.VerifyChanBackupResponse)
                return object;
            return new $root.lnrpc.VerifyChanBackupResponse();
        };

        /**
         * Creates a plain object from a VerifyChanBackupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.VerifyChanBackupResponse
         * @static
         * @param {lnrpc.VerifyChanBackupResponse} message VerifyChanBackupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyChanBackupResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyChanBackupResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.VerifyChanBackupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyChanBackupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyChanBackupResponse;
    })();

    lnrpc.MacaroonPermission = (function() {

        /**
         * Properties of a MacaroonPermission.
         * @memberof lnrpc
         * @interface IMacaroonPermission
         * @property {string|null} [entity] MacaroonPermission entity
         * @property {string|null} [action] MacaroonPermission action
         */

        /**
         * Constructs a new MacaroonPermission.
         * @memberof lnrpc
         * @classdesc Represents a MacaroonPermission.
         * @implements IMacaroonPermission
         * @constructor
         * @param {lnrpc.IMacaroonPermission=} [properties] Properties to set
         */
        function MacaroonPermission(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MacaroonPermission entity.
         * @member {string} entity
         * @memberof lnrpc.MacaroonPermission
         * @instance
         */
        MacaroonPermission.prototype.entity = "";

        /**
         * MacaroonPermission action.
         * @member {string} action
         * @memberof lnrpc.MacaroonPermission
         * @instance
         */
        MacaroonPermission.prototype.action = "";

        /**
         * Creates a new MacaroonPermission instance using the specified properties.
         * @function create
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {lnrpc.IMacaroonPermission=} [properties] Properties to set
         * @returns {lnrpc.MacaroonPermission} MacaroonPermission instance
         */
        MacaroonPermission.create = function create(properties) {
            return new MacaroonPermission(properties);
        };

        /**
         * Encodes the specified MacaroonPermission message. Does not implicitly {@link lnrpc.MacaroonPermission.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {lnrpc.IMacaroonPermission} message MacaroonPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MacaroonPermission.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.entity);
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.action);
            return writer;
        };

        /**
         * Encodes the specified MacaroonPermission message, length delimited. Does not implicitly {@link lnrpc.MacaroonPermission.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {lnrpc.IMacaroonPermission} message MacaroonPermission message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MacaroonPermission.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MacaroonPermission message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.MacaroonPermission} MacaroonPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MacaroonPermission.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.MacaroonPermission();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entity = reader.string();
                        break;
                    }
                case 2: {
                        message.action = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MacaroonPermission message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.MacaroonPermission} MacaroonPermission
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MacaroonPermission.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MacaroonPermission message.
         * @function verify
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MacaroonPermission.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.entity != null && message.hasOwnProperty("entity"))
                if (!$util.isString(message.entity))
                    return "entity: string expected";
            if (message.action != null && message.hasOwnProperty("action"))
                if (!$util.isString(message.action))
                    return "action: string expected";
            return null;
        };

        /**
         * Creates a MacaroonPermission message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.MacaroonPermission} MacaroonPermission
         */
        MacaroonPermission.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.MacaroonPermission)
                return object;
            let message = new $root.lnrpc.MacaroonPermission();
            if (object.entity != null)
                message.entity = String(object.entity);
            if (object.action != null)
                message.action = String(object.action);
            return message;
        };

        /**
         * Creates a plain object from a MacaroonPermission message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.MacaroonPermission
         * @static
         * @param {lnrpc.MacaroonPermission} message MacaroonPermission
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MacaroonPermission.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.entity = "";
                object.action = "";
            }
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = message.entity;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = message.action;
            return object;
        };

        /**
         * Converts this MacaroonPermission to JSON.
         * @function toJSON
         * @memberof lnrpc.MacaroonPermission
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MacaroonPermission.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MacaroonPermission;
    })();

    lnrpc.BakeMacaroonRequest = (function() {

        /**
         * Properties of a BakeMacaroonRequest.
         * @memberof lnrpc
         * @interface IBakeMacaroonRequest
         * @property {Array.<lnrpc.IMacaroonPermission>|null} [permissions] BakeMacaroonRequest permissions
         * @property {Long|null} [rootKeyId] BakeMacaroonRequest rootKeyId
         * @property {boolean|null} [allowExternalPermissions] BakeMacaroonRequest allowExternalPermissions
         */

        /**
         * Constructs a new BakeMacaroonRequest.
         * @memberof lnrpc
         * @classdesc Represents a BakeMacaroonRequest.
         * @implements IBakeMacaroonRequest
         * @constructor
         * @param {lnrpc.IBakeMacaroonRequest=} [properties] Properties to set
         */
        function BakeMacaroonRequest(properties) {
            this.permissions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BakeMacaroonRequest permissions.
         * @member {Array.<lnrpc.IMacaroonPermission>} permissions
         * @memberof lnrpc.BakeMacaroonRequest
         * @instance
         */
        BakeMacaroonRequest.prototype.permissions = $util.emptyArray;

        /**
         * BakeMacaroonRequest rootKeyId.
         * @member {Long} rootKeyId
         * @memberof lnrpc.BakeMacaroonRequest
         * @instance
         */
        BakeMacaroonRequest.prototype.rootKeyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BakeMacaroonRequest allowExternalPermissions.
         * @member {boolean} allowExternalPermissions
         * @memberof lnrpc.BakeMacaroonRequest
         * @instance
         */
        BakeMacaroonRequest.prototype.allowExternalPermissions = false;

        /**
         * Creates a new BakeMacaroonRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {lnrpc.IBakeMacaroonRequest=} [properties] Properties to set
         * @returns {lnrpc.BakeMacaroonRequest} BakeMacaroonRequest instance
         */
        BakeMacaroonRequest.create = function create(properties) {
            return new BakeMacaroonRequest(properties);
        };

        /**
         * Encodes the specified BakeMacaroonRequest message. Does not implicitly {@link lnrpc.BakeMacaroonRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {lnrpc.IBakeMacaroonRequest} message BakeMacaroonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BakeMacaroonRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.permissions != null && message.permissions.length)
                for (let i = 0; i < message.permissions.length; ++i)
                    $root.lnrpc.MacaroonPermission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rootKeyId != null && Object.hasOwnProperty.call(message, "rootKeyId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.rootKeyId);
            if (message.allowExternalPermissions != null && Object.hasOwnProperty.call(message, "allowExternalPermissions"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.allowExternalPermissions);
            return writer;
        };

        /**
         * Encodes the specified BakeMacaroonRequest message, length delimited. Does not implicitly {@link lnrpc.BakeMacaroonRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {lnrpc.IBakeMacaroonRequest} message BakeMacaroonRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BakeMacaroonRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BakeMacaroonRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.BakeMacaroonRequest} BakeMacaroonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BakeMacaroonRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.BakeMacaroonRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.permissions && message.permissions.length))
                            message.permissions = [];
                        message.permissions.push($root.lnrpc.MacaroonPermission.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.rootKeyId = reader.uint64();
                        break;
                    }
                case 3: {
                        message.allowExternalPermissions = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BakeMacaroonRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.BakeMacaroonRequest} BakeMacaroonRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BakeMacaroonRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BakeMacaroonRequest message.
         * @function verify
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BakeMacaroonRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                if (!Array.isArray(message.permissions))
                    return "permissions: array expected";
                for (let i = 0; i < message.permissions.length; ++i) {
                    let error = $root.lnrpc.MacaroonPermission.verify(message.permissions[i]);
                    if (error)
                        return "permissions." + error;
                }
            }
            if (message.rootKeyId != null && message.hasOwnProperty("rootKeyId"))
                if (!$util.isInteger(message.rootKeyId) && !(message.rootKeyId && $util.isInteger(message.rootKeyId.low) && $util.isInteger(message.rootKeyId.high)))
                    return "rootKeyId: integer|Long expected";
            if (message.allowExternalPermissions != null && message.hasOwnProperty("allowExternalPermissions"))
                if (typeof message.allowExternalPermissions !== "boolean")
                    return "allowExternalPermissions: boolean expected";
            return null;
        };

        /**
         * Creates a BakeMacaroonRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.BakeMacaroonRequest} BakeMacaroonRequest
         */
        BakeMacaroonRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.BakeMacaroonRequest)
                return object;
            let message = new $root.lnrpc.BakeMacaroonRequest();
            if (object.permissions) {
                if (!Array.isArray(object.permissions))
                    throw TypeError(".lnrpc.BakeMacaroonRequest.permissions: array expected");
                message.permissions = [];
                for (let i = 0; i < object.permissions.length; ++i) {
                    if (typeof object.permissions[i] !== "object")
                        throw TypeError(".lnrpc.BakeMacaroonRequest.permissions: object expected");
                    message.permissions[i] = $root.lnrpc.MacaroonPermission.fromObject(object.permissions[i]);
                }
            }
            if (object.rootKeyId != null)
                if ($util.Long)
                    (message.rootKeyId = $util.Long.fromValue(object.rootKeyId)).unsigned = true;
                else if (typeof object.rootKeyId === "string")
                    message.rootKeyId = parseInt(object.rootKeyId, 10);
                else if (typeof object.rootKeyId === "number")
                    message.rootKeyId = object.rootKeyId;
                else if (typeof object.rootKeyId === "object")
                    message.rootKeyId = new $util.LongBits(object.rootKeyId.low >>> 0, object.rootKeyId.high >>> 0).toNumber(true);
            if (object.allowExternalPermissions != null)
                message.allowExternalPermissions = Boolean(object.allowExternalPermissions);
            return message;
        };

        /**
         * Creates a plain object from a BakeMacaroonRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.BakeMacaroonRequest
         * @static
         * @param {lnrpc.BakeMacaroonRequest} message BakeMacaroonRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BakeMacaroonRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.permissions = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.rootKeyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.rootKeyId = options.longs === String ? "0" : 0;
                object.allowExternalPermissions = false;
            }
            if (message.permissions && message.permissions.length) {
                object.permissions = [];
                for (let j = 0; j < message.permissions.length; ++j)
                    object.permissions[j] = $root.lnrpc.MacaroonPermission.toObject(message.permissions[j], options);
            }
            if (message.rootKeyId != null && message.hasOwnProperty("rootKeyId"))
                if (typeof message.rootKeyId === "number")
                    object.rootKeyId = options.longs === String ? String(message.rootKeyId) : message.rootKeyId;
                else
                    object.rootKeyId = options.longs === String ? $util.Long.prototype.toString.call(message.rootKeyId) : options.longs === Number ? new $util.LongBits(message.rootKeyId.low >>> 0, message.rootKeyId.high >>> 0).toNumber(true) : message.rootKeyId;
            if (message.allowExternalPermissions != null && message.hasOwnProperty("allowExternalPermissions"))
                object.allowExternalPermissions = message.allowExternalPermissions;
            return object;
        };

        /**
         * Converts this BakeMacaroonRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.BakeMacaroonRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BakeMacaroonRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BakeMacaroonRequest;
    })();

    lnrpc.BakeMacaroonResponse = (function() {

        /**
         * Properties of a BakeMacaroonResponse.
         * @memberof lnrpc
         * @interface IBakeMacaroonResponse
         * @property {string|null} [macaroon] BakeMacaroonResponse macaroon
         */

        /**
         * Constructs a new BakeMacaroonResponse.
         * @memberof lnrpc
         * @classdesc Represents a BakeMacaroonResponse.
         * @implements IBakeMacaroonResponse
         * @constructor
         * @param {lnrpc.IBakeMacaroonResponse=} [properties] Properties to set
         */
        function BakeMacaroonResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BakeMacaroonResponse macaroon.
         * @member {string} macaroon
         * @memberof lnrpc.BakeMacaroonResponse
         * @instance
         */
        BakeMacaroonResponse.prototype.macaroon = "";

        /**
         * Creates a new BakeMacaroonResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {lnrpc.IBakeMacaroonResponse=} [properties] Properties to set
         * @returns {lnrpc.BakeMacaroonResponse} BakeMacaroonResponse instance
         */
        BakeMacaroonResponse.create = function create(properties) {
            return new BakeMacaroonResponse(properties);
        };

        /**
         * Encodes the specified BakeMacaroonResponse message. Does not implicitly {@link lnrpc.BakeMacaroonResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {lnrpc.IBakeMacaroonResponse} message BakeMacaroonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BakeMacaroonResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.macaroon != null && Object.hasOwnProperty.call(message, "macaroon"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.macaroon);
            return writer;
        };

        /**
         * Encodes the specified BakeMacaroonResponse message, length delimited. Does not implicitly {@link lnrpc.BakeMacaroonResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {lnrpc.IBakeMacaroonResponse} message BakeMacaroonResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BakeMacaroonResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BakeMacaroonResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.BakeMacaroonResponse} BakeMacaroonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BakeMacaroonResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.BakeMacaroonResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.macaroon = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BakeMacaroonResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.BakeMacaroonResponse} BakeMacaroonResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BakeMacaroonResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BakeMacaroonResponse message.
         * @function verify
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BakeMacaroonResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.macaroon != null && message.hasOwnProperty("macaroon"))
                if (!$util.isString(message.macaroon))
                    return "macaroon: string expected";
            return null;
        };

        /**
         * Creates a BakeMacaroonResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.BakeMacaroonResponse} BakeMacaroonResponse
         */
        BakeMacaroonResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.BakeMacaroonResponse)
                return object;
            let message = new $root.lnrpc.BakeMacaroonResponse();
            if (object.macaroon != null)
                message.macaroon = String(object.macaroon);
            return message;
        };

        /**
         * Creates a plain object from a BakeMacaroonResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.BakeMacaroonResponse
         * @static
         * @param {lnrpc.BakeMacaroonResponse} message BakeMacaroonResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BakeMacaroonResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.macaroon = "";
            if (message.macaroon != null && message.hasOwnProperty("macaroon"))
                object.macaroon = message.macaroon;
            return object;
        };

        /**
         * Converts this BakeMacaroonResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.BakeMacaroonResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BakeMacaroonResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BakeMacaroonResponse;
    })();

    lnrpc.ListMacaroonIDsRequest = (function() {

        /**
         * Properties of a ListMacaroonIDsRequest.
         * @memberof lnrpc
         * @interface IListMacaroonIDsRequest
         */

        /**
         * Constructs a new ListMacaroonIDsRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListMacaroonIDsRequest.
         * @implements IListMacaroonIDsRequest
         * @constructor
         * @param {lnrpc.IListMacaroonIDsRequest=} [properties] Properties to set
         */
        function ListMacaroonIDsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ListMacaroonIDsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {lnrpc.IListMacaroonIDsRequest=} [properties] Properties to set
         * @returns {lnrpc.ListMacaroonIDsRequest} ListMacaroonIDsRequest instance
         */
        ListMacaroonIDsRequest.create = function create(properties) {
            return new ListMacaroonIDsRequest(properties);
        };

        /**
         * Encodes the specified ListMacaroonIDsRequest message. Does not implicitly {@link lnrpc.ListMacaroonIDsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {lnrpc.IListMacaroonIDsRequest} message ListMacaroonIDsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListMacaroonIDsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ListMacaroonIDsRequest message, length delimited. Does not implicitly {@link lnrpc.ListMacaroonIDsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {lnrpc.IListMacaroonIDsRequest} message ListMacaroonIDsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListMacaroonIDsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListMacaroonIDsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListMacaroonIDsRequest} ListMacaroonIDsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListMacaroonIDsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListMacaroonIDsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListMacaroonIDsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListMacaroonIDsRequest} ListMacaroonIDsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListMacaroonIDsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListMacaroonIDsRequest message.
         * @function verify
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListMacaroonIDsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ListMacaroonIDsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListMacaroonIDsRequest} ListMacaroonIDsRequest
         */
        ListMacaroonIDsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListMacaroonIDsRequest)
                return object;
            return new $root.lnrpc.ListMacaroonIDsRequest();
        };

        /**
         * Creates a plain object from a ListMacaroonIDsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @static
         * @param {lnrpc.ListMacaroonIDsRequest} message ListMacaroonIDsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListMacaroonIDsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ListMacaroonIDsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListMacaroonIDsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListMacaroonIDsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListMacaroonIDsRequest;
    })();

    lnrpc.ListMacaroonIDsResponse = (function() {

        /**
         * Properties of a ListMacaroonIDsResponse.
         * @memberof lnrpc
         * @interface IListMacaroonIDsResponse
         * @property {Array.<Long>|null} [rootKeyIds] ListMacaroonIDsResponse rootKeyIds
         */

        /**
         * Constructs a new ListMacaroonIDsResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListMacaroonIDsResponse.
         * @implements IListMacaroonIDsResponse
         * @constructor
         * @param {lnrpc.IListMacaroonIDsResponse=} [properties] Properties to set
         */
        function ListMacaroonIDsResponse(properties) {
            this.rootKeyIds = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListMacaroonIDsResponse rootKeyIds.
         * @member {Array.<Long>} rootKeyIds
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @instance
         */
        ListMacaroonIDsResponse.prototype.rootKeyIds = $util.emptyArray;

        /**
         * Creates a new ListMacaroonIDsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {lnrpc.IListMacaroonIDsResponse=} [properties] Properties to set
         * @returns {lnrpc.ListMacaroonIDsResponse} ListMacaroonIDsResponse instance
         */
        ListMacaroonIDsResponse.create = function create(properties) {
            return new ListMacaroonIDsResponse(properties);
        };

        /**
         * Encodes the specified ListMacaroonIDsResponse message. Does not implicitly {@link lnrpc.ListMacaroonIDsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {lnrpc.IListMacaroonIDsResponse} message ListMacaroonIDsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListMacaroonIDsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rootKeyIds != null && message.rootKeyIds.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.rootKeyIds.length; ++i)
                    writer.uint64(message.rootKeyIds[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified ListMacaroonIDsResponse message, length delimited. Does not implicitly {@link lnrpc.ListMacaroonIDsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {lnrpc.IListMacaroonIDsResponse} message ListMacaroonIDsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListMacaroonIDsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListMacaroonIDsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListMacaroonIDsResponse} ListMacaroonIDsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListMacaroonIDsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListMacaroonIDsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rootKeyIds && message.rootKeyIds.length))
                            message.rootKeyIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.rootKeyIds.push(reader.uint64());
                        } else
                            message.rootKeyIds.push(reader.uint64());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListMacaroonIDsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListMacaroonIDsResponse} ListMacaroonIDsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListMacaroonIDsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListMacaroonIDsResponse message.
         * @function verify
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListMacaroonIDsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rootKeyIds != null && message.hasOwnProperty("rootKeyIds")) {
                if (!Array.isArray(message.rootKeyIds))
                    return "rootKeyIds: array expected";
                for (let i = 0; i < message.rootKeyIds.length; ++i)
                    if (!$util.isInteger(message.rootKeyIds[i]) && !(message.rootKeyIds[i] && $util.isInteger(message.rootKeyIds[i].low) && $util.isInteger(message.rootKeyIds[i].high)))
                        return "rootKeyIds: integer|Long[] expected";
            }
            return null;
        };

        /**
         * Creates a ListMacaroonIDsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListMacaroonIDsResponse} ListMacaroonIDsResponse
         */
        ListMacaroonIDsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListMacaroonIDsResponse)
                return object;
            let message = new $root.lnrpc.ListMacaroonIDsResponse();
            if (object.rootKeyIds) {
                if (!Array.isArray(object.rootKeyIds))
                    throw TypeError(".lnrpc.ListMacaroonIDsResponse.rootKeyIds: array expected");
                message.rootKeyIds = [];
                for (let i = 0; i < object.rootKeyIds.length; ++i)
                    if ($util.Long)
                        (message.rootKeyIds[i] = $util.Long.fromValue(object.rootKeyIds[i])).unsigned = true;
                    else if (typeof object.rootKeyIds[i] === "string")
                        message.rootKeyIds[i] = parseInt(object.rootKeyIds[i], 10);
                    else if (typeof object.rootKeyIds[i] === "number")
                        message.rootKeyIds[i] = object.rootKeyIds[i];
                    else if (typeof object.rootKeyIds[i] === "object")
                        message.rootKeyIds[i] = new $util.LongBits(object.rootKeyIds[i].low >>> 0, object.rootKeyIds[i].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from a ListMacaroonIDsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @static
         * @param {lnrpc.ListMacaroonIDsResponse} message ListMacaroonIDsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListMacaroonIDsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rootKeyIds = [];
            if (message.rootKeyIds && message.rootKeyIds.length) {
                object.rootKeyIds = [];
                for (let j = 0; j < message.rootKeyIds.length; ++j)
                    if (typeof message.rootKeyIds[j] === "number")
                        object.rootKeyIds[j] = options.longs === String ? String(message.rootKeyIds[j]) : message.rootKeyIds[j];
                    else
                        object.rootKeyIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.rootKeyIds[j]) : options.longs === Number ? new $util.LongBits(message.rootKeyIds[j].low >>> 0, message.rootKeyIds[j].high >>> 0).toNumber(true) : message.rootKeyIds[j];
            }
            return object;
        };

        /**
         * Converts this ListMacaroonIDsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListMacaroonIDsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListMacaroonIDsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListMacaroonIDsResponse;
    })();

    lnrpc.DeleteMacaroonIDRequest = (function() {

        /**
         * Properties of a DeleteMacaroonIDRequest.
         * @memberof lnrpc
         * @interface IDeleteMacaroonIDRequest
         * @property {Long|null} [rootKeyId] DeleteMacaroonIDRequest rootKeyId
         */

        /**
         * Constructs a new DeleteMacaroonIDRequest.
         * @memberof lnrpc
         * @classdesc Represents a DeleteMacaroonIDRequest.
         * @implements IDeleteMacaroonIDRequest
         * @constructor
         * @param {lnrpc.IDeleteMacaroonIDRequest=} [properties] Properties to set
         */
        function DeleteMacaroonIDRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteMacaroonIDRequest rootKeyId.
         * @member {Long} rootKeyId
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @instance
         */
        DeleteMacaroonIDRequest.prototype.rootKeyId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new DeleteMacaroonIDRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {lnrpc.IDeleteMacaroonIDRequest=} [properties] Properties to set
         * @returns {lnrpc.DeleteMacaroonIDRequest} DeleteMacaroonIDRequest instance
         */
        DeleteMacaroonIDRequest.create = function create(properties) {
            return new DeleteMacaroonIDRequest(properties);
        };

        /**
         * Encodes the specified DeleteMacaroonIDRequest message. Does not implicitly {@link lnrpc.DeleteMacaroonIDRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {lnrpc.IDeleteMacaroonIDRequest} message DeleteMacaroonIDRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMacaroonIDRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rootKeyId != null && Object.hasOwnProperty.call(message, "rootKeyId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.rootKeyId);
            return writer;
        };

        /**
         * Encodes the specified DeleteMacaroonIDRequest message, length delimited. Does not implicitly {@link lnrpc.DeleteMacaroonIDRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {lnrpc.IDeleteMacaroonIDRequest} message DeleteMacaroonIDRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMacaroonIDRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteMacaroonIDRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DeleteMacaroonIDRequest} DeleteMacaroonIDRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMacaroonIDRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DeleteMacaroonIDRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rootKeyId = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteMacaroonIDRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DeleteMacaroonIDRequest} DeleteMacaroonIDRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMacaroonIDRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteMacaroonIDRequest message.
         * @function verify
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteMacaroonIDRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rootKeyId != null && message.hasOwnProperty("rootKeyId"))
                if (!$util.isInteger(message.rootKeyId) && !(message.rootKeyId && $util.isInteger(message.rootKeyId.low) && $util.isInteger(message.rootKeyId.high)))
                    return "rootKeyId: integer|Long expected";
            return null;
        };

        /**
         * Creates a DeleteMacaroonIDRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DeleteMacaroonIDRequest} DeleteMacaroonIDRequest
         */
        DeleteMacaroonIDRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DeleteMacaroonIDRequest)
                return object;
            let message = new $root.lnrpc.DeleteMacaroonIDRequest();
            if (object.rootKeyId != null)
                if ($util.Long)
                    (message.rootKeyId = $util.Long.fromValue(object.rootKeyId)).unsigned = true;
                else if (typeof object.rootKeyId === "string")
                    message.rootKeyId = parseInt(object.rootKeyId, 10);
                else if (typeof object.rootKeyId === "number")
                    message.rootKeyId = object.rootKeyId;
                else if (typeof object.rootKeyId === "object")
                    message.rootKeyId = new $util.LongBits(object.rootKeyId.low >>> 0, object.rootKeyId.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a DeleteMacaroonIDRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @static
         * @param {lnrpc.DeleteMacaroonIDRequest} message DeleteMacaroonIDRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMacaroonIDRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.rootKeyId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.rootKeyId = options.longs === String ? "0" : 0;
            if (message.rootKeyId != null && message.hasOwnProperty("rootKeyId"))
                if (typeof message.rootKeyId === "number")
                    object.rootKeyId = options.longs === String ? String(message.rootKeyId) : message.rootKeyId;
                else
                    object.rootKeyId = options.longs === String ? $util.Long.prototype.toString.call(message.rootKeyId) : options.longs === Number ? new $util.LongBits(message.rootKeyId.low >>> 0, message.rootKeyId.high >>> 0).toNumber(true) : message.rootKeyId;
            return object;
        };

        /**
         * Converts this DeleteMacaroonIDRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.DeleteMacaroonIDRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMacaroonIDRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteMacaroonIDRequest;
    })();

    lnrpc.DeleteMacaroonIDResponse = (function() {

        /**
         * Properties of a DeleteMacaroonIDResponse.
         * @memberof lnrpc
         * @interface IDeleteMacaroonIDResponse
         * @property {boolean|null} [deleted] DeleteMacaroonIDResponse deleted
         */

        /**
         * Constructs a new DeleteMacaroonIDResponse.
         * @memberof lnrpc
         * @classdesc Represents a DeleteMacaroonIDResponse.
         * @implements IDeleteMacaroonIDResponse
         * @constructor
         * @param {lnrpc.IDeleteMacaroonIDResponse=} [properties] Properties to set
         */
        function DeleteMacaroonIDResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteMacaroonIDResponse deleted.
         * @member {boolean} deleted
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @instance
         */
        DeleteMacaroonIDResponse.prototype.deleted = false;

        /**
         * Creates a new DeleteMacaroonIDResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {lnrpc.IDeleteMacaroonIDResponse=} [properties] Properties to set
         * @returns {lnrpc.DeleteMacaroonIDResponse} DeleteMacaroonIDResponse instance
         */
        DeleteMacaroonIDResponse.create = function create(properties) {
            return new DeleteMacaroonIDResponse(properties);
        };

        /**
         * Encodes the specified DeleteMacaroonIDResponse message. Does not implicitly {@link lnrpc.DeleteMacaroonIDResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {lnrpc.IDeleteMacaroonIDResponse} message DeleteMacaroonIDResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMacaroonIDResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deleted);
            return writer;
        };

        /**
         * Encodes the specified DeleteMacaroonIDResponse message, length delimited. Does not implicitly {@link lnrpc.DeleteMacaroonIDResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {lnrpc.IDeleteMacaroonIDResponse} message DeleteMacaroonIDResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMacaroonIDResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteMacaroonIDResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.DeleteMacaroonIDResponse} DeleteMacaroonIDResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMacaroonIDResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.DeleteMacaroonIDResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.deleted = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteMacaroonIDResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.DeleteMacaroonIDResponse} DeleteMacaroonIDResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMacaroonIDResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteMacaroonIDResponse message.
         * @function verify
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteMacaroonIDResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.deleted != null && message.hasOwnProperty("deleted"))
                if (typeof message.deleted !== "boolean")
                    return "deleted: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteMacaroonIDResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.DeleteMacaroonIDResponse} DeleteMacaroonIDResponse
         */
        DeleteMacaroonIDResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.DeleteMacaroonIDResponse)
                return object;
            let message = new $root.lnrpc.DeleteMacaroonIDResponse();
            if (object.deleted != null)
                message.deleted = Boolean(object.deleted);
            return message;
        };

        /**
         * Creates a plain object from a DeleteMacaroonIDResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @static
         * @param {lnrpc.DeleteMacaroonIDResponse} message DeleteMacaroonIDResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMacaroonIDResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.deleted = false;
            if (message.deleted != null && message.hasOwnProperty("deleted"))
                object.deleted = message.deleted;
            return object;
        };

        /**
         * Converts this DeleteMacaroonIDResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.DeleteMacaroonIDResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMacaroonIDResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteMacaroonIDResponse;
    })();

    lnrpc.MacaroonPermissionList = (function() {

        /**
         * Properties of a MacaroonPermissionList.
         * @memberof lnrpc
         * @interface IMacaroonPermissionList
         * @property {Array.<lnrpc.IMacaroonPermission>|null} [permissions] MacaroonPermissionList permissions
         */

        /**
         * Constructs a new MacaroonPermissionList.
         * @memberof lnrpc
         * @classdesc Represents a MacaroonPermissionList.
         * @implements IMacaroonPermissionList
         * @constructor
         * @param {lnrpc.IMacaroonPermissionList=} [properties] Properties to set
         */
        function MacaroonPermissionList(properties) {
            this.permissions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MacaroonPermissionList permissions.
         * @member {Array.<lnrpc.IMacaroonPermission>} permissions
         * @memberof lnrpc.MacaroonPermissionList
         * @instance
         */
        MacaroonPermissionList.prototype.permissions = $util.emptyArray;

        /**
         * Creates a new MacaroonPermissionList instance using the specified properties.
         * @function create
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {lnrpc.IMacaroonPermissionList=} [properties] Properties to set
         * @returns {lnrpc.MacaroonPermissionList} MacaroonPermissionList instance
         */
        MacaroonPermissionList.create = function create(properties) {
            return new MacaroonPermissionList(properties);
        };

        /**
         * Encodes the specified MacaroonPermissionList message. Does not implicitly {@link lnrpc.MacaroonPermissionList.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {lnrpc.IMacaroonPermissionList} message MacaroonPermissionList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MacaroonPermissionList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.permissions != null && message.permissions.length)
                for (let i = 0; i < message.permissions.length; ++i)
                    $root.lnrpc.MacaroonPermission.encode(message.permissions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MacaroonPermissionList message, length delimited. Does not implicitly {@link lnrpc.MacaroonPermissionList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {lnrpc.IMacaroonPermissionList} message MacaroonPermissionList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MacaroonPermissionList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MacaroonPermissionList message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.MacaroonPermissionList} MacaroonPermissionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MacaroonPermissionList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.MacaroonPermissionList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.permissions && message.permissions.length))
                            message.permissions = [];
                        message.permissions.push($root.lnrpc.MacaroonPermission.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MacaroonPermissionList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.MacaroonPermissionList} MacaroonPermissionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MacaroonPermissionList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MacaroonPermissionList message.
         * @function verify
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MacaroonPermissionList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                if (!Array.isArray(message.permissions))
                    return "permissions: array expected";
                for (let i = 0; i < message.permissions.length; ++i) {
                    let error = $root.lnrpc.MacaroonPermission.verify(message.permissions[i]);
                    if (error)
                        return "permissions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MacaroonPermissionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.MacaroonPermissionList} MacaroonPermissionList
         */
        MacaroonPermissionList.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.MacaroonPermissionList)
                return object;
            let message = new $root.lnrpc.MacaroonPermissionList();
            if (object.permissions) {
                if (!Array.isArray(object.permissions))
                    throw TypeError(".lnrpc.MacaroonPermissionList.permissions: array expected");
                message.permissions = [];
                for (let i = 0; i < object.permissions.length; ++i) {
                    if (typeof object.permissions[i] !== "object")
                        throw TypeError(".lnrpc.MacaroonPermissionList.permissions: object expected");
                    message.permissions[i] = $root.lnrpc.MacaroonPermission.fromObject(object.permissions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MacaroonPermissionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.MacaroonPermissionList
         * @static
         * @param {lnrpc.MacaroonPermissionList} message MacaroonPermissionList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MacaroonPermissionList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.permissions = [];
            if (message.permissions && message.permissions.length) {
                object.permissions = [];
                for (let j = 0; j < message.permissions.length; ++j)
                    object.permissions[j] = $root.lnrpc.MacaroonPermission.toObject(message.permissions[j], options);
            }
            return object;
        };

        /**
         * Converts this MacaroonPermissionList to JSON.
         * @function toJSON
         * @memberof lnrpc.MacaroonPermissionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MacaroonPermissionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MacaroonPermissionList;
    })();

    lnrpc.ListPermissionsRequest = (function() {

        /**
         * Properties of a ListPermissionsRequest.
         * @memberof lnrpc
         * @interface IListPermissionsRequest
         */

        /**
         * Constructs a new ListPermissionsRequest.
         * @memberof lnrpc
         * @classdesc Represents a ListPermissionsRequest.
         * @implements IListPermissionsRequest
         * @constructor
         * @param {lnrpc.IListPermissionsRequest=} [properties] Properties to set
         */
        function ListPermissionsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ListPermissionsRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {lnrpc.IListPermissionsRequest=} [properties] Properties to set
         * @returns {lnrpc.ListPermissionsRequest} ListPermissionsRequest instance
         */
        ListPermissionsRequest.create = function create(properties) {
            return new ListPermissionsRequest(properties);
        };

        /**
         * Encodes the specified ListPermissionsRequest message. Does not implicitly {@link lnrpc.ListPermissionsRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {lnrpc.IListPermissionsRequest} message ListPermissionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPermissionsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ListPermissionsRequest message, length delimited. Does not implicitly {@link lnrpc.ListPermissionsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {lnrpc.IListPermissionsRequest} message ListPermissionsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPermissionsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListPermissionsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListPermissionsRequest} ListPermissionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPermissionsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListPermissionsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListPermissionsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListPermissionsRequest} ListPermissionsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPermissionsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListPermissionsRequest message.
         * @function verify
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListPermissionsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ListPermissionsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListPermissionsRequest} ListPermissionsRequest
         */
        ListPermissionsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListPermissionsRequest)
                return object;
            return new $root.lnrpc.ListPermissionsRequest();
        };

        /**
         * Creates a plain object from a ListPermissionsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListPermissionsRequest
         * @static
         * @param {lnrpc.ListPermissionsRequest} message ListPermissionsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListPermissionsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ListPermissionsRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ListPermissionsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListPermissionsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListPermissionsRequest;
    })();

    lnrpc.ListPermissionsResponse = (function() {

        /**
         * Properties of a ListPermissionsResponse.
         * @memberof lnrpc
         * @interface IListPermissionsResponse
         * @property {Object.<string,lnrpc.IMacaroonPermissionList>|null} [methodPermissions] ListPermissionsResponse methodPermissions
         */

        /**
         * Constructs a new ListPermissionsResponse.
         * @memberof lnrpc
         * @classdesc Represents a ListPermissionsResponse.
         * @implements IListPermissionsResponse
         * @constructor
         * @param {lnrpc.IListPermissionsResponse=} [properties] Properties to set
         */
        function ListPermissionsResponse(properties) {
            this.methodPermissions = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListPermissionsResponse methodPermissions.
         * @member {Object.<string,lnrpc.IMacaroonPermissionList>} methodPermissions
         * @memberof lnrpc.ListPermissionsResponse
         * @instance
         */
        ListPermissionsResponse.prototype.methodPermissions = $util.emptyObject;

        /**
         * Creates a new ListPermissionsResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {lnrpc.IListPermissionsResponse=} [properties] Properties to set
         * @returns {lnrpc.ListPermissionsResponse} ListPermissionsResponse instance
         */
        ListPermissionsResponse.create = function create(properties) {
            return new ListPermissionsResponse(properties);
        };

        /**
         * Encodes the specified ListPermissionsResponse message. Does not implicitly {@link lnrpc.ListPermissionsResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {lnrpc.IListPermissionsResponse} message ListPermissionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPermissionsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.methodPermissions != null && Object.hasOwnProperty.call(message, "methodPermissions"))
                for (let keys = Object.keys(message.methodPermissions), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.lnrpc.MacaroonPermissionList.encode(message.methodPermissions[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            return writer;
        };

        /**
         * Encodes the specified ListPermissionsResponse message, length delimited. Does not implicitly {@link lnrpc.ListPermissionsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {lnrpc.IListPermissionsResponse} message ListPermissionsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListPermissionsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListPermissionsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ListPermissionsResponse} ListPermissionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPermissionsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ListPermissionsResponse(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.methodPermissions === $util.emptyObject)
                            message.methodPermissions = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.lnrpc.MacaroonPermissionList.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.methodPermissions[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListPermissionsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ListPermissionsResponse} ListPermissionsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListPermissionsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListPermissionsResponse message.
         * @function verify
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListPermissionsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.methodPermissions != null && message.hasOwnProperty("methodPermissions")) {
                if (!$util.isObject(message.methodPermissions))
                    return "methodPermissions: object expected";
                let key = Object.keys(message.methodPermissions);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.lnrpc.MacaroonPermissionList.verify(message.methodPermissions[key[i]]);
                    if (error)
                        return "methodPermissions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListPermissionsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ListPermissionsResponse} ListPermissionsResponse
         */
        ListPermissionsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ListPermissionsResponse)
                return object;
            let message = new $root.lnrpc.ListPermissionsResponse();
            if (object.methodPermissions) {
                if (typeof object.methodPermissions !== "object")
                    throw TypeError(".lnrpc.ListPermissionsResponse.methodPermissions: object expected");
                message.methodPermissions = {};
                for (let keys = Object.keys(object.methodPermissions), i = 0; i < keys.length; ++i) {
                    if (typeof object.methodPermissions[keys[i]] !== "object")
                        throw TypeError(".lnrpc.ListPermissionsResponse.methodPermissions: object expected");
                    message.methodPermissions[keys[i]] = $root.lnrpc.MacaroonPermissionList.fromObject(object.methodPermissions[keys[i]]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListPermissionsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ListPermissionsResponse
         * @static
         * @param {lnrpc.ListPermissionsResponse} message ListPermissionsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListPermissionsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.methodPermissions = {};
            let keys2;
            if (message.methodPermissions && (keys2 = Object.keys(message.methodPermissions)).length) {
                object.methodPermissions = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.methodPermissions[keys2[j]] = $root.lnrpc.MacaroonPermissionList.toObject(message.methodPermissions[keys2[j]], options);
            }
            return object;
        };

        /**
         * Converts this ListPermissionsResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ListPermissionsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListPermissionsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListPermissionsResponse;
    })();

    lnrpc.Failure = (function() {

        /**
         * Properties of a Failure.
         * @memberof lnrpc
         * @interface IFailure
         * @property {lnrpc.Failure.FailureCode|null} [code] Failure code
         * @property {lnrpc.IChannelUpdate|null} [channelUpdate] Failure channelUpdate
         * @property {Long|null} [htlcMsat] Failure htlcMsat
         * @property {Uint8Array|null} [onionSha_256] Failure onionSha_256
         * @property {number|null} [cltvExpiry] Failure cltvExpiry
         * @property {number|null} [flags] Failure flags
         * @property {number|null} [failureSourceIndex] Failure failureSourceIndex
         * @property {number|null} [height] Failure height
         */

        /**
         * Constructs a new Failure.
         * @memberof lnrpc
         * @classdesc Represents a Failure.
         * @implements IFailure
         * @constructor
         * @param {lnrpc.IFailure=} [properties] Properties to set
         */
        function Failure(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Failure code.
         * @member {lnrpc.Failure.FailureCode} code
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.code = 0;

        /**
         * Failure channelUpdate.
         * @member {lnrpc.IChannelUpdate|null|undefined} channelUpdate
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.channelUpdate = null;

        /**
         * Failure htlcMsat.
         * @member {Long} htlcMsat
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.htlcMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Failure onionSha_256.
         * @member {Uint8Array} onionSha_256
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.onionSha_256 = $util.newBuffer([]);

        /**
         * Failure cltvExpiry.
         * @member {number} cltvExpiry
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.cltvExpiry = 0;

        /**
         * Failure flags.
         * @member {number} flags
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.flags = 0;

        /**
         * Failure failureSourceIndex.
         * @member {number} failureSourceIndex
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.failureSourceIndex = 0;

        /**
         * Failure height.
         * @member {number} height
         * @memberof lnrpc.Failure
         * @instance
         */
        Failure.prototype.height = 0;

        /**
         * Creates a new Failure instance using the specified properties.
         * @function create
         * @memberof lnrpc.Failure
         * @static
         * @param {lnrpc.IFailure=} [properties] Properties to set
         * @returns {lnrpc.Failure} Failure instance
         */
        Failure.create = function create(properties) {
            return new Failure(properties);
        };

        /**
         * Encodes the specified Failure message. Does not implicitly {@link lnrpc.Failure.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Failure
         * @static
         * @param {lnrpc.IFailure} message Failure message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Failure.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.channelUpdate != null && Object.hasOwnProperty.call(message, "channelUpdate"))
                $root.lnrpc.ChannelUpdate.encode(message.channelUpdate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.htlcMsat != null && Object.hasOwnProperty.call(message, "htlcMsat"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.htlcMsat);
            if (message.onionSha_256 != null && Object.hasOwnProperty.call(message, "onionSha_256"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.onionSha_256);
            if (message.cltvExpiry != null && Object.hasOwnProperty.call(message, "cltvExpiry"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.cltvExpiry);
            if (message.flags != null && Object.hasOwnProperty.call(message, "flags"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.flags);
            if (message.failureSourceIndex != null && Object.hasOwnProperty.call(message, "failureSourceIndex"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.failureSourceIndex);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.height);
            return writer;
        };

        /**
         * Encodes the specified Failure message, length delimited. Does not implicitly {@link lnrpc.Failure.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Failure
         * @static
         * @param {lnrpc.IFailure} message Failure message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Failure.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Failure message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Failure
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Failure} Failure
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Failure.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Failure();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.int32();
                        break;
                    }
                case 3: {
                        message.channelUpdate = $root.lnrpc.ChannelUpdate.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.htlcMsat = reader.uint64();
                        break;
                    }
                case 5: {
                        message.onionSha_256 = reader.bytes();
                        break;
                    }
                case 6: {
                        message.cltvExpiry = reader.uint32();
                        break;
                    }
                case 7: {
                        message.flags = reader.uint32();
                        break;
                    }
                case 8: {
                        message.failureSourceIndex = reader.uint32();
                        break;
                    }
                case 9: {
                        message.height = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Failure message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Failure
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Failure} Failure
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Failure.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Failure message.
         * @function verify
         * @memberof lnrpc.Failure
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Failure.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                switch (message.code) {
                default:
                    return "code: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 997:
                case 998:
                case 999:
                    break;
                }
            if (message.channelUpdate != null && message.hasOwnProperty("channelUpdate")) {
                let error = $root.lnrpc.ChannelUpdate.verify(message.channelUpdate);
                if (error)
                    return "channelUpdate." + error;
            }
            if (message.htlcMsat != null && message.hasOwnProperty("htlcMsat"))
                if (!$util.isInteger(message.htlcMsat) && !(message.htlcMsat && $util.isInteger(message.htlcMsat.low) && $util.isInteger(message.htlcMsat.high)))
                    return "htlcMsat: integer|Long expected";
            if (message.onionSha_256 != null && message.hasOwnProperty("onionSha_256"))
                if (!(message.onionSha_256 && typeof message.onionSha_256.length === "number" || $util.isString(message.onionSha_256)))
                    return "onionSha_256: buffer expected";
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (!$util.isInteger(message.cltvExpiry))
                    return "cltvExpiry: integer expected";
            if (message.flags != null && message.hasOwnProperty("flags"))
                if (!$util.isInteger(message.flags))
                    return "flags: integer expected";
            if (message.failureSourceIndex != null && message.hasOwnProperty("failureSourceIndex"))
                if (!$util.isInteger(message.failureSourceIndex))
                    return "failureSourceIndex: integer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a Failure message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Failure
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Failure} Failure
         */
        Failure.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Failure)
                return object;
            let message = new $root.lnrpc.Failure();
            switch (object.code) {
            case "RESERVED":
            case 0:
                message.code = 0;
                break;
            case "INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS":
            case 1:
                message.code = 1;
                break;
            case "INCORRECT_PAYMENT_AMOUNT":
            case 2:
                message.code = 2;
                break;
            case "FINAL_INCORRECT_CLTV_EXPIRY":
            case 3:
                message.code = 3;
                break;
            case "FINAL_INCORRECT_HTLC_AMOUNT":
            case 4:
                message.code = 4;
                break;
            case "FINAL_EXPIRY_TOO_SOON":
            case 5:
                message.code = 5;
                break;
            case "INVALID_REALM":
            case 6:
                message.code = 6;
                break;
            case "EXPIRY_TOO_SOON":
            case 7:
                message.code = 7;
                break;
            case "INVALID_ONION_VERSION":
            case 8:
                message.code = 8;
                break;
            case "INVALID_ONION_HMAC":
            case 9:
                message.code = 9;
                break;
            case "INVALID_ONION_KEY":
            case 10:
                message.code = 10;
                break;
            case "AMOUNT_BELOW_MINIMUM":
            case 11:
                message.code = 11;
                break;
            case "FEE_INSUFFICIENT":
            case 12:
                message.code = 12;
                break;
            case "INCORRECT_CLTV_EXPIRY":
            case 13:
                message.code = 13;
                break;
            case "CHANNEL_DISABLED":
            case 14:
                message.code = 14;
                break;
            case "TEMPORARY_CHANNEL_FAILURE":
            case 15:
                message.code = 15;
                break;
            case "REQUIRED_NODE_FEATURE_MISSING":
            case 16:
                message.code = 16;
                break;
            case "REQUIRED_CHANNEL_FEATURE_MISSING":
            case 17:
                message.code = 17;
                break;
            case "UNKNOWN_NEXT_PEER":
            case 18:
                message.code = 18;
                break;
            case "TEMPORARY_NODE_FAILURE":
            case 19:
                message.code = 19;
                break;
            case "PERMANENT_NODE_FAILURE":
            case 20:
                message.code = 20;
                break;
            case "PERMANENT_CHANNEL_FAILURE":
            case 21:
                message.code = 21;
                break;
            case "EXPIRY_TOO_FAR":
            case 22:
                message.code = 22;
                break;
            case "MPP_TIMEOUT":
            case 23:
                message.code = 23;
                break;
            case "INVALID_ONION_PAYLOAD":
            case 24:
                message.code = 24;
                break;
            case "INTERNAL_FAILURE":
            case 997:
                message.code = 997;
                break;
            case "UNKNOWN_FAILURE":
            case 998:
                message.code = 998;
                break;
            case "UNREADABLE_FAILURE":
            case 999:
                message.code = 999;
                break;
            }
            if (object.channelUpdate != null) {
                if (typeof object.channelUpdate !== "object")
                    throw TypeError(".lnrpc.Failure.channelUpdate: object expected");
                message.channelUpdate = $root.lnrpc.ChannelUpdate.fromObject(object.channelUpdate);
            }
            if (object.htlcMsat != null)
                if ($util.Long)
                    (message.htlcMsat = $util.Long.fromValue(object.htlcMsat)).unsigned = true;
                else if (typeof object.htlcMsat === "string")
                    message.htlcMsat = parseInt(object.htlcMsat, 10);
                else if (typeof object.htlcMsat === "number")
                    message.htlcMsat = object.htlcMsat;
                else if (typeof object.htlcMsat === "object")
                    message.htlcMsat = new $util.LongBits(object.htlcMsat.low >>> 0, object.htlcMsat.high >>> 0).toNumber(true);
            if (object.onionSha_256 != null)
                if (typeof object.onionSha_256 === "string")
                    $util.base64.decode(object.onionSha_256, message.onionSha_256 = $util.newBuffer($util.base64.length(object.onionSha_256)), 0);
                else if (object.onionSha_256.length)
                    message.onionSha_256 = object.onionSha_256;
            if (object.cltvExpiry != null)
                message.cltvExpiry = object.cltvExpiry >>> 0;
            if (object.flags != null)
                message.flags = object.flags >>> 0;
            if (object.failureSourceIndex != null)
                message.failureSourceIndex = object.failureSourceIndex >>> 0;
            if (object.height != null)
                message.height = object.height >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a Failure message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Failure
         * @static
         * @param {lnrpc.Failure} message Failure
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Failure.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.code = options.enums === String ? "RESERVED" : 0;
                object.channelUpdate = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.htlcMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.htlcMsat = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.onionSha_256 = "";
                else {
                    object.onionSha_256 = [];
                    if (options.bytes !== Array)
                        object.onionSha_256 = $util.newBuffer(object.onionSha_256);
                }
                object.cltvExpiry = 0;
                object.flags = 0;
                object.failureSourceIndex = 0;
                object.height = 0;
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = options.enums === String ? $root.lnrpc.Failure.FailureCode[message.code] : message.code;
            if (message.channelUpdate != null && message.hasOwnProperty("channelUpdate"))
                object.channelUpdate = $root.lnrpc.ChannelUpdate.toObject(message.channelUpdate, options);
            if (message.htlcMsat != null && message.hasOwnProperty("htlcMsat"))
                if (typeof message.htlcMsat === "number")
                    object.htlcMsat = options.longs === String ? String(message.htlcMsat) : message.htlcMsat;
                else
                    object.htlcMsat = options.longs === String ? $util.Long.prototype.toString.call(message.htlcMsat) : options.longs === Number ? new $util.LongBits(message.htlcMsat.low >>> 0, message.htlcMsat.high >>> 0).toNumber(true) : message.htlcMsat;
            if (message.onionSha_256 != null && message.hasOwnProperty("onionSha_256"))
                object.onionSha_256 = options.bytes === String ? $util.base64.encode(message.onionSha_256, 0, message.onionSha_256.length) : options.bytes === Array ? Array.prototype.slice.call(message.onionSha_256) : message.onionSha_256;
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                object.cltvExpiry = message.cltvExpiry;
            if (message.flags != null && message.hasOwnProperty("flags"))
                object.flags = message.flags;
            if (message.failureSourceIndex != null && message.hasOwnProperty("failureSourceIndex"))
                object.failureSourceIndex = message.failureSourceIndex;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this Failure to JSON.
         * @function toJSON
         * @memberof lnrpc.Failure
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Failure.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * FailureCode enum.
         * @name lnrpc.Failure.FailureCode
         * @enum {number}
         * @property {number} RESERVED=0 RESERVED value
         * @property {number} INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS=1 INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS value
         * @property {number} INCORRECT_PAYMENT_AMOUNT=2 INCORRECT_PAYMENT_AMOUNT value
         * @property {number} FINAL_INCORRECT_CLTV_EXPIRY=3 FINAL_INCORRECT_CLTV_EXPIRY value
         * @property {number} FINAL_INCORRECT_HTLC_AMOUNT=4 FINAL_INCORRECT_HTLC_AMOUNT value
         * @property {number} FINAL_EXPIRY_TOO_SOON=5 FINAL_EXPIRY_TOO_SOON value
         * @property {number} INVALID_REALM=6 INVALID_REALM value
         * @property {number} EXPIRY_TOO_SOON=7 EXPIRY_TOO_SOON value
         * @property {number} INVALID_ONION_VERSION=8 INVALID_ONION_VERSION value
         * @property {number} INVALID_ONION_HMAC=9 INVALID_ONION_HMAC value
         * @property {number} INVALID_ONION_KEY=10 INVALID_ONION_KEY value
         * @property {number} AMOUNT_BELOW_MINIMUM=11 AMOUNT_BELOW_MINIMUM value
         * @property {number} FEE_INSUFFICIENT=12 FEE_INSUFFICIENT value
         * @property {number} INCORRECT_CLTV_EXPIRY=13 INCORRECT_CLTV_EXPIRY value
         * @property {number} CHANNEL_DISABLED=14 CHANNEL_DISABLED value
         * @property {number} TEMPORARY_CHANNEL_FAILURE=15 TEMPORARY_CHANNEL_FAILURE value
         * @property {number} REQUIRED_NODE_FEATURE_MISSING=16 REQUIRED_NODE_FEATURE_MISSING value
         * @property {number} REQUIRED_CHANNEL_FEATURE_MISSING=17 REQUIRED_CHANNEL_FEATURE_MISSING value
         * @property {number} UNKNOWN_NEXT_PEER=18 UNKNOWN_NEXT_PEER value
         * @property {number} TEMPORARY_NODE_FAILURE=19 TEMPORARY_NODE_FAILURE value
         * @property {number} PERMANENT_NODE_FAILURE=20 PERMANENT_NODE_FAILURE value
         * @property {number} PERMANENT_CHANNEL_FAILURE=21 PERMANENT_CHANNEL_FAILURE value
         * @property {number} EXPIRY_TOO_FAR=22 EXPIRY_TOO_FAR value
         * @property {number} MPP_TIMEOUT=23 MPP_TIMEOUT value
         * @property {number} INVALID_ONION_PAYLOAD=24 INVALID_ONION_PAYLOAD value
         * @property {number} INTERNAL_FAILURE=997 INTERNAL_FAILURE value
         * @property {number} UNKNOWN_FAILURE=998 UNKNOWN_FAILURE value
         * @property {number} UNREADABLE_FAILURE=999 UNREADABLE_FAILURE value
         */
        Failure.FailureCode = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RESERVED"] = 0;
            values[valuesById[1] = "INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS"] = 1;
            values[valuesById[2] = "INCORRECT_PAYMENT_AMOUNT"] = 2;
            values[valuesById[3] = "FINAL_INCORRECT_CLTV_EXPIRY"] = 3;
            values[valuesById[4] = "FINAL_INCORRECT_HTLC_AMOUNT"] = 4;
            values[valuesById[5] = "FINAL_EXPIRY_TOO_SOON"] = 5;
            values[valuesById[6] = "INVALID_REALM"] = 6;
            values[valuesById[7] = "EXPIRY_TOO_SOON"] = 7;
            values[valuesById[8] = "INVALID_ONION_VERSION"] = 8;
            values[valuesById[9] = "INVALID_ONION_HMAC"] = 9;
            values[valuesById[10] = "INVALID_ONION_KEY"] = 10;
            values[valuesById[11] = "AMOUNT_BELOW_MINIMUM"] = 11;
            values[valuesById[12] = "FEE_INSUFFICIENT"] = 12;
            values[valuesById[13] = "INCORRECT_CLTV_EXPIRY"] = 13;
            values[valuesById[14] = "CHANNEL_DISABLED"] = 14;
            values[valuesById[15] = "TEMPORARY_CHANNEL_FAILURE"] = 15;
            values[valuesById[16] = "REQUIRED_NODE_FEATURE_MISSING"] = 16;
            values[valuesById[17] = "REQUIRED_CHANNEL_FEATURE_MISSING"] = 17;
            values[valuesById[18] = "UNKNOWN_NEXT_PEER"] = 18;
            values[valuesById[19] = "TEMPORARY_NODE_FAILURE"] = 19;
            values[valuesById[20] = "PERMANENT_NODE_FAILURE"] = 20;
            values[valuesById[21] = "PERMANENT_CHANNEL_FAILURE"] = 21;
            values[valuesById[22] = "EXPIRY_TOO_FAR"] = 22;
            values[valuesById[23] = "MPP_TIMEOUT"] = 23;
            values[valuesById[24] = "INVALID_ONION_PAYLOAD"] = 24;
            values[valuesById[997] = "INTERNAL_FAILURE"] = 997;
            values[valuesById[998] = "UNKNOWN_FAILURE"] = 998;
            values[valuesById[999] = "UNREADABLE_FAILURE"] = 999;
            return values;
        })();

        return Failure;
    })();

    lnrpc.ChannelUpdate = (function() {

        /**
         * Properties of a ChannelUpdate.
         * @memberof lnrpc
         * @interface IChannelUpdate
         * @property {Uint8Array|null} [signature] ChannelUpdate signature
         * @property {Uint8Array|null} [chainHash] ChannelUpdate chainHash
         * @property {Long|null} [chanId] ChannelUpdate chanId
         * @property {number|null} [timestamp] ChannelUpdate timestamp
         * @property {number|null} [messageFlags] ChannelUpdate messageFlags
         * @property {number|null} [channelFlags] ChannelUpdate channelFlags
         * @property {number|null} [timeLockDelta] ChannelUpdate timeLockDelta
         * @property {Long|null} [htlcMinimumMsat] ChannelUpdate htlcMinimumMsat
         * @property {number|null} [baseFee] ChannelUpdate baseFee
         * @property {number|null} [feeRate] ChannelUpdate feeRate
         * @property {Long|null} [htlcMaximumMsat] ChannelUpdate htlcMaximumMsat
         * @property {Uint8Array|null} [extraOpaqueData] ChannelUpdate extraOpaqueData
         */

        /**
         * Constructs a new ChannelUpdate.
         * @memberof lnrpc
         * @classdesc Represents a ChannelUpdate.
         * @implements IChannelUpdate
         * @constructor
         * @param {lnrpc.IChannelUpdate=} [properties] Properties to set
         */
        function ChannelUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChannelUpdate signature.
         * @member {Uint8Array} signature
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.signature = $util.newBuffer([]);

        /**
         * ChannelUpdate chainHash.
         * @member {Uint8Array} chainHash
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.chainHash = $util.newBuffer([]);

        /**
         * ChannelUpdate chanId.
         * @member {Long} chanId
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelUpdate timestamp.
         * @member {number} timestamp
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.timestamp = 0;

        /**
         * ChannelUpdate messageFlags.
         * @member {number} messageFlags
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.messageFlags = 0;

        /**
         * ChannelUpdate channelFlags.
         * @member {number} channelFlags
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.channelFlags = 0;

        /**
         * ChannelUpdate timeLockDelta.
         * @member {number} timeLockDelta
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.timeLockDelta = 0;

        /**
         * ChannelUpdate htlcMinimumMsat.
         * @member {Long} htlcMinimumMsat
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.htlcMinimumMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelUpdate baseFee.
         * @member {number} baseFee
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.baseFee = 0;

        /**
         * ChannelUpdate feeRate.
         * @member {number} feeRate
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.feeRate = 0;

        /**
         * ChannelUpdate htlcMaximumMsat.
         * @member {Long} htlcMaximumMsat
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.htlcMaximumMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ChannelUpdate extraOpaqueData.
         * @member {Uint8Array} extraOpaqueData
         * @memberof lnrpc.ChannelUpdate
         * @instance
         */
        ChannelUpdate.prototype.extraOpaqueData = $util.newBuffer([]);

        /**
         * Creates a new ChannelUpdate instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {lnrpc.IChannelUpdate=} [properties] Properties to set
         * @returns {lnrpc.ChannelUpdate} ChannelUpdate instance
         */
        ChannelUpdate.create = function create(properties) {
            return new ChannelUpdate(properties);
        };

        /**
         * Encodes the specified ChannelUpdate message. Does not implicitly {@link lnrpc.ChannelUpdate.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {lnrpc.IChannelUpdate} message ChannelUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.signature);
            if (message.chainHash != null && Object.hasOwnProperty.call(message, "chainHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.chainHash);
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.chanId);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.timestamp);
            if (message.channelFlags != null && Object.hasOwnProperty.call(message, "channelFlags"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.channelFlags);
            if (message.timeLockDelta != null && Object.hasOwnProperty.call(message, "timeLockDelta"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.timeLockDelta);
            if (message.htlcMinimumMsat != null && Object.hasOwnProperty.call(message, "htlcMinimumMsat"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.htlcMinimumMsat);
            if (message.baseFee != null && Object.hasOwnProperty.call(message, "baseFee"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.baseFee);
            if (message.feeRate != null && Object.hasOwnProperty.call(message, "feeRate"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.feeRate);
            if (message.messageFlags != null && Object.hasOwnProperty.call(message, "messageFlags"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint32(message.messageFlags);
            if (message.htlcMaximumMsat != null && Object.hasOwnProperty.call(message, "htlcMaximumMsat"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.htlcMaximumMsat);
            if (message.extraOpaqueData != null && Object.hasOwnProperty.call(message, "extraOpaqueData"))
                writer.uint32(/* id 12, wireType 2 =*/98).bytes(message.extraOpaqueData);
            return writer;
        };

        /**
         * Encodes the specified ChannelUpdate message, length delimited. Does not implicitly {@link lnrpc.ChannelUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {lnrpc.IChannelUpdate} message ChannelUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChannelUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChannelUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChannelUpdate} ChannelUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChannelUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signature = reader.bytes();
                        break;
                    }
                case 2: {
                        message.chainHash = reader.bytes();
                        break;
                    }
                case 3: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 4: {
                        message.timestamp = reader.uint32();
                        break;
                    }
                case 10: {
                        message.messageFlags = reader.uint32();
                        break;
                    }
                case 5: {
                        message.channelFlags = reader.uint32();
                        break;
                    }
                case 6: {
                        message.timeLockDelta = reader.uint32();
                        break;
                    }
                case 7: {
                        message.htlcMinimumMsat = reader.uint64();
                        break;
                    }
                case 8: {
                        message.baseFee = reader.uint32();
                        break;
                    }
                case 9: {
                        message.feeRate = reader.uint32();
                        break;
                    }
                case 11: {
                        message.htlcMaximumMsat = reader.uint64();
                        break;
                    }
                case 12: {
                        message.extraOpaqueData = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChannelUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChannelUpdate} ChannelUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChannelUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChannelUpdate message.
         * @function verify
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChannelUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                    return "signature: buffer expected";
            if (message.chainHash != null && message.hasOwnProperty("chainHash"))
                if (!(message.chainHash && typeof message.chainHash.length === "number" || $util.isString(message.chainHash)))
                    return "chainHash: buffer expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                if (!$util.isInteger(message.timestamp))
                    return "timestamp: integer expected";
            if (message.messageFlags != null && message.hasOwnProperty("messageFlags"))
                if (!$util.isInteger(message.messageFlags))
                    return "messageFlags: integer expected";
            if (message.channelFlags != null && message.hasOwnProperty("channelFlags"))
                if (!$util.isInteger(message.channelFlags))
                    return "channelFlags: integer expected";
            if (message.timeLockDelta != null && message.hasOwnProperty("timeLockDelta"))
                if (!$util.isInteger(message.timeLockDelta))
                    return "timeLockDelta: integer expected";
            if (message.htlcMinimumMsat != null && message.hasOwnProperty("htlcMinimumMsat"))
                if (!$util.isInteger(message.htlcMinimumMsat) && !(message.htlcMinimumMsat && $util.isInteger(message.htlcMinimumMsat.low) && $util.isInteger(message.htlcMinimumMsat.high)))
                    return "htlcMinimumMsat: integer|Long expected";
            if (message.baseFee != null && message.hasOwnProperty("baseFee"))
                if (!$util.isInteger(message.baseFee))
                    return "baseFee: integer expected";
            if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                if (!$util.isInteger(message.feeRate))
                    return "feeRate: integer expected";
            if (message.htlcMaximumMsat != null && message.hasOwnProperty("htlcMaximumMsat"))
                if (!$util.isInteger(message.htlcMaximumMsat) && !(message.htlcMaximumMsat && $util.isInteger(message.htlcMaximumMsat.low) && $util.isInteger(message.htlcMaximumMsat.high)))
                    return "htlcMaximumMsat: integer|Long expected";
            if (message.extraOpaqueData != null && message.hasOwnProperty("extraOpaqueData"))
                if (!(message.extraOpaqueData && typeof message.extraOpaqueData.length === "number" || $util.isString(message.extraOpaqueData)))
                    return "extraOpaqueData: buffer expected";
            return null;
        };

        /**
         * Creates a ChannelUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChannelUpdate} ChannelUpdate
         */
        ChannelUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChannelUpdate)
                return object;
            let message = new $root.lnrpc.ChannelUpdate();
            if (object.signature != null)
                if (typeof object.signature === "string")
                    $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                else if (object.signature.length)
                    message.signature = object.signature;
            if (object.chainHash != null)
                if (typeof object.chainHash === "string")
                    $util.base64.decode(object.chainHash, message.chainHash = $util.newBuffer($util.base64.length(object.chainHash)), 0);
                else if (object.chainHash.length)
                    message.chainHash = object.chainHash;
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.timestamp != null)
                message.timestamp = object.timestamp >>> 0;
            if (object.messageFlags != null)
                message.messageFlags = object.messageFlags >>> 0;
            if (object.channelFlags != null)
                message.channelFlags = object.channelFlags >>> 0;
            if (object.timeLockDelta != null)
                message.timeLockDelta = object.timeLockDelta >>> 0;
            if (object.htlcMinimumMsat != null)
                if ($util.Long)
                    (message.htlcMinimumMsat = $util.Long.fromValue(object.htlcMinimumMsat)).unsigned = true;
                else if (typeof object.htlcMinimumMsat === "string")
                    message.htlcMinimumMsat = parseInt(object.htlcMinimumMsat, 10);
                else if (typeof object.htlcMinimumMsat === "number")
                    message.htlcMinimumMsat = object.htlcMinimumMsat;
                else if (typeof object.htlcMinimumMsat === "object")
                    message.htlcMinimumMsat = new $util.LongBits(object.htlcMinimumMsat.low >>> 0, object.htlcMinimumMsat.high >>> 0).toNumber(true);
            if (object.baseFee != null)
                message.baseFee = object.baseFee >>> 0;
            if (object.feeRate != null)
                message.feeRate = object.feeRate >>> 0;
            if (object.htlcMaximumMsat != null)
                if ($util.Long)
                    (message.htlcMaximumMsat = $util.Long.fromValue(object.htlcMaximumMsat)).unsigned = true;
                else if (typeof object.htlcMaximumMsat === "string")
                    message.htlcMaximumMsat = parseInt(object.htlcMaximumMsat, 10);
                else if (typeof object.htlcMaximumMsat === "number")
                    message.htlcMaximumMsat = object.htlcMaximumMsat;
                else if (typeof object.htlcMaximumMsat === "object")
                    message.htlcMaximumMsat = new $util.LongBits(object.htlcMaximumMsat.low >>> 0, object.htlcMaximumMsat.high >>> 0).toNumber(true);
            if (object.extraOpaqueData != null)
                if (typeof object.extraOpaqueData === "string")
                    $util.base64.decode(object.extraOpaqueData, message.extraOpaqueData = $util.newBuffer($util.base64.length(object.extraOpaqueData)), 0);
                else if (object.extraOpaqueData.length)
                    message.extraOpaqueData = object.extraOpaqueData;
            return message;
        };

        /**
         * Creates a plain object from a ChannelUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChannelUpdate
         * @static
         * @param {lnrpc.ChannelUpdate} message ChannelUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChannelUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.signature = "";
                else {
                    object.signature = [];
                    if (options.bytes !== Array)
                        object.signature = $util.newBuffer(object.signature);
                }
                if (options.bytes === String)
                    object.chainHash = "";
                else {
                    object.chainHash = [];
                    if (options.bytes !== Array)
                        object.chainHash = $util.newBuffer(object.chainHash);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                object.timestamp = 0;
                object.channelFlags = 0;
                object.timeLockDelta = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.htlcMinimumMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.htlcMinimumMsat = options.longs === String ? "0" : 0;
                object.baseFee = 0;
                object.feeRate = 0;
                object.messageFlags = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.htlcMaximumMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.htlcMaximumMsat = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.extraOpaqueData = "";
                else {
                    object.extraOpaqueData = [];
                    if (options.bytes !== Array)
                        object.extraOpaqueData = $util.newBuffer(object.extraOpaqueData);
                }
            }
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
            if (message.chainHash != null && message.hasOwnProperty("chainHash"))
                object.chainHash = options.bytes === String ? $util.base64.encode(message.chainHash, 0, message.chainHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.chainHash) : message.chainHash;
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = message.timestamp;
            if (message.channelFlags != null && message.hasOwnProperty("channelFlags"))
                object.channelFlags = message.channelFlags;
            if (message.timeLockDelta != null && message.hasOwnProperty("timeLockDelta"))
                object.timeLockDelta = message.timeLockDelta;
            if (message.htlcMinimumMsat != null && message.hasOwnProperty("htlcMinimumMsat"))
                if (typeof message.htlcMinimumMsat === "number")
                    object.htlcMinimumMsat = options.longs === String ? String(message.htlcMinimumMsat) : message.htlcMinimumMsat;
                else
                    object.htlcMinimumMsat = options.longs === String ? $util.Long.prototype.toString.call(message.htlcMinimumMsat) : options.longs === Number ? new $util.LongBits(message.htlcMinimumMsat.low >>> 0, message.htlcMinimumMsat.high >>> 0).toNumber(true) : message.htlcMinimumMsat;
            if (message.baseFee != null && message.hasOwnProperty("baseFee"))
                object.baseFee = message.baseFee;
            if (message.feeRate != null && message.hasOwnProperty("feeRate"))
                object.feeRate = message.feeRate;
            if (message.messageFlags != null && message.hasOwnProperty("messageFlags"))
                object.messageFlags = message.messageFlags;
            if (message.htlcMaximumMsat != null && message.hasOwnProperty("htlcMaximumMsat"))
                if (typeof message.htlcMaximumMsat === "number")
                    object.htlcMaximumMsat = options.longs === String ? String(message.htlcMaximumMsat) : message.htlcMaximumMsat;
                else
                    object.htlcMaximumMsat = options.longs === String ? $util.Long.prototype.toString.call(message.htlcMaximumMsat) : options.longs === Number ? new $util.LongBits(message.htlcMaximumMsat.low >>> 0, message.htlcMaximumMsat.high >>> 0).toNumber(true) : message.htlcMaximumMsat;
            if (message.extraOpaqueData != null && message.hasOwnProperty("extraOpaqueData"))
                object.extraOpaqueData = options.bytes === String ? $util.base64.encode(message.extraOpaqueData, 0, message.extraOpaqueData.length) : options.bytes === Array ? Array.prototype.slice.call(message.extraOpaqueData) : message.extraOpaqueData;
            return object;
        };

        /**
         * Converts this ChannelUpdate to JSON.
         * @function toJSON
         * @memberof lnrpc.ChannelUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChannelUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChannelUpdate;
    })();

    lnrpc.MacaroonId = (function() {

        /**
         * Properties of a MacaroonId.
         * @memberof lnrpc
         * @interface IMacaroonId
         * @property {Uint8Array|null} [nonce] MacaroonId nonce
         * @property {Uint8Array|null} [storageId] MacaroonId storageId
         * @property {Array.<lnrpc.IOp>|null} [ops] MacaroonId ops
         */

        /**
         * Constructs a new MacaroonId.
         * @memberof lnrpc
         * @classdesc Represents a MacaroonId.
         * @implements IMacaroonId
         * @constructor
         * @param {lnrpc.IMacaroonId=} [properties] Properties to set
         */
        function MacaroonId(properties) {
            this.ops = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MacaroonId nonce.
         * @member {Uint8Array} nonce
         * @memberof lnrpc.MacaroonId
         * @instance
         */
        MacaroonId.prototype.nonce = $util.newBuffer([]);

        /**
         * MacaroonId storageId.
         * @member {Uint8Array} storageId
         * @memberof lnrpc.MacaroonId
         * @instance
         */
        MacaroonId.prototype.storageId = $util.newBuffer([]);

        /**
         * MacaroonId ops.
         * @member {Array.<lnrpc.IOp>} ops
         * @memberof lnrpc.MacaroonId
         * @instance
         */
        MacaroonId.prototype.ops = $util.emptyArray;

        /**
         * Creates a new MacaroonId instance using the specified properties.
         * @function create
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {lnrpc.IMacaroonId=} [properties] Properties to set
         * @returns {lnrpc.MacaroonId} MacaroonId instance
         */
        MacaroonId.create = function create(properties) {
            return new MacaroonId(properties);
        };

        /**
         * Encodes the specified MacaroonId message. Does not implicitly {@link lnrpc.MacaroonId.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {lnrpc.IMacaroonId} message MacaroonId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MacaroonId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nonce);
            if (message.storageId != null && Object.hasOwnProperty.call(message, "storageId"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.storageId);
            if (message.ops != null && message.ops.length)
                for (let i = 0; i < message.ops.length; ++i)
                    $root.lnrpc.Op.encode(message.ops[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MacaroonId message, length delimited. Does not implicitly {@link lnrpc.MacaroonId.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {lnrpc.IMacaroonId} message MacaroonId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MacaroonId.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MacaroonId message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.MacaroonId} MacaroonId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MacaroonId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.MacaroonId();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nonce = reader.bytes();
                        break;
                    }
                case 2: {
                        message.storageId = reader.bytes();
                        break;
                    }
                case 3: {
                        if (!(message.ops && message.ops.length))
                            message.ops = [];
                        message.ops.push($root.lnrpc.Op.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MacaroonId message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.MacaroonId} MacaroonId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MacaroonId.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MacaroonId message.
         * @function verify
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MacaroonId.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!(message.nonce && typeof message.nonce.length === "number" || $util.isString(message.nonce)))
                    return "nonce: buffer expected";
            if (message.storageId != null && message.hasOwnProperty("storageId"))
                if (!(message.storageId && typeof message.storageId.length === "number" || $util.isString(message.storageId)))
                    return "storageId: buffer expected";
            if (message.ops != null && message.hasOwnProperty("ops")) {
                if (!Array.isArray(message.ops))
                    return "ops: array expected";
                for (let i = 0; i < message.ops.length; ++i) {
                    let error = $root.lnrpc.Op.verify(message.ops[i]);
                    if (error)
                        return "ops." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MacaroonId message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.MacaroonId} MacaroonId
         */
        MacaroonId.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.MacaroonId)
                return object;
            let message = new $root.lnrpc.MacaroonId();
            if (object.nonce != null)
                if (typeof object.nonce === "string")
                    $util.base64.decode(object.nonce, message.nonce = $util.newBuffer($util.base64.length(object.nonce)), 0);
                else if (object.nonce.length)
                    message.nonce = object.nonce;
            if (object.storageId != null)
                if (typeof object.storageId === "string")
                    $util.base64.decode(object.storageId, message.storageId = $util.newBuffer($util.base64.length(object.storageId)), 0);
                else if (object.storageId.length)
                    message.storageId = object.storageId;
            if (object.ops) {
                if (!Array.isArray(object.ops))
                    throw TypeError(".lnrpc.MacaroonId.ops: array expected");
                message.ops = [];
                for (let i = 0; i < object.ops.length; ++i) {
                    if (typeof object.ops[i] !== "object")
                        throw TypeError(".lnrpc.MacaroonId.ops: object expected");
                    message.ops[i] = $root.lnrpc.Op.fromObject(object.ops[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a MacaroonId message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.MacaroonId
         * @static
         * @param {lnrpc.MacaroonId} message MacaroonId
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MacaroonId.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ops = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.nonce = "";
                else {
                    object.nonce = [];
                    if (options.bytes !== Array)
                        object.nonce = $util.newBuffer(object.nonce);
                }
                if (options.bytes === String)
                    object.storageId = "";
                else {
                    object.storageId = [];
                    if (options.bytes !== Array)
                        object.storageId = $util.newBuffer(object.storageId);
                }
            }
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = options.bytes === String ? $util.base64.encode(message.nonce, 0, message.nonce.length) : options.bytes === Array ? Array.prototype.slice.call(message.nonce) : message.nonce;
            if (message.storageId != null && message.hasOwnProperty("storageId"))
                object.storageId = options.bytes === String ? $util.base64.encode(message.storageId, 0, message.storageId.length) : options.bytes === Array ? Array.prototype.slice.call(message.storageId) : message.storageId;
            if (message.ops && message.ops.length) {
                object.ops = [];
                for (let j = 0; j < message.ops.length; ++j)
                    object.ops[j] = $root.lnrpc.Op.toObject(message.ops[j], options);
            }
            return object;
        };

        /**
         * Converts this MacaroonId to JSON.
         * @function toJSON
         * @memberof lnrpc.MacaroonId
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MacaroonId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MacaroonId;
    })();

    lnrpc.Op = (function() {

        /**
         * Properties of an Op.
         * @memberof lnrpc
         * @interface IOp
         * @property {string|null} [entity] Op entity
         * @property {Array.<string>|null} [actions] Op actions
         */

        /**
         * Constructs a new Op.
         * @memberof lnrpc
         * @classdesc Represents an Op.
         * @implements IOp
         * @constructor
         * @param {lnrpc.IOp=} [properties] Properties to set
         */
        function Op(properties) {
            this.actions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Op entity.
         * @member {string} entity
         * @memberof lnrpc.Op
         * @instance
         */
        Op.prototype.entity = "";

        /**
         * Op actions.
         * @member {Array.<string>} actions
         * @memberof lnrpc.Op
         * @instance
         */
        Op.prototype.actions = $util.emptyArray;

        /**
         * Creates a new Op instance using the specified properties.
         * @function create
         * @memberof lnrpc.Op
         * @static
         * @param {lnrpc.IOp=} [properties] Properties to set
         * @returns {lnrpc.Op} Op instance
         */
        Op.create = function create(properties) {
            return new Op(properties);
        };

        /**
         * Encodes the specified Op message. Does not implicitly {@link lnrpc.Op.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.Op
         * @static
         * @param {lnrpc.IOp} message Op message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Op.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.entity);
            if (message.actions != null && message.actions.length)
                for (let i = 0; i < message.actions.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.actions[i]);
            return writer;
        };

        /**
         * Encodes the specified Op message, length delimited. Does not implicitly {@link lnrpc.Op.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.Op
         * @static
         * @param {lnrpc.IOp} message Op message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Op.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Op message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.Op
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.Op} Op
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Op.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.Op();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.entity = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.actions && message.actions.length))
                            message.actions = [];
                        message.actions.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Op message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.Op
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.Op} Op
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Op.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Op message.
         * @function verify
         * @memberof lnrpc.Op
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Op.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.entity != null && message.hasOwnProperty("entity"))
                if (!$util.isString(message.entity))
                    return "entity: string expected";
            if (message.actions != null && message.hasOwnProperty("actions")) {
                if (!Array.isArray(message.actions))
                    return "actions: array expected";
                for (let i = 0; i < message.actions.length; ++i)
                    if (!$util.isString(message.actions[i]))
                        return "actions: string[] expected";
            }
            return null;
        };

        /**
         * Creates an Op message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.Op
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.Op} Op
         */
        Op.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.Op)
                return object;
            let message = new $root.lnrpc.Op();
            if (object.entity != null)
                message.entity = String(object.entity);
            if (object.actions) {
                if (!Array.isArray(object.actions))
                    throw TypeError(".lnrpc.Op.actions: array expected");
                message.actions = [];
                for (let i = 0; i < object.actions.length; ++i)
                    message.actions[i] = String(object.actions[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an Op message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.Op
         * @static
         * @param {lnrpc.Op} message Op
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Op.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.actions = [];
            if (options.defaults)
                object.entity = "";
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = message.entity;
            if (message.actions && message.actions.length) {
                object.actions = [];
                for (let j = 0; j < message.actions.length; ++j)
                    object.actions[j] = message.actions[j];
            }
            return object;
        };

        /**
         * Converts this Op to JSON.
         * @function toJSON
         * @memberof lnrpc.Op
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Op.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Op;
    })();

    lnrpc.CheckMacPermRequest = (function() {

        /**
         * Properties of a CheckMacPermRequest.
         * @memberof lnrpc
         * @interface ICheckMacPermRequest
         * @property {Uint8Array|null} [macaroon] CheckMacPermRequest macaroon
         * @property {Array.<lnrpc.IMacaroonPermission>|null} [permissions] CheckMacPermRequest permissions
         * @property {string|null} [fullMethod] CheckMacPermRequest fullMethod
         */

        /**
         * Constructs a new CheckMacPermRequest.
         * @memberof lnrpc
         * @classdesc Represents a CheckMacPermRequest.
         * @implements ICheckMacPermRequest
         * @constructor
         * @param {lnrpc.ICheckMacPermRequest=} [properties] Properties to set
         */
        function CheckMacPermRequest(properties) {
            this.permissions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckMacPermRequest macaroon.
         * @member {Uint8Array} macaroon
         * @memberof lnrpc.CheckMacPermRequest
         * @instance
         */
        CheckMacPermRequest.prototype.macaroon = $util.newBuffer([]);

        /**
         * CheckMacPermRequest permissions.
         * @member {Array.<lnrpc.IMacaroonPermission>} permissions
         * @memberof lnrpc.CheckMacPermRequest
         * @instance
         */
        CheckMacPermRequest.prototype.permissions = $util.emptyArray;

        /**
         * CheckMacPermRequest fullMethod.
         * @member {string} fullMethod
         * @memberof lnrpc.CheckMacPermRequest
         * @instance
         */
        CheckMacPermRequest.prototype.fullMethod = "";

        /**
         * Creates a new CheckMacPermRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {lnrpc.ICheckMacPermRequest=} [properties] Properties to set
         * @returns {lnrpc.CheckMacPermRequest} CheckMacPermRequest instance
         */
        CheckMacPermRequest.create = function create(properties) {
            return new CheckMacPermRequest(properties);
        };

        /**
         * Encodes the specified CheckMacPermRequest message. Does not implicitly {@link lnrpc.CheckMacPermRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {lnrpc.ICheckMacPermRequest} message CheckMacPermRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckMacPermRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.macaroon != null && Object.hasOwnProperty.call(message, "macaroon"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.macaroon);
            if (message.permissions != null && message.permissions.length)
                for (let i = 0; i < message.permissions.length; ++i)
                    $root.lnrpc.MacaroonPermission.encode(message.permissions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.fullMethod != null && Object.hasOwnProperty.call(message, "fullMethod"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.fullMethod);
            return writer;
        };

        /**
         * Encodes the specified CheckMacPermRequest message, length delimited. Does not implicitly {@link lnrpc.CheckMacPermRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {lnrpc.ICheckMacPermRequest} message CheckMacPermRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckMacPermRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckMacPermRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.CheckMacPermRequest} CheckMacPermRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckMacPermRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.CheckMacPermRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.macaroon = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.permissions && message.permissions.length))
                            message.permissions = [];
                        message.permissions.push($root.lnrpc.MacaroonPermission.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.fullMethod = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckMacPermRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.CheckMacPermRequest} CheckMacPermRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckMacPermRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckMacPermRequest message.
         * @function verify
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckMacPermRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.macaroon != null && message.hasOwnProperty("macaroon"))
                if (!(message.macaroon && typeof message.macaroon.length === "number" || $util.isString(message.macaroon)))
                    return "macaroon: buffer expected";
            if (message.permissions != null && message.hasOwnProperty("permissions")) {
                if (!Array.isArray(message.permissions))
                    return "permissions: array expected";
                for (let i = 0; i < message.permissions.length; ++i) {
                    let error = $root.lnrpc.MacaroonPermission.verify(message.permissions[i]);
                    if (error)
                        return "permissions." + error;
                }
            }
            if (message.fullMethod != null && message.hasOwnProperty("fullMethod"))
                if (!$util.isString(message.fullMethod))
                    return "fullMethod: string expected";
            return null;
        };

        /**
         * Creates a CheckMacPermRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.CheckMacPermRequest} CheckMacPermRequest
         */
        CheckMacPermRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.CheckMacPermRequest)
                return object;
            let message = new $root.lnrpc.CheckMacPermRequest();
            if (object.macaroon != null)
                if (typeof object.macaroon === "string")
                    $util.base64.decode(object.macaroon, message.macaroon = $util.newBuffer($util.base64.length(object.macaroon)), 0);
                else if (object.macaroon.length)
                    message.macaroon = object.macaroon;
            if (object.permissions) {
                if (!Array.isArray(object.permissions))
                    throw TypeError(".lnrpc.CheckMacPermRequest.permissions: array expected");
                message.permissions = [];
                for (let i = 0; i < object.permissions.length; ++i) {
                    if (typeof object.permissions[i] !== "object")
                        throw TypeError(".lnrpc.CheckMacPermRequest.permissions: object expected");
                    message.permissions[i] = $root.lnrpc.MacaroonPermission.fromObject(object.permissions[i]);
                }
            }
            if (object.fullMethod != null)
                message.fullMethod = String(object.fullMethod);
            return message;
        };

        /**
         * Creates a plain object from a CheckMacPermRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.CheckMacPermRequest
         * @static
         * @param {lnrpc.CheckMacPermRequest} message CheckMacPermRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckMacPermRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.permissions = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.macaroon = "";
                else {
                    object.macaroon = [];
                    if (options.bytes !== Array)
                        object.macaroon = $util.newBuffer(object.macaroon);
                }
                object.fullMethod = "";
            }
            if (message.macaroon != null && message.hasOwnProperty("macaroon"))
                object.macaroon = options.bytes === String ? $util.base64.encode(message.macaroon, 0, message.macaroon.length) : options.bytes === Array ? Array.prototype.slice.call(message.macaroon) : message.macaroon;
            if (message.permissions && message.permissions.length) {
                object.permissions = [];
                for (let j = 0; j < message.permissions.length; ++j)
                    object.permissions[j] = $root.lnrpc.MacaroonPermission.toObject(message.permissions[j], options);
            }
            if (message.fullMethod != null && message.hasOwnProperty("fullMethod"))
                object.fullMethod = message.fullMethod;
            return object;
        };

        /**
         * Converts this CheckMacPermRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.CheckMacPermRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckMacPermRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CheckMacPermRequest;
    })();

    lnrpc.CheckMacPermResponse = (function() {

        /**
         * Properties of a CheckMacPermResponse.
         * @memberof lnrpc
         * @interface ICheckMacPermResponse
         * @property {boolean|null} [valid] CheckMacPermResponse valid
         */

        /**
         * Constructs a new CheckMacPermResponse.
         * @memberof lnrpc
         * @classdesc Represents a CheckMacPermResponse.
         * @implements ICheckMacPermResponse
         * @constructor
         * @param {lnrpc.ICheckMacPermResponse=} [properties] Properties to set
         */
        function CheckMacPermResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckMacPermResponse valid.
         * @member {boolean} valid
         * @memberof lnrpc.CheckMacPermResponse
         * @instance
         */
        CheckMacPermResponse.prototype.valid = false;

        /**
         * Creates a new CheckMacPermResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {lnrpc.ICheckMacPermResponse=} [properties] Properties to set
         * @returns {lnrpc.CheckMacPermResponse} CheckMacPermResponse instance
         */
        CheckMacPermResponse.create = function create(properties) {
            return new CheckMacPermResponse(properties);
        };

        /**
         * Encodes the specified CheckMacPermResponse message. Does not implicitly {@link lnrpc.CheckMacPermResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {lnrpc.ICheckMacPermResponse} message CheckMacPermResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckMacPermResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.valid);
            return writer;
        };

        /**
         * Encodes the specified CheckMacPermResponse message, length delimited. Does not implicitly {@link lnrpc.CheckMacPermResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {lnrpc.ICheckMacPermResponse} message CheckMacPermResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckMacPermResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CheckMacPermResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.CheckMacPermResponse} CheckMacPermResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckMacPermResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.CheckMacPermResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.valid = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CheckMacPermResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.CheckMacPermResponse} CheckMacPermResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckMacPermResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CheckMacPermResponse message.
         * @function verify
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CheckMacPermResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.valid != null && message.hasOwnProperty("valid"))
                if (typeof message.valid !== "boolean")
                    return "valid: boolean expected";
            return null;
        };

        /**
         * Creates a CheckMacPermResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.CheckMacPermResponse} CheckMacPermResponse
         */
        CheckMacPermResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.CheckMacPermResponse)
                return object;
            let message = new $root.lnrpc.CheckMacPermResponse();
            if (object.valid != null)
                message.valid = Boolean(object.valid);
            return message;
        };

        /**
         * Creates a plain object from a CheckMacPermResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.CheckMacPermResponse
         * @static
         * @param {lnrpc.CheckMacPermResponse} message CheckMacPermResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CheckMacPermResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.valid = false;
            if (message.valid != null && message.hasOwnProperty("valid"))
                object.valid = message.valid;
            return object;
        };

        /**
         * Converts this CheckMacPermResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.CheckMacPermResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CheckMacPermResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CheckMacPermResponse;
    })();

    lnrpc.RPCMiddlewareRequest = (function() {

        /**
         * Properties of a RPCMiddlewareRequest.
         * @memberof lnrpc
         * @interface IRPCMiddlewareRequest
         * @property {Long|null} [requestId] RPCMiddlewareRequest requestId
         * @property {Uint8Array|null} [rawMacaroon] RPCMiddlewareRequest rawMacaroon
         * @property {string|null} [customCaveatCondition] RPCMiddlewareRequest customCaveatCondition
         * @property {lnrpc.IStreamAuth|null} [streamAuth] RPCMiddlewareRequest streamAuth
         * @property {lnrpc.IRPCMessage|null} [request] RPCMiddlewareRequest request
         * @property {lnrpc.IRPCMessage|null} [response] RPCMiddlewareRequest response
         * @property {boolean|null} [regComplete] RPCMiddlewareRequest regComplete
         * @property {Long|null} [msgId] RPCMiddlewareRequest msgId
         */

        /**
         * Constructs a new RPCMiddlewareRequest.
         * @memberof lnrpc
         * @classdesc Represents a RPCMiddlewareRequest.
         * @implements IRPCMiddlewareRequest
         * @constructor
         * @param {lnrpc.IRPCMiddlewareRequest=} [properties] Properties to set
         */
        function RPCMiddlewareRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RPCMiddlewareRequest requestId.
         * @member {Long} requestId
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RPCMiddlewareRequest rawMacaroon.
         * @member {Uint8Array} rawMacaroon
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.rawMacaroon = $util.newBuffer([]);

        /**
         * RPCMiddlewareRequest customCaveatCondition.
         * @member {string} customCaveatCondition
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.customCaveatCondition = "";

        /**
         * RPCMiddlewareRequest streamAuth.
         * @member {lnrpc.IStreamAuth|null|undefined} streamAuth
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.streamAuth = null;

        /**
         * RPCMiddlewareRequest request.
         * @member {lnrpc.IRPCMessage|null|undefined} request
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.request = null;

        /**
         * RPCMiddlewareRequest response.
         * @member {lnrpc.IRPCMessage|null|undefined} response
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.response = null;

        /**
         * RPCMiddlewareRequest regComplete.
         * @member {boolean} regComplete
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.regComplete = false;

        /**
         * RPCMiddlewareRequest msgId.
         * @member {Long} msgId
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        RPCMiddlewareRequest.prototype.msgId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RPCMiddlewareRequest interceptType.
         * @member {"streamAuth"|"request"|"response"|"regComplete"|undefined} interceptType
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         */
        Object.defineProperty(RPCMiddlewareRequest.prototype, "interceptType", {
            get: $util.oneOfGetter($oneOfFields = ["streamAuth", "request", "response", "regComplete"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RPCMiddlewareRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {lnrpc.IRPCMiddlewareRequest=} [properties] Properties to set
         * @returns {lnrpc.RPCMiddlewareRequest} RPCMiddlewareRequest instance
         */
        RPCMiddlewareRequest.create = function create(properties) {
            return new RPCMiddlewareRequest(properties);
        };

        /**
         * Encodes the specified RPCMiddlewareRequest message. Does not implicitly {@link lnrpc.RPCMiddlewareRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {lnrpc.IRPCMiddlewareRequest} message RPCMiddlewareRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RPCMiddlewareRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
            if (message.rawMacaroon != null && Object.hasOwnProperty.call(message, "rawMacaroon"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rawMacaroon);
            if (message.customCaveatCondition != null && Object.hasOwnProperty.call(message, "customCaveatCondition"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.customCaveatCondition);
            if (message.streamAuth != null && Object.hasOwnProperty.call(message, "streamAuth"))
                $root.lnrpc.StreamAuth.encode(message.streamAuth, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.request != null && Object.hasOwnProperty.call(message, "request"))
                $root.lnrpc.RPCMessage.encode(message.request, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.response != null && Object.hasOwnProperty.call(message, "response"))
                $root.lnrpc.RPCMessage.encode(message.response, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.msgId != null && Object.hasOwnProperty.call(message, "msgId"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.msgId);
            if (message.regComplete != null && Object.hasOwnProperty.call(message, "regComplete"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.regComplete);
            return writer;
        };

        /**
         * Encodes the specified RPCMiddlewareRequest message, length delimited. Does not implicitly {@link lnrpc.RPCMiddlewareRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {lnrpc.IRPCMiddlewareRequest} message RPCMiddlewareRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RPCMiddlewareRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RPCMiddlewareRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RPCMiddlewareRequest} RPCMiddlewareRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RPCMiddlewareRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RPCMiddlewareRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.requestId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.rawMacaroon = reader.bytes();
                        break;
                    }
                case 3: {
                        message.customCaveatCondition = reader.string();
                        break;
                    }
                case 4: {
                        message.streamAuth = $root.lnrpc.StreamAuth.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.request = $root.lnrpc.RPCMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.response = $root.lnrpc.RPCMessage.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.regComplete = reader.bool();
                        break;
                    }
                case 7: {
                        message.msgId = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RPCMiddlewareRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RPCMiddlewareRequest} RPCMiddlewareRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RPCMiddlewareRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RPCMiddlewareRequest message.
         * @function verify
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RPCMiddlewareRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                    return "requestId: integer|Long expected";
            if (message.rawMacaroon != null && message.hasOwnProperty("rawMacaroon"))
                if (!(message.rawMacaroon && typeof message.rawMacaroon.length === "number" || $util.isString(message.rawMacaroon)))
                    return "rawMacaroon: buffer expected";
            if (message.customCaveatCondition != null && message.hasOwnProperty("customCaveatCondition"))
                if (!$util.isString(message.customCaveatCondition))
                    return "customCaveatCondition: string expected";
            if (message.streamAuth != null && message.hasOwnProperty("streamAuth")) {
                properties.interceptType = 1;
                {
                    let error = $root.lnrpc.StreamAuth.verify(message.streamAuth);
                    if (error)
                        return "streamAuth." + error;
                }
            }
            if (message.request != null && message.hasOwnProperty("request")) {
                if (properties.interceptType === 1)
                    return "interceptType: multiple values";
                properties.interceptType = 1;
                {
                    let error = $root.lnrpc.RPCMessage.verify(message.request);
                    if (error)
                        return "request." + error;
                }
            }
            if (message.response != null && message.hasOwnProperty("response")) {
                if (properties.interceptType === 1)
                    return "interceptType: multiple values";
                properties.interceptType = 1;
                {
                    let error = $root.lnrpc.RPCMessage.verify(message.response);
                    if (error)
                        return "response." + error;
                }
            }
            if (message.regComplete != null && message.hasOwnProperty("regComplete")) {
                if (properties.interceptType === 1)
                    return "interceptType: multiple values";
                properties.interceptType = 1;
                if (typeof message.regComplete !== "boolean")
                    return "regComplete: boolean expected";
            }
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (!$util.isInteger(message.msgId) && !(message.msgId && $util.isInteger(message.msgId.low) && $util.isInteger(message.msgId.high)))
                    return "msgId: integer|Long expected";
            return null;
        };

        /**
         * Creates a RPCMiddlewareRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RPCMiddlewareRequest} RPCMiddlewareRequest
         */
        RPCMiddlewareRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RPCMiddlewareRequest)
                return object;
            let message = new $root.lnrpc.RPCMiddlewareRequest();
            if (object.requestId != null)
                if ($util.Long)
                    (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                else if (typeof object.requestId === "string")
                    message.requestId = parseInt(object.requestId, 10);
                else if (typeof object.requestId === "number")
                    message.requestId = object.requestId;
                else if (typeof object.requestId === "object")
                    message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
            if (object.rawMacaroon != null)
                if (typeof object.rawMacaroon === "string")
                    $util.base64.decode(object.rawMacaroon, message.rawMacaroon = $util.newBuffer($util.base64.length(object.rawMacaroon)), 0);
                else if (object.rawMacaroon.length)
                    message.rawMacaroon = object.rawMacaroon;
            if (object.customCaveatCondition != null)
                message.customCaveatCondition = String(object.customCaveatCondition);
            if (object.streamAuth != null) {
                if (typeof object.streamAuth !== "object")
                    throw TypeError(".lnrpc.RPCMiddlewareRequest.streamAuth: object expected");
                message.streamAuth = $root.lnrpc.StreamAuth.fromObject(object.streamAuth);
            }
            if (object.request != null) {
                if (typeof object.request !== "object")
                    throw TypeError(".lnrpc.RPCMiddlewareRequest.request: object expected");
                message.request = $root.lnrpc.RPCMessage.fromObject(object.request);
            }
            if (object.response != null) {
                if (typeof object.response !== "object")
                    throw TypeError(".lnrpc.RPCMiddlewareRequest.response: object expected");
                message.response = $root.lnrpc.RPCMessage.fromObject(object.response);
            }
            if (object.regComplete != null)
                message.regComplete = Boolean(object.regComplete);
            if (object.msgId != null)
                if ($util.Long)
                    (message.msgId = $util.Long.fromValue(object.msgId)).unsigned = true;
                else if (typeof object.msgId === "string")
                    message.msgId = parseInt(object.msgId, 10);
                else if (typeof object.msgId === "number")
                    message.msgId = object.msgId;
                else if (typeof object.msgId === "object")
                    message.msgId = new $util.LongBits(object.msgId.low >>> 0, object.msgId.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a RPCMiddlewareRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RPCMiddlewareRequest
         * @static
         * @param {lnrpc.RPCMiddlewareRequest} message RPCMiddlewareRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RPCMiddlewareRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.requestId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestId = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.rawMacaroon = "";
                else {
                    object.rawMacaroon = [];
                    if (options.bytes !== Array)
                        object.rawMacaroon = $util.newBuffer(object.rawMacaroon);
                }
                object.customCaveatCondition = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.msgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.msgId = options.longs === String ? "0" : 0;
            }
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (typeof message.requestId === "number")
                    object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                else
                    object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
            if (message.rawMacaroon != null && message.hasOwnProperty("rawMacaroon"))
                object.rawMacaroon = options.bytes === String ? $util.base64.encode(message.rawMacaroon, 0, message.rawMacaroon.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawMacaroon) : message.rawMacaroon;
            if (message.customCaveatCondition != null && message.hasOwnProperty("customCaveatCondition"))
                object.customCaveatCondition = message.customCaveatCondition;
            if (message.streamAuth != null && message.hasOwnProperty("streamAuth")) {
                object.streamAuth = $root.lnrpc.StreamAuth.toObject(message.streamAuth, options);
                if (options.oneofs)
                    object.interceptType = "streamAuth";
            }
            if (message.request != null && message.hasOwnProperty("request")) {
                object.request = $root.lnrpc.RPCMessage.toObject(message.request, options);
                if (options.oneofs)
                    object.interceptType = "request";
            }
            if (message.response != null && message.hasOwnProperty("response")) {
                object.response = $root.lnrpc.RPCMessage.toObject(message.response, options);
                if (options.oneofs)
                    object.interceptType = "response";
            }
            if (message.msgId != null && message.hasOwnProperty("msgId"))
                if (typeof message.msgId === "number")
                    object.msgId = options.longs === String ? String(message.msgId) : message.msgId;
                else
                    object.msgId = options.longs === String ? $util.Long.prototype.toString.call(message.msgId) : options.longs === Number ? new $util.LongBits(message.msgId.low >>> 0, message.msgId.high >>> 0).toNumber(true) : message.msgId;
            if (message.regComplete != null && message.hasOwnProperty("regComplete")) {
                object.regComplete = message.regComplete;
                if (options.oneofs)
                    object.interceptType = "regComplete";
            }
            return object;
        };

        /**
         * Converts this RPCMiddlewareRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.RPCMiddlewareRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RPCMiddlewareRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RPCMiddlewareRequest;
    })();

    lnrpc.StreamAuth = (function() {

        /**
         * Properties of a StreamAuth.
         * @memberof lnrpc
         * @interface IStreamAuth
         * @property {string|null} [methodFullUri] StreamAuth methodFullUri
         */

        /**
         * Constructs a new StreamAuth.
         * @memberof lnrpc
         * @classdesc Represents a StreamAuth.
         * @implements IStreamAuth
         * @constructor
         * @param {lnrpc.IStreamAuth=} [properties] Properties to set
         */
        function StreamAuth(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StreamAuth methodFullUri.
         * @member {string} methodFullUri
         * @memberof lnrpc.StreamAuth
         * @instance
         */
        StreamAuth.prototype.methodFullUri = "";

        /**
         * Creates a new StreamAuth instance using the specified properties.
         * @function create
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {lnrpc.IStreamAuth=} [properties] Properties to set
         * @returns {lnrpc.StreamAuth} StreamAuth instance
         */
        StreamAuth.create = function create(properties) {
            return new StreamAuth(properties);
        };

        /**
         * Encodes the specified StreamAuth message. Does not implicitly {@link lnrpc.StreamAuth.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {lnrpc.IStreamAuth} message StreamAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StreamAuth.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.methodFullUri != null && Object.hasOwnProperty.call(message, "methodFullUri"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.methodFullUri);
            return writer;
        };

        /**
         * Encodes the specified StreamAuth message, length delimited. Does not implicitly {@link lnrpc.StreamAuth.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {lnrpc.IStreamAuth} message StreamAuth message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StreamAuth.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StreamAuth message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.StreamAuth} StreamAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StreamAuth.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.StreamAuth();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.methodFullUri = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StreamAuth message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.StreamAuth} StreamAuth
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StreamAuth.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StreamAuth message.
         * @function verify
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StreamAuth.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.methodFullUri != null && message.hasOwnProperty("methodFullUri"))
                if (!$util.isString(message.methodFullUri))
                    return "methodFullUri: string expected";
            return null;
        };

        /**
         * Creates a StreamAuth message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.StreamAuth} StreamAuth
         */
        StreamAuth.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.StreamAuth)
                return object;
            let message = new $root.lnrpc.StreamAuth();
            if (object.methodFullUri != null)
                message.methodFullUri = String(object.methodFullUri);
            return message;
        };

        /**
         * Creates a plain object from a StreamAuth message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.StreamAuth
         * @static
         * @param {lnrpc.StreamAuth} message StreamAuth
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StreamAuth.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.methodFullUri = "";
            if (message.methodFullUri != null && message.hasOwnProperty("methodFullUri"))
                object.methodFullUri = message.methodFullUri;
            return object;
        };

        /**
         * Converts this StreamAuth to JSON.
         * @function toJSON
         * @memberof lnrpc.StreamAuth
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StreamAuth.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StreamAuth;
    })();

    lnrpc.RPCMessage = (function() {

        /**
         * Properties of a RPCMessage.
         * @memberof lnrpc
         * @interface IRPCMessage
         * @property {string|null} [methodFullUri] RPCMessage methodFullUri
         * @property {boolean|null} [streamRpc] RPCMessage streamRpc
         * @property {string|null} [typeName] RPCMessage typeName
         * @property {Uint8Array|null} [serialized] RPCMessage serialized
         * @property {boolean|null} [isError] RPCMessage isError
         */

        /**
         * Constructs a new RPCMessage.
         * @memberof lnrpc
         * @classdesc Represents a RPCMessage.
         * @implements IRPCMessage
         * @constructor
         * @param {lnrpc.IRPCMessage=} [properties] Properties to set
         */
        function RPCMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RPCMessage methodFullUri.
         * @member {string} methodFullUri
         * @memberof lnrpc.RPCMessage
         * @instance
         */
        RPCMessage.prototype.methodFullUri = "";

        /**
         * RPCMessage streamRpc.
         * @member {boolean} streamRpc
         * @memberof lnrpc.RPCMessage
         * @instance
         */
        RPCMessage.prototype.streamRpc = false;

        /**
         * RPCMessage typeName.
         * @member {string} typeName
         * @memberof lnrpc.RPCMessage
         * @instance
         */
        RPCMessage.prototype.typeName = "";

        /**
         * RPCMessage serialized.
         * @member {Uint8Array} serialized
         * @memberof lnrpc.RPCMessage
         * @instance
         */
        RPCMessage.prototype.serialized = $util.newBuffer([]);

        /**
         * RPCMessage isError.
         * @member {boolean} isError
         * @memberof lnrpc.RPCMessage
         * @instance
         */
        RPCMessage.prototype.isError = false;

        /**
         * Creates a new RPCMessage instance using the specified properties.
         * @function create
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {lnrpc.IRPCMessage=} [properties] Properties to set
         * @returns {lnrpc.RPCMessage} RPCMessage instance
         */
        RPCMessage.create = function create(properties) {
            return new RPCMessage(properties);
        };

        /**
         * Encodes the specified RPCMessage message. Does not implicitly {@link lnrpc.RPCMessage.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {lnrpc.IRPCMessage} message RPCMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RPCMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.methodFullUri != null && Object.hasOwnProperty.call(message, "methodFullUri"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.methodFullUri);
            if (message.streamRpc != null && Object.hasOwnProperty.call(message, "streamRpc"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.streamRpc);
            if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.typeName);
            if (message.serialized != null && Object.hasOwnProperty.call(message, "serialized"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.serialized);
            if (message.isError != null && Object.hasOwnProperty.call(message, "isError"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isError);
            return writer;
        };

        /**
         * Encodes the specified RPCMessage message, length delimited. Does not implicitly {@link lnrpc.RPCMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {lnrpc.IRPCMessage} message RPCMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RPCMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RPCMessage message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RPCMessage} RPCMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RPCMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RPCMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.methodFullUri = reader.string();
                        break;
                    }
                case 2: {
                        message.streamRpc = reader.bool();
                        break;
                    }
                case 3: {
                        message.typeName = reader.string();
                        break;
                    }
                case 4: {
                        message.serialized = reader.bytes();
                        break;
                    }
                case 5: {
                        message.isError = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RPCMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RPCMessage} RPCMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RPCMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RPCMessage message.
         * @function verify
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RPCMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.methodFullUri != null && message.hasOwnProperty("methodFullUri"))
                if (!$util.isString(message.methodFullUri))
                    return "methodFullUri: string expected";
            if (message.streamRpc != null && message.hasOwnProperty("streamRpc"))
                if (typeof message.streamRpc !== "boolean")
                    return "streamRpc: boolean expected";
            if (message.typeName != null && message.hasOwnProperty("typeName"))
                if (!$util.isString(message.typeName))
                    return "typeName: string expected";
            if (message.serialized != null && message.hasOwnProperty("serialized"))
                if (!(message.serialized && typeof message.serialized.length === "number" || $util.isString(message.serialized)))
                    return "serialized: buffer expected";
            if (message.isError != null && message.hasOwnProperty("isError"))
                if (typeof message.isError !== "boolean")
                    return "isError: boolean expected";
            return null;
        };

        /**
         * Creates a RPCMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RPCMessage} RPCMessage
         */
        RPCMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RPCMessage)
                return object;
            let message = new $root.lnrpc.RPCMessage();
            if (object.methodFullUri != null)
                message.methodFullUri = String(object.methodFullUri);
            if (object.streamRpc != null)
                message.streamRpc = Boolean(object.streamRpc);
            if (object.typeName != null)
                message.typeName = String(object.typeName);
            if (object.serialized != null)
                if (typeof object.serialized === "string")
                    $util.base64.decode(object.serialized, message.serialized = $util.newBuffer($util.base64.length(object.serialized)), 0);
                else if (object.serialized.length)
                    message.serialized = object.serialized;
            if (object.isError != null)
                message.isError = Boolean(object.isError);
            return message;
        };

        /**
         * Creates a plain object from a RPCMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RPCMessage
         * @static
         * @param {lnrpc.RPCMessage} message RPCMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RPCMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.methodFullUri = "";
                object.streamRpc = false;
                object.typeName = "";
                if (options.bytes === String)
                    object.serialized = "";
                else {
                    object.serialized = [];
                    if (options.bytes !== Array)
                        object.serialized = $util.newBuffer(object.serialized);
                }
                object.isError = false;
            }
            if (message.methodFullUri != null && message.hasOwnProperty("methodFullUri"))
                object.methodFullUri = message.methodFullUri;
            if (message.streamRpc != null && message.hasOwnProperty("streamRpc"))
                object.streamRpc = message.streamRpc;
            if (message.typeName != null && message.hasOwnProperty("typeName"))
                object.typeName = message.typeName;
            if (message.serialized != null && message.hasOwnProperty("serialized"))
                object.serialized = options.bytes === String ? $util.base64.encode(message.serialized, 0, message.serialized.length) : options.bytes === Array ? Array.prototype.slice.call(message.serialized) : message.serialized;
            if (message.isError != null && message.hasOwnProperty("isError"))
                object.isError = message.isError;
            return object;
        };

        /**
         * Converts this RPCMessage to JSON.
         * @function toJSON
         * @memberof lnrpc.RPCMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RPCMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RPCMessage;
    })();

    lnrpc.RPCMiddlewareResponse = (function() {

        /**
         * Properties of a RPCMiddlewareResponse.
         * @memberof lnrpc
         * @interface IRPCMiddlewareResponse
         * @property {Long|null} [refMsgId] RPCMiddlewareResponse refMsgId
         * @property {lnrpc.IMiddlewareRegistration|null} [register] RPCMiddlewareResponse register
         * @property {lnrpc.IInterceptFeedback|null} [feedback] RPCMiddlewareResponse feedback
         */

        /**
         * Constructs a new RPCMiddlewareResponse.
         * @memberof lnrpc
         * @classdesc Represents a RPCMiddlewareResponse.
         * @implements IRPCMiddlewareResponse
         * @constructor
         * @param {lnrpc.IRPCMiddlewareResponse=} [properties] Properties to set
         */
        function RPCMiddlewareResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RPCMiddlewareResponse refMsgId.
         * @member {Long} refMsgId
         * @memberof lnrpc.RPCMiddlewareResponse
         * @instance
         */
        RPCMiddlewareResponse.prototype.refMsgId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * RPCMiddlewareResponse register.
         * @member {lnrpc.IMiddlewareRegistration|null|undefined} register
         * @memberof lnrpc.RPCMiddlewareResponse
         * @instance
         */
        RPCMiddlewareResponse.prototype.register = null;

        /**
         * RPCMiddlewareResponse feedback.
         * @member {lnrpc.IInterceptFeedback|null|undefined} feedback
         * @memberof lnrpc.RPCMiddlewareResponse
         * @instance
         */
        RPCMiddlewareResponse.prototype.feedback = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * RPCMiddlewareResponse middlewareMessage.
         * @member {"register"|"feedback"|undefined} middlewareMessage
         * @memberof lnrpc.RPCMiddlewareResponse
         * @instance
         */
        Object.defineProperty(RPCMiddlewareResponse.prototype, "middlewareMessage", {
            get: $util.oneOfGetter($oneOfFields = ["register", "feedback"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new RPCMiddlewareResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {lnrpc.IRPCMiddlewareResponse=} [properties] Properties to set
         * @returns {lnrpc.RPCMiddlewareResponse} RPCMiddlewareResponse instance
         */
        RPCMiddlewareResponse.create = function create(properties) {
            return new RPCMiddlewareResponse(properties);
        };

        /**
         * Encodes the specified RPCMiddlewareResponse message. Does not implicitly {@link lnrpc.RPCMiddlewareResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {lnrpc.IRPCMiddlewareResponse} message RPCMiddlewareResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RPCMiddlewareResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refMsgId != null && Object.hasOwnProperty.call(message, "refMsgId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.refMsgId);
            if (message.register != null && Object.hasOwnProperty.call(message, "register"))
                $root.lnrpc.MiddlewareRegistration.encode(message.register, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.feedback != null && Object.hasOwnProperty.call(message, "feedback"))
                $root.lnrpc.InterceptFeedback.encode(message.feedback, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RPCMiddlewareResponse message, length delimited. Does not implicitly {@link lnrpc.RPCMiddlewareResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {lnrpc.IRPCMiddlewareResponse} message RPCMiddlewareResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RPCMiddlewareResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RPCMiddlewareResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.RPCMiddlewareResponse} RPCMiddlewareResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RPCMiddlewareResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.RPCMiddlewareResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.refMsgId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.register = $root.lnrpc.MiddlewareRegistration.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.feedback = $root.lnrpc.InterceptFeedback.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RPCMiddlewareResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.RPCMiddlewareResponse} RPCMiddlewareResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RPCMiddlewareResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RPCMiddlewareResponse message.
         * @function verify
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RPCMiddlewareResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.refMsgId != null && message.hasOwnProperty("refMsgId"))
                if (!$util.isInteger(message.refMsgId) && !(message.refMsgId && $util.isInteger(message.refMsgId.low) && $util.isInteger(message.refMsgId.high)))
                    return "refMsgId: integer|Long expected";
            if (message.register != null && message.hasOwnProperty("register")) {
                properties.middlewareMessage = 1;
                {
                    let error = $root.lnrpc.MiddlewareRegistration.verify(message.register);
                    if (error)
                        return "register." + error;
                }
            }
            if (message.feedback != null && message.hasOwnProperty("feedback")) {
                if (properties.middlewareMessage === 1)
                    return "middlewareMessage: multiple values";
                properties.middlewareMessage = 1;
                {
                    let error = $root.lnrpc.InterceptFeedback.verify(message.feedback);
                    if (error)
                        return "feedback." + error;
                }
            }
            return null;
        };

        /**
         * Creates a RPCMiddlewareResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.RPCMiddlewareResponse} RPCMiddlewareResponse
         */
        RPCMiddlewareResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.RPCMiddlewareResponse)
                return object;
            let message = new $root.lnrpc.RPCMiddlewareResponse();
            if (object.refMsgId != null)
                if ($util.Long)
                    (message.refMsgId = $util.Long.fromValue(object.refMsgId)).unsigned = true;
                else if (typeof object.refMsgId === "string")
                    message.refMsgId = parseInt(object.refMsgId, 10);
                else if (typeof object.refMsgId === "number")
                    message.refMsgId = object.refMsgId;
                else if (typeof object.refMsgId === "object")
                    message.refMsgId = new $util.LongBits(object.refMsgId.low >>> 0, object.refMsgId.high >>> 0).toNumber(true);
            if (object.register != null) {
                if (typeof object.register !== "object")
                    throw TypeError(".lnrpc.RPCMiddlewareResponse.register: object expected");
                message.register = $root.lnrpc.MiddlewareRegistration.fromObject(object.register);
            }
            if (object.feedback != null) {
                if (typeof object.feedback !== "object")
                    throw TypeError(".lnrpc.RPCMiddlewareResponse.feedback: object expected");
                message.feedback = $root.lnrpc.InterceptFeedback.fromObject(object.feedback);
            }
            return message;
        };

        /**
         * Creates a plain object from a RPCMiddlewareResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.RPCMiddlewareResponse
         * @static
         * @param {lnrpc.RPCMiddlewareResponse} message RPCMiddlewareResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RPCMiddlewareResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.refMsgId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.refMsgId = options.longs === String ? "0" : 0;
            if (message.refMsgId != null && message.hasOwnProperty("refMsgId"))
                if (typeof message.refMsgId === "number")
                    object.refMsgId = options.longs === String ? String(message.refMsgId) : message.refMsgId;
                else
                    object.refMsgId = options.longs === String ? $util.Long.prototype.toString.call(message.refMsgId) : options.longs === Number ? new $util.LongBits(message.refMsgId.low >>> 0, message.refMsgId.high >>> 0).toNumber(true) : message.refMsgId;
            if (message.register != null && message.hasOwnProperty("register")) {
                object.register = $root.lnrpc.MiddlewareRegistration.toObject(message.register, options);
                if (options.oneofs)
                    object.middlewareMessage = "register";
            }
            if (message.feedback != null && message.hasOwnProperty("feedback")) {
                object.feedback = $root.lnrpc.InterceptFeedback.toObject(message.feedback, options);
                if (options.oneofs)
                    object.middlewareMessage = "feedback";
            }
            return object;
        };

        /**
         * Converts this RPCMiddlewareResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.RPCMiddlewareResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RPCMiddlewareResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RPCMiddlewareResponse;
    })();

    lnrpc.MiddlewareRegistration = (function() {

        /**
         * Properties of a MiddlewareRegistration.
         * @memberof lnrpc
         * @interface IMiddlewareRegistration
         * @property {string|null} [middlewareName] MiddlewareRegistration middlewareName
         * @property {string|null} [customMacaroonCaveatName] MiddlewareRegistration customMacaroonCaveatName
         * @property {boolean|null} [readOnlyMode] MiddlewareRegistration readOnlyMode
         */

        /**
         * Constructs a new MiddlewareRegistration.
         * @memberof lnrpc
         * @classdesc Represents a MiddlewareRegistration.
         * @implements IMiddlewareRegistration
         * @constructor
         * @param {lnrpc.IMiddlewareRegistration=} [properties] Properties to set
         */
        function MiddlewareRegistration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MiddlewareRegistration middlewareName.
         * @member {string} middlewareName
         * @memberof lnrpc.MiddlewareRegistration
         * @instance
         */
        MiddlewareRegistration.prototype.middlewareName = "";

        /**
         * MiddlewareRegistration customMacaroonCaveatName.
         * @member {string} customMacaroonCaveatName
         * @memberof lnrpc.MiddlewareRegistration
         * @instance
         */
        MiddlewareRegistration.prototype.customMacaroonCaveatName = "";

        /**
         * MiddlewareRegistration readOnlyMode.
         * @member {boolean} readOnlyMode
         * @memberof lnrpc.MiddlewareRegistration
         * @instance
         */
        MiddlewareRegistration.prototype.readOnlyMode = false;

        /**
         * Creates a new MiddlewareRegistration instance using the specified properties.
         * @function create
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {lnrpc.IMiddlewareRegistration=} [properties] Properties to set
         * @returns {lnrpc.MiddlewareRegistration} MiddlewareRegistration instance
         */
        MiddlewareRegistration.create = function create(properties) {
            return new MiddlewareRegistration(properties);
        };

        /**
         * Encodes the specified MiddlewareRegistration message. Does not implicitly {@link lnrpc.MiddlewareRegistration.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {lnrpc.IMiddlewareRegistration} message MiddlewareRegistration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MiddlewareRegistration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.middlewareName != null && Object.hasOwnProperty.call(message, "middlewareName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.middlewareName);
            if (message.customMacaroonCaveatName != null && Object.hasOwnProperty.call(message, "customMacaroonCaveatName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.customMacaroonCaveatName);
            if (message.readOnlyMode != null && Object.hasOwnProperty.call(message, "readOnlyMode"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.readOnlyMode);
            return writer;
        };

        /**
         * Encodes the specified MiddlewareRegistration message, length delimited. Does not implicitly {@link lnrpc.MiddlewareRegistration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {lnrpc.IMiddlewareRegistration} message MiddlewareRegistration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MiddlewareRegistration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MiddlewareRegistration message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.MiddlewareRegistration} MiddlewareRegistration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MiddlewareRegistration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.MiddlewareRegistration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.middlewareName = reader.string();
                        break;
                    }
                case 2: {
                        message.customMacaroonCaveatName = reader.string();
                        break;
                    }
                case 3: {
                        message.readOnlyMode = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MiddlewareRegistration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.MiddlewareRegistration} MiddlewareRegistration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MiddlewareRegistration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MiddlewareRegistration message.
         * @function verify
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MiddlewareRegistration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.middlewareName != null && message.hasOwnProperty("middlewareName"))
                if (!$util.isString(message.middlewareName))
                    return "middlewareName: string expected";
            if (message.customMacaroonCaveatName != null && message.hasOwnProperty("customMacaroonCaveatName"))
                if (!$util.isString(message.customMacaroonCaveatName))
                    return "customMacaroonCaveatName: string expected";
            if (message.readOnlyMode != null && message.hasOwnProperty("readOnlyMode"))
                if (typeof message.readOnlyMode !== "boolean")
                    return "readOnlyMode: boolean expected";
            return null;
        };

        /**
         * Creates a MiddlewareRegistration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.MiddlewareRegistration} MiddlewareRegistration
         */
        MiddlewareRegistration.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.MiddlewareRegistration)
                return object;
            let message = new $root.lnrpc.MiddlewareRegistration();
            if (object.middlewareName != null)
                message.middlewareName = String(object.middlewareName);
            if (object.customMacaroonCaveatName != null)
                message.customMacaroonCaveatName = String(object.customMacaroonCaveatName);
            if (object.readOnlyMode != null)
                message.readOnlyMode = Boolean(object.readOnlyMode);
            return message;
        };

        /**
         * Creates a plain object from a MiddlewareRegistration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.MiddlewareRegistration
         * @static
         * @param {lnrpc.MiddlewareRegistration} message MiddlewareRegistration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MiddlewareRegistration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.middlewareName = "";
                object.customMacaroonCaveatName = "";
                object.readOnlyMode = false;
            }
            if (message.middlewareName != null && message.hasOwnProperty("middlewareName"))
                object.middlewareName = message.middlewareName;
            if (message.customMacaroonCaveatName != null && message.hasOwnProperty("customMacaroonCaveatName"))
                object.customMacaroonCaveatName = message.customMacaroonCaveatName;
            if (message.readOnlyMode != null && message.hasOwnProperty("readOnlyMode"))
                object.readOnlyMode = message.readOnlyMode;
            return object;
        };

        /**
         * Converts this MiddlewareRegistration to JSON.
         * @function toJSON
         * @memberof lnrpc.MiddlewareRegistration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MiddlewareRegistration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MiddlewareRegistration;
    })();

    lnrpc.InterceptFeedback = (function() {

        /**
         * Properties of an InterceptFeedback.
         * @memberof lnrpc
         * @interface IInterceptFeedback
         * @property {string|null} [error] InterceptFeedback error
         * @property {boolean|null} [replaceResponse] InterceptFeedback replaceResponse
         * @property {Uint8Array|null} [replacementSerialized] InterceptFeedback replacementSerialized
         */

        /**
         * Constructs a new InterceptFeedback.
         * @memberof lnrpc
         * @classdesc Represents an InterceptFeedback.
         * @implements IInterceptFeedback
         * @constructor
         * @param {lnrpc.IInterceptFeedback=} [properties] Properties to set
         */
        function InterceptFeedback(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InterceptFeedback error.
         * @member {string} error
         * @memberof lnrpc.InterceptFeedback
         * @instance
         */
        InterceptFeedback.prototype.error = "";

        /**
         * InterceptFeedback replaceResponse.
         * @member {boolean} replaceResponse
         * @memberof lnrpc.InterceptFeedback
         * @instance
         */
        InterceptFeedback.prototype.replaceResponse = false;

        /**
         * InterceptFeedback replacementSerialized.
         * @member {Uint8Array} replacementSerialized
         * @memberof lnrpc.InterceptFeedback
         * @instance
         */
        InterceptFeedback.prototype.replacementSerialized = $util.newBuffer([]);

        /**
         * Creates a new InterceptFeedback instance using the specified properties.
         * @function create
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {lnrpc.IInterceptFeedback=} [properties] Properties to set
         * @returns {lnrpc.InterceptFeedback} InterceptFeedback instance
         */
        InterceptFeedback.create = function create(properties) {
            return new InterceptFeedback(properties);
        };

        /**
         * Encodes the specified InterceptFeedback message. Does not implicitly {@link lnrpc.InterceptFeedback.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {lnrpc.IInterceptFeedback} message InterceptFeedback message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InterceptFeedback.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.error);
            if (message.replaceResponse != null && Object.hasOwnProperty.call(message, "replaceResponse"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.replaceResponse);
            if (message.replacementSerialized != null && Object.hasOwnProperty.call(message, "replacementSerialized"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.replacementSerialized);
            return writer;
        };

        /**
         * Encodes the specified InterceptFeedback message, length delimited. Does not implicitly {@link lnrpc.InterceptFeedback.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {lnrpc.IInterceptFeedback} message InterceptFeedback message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InterceptFeedback.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InterceptFeedback message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.InterceptFeedback} InterceptFeedback
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InterceptFeedback.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.InterceptFeedback();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.error = reader.string();
                        break;
                    }
                case 2: {
                        message.replaceResponse = reader.bool();
                        break;
                    }
                case 3: {
                        message.replacementSerialized = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InterceptFeedback message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.InterceptFeedback} InterceptFeedback
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InterceptFeedback.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InterceptFeedback message.
         * @function verify
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InterceptFeedback.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.replaceResponse != null && message.hasOwnProperty("replaceResponse"))
                if (typeof message.replaceResponse !== "boolean")
                    return "replaceResponse: boolean expected";
            if (message.replacementSerialized != null && message.hasOwnProperty("replacementSerialized"))
                if (!(message.replacementSerialized && typeof message.replacementSerialized.length === "number" || $util.isString(message.replacementSerialized)))
                    return "replacementSerialized: buffer expected";
            return null;
        };

        /**
         * Creates an InterceptFeedback message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.InterceptFeedback} InterceptFeedback
         */
        InterceptFeedback.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.InterceptFeedback)
                return object;
            let message = new $root.lnrpc.InterceptFeedback();
            if (object.error != null)
                message.error = String(object.error);
            if (object.replaceResponse != null)
                message.replaceResponse = Boolean(object.replaceResponse);
            if (object.replacementSerialized != null)
                if (typeof object.replacementSerialized === "string")
                    $util.base64.decode(object.replacementSerialized, message.replacementSerialized = $util.newBuffer($util.base64.length(object.replacementSerialized)), 0);
                else if (object.replacementSerialized.length)
                    message.replacementSerialized = object.replacementSerialized;
            return message;
        };

        /**
         * Creates a plain object from an InterceptFeedback message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.InterceptFeedback
         * @static
         * @param {lnrpc.InterceptFeedback} message InterceptFeedback
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InterceptFeedback.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.error = "";
                object.replaceResponse = false;
                if (options.bytes === String)
                    object.replacementSerialized = "";
                else {
                    object.replacementSerialized = [];
                    if (options.bytes !== Array)
                        object.replacementSerialized = $util.newBuffer(object.replacementSerialized);
                }
            }
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.replaceResponse != null && message.hasOwnProperty("replaceResponse"))
                object.replaceResponse = message.replaceResponse;
            if (message.replacementSerialized != null && message.hasOwnProperty("replacementSerialized"))
                object.replacementSerialized = options.bytes === String ? $util.base64.encode(message.replacementSerialized, 0, message.replacementSerialized.length) : options.bytes === Array ? Array.prototype.slice.call(message.replacementSerialized) : message.replacementSerialized;
            return object;
        };

        /**
         * Converts this InterceptFeedback to JSON.
         * @function toJSON
         * @memberof lnrpc.InterceptFeedback
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InterceptFeedback.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InterceptFeedback;
    })();

    lnrpc.State = (function() {

        /**
         * Constructs a new State service.
         * @memberof lnrpc
         * @classdesc Represents a State
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function State(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (State.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = State;

        /**
         * Creates new State service using the specified rpc implementation.
         * @function create
         * @memberof lnrpc.State
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {State} RPC service. Useful where requests and/or responses are streamed.
         */
        State.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link lnrpc.State#subscribeState}.
         * @memberof lnrpc.State
         * @typedef SubscribeStateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.SubscribeStateResponse} [response] SubscribeStateResponse
         */

        /**
         * Calls SubscribeState.
         * @function subscribeState
         * @memberof lnrpc.State
         * @instance
         * @param {lnrpc.ISubscribeStateRequest} request SubscribeStateRequest message or plain object
         * @param {lnrpc.State.SubscribeStateCallback} callback Node-style callback called with the error, if any, and SubscribeStateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(State.prototype.subscribeState = function subscribeState(request, callback) {
            return this.rpcCall(subscribeState, $root.lnrpc.SubscribeStateRequest, $root.lnrpc.SubscribeStateResponse, request, callback);
        }, "name", { value: "SubscribeState" });

        /**
         * Calls SubscribeState.
         * @function subscribeState
         * @memberof lnrpc.State
         * @instance
         * @param {lnrpc.ISubscribeStateRequest} request SubscribeStateRequest message or plain object
         * @returns {Promise<lnrpc.SubscribeStateResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.State#getState}.
         * @memberof lnrpc.State
         * @typedef GetStateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.GetStateResponse} [response] GetStateResponse
         */

        /**
         * Calls GetState.
         * @function getState
         * @memberof lnrpc.State
         * @instance
         * @param {lnrpc.IGetStateRequest} request GetStateRequest message or plain object
         * @param {lnrpc.State.GetStateCallback} callback Node-style callback called with the error, if any, and GetStateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(State.prototype.getState = function getState(request, callback) {
            return this.rpcCall(getState, $root.lnrpc.GetStateRequest, $root.lnrpc.GetStateResponse, request, callback);
        }, "name", { value: "GetState" });

        /**
         * Calls GetState.
         * @function getState
         * @memberof lnrpc.State
         * @instance
         * @param {lnrpc.IGetStateRequest} request GetStateRequest message or plain object
         * @returns {Promise<lnrpc.GetStateResponse>} Promise
         * @variation 2
         */

        return State;
    })();

    /**
     * WalletState enum.
     * @name lnrpc.WalletState
     * @enum {number}
     * @property {number} NON_EXISTING=0 NON_EXISTING value
     * @property {number} LOCKED=1 LOCKED value
     * @property {number} UNLOCKED=2 UNLOCKED value
     * @property {number} RPC_ACTIVE=3 RPC_ACTIVE value
     * @property {number} SERVER_ACTIVE=4 SERVER_ACTIVE value
     * @property {number} WAITING_TO_START=255 WAITING_TO_START value
     */
    lnrpc.WalletState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NON_EXISTING"] = 0;
        values[valuesById[1] = "LOCKED"] = 1;
        values[valuesById[2] = "UNLOCKED"] = 2;
        values[valuesById[3] = "RPC_ACTIVE"] = 3;
        values[valuesById[4] = "SERVER_ACTIVE"] = 4;
        values[valuesById[255] = "WAITING_TO_START"] = 255;
        return values;
    })();

    lnrpc.SubscribeStateRequest = (function() {

        /**
         * Properties of a SubscribeStateRequest.
         * @memberof lnrpc
         * @interface ISubscribeStateRequest
         */

        /**
         * Constructs a new SubscribeStateRequest.
         * @memberof lnrpc
         * @classdesc Represents a SubscribeStateRequest.
         * @implements ISubscribeStateRequest
         * @constructor
         * @param {lnrpc.ISubscribeStateRequest=} [properties] Properties to set
         */
        function SubscribeStateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SubscribeStateRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {lnrpc.ISubscribeStateRequest=} [properties] Properties to set
         * @returns {lnrpc.SubscribeStateRequest} SubscribeStateRequest instance
         */
        SubscribeStateRequest.create = function create(properties) {
            return new SubscribeStateRequest(properties);
        };

        /**
         * Encodes the specified SubscribeStateRequest message. Does not implicitly {@link lnrpc.SubscribeStateRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {lnrpc.ISubscribeStateRequest} message SubscribeStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeStateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SubscribeStateRequest message, length delimited. Does not implicitly {@link lnrpc.SubscribeStateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {lnrpc.ISubscribeStateRequest} message SubscribeStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubscribeStateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SubscribeStateRequest} SubscribeStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeStateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SubscribeStateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubscribeStateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SubscribeStateRequest} SubscribeStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeStateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubscribeStateRequest message.
         * @function verify
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubscribeStateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SubscribeStateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SubscribeStateRequest} SubscribeStateRequest
         */
        SubscribeStateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SubscribeStateRequest)
                return object;
            return new $root.lnrpc.SubscribeStateRequest();
        };

        /**
         * Creates a plain object from a SubscribeStateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SubscribeStateRequest
         * @static
         * @param {lnrpc.SubscribeStateRequest} message SubscribeStateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscribeStateRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SubscribeStateRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.SubscribeStateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscribeStateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscribeStateRequest;
    })();

    lnrpc.SubscribeStateResponse = (function() {

        /**
         * Properties of a SubscribeStateResponse.
         * @memberof lnrpc
         * @interface ISubscribeStateResponse
         * @property {lnrpc.WalletState|null} [state] SubscribeStateResponse state
         */

        /**
         * Constructs a new SubscribeStateResponse.
         * @memberof lnrpc
         * @classdesc Represents a SubscribeStateResponse.
         * @implements ISubscribeStateResponse
         * @constructor
         * @param {lnrpc.ISubscribeStateResponse=} [properties] Properties to set
         */
        function SubscribeStateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubscribeStateResponse state.
         * @member {lnrpc.WalletState} state
         * @memberof lnrpc.SubscribeStateResponse
         * @instance
         */
        SubscribeStateResponse.prototype.state = 0;

        /**
         * Creates a new SubscribeStateResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {lnrpc.ISubscribeStateResponse=} [properties] Properties to set
         * @returns {lnrpc.SubscribeStateResponse} SubscribeStateResponse instance
         */
        SubscribeStateResponse.create = function create(properties) {
            return new SubscribeStateResponse(properties);
        };

        /**
         * Encodes the specified SubscribeStateResponse message. Does not implicitly {@link lnrpc.SubscribeStateResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {lnrpc.ISubscribeStateResponse} message SubscribeStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeStateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified SubscribeStateResponse message, length delimited. Does not implicitly {@link lnrpc.SubscribeStateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {lnrpc.ISubscribeStateResponse} message SubscribeStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubscribeStateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.SubscribeStateResponse} SubscribeStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeStateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.SubscribeStateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubscribeStateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.SubscribeStateResponse} SubscribeStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeStateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubscribeStateResponse message.
         * @function verify
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubscribeStateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 255:
                    break;
                }
            return null;
        };

        /**
         * Creates a SubscribeStateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.SubscribeStateResponse} SubscribeStateResponse
         */
        SubscribeStateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.SubscribeStateResponse)
                return object;
            let message = new $root.lnrpc.SubscribeStateResponse();
            switch (object.state) {
            case "NON_EXISTING":
            case 0:
                message.state = 0;
                break;
            case "LOCKED":
            case 1:
                message.state = 1;
                break;
            case "UNLOCKED":
            case 2:
                message.state = 2;
                break;
            case "RPC_ACTIVE":
            case 3:
                message.state = 3;
                break;
            case "SERVER_ACTIVE":
            case 4:
                message.state = 4;
                break;
            case "WAITING_TO_START":
            case 255:
                message.state = 255;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SubscribeStateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.SubscribeStateResponse
         * @static
         * @param {lnrpc.SubscribeStateResponse} message SubscribeStateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscribeStateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.state = options.enums === String ? "NON_EXISTING" : 0;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.lnrpc.WalletState[message.state] : message.state;
            return object;
        };

        /**
         * Converts this SubscribeStateResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.SubscribeStateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscribeStateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscribeStateResponse;
    })();

    lnrpc.GetStateRequest = (function() {

        /**
         * Properties of a GetStateRequest.
         * @memberof lnrpc
         * @interface IGetStateRequest
         */

        /**
         * Constructs a new GetStateRequest.
         * @memberof lnrpc
         * @classdesc Represents a GetStateRequest.
         * @implements IGetStateRequest
         * @constructor
         * @param {lnrpc.IGetStateRequest=} [properties] Properties to set
         */
        function GetStateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetStateRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {lnrpc.IGetStateRequest=} [properties] Properties to set
         * @returns {lnrpc.GetStateRequest} GetStateRequest instance
         */
        GetStateRequest.create = function create(properties) {
            return new GetStateRequest(properties);
        };

        /**
         * Encodes the specified GetStateRequest message. Does not implicitly {@link lnrpc.GetStateRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {lnrpc.IGetStateRequest} message GetStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetStateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetStateRequest message, length delimited. Does not implicitly {@link lnrpc.GetStateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {lnrpc.IGetStateRequest} message GetStateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetStateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetStateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetStateRequest} GetStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetStateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetStateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetStateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetStateRequest} GetStateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetStateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetStateRequest message.
         * @function verify
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetStateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetStateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetStateRequest} GetStateRequest
         */
        GetStateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetStateRequest)
                return object;
            return new $root.lnrpc.GetStateRequest();
        };

        /**
         * Creates a plain object from a GetStateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetStateRequest
         * @static
         * @param {lnrpc.GetStateRequest} message GetStateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetStateRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetStateRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.GetStateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetStateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetStateRequest;
    })();

    lnrpc.GetStateResponse = (function() {

        /**
         * Properties of a GetStateResponse.
         * @memberof lnrpc
         * @interface IGetStateResponse
         * @property {lnrpc.WalletState|null} [state] GetStateResponse state
         */

        /**
         * Constructs a new GetStateResponse.
         * @memberof lnrpc
         * @classdesc Represents a GetStateResponse.
         * @implements IGetStateResponse
         * @constructor
         * @param {lnrpc.IGetStateResponse=} [properties] Properties to set
         */
        function GetStateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetStateResponse state.
         * @member {lnrpc.WalletState} state
         * @memberof lnrpc.GetStateResponse
         * @instance
         */
        GetStateResponse.prototype.state = 0;

        /**
         * Creates a new GetStateResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {lnrpc.IGetStateResponse=} [properties] Properties to set
         * @returns {lnrpc.GetStateResponse} GetStateResponse instance
         */
        GetStateResponse.create = function create(properties) {
            return new GetStateResponse(properties);
        };

        /**
         * Encodes the specified GetStateResponse message. Does not implicitly {@link lnrpc.GetStateResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {lnrpc.IGetStateResponse} message GetStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetStateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
            return writer;
        };

        /**
         * Encodes the specified GetStateResponse message, length delimited. Does not implicitly {@link lnrpc.GetStateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {lnrpc.IGetStateResponse} message GetStateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetStateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetStateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GetStateResponse} GetStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetStateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GetStateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetStateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GetStateResponse} GetStateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetStateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetStateResponse message.
         * @function verify
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetStateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 255:
                    break;
                }
            return null;
        };

        /**
         * Creates a GetStateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GetStateResponse} GetStateResponse
         */
        GetStateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GetStateResponse)
                return object;
            let message = new $root.lnrpc.GetStateResponse();
            switch (object.state) {
            case "NON_EXISTING":
            case 0:
                message.state = 0;
                break;
            case "LOCKED":
            case 1:
                message.state = 1;
                break;
            case "UNLOCKED":
            case 2:
                message.state = 2;
                break;
            case "RPC_ACTIVE":
            case 3:
                message.state = 3;
                break;
            case "SERVER_ACTIVE":
            case 4:
                message.state = 4;
                break;
            case "WAITING_TO_START":
            case 255:
                message.state = 255;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetStateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GetStateResponse
         * @static
         * @param {lnrpc.GetStateResponse} message GetStateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetStateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.state = options.enums === String ? "NON_EXISTING" : 0;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.lnrpc.WalletState[message.state] : message.state;
            return object;
        };

        /**
         * Converts this GetStateResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.GetStateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetStateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetStateResponse;
    })();

    lnrpc.WalletUnlocker = (function() {

        /**
         * Constructs a new WalletUnlocker service.
         * @memberof lnrpc
         * @classdesc Represents a WalletUnlocker
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function WalletUnlocker(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (WalletUnlocker.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WalletUnlocker;

        /**
         * Creates new WalletUnlocker service using the specified rpc implementation.
         * @function create
         * @memberof lnrpc.WalletUnlocker
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {WalletUnlocker} RPC service. Useful where requests and/or responses are streamed.
         */
        WalletUnlocker.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link lnrpc.WalletUnlocker#genSeed}.
         * @memberof lnrpc.WalletUnlocker
         * @typedef GenSeedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.GenSeedResponse} [response] GenSeedResponse
         */

        /**
         * Calls GenSeed.
         * @function genSeed
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IGenSeedRequest} request GenSeedRequest message or plain object
         * @param {lnrpc.WalletUnlocker.GenSeedCallback} callback Node-style callback called with the error, if any, and GenSeedResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletUnlocker.prototype.genSeed = function genSeed(request, callback) {
            return this.rpcCall(genSeed, $root.lnrpc.GenSeedRequest, $root.lnrpc.GenSeedResponse, request, callback);
        }, "name", { value: "GenSeed" });

        /**
         * Calls GenSeed.
         * @function genSeed
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IGenSeedRequest} request GenSeedRequest message or plain object
         * @returns {Promise<lnrpc.GenSeedResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.WalletUnlocker#initWallet}.
         * @memberof lnrpc.WalletUnlocker
         * @typedef InitWalletCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.InitWalletResponse} [response] InitWalletResponse
         */

        /**
         * Calls InitWallet.
         * @function initWallet
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IInitWalletRequest} request InitWalletRequest message or plain object
         * @param {lnrpc.WalletUnlocker.InitWalletCallback} callback Node-style callback called with the error, if any, and InitWalletResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletUnlocker.prototype.initWallet = function initWallet(request, callback) {
            return this.rpcCall(initWallet, $root.lnrpc.InitWalletRequest, $root.lnrpc.InitWalletResponse, request, callback);
        }, "name", { value: "InitWallet" });

        /**
         * Calls InitWallet.
         * @function initWallet
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IInitWalletRequest} request InitWalletRequest message or plain object
         * @returns {Promise<lnrpc.InitWalletResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.WalletUnlocker#unlockWallet}.
         * @memberof lnrpc.WalletUnlocker
         * @typedef UnlockWalletCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.UnlockWalletResponse} [response] UnlockWalletResponse
         */

        /**
         * Calls UnlockWallet.
         * @function unlockWallet
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IUnlockWalletRequest} request UnlockWalletRequest message or plain object
         * @param {lnrpc.WalletUnlocker.UnlockWalletCallback} callback Node-style callback called with the error, if any, and UnlockWalletResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletUnlocker.prototype.unlockWallet = function unlockWallet(request, callback) {
            return this.rpcCall(unlockWallet, $root.lnrpc.UnlockWalletRequest, $root.lnrpc.UnlockWalletResponse, request, callback);
        }, "name", { value: "UnlockWallet" });

        /**
         * Calls UnlockWallet.
         * @function unlockWallet
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IUnlockWalletRequest} request UnlockWalletRequest message or plain object
         * @returns {Promise<lnrpc.UnlockWalletResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link lnrpc.WalletUnlocker#changePassword}.
         * @memberof lnrpc.WalletUnlocker
         * @typedef ChangePasswordCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.ChangePasswordResponse} [response] ChangePasswordResponse
         */

        /**
         * Calls ChangePassword.
         * @function changePassword
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IChangePasswordRequest} request ChangePasswordRequest message or plain object
         * @param {lnrpc.WalletUnlocker.ChangePasswordCallback} callback Node-style callback called with the error, if any, and ChangePasswordResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletUnlocker.prototype.changePassword = function changePassword(request, callback) {
            return this.rpcCall(changePassword, $root.lnrpc.ChangePasswordRequest, $root.lnrpc.ChangePasswordResponse, request, callback);
        }, "name", { value: "ChangePassword" });

        /**
         * Calls ChangePassword.
         * @function changePassword
         * @memberof lnrpc.WalletUnlocker
         * @instance
         * @param {lnrpc.IChangePasswordRequest} request ChangePasswordRequest message or plain object
         * @returns {Promise<lnrpc.ChangePasswordResponse>} Promise
         * @variation 2
         */

        return WalletUnlocker;
    })();

    lnrpc.GenSeedRequest = (function() {

        /**
         * Properties of a GenSeedRequest.
         * @memberof lnrpc
         * @interface IGenSeedRequest
         * @property {Uint8Array|null} [aezeedPassphrase] GenSeedRequest aezeedPassphrase
         * @property {Uint8Array|null} [seedEntropy] GenSeedRequest seedEntropy
         */

        /**
         * Constructs a new GenSeedRequest.
         * @memberof lnrpc
         * @classdesc Represents a GenSeedRequest.
         * @implements IGenSeedRequest
         * @constructor
         * @param {lnrpc.IGenSeedRequest=} [properties] Properties to set
         */
        function GenSeedRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenSeedRequest aezeedPassphrase.
         * @member {Uint8Array} aezeedPassphrase
         * @memberof lnrpc.GenSeedRequest
         * @instance
         */
        GenSeedRequest.prototype.aezeedPassphrase = $util.newBuffer([]);

        /**
         * GenSeedRequest seedEntropy.
         * @member {Uint8Array} seedEntropy
         * @memberof lnrpc.GenSeedRequest
         * @instance
         */
        GenSeedRequest.prototype.seedEntropy = $util.newBuffer([]);

        /**
         * Creates a new GenSeedRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {lnrpc.IGenSeedRequest=} [properties] Properties to set
         * @returns {lnrpc.GenSeedRequest} GenSeedRequest instance
         */
        GenSeedRequest.create = function create(properties) {
            return new GenSeedRequest(properties);
        };

        /**
         * Encodes the specified GenSeedRequest message. Does not implicitly {@link lnrpc.GenSeedRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {lnrpc.IGenSeedRequest} message GenSeedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenSeedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.aezeedPassphrase != null && Object.hasOwnProperty.call(message, "aezeedPassphrase"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.aezeedPassphrase);
            if (message.seedEntropy != null && Object.hasOwnProperty.call(message, "seedEntropy"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.seedEntropy);
            return writer;
        };

        /**
         * Encodes the specified GenSeedRequest message, length delimited. Does not implicitly {@link lnrpc.GenSeedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {lnrpc.IGenSeedRequest} message GenSeedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenSeedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenSeedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GenSeedRequest} GenSeedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenSeedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GenSeedRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.aezeedPassphrase = reader.bytes();
                        break;
                    }
                case 2: {
                        message.seedEntropy = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenSeedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GenSeedRequest} GenSeedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenSeedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenSeedRequest message.
         * @function verify
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenSeedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.aezeedPassphrase != null && message.hasOwnProperty("aezeedPassphrase"))
                if (!(message.aezeedPassphrase && typeof message.aezeedPassphrase.length === "number" || $util.isString(message.aezeedPassphrase)))
                    return "aezeedPassphrase: buffer expected";
            if (message.seedEntropy != null && message.hasOwnProperty("seedEntropy"))
                if (!(message.seedEntropy && typeof message.seedEntropy.length === "number" || $util.isString(message.seedEntropy)))
                    return "seedEntropy: buffer expected";
            return null;
        };

        /**
         * Creates a GenSeedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GenSeedRequest} GenSeedRequest
         */
        GenSeedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GenSeedRequest)
                return object;
            let message = new $root.lnrpc.GenSeedRequest();
            if (object.aezeedPassphrase != null)
                if (typeof object.aezeedPassphrase === "string")
                    $util.base64.decode(object.aezeedPassphrase, message.aezeedPassphrase = $util.newBuffer($util.base64.length(object.aezeedPassphrase)), 0);
                else if (object.aezeedPassphrase.length)
                    message.aezeedPassphrase = object.aezeedPassphrase;
            if (object.seedEntropy != null)
                if (typeof object.seedEntropy === "string")
                    $util.base64.decode(object.seedEntropy, message.seedEntropy = $util.newBuffer($util.base64.length(object.seedEntropy)), 0);
                else if (object.seedEntropy.length)
                    message.seedEntropy = object.seedEntropy;
            return message;
        };

        /**
         * Creates a plain object from a GenSeedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GenSeedRequest
         * @static
         * @param {lnrpc.GenSeedRequest} message GenSeedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenSeedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.aezeedPassphrase = "";
                else {
                    object.aezeedPassphrase = [];
                    if (options.bytes !== Array)
                        object.aezeedPassphrase = $util.newBuffer(object.aezeedPassphrase);
                }
                if (options.bytes === String)
                    object.seedEntropy = "";
                else {
                    object.seedEntropy = [];
                    if (options.bytes !== Array)
                        object.seedEntropy = $util.newBuffer(object.seedEntropy);
                }
            }
            if (message.aezeedPassphrase != null && message.hasOwnProperty("aezeedPassphrase"))
                object.aezeedPassphrase = options.bytes === String ? $util.base64.encode(message.aezeedPassphrase, 0, message.aezeedPassphrase.length) : options.bytes === Array ? Array.prototype.slice.call(message.aezeedPassphrase) : message.aezeedPassphrase;
            if (message.seedEntropy != null && message.hasOwnProperty("seedEntropy"))
                object.seedEntropy = options.bytes === String ? $util.base64.encode(message.seedEntropy, 0, message.seedEntropy.length) : options.bytes === Array ? Array.prototype.slice.call(message.seedEntropy) : message.seedEntropy;
            return object;
        };

        /**
         * Converts this GenSeedRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.GenSeedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenSeedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenSeedRequest;
    })();

    lnrpc.GenSeedResponse = (function() {

        /**
         * Properties of a GenSeedResponse.
         * @memberof lnrpc
         * @interface IGenSeedResponse
         * @property {Array.<string>|null} [cipherSeedMnemonic] GenSeedResponse cipherSeedMnemonic
         * @property {Uint8Array|null} [encipheredSeed] GenSeedResponse encipheredSeed
         */

        /**
         * Constructs a new GenSeedResponse.
         * @memberof lnrpc
         * @classdesc Represents a GenSeedResponse.
         * @implements IGenSeedResponse
         * @constructor
         * @param {lnrpc.IGenSeedResponse=} [properties] Properties to set
         */
        function GenSeedResponse(properties) {
            this.cipherSeedMnemonic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GenSeedResponse cipherSeedMnemonic.
         * @member {Array.<string>} cipherSeedMnemonic
         * @memberof lnrpc.GenSeedResponse
         * @instance
         */
        GenSeedResponse.prototype.cipherSeedMnemonic = $util.emptyArray;

        /**
         * GenSeedResponse encipheredSeed.
         * @member {Uint8Array} encipheredSeed
         * @memberof lnrpc.GenSeedResponse
         * @instance
         */
        GenSeedResponse.prototype.encipheredSeed = $util.newBuffer([]);

        /**
         * Creates a new GenSeedResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {lnrpc.IGenSeedResponse=} [properties] Properties to set
         * @returns {lnrpc.GenSeedResponse} GenSeedResponse instance
         */
        GenSeedResponse.create = function create(properties) {
            return new GenSeedResponse(properties);
        };

        /**
         * Encodes the specified GenSeedResponse message. Does not implicitly {@link lnrpc.GenSeedResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {lnrpc.IGenSeedResponse} message GenSeedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenSeedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cipherSeedMnemonic != null && message.cipherSeedMnemonic.length)
                for (let i = 0; i < message.cipherSeedMnemonic.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.cipherSeedMnemonic[i]);
            if (message.encipheredSeed != null && Object.hasOwnProperty.call(message, "encipheredSeed"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.encipheredSeed);
            return writer;
        };

        /**
         * Encodes the specified GenSeedResponse message, length delimited. Does not implicitly {@link lnrpc.GenSeedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {lnrpc.IGenSeedResponse} message GenSeedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GenSeedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GenSeedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.GenSeedResponse} GenSeedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenSeedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.GenSeedResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.cipherSeedMnemonic && message.cipherSeedMnemonic.length))
                            message.cipherSeedMnemonic = [];
                        message.cipherSeedMnemonic.push(reader.string());
                        break;
                    }
                case 2: {
                        message.encipheredSeed = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GenSeedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.GenSeedResponse} GenSeedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GenSeedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GenSeedResponse message.
         * @function verify
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GenSeedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cipherSeedMnemonic != null && message.hasOwnProperty("cipherSeedMnemonic")) {
                if (!Array.isArray(message.cipherSeedMnemonic))
                    return "cipherSeedMnemonic: array expected";
                for (let i = 0; i < message.cipherSeedMnemonic.length; ++i)
                    if (!$util.isString(message.cipherSeedMnemonic[i]))
                        return "cipherSeedMnemonic: string[] expected";
            }
            if (message.encipheredSeed != null && message.hasOwnProperty("encipheredSeed"))
                if (!(message.encipheredSeed && typeof message.encipheredSeed.length === "number" || $util.isString(message.encipheredSeed)))
                    return "encipheredSeed: buffer expected";
            return null;
        };

        /**
         * Creates a GenSeedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.GenSeedResponse} GenSeedResponse
         */
        GenSeedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.GenSeedResponse)
                return object;
            let message = new $root.lnrpc.GenSeedResponse();
            if (object.cipherSeedMnemonic) {
                if (!Array.isArray(object.cipherSeedMnemonic))
                    throw TypeError(".lnrpc.GenSeedResponse.cipherSeedMnemonic: array expected");
                message.cipherSeedMnemonic = [];
                for (let i = 0; i < object.cipherSeedMnemonic.length; ++i)
                    message.cipherSeedMnemonic[i] = String(object.cipherSeedMnemonic[i]);
            }
            if (object.encipheredSeed != null)
                if (typeof object.encipheredSeed === "string")
                    $util.base64.decode(object.encipheredSeed, message.encipheredSeed = $util.newBuffer($util.base64.length(object.encipheredSeed)), 0);
                else if (object.encipheredSeed.length)
                    message.encipheredSeed = object.encipheredSeed;
            return message;
        };

        /**
         * Creates a plain object from a GenSeedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.GenSeedResponse
         * @static
         * @param {lnrpc.GenSeedResponse} message GenSeedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GenSeedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cipherSeedMnemonic = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.encipheredSeed = "";
                else {
                    object.encipheredSeed = [];
                    if (options.bytes !== Array)
                        object.encipheredSeed = $util.newBuffer(object.encipheredSeed);
                }
            if (message.cipherSeedMnemonic && message.cipherSeedMnemonic.length) {
                object.cipherSeedMnemonic = [];
                for (let j = 0; j < message.cipherSeedMnemonic.length; ++j)
                    object.cipherSeedMnemonic[j] = message.cipherSeedMnemonic[j];
            }
            if (message.encipheredSeed != null && message.hasOwnProperty("encipheredSeed"))
                object.encipheredSeed = options.bytes === String ? $util.base64.encode(message.encipheredSeed, 0, message.encipheredSeed.length) : options.bytes === Array ? Array.prototype.slice.call(message.encipheredSeed) : message.encipheredSeed;
            return object;
        };

        /**
         * Converts this GenSeedResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.GenSeedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GenSeedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GenSeedResponse;
    })();

    lnrpc.InitWalletRequest = (function() {

        /**
         * Properties of an InitWalletRequest.
         * @memberof lnrpc
         * @interface IInitWalletRequest
         * @property {Uint8Array|null} [walletPassword] InitWalletRequest walletPassword
         * @property {Array.<string>|null} [cipherSeedMnemonic] InitWalletRequest cipherSeedMnemonic
         * @property {Uint8Array|null} [aezeedPassphrase] InitWalletRequest aezeedPassphrase
         * @property {number|null} [recoveryWindow] InitWalletRequest recoveryWindow
         * @property {lnrpc.IChanBackupSnapshot|null} [channelBackups] InitWalletRequest channelBackups
         * @property {boolean|null} [statelessInit] InitWalletRequest statelessInit
         * @property {string|null} [extendedMasterKey] InitWalletRequest extendedMasterKey
         * @property {Long|null} [extendedMasterKeyBirthdayTimestamp] InitWalletRequest extendedMasterKeyBirthdayTimestamp
         * @property {lnrpc.IWatchOnly|null} [watchOnly] InitWalletRequest watchOnly
         */

        /**
         * Constructs a new InitWalletRequest.
         * @memberof lnrpc
         * @classdesc Represents an InitWalletRequest.
         * @implements IInitWalletRequest
         * @constructor
         * @param {lnrpc.IInitWalletRequest=} [properties] Properties to set
         */
        function InitWalletRequest(properties) {
            this.cipherSeedMnemonic = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InitWalletRequest walletPassword.
         * @member {Uint8Array} walletPassword
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.walletPassword = $util.newBuffer([]);

        /**
         * InitWalletRequest cipherSeedMnemonic.
         * @member {Array.<string>} cipherSeedMnemonic
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.cipherSeedMnemonic = $util.emptyArray;

        /**
         * InitWalletRequest aezeedPassphrase.
         * @member {Uint8Array} aezeedPassphrase
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.aezeedPassphrase = $util.newBuffer([]);

        /**
         * InitWalletRequest recoveryWindow.
         * @member {number} recoveryWindow
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.recoveryWindow = 0;

        /**
         * InitWalletRequest channelBackups.
         * @member {lnrpc.IChanBackupSnapshot|null|undefined} channelBackups
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.channelBackups = null;

        /**
         * InitWalletRequest statelessInit.
         * @member {boolean} statelessInit
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.statelessInit = false;

        /**
         * InitWalletRequest extendedMasterKey.
         * @member {string} extendedMasterKey
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.extendedMasterKey = "";

        /**
         * InitWalletRequest extendedMasterKeyBirthdayTimestamp.
         * @member {Long} extendedMasterKeyBirthdayTimestamp
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.extendedMasterKeyBirthdayTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * InitWalletRequest watchOnly.
         * @member {lnrpc.IWatchOnly|null|undefined} watchOnly
         * @memberof lnrpc.InitWalletRequest
         * @instance
         */
        InitWalletRequest.prototype.watchOnly = null;

        /**
         * Creates a new InitWalletRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {lnrpc.IInitWalletRequest=} [properties] Properties to set
         * @returns {lnrpc.InitWalletRequest} InitWalletRequest instance
         */
        InitWalletRequest.create = function create(properties) {
            return new InitWalletRequest(properties);
        };

        /**
         * Encodes the specified InitWalletRequest message. Does not implicitly {@link lnrpc.InitWalletRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {lnrpc.IInitWalletRequest} message InitWalletRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitWalletRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.walletPassword != null && Object.hasOwnProperty.call(message, "walletPassword"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.walletPassword);
            if (message.cipherSeedMnemonic != null && message.cipherSeedMnemonic.length)
                for (let i = 0; i < message.cipherSeedMnemonic.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.cipherSeedMnemonic[i]);
            if (message.aezeedPassphrase != null && Object.hasOwnProperty.call(message, "aezeedPassphrase"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.aezeedPassphrase);
            if (message.recoveryWindow != null && Object.hasOwnProperty.call(message, "recoveryWindow"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.recoveryWindow);
            if (message.channelBackups != null && Object.hasOwnProperty.call(message, "channelBackups"))
                $root.lnrpc.ChanBackupSnapshot.encode(message.channelBackups, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.statelessInit != null && Object.hasOwnProperty.call(message, "statelessInit"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.statelessInit);
            if (message.extendedMasterKey != null && Object.hasOwnProperty.call(message, "extendedMasterKey"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.extendedMasterKey);
            if (message.extendedMasterKeyBirthdayTimestamp != null && Object.hasOwnProperty.call(message, "extendedMasterKeyBirthdayTimestamp"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.extendedMasterKeyBirthdayTimestamp);
            if (message.watchOnly != null && Object.hasOwnProperty.call(message, "watchOnly"))
                $root.lnrpc.WatchOnly.encode(message.watchOnly, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified InitWalletRequest message, length delimited. Does not implicitly {@link lnrpc.InitWalletRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {lnrpc.IInitWalletRequest} message InitWalletRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitWalletRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InitWalletRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.InitWalletRequest} InitWalletRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitWalletRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.InitWalletRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.walletPassword = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.cipherSeedMnemonic && message.cipherSeedMnemonic.length))
                            message.cipherSeedMnemonic = [];
                        message.cipherSeedMnemonic.push(reader.string());
                        break;
                    }
                case 3: {
                        message.aezeedPassphrase = reader.bytes();
                        break;
                    }
                case 4: {
                        message.recoveryWindow = reader.int32();
                        break;
                    }
                case 5: {
                        message.channelBackups = $root.lnrpc.ChanBackupSnapshot.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.statelessInit = reader.bool();
                        break;
                    }
                case 7: {
                        message.extendedMasterKey = reader.string();
                        break;
                    }
                case 8: {
                        message.extendedMasterKeyBirthdayTimestamp = reader.uint64();
                        break;
                    }
                case 9: {
                        message.watchOnly = $root.lnrpc.WatchOnly.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InitWalletRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.InitWalletRequest} InitWalletRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitWalletRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InitWalletRequest message.
         * @function verify
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InitWalletRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.walletPassword != null && message.hasOwnProperty("walletPassword"))
                if (!(message.walletPassword && typeof message.walletPassword.length === "number" || $util.isString(message.walletPassword)))
                    return "walletPassword: buffer expected";
            if (message.cipherSeedMnemonic != null && message.hasOwnProperty("cipherSeedMnemonic")) {
                if (!Array.isArray(message.cipherSeedMnemonic))
                    return "cipherSeedMnemonic: array expected";
                for (let i = 0; i < message.cipherSeedMnemonic.length; ++i)
                    if (!$util.isString(message.cipherSeedMnemonic[i]))
                        return "cipherSeedMnemonic: string[] expected";
            }
            if (message.aezeedPassphrase != null && message.hasOwnProperty("aezeedPassphrase"))
                if (!(message.aezeedPassphrase && typeof message.aezeedPassphrase.length === "number" || $util.isString(message.aezeedPassphrase)))
                    return "aezeedPassphrase: buffer expected";
            if (message.recoveryWindow != null && message.hasOwnProperty("recoveryWindow"))
                if (!$util.isInteger(message.recoveryWindow))
                    return "recoveryWindow: integer expected";
            if (message.channelBackups != null && message.hasOwnProperty("channelBackups")) {
                let error = $root.lnrpc.ChanBackupSnapshot.verify(message.channelBackups);
                if (error)
                    return "channelBackups." + error;
            }
            if (message.statelessInit != null && message.hasOwnProperty("statelessInit"))
                if (typeof message.statelessInit !== "boolean")
                    return "statelessInit: boolean expected";
            if (message.extendedMasterKey != null && message.hasOwnProperty("extendedMasterKey"))
                if (!$util.isString(message.extendedMasterKey))
                    return "extendedMasterKey: string expected";
            if (message.extendedMasterKeyBirthdayTimestamp != null && message.hasOwnProperty("extendedMasterKeyBirthdayTimestamp"))
                if (!$util.isInteger(message.extendedMasterKeyBirthdayTimestamp) && !(message.extendedMasterKeyBirthdayTimestamp && $util.isInteger(message.extendedMasterKeyBirthdayTimestamp.low) && $util.isInteger(message.extendedMasterKeyBirthdayTimestamp.high)))
                    return "extendedMasterKeyBirthdayTimestamp: integer|Long expected";
            if (message.watchOnly != null && message.hasOwnProperty("watchOnly")) {
                let error = $root.lnrpc.WatchOnly.verify(message.watchOnly);
                if (error)
                    return "watchOnly." + error;
            }
            return null;
        };

        /**
         * Creates an InitWalletRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.InitWalletRequest} InitWalletRequest
         */
        InitWalletRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.InitWalletRequest)
                return object;
            let message = new $root.lnrpc.InitWalletRequest();
            if (object.walletPassword != null)
                if (typeof object.walletPassword === "string")
                    $util.base64.decode(object.walletPassword, message.walletPassword = $util.newBuffer($util.base64.length(object.walletPassword)), 0);
                else if (object.walletPassword.length)
                    message.walletPassword = object.walletPassword;
            if (object.cipherSeedMnemonic) {
                if (!Array.isArray(object.cipherSeedMnemonic))
                    throw TypeError(".lnrpc.InitWalletRequest.cipherSeedMnemonic: array expected");
                message.cipherSeedMnemonic = [];
                for (let i = 0; i < object.cipherSeedMnemonic.length; ++i)
                    message.cipherSeedMnemonic[i] = String(object.cipherSeedMnemonic[i]);
            }
            if (object.aezeedPassphrase != null)
                if (typeof object.aezeedPassphrase === "string")
                    $util.base64.decode(object.aezeedPassphrase, message.aezeedPassphrase = $util.newBuffer($util.base64.length(object.aezeedPassphrase)), 0);
                else if (object.aezeedPassphrase.length)
                    message.aezeedPassphrase = object.aezeedPassphrase;
            if (object.recoveryWindow != null)
                message.recoveryWindow = object.recoveryWindow | 0;
            if (object.channelBackups != null) {
                if (typeof object.channelBackups !== "object")
                    throw TypeError(".lnrpc.InitWalletRequest.channelBackups: object expected");
                message.channelBackups = $root.lnrpc.ChanBackupSnapshot.fromObject(object.channelBackups);
            }
            if (object.statelessInit != null)
                message.statelessInit = Boolean(object.statelessInit);
            if (object.extendedMasterKey != null)
                message.extendedMasterKey = String(object.extendedMasterKey);
            if (object.extendedMasterKeyBirthdayTimestamp != null)
                if ($util.Long)
                    (message.extendedMasterKeyBirthdayTimestamp = $util.Long.fromValue(object.extendedMasterKeyBirthdayTimestamp)).unsigned = true;
                else if (typeof object.extendedMasterKeyBirthdayTimestamp === "string")
                    message.extendedMasterKeyBirthdayTimestamp = parseInt(object.extendedMasterKeyBirthdayTimestamp, 10);
                else if (typeof object.extendedMasterKeyBirthdayTimestamp === "number")
                    message.extendedMasterKeyBirthdayTimestamp = object.extendedMasterKeyBirthdayTimestamp;
                else if (typeof object.extendedMasterKeyBirthdayTimestamp === "object")
                    message.extendedMasterKeyBirthdayTimestamp = new $util.LongBits(object.extendedMasterKeyBirthdayTimestamp.low >>> 0, object.extendedMasterKeyBirthdayTimestamp.high >>> 0).toNumber(true);
            if (object.watchOnly != null) {
                if (typeof object.watchOnly !== "object")
                    throw TypeError(".lnrpc.InitWalletRequest.watchOnly: object expected");
                message.watchOnly = $root.lnrpc.WatchOnly.fromObject(object.watchOnly);
            }
            return message;
        };

        /**
         * Creates a plain object from an InitWalletRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.InitWalletRequest
         * @static
         * @param {lnrpc.InitWalletRequest} message InitWalletRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InitWalletRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cipherSeedMnemonic = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.walletPassword = "";
                else {
                    object.walletPassword = [];
                    if (options.bytes !== Array)
                        object.walletPassword = $util.newBuffer(object.walletPassword);
                }
                if (options.bytes === String)
                    object.aezeedPassphrase = "";
                else {
                    object.aezeedPassphrase = [];
                    if (options.bytes !== Array)
                        object.aezeedPassphrase = $util.newBuffer(object.aezeedPassphrase);
                }
                object.recoveryWindow = 0;
                object.channelBackups = null;
                object.statelessInit = false;
                object.extendedMasterKey = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.extendedMasterKeyBirthdayTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.extendedMasterKeyBirthdayTimestamp = options.longs === String ? "0" : 0;
                object.watchOnly = null;
            }
            if (message.walletPassword != null && message.hasOwnProperty("walletPassword"))
                object.walletPassword = options.bytes === String ? $util.base64.encode(message.walletPassword, 0, message.walletPassword.length) : options.bytes === Array ? Array.prototype.slice.call(message.walletPassword) : message.walletPassword;
            if (message.cipherSeedMnemonic && message.cipherSeedMnemonic.length) {
                object.cipherSeedMnemonic = [];
                for (let j = 0; j < message.cipherSeedMnemonic.length; ++j)
                    object.cipherSeedMnemonic[j] = message.cipherSeedMnemonic[j];
            }
            if (message.aezeedPassphrase != null && message.hasOwnProperty("aezeedPassphrase"))
                object.aezeedPassphrase = options.bytes === String ? $util.base64.encode(message.aezeedPassphrase, 0, message.aezeedPassphrase.length) : options.bytes === Array ? Array.prototype.slice.call(message.aezeedPassphrase) : message.aezeedPassphrase;
            if (message.recoveryWindow != null && message.hasOwnProperty("recoveryWindow"))
                object.recoveryWindow = message.recoveryWindow;
            if (message.channelBackups != null && message.hasOwnProperty("channelBackups"))
                object.channelBackups = $root.lnrpc.ChanBackupSnapshot.toObject(message.channelBackups, options);
            if (message.statelessInit != null && message.hasOwnProperty("statelessInit"))
                object.statelessInit = message.statelessInit;
            if (message.extendedMasterKey != null && message.hasOwnProperty("extendedMasterKey"))
                object.extendedMasterKey = message.extendedMasterKey;
            if (message.extendedMasterKeyBirthdayTimestamp != null && message.hasOwnProperty("extendedMasterKeyBirthdayTimestamp"))
                if (typeof message.extendedMasterKeyBirthdayTimestamp === "number")
                    object.extendedMasterKeyBirthdayTimestamp = options.longs === String ? String(message.extendedMasterKeyBirthdayTimestamp) : message.extendedMasterKeyBirthdayTimestamp;
                else
                    object.extendedMasterKeyBirthdayTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.extendedMasterKeyBirthdayTimestamp) : options.longs === Number ? new $util.LongBits(message.extendedMasterKeyBirthdayTimestamp.low >>> 0, message.extendedMasterKeyBirthdayTimestamp.high >>> 0).toNumber(true) : message.extendedMasterKeyBirthdayTimestamp;
            if (message.watchOnly != null && message.hasOwnProperty("watchOnly"))
                object.watchOnly = $root.lnrpc.WatchOnly.toObject(message.watchOnly, options);
            return object;
        };

        /**
         * Converts this InitWalletRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.InitWalletRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InitWalletRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InitWalletRequest;
    })();

    lnrpc.InitWalletResponse = (function() {

        /**
         * Properties of an InitWalletResponse.
         * @memberof lnrpc
         * @interface IInitWalletResponse
         * @property {Uint8Array|null} [adminMacaroon] InitWalletResponse adminMacaroon
         */

        /**
         * Constructs a new InitWalletResponse.
         * @memberof lnrpc
         * @classdesc Represents an InitWalletResponse.
         * @implements IInitWalletResponse
         * @constructor
         * @param {lnrpc.IInitWalletResponse=} [properties] Properties to set
         */
        function InitWalletResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InitWalletResponse adminMacaroon.
         * @member {Uint8Array} adminMacaroon
         * @memberof lnrpc.InitWalletResponse
         * @instance
         */
        InitWalletResponse.prototype.adminMacaroon = $util.newBuffer([]);

        /**
         * Creates a new InitWalletResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {lnrpc.IInitWalletResponse=} [properties] Properties to set
         * @returns {lnrpc.InitWalletResponse} InitWalletResponse instance
         */
        InitWalletResponse.create = function create(properties) {
            return new InitWalletResponse(properties);
        };

        /**
         * Encodes the specified InitWalletResponse message. Does not implicitly {@link lnrpc.InitWalletResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {lnrpc.IInitWalletResponse} message InitWalletResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitWalletResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.adminMacaroon != null && Object.hasOwnProperty.call(message, "adminMacaroon"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.adminMacaroon);
            return writer;
        };

        /**
         * Encodes the specified InitWalletResponse message, length delimited. Does not implicitly {@link lnrpc.InitWalletResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {lnrpc.IInitWalletResponse} message InitWalletResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InitWalletResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InitWalletResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.InitWalletResponse} InitWalletResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitWalletResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.InitWalletResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.adminMacaroon = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InitWalletResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.InitWalletResponse} InitWalletResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InitWalletResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InitWalletResponse message.
         * @function verify
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InitWalletResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.adminMacaroon != null && message.hasOwnProperty("adminMacaroon"))
                if (!(message.adminMacaroon && typeof message.adminMacaroon.length === "number" || $util.isString(message.adminMacaroon)))
                    return "adminMacaroon: buffer expected";
            return null;
        };

        /**
         * Creates an InitWalletResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.InitWalletResponse} InitWalletResponse
         */
        InitWalletResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.InitWalletResponse)
                return object;
            let message = new $root.lnrpc.InitWalletResponse();
            if (object.adminMacaroon != null)
                if (typeof object.adminMacaroon === "string")
                    $util.base64.decode(object.adminMacaroon, message.adminMacaroon = $util.newBuffer($util.base64.length(object.adminMacaroon)), 0);
                else if (object.adminMacaroon.length)
                    message.adminMacaroon = object.adminMacaroon;
            return message;
        };

        /**
         * Creates a plain object from an InitWalletResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.InitWalletResponse
         * @static
         * @param {lnrpc.InitWalletResponse} message InitWalletResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InitWalletResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.adminMacaroon = "";
                else {
                    object.adminMacaroon = [];
                    if (options.bytes !== Array)
                        object.adminMacaroon = $util.newBuffer(object.adminMacaroon);
                }
            if (message.adminMacaroon != null && message.hasOwnProperty("adminMacaroon"))
                object.adminMacaroon = options.bytes === String ? $util.base64.encode(message.adminMacaroon, 0, message.adminMacaroon.length) : options.bytes === Array ? Array.prototype.slice.call(message.adminMacaroon) : message.adminMacaroon;
            return object;
        };

        /**
         * Converts this InitWalletResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.InitWalletResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InitWalletResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InitWalletResponse;
    })();

    lnrpc.WatchOnly = (function() {

        /**
         * Properties of a WatchOnly.
         * @memberof lnrpc
         * @interface IWatchOnly
         * @property {Long|null} [masterKeyBirthdayTimestamp] WatchOnly masterKeyBirthdayTimestamp
         * @property {Uint8Array|null} [masterKeyFingerprint] WatchOnly masterKeyFingerprint
         * @property {Array.<lnrpc.IWatchOnlyAccount>|null} [accounts] WatchOnly accounts
         */

        /**
         * Constructs a new WatchOnly.
         * @memberof lnrpc
         * @classdesc Represents a WatchOnly.
         * @implements IWatchOnly
         * @constructor
         * @param {lnrpc.IWatchOnly=} [properties] Properties to set
         */
        function WatchOnly(properties) {
            this.accounts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WatchOnly masterKeyBirthdayTimestamp.
         * @member {Long} masterKeyBirthdayTimestamp
         * @memberof lnrpc.WatchOnly
         * @instance
         */
        WatchOnly.prototype.masterKeyBirthdayTimestamp = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * WatchOnly masterKeyFingerprint.
         * @member {Uint8Array} masterKeyFingerprint
         * @memberof lnrpc.WatchOnly
         * @instance
         */
        WatchOnly.prototype.masterKeyFingerprint = $util.newBuffer([]);

        /**
         * WatchOnly accounts.
         * @member {Array.<lnrpc.IWatchOnlyAccount>} accounts
         * @memberof lnrpc.WatchOnly
         * @instance
         */
        WatchOnly.prototype.accounts = $util.emptyArray;

        /**
         * Creates a new WatchOnly instance using the specified properties.
         * @function create
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {lnrpc.IWatchOnly=} [properties] Properties to set
         * @returns {lnrpc.WatchOnly} WatchOnly instance
         */
        WatchOnly.create = function create(properties) {
            return new WatchOnly(properties);
        };

        /**
         * Encodes the specified WatchOnly message. Does not implicitly {@link lnrpc.WatchOnly.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {lnrpc.IWatchOnly} message WatchOnly message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WatchOnly.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.masterKeyBirthdayTimestamp != null && Object.hasOwnProperty.call(message, "masterKeyBirthdayTimestamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.masterKeyBirthdayTimestamp);
            if (message.masterKeyFingerprint != null && Object.hasOwnProperty.call(message, "masterKeyFingerprint"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.masterKeyFingerprint);
            if (message.accounts != null && message.accounts.length)
                for (let i = 0; i < message.accounts.length; ++i)
                    $root.lnrpc.WatchOnlyAccount.encode(message.accounts[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WatchOnly message, length delimited. Does not implicitly {@link lnrpc.WatchOnly.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {lnrpc.IWatchOnly} message WatchOnly message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WatchOnly.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WatchOnly message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.WatchOnly} WatchOnly
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WatchOnly.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.WatchOnly();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.masterKeyBirthdayTimestamp = reader.uint64();
                        break;
                    }
                case 2: {
                        message.masterKeyFingerprint = reader.bytes();
                        break;
                    }
                case 3: {
                        if (!(message.accounts && message.accounts.length))
                            message.accounts = [];
                        message.accounts.push($root.lnrpc.WatchOnlyAccount.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WatchOnly message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.WatchOnly} WatchOnly
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WatchOnly.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WatchOnly message.
         * @function verify
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WatchOnly.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.masterKeyBirthdayTimestamp != null && message.hasOwnProperty("masterKeyBirthdayTimestamp"))
                if (!$util.isInteger(message.masterKeyBirthdayTimestamp) && !(message.masterKeyBirthdayTimestamp && $util.isInteger(message.masterKeyBirthdayTimestamp.low) && $util.isInteger(message.masterKeyBirthdayTimestamp.high)))
                    return "masterKeyBirthdayTimestamp: integer|Long expected";
            if (message.masterKeyFingerprint != null && message.hasOwnProperty("masterKeyFingerprint"))
                if (!(message.masterKeyFingerprint && typeof message.masterKeyFingerprint.length === "number" || $util.isString(message.masterKeyFingerprint)))
                    return "masterKeyFingerprint: buffer expected";
            if (message.accounts != null && message.hasOwnProperty("accounts")) {
                if (!Array.isArray(message.accounts))
                    return "accounts: array expected";
                for (let i = 0; i < message.accounts.length; ++i) {
                    let error = $root.lnrpc.WatchOnlyAccount.verify(message.accounts[i]);
                    if (error)
                        return "accounts." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WatchOnly message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.WatchOnly} WatchOnly
         */
        WatchOnly.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.WatchOnly)
                return object;
            let message = new $root.lnrpc.WatchOnly();
            if (object.masterKeyBirthdayTimestamp != null)
                if ($util.Long)
                    (message.masterKeyBirthdayTimestamp = $util.Long.fromValue(object.masterKeyBirthdayTimestamp)).unsigned = true;
                else if (typeof object.masterKeyBirthdayTimestamp === "string")
                    message.masterKeyBirthdayTimestamp = parseInt(object.masterKeyBirthdayTimestamp, 10);
                else if (typeof object.masterKeyBirthdayTimestamp === "number")
                    message.masterKeyBirthdayTimestamp = object.masterKeyBirthdayTimestamp;
                else if (typeof object.masterKeyBirthdayTimestamp === "object")
                    message.masterKeyBirthdayTimestamp = new $util.LongBits(object.masterKeyBirthdayTimestamp.low >>> 0, object.masterKeyBirthdayTimestamp.high >>> 0).toNumber(true);
            if (object.masterKeyFingerprint != null)
                if (typeof object.masterKeyFingerprint === "string")
                    $util.base64.decode(object.masterKeyFingerprint, message.masterKeyFingerprint = $util.newBuffer($util.base64.length(object.masterKeyFingerprint)), 0);
                else if (object.masterKeyFingerprint.length)
                    message.masterKeyFingerprint = object.masterKeyFingerprint;
            if (object.accounts) {
                if (!Array.isArray(object.accounts))
                    throw TypeError(".lnrpc.WatchOnly.accounts: array expected");
                message.accounts = [];
                for (let i = 0; i < object.accounts.length; ++i) {
                    if (typeof object.accounts[i] !== "object")
                        throw TypeError(".lnrpc.WatchOnly.accounts: object expected");
                    message.accounts[i] = $root.lnrpc.WatchOnlyAccount.fromObject(object.accounts[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WatchOnly message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.WatchOnly
         * @static
         * @param {lnrpc.WatchOnly} message WatchOnly
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WatchOnly.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.accounts = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.masterKeyBirthdayTimestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.masterKeyBirthdayTimestamp = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.masterKeyFingerprint = "";
                else {
                    object.masterKeyFingerprint = [];
                    if (options.bytes !== Array)
                        object.masterKeyFingerprint = $util.newBuffer(object.masterKeyFingerprint);
                }
            }
            if (message.masterKeyBirthdayTimestamp != null && message.hasOwnProperty("masterKeyBirthdayTimestamp"))
                if (typeof message.masterKeyBirthdayTimestamp === "number")
                    object.masterKeyBirthdayTimestamp = options.longs === String ? String(message.masterKeyBirthdayTimestamp) : message.masterKeyBirthdayTimestamp;
                else
                    object.masterKeyBirthdayTimestamp = options.longs === String ? $util.Long.prototype.toString.call(message.masterKeyBirthdayTimestamp) : options.longs === Number ? new $util.LongBits(message.masterKeyBirthdayTimestamp.low >>> 0, message.masterKeyBirthdayTimestamp.high >>> 0).toNumber(true) : message.masterKeyBirthdayTimestamp;
            if (message.masterKeyFingerprint != null && message.hasOwnProperty("masterKeyFingerprint"))
                object.masterKeyFingerprint = options.bytes === String ? $util.base64.encode(message.masterKeyFingerprint, 0, message.masterKeyFingerprint.length) : options.bytes === Array ? Array.prototype.slice.call(message.masterKeyFingerprint) : message.masterKeyFingerprint;
            if (message.accounts && message.accounts.length) {
                object.accounts = [];
                for (let j = 0; j < message.accounts.length; ++j)
                    object.accounts[j] = $root.lnrpc.WatchOnlyAccount.toObject(message.accounts[j], options);
            }
            return object;
        };

        /**
         * Converts this WatchOnly to JSON.
         * @function toJSON
         * @memberof lnrpc.WatchOnly
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WatchOnly.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WatchOnly;
    })();

    lnrpc.WatchOnlyAccount = (function() {

        /**
         * Properties of a WatchOnlyAccount.
         * @memberof lnrpc
         * @interface IWatchOnlyAccount
         * @property {number|null} [purpose] WatchOnlyAccount purpose
         * @property {number|null} [coinType] WatchOnlyAccount coinType
         * @property {number|null} [account] WatchOnlyAccount account
         * @property {string|null} [xpub] WatchOnlyAccount xpub
         */

        /**
         * Constructs a new WatchOnlyAccount.
         * @memberof lnrpc
         * @classdesc Represents a WatchOnlyAccount.
         * @implements IWatchOnlyAccount
         * @constructor
         * @param {lnrpc.IWatchOnlyAccount=} [properties] Properties to set
         */
        function WatchOnlyAccount(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WatchOnlyAccount purpose.
         * @member {number} purpose
         * @memberof lnrpc.WatchOnlyAccount
         * @instance
         */
        WatchOnlyAccount.prototype.purpose = 0;

        /**
         * WatchOnlyAccount coinType.
         * @member {number} coinType
         * @memberof lnrpc.WatchOnlyAccount
         * @instance
         */
        WatchOnlyAccount.prototype.coinType = 0;

        /**
         * WatchOnlyAccount account.
         * @member {number} account
         * @memberof lnrpc.WatchOnlyAccount
         * @instance
         */
        WatchOnlyAccount.prototype.account = 0;

        /**
         * WatchOnlyAccount xpub.
         * @member {string} xpub
         * @memberof lnrpc.WatchOnlyAccount
         * @instance
         */
        WatchOnlyAccount.prototype.xpub = "";

        /**
         * Creates a new WatchOnlyAccount instance using the specified properties.
         * @function create
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {lnrpc.IWatchOnlyAccount=} [properties] Properties to set
         * @returns {lnrpc.WatchOnlyAccount} WatchOnlyAccount instance
         */
        WatchOnlyAccount.create = function create(properties) {
            return new WatchOnlyAccount(properties);
        };

        /**
         * Encodes the specified WatchOnlyAccount message. Does not implicitly {@link lnrpc.WatchOnlyAccount.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {lnrpc.IWatchOnlyAccount} message WatchOnlyAccount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WatchOnlyAccount.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.purpose != null && Object.hasOwnProperty.call(message, "purpose"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.purpose);
            if (message.coinType != null && Object.hasOwnProperty.call(message, "coinType"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.coinType);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.account);
            if (message.xpub != null && Object.hasOwnProperty.call(message, "xpub"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.xpub);
            return writer;
        };

        /**
         * Encodes the specified WatchOnlyAccount message, length delimited. Does not implicitly {@link lnrpc.WatchOnlyAccount.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {lnrpc.IWatchOnlyAccount} message WatchOnlyAccount message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WatchOnlyAccount.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WatchOnlyAccount message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.WatchOnlyAccount} WatchOnlyAccount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WatchOnlyAccount.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.WatchOnlyAccount();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.purpose = reader.uint32();
                        break;
                    }
                case 2: {
                        message.coinType = reader.uint32();
                        break;
                    }
                case 3: {
                        message.account = reader.uint32();
                        break;
                    }
                case 4: {
                        message.xpub = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WatchOnlyAccount message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.WatchOnlyAccount} WatchOnlyAccount
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WatchOnlyAccount.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WatchOnlyAccount message.
         * @function verify
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WatchOnlyAccount.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.purpose != null && message.hasOwnProperty("purpose"))
                if (!$util.isInteger(message.purpose))
                    return "purpose: integer expected";
            if (message.coinType != null && message.hasOwnProperty("coinType"))
                if (!$util.isInteger(message.coinType))
                    return "coinType: integer expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isInteger(message.account))
                    return "account: integer expected";
            if (message.xpub != null && message.hasOwnProperty("xpub"))
                if (!$util.isString(message.xpub))
                    return "xpub: string expected";
            return null;
        };

        /**
         * Creates a WatchOnlyAccount message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.WatchOnlyAccount} WatchOnlyAccount
         */
        WatchOnlyAccount.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.WatchOnlyAccount)
                return object;
            let message = new $root.lnrpc.WatchOnlyAccount();
            if (object.purpose != null)
                message.purpose = object.purpose >>> 0;
            if (object.coinType != null)
                message.coinType = object.coinType >>> 0;
            if (object.account != null)
                message.account = object.account >>> 0;
            if (object.xpub != null)
                message.xpub = String(object.xpub);
            return message;
        };

        /**
         * Creates a plain object from a WatchOnlyAccount message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.WatchOnlyAccount
         * @static
         * @param {lnrpc.WatchOnlyAccount} message WatchOnlyAccount
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WatchOnlyAccount.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.purpose = 0;
                object.coinType = 0;
                object.account = 0;
                object.xpub = "";
            }
            if (message.purpose != null && message.hasOwnProperty("purpose"))
                object.purpose = message.purpose;
            if (message.coinType != null && message.hasOwnProperty("coinType"))
                object.coinType = message.coinType;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            if (message.xpub != null && message.hasOwnProperty("xpub"))
                object.xpub = message.xpub;
            return object;
        };

        /**
         * Converts this WatchOnlyAccount to JSON.
         * @function toJSON
         * @memberof lnrpc.WatchOnlyAccount
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WatchOnlyAccount.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WatchOnlyAccount;
    })();

    lnrpc.UnlockWalletRequest = (function() {

        /**
         * Properties of an UnlockWalletRequest.
         * @memberof lnrpc
         * @interface IUnlockWalletRequest
         * @property {Uint8Array|null} [walletPassword] UnlockWalletRequest walletPassword
         * @property {number|null} [recoveryWindow] UnlockWalletRequest recoveryWindow
         * @property {lnrpc.IChanBackupSnapshot|null} [channelBackups] UnlockWalletRequest channelBackups
         * @property {boolean|null} [statelessInit] UnlockWalletRequest statelessInit
         */

        /**
         * Constructs a new UnlockWalletRequest.
         * @memberof lnrpc
         * @classdesc Represents an UnlockWalletRequest.
         * @implements IUnlockWalletRequest
         * @constructor
         * @param {lnrpc.IUnlockWalletRequest=} [properties] Properties to set
         */
        function UnlockWalletRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnlockWalletRequest walletPassword.
         * @member {Uint8Array} walletPassword
         * @memberof lnrpc.UnlockWalletRequest
         * @instance
         */
        UnlockWalletRequest.prototype.walletPassword = $util.newBuffer([]);

        /**
         * UnlockWalletRequest recoveryWindow.
         * @member {number} recoveryWindow
         * @memberof lnrpc.UnlockWalletRequest
         * @instance
         */
        UnlockWalletRequest.prototype.recoveryWindow = 0;

        /**
         * UnlockWalletRequest channelBackups.
         * @member {lnrpc.IChanBackupSnapshot|null|undefined} channelBackups
         * @memberof lnrpc.UnlockWalletRequest
         * @instance
         */
        UnlockWalletRequest.prototype.channelBackups = null;

        /**
         * UnlockWalletRequest statelessInit.
         * @member {boolean} statelessInit
         * @memberof lnrpc.UnlockWalletRequest
         * @instance
         */
        UnlockWalletRequest.prototype.statelessInit = false;

        /**
         * Creates a new UnlockWalletRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {lnrpc.IUnlockWalletRequest=} [properties] Properties to set
         * @returns {lnrpc.UnlockWalletRequest} UnlockWalletRequest instance
         */
        UnlockWalletRequest.create = function create(properties) {
            return new UnlockWalletRequest(properties);
        };

        /**
         * Encodes the specified UnlockWalletRequest message. Does not implicitly {@link lnrpc.UnlockWalletRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {lnrpc.IUnlockWalletRequest} message UnlockWalletRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnlockWalletRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.walletPassword != null && Object.hasOwnProperty.call(message, "walletPassword"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.walletPassword);
            if (message.recoveryWindow != null && Object.hasOwnProperty.call(message, "recoveryWindow"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.recoveryWindow);
            if (message.channelBackups != null && Object.hasOwnProperty.call(message, "channelBackups"))
                $root.lnrpc.ChanBackupSnapshot.encode(message.channelBackups, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.statelessInit != null && Object.hasOwnProperty.call(message, "statelessInit"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.statelessInit);
            return writer;
        };

        /**
         * Encodes the specified UnlockWalletRequest message, length delimited. Does not implicitly {@link lnrpc.UnlockWalletRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {lnrpc.IUnlockWalletRequest} message UnlockWalletRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnlockWalletRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnlockWalletRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.UnlockWalletRequest} UnlockWalletRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnlockWalletRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.UnlockWalletRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.walletPassword = reader.bytes();
                        break;
                    }
                case 2: {
                        message.recoveryWindow = reader.int32();
                        break;
                    }
                case 3: {
                        message.channelBackups = $root.lnrpc.ChanBackupSnapshot.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.statelessInit = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnlockWalletRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.UnlockWalletRequest} UnlockWalletRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnlockWalletRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnlockWalletRequest message.
         * @function verify
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnlockWalletRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.walletPassword != null && message.hasOwnProperty("walletPassword"))
                if (!(message.walletPassword && typeof message.walletPassword.length === "number" || $util.isString(message.walletPassword)))
                    return "walletPassword: buffer expected";
            if (message.recoveryWindow != null && message.hasOwnProperty("recoveryWindow"))
                if (!$util.isInteger(message.recoveryWindow))
                    return "recoveryWindow: integer expected";
            if (message.channelBackups != null && message.hasOwnProperty("channelBackups")) {
                let error = $root.lnrpc.ChanBackupSnapshot.verify(message.channelBackups);
                if (error)
                    return "channelBackups." + error;
            }
            if (message.statelessInit != null && message.hasOwnProperty("statelessInit"))
                if (typeof message.statelessInit !== "boolean")
                    return "statelessInit: boolean expected";
            return null;
        };

        /**
         * Creates an UnlockWalletRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.UnlockWalletRequest} UnlockWalletRequest
         */
        UnlockWalletRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.UnlockWalletRequest)
                return object;
            let message = new $root.lnrpc.UnlockWalletRequest();
            if (object.walletPassword != null)
                if (typeof object.walletPassword === "string")
                    $util.base64.decode(object.walletPassword, message.walletPassword = $util.newBuffer($util.base64.length(object.walletPassword)), 0);
                else if (object.walletPassword.length)
                    message.walletPassword = object.walletPassword;
            if (object.recoveryWindow != null)
                message.recoveryWindow = object.recoveryWindow | 0;
            if (object.channelBackups != null) {
                if (typeof object.channelBackups !== "object")
                    throw TypeError(".lnrpc.UnlockWalletRequest.channelBackups: object expected");
                message.channelBackups = $root.lnrpc.ChanBackupSnapshot.fromObject(object.channelBackups);
            }
            if (object.statelessInit != null)
                message.statelessInit = Boolean(object.statelessInit);
            return message;
        };

        /**
         * Creates a plain object from an UnlockWalletRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.UnlockWalletRequest
         * @static
         * @param {lnrpc.UnlockWalletRequest} message UnlockWalletRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnlockWalletRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.walletPassword = "";
                else {
                    object.walletPassword = [];
                    if (options.bytes !== Array)
                        object.walletPassword = $util.newBuffer(object.walletPassword);
                }
                object.recoveryWindow = 0;
                object.channelBackups = null;
                object.statelessInit = false;
            }
            if (message.walletPassword != null && message.hasOwnProperty("walletPassword"))
                object.walletPassword = options.bytes === String ? $util.base64.encode(message.walletPassword, 0, message.walletPassword.length) : options.bytes === Array ? Array.prototype.slice.call(message.walletPassword) : message.walletPassword;
            if (message.recoveryWindow != null && message.hasOwnProperty("recoveryWindow"))
                object.recoveryWindow = message.recoveryWindow;
            if (message.channelBackups != null && message.hasOwnProperty("channelBackups"))
                object.channelBackups = $root.lnrpc.ChanBackupSnapshot.toObject(message.channelBackups, options);
            if (message.statelessInit != null && message.hasOwnProperty("statelessInit"))
                object.statelessInit = message.statelessInit;
            return object;
        };

        /**
         * Converts this UnlockWalletRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.UnlockWalletRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnlockWalletRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UnlockWalletRequest;
    })();

    lnrpc.UnlockWalletResponse = (function() {

        /**
         * Properties of an UnlockWalletResponse.
         * @memberof lnrpc
         * @interface IUnlockWalletResponse
         */

        /**
         * Constructs a new UnlockWalletResponse.
         * @memberof lnrpc
         * @classdesc Represents an UnlockWalletResponse.
         * @implements IUnlockWalletResponse
         * @constructor
         * @param {lnrpc.IUnlockWalletResponse=} [properties] Properties to set
         */
        function UnlockWalletResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UnlockWalletResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {lnrpc.IUnlockWalletResponse=} [properties] Properties to set
         * @returns {lnrpc.UnlockWalletResponse} UnlockWalletResponse instance
         */
        UnlockWalletResponse.create = function create(properties) {
            return new UnlockWalletResponse(properties);
        };

        /**
         * Encodes the specified UnlockWalletResponse message. Does not implicitly {@link lnrpc.UnlockWalletResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {lnrpc.IUnlockWalletResponse} message UnlockWalletResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnlockWalletResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UnlockWalletResponse message, length delimited. Does not implicitly {@link lnrpc.UnlockWalletResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {lnrpc.IUnlockWalletResponse} message UnlockWalletResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnlockWalletResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnlockWalletResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.UnlockWalletResponse} UnlockWalletResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnlockWalletResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.UnlockWalletResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnlockWalletResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.UnlockWalletResponse} UnlockWalletResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnlockWalletResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnlockWalletResponse message.
         * @function verify
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnlockWalletResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UnlockWalletResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.UnlockWalletResponse} UnlockWalletResponse
         */
        UnlockWalletResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.UnlockWalletResponse)
                return object;
            return new $root.lnrpc.UnlockWalletResponse();
        };

        /**
         * Creates a plain object from an UnlockWalletResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.UnlockWalletResponse
         * @static
         * @param {lnrpc.UnlockWalletResponse} message UnlockWalletResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnlockWalletResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UnlockWalletResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.UnlockWalletResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnlockWalletResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UnlockWalletResponse;
    })();

    lnrpc.ChangePasswordRequest = (function() {

        /**
         * Properties of a ChangePasswordRequest.
         * @memberof lnrpc
         * @interface IChangePasswordRequest
         * @property {Uint8Array|null} [currentPassword] ChangePasswordRequest currentPassword
         * @property {Uint8Array|null} [newPassword] ChangePasswordRequest newPassword
         * @property {boolean|null} [statelessInit] ChangePasswordRequest statelessInit
         * @property {boolean|null} [newMacaroonRootKey] ChangePasswordRequest newMacaroonRootKey
         */

        /**
         * Constructs a new ChangePasswordRequest.
         * @memberof lnrpc
         * @classdesc Represents a ChangePasswordRequest.
         * @implements IChangePasswordRequest
         * @constructor
         * @param {lnrpc.IChangePasswordRequest=} [properties] Properties to set
         */
        function ChangePasswordRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangePasswordRequest currentPassword.
         * @member {Uint8Array} currentPassword
         * @memberof lnrpc.ChangePasswordRequest
         * @instance
         */
        ChangePasswordRequest.prototype.currentPassword = $util.newBuffer([]);

        /**
         * ChangePasswordRequest newPassword.
         * @member {Uint8Array} newPassword
         * @memberof lnrpc.ChangePasswordRequest
         * @instance
         */
        ChangePasswordRequest.prototype.newPassword = $util.newBuffer([]);

        /**
         * ChangePasswordRequest statelessInit.
         * @member {boolean} statelessInit
         * @memberof lnrpc.ChangePasswordRequest
         * @instance
         */
        ChangePasswordRequest.prototype.statelessInit = false;

        /**
         * ChangePasswordRequest newMacaroonRootKey.
         * @member {boolean} newMacaroonRootKey
         * @memberof lnrpc.ChangePasswordRequest
         * @instance
         */
        ChangePasswordRequest.prototype.newMacaroonRootKey = false;

        /**
         * Creates a new ChangePasswordRequest instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {lnrpc.IChangePasswordRequest=} [properties] Properties to set
         * @returns {lnrpc.ChangePasswordRequest} ChangePasswordRequest instance
         */
        ChangePasswordRequest.create = function create(properties) {
            return new ChangePasswordRequest(properties);
        };

        /**
         * Encodes the specified ChangePasswordRequest message. Does not implicitly {@link lnrpc.ChangePasswordRequest.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {lnrpc.IChangePasswordRequest} message ChangePasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.currentPassword != null && Object.hasOwnProperty.call(message, "currentPassword"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.currentPassword);
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.newPassword);
            if (message.statelessInit != null && Object.hasOwnProperty.call(message, "statelessInit"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.statelessInit);
            if (message.newMacaroonRootKey != null && Object.hasOwnProperty.call(message, "newMacaroonRootKey"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.newMacaroonRootKey);
            return writer;
        };

        /**
         * Encodes the specified ChangePasswordRequest message, length delimited. Does not implicitly {@link lnrpc.ChangePasswordRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {lnrpc.IChangePasswordRequest} message ChangePasswordRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangePasswordRequest message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChangePasswordRequest} ChangePasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChangePasswordRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.currentPassword = reader.bytes();
                        break;
                    }
                case 2: {
                        message.newPassword = reader.bytes();
                        break;
                    }
                case 3: {
                        message.statelessInit = reader.bool();
                        break;
                    }
                case 4: {
                        message.newMacaroonRootKey = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangePasswordRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChangePasswordRequest} ChangePasswordRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangePasswordRequest message.
         * @function verify
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangePasswordRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.currentPassword != null && message.hasOwnProperty("currentPassword"))
                if (!(message.currentPassword && typeof message.currentPassword.length === "number" || $util.isString(message.currentPassword)))
                    return "currentPassword: buffer expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (!(message.newPassword && typeof message.newPassword.length === "number" || $util.isString(message.newPassword)))
                    return "newPassword: buffer expected";
            if (message.statelessInit != null && message.hasOwnProperty("statelessInit"))
                if (typeof message.statelessInit !== "boolean")
                    return "statelessInit: boolean expected";
            if (message.newMacaroonRootKey != null && message.hasOwnProperty("newMacaroonRootKey"))
                if (typeof message.newMacaroonRootKey !== "boolean")
                    return "newMacaroonRootKey: boolean expected";
            return null;
        };

        /**
         * Creates a ChangePasswordRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChangePasswordRequest} ChangePasswordRequest
         */
        ChangePasswordRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChangePasswordRequest)
                return object;
            let message = new $root.lnrpc.ChangePasswordRequest();
            if (object.currentPassword != null)
                if (typeof object.currentPassword === "string")
                    $util.base64.decode(object.currentPassword, message.currentPassword = $util.newBuffer($util.base64.length(object.currentPassword)), 0);
                else if (object.currentPassword.length)
                    message.currentPassword = object.currentPassword;
            if (object.newPassword != null)
                if (typeof object.newPassword === "string")
                    $util.base64.decode(object.newPassword, message.newPassword = $util.newBuffer($util.base64.length(object.newPassword)), 0);
                else if (object.newPassword.length)
                    message.newPassword = object.newPassword;
            if (object.statelessInit != null)
                message.statelessInit = Boolean(object.statelessInit);
            if (object.newMacaroonRootKey != null)
                message.newMacaroonRootKey = Boolean(object.newMacaroonRootKey);
            return message;
        };

        /**
         * Creates a plain object from a ChangePasswordRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChangePasswordRequest
         * @static
         * @param {lnrpc.ChangePasswordRequest} message ChangePasswordRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangePasswordRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.currentPassword = "";
                else {
                    object.currentPassword = [];
                    if (options.bytes !== Array)
                        object.currentPassword = $util.newBuffer(object.currentPassword);
                }
                if (options.bytes === String)
                    object.newPassword = "";
                else {
                    object.newPassword = [];
                    if (options.bytes !== Array)
                        object.newPassword = $util.newBuffer(object.newPassword);
                }
                object.statelessInit = false;
                object.newMacaroonRootKey = false;
            }
            if (message.currentPassword != null && message.hasOwnProperty("currentPassword"))
                object.currentPassword = options.bytes === String ? $util.base64.encode(message.currentPassword, 0, message.currentPassword.length) : options.bytes === Array ? Array.prototype.slice.call(message.currentPassword) : message.currentPassword;
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = options.bytes === String ? $util.base64.encode(message.newPassword, 0, message.newPassword.length) : options.bytes === Array ? Array.prototype.slice.call(message.newPassword) : message.newPassword;
            if (message.statelessInit != null && message.hasOwnProperty("statelessInit"))
                object.statelessInit = message.statelessInit;
            if (message.newMacaroonRootKey != null && message.hasOwnProperty("newMacaroonRootKey"))
                object.newMacaroonRootKey = message.newMacaroonRootKey;
            return object;
        };

        /**
         * Converts this ChangePasswordRequest to JSON.
         * @function toJSON
         * @memberof lnrpc.ChangePasswordRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangePasswordRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChangePasswordRequest;
    })();

    lnrpc.ChangePasswordResponse = (function() {

        /**
         * Properties of a ChangePasswordResponse.
         * @memberof lnrpc
         * @interface IChangePasswordResponse
         * @property {Uint8Array|null} [adminMacaroon] ChangePasswordResponse adminMacaroon
         */

        /**
         * Constructs a new ChangePasswordResponse.
         * @memberof lnrpc
         * @classdesc Represents a ChangePasswordResponse.
         * @implements IChangePasswordResponse
         * @constructor
         * @param {lnrpc.IChangePasswordResponse=} [properties] Properties to set
         */
        function ChangePasswordResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChangePasswordResponse adminMacaroon.
         * @member {Uint8Array} adminMacaroon
         * @memberof lnrpc.ChangePasswordResponse
         * @instance
         */
        ChangePasswordResponse.prototype.adminMacaroon = $util.newBuffer([]);

        /**
         * Creates a new ChangePasswordResponse instance using the specified properties.
         * @function create
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {lnrpc.IChangePasswordResponse=} [properties] Properties to set
         * @returns {lnrpc.ChangePasswordResponse} ChangePasswordResponse instance
         */
        ChangePasswordResponse.create = function create(properties) {
            return new ChangePasswordResponse(properties);
        };

        /**
         * Encodes the specified ChangePasswordResponse message. Does not implicitly {@link lnrpc.ChangePasswordResponse.verify|verify} messages.
         * @function encode
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {lnrpc.IChangePasswordResponse} message ChangePasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.adminMacaroon != null && Object.hasOwnProperty.call(message, "adminMacaroon"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.adminMacaroon);
            return writer;
        };

        /**
         * Encodes the specified ChangePasswordResponse message, length delimited. Does not implicitly {@link lnrpc.ChangePasswordResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {lnrpc.IChangePasswordResponse} message ChangePasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangePasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChangePasswordResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnrpc.ChangePasswordResponse} ChangePasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnrpc.ChangePasswordResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.adminMacaroon = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChangePasswordResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnrpc.ChangePasswordResponse} ChangePasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangePasswordResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChangePasswordResponse message.
         * @function verify
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChangePasswordResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.adminMacaroon != null && message.hasOwnProperty("adminMacaroon"))
                if (!(message.adminMacaroon && typeof message.adminMacaroon.length === "number" || $util.isString(message.adminMacaroon)))
                    return "adminMacaroon: buffer expected";
            return null;
        };

        /**
         * Creates a ChangePasswordResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnrpc.ChangePasswordResponse} ChangePasswordResponse
         */
        ChangePasswordResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnrpc.ChangePasswordResponse)
                return object;
            let message = new $root.lnrpc.ChangePasswordResponse();
            if (object.adminMacaroon != null)
                if (typeof object.adminMacaroon === "string")
                    $util.base64.decode(object.adminMacaroon, message.adminMacaroon = $util.newBuffer($util.base64.length(object.adminMacaroon)), 0);
                else if (object.adminMacaroon.length)
                    message.adminMacaroon = object.adminMacaroon;
            return message;
        };

        /**
         * Creates a plain object from a ChangePasswordResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnrpc.ChangePasswordResponse
         * @static
         * @param {lnrpc.ChangePasswordResponse} message ChangePasswordResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChangePasswordResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.adminMacaroon = "";
                else {
                    object.adminMacaroon = [];
                    if (options.bytes !== Array)
                        object.adminMacaroon = $util.newBuffer(object.adminMacaroon);
                }
            if (message.adminMacaroon != null && message.hasOwnProperty("adminMacaroon"))
                object.adminMacaroon = options.bytes === String ? $util.base64.encode(message.adminMacaroon, 0, message.adminMacaroon.length) : options.bytes === Array ? Array.prototype.slice.call(message.adminMacaroon) : message.adminMacaroon;
            return object;
        };

        /**
         * Converts this ChangePasswordResponse to JSON.
         * @function toJSON
         * @memberof lnrpc.ChangePasswordResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChangePasswordResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChangePasswordResponse;
    })();

    return lnrpc;
})();

export const autopilotrpc = $root.autopilotrpc = (() => {

    /**
     * Namespace autopilotrpc.
     * @exports autopilotrpc
     * @namespace
     */
    const autopilotrpc = {};

    autopilotrpc.Autopilot = (function() {

        /**
         * Constructs a new Autopilot service.
         * @memberof autopilotrpc
         * @classdesc Represents an Autopilot
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Autopilot(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Autopilot.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Autopilot;

        /**
         * Creates new Autopilot service using the specified rpc implementation.
         * @function create
         * @memberof autopilotrpc.Autopilot
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Autopilot} RPC service. Useful where requests and/or responses are streamed.
         */
        Autopilot.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link autopilotrpc.Autopilot#status}.
         * @memberof autopilotrpc.Autopilot
         * @typedef StatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {autopilotrpc.StatusResponse} [response] StatusResponse
         */

        /**
         * Calls Status.
         * @function status
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.IStatusRequest} request StatusRequest message or plain object
         * @param {autopilotrpc.Autopilot.StatusCallback} callback Node-style callback called with the error, if any, and StatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Autopilot.prototype.status = function status(request, callback) {
            return this.rpcCall(status, $root.autopilotrpc.StatusRequest, $root.autopilotrpc.StatusResponse, request, callback);
        }, "name", { value: "Status" });

        /**
         * Calls Status.
         * @function status
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.IStatusRequest} request StatusRequest message or plain object
         * @returns {Promise<autopilotrpc.StatusResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link autopilotrpc.Autopilot#modifyStatus}.
         * @memberof autopilotrpc.Autopilot
         * @typedef ModifyStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {autopilotrpc.ModifyStatusResponse} [response] ModifyStatusResponse
         */

        /**
         * Calls ModifyStatus.
         * @function modifyStatus
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.IModifyStatusRequest} request ModifyStatusRequest message or plain object
         * @param {autopilotrpc.Autopilot.ModifyStatusCallback} callback Node-style callback called with the error, if any, and ModifyStatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Autopilot.prototype.modifyStatus = function modifyStatus(request, callback) {
            return this.rpcCall(modifyStatus, $root.autopilotrpc.ModifyStatusRequest, $root.autopilotrpc.ModifyStatusResponse, request, callback);
        }, "name", { value: "ModifyStatus" });

        /**
         * Calls ModifyStatus.
         * @function modifyStatus
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.IModifyStatusRequest} request ModifyStatusRequest message or plain object
         * @returns {Promise<autopilotrpc.ModifyStatusResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link autopilotrpc.Autopilot#queryScores}.
         * @memberof autopilotrpc.Autopilot
         * @typedef QueryScoresCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {autopilotrpc.QueryScoresResponse} [response] QueryScoresResponse
         */

        /**
         * Calls QueryScores.
         * @function queryScores
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.IQueryScoresRequest} request QueryScoresRequest message or plain object
         * @param {autopilotrpc.Autopilot.QueryScoresCallback} callback Node-style callback called with the error, if any, and QueryScoresResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Autopilot.prototype.queryScores = function queryScores(request, callback) {
            return this.rpcCall(queryScores, $root.autopilotrpc.QueryScoresRequest, $root.autopilotrpc.QueryScoresResponse, request, callback);
        }, "name", { value: "QueryScores" });

        /**
         * Calls QueryScores.
         * @function queryScores
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.IQueryScoresRequest} request QueryScoresRequest message or plain object
         * @returns {Promise<autopilotrpc.QueryScoresResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link autopilotrpc.Autopilot#setScores}.
         * @memberof autopilotrpc.Autopilot
         * @typedef SetScoresCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {autopilotrpc.SetScoresResponse} [response] SetScoresResponse
         */

        /**
         * Calls SetScores.
         * @function setScores
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.ISetScoresRequest} request SetScoresRequest message or plain object
         * @param {autopilotrpc.Autopilot.SetScoresCallback} callback Node-style callback called with the error, if any, and SetScoresResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Autopilot.prototype.setScores = function setScores(request, callback) {
            return this.rpcCall(setScores, $root.autopilotrpc.SetScoresRequest, $root.autopilotrpc.SetScoresResponse, request, callback);
        }, "name", { value: "SetScores" });

        /**
         * Calls SetScores.
         * @function setScores
         * @memberof autopilotrpc.Autopilot
         * @instance
         * @param {autopilotrpc.ISetScoresRequest} request SetScoresRequest message or plain object
         * @returns {Promise<autopilotrpc.SetScoresResponse>} Promise
         * @variation 2
         */

        return Autopilot;
    })();

    autopilotrpc.StatusRequest = (function() {

        /**
         * Properties of a StatusRequest.
         * @memberof autopilotrpc
         * @interface IStatusRequest
         */

        /**
         * Constructs a new StatusRequest.
         * @memberof autopilotrpc
         * @classdesc Represents a StatusRequest.
         * @implements IStatusRequest
         * @constructor
         * @param {autopilotrpc.IStatusRequest=} [properties] Properties to set
         */
        function StatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StatusRequest instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {autopilotrpc.IStatusRequest=} [properties] Properties to set
         * @returns {autopilotrpc.StatusRequest} StatusRequest instance
         */
        StatusRequest.create = function create(properties) {
            return new StatusRequest(properties);
        };

        /**
         * Encodes the specified StatusRequest message. Does not implicitly {@link autopilotrpc.StatusRequest.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {autopilotrpc.IStatusRequest} message StatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StatusRequest message, length delimited. Does not implicitly {@link autopilotrpc.StatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {autopilotrpc.IStatusRequest} message StatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.StatusRequest} StatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.StatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.StatusRequest} StatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusRequest message.
         * @function verify
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.StatusRequest} StatusRequest
         */
        StatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.StatusRequest)
                return object;
            return new $root.autopilotrpc.StatusRequest();
        };

        /**
         * Creates a plain object from a StatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.StatusRequest
         * @static
         * @param {autopilotrpc.StatusRequest} message StatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StatusRequest to JSON.
         * @function toJSON
         * @memberof autopilotrpc.StatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatusRequest;
    })();

    autopilotrpc.StatusResponse = (function() {

        /**
         * Properties of a StatusResponse.
         * @memberof autopilotrpc
         * @interface IStatusResponse
         * @property {boolean|null} [active] StatusResponse active
         */

        /**
         * Constructs a new StatusResponse.
         * @memberof autopilotrpc
         * @classdesc Represents a StatusResponse.
         * @implements IStatusResponse
         * @constructor
         * @param {autopilotrpc.IStatusResponse=} [properties] Properties to set
         */
        function StatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusResponse active.
         * @member {boolean} active
         * @memberof autopilotrpc.StatusResponse
         * @instance
         */
        StatusResponse.prototype.active = false;

        /**
         * Creates a new StatusResponse instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {autopilotrpc.IStatusResponse=} [properties] Properties to set
         * @returns {autopilotrpc.StatusResponse} StatusResponse instance
         */
        StatusResponse.create = function create(properties) {
            return new StatusResponse(properties);
        };

        /**
         * Encodes the specified StatusResponse message. Does not implicitly {@link autopilotrpc.StatusResponse.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {autopilotrpc.IStatusResponse} message StatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.active);
            return writer;
        };

        /**
         * Encodes the specified StatusResponse message, length delimited. Does not implicitly {@link autopilotrpc.StatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {autopilotrpc.IStatusResponse} message StatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.StatusResponse} StatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.StatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.active = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.StatusResponse} StatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusResponse message.
         * @function verify
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.active != null && message.hasOwnProperty("active"))
                if (typeof message.active !== "boolean")
                    return "active: boolean expected";
            return null;
        };

        /**
         * Creates a StatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.StatusResponse} StatusResponse
         */
        StatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.StatusResponse)
                return object;
            let message = new $root.autopilotrpc.StatusResponse();
            if (object.active != null)
                message.active = Boolean(object.active);
            return message;
        };

        /**
         * Creates a plain object from a StatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.StatusResponse
         * @static
         * @param {autopilotrpc.StatusResponse} message StatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.active = false;
            if (message.active != null && message.hasOwnProperty("active"))
                object.active = message.active;
            return object;
        };

        /**
         * Converts this StatusResponse to JSON.
         * @function toJSON
         * @memberof autopilotrpc.StatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatusResponse;
    })();

    autopilotrpc.ModifyStatusRequest = (function() {

        /**
         * Properties of a ModifyStatusRequest.
         * @memberof autopilotrpc
         * @interface IModifyStatusRequest
         * @property {boolean|null} [enable] ModifyStatusRequest enable
         */

        /**
         * Constructs a new ModifyStatusRequest.
         * @memberof autopilotrpc
         * @classdesc Represents a ModifyStatusRequest.
         * @implements IModifyStatusRequest
         * @constructor
         * @param {autopilotrpc.IModifyStatusRequest=} [properties] Properties to set
         */
        function ModifyStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ModifyStatusRequest enable.
         * @member {boolean} enable
         * @memberof autopilotrpc.ModifyStatusRequest
         * @instance
         */
        ModifyStatusRequest.prototype.enable = false;

        /**
         * Creates a new ModifyStatusRequest instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {autopilotrpc.IModifyStatusRequest=} [properties] Properties to set
         * @returns {autopilotrpc.ModifyStatusRequest} ModifyStatusRequest instance
         */
        ModifyStatusRequest.create = function create(properties) {
            return new ModifyStatusRequest(properties);
        };

        /**
         * Encodes the specified ModifyStatusRequest message. Does not implicitly {@link autopilotrpc.ModifyStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {autopilotrpc.IModifyStatusRequest} message ModifyStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.enable != null && Object.hasOwnProperty.call(message, "enable"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enable);
            return writer;
        };

        /**
         * Encodes the specified ModifyStatusRequest message, length delimited. Does not implicitly {@link autopilotrpc.ModifyStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {autopilotrpc.IModifyStatusRequest} message ModifyStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.ModifyStatusRequest} ModifyStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.ModifyStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.enable = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModifyStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.ModifyStatusRequest} ModifyStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyStatusRequest message.
         * @function verify
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.enable != null && message.hasOwnProperty("enable"))
                if (typeof message.enable !== "boolean")
                    return "enable: boolean expected";
            return null;
        };

        /**
         * Creates a ModifyStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.ModifyStatusRequest} ModifyStatusRequest
         */
        ModifyStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.ModifyStatusRequest)
                return object;
            let message = new $root.autopilotrpc.ModifyStatusRequest();
            if (object.enable != null)
                message.enable = Boolean(object.enable);
            return message;
        };

        /**
         * Creates a plain object from a ModifyStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.ModifyStatusRequest
         * @static
         * @param {autopilotrpc.ModifyStatusRequest} message ModifyStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.enable = false;
            if (message.enable != null && message.hasOwnProperty("enable"))
                object.enable = message.enable;
            return object;
        };

        /**
         * Converts this ModifyStatusRequest to JSON.
         * @function toJSON
         * @memberof autopilotrpc.ModifyStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ModifyStatusRequest;
    })();

    autopilotrpc.ModifyStatusResponse = (function() {

        /**
         * Properties of a ModifyStatusResponse.
         * @memberof autopilotrpc
         * @interface IModifyStatusResponse
         */

        /**
         * Constructs a new ModifyStatusResponse.
         * @memberof autopilotrpc
         * @classdesc Represents a ModifyStatusResponse.
         * @implements IModifyStatusResponse
         * @constructor
         * @param {autopilotrpc.IModifyStatusResponse=} [properties] Properties to set
         */
        function ModifyStatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ModifyStatusResponse instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {autopilotrpc.IModifyStatusResponse=} [properties] Properties to set
         * @returns {autopilotrpc.ModifyStatusResponse} ModifyStatusResponse instance
         */
        ModifyStatusResponse.create = function create(properties) {
            return new ModifyStatusResponse(properties);
        };

        /**
         * Encodes the specified ModifyStatusResponse message. Does not implicitly {@link autopilotrpc.ModifyStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {autopilotrpc.IModifyStatusResponse} message ModifyStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ModifyStatusResponse message, length delimited. Does not implicitly {@link autopilotrpc.ModifyStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {autopilotrpc.IModifyStatusResponse} message ModifyStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ModifyStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ModifyStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.ModifyStatusResponse} ModifyStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.ModifyStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ModifyStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.ModifyStatusResponse} ModifyStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ModifyStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ModifyStatusResponse message.
         * @function verify
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ModifyStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ModifyStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.ModifyStatusResponse} ModifyStatusResponse
         */
        ModifyStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.ModifyStatusResponse)
                return object;
            return new $root.autopilotrpc.ModifyStatusResponse();
        };

        /**
         * Creates a plain object from a ModifyStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.ModifyStatusResponse
         * @static
         * @param {autopilotrpc.ModifyStatusResponse} message ModifyStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ModifyStatusResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ModifyStatusResponse to JSON.
         * @function toJSON
         * @memberof autopilotrpc.ModifyStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ModifyStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ModifyStatusResponse;
    })();

    autopilotrpc.QueryScoresRequest = (function() {

        /**
         * Properties of a QueryScoresRequest.
         * @memberof autopilotrpc
         * @interface IQueryScoresRequest
         * @property {Array.<string>|null} [pubkeys] QueryScoresRequest pubkeys
         * @property {boolean|null} [ignoreLocalState] QueryScoresRequest ignoreLocalState
         */

        /**
         * Constructs a new QueryScoresRequest.
         * @memberof autopilotrpc
         * @classdesc Represents a QueryScoresRequest.
         * @implements IQueryScoresRequest
         * @constructor
         * @param {autopilotrpc.IQueryScoresRequest=} [properties] Properties to set
         */
        function QueryScoresRequest(properties) {
            this.pubkeys = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryScoresRequest pubkeys.
         * @member {Array.<string>} pubkeys
         * @memberof autopilotrpc.QueryScoresRequest
         * @instance
         */
        QueryScoresRequest.prototype.pubkeys = $util.emptyArray;

        /**
         * QueryScoresRequest ignoreLocalState.
         * @member {boolean} ignoreLocalState
         * @memberof autopilotrpc.QueryScoresRequest
         * @instance
         */
        QueryScoresRequest.prototype.ignoreLocalState = false;

        /**
         * Creates a new QueryScoresRequest instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {autopilotrpc.IQueryScoresRequest=} [properties] Properties to set
         * @returns {autopilotrpc.QueryScoresRequest} QueryScoresRequest instance
         */
        QueryScoresRequest.create = function create(properties) {
            return new QueryScoresRequest(properties);
        };

        /**
         * Encodes the specified QueryScoresRequest message. Does not implicitly {@link autopilotrpc.QueryScoresRequest.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {autopilotrpc.IQueryScoresRequest} message QueryScoresRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryScoresRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkeys != null && message.pubkeys.length)
                for (let i = 0; i < message.pubkeys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.pubkeys[i]);
            if (message.ignoreLocalState != null && Object.hasOwnProperty.call(message, "ignoreLocalState"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.ignoreLocalState);
            return writer;
        };

        /**
         * Encodes the specified QueryScoresRequest message, length delimited. Does not implicitly {@link autopilotrpc.QueryScoresRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {autopilotrpc.IQueryScoresRequest} message QueryScoresRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryScoresRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryScoresRequest message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.QueryScoresRequest} QueryScoresRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryScoresRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.QueryScoresRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.pubkeys && message.pubkeys.length))
                            message.pubkeys = [];
                        message.pubkeys.push(reader.string());
                        break;
                    }
                case 2: {
                        message.ignoreLocalState = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryScoresRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.QueryScoresRequest} QueryScoresRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryScoresRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryScoresRequest message.
         * @function verify
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryScoresRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkeys != null && message.hasOwnProperty("pubkeys")) {
                if (!Array.isArray(message.pubkeys))
                    return "pubkeys: array expected";
                for (let i = 0; i < message.pubkeys.length; ++i)
                    if (!$util.isString(message.pubkeys[i]))
                        return "pubkeys: string[] expected";
            }
            if (message.ignoreLocalState != null && message.hasOwnProperty("ignoreLocalState"))
                if (typeof message.ignoreLocalState !== "boolean")
                    return "ignoreLocalState: boolean expected";
            return null;
        };

        /**
         * Creates a QueryScoresRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.QueryScoresRequest} QueryScoresRequest
         */
        QueryScoresRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.QueryScoresRequest)
                return object;
            let message = new $root.autopilotrpc.QueryScoresRequest();
            if (object.pubkeys) {
                if (!Array.isArray(object.pubkeys))
                    throw TypeError(".autopilotrpc.QueryScoresRequest.pubkeys: array expected");
                message.pubkeys = [];
                for (let i = 0; i < object.pubkeys.length; ++i)
                    message.pubkeys[i] = String(object.pubkeys[i]);
            }
            if (object.ignoreLocalState != null)
                message.ignoreLocalState = Boolean(object.ignoreLocalState);
            return message;
        };

        /**
         * Creates a plain object from a QueryScoresRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.QueryScoresRequest
         * @static
         * @param {autopilotrpc.QueryScoresRequest} message QueryScoresRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryScoresRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.pubkeys = [];
            if (options.defaults)
                object.ignoreLocalState = false;
            if (message.pubkeys && message.pubkeys.length) {
                object.pubkeys = [];
                for (let j = 0; j < message.pubkeys.length; ++j)
                    object.pubkeys[j] = message.pubkeys[j];
            }
            if (message.ignoreLocalState != null && message.hasOwnProperty("ignoreLocalState"))
                object.ignoreLocalState = message.ignoreLocalState;
            return object;
        };

        /**
         * Converts this QueryScoresRequest to JSON.
         * @function toJSON
         * @memberof autopilotrpc.QueryScoresRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryScoresRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryScoresRequest;
    })();

    autopilotrpc.QueryScoresResponse = (function() {

        /**
         * Properties of a QueryScoresResponse.
         * @memberof autopilotrpc
         * @interface IQueryScoresResponse
         * @property {Array.<autopilotrpc.QueryScoresResponse.IHeuristicResult>|null} [results] QueryScoresResponse results
         */

        /**
         * Constructs a new QueryScoresResponse.
         * @memberof autopilotrpc
         * @classdesc Represents a QueryScoresResponse.
         * @implements IQueryScoresResponse
         * @constructor
         * @param {autopilotrpc.IQueryScoresResponse=} [properties] Properties to set
         */
        function QueryScoresResponse(properties) {
            this.results = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryScoresResponse results.
         * @member {Array.<autopilotrpc.QueryScoresResponse.IHeuristicResult>} results
         * @memberof autopilotrpc.QueryScoresResponse
         * @instance
         */
        QueryScoresResponse.prototype.results = $util.emptyArray;

        /**
         * Creates a new QueryScoresResponse instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {autopilotrpc.IQueryScoresResponse=} [properties] Properties to set
         * @returns {autopilotrpc.QueryScoresResponse} QueryScoresResponse instance
         */
        QueryScoresResponse.create = function create(properties) {
            return new QueryScoresResponse(properties);
        };

        /**
         * Encodes the specified QueryScoresResponse message. Does not implicitly {@link autopilotrpc.QueryScoresResponse.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {autopilotrpc.IQueryScoresResponse} message QueryScoresResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryScoresResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.results != null && message.results.length)
                for (let i = 0; i < message.results.length; ++i)
                    $root.autopilotrpc.QueryScoresResponse.HeuristicResult.encode(message.results[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueryScoresResponse message, length delimited. Does not implicitly {@link autopilotrpc.QueryScoresResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {autopilotrpc.IQueryScoresResponse} message QueryScoresResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryScoresResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryScoresResponse message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.QueryScoresResponse} QueryScoresResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryScoresResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.QueryScoresResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.results && message.results.length))
                            message.results = [];
                        message.results.push($root.autopilotrpc.QueryScoresResponse.HeuristicResult.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryScoresResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.QueryScoresResponse} QueryScoresResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryScoresResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryScoresResponse message.
         * @function verify
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryScoresResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.results != null && message.hasOwnProperty("results")) {
                if (!Array.isArray(message.results))
                    return "results: array expected";
                for (let i = 0; i < message.results.length; ++i) {
                    let error = $root.autopilotrpc.QueryScoresResponse.HeuristicResult.verify(message.results[i]);
                    if (error)
                        return "results." + error;
                }
            }
            return null;
        };

        /**
         * Creates a QueryScoresResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.QueryScoresResponse} QueryScoresResponse
         */
        QueryScoresResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.QueryScoresResponse)
                return object;
            let message = new $root.autopilotrpc.QueryScoresResponse();
            if (object.results) {
                if (!Array.isArray(object.results))
                    throw TypeError(".autopilotrpc.QueryScoresResponse.results: array expected");
                message.results = [];
                for (let i = 0; i < object.results.length; ++i) {
                    if (typeof object.results[i] !== "object")
                        throw TypeError(".autopilotrpc.QueryScoresResponse.results: object expected");
                    message.results[i] = $root.autopilotrpc.QueryScoresResponse.HeuristicResult.fromObject(object.results[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryScoresResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.QueryScoresResponse
         * @static
         * @param {autopilotrpc.QueryScoresResponse} message QueryScoresResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryScoresResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.results = [];
            if (message.results && message.results.length) {
                object.results = [];
                for (let j = 0; j < message.results.length; ++j)
                    object.results[j] = $root.autopilotrpc.QueryScoresResponse.HeuristicResult.toObject(message.results[j], options);
            }
            return object;
        };

        /**
         * Converts this QueryScoresResponse to JSON.
         * @function toJSON
         * @memberof autopilotrpc.QueryScoresResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryScoresResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        QueryScoresResponse.HeuristicResult = (function() {

            /**
             * Properties of a HeuristicResult.
             * @memberof autopilotrpc.QueryScoresResponse
             * @interface IHeuristicResult
             * @property {string|null} [heuristic] HeuristicResult heuristic
             * @property {Object.<string,number>|null} [scores] HeuristicResult scores
             */

            /**
             * Constructs a new HeuristicResult.
             * @memberof autopilotrpc.QueryScoresResponse
             * @classdesc Represents a HeuristicResult.
             * @implements IHeuristicResult
             * @constructor
             * @param {autopilotrpc.QueryScoresResponse.IHeuristicResult=} [properties] Properties to set
             */
            function HeuristicResult(properties) {
                this.scores = {};
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * HeuristicResult heuristic.
             * @member {string} heuristic
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @instance
             */
            HeuristicResult.prototype.heuristic = "";

            /**
             * HeuristicResult scores.
             * @member {Object.<string,number>} scores
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @instance
             */
            HeuristicResult.prototype.scores = $util.emptyObject;

            /**
             * Creates a new HeuristicResult instance using the specified properties.
             * @function create
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {autopilotrpc.QueryScoresResponse.IHeuristicResult=} [properties] Properties to set
             * @returns {autopilotrpc.QueryScoresResponse.HeuristicResult} HeuristicResult instance
             */
            HeuristicResult.create = function create(properties) {
                return new HeuristicResult(properties);
            };

            /**
             * Encodes the specified HeuristicResult message. Does not implicitly {@link autopilotrpc.QueryScoresResponse.HeuristicResult.verify|verify} messages.
             * @function encode
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {autopilotrpc.QueryScoresResponse.IHeuristicResult} message HeuristicResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HeuristicResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.heuristic != null && Object.hasOwnProperty.call(message, "heuristic"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.heuristic);
                if (message.scores != null && Object.hasOwnProperty.call(message, "scores"))
                    for (let keys = Object.keys(message.scores), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 1 =*/17).double(message.scores[keys[i]]).ldelim();
                return writer;
            };

            /**
             * Encodes the specified HeuristicResult message, length delimited. Does not implicitly {@link autopilotrpc.QueryScoresResponse.HeuristicResult.verify|verify} messages.
             * @function encodeDelimited
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {autopilotrpc.QueryScoresResponse.IHeuristicResult} message HeuristicResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            HeuristicResult.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a HeuristicResult message from the specified reader or buffer.
             * @function decode
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {autopilotrpc.QueryScoresResponse.HeuristicResult} HeuristicResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HeuristicResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.QueryScoresResponse.HeuristicResult(), key, value;
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.heuristic = reader.string();
                            break;
                        }
                    case 2: {
                            if (message.scores === $util.emptyObject)
                                message.scores = {};
                            let end2 = reader.uint32() + reader.pos;
                            key = "";
                            value = 0;
                            while (reader.pos < end2) {
                                let tag2 = reader.uint32();
                                switch (tag2 >>> 3) {
                                case 1:
                                    key = reader.string();
                                    break;
                                case 2:
                                    value = reader.double();
                                    break;
                                default:
                                    reader.skipType(tag2 & 7);
                                    break;
                                }
                            }
                            message.scores[key] = value;
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a HeuristicResult message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {autopilotrpc.QueryScoresResponse.HeuristicResult} HeuristicResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            HeuristicResult.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a HeuristicResult message.
             * @function verify
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            HeuristicResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.heuristic != null && message.hasOwnProperty("heuristic"))
                    if (!$util.isString(message.heuristic))
                        return "heuristic: string expected";
                if (message.scores != null && message.hasOwnProperty("scores")) {
                    if (!$util.isObject(message.scores))
                        return "scores: object expected";
                    let key = Object.keys(message.scores);
                    for (let i = 0; i < key.length; ++i)
                        if (typeof message.scores[key[i]] !== "number")
                            return "scores: number{k:string} expected";
                }
                return null;
            };

            /**
             * Creates a HeuristicResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {autopilotrpc.QueryScoresResponse.HeuristicResult} HeuristicResult
             */
            HeuristicResult.fromObject = function fromObject(object) {
                if (object instanceof $root.autopilotrpc.QueryScoresResponse.HeuristicResult)
                    return object;
                let message = new $root.autopilotrpc.QueryScoresResponse.HeuristicResult();
                if (object.heuristic != null)
                    message.heuristic = String(object.heuristic);
                if (object.scores) {
                    if (typeof object.scores !== "object")
                        throw TypeError(".autopilotrpc.QueryScoresResponse.HeuristicResult.scores: object expected");
                    message.scores = {};
                    for (let keys = Object.keys(object.scores), i = 0; i < keys.length; ++i)
                        message.scores[keys[i]] = Number(object.scores[keys[i]]);
                }
                return message;
            };

            /**
             * Creates a plain object from a HeuristicResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @static
             * @param {autopilotrpc.QueryScoresResponse.HeuristicResult} message HeuristicResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            HeuristicResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.objects || options.defaults)
                    object.scores = {};
                if (options.defaults)
                    object.heuristic = "";
                if (message.heuristic != null && message.hasOwnProperty("heuristic"))
                    object.heuristic = message.heuristic;
                let keys2;
                if (message.scores && (keys2 = Object.keys(message.scores)).length) {
                    object.scores = {};
                    for (let j = 0; j < keys2.length; ++j)
                        object.scores[keys2[j]] = options.json && !isFinite(message.scores[keys2[j]]) ? String(message.scores[keys2[j]]) : message.scores[keys2[j]];
                }
                return object;
            };

            /**
             * Converts this HeuristicResult to JSON.
             * @function toJSON
             * @memberof autopilotrpc.QueryScoresResponse.HeuristicResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            HeuristicResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return HeuristicResult;
        })();

        return QueryScoresResponse;
    })();

    autopilotrpc.SetScoresRequest = (function() {

        /**
         * Properties of a SetScoresRequest.
         * @memberof autopilotrpc
         * @interface ISetScoresRequest
         * @property {string|null} [heuristic] SetScoresRequest heuristic
         * @property {Object.<string,number>|null} [scores] SetScoresRequest scores
         */

        /**
         * Constructs a new SetScoresRequest.
         * @memberof autopilotrpc
         * @classdesc Represents a SetScoresRequest.
         * @implements ISetScoresRequest
         * @constructor
         * @param {autopilotrpc.ISetScoresRequest=} [properties] Properties to set
         */
        function SetScoresRequest(properties) {
            this.scores = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetScoresRequest heuristic.
         * @member {string} heuristic
         * @memberof autopilotrpc.SetScoresRequest
         * @instance
         */
        SetScoresRequest.prototype.heuristic = "";

        /**
         * SetScoresRequest scores.
         * @member {Object.<string,number>} scores
         * @memberof autopilotrpc.SetScoresRequest
         * @instance
         */
        SetScoresRequest.prototype.scores = $util.emptyObject;

        /**
         * Creates a new SetScoresRequest instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {autopilotrpc.ISetScoresRequest=} [properties] Properties to set
         * @returns {autopilotrpc.SetScoresRequest} SetScoresRequest instance
         */
        SetScoresRequest.create = function create(properties) {
            return new SetScoresRequest(properties);
        };

        /**
         * Encodes the specified SetScoresRequest message. Does not implicitly {@link autopilotrpc.SetScoresRequest.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {autopilotrpc.ISetScoresRequest} message SetScoresRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetScoresRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.heuristic != null && Object.hasOwnProperty.call(message, "heuristic"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.heuristic);
            if (message.scores != null && Object.hasOwnProperty.call(message, "scores"))
                for (let keys = Object.keys(message.scores), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 1 =*/17).double(message.scores[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetScoresRequest message, length delimited. Does not implicitly {@link autopilotrpc.SetScoresRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {autopilotrpc.ISetScoresRequest} message SetScoresRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetScoresRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetScoresRequest message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.SetScoresRequest} SetScoresRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetScoresRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.SetScoresRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.heuristic = reader.string();
                        break;
                    }
                case 2: {
                        if (message.scores === $util.emptyObject)
                            message.scores = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.double();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.scores[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetScoresRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.SetScoresRequest} SetScoresRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetScoresRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetScoresRequest message.
         * @function verify
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetScoresRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.heuristic != null && message.hasOwnProperty("heuristic"))
                if (!$util.isString(message.heuristic))
                    return "heuristic: string expected";
            if (message.scores != null && message.hasOwnProperty("scores")) {
                if (!$util.isObject(message.scores))
                    return "scores: object expected";
                let key = Object.keys(message.scores);
                for (let i = 0; i < key.length; ++i)
                    if (typeof message.scores[key[i]] !== "number")
                        return "scores: number{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a SetScoresRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.SetScoresRequest} SetScoresRequest
         */
        SetScoresRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.SetScoresRequest)
                return object;
            let message = new $root.autopilotrpc.SetScoresRequest();
            if (object.heuristic != null)
                message.heuristic = String(object.heuristic);
            if (object.scores) {
                if (typeof object.scores !== "object")
                    throw TypeError(".autopilotrpc.SetScoresRequest.scores: object expected");
                message.scores = {};
                for (let keys = Object.keys(object.scores), i = 0; i < keys.length; ++i)
                    message.scores[keys[i]] = Number(object.scores[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a SetScoresRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.SetScoresRequest
         * @static
         * @param {autopilotrpc.SetScoresRequest} message SetScoresRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetScoresRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.scores = {};
            if (options.defaults)
                object.heuristic = "";
            if (message.heuristic != null && message.hasOwnProperty("heuristic"))
                object.heuristic = message.heuristic;
            let keys2;
            if (message.scores && (keys2 = Object.keys(message.scores)).length) {
                object.scores = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.scores[keys2[j]] = options.json && !isFinite(message.scores[keys2[j]]) ? String(message.scores[keys2[j]]) : message.scores[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this SetScoresRequest to JSON.
         * @function toJSON
         * @memberof autopilotrpc.SetScoresRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetScoresRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetScoresRequest;
    })();

    autopilotrpc.SetScoresResponse = (function() {

        /**
         * Properties of a SetScoresResponse.
         * @memberof autopilotrpc
         * @interface ISetScoresResponse
         */

        /**
         * Constructs a new SetScoresResponse.
         * @memberof autopilotrpc
         * @classdesc Represents a SetScoresResponse.
         * @implements ISetScoresResponse
         * @constructor
         * @param {autopilotrpc.ISetScoresResponse=} [properties] Properties to set
         */
        function SetScoresResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetScoresResponse instance using the specified properties.
         * @function create
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {autopilotrpc.ISetScoresResponse=} [properties] Properties to set
         * @returns {autopilotrpc.SetScoresResponse} SetScoresResponse instance
         */
        SetScoresResponse.create = function create(properties) {
            return new SetScoresResponse(properties);
        };

        /**
         * Encodes the specified SetScoresResponse message. Does not implicitly {@link autopilotrpc.SetScoresResponse.verify|verify} messages.
         * @function encode
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {autopilotrpc.ISetScoresResponse} message SetScoresResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetScoresResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetScoresResponse message, length delimited. Does not implicitly {@link autopilotrpc.SetScoresResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {autopilotrpc.ISetScoresResponse} message SetScoresResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetScoresResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetScoresResponse message from the specified reader or buffer.
         * @function decode
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {autopilotrpc.SetScoresResponse} SetScoresResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetScoresResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.autopilotrpc.SetScoresResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetScoresResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {autopilotrpc.SetScoresResponse} SetScoresResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetScoresResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetScoresResponse message.
         * @function verify
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetScoresResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetScoresResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {autopilotrpc.SetScoresResponse} SetScoresResponse
         */
        SetScoresResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.autopilotrpc.SetScoresResponse)
                return object;
            return new $root.autopilotrpc.SetScoresResponse();
        };

        /**
         * Creates a plain object from a SetScoresResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof autopilotrpc.SetScoresResponse
         * @static
         * @param {autopilotrpc.SetScoresResponse} message SetScoresResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetScoresResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetScoresResponse to JSON.
         * @function toJSON
         * @memberof autopilotrpc.SetScoresResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetScoresResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetScoresResponse;
    })();

    return autopilotrpc;
})();

export const chainrpc = $root.chainrpc = (() => {

    /**
     * Namespace chainrpc.
     * @exports chainrpc
     * @namespace
     */
    const chainrpc = {};

    chainrpc.ChainNotifier = (function() {

        /**
         * Constructs a new ChainNotifier service.
         * @memberof chainrpc
         * @classdesc Represents a ChainNotifier
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ChainNotifier(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ChainNotifier.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ChainNotifier;

        /**
         * Creates new ChainNotifier service using the specified rpc implementation.
         * @function create
         * @memberof chainrpc.ChainNotifier
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {ChainNotifier} RPC service. Useful where requests and/or responses are streamed.
         */
        ChainNotifier.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link chainrpc.ChainNotifier#registerConfirmationsNtfn}.
         * @memberof chainrpc.ChainNotifier
         * @typedef RegisterConfirmationsNtfnCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {chainrpc.ConfEvent} [response] ConfEvent
         */

        /**
         * Calls RegisterConfirmationsNtfn.
         * @function registerConfirmationsNtfn
         * @memberof chainrpc.ChainNotifier
         * @instance
         * @param {chainrpc.IConfRequest} request ConfRequest message or plain object
         * @param {chainrpc.ChainNotifier.RegisterConfirmationsNtfnCallback} callback Node-style callback called with the error, if any, and ConfEvent
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ChainNotifier.prototype.registerConfirmationsNtfn = function registerConfirmationsNtfn(request, callback) {
            return this.rpcCall(registerConfirmationsNtfn, $root.chainrpc.ConfRequest, $root.chainrpc.ConfEvent, request, callback);
        }, "name", { value: "RegisterConfirmationsNtfn" });

        /**
         * Calls RegisterConfirmationsNtfn.
         * @function registerConfirmationsNtfn
         * @memberof chainrpc.ChainNotifier
         * @instance
         * @param {chainrpc.IConfRequest} request ConfRequest message or plain object
         * @returns {Promise<chainrpc.ConfEvent>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link chainrpc.ChainNotifier#registerSpendNtfn}.
         * @memberof chainrpc.ChainNotifier
         * @typedef RegisterSpendNtfnCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {chainrpc.SpendEvent} [response] SpendEvent
         */

        /**
         * Calls RegisterSpendNtfn.
         * @function registerSpendNtfn
         * @memberof chainrpc.ChainNotifier
         * @instance
         * @param {chainrpc.ISpendRequest} request SpendRequest message or plain object
         * @param {chainrpc.ChainNotifier.RegisterSpendNtfnCallback} callback Node-style callback called with the error, if any, and SpendEvent
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ChainNotifier.prototype.registerSpendNtfn = function registerSpendNtfn(request, callback) {
            return this.rpcCall(registerSpendNtfn, $root.chainrpc.SpendRequest, $root.chainrpc.SpendEvent, request, callback);
        }, "name", { value: "RegisterSpendNtfn" });

        /**
         * Calls RegisterSpendNtfn.
         * @function registerSpendNtfn
         * @memberof chainrpc.ChainNotifier
         * @instance
         * @param {chainrpc.ISpendRequest} request SpendRequest message or plain object
         * @returns {Promise<chainrpc.SpendEvent>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link chainrpc.ChainNotifier#registerBlockEpochNtfn}.
         * @memberof chainrpc.ChainNotifier
         * @typedef RegisterBlockEpochNtfnCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {chainrpc.BlockEpoch} [response] BlockEpoch
         */

        /**
         * Calls RegisterBlockEpochNtfn.
         * @function registerBlockEpochNtfn
         * @memberof chainrpc.ChainNotifier
         * @instance
         * @param {chainrpc.IBlockEpoch} request BlockEpoch message or plain object
         * @param {chainrpc.ChainNotifier.RegisterBlockEpochNtfnCallback} callback Node-style callback called with the error, if any, and BlockEpoch
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ChainNotifier.prototype.registerBlockEpochNtfn = function registerBlockEpochNtfn(request, callback) {
            return this.rpcCall(registerBlockEpochNtfn, $root.chainrpc.BlockEpoch, $root.chainrpc.BlockEpoch, request, callback);
        }, "name", { value: "RegisterBlockEpochNtfn" });

        /**
         * Calls RegisterBlockEpochNtfn.
         * @function registerBlockEpochNtfn
         * @memberof chainrpc.ChainNotifier
         * @instance
         * @param {chainrpc.IBlockEpoch} request BlockEpoch message or plain object
         * @returns {Promise<chainrpc.BlockEpoch>} Promise
         * @variation 2
         */

        return ChainNotifier;
    })();

    chainrpc.ConfRequest = (function() {

        /**
         * Properties of a ConfRequest.
         * @memberof chainrpc
         * @interface IConfRequest
         * @property {Uint8Array|null} [txid] ConfRequest txid
         * @property {Uint8Array|null} [script] ConfRequest script
         * @property {number|null} [numConfs] ConfRequest numConfs
         * @property {number|null} [heightHint] ConfRequest heightHint
         */

        /**
         * Constructs a new ConfRequest.
         * @memberof chainrpc
         * @classdesc Represents a ConfRequest.
         * @implements IConfRequest
         * @constructor
         * @param {chainrpc.IConfRequest=} [properties] Properties to set
         */
        function ConfRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfRequest txid.
         * @member {Uint8Array} txid
         * @memberof chainrpc.ConfRequest
         * @instance
         */
        ConfRequest.prototype.txid = $util.newBuffer([]);

        /**
         * ConfRequest script.
         * @member {Uint8Array} script
         * @memberof chainrpc.ConfRequest
         * @instance
         */
        ConfRequest.prototype.script = $util.newBuffer([]);

        /**
         * ConfRequest numConfs.
         * @member {number} numConfs
         * @memberof chainrpc.ConfRequest
         * @instance
         */
        ConfRequest.prototype.numConfs = 0;

        /**
         * ConfRequest heightHint.
         * @member {number} heightHint
         * @memberof chainrpc.ConfRequest
         * @instance
         */
        ConfRequest.prototype.heightHint = 0;

        /**
         * Creates a new ConfRequest instance using the specified properties.
         * @function create
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {chainrpc.IConfRequest=} [properties] Properties to set
         * @returns {chainrpc.ConfRequest} ConfRequest instance
         */
        ConfRequest.create = function create(properties) {
            return new ConfRequest(properties);
        };

        /**
         * Encodes the specified ConfRequest message. Does not implicitly {@link chainrpc.ConfRequest.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {chainrpc.IConfRequest} message ConfRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txid != null && Object.hasOwnProperty.call(message, "txid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.txid);
            if (message.script != null && Object.hasOwnProperty.call(message, "script"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.script);
            if (message.numConfs != null && Object.hasOwnProperty.call(message, "numConfs"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numConfs);
            if (message.heightHint != null && Object.hasOwnProperty.call(message, "heightHint"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.heightHint);
            return writer;
        };

        /**
         * Encodes the specified ConfRequest message, length delimited. Does not implicitly {@link chainrpc.ConfRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {chainrpc.IConfRequest} message ConfRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfRequest message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.ConfRequest} ConfRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.ConfRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.script = reader.bytes();
                        break;
                    }
                case 3: {
                        message.numConfs = reader.uint32();
                        break;
                    }
                case 4: {
                        message.heightHint = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.ConfRequest} ConfRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfRequest message.
         * @function verify
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txid != null && message.hasOwnProperty("txid"))
                if (!(message.txid && typeof message.txid.length === "number" || $util.isString(message.txid)))
                    return "txid: buffer expected";
            if (message.script != null && message.hasOwnProperty("script"))
                if (!(message.script && typeof message.script.length === "number" || $util.isString(message.script)))
                    return "script: buffer expected";
            if (message.numConfs != null && message.hasOwnProperty("numConfs"))
                if (!$util.isInteger(message.numConfs))
                    return "numConfs: integer expected";
            if (message.heightHint != null && message.hasOwnProperty("heightHint"))
                if (!$util.isInteger(message.heightHint))
                    return "heightHint: integer expected";
            return null;
        };

        /**
         * Creates a ConfRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.ConfRequest} ConfRequest
         */
        ConfRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.ConfRequest)
                return object;
            let message = new $root.chainrpc.ConfRequest();
            if (object.txid != null)
                if (typeof object.txid === "string")
                    $util.base64.decode(object.txid, message.txid = $util.newBuffer($util.base64.length(object.txid)), 0);
                else if (object.txid.length)
                    message.txid = object.txid;
            if (object.script != null)
                if (typeof object.script === "string")
                    $util.base64.decode(object.script, message.script = $util.newBuffer($util.base64.length(object.script)), 0);
                else if (object.script.length)
                    message.script = object.script;
            if (object.numConfs != null)
                message.numConfs = object.numConfs >>> 0;
            if (object.heightHint != null)
                message.heightHint = object.heightHint >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ConfRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.ConfRequest
         * @static
         * @param {chainrpc.ConfRequest} message ConfRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.txid = "";
                else {
                    object.txid = [];
                    if (options.bytes !== Array)
                        object.txid = $util.newBuffer(object.txid);
                }
                if (options.bytes === String)
                    object.script = "";
                else {
                    object.script = [];
                    if (options.bytes !== Array)
                        object.script = $util.newBuffer(object.script);
                }
                object.numConfs = 0;
                object.heightHint = 0;
            }
            if (message.txid != null && message.hasOwnProperty("txid"))
                object.txid = options.bytes === String ? $util.base64.encode(message.txid, 0, message.txid.length) : options.bytes === Array ? Array.prototype.slice.call(message.txid) : message.txid;
            if (message.script != null && message.hasOwnProperty("script"))
                object.script = options.bytes === String ? $util.base64.encode(message.script, 0, message.script.length) : options.bytes === Array ? Array.prototype.slice.call(message.script) : message.script;
            if (message.numConfs != null && message.hasOwnProperty("numConfs"))
                object.numConfs = message.numConfs;
            if (message.heightHint != null && message.hasOwnProperty("heightHint"))
                object.heightHint = message.heightHint;
            return object;
        };

        /**
         * Converts this ConfRequest to JSON.
         * @function toJSON
         * @memberof chainrpc.ConfRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConfRequest;
    })();

    chainrpc.ConfDetails = (function() {

        /**
         * Properties of a ConfDetails.
         * @memberof chainrpc
         * @interface IConfDetails
         * @property {Uint8Array|null} [rawTx] ConfDetails rawTx
         * @property {Uint8Array|null} [blockHash] ConfDetails blockHash
         * @property {number|null} [blockHeight] ConfDetails blockHeight
         * @property {number|null} [txIndex] ConfDetails txIndex
         */

        /**
         * Constructs a new ConfDetails.
         * @memberof chainrpc
         * @classdesc Represents a ConfDetails.
         * @implements IConfDetails
         * @constructor
         * @param {chainrpc.IConfDetails=} [properties] Properties to set
         */
        function ConfDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfDetails rawTx.
         * @member {Uint8Array} rawTx
         * @memberof chainrpc.ConfDetails
         * @instance
         */
        ConfDetails.prototype.rawTx = $util.newBuffer([]);

        /**
         * ConfDetails blockHash.
         * @member {Uint8Array} blockHash
         * @memberof chainrpc.ConfDetails
         * @instance
         */
        ConfDetails.prototype.blockHash = $util.newBuffer([]);

        /**
         * ConfDetails blockHeight.
         * @member {number} blockHeight
         * @memberof chainrpc.ConfDetails
         * @instance
         */
        ConfDetails.prototype.blockHeight = 0;

        /**
         * ConfDetails txIndex.
         * @member {number} txIndex
         * @memberof chainrpc.ConfDetails
         * @instance
         */
        ConfDetails.prototype.txIndex = 0;

        /**
         * Creates a new ConfDetails instance using the specified properties.
         * @function create
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {chainrpc.IConfDetails=} [properties] Properties to set
         * @returns {chainrpc.ConfDetails} ConfDetails instance
         */
        ConfDetails.create = function create(properties) {
            return new ConfDetails(properties);
        };

        /**
         * Encodes the specified ConfDetails message. Does not implicitly {@link chainrpc.ConfDetails.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {chainrpc.IConfDetails} message ConfDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawTx != null && Object.hasOwnProperty.call(message, "rawTx"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rawTx);
            if (message.blockHash != null && Object.hasOwnProperty.call(message, "blockHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.blockHash);
            if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.blockHeight);
            if (message.txIndex != null && Object.hasOwnProperty.call(message, "txIndex"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.txIndex);
            return writer;
        };

        /**
         * Encodes the specified ConfDetails message, length delimited. Does not implicitly {@link chainrpc.ConfDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {chainrpc.IConfDetails} message ConfDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfDetails message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.ConfDetails} ConfDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.ConfDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rawTx = reader.bytes();
                        break;
                    }
                case 2: {
                        message.blockHash = reader.bytes();
                        break;
                    }
                case 3: {
                        message.blockHeight = reader.uint32();
                        break;
                    }
                case 4: {
                        message.txIndex = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.ConfDetails} ConfDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfDetails message.
         * @function verify
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawTx != null && message.hasOwnProperty("rawTx"))
                if (!(message.rawTx && typeof message.rawTx.length === "number" || $util.isString(message.rawTx)))
                    return "rawTx: buffer expected";
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                if (!(message.blockHash && typeof message.blockHash.length === "number" || $util.isString(message.blockHash)))
                    return "blockHash: buffer expected";
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                if (!$util.isInteger(message.blockHeight))
                    return "blockHeight: integer expected";
            if (message.txIndex != null && message.hasOwnProperty("txIndex"))
                if (!$util.isInteger(message.txIndex))
                    return "txIndex: integer expected";
            return null;
        };

        /**
         * Creates a ConfDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.ConfDetails} ConfDetails
         */
        ConfDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.ConfDetails)
                return object;
            let message = new $root.chainrpc.ConfDetails();
            if (object.rawTx != null)
                if (typeof object.rawTx === "string")
                    $util.base64.decode(object.rawTx, message.rawTx = $util.newBuffer($util.base64.length(object.rawTx)), 0);
                else if (object.rawTx.length)
                    message.rawTx = object.rawTx;
            if (object.blockHash != null)
                if (typeof object.blockHash === "string")
                    $util.base64.decode(object.blockHash, message.blockHash = $util.newBuffer($util.base64.length(object.blockHash)), 0);
                else if (object.blockHash.length)
                    message.blockHash = object.blockHash;
            if (object.blockHeight != null)
                message.blockHeight = object.blockHeight >>> 0;
            if (object.txIndex != null)
                message.txIndex = object.txIndex >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a ConfDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.ConfDetails
         * @static
         * @param {chainrpc.ConfDetails} message ConfDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.rawTx = "";
                else {
                    object.rawTx = [];
                    if (options.bytes !== Array)
                        object.rawTx = $util.newBuffer(object.rawTx);
                }
                if (options.bytes === String)
                    object.blockHash = "";
                else {
                    object.blockHash = [];
                    if (options.bytes !== Array)
                        object.blockHash = $util.newBuffer(object.blockHash);
                }
                object.blockHeight = 0;
                object.txIndex = 0;
            }
            if (message.rawTx != null && message.hasOwnProperty("rawTx"))
                object.rawTx = options.bytes === String ? $util.base64.encode(message.rawTx, 0, message.rawTx.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawTx) : message.rawTx;
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                object.blockHash = options.bytes === String ? $util.base64.encode(message.blockHash, 0, message.blockHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.blockHash) : message.blockHash;
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                object.blockHeight = message.blockHeight;
            if (message.txIndex != null && message.hasOwnProperty("txIndex"))
                object.txIndex = message.txIndex;
            return object;
        };

        /**
         * Converts this ConfDetails to JSON.
         * @function toJSON
         * @memberof chainrpc.ConfDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConfDetails;
    })();

    chainrpc.Reorg = (function() {

        /**
         * Properties of a Reorg.
         * @memberof chainrpc
         * @interface IReorg
         */

        /**
         * Constructs a new Reorg.
         * @memberof chainrpc
         * @classdesc Represents a Reorg.
         * @implements IReorg
         * @constructor
         * @param {chainrpc.IReorg=} [properties] Properties to set
         */
        function Reorg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new Reorg instance using the specified properties.
         * @function create
         * @memberof chainrpc.Reorg
         * @static
         * @param {chainrpc.IReorg=} [properties] Properties to set
         * @returns {chainrpc.Reorg} Reorg instance
         */
        Reorg.create = function create(properties) {
            return new Reorg(properties);
        };

        /**
         * Encodes the specified Reorg message. Does not implicitly {@link chainrpc.Reorg.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.Reorg
         * @static
         * @param {chainrpc.IReorg} message Reorg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reorg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified Reorg message, length delimited. Does not implicitly {@link chainrpc.Reorg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.Reorg
         * @static
         * @param {chainrpc.IReorg} message Reorg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reorg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Reorg message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.Reorg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.Reorg} Reorg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reorg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.Reorg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Reorg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.Reorg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.Reorg} Reorg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reorg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Reorg message.
         * @function verify
         * @memberof chainrpc.Reorg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Reorg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a Reorg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.Reorg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.Reorg} Reorg
         */
        Reorg.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.Reorg)
                return object;
            return new $root.chainrpc.Reorg();
        };

        /**
         * Creates a plain object from a Reorg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.Reorg
         * @static
         * @param {chainrpc.Reorg} message Reorg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Reorg.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this Reorg to JSON.
         * @function toJSON
         * @memberof chainrpc.Reorg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Reorg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Reorg;
    })();

    chainrpc.ConfEvent = (function() {

        /**
         * Properties of a ConfEvent.
         * @memberof chainrpc
         * @interface IConfEvent
         * @property {chainrpc.IConfDetails|null} [conf] ConfEvent conf
         * @property {chainrpc.IReorg|null} [reorg] ConfEvent reorg
         */

        /**
         * Constructs a new ConfEvent.
         * @memberof chainrpc
         * @classdesc Represents a ConfEvent.
         * @implements IConfEvent
         * @constructor
         * @param {chainrpc.IConfEvent=} [properties] Properties to set
         */
        function ConfEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfEvent conf.
         * @member {chainrpc.IConfDetails|null|undefined} conf
         * @memberof chainrpc.ConfEvent
         * @instance
         */
        ConfEvent.prototype.conf = null;

        /**
         * ConfEvent reorg.
         * @member {chainrpc.IReorg|null|undefined} reorg
         * @memberof chainrpc.ConfEvent
         * @instance
         */
        ConfEvent.prototype.reorg = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ConfEvent event.
         * @member {"conf"|"reorg"|undefined} event
         * @memberof chainrpc.ConfEvent
         * @instance
         */
        Object.defineProperty(ConfEvent.prototype, "event", {
            get: $util.oneOfGetter($oneOfFields = ["conf", "reorg"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ConfEvent instance using the specified properties.
         * @function create
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {chainrpc.IConfEvent=} [properties] Properties to set
         * @returns {chainrpc.ConfEvent} ConfEvent instance
         */
        ConfEvent.create = function create(properties) {
            return new ConfEvent(properties);
        };

        /**
         * Encodes the specified ConfEvent message. Does not implicitly {@link chainrpc.ConfEvent.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {chainrpc.IConfEvent} message ConfEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conf != null && Object.hasOwnProperty.call(message, "conf"))
                $root.chainrpc.ConfDetails.encode(message.conf, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.reorg != null && Object.hasOwnProperty.call(message, "reorg"))
                $root.chainrpc.Reorg.encode(message.reorg, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConfEvent message, length delimited. Does not implicitly {@link chainrpc.ConfEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {chainrpc.IConfEvent} message ConfEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfEvent message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.ConfEvent} ConfEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.ConfEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.conf = $root.chainrpc.ConfDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.reorg = $root.chainrpc.Reorg.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.ConfEvent} ConfEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfEvent message.
         * @function verify
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.conf != null && message.hasOwnProperty("conf")) {
                properties.event = 1;
                {
                    let error = $root.chainrpc.ConfDetails.verify(message.conf);
                    if (error)
                        return "conf." + error;
                }
            }
            if (message.reorg != null && message.hasOwnProperty("reorg")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.chainrpc.Reorg.verify(message.reorg);
                    if (error)
                        return "reorg." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ConfEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.ConfEvent} ConfEvent
         */
        ConfEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.ConfEvent)
                return object;
            let message = new $root.chainrpc.ConfEvent();
            if (object.conf != null) {
                if (typeof object.conf !== "object")
                    throw TypeError(".chainrpc.ConfEvent.conf: object expected");
                message.conf = $root.chainrpc.ConfDetails.fromObject(object.conf);
            }
            if (object.reorg != null) {
                if (typeof object.reorg !== "object")
                    throw TypeError(".chainrpc.ConfEvent.reorg: object expected");
                message.reorg = $root.chainrpc.Reorg.fromObject(object.reorg);
            }
            return message;
        };

        /**
         * Creates a plain object from a ConfEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.ConfEvent
         * @static
         * @param {chainrpc.ConfEvent} message ConfEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.conf != null && message.hasOwnProperty("conf")) {
                object.conf = $root.chainrpc.ConfDetails.toObject(message.conf, options);
                if (options.oneofs)
                    object.event = "conf";
            }
            if (message.reorg != null && message.hasOwnProperty("reorg")) {
                object.reorg = $root.chainrpc.Reorg.toObject(message.reorg, options);
                if (options.oneofs)
                    object.event = "reorg";
            }
            return object;
        };

        /**
         * Converts this ConfEvent to JSON.
         * @function toJSON
         * @memberof chainrpc.ConfEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConfEvent;
    })();

    chainrpc.Outpoint = (function() {

        /**
         * Properties of an Outpoint.
         * @memberof chainrpc
         * @interface IOutpoint
         * @property {Uint8Array|null} [hash] Outpoint hash
         * @property {number|null} [index] Outpoint index
         */

        /**
         * Constructs a new Outpoint.
         * @memberof chainrpc
         * @classdesc Represents an Outpoint.
         * @implements IOutpoint
         * @constructor
         * @param {chainrpc.IOutpoint=} [properties] Properties to set
         */
        function Outpoint(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Outpoint hash.
         * @member {Uint8Array} hash
         * @memberof chainrpc.Outpoint
         * @instance
         */
        Outpoint.prototype.hash = $util.newBuffer([]);

        /**
         * Outpoint index.
         * @member {number} index
         * @memberof chainrpc.Outpoint
         * @instance
         */
        Outpoint.prototype.index = 0;

        /**
         * Creates a new Outpoint instance using the specified properties.
         * @function create
         * @memberof chainrpc.Outpoint
         * @static
         * @param {chainrpc.IOutpoint=} [properties] Properties to set
         * @returns {chainrpc.Outpoint} Outpoint instance
         */
        Outpoint.create = function create(properties) {
            return new Outpoint(properties);
        };

        /**
         * Encodes the specified Outpoint message. Does not implicitly {@link chainrpc.Outpoint.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.Outpoint
         * @static
         * @param {chainrpc.IOutpoint} message Outpoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Outpoint.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.hash);
            if (message.index != null && Object.hasOwnProperty.call(message, "index"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.index);
            return writer;
        };

        /**
         * Encodes the specified Outpoint message, length delimited. Does not implicitly {@link chainrpc.Outpoint.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.Outpoint
         * @static
         * @param {chainrpc.IOutpoint} message Outpoint message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Outpoint.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Outpoint message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.Outpoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.Outpoint} Outpoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Outpoint.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.Outpoint();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.index = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Outpoint message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.Outpoint
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.Outpoint} Outpoint
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Outpoint.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Outpoint message.
         * @function verify
         * @memberof chainrpc.Outpoint
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Outpoint.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!(message.hash && typeof message.hash.length === "number" || $util.isString(message.hash)))
                    return "hash: buffer expected";
            if (message.index != null && message.hasOwnProperty("index"))
                if (!$util.isInteger(message.index))
                    return "index: integer expected";
            return null;
        };

        /**
         * Creates an Outpoint message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.Outpoint
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.Outpoint} Outpoint
         */
        Outpoint.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.Outpoint)
                return object;
            let message = new $root.chainrpc.Outpoint();
            if (object.hash != null)
                if (typeof object.hash === "string")
                    $util.base64.decode(object.hash, message.hash = $util.newBuffer($util.base64.length(object.hash)), 0);
                else if (object.hash.length)
                    message.hash = object.hash;
            if (object.index != null)
                message.index = object.index >>> 0;
            return message;
        };

        /**
         * Creates a plain object from an Outpoint message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.Outpoint
         * @static
         * @param {chainrpc.Outpoint} message Outpoint
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Outpoint.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.hash = "";
                else {
                    object.hash = [];
                    if (options.bytes !== Array)
                        object.hash = $util.newBuffer(object.hash);
                }
                object.index = 0;
            }
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = options.bytes === String ? $util.base64.encode(message.hash, 0, message.hash.length) : options.bytes === Array ? Array.prototype.slice.call(message.hash) : message.hash;
            if (message.index != null && message.hasOwnProperty("index"))
                object.index = message.index;
            return object;
        };

        /**
         * Converts this Outpoint to JSON.
         * @function toJSON
         * @memberof chainrpc.Outpoint
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Outpoint.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Outpoint;
    })();

    chainrpc.SpendRequest = (function() {

        /**
         * Properties of a SpendRequest.
         * @memberof chainrpc
         * @interface ISpendRequest
         * @property {chainrpc.IOutpoint|null} [outpoint] SpendRequest outpoint
         * @property {Uint8Array|null} [script] SpendRequest script
         * @property {number|null} [heightHint] SpendRequest heightHint
         */

        /**
         * Constructs a new SpendRequest.
         * @memberof chainrpc
         * @classdesc Represents a SpendRequest.
         * @implements ISpendRequest
         * @constructor
         * @param {chainrpc.ISpendRequest=} [properties] Properties to set
         */
        function SpendRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpendRequest outpoint.
         * @member {chainrpc.IOutpoint|null|undefined} outpoint
         * @memberof chainrpc.SpendRequest
         * @instance
         */
        SpendRequest.prototype.outpoint = null;

        /**
         * SpendRequest script.
         * @member {Uint8Array} script
         * @memberof chainrpc.SpendRequest
         * @instance
         */
        SpendRequest.prototype.script = $util.newBuffer([]);

        /**
         * SpendRequest heightHint.
         * @member {number} heightHint
         * @memberof chainrpc.SpendRequest
         * @instance
         */
        SpendRequest.prototype.heightHint = 0;

        /**
         * Creates a new SpendRequest instance using the specified properties.
         * @function create
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {chainrpc.ISpendRequest=} [properties] Properties to set
         * @returns {chainrpc.SpendRequest} SpendRequest instance
         */
        SpendRequest.create = function create(properties) {
            return new SpendRequest(properties);
        };

        /**
         * Encodes the specified SpendRequest message. Does not implicitly {@link chainrpc.SpendRequest.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {chainrpc.ISpendRequest} message SpendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpendRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.chainrpc.Outpoint.encode(message.outpoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.script != null && Object.hasOwnProperty.call(message, "script"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.script);
            if (message.heightHint != null && Object.hasOwnProperty.call(message, "heightHint"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.heightHint);
            return writer;
        };

        /**
         * Encodes the specified SpendRequest message, length delimited. Does not implicitly {@link chainrpc.SpendRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {chainrpc.ISpendRequest} message SpendRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpendRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpendRequest message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.SpendRequest} SpendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpendRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.SpendRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.outpoint = $root.chainrpc.Outpoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.script = reader.bytes();
                        break;
                    }
                case 3: {
                        message.heightHint = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpendRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.SpendRequest} SpendRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpendRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpendRequest message.
         * @function verify
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpendRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.chainrpc.Outpoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.script != null && message.hasOwnProperty("script"))
                if (!(message.script && typeof message.script.length === "number" || $util.isString(message.script)))
                    return "script: buffer expected";
            if (message.heightHint != null && message.hasOwnProperty("heightHint"))
                if (!$util.isInteger(message.heightHint))
                    return "heightHint: integer expected";
            return null;
        };

        /**
         * Creates a SpendRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.SpendRequest} SpendRequest
         */
        SpendRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.SpendRequest)
                return object;
            let message = new $root.chainrpc.SpendRequest();
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".chainrpc.SpendRequest.outpoint: object expected");
                message.outpoint = $root.chainrpc.Outpoint.fromObject(object.outpoint);
            }
            if (object.script != null)
                if (typeof object.script === "string")
                    $util.base64.decode(object.script, message.script = $util.newBuffer($util.base64.length(object.script)), 0);
                else if (object.script.length)
                    message.script = object.script;
            if (object.heightHint != null)
                message.heightHint = object.heightHint >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a SpendRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.SpendRequest
         * @static
         * @param {chainrpc.SpendRequest} message SpendRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpendRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outpoint = null;
                if (options.bytes === String)
                    object.script = "";
                else {
                    object.script = [];
                    if (options.bytes !== Array)
                        object.script = $util.newBuffer(object.script);
                }
                object.heightHint = 0;
            }
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.chainrpc.Outpoint.toObject(message.outpoint, options);
            if (message.script != null && message.hasOwnProperty("script"))
                object.script = options.bytes === String ? $util.base64.encode(message.script, 0, message.script.length) : options.bytes === Array ? Array.prototype.slice.call(message.script) : message.script;
            if (message.heightHint != null && message.hasOwnProperty("heightHint"))
                object.heightHint = message.heightHint;
            return object;
        };

        /**
         * Converts this SpendRequest to JSON.
         * @function toJSON
         * @memberof chainrpc.SpendRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpendRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SpendRequest;
    })();

    chainrpc.SpendDetails = (function() {

        /**
         * Properties of a SpendDetails.
         * @memberof chainrpc
         * @interface ISpendDetails
         * @property {chainrpc.IOutpoint|null} [spendingOutpoint] SpendDetails spendingOutpoint
         * @property {Uint8Array|null} [rawSpendingTx] SpendDetails rawSpendingTx
         * @property {Uint8Array|null} [spendingTxHash] SpendDetails spendingTxHash
         * @property {number|null} [spendingInputIndex] SpendDetails spendingInputIndex
         * @property {number|null} [spendingHeight] SpendDetails spendingHeight
         */

        /**
         * Constructs a new SpendDetails.
         * @memberof chainrpc
         * @classdesc Represents a SpendDetails.
         * @implements ISpendDetails
         * @constructor
         * @param {chainrpc.ISpendDetails=} [properties] Properties to set
         */
        function SpendDetails(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpendDetails spendingOutpoint.
         * @member {chainrpc.IOutpoint|null|undefined} spendingOutpoint
         * @memberof chainrpc.SpendDetails
         * @instance
         */
        SpendDetails.prototype.spendingOutpoint = null;

        /**
         * SpendDetails rawSpendingTx.
         * @member {Uint8Array} rawSpendingTx
         * @memberof chainrpc.SpendDetails
         * @instance
         */
        SpendDetails.prototype.rawSpendingTx = $util.newBuffer([]);

        /**
         * SpendDetails spendingTxHash.
         * @member {Uint8Array} spendingTxHash
         * @memberof chainrpc.SpendDetails
         * @instance
         */
        SpendDetails.prototype.spendingTxHash = $util.newBuffer([]);

        /**
         * SpendDetails spendingInputIndex.
         * @member {number} spendingInputIndex
         * @memberof chainrpc.SpendDetails
         * @instance
         */
        SpendDetails.prototype.spendingInputIndex = 0;

        /**
         * SpendDetails spendingHeight.
         * @member {number} spendingHeight
         * @memberof chainrpc.SpendDetails
         * @instance
         */
        SpendDetails.prototype.spendingHeight = 0;

        /**
         * Creates a new SpendDetails instance using the specified properties.
         * @function create
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {chainrpc.ISpendDetails=} [properties] Properties to set
         * @returns {chainrpc.SpendDetails} SpendDetails instance
         */
        SpendDetails.create = function create(properties) {
            return new SpendDetails(properties);
        };

        /**
         * Encodes the specified SpendDetails message. Does not implicitly {@link chainrpc.SpendDetails.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {chainrpc.ISpendDetails} message SpendDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpendDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.spendingOutpoint != null && Object.hasOwnProperty.call(message, "spendingOutpoint"))
                $root.chainrpc.Outpoint.encode(message.spendingOutpoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rawSpendingTx != null && Object.hasOwnProperty.call(message, "rawSpendingTx"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rawSpendingTx);
            if (message.spendingTxHash != null && Object.hasOwnProperty.call(message, "spendingTxHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.spendingTxHash);
            if (message.spendingInputIndex != null && Object.hasOwnProperty.call(message, "spendingInputIndex"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.spendingInputIndex);
            if (message.spendingHeight != null && Object.hasOwnProperty.call(message, "spendingHeight"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.spendingHeight);
            return writer;
        };

        /**
         * Encodes the specified SpendDetails message, length delimited. Does not implicitly {@link chainrpc.SpendDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {chainrpc.ISpendDetails} message SpendDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpendDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpendDetails message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.SpendDetails} SpendDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpendDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.SpendDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.spendingOutpoint = $root.chainrpc.Outpoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.rawSpendingTx = reader.bytes();
                        break;
                    }
                case 3: {
                        message.spendingTxHash = reader.bytes();
                        break;
                    }
                case 4: {
                        message.spendingInputIndex = reader.uint32();
                        break;
                    }
                case 5: {
                        message.spendingHeight = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpendDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.SpendDetails} SpendDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpendDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpendDetails message.
         * @function verify
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpendDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.spendingOutpoint != null && message.hasOwnProperty("spendingOutpoint")) {
                let error = $root.chainrpc.Outpoint.verify(message.spendingOutpoint);
                if (error)
                    return "spendingOutpoint." + error;
            }
            if (message.rawSpendingTx != null && message.hasOwnProperty("rawSpendingTx"))
                if (!(message.rawSpendingTx && typeof message.rawSpendingTx.length === "number" || $util.isString(message.rawSpendingTx)))
                    return "rawSpendingTx: buffer expected";
            if (message.spendingTxHash != null && message.hasOwnProperty("spendingTxHash"))
                if (!(message.spendingTxHash && typeof message.spendingTxHash.length === "number" || $util.isString(message.spendingTxHash)))
                    return "spendingTxHash: buffer expected";
            if (message.spendingInputIndex != null && message.hasOwnProperty("spendingInputIndex"))
                if (!$util.isInteger(message.spendingInputIndex))
                    return "spendingInputIndex: integer expected";
            if (message.spendingHeight != null && message.hasOwnProperty("spendingHeight"))
                if (!$util.isInteger(message.spendingHeight))
                    return "spendingHeight: integer expected";
            return null;
        };

        /**
         * Creates a SpendDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.SpendDetails} SpendDetails
         */
        SpendDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.SpendDetails)
                return object;
            let message = new $root.chainrpc.SpendDetails();
            if (object.spendingOutpoint != null) {
                if (typeof object.spendingOutpoint !== "object")
                    throw TypeError(".chainrpc.SpendDetails.spendingOutpoint: object expected");
                message.spendingOutpoint = $root.chainrpc.Outpoint.fromObject(object.spendingOutpoint);
            }
            if (object.rawSpendingTx != null)
                if (typeof object.rawSpendingTx === "string")
                    $util.base64.decode(object.rawSpendingTx, message.rawSpendingTx = $util.newBuffer($util.base64.length(object.rawSpendingTx)), 0);
                else if (object.rawSpendingTx.length)
                    message.rawSpendingTx = object.rawSpendingTx;
            if (object.spendingTxHash != null)
                if (typeof object.spendingTxHash === "string")
                    $util.base64.decode(object.spendingTxHash, message.spendingTxHash = $util.newBuffer($util.base64.length(object.spendingTxHash)), 0);
                else if (object.spendingTxHash.length)
                    message.spendingTxHash = object.spendingTxHash;
            if (object.spendingInputIndex != null)
                message.spendingInputIndex = object.spendingInputIndex >>> 0;
            if (object.spendingHeight != null)
                message.spendingHeight = object.spendingHeight >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a SpendDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.SpendDetails
         * @static
         * @param {chainrpc.SpendDetails} message SpendDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpendDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.spendingOutpoint = null;
                if (options.bytes === String)
                    object.rawSpendingTx = "";
                else {
                    object.rawSpendingTx = [];
                    if (options.bytes !== Array)
                        object.rawSpendingTx = $util.newBuffer(object.rawSpendingTx);
                }
                if (options.bytes === String)
                    object.spendingTxHash = "";
                else {
                    object.spendingTxHash = [];
                    if (options.bytes !== Array)
                        object.spendingTxHash = $util.newBuffer(object.spendingTxHash);
                }
                object.spendingInputIndex = 0;
                object.spendingHeight = 0;
            }
            if (message.spendingOutpoint != null && message.hasOwnProperty("spendingOutpoint"))
                object.spendingOutpoint = $root.chainrpc.Outpoint.toObject(message.spendingOutpoint, options);
            if (message.rawSpendingTx != null && message.hasOwnProperty("rawSpendingTx"))
                object.rawSpendingTx = options.bytes === String ? $util.base64.encode(message.rawSpendingTx, 0, message.rawSpendingTx.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawSpendingTx) : message.rawSpendingTx;
            if (message.spendingTxHash != null && message.hasOwnProperty("spendingTxHash"))
                object.spendingTxHash = options.bytes === String ? $util.base64.encode(message.spendingTxHash, 0, message.spendingTxHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.spendingTxHash) : message.spendingTxHash;
            if (message.spendingInputIndex != null && message.hasOwnProperty("spendingInputIndex"))
                object.spendingInputIndex = message.spendingInputIndex;
            if (message.spendingHeight != null && message.hasOwnProperty("spendingHeight"))
                object.spendingHeight = message.spendingHeight;
            return object;
        };

        /**
         * Converts this SpendDetails to JSON.
         * @function toJSON
         * @memberof chainrpc.SpendDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpendDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SpendDetails;
    })();

    chainrpc.SpendEvent = (function() {

        /**
         * Properties of a SpendEvent.
         * @memberof chainrpc
         * @interface ISpendEvent
         * @property {chainrpc.ISpendDetails|null} [spend] SpendEvent spend
         * @property {chainrpc.IReorg|null} [reorg] SpendEvent reorg
         */

        /**
         * Constructs a new SpendEvent.
         * @memberof chainrpc
         * @classdesc Represents a SpendEvent.
         * @implements ISpendEvent
         * @constructor
         * @param {chainrpc.ISpendEvent=} [properties] Properties to set
         */
        function SpendEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SpendEvent spend.
         * @member {chainrpc.ISpendDetails|null|undefined} spend
         * @memberof chainrpc.SpendEvent
         * @instance
         */
        SpendEvent.prototype.spend = null;

        /**
         * SpendEvent reorg.
         * @member {chainrpc.IReorg|null|undefined} reorg
         * @memberof chainrpc.SpendEvent
         * @instance
         */
        SpendEvent.prototype.reorg = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SpendEvent event.
         * @member {"spend"|"reorg"|undefined} event
         * @memberof chainrpc.SpendEvent
         * @instance
         */
        Object.defineProperty(SpendEvent.prototype, "event", {
            get: $util.oneOfGetter($oneOfFields = ["spend", "reorg"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SpendEvent instance using the specified properties.
         * @function create
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {chainrpc.ISpendEvent=} [properties] Properties to set
         * @returns {chainrpc.SpendEvent} SpendEvent instance
         */
        SpendEvent.create = function create(properties) {
            return new SpendEvent(properties);
        };

        /**
         * Encodes the specified SpendEvent message. Does not implicitly {@link chainrpc.SpendEvent.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {chainrpc.ISpendEvent} message SpendEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpendEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.spend != null && Object.hasOwnProperty.call(message, "spend"))
                $root.chainrpc.SpendDetails.encode(message.spend, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.reorg != null && Object.hasOwnProperty.call(message, "reorg"))
                $root.chainrpc.Reorg.encode(message.reorg, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SpendEvent message, length delimited. Does not implicitly {@link chainrpc.SpendEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {chainrpc.ISpendEvent} message SpendEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SpendEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SpendEvent message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.SpendEvent} SpendEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpendEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.SpendEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.spend = $root.chainrpc.SpendDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.reorg = $root.chainrpc.Reorg.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SpendEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.SpendEvent} SpendEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SpendEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SpendEvent message.
         * @function verify
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SpendEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.spend != null && message.hasOwnProperty("spend")) {
                properties.event = 1;
                {
                    let error = $root.chainrpc.SpendDetails.verify(message.spend);
                    if (error)
                        return "spend." + error;
                }
            }
            if (message.reorg != null && message.hasOwnProperty("reorg")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.chainrpc.Reorg.verify(message.reorg);
                    if (error)
                        return "reorg." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SpendEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.SpendEvent} SpendEvent
         */
        SpendEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.SpendEvent)
                return object;
            let message = new $root.chainrpc.SpendEvent();
            if (object.spend != null) {
                if (typeof object.spend !== "object")
                    throw TypeError(".chainrpc.SpendEvent.spend: object expected");
                message.spend = $root.chainrpc.SpendDetails.fromObject(object.spend);
            }
            if (object.reorg != null) {
                if (typeof object.reorg !== "object")
                    throw TypeError(".chainrpc.SpendEvent.reorg: object expected");
                message.reorg = $root.chainrpc.Reorg.fromObject(object.reorg);
            }
            return message;
        };

        /**
         * Creates a plain object from a SpendEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.SpendEvent
         * @static
         * @param {chainrpc.SpendEvent} message SpendEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SpendEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.spend != null && message.hasOwnProperty("spend")) {
                object.spend = $root.chainrpc.SpendDetails.toObject(message.spend, options);
                if (options.oneofs)
                    object.event = "spend";
            }
            if (message.reorg != null && message.hasOwnProperty("reorg")) {
                object.reorg = $root.chainrpc.Reorg.toObject(message.reorg, options);
                if (options.oneofs)
                    object.event = "reorg";
            }
            return object;
        };

        /**
         * Converts this SpendEvent to JSON.
         * @function toJSON
         * @memberof chainrpc.SpendEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SpendEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SpendEvent;
    })();

    chainrpc.BlockEpoch = (function() {

        /**
         * Properties of a BlockEpoch.
         * @memberof chainrpc
         * @interface IBlockEpoch
         * @property {Uint8Array|null} [hash] BlockEpoch hash
         * @property {number|null} [height] BlockEpoch height
         */

        /**
         * Constructs a new BlockEpoch.
         * @memberof chainrpc
         * @classdesc Represents a BlockEpoch.
         * @implements IBlockEpoch
         * @constructor
         * @param {chainrpc.IBlockEpoch=} [properties] Properties to set
         */
        function BlockEpoch(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockEpoch hash.
         * @member {Uint8Array} hash
         * @memberof chainrpc.BlockEpoch
         * @instance
         */
        BlockEpoch.prototype.hash = $util.newBuffer([]);

        /**
         * BlockEpoch height.
         * @member {number} height
         * @memberof chainrpc.BlockEpoch
         * @instance
         */
        BlockEpoch.prototype.height = 0;

        /**
         * Creates a new BlockEpoch instance using the specified properties.
         * @function create
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {chainrpc.IBlockEpoch=} [properties] Properties to set
         * @returns {chainrpc.BlockEpoch} BlockEpoch instance
         */
        BlockEpoch.create = function create(properties) {
            return new BlockEpoch(properties);
        };

        /**
         * Encodes the specified BlockEpoch message. Does not implicitly {@link chainrpc.BlockEpoch.verify|verify} messages.
         * @function encode
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {chainrpc.IBlockEpoch} message BlockEpoch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockEpoch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.hash);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.height);
            return writer;
        };

        /**
         * Encodes the specified BlockEpoch message, length delimited. Does not implicitly {@link chainrpc.BlockEpoch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {chainrpc.IBlockEpoch} message BlockEpoch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockEpoch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BlockEpoch message from the specified reader or buffer.
         * @function decode
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {chainrpc.BlockEpoch} BlockEpoch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockEpoch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.chainrpc.BlockEpoch();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.height = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BlockEpoch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {chainrpc.BlockEpoch} BlockEpoch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockEpoch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BlockEpoch message.
         * @function verify
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BlockEpoch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!(message.hash && typeof message.hash.length === "number" || $util.isString(message.hash)))
                    return "hash: buffer expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a BlockEpoch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {chainrpc.BlockEpoch} BlockEpoch
         */
        BlockEpoch.fromObject = function fromObject(object) {
            if (object instanceof $root.chainrpc.BlockEpoch)
                return object;
            let message = new $root.chainrpc.BlockEpoch();
            if (object.hash != null)
                if (typeof object.hash === "string")
                    $util.base64.decode(object.hash, message.hash = $util.newBuffer($util.base64.length(object.hash)), 0);
                else if (object.hash.length)
                    message.hash = object.hash;
            if (object.height != null)
                message.height = object.height >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a BlockEpoch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof chainrpc.BlockEpoch
         * @static
         * @param {chainrpc.BlockEpoch} message BlockEpoch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BlockEpoch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.hash = "";
                else {
                    object.hash = [];
                    if (options.bytes !== Array)
                        object.hash = $util.newBuffer(object.hash);
                }
                object.height = 0;
            }
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = options.bytes === String ? $util.base64.encode(message.hash, 0, message.hash.length) : options.bytes === Array ? Array.prototype.slice.call(message.hash) : message.hash;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this BlockEpoch to JSON.
         * @function toJSON
         * @memberof chainrpc.BlockEpoch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BlockEpoch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BlockEpoch;
    })();

    return chainrpc;
})();

export const devrpc = $root.devrpc = (() => {

    /**
     * Namespace devrpc.
     * @exports devrpc
     * @namespace
     */
    const devrpc = {};

    devrpc.Dev = (function() {

        /**
         * Constructs a new Dev service.
         * @memberof devrpc
         * @classdesc Represents a Dev
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Dev(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Dev.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Dev;

        /**
         * Creates new Dev service using the specified rpc implementation.
         * @function create
         * @memberof devrpc.Dev
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Dev} RPC service. Useful where requests and/or responses are streamed.
         */
        Dev.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link devrpc.Dev#importGraph}.
         * @memberof devrpc.Dev
         * @typedef ImportGraphCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {devrpc.ImportGraphResponse} [response] ImportGraphResponse
         */

        /**
         * Calls ImportGraph.
         * @function importGraph
         * @memberof devrpc.Dev
         * @instance
         * @param {lnrpc.IChannelGraph} request ChannelGraph message or plain object
         * @param {devrpc.Dev.ImportGraphCallback} callback Node-style callback called with the error, if any, and ImportGraphResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Dev.prototype.importGraph = function importGraph(request, callback) {
            return this.rpcCall(importGraph, $root.lnrpc.ChannelGraph, $root.devrpc.ImportGraphResponse, request, callback);
        }, "name", { value: "ImportGraph" });

        /**
         * Calls ImportGraph.
         * @function importGraph
         * @memberof devrpc.Dev
         * @instance
         * @param {lnrpc.IChannelGraph} request ChannelGraph message or plain object
         * @returns {Promise<devrpc.ImportGraphResponse>} Promise
         * @variation 2
         */

        return Dev;
    })();

    devrpc.ImportGraphResponse = (function() {

        /**
         * Properties of an ImportGraphResponse.
         * @memberof devrpc
         * @interface IImportGraphResponse
         */

        /**
         * Constructs a new ImportGraphResponse.
         * @memberof devrpc
         * @classdesc Represents an ImportGraphResponse.
         * @implements IImportGraphResponse
         * @constructor
         * @param {devrpc.IImportGraphResponse=} [properties] Properties to set
         */
        function ImportGraphResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ImportGraphResponse instance using the specified properties.
         * @function create
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {devrpc.IImportGraphResponse=} [properties] Properties to set
         * @returns {devrpc.ImportGraphResponse} ImportGraphResponse instance
         */
        ImportGraphResponse.create = function create(properties) {
            return new ImportGraphResponse(properties);
        };

        /**
         * Encodes the specified ImportGraphResponse message. Does not implicitly {@link devrpc.ImportGraphResponse.verify|verify} messages.
         * @function encode
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {devrpc.IImportGraphResponse} message ImportGraphResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportGraphResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ImportGraphResponse message, length delimited. Does not implicitly {@link devrpc.ImportGraphResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {devrpc.IImportGraphResponse} message ImportGraphResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportGraphResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportGraphResponse message from the specified reader or buffer.
         * @function decode
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {devrpc.ImportGraphResponse} ImportGraphResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportGraphResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.devrpc.ImportGraphResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportGraphResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {devrpc.ImportGraphResponse} ImportGraphResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportGraphResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportGraphResponse message.
         * @function verify
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportGraphResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ImportGraphResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {devrpc.ImportGraphResponse} ImportGraphResponse
         */
        ImportGraphResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.devrpc.ImportGraphResponse)
                return object;
            return new $root.devrpc.ImportGraphResponse();
        };

        /**
         * Creates a plain object from an ImportGraphResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof devrpc.ImportGraphResponse
         * @static
         * @param {devrpc.ImportGraphResponse} message ImportGraphResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportGraphResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ImportGraphResponse to JSON.
         * @function toJSON
         * @memberof devrpc.ImportGraphResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportGraphResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportGraphResponse;
    })();

    return devrpc;
})();

export const invoicesrpc = $root.invoicesrpc = (() => {

    /**
     * Namespace invoicesrpc.
     * @exports invoicesrpc
     * @namespace
     */
    const invoicesrpc = {};

    invoicesrpc.Invoices = (function() {

        /**
         * Constructs a new Invoices service.
         * @memberof invoicesrpc
         * @classdesc Represents an Invoices
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Invoices(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Invoices.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Invoices;

        /**
         * Creates new Invoices service using the specified rpc implementation.
         * @function create
         * @memberof invoicesrpc.Invoices
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Invoices} RPC service. Useful where requests and/or responses are streamed.
         */
        Invoices.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link invoicesrpc.Invoices#subscribeSingleInvoice}.
         * @memberof invoicesrpc.Invoices
         * @typedef SubscribeSingleInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Invoice} [response] Invoice
         */

        /**
         * Calls SubscribeSingleInvoice.
         * @function subscribeSingleInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ISubscribeSingleInvoiceRequest} request SubscribeSingleInvoiceRequest message or plain object
         * @param {invoicesrpc.Invoices.SubscribeSingleInvoiceCallback} callback Node-style callback called with the error, if any, and Invoice
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Invoices.prototype.subscribeSingleInvoice = function subscribeSingleInvoice(request, callback) {
            return this.rpcCall(subscribeSingleInvoice, $root.invoicesrpc.SubscribeSingleInvoiceRequest, $root.lnrpc.Invoice, request, callback);
        }, "name", { value: "SubscribeSingleInvoice" });

        /**
         * Calls SubscribeSingleInvoice.
         * @function subscribeSingleInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ISubscribeSingleInvoiceRequest} request SubscribeSingleInvoiceRequest message or plain object
         * @returns {Promise<lnrpc.Invoice>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link invoicesrpc.Invoices#cancelInvoice}.
         * @memberof invoicesrpc.Invoices
         * @typedef CancelInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {invoicesrpc.CancelInvoiceResp} [response] CancelInvoiceResp
         */

        /**
         * Calls CancelInvoice.
         * @function cancelInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ICancelInvoiceMsg} request CancelInvoiceMsg message or plain object
         * @param {invoicesrpc.Invoices.CancelInvoiceCallback} callback Node-style callback called with the error, if any, and CancelInvoiceResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Invoices.prototype.cancelInvoice = function cancelInvoice(request, callback) {
            return this.rpcCall(cancelInvoice, $root.invoicesrpc.CancelInvoiceMsg, $root.invoicesrpc.CancelInvoiceResp, request, callback);
        }, "name", { value: "CancelInvoice" });

        /**
         * Calls CancelInvoice.
         * @function cancelInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ICancelInvoiceMsg} request CancelInvoiceMsg message or plain object
         * @returns {Promise<invoicesrpc.CancelInvoiceResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link invoicesrpc.Invoices#addHoldInvoice}.
         * @memberof invoicesrpc.Invoices
         * @typedef AddHoldInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {invoicesrpc.AddHoldInvoiceResp} [response] AddHoldInvoiceResp
         */

        /**
         * Calls AddHoldInvoice.
         * @function addHoldInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.IAddHoldInvoiceRequest} request AddHoldInvoiceRequest message or plain object
         * @param {invoicesrpc.Invoices.AddHoldInvoiceCallback} callback Node-style callback called with the error, if any, and AddHoldInvoiceResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Invoices.prototype.addHoldInvoice = function addHoldInvoice(request, callback) {
            return this.rpcCall(addHoldInvoice, $root.invoicesrpc.AddHoldInvoiceRequest, $root.invoicesrpc.AddHoldInvoiceResp, request, callback);
        }, "name", { value: "AddHoldInvoice" });

        /**
         * Calls AddHoldInvoice.
         * @function addHoldInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.IAddHoldInvoiceRequest} request AddHoldInvoiceRequest message or plain object
         * @returns {Promise<invoicesrpc.AddHoldInvoiceResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link invoicesrpc.Invoices#settleInvoice}.
         * @memberof invoicesrpc.Invoices
         * @typedef SettleInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {invoicesrpc.SettleInvoiceResp} [response] SettleInvoiceResp
         */

        /**
         * Calls SettleInvoice.
         * @function settleInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ISettleInvoiceMsg} request SettleInvoiceMsg message or plain object
         * @param {invoicesrpc.Invoices.SettleInvoiceCallback} callback Node-style callback called with the error, if any, and SettleInvoiceResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Invoices.prototype.settleInvoice = function settleInvoice(request, callback) {
            return this.rpcCall(settleInvoice, $root.invoicesrpc.SettleInvoiceMsg, $root.invoicesrpc.SettleInvoiceResp, request, callback);
        }, "name", { value: "SettleInvoice" });

        /**
         * Calls SettleInvoice.
         * @function settleInvoice
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ISettleInvoiceMsg} request SettleInvoiceMsg message or plain object
         * @returns {Promise<invoicesrpc.SettleInvoiceResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link invoicesrpc.Invoices#lookupInvoiceV2}.
         * @memberof invoicesrpc.Invoices
         * @typedef LookupInvoiceV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Invoice} [response] Invoice
         */

        /**
         * Calls LookupInvoiceV2.
         * @function lookupInvoiceV2
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ILookupInvoiceMsg} request LookupInvoiceMsg message or plain object
         * @param {invoicesrpc.Invoices.LookupInvoiceV2Callback} callback Node-style callback called with the error, if any, and Invoice
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Invoices.prototype.lookupInvoiceV2 = function lookupInvoiceV2(request, callback) {
            return this.rpcCall(lookupInvoiceV2, $root.invoicesrpc.LookupInvoiceMsg, $root.lnrpc.Invoice, request, callback);
        }, "name", { value: "LookupInvoiceV2" });

        /**
         * Calls LookupInvoiceV2.
         * @function lookupInvoiceV2
         * @memberof invoicesrpc.Invoices
         * @instance
         * @param {invoicesrpc.ILookupInvoiceMsg} request LookupInvoiceMsg message or plain object
         * @returns {Promise<lnrpc.Invoice>} Promise
         * @variation 2
         */

        return Invoices;
    })();

    invoicesrpc.CancelInvoiceMsg = (function() {

        /**
         * Properties of a CancelInvoiceMsg.
         * @memberof invoicesrpc
         * @interface ICancelInvoiceMsg
         * @property {Uint8Array|null} [paymentHash] CancelInvoiceMsg paymentHash
         */

        /**
         * Constructs a new CancelInvoiceMsg.
         * @memberof invoicesrpc
         * @classdesc Represents a CancelInvoiceMsg.
         * @implements ICancelInvoiceMsg
         * @constructor
         * @param {invoicesrpc.ICancelInvoiceMsg=} [properties] Properties to set
         */
        function CancelInvoiceMsg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CancelInvoiceMsg paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @instance
         */
        CancelInvoiceMsg.prototype.paymentHash = $util.newBuffer([]);

        /**
         * Creates a new CancelInvoiceMsg instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {invoicesrpc.ICancelInvoiceMsg=} [properties] Properties to set
         * @returns {invoicesrpc.CancelInvoiceMsg} CancelInvoiceMsg instance
         */
        CancelInvoiceMsg.create = function create(properties) {
            return new CancelInvoiceMsg(properties);
        };

        /**
         * Encodes the specified CancelInvoiceMsg message. Does not implicitly {@link invoicesrpc.CancelInvoiceMsg.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {invoicesrpc.ICancelInvoiceMsg} message CancelInvoiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CancelInvoiceMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.paymentHash);
            return writer;
        };

        /**
         * Encodes the specified CancelInvoiceMsg message, length delimited. Does not implicitly {@link invoicesrpc.CancelInvoiceMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {invoicesrpc.ICancelInvoiceMsg} message CancelInvoiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CancelInvoiceMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CancelInvoiceMsg message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.CancelInvoiceMsg} CancelInvoiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CancelInvoiceMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.CancelInvoiceMsg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CancelInvoiceMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.CancelInvoiceMsg} CancelInvoiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CancelInvoiceMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CancelInvoiceMsg message.
         * @function verify
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CancelInvoiceMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            return null;
        };

        /**
         * Creates a CancelInvoiceMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.CancelInvoiceMsg} CancelInvoiceMsg
         */
        CancelInvoiceMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.CancelInvoiceMsg)
                return object;
            let message = new $root.invoicesrpc.CancelInvoiceMsg();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            return message;
        };

        /**
         * Creates a plain object from a CancelInvoiceMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @static
         * @param {invoicesrpc.CancelInvoiceMsg} message CancelInvoiceMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CancelInvoiceMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            return object;
        };

        /**
         * Converts this CancelInvoiceMsg to JSON.
         * @function toJSON
         * @memberof invoicesrpc.CancelInvoiceMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CancelInvoiceMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CancelInvoiceMsg;
    })();

    invoicesrpc.CancelInvoiceResp = (function() {

        /**
         * Properties of a CancelInvoiceResp.
         * @memberof invoicesrpc
         * @interface ICancelInvoiceResp
         */

        /**
         * Constructs a new CancelInvoiceResp.
         * @memberof invoicesrpc
         * @classdesc Represents a CancelInvoiceResp.
         * @implements ICancelInvoiceResp
         * @constructor
         * @param {invoicesrpc.ICancelInvoiceResp=} [properties] Properties to set
         */
        function CancelInvoiceResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CancelInvoiceResp instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {invoicesrpc.ICancelInvoiceResp=} [properties] Properties to set
         * @returns {invoicesrpc.CancelInvoiceResp} CancelInvoiceResp instance
         */
        CancelInvoiceResp.create = function create(properties) {
            return new CancelInvoiceResp(properties);
        };

        /**
         * Encodes the specified CancelInvoiceResp message. Does not implicitly {@link invoicesrpc.CancelInvoiceResp.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {invoicesrpc.ICancelInvoiceResp} message CancelInvoiceResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CancelInvoiceResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CancelInvoiceResp message, length delimited. Does not implicitly {@link invoicesrpc.CancelInvoiceResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {invoicesrpc.ICancelInvoiceResp} message CancelInvoiceResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CancelInvoiceResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CancelInvoiceResp message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.CancelInvoiceResp} CancelInvoiceResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CancelInvoiceResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.CancelInvoiceResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CancelInvoiceResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.CancelInvoiceResp} CancelInvoiceResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CancelInvoiceResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CancelInvoiceResp message.
         * @function verify
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CancelInvoiceResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CancelInvoiceResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.CancelInvoiceResp} CancelInvoiceResp
         */
        CancelInvoiceResp.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.CancelInvoiceResp)
                return object;
            return new $root.invoicesrpc.CancelInvoiceResp();
        };

        /**
         * Creates a plain object from a CancelInvoiceResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.CancelInvoiceResp
         * @static
         * @param {invoicesrpc.CancelInvoiceResp} message CancelInvoiceResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CancelInvoiceResp.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CancelInvoiceResp to JSON.
         * @function toJSON
         * @memberof invoicesrpc.CancelInvoiceResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CancelInvoiceResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CancelInvoiceResp;
    })();

    invoicesrpc.AddHoldInvoiceRequest = (function() {

        /**
         * Properties of an AddHoldInvoiceRequest.
         * @memberof invoicesrpc
         * @interface IAddHoldInvoiceRequest
         * @property {string|null} [memo] AddHoldInvoiceRequest memo
         * @property {Uint8Array|null} [hash] AddHoldInvoiceRequest hash
         * @property {Long|null} [value] AddHoldInvoiceRequest value
         * @property {Long|null} [valueMsat] AddHoldInvoiceRequest valueMsat
         * @property {Uint8Array|null} [descriptionHash] AddHoldInvoiceRequest descriptionHash
         * @property {Long|null} [expiry] AddHoldInvoiceRequest expiry
         * @property {string|null} [fallbackAddr] AddHoldInvoiceRequest fallbackAddr
         * @property {Long|null} [cltvExpiry] AddHoldInvoiceRequest cltvExpiry
         * @property {Array.<lnrpc.IRouteHint>|null} [routeHints] AddHoldInvoiceRequest routeHints
         * @property {boolean|null} ["private"] AddHoldInvoiceRequest private
         */

        /**
         * Constructs a new AddHoldInvoiceRequest.
         * @memberof invoicesrpc
         * @classdesc Represents an AddHoldInvoiceRequest.
         * @implements IAddHoldInvoiceRequest
         * @constructor
         * @param {invoicesrpc.IAddHoldInvoiceRequest=} [properties] Properties to set
         */
        function AddHoldInvoiceRequest(properties) {
            this.routeHints = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddHoldInvoiceRequest memo.
         * @member {string} memo
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.memo = "";

        /**
         * AddHoldInvoiceRequest hash.
         * @member {Uint8Array} hash
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.hash = $util.newBuffer([]);

        /**
         * AddHoldInvoiceRequest value.
         * @member {Long} value
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AddHoldInvoiceRequest valueMsat.
         * @member {Long} valueMsat
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.valueMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AddHoldInvoiceRequest descriptionHash.
         * @member {Uint8Array} descriptionHash
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.descriptionHash = $util.newBuffer([]);

        /**
         * AddHoldInvoiceRequest expiry.
         * @member {Long} expiry
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.expiry = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * AddHoldInvoiceRequest fallbackAddr.
         * @member {string} fallbackAddr
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.fallbackAddr = "";

        /**
         * AddHoldInvoiceRequest cltvExpiry.
         * @member {Long} cltvExpiry
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.cltvExpiry = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AddHoldInvoiceRequest routeHints.
         * @member {Array.<lnrpc.IRouteHint>} routeHints
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype.routeHints = $util.emptyArray;

        /**
         * AddHoldInvoiceRequest private.
         * @member {boolean} private
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         */
        AddHoldInvoiceRequest.prototype["private"] = false;

        /**
         * Creates a new AddHoldInvoiceRequest instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {invoicesrpc.IAddHoldInvoiceRequest=} [properties] Properties to set
         * @returns {invoicesrpc.AddHoldInvoiceRequest} AddHoldInvoiceRequest instance
         */
        AddHoldInvoiceRequest.create = function create(properties) {
            return new AddHoldInvoiceRequest(properties);
        };

        /**
         * Encodes the specified AddHoldInvoiceRequest message. Does not implicitly {@link invoicesrpc.AddHoldInvoiceRequest.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {invoicesrpc.IAddHoldInvoiceRequest} message AddHoldInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddHoldInvoiceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.memo != null && Object.hasOwnProperty.call(message, "memo"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.memo);
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.hash);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.value);
            if (message.descriptionHash != null && Object.hasOwnProperty.call(message, "descriptionHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.descriptionHash);
            if (message.expiry != null && Object.hasOwnProperty.call(message, "expiry"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.expiry);
            if (message.fallbackAddr != null && Object.hasOwnProperty.call(message, "fallbackAddr"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.fallbackAddr);
            if (message.cltvExpiry != null && Object.hasOwnProperty.call(message, "cltvExpiry"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.cltvExpiry);
            if (message.routeHints != null && message.routeHints.length)
                for (let i = 0; i < message.routeHints.length; ++i)
                    $root.lnrpc.RouteHint.encode(message.routeHints[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message["private"] != null && Object.hasOwnProperty.call(message, "private"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message["private"]);
            if (message.valueMsat != null && Object.hasOwnProperty.call(message, "valueMsat"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.valueMsat);
            return writer;
        };

        /**
         * Encodes the specified AddHoldInvoiceRequest message, length delimited. Does not implicitly {@link invoicesrpc.AddHoldInvoiceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {invoicesrpc.IAddHoldInvoiceRequest} message AddHoldInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddHoldInvoiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddHoldInvoiceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.AddHoldInvoiceRequest} AddHoldInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddHoldInvoiceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.AddHoldInvoiceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.memo = reader.string();
                        break;
                    }
                case 2: {
                        message.hash = reader.bytes();
                        break;
                    }
                case 3: {
                        message.value = reader.int64();
                        break;
                    }
                case 10: {
                        message.valueMsat = reader.int64();
                        break;
                    }
                case 4: {
                        message.descriptionHash = reader.bytes();
                        break;
                    }
                case 5: {
                        message.expiry = reader.int64();
                        break;
                    }
                case 6: {
                        message.fallbackAddr = reader.string();
                        break;
                    }
                case 7: {
                        message.cltvExpiry = reader.uint64();
                        break;
                    }
                case 8: {
                        if (!(message.routeHints && message.routeHints.length))
                            message.routeHints = [];
                        message.routeHints.push($root.lnrpc.RouteHint.decode(reader, reader.uint32()));
                        break;
                    }
                case 9: {
                        message["private"] = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddHoldInvoiceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.AddHoldInvoiceRequest} AddHoldInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddHoldInvoiceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddHoldInvoiceRequest message.
         * @function verify
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddHoldInvoiceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.memo != null && message.hasOwnProperty("memo"))
                if (!$util.isString(message.memo))
                    return "memo: string expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!(message.hash && typeof message.hash.length === "number" || $util.isString(message.hash)))
                    return "hash: buffer expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                    return "value: integer|Long expected";
            if (message.valueMsat != null && message.hasOwnProperty("valueMsat"))
                if (!$util.isInteger(message.valueMsat) && !(message.valueMsat && $util.isInteger(message.valueMsat.low) && $util.isInteger(message.valueMsat.high)))
                    return "valueMsat: integer|Long expected";
            if (message.descriptionHash != null && message.hasOwnProperty("descriptionHash"))
                if (!(message.descriptionHash && typeof message.descriptionHash.length === "number" || $util.isString(message.descriptionHash)))
                    return "descriptionHash: buffer expected";
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (!$util.isInteger(message.expiry) && !(message.expiry && $util.isInteger(message.expiry.low) && $util.isInteger(message.expiry.high)))
                    return "expiry: integer|Long expected";
            if (message.fallbackAddr != null && message.hasOwnProperty("fallbackAddr"))
                if (!$util.isString(message.fallbackAddr))
                    return "fallbackAddr: string expected";
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (!$util.isInteger(message.cltvExpiry) && !(message.cltvExpiry && $util.isInteger(message.cltvExpiry.low) && $util.isInteger(message.cltvExpiry.high)))
                    return "cltvExpiry: integer|Long expected";
            if (message.routeHints != null && message.hasOwnProperty("routeHints")) {
                if (!Array.isArray(message.routeHints))
                    return "routeHints: array expected";
                for (let i = 0; i < message.routeHints.length; ++i) {
                    let error = $root.lnrpc.RouteHint.verify(message.routeHints[i]);
                    if (error)
                        return "routeHints." + error;
                }
            }
            if (message["private"] != null && message.hasOwnProperty("private"))
                if (typeof message["private"] !== "boolean")
                    return "private: boolean expected";
            return null;
        };

        /**
         * Creates an AddHoldInvoiceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.AddHoldInvoiceRequest} AddHoldInvoiceRequest
         */
        AddHoldInvoiceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.AddHoldInvoiceRequest)
                return object;
            let message = new $root.invoicesrpc.AddHoldInvoiceRequest();
            if (object.memo != null)
                message.memo = String(object.memo);
            if (object.hash != null)
                if (typeof object.hash === "string")
                    $util.base64.decode(object.hash, message.hash = $util.newBuffer($util.base64.length(object.hash)), 0);
                else if (object.hash.length)
                    message.hash = object.hash;
            if (object.value != null)
                if ($util.Long)
                    (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                else if (typeof object.value === "string")
                    message.value = parseInt(object.value, 10);
                else if (typeof object.value === "number")
                    message.value = object.value;
                else if (typeof object.value === "object")
                    message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
            if (object.valueMsat != null)
                if ($util.Long)
                    (message.valueMsat = $util.Long.fromValue(object.valueMsat)).unsigned = false;
                else if (typeof object.valueMsat === "string")
                    message.valueMsat = parseInt(object.valueMsat, 10);
                else if (typeof object.valueMsat === "number")
                    message.valueMsat = object.valueMsat;
                else if (typeof object.valueMsat === "object")
                    message.valueMsat = new $util.LongBits(object.valueMsat.low >>> 0, object.valueMsat.high >>> 0).toNumber();
            if (object.descriptionHash != null)
                if (typeof object.descriptionHash === "string")
                    $util.base64.decode(object.descriptionHash, message.descriptionHash = $util.newBuffer($util.base64.length(object.descriptionHash)), 0);
                else if (object.descriptionHash.length)
                    message.descriptionHash = object.descriptionHash;
            if (object.expiry != null)
                if ($util.Long)
                    (message.expiry = $util.Long.fromValue(object.expiry)).unsigned = false;
                else if (typeof object.expiry === "string")
                    message.expiry = parseInt(object.expiry, 10);
                else if (typeof object.expiry === "number")
                    message.expiry = object.expiry;
                else if (typeof object.expiry === "object")
                    message.expiry = new $util.LongBits(object.expiry.low >>> 0, object.expiry.high >>> 0).toNumber();
            if (object.fallbackAddr != null)
                message.fallbackAddr = String(object.fallbackAddr);
            if (object.cltvExpiry != null)
                if ($util.Long)
                    (message.cltvExpiry = $util.Long.fromValue(object.cltvExpiry)).unsigned = true;
                else if (typeof object.cltvExpiry === "string")
                    message.cltvExpiry = parseInt(object.cltvExpiry, 10);
                else if (typeof object.cltvExpiry === "number")
                    message.cltvExpiry = object.cltvExpiry;
                else if (typeof object.cltvExpiry === "object")
                    message.cltvExpiry = new $util.LongBits(object.cltvExpiry.low >>> 0, object.cltvExpiry.high >>> 0).toNumber(true);
            if (object.routeHints) {
                if (!Array.isArray(object.routeHints))
                    throw TypeError(".invoicesrpc.AddHoldInvoiceRequest.routeHints: array expected");
                message.routeHints = [];
                for (let i = 0; i < object.routeHints.length; ++i) {
                    if (typeof object.routeHints[i] !== "object")
                        throw TypeError(".invoicesrpc.AddHoldInvoiceRequest.routeHints: object expected");
                    message.routeHints[i] = $root.lnrpc.RouteHint.fromObject(object.routeHints[i]);
                }
            }
            if (object["private"] != null)
                message["private"] = Boolean(object["private"]);
            return message;
        };

        /**
         * Creates a plain object from an AddHoldInvoiceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @static
         * @param {invoicesrpc.AddHoldInvoiceRequest} message AddHoldInvoiceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddHoldInvoiceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.routeHints = [];
            if (options.defaults) {
                object.memo = "";
                if (options.bytes === String)
                    object.hash = "";
                else {
                    object.hash = [];
                    if (options.bytes !== Array)
                        object.hash = $util.newBuffer(object.hash);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.value = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.descriptionHash = "";
                else {
                    object.descriptionHash = [];
                    if (options.bytes !== Array)
                        object.descriptionHash = $util.newBuffer(object.descriptionHash);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expiry = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiry = options.longs === String ? "0" : 0;
                object.fallbackAddr = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.cltvExpiry = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cltvExpiry = options.longs === String ? "0" : 0;
                object["private"] = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.valueMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.valueMsat = options.longs === String ? "0" : 0;
            }
            if (message.memo != null && message.hasOwnProperty("memo"))
                object.memo = message.memo;
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = options.bytes === String ? $util.base64.encode(message.hash, 0, message.hash.length) : options.bytes === Array ? Array.prototype.slice.call(message.hash) : message.hash;
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value === "number")
                    object.value = options.longs === String ? String(message.value) : message.value;
                else
                    object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
            if (message.descriptionHash != null && message.hasOwnProperty("descriptionHash"))
                object.descriptionHash = options.bytes === String ? $util.base64.encode(message.descriptionHash, 0, message.descriptionHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.descriptionHash) : message.descriptionHash;
            if (message.expiry != null && message.hasOwnProperty("expiry"))
                if (typeof message.expiry === "number")
                    object.expiry = options.longs === String ? String(message.expiry) : message.expiry;
                else
                    object.expiry = options.longs === String ? $util.Long.prototype.toString.call(message.expiry) : options.longs === Number ? new $util.LongBits(message.expiry.low >>> 0, message.expiry.high >>> 0).toNumber() : message.expiry;
            if (message.fallbackAddr != null && message.hasOwnProperty("fallbackAddr"))
                object.fallbackAddr = message.fallbackAddr;
            if (message.cltvExpiry != null && message.hasOwnProperty("cltvExpiry"))
                if (typeof message.cltvExpiry === "number")
                    object.cltvExpiry = options.longs === String ? String(message.cltvExpiry) : message.cltvExpiry;
                else
                    object.cltvExpiry = options.longs === String ? $util.Long.prototype.toString.call(message.cltvExpiry) : options.longs === Number ? new $util.LongBits(message.cltvExpiry.low >>> 0, message.cltvExpiry.high >>> 0).toNumber(true) : message.cltvExpiry;
            if (message.routeHints && message.routeHints.length) {
                object.routeHints = [];
                for (let j = 0; j < message.routeHints.length; ++j)
                    object.routeHints[j] = $root.lnrpc.RouteHint.toObject(message.routeHints[j], options);
            }
            if (message["private"] != null && message.hasOwnProperty("private"))
                object["private"] = message["private"];
            if (message.valueMsat != null && message.hasOwnProperty("valueMsat"))
                if (typeof message.valueMsat === "number")
                    object.valueMsat = options.longs === String ? String(message.valueMsat) : message.valueMsat;
                else
                    object.valueMsat = options.longs === String ? $util.Long.prototype.toString.call(message.valueMsat) : options.longs === Number ? new $util.LongBits(message.valueMsat.low >>> 0, message.valueMsat.high >>> 0).toNumber() : message.valueMsat;
            return object;
        };

        /**
         * Converts this AddHoldInvoiceRequest to JSON.
         * @function toJSON
         * @memberof invoicesrpc.AddHoldInvoiceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddHoldInvoiceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddHoldInvoiceRequest;
    })();

    invoicesrpc.AddHoldInvoiceResp = (function() {

        /**
         * Properties of an AddHoldInvoiceResp.
         * @memberof invoicesrpc
         * @interface IAddHoldInvoiceResp
         * @property {string|null} [paymentRequest] AddHoldInvoiceResp paymentRequest
         * @property {Long|null} [addIndex] AddHoldInvoiceResp addIndex
         * @property {Uint8Array|null} [paymentAddr] AddHoldInvoiceResp paymentAddr
         */

        /**
         * Constructs a new AddHoldInvoiceResp.
         * @memberof invoicesrpc
         * @classdesc Represents an AddHoldInvoiceResp.
         * @implements IAddHoldInvoiceResp
         * @constructor
         * @param {invoicesrpc.IAddHoldInvoiceResp=} [properties] Properties to set
         */
        function AddHoldInvoiceResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddHoldInvoiceResp paymentRequest.
         * @member {string} paymentRequest
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @instance
         */
        AddHoldInvoiceResp.prototype.paymentRequest = "";

        /**
         * AddHoldInvoiceResp addIndex.
         * @member {Long} addIndex
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @instance
         */
        AddHoldInvoiceResp.prototype.addIndex = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * AddHoldInvoiceResp paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @instance
         */
        AddHoldInvoiceResp.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * Creates a new AddHoldInvoiceResp instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {invoicesrpc.IAddHoldInvoiceResp=} [properties] Properties to set
         * @returns {invoicesrpc.AddHoldInvoiceResp} AddHoldInvoiceResp instance
         */
        AddHoldInvoiceResp.create = function create(properties) {
            return new AddHoldInvoiceResp(properties);
        };

        /**
         * Encodes the specified AddHoldInvoiceResp message. Does not implicitly {@link invoicesrpc.AddHoldInvoiceResp.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {invoicesrpc.IAddHoldInvoiceResp} message AddHoldInvoiceResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddHoldInvoiceResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentRequest != null && Object.hasOwnProperty.call(message, "paymentRequest"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentRequest);
            if (message.addIndex != null && Object.hasOwnProperty.call(message, "addIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.addIndex);
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.paymentAddr);
            return writer;
        };

        /**
         * Encodes the specified AddHoldInvoiceResp message, length delimited. Does not implicitly {@link invoicesrpc.AddHoldInvoiceResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {invoicesrpc.IAddHoldInvoiceResp} message AddHoldInvoiceResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddHoldInvoiceResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddHoldInvoiceResp message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.AddHoldInvoiceResp} AddHoldInvoiceResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddHoldInvoiceResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.AddHoldInvoiceResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentRequest = reader.string();
                        break;
                    }
                case 2: {
                        message.addIndex = reader.uint64();
                        break;
                    }
                case 3: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddHoldInvoiceResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.AddHoldInvoiceResp} AddHoldInvoiceResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddHoldInvoiceResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddHoldInvoiceResp message.
         * @function verify
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddHoldInvoiceResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                if (!$util.isString(message.paymentRequest))
                    return "paymentRequest: string expected";
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (!$util.isInteger(message.addIndex) && !(message.addIndex && $util.isInteger(message.addIndex.low) && $util.isInteger(message.addIndex.high)))
                    return "addIndex: integer|Long expected";
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            return null;
        };

        /**
         * Creates an AddHoldInvoiceResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.AddHoldInvoiceResp} AddHoldInvoiceResp
         */
        AddHoldInvoiceResp.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.AddHoldInvoiceResp)
                return object;
            let message = new $root.invoicesrpc.AddHoldInvoiceResp();
            if (object.paymentRequest != null)
                message.paymentRequest = String(object.paymentRequest);
            if (object.addIndex != null)
                if ($util.Long)
                    (message.addIndex = $util.Long.fromValue(object.addIndex)).unsigned = true;
                else if (typeof object.addIndex === "string")
                    message.addIndex = parseInt(object.addIndex, 10);
                else if (typeof object.addIndex === "number")
                    message.addIndex = object.addIndex;
                else if (typeof object.addIndex === "object")
                    message.addIndex = new $util.LongBits(object.addIndex.low >>> 0, object.addIndex.high >>> 0).toNumber(true);
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            return message;
        };

        /**
         * Creates a plain object from an AddHoldInvoiceResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @static
         * @param {invoicesrpc.AddHoldInvoiceResp} message AddHoldInvoiceResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddHoldInvoiceResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.paymentRequest = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.addIndex = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.addIndex = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
            }
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                object.paymentRequest = message.paymentRequest;
            if (message.addIndex != null && message.hasOwnProperty("addIndex"))
                if (typeof message.addIndex === "number")
                    object.addIndex = options.longs === String ? String(message.addIndex) : message.addIndex;
                else
                    object.addIndex = options.longs === String ? $util.Long.prototype.toString.call(message.addIndex) : options.longs === Number ? new $util.LongBits(message.addIndex.low >>> 0, message.addIndex.high >>> 0).toNumber(true) : message.addIndex;
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            return object;
        };

        /**
         * Converts this AddHoldInvoiceResp to JSON.
         * @function toJSON
         * @memberof invoicesrpc.AddHoldInvoiceResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddHoldInvoiceResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddHoldInvoiceResp;
    })();

    invoicesrpc.SettleInvoiceMsg = (function() {

        /**
         * Properties of a SettleInvoiceMsg.
         * @memberof invoicesrpc
         * @interface ISettleInvoiceMsg
         * @property {Uint8Array|null} [preimage] SettleInvoiceMsg preimage
         */

        /**
         * Constructs a new SettleInvoiceMsg.
         * @memberof invoicesrpc
         * @classdesc Represents a SettleInvoiceMsg.
         * @implements ISettleInvoiceMsg
         * @constructor
         * @param {invoicesrpc.ISettleInvoiceMsg=} [properties] Properties to set
         */
        function SettleInvoiceMsg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SettleInvoiceMsg preimage.
         * @member {Uint8Array} preimage
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @instance
         */
        SettleInvoiceMsg.prototype.preimage = $util.newBuffer([]);

        /**
         * Creates a new SettleInvoiceMsg instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {invoicesrpc.ISettleInvoiceMsg=} [properties] Properties to set
         * @returns {invoicesrpc.SettleInvoiceMsg} SettleInvoiceMsg instance
         */
        SettleInvoiceMsg.create = function create(properties) {
            return new SettleInvoiceMsg(properties);
        };

        /**
         * Encodes the specified SettleInvoiceMsg message. Does not implicitly {@link invoicesrpc.SettleInvoiceMsg.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {invoicesrpc.ISettleInvoiceMsg} message SettleInvoiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettleInvoiceMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.preimage);
            return writer;
        };

        /**
         * Encodes the specified SettleInvoiceMsg message, length delimited. Does not implicitly {@link invoicesrpc.SettleInvoiceMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {invoicesrpc.ISettleInvoiceMsg} message SettleInvoiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettleInvoiceMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SettleInvoiceMsg message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.SettleInvoiceMsg} SettleInvoiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettleInvoiceMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.SettleInvoiceMsg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.preimage = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SettleInvoiceMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.SettleInvoiceMsg} SettleInvoiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettleInvoiceMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SettleInvoiceMsg message.
         * @function verify
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SettleInvoiceMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            return null;
        };

        /**
         * Creates a SettleInvoiceMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.SettleInvoiceMsg} SettleInvoiceMsg
         */
        SettleInvoiceMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.SettleInvoiceMsg)
                return object;
            let message = new $root.invoicesrpc.SettleInvoiceMsg();
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            return message;
        };

        /**
         * Creates a plain object from a SettleInvoiceMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @static
         * @param {invoicesrpc.SettleInvoiceMsg} message SettleInvoiceMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SettleInvoiceMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            return object;
        };

        /**
         * Converts this SettleInvoiceMsg to JSON.
         * @function toJSON
         * @memberof invoicesrpc.SettleInvoiceMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SettleInvoiceMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SettleInvoiceMsg;
    })();

    invoicesrpc.SettleInvoiceResp = (function() {

        /**
         * Properties of a SettleInvoiceResp.
         * @memberof invoicesrpc
         * @interface ISettleInvoiceResp
         */

        /**
         * Constructs a new SettleInvoiceResp.
         * @memberof invoicesrpc
         * @classdesc Represents a SettleInvoiceResp.
         * @implements ISettleInvoiceResp
         * @constructor
         * @param {invoicesrpc.ISettleInvoiceResp=} [properties] Properties to set
         */
        function SettleInvoiceResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SettleInvoiceResp instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {invoicesrpc.ISettleInvoiceResp=} [properties] Properties to set
         * @returns {invoicesrpc.SettleInvoiceResp} SettleInvoiceResp instance
         */
        SettleInvoiceResp.create = function create(properties) {
            return new SettleInvoiceResp(properties);
        };

        /**
         * Encodes the specified SettleInvoiceResp message. Does not implicitly {@link invoicesrpc.SettleInvoiceResp.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {invoicesrpc.ISettleInvoiceResp} message SettleInvoiceResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettleInvoiceResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SettleInvoiceResp message, length delimited. Does not implicitly {@link invoicesrpc.SettleInvoiceResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {invoicesrpc.ISettleInvoiceResp} message SettleInvoiceResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettleInvoiceResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SettleInvoiceResp message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.SettleInvoiceResp} SettleInvoiceResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettleInvoiceResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.SettleInvoiceResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SettleInvoiceResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.SettleInvoiceResp} SettleInvoiceResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettleInvoiceResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SettleInvoiceResp message.
         * @function verify
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SettleInvoiceResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SettleInvoiceResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.SettleInvoiceResp} SettleInvoiceResp
         */
        SettleInvoiceResp.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.SettleInvoiceResp)
                return object;
            return new $root.invoicesrpc.SettleInvoiceResp();
        };

        /**
         * Creates a plain object from a SettleInvoiceResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.SettleInvoiceResp
         * @static
         * @param {invoicesrpc.SettleInvoiceResp} message SettleInvoiceResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SettleInvoiceResp.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SettleInvoiceResp to JSON.
         * @function toJSON
         * @memberof invoicesrpc.SettleInvoiceResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SettleInvoiceResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SettleInvoiceResp;
    })();

    invoicesrpc.SubscribeSingleInvoiceRequest = (function() {

        /**
         * Properties of a SubscribeSingleInvoiceRequest.
         * @memberof invoicesrpc
         * @interface ISubscribeSingleInvoiceRequest
         * @property {Uint8Array|null} [rHash] SubscribeSingleInvoiceRequest rHash
         */

        /**
         * Constructs a new SubscribeSingleInvoiceRequest.
         * @memberof invoicesrpc
         * @classdesc Represents a SubscribeSingleInvoiceRequest.
         * @implements ISubscribeSingleInvoiceRequest
         * @constructor
         * @param {invoicesrpc.ISubscribeSingleInvoiceRequest=} [properties] Properties to set
         */
        function SubscribeSingleInvoiceRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubscribeSingleInvoiceRequest rHash.
         * @member {Uint8Array} rHash
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @instance
         */
        SubscribeSingleInvoiceRequest.prototype.rHash = $util.newBuffer([]);

        /**
         * Creates a new SubscribeSingleInvoiceRequest instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {invoicesrpc.ISubscribeSingleInvoiceRequest=} [properties] Properties to set
         * @returns {invoicesrpc.SubscribeSingleInvoiceRequest} SubscribeSingleInvoiceRequest instance
         */
        SubscribeSingleInvoiceRequest.create = function create(properties) {
            return new SubscribeSingleInvoiceRequest(properties);
        };

        /**
         * Encodes the specified SubscribeSingleInvoiceRequest message. Does not implicitly {@link invoicesrpc.SubscribeSingleInvoiceRequest.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {invoicesrpc.ISubscribeSingleInvoiceRequest} message SubscribeSingleInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeSingleInvoiceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rHash != null && Object.hasOwnProperty.call(message, "rHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rHash);
            return writer;
        };

        /**
         * Encodes the specified SubscribeSingleInvoiceRequest message, length delimited. Does not implicitly {@link invoicesrpc.SubscribeSingleInvoiceRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {invoicesrpc.ISubscribeSingleInvoiceRequest} message SubscribeSingleInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeSingleInvoiceRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubscribeSingleInvoiceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.SubscribeSingleInvoiceRequest} SubscribeSingleInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeSingleInvoiceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.SubscribeSingleInvoiceRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.rHash = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubscribeSingleInvoiceRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.SubscribeSingleInvoiceRequest} SubscribeSingleInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeSingleInvoiceRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubscribeSingleInvoiceRequest message.
         * @function verify
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubscribeSingleInvoiceRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                if (!(message.rHash && typeof message.rHash.length === "number" || $util.isString(message.rHash)))
                    return "rHash: buffer expected";
            return null;
        };

        /**
         * Creates a SubscribeSingleInvoiceRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.SubscribeSingleInvoiceRequest} SubscribeSingleInvoiceRequest
         */
        SubscribeSingleInvoiceRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.SubscribeSingleInvoiceRequest)
                return object;
            let message = new $root.invoicesrpc.SubscribeSingleInvoiceRequest();
            if (object.rHash != null)
                if (typeof object.rHash === "string")
                    $util.base64.decode(object.rHash, message.rHash = $util.newBuffer($util.base64.length(object.rHash)), 0);
                else if (object.rHash.length)
                    message.rHash = object.rHash;
            return message;
        };

        /**
         * Creates a plain object from a SubscribeSingleInvoiceRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @static
         * @param {invoicesrpc.SubscribeSingleInvoiceRequest} message SubscribeSingleInvoiceRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscribeSingleInvoiceRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.rHash = "";
                else {
                    object.rHash = [];
                    if (options.bytes !== Array)
                        object.rHash = $util.newBuffer(object.rHash);
                }
            if (message.rHash != null && message.hasOwnProperty("rHash"))
                object.rHash = options.bytes === String ? $util.base64.encode(message.rHash, 0, message.rHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.rHash) : message.rHash;
            return object;
        };

        /**
         * Converts this SubscribeSingleInvoiceRequest to JSON.
         * @function toJSON
         * @memberof invoicesrpc.SubscribeSingleInvoiceRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscribeSingleInvoiceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscribeSingleInvoiceRequest;
    })();

    /**
     * LookupModifier enum.
     * @name invoicesrpc.LookupModifier
     * @enum {number}
     * @property {number} DEFAULT=0 DEFAULT value
     * @property {number} HTLC_SET_ONLY=1 HTLC_SET_ONLY value
     * @property {number} HTLC_SET_BLANK=2 HTLC_SET_BLANK value
     */
    invoicesrpc.LookupModifier = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "DEFAULT"] = 0;
        values[valuesById[1] = "HTLC_SET_ONLY"] = 1;
        values[valuesById[2] = "HTLC_SET_BLANK"] = 2;
        return values;
    })();

    invoicesrpc.LookupInvoiceMsg = (function() {

        /**
         * Properties of a LookupInvoiceMsg.
         * @memberof invoicesrpc
         * @interface ILookupInvoiceMsg
         * @property {Uint8Array|null} [paymentHash] LookupInvoiceMsg paymentHash
         * @property {Uint8Array|null} [paymentAddr] LookupInvoiceMsg paymentAddr
         * @property {Uint8Array|null} [setId] LookupInvoiceMsg setId
         * @property {invoicesrpc.LookupModifier|null} [lookupModifier] LookupInvoiceMsg lookupModifier
         */

        /**
         * Constructs a new LookupInvoiceMsg.
         * @memberof invoicesrpc
         * @classdesc Represents a LookupInvoiceMsg.
         * @implements ILookupInvoiceMsg
         * @constructor
         * @param {invoicesrpc.ILookupInvoiceMsg=} [properties] Properties to set
         */
        function LookupInvoiceMsg(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LookupInvoiceMsg paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @instance
         */
        LookupInvoiceMsg.prototype.paymentHash = $util.newBuffer([]);

        /**
         * LookupInvoiceMsg paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @instance
         */
        LookupInvoiceMsg.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * LookupInvoiceMsg setId.
         * @member {Uint8Array} setId
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @instance
         */
        LookupInvoiceMsg.prototype.setId = $util.newBuffer([]);

        /**
         * LookupInvoiceMsg lookupModifier.
         * @member {invoicesrpc.LookupModifier} lookupModifier
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @instance
         */
        LookupInvoiceMsg.prototype.lookupModifier = 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * LookupInvoiceMsg invoiceRef.
         * @member {"paymentHash"|"paymentAddr"|"setId"|undefined} invoiceRef
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @instance
         */
        Object.defineProperty(LookupInvoiceMsg.prototype, "invoiceRef", {
            get: $util.oneOfGetter($oneOfFields = ["paymentHash", "paymentAddr", "setId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LookupInvoiceMsg instance using the specified properties.
         * @function create
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {invoicesrpc.ILookupInvoiceMsg=} [properties] Properties to set
         * @returns {invoicesrpc.LookupInvoiceMsg} LookupInvoiceMsg instance
         */
        LookupInvoiceMsg.create = function create(properties) {
            return new LookupInvoiceMsg(properties);
        };

        /**
         * Encodes the specified LookupInvoiceMsg message. Does not implicitly {@link invoicesrpc.LookupInvoiceMsg.verify|verify} messages.
         * @function encode
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {invoicesrpc.ILookupInvoiceMsg} message LookupInvoiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LookupInvoiceMsg.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.paymentHash);
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.paymentAddr);
            if (message.setId != null && Object.hasOwnProperty.call(message, "setId"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.setId);
            if (message.lookupModifier != null && Object.hasOwnProperty.call(message, "lookupModifier"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lookupModifier);
            return writer;
        };

        /**
         * Encodes the specified LookupInvoiceMsg message, length delimited. Does not implicitly {@link invoicesrpc.LookupInvoiceMsg.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {invoicesrpc.ILookupInvoiceMsg} message LookupInvoiceMsg message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LookupInvoiceMsg.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LookupInvoiceMsg message from the specified reader or buffer.
         * @function decode
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invoicesrpc.LookupInvoiceMsg} LookupInvoiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LookupInvoiceMsg.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invoicesrpc.LookupInvoiceMsg();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                case 3: {
                        message.setId = reader.bytes();
                        break;
                    }
                case 4: {
                        message.lookupModifier = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LookupInvoiceMsg message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invoicesrpc.LookupInvoiceMsg} LookupInvoiceMsg
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LookupInvoiceMsg.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LookupInvoiceMsg message.
         * @function verify
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LookupInvoiceMsg.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash")) {
                properties.invoiceRef = 1;
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr")) {
                if (properties.invoiceRef === 1)
                    return "invoiceRef: multiple values";
                properties.invoiceRef = 1;
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            }
            if (message.setId != null && message.hasOwnProperty("setId")) {
                if (properties.invoiceRef === 1)
                    return "invoiceRef: multiple values";
                properties.invoiceRef = 1;
                if (!(message.setId && typeof message.setId.length === "number" || $util.isString(message.setId)))
                    return "setId: buffer expected";
            }
            if (message.lookupModifier != null && message.hasOwnProperty("lookupModifier"))
                switch (message.lookupModifier) {
                default:
                    return "lookupModifier: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a LookupInvoiceMsg message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invoicesrpc.LookupInvoiceMsg} LookupInvoiceMsg
         */
        LookupInvoiceMsg.fromObject = function fromObject(object) {
            if (object instanceof $root.invoicesrpc.LookupInvoiceMsg)
                return object;
            let message = new $root.invoicesrpc.LookupInvoiceMsg();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            if (object.setId != null)
                if (typeof object.setId === "string")
                    $util.base64.decode(object.setId, message.setId = $util.newBuffer($util.base64.length(object.setId)), 0);
                else if (object.setId.length)
                    message.setId = object.setId;
            switch (object.lookupModifier) {
            case "DEFAULT":
            case 0:
                message.lookupModifier = 0;
                break;
            case "HTLC_SET_ONLY":
            case 1:
                message.lookupModifier = 1;
                break;
            case "HTLC_SET_BLANK":
            case 2:
                message.lookupModifier = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a LookupInvoiceMsg message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @static
         * @param {invoicesrpc.LookupInvoiceMsg} message LookupInvoiceMsg
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LookupInvoiceMsg.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.lookupModifier = options.enums === String ? "DEFAULT" : 0;
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash")) {
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
                if (options.oneofs)
                    object.invoiceRef = "paymentHash";
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr")) {
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
                if (options.oneofs)
                    object.invoiceRef = "paymentAddr";
            }
            if (message.setId != null && message.hasOwnProperty("setId")) {
                object.setId = options.bytes === String ? $util.base64.encode(message.setId, 0, message.setId.length) : options.bytes === Array ? Array.prototype.slice.call(message.setId) : message.setId;
                if (options.oneofs)
                    object.invoiceRef = "setId";
            }
            if (message.lookupModifier != null && message.hasOwnProperty("lookupModifier"))
                object.lookupModifier = options.enums === String ? $root.invoicesrpc.LookupModifier[message.lookupModifier] : message.lookupModifier;
            return object;
        };

        /**
         * Converts this LookupInvoiceMsg to JSON.
         * @function toJSON
         * @memberof invoicesrpc.LookupInvoiceMsg
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LookupInvoiceMsg.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LookupInvoiceMsg;
    })();

    return invoicesrpc;
})();

export const lnclipb = $root.lnclipb = (() => {

    /**
     * Namespace lnclipb.
     * @exports lnclipb
     * @namespace
     */
    const lnclipb = {};

    lnclipb.VersionResponse = (function() {

        /**
         * Properties of a VersionResponse.
         * @memberof lnclipb
         * @interface IVersionResponse
         * @property {verrpc.IVersion|null} [lncli] VersionResponse lncli
         * @property {verrpc.IVersion|null} [lnd] VersionResponse lnd
         */

        /**
         * Constructs a new VersionResponse.
         * @memberof lnclipb
         * @classdesc Represents a VersionResponse.
         * @implements IVersionResponse
         * @constructor
         * @param {lnclipb.IVersionResponse=} [properties] Properties to set
         */
        function VersionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VersionResponse lncli.
         * @member {verrpc.IVersion|null|undefined} lncli
         * @memberof lnclipb.VersionResponse
         * @instance
         */
        VersionResponse.prototype.lncli = null;

        /**
         * VersionResponse lnd.
         * @member {verrpc.IVersion|null|undefined} lnd
         * @memberof lnclipb.VersionResponse
         * @instance
         */
        VersionResponse.prototype.lnd = null;

        /**
         * Creates a new VersionResponse instance using the specified properties.
         * @function create
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {lnclipb.IVersionResponse=} [properties] Properties to set
         * @returns {lnclipb.VersionResponse} VersionResponse instance
         */
        VersionResponse.create = function create(properties) {
            return new VersionResponse(properties);
        };

        /**
         * Encodes the specified VersionResponse message. Does not implicitly {@link lnclipb.VersionResponse.verify|verify} messages.
         * @function encode
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {lnclipb.IVersionResponse} message VersionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lncli != null && Object.hasOwnProperty.call(message, "lncli"))
                $root.verrpc.Version.encode(message.lncli, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lnd != null && Object.hasOwnProperty.call(message, "lnd"))
                $root.verrpc.Version.encode(message.lnd, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified VersionResponse message, length delimited. Does not implicitly {@link lnclipb.VersionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {lnclipb.IVersionResponse} message VersionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {lnclipb.VersionResponse} VersionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.lnclipb.VersionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.lncli = $root.verrpc.Version.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.lnd = $root.verrpc.Version.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {lnclipb.VersionResponse} VersionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionResponse message.
         * @function verify
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.lncli != null && message.hasOwnProperty("lncli")) {
                let error = $root.verrpc.Version.verify(message.lncli);
                if (error)
                    return "lncli." + error;
            }
            if (message.lnd != null && message.hasOwnProperty("lnd")) {
                let error = $root.verrpc.Version.verify(message.lnd);
                if (error)
                    return "lnd." + error;
            }
            return null;
        };

        /**
         * Creates a VersionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {lnclipb.VersionResponse} VersionResponse
         */
        VersionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.lnclipb.VersionResponse)
                return object;
            let message = new $root.lnclipb.VersionResponse();
            if (object.lncli != null) {
                if (typeof object.lncli !== "object")
                    throw TypeError(".lnclipb.VersionResponse.lncli: object expected");
                message.lncli = $root.verrpc.Version.fromObject(object.lncli);
            }
            if (object.lnd != null) {
                if (typeof object.lnd !== "object")
                    throw TypeError(".lnclipb.VersionResponse.lnd: object expected");
                message.lnd = $root.verrpc.Version.fromObject(object.lnd);
            }
            return message;
        };

        /**
         * Creates a plain object from a VersionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof lnclipb.VersionResponse
         * @static
         * @param {lnclipb.VersionResponse} message VersionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.lncli = null;
                object.lnd = null;
            }
            if (message.lncli != null && message.hasOwnProperty("lncli"))
                object.lncli = $root.verrpc.Version.toObject(message.lncli, options);
            if (message.lnd != null && message.hasOwnProperty("lnd"))
                object.lnd = $root.verrpc.Version.toObject(message.lnd, options);
            return object;
        };

        /**
         * Converts this VersionResponse to JSON.
         * @function toJSON
         * @memberof lnclipb.VersionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VersionResponse;
    })();

    return lnclipb;
})();

export const neutrinorpc = $root.neutrinorpc = (() => {

    /**
     * Namespace neutrinorpc.
     * @exports neutrinorpc
     * @namespace
     */
    const neutrinorpc = {};

    neutrinorpc.NeutrinoKit = (function() {

        /**
         * Constructs a new NeutrinoKit service.
         * @memberof neutrinorpc
         * @classdesc Represents a NeutrinoKit
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function NeutrinoKit(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (NeutrinoKit.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = NeutrinoKit;

        /**
         * Creates new NeutrinoKit service using the specified rpc implementation.
         * @function create
         * @memberof neutrinorpc.NeutrinoKit
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {NeutrinoKit} RPC service. Useful where requests and/or responses are streamed.
         */
        NeutrinoKit.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#status}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef StatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.StatusResponse} [response] StatusResponse
         */

        /**
         * Calls Status.
         * @function status
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IStatusRequest} request StatusRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.StatusCallback} callback Node-style callback called with the error, if any, and StatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.status = function status(request, callback) {
            return this.rpcCall(status, $root.neutrinorpc.StatusRequest, $root.neutrinorpc.StatusResponse, request, callback);
        }, "name", { value: "Status" });

        /**
         * Calls Status.
         * @function status
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IStatusRequest} request StatusRequest message or plain object
         * @returns {Promise<neutrinorpc.StatusResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#addPeer}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef AddPeerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.AddPeerResponse} [response] AddPeerResponse
         */

        /**
         * Calls AddPeer.
         * @function addPeer
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IAddPeerRequest} request AddPeerRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.AddPeerCallback} callback Node-style callback called with the error, if any, and AddPeerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.addPeer = function addPeer(request, callback) {
            return this.rpcCall(addPeer, $root.neutrinorpc.AddPeerRequest, $root.neutrinorpc.AddPeerResponse, request, callback);
        }, "name", { value: "AddPeer" });

        /**
         * Calls AddPeer.
         * @function addPeer
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IAddPeerRequest} request AddPeerRequest message or plain object
         * @returns {Promise<neutrinorpc.AddPeerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#disconnectPeer}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef DisconnectPeerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.DisconnectPeerResponse} [response] DisconnectPeerResponse
         */

        /**
         * Calls DisconnectPeer.
         * @function disconnectPeer
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IDisconnectPeerRequest} request DisconnectPeerRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.DisconnectPeerCallback} callback Node-style callback called with the error, if any, and DisconnectPeerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.disconnectPeer = function disconnectPeer(request, callback) {
            return this.rpcCall(disconnectPeer, $root.neutrinorpc.DisconnectPeerRequest, $root.neutrinorpc.DisconnectPeerResponse, request, callback);
        }, "name", { value: "DisconnectPeer" });

        /**
         * Calls DisconnectPeer.
         * @function disconnectPeer
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IDisconnectPeerRequest} request DisconnectPeerRequest message or plain object
         * @returns {Promise<neutrinorpc.DisconnectPeerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#isBanned}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef IsBannedCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.IsBannedResponse} [response] IsBannedResponse
         */

        /**
         * Calls IsBanned.
         * @function isBanned
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IIsBannedRequest} request IsBannedRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.IsBannedCallback} callback Node-style callback called with the error, if any, and IsBannedResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.isBanned = function isBanned(request, callback) {
            return this.rpcCall(isBanned, $root.neutrinorpc.IsBannedRequest, $root.neutrinorpc.IsBannedResponse, request, callback);
        }, "name", { value: "IsBanned" });

        /**
         * Calls IsBanned.
         * @function isBanned
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IIsBannedRequest} request IsBannedRequest message or plain object
         * @returns {Promise<neutrinorpc.IsBannedResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#getBlockHeader}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef GetBlockHeaderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.GetBlockHeaderResponse} [response] GetBlockHeaderResponse
         */

        /**
         * Calls GetBlockHeader.
         * @function getBlockHeader
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetBlockHeaderRequest} request GetBlockHeaderRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.GetBlockHeaderCallback} callback Node-style callback called with the error, if any, and GetBlockHeaderResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.getBlockHeader = function getBlockHeader(request, callback) {
            return this.rpcCall(getBlockHeader, $root.neutrinorpc.GetBlockHeaderRequest, $root.neutrinorpc.GetBlockHeaderResponse, request, callback);
        }, "name", { value: "GetBlockHeader" });

        /**
         * Calls GetBlockHeader.
         * @function getBlockHeader
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetBlockHeaderRequest} request GetBlockHeaderRequest message or plain object
         * @returns {Promise<neutrinorpc.GetBlockHeaderResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#getBlock}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef GetBlockCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.GetBlockResponse} [response] GetBlockResponse
         */

        /**
         * Calls GetBlock.
         * @function getBlock
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetBlockRequest} request GetBlockRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.GetBlockCallback} callback Node-style callback called with the error, if any, and GetBlockResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.getBlock = function getBlock(request, callback) {
            return this.rpcCall(getBlock, $root.neutrinorpc.GetBlockRequest, $root.neutrinorpc.GetBlockResponse, request, callback);
        }, "name", { value: "GetBlock" });

        /**
         * Calls GetBlock.
         * @function getBlock
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetBlockRequest} request GetBlockRequest message or plain object
         * @returns {Promise<neutrinorpc.GetBlockResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#getCFilter}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef GetCFilterCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.GetCFilterResponse} [response] GetCFilterResponse
         */

        /**
         * Calls GetCFilter.
         * @function getCFilter
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetCFilterRequest} request GetCFilterRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.GetCFilterCallback} callback Node-style callback called with the error, if any, and GetCFilterResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.getCFilter = function getCFilter(request, callback) {
            return this.rpcCall(getCFilter, $root.neutrinorpc.GetCFilterRequest, $root.neutrinorpc.GetCFilterResponse, request, callback);
        }, "name", { value: "GetCFilter" });

        /**
         * Calls GetCFilter.
         * @function getCFilter
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetCFilterRequest} request GetCFilterRequest message or plain object
         * @returns {Promise<neutrinorpc.GetCFilterResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link neutrinorpc.NeutrinoKit#getBlockHash}.
         * @memberof neutrinorpc.NeutrinoKit
         * @typedef GetBlockHashCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {neutrinorpc.GetBlockHashResponse} [response] GetBlockHashResponse
         */

        /**
         * Calls GetBlockHash.
         * @function getBlockHash
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetBlockHashRequest} request GetBlockHashRequest message or plain object
         * @param {neutrinorpc.NeutrinoKit.GetBlockHashCallback} callback Node-style callback called with the error, if any, and GetBlockHashResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(NeutrinoKit.prototype.getBlockHash = function getBlockHash(request, callback) {
            return this.rpcCall(getBlockHash, $root.neutrinorpc.GetBlockHashRequest, $root.neutrinorpc.GetBlockHashResponse, request, callback);
        }, "name", { value: "GetBlockHash" });

        /**
         * Calls GetBlockHash.
         * @function getBlockHash
         * @memberof neutrinorpc.NeutrinoKit
         * @instance
         * @param {neutrinorpc.IGetBlockHashRequest} request GetBlockHashRequest message or plain object
         * @returns {Promise<neutrinorpc.GetBlockHashResponse>} Promise
         * @variation 2
         */

        return NeutrinoKit;
    })();

    neutrinorpc.StatusRequest = (function() {

        /**
         * Properties of a StatusRequest.
         * @memberof neutrinorpc
         * @interface IStatusRequest
         */

        /**
         * Constructs a new StatusRequest.
         * @memberof neutrinorpc
         * @classdesc Represents a StatusRequest.
         * @implements IStatusRequest
         * @constructor
         * @param {neutrinorpc.IStatusRequest=} [properties] Properties to set
         */
        function StatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StatusRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {neutrinorpc.IStatusRequest=} [properties] Properties to set
         * @returns {neutrinorpc.StatusRequest} StatusRequest instance
         */
        StatusRequest.create = function create(properties) {
            return new StatusRequest(properties);
        };

        /**
         * Encodes the specified StatusRequest message. Does not implicitly {@link neutrinorpc.StatusRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {neutrinorpc.IStatusRequest} message StatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StatusRequest message, length delimited. Does not implicitly {@link neutrinorpc.StatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {neutrinorpc.IStatusRequest} message StatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.StatusRequest} StatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.StatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.StatusRequest} StatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusRequest message.
         * @function verify
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.StatusRequest} StatusRequest
         */
        StatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.StatusRequest)
                return object;
            return new $root.neutrinorpc.StatusRequest();
        };

        /**
         * Creates a plain object from a StatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.StatusRequest
         * @static
         * @param {neutrinorpc.StatusRequest} message StatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StatusRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.StatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatusRequest;
    })();

    neutrinorpc.StatusResponse = (function() {

        /**
         * Properties of a StatusResponse.
         * @memberof neutrinorpc
         * @interface IStatusResponse
         * @property {boolean|null} [active] StatusResponse active
         * @property {boolean|null} [synced] StatusResponse synced
         * @property {number|null} [blockHeight] StatusResponse blockHeight
         * @property {string|null} [blockHash] StatusResponse blockHash
         * @property {Array.<string>|null} [peers] StatusResponse peers
         */

        /**
         * Constructs a new StatusResponse.
         * @memberof neutrinorpc
         * @classdesc Represents a StatusResponse.
         * @implements IStatusResponse
         * @constructor
         * @param {neutrinorpc.IStatusResponse=} [properties] Properties to set
         */
        function StatusResponse(properties) {
            this.peers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatusResponse active.
         * @member {boolean} active
         * @memberof neutrinorpc.StatusResponse
         * @instance
         */
        StatusResponse.prototype.active = false;

        /**
         * StatusResponse synced.
         * @member {boolean} synced
         * @memberof neutrinorpc.StatusResponse
         * @instance
         */
        StatusResponse.prototype.synced = false;

        /**
         * StatusResponse blockHeight.
         * @member {number} blockHeight
         * @memberof neutrinorpc.StatusResponse
         * @instance
         */
        StatusResponse.prototype.blockHeight = 0;

        /**
         * StatusResponse blockHash.
         * @member {string} blockHash
         * @memberof neutrinorpc.StatusResponse
         * @instance
         */
        StatusResponse.prototype.blockHash = "";

        /**
         * StatusResponse peers.
         * @member {Array.<string>} peers
         * @memberof neutrinorpc.StatusResponse
         * @instance
         */
        StatusResponse.prototype.peers = $util.emptyArray;

        /**
         * Creates a new StatusResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {neutrinorpc.IStatusResponse=} [properties] Properties to set
         * @returns {neutrinorpc.StatusResponse} StatusResponse instance
         */
        StatusResponse.create = function create(properties) {
            return new StatusResponse(properties);
        };

        /**
         * Encodes the specified StatusResponse message. Does not implicitly {@link neutrinorpc.StatusResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {neutrinorpc.IStatusResponse} message StatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.active != null && Object.hasOwnProperty.call(message, "active"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.active);
            if (message.synced != null && Object.hasOwnProperty.call(message, "synced"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.synced);
            if (message.blockHeight != null && Object.hasOwnProperty.call(message, "blockHeight"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.blockHeight);
            if (message.blockHash != null && Object.hasOwnProperty.call(message, "blockHash"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.blockHash);
            if (message.peers != null && message.peers.length)
                for (let i = 0; i < message.peers.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.peers[i]);
            return writer;
        };

        /**
         * Encodes the specified StatusResponse message, length delimited. Does not implicitly {@link neutrinorpc.StatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {neutrinorpc.IStatusResponse} message StatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.StatusResponse} StatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.StatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.active = reader.bool();
                        break;
                    }
                case 2: {
                        message.synced = reader.bool();
                        break;
                    }
                case 3: {
                        message.blockHeight = reader.int32();
                        break;
                    }
                case 4: {
                        message.blockHash = reader.string();
                        break;
                    }
                case 5: {
                        if (!(message.peers && message.peers.length))
                            message.peers = [];
                        message.peers.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.StatusResponse} StatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatusResponse message.
         * @function verify
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.active != null && message.hasOwnProperty("active"))
                if (typeof message.active !== "boolean")
                    return "active: boolean expected";
            if (message.synced != null && message.hasOwnProperty("synced"))
                if (typeof message.synced !== "boolean")
                    return "synced: boolean expected";
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                if (!$util.isInteger(message.blockHeight))
                    return "blockHeight: integer expected";
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                if (!$util.isString(message.blockHash))
                    return "blockHash: string expected";
            if (message.peers != null && message.hasOwnProperty("peers")) {
                if (!Array.isArray(message.peers))
                    return "peers: array expected";
                for (let i = 0; i < message.peers.length; ++i)
                    if (!$util.isString(message.peers[i]))
                        return "peers: string[] expected";
            }
            return null;
        };

        /**
         * Creates a StatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.StatusResponse} StatusResponse
         */
        StatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.StatusResponse)
                return object;
            let message = new $root.neutrinorpc.StatusResponse();
            if (object.active != null)
                message.active = Boolean(object.active);
            if (object.synced != null)
                message.synced = Boolean(object.synced);
            if (object.blockHeight != null)
                message.blockHeight = object.blockHeight | 0;
            if (object.blockHash != null)
                message.blockHash = String(object.blockHash);
            if (object.peers) {
                if (!Array.isArray(object.peers))
                    throw TypeError(".neutrinorpc.StatusResponse.peers: array expected");
                message.peers = [];
                for (let i = 0; i < object.peers.length; ++i)
                    message.peers[i] = String(object.peers[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a StatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.StatusResponse
         * @static
         * @param {neutrinorpc.StatusResponse} message StatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.peers = [];
            if (options.defaults) {
                object.active = false;
                object.synced = false;
                object.blockHeight = 0;
                object.blockHash = "";
            }
            if (message.active != null && message.hasOwnProperty("active"))
                object.active = message.active;
            if (message.synced != null && message.hasOwnProperty("synced"))
                object.synced = message.synced;
            if (message.blockHeight != null && message.hasOwnProperty("blockHeight"))
                object.blockHeight = message.blockHeight;
            if (message.blockHash != null && message.hasOwnProperty("blockHash"))
                object.blockHash = message.blockHash;
            if (message.peers && message.peers.length) {
                object.peers = [];
                for (let j = 0; j < message.peers.length; ++j)
                    object.peers[j] = message.peers[j];
            }
            return object;
        };

        /**
         * Converts this StatusResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.StatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatusResponse;
    })();

    neutrinorpc.AddPeerRequest = (function() {

        /**
         * Properties of an AddPeerRequest.
         * @memberof neutrinorpc
         * @interface IAddPeerRequest
         * @property {string|null} [peerAddrs] AddPeerRequest peerAddrs
         */

        /**
         * Constructs a new AddPeerRequest.
         * @memberof neutrinorpc
         * @classdesc Represents an AddPeerRequest.
         * @implements IAddPeerRequest
         * @constructor
         * @param {neutrinorpc.IAddPeerRequest=} [properties] Properties to set
         */
        function AddPeerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPeerRequest peerAddrs.
         * @member {string} peerAddrs
         * @memberof neutrinorpc.AddPeerRequest
         * @instance
         */
        AddPeerRequest.prototype.peerAddrs = "";

        /**
         * Creates a new AddPeerRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {neutrinorpc.IAddPeerRequest=} [properties] Properties to set
         * @returns {neutrinorpc.AddPeerRequest} AddPeerRequest instance
         */
        AddPeerRequest.create = function create(properties) {
            return new AddPeerRequest(properties);
        };

        /**
         * Encodes the specified AddPeerRequest message. Does not implicitly {@link neutrinorpc.AddPeerRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {neutrinorpc.IAddPeerRequest} message AddPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPeerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.peerAddrs != null && Object.hasOwnProperty.call(message, "peerAddrs"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.peerAddrs);
            return writer;
        };

        /**
         * Encodes the specified AddPeerRequest message, length delimited. Does not implicitly {@link neutrinorpc.AddPeerRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {neutrinorpc.IAddPeerRequest} message AddPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPeerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddPeerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.AddPeerRequest} AddPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPeerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.AddPeerRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.peerAddrs = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddPeerRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.AddPeerRequest} AddPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPeerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddPeerRequest message.
         * @function verify
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddPeerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.peerAddrs != null && message.hasOwnProperty("peerAddrs"))
                if (!$util.isString(message.peerAddrs))
                    return "peerAddrs: string expected";
            return null;
        };

        /**
         * Creates an AddPeerRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.AddPeerRequest} AddPeerRequest
         */
        AddPeerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.AddPeerRequest)
                return object;
            let message = new $root.neutrinorpc.AddPeerRequest();
            if (object.peerAddrs != null)
                message.peerAddrs = String(object.peerAddrs);
            return message;
        };

        /**
         * Creates a plain object from an AddPeerRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.AddPeerRequest
         * @static
         * @param {neutrinorpc.AddPeerRequest} message AddPeerRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddPeerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.peerAddrs = "";
            if (message.peerAddrs != null && message.hasOwnProperty("peerAddrs"))
                object.peerAddrs = message.peerAddrs;
            return object;
        };

        /**
         * Converts this AddPeerRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.AddPeerRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddPeerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddPeerRequest;
    })();

    neutrinorpc.AddPeerResponse = (function() {

        /**
         * Properties of an AddPeerResponse.
         * @memberof neutrinorpc
         * @interface IAddPeerResponse
         */

        /**
         * Constructs a new AddPeerResponse.
         * @memberof neutrinorpc
         * @classdesc Represents an AddPeerResponse.
         * @implements IAddPeerResponse
         * @constructor
         * @param {neutrinorpc.IAddPeerResponse=} [properties] Properties to set
         */
        function AddPeerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AddPeerResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {neutrinorpc.IAddPeerResponse=} [properties] Properties to set
         * @returns {neutrinorpc.AddPeerResponse} AddPeerResponse instance
         */
        AddPeerResponse.create = function create(properties) {
            return new AddPeerResponse(properties);
        };

        /**
         * Encodes the specified AddPeerResponse message. Does not implicitly {@link neutrinorpc.AddPeerResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {neutrinorpc.IAddPeerResponse} message AddPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPeerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AddPeerResponse message, length delimited. Does not implicitly {@link neutrinorpc.AddPeerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {neutrinorpc.IAddPeerResponse} message AddPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPeerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddPeerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.AddPeerResponse} AddPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPeerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.AddPeerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddPeerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.AddPeerResponse} AddPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPeerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddPeerResponse message.
         * @function verify
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddPeerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AddPeerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.AddPeerResponse} AddPeerResponse
         */
        AddPeerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.AddPeerResponse)
                return object;
            return new $root.neutrinorpc.AddPeerResponse();
        };

        /**
         * Creates a plain object from an AddPeerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.AddPeerResponse
         * @static
         * @param {neutrinorpc.AddPeerResponse} message AddPeerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddPeerResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AddPeerResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.AddPeerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddPeerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddPeerResponse;
    })();

    neutrinorpc.DisconnectPeerRequest = (function() {

        /**
         * Properties of a DisconnectPeerRequest.
         * @memberof neutrinorpc
         * @interface IDisconnectPeerRequest
         * @property {string|null} [peerAddrs] DisconnectPeerRequest peerAddrs
         */

        /**
         * Constructs a new DisconnectPeerRequest.
         * @memberof neutrinorpc
         * @classdesc Represents a DisconnectPeerRequest.
         * @implements IDisconnectPeerRequest
         * @constructor
         * @param {neutrinorpc.IDisconnectPeerRequest=} [properties] Properties to set
         */
        function DisconnectPeerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DisconnectPeerRequest peerAddrs.
         * @member {string} peerAddrs
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @instance
         */
        DisconnectPeerRequest.prototype.peerAddrs = "";

        /**
         * Creates a new DisconnectPeerRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {neutrinorpc.IDisconnectPeerRequest=} [properties] Properties to set
         * @returns {neutrinorpc.DisconnectPeerRequest} DisconnectPeerRequest instance
         */
        DisconnectPeerRequest.create = function create(properties) {
            return new DisconnectPeerRequest(properties);
        };

        /**
         * Encodes the specified DisconnectPeerRequest message. Does not implicitly {@link neutrinorpc.DisconnectPeerRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {neutrinorpc.IDisconnectPeerRequest} message DisconnectPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.peerAddrs != null && Object.hasOwnProperty.call(message, "peerAddrs"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.peerAddrs);
            return writer;
        };

        /**
         * Encodes the specified DisconnectPeerRequest message, length delimited. Does not implicitly {@link neutrinorpc.DisconnectPeerRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {neutrinorpc.IDisconnectPeerRequest} message DisconnectPeerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DisconnectPeerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.DisconnectPeerRequest} DisconnectPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.DisconnectPeerRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.peerAddrs = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DisconnectPeerRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.DisconnectPeerRequest} DisconnectPeerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DisconnectPeerRequest message.
         * @function verify
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DisconnectPeerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.peerAddrs != null && message.hasOwnProperty("peerAddrs"))
                if (!$util.isString(message.peerAddrs))
                    return "peerAddrs: string expected";
            return null;
        };

        /**
         * Creates a DisconnectPeerRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.DisconnectPeerRequest} DisconnectPeerRequest
         */
        DisconnectPeerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.DisconnectPeerRequest)
                return object;
            let message = new $root.neutrinorpc.DisconnectPeerRequest();
            if (object.peerAddrs != null)
                message.peerAddrs = String(object.peerAddrs);
            return message;
        };

        /**
         * Creates a plain object from a DisconnectPeerRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @static
         * @param {neutrinorpc.DisconnectPeerRequest} message DisconnectPeerRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DisconnectPeerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.peerAddrs = "";
            if (message.peerAddrs != null && message.hasOwnProperty("peerAddrs"))
                object.peerAddrs = message.peerAddrs;
            return object;
        };

        /**
         * Converts this DisconnectPeerRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.DisconnectPeerRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DisconnectPeerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DisconnectPeerRequest;
    })();

    neutrinorpc.DisconnectPeerResponse = (function() {

        /**
         * Properties of a DisconnectPeerResponse.
         * @memberof neutrinorpc
         * @interface IDisconnectPeerResponse
         */

        /**
         * Constructs a new DisconnectPeerResponse.
         * @memberof neutrinorpc
         * @classdesc Represents a DisconnectPeerResponse.
         * @implements IDisconnectPeerResponse
         * @constructor
         * @param {neutrinorpc.IDisconnectPeerResponse=} [properties] Properties to set
         */
        function DisconnectPeerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DisconnectPeerResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {neutrinorpc.IDisconnectPeerResponse=} [properties] Properties to set
         * @returns {neutrinorpc.DisconnectPeerResponse} DisconnectPeerResponse instance
         */
        DisconnectPeerResponse.create = function create(properties) {
            return new DisconnectPeerResponse(properties);
        };

        /**
         * Encodes the specified DisconnectPeerResponse message. Does not implicitly {@link neutrinorpc.DisconnectPeerResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {neutrinorpc.IDisconnectPeerResponse} message DisconnectPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DisconnectPeerResponse message, length delimited. Does not implicitly {@link neutrinorpc.DisconnectPeerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {neutrinorpc.IDisconnectPeerResponse} message DisconnectPeerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisconnectPeerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DisconnectPeerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.DisconnectPeerResponse} DisconnectPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.DisconnectPeerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DisconnectPeerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.DisconnectPeerResponse} DisconnectPeerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisconnectPeerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DisconnectPeerResponse message.
         * @function verify
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DisconnectPeerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DisconnectPeerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.DisconnectPeerResponse} DisconnectPeerResponse
         */
        DisconnectPeerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.DisconnectPeerResponse)
                return object;
            return new $root.neutrinorpc.DisconnectPeerResponse();
        };

        /**
         * Creates a plain object from a DisconnectPeerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @static
         * @param {neutrinorpc.DisconnectPeerResponse} message DisconnectPeerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DisconnectPeerResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DisconnectPeerResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.DisconnectPeerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DisconnectPeerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DisconnectPeerResponse;
    })();

    neutrinorpc.IsBannedRequest = (function() {

        /**
         * Properties of an IsBannedRequest.
         * @memberof neutrinorpc
         * @interface IIsBannedRequest
         * @property {string|null} [peerAddrs] IsBannedRequest peerAddrs
         */

        /**
         * Constructs a new IsBannedRequest.
         * @memberof neutrinorpc
         * @classdesc Represents an IsBannedRequest.
         * @implements IIsBannedRequest
         * @constructor
         * @param {neutrinorpc.IIsBannedRequest=} [properties] Properties to set
         */
        function IsBannedRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IsBannedRequest peerAddrs.
         * @member {string} peerAddrs
         * @memberof neutrinorpc.IsBannedRequest
         * @instance
         */
        IsBannedRequest.prototype.peerAddrs = "";

        /**
         * Creates a new IsBannedRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {neutrinorpc.IIsBannedRequest=} [properties] Properties to set
         * @returns {neutrinorpc.IsBannedRequest} IsBannedRequest instance
         */
        IsBannedRequest.create = function create(properties) {
            return new IsBannedRequest(properties);
        };

        /**
         * Encodes the specified IsBannedRequest message. Does not implicitly {@link neutrinorpc.IsBannedRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {neutrinorpc.IIsBannedRequest} message IsBannedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IsBannedRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.peerAddrs != null && Object.hasOwnProperty.call(message, "peerAddrs"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.peerAddrs);
            return writer;
        };

        /**
         * Encodes the specified IsBannedRequest message, length delimited. Does not implicitly {@link neutrinorpc.IsBannedRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {neutrinorpc.IIsBannedRequest} message IsBannedRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IsBannedRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IsBannedRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.IsBannedRequest} IsBannedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IsBannedRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.IsBannedRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.peerAddrs = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IsBannedRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.IsBannedRequest} IsBannedRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IsBannedRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IsBannedRequest message.
         * @function verify
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IsBannedRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.peerAddrs != null && message.hasOwnProperty("peerAddrs"))
                if (!$util.isString(message.peerAddrs))
                    return "peerAddrs: string expected";
            return null;
        };

        /**
         * Creates an IsBannedRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.IsBannedRequest} IsBannedRequest
         */
        IsBannedRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.IsBannedRequest)
                return object;
            let message = new $root.neutrinorpc.IsBannedRequest();
            if (object.peerAddrs != null)
                message.peerAddrs = String(object.peerAddrs);
            return message;
        };

        /**
         * Creates a plain object from an IsBannedRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.IsBannedRequest
         * @static
         * @param {neutrinorpc.IsBannedRequest} message IsBannedRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IsBannedRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.peerAddrs = "";
            if (message.peerAddrs != null && message.hasOwnProperty("peerAddrs"))
                object.peerAddrs = message.peerAddrs;
            return object;
        };

        /**
         * Converts this IsBannedRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.IsBannedRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IsBannedRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IsBannedRequest;
    })();

    neutrinorpc.IsBannedResponse = (function() {

        /**
         * Properties of an IsBannedResponse.
         * @memberof neutrinorpc
         * @interface IIsBannedResponse
         * @property {boolean|null} [banned] IsBannedResponse banned
         */

        /**
         * Constructs a new IsBannedResponse.
         * @memberof neutrinorpc
         * @classdesc Represents an IsBannedResponse.
         * @implements IIsBannedResponse
         * @constructor
         * @param {neutrinorpc.IIsBannedResponse=} [properties] Properties to set
         */
        function IsBannedResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IsBannedResponse banned.
         * @member {boolean} banned
         * @memberof neutrinorpc.IsBannedResponse
         * @instance
         */
        IsBannedResponse.prototype.banned = false;

        /**
         * Creates a new IsBannedResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {neutrinorpc.IIsBannedResponse=} [properties] Properties to set
         * @returns {neutrinorpc.IsBannedResponse} IsBannedResponse instance
         */
        IsBannedResponse.create = function create(properties) {
            return new IsBannedResponse(properties);
        };

        /**
         * Encodes the specified IsBannedResponse message. Does not implicitly {@link neutrinorpc.IsBannedResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {neutrinorpc.IIsBannedResponse} message IsBannedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IsBannedResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.banned != null && Object.hasOwnProperty.call(message, "banned"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.banned);
            return writer;
        };

        /**
         * Encodes the specified IsBannedResponse message, length delimited. Does not implicitly {@link neutrinorpc.IsBannedResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {neutrinorpc.IIsBannedResponse} message IsBannedResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IsBannedResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IsBannedResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.IsBannedResponse} IsBannedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IsBannedResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.IsBannedResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.banned = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IsBannedResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.IsBannedResponse} IsBannedResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IsBannedResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IsBannedResponse message.
         * @function verify
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IsBannedResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.banned != null && message.hasOwnProperty("banned"))
                if (typeof message.banned !== "boolean")
                    return "banned: boolean expected";
            return null;
        };

        /**
         * Creates an IsBannedResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.IsBannedResponse} IsBannedResponse
         */
        IsBannedResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.IsBannedResponse)
                return object;
            let message = new $root.neutrinorpc.IsBannedResponse();
            if (object.banned != null)
                message.banned = Boolean(object.banned);
            return message;
        };

        /**
         * Creates a plain object from an IsBannedResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.IsBannedResponse
         * @static
         * @param {neutrinorpc.IsBannedResponse} message IsBannedResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IsBannedResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.banned = false;
            if (message.banned != null && message.hasOwnProperty("banned"))
                object.banned = message.banned;
            return object;
        };

        /**
         * Converts this IsBannedResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.IsBannedResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IsBannedResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IsBannedResponse;
    })();

    neutrinorpc.GetBlockHeaderRequest = (function() {

        /**
         * Properties of a GetBlockHeaderRequest.
         * @memberof neutrinorpc
         * @interface IGetBlockHeaderRequest
         * @property {string|null} [hash] GetBlockHeaderRequest hash
         */

        /**
         * Constructs a new GetBlockHeaderRequest.
         * @memberof neutrinorpc
         * @classdesc Represents a GetBlockHeaderRequest.
         * @implements IGetBlockHeaderRequest
         * @constructor
         * @param {neutrinorpc.IGetBlockHeaderRequest=} [properties] Properties to set
         */
        function GetBlockHeaderRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBlockHeaderRequest hash.
         * @member {string} hash
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @instance
         */
        GetBlockHeaderRequest.prototype.hash = "";

        /**
         * Creates a new GetBlockHeaderRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {neutrinorpc.IGetBlockHeaderRequest=} [properties] Properties to set
         * @returns {neutrinorpc.GetBlockHeaderRequest} GetBlockHeaderRequest instance
         */
        GetBlockHeaderRequest.create = function create(properties) {
            return new GetBlockHeaderRequest(properties);
        };

        /**
         * Encodes the specified GetBlockHeaderRequest message. Does not implicitly {@link neutrinorpc.GetBlockHeaderRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {neutrinorpc.IGetBlockHeaderRequest} message GetBlockHeaderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHeaderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
            return writer;
        };

        /**
         * Encodes the specified GetBlockHeaderRequest message, length delimited. Does not implicitly {@link neutrinorpc.GetBlockHeaderRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {neutrinorpc.IGetBlockHeaderRequest} message GetBlockHeaderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHeaderRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBlockHeaderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetBlockHeaderRequest} GetBlockHeaderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHeaderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetBlockHeaderRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBlockHeaderRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetBlockHeaderRequest} GetBlockHeaderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHeaderRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBlockHeaderRequest message.
         * @function verify
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBlockHeaderRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            return null;
        };

        /**
         * Creates a GetBlockHeaderRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetBlockHeaderRequest} GetBlockHeaderRequest
         */
        GetBlockHeaderRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetBlockHeaderRequest)
                return object;
            let message = new $root.neutrinorpc.GetBlockHeaderRequest();
            if (object.hash != null)
                message.hash = String(object.hash);
            return message;
        };

        /**
         * Creates a plain object from a GetBlockHeaderRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @static
         * @param {neutrinorpc.GetBlockHeaderRequest} message GetBlockHeaderRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBlockHeaderRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.hash = "";
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            return object;
        };

        /**
         * Converts this GetBlockHeaderRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetBlockHeaderRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBlockHeaderRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBlockHeaderRequest;
    })();

    neutrinorpc.GetBlockHeaderResponse = (function() {

        /**
         * Properties of a GetBlockHeaderResponse.
         * @memberof neutrinorpc
         * @interface IGetBlockHeaderResponse
         * @property {string|null} [hash] GetBlockHeaderResponse hash
         * @property {Long|null} [confirmations] GetBlockHeaderResponse confirmations
         * @property {Long|null} [strippedSize] GetBlockHeaderResponse strippedSize
         * @property {Long|null} [size] GetBlockHeaderResponse size
         * @property {Long|null} [weight] GetBlockHeaderResponse weight
         * @property {number|null} [height] GetBlockHeaderResponse height
         * @property {number|null} [version] GetBlockHeaderResponse version
         * @property {string|null} [versionHex] GetBlockHeaderResponse versionHex
         * @property {string|null} [merkleroot] GetBlockHeaderResponse merkleroot
         * @property {Long|null} [time] GetBlockHeaderResponse time
         * @property {number|null} [nonce] GetBlockHeaderResponse nonce
         * @property {string|null} [bits] GetBlockHeaderResponse bits
         * @property {number|null} [ntx] GetBlockHeaderResponse ntx
         * @property {string|null} [previousBlockHash] GetBlockHeaderResponse previousBlockHash
         * @property {Uint8Array|null} [rawHex] GetBlockHeaderResponse rawHex
         */

        /**
         * Constructs a new GetBlockHeaderResponse.
         * @memberof neutrinorpc
         * @classdesc Represents a GetBlockHeaderResponse.
         * @implements IGetBlockHeaderResponse
         * @constructor
         * @param {neutrinorpc.IGetBlockHeaderResponse=} [properties] Properties to set
         */
        function GetBlockHeaderResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBlockHeaderResponse hash.
         * @member {string} hash
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.hash = "";

        /**
         * GetBlockHeaderResponse confirmations.
         * @member {Long} confirmations
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.confirmations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockHeaderResponse strippedSize.
         * @member {Long} strippedSize
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.strippedSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockHeaderResponse size.
         * @member {Long} size
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockHeaderResponse weight.
         * @member {Long} weight
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.weight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockHeaderResponse height.
         * @member {number} height
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.height = 0;

        /**
         * GetBlockHeaderResponse version.
         * @member {number} version
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.version = 0;

        /**
         * GetBlockHeaderResponse versionHex.
         * @member {string} versionHex
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.versionHex = "";

        /**
         * GetBlockHeaderResponse merkleroot.
         * @member {string} merkleroot
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.merkleroot = "";

        /**
         * GetBlockHeaderResponse time.
         * @member {Long} time
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockHeaderResponse nonce.
         * @member {number} nonce
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.nonce = 0;

        /**
         * GetBlockHeaderResponse bits.
         * @member {string} bits
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.bits = "";

        /**
         * GetBlockHeaderResponse ntx.
         * @member {number} ntx
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.ntx = 0;

        /**
         * GetBlockHeaderResponse previousBlockHash.
         * @member {string} previousBlockHash
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.previousBlockHash = "";

        /**
         * GetBlockHeaderResponse rawHex.
         * @member {Uint8Array} rawHex
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         */
        GetBlockHeaderResponse.prototype.rawHex = $util.newBuffer([]);

        /**
         * Creates a new GetBlockHeaderResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {neutrinorpc.IGetBlockHeaderResponse=} [properties] Properties to set
         * @returns {neutrinorpc.GetBlockHeaderResponse} GetBlockHeaderResponse instance
         */
        GetBlockHeaderResponse.create = function create(properties) {
            return new GetBlockHeaderResponse(properties);
        };

        /**
         * Encodes the specified GetBlockHeaderResponse message. Does not implicitly {@link neutrinorpc.GetBlockHeaderResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {neutrinorpc.IGetBlockHeaderResponse} message GetBlockHeaderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHeaderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
            if (message.confirmations != null && Object.hasOwnProperty.call(message, "confirmations"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.confirmations);
            if (message.strippedSize != null && Object.hasOwnProperty.call(message, "strippedSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.strippedSize);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.size);
            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.weight);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.height);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.version);
            if (message.versionHex != null && Object.hasOwnProperty.call(message, "versionHex"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.versionHex);
            if (message.merkleroot != null && Object.hasOwnProperty.call(message, "merkleroot"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.merkleroot);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.time);
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint32(message.nonce);
            if (message.bits != null && Object.hasOwnProperty.call(message, "bits"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.bits);
            if (message.ntx != null && Object.hasOwnProperty.call(message, "ntx"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.ntx);
            if (message.previousBlockHash != null && Object.hasOwnProperty.call(message, "previousBlockHash"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.previousBlockHash);
            if (message.rawHex != null && Object.hasOwnProperty.call(message, "rawHex"))
                writer.uint32(/* id 15, wireType 2 =*/122).bytes(message.rawHex);
            return writer;
        };

        /**
         * Encodes the specified GetBlockHeaderResponse message, length delimited. Does not implicitly {@link neutrinorpc.GetBlockHeaderResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {neutrinorpc.IGetBlockHeaderResponse} message GetBlockHeaderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHeaderResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBlockHeaderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetBlockHeaderResponse} GetBlockHeaderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHeaderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetBlockHeaderResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.string();
                        break;
                    }
                case 2: {
                        message.confirmations = reader.int64();
                        break;
                    }
                case 3: {
                        message.strippedSize = reader.int64();
                        break;
                    }
                case 4: {
                        message.size = reader.int64();
                        break;
                    }
                case 5: {
                        message.weight = reader.int64();
                        break;
                    }
                case 6: {
                        message.height = reader.int32();
                        break;
                    }
                case 7: {
                        message.version = reader.int32();
                        break;
                    }
                case 8: {
                        message.versionHex = reader.string();
                        break;
                    }
                case 9: {
                        message.merkleroot = reader.string();
                        break;
                    }
                case 10: {
                        message.time = reader.int64();
                        break;
                    }
                case 11: {
                        message.nonce = reader.uint32();
                        break;
                    }
                case 12: {
                        message.bits = reader.string();
                        break;
                    }
                case 13: {
                        message.ntx = reader.int32();
                        break;
                    }
                case 14: {
                        message.previousBlockHash = reader.string();
                        break;
                    }
                case 15: {
                        message.rawHex = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBlockHeaderResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetBlockHeaderResponse} GetBlockHeaderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHeaderResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBlockHeaderResponse message.
         * @function verify
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBlockHeaderResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                if (!$util.isInteger(message.confirmations) && !(message.confirmations && $util.isInteger(message.confirmations.low) && $util.isInteger(message.confirmations.high)))
                    return "confirmations: integer|Long expected";
            if (message.strippedSize != null && message.hasOwnProperty("strippedSize"))
                if (!$util.isInteger(message.strippedSize) && !(message.strippedSize && $util.isInteger(message.strippedSize.low) && $util.isInteger(message.strippedSize.high)))
                    return "strippedSize: integer|Long expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (!$util.isInteger(message.weight) && !(message.weight && $util.isInteger(message.weight.low) && $util.isInteger(message.weight.high)))
                    return "weight: integer|Long expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.versionHex != null && message.hasOwnProperty("versionHex"))
                if (!$util.isString(message.versionHex))
                    return "versionHex: string expected";
            if (message.merkleroot != null && message.hasOwnProperty("merkleroot"))
                if (!$util.isString(message.merkleroot))
                    return "merkleroot: string expected";
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!$util.isInteger(message.nonce))
                    return "nonce: integer expected";
            if (message.bits != null && message.hasOwnProperty("bits"))
                if (!$util.isString(message.bits))
                    return "bits: string expected";
            if (message.ntx != null && message.hasOwnProperty("ntx"))
                if (!$util.isInteger(message.ntx))
                    return "ntx: integer expected";
            if (message.previousBlockHash != null && message.hasOwnProperty("previousBlockHash"))
                if (!$util.isString(message.previousBlockHash))
                    return "previousBlockHash: string expected";
            if (message.rawHex != null && message.hasOwnProperty("rawHex"))
                if (!(message.rawHex && typeof message.rawHex.length === "number" || $util.isString(message.rawHex)))
                    return "rawHex: buffer expected";
            return null;
        };

        /**
         * Creates a GetBlockHeaderResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetBlockHeaderResponse} GetBlockHeaderResponse
         */
        GetBlockHeaderResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetBlockHeaderResponse)
                return object;
            let message = new $root.neutrinorpc.GetBlockHeaderResponse();
            if (object.hash != null)
                message.hash = String(object.hash);
            if (object.confirmations != null)
                if ($util.Long)
                    (message.confirmations = $util.Long.fromValue(object.confirmations)).unsigned = false;
                else if (typeof object.confirmations === "string")
                    message.confirmations = parseInt(object.confirmations, 10);
                else if (typeof object.confirmations === "number")
                    message.confirmations = object.confirmations;
                else if (typeof object.confirmations === "object")
                    message.confirmations = new $util.LongBits(object.confirmations.low >>> 0, object.confirmations.high >>> 0).toNumber();
            if (object.strippedSize != null)
                if ($util.Long)
                    (message.strippedSize = $util.Long.fromValue(object.strippedSize)).unsigned = false;
                else if (typeof object.strippedSize === "string")
                    message.strippedSize = parseInt(object.strippedSize, 10);
                else if (typeof object.strippedSize === "number")
                    message.strippedSize = object.strippedSize;
                else if (typeof object.strippedSize === "object")
                    message.strippedSize = new $util.LongBits(object.strippedSize.low >>> 0, object.strippedSize.high >>> 0).toNumber();
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            if (object.weight != null)
                if ($util.Long)
                    (message.weight = $util.Long.fromValue(object.weight)).unsigned = false;
                else if (typeof object.weight === "string")
                    message.weight = parseInt(object.weight, 10);
                else if (typeof object.weight === "number")
                    message.weight = object.weight;
                else if (typeof object.weight === "object")
                    message.weight = new $util.LongBits(object.weight.low >>> 0, object.weight.high >>> 0).toNumber();
            if (object.height != null)
                message.height = object.height | 0;
            if (object.version != null)
                message.version = object.version | 0;
            if (object.versionHex != null)
                message.versionHex = String(object.versionHex);
            if (object.merkleroot != null)
                message.merkleroot = String(object.merkleroot);
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
            if (object.nonce != null)
                message.nonce = object.nonce >>> 0;
            if (object.bits != null)
                message.bits = String(object.bits);
            if (object.ntx != null)
                message.ntx = object.ntx | 0;
            if (object.previousBlockHash != null)
                message.previousBlockHash = String(object.previousBlockHash);
            if (object.rawHex != null)
                if (typeof object.rawHex === "string")
                    $util.base64.decode(object.rawHex, message.rawHex = $util.newBuffer($util.base64.length(object.rawHex)), 0);
                else if (object.rawHex.length)
                    message.rawHex = object.rawHex;
            return message;
        };

        /**
         * Creates a plain object from a GetBlockHeaderResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @static
         * @param {neutrinorpc.GetBlockHeaderResponse} message GetBlockHeaderResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBlockHeaderResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hash = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.confirmations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.confirmations = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.strippedSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.strippedSize = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.weight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.weight = options.longs === String ? "0" : 0;
                object.height = 0;
                object.version = 0;
                object.versionHex = "";
                object.merkleroot = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
                object.nonce = 0;
                object.bits = "";
                object.ntx = 0;
                object.previousBlockHash = "";
                if (options.bytes === String)
                    object.rawHex = "";
                else {
                    object.rawHex = [];
                    if (options.bytes !== Array)
                        object.rawHex = $util.newBuffer(object.rawHex);
                }
            }
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                if (typeof message.confirmations === "number")
                    object.confirmations = options.longs === String ? String(message.confirmations) : message.confirmations;
                else
                    object.confirmations = options.longs === String ? $util.Long.prototype.toString.call(message.confirmations) : options.longs === Number ? new $util.LongBits(message.confirmations.low >>> 0, message.confirmations.high >>> 0).toNumber() : message.confirmations;
            if (message.strippedSize != null && message.hasOwnProperty("strippedSize"))
                if (typeof message.strippedSize === "number")
                    object.strippedSize = options.longs === String ? String(message.strippedSize) : message.strippedSize;
                else
                    object.strippedSize = options.longs === String ? $util.Long.prototype.toString.call(message.strippedSize) : options.longs === Number ? new $util.LongBits(message.strippedSize.low >>> 0, message.strippedSize.high >>> 0).toNumber() : message.strippedSize;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (typeof message.weight === "number")
                    object.weight = options.longs === String ? String(message.weight) : message.weight;
                else
                    object.weight = options.longs === String ? $util.Long.prototype.toString.call(message.weight) : options.longs === Number ? new $util.LongBits(message.weight.low >>> 0, message.weight.high >>> 0).toNumber() : message.weight;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.versionHex != null && message.hasOwnProperty("versionHex"))
                object.versionHex = message.versionHex;
            if (message.merkleroot != null && message.hasOwnProperty("merkleroot"))
                object.merkleroot = message.merkleroot;
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = message.nonce;
            if (message.bits != null && message.hasOwnProperty("bits"))
                object.bits = message.bits;
            if (message.ntx != null && message.hasOwnProperty("ntx"))
                object.ntx = message.ntx;
            if (message.previousBlockHash != null && message.hasOwnProperty("previousBlockHash"))
                object.previousBlockHash = message.previousBlockHash;
            if (message.rawHex != null && message.hasOwnProperty("rawHex"))
                object.rawHex = options.bytes === String ? $util.base64.encode(message.rawHex, 0, message.rawHex.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawHex) : message.rawHex;
            return object;
        };

        /**
         * Converts this GetBlockHeaderResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetBlockHeaderResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBlockHeaderResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBlockHeaderResponse;
    })();

    neutrinorpc.GetBlockRequest = (function() {

        /**
         * Properties of a GetBlockRequest.
         * @memberof neutrinorpc
         * @interface IGetBlockRequest
         * @property {string|null} [hash] GetBlockRequest hash
         */

        /**
         * Constructs a new GetBlockRequest.
         * @memberof neutrinorpc
         * @classdesc Represents a GetBlockRequest.
         * @implements IGetBlockRequest
         * @constructor
         * @param {neutrinorpc.IGetBlockRequest=} [properties] Properties to set
         */
        function GetBlockRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBlockRequest hash.
         * @member {string} hash
         * @memberof neutrinorpc.GetBlockRequest
         * @instance
         */
        GetBlockRequest.prototype.hash = "";

        /**
         * Creates a new GetBlockRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {neutrinorpc.IGetBlockRequest=} [properties] Properties to set
         * @returns {neutrinorpc.GetBlockRequest} GetBlockRequest instance
         */
        GetBlockRequest.create = function create(properties) {
            return new GetBlockRequest(properties);
        };

        /**
         * Encodes the specified GetBlockRequest message. Does not implicitly {@link neutrinorpc.GetBlockRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {neutrinorpc.IGetBlockRequest} message GetBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
            return writer;
        };

        /**
         * Encodes the specified GetBlockRequest message, length delimited. Does not implicitly {@link neutrinorpc.GetBlockRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {neutrinorpc.IGetBlockRequest} message GetBlockRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBlockRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetBlockRequest} GetBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetBlockRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBlockRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetBlockRequest} GetBlockRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBlockRequest message.
         * @function verify
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBlockRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            return null;
        };

        /**
         * Creates a GetBlockRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetBlockRequest} GetBlockRequest
         */
        GetBlockRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetBlockRequest)
                return object;
            let message = new $root.neutrinorpc.GetBlockRequest();
            if (object.hash != null)
                message.hash = String(object.hash);
            return message;
        };

        /**
         * Creates a plain object from a GetBlockRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetBlockRequest
         * @static
         * @param {neutrinorpc.GetBlockRequest} message GetBlockRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBlockRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.hash = "";
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            return object;
        };

        /**
         * Converts this GetBlockRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetBlockRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBlockRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBlockRequest;
    })();

    neutrinorpc.GetBlockResponse = (function() {

        /**
         * Properties of a GetBlockResponse.
         * @memberof neutrinorpc
         * @interface IGetBlockResponse
         * @property {string|null} [hash] GetBlockResponse hash
         * @property {Long|null} [confirmations] GetBlockResponse confirmations
         * @property {Long|null} [strippedSize] GetBlockResponse strippedSize
         * @property {Long|null} [size] GetBlockResponse size
         * @property {Long|null} [weight] GetBlockResponse weight
         * @property {number|null} [height] GetBlockResponse height
         * @property {number|null} [version] GetBlockResponse version
         * @property {string|null} [versionHex] GetBlockResponse versionHex
         * @property {string|null} [merkleroot] GetBlockResponse merkleroot
         * @property {Array.<string>|null} [tx] GetBlockResponse tx
         * @property {Long|null} [time] GetBlockResponse time
         * @property {number|null} [nonce] GetBlockResponse nonce
         * @property {string|null} [bits] GetBlockResponse bits
         * @property {number|null} [ntx] GetBlockResponse ntx
         * @property {string|null} [previousBlockHash] GetBlockResponse previousBlockHash
         * @property {Uint8Array|null} [rawHex] GetBlockResponse rawHex
         */

        /**
         * Constructs a new GetBlockResponse.
         * @memberof neutrinorpc
         * @classdesc Represents a GetBlockResponse.
         * @implements IGetBlockResponse
         * @constructor
         * @param {neutrinorpc.IGetBlockResponse=} [properties] Properties to set
         */
        function GetBlockResponse(properties) {
            this.tx = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBlockResponse hash.
         * @member {string} hash
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.hash = "";

        /**
         * GetBlockResponse confirmations.
         * @member {Long} confirmations
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.confirmations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockResponse strippedSize.
         * @member {Long} strippedSize
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.strippedSize = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockResponse size.
         * @member {Long} size
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockResponse weight.
         * @member {Long} weight
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.weight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockResponse height.
         * @member {number} height
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.height = 0;

        /**
         * GetBlockResponse version.
         * @member {number} version
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.version = 0;

        /**
         * GetBlockResponse versionHex.
         * @member {string} versionHex
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.versionHex = "";

        /**
         * GetBlockResponse merkleroot.
         * @member {string} merkleroot
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.merkleroot = "";

        /**
         * GetBlockResponse tx.
         * @member {Array.<string>} tx
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.tx = $util.emptyArray;

        /**
         * GetBlockResponse time.
         * @member {Long} time
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetBlockResponse nonce.
         * @member {number} nonce
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.nonce = 0;

        /**
         * GetBlockResponse bits.
         * @member {string} bits
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.bits = "";

        /**
         * GetBlockResponse ntx.
         * @member {number} ntx
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.ntx = 0;

        /**
         * GetBlockResponse previousBlockHash.
         * @member {string} previousBlockHash
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.previousBlockHash = "";

        /**
         * GetBlockResponse rawHex.
         * @member {Uint8Array} rawHex
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         */
        GetBlockResponse.prototype.rawHex = $util.newBuffer([]);

        /**
         * Creates a new GetBlockResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {neutrinorpc.IGetBlockResponse=} [properties] Properties to set
         * @returns {neutrinorpc.GetBlockResponse} GetBlockResponse instance
         */
        GetBlockResponse.create = function create(properties) {
            return new GetBlockResponse(properties);
        };

        /**
         * Encodes the specified GetBlockResponse message. Does not implicitly {@link neutrinorpc.GetBlockResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {neutrinorpc.IGetBlockResponse} message GetBlockResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
            if (message.confirmations != null && Object.hasOwnProperty.call(message, "confirmations"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.confirmations);
            if (message.strippedSize != null && Object.hasOwnProperty.call(message, "strippedSize"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.strippedSize);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.size);
            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.weight);
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.height);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.version);
            if (message.versionHex != null && Object.hasOwnProperty.call(message, "versionHex"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.versionHex);
            if (message.merkleroot != null && Object.hasOwnProperty.call(message, "merkleroot"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.merkleroot);
            if (message.tx != null && message.tx.length)
                for (let i = 0; i < message.tx.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.tx[i]);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.time);
            if (message.nonce != null && Object.hasOwnProperty.call(message, "nonce"))
                writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.nonce);
            if (message.bits != null && Object.hasOwnProperty.call(message, "bits"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.bits);
            if (message.ntx != null && Object.hasOwnProperty.call(message, "ntx"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.ntx);
            if (message.previousBlockHash != null && Object.hasOwnProperty.call(message, "previousBlockHash"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.previousBlockHash);
            if (message.rawHex != null && Object.hasOwnProperty.call(message, "rawHex"))
                writer.uint32(/* id 16, wireType 2 =*/130).bytes(message.rawHex);
            return writer;
        };

        /**
         * Encodes the specified GetBlockResponse message, length delimited. Does not implicitly {@link neutrinorpc.GetBlockResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {neutrinorpc.IGetBlockResponse} message GetBlockResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBlockResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetBlockResponse} GetBlockResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetBlockResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.string();
                        break;
                    }
                case 2: {
                        message.confirmations = reader.int64();
                        break;
                    }
                case 3: {
                        message.strippedSize = reader.int64();
                        break;
                    }
                case 4: {
                        message.size = reader.int64();
                        break;
                    }
                case 5: {
                        message.weight = reader.int64();
                        break;
                    }
                case 6: {
                        message.height = reader.int32();
                        break;
                    }
                case 7: {
                        message.version = reader.int32();
                        break;
                    }
                case 8: {
                        message.versionHex = reader.string();
                        break;
                    }
                case 9: {
                        message.merkleroot = reader.string();
                        break;
                    }
                case 10: {
                        if (!(message.tx && message.tx.length))
                            message.tx = [];
                        message.tx.push(reader.string());
                        break;
                    }
                case 11: {
                        message.time = reader.int64();
                        break;
                    }
                case 12: {
                        message.nonce = reader.uint32();
                        break;
                    }
                case 13: {
                        message.bits = reader.string();
                        break;
                    }
                case 14: {
                        message.ntx = reader.int32();
                        break;
                    }
                case 15: {
                        message.previousBlockHash = reader.string();
                        break;
                    }
                case 16: {
                        message.rawHex = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBlockResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetBlockResponse} GetBlockResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBlockResponse message.
         * @function verify
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBlockResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                if (!$util.isInteger(message.confirmations) && !(message.confirmations && $util.isInteger(message.confirmations.low) && $util.isInteger(message.confirmations.high)))
                    return "confirmations: integer|Long expected";
            if (message.strippedSize != null && message.hasOwnProperty("strippedSize"))
                if (!$util.isInteger(message.strippedSize) && !(message.strippedSize && $util.isInteger(message.strippedSize.low) && $util.isInteger(message.strippedSize.high)))
                    return "strippedSize: integer|Long expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (!$util.isInteger(message.weight) && !(message.weight && $util.isInteger(message.weight.low) && $util.isInteger(message.weight.high)))
                    return "weight: integer|Long expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isInteger(message.version))
                    return "version: integer expected";
            if (message.versionHex != null && message.hasOwnProperty("versionHex"))
                if (!$util.isString(message.versionHex))
                    return "versionHex: string expected";
            if (message.merkleroot != null && message.hasOwnProperty("merkleroot"))
                if (!$util.isString(message.merkleroot))
                    return "merkleroot: string expected";
            if (message.tx != null && message.hasOwnProperty("tx")) {
                if (!Array.isArray(message.tx))
                    return "tx: array expected";
                for (let i = 0; i < message.tx.length; ++i)
                    if (!$util.isString(message.tx[i]))
                        return "tx: string[] expected";
            }
            if (message.time != null && message.hasOwnProperty("time"))
                if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                    return "time: integer|Long expected";
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                if (!$util.isInteger(message.nonce))
                    return "nonce: integer expected";
            if (message.bits != null && message.hasOwnProperty("bits"))
                if (!$util.isString(message.bits))
                    return "bits: string expected";
            if (message.ntx != null && message.hasOwnProperty("ntx"))
                if (!$util.isInteger(message.ntx))
                    return "ntx: integer expected";
            if (message.previousBlockHash != null && message.hasOwnProperty("previousBlockHash"))
                if (!$util.isString(message.previousBlockHash))
                    return "previousBlockHash: string expected";
            if (message.rawHex != null && message.hasOwnProperty("rawHex"))
                if (!(message.rawHex && typeof message.rawHex.length === "number" || $util.isString(message.rawHex)))
                    return "rawHex: buffer expected";
            return null;
        };

        /**
         * Creates a GetBlockResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetBlockResponse} GetBlockResponse
         */
        GetBlockResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetBlockResponse)
                return object;
            let message = new $root.neutrinorpc.GetBlockResponse();
            if (object.hash != null)
                message.hash = String(object.hash);
            if (object.confirmations != null)
                if ($util.Long)
                    (message.confirmations = $util.Long.fromValue(object.confirmations)).unsigned = false;
                else if (typeof object.confirmations === "string")
                    message.confirmations = parseInt(object.confirmations, 10);
                else if (typeof object.confirmations === "number")
                    message.confirmations = object.confirmations;
                else if (typeof object.confirmations === "object")
                    message.confirmations = new $util.LongBits(object.confirmations.low >>> 0, object.confirmations.high >>> 0).toNumber();
            if (object.strippedSize != null)
                if ($util.Long)
                    (message.strippedSize = $util.Long.fromValue(object.strippedSize)).unsigned = false;
                else if (typeof object.strippedSize === "string")
                    message.strippedSize = parseInt(object.strippedSize, 10);
                else if (typeof object.strippedSize === "number")
                    message.strippedSize = object.strippedSize;
                else if (typeof object.strippedSize === "object")
                    message.strippedSize = new $util.LongBits(object.strippedSize.low >>> 0, object.strippedSize.high >>> 0).toNumber();
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            if (object.weight != null)
                if ($util.Long)
                    (message.weight = $util.Long.fromValue(object.weight)).unsigned = false;
                else if (typeof object.weight === "string")
                    message.weight = parseInt(object.weight, 10);
                else if (typeof object.weight === "number")
                    message.weight = object.weight;
                else if (typeof object.weight === "object")
                    message.weight = new $util.LongBits(object.weight.low >>> 0, object.weight.high >>> 0).toNumber();
            if (object.height != null)
                message.height = object.height | 0;
            if (object.version != null)
                message.version = object.version | 0;
            if (object.versionHex != null)
                message.versionHex = String(object.versionHex);
            if (object.merkleroot != null)
                message.merkleroot = String(object.merkleroot);
            if (object.tx) {
                if (!Array.isArray(object.tx))
                    throw TypeError(".neutrinorpc.GetBlockResponse.tx: array expected");
                message.tx = [];
                for (let i = 0; i < object.tx.length; ++i)
                    message.tx[i] = String(object.tx[i]);
            }
            if (object.time != null)
                if ($util.Long)
                    (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                else if (typeof object.time === "string")
                    message.time = parseInt(object.time, 10);
                else if (typeof object.time === "number")
                    message.time = object.time;
                else if (typeof object.time === "object")
                    message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
            if (object.nonce != null)
                message.nonce = object.nonce >>> 0;
            if (object.bits != null)
                message.bits = String(object.bits);
            if (object.ntx != null)
                message.ntx = object.ntx | 0;
            if (object.previousBlockHash != null)
                message.previousBlockHash = String(object.previousBlockHash);
            if (object.rawHex != null)
                if (typeof object.rawHex === "string")
                    $util.base64.decode(object.rawHex, message.rawHex = $util.newBuffer($util.base64.length(object.rawHex)), 0);
                else if (object.rawHex.length)
                    message.rawHex = object.rawHex;
            return message;
        };

        /**
         * Creates a plain object from a GetBlockResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetBlockResponse
         * @static
         * @param {neutrinorpc.GetBlockResponse} message GetBlockResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBlockResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tx = [];
            if (options.defaults) {
                object.hash = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.confirmations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.confirmations = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.strippedSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.strippedSize = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.weight = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.weight = options.longs === String ? "0" : 0;
                object.height = 0;
                object.version = 0;
                object.versionHex = "";
                object.merkleroot = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.time = options.longs === String ? "0" : 0;
                object.nonce = 0;
                object.bits = "";
                object.ntx = 0;
                object.previousBlockHash = "";
                if (options.bytes === String)
                    object.rawHex = "";
                else {
                    object.rawHex = [];
                    if (options.bytes !== Array)
                        object.rawHex = $util.newBuffer(object.rawHex);
                }
            }
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            if (message.confirmations != null && message.hasOwnProperty("confirmations"))
                if (typeof message.confirmations === "number")
                    object.confirmations = options.longs === String ? String(message.confirmations) : message.confirmations;
                else
                    object.confirmations = options.longs === String ? $util.Long.prototype.toString.call(message.confirmations) : options.longs === Number ? new $util.LongBits(message.confirmations.low >>> 0, message.confirmations.high >>> 0).toNumber() : message.confirmations;
            if (message.strippedSize != null && message.hasOwnProperty("strippedSize"))
                if (typeof message.strippedSize === "number")
                    object.strippedSize = options.longs === String ? String(message.strippedSize) : message.strippedSize;
                else
                    object.strippedSize = options.longs === String ? $util.Long.prototype.toString.call(message.strippedSize) : options.longs === Number ? new $util.LongBits(message.strippedSize.low >>> 0, message.strippedSize.high >>> 0).toNumber() : message.strippedSize;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (typeof message.weight === "number")
                    object.weight = options.longs === String ? String(message.weight) : message.weight;
                else
                    object.weight = options.longs === String ? $util.Long.prototype.toString.call(message.weight) : options.longs === Number ? new $util.LongBits(message.weight.low >>> 0, message.weight.high >>> 0).toNumber() : message.weight;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.versionHex != null && message.hasOwnProperty("versionHex"))
                object.versionHex = message.versionHex;
            if (message.merkleroot != null && message.hasOwnProperty("merkleroot"))
                object.merkleroot = message.merkleroot;
            if (message.tx && message.tx.length) {
                object.tx = [];
                for (let j = 0; j < message.tx.length; ++j)
                    object.tx[j] = message.tx[j];
            }
            if (message.time != null && message.hasOwnProperty("time"))
                if (typeof message.time === "number")
                    object.time = options.longs === String ? String(message.time) : message.time;
                else
                    object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
            if (message.nonce != null && message.hasOwnProperty("nonce"))
                object.nonce = message.nonce;
            if (message.bits != null && message.hasOwnProperty("bits"))
                object.bits = message.bits;
            if (message.ntx != null && message.hasOwnProperty("ntx"))
                object.ntx = message.ntx;
            if (message.previousBlockHash != null && message.hasOwnProperty("previousBlockHash"))
                object.previousBlockHash = message.previousBlockHash;
            if (message.rawHex != null && message.hasOwnProperty("rawHex"))
                object.rawHex = options.bytes === String ? $util.base64.encode(message.rawHex, 0, message.rawHex.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawHex) : message.rawHex;
            return object;
        };

        /**
         * Converts this GetBlockResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetBlockResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBlockResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBlockResponse;
    })();

    neutrinorpc.GetCFilterRequest = (function() {

        /**
         * Properties of a GetCFilterRequest.
         * @memberof neutrinorpc
         * @interface IGetCFilterRequest
         * @property {string|null} [hash] GetCFilterRequest hash
         */

        /**
         * Constructs a new GetCFilterRequest.
         * @memberof neutrinorpc
         * @classdesc Represents a GetCFilterRequest.
         * @implements IGetCFilterRequest
         * @constructor
         * @param {neutrinorpc.IGetCFilterRequest=} [properties] Properties to set
         */
        function GetCFilterRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCFilterRequest hash.
         * @member {string} hash
         * @memberof neutrinorpc.GetCFilterRequest
         * @instance
         */
        GetCFilterRequest.prototype.hash = "";

        /**
         * Creates a new GetCFilterRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {neutrinorpc.IGetCFilterRequest=} [properties] Properties to set
         * @returns {neutrinorpc.GetCFilterRequest} GetCFilterRequest instance
         */
        GetCFilterRequest.create = function create(properties) {
            return new GetCFilterRequest(properties);
        };

        /**
         * Encodes the specified GetCFilterRequest message. Does not implicitly {@link neutrinorpc.GetCFilterRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {neutrinorpc.IGetCFilterRequest} message GetCFilterRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCFilterRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
            return writer;
        };

        /**
         * Encodes the specified GetCFilterRequest message, length delimited. Does not implicitly {@link neutrinorpc.GetCFilterRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {neutrinorpc.IGetCFilterRequest} message GetCFilterRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCFilterRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCFilterRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetCFilterRequest} GetCFilterRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCFilterRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetCFilterRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCFilterRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetCFilterRequest} GetCFilterRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCFilterRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCFilterRequest message.
         * @function verify
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCFilterRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            return null;
        };

        /**
         * Creates a GetCFilterRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetCFilterRequest} GetCFilterRequest
         */
        GetCFilterRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetCFilterRequest)
                return object;
            let message = new $root.neutrinorpc.GetCFilterRequest();
            if (object.hash != null)
                message.hash = String(object.hash);
            return message;
        };

        /**
         * Creates a plain object from a GetCFilterRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetCFilterRequest
         * @static
         * @param {neutrinorpc.GetCFilterRequest} message GetCFilterRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCFilterRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.hash = "";
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            return object;
        };

        /**
         * Converts this GetCFilterRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetCFilterRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCFilterRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetCFilterRequest;
    })();

    neutrinorpc.GetCFilterResponse = (function() {

        /**
         * Properties of a GetCFilterResponse.
         * @memberof neutrinorpc
         * @interface IGetCFilterResponse
         * @property {Uint8Array|null} [filter] GetCFilterResponse filter
         */

        /**
         * Constructs a new GetCFilterResponse.
         * @memberof neutrinorpc
         * @classdesc Represents a GetCFilterResponse.
         * @implements IGetCFilterResponse
         * @constructor
         * @param {neutrinorpc.IGetCFilterResponse=} [properties] Properties to set
         */
        function GetCFilterResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCFilterResponse filter.
         * @member {Uint8Array} filter
         * @memberof neutrinorpc.GetCFilterResponse
         * @instance
         */
        GetCFilterResponse.prototype.filter = $util.newBuffer([]);

        /**
         * Creates a new GetCFilterResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {neutrinorpc.IGetCFilterResponse=} [properties] Properties to set
         * @returns {neutrinorpc.GetCFilterResponse} GetCFilterResponse instance
         */
        GetCFilterResponse.create = function create(properties) {
            return new GetCFilterResponse(properties);
        };

        /**
         * Encodes the specified GetCFilterResponse message. Does not implicitly {@link neutrinorpc.GetCFilterResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {neutrinorpc.IGetCFilterResponse} message GetCFilterResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCFilterResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filter != null && Object.hasOwnProperty.call(message, "filter"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.filter);
            return writer;
        };

        /**
         * Encodes the specified GetCFilterResponse message, length delimited. Does not implicitly {@link neutrinorpc.GetCFilterResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {neutrinorpc.IGetCFilterResponse} message GetCFilterResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCFilterResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetCFilterResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetCFilterResponse} GetCFilterResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCFilterResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetCFilterResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.filter = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetCFilterResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetCFilterResponse} GetCFilterResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCFilterResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetCFilterResponse message.
         * @function verify
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetCFilterResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filter != null && message.hasOwnProperty("filter"))
                if (!(message.filter && typeof message.filter.length === "number" || $util.isString(message.filter)))
                    return "filter: buffer expected";
            return null;
        };

        /**
         * Creates a GetCFilterResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetCFilterResponse} GetCFilterResponse
         */
        GetCFilterResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetCFilterResponse)
                return object;
            let message = new $root.neutrinorpc.GetCFilterResponse();
            if (object.filter != null)
                if (typeof object.filter === "string")
                    $util.base64.decode(object.filter, message.filter = $util.newBuffer($util.base64.length(object.filter)), 0);
                else if (object.filter.length)
                    message.filter = object.filter;
            return message;
        };

        /**
         * Creates a plain object from a GetCFilterResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetCFilterResponse
         * @static
         * @param {neutrinorpc.GetCFilterResponse} message GetCFilterResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetCFilterResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.filter = "";
                else {
                    object.filter = [];
                    if (options.bytes !== Array)
                        object.filter = $util.newBuffer(object.filter);
                }
            if (message.filter != null && message.hasOwnProperty("filter"))
                object.filter = options.bytes === String ? $util.base64.encode(message.filter, 0, message.filter.length) : options.bytes === Array ? Array.prototype.slice.call(message.filter) : message.filter;
            return object;
        };

        /**
         * Converts this GetCFilterResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetCFilterResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetCFilterResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetCFilterResponse;
    })();

    neutrinorpc.GetBlockHashRequest = (function() {

        /**
         * Properties of a GetBlockHashRequest.
         * @memberof neutrinorpc
         * @interface IGetBlockHashRequest
         * @property {number|null} [height] GetBlockHashRequest height
         */

        /**
         * Constructs a new GetBlockHashRequest.
         * @memberof neutrinorpc
         * @classdesc Represents a GetBlockHashRequest.
         * @implements IGetBlockHashRequest
         * @constructor
         * @param {neutrinorpc.IGetBlockHashRequest=} [properties] Properties to set
         */
        function GetBlockHashRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBlockHashRequest height.
         * @member {number} height
         * @memberof neutrinorpc.GetBlockHashRequest
         * @instance
         */
        GetBlockHashRequest.prototype.height = 0;

        /**
         * Creates a new GetBlockHashRequest instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {neutrinorpc.IGetBlockHashRequest=} [properties] Properties to set
         * @returns {neutrinorpc.GetBlockHashRequest} GetBlockHashRequest instance
         */
        GetBlockHashRequest.create = function create(properties) {
            return new GetBlockHashRequest(properties);
        };

        /**
         * Encodes the specified GetBlockHashRequest message. Does not implicitly {@link neutrinorpc.GetBlockHashRequest.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {neutrinorpc.IGetBlockHashRequest} message GetBlockHashRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHashRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.height != null && Object.hasOwnProperty.call(message, "height"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.height);
            return writer;
        };

        /**
         * Encodes the specified GetBlockHashRequest message, length delimited. Does not implicitly {@link neutrinorpc.GetBlockHashRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {neutrinorpc.IGetBlockHashRequest} message GetBlockHashRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHashRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBlockHashRequest message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetBlockHashRequest} GetBlockHashRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHashRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetBlockHashRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.height = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBlockHashRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetBlockHashRequest} GetBlockHashRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHashRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBlockHashRequest message.
         * @function verify
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBlockHashRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.height != null && message.hasOwnProperty("height"))
                if (!$util.isInteger(message.height))
                    return "height: integer expected";
            return null;
        };

        /**
         * Creates a GetBlockHashRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetBlockHashRequest} GetBlockHashRequest
         */
        GetBlockHashRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetBlockHashRequest)
                return object;
            let message = new $root.neutrinorpc.GetBlockHashRequest();
            if (object.height != null)
                message.height = object.height | 0;
            return message;
        };

        /**
         * Creates a plain object from a GetBlockHashRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetBlockHashRequest
         * @static
         * @param {neutrinorpc.GetBlockHashRequest} message GetBlockHashRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBlockHashRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.height = 0;
            if (message.height != null && message.hasOwnProperty("height"))
                object.height = message.height;
            return object;
        };

        /**
         * Converts this GetBlockHashRequest to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetBlockHashRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBlockHashRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBlockHashRequest;
    })();

    neutrinorpc.GetBlockHashResponse = (function() {

        /**
         * Properties of a GetBlockHashResponse.
         * @memberof neutrinorpc
         * @interface IGetBlockHashResponse
         * @property {string|null} [hash] GetBlockHashResponse hash
         */

        /**
         * Constructs a new GetBlockHashResponse.
         * @memberof neutrinorpc
         * @classdesc Represents a GetBlockHashResponse.
         * @implements IGetBlockHashResponse
         * @constructor
         * @param {neutrinorpc.IGetBlockHashResponse=} [properties] Properties to set
         */
        function GetBlockHashResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBlockHashResponse hash.
         * @member {string} hash
         * @memberof neutrinorpc.GetBlockHashResponse
         * @instance
         */
        GetBlockHashResponse.prototype.hash = "";

        /**
         * Creates a new GetBlockHashResponse instance using the specified properties.
         * @function create
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {neutrinorpc.IGetBlockHashResponse=} [properties] Properties to set
         * @returns {neutrinorpc.GetBlockHashResponse} GetBlockHashResponse instance
         */
        GetBlockHashResponse.create = function create(properties) {
            return new GetBlockHashResponse(properties);
        };

        /**
         * Encodes the specified GetBlockHashResponse message. Does not implicitly {@link neutrinorpc.GetBlockHashResponse.verify|verify} messages.
         * @function encode
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {neutrinorpc.IGetBlockHashResponse} message GetBlockHashResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHashResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hash != null && Object.hasOwnProperty.call(message, "hash"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.hash);
            return writer;
        };

        /**
         * Encodes the specified GetBlockHashResponse message, length delimited. Does not implicitly {@link neutrinorpc.GetBlockHashResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {neutrinorpc.IGetBlockHashResponse} message GetBlockHashResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBlockHashResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBlockHashResponse message from the specified reader or buffer.
         * @function decode
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {neutrinorpc.GetBlockHashResponse} GetBlockHashResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHashResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.neutrinorpc.GetBlockHashResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hash = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBlockHashResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {neutrinorpc.GetBlockHashResponse} GetBlockHashResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBlockHashResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBlockHashResponse message.
         * @function verify
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBlockHashResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hash != null && message.hasOwnProperty("hash"))
                if (!$util.isString(message.hash))
                    return "hash: string expected";
            return null;
        };

        /**
         * Creates a GetBlockHashResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {neutrinorpc.GetBlockHashResponse} GetBlockHashResponse
         */
        GetBlockHashResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.neutrinorpc.GetBlockHashResponse)
                return object;
            let message = new $root.neutrinorpc.GetBlockHashResponse();
            if (object.hash != null)
                message.hash = String(object.hash);
            return message;
        };

        /**
         * Creates a plain object from a GetBlockHashResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof neutrinorpc.GetBlockHashResponse
         * @static
         * @param {neutrinorpc.GetBlockHashResponse} message GetBlockHashResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBlockHashResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.hash = "";
            if (message.hash != null && message.hasOwnProperty("hash"))
                object.hash = message.hash;
            return object;
        };

        /**
         * Converts this GetBlockHashResponse to JSON.
         * @function toJSON
         * @memberof neutrinorpc.GetBlockHashResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBlockHashResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBlockHashResponse;
    })();

    return neutrinorpc;
})();

export const peersrpc = $root.peersrpc = (() => {

    /**
     * Namespace peersrpc.
     * @exports peersrpc
     * @namespace
     */
    const peersrpc = {};

    peersrpc.Peers = (function() {

        /**
         * Constructs a new Peers service.
         * @memberof peersrpc
         * @classdesc Represents a Peers
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Peers(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Peers.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Peers;

        /**
         * Creates new Peers service using the specified rpc implementation.
         * @function create
         * @memberof peersrpc.Peers
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Peers} RPC service. Useful where requests and/or responses are streamed.
         */
        Peers.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link peersrpc.Peers#updateNodeAnnouncement}.
         * @memberof peersrpc.Peers
         * @typedef UpdateNodeAnnouncementCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {peersrpc.NodeAnnouncementUpdateResponse} [response] NodeAnnouncementUpdateResponse
         */

        /**
         * Calls UpdateNodeAnnouncement.
         * @function updateNodeAnnouncement
         * @memberof peersrpc.Peers
         * @instance
         * @param {peersrpc.INodeAnnouncementUpdateRequest} request NodeAnnouncementUpdateRequest message or plain object
         * @param {peersrpc.Peers.UpdateNodeAnnouncementCallback} callback Node-style callback called with the error, if any, and NodeAnnouncementUpdateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Peers.prototype.updateNodeAnnouncement = function updateNodeAnnouncement(request, callback) {
            return this.rpcCall(updateNodeAnnouncement, $root.peersrpc.NodeAnnouncementUpdateRequest, $root.peersrpc.NodeAnnouncementUpdateResponse, request, callback);
        }, "name", { value: "UpdateNodeAnnouncement" });

        /**
         * Calls UpdateNodeAnnouncement.
         * @function updateNodeAnnouncement
         * @memberof peersrpc.Peers
         * @instance
         * @param {peersrpc.INodeAnnouncementUpdateRequest} request NodeAnnouncementUpdateRequest message or plain object
         * @returns {Promise<peersrpc.NodeAnnouncementUpdateResponse>} Promise
         * @variation 2
         */

        return Peers;
    })();

    /**
     * UpdateAction enum.
     * @name peersrpc.UpdateAction
     * @enum {number}
     * @property {number} ADD=0 ADD value
     * @property {number} REMOVE=1 REMOVE value
     */
    peersrpc.UpdateAction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ADD"] = 0;
        values[valuesById[1] = "REMOVE"] = 1;
        return values;
    })();

    /**
     * FeatureSet enum.
     * @name peersrpc.FeatureSet
     * @enum {number}
     * @property {number} SET_INIT=0 SET_INIT value
     * @property {number} SET_LEGACY_GLOBAL=1 SET_LEGACY_GLOBAL value
     * @property {number} SET_NODE_ANN=2 SET_NODE_ANN value
     * @property {number} SET_INVOICE=3 SET_INVOICE value
     * @property {number} SET_INVOICE_AMP=4 SET_INVOICE_AMP value
     */
    peersrpc.FeatureSet = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SET_INIT"] = 0;
        values[valuesById[1] = "SET_LEGACY_GLOBAL"] = 1;
        values[valuesById[2] = "SET_NODE_ANN"] = 2;
        values[valuesById[3] = "SET_INVOICE"] = 3;
        values[valuesById[4] = "SET_INVOICE_AMP"] = 4;
        return values;
    })();

    peersrpc.UpdateAddressAction = (function() {

        /**
         * Properties of an UpdateAddressAction.
         * @memberof peersrpc
         * @interface IUpdateAddressAction
         * @property {peersrpc.UpdateAction|null} [action] UpdateAddressAction action
         * @property {string|null} [address] UpdateAddressAction address
         */

        /**
         * Constructs a new UpdateAddressAction.
         * @memberof peersrpc
         * @classdesc Represents an UpdateAddressAction.
         * @implements IUpdateAddressAction
         * @constructor
         * @param {peersrpc.IUpdateAddressAction=} [properties] Properties to set
         */
        function UpdateAddressAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateAddressAction action.
         * @member {peersrpc.UpdateAction} action
         * @memberof peersrpc.UpdateAddressAction
         * @instance
         */
        UpdateAddressAction.prototype.action = 0;

        /**
         * UpdateAddressAction address.
         * @member {string} address
         * @memberof peersrpc.UpdateAddressAction
         * @instance
         */
        UpdateAddressAction.prototype.address = "";

        /**
         * Creates a new UpdateAddressAction instance using the specified properties.
         * @function create
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {peersrpc.IUpdateAddressAction=} [properties] Properties to set
         * @returns {peersrpc.UpdateAddressAction} UpdateAddressAction instance
         */
        UpdateAddressAction.create = function create(properties) {
            return new UpdateAddressAction(properties);
        };

        /**
         * Encodes the specified UpdateAddressAction message. Does not implicitly {@link peersrpc.UpdateAddressAction.verify|verify} messages.
         * @function encode
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {peersrpc.IUpdateAddressAction} message UpdateAddressAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateAddressAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.action);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
            return writer;
        };

        /**
         * Encodes the specified UpdateAddressAction message, length delimited. Does not implicitly {@link peersrpc.UpdateAddressAction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {peersrpc.IUpdateAddressAction} message UpdateAddressAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateAddressAction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateAddressAction message from the specified reader or buffer.
         * @function decode
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {peersrpc.UpdateAddressAction} UpdateAddressAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateAddressAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.peersrpc.UpdateAddressAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.int32();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateAddressAction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {peersrpc.UpdateAddressAction} UpdateAddressAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateAddressAction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateAddressAction message.
         * @function verify
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateAddressAction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            return null;
        };

        /**
         * Creates an UpdateAddressAction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {peersrpc.UpdateAddressAction} UpdateAddressAction
         */
        UpdateAddressAction.fromObject = function fromObject(object) {
            if (object instanceof $root.peersrpc.UpdateAddressAction)
                return object;
            let message = new $root.peersrpc.UpdateAddressAction();
            switch (object.action) {
            case "ADD":
            case 0:
                message.action = 0;
                break;
            case "REMOVE":
            case 1:
                message.action = 1;
                break;
            }
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        /**
         * Creates a plain object from an UpdateAddressAction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof peersrpc.UpdateAddressAction
         * @static
         * @param {peersrpc.UpdateAddressAction} message UpdateAddressAction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateAddressAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = options.enums === String ? "ADD" : 0;
                object.address = "";
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = options.enums === String ? $root.peersrpc.UpdateAction[message.action] : message.action;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            return object;
        };

        /**
         * Converts this UpdateAddressAction to JSON.
         * @function toJSON
         * @memberof peersrpc.UpdateAddressAction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateAddressAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateAddressAction;
    })();

    peersrpc.UpdateFeatureAction = (function() {

        /**
         * Properties of an UpdateFeatureAction.
         * @memberof peersrpc
         * @interface IUpdateFeatureAction
         * @property {peersrpc.UpdateAction|null} [action] UpdateFeatureAction action
         * @property {lnrpc.FeatureBit|null} [featureBit] UpdateFeatureAction featureBit
         */

        /**
         * Constructs a new UpdateFeatureAction.
         * @memberof peersrpc
         * @classdesc Represents an UpdateFeatureAction.
         * @implements IUpdateFeatureAction
         * @constructor
         * @param {peersrpc.IUpdateFeatureAction=} [properties] Properties to set
         */
        function UpdateFeatureAction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateFeatureAction action.
         * @member {peersrpc.UpdateAction} action
         * @memberof peersrpc.UpdateFeatureAction
         * @instance
         */
        UpdateFeatureAction.prototype.action = 0;

        /**
         * UpdateFeatureAction featureBit.
         * @member {lnrpc.FeatureBit} featureBit
         * @memberof peersrpc.UpdateFeatureAction
         * @instance
         */
        UpdateFeatureAction.prototype.featureBit = 0;

        /**
         * Creates a new UpdateFeatureAction instance using the specified properties.
         * @function create
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {peersrpc.IUpdateFeatureAction=} [properties] Properties to set
         * @returns {peersrpc.UpdateFeatureAction} UpdateFeatureAction instance
         */
        UpdateFeatureAction.create = function create(properties) {
            return new UpdateFeatureAction(properties);
        };

        /**
         * Encodes the specified UpdateFeatureAction message. Does not implicitly {@link peersrpc.UpdateFeatureAction.verify|verify} messages.
         * @function encode
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {peersrpc.IUpdateFeatureAction} message UpdateFeatureAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFeatureAction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.action);
            if (message.featureBit != null && Object.hasOwnProperty.call(message, "featureBit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.featureBit);
            return writer;
        };

        /**
         * Encodes the specified UpdateFeatureAction message, length delimited. Does not implicitly {@link peersrpc.UpdateFeatureAction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {peersrpc.IUpdateFeatureAction} message UpdateFeatureAction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateFeatureAction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateFeatureAction message from the specified reader or buffer.
         * @function decode
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {peersrpc.UpdateFeatureAction} UpdateFeatureAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFeatureAction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.peersrpc.UpdateFeatureAction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.action = reader.int32();
                        break;
                    }
                case 2: {
                        message.featureBit = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateFeatureAction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {peersrpc.UpdateFeatureAction} UpdateFeatureAction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateFeatureAction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateFeatureAction message.
         * @function verify
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateFeatureAction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.featureBit != null && message.hasOwnProperty("featureBit"))
                switch (message.featureBit) {
                default:
                    return "featureBit: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 30:
                case 31:
                    break;
                }
            return null;
        };

        /**
         * Creates an UpdateFeatureAction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {peersrpc.UpdateFeatureAction} UpdateFeatureAction
         */
        UpdateFeatureAction.fromObject = function fromObject(object) {
            if (object instanceof $root.peersrpc.UpdateFeatureAction)
                return object;
            let message = new $root.peersrpc.UpdateFeatureAction();
            switch (object.action) {
            case "ADD":
            case 0:
                message.action = 0;
                break;
            case "REMOVE":
            case 1:
                message.action = 1;
                break;
            }
            switch (object.featureBit) {
            case "DATALOSS_PROTECT_REQ":
            case 0:
                message.featureBit = 0;
                break;
            case "DATALOSS_PROTECT_OPT":
            case 1:
                message.featureBit = 1;
                break;
            case "INITIAL_ROUING_SYNC":
            case 3:
                message.featureBit = 3;
                break;
            case "UPFRONT_SHUTDOWN_SCRIPT_REQ":
            case 4:
                message.featureBit = 4;
                break;
            case "UPFRONT_SHUTDOWN_SCRIPT_OPT":
            case 5:
                message.featureBit = 5;
                break;
            case "GOSSIP_QUERIES_REQ":
            case 6:
                message.featureBit = 6;
                break;
            case "GOSSIP_QUERIES_OPT":
            case 7:
                message.featureBit = 7;
                break;
            case "TLV_ONION_REQ":
            case 8:
                message.featureBit = 8;
                break;
            case "TLV_ONION_OPT":
            case 9:
                message.featureBit = 9;
                break;
            case "EXT_GOSSIP_QUERIES_REQ":
            case 10:
                message.featureBit = 10;
                break;
            case "EXT_GOSSIP_QUERIES_OPT":
            case 11:
                message.featureBit = 11;
                break;
            case "STATIC_REMOTE_KEY_REQ":
            case 12:
                message.featureBit = 12;
                break;
            case "STATIC_REMOTE_KEY_OPT":
            case 13:
                message.featureBit = 13;
                break;
            case "PAYMENT_ADDR_REQ":
            case 14:
                message.featureBit = 14;
                break;
            case "PAYMENT_ADDR_OPT":
            case 15:
                message.featureBit = 15;
                break;
            case "MPP_REQ":
            case 16:
                message.featureBit = 16;
                break;
            case "MPP_OPT":
            case 17:
                message.featureBit = 17;
                break;
            case "WUMBO_CHANNELS_REQ":
            case 18:
                message.featureBit = 18;
                break;
            case "WUMBO_CHANNELS_OPT":
            case 19:
                message.featureBit = 19;
                break;
            case "ANCHORS_REQ":
            case 20:
                message.featureBit = 20;
                break;
            case "ANCHORS_OPT":
            case 21:
                message.featureBit = 21;
                break;
            case "ANCHORS_ZERO_FEE_HTLC_REQ":
            case 22:
                message.featureBit = 22;
                break;
            case "ANCHORS_ZERO_FEE_HTLC_OPT":
            case 23:
                message.featureBit = 23;
                break;
            case "AMP_REQ":
            case 30:
                message.featureBit = 30;
                break;
            case "AMP_OPT":
            case 31:
                message.featureBit = 31;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateFeatureAction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof peersrpc.UpdateFeatureAction
         * @static
         * @param {peersrpc.UpdateFeatureAction} message UpdateFeatureAction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateFeatureAction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.action = options.enums === String ? "ADD" : 0;
                object.featureBit = options.enums === String ? "DATALOSS_PROTECT_REQ" : 0;
            }
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = options.enums === String ? $root.peersrpc.UpdateAction[message.action] : message.action;
            if (message.featureBit != null && message.hasOwnProperty("featureBit"))
                object.featureBit = options.enums === String ? $root.lnrpc.FeatureBit[message.featureBit] : message.featureBit;
            return object;
        };

        /**
         * Converts this UpdateFeatureAction to JSON.
         * @function toJSON
         * @memberof peersrpc.UpdateFeatureAction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateFeatureAction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateFeatureAction;
    })();

    peersrpc.NodeAnnouncementUpdateRequest = (function() {

        /**
         * Properties of a NodeAnnouncementUpdateRequest.
         * @memberof peersrpc
         * @interface INodeAnnouncementUpdateRequest
         * @property {Array.<peersrpc.IUpdateFeatureAction>|null} [featureUpdates] NodeAnnouncementUpdateRequest featureUpdates
         * @property {string|null} [color] NodeAnnouncementUpdateRequest color
         * @property {string|null} [alias] NodeAnnouncementUpdateRequest alias
         * @property {Array.<peersrpc.IUpdateAddressAction>|null} [addressUpdates] NodeAnnouncementUpdateRequest addressUpdates
         */

        /**
         * Constructs a new NodeAnnouncementUpdateRequest.
         * @memberof peersrpc
         * @classdesc Represents a NodeAnnouncementUpdateRequest.
         * @implements INodeAnnouncementUpdateRequest
         * @constructor
         * @param {peersrpc.INodeAnnouncementUpdateRequest=} [properties] Properties to set
         */
        function NodeAnnouncementUpdateRequest(properties) {
            this.featureUpdates = [];
            this.addressUpdates = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeAnnouncementUpdateRequest featureUpdates.
         * @member {Array.<peersrpc.IUpdateFeatureAction>} featureUpdates
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @instance
         */
        NodeAnnouncementUpdateRequest.prototype.featureUpdates = $util.emptyArray;

        /**
         * NodeAnnouncementUpdateRequest color.
         * @member {string} color
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @instance
         */
        NodeAnnouncementUpdateRequest.prototype.color = "";

        /**
         * NodeAnnouncementUpdateRequest alias.
         * @member {string} alias
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @instance
         */
        NodeAnnouncementUpdateRequest.prototype.alias = "";

        /**
         * NodeAnnouncementUpdateRequest addressUpdates.
         * @member {Array.<peersrpc.IUpdateAddressAction>} addressUpdates
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @instance
         */
        NodeAnnouncementUpdateRequest.prototype.addressUpdates = $util.emptyArray;

        /**
         * Creates a new NodeAnnouncementUpdateRequest instance using the specified properties.
         * @function create
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {peersrpc.INodeAnnouncementUpdateRequest=} [properties] Properties to set
         * @returns {peersrpc.NodeAnnouncementUpdateRequest} NodeAnnouncementUpdateRequest instance
         */
        NodeAnnouncementUpdateRequest.create = function create(properties) {
            return new NodeAnnouncementUpdateRequest(properties);
        };

        /**
         * Encodes the specified NodeAnnouncementUpdateRequest message. Does not implicitly {@link peersrpc.NodeAnnouncementUpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {peersrpc.INodeAnnouncementUpdateRequest} message NodeAnnouncementUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeAnnouncementUpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.featureUpdates != null && message.featureUpdates.length)
                for (let i = 0; i < message.featureUpdates.length; ++i)
                    $root.peersrpc.UpdateFeatureAction.encode(message.featureUpdates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.color != null && Object.hasOwnProperty.call(message, "color"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.color);
            if (message.alias != null && Object.hasOwnProperty.call(message, "alias"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.alias);
            if (message.addressUpdates != null && message.addressUpdates.length)
                for (let i = 0; i < message.addressUpdates.length; ++i)
                    $root.peersrpc.UpdateAddressAction.encode(message.addressUpdates[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NodeAnnouncementUpdateRequest message, length delimited. Does not implicitly {@link peersrpc.NodeAnnouncementUpdateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {peersrpc.INodeAnnouncementUpdateRequest} message NodeAnnouncementUpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeAnnouncementUpdateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeAnnouncementUpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {peersrpc.NodeAnnouncementUpdateRequest} NodeAnnouncementUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeAnnouncementUpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.peersrpc.NodeAnnouncementUpdateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.featureUpdates && message.featureUpdates.length))
                            message.featureUpdates = [];
                        message.featureUpdates.push($root.peersrpc.UpdateFeatureAction.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.color = reader.string();
                        break;
                    }
                case 3: {
                        message.alias = reader.string();
                        break;
                    }
                case 4: {
                        if (!(message.addressUpdates && message.addressUpdates.length))
                            message.addressUpdates = [];
                        message.addressUpdates.push($root.peersrpc.UpdateAddressAction.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeAnnouncementUpdateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {peersrpc.NodeAnnouncementUpdateRequest} NodeAnnouncementUpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeAnnouncementUpdateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeAnnouncementUpdateRequest message.
         * @function verify
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeAnnouncementUpdateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.featureUpdates != null && message.hasOwnProperty("featureUpdates")) {
                if (!Array.isArray(message.featureUpdates))
                    return "featureUpdates: array expected";
                for (let i = 0; i < message.featureUpdates.length; ++i) {
                    let error = $root.peersrpc.UpdateFeatureAction.verify(message.featureUpdates[i]);
                    if (error)
                        return "featureUpdates." + error;
                }
            }
            if (message.color != null && message.hasOwnProperty("color"))
                if (!$util.isString(message.color))
                    return "color: string expected";
            if (message.alias != null && message.hasOwnProperty("alias"))
                if (!$util.isString(message.alias))
                    return "alias: string expected";
            if (message.addressUpdates != null && message.hasOwnProperty("addressUpdates")) {
                if (!Array.isArray(message.addressUpdates))
                    return "addressUpdates: array expected";
                for (let i = 0; i < message.addressUpdates.length; ++i) {
                    let error = $root.peersrpc.UpdateAddressAction.verify(message.addressUpdates[i]);
                    if (error)
                        return "addressUpdates." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NodeAnnouncementUpdateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {peersrpc.NodeAnnouncementUpdateRequest} NodeAnnouncementUpdateRequest
         */
        NodeAnnouncementUpdateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.peersrpc.NodeAnnouncementUpdateRequest)
                return object;
            let message = new $root.peersrpc.NodeAnnouncementUpdateRequest();
            if (object.featureUpdates) {
                if (!Array.isArray(object.featureUpdates))
                    throw TypeError(".peersrpc.NodeAnnouncementUpdateRequest.featureUpdates: array expected");
                message.featureUpdates = [];
                for (let i = 0; i < object.featureUpdates.length; ++i) {
                    if (typeof object.featureUpdates[i] !== "object")
                        throw TypeError(".peersrpc.NodeAnnouncementUpdateRequest.featureUpdates: object expected");
                    message.featureUpdates[i] = $root.peersrpc.UpdateFeatureAction.fromObject(object.featureUpdates[i]);
                }
            }
            if (object.color != null)
                message.color = String(object.color);
            if (object.alias != null)
                message.alias = String(object.alias);
            if (object.addressUpdates) {
                if (!Array.isArray(object.addressUpdates))
                    throw TypeError(".peersrpc.NodeAnnouncementUpdateRequest.addressUpdates: array expected");
                message.addressUpdates = [];
                for (let i = 0; i < object.addressUpdates.length; ++i) {
                    if (typeof object.addressUpdates[i] !== "object")
                        throw TypeError(".peersrpc.NodeAnnouncementUpdateRequest.addressUpdates: object expected");
                    message.addressUpdates[i] = $root.peersrpc.UpdateAddressAction.fromObject(object.addressUpdates[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NodeAnnouncementUpdateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @static
         * @param {peersrpc.NodeAnnouncementUpdateRequest} message NodeAnnouncementUpdateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeAnnouncementUpdateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.featureUpdates = [];
                object.addressUpdates = [];
            }
            if (options.defaults) {
                object.color = "";
                object.alias = "";
            }
            if (message.featureUpdates && message.featureUpdates.length) {
                object.featureUpdates = [];
                for (let j = 0; j < message.featureUpdates.length; ++j)
                    object.featureUpdates[j] = $root.peersrpc.UpdateFeatureAction.toObject(message.featureUpdates[j], options);
            }
            if (message.color != null && message.hasOwnProperty("color"))
                object.color = message.color;
            if (message.alias != null && message.hasOwnProperty("alias"))
                object.alias = message.alias;
            if (message.addressUpdates && message.addressUpdates.length) {
                object.addressUpdates = [];
                for (let j = 0; j < message.addressUpdates.length; ++j)
                    object.addressUpdates[j] = $root.peersrpc.UpdateAddressAction.toObject(message.addressUpdates[j], options);
            }
            return object;
        };

        /**
         * Converts this NodeAnnouncementUpdateRequest to JSON.
         * @function toJSON
         * @memberof peersrpc.NodeAnnouncementUpdateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeAnnouncementUpdateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeAnnouncementUpdateRequest;
    })();

    peersrpc.NodeAnnouncementUpdateResponse = (function() {

        /**
         * Properties of a NodeAnnouncementUpdateResponse.
         * @memberof peersrpc
         * @interface INodeAnnouncementUpdateResponse
         * @property {Array.<lnrpc.IOp>|null} [ops] NodeAnnouncementUpdateResponse ops
         */

        /**
         * Constructs a new NodeAnnouncementUpdateResponse.
         * @memberof peersrpc
         * @classdesc Represents a NodeAnnouncementUpdateResponse.
         * @implements INodeAnnouncementUpdateResponse
         * @constructor
         * @param {peersrpc.INodeAnnouncementUpdateResponse=} [properties] Properties to set
         */
        function NodeAnnouncementUpdateResponse(properties) {
            this.ops = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NodeAnnouncementUpdateResponse ops.
         * @member {Array.<lnrpc.IOp>} ops
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @instance
         */
        NodeAnnouncementUpdateResponse.prototype.ops = $util.emptyArray;

        /**
         * Creates a new NodeAnnouncementUpdateResponse instance using the specified properties.
         * @function create
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {peersrpc.INodeAnnouncementUpdateResponse=} [properties] Properties to set
         * @returns {peersrpc.NodeAnnouncementUpdateResponse} NodeAnnouncementUpdateResponse instance
         */
        NodeAnnouncementUpdateResponse.create = function create(properties) {
            return new NodeAnnouncementUpdateResponse(properties);
        };

        /**
         * Encodes the specified NodeAnnouncementUpdateResponse message. Does not implicitly {@link peersrpc.NodeAnnouncementUpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {peersrpc.INodeAnnouncementUpdateResponse} message NodeAnnouncementUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeAnnouncementUpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ops != null && message.ops.length)
                for (let i = 0; i < message.ops.length; ++i)
                    $root.lnrpc.Op.encode(message.ops[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NodeAnnouncementUpdateResponse message, length delimited. Does not implicitly {@link peersrpc.NodeAnnouncementUpdateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {peersrpc.INodeAnnouncementUpdateResponse} message NodeAnnouncementUpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NodeAnnouncementUpdateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NodeAnnouncementUpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {peersrpc.NodeAnnouncementUpdateResponse} NodeAnnouncementUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeAnnouncementUpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.peersrpc.NodeAnnouncementUpdateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.ops && message.ops.length))
                            message.ops = [];
                        message.ops.push($root.lnrpc.Op.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NodeAnnouncementUpdateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {peersrpc.NodeAnnouncementUpdateResponse} NodeAnnouncementUpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NodeAnnouncementUpdateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NodeAnnouncementUpdateResponse message.
         * @function verify
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NodeAnnouncementUpdateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ops != null && message.hasOwnProperty("ops")) {
                if (!Array.isArray(message.ops))
                    return "ops: array expected";
                for (let i = 0; i < message.ops.length; ++i) {
                    let error = $root.lnrpc.Op.verify(message.ops[i]);
                    if (error)
                        return "ops." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NodeAnnouncementUpdateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {peersrpc.NodeAnnouncementUpdateResponse} NodeAnnouncementUpdateResponse
         */
        NodeAnnouncementUpdateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.peersrpc.NodeAnnouncementUpdateResponse)
                return object;
            let message = new $root.peersrpc.NodeAnnouncementUpdateResponse();
            if (object.ops) {
                if (!Array.isArray(object.ops))
                    throw TypeError(".peersrpc.NodeAnnouncementUpdateResponse.ops: array expected");
                message.ops = [];
                for (let i = 0; i < object.ops.length; ++i) {
                    if (typeof object.ops[i] !== "object")
                        throw TypeError(".peersrpc.NodeAnnouncementUpdateResponse.ops: object expected");
                    message.ops[i] = $root.lnrpc.Op.fromObject(object.ops[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NodeAnnouncementUpdateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @static
         * @param {peersrpc.NodeAnnouncementUpdateResponse} message NodeAnnouncementUpdateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NodeAnnouncementUpdateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.ops = [];
            if (message.ops && message.ops.length) {
                object.ops = [];
                for (let j = 0; j < message.ops.length; ++j)
                    object.ops[j] = $root.lnrpc.Op.toObject(message.ops[j], options);
            }
            return object;
        };

        /**
         * Converts this NodeAnnouncementUpdateResponse to JSON.
         * @function toJSON
         * @memberof peersrpc.NodeAnnouncementUpdateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NodeAnnouncementUpdateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NodeAnnouncementUpdateResponse;
    })();

    return peersrpc;
})();

export const routerrpc = $root.routerrpc = (() => {

    /**
     * Namespace routerrpc.
     * @exports routerrpc
     * @namespace
     */
    const routerrpc = {};

    routerrpc.Router = (function() {

        /**
         * Constructs a new Router service.
         * @memberof routerrpc
         * @classdesc Represents a Router
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Router(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Router.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Router;

        /**
         * Creates new Router service using the specified rpc implementation.
         * @function create
         * @memberof routerrpc.Router
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Router} RPC service. Useful where requests and/or responses are streamed.
         */
        Router.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link routerrpc.Router#sendPaymentV2}.
         * @memberof routerrpc.Router
         * @typedef SendPaymentV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Payment} [response] Payment
         */

        /**
         * Calls SendPaymentV2.
         * @function sendPaymentV2
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendPaymentRequest} request SendPaymentRequest message or plain object
         * @param {routerrpc.Router.SendPaymentV2Callback} callback Node-style callback called with the error, if any, and Payment
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.sendPaymentV2 = function sendPaymentV2(request, callback) {
            return this.rpcCall(sendPaymentV2, $root.routerrpc.SendPaymentRequest, $root.lnrpc.Payment, request, callback);
        }, "name", { value: "SendPaymentV2" });

        /**
         * Calls SendPaymentV2.
         * @function sendPaymentV2
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendPaymentRequest} request SendPaymentRequest message or plain object
         * @returns {Promise<lnrpc.Payment>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#trackPaymentV2}.
         * @memberof routerrpc.Router
         * @typedef TrackPaymentV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.Payment} [response] Payment
         */

        /**
         * Calls TrackPaymentV2.
         * @function trackPaymentV2
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ITrackPaymentRequest} request TrackPaymentRequest message or plain object
         * @param {routerrpc.Router.TrackPaymentV2Callback} callback Node-style callback called with the error, if any, and Payment
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.trackPaymentV2 = function trackPaymentV2(request, callback) {
            return this.rpcCall(trackPaymentV2, $root.routerrpc.TrackPaymentRequest, $root.lnrpc.Payment, request, callback);
        }, "name", { value: "TrackPaymentV2" });

        /**
         * Calls TrackPaymentV2.
         * @function trackPaymentV2
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ITrackPaymentRequest} request TrackPaymentRequest message or plain object
         * @returns {Promise<lnrpc.Payment>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#estimateRouteFee}.
         * @memberof routerrpc.Router
         * @typedef EstimateRouteFeeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.RouteFeeResponse} [response] RouteFeeResponse
         */

        /**
         * Calls EstimateRouteFee.
         * @function estimateRouteFee
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IRouteFeeRequest} request RouteFeeRequest message or plain object
         * @param {routerrpc.Router.EstimateRouteFeeCallback} callback Node-style callback called with the error, if any, and RouteFeeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.estimateRouteFee = function estimateRouteFee(request, callback) {
            return this.rpcCall(estimateRouteFee, $root.routerrpc.RouteFeeRequest, $root.routerrpc.RouteFeeResponse, request, callback);
        }, "name", { value: "EstimateRouteFee" });

        /**
         * Calls EstimateRouteFee.
         * @function estimateRouteFee
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IRouteFeeRequest} request RouteFeeRequest message or plain object
         * @returns {Promise<routerrpc.RouteFeeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#sendToRoute}.
         * @memberof routerrpc.Router
         * @typedef SendToRouteCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.SendToRouteResponse} [response] SendToRouteResponse
         */

        /**
         * Calls SendToRoute.
         * @function sendToRoute
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @param {routerrpc.Router.SendToRouteCallback} callback Node-style callback called with the error, if any, and SendToRouteResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.sendToRoute = function sendToRoute(request, callback) {
            return this.rpcCall(sendToRoute, $root.routerrpc.SendToRouteRequest, $root.routerrpc.SendToRouteResponse, request, callback);
        }, "name", { value: "SendToRoute" });

        /**
         * Calls SendToRoute.
         * @function sendToRoute
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @returns {Promise<routerrpc.SendToRouteResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#sendToRouteV2}.
         * @memberof routerrpc.Router
         * @typedef SendToRouteV2Callback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {lnrpc.HTLCAttempt} [response] HTLCAttempt
         */

        /**
         * Calls SendToRouteV2.
         * @function sendToRouteV2
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @param {routerrpc.Router.SendToRouteV2Callback} callback Node-style callback called with the error, if any, and HTLCAttempt
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.sendToRouteV2 = function sendToRouteV2(request, callback) {
            return this.rpcCall(sendToRouteV2, $root.routerrpc.SendToRouteRequest, $root.lnrpc.HTLCAttempt, request, callback);
        }, "name", { value: "SendToRouteV2" });

        /**
         * Calls SendToRouteV2.
         * @function sendToRouteV2
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendToRouteRequest} request SendToRouteRequest message or plain object
         * @returns {Promise<lnrpc.HTLCAttempt>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#resetMissionControl}.
         * @memberof routerrpc.Router
         * @typedef ResetMissionControlCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.ResetMissionControlResponse} [response] ResetMissionControlResponse
         */

        /**
         * Calls ResetMissionControl.
         * @function resetMissionControl
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IResetMissionControlRequest} request ResetMissionControlRequest message or plain object
         * @param {routerrpc.Router.ResetMissionControlCallback} callback Node-style callback called with the error, if any, and ResetMissionControlResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.resetMissionControl = function resetMissionControl(request, callback) {
            return this.rpcCall(resetMissionControl, $root.routerrpc.ResetMissionControlRequest, $root.routerrpc.ResetMissionControlResponse, request, callback);
        }, "name", { value: "ResetMissionControl" });

        /**
         * Calls ResetMissionControl.
         * @function resetMissionControl
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IResetMissionControlRequest} request ResetMissionControlRequest message or plain object
         * @returns {Promise<routerrpc.ResetMissionControlResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#queryMissionControl}.
         * @memberof routerrpc.Router
         * @typedef QueryMissionControlCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.QueryMissionControlResponse} [response] QueryMissionControlResponse
         */

        /**
         * Calls QueryMissionControl.
         * @function queryMissionControl
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IQueryMissionControlRequest} request QueryMissionControlRequest message or plain object
         * @param {routerrpc.Router.QueryMissionControlCallback} callback Node-style callback called with the error, if any, and QueryMissionControlResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.queryMissionControl = function queryMissionControl(request, callback) {
            return this.rpcCall(queryMissionControl, $root.routerrpc.QueryMissionControlRequest, $root.routerrpc.QueryMissionControlResponse, request, callback);
        }, "name", { value: "QueryMissionControl" });

        /**
         * Calls QueryMissionControl.
         * @function queryMissionControl
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IQueryMissionControlRequest} request QueryMissionControlRequest message or plain object
         * @returns {Promise<routerrpc.QueryMissionControlResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#xImportMissionControl}.
         * @memberof routerrpc.Router
         * @typedef XImportMissionControlCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.XImportMissionControlResponse} [response] XImportMissionControlResponse
         */

        /**
         * Calls XImportMissionControl.
         * @function xImportMissionControl
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IXImportMissionControlRequest} request XImportMissionControlRequest message or plain object
         * @param {routerrpc.Router.XImportMissionControlCallback} callback Node-style callback called with the error, if any, and XImportMissionControlResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.xImportMissionControl = function xImportMissionControl(request, callback) {
            return this.rpcCall(xImportMissionControl, $root.routerrpc.XImportMissionControlRequest, $root.routerrpc.XImportMissionControlResponse, request, callback);
        }, "name", { value: "XImportMissionControl" });

        /**
         * Calls XImportMissionControl.
         * @function xImportMissionControl
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IXImportMissionControlRequest} request XImportMissionControlRequest message or plain object
         * @returns {Promise<routerrpc.XImportMissionControlResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#getMissionControlConfig}.
         * @memberof routerrpc.Router
         * @typedef GetMissionControlConfigCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.GetMissionControlConfigResponse} [response] GetMissionControlConfigResponse
         */

        /**
         * Calls GetMissionControlConfig.
         * @function getMissionControlConfig
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IGetMissionControlConfigRequest} request GetMissionControlConfigRequest message or plain object
         * @param {routerrpc.Router.GetMissionControlConfigCallback} callback Node-style callback called with the error, if any, and GetMissionControlConfigResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.getMissionControlConfig = function getMissionControlConfig(request, callback) {
            return this.rpcCall(getMissionControlConfig, $root.routerrpc.GetMissionControlConfigRequest, $root.routerrpc.GetMissionControlConfigResponse, request, callback);
        }, "name", { value: "GetMissionControlConfig" });

        /**
         * Calls GetMissionControlConfig.
         * @function getMissionControlConfig
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IGetMissionControlConfigRequest} request GetMissionControlConfigRequest message or plain object
         * @returns {Promise<routerrpc.GetMissionControlConfigResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#setMissionControlConfig}.
         * @memberof routerrpc.Router
         * @typedef SetMissionControlConfigCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.SetMissionControlConfigResponse} [response] SetMissionControlConfigResponse
         */

        /**
         * Calls SetMissionControlConfig.
         * @function setMissionControlConfig
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISetMissionControlConfigRequest} request SetMissionControlConfigRequest message or plain object
         * @param {routerrpc.Router.SetMissionControlConfigCallback} callback Node-style callback called with the error, if any, and SetMissionControlConfigResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.setMissionControlConfig = function setMissionControlConfig(request, callback) {
            return this.rpcCall(setMissionControlConfig, $root.routerrpc.SetMissionControlConfigRequest, $root.routerrpc.SetMissionControlConfigResponse, request, callback);
        }, "name", { value: "SetMissionControlConfig" });

        /**
         * Calls SetMissionControlConfig.
         * @function setMissionControlConfig
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISetMissionControlConfigRequest} request SetMissionControlConfigRequest message or plain object
         * @returns {Promise<routerrpc.SetMissionControlConfigResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#queryProbability}.
         * @memberof routerrpc.Router
         * @typedef QueryProbabilityCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.QueryProbabilityResponse} [response] QueryProbabilityResponse
         */

        /**
         * Calls QueryProbability.
         * @function queryProbability
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IQueryProbabilityRequest} request QueryProbabilityRequest message or plain object
         * @param {routerrpc.Router.QueryProbabilityCallback} callback Node-style callback called with the error, if any, and QueryProbabilityResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.queryProbability = function queryProbability(request, callback) {
            return this.rpcCall(queryProbability, $root.routerrpc.QueryProbabilityRequest, $root.routerrpc.QueryProbabilityResponse, request, callback);
        }, "name", { value: "QueryProbability" });

        /**
         * Calls QueryProbability.
         * @function queryProbability
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IQueryProbabilityRequest} request QueryProbabilityRequest message or plain object
         * @returns {Promise<routerrpc.QueryProbabilityResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#buildRoute}.
         * @memberof routerrpc.Router
         * @typedef BuildRouteCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.BuildRouteResponse} [response] BuildRouteResponse
         */

        /**
         * Calls BuildRoute.
         * @function buildRoute
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IBuildRouteRequest} request BuildRouteRequest message or plain object
         * @param {routerrpc.Router.BuildRouteCallback} callback Node-style callback called with the error, if any, and BuildRouteResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.buildRoute = function buildRoute(request, callback) {
            return this.rpcCall(buildRoute, $root.routerrpc.BuildRouteRequest, $root.routerrpc.BuildRouteResponse, request, callback);
        }, "name", { value: "BuildRoute" });

        /**
         * Calls BuildRoute.
         * @function buildRoute
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IBuildRouteRequest} request BuildRouteRequest message or plain object
         * @returns {Promise<routerrpc.BuildRouteResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#subscribeHtlcEvents}.
         * @memberof routerrpc.Router
         * @typedef SubscribeHtlcEventsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.HtlcEvent} [response] HtlcEvent
         */

        /**
         * Calls SubscribeHtlcEvents.
         * @function subscribeHtlcEvents
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISubscribeHtlcEventsRequest} request SubscribeHtlcEventsRequest message or plain object
         * @param {routerrpc.Router.SubscribeHtlcEventsCallback} callback Node-style callback called with the error, if any, and HtlcEvent
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.subscribeHtlcEvents = function subscribeHtlcEvents(request, callback) {
            return this.rpcCall(subscribeHtlcEvents, $root.routerrpc.SubscribeHtlcEventsRequest, $root.routerrpc.HtlcEvent, request, callback);
        }, "name", { value: "SubscribeHtlcEvents" });

        /**
         * Calls SubscribeHtlcEvents.
         * @function subscribeHtlcEvents
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISubscribeHtlcEventsRequest} request SubscribeHtlcEventsRequest message or plain object
         * @returns {Promise<routerrpc.HtlcEvent>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#sendPayment}.
         * @memberof routerrpc.Router
         * @typedef SendPaymentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.PaymentStatus} [response] PaymentStatus
         */

        /**
         * Calls SendPayment.
         * @function sendPayment
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendPaymentRequest} request SendPaymentRequest message or plain object
         * @param {routerrpc.Router.SendPaymentCallback} callback Node-style callback called with the error, if any, and PaymentStatus
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.sendPayment = function sendPayment(request, callback) {
            return this.rpcCall(sendPayment, $root.routerrpc.SendPaymentRequest, $root.routerrpc.PaymentStatus, request, callback);
        }, "name", { value: "SendPayment" });

        /**
         * Calls SendPayment.
         * @function sendPayment
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ISendPaymentRequest} request SendPaymentRequest message or plain object
         * @returns {Promise<routerrpc.PaymentStatus>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#trackPayment}.
         * @memberof routerrpc.Router
         * @typedef TrackPaymentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.PaymentStatus} [response] PaymentStatus
         */

        /**
         * Calls TrackPayment.
         * @function trackPayment
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ITrackPaymentRequest} request TrackPaymentRequest message or plain object
         * @param {routerrpc.Router.TrackPaymentCallback} callback Node-style callback called with the error, if any, and PaymentStatus
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.trackPayment = function trackPayment(request, callback) {
            return this.rpcCall(trackPayment, $root.routerrpc.TrackPaymentRequest, $root.routerrpc.PaymentStatus, request, callback);
        }, "name", { value: "TrackPayment" });

        /**
         * Calls TrackPayment.
         * @function trackPayment
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.ITrackPaymentRequest} request TrackPaymentRequest message or plain object
         * @returns {Promise<routerrpc.PaymentStatus>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#htlcInterceptor}.
         * @memberof routerrpc.Router
         * @typedef HtlcInterceptorCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.ForwardHtlcInterceptRequest} [response] ForwardHtlcInterceptRequest
         */

        /**
         * HtlcInterceptor dispatches a bi-directional streaming RPC in which
         * Forwarded HTLC requests are sent to the client and the client responds with
         * a boolean that tells LND if this htlc should be intercepted.
         * In case of interception, the htlc can be either settled, cancelled or
         * resumed later by using the ResolveHoldForward endpoint.
         * @function htlcInterceptor
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IForwardHtlcInterceptResponse} request ForwardHtlcInterceptResponse message or plain object
         * @param {routerrpc.Router.HtlcInterceptorCallback} callback Node-style callback called with the error, if any, and ForwardHtlcInterceptRequest
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.htlcInterceptor = function htlcInterceptor(request, callback) {
            return this.rpcCall(htlcInterceptor, $root.routerrpc.ForwardHtlcInterceptResponse, $root.routerrpc.ForwardHtlcInterceptRequest, request, callback);
        }, "name", { value: "HtlcInterceptor" });

        /**
         * HtlcInterceptor dispatches a bi-directional streaming RPC in which
         * Forwarded HTLC requests are sent to the client and the client responds with
         * a boolean that tells LND if this htlc should be intercepted.
         * In case of interception, the htlc can be either settled, cancelled or
         * resumed later by using the ResolveHoldForward endpoint.
         * @function htlcInterceptor
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IForwardHtlcInterceptResponse} request ForwardHtlcInterceptResponse message or plain object
         * @returns {Promise<routerrpc.ForwardHtlcInterceptRequest>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link routerrpc.Router#updateChanStatus}.
         * @memberof routerrpc.Router
         * @typedef UpdateChanStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {routerrpc.UpdateChanStatusResponse} [response] UpdateChanStatusResponse
         */

        /**
         * Calls UpdateChanStatus.
         * @function updateChanStatus
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IUpdateChanStatusRequest} request UpdateChanStatusRequest message or plain object
         * @param {routerrpc.Router.UpdateChanStatusCallback} callback Node-style callback called with the error, if any, and UpdateChanStatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Router.prototype.updateChanStatus = function updateChanStatus(request, callback) {
            return this.rpcCall(updateChanStatus, $root.routerrpc.UpdateChanStatusRequest, $root.routerrpc.UpdateChanStatusResponse, request, callback);
        }, "name", { value: "UpdateChanStatus" });

        /**
         * Calls UpdateChanStatus.
         * @function updateChanStatus
         * @memberof routerrpc.Router
         * @instance
         * @param {routerrpc.IUpdateChanStatusRequest} request UpdateChanStatusRequest message or plain object
         * @returns {Promise<routerrpc.UpdateChanStatusResponse>} Promise
         * @variation 2
         */

        return Router;
    })();

    routerrpc.SendPaymentRequest = (function() {

        /**
         * Properties of a SendPaymentRequest.
         * @memberof routerrpc
         * @interface ISendPaymentRequest
         * @property {Uint8Array|null} [dest] SendPaymentRequest dest
         * @property {Long|null} [amt] SendPaymentRequest amt
         * @property {Long|null} [amtMsat] SendPaymentRequest amtMsat
         * @property {Uint8Array|null} [paymentHash] SendPaymentRequest paymentHash
         * @property {number|null} [finalCltvDelta] SendPaymentRequest finalCltvDelta
         * @property {Uint8Array|null} [paymentAddr] SendPaymentRequest paymentAddr
         * @property {string|null} [paymentRequest] SendPaymentRequest paymentRequest
         * @property {number|null} [timeoutSeconds] SendPaymentRequest timeoutSeconds
         * @property {Long|null} [feeLimitSat] SendPaymentRequest feeLimitSat
         * @property {Long|null} [feeLimitMsat] SendPaymentRequest feeLimitMsat
         * @property {Long|null} [outgoingChanId] SendPaymentRequest outgoingChanId
         * @property {Array.<Long>|null} [outgoingChanIds] SendPaymentRequest outgoingChanIds
         * @property {Uint8Array|null} [lastHopPubkey] SendPaymentRequest lastHopPubkey
         * @property {number|null} [cltvLimit] SendPaymentRequest cltvLimit
         * @property {Array.<lnrpc.IRouteHint>|null} [routeHints] SendPaymentRequest routeHints
         * @property {Object.<string,Uint8Array>|null} [destCustomRecords] SendPaymentRequest destCustomRecords
         * @property {boolean|null} [allowSelfPayment] SendPaymentRequest allowSelfPayment
         * @property {Array.<lnrpc.FeatureBit>|null} [destFeatures] SendPaymentRequest destFeatures
         * @property {number|null} [maxParts] SendPaymentRequest maxParts
         * @property {boolean|null} [noInflightUpdates] SendPaymentRequest noInflightUpdates
         * @property {Long|null} [maxShardSizeMsat] SendPaymentRequest maxShardSizeMsat
         * @property {boolean|null} [amp] SendPaymentRequest amp
         * @property {number|null} [timePref] SendPaymentRequest timePref
         */

        /**
         * Constructs a new SendPaymentRequest.
         * @memberof routerrpc
         * @classdesc Represents a SendPaymentRequest.
         * @implements ISendPaymentRequest
         * @constructor
         * @param {routerrpc.ISendPaymentRequest=} [properties] Properties to set
         */
        function SendPaymentRequest(properties) {
            this.outgoingChanIds = [];
            this.routeHints = [];
            this.destCustomRecords = {};
            this.destFeatures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendPaymentRequest dest.
         * @member {Uint8Array} dest
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.dest = $util.newBuffer([]);

        /**
         * SendPaymentRequest amt.
         * @member {Long} amt
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.amt = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendPaymentRequest amtMsat.
         * @member {Long} amtMsat
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.amtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendPaymentRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * SendPaymentRequest finalCltvDelta.
         * @member {number} finalCltvDelta
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.finalCltvDelta = 0;

        /**
         * SendPaymentRequest paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * SendPaymentRequest paymentRequest.
         * @member {string} paymentRequest
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.paymentRequest = "";

        /**
         * SendPaymentRequest timeoutSeconds.
         * @member {number} timeoutSeconds
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.timeoutSeconds = 0;

        /**
         * SendPaymentRequest feeLimitSat.
         * @member {Long} feeLimitSat
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.feeLimitSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendPaymentRequest feeLimitMsat.
         * @member {Long} feeLimitMsat
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.feeLimitMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendPaymentRequest outgoingChanId.
         * @member {Long} outgoingChanId
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.outgoingChanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SendPaymentRequest outgoingChanIds.
         * @member {Array.<Long>} outgoingChanIds
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.outgoingChanIds = $util.emptyArray;

        /**
         * SendPaymentRequest lastHopPubkey.
         * @member {Uint8Array} lastHopPubkey
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.lastHopPubkey = $util.newBuffer([]);

        /**
         * SendPaymentRequest cltvLimit.
         * @member {number} cltvLimit
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.cltvLimit = 0;

        /**
         * SendPaymentRequest routeHints.
         * @member {Array.<lnrpc.IRouteHint>} routeHints
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.routeHints = $util.emptyArray;

        /**
         * SendPaymentRequest destCustomRecords.
         * @member {Object.<string,Uint8Array>} destCustomRecords
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.destCustomRecords = $util.emptyObject;

        /**
         * SendPaymentRequest allowSelfPayment.
         * @member {boolean} allowSelfPayment
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.allowSelfPayment = false;

        /**
         * SendPaymentRequest destFeatures.
         * @member {Array.<lnrpc.FeatureBit>} destFeatures
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.destFeatures = $util.emptyArray;

        /**
         * SendPaymentRequest maxParts.
         * @member {number} maxParts
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.maxParts = 0;

        /**
         * SendPaymentRequest noInflightUpdates.
         * @member {boolean} noInflightUpdates
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.noInflightUpdates = false;

        /**
         * SendPaymentRequest maxShardSizeMsat.
         * @member {Long} maxShardSizeMsat
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.maxShardSizeMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * SendPaymentRequest amp.
         * @member {boolean} amp
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.amp = false;

        /**
         * SendPaymentRequest timePref.
         * @member {number} timePref
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         */
        SendPaymentRequest.prototype.timePref = 0;

        /**
         * Creates a new SendPaymentRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {routerrpc.ISendPaymentRequest=} [properties] Properties to set
         * @returns {routerrpc.SendPaymentRequest} SendPaymentRequest instance
         */
        SendPaymentRequest.create = function create(properties) {
            return new SendPaymentRequest(properties);
        };

        /**
         * Encodes the specified SendPaymentRequest message. Does not implicitly {@link routerrpc.SendPaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {routerrpc.ISendPaymentRequest} message SendPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendPaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dest != null && Object.hasOwnProperty.call(message, "dest"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dest);
            if (message.amt != null && Object.hasOwnProperty.call(message, "amt"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amt);
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.paymentHash);
            if (message.finalCltvDelta != null && Object.hasOwnProperty.call(message, "finalCltvDelta"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.finalCltvDelta);
            if (message.paymentRequest != null && Object.hasOwnProperty.call(message, "paymentRequest"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.paymentRequest);
            if (message.timeoutSeconds != null && Object.hasOwnProperty.call(message, "timeoutSeconds"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.timeoutSeconds);
            if (message.feeLimitSat != null && Object.hasOwnProperty.call(message, "feeLimitSat"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.feeLimitSat);
            if (message.outgoingChanId != null && Object.hasOwnProperty.call(message, "outgoingChanId"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.outgoingChanId);
            if (message.cltvLimit != null && Object.hasOwnProperty.call(message, "cltvLimit"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.cltvLimit);
            if (message.routeHints != null && message.routeHints.length)
                for (let i = 0; i < message.routeHints.length; ++i)
                    $root.lnrpc.RouteHint.encode(message.routeHints[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.destCustomRecords != null && Object.hasOwnProperty.call(message, "destCustomRecords"))
                for (let keys = Object.keys(message.destCustomRecords), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 0 =*/8).uint64(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.destCustomRecords[keys[i]]).ldelim();
            if (message.amtMsat != null && Object.hasOwnProperty.call(message, "amtMsat"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.amtMsat);
            if (message.feeLimitMsat != null && Object.hasOwnProperty.call(message, "feeLimitMsat"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.feeLimitMsat);
            if (message.lastHopPubkey != null && Object.hasOwnProperty.call(message, "lastHopPubkey"))
                writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.lastHopPubkey);
            if (message.allowSelfPayment != null && Object.hasOwnProperty.call(message, "allowSelfPayment"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.allowSelfPayment);
            if (message.destFeatures != null && message.destFeatures.length) {
                writer.uint32(/* id 16, wireType 2 =*/130).fork();
                for (let i = 0; i < message.destFeatures.length; ++i)
                    writer.int32(message.destFeatures[i]);
                writer.ldelim();
            }
            if (message.maxParts != null && Object.hasOwnProperty.call(message, "maxParts"))
                writer.uint32(/* id 17, wireType 0 =*/136).uint32(message.maxParts);
            if (message.noInflightUpdates != null && Object.hasOwnProperty.call(message, "noInflightUpdates"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.noInflightUpdates);
            if (message.outgoingChanIds != null && message.outgoingChanIds.length) {
                writer.uint32(/* id 19, wireType 2 =*/154).fork();
                for (let i = 0; i < message.outgoingChanIds.length; ++i)
                    writer.uint64(message.outgoingChanIds[i]);
                writer.ldelim();
            }
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 20, wireType 2 =*/162).bytes(message.paymentAddr);
            if (message.maxShardSizeMsat != null && Object.hasOwnProperty.call(message, "maxShardSizeMsat"))
                writer.uint32(/* id 21, wireType 0 =*/168).uint64(message.maxShardSizeMsat);
            if (message.amp != null && Object.hasOwnProperty.call(message, "amp"))
                writer.uint32(/* id 22, wireType 0 =*/176).bool(message.amp);
            if (message.timePref != null && Object.hasOwnProperty.call(message, "timePref"))
                writer.uint32(/* id 23, wireType 1 =*/185).double(message.timePref);
            return writer;
        };

        /**
         * Encodes the specified SendPaymentRequest message, length delimited. Does not implicitly {@link routerrpc.SendPaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {routerrpc.ISendPaymentRequest} message SendPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendPaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendPaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SendPaymentRequest} SendPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendPaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SendPaymentRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dest = reader.bytes();
                        break;
                    }
                case 2: {
                        message.amt = reader.int64();
                        break;
                    }
                case 12: {
                        message.amtMsat = reader.int64();
                        break;
                    }
                case 3: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 4: {
                        message.finalCltvDelta = reader.int32();
                        break;
                    }
                case 20: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                case 5: {
                        message.paymentRequest = reader.string();
                        break;
                    }
                case 6: {
                        message.timeoutSeconds = reader.int32();
                        break;
                    }
                case 7: {
                        message.feeLimitSat = reader.int64();
                        break;
                    }
                case 13: {
                        message.feeLimitMsat = reader.int64();
                        break;
                    }
                case 8: {
                        message.outgoingChanId = reader.uint64();
                        break;
                    }
                case 19: {
                        if (!(message.outgoingChanIds && message.outgoingChanIds.length))
                            message.outgoingChanIds = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.outgoingChanIds.push(reader.uint64());
                        } else
                            message.outgoingChanIds.push(reader.uint64());
                        break;
                    }
                case 14: {
                        message.lastHopPubkey = reader.bytes();
                        break;
                    }
                case 9: {
                        message.cltvLimit = reader.int32();
                        break;
                    }
                case 10: {
                        if (!(message.routeHints && message.routeHints.length))
                            message.routeHints = [];
                        message.routeHints.push($root.lnrpc.RouteHint.decode(reader, reader.uint32()));
                        break;
                    }
                case 11: {
                        if (message.destCustomRecords === $util.emptyObject)
                            message.destCustomRecords = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint64();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.destCustomRecords[typeof key === "object" ? $util.longToHash(key) : key] = value;
                        break;
                    }
                case 15: {
                        message.allowSelfPayment = reader.bool();
                        break;
                    }
                case 16: {
                        if (!(message.destFeatures && message.destFeatures.length))
                            message.destFeatures = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.destFeatures.push(reader.int32());
                        } else
                            message.destFeatures.push(reader.int32());
                        break;
                    }
                case 17: {
                        message.maxParts = reader.uint32();
                        break;
                    }
                case 18: {
                        message.noInflightUpdates = reader.bool();
                        break;
                    }
                case 21: {
                        message.maxShardSizeMsat = reader.uint64();
                        break;
                    }
                case 22: {
                        message.amp = reader.bool();
                        break;
                    }
                case 23: {
                        message.timePref = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendPaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SendPaymentRequest} SendPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendPaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendPaymentRequest message.
         * @function verify
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendPaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dest != null && message.hasOwnProperty("dest"))
                if (!(message.dest && typeof message.dest.length === "number" || $util.isString(message.dest)))
                    return "dest: buffer expected";
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (!$util.isInteger(message.amt) && !(message.amt && $util.isInteger(message.amt.low) && $util.isInteger(message.amt.high)))
                    return "amt: integer|Long expected";
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (!$util.isInteger(message.amtMsat) && !(message.amtMsat && $util.isInteger(message.amtMsat.low) && $util.isInteger(message.amtMsat.high)))
                    return "amtMsat: integer|Long expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                if (!$util.isInteger(message.finalCltvDelta))
                    return "finalCltvDelta: integer expected";
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                if (!$util.isString(message.paymentRequest))
                    return "paymentRequest: string expected";
            if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                if (!$util.isInteger(message.timeoutSeconds))
                    return "timeoutSeconds: integer expected";
            if (message.feeLimitSat != null && message.hasOwnProperty("feeLimitSat"))
                if (!$util.isInteger(message.feeLimitSat) && !(message.feeLimitSat && $util.isInteger(message.feeLimitSat.low) && $util.isInteger(message.feeLimitSat.high)))
                    return "feeLimitSat: integer|Long expected";
            if (message.feeLimitMsat != null && message.hasOwnProperty("feeLimitMsat"))
                if (!$util.isInteger(message.feeLimitMsat) && !(message.feeLimitMsat && $util.isInteger(message.feeLimitMsat.low) && $util.isInteger(message.feeLimitMsat.high)))
                    return "feeLimitMsat: integer|Long expected";
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (!$util.isInteger(message.outgoingChanId) && !(message.outgoingChanId && $util.isInteger(message.outgoingChanId.low) && $util.isInteger(message.outgoingChanId.high)))
                    return "outgoingChanId: integer|Long expected";
            if (message.outgoingChanIds != null && message.hasOwnProperty("outgoingChanIds")) {
                if (!Array.isArray(message.outgoingChanIds))
                    return "outgoingChanIds: array expected";
                for (let i = 0; i < message.outgoingChanIds.length; ++i)
                    if (!$util.isInteger(message.outgoingChanIds[i]) && !(message.outgoingChanIds[i] && $util.isInteger(message.outgoingChanIds[i].low) && $util.isInteger(message.outgoingChanIds[i].high)))
                        return "outgoingChanIds: integer|Long[] expected";
            }
            if (message.lastHopPubkey != null && message.hasOwnProperty("lastHopPubkey"))
                if (!(message.lastHopPubkey && typeof message.lastHopPubkey.length === "number" || $util.isString(message.lastHopPubkey)))
                    return "lastHopPubkey: buffer expected";
            if (message.cltvLimit != null && message.hasOwnProperty("cltvLimit"))
                if (!$util.isInteger(message.cltvLimit))
                    return "cltvLimit: integer expected";
            if (message.routeHints != null && message.hasOwnProperty("routeHints")) {
                if (!Array.isArray(message.routeHints))
                    return "routeHints: array expected";
                for (let i = 0; i < message.routeHints.length; ++i) {
                    let error = $root.lnrpc.RouteHint.verify(message.routeHints[i]);
                    if (error)
                        return "routeHints." + error;
                }
            }
            if (message.destCustomRecords != null && message.hasOwnProperty("destCustomRecords")) {
                if (!$util.isObject(message.destCustomRecords))
                    return "destCustomRecords: object expected";
                let key = Object.keys(message.destCustomRecords);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key64Re.test(key[i]))
                        return "destCustomRecords: integer|Long key{k:uint64} expected";
                    if (!(message.destCustomRecords[key[i]] && typeof message.destCustomRecords[key[i]].length === "number" || $util.isString(message.destCustomRecords[key[i]])))
                        return "destCustomRecords: buffer{k:uint64} expected";
                }
            }
            if (message.allowSelfPayment != null && message.hasOwnProperty("allowSelfPayment"))
                if (typeof message.allowSelfPayment !== "boolean")
                    return "allowSelfPayment: boolean expected";
            if (message.destFeatures != null && message.hasOwnProperty("destFeatures")) {
                if (!Array.isArray(message.destFeatures))
                    return "destFeatures: array expected";
                for (let i = 0; i < message.destFeatures.length; ++i)
                    switch (message.destFeatures[i]) {
                    default:
                        return "destFeatures: enum value[] expected";
                    case 0:
                    case 1:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 30:
                    case 31:
                        break;
                    }
            }
            if (message.maxParts != null && message.hasOwnProperty("maxParts"))
                if (!$util.isInteger(message.maxParts))
                    return "maxParts: integer expected";
            if (message.noInflightUpdates != null && message.hasOwnProperty("noInflightUpdates"))
                if (typeof message.noInflightUpdates !== "boolean")
                    return "noInflightUpdates: boolean expected";
            if (message.maxShardSizeMsat != null && message.hasOwnProperty("maxShardSizeMsat"))
                if (!$util.isInteger(message.maxShardSizeMsat) && !(message.maxShardSizeMsat && $util.isInteger(message.maxShardSizeMsat.low) && $util.isInteger(message.maxShardSizeMsat.high)))
                    return "maxShardSizeMsat: integer|Long expected";
            if (message.amp != null && message.hasOwnProperty("amp"))
                if (typeof message.amp !== "boolean")
                    return "amp: boolean expected";
            if (message.timePref != null && message.hasOwnProperty("timePref"))
                if (typeof message.timePref !== "number")
                    return "timePref: number expected";
            return null;
        };

        /**
         * Creates a SendPaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SendPaymentRequest} SendPaymentRequest
         */
        SendPaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SendPaymentRequest)
                return object;
            let message = new $root.routerrpc.SendPaymentRequest();
            if (object.dest != null)
                if (typeof object.dest === "string")
                    $util.base64.decode(object.dest, message.dest = $util.newBuffer($util.base64.length(object.dest)), 0);
                else if (object.dest.length)
                    message.dest = object.dest;
            if (object.amt != null)
                if ($util.Long)
                    (message.amt = $util.Long.fromValue(object.amt)).unsigned = false;
                else if (typeof object.amt === "string")
                    message.amt = parseInt(object.amt, 10);
                else if (typeof object.amt === "number")
                    message.amt = object.amt;
                else if (typeof object.amt === "object")
                    message.amt = new $util.LongBits(object.amt.low >>> 0, object.amt.high >>> 0).toNumber();
            if (object.amtMsat != null)
                if ($util.Long)
                    (message.amtMsat = $util.Long.fromValue(object.amtMsat)).unsigned = false;
                else if (typeof object.amtMsat === "string")
                    message.amtMsat = parseInt(object.amtMsat, 10);
                else if (typeof object.amtMsat === "number")
                    message.amtMsat = object.amtMsat;
                else if (typeof object.amtMsat === "object")
                    message.amtMsat = new $util.LongBits(object.amtMsat.low >>> 0, object.amtMsat.high >>> 0).toNumber();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.finalCltvDelta != null)
                message.finalCltvDelta = object.finalCltvDelta | 0;
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            if (object.paymentRequest != null)
                message.paymentRequest = String(object.paymentRequest);
            if (object.timeoutSeconds != null)
                message.timeoutSeconds = object.timeoutSeconds | 0;
            if (object.feeLimitSat != null)
                if ($util.Long)
                    (message.feeLimitSat = $util.Long.fromValue(object.feeLimitSat)).unsigned = false;
                else if (typeof object.feeLimitSat === "string")
                    message.feeLimitSat = parseInt(object.feeLimitSat, 10);
                else if (typeof object.feeLimitSat === "number")
                    message.feeLimitSat = object.feeLimitSat;
                else if (typeof object.feeLimitSat === "object")
                    message.feeLimitSat = new $util.LongBits(object.feeLimitSat.low >>> 0, object.feeLimitSat.high >>> 0).toNumber();
            if (object.feeLimitMsat != null)
                if ($util.Long)
                    (message.feeLimitMsat = $util.Long.fromValue(object.feeLimitMsat)).unsigned = false;
                else if (typeof object.feeLimitMsat === "string")
                    message.feeLimitMsat = parseInt(object.feeLimitMsat, 10);
                else if (typeof object.feeLimitMsat === "number")
                    message.feeLimitMsat = object.feeLimitMsat;
                else if (typeof object.feeLimitMsat === "object")
                    message.feeLimitMsat = new $util.LongBits(object.feeLimitMsat.low >>> 0, object.feeLimitMsat.high >>> 0).toNumber();
            if (object.outgoingChanId != null)
                if ($util.Long)
                    (message.outgoingChanId = $util.Long.fromValue(object.outgoingChanId)).unsigned = true;
                else if (typeof object.outgoingChanId === "string")
                    message.outgoingChanId = parseInt(object.outgoingChanId, 10);
                else if (typeof object.outgoingChanId === "number")
                    message.outgoingChanId = object.outgoingChanId;
                else if (typeof object.outgoingChanId === "object")
                    message.outgoingChanId = new $util.LongBits(object.outgoingChanId.low >>> 0, object.outgoingChanId.high >>> 0).toNumber(true);
            if (object.outgoingChanIds) {
                if (!Array.isArray(object.outgoingChanIds))
                    throw TypeError(".routerrpc.SendPaymentRequest.outgoingChanIds: array expected");
                message.outgoingChanIds = [];
                for (let i = 0; i < object.outgoingChanIds.length; ++i)
                    if ($util.Long)
                        (message.outgoingChanIds[i] = $util.Long.fromValue(object.outgoingChanIds[i])).unsigned = true;
                    else if (typeof object.outgoingChanIds[i] === "string")
                        message.outgoingChanIds[i] = parseInt(object.outgoingChanIds[i], 10);
                    else if (typeof object.outgoingChanIds[i] === "number")
                        message.outgoingChanIds[i] = object.outgoingChanIds[i];
                    else if (typeof object.outgoingChanIds[i] === "object")
                        message.outgoingChanIds[i] = new $util.LongBits(object.outgoingChanIds[i].low >>> 0, object.outgoingChanIds[i].high >>> 0).toNumber(true);
            }
            if (object.lastHopPubkey != null)
                if (typeof object.lastHopPubkey === "string")
                    $util.base64.decode(object.lastHopPubkey, message.lastHopPubkey = $util.newBuffer($util.base64.length(object.lastHopPubkey)), 0);
                else if (object.lastHopPubkey.length)
                    message.lastHopPubkey = object.lastHopPubkey;
            if (object.cltvLimit != null)
                message.cltvLimit = object.cltvLimit | 0;
            if (object.routeHints) {
                if (!Array.isArray(object.routeHints))
                    throw TypeError(".routerrpc.SendPaymentRequest.routeHints: array expected");
                message.routeHints = [];
                for (let i = 0; i < object.routeHints.length; ++i) {
                    if (typeof object.routeHints[i] !== "object")
                        throw TypeError(".routerrpc.SendPaymentRequest.routeHints: object expected");
                    message.routeHints[i] = $root.lnrpc.RouteHint.fromObject(object.routeHints[i]);
                }
            }
            if (object.destCustomRecords) {
                if (typeof object.destCustomRecords !== "object")
                    throw TypeError(".routerrpc.SendPaymentRequest.destCustomRecords: object expected");
                message.destCustomRecords = {};
                for (let keys = Object.keys(object.destCustomRecords), i = 0; i < keys.length; ++i)
                    if (typeof object.destCustomRecords[keys[i]] === "string")
                        $util.base64.decode(object.destCustomRecords[keys[i]], message.destCustomRecords[keys[i]] = $util.newBuffer($util.base64.length(object.destCustomRecords[keys[i]])), 0);
                    else if (object.destCustomRecords[keys[i]].length)
                        message.destCustomRecords[keys[i]] = object.destCustomRecords[keys[i]];
            }
            if (object.allowSelfPayment != null)
                message.allowSelfPayment = Boolean(object.allowSelfPayment);
            if (object.destFeatures) {
                if (!Array.isArray(object.destFeatures))
                    throw TypeError(".routerrpc.SendPaymentRequest.destFeatures: array expected");
                message.destFeatures = [];
                for (let i = 0; i < object.destFeatures.length; ++i)
                    switch (object.destFeatures[i]) {
                    default:
                    case "DATALOSS_PROTECT_REQ":
                    case 0:
                        message.destFeatures[i] = 0;
                        break;
                    case "DATALOSS_PROTECT_OPT":
                    case 1:
                        message.destFeatures[i] = 1;
                        break;
                    case "INITIAL_ROUING_SYNC":
                    case 3:
                        message.destFeatures[i] = 3;
                        break;
                    case "UPFRONT_SHUTDOWN_SCRIPT_REQ":
                    case 4:
                        message.destFeatures[i] = 4;
                        break;
                    case "UPFRONT_SHUTDOWN_SCRIPT_OPT":
                    case 5:
                        message.destFeatures[i] = 5;
                        break;
                    case "GOSSIP_QUERIES_REQ":
                    case 6:
                        message.destFeatures[i] = 6;
                        break;
                    case "GOSSIP_QUERIES_OPT":
                    case 7:
                        message.destFeatures[i] = 7;
                        break;
                    case "TLV_ONION_REQ":
                    case 8:
                        message.destFeatures[i] = 8;
                        break;
                    case "TLV_ONION_OPT":
                    case 9:
                        message.destFeatures[i] = 9;
                        break;
                    case "EXT_GOSSIP_QUERIES_REQ":
                    case 10:
                        message.destFeatures[i] = 10;
                        break;
                    case "EXT_GOSSIP_QUERIES_OPT":
                    case 11:
                        message.destFeatures[i] = 11;
                        break;
                    case "STATIC_REMOTE_KEY_REQ":
                    case 12:
                        message.destFeatures[i] = 12;
                        break;
                    case "STATIC_REMOTE_KEY_OPT":
                    case 13:
                        message.destFeatures[i] = 13;
                        break;
                    case "PAYMENT_ADDR_REQ":
                    case 14:
                        message.destFeatures[i] = 14;
                        break;
                    case "PAYMENT_ADDR_OPT":
                    case 15:
                        message.destFeatures[i] = 15;
                        break;
                    case "MPP_REQ":
                    case 16:
                        message.destFeatures[i] = 16;
                        break;
                    case "MPP_OPT":
                    case 17:
                        message.destFeatures[i] = 17;
                        break;
                    case "WUMBO_CHANNELS_REQ":
                    case 18:
                        message.destFeatures[i] = 18;
                        break;
                    case "WUMBO_CHANNELS_OPT":
                    case 19:
                        message.destFeatures[i] = 19;
                        break;
                    case "ANCHORS_REQ":
                    case 20:
                        message.destFeatures[i] = 20;
                        break;
                    case "ANCHORS_OPT":
                    case 21:
                        message.destFeatures[i] = 21;
                        break;
                    case "ANCHORS_ZERO_FEE_HTLC_REQ":
                    case 22:
                        message.destFeatures[i] = 22;
                        break;
                    case "ANCHORS_ZERO_FEE_HTLC_OPT":
                    case 23:
                        message.destFeatures[i] = 23;
                        break;
                    case "AMP_REQ":
                    case 30:
                        message.destFeatures[i] = 30;
                        break;
                    case "AMP_OPT":
                    case 31:
                        message.destFeatures[i] = 31;
                        break;
                    }
            }
            if (object.maxParts != null)
                message.maxParts = object.maxParts >>> 0;
            if (object.noInflightUpdates != null)
                message.noInflightUpdates = Boolean(object.noInflightUpdates);
            if (object.maxShardSizeMsat != null)
                if ($util.Long)
                    (message.maxShardSizeMsat = $util.Long.fromValue(object.maxShardSizeMsat)).unsigned = true;
                else if (typeof object.maxShardSizeMsat === "string")
                    message.maxShardSizeMsat = parseInt(object.maxShardSizeMsat, 10);
                else if (typeof object.maxShardSizeMsat === "number")
                    message.maxShardSizeMsat = object.maxShardSizeMsat;
                else if (typeof object.maxShardSizeMsat === "object")
                    message.maxShardSizeMsat = new $util.LongBits(object.maxShardSizeMsat.low >>> 0, object.maxShardSizeMsat.high >>> 0).toNumber(true);
            if (object.amp != null)
                message.amp = Boolean(object.amp);
            if (object.timePref != null)
                message.timePref = Number(object.timePref);
            return message;
        };

        /**
         * Creates a plain object from a SendPaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SendPaymentRequest
         * @static
         * @param {routerrpc.SendPaymentRequest} message SendPaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendPaymentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.routeHints = [];
                object.destFeatures = [];
                object.outgoingChanIds = [];
            }
            if (options.objects || options.defaults)
                object.destCustomRecords = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dest = "";
                else {
                    object.dest = [];
                    if (options.bytes !== Array)
                        object.dest = $util.newBuffer(object.dest);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amt = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                object.finalCltvDelta = 0;
                object.paymentRequest = "";
                object.timeoutSeconds = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeLimitSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeLimitSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingChanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingChanId = options.longs === String ? "0" : 0;
                object.cltvLimit = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.feeLimitMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.feeLimitMsat = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.lastHopPubkey = "";
                else {
                    object.lastHopPubkey = [];
                    if (options.bytes !== Array)
                        object.lastHopPubkey = $util.newBuffer(object.lastHopPubkey);
                }
                object.allowSelfPayment = false;
                object.maxParts = 0;
                object.noInflightUpdates = false;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.maxShardSizeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxShardSizeMsat = options.longs === String ? "0" : 0;
                object.amp = false;
                object.timePref = 0;
            }
            if (message.dest != null && message.hasOwnProperty("dest"))
                object.dest = options.bytes === String ? $util.base64.encode(message.dest, 0, message.dest.length) : options.bytes === Array ? Array.prototype.slice.call(message.dest) : message.dest;
            if (message.amt != null && message.hasOwnProperty("amt"))
                if (typeof message.amt === "number")
                    object.amt = options.longs === String ? String(message.amt) : message.amt;
                else
                    object.amt = options.longs === String ? $util.Long.prototype.toString.call(message.amt) : options.longs === Number ? new $util.LongBits(message.amt.low >>> 0, message.amt.high >>> 0).toNumber() : message.amt;
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                object.finalCltvDelta = message.finalCltvDelta;
            if (message.paymentRequest != null && message.hasOwnProperty("paymentRequest"))
                object.paymentRequest = message.paymentRequest;
            if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                object.timeoutSeconds = message.timeoutSeconds;
            if (message.feeLimitSat != null && message.hasOwnProperty("feeLimitSat"))
                if (typeof message.feeLimitSat === "number")
                    object.feeLimitSat = options.longs === String ? String(message.feeLimitSat) : message.feeLimitSat;
                else
                    object.feeLimitSat = options.longs === String ? $util.Long.prototype.toString.call(message.feeLimitSat) : options.longs === Number ? new $util.LongBits(message.feeLimitSat.low >>> 0, message.feeLimitSat.high >>> 0).toNumber() : message.feeLimitSat;
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (typeof message.outgoingChanId === "number")
                    object.outgoingChanId = options.longs === String ? String(message.outgoingChanId) : message.outgoingChanId;
                else
                    object.outgoingChanId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingChanId) : options.longs === Number ? new $util.LongBits(message.outgoingChanId.low >>> 0, message.outgoingChanId.high >>> 0).toNumber(true) : message.outgoingChanId;
            if (message.cltvLimit != null && message.hasOwnProperty("cltvLimit"))
                object.cltvLimit = message.cltvLimit;
            if (message.routeHints && message.routeHints.length) {
                object.routeHints = [];
                for (let j = 0; j < message.routeHints.length; ++j)
                    object.routeHints[j] = $root.lnrpc.RouteHint.toObject(message.routeHints[j], options);
            }
            let keys2;
            if (message.destCustomRecords && (keys2 = Object.keys(message.destCustomRecords)).length) {
                object.destCustomRecords = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.destCustomRecords[keys2[j]] = options.bytes === String ? $util.base64.encode(message.destCustomRecords[keys2[j]], 0, message.destCustomRecords[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.destCustomRecords[keys2[j]]) : message.destCustomRecords[keys2[j]];
            }
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (typeof message.amtMsat === "number")
                    object.amtMsat = options.longs === String ? String(message.amtMsat) : message.amtMsat;
                else
                    object.amtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtMsat) : options.longs === Number ? new $util.LongBits(message.amtMsat.low >>> 0, message.amtMsat.high >>> 0).toNumber() : message.amtMsat;
            if (message.feeLimitMsat != null && message.hasOwnProperty("feeLimitMsat"))
                if (typeof message.feeLimitMsat === "number")
                    object.feeLimitMsat = options.longs === String ? String(message.feeLimitMsat) : message.feeLimitMsat;
                else
                    object.feeLimitMsat = options.longs === String ? $util.Long.prototype.toString.call(message.feeLimitMsat) : options.longs === Number ? new $util.LongBits(message.feeLimitMsat.low >>> 0, message.feeLimitMsat.high >>> 0).toNumber() : message.feeLimitMsat;
            if (message.lastHopPubkey != null && message.hasOwnProperty("lastHopPubkey"))
                object.lastHopPubkey = options.bytes === String ? $util.base64.encode(message.lastHopPubkey, 0, message.lastHopPubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.lastHopPubkey) : message.lastHopPubkey;
            if (message.allowSelfPayment != null && message.hasOwnProperty("allowSelfPayment"))
                object.allowSelfPayment = message.allowSelfPayment;
            if (message.destFeatures && message.destFeatures.length) {
                object.destFeatures = [];
                for (let j = 0; j < message.destFeatures.length; ++j)
                    object.destFeatures[j] = options.enums === String ? $root.lnrpc.FeatureBit[message.destFeatures[j]] : message.destFeatures[j];
            }
            if (message.maxParts != null && message.hasOwnProperty("maxParts"))
                object.maxParts = message.maxParts;
            if (message.noInflightUpdates != null && message.hasOwnProperty("noInflightUpdates"))
                object.noInflightUpdates = message.noInflightUpdates;
            if (message.outgoingChanIds && message.outgoingChanIds.length) {
                object.outgoingChanIds = [];
                for (let j = 0; j < message.outgoingChanIds.length; ++j)
                    if (typeof message.outgoingChanIds[j] === "number")
                        object.outgoingChanIds[j] = options.longs === String ? String(message.outgoingChanIds[j]) : message.outgoingChanIds[j];
                    else
                        object.outgoingChanIds[j] = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingChanIds[j]) : options.longs === Number ? new $util.LongBits(message.outgoingChanIds[j].low >>> 0, message.outgoingChanIds[j].high >>> 0).toNumber(true) : message.outgoingChanIds[j];
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            if (message.maxShardSizeMsat != null && message.hasOwnProperty("maxShardSizeMsat"))
                if (typeof message.maxShardSizeMsat === "number")
                    object.maxShardSizeMsat = options.longs === String ? String(message.maxShardSizeMsat) : message.maxShardSizeMsat;
                else
                    object.maxShardSizeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.maxShardSizeMsat) : options.longs === Number ? new $util.LongBits(message.maxShardSizeMsat.low >>> 0, message.maxShardSizeMsat.high >>> 0).toNumber(true) : message.maxShardSizeMsat;
            if (message.amp != null && message.hasOwnProperty("amp"))
                object.amp = message.amp;
            if (message.timePref != null && message.hasOwnProperty("timePref"))
                object.timePref = options.json && !isFinite(message.timePref) ? String(message.timePref) : message.timePref;
            return object;
        };

        /**
         * Converts this SendPaymentRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.SendPaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendPaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendPaymentRequest;
    })();

    routerrpc.TrackPaymentRequest = (function() {

        /**
         * Properties of a TrackPaymentRequest.
         * @memberof routerrpc
         * @interface ITrackPaymentRequest
         * @property {Uint8Array|null} [paymentHash] TrackPaymentRequest paymentHash
         * @property {boolean|null} [noInflightUpdates] TrackPaymentRequest noInflightUpdates
         */

        /**
         * Constructs a new TrackPaymentRequest.
         * @memberof routerrpc
         * @classdesc Represents a TrackPaymentRequest.
         * @implements ITrackPaymentRequest
         * @constructor
         * @param {routerrpc.ITrackPaymentRequest=} [properties] Properties to set
         */
        function TrackPaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TrackPaymentRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof routerrpc.TrackPaymentRequest
         * @instance
         */
        TrackPaymentRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * TrackPaymentRequest noInflightUpdates.
         * @member {boolean} noInflightUpdates
         * @memberof routerrpc.TrackPaymentRequest
         * @instance
         */
        TrackPaymentRequest.prototype.noInflightUpdates = false;

        /**
         * Creates a new TrackPaymentRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {routerrpc.ITrackPaymentRequest=} [properties] Properties to set
         * @returns {routerrpc.TrackPaymentRequest} TrackPaymentRequest instance
         */
        TrackPaymentRequest.create = function create(properties) {
            return new TrackPaymentRequest(properties);
        };

        /**
         * Encodes the specified TrackPaymentRequest message. Does not implicitly {@link routerrpc.TrackPaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {routerrpc.ITrackPaymentRequest} message TrackPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrackPaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.paymentHash);
            if (message.noInflightUpdates != null && Object.hasOwnProperty.call(message, "noInflightUpdates"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noInflightUpdates);
            return writer;
        };

        /**
         * Encodes the specified TrackPaymentRequest message, length delimited. Does not implicitly {@link routerrpc.TrackPaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {routerrpc.ITrackPaymentRequest} message TrackPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TrackPaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TrackPaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.TrackPaymentRequest} TrackPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrackPaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.TrackPaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.noInflightUpdates = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TrackPaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.TrackPaymentRequest} TrackPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TrackPaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TrackPaymentRequest message.
         * @function verify
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TrackPaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.noInflightUpdates != null && message.hasOwnProperty("noInflightUpdates"))
                if (typeof message.noInflightUpdates !== "boolean")
                    return "noInflightUpdates: boolean expected";
            return null;
        };

        /**
         * Creates a TrackPaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.TrackPaymentRequest} TrackPaymentRequest
         */
        TrackPaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.TrackPaymentRequest)
                return object;
            let message = new $root.routerrpc.TrackPaymentRequest();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.noInflightUpdates != null)
                message.noInflightUpdates = Boolean(object.noInflightUpdates);
            return message;
        };

        /**
         * Creates a plain object from a TrackPaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.TrackPaymentRequest
         * @static
         * @param {routerrpc.TrackPaymentRequest} message TrackPaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TrackPaymentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                object.noInflightUpdates = false;
            }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.noInflightUpdates != null && message.hasOwnProperty("noInflightUpdates"))
                object.noInflightUpdates = message.noInflightUpdates;
            return object;
        };

        /**
         * Converts this TrackPaymentRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.TrackPaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TrackPaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TrackPaymentRequest;
    })();

    routerrpc.RouteFeeRequest = (function() {

        /**
         * Properties of a RouteFeeRequest.
         * @memberof routerrpc
         * @interface IRouteFeeRequest
         * @property {Uint8Array|null} [dest] RouteFeeRequest dest
         * @property {Long|null} [amtSat] RouteFeeRequest amtSat
         */

        /**
         * Constructs a new RouteFeeRequest.
         * @memberof routerrpc
         * @classdesc Represents a RouteFeeRequest.
         * @implements IRouteFeeRequest
         * @constructor
         * @param {routerrpc.IRouteFeeRequest=} [properties] Properties to set
         */
        function RouteFeeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RouteFeeRequest dest.
         * @member {Uint8Array} dest
         * @memberof routerrpc.RouteFeeRequest
         * @instance
         */
        RouteFeeRequest.prototype.dest = $util.newBuffer([]);

        /**
         * RouteFeeRequest amtSat.
         * @member {Long} amtSat
         * @memberof routerrpc.RouteFeeRequest
         * @instance
         */
        RouteFeeRequest.prototype.amtSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RouteFeeRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {routerrpc.IRouteFeeRequest=} [properties] Properties to set
         * @returns {routerrpc.RouteFeeRequest} RouteFeeRequest instance
         */
        RouteFeeRequest.create = function create(properties) {
            return new RouteFeeRequest(properties);
        };

        /**
         * Encodes the specified RouteFeeRequest message. Does not implicitly {@link routerrpc.RouteFeeRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {routerrpc.IRouteFeeRequest} message RouteFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RouteFeeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dest != null && Object.hasOwnProperty.call(message, "dest"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.dest);
            if (message.amtSat != null && Object.hasOwnProperty.call(message, "amtSat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.amtSat);
            return writer;
        };

        /**
         * Encodes the specified RouteFeeRequest message, length delimited. Does not implicitly {@link routerrpc.RouteFeeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {routerrpc.IRouteFeeRequest} message RouteFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RouteFeeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RouteFeeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.RouteFeeRequest} RouteFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RouteFeeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.RouteFeeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.dest = reader.bytes();
                        break;
                    }
                case 2: {
                        message.amtSat = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RouteFeeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.RouteFeeRequest} RouteFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RouteFeeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RouteFeeRequest message.
         * @function verify
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RouteFeeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.dest != null && message.hasOwnProperty("dest"))
                if (!(message.dest && typeof message.dest.length === "number" || $util.isString(message.dest)))
                    return "dest: buffer expected";
            if (message.amtSat != null && message.hasOwnProperty("amtSat"))
                if (!$util.isInteger(message.amtSat) && !(message.amtSat && $util.isInteger(message.amtSat.low) && $util.isInteger(message.amtSat.high)))
                    return "amtSat: integer|Long expected";
            return null;
        };

        /**
         * Creates a RouteFeeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.RouteFeeRequest} RouteFeeRequest
         */
        RouteFeeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.RouteFeeRequest)
                return object;
            let message = new $root.routerrpc.RouteFeeRequest();
            if (object.dest != null)
                if (typeof object.dest === "string")
                    $util.base64.decode(object.dest, message.dest = $util.newBuffer($util.base64.length(object.dest)), 0);
                else if (object.dest.length)
                    message.dest = object.dest;
            if (object.amtSat != null)
                if ($util.Long)
                    (message.amtSat = $util.Long.fromValue(object.amtSat)).unsigned = false;
                else if (typeof object.amtSat === "string")
                    message.amtSat = parseInt(object.amtSat, 10);
                else if (typeof object.amtSat === "number")
                    message.amtSat = object.amtSat;
                else if (typeof object.amtSat === "object")
                    message.amtSat = new $util.LongBits(object.amtSat.low >>> 0, object.amtSat.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RouteFeeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.RouteFeeRequest
         * @static
         * @param {routerrpc.RouteFeeRequest} message RouteFeeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RouteFeeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.dest = "";
                else {
                    object.dest = [];
                    if (options.bytes !== Array)
                        object.dest = $util.newBuffer(object.dest);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtSat = options.longs === String ? "0" : 0;
            }
            if (message.dest != null && message.hasOwnProperty("dest"))
                object.dest = options.bytes === String ? $util.base64.encode(message.dest, 0, message.dest.length) : options.bytes === Array ? Array.prototype.slice.call(message.dest) : message.dest;
            if (message.amtSat != null && message.hasOwnProperty("amtSat"))
                if (typeof message.amtSat === "number")
                    object.amtSat = options.longs === String ? String(message.amtSat) : message.amtSat;
                else
                    object.amtSat = options.longs === String ? $util.Long.prototype.toString.call(message.amtSat) : options.longs === Number ? new $util.LongBits(message.amtSat.low >>> 0, message.amtSat.high >>> 0).toNumber() : message.amtSat;
            return object;
        };

        /**
         * Converts this RouteFeeRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.RouteFeeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RouteFeeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RouteFeeRequest;
    })();

    routerrpc.RouteFeeResponse = (function() {

        /**
         * Properties of a RouteFeeResponse.
         * @memberof routerrpc
         * @interface IRouteFeeResponse
         * @property {Long|null} [routingFeeMsat] RouteFeeResponse routingFeeMsat
         * @property {Long|null} [timeLockDelay] RouteFeeResponse timeLockDelay
         */

        /**
         * Constructs a new RouteFeeResponse.
         * @memberof routerrpc
         * @classdesc Represents a RouteFeeResponse.
         * @implements IRouteFeeResponse
         * @constructor
         * @param {routerrpc.IRouteFeeResponse=} [properties] Properties to set
         */
        function RouteFeeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RouteFeeResponse routingFeeMsat.
         * @member {Long} routingFeeMsat
         * @memberof routerrpc.RouteFeeResponse
         * @instance
         */
        RouteFeeResponse.prototype.routingFeeMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * RouteFeeResponse timeLockDelay.
         * @member {Long} timeLockDelay
         * @memberof routerrpc.RouteFeeResponse
         * @instance
         */
        RouteFeeResponse.prototype.timeLockDelay = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RouteFeeResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {routerrpc.IRouteFeeResponse=} [properties] Properties to set
         * @returns {routerrpc.RouteFeeResponse} RouteFeeResponse instance
         */
        RouteFeeResponse.create = function create(properties) {
            return new RouteFeeResponse(properties);
        };

        /**
         * Encodes the specified RouteFeeResponse message. Does not implicitly {@link routerrpc.RouteFeeResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {routerrpc.IRouteFeeResponse} message RouteFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RouteFeeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.routingFeeMsat != null && Object.hasOwnProperty.call(message, "routingFeeMsat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.routingFeeMsat);
            if (message.timeLockDelay != null && Object.hasOwnProperty.call(message, "timeLockDelay"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timeLockDelay);
            return writer;
        };

        /**
         * Encodes the specified RouteFeeResponse message, length delimited. Does not implicitly {@link routerrpc.RouteFeeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {routerrpc.IRouteFeeResponse} message RouteFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RouteFeeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RouteFeeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.RouteFeeResponse} RouteFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RouteFeeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.RouteFeeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.routingFeeMsat = reader.int64();
                        break;
                    }
                case 2: {
                        message.timeLockDelay = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RouteFeeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.RouteFeeResponse} RouteFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RouteFeeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RouteFeeResponse message.
         * @function verify
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RouteFeeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.routingFeeMsat != null && message.hasOwnProperty("routingFeeMsat"))
                if (!$util.isInteger(message.routingFeeMsat) && !(message.routingFeeMsat && $util.isInteger(message.routingFeeMsat.low) && $util.isInteger(message.routingFeeMsat.high)))
                    return "routingFeeMsat: integer|Long expected";
            if (message.timeLockDelay != null && message.hasOwnProperty("timeLockDelay"))
                if (!$util.isInteger(message.timeLockDelay) && !(message.timeLockDelay && $util.isInteger(message.timeLockDelay.low) && $util.isInteger(message.timeLockDelay.high)))
                    return "timeLockDelay: integer|Long expected";
            return null;
        };

        /**
         * Creates a RouteFeeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.RouteFeeResponse} RouteFeeResponse
         */
        RouteFeeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.RouteFeeResponse)
                return object;
            let message = new $root.routerrpc.RouteFeeResponse();
            if (object.routingFeeMsat != null)
                if ($util.Long)
                    (message.routingFeeMsat = $util.Long.fromValue(object.routingFeeMsat)).unsigned = false;
                else if (typeof object.routingFeeMsat === "string")
                    message.routingFeeMsat = parseInt(object.routingFeeMsat, 10);
                else if (typeof object.routingFeeMsat === "number")
                    message.routingFeeMsat = object.routingFeeMsat;
                else if (typeof object.routingFeeMsat === "object")
                    message.routingFeeMsat = new $util.LongBits(object.routingFeeMsat.low >>> 0, object.routingFeeMsat.high >>> 0).toNumber();
            if (object.timeLockDelay != null)
                if ($util.Long)
                    (message.timeLockDelay = $util.Long.fromValue(object.timeLockDelay)).unsigned = false;
                else if (typeof object.timeLockDelay === "string")
                    message.timeLockDelay = parseInt(object.timeLockDelay, 10);
                else if (typeof object.timeLockDelay === "number")
                    message.timeLockDelay = object.timeLockDelay;
                else if (typeof object.timeLockDelay === "object")
                    message.timeLockDelay = new $util.LongBits(object.timeLockDelay.low >>> 0, object.timeLockDelay.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RouteFeeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.RouteFeeResponse
         * @static
         * @param {routerrpc.RouteFeeResponse} message RouteFeeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RouteFeeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.routingFeeMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.routingFeeMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timeLockDelay = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeLockDelay = options.longs === String ? "0" : 0;
            }
            if (message.routingFeeMsat != null && message.hasOwnProperty("routingFeeMsat"))
                if (typeof message.routingFeeMsat === "number")
                    object.routingFeeMsat = options.longs === String ? String(message.routingFeeMsat) : message.routingFeeMsat;
                else
                    object.routingFeeMsat = options.longs === String ? $util.Long.prototype.toString.call(message.routingFeeMsat) : options.longs === Number ? new $util.LongBits(message.routingFeeMsat.low >>> 0, message.routingFeeMsat.high >>> 0).toNumber() : message.routingFeeMsat;
            if (message.timeLockDelay != null && message.hasOwnProperty("timeLockDelay"))
                if (typeof message.timeLockDelay === "number")
                    object.timeLockDelay = options.longs === String ? String(message.timeLockDelay) : message.timeLockDelay;
                else
                    object.timeLockDelay = options.longs === String ? $util.Long.prototype.toString.call(message.timeLockDelay) : options.longs === Number ? new $util.LongBits(message.timeLockDelay.low >>> 0, message.timeLockDelay.high >>> 0).toNumber() : message.timeLockDelay;
            return object;
        };

        /**
         * Converts this RouteFeeResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.RouteFeeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RouteFeeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RouteFeeResponse;
    })();

    routerrpc.SendToRouteRequest = (function() {

        /**
         * Properties of a SendToRouteRequest.
         * @memberof routerrpc
         * @interface ISendToRouteRequest
         * @property {Uint8Array|null} [paymentHash] SendToRouteRequest paymentHash
         * @property {lnrpc.IRoute|null} [route] SendToRouteRequest route
         * @property {boolean|null} [skipTempErr] SendToRouteRequest skipTempErr
         */

        /**
         * Constructs a new SendToRouteRequest.
         * @memberof routerrpc
         * @classdesc Represents a SendToRouteRequest.
         * @implements ISendToRouteRequest
         * @constructor
         * @param {routerrpc.ISendToRouteRequest=} [properties] Properties to set
         */
        function SendToRouteRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendToRouteRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof routerrpc.SendToRouteRequest
         * @instance
         */
        SendToRouteRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * SendToRouteRequest route.
         * @member {lnrpc.IRoute|null|undefined} route
         * @memberof routerrpc.SendToRouteRequest
         * @instance
         */
        SendToRouteRequest.prototype.route = null;

        /**
         * SendToRouteRequest skipTempErr.
         * @member {boolean} skipTempErr
         * @memberof routerrpc.SendToRouteRequest
         * @instance
         */
        SendToRouteRequest.prototype.skipTempErr = false;

        /**
         * Creates a new SendToRouteRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {routerrpc.ISendToRouteRequest=} [properties] Properties to set
         * @returns {routerrpc.SendToRouteRequest} SendToRouteRequest instance
         */
        SendToRouteRequest.create = function create(properties) {
            return new SendToRouteRequest(properties);
        };

        /**
         * Encodes the specified SendToRouteRequest message. Does not implicitly {@link routerrpc.SendToRouteRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {routerrpc.ISendToRouteRequest} message SendToRouteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendToRouteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.paymentHash);
            if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                $root.lnrpc.Route.encode(message.route, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.skipTempErr != null && Object.hasOwnProperty.call(message, "skipTempErr"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.skipTempErr);
            return writer;
        };

        /**
         * Encodes the specified SendToRouteRequest message, length delimited. Does not implicitly {@link routerrpc.SendToRouteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {routerrpc.ISendToRouteRequest} message SendToRouteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendToRouteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendToRouteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SendToRouteRequest} SendToRouteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendToRouteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SendToRouteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 2: {
                        message.route = $root.lnrpc.Route.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.skipTempErr = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendToRouteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SendToRouteRequest} SendToRouteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendToRouteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendToRouteRequest message.
         * @function verify
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendToRouteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.route != null && message.hasOwnProperty("route")) {
                let error = $root.lnrpc.Route.verify(message.route);
                if (error)
                    return "route." + error;
            }
            if (message.skipTempErr != null && message.hasOwnProperty("skipTempErr"))
                if (typeof message.skipTempErr !== "boolean")
                    return "skipTempErr: boolean expected";
            return null;
        };

        /**
         * Creates a SendToRouteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SendToRouteRequest} SendToRouteRequest
         */
        SendToRouteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SendToRouteRequest)
                return object;
            let message = new $root.routerrpc.SendToRouteRequest();
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.route != null) {
                if (typeof object.route !== "object")
                    throw TypeError(".routerrpc.SendToRouteRequest.route: object expected");
                message.route = $root.lnrpc.Route.fromObject(object.route);
            }
            if (object.skipTempErr != null)
                message.skipTempErr = Boolean(object.skipTempErr);
            return message;
        };

        /**
         * Creates a plain object from a SendToRouteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SendToRouteRequest
         * @static
         * @param {routerrpc.SendToRouteRequest} message SendToRouteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendToRouteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                object.route = null;
                object.skipTempErr = false;
            }
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.route != null && message.hasOwnProperty("route"))
                object.route = $root.lnrpc.Route.toObject(message.route, options);
            if (message.skipTempErr != null && message.hasOwnProperty("skipTempErr"))
                object.skipTempErr = message.skipTempErr;
            return object;
        };

        /**
         * Converts this SendToRouteRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.SendToRouteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendToRouteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendToRouteRequest;
    })();

    routerrpc.SendToRouteResponse = (function() {

        /**
         * Properties of a SendToRouteResponse.
         * @memberof routerrpc
         * @interface ISendToRouteResponse
         * @property {Uint8Array|null} [preimage] SendToRouteResponse preimage
         * @property {lnrpc.IFailure|null} [failure] SendToRouteResponse failure
         */

        /**
         * Constructs a new SendToRouteResponse.
         * @memberof routerrpc
         * @classdesc Represents a SendToRouteResponse.
         * @implements ISendToRouteResponse
         * @constructor
         * @param {routerrpc.ISendToRouteResponse=} [properties] Properties to set
         */
        function SendToRouteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendToRouteResponse preimage.
         * @member {Uint8Array} preimage
         * @memberof routerrpc.SendToRouteResponse
         * @instance
         */
        SendToRouteResponse.prototype.preimage = $util.newBuffer([]);

        /**
         * SendToRouteResponse failure.
         * @member {lnrpc.IFailure|null|undefined} failure
         * @memberof routerrpc.SendToRouteResponse
         * @instance
         */
        SendToRouteResponse.prototype.failure = null;

        /**
         * Creates a new SendToRouteResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {routerrpc.ISendToRouteResponse=} [properties] Properties to set
         * @returns {routerrpc.SendToRouteResponse} SendToRouteResponse instance
         */
        SendToRouteResponse.create = function create(properties) {
            return new SendToRouteResponse(properties);
        };

        /**
         * Encodes the specified SendToRouteResponse message. Does not implicitly {@link routerrpc.SendToRouteResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {routerrpc.ISendToRouteResponse} message SendToRouteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendToRouteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.preimage);
            if (message.failure != null && Object.hasOwnProperty.call(message, "failure"))
                $root.lnrpc.Failure.encode(message.failure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SendToRouteResponse message, length delimited. Does not implicitly {@link routerrpc.SendToRouteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {routerrpc.ISendToRouteResponse} message SendToRouteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendToRouteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendToRouteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SendToRouteResponse} SendToRouteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendToRouteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SendToRouteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.preimage = reader.bytes();
                        break;
                    }
                case 2: {
                        message.failure = $root.lnrpc.Failure.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendToRouteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SendToRouteResponse} SendToRouteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendToRouteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendToRouteResponse message.
         * @function verify
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendToRouteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            if (message.failure != null && message.hasOwnProperty("failure")) {
                let error = $root.lnrpc.Failure.verify(message.failure);
                if (error)
                    return "failure." + error;
            }
            return null;
        };

        /**
         * Creates a SendToRouteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SendToRouteResponse} SendToRouteResponse
         */
        SendToRouteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SendToRouteResponse)
                return object;
            let message = new $root.routerrpc.SendToRouteResponse();
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            if (object.failure != null) {
                if (typeof object.failure !== "object")
                    throw TypeError(".routerrpc.SendToRouteResponse.failure: object expected");
                message.failure = $root.lnrpc.Failure.fromObject(object.failure);
            }
            return message;
        };

        /**
         * Creates a plain object from a SendToRouteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SendToRouteResponse
         * @static
         * @param {routerrpc.SendToRouteResponse} message SendToRouteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendToRouteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
                object.failure = null;
            }
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            if (message.failure != null && message.hasOwnProperty("failure"))
                object.failure = $root.lnrpc.Failure.toObject(message.failure, options);
            return object;
        };

        /**
         * Converts this SendToRouteResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.SendToRouteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendToRouteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendToRouteResponse;
    })();

    routerrpc.ResetMissionControlRequest = (function() {

        /**
         * Properties of a ResetMissionControlRequest.
         * @memberof routerrpc
         * @interface IResetMissionControlRequest
         */

        /**
         * Constructs a new ResetMissionControlRequest.
         * @memberof routerrpc
         * @classdesc Represents a ResetMissionControlRequest.
         * @implements IResetMissionControlRequest
         * @constructor
         * @param {routerrpc.IResetMissionControlRequest=} [properties] Properties to set
         */
        function ResetMissionControlRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResetMissionControlRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {routerrpc.IResetMissionControlRequest=} [properties] Properties to set
         * @returns {routerrpc.ResetMissionControlRequest} ResetMissionControlRequest instance
         */
        ResetMissionControlRequest.create = function create(properties) {
            return new ResetMissionControlRequest(properties);
        };

        /**
         * Encodes the specified ResetMissionControlRequest message. Does not implicitly {@link routerrpc.ResetMissionControlRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {routerrpc.IResetMissionControlRequest} message ResetMissionControlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMissionControlRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResetMissionControlRequest message, length delimited. Does not implicitly {@link routerrpc.ResetMissionControlRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {routerrpc.IResetMissionControlRequest} message ResetMissionControlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMissionControlRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetMissionControlRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.ResetMissionControlRequest} ResetMissionControlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMissionControlRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.ResetMissionControlRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetMissionControlRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.ResetMissionControlRequest} ResetMissionControlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMissionControlRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetMissionControlRequest message.
         * @function verify
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetMissionControlRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResetMissionControlRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.ResetMissionControlRequest} ResetMissionControlRequest
         */
        ResetMissionControlRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.ResetMissionControlRequest)
                return object;
            return new $root.routerrpc.ResetMissionControlRequest();
        };

        /**
         * Creates a plain object from a ResetMissionControlRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.ResetMissionControlRequest
         * @static
         * @param {routerrpc.ResetMissionControlRequest} message ResetMissionControlRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetMissionControlRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResetMissionControlRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.ResetMissionControlRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetMissionControlRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResetMissionControlRequest;
    })();

    routerrpc.ResetMissionControlResponse = (function() {

        /**
         * Properties of a ResetMissionControlResponse.
         * @memberof routerrpc
         * @interface IResetMissionControlResponse
         */

        /**
         * Constructs a new ResetMissionControlResponse.
         * @memberof routerrpc
         * @classdesc Represents a ResetMissionControlResponse.
         * @implements IResetMissionControlResponse
         * @constructor
         * @param {routerrpc.IResetMissionControlResponse=} [properties] Properties to set
         */
        function ResetMissionControlResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResetMissionControlResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {routerrpc.IResetMissionControlResponse=} [properties] Properties to set
         * @returns {routerrpc.ResetMissionControlResponse} ResetMissionControlResponse instance
         */
        ResetMissionControlResponse.create = function create(properties) {
            return new ResetMissionControlResponse(properties);
        };

        /**
         * Encodes the specified ResetMissionControlResponse message. Does not implicitly {@link routerrpc.ResetMissionControlResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {routerrpc.IResetMissionControlResponse} message ResetMissionControlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMissionControlResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResetMissionControlResponse message, length delimited. Does not implicitly {@link routerrpc.ResetMissionControlResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {routerrpc.IResetMissionControlResponse} message ResetMissionControlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetMissionControlResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetMissionControlResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.ResetMissionControlResponse} ResetMissionControlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMissionControlResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.ResetMissionControlResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetMissionControlResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.ResetMissionControlResponse} ResetMissionControlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetMissionControlResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetMissionControlResponse message.
         * @function verify
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetMissionControlResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResetMissionControlResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.ResetMissionControlResponse} ResetMissionControlResponse
         */
        ResetMissionControlResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.ResetMissionControlResponse)
                return object;
            return new $root.routerrpc.ResetMissionControlResponse();
        };

        /**
         * Creates a plain object from a ResetMissionControlResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.ResetMissionControlResponse
         * @static
         * @param {routerrpc.ResetMissionControlResponse} message ResetMissionControlResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetMissionControlResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResetMissionControlResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.ResetMissionControlResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetMissionControlResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResetMissionControlResponse;
    })();

    routerrpc.QueryMissionControlRequest = (function() {

        /**
         * Properties of a QueryMissionControlRequest.
         * @memberof routerrpc
         * @interface IQueryMissionControlRequest
         */

        /**
         * Constructs a new QueryMissionControlRequest.
         * @memberof routerrpc
         * @classdesc Represents a QueryMissionControlRequest.
         * @implements IQueryMissionControlRequest
         * @constructor
         * @param {routerrpc.IQueryMissionControlRequest=} [properties] Properties to set
         */
        function QueryMissionControlRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new QueryMissionControlRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {routerrpc.IQueryMissionControlRequest=} [properties] Properties to set
         * @returns {routerrpc.QueryMissionControlRequest} QueryMissionControlRequest instance
         */
        QueryMissionControlRequest.create = function create(properties) {
            return new QueryMissionControlRequest(properties);
        };

        /**
         * Encodes the specified QueryMissionControlRequest message. Does not implicitly {@link routerrpc.QueryMissionControlRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {routerrpc.IQueryMissionControlRequest} message QueryMissionControlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMissionControlRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified QueryMissionControlRequest message, length delimited. Does not implicitly {@link routerrpc.QueryMissionControlRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {routerrpc.IQueryMissionControlRequest} message QueryMissionControlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMissionControlRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryMissionControlRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.QueryMissionControlRequest} QueryMissionControlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMissionControlRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.QueryMissionControlRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryMissionControlRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.QueryMissionControlRequest} QueryMissionControlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMissionControlRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryMissionControlRequest message.
         * @function verify
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryMissionControlRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a QueryMissionControlRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.QueryMissionControlRequest} QueryMissionControlRequest
         */
        QueryMissionControlRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.QueryMissionControlRequest)
                return object;
            return new $root.routerrpc.QueryMissionControlRequest();
        };

        /**
         * Creates a plain object from a QueryMissionControlRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.QueryMissionControlRequest
         * @static
         * @param {routerrpc.QueryMissionControlRequest} message QueryMissionControlRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryMissionControlRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this QueryMissionControlRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.QueryMissionControlRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryMissionControlRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryMissionControlRequest;
    })();

    routerrpc.QueryMissionControlResponse = (function() {

        /**
         * Properties of a QueryMissionControlResponse.
         * @memberof routerrpc
         * @interface IQueryMissionControlResponse
         * @property {Array.<routerrpc.IPairHistory>|null} [pairs] QueryMissionControlResponse pairs
         */

        /**
         * Constructs a new QueryMissionControlResponse.
         * @memberof routerrpc
         * @classdesc Represents a QueryMissionControlResponse.
         * @implements IQueryMissionControlResponse
         * @constructor
         * @param {routerrpc.IQueryMissionControlResponse=} [properties] Properties to set
         */
        function QueryMissionControlResponse(properties) {
            this.pairs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryMissionControlResponse pairs.
         * @member {Array.<routerrpc.IPairHistory>} pairs
         * @memberof routerrpc.QueryMissionControlResponse
         * @instance
         */
        QueryMissionControlResponse.prototype.pairs = $util.emptyArray;

        /**
         * Creates a new QueryMissionControlResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {routerrpc.IQueryMissionControlResponse=} [properties] Properties to set
         * @returns {routerrpc.QueryMissionControlResponse} QueryMissionControlResponse instance
         */
        QueryMissionControlResponse.create = function create(properties) {
            return new QueryMissionControlResponse(properties);
        };

        /**
         * Encodes the specified QueryMissionControlResponse message. Does not implicitly {@link routerrpc.QueryMissionControlResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {routerrpc.IQueryMissionControlResponse} message QueryMissionControlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMissionControlResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pairs != null && message.pairs.length)
                for (let i = 0; i < message.pairs.length; ++i)
                    $root.routerrpc.PairHistory.encode(message.pairs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueryMissionControlResponse message, length delimited. Does not implicitly {@link routerrpc.QueryMissionControlResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {routerrpc.IQueryMissionControlResponse} message QueryMissionControlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryMissionControlResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryMissionControlResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.QueryMissionControlResponse} QueryMissionControlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMissionControlResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.QueryMissionControlResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        if (!(message.pairs && message.pairs.length))
                            message.pairs = [];
                        message.pairs.push($root.routerrpc.PairHistory.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryMissionControlResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.QueryMissionControlResponse} QueryMissionControlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryMissionControlResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryMissionControlResponse message.
         * @function verify
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryMissionControlResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pairs != null && message.hasOwnProperty("pairs")) {
                if (!Array.isArray(message.pairs))
                    return "pairs: array expected";
                for (let i = 0; i < message.pairs.length; ++i) {
                    let error = $root.routerrpc.PairHistory.verify(message.pairs[i]);
                    if (error)
                        return "pairs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a QueryMissionControlResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.QueryMissionControlResponse} QueryMissionControlResponse
         */
        QueryMissionControlResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.QueryMissionControlResponse)
                return object;
            let message = new $root.routerrpc.QueryMissionControlResponse();
            if (object.pairs) {
                if (!Array.isArray(object.pairs))
                    throw TypeError(".routerrpc.QueryMissionControlResponse.pairs: array expected");
                message.pairs = [];
                for (let i = 0; i < object.pairs.length; ++i) {
                    if (typeof object.pairs[i] !== "object")
                        throw TypeError(".routerrpc.QueryMissionControlResponse.pairs: object expected");
                    message.pairs[i] = $root.routerrpc.PairHistory.fromObject(object.pairs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryMissionControlResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.QueryMissionControlResponse
         * @static
         * @param {routerrpc.QueryMissionControlResponse} message QueryMissionControlResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryMissionControlResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.pairs = [];
            if (message.pairs && message.pairs.length) {
                object.pairs = [];
                for (let j = 0; j < message.pairs.length; ++j)
                    object.pairs[j] = $root.routerrpc.PairHistory.toObject(message.pairs[j], options);
            }
            return object;
        };

        /**
         * Converts this QueryMissionControlResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.QueryMissionControlResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryMissionControlResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryMissionControlResponse;
    })();

    routerrpc.XImportMissionControlRequest = (function() {

        /**
         * Properties of a XImportMissionControlRequest.
         * @memberof routerrpc
         * @interface IXImportMissionControlRequest
         * @property {Array.<routerrpc.IPairHistory>|null} [pairs] XImportMissionControlRequest pairs
         * @property {boolean|null} [force] XImportMissionControlRequest force
         */

        /**
         * Constructs a new XImportMissionControlRequest.
         * @memberof routerrpc
         * @classdesc Represents a XImportMissionControlRequest.
         * @implements IXImportMissionControlRequest
         * @constructor
         * @param {routerrpc.IXImportMissionControlRequest=} [properties] Properties to set
         */
        function XImportMissionControlRequest(properties) {
            this.pairs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * XImportMissionControlRequest pairs.
         * @member {Array.<routerrpc.IPairHistory>} pairs
         * @memberof routerrpc.XImportMissionControlRequest
         * @instance
         */
        XImportMissionControlRequest.prototype.pairs = $util.emptyArray;

        /**
         * XImportMissionControlRequest force.
         * @member {boolean} force
         * @memberof routerrpc.XImportMissionControlRequest
         * @instance
         */
        XImportMissionControlRequest.prototype.force = false;

        /**
         * Creates a new XImportMissionControlRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {routerrpc.IXImportMissionControlRequest=} [properties] Properties to set
         * @returns {routerrpc.XImportMissionControlRequest} XImportMissionControlRequest instance
         */
        XImportMissionControlRequest.create = function create(properties) {
            return new XImportMissionControlRequest(properties);
        };

        /**
         * Encodes the specified XImportMissionControlRequest message. Does not implicitly {@link routerrpc.XImportMissionControlRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {routerrpc.IXImportMissionControlRequest} message XImportMissionControlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        XImportMissionControlRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pairs != null && message.pairs.length)
                for (let i = 0; i < message.pairs.length; ++i)
                    $root.routerrpc.PairHistory.encode(message.pairs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified XImportMissionControlRequest message, length delimited. Does not implicitly {@link routerrpc.XImportMissionControlRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {routerrpc.IXImportMissionControlRequest} message XImportMissionControlRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        XImportMissionControlRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a XImportMissionControlRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.XImportMissionControlRequest} XImportMissionControlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        XImportMissionControlRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.XImportMissionControlRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.pairs && message.pairs.length))
                            message.pairs = [];
                        message.pairs.push($root.routerrpc.PairHistory.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.force = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a XImportMissionControlRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.XImportMissionControlRequest} XImportMissionControlRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        XImportMissionControlRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a XImportMissionControlRequest message.
         * @function verify
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        XImportMissionControlRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pairs != null && message.hasOwnProperty("pairs")) {
                if (!Array.isArray(message.pairs))
                    return "pairs: array expected";
                for (let i = 0; i < message.pairs.length; ++i) {
                    let error = $root.routerrpc.PairHistory.verify(message.pairs[i]);
                    if (error)
                        return "pairs." + error;
                }
            }
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a XImportMissionControlRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.XImportMissionControlRequest} XImportMissionControlRequest
         */
        XImportMissionControlRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.XImportMissionControlRequest)
                return object;
            let message = new $root.routerrpc.XImportMissionControlRequest();
            if (object.pairs) {
                if (!Array.isArray(object.pairs))
                    throw TypeError(".routerrpc.XImportMissionControlRequest.pairs: array expected");
                message.pairs = [];
                for (let i = 0; i < object.pairs.length; ++i) {
                    if (typeof object.pairs[i] !== "object")
                        throw TypeError(".routerrpc.XImportMissionControlRequest.pairs: object expected");
                    message.pairs[i] = $root.routerrpc.PairHistory.fromObject(object.pairs[i]);
                }
            }
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a XImportMissionControlRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.XImportMissionControlRequest
         * @static
         * @param {routerrpc.XImportMissionControlRequest} message XImportMissionControlRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        XImportMissionControlRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.pairs = [];
            if (options.defaults)
                object.force = false;
            if (message.pairs && message.pairs.length) {
                object.pairs = [];
                for (let j = 0; j < message.pairs.length; ++j)
                    object.pairs[j] = $root.routerrpc.PairHistory.toObject(message.pairs[j], options);
            }
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this XImportMissionControlRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.XImportMissionControlRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        XImportMissionControlRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return XImportMissionControlRequest;
    })();

    routerrpc.XImportMissionControlResponse = (function() {

        /**
         * Properties of a XImportMissionControlResponse.
         * @memberof routerrpc
         * @interface IXImportMissionControlResponse
         */

        /**
         * Constructs a new XImportMissionControlResponse.
         * @memberof routerrpc
         * @classdesc Represents a XImportMissionControlResponse.
         * @implements IXImportMissionControlResponse
         * @constructor
         * @param {routerrpc.IXImportMissionControlResponse=} [properties] Properties to set
         */
        function XImportMissionControlResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new XImportMissionControlResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {routerrpc.IXImportMissionControlResponse=} [properties] Properties to set
         * @returns {routerrpc.XImportMissionControlResponse} XImportMissionControlResponse instance
         */
        XImportMissionControlResponse.create = function create(properties) {
            return new XImportMissionControlResponse(properties);
        };

        /**
         * Encodes the specified XImportMissionControlResponse message. Does not implicitly {@link routerrpc.XImportMissionControlResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {routerrpc.IXImportMissionControlResponse} message XImportMissionControlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        XImportMissionControlResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified XImportMissionControlResponse message, length delimited. Does not implicitly {@link routerrpc.XImportMissionControlResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {routerrpc.IXImportMissionControlResponse} message XImportMissionControlResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        XImportMissionControlResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a XImportMissionControlResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.XImportMissionControlResponse} XImportMissionControlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        XImportMissionControlResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.XImportMissionControlResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a XImportMissionControlResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.XImportMissionControlResponse} XImportMissionControlResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        XImportMissionControlResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a XImportMissionControlResponse message.
         * @function verify
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        XImportMissionControlResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a XImportMissionControlResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.XImportMissionControlResponse} XImportMissionControlResponse
         */
        XImportMissionControlResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.XImportMissionControlResponse)
                return object;
            return new $root.routerrpc.XImportMissionControlResponse();
        };

        /**
         * Creates a plain object from a XImportMissionControlResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.XImportMissionControlResponse
         * @static
         * @param {routerrpc.XImportMissionControlResponse} message XImportMissionControlResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        XImportMissionControlResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this XImportMissionControlResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.XImportMissionControlResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        XImportMissionControlResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return XImportMissionControlResponse;
    })();

    routerrpc.PairHistory = (function() {

        /**
         * Properties of a PairHistory.
         * @memberof routerrpc
         * @interface IPairHistory
         * @property {Uint8Array|null} [nodeFrom] PairHistory nodeFrom
         * @property {Uint8Array|null} [nodeTo] PairHistory nodeTo
         * @property {routerrpc.IPairData|null} [history] PairHistory history
         */

        /**
         * Constructs a new PairHistory.
         * @memberof routerrpc
         * @classdesc Represents a PairHistory.
         * @implements IPairHistory
         * @constructor
         * @param {routerrpc.IPairHistory=} [properties] Properties to set
         */
        function PairHistory(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PairHistory nodeFrom.
         * @member {Uint8Array} nodeFrom
         * @memberof routerrpc.PairHistory
         * @instance
         */
        PairHistory.prototype.nodeFrom = $util.newBuffer([]);

        /**
         * PairHistory nodeTo.
         * @member {Uint8Array} nodeTo
         * @memberof routerrpc.PairHistory
         * @instance
         */
        PairHistory.prototype.nodeTo = $util.newBuffer([]);

        /**
         * PairHistory history.
         * @member {routerrpc.IPairData|null|undefined} history
         * @memberof routerrpc.PairHistory
         * @instance
         */
        PairHistory.prototype.history = null;

        /**
         * Creates a new PairHistory instance using the specified properties.
         * @function create
         * @memberof routerrpc.PairHistory
         * @static
         * @param {routerrpc.IPairHistory=} [properties] Properties to set
         * @returns {routerrpc.PairHistory} PairHistory instance
         */
        PairHistory.create = function create(properties) {
            return new PairHistory(properties);
        };

        /**
         * Encodes the specified PairHistory message. Does not implicitly {@link routerrpc.PairHistory.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.PairHistory
         * @static
         * @param {routerrpc.IPairHistory} message PairHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PairHistory.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nodeFrom != null && Object.hasOwnProperty.call(message, "nodeFrom"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.nodeFrom);
            if (message.nodeTo != null && Object.hasOwnProperty.call(message, "nodeTo"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.nodeTo);
            if (message.history != null && Object.hasOwnProperty.call(message, "history"))
                $root.routerrpc.PairData.encode(message.history, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PairHistory message, length delimited. Does not implicitly {@link routerrpc.PairHistory.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.PairHistory
         * @static
         * @param {routerrpc.IPairHistory} message PairHistory message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PairHistory.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PairHistory message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.PairHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.PairHistory} PairHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PairHistory.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.PairHistory();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nodeFrom = reader.bytes();
                        break;
                    }
                case 2: {
                        message.nodeTo = reader.bytes();
                        break;
                    }
                case 7: {
                        message.history = $root.routerrpc.PairData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PairHistory message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.PairHistory
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.PairHistory} PairHistory
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PairHistory.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PairHistory message.
         * @function verify
         * @memberof routerrpc.PairHistory
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PairHistory.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nodeFrom != null && message.hasOwnProperty("nodeFrom"))
                if (!(message.nodeFrom && typeof message.nodeFrom.length === "number" || $util.isString(message.nodeFrom)))
                    return "nodeFrom: buffer expected";
            if (message.nodeTo != null && message.hasOwnProperty("nodeTo"))
                if (!(message.nodeTo && typeof message.nodeTo.length === "number" || $util.isString(message.nodeTo)))
                    return "nodeTo: buffer expected";
            if (message.history != null && message.hasOwnProperty("history")) {
                let error = $root.routerrpc.PairData.verify(message.history);
                if (error)
                    return "history." + error;
            }
            return null;
        };

        /**
         * Creates a PairHistory message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.PairHistory
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.PairHistory} PairHistory
         */
        PairHistory.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.PairHistory)
                return object;
            let message = new $root.routerrpc.PairHistory();
            if (object.nodeFrom != null)
                if (typeof object.nodeFrom === "string")
                    $util.base64.decode(object.nodeFrom, message.nodeFrom = $util.newBuffer($util.base64.length(object.nodeFrom)), 0);
                else if (object.nodeFrom.length)
                    message.nodeFrom = object.nodeFrom;
            if (object.nodeTo != null)
                if (typeof object.nodeTo === "string")
                    $util.base64.decode(object.nodeTo, message.nodeTo = $util.newBuffer($util.base64.length(object.nodeTo)), 0);
                else if (object.nodeTo.length)
                    message.nodeTo = object.nodeTo;
            if (object.history != null) {
                if (typeof object.history !== "object")
                    throw TypeError(".routerrpc.PairHistory.history: object expected");
                message.history = $root.routerrpc.PairData.fromObject(object.history);
            }
            return message;
        };

        /**
         * Creates a plain object from a PairHistory message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.PairHistory
         * @static
         * @param {routerrpc.PairHistory} message PairHistory
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PairHistory.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.nodeFrom = "";
                else {
                    object.nodeFrom = [];
                    if (options.bytes !== Array)
                        object.nodeFrom = $util.newBuffer(object.nodeFrom);
                }
                if (options.bytes === String)
                    object.nodeTo = "";
                else {
                    object.nodeTo = [];
                    if (options.bytes !== Array)
                        object.nodeTo = $util.newBuffer(object.nodeTo);
                }
                object.history = null;
            }
            if (message.nodeFrom != null && message.hasOwnProperty("nodeFrom"))
                object.nodeFrom = options.bytes === String ? $util.base64.encode(message.nodeFrom, 0, message.nodeFrom.length) : options.bytes === Array ? Array.prototype.slice.call(message.nodeFrom) : message.nodeFrom;
            if (message.nodeTo != null && message.hasOwnProperty("nodeTo"))
                object.nodeTo = options.bytes === String ? $util.base64.encode(message.nodeTo, 0, message.nodeTo.length) : options.bytes === Array ? Array.prototype.slice.call(message.nodeTo) : message.nodeTo;
            if (message.history != null && message.hasOwnProperty("history"))
                object.history = $root.routerrpc.PairData.toObject(message.history, options);
            return object;
        };

        /**
         * Converts this PairHistory to JSON.
         * @function toJSON
         * @memberof routerrpc.PairHistory
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PairHistory.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PairHistory;
    })();

    routerrpc.PairData = (function() {

        /**
         * Properties of a PairData.
         * @memberof routerrpc
         * @interface IPairData
         * @property {Long|null} [failTime] PairData failTime
         * @property {Long|null} [failAmtSat] PairData failAmtSat
         * @property {Long|null} [failAmtMsat] PairData failAmtMsat
         * @property {Long|null} [successTime] PairData successTime
         * @property {Long|null} [successAmtSat] PairData successAmtSat
         * @property {Long|null} [successAmtMsat] PairData successAmtMsat
         */

        /**
         * Constructs a new PairData.
         * @memberof routerrpc
         * @classdesc Represents a PairData.
         * @implements IPairData
         * @constructor
         * @param {routerrpc.IPairData=} [properties] Properties to set
         */
        function PairData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PairData failTime.
         * @member {Long} failTime
         * @memberof routerrpc.PairData
         * @instance
         */
        PairData.prototype.failTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PairData failAmtSat.
         * @member {Long} failAmtSat
         * @memberof routerrpc.PairData
         * @instance
         */
        PairData.prototype.failAmtSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PairData failAmtMsat.
         * @member {Long} failAmtMsat
         * @memberof routerrpc.PairData
         * @instance
         */
        PairData.prototype.failAmtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PairData successTime.
         * @member {Long} successTime
         * @memberof routerrpc.PairData
         * @instance
         */
        PairData.prototype.successTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PairData successAmtSat.
         * @member {Long} successAmtSat
         * @memberof routerrpc.PairData
         * @instance
         */
        PairData.prototype.successAmtSat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * PairData successAmtMsat.
         * @member {Long} successAmtMsat
         * @memberof routerrpc.PairData
         * @instance
         */
        PairData.prototype.successAmtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new PairData instance using the specified properties.
         * @function create
         * @memberof routerrpc.PairData
         * @static
         * @param {routerrpc.IPairData=} [properties] Properties to set
         * @returns {routerrpc.PairData} PairData instance
         */
        PairData.create = function create(properties) {
            return new PairData(properties);
        };

        /**
         * Encodes the specified PairData message. Does not implicitly {@link routerrpc.PairData.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.PairData
         * @static
         * @param {routerrpc.IPairData} message PairData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PairData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.failTime != null && Object.hasOwnProperty.call(message, "failTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.failTime);
            if (message.failAmtSat != null && Object.hasOwnProperty.call(message, "failAmtSat"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.failAmtSat);
            if (message.failAmtMsat != null && Object.hasOwnProperty.call(message, "failAmtMsat"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.failAmtMsat);
            if (message.successTime != null && Object.hasOwnProperty.call(message, "successTime"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.successTime);
            if (message.successAmtSat != null && Object.hasOwnProperty.call(message, "successAmtSat"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.successAmtSat);
            if (message.successAmtMsat != null && Object.hasOwnProperty.call(message, "successAmtMsat"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.successAmtMsat);
            return writer;
        };

        /**
         * Encodes the specified PairData message, length delimited. Does not implicitly {@link routerrpc.PairData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.PairData
         * @static
         * @param {routerrpc.IPairData} message PairData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PairData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PairData message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.PairData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.PairData} PairData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PairData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.PairData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.failTime = reader.int64();
                        break;
                    }
                case 2: {
                        message.failAmtSat = reader.int64();
                        break;
                    }
                case 4: {
                        message.failAmtMsat = reader.int64();
                        break;
                    }
                case 5: {
                        message.successTime = reader.int64();
                        break;
                    }
                case 6: {
                        message.successAmtSat = reader.int64();
                        break;
                    }
                case 7: {
                        message.successAmtMsat = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PairData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.PairData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.PairData} PairData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PairData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PairData message.
         * @function verify
         * @memberof routerrpc.PairData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PairData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.failTime != null && message.hasOwnProperty("failTime"))
                if (!$util.isInteger(message.failTime) && !(message.failTime && $util.isInteger(message.failTime.low) && $util.isInteger(message.failTime.high)))
                    return "failTime: integer|Long expected";
            if (message.failAmtSat != null && message.hasOwnProperty("failAmtSat"))
                if (!$util.isInteger(message.failAmtSat) && !(message.failAmtSat && $util.isInteger(message.failAmtSat.low) && $util.isInteger(message.failAmtSat.high)))
                    return "failAmtSat: integer|Long expected";
            if (message.failAmtMsat != null && message.hasOwnProperty("failAmtMsat"))
                if (!$util.isInteger(message.failAmtMsat) && !(message.failAmtMsat && $util.isInteger(message.failAmtMsat.low) && $util.isInteger(message.failAmtMsat.high)))
                    return "failAmtMsat: integer|Long expected";
            if (message.successTime != null && message.hasOwnProperty("successTime"))
                if (!$util.isInteger(message.successTime) && !(message.successTime && $util.isInteger(message.successTime.low) && $util.isInteger(message.successTime.high)))
                    return "successTime: integer|Long expected";
            if (message.successAmtSat != null && message.hasOwnProperty("successAmtSat"))
                if (!$util.isInteger(message.successAmtSat) && !(message.successAmtSat && $util.isInteger(message.successAmtSat.low) && $util.isInteger(message.successAmtSat.high)))
                    return "successAmtSat: integer|Long expected";
            if (message.successAmtMsat != null && message.hasOwnProperty("successAmtMsat"))
                if (!$util.isInteger(message.successAmtMsat) && !(message.successAmtMsat && $util.isInteger(message.successAmtMsat.low) && $util.isInteger(message.successAmtMsat.high)))
                    return "successAmtMsat: integer|Long expected";
            return null;
        };

        /**
         * Creates a PairData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.PairData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.PairData} PairData
         */
        PairData.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.PairData)
                return object;
            let message = new $root.routerrpc.PairData();
            if (object.failTime != null)
                if ($util.Long)
                    (message.failTime = $util.Long.fromValue(object.failTime)).unsigned = false;
                else if (typeof object.failTime === "string")
                    message.failTime = parseInt(object.failTime, 10);
                else if (typeof object.failTime === "number")
                    message.failTime = object.failTime;
                else if (typeof object.failTime === "object")
                    message.failTime = new $util.LongBits(object.failTime.low >>> 0, object.failTime.high >>> 0).toNumber();
            if (object.failAmtSat != null)
                if ($util.Long)
                    (message.failAmtSat = $util.Long.fromValue(object.failAmtSat)).unsigned = false;
                else if (typeof object.failAmtSat === "string")
                    message.failAmtSat = parseInt(object.failAmtSat, 10);
                else if (typeof object.failAmtSat === "number")
                    message.failAmtSat = object.failAmtSat;
                else if (typeof object.failAmtSat === "object")
                    message.failAmtSat = new $util.LongBits(object.failAmtSat.low >>> 0, object.failAmtSat.high >>> 0).toNumber();
            if (object.failAmtMsat != null)
                if ($util.Long)
                    (message.failAmtMsat = $util.Long.fromValue(object.failAmtMsat)).unsigned = false;
                else if (typeof object.failAmtMsat === "string")
                    message.failAmtMsat = parseInt(object.failAmtMsat, 10);
                else if (typeof object.failAmtMsat === "number")
                    message.failAmtMsat = object.failAmtMsat;
                else if (typeof object.failAmtMsat === "object")
                    message.failAmtMsat = new $util.LongBits(object.failAmtMsat.low >>> 0, object.failAmtMsat.high >>> 0).toNumber();
            if (object.successTime != null)
                if ($util.Long)
                    (message.successTime = $util.Long.fromValue(object.successTime)).unsigned = false;
                else if (typeof object.successTime === "string")
                    message.successTime = parseInt(object.successTime, 10);
                else if (typeof object.successTime === "number")
                    message.successTime = object.successTime;
                else if (typeof object.successTime === "object")
                    message.successTime = new $util.LongBits(object.successTime.low >>> 0, object.successTime.high >>> 0).toNumber();
            if (object.successAmtSat != null)
                if ($util.Long)
                    (message.successAmtSat = $util.Long.fromValue(object.successAmtSat)).unsigned = false;
                else if (typeof object.successAmtSat === "string")
                    message.successAmtSat = parseInt(object.successAmtSat, 10);
                else if (typeof object.successAmtSat === "number")
                    message.successAmtSat = object.successAmtSat;
                else if (typeof object.successAmtSat === "object")
                    message.successAmtSat = new $util.LongBits(object.successAmtSat.low >>> 0, object.successAmtSat.high >>> 0).toNumber();
            if (object.successAmtMsat != null)
                if ($util.Long)
                    (message.successAmtMsat = $util.Long.fromValue(object.successAmtMsat)).unsigned = false;
                else if (typeof object.successAmtMsat === "string")
                    message.successAmtMsat = parseInt(object.successAmtMsat, 10);
                else if (typeof object.successAmtMsat === "number")
                    message.successAmtMsat = object.successAmtMsat;
                else if (typeof object.successAmtMsat === "object")
                    message.successAmtMsat = new $util.LongBits(object.successAmtMsat.low >>> 0, object.successAmtMsat.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a PairData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.PairData
         * @static
         * @param {routerrpc.PairData} message PairData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PairData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.failTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.failTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.failAmtSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.failAmtSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.failAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.failAmtMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.successTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.successTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.successAmtSat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.successAmtSat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.successAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.successAmtMsat = options.longs === String ? "0" : 0;
            }
            if (message.failTime != null && message.hasOwnProperty("failTime"))
                if (typeof message.failTime === "number")
                    object.failTime = options.longs === String ? String(message.failTime) : message.failTime;
                else
                    object.failTime = options.longs === String ? $util.Long.prototype.toString.call(message.failTime) : options.longs === Number ? new $util.LongBits(message.failTime.low >>> 0, message.failTime.high >>> 0).toNumber() : message.failTime;
            if (message.failAmtSat != null && message.hasOwnProperty("failAmtSat"))
                if (typeof message.failAmtSat === "number")
                    object.failAmtSat = options.longs === String ? String(message.failAmtSat) : message.failAmtSat;
                else
                    object.failAmtSat = options.longs === String ? $util.Long.prototype.toString.call(message.failAmtSat) : options.longs === Number ? new $util.LongBits(message.failAmtSat.low >>> 0, message.failAmtSat.high >>> 0).toNumber() : message.failAmtSat;
            if (message.failAmtMsat != null && message.hasOwnProperty("failAmtMsat"))
                if (typeof message.failAmtMsat === "number")
                    object.failAmtMsat = options.longs === String ? String(message.failAmtMsat) : message.failAmtMsat;
                else
                    object.failAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.failAmtMsat) : options.longs === Number ? new $util.LongBits(message.failAmtMsat.low >>> 0, message.failAmtMsat.high >>> 0).toNumber() : message.failAmtMsat;
            if (message.successTime != null && message.hasOwnProperty("successTime"))
                if (typeof message.successTime === "number")
                    object.successTime = options.longs === String ? String(message.successTime) : message.successTime;
                else
                    object.successTime = options.longs === String ? $util.Long.prototype.toString.call(message.successTime) : options.longs === Number ? new $util.LongBits(message.successTime.low >>> 0, message.successTime.high >>> 0).toNumber() : message.successTime;
            if (message.successAmtSat != null && message.hasOwnProperty("successAmtSat"))
                if (typeof message.successAmtSat === "number")
                    object.successAmtSat = options.longs === String ? String(message.successAmtSat) : message.successAmtSat;
                else
                    object.successAmtSat = options.longs === String ? $util.Long.prototype.toString.call(message.successAmtSat) : options.longs === Number ? new $util.LongBits(message.successAmtSat.low >>> 0, message.successAmtSat.high >>> 0).toNumber() : message.successAmtSat;
            if (message.successAmtMsat != null && message.hasOwnProperty("successAmtMsat"))
                if (typeof message.successAmtMsat === "number")
                    object.successAmtMsat = options.longs === String ? String(message.successAmtMsat) : message.successAmtMsat;
                else
                    object.successAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.successAmtMsat) : options.longs === Number ? new $util.LongBits(message.successAmtMsat.low >>> 0, message.successAmtMsat.high >>> 0).toNumber() : message.successAmtMsat;
            return object;
        };

        /**
         * Converts this PairData to JSON.
         * @function toJSON
         * @memberof routerrpc.PairData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PairData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PairData;
    })();

    routerrpc.GetMissionControlConfigRequest = (function() {

        /**
         * Properties of a GetMissionControlConfigRequest.
         * @memberof routerrpc
         * @interface IGetMissionControlConfigRequest
         */

        /**
         * Constructs a new GetMissionControlConfigRequest.
         * @memberof routerrpc
         * @classdesc Represents a GetMissionControlConfigRequest.
         * @implements IGetMissionControlConfigRequest
         * @constructor
         * @param {routerrpc.IGetMissionControlConfigRequest=} [properties] Properties to set
         */
        function GetMissionControlConfigRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetMissionControlConfigRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {routerrpc.IGetMissionControlConfigRequest=} [properties] Properties to set
         * @returns {routerrpc.GetMissionControlConfigRequest} GetMissionControlConfigRequest instance
         */
        GetMissionControlConfigRequest.create = function create(properties) {
            return new GetMissionControlConfigRequest(properties);
        };

        /**
         * Encodes the specified GetMissionControlConfigRequest message. Does not implicitly {@link routerrpc.GetMissionControlConfigRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {routerrpc.IGetMissionControlConfigRequest} message GetMissionControlConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMissionControlConfigRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetMissionControlConfigRequest message, length delimited. Does not implicitly {@link routerrpc.GetMissionControlConfigRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {routerrpc.IGetMissionControlConfigRequest} message GetMissionControlConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMissionControlConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMissionControlConfigRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.GetMissionControlConfigRequest} GetMissionControlConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMissionControlConfigRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.GetMissionControlConfigRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMissionControlConfigRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.GetMissionControlConfigRequest} GetMissionControlConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMissionControlConfigRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMissionControlConfigRequest message.
         * @function verify
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMissionControlConfigRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetMissionControlConfigRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.GetMissionControlConfigRequest} GetMissionControlConfigRequest
         */
        GetMissionControlConfigRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.GetMissionControlConfigRequest)
                return object;
            return new $root.routerrpc.GetMissionControlConfigRequest();
        };

        /**
         * Creates a plain object from a GetMissionControlConfigRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @static
         * @param {routerrpc.GetMissionControlConfigRequest} message GetMissionControlConfigRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMissionControlConfigRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetMissionControlConfigRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.GetMissionControlConfigRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMissionControlConfigRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMissionControlConfigRequest;
    })();

    routerrpc.GetMissionControlConfigResponse = (function() {

        /**
         * Properties of a GetMissionControlConfigResponse.
         * @memberof routerrpc
         * @interface IGetMissionControlConfigResponse
         * @property {routerrpc.IMissionControlConfig|null} [config] GetMissionControlConfigResponse config
         */

        /**
         * Constructs a new GetMissionControlConfigResponse.
         * @memberof routerrpc
         * @classdesc Represents a GetMissionControlConfigResponse.
         * @implements IGetMissionControlConfigResponse
         * @constructor
         * @param {routerrpc.IGetMissionControlConfigResponse=} [properties] Properties to set
         */
        function GetMissionControlConfigResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMissionControlConfigResponse config.
         * @member {routerrpc.IMissionControlConfig|null|undefined} config
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @instance
         */
        GetMissionControlConfigResponse.prototype.config = null;

        /**
         * Creates a new GetMissionControlConfigResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {routerrpc.IGetMissionControlConfigResponse=} [properties] Properties to set
         * @returns {routerrpc.GetMissionControlConfigResponse} GetMissionControlConfigResponse instance
         */
        GetMissionControlConfigResponse.create = function create(properties) {
            return new GetMissionControlConfigResponse(properties);
        };

        /**
         * Encodes the specified GetMissionControlConfigResponse message. Does not implicitly {@link routerrpc.GetMissionControlConfigResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {routerrpc.IGetMissionControlConfigResponse} message GetMissionControlConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMissionControlConfigResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.routerrpc.MissionControlConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetMissionControlConfigResponse message, length delimited. Does not implicitly {@link routerrpc.GetMissionControlConfigResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {routerrpc.IGetMissionControlConfigResponse} message GetMissionControlConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMissionControlConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMissionControlConfigResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.GetMissionControlConfigResponse} GetMissionControlConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMissionControlConfigResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.GetMissionControlConfigResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.config = $root.routerrpc.MissionControlConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMissionControlConfigResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.GetMissionControlConfigResponse} GetMissionControlConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMissionControlConfigResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMissionControlConfigResponse message.
         * @function verify
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMissionControlConfigResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config")) {
                let error = $root.routerrpc.MissionControlConfig.verify(message.config);
                if (error)
                    return "config." + error;
            }
            return null;
        };

        /**
         * Creates a GetMissionControlConfigResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.GetMissionControlConfigResponse} GetMissionControlConfigResponse
         */
        GetMissionControlConfigResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.GetMissionControlConfigResponse)
                return object;
            let message = new $root.routerrpc.GetMissionControlConfigResponse();
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".routerrpc.GetMissionControlConfigResponse.config: object expected");
                message.config = $root.routerrpc.MissionControlConfig.fromObject(object.config);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetMissionControlConfigResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @static
         * @param {routerrpc.GetMissionControlConfigResponse} message GetMissionControlConfigResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMissionControlConfigResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.config = null;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = $root.routerrpc.MissionControlConfig.toObject(message.config, options);
            return object;
        };

        /**
         * Converts this GetMissionControlConfigResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.GetMissionControlConfigResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMissionControlConfigResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetMissionControlConfigResponse;
    })();

    routerrpc.SetMissionControlConfigRequest = (function() {

        /**
         * Properties of a SetMissionControlConfigRequest.
         * @memberof routerrpc
         * @interface ISetMissionControlConfigRequest
         * @property {routerrpc.IMissionControlConfig|null} [config] SetMissionControlConfigRequest config
         */

        /**
         * Constructs a new SetMissionControlConfigRequest.
         * @memberof routerrpc
         * @classdesc Represents a SetMissionControlConfigRequest.
         * @implements ISetMissionControlConfigRequest
         * @constructor
         * @param {routerrpc.ISetMissionControlConfigRequest=} [properties] Properties to set
         */
        function SetMissionControlConfigRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetMissionControlConfigRequest config.
         * @member {routerrpc.IMissionControlConfig|null|undefined} config
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @instance
         */
        SetMissionControlConfigRequest.prototype.config = null;

        /**
         * Creates a new SetMissionControlConfigRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {routerrpc.ISetMissionControlConfigRequest=} [properties] Properties to set
         * @returns {routerrpc.SetMissionControlConfigRequest} SetMissionControlConfigRequest instance
         */
        SetMissionControlConfigRequest.create = function create(properties) {
            return new SetMissionControlConfigRequest(properties);
        };

        /**
         * Encodes the specified SetMissionControlConfigRequest message. Does not implicitly {@link routerrpc.SetMissionControlConfigRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {routerrpc.ISetMissionControlConfigRequest} message SetMissionControlConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMissionControlConfigRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.config != null && Object.hasOwnProperty.call(message, "config"))
                $root.routerrpc.MissionControlConfig.encode(message.config, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SetMissionControlConfigRequest message, length delimited. Does not implicitly {@link routerrpc.SetMissionControlConfigRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {routerrpc.ISetMissionControlConfigRequest} message SetMissionControlConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMissionControlConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMissionControlConfigRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SetMissionControlConfigRequest} SetMissionControlConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMissionControlConfigRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SetMissionControlConfigRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.config = $root.routerrpc.MissionControlConfig.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMissionControlConfigRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SetMissionControlConfigRequest} SetMissionControlConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMissionControlConfigRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMissionControlConfigRequest message.
         * @function verify
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMissionControlConfigRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.config != null && message.hasOwnProperty("config")) {
                let error = $root.routerrpc.MissionControlConfig.verify(message.config);
                if (error)
                    return "config." + error;
            }
            return null;
        };

        /**
         * Creates a SetMissionControlConfigRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SetMissionControlConfigRequest} SetMissionControlConfigRequest
         */
        SetMissionControlConfigRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SetMissionControlConfigRequest)
                return object;
            let message = new $root.routerrpc.SetMissionControlConfigRequest();
            if (object.config != null) {
                if (typeof object.config !== "object")
                    throw TypeError(".routerrpc.SetMissionControlConfigRequest.config: object expected");
                message.config = $root.routerrpc.MissionControlConfig.fromObject(object.config);
            }
            return message;
        };

        /**
         * Creates a plain object from a SetMissionControlConfigRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @static
         * @param {routerrpc.SetMissionControlConfigRequest} message SetMissionControlConfigRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMissionControlConfigRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.config = null;
            if (message.config != null && message.hasOwnProperty("config"))
                object.config = $root.routerrpc.MissionControlConfig.toObject(message.config, options);
            return object;
        };

        /**
         * Converts this SetMissionControlConfigRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.SetMissionControlConfigRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMissionControlConfigRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetMissionControlConfigRequest;
    })();

    routerrpc.SetMissionControlConfigResponse = (function() {

        /**
         * Properties of a SetMissionControlConfigResponse.
         * @memberof routerrpc
         * @interface ISetMissionControlConfigResponse
         */

        /**
         * Constructs a new SetMissionControlConfigResponse.
         * @memberof routerrpc
         * @classdesc Represents a SetMissionControlConfigResponse.
         * @implements ISetMissionControlConfigResponse
         * @constructor
         * @param {routerrpc.ISetMissionControlConfigResponse=} [properties] Properties to set
         */
        function SetMissionControlConfigResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetMissionControlConfigResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {routerrpc.ISetMissionControlConfigResponse=} [properties] Properties to set
         * @returns {routerrpc.SetMissionControlConfigResponse} SetMissionControlConfigResponse instance
         */
        SetMissionControlConfigResponse.create = function create(properties) {
            return new SetMissionControlConfigResponse(properties);
        };

        /**
         * Encodes the specified SetMissionControlConfigResponse message. Does not implicitly {@link routerrpc.SetMissionControlConfigResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {routerrpc.ISetMissionControlConfigResponse} message SetMissionControlConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMissionControlConfigResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetMissionControlConfigResponse message, length delimited. Does not implicitly {@link routerrpc.SetMissionControlConfigResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {routerrpc.ISetMissionControlConfigResponse} message SetMissionControlConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetMissionControlConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetMissionControlConfigResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SetMissionControlConfigResponse} SetMissionControlConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMissionControlConfigResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SetMissionControlConfigResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetMissionControlConfigResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SetMissionControlConfigResponse} SetMissionControlConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetMissionControlConfigResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetMissionControlConfigResponse message.
         * @function verify
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetMissionControlConfigResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetMissionControlConfigResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SetMissionControlConfigResponse} SetMissionControlConfigResponse
         */
        SetMissionControlConfigResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SetMissionControlConfigResponse)
                return object;
            return new $root.routerrpc.SetMissionControlConfigResponse();
        };

        /**
         * Creates a plain object from a SetMissionControlConfigResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @static
         * @param {routerrpc.SetMissionControlConfigResponse} message SetMissionControlConfigResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetMissionControlConfigResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetMissionControlConfigResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.SetMissionControlConfigResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetMissionControlConfigResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetMissionControlConfigResponse;
    })();

    routerrpc.MissionControlConfig = (function() {

        /**
         * Properties of a MissionControlConfig.
         * @memberof routerrpc
         * @interface IMissionControlConfig
         * @property {Long|null} [halfLifeSeconds] MissionControlConfig halfLifeSeconds
         * @property {number|null} [hopProbability] MissionControlConfig hopProbability
         * @property {number|null} [weight] MissionControlConfig weight
         * @property {number|null} [maximumPaymentResults] MissionControlConfig maximumPaymentResults
         * @property {Long|null} [minimumFailureRelaxInterval] MissionControlConfig minimumFailureRelaxInterval
         */

        /**
         * Constructs a new MissionControlConfig.
         * @memberof routerrpc
         * @classdesc Represents a MissionControlConfig.
         * @implements IMissionControlConfig
         * @constructor
         * @param {routerrpc.IMissionControlConfig=} [properties] Properties to set
         */
        function MissionControlConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MissionControlConfig halfLifeSeconds.
         * @member {Long} halfLifeSeconds
         * @memberof routerrpc.MissionControlConfig
         * @instance
         */
        MissionControlConfig.prototype.halfLifeSeconds = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * MissionControlConfig hopProbability.
         * @member {number} hopProbability
         * @memberof routerrpc.MissionControlConfig
         * @instance
         */
        MissionControlConfig.prototype.hopProbability = 0;

        /**
         * MissionControlConfig weight.
         * @member {number} weight
         * @memberof routerrpc.MissionControlConfig
         * @instance
         */
        MissionControlConfig.prototype.weight = 0;

        /**
         * MissionControlConfig maximumPaymentResults.
         * @member {number} maximumPaymentResults
         * @memberof routerrpc.MissionControlConfig
         * @instance
         */
        MissionControlConfig.prototype.maximumPaymentResults = 0;

        /**
         * MissionControlConfig minimumFailureRelaxInterval.
         * @member {Long} minimumFailureRelaxInterval
         * @memberof routerrpc.MissionControlConfig
         * @instance
         */
        MissionControlConfig.prototype.minimumFailureRelaxInterval = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new MissionControlConfig instance using the specified properties.
         * @function create
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {routerrpc.IMissionControlConfig=} [properties] Properties to set
         * @returns {routerrpc.MissionControlConfig} MissionControlConfig instance
         */
        MissionControlConfig.create = function create(properties) {
            return new MissionControlConfig(properties);
        };

        /**
         * Encodes the specified MissionControlConfig message. Does not implicitly {@link routerrpc.MissionControlConfig.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {routerrpc.IMissionControlConfig} message MissionControlConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MissionControlConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.halfLifeSeconds != null && Object.hasOwnProperty.call(message, "halfLifeSeconds"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.halfLifeSeconds);
            if (message.hopProbability != null && Object.hasOwnProperty.call(message, "hopProbability"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.hopProbability);
            if (message.weight != null && Object.hasOwnProperty.call(message, "weight"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.weight);
            if (message.maximumPaymentResults != null && Object.hasOwnProperty.call(message, "maximumPaymentResults"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.maximumPaymentResults);
            if (message.minimumFailureRelaxInterval != null && Object.hasOwnProperty.call(message, "minimumFailureRelaxInterval"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.minimumFailureRelaxInterval);
            return writer;
        };

        /**
         * Encodes the specified MissionControlConfig message, length delimited. Does not implicitly {@link routerrpc.MissionControlConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {routerrpc.IMissionControlConfig} message MissionControlConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MissionControlConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MissionControlConfig message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.MissionControlConfig} MissionControlConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MissionControlConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.MissionControlConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.halfLifeSeconds = reader.uint64();
                        break;
                    }
                case 2: {
                        message.hopProbability = reader.float();
                        break;
                    }
                case 3: {
                        message.weight = reader.float();
                        break;
                    }
                case 4: {
                        message.maximumPaymentResults = reader.uint32();
                        break;
                    }
                case 5: {
                        message.minimumFailureRelaxInterval = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MissionControlConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.MissionControlConfig} MissionControlConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MissionControlConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MissionControlConfig message.
         * @function verify
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MissionControlConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.halfLifeSeconds != null && message.hasOwnProperty("halfLifeSeconds"))
                if (!$util.isInteger(message.halfLifeSeconds) && !(message.halfLifeSeconds && $util.isInteger(message.halfLifeSeconds.low) && $util.isInteger(message.halfLifeSeconds.high)))
                    return "halfLifeSeconds: integer|Long expected";
            if (message.hopProbability != null && message.hasOwnProperty("hopProbability"))
                if (typeof message.hopProbability !== "number")
                    return "hopProbability: number expected";
            if (message.weight != null && message.hasOwnProperty("weight"))
                if (typeof message.weight !== "number")
                    return "weight: number expected";
            if (message.maximumPaymentResults != null && message.hasOwnProperty("maximumPaymentResults"))
                if (!$util.isInteger(message.maximumPaymentResults))
                    return "maximumPaymentResults: integer expected";
            if (message.minimumFailureRelaxInterval != null && message.hasOwnProperty("minimumFailureRelaxInterval"))
                if (!$util.isInteger(message.minimumFailureRelaxInterval) && !(message.minimumFailureRelaxInterval && $util.isInteger(message.minimumFailureRelaxInterval.low) && $util.isInteger(message.minimumFailureRelaxInterval.high)))
                    return "minimumFailureRelaxInterval: integer|Long expected";
            return null;
        };

        /**
         * Creates a MissionControlConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.MissionControlConfig} MissionControlConfig
         */
        MissionControlConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.MissionControlConfig)
                return object;
            let message = new $root.routerrpc.MissionControlConfig();
            if (object.halfLifeSeconds != null)
                if ($util.Long)
                    (message.halfLifeSeconds = $util.Long.fromValue(object.halfLifeSeconds)).unsigned = true;
                else if (typeof object.halfLifeSeconds === "string")
                    message.halfLifeSeconds = parseInt(object.halfLifeSeconds, 10);
                else if (typeof object.halfLifeSeconds === "number")
                    message.halfLifeSeconds = object.halfLifeSeconds;
                else if (typeof object.halfLifeSeconds === "object")
                    message.halfLifeSeconds = new $util.LongBits(object.halfLifeSeconds.low >>> 0, object.halfLifeSeconds.high >>> 0).toNumber(true);
            if (object.hopProbability != null)
                message.hopProbability = Number(object.hopProbability);
            if (object.weight != null)
                message.weight = Number(object.weight);
            if (object.maximumPaymentResults != null)
                message.maximumPaymentResults = object.maximumPaymentResults >>> 0;
            if (object.minimumFailureRelaxInterval != null)
                if ($util.Long)
                    (message.minimumFailureRelaxInterval = $util.Long.fromValue(object.minimumFailureRelaxInterval)).unsigned = true;
                else if (typeof object.minimumFailureRelaxInterval === "string")
                    message.minimumFailureRelaxInterval = parseInt(object.minimumFailureRelaxInterval, 10);
                else if (typeof object.minimumFailureRelaxInterval === "number")
                    message.minimumFailureRelaxInterval = object.minimumFailureRelaxInterval;
                else if (typeof object.minimumFailureRelaxInterval === "object")
                    message.minimumFailureRelaxInterval = new $util.LongBits(object.minimumFailureRelaxInterval.low >>> 0, object.minimumFailureRelaxInterval.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a MissionControlConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.MissionControlConfig
         * @static
         * @param {routerrpc.MissionControlConfig} message MissionControlConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MissionControlConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.halfLifeSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.halfLifeSeconds = options.longs === String ? "0" : 0;
                object.hopProbability = 0;
                object.weight = 0;
                object.maximumPaymentResults = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.minimumFailureRelaxInterval = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minimumFailureRelaxInterval = options.longs === String ? "0" : 0;
            }
            if (message.halfLifeSeconds != null && message.hasOwnProperty("halfLifeSeconds"))
                if (typeof message.halfLifeSeconds === "number")
                    object.halfLifeSeconds = options.longs === String ? String(message.halfLifeSeconds) : message.halfLifeSeconds;
                else
                    object.halfLifeSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.halfLifeSeconds) : options.longs === Number ? new $util.LongBits(message.halfLifeSeconds.low >>> 0, message.halfLifeSeconds.high >>> 0).toNumber(true) : message.halfLifeSeconds;
            if (message.hopProbability != null && message.hasOwnProperty("hopProbability"))
                object.hopProbability = options.json && !isFinite(message.hopProbability) ? String(message.hopProbability) : message.hopProbability;
            if (message.weight != null && message.hasOwnProperty("weight"))
                object.weight = options.json && !isFinite(message.weight) ? String(message.weight) : message.weight;
            if (message.maximumPaymentResults != null && message.hasOwnProperty("maximumPaymentResults"))
                object.maximumPaymentResults = message.maximumPaymentResults;
            if (message.minimumFailureRelaxInterval != null && message.hasOwnProperty("minimumFailureRelaxInterval"))
                if (typeof message.minimumFailureRelaxInterval === "number")
                    object.minimumFailureRelaxInterval = options.longs === String ? String(message.minimumFailureRelaxInterval) : message.minimumFailureRelaxInterval;
                else
                    object.minimumFailureRelaxInterval = options.longs === String ? $util.Long.prototype.toString.call(message.minimumFailureRelaxInterval) : options.longs === Number ? new $util.LongBits(message.minimumFailureRelaxInterval.low >>> 0, message.minimumFailureRelaxInterval.high >>> 0).toNumber(true) : message.minimumFailureRelaxInterval;
            return object;
        };

        /**
         * Converts this MissionControlConfig to JSON.
         * @function toJSON
         * @memberof routerrpc.MissionControlConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MissionControlConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MissionControlConfig;
    })();

    routerrpc.QueryProbabilityRequest = (function() {

        /**
         * Properties of a QueryProbabilityRequest.
         * @memberof routerrpc
         * @interface IQueryProbabilityRequest
         * @property {Uint8Array|null} [fromNode] QueryProbabilityRequest fromNode
         * @property {Uint8Array|null} [toNode] QueryProbabilityRequest toNode
         * @property {Long|null} [amtMsat] QueryProbabilityRequest amtMsat
         */

        /**
         * Constructs a new QueryProbabilityRequest.
         * @memberof routerrpc
         * @classdesc Represents a QueryProbabilityRequest.
         * @implements IQueryProbabilityRequest
         * @constructor
         * @param {routerrpc.IQueryProbabilityRequest=} [properties] Properties to set
         */
        function QueryProbabilityRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryProbabilityRequest fromNode.
         * @member {Uint8Array} fromNode
         * @memberof routerrpc.QueryProbabilityRequest
         * @instance
         */
        QueryProbabilityRequest.prototype.fromNode = $util.newBuffer([]);

        /**
         * QueryProbabilityRequest toNode.
         * @member {Uint8Array} toNode
         * @memberof routerrpc.QueryProbabilityRequest
         * @instance
         */
        QueryProbabilityRequest.prototype.toNode = $util.newBuffer([]);

        /**
         * QueryProbabilityRequest amtMsat.
         * @member {Long} amtMsat
         * @memberof routerrpc.QueryProbabilityRequest
         * @instance
         */
        QueryProbabilityRequest.prototype.amtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new QueryProbabilityRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {routerrpc.IQueryProbabilityRequest=} [properties] Properties to set
         * @returns {routerrpc.QueryProbabilityRequest} QueryProbabilityRequest instance
         */
        QueryProbabilityRequest.create = function create(properties) {
            return new QueryProbabilityRequest(properties);
        };

        /**
         * Encodes the specified QueryProbabilityRequest message. Does not implicitly {@link routerrpc.QueryProbabilityRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {routerrpc.IQueryProbabilityRequest} message QueryProbabilityRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryProbabilityRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fromNode != null && Object.hasOwnProperty.call(message, "fromNode"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fromNode);
            if (message.toNode != null && Object.hasOwnProperty.call(message, "toNode"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.toNode);
            if (message.amtMsat != null && Object.hasOwnProperty.call(message, "amtMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amtMsat);
            return writer;
        };

        /**
         * Encodes the specified QueryProbabilityRequest message, length delimited. Does not implicitly {@link routerrpc.QueryProbabilityRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {routerrpc.IQueryProbabilityRequest} message QueryProbabilityRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryProbabilityRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryProbabilityRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.QueryProbabilityRequest} QueryProbabilityRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryProbabilityRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.QueryProbabilityRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fromNode = reader.bytes();
                        break;
                    }
                case 2: {
                        message.toNode = reader.bytes();
                        break;
                    }
                case 3: {
                        message.amtMsat = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryProbabilityRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.QueryProbabilityRequest} QueryProbabilityRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryProbabilityRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryProbabilityRequest message.
         * @function verify
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryProbabilityRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fromNode != null && message.hasOwnProperty("fromNode"))
                if (!(message.fromNode && typeof message.fromNode.length === "number" || $util.isString(message.fromNode)))
                    return "fromNode: buffer expected";
            if (message.toNode != null && message.hasOwnProperty("toNode"))
                if (!(message.toNode && typeof message.toNode.length === "number" || $util.isString(message.toNode)))
                    return "toNode: buffer expected";
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (!$util.isInteger(message.amtMsat) && !(message.amtMsat && $util.isInteger(message.amtMsat.low) && $util.isInteger(message.amtMsat.high)))
                    return "amtMsat: integer|Long expected";
            return null;
        };

        /**
         * Creates a QueryProbabilityRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.QueryProbabilityRequest} QueryProbabilityRequest
         */
        QueryProbabilityRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.QueryProbabilityRequest)
                return object;
            let message = new $root.routerrpc.QueryProbabilityRequest();
            if (object.fromNode != null)
                if (typeof object.fromNode === "string")
                    $util.base64.decode(object.fromNode, message.fromNode = $util.newBuffer($util.base64.length(object.fromNode)), 0);
                else if (object.fromNode.length)
                    message.fromNode = object.fromNode;
            if (object.toNode != null)
                if (typeof object.toNode === "string")
                    $util.base64.decode(object.toNode, message.toNode = $util.newBuffer($util.base64.length(object.toNode)), 0);
                else if (object.toNode.length)
                    message.toNode = object.toNode;
            if (object.amtMsat != null)
                if ($util.Long)
                    (message.amtMsat = $util.Long.fromValue(object.amtMsat)).unsigned = false;
                else if (typeof object.amtMsat === "string")
                    message.amtMsat = parseInt(object.amtMsat, 10);
                else if (typeof object.amtMsat === "number")
                    message.amtMsat = object.amtMsat;
                else if (typeof object.amtMsat === "object")
                    message.amtMsat = new $util.LongBits(object.amtMsat.low >>> 0, object.amtMsat.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a QueryProbabilityRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.QueryProbabilityRequest
         * @static
         * @param {routerrpc.QueryProbabilityRequest} message QueryProbabilityRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryProbabilityRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.fromNode = "";
                else {
                    object.fromNode = [];
                    if (options.bytes !== Array)
                        object.fromNode = $util.newBuffer(object.fromNode);
                }
                if (options.bytes === String)
                    object.toNode = "";
                else {
                    object.toNode = [];
                    if (options.bytes !== Array)
                        object.toNode = $util.newBuffer(object.toNode);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtMsat = options.longs === String ? "0" : 0;
            }
            if (message.fromNode != null && message.hasOwnProperty("fromNode"))
                object.fromNode = options.bytes === String ? $util.base64.encode(message.fromNode, 0, message.fromNode.length) : options.bytes === Array ? Array.prototype.slice.call(message.fromNode) : message.fromNode;
            if (message.toNode != null && message.hasOwnProperty("toNode"))
                object.toNode = options.bytes === String ? $util.base64.encode(message.toNode, 0, message.toNode.length) : options.bytes === Array ? Array.prototype.slice.call(message.toNode) : message.toNode;
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (typeof message.amtMsat === "number")
                    object.amtMsat = options.longs === String ? String(message.amtMsat) : message.amtMsat;
                else
                    object.amtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtMsat) : options.longs === Number ? new $util.LongBits(message.amtMsat.low >>> 0, message.amtMsat.high >>> 0).toNumber() : message.amtMsat;
            return object;
        };

        /**
         * Converts this QueryProbabilityRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.QueryProbabilityRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryProbabilityRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryProbabilityRequest;
    })();

    routerrpc.QueryProbabilityResponse = (function() {

        /**
         * Properties of a QueryProbabilityResponse.
         * @memberof routerrpc
         * @interface IQueryProbabilityResponse
         * @property {number|null} [probability] QueryProbabilityResponse probability
         * @property {routerrpc.IPairData|null} [history] QueryProbabilityResponse history
         */

        /**
         * Constructs a new QueryProbabilityResponse.
         * @memberof routerrpc
         * @classdesc Represents a QueryProbabilityResponse.
         * @implements IQueryProbabilityResponse
         * @constructor
         * @param {routerrpc.IQueryProbabilityResponse=} [properties] Properties to set
         */
        function QueryProbabilityResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * QueryProbabilityResponse probability.
         * @member {number} probability
         * @memberof routerrpc.QueryProbabilityResponse
         * @instance
         */
        QueryProbabilityResponse.prototype.probability = 0;

        /**
         * QueryProbabilityResponse history.
         * @member {routerrpc.IPairData|null|undefined} history
         * @memberof routerrpc.QueryProbabilityResponse
         * @instance
         */
        QueryProbabilityResponse.prototype.history = null;

        /**
         * Creates a new QueryProbabilityResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {routerrpc.IQueryProbabilityResponse=} [properties] Properties to set
         * @returns {routerrpc.QueryProbabilityResponse} QueryProbabilityResponse instance
         */
        QueryProbabilityResponse.create = function create(properties) {
            return new QueryProbabilityResponse(properties);
        };

        /**
         * Encodes the specified QueryProbabilityResponse message. Does not implicitly {@link routerrpc.QueryProbabilityResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {routerrpc.IQueryProbabilityResponse} message QueryProbabilityResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryProbabilityResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.probability != null && Object.hasOwnProperty.call(message, "probability"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.probability);
            if (message.history != null && Object.hasOwnProperty.call(message, "history"))
                $root.routerrpc.PairData.encode(message.history, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified QueryProbabilityResponse message, length delimited. Does not implicitly {@link routerrpc.QueryProbabilityResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {routerrpc.IQueryProbabilityResponse} message QueryProbabilityResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QueryProbabilityResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QueryProbabilityResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.QueryProbabilityResponse} QueryProbabilityResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryProbabilityResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.QueryProbabilityResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.probability = reader.double();
                        break;
                    }
                case 2: {
                        message.history = $root.routerrpc.PairData.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a QueryProbabilityResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.QueryProbabilityResponse} QueryProbabilityResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QueryProbabilityResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a QueryProbabilityResponse message.
         * @function verify
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        QueryProbabilityResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.probability != null && message.hasOwnProperty("probability"))
                if (typeof message.probability !== "number")
                    return "probability: number expected";
            if (message.history != null && message.hasOwnProperty("history")) {
                let error = $root.routerrpc.PairData.verify(message.history);
                if (error)
                    return "history." + error;
            }
            return null;
        };

        /**
         * Creates a QueryProbabilityResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.QueryProbabilityResponse} QueryProbabilityResponse
         */
        QueryProbabilityResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.QueryProbabilityResponse)
                return object;
            let message = new $root.routerrpc.QueryProbabilityResponse();
            if (object.probability != null)
                message.probability = Number(object.probability);
            if (object.history != null) {
                if (typeof object.history !== "object")
                    throw TypeError(".routerrpc.QueryProbabilityResponse.history: object expected");
                message.history = $root.routerrpc.PairData.fromObject(object.history);
            }
            return message;
        };

        /**
         * Creates a plain object from a QueryProbabilityResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.QueryProbabilityResponse
         * @static
         * @param {routerrpc.QueryProbabilityResponse} message QueryProbabilityResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        QueryProbabilityResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.probability = 0;
                object.history = null;
            }
            if (message.probability != null && message.hasOwnProperty("probability"))
                object.probability = options.json && !isFinite(message.probability) ? String(message.probability) : message.probability;
            if (message.history != null && message.hasOwnProperty("history"))
                object.history = $root.routerrpc.PairData.toObject(message.history, options);
            return object;
        };

        /**
         * Converts this QueryProbabilityResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.QueryProbabilityResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        QueryProbabilityResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return QueryProbabilityResponse;
    })();

    routerrpc.BuildRouteRequest = (function() {

        /**
         * Properties of a BuildRouteRequest.
         * @memberof routerrpc
         * @interface IBuildRouteRequest
         * @property {Long|null} [amtMsat] BuildRouteRequest amtMsat
         * @property {number|null} [finalCltvDelta] BuildRouteRequest finalCltvDelta
         * @property {Long|null} [outgoingChanId] BuildRouteRequest outgoingChanId
         * @property {Array.<Uint8Array>|null} [hopPubkeys] BuildRouteRequest hopPubkeys
         * @property {Uint8Array|null} [paymentAddr] BuildRouteRequest paymentAddr
         */

        /**
         * Constructs a new BuildRouteRequest.
         * @memberof routerrpc
         * @classdesc Represents a BuildRouteRequest.
         * @implements IBuildRouteRequest
         * @constructor
         * @param {routerrpc.IBuildRouteRequest=} [properties] Properties to set
         */
        function BuildRouteRequest(properties) {
            this.hopPubkeys = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildRouteRequest amtMsat.
         * @member {Long} amtMsat
         * @memberof routerrpc.BuildRouteRequest
         * @instance
         */
        BuildRouteRequest.prototype.amtMsat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BuildRouteRequest finalCltvDelta.
         * @member {number} finalCltvDelta
         * @memberof routerrpc.BuildRouteRequest
         * @instance
         */
        BuildRouteRequest.prototype.finalCltvDelta = 0;

        /**
         * BuildRouteRequest outgoingChanId.
         * @member {Long} outgoingChanId
         * @memberof routerrpc.BuildRouteRequest
         * @instance
         */
        BuildRouteRequest.prototype.outgoingChanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * BuildRouteRequest hopPubkeys.
         * @member {Array.<Uint8Array>} hopPubkeys
         * @memberof routerrpc.BuildRouteRequest
         * @instance
         */
        BuildRouteRequest.prototype.hopPubkeys = $util.emptyArray;

        /**
         * BuildRouteRequest paymentAddr.
         * @member {Uint8Array} paymentAddr
         * @memberof routerrpc.BuildRouteRequest
         * @instance
         */
        BuildRouteRequest.prototype.paymentAddr = $util.newBuffer([]);

        /**
         * Creates a new BuildRouteRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {routerrpc.IBuildRouteRequest=} [properties] Properties to set
         * @returns {routerrpc.BuildRouteRequest} BuildRouteRequest instance
         */
        BuildRouteRequest.create = function create(properties) {
            return new BuildRouteRequest(properties);
        };

        /**
         * Encodes the specified BuildRouteRequest message. Does not implicitly {@link routerrpc.BuildRouteRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {routerrpc.IBuildRouteRequest} message BuildRouteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildRouteRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.amtMsat != null && Object.hasOwnProperty.call(message, "amtMsat"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.amtMsat);
            if (message.finalCltvDelta != null && Object.hasOwnProperty.call(message, "finalCltvDelta"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.finalCltvDelta);
            if (message.outgoingChanId != null && Object.hasOwnProperty.call(message, "outgoingChanId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.outgoingChanId);
            if (message.hopPubkeys != null && message.hopPubkeys.length)
                for (let i = 0; i < message.hopPubkeys.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.hopPubkeys[i]);
            if (message.paymentAddr != null && Object.hasOwnProperty.call(message, "paymentAddr"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.paymentAddr);
            return writer;
        };

        /**
         * Encodes the specified BuildRouteRequest message, length delimited. Does not implicitly {@link routerrpc.BuildRouteRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {routerrpc.IBuildRouteRequest} message BuildRouteRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildRouteRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildRouteRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.BuildRouteRequest} BuildRouteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildRouteRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.BuildRouteRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.amtMsat = reader.int64();
                        break;
                    }
                case 2: {
                        message.finalCltvDelta = reader.int32();
                        break;
                    }
                case 3: {
                        message.outgoingChanId = reader.uint64();
                        break;
                    }
                case 4: {
                        if (!(message.hopPubkeys && message.hopPubkeys.length))
                            message.hopPubkeys = [];
                        message.hopPubkeys.push(reader.bytes());
                        break;
                    }
                case 5: {
                        message.paymentAddr = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildRouteRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.BuildRouteRequest} BuildRouteRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildRouteRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildRouteRequest message.
         * @function verify
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildRouteRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (!$util.isInteger(message.amtMsat) && !(message.amtMsat && $util.isInteger(message.amtMsat.low) && $util.isInteger(message.amtMsat.high)))
                    return "amtMsat: integer|Long expected";
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                if (!$util.isInteger(message.finalCltvDelta))
                    return "finalCltvDelta: integer expected";
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (!$util.isInteger(message.outgoingChanId) && !(message.outgoingChanId && $util.isInteger(message.outgoingChanId.low) && $util.isInteger(message.outgoingChanId.high)))
                    return "outgoingChanId: integer|Long expected";
            if (message.hopPubkeys != null && message.hasOwnProperty("hopPubkeys")) {
                if (!Array.isArray(message.hopPubkeys))
                    return "hopPubkeys: array expected";
                for (let i = 0; i < message.hopPubkeys.length; ++i)
                    if (!(message.hopPubkeys[i] && typeof message.hopPubkeys[i].length === "number" || $util.isString(message.hopPubkeys[i])))
                        return "hopPubkeys: buffer[] expected";
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                if (!(message.paymentAddr && typeof message.paymentAddr.length === "number" || $util.isString(message.paymentAddr)))
                    return "paymentAddr: buffer expected";
            return null;
        };

        /**
         * Creates a BuildRouteRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.BuildRouteRequest} BuildRouteRequest
         */
        BuildRouteRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.BuildRouteRequest)
                return object;
            let message = new $root.routerrpc.BuildRouteRequest();
            if (object.amtMsat != null)
                if ($util.Long)
                    (message.amtMsat = $util.Long.fromValue(object.amtMsat)).unsigned = false;
                else if (typeof object.amtMsat === "string")
                    message.amtMsat = parseInt(object.amtMsat, 10);
                else if (typeof object.amtMsat === "number")
                    message.amtMsat = object.amtMsat;
                else if (typeof object.amtMsat === "object")
                    message.amtMsat = new $util.LongBits(object.amtMsat.low >>> 0, object.amtMsat.high >>> 0).toNumber();
            if (object.finalCltvDelta != null)
                message.finalCltvDelta = object.finalCltvDelta | 0;
            if (object.outgoingChanId != null)
                if ($util.Long)
                    (message.outgoingChanId = $util.Long.fromValue(object.outgoingChanId)).unsigned = true;
                else if (typeof object.outgoingChanId === "string")
                    message.outgoingChanId = parseInt(object.outgoingChanId, 10);
                else if (typeof object.outgoingChanId === "number")
                    message.outgoingChanId = object.outgoingChanId;
                else if (typeof object.outgoingChanId === "object")
                    message.outgoingChanId = new $util.LongBits(object.outgoingChanId.low >>> 0, object.outgoingChanId.high >>> 0).toNumber(true);
            if (object.hopPubkeys) {
                if (!Array.isArray(object.hopPubkeys))
                    throw TypeError(".routerrpc.BuildRouteRequest.hopPubkeys: array expected");
                message.hopPubkeys = [];
                for (let i = 0; i < object.hopPubkeys.length; ++i)
                    if (typeof object.hopPubkeys[i] === "string")
                        $util.base64.decode(object.hopPubkeys[i], message.hopPubkeys[i] = $util.newBuffer($util.base64.length(object.hopPubkeys[i])), 0);
                    else if (object.hopPubkeys[i].length)
                        message.hopPubkeys[i] = object.hopPubkeys[i];
            }
            if (object.paymentAddr != null)
                if (typeof object.paymentAddr === "string")
                    $util.base64.decode(object.paymentAddr, message.paymentAddr = $util.newBuffer($util.base64.length(object.paymentAddr)), 0);
                else if (object.paymentAddr.length)
                    message.paymentAddr = object.paymentAddr;
            return message;
        };

        /**
         * Creates a plain object from a BuildRouteRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.BuildRouteRequest
         * @static
         * @param {routerrpc.BuildRouteRequest} message BuildRouteRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildRouteRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.hopPubkeys = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.amtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.amtMsat = options.longs === String ? "0" : 0;
                object.finalCltvDelta = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingChanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingChanId = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.paymentAddr = "";
                else {
                    object.paymentAddr = [];
                    if (options.bytes !== Array)
                        object.paymentAddr = $util.newBuffer(object.paymentAddr);
                }
            }
            if (message.amtMsat != null && message.hasOwnProperty("amtMsat"))
                if (typeof message.amtMsat === "number")
                    object.amtMsat = options.longs === String ? String(message.amtMsat) : message.amtMsat;
                else
                    object.amtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.amtMsat) : options.longs === Number ? new $util.LongBits(message.amtMsat.low >>> 0, message.amtMsat.high >>> 0).toNumber() : message.amtMsat;
            if (message.finalCltvDelta != null && message.hasOwnProperty("finalCltvDelta"))
                object.finalCltvDelta = message.finalCltvDelta;
            if (message.outgoingChanId != null && message.hasOwnProperty("outgoingChanId"))
                if (typeof message.outgoingChanId === "number")
                    object.outgoingChanId = options.longs === String ? String(message.outgoingChanId) : message.outgoingChanId;
                else
                    object.outgoingChanId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingChanId) : options.longs === Number ? new $util.LongBits(message.outgoingChanId.low >>> 0, message.outgoingChanId.high >>> 0).toNumber(true) : message.outgoingChanId;
            if (message.hopPubkeys && message.hopPubkeys.length) {
                object.hopPubkeys = [];
                for (let j = 0; j < message.hopPubkeys.length; ++j)
                    object.hopPubkeys[j] = options.bytes === String ? $util.base64.encode(message.hopPubkeys[j], 0, message.hopPubkeys[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.hopPubkeys[j]) : message.hopPubkeys[j];
            }
            if (message.paymentAddr != null && message.hasOwnProperty("paymentAddr"))
                object.paymentAddr = options.bytes === String ? $util.base64.encode(message.paymentAddr, 0, message.paymentAddr.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentAddr) : message.paymentAddr;
            return object;
        };

        /**
         * Converts this BuildRouteRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.BuildRouteRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildRouteRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BuildRouteRequest;
    })();

    routerrpc.BuildRouteResponse = (function() {

        /**
         * Properties of a BuildRouteResponse.
         * @memberof routerrpc
         * @interface IBuildRouteResponse
         * @property {lnrpc.IRoute|null} [route] BuildRouteResponse route
         */

        /**
         * Constructs a new BuildRouteResponse.
         * @memberof routerrpc
         * @classdesc Represents a BuildRouteResponse.
         * @implements IBuildRouteResponse
         * @constructor
         * @param {routerrpc.IBuildRouteResponse=} [properties] Properties to set
         */
        function BuildRouteResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildRouteResponse route.
         * @member {lnrpc.IRoute|null|undefined} route
         * @memberof routerrpc.BuildRouteResponse
         * @instance
         */
        BuildRouteResponse.prototype.route = null;

        /**
         * Creates a new BuildRouteResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {routerrpc.IBuildRouteResponse=} [properties] Properties to set
         * @returns {routerrpc.BuildRouteResponse} BuildRouteResponse instance
         */
        BuildRouteResponse.create = function create(properties) {
            return new BuildRouteResponse(properties);
        };

        /**
         * Encodes the specified BuildRouteResponse message. Does not implicitly {@link routerrpc.BuildRouteResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {routerrpc.IBuildRouteResponse} message BuildRouteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildRouteResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.route != null && Object.hasOwnProperty.call(message, "route"))
                $root.lnrpc.Route.encode(message.route, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildRouteResponse message, length delimited. Does not implicitly {@link routerrpc.BuildRouteResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {routerrpc.IBuildRouteResponse} message BuildRouteResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildRouteResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildRouteResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.BuildRouteResponse} BuildRouteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildRouteResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.BuildRouteResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.route = $root.lnrpc.Route.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildRouteResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.BuildRouteResponse} BuildRouteResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildRouteResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildRouteResponse message.
         * @function verify
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildRouteResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.route != null && message.hasOwnProperty("route")) {
                let error = $root.lnrpc.Route.verify(message.route);
                if (error)
                    return "route." + error;
            }
            return null;
        };

        /**
         * Creates a BuildRouteResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.BuildRouteResponse} BuildRouteResponse
         */
        BuildRouteResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.BuildRouteResponse)
                return object;
            let message = new $root.routerrpc.BuildRouteResponse();
            if (object.route != null) {
                if (typeof object.route !== "object")
                    throw TypeError(".routerrpc.BuildRouteResponse.route: object expected");
                message.route = $root.lnrpc.Route.fromObject(object.route);
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildRouteResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.BuildRouteResponse
         * @static
         * @param {routerrpc.BuildRouteResponse} message BuildRouteResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildRouteResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.route = null;
            if (message.route != null && message.hasOwnProperty("route"))
                object.route = $root.lnrpc.Route.toObject(message.route, options);
            return object;
        };

        /**
         * Converts this BuildRouteResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.BuildRouteResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildRouteResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BuildRouteResponse;
    })();

    routerrpc.SubscribeHtlcEventsRequest = (function() {

        /**
         * Properties of a SubscribeHtlcEventsRequest.
         * @memberof routerrpc
         * @interface ISubscribeHtlcEventsRequest
         */

        /**
         * Constructs a new SubscribeHtlcEventsRequest.
         * @memberof routerrpc
         * @classdesc Represents a SubscribeHtlcEventsRequest.
         * @implements ISubscribeHtlcEventsRequest
         * @constructor
         * @param {routerrpc.ISubscribeHtlcEventsRequest=} [properties] Properties to set
         */
        function SubscribeHtlcEventsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SubscribeHtlcEventsRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {routerrpc.ISubscribeHtlcEventsRequest=} [properties] Properties to set
         * @returns {routerrpc.SubscribeHtlcEventsRequest} SubscribeHtlcEventsRequest instance
         */
        SubscribeHtlcEventsRequest.create = function create(properties) {
            return new SubscribeHtlcEventsRequest(properties);
        };

        /**
         * Encodes the specified SubscribeHtlcEventsRequest message. Does not implicitly {@link routerrpc.SubscribeHtlcEventsRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {routerrpc.ISubscribeHtlcEventsRequest} message SubscribeHtlcEventsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeHtlcEventsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SubscribeHtlcEventsRequest message, length delimited. Does not implicitly {@link routerrpc.SubscribeHtlcEventsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {routerrpc.ISubscribeHtlcEventsRequest} message SubscribeHtlcEventsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubscribeHtlcEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubscribeHtlcEventsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SubscribeHtlcEventsRequest} SubscribeHtlcEventsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeHtlcEventsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SubscribeHtlcEventsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubscribeHtlcEventsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SubscribeHtlcEventsRequest} SubscribeHtlcEventsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubscribeHtlcEventsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubscribeHtlcEventsRequest message.
         * @function verify
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubscribeHtlcEventsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SubscribeHtlcEventsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SubscribeHtlcEventsRequest} SubscribeHtlcEventsRequest
         */
        SubscribeHtlcEventsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SubscribeHtlcEventsRequest)
                return object;
            return new $root.routerrpc.SubscribeHtlcEventsRequest();
        };

        /**
         * Creates a plain object from a SubscribeHtlcEventsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @static
         * @param {routerrpc.SubscribeHtlcEventsRequest} message SubscribeHtlcEventsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubscribeHtlcEventsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SubscribeHtlcEventsRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.SubscribeHtlcEventsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubscribeHtlcEventsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SubscribeHtlcEventsRequest;
    })();

    routerrpc.HtlcEvent = (function() {

        /**
         * Properties of a HtlcEvent.
         * @memberof routerrpc
         * @interface IHtlcEvent
         * @property {Long|null} [incomingChannelId] HtlcEvent incomingChannelId
         * @property {Long|null} [outgoingChannelId] HtlcEvent outgoingChannelId
         * @property {Long|null} [incomingHtlcId] HtlcEvent incomingHtlcId
         * @property {Long|null} [outgoingHtlcId] HtlcEvent outgoingHtlcId
         * @property {Long|null} [timestampNs] HtlcEvent timestampNs
         * @property {routerrpc.HtlcEvent.EventType|null} [eventType] HtlcEvent eventType
         * @property {routerrpc.IForwardEvent|null} [forwardEvent] HtlcEvent forwardEvent
         * @property {routerrpc.IForwardFailEvent|null} [forwardFailEvent] HtlcEvent forwardFailEvent
         * @property {routerrpc.ISettleEvent|null} [settleEvent] HtlcEvent settleEvent
         * @property {routerrpc.ILinkFailEvent|null} [linkFailEvent] HtlcEvent linkFailEvent
         */

        /**
         * Constructs a new HtlcEvent.
         * @memberof routerrpc
         * @classdesc Represents a HtlcEvent.
         * @implements IHtlcEvent
         * @constructor
         * @param {routerrpc.IHtlcEvent=} [properties] Properties to set
         */
        function HtlcEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HtlcEvent incomingChannelId.
         * @member {Long} incomingChannelId
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.incomingChannelId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HtlcEvent outgoingChannelId.
         * @member {Long} outgoingChannelId
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.outgoingChannelId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HtlcEvent incomingHtlcId.
         * @member {Long} incomingHtlcId
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.incomingHtlcId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HtlcEvent outgoingHtlcId.
         * @member {Long} outgoingHtlcId
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.outgoingHtlcId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HtlcEvent timestampNs.
         * @member {Long} timestampNs
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.timestampNs = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HtlcEvent eventType.
         * @member {routerrpc.HtlcEvent.EventType} eventType
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.eventType = 0;

        /**
         * HtlcEvent forwardEvent.
         * @member {routerrpc.IForwardEvent|null|undefined} forwardEvent
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.forwardEvent = null;

        /**
         * HtlcEvent forwardFailEvent.
         * @member {routerrpc.IForwardFailEvent|null|undefined} forwardFailEvent
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.forwardFailEvent = null;

        /**
         * HtlcEvent settleEvent.
         * @member {routerrpc.ISettleEvent|null|undefined} settleEvent
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.settleEvent = null;

        /**
         * HtlcEvent linkFailEvent.
         * @member {routerrpc.ILinkFailEvent|null|undefined} linkFailEvent
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        HtlcEvent.prototype.linkFailEvent = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * HtlcEvent event.
         * @member {"forwardEvent"|"forwardFailEvent"|"settleEvent"|"linkFailEvent"|undefined} event
         * @memberof routerrpc.HtlcEvent
         * @instance
         */
        Object.defineProperty(HtlcEvent.prototype, "event", {
            get: $util.oneOfGetter($oneOfFields = ["forwardEvent", "forwardFailEvent", "settleEvent", "linkFailEvent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new HtlcEvent instance using the specified properties.
         * @function create
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {routerrpc.IHtlcEvent=} [properties] Properties to set
         * @returns {routerrpc.HtlcEvent} HtlcEvent instance
         */
        HtlcEvent.create = function create(properties) {
            return new HtlcEvent(properties);
        };

        /**
         * Encodes the specified HtlcEvent message. Does not implicitly {@link routerrpc.HtlcEvent.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {routerrpc.IHtlcEvent} message HtlcEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HtlcEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incomingChannelId != null && Object.hasOwnProperty.call(message, "incomingChannelId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.incomingChannelId);
            if (message.outgoingChannelId != null && Object.hasOwnProperty.call(message, "outgoingChannelId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.outgoingChannelId);
            if (message.incomingHtlcId != null && Object.hasOwnProperty.call(message, "incomingHtlcId"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.incomingHtlcId);
            if (message.outgoingHtlcId != null && Object.hasOwnProperty.call(message, "outgoingHtlcId"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.outgoingHtlcId);
            if (message.timestampNs != null && Object.hasOwnProperty.call(message, "timestampNs"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.timestampNs);
            if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.eventType);
            if (message.forwardEvent != null && Object.hasOwnProperty.call(message, "forwardEvent"))
                $root.routerrpc.ForwardEvent.encode(message.forwardEvent, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.forwardFailEvent != null && Object.hasOwnProperty.call(message, "forwardFailEvent"))
                $root.routerrpc.ForwardFailEvent.encode(message.forwardFailEvent, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.settleEvent != null && Object.hasOwnProperty.call(message, "settleEvent"))
                $root.routerrpc.SettleEvent.encode(message.settleEvent, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.linkFailEvent != null && Object.hasOwnProperty.call(message, "linkFailEvent"))
                $root.routerrpc.LinkFailEvent.encode(message.linkFailEvent, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HtlcEvent message, length delimited. Does not implicitly {@link routerrpc.HtlcEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {routerrpc.IHtlcEvent} message HtlcEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HtlcEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HtlcEvent message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.HtlcEvent} HtlcEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HtlcEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.HtlcEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incomingChannelId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.outgoingChannelId = reader.uint64();
                        break;
                    }
                case 3: {
                        message.incomingHtlcId = reader.uint64();
                        break;
                    }
                case 4: {
                        message.outgoingHtlcId = reader.uint64();
                        break;
                    }
                case 5: {
                        message.timestampNs = reader.uint64();
                        break;
                    }
                case 6: {
                        message.eventType = reader.int32();
                        break;
                    }
                case 7: {
                        message.forwardEvent = $root.routerrpc.ForwardEvent.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.forwardFailEvent = $root.routerrpc.ForwardFailEvent.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.settleEvent = $root.routerrpc.SettleEvent.decode(reader, reader.uint32());
                        break;
                    }
                case 10: {
                        message.linkFailEvent = $root.routerrpc.LinkFailEvent.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HtlcEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.HtlcEvent} HtlcEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HtlcEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HtlcEvent message.
         * @function verify
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HtlcEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.incomingChannelId != null && message.hasOwnProperty("incomingChannelId"))
                if (!$util.isInteger(message.incomingChannelId) && !(message.incomingChannelId && $util.isInteger(message.incomingChannelId.low) && $util.isInteger(message.incomingChannelId.high)))
                    return "incomingChannelId: integer|Long expected";
            if (message.outgoingChannelId != null && message.hasOwnProperty("outgoingChannelId"))
                if (!$util.isInteger(message.outgoingChannelId) && !(message.outgoingChannelId && $util.isInteger(message.outgoingChannelId.low) && $util.isInteger(message.outgoingChannelId.high)))
                    return "outgoingChannelId: integer|Long expected";
            if (message.incomingHtlcId != null && message.hasOwnProperty("incomingHtlcId"))
                if (!$util.isInteger(message.incomingHtlcId) && !(message.incomingHtlcId && $util.isInteger(message.incomingHtlcId.low) && $util.isInteger(message.incomingHtlcId.high)))
                    return "incomingHtlcId: integer|Long expected";
            if (message.outgoingHtlcId != null && message.hasOwnProperty("outgoingHtlcId"))
                if (!$util.isInteger(message.outgoingHtlcId) && !(message.outgoingHtlcId && $util.isInteger(message.outgoingHtlcId.low) && $util.isInteger(message.outgoingHtlcId.high)))
                    return "outgoingHtlcId: integer|Long expected";
            if (message.timestampNs != null && message.hasOwnProperty("timestampNs"))
                if (!$util.isInteger(message.timestampNs) && !(message.timestampNs && $util.isInteger(message.timestampNs.low) && $util.isInteger(message.timestampNs.high)))
                    return "timestampNs: integer|Long expected";
            if (message.eventType != null && message.hasOwnProperty("eventType"))
                switch (message.eventType) {
                default:
                    return "eventType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.forwardEvent != null && message.hasOwnProperty("forwardEvent")) {
                properties.event = 1;
                {
                    let error = $root.routerrpc.ForwardEvent.verify(message.forwardEvent);
                    if (error)
                        return "forwardEvent." + error;
                }
            }
            if (message.forwardFailEvent != null && message.hasOwnProperty("forwardFailEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.routerrpc.ForwardFailEvent.verify(message.forwardFailEvent);
                    if (error)
                        return "forwardFailEvent." + error;
                }
            }
            if (message.settleEvent != null && message.hasOwnProperty("settleEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.routerrpc.SettleEvent.verify(message.settleEvent);
                    if (error)
                        return "settleEvent." + error;
                }
            }
            if (message.linkFailEvent != null && message.hasOwnProperty("linkFailEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.routerrpc.LinkFailEvent.verify(message.linkFailEvent);
                    if (error)
                        return "linkFailEvent." + error;
                }
            }
            return null;
        };

        /**
         * Creates a HtlcEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.HtlcEvent} HtlcEvent
         */
        HtlcEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.HtlcEvent)
                return object;
            let message = new $root.routerrpc.HtlcEvent();
            if (object.incomingChannelId != null)
                if ($util.Long)
                    (message.incomingChannelId = $util.Long.fromValue(object.incomingChannelId)).unsigned = true;
                else if (typeof object.incomingChannelId === "string")
                    message.incomingChannelId = parseInt(object.incomingChannelId, 10);
                else if (typeof object.incomingChannelId === "number")
                    message.incomingChannelId = object.incomingChannelId;
                else if (typeof object.incomingChannelId === "object")
                    message.incomingChannelId = new $util.LongBits(object.incomingChannelId.low >>> 0, object.incomingChannelId.high >>> 0).toNumber(true);
            if (object.outgoingChannelId != null)
                if ($util.Long)
                    (message.outgoingChannelId = $util.Long.fromValue(object.outgoingChannelId)).unsigned = true;
                else if (typeof object.outgoingChannelId === "string")
                    message.outgoingChannelId = parseInt(object.outgoingChannelId, 10);
                else if (typeof object.outgoingChannelId === "number")
                    message.outgoingChannelId = object.outgoingChannelId;
                else if (typeof object.outgoingChannelId === "object")
                    message.outgoingChannelId = new $util.LongBits(object.outgoingChannelId.low >>> 0, object.outgoingChannelId.high >>> 0).toNumber(true);
            if (object.incomingHtlcId != null)
                if ($util.Long)
                    (message.incomingHtlcId = $util.Long.fromValue(object.incomingHtlcId)).unsigned = true;
                else if (typeof object.incomingHtlcId === "string")
                    message.incomingHtlcId = parseInt(object.incomingHtlcId, 10);
                else if (typeof object.incomingHtlcId === "number")
                    message.incomingHtlcId = object.incomingHtlcId;
                else if (typeof object.incomingHtlcId === "object")
                    message.incomingHtlcId = new $util.LongBits(object.incomingHtlcId.low >>> 0, object.incomingHtlcId.high >>> 0).toNumber(true);
            if (object.outgoingHtlcId != null)
                if ($util.Long)
                    (message.outgoingHtlcId = $util.Long.fromValue(object.outgoingHtlcId)).unsigned = true;
                else if (typeof object.outgoingHtlcId === "string")
                    message.outgoingHtlcId = parseInt(object.outgoingHtlcId, 10);
                else if (typeof object.outgoingHtlcId === "number")
                    message.outgoingHtlcId = object.outgoingHtlcId;
                else if (typeof object.outgoingHtlcId === "object")
                    message.outgoingHtlcId = new $util.LongBits(object.outgoingHtlcId.low >>> 0, object.outgoingHtlcId.high >>> 0).toNumber(true);
            if (object.timestampNs != null)
                if ($util.Long)
                    (message.timestampNs = $util.Long.fromValue(object.timestampNs)).unsigned = true;
                else if (typeof object.timestampNs === "string")
                    message.timestampNs = parseInt(object.timestampNs, 10);
                else if (typeof object.timestampNs === "number")
                    message.timestampNs = object.timestampNs;
                else if (typeof object.timestampNs === "object")
                    message.timestampNs = new $util.LongBits(object.timestampNs.low >>> 0, object.timestampNs.high >>> 0).toNumber(true);
            switch (object.eventType) {
            case "UNKNOWN":
            case 0:
                message.eventType = 0;
                break;
            case "SEND":
            case 1:
                message.eventType = 1;
                break;
            case "RECEIVE":
            case 2:
                message.eventType = 2;
                break;
            case "FORWARD":
            case 3:
                message.eventType = 3;
                break;
            }
            if (object.forwardEvent != null) {
                if (typeof object.forwardEvent !== "object")
                    throw TypeError(".routerrpc.HtlcEvent.forwardEvent: object expected");
                message.forwardEvent = $root.routerrpc.ForwardEvent.fromObject(object.forwardEvent);
            }
            if (object.forwardFailEvent != null) {
                if (typeof object.forwardFailEvent !== "object")
                    throw TypeError(".routerrpc.HtlcEvent.forwardFailEvent: object expected");
                message.forwardFailEvent = $root.routerrpc.ForwardFailEvent.fromObject(object.forwardFailEvent);
            }
            if (object.settleEvent != null) {
                if (typeof object.settleEvent !== "object")
                    throw TypeError(".routerrpc.HtlcEvent.settleEvent: object expected");
                message.settleEvent = $root.routerrpc.SettleEvent.fromObject(object.settleEvent);
            }
            if (object.linkFailEvent != null) {
                if (typeof object.linkFailEvent !== "object")
                    throw TypeError(".routerrpc.HtlcEvent.linkFailEvent: object expected");
                message.linkFailEvent = $root.routerrpc.LinkFailEvent.fromObject(object.linkFailEvent);
            }
            return message;
        };

        /**
         * Creates a plain object from a HtlcEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.HtlcEvent
         * @static
         * @param {routerrpc.HtlcEvent} message HtlcEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HtlcEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.incomingChannelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.incomingChannelId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingChannelId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingChannelId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.incomingHtlcId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.incomingHtlcId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingHtlcId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingHtlcId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.timestampNs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timestampNs = options.longs === String ? "0" : 0;
                object.eventType = options.enums === String ? "UNKNOWN" : 0;
            }
            if (message.incomingChannelId != null && message.hasOwnProperty("incomingChannelId"))
                if (typeof message.incomingChannelId === "number")
                    object.incomingChannelId = options.longs === String ? String(message.incomingChannelId) : message.incomingChannelId;
                else
                    object.incomingChannelId = options.longs === String ? $util.Long.prototype.toString.call(message.incomingChannelId) : options.longs === Number ? new $util.LongBits(message.incomingChannelId.low >>> 0, message.incomingChannelId.high >>> 0).toNumber(true) : message.incomingChannelId;
            if (message.outgoingChannelId != null && message.hasOwnProperty("outgoingChannelId"))
                if (typeof message.outgoingChannelId === "number")
                    object.outgoingChannelId = options.longs === String ? String(message.outgoingChannelId) : message.outgoingChannelId;
                else
                    object.outgoingChannelId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingChannelId) : options.longs === Number ? new $util.LongBits(message.outgoingChannelId.low >>> 0, message.outgoingChannelId.high >>> 0).toNumber(true) : message.outgoingChannelId;
            if (message.incomingHtlcId != null && message.hasOwnProperty("incomingHtlcId"))
                if (typeof message.incomingHtlcId === "number")
                    object.incomingHtlcId = options.longs === String ? String(message.incomingHtlcId) : message.incomingHtlcId;
                else
                    object.incomingHtlcId = options.longs === String ? $util.Long.prototype.toString.call(message.incomingHtlcId) : options.longs === Number ? new $util.LongBits(message.incomingHtlcId.low >>> 0, message.incomingHtlcId.high >>> 0).toNumber(true) : message.incomingHtlcId;
            if (message.outgoingHtlcId != null && message.hasOwnProperty("outgoingHtlcId"))
                if (typeof message.outgoingHtlcId === "number")
                    object.outgoingHtlcId = options.longs === String ? String(message.outgoingHtlcId) : message.outgoingHtlcId;
                else
                    object.outgoingHtlcId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingHtlcId) : options.longs === Number ? new $util.LongBits(message.outgoingHtlcId.low >>> 0, message.outgoingHtlcId.high >>> 0).toNumber(true) : message.outgoingHtlcId;
            if (message.timestampNs != null && message.hasOwnProperty("timestampNs"))
                if (typeof message.timestampNs === "number")
                    object.timestampNs = options.longs === String ? String(message.timestampNs) : message.timestampNs;
                else
                    object.timestampNs = options.longs === String ? $util.Long.prototype.toString.call(message.timestampNs) : options.longs === Number ? new $util.LongBits(message.timestampNs.low >>> 0, message.timestampNs.high >>> 0).toNumber(true) : message.timestampNs;
            if (message.eventType != null && message.hasOwnProperty("eventType"))
                object.eventType = options.enums === String ? $root.routerrpc.HtlcEvent.EventType[message.eventType] : message.eventType;
            if (message.forwardEvent != null && message.hasOwnProperty("forwardEvent")) {
                object.forwardEvent = $root.routerrpc.ForwardEvent.toObject(message.forwardEvent, options);
                if (options.oneofs)
                    object.event = "forwardEvent";
            }
            if (message.forwardFailEvent != null && message.hasOwnProperty("forwardFailEvent")) {
                object.forwardFailEvent = $root.routerrpc.ForwardFailEvent.toObject(message.forwardFailEvent, options);
                if (options.oneofs)
                    object.event = "forwardFailEvent";
            }
            if (message.settleEvent != null && message.hasOwnProperty("settleEvent")) {
                object.settleEvent = $root.routerrpc.SettleEvent.toObject(message.settleEvent, options);
                if (options.oneofs)
                    object.event = "settleEvent";
            }
            if (message.linkFailEvent != null && message.hasOwnProperty("linkFailEvent")) {
                object.linkFailEvent = $root.routerrpc.LinkFailEvent.toObject(message.linkFailEvent, options);
                if (options.oneofs)
                    object.event = "linkFailEvent";
            }
            return object;
        };

        /**
         * Converts this HtlcEvent to JSON.
         * @function toJSON
         * @memberof routerrpc.HtlcEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HtlcEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * EventType enum.
         * @name routerrpc.HtlcEvent.EventType
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} SEND=1 SEND value
         * @property {number} RECEIVE=2 RECEIVE value
         * @property {number} FORWARD=3 FORWARD value
         */
        HtlcEvent.EventType = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "SEND"] = 1;
            values[valuesById[2] = "RECEIVE"] = 2;
            values[valuesById[3] = "FORWARD"] = 3;
            return values;
        })();

        return HtlcEvent;
    })();

    routerrpc.HtlcInfo = (function() {

        /**
         * Properties of a HtlcInfo.
         * @memberof routerrpc
         * @interface IHtlcInfo
         * @property {number|null} [incomingTimelock] HtlcInfo incomingTimelock
         * @property {number|null} [outgoingTimelock] HtlcInfo outgoingTimelock
         * @property {Long|null} [incomingAmtMsat] HtlcInfo incomingAmtMsat
         * @property {Long|null} [outgoingAmtMsat] HtlcInfo outgoingAmtMsat
         */

        /**
         * Constructs a new HtlcInfo.
         * @memberof routerrpc
         * @classdesc Represents a HtlcInfo.
         * @implements IHtlcInfo
         * @constructor
         * @param {routerrpc.IHtlcInfo=} [properties] Properties to set
         */
        function HtlcInfo(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HtlcInfo incomingTimelock.
         * @member {number} incomingTimelock
         * @memberof routerrpc.HtlcInfo
         * @instance
         */
        HtlcInfo.prototype.incomingTimelock = 0;

        /**
         * HtlcInfo outgoingTimelock.
         * @member {number} outgoingTimelock
         * @memberof routerrpc.HtlcInfo
         * @instance
         */
        HtlcInfo.prototype.outgoingTimelock = 0;

        /**
         * HtlcInfo incomingAmtMsat.
         * @member {Long} incomingAmtMsat
         * @memberof routerrpc.HtlcInfo
         * @instance
         */
        HtlcInfo.prototype.incomingAmtMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * HtlcInfo outgoingAmtMsat.
         * @member {Long} outgoingAmtMsat
         * @memberof routerrpc.HtlcInfo
         * @instance
         */
        HtlcInfo.prototype.outgoingAmtMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new HtlcInfo instance using the specified properties.
         * @function create
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {routerrpc.IHtlcInfo=} [properties] Properties to set
         * @returns {routerrpc.HtlcInfo} HtlcInfo instance
         */
        HtlcInfo.create = function create(properties) {
            return new HtlcInfo(properties);
        };

        /**
         * Encodes the specified HtlcInfo message. Does not implicitly {@link routerrpc.HtlcInfo.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {routerrpc.IHtlcInfo} message HtlcInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HtlcInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incomingTimelock != null && Object.hasOwnProperty.call(message, "incomingTimelock"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.incomingTimelock);
            if (message.outgoingTimelock != null && Object.hasOwnProperty.call(message, "outgoingTimelock"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.outgoingTimelock);
            if (message.incomingAmtMsat != null && Object.hasOwnProperty.call(message, "incomingAmtMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.incomingAmtMsat);
            if (message.outgoingAmtMsat != null && Object.hasOwnProperty.call(message, "outgoingAmtMsat"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.outgoingAmtMsat);
            return writer;
        };

        /**
         * Encodes the specified HtlcInfo message, length delimited. Does not implicitly {@link routerrpc.HtlcInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {routerrpc.IHtlcInfo} message HtlcInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HtlcInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HtlcInfo message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.HtlcInfo} HtlcInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HtlcInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.HtlcInfo();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incomingTimelock = reader.uint32();
                        break;
                    }
                case 2: {
                        message.outgoingTimelock = reader.uint32();
                        break;
                    }
                case 3: {
                        message.incomingAmtMsat = reader.uint64();
                        break;
                    }
                case 4: {
                        message.outgoingAmtMsat = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HtlcInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.HtlcInfo} HtlcInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HtlcInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HtlcInfo message.
         * @function verify
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HtlcInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.incomingTimelock != null && message.hasOwnProperty("incomingTimelock"))
                if (!$util.isInteger(message.incomingTimelock))
                    return "incomingTimelock: integer expected";
            if (message.outgoingTimelock != null && message.hasOwnProperty("outgoingTimelock"))
                if (!$util.isInteger(message.outgoingTimelock))
                    return "outgoingTimelock: integer expected";
            if (message.incomingAmtMsat != null && message.hasOwnProperty("incomingAmtMsat"))
                if (!$util.isInteger(message.incomingAmtMsat) && !(message.incomingAmtMsat && $util.isInteger(message.incomingAmtMsat.low) && $util.isInteger(message.incomingAmtMsat.high)))
                    return "incomingAmtMsat: integer|Long expected";
            if (message.outgoingAmtMsat != null && message.hasOwnProperty("outgoingAmtMsat"))
                if (!$util.isInteger(message.outgoingAmtMsat) && !(message.outgoingAmtMsat && $util.isInteger(message.outgoingAmtMsat.low) && $util.isInteger(message.outgoingAmtMsat.high)))
                    return "outgoingAmtMsat: integer|Long expected";
            return null;
        };

        /**
         * Creates a HtlcInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.HtlcInfo} HtlcInfo
         */
        HtlcInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.HtlcInfo)
                return object;
            let message = new $root.routerrpc.HtlcInfo();
            if (object.incomingTimelock != null)
                message.incomingTimelock = object.incomingTimelock >>> 0;
            if (object.outgoingTimelock != null)
                message.outgoingTimelock = object.outgoingTimelock >>> 0;
            if (object.incomingAmtMsat != null)
                if ($util.Long)
                    (message.incomingAmtMsat = $util.Long.fromValue(object.incomingAmtMsat)).unsigned = true;
                else if (typeof object.incomingAmtMsat === "string")
                    message.incomingAmtMsat = parseInt(object.incomingAmtMsat, 10);
                else if (typeof object.incomingAmtMsat === "number")
                    message.incomingAmtMsat = object.incomingAmtMsat;
                else if (typeof object.incomingAmtMsat === "object")
                    message.incomingAmtMsat = new $util.LongBits(object.incomingAmtMsat.low >>> 0, object.incomingAmtMsat.high >>> 0).toNumber(true);
            if (object.outgoingAmtMsat != null)
                if ($util.Long)
                    (message.outgoingAmtMsat = $util.Long.fromValue(object.outgoingAmtMsat)).unsigned = true;
                else if (typeof object.outgoingAmtMsat === "string")
                    message.outgoingAmtMsat = parseInt(object.outgoingAmtMsat, 10);
                else if (typeof object.outgoingAmtMsat === "number")
                    message.outgoingAmtMsat = object.outgoingAmtMsat;
                else if (typeof object.outgoingAmtMsat === "object")
                    message.outgoingAmtMsat = new $util.LongBits(object.outgoingAmtMsat.low >>> 0, object.outgoingAmtMsat.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a HtlcInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.HtlcInfo
         * @static
         * @param {routerrpc.HtlcInfo} message HtlcInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HtlcInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.incomingTimelock = 0;
                object.outgoingTimelock = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.incomingAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.incomingAmtMsat = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingAmtMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingAmtMsat = options.longs === String ? "0" : 0;
            }
            if (message.incomingTimelock != null && message.hasOwnProperty("incomingTimelock"))
                object.incomingTimelock = message.incomingTimelock;
            if (message.outgoingTimelock != null && message.hasOwnProperty("outgoingTimelock"))
                object.outgoingTimelock = message.outgoingTimelock;
            if (message.incomingAmtMsat != null && message.hasOwnProperty("incomingAmtMsat"))
                if (typeof message.incomingAmtMsat === "number")
                    object.incomingAmtMsat = options.longs === String ? String(message.incomingAmtMsat) : message.incomingAmtMsat;
                else
                    object.incomingAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.incomingAmtMsat) : options.longs === Number ? new $util.LongBits(message.incomingAmtMsat.low >>> 0, message.incomingAmtMsat.high >>> 0).toNumber(true) : message.incomingAmtMsat;
            if (message.outgoingAmtMsat != null && message.hasOwnProperty("outgoingAmtMsat"))
                if (typeof message.outgoingAmtMsat === "number")
                    object.outgoingAmtMsat = options.longs === String ? String(message.outgoingAmtMsat) : message.outgoingAmtMsat;
                else
                    object.outgoingAmtMsat = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingAmtMsat) : options.longs === Number ? new $util.LongBits(message.outgoingAmtMsat.low >>> 0, message.outgoingAmtMsat.high >>> 0).toNumber(true) : message.outgoingAmtMsat;
            return object;
        };

        /**
         * Converts this HtlcInfo to JSON.
         * @function toJSON
         * @memberof routerrpc.HtlcInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HtlcInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HtlcInfo;
    })();

    routerrpc.ForwardEvent = (function() {

        /**
         * Properties of a ForwardEvent.
         * @memberof routerrpc
         * @interface IForwardEvent
         * @property {routerrpc.IHtlcInfo|null} [info] ForwardEvent info
         */

        /**
         * Constructs a new ForwardEvent.
         * @memberof routerrpc
         * @classdesc Represents a ForwardEvent.
         * @implements IForwardEvent
         * @constructor
         * @param {routerrpc.IForwardEvent=} [properties] Properties to set
         */
        function ForwardEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForwardEvent info.
         * @member {routerrpc.IHtlcInfo|null|undefined} info
         * @memberof routerrpc.ForwardEvent
         * @instance
         */
        ForwardEvent.prototype.info = null;

        /**
         * Creates a new ForwardEvent instance using the specified properties.
         * @function create
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {routerrpc.IForwardEvent=} [properties] Properties to set
         * @returns {routerrpc.ForwardEvent} ForwardEvent instance
         */
        ForwardEvent.create = function create(properties) {
            return new ForwardEvent(properties);
        };

        /**
         * Encodes the specified ForwardEvent message. Does not implicitly {@link routerrpc.ForwardEvent.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {routerrpc.IForwardEvent} message ForwardEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.routerrpc.HtlcInfo.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ForwardEvent message, length delimited. Does not implicitly {@link routerrpc.ForwardEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {routerrpc.IForwardEvent} message ForwardEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardEvent message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.ForwardEvent} ForwardEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.ForwardEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.info = $root.routerrpc.HtlcInfo.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.ForwardEvent} ForwardEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardEvent message.
         * @function verify
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.info != null && message.hasOwnProperty("info")) {
                let error = $root.routerrpc.HtlcInfo.verify(message.info);
                if (error)
                    return "info." + error;
            }
            return null;
        };

        /**
         * Creates a ForwardEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.ForwardEvent} ForwardEvent
         */
        ForwardEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.ForwardEvent)
                return object;
            let message = new $root.routerrpc.ForwardEvent();
            if (object.info != null) {
                if (typeof object.info !== "object")
                    throw TypeError(".routerrpc.ForwardEvent.info: object expected");
                message.info = $root.routerrpc.HtlcInfo.fromObject(object.info);
            }
            return message;
        };

        /**
         * Creates a plain object from a ForwardEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.ForwardEvent
         * @static
         * @param {routerrpc.ForwardEvent} message ForwardEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.info = null;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = $root.routerrpc.HtlcInfo.toObject(message.info, options);
            return object;
        };

        /**
         * Converts this ForwardEvent to JSON.
         * @function toJSON
         * @memberof routerrpc.ForwardEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardEvent;
    })();

    routerrpc.ForwardFailEvent = (function() {

        /**
         * Properties of a ForwardFailEvent.
         * @memberof routerrpc
         * @interface IForwardFailEvent
         */

        /**
         * Constructs a new ForwardFailEvent.
         * @memberof routerrpc
         * @classdesc Represents a ForwardFailEvent.
         * @implements IForwardFailEvent
         * @constructor
         * @param {routerrpc.IForwardFailEvent=} [properties] Properties to set
         */
        function ForwardFailEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ForwardFailEvent instance using the specified properties.
         * @function create
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {routerrpc.IForwardFailEvent=} [properties] Properties to set
         * @returns {routerrpc.ForwardFailEvent} ForwardFailEvent instance
         */
        ForwardFailEvent.create = function create(properties) {
            return new ForwardFailEvent(properties);
        };

        /**
         * Encodes the specified ForwardFailEvent message. Does not implicitly {@link routerrpc.ForwardFailEvent.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {routerrpc.IForwardFailEvent} message ForwardFailEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardFailEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ForwardFailEvent message, length delimited. Does not implicitly {@link routerrpc.ForwardFailEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {routerrpc.IForwardFailEvent} message ForwardFailEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardFailEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardFailEvent message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.ForwardFailEvent} ForwardFailEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardFailEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.ForwardFailEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardFailEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.ForwardFailEvent} ForwardFailEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardFailEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardFailEvent message.
         * @function verify
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardFailEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ForwardFailEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.ForwardFailEvent} ForwardFailEvent
         */
        ForwardFailEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.ForwardFailEvent)
                return object;
            return new $root.routerrpc.ForwardFailEvent();
        };

        /**
         * Creates a plain object from a ForwardFailEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.ForwardFailEvent
         * @static
         * @param {routerrpc.ForwardFailEvent} message ForwardFailEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardFailEvent.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ForwardFailEvent to JSON.
         * @function toJSON
         * @memberof routerrpc.ForwardFailEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardFailEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardFailEvent;
    })();

    routerrpc.SettleEvent = (function() {

        /**
         * Properties of a SettleEvent.
         * @memberof routerrpc
         * @interface ISettleEvent
         * @property {Uint8Array|null} [preimage] SettleEvent preimage
         */

        /**
         * Constructs a new SettleEvent.
         * @memberof routerrpc
         * @classdesc Represents a SettleEvent.
         * @implements ISettleEvent
         * @constructor
         * @param {routerrpc.ISettleEvent=} [properties] Properties to set
         */
        function SettleEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SettleEvent preimage.
         * @member {Uint8Array} preimage
         * @memberof routerrpc.SettleEvent
         * @instance
         */
        SettleEvent.prototype.preimage = $util.newBuffer([]);

        /**
         * Creates a new SettleEvent instance using the specified properties.
         * @function create
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {routerrpc.ISettleEvent=} [properties] Properties to set
         * @returns {routerrpc.SettleEvent} SettleEvent instance
         */
        SettleEvent.create = function create(properties) {
            return new SettleEvent(properties);
        };

        /**
         * Encodes the specified SettleEvent message. Does not implicitly {@link routerrpc.SettleEvent.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {routerrpc.ISettleEvent} message SettleEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettleEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.preimage);
            return writer;
        };

        /**
         * Encodes the specified SettleEvent message, length delimited. Does not implicitly {@link routerrpc.SettleEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {routerrpc.ISettleEvent} message SettleEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SettleEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SettleEvent message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.SettleEvent} SettleEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettleEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.SettleEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.preimage = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SettleEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.SettleEvent} SettleEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SettleEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SettleEvent message.
         * @function verify
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SettleEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            return null;
        };

        /**
         * Creates a SettleEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.SettleEvent} SettleEvent
         */
        SettleEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.SettleEvent)
                return object;
            let message = new $root.routerrpc.SettleEvent();
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            return message;
        };

        /**
         * Creates a plain object from a SettleEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.SettleEvent
         * @static
         * @param {routerrpc.SettleEvent} message SettleEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SettleEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            return object;
        };

        /**
         * Converts this SettleEvent to JSON.
         * @function toJSON
         * @memberof routerrpc.SettleEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SettleEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SettleEvent;
    })();

    routerrpc.LinkFailEvent = (function() {

        /**
         * Properties of a LinkFailEvent.
         * @memberof routerrpc
         * @interface ILinkFailEvent
         * @property {routerrpc.IHtlcInfo|null} [info] LinkFailEvent info
         * @property {lnrpc.Failure.FailureCode|null} [wireFailure] LinkFailEvent wireFailure
         * @property {routerrpc.FailureDetail|null} [failureDetail] LinkFailEvent failureDetail
         * @property {string|null} [failureString] LinkFailEvent failureString
         */

        /**
         * Constructs a new LinkFailEvent.
         * @memberof routerrpc
         * @classdesc Represents a LinkFailEvent.
         * @implements ILinkFailEvent
         * @constructor
         * @param {routerrpc.ILinkFailEvent=} [properties] Properties to set
         */
        function LinkFailEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LinkFailEvent info.
         * @member {routerrpc.IHtlcInfo|null|undefined} info
         * @memberof routerrpc.LinkFailEvent
         * @instance
         */
        LinkFailEvent.prototype.info = null;

        /**
         * LinkFailEvent wireFailure.
         * @member {lnrpc.Failure.FailureCode} wireFailure
         * @memberof routerrpc.LinkFailEvent
         * @instance
         */
        LinkFailEvent.prototype.wireFailure = 0;

        /**
         * LinkFailEvent failureDetail.
         * @member {routerrpc.FailureDetail} failureDetail
         * @memberof routerrpc.LinkFailEvent
         * @instance
         */
        LinkFailEvent.prototype.failureDetail = 0;

        /**
         * LinkFailEvent failureString.
         * @member {string} failureString
         * @memberof routerrpc.LinkFailEvent
         * @instance
         */
        LinkFailEvent.prototype.failureString = "";

        /**
         * Creates a new LinkFailEvent instance using the specified properties.
         * @function create
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {routerrpc.ILinkFailEvent=} [properties] Properties to set
         * @returns {routerrpc.LinkFailEvent} LinkFailEvent instance
         */
        LinkFailEvent.create = function create(properties) {
            return new LinkFailEvent(properties);
        };

        /**
         * Encodes the specified LinkFailEvent message. Does not implicitly {@link routerrpc.LinkFailEvent.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {routerrpc.ILinkFailEvent} message LinkFailEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LinkFailEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                $root.routerrpc.HtlcInfo.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.wireFailure != null && Object.hasOwnProperty.call(message, "wireFailure"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.wireFailure);
            if (message.failureDetail != null && Object.hasOwnProperty.call(message, "failureDetail"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.failureDetail);
            if (message.failureString != null && Object.hasOwnProperty.call(message, "failureString"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.failureString);
            return writer;
        };

        /**
         * Encodes the specified LinkFailEvent message, length delimited. Does not implicitly {@link routerrpc.LinkFailEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {routerrpc.ILinkFailEvent} message LinkFailEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LinkFailEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LinkFailEvent message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.LinkFailEvent} LinkFailEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LinkFailEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.LinkFailEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.info = $root.routerrpc.HtlcInfo.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.wireFailure = reader.int32();
                        break;
                    }
                case 3: {
                        message.failureDetail = reader.int32();
                        break;
                    }
                case 4: {
                        message.failureString = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LinkFailEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.LinkFailEvent} LinkFailEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LinkFailEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LinkFailEvent message.
         * @function verify
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LinkFailEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.info != null && message.hasOwnProperty("info")) {
                let error = $root.routerrpc.HtlcInfo.verify(message.info);
                if (error)
                    return "info." + error;
            }
            if (message.wireFailure != null && message.hasOwnProperty("wireFailure"))
                switch (message.wireFailure) {
                default:
                    return "wireFailure: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 997:
                case 998:
                case 999:
                    break;
                }
            if (message.failureDetail != null && message.hasOwnProperty("failureDetail"))
                switch (message.failureDetail) {
                default:
                    return "failureDetail: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                    break;
                }
            if (message.failureString != null && message.hasOwnProperty("failureString"))
                if (!$util.isString(message.failureString))
                    return "failureString: string expected";
            return null;
        };

        /**
         * Creates a LinkFailEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.LinkFailEvent} LinkFailEvent
         */
        LinkFailEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.LinkFailEvent)
                return object;
            let message = new $root.routerrpc.LinkFailEvent();
            if (object.info != null) {
                if (typeof object.info !== "object")
                    throw TypeError(".routerrpc.LinkFailEvent.info: object expected");
                message.info = $root.routerrpc.HtlcInfo.fromObject(object.info);
            }
            switch (object.wireFailure) {
            case "RESERVED":
            case 0:
                message.wireFailure = 0;
                break;
            case "INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS":
            case 1:
                message.wireFailure = 1;
                break;
            case "INCORRECT_PAYMENT_AMOUNT":
            case 2:
                message.wireFailure = 2;
                break;
            case "FINAL_INCORRECT_CLTV_EXPIRY":
            case 3:
                message.wireFailure = 3;
                break;
            case "FINAL_INCORRECT_HTLC_AMOUNT":
            case 4:
                message.wireFailure = 4;
                break;
            case "FINAL_EXPIRY_TOO_SOON":
            case 5:
                message.wireFailure = 5;
                break;
            case "INVALID_REALM":
            case 6:
                message.wireFailure = 6;
                break;
            case "EXPIRY_TOO_SOON":
            case 7:
                message.wireFailure = 7;
                break;
            case "INVALID_ONION_VERSION":
            case 8:
                message.wireFailure = 8;
                break;
            case "INVALID_ONION_HMAC":
            case 9:
                message.wireFailure = 9;
                break;
            case "INVALID_ONION_KEY":
            case 10:
                message.wireFailure = 10;
                break;
            case "AMOUNT_BELOW_MINIMUM":
            case 11:
                message.wireFailure = 11;
                break;
            case "FEE_INSUFFICIENT":
            case 12:
                message.wireFailure = 12;
                break;
            case "INCORRECT_CLTV_EXPIRY":
            case 13:
                message.wireFailure = 13;
                break;
            case "CHANNEL_DISABLED":
            case 14:
                message.wireFailure = 14;
                break;
            case "TEMPORARY_CHANNEL_FAILURE":
            case 15:
                message.wireFailure = 15;
                break;
            case "REQUIRED_NODE_FEATURE_MISSING":
            case 16:
                message.wireFailure = 16;
                break;
            case "REQUIRED_CHANNEL_FEATURE_MISSING":
            case 17:
                message.wireFailure = 17;
                break;
            case "UNKNOWN_NEXT_PEER":
            case 18:
                message.wireFailure = 18;
                break;
            case "TEMPORARY_NODE_FAILURE":
            case 19:
                message.wireFailure = 19;
                break;
            case "PERMANENT_NODE_FAILURE":
            case 20:
                message.wireFailure = 20;
                break;
            case "PERMANENT_CHANNEL_FAILURE":
            case 21:
                message.wireFailure = 21;
                break;
            case "EXPIRY_TOO_FAR":
            case 22:
                message.wireFailure = 22;
                break;
            case "MPP_TIMEOUT":
            case 23:
                message.wireFailure = 23;
                break;
            case "INVALID_ONION_PAYLOAD":
            case 24:
                message.wireFailure = 24;
                break;
            case "INTERNAL_FAILURE":
            case 997:
                message.wireFailure = 997;
                break;
            case "UNKNOWN_FAILURE":
            case 998:
                message.wireFailure = 998;
                break;
            case "UNREADABLE_FAILURE":
            case 999:
                message.wireFailure = 999;
                break;
            }
            switch (object.failureDetail) {
            case "UNKNOWN":
            case 0:
                message.failureDetail = 0;
                break;
            case "NO_DETAIL":
            case 1:
                message.failureDetail = 1;
                break;
            case "ONION_DECODE":
            case 2:
                message.failureDetail = 2;
                break;
            case "LINK_NOT_ELIGIBLE":
            case 3:
                message.failureDetail = 3;
                break;
            case "ON_CHAIN_TIMEOUT":
            case 4:
                message.failureDetail = 4;
                break;
            case "HTLC_EXCEEDS_MAX":
            case 5:
                message.failureDetail = 5;
                break;
            case "INSUFFICIENT_BALANCE":
            case 6:
                message.failureDetail = 6;
                break;
            case "INCOMPLETE_FORWARD":
            case 7:
                message.failureDetail = 7;
                break;
            case "HTLC_ADD_FAILED":
            case 8:
                message.failureDetail = 8;
                break;
            case "FORWARDS_DISABLED":
            case 9:
                message.failureDetail = 9;
                break;
            case "INVOICE_CANCELED":
            case 10:
                message.failureDetail = 10;
                break;
            case "INVOICE_UNDERPAID":
            case 11:
                message.failureDetail = 11;
                break;
            case "INVOICE_EXPIRY_TOO_SOON":
            case 12:
                message.failureDetail = 12;
                break;
            case "INVOICE_NOT_OPEN":
            case 13:
                message.failureDetail = 13;
                break;
            case "MPP_INVOICE_TIMEOUT":
            case 14:
                message.failureDetail = 14;
                break;
            case "ADDRESS_MISMATCH":
            case 15:
                message.failureDetail = 15;
                break;
            case "SET_TOTAL_MISMATCH":
            case 16:
                message.failureDetail = 16;
                break;
            case "SET_TOTAL_TOO_LOW":
            case 17:
                message.failureDetail = 17;
                break;
            case "SET_OVERPAID":
            case 18:
                message.failureDetail = 18;
                break;
            case "UNKNOWN_INVOICE":
            case 19:
                message.failureDetail = 19;
                break;
            case "INVALID_KEYSEND":
            case 20:
                message.failureDetail = 20;
                break;
            case "MPP_IN_PROGRESS":
            case 21:
                message.failureDetail = 21;
                break;
            case "CIRCULAR_ROUTE":
            case 22:
                message.failureDetail = 22;
                break;
            }
            if (object.failureString != null)
                message.failureString = String(object.failureString);
            return message;
        };

        /**
         * Creates a plain object from a LinkFailEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.LinkFailEvent
         * @static
         * @param {routerrpc.LinkFailEvent} message LinkFailEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LinkFailEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.info = null;
                object.wireFailure = options.enums === String ? "RESERVED" : 0;
                object.failureDetail = options.enums === String ? "UNKNOWN" : 0;
                object.failureString = "";
            }
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = $root.routerrpc.HtlcInfo.toObject(message.info, options);
            if (message.wireFailure != null && message.hasOwnProperty("wireFailure"))
                object.wireFailure = options.enums === String ? $root.lnrpc.Failure.FailureCode[message.wireFailure] : message.wireFailure;
            if (message.failureDetail != null && message.hasOwnProperty("failureDetail"))
                object.failureDetail = options.enums === String ? $root.routerrpc.FailureDetail[message.failureDetail] : message.failureDetail;
            if (message.failureString != null && message.hasOwnProperty("failureString"))
                object.failureString = message.failureString;
            return object;
        };

        /**
         * Converts this LinkFailEvent to JSON.
         * @function toJSON
         * @memberof routerrpc.LinkFailEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LinkFailEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LinkFailEvent;
    })();

    /**
     * FailureDetail enum.
     * @name routerrpc.FailureDetail
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} NO_DETAIL=1 NO_DETAIL value
     * @property {number} ONION_DECODE=2 ONION_DECODE value
     * @property {number} LINK_NOT_ELIGIBLE=3 LINK_NOT_ELIGIBLE value
     * @property {number} ON_CHAIN_TIMEOUT=4 ON_CHAIN_TIMEOUT value
     * @property {number} HTLC_EXCEEDS_MAX=5 HTLC_EXCEEDS_MAX value
     * @property {number} INSUFFICIENT_BALANCE=6 INSUFFICIENT_BALANCE value
     * @property {number} INCOMPLETE_FORWARD=7 INCOMPLETE_FORWARD value
     * @property {number} HTLC_ADD_FAILED=8 HTLC_ADD_FAILED value
     * @property {number} FORWARDS_DISABLED=9 FORWARDS_DISABLED value
     * @property {number} INVOICE_CANCELED=10 INVOICE_CANCELED value
     * @property {number} INVOICE_UNDERPAID=11 INVOICE_UNDERPAID value
     * @property {number} INVOICE_EXPIRY_TOO_SOON=12 INVOICE_EXPIRY_TOO_SOON value
     * @property {number} INVOICE_NOT_OPEN=13 INVOICE_NOT_OPEN value
     * @property {number} MPP_INVOICE_TIMEOUT=14 MPP_INVOICE_TIMEOUT value
     * @property {number} ADDRESS_MISMATCH=15 ADDRESS_MISMATCH value
     * @property {number} SET_TOTAL_MISMATCH=16 SET_TOTAL_MISMATCH value
     * @property {number} SET_TOTAL_TOO_LOW=17 SET_TOTAL_TOO_LOW value
     * @property {number} SET_OVERPAID=18 SET_OVERPAID value
     * @property {number} UNKNOWN_INVOICE=19 UNKNOWN_INVOICE value
     * @property {number} INVALID_KEYSEND=20 INVALID_KEYSEND value
     * @property {number} MPP_IN_PROGRESS=21 MPP_IN_PROGRESS value
     * @property {number} CIRCULAR_ROUTE=22 CIRCULAR_ROUTE value
     */
    routerrpc.FailureDetail = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "NO_DETAIL"] = 1;
        values[valuesById[2] = "ONION_DECODE"] = 2;
        values[valuesById[3] = "LINK_NOT_ELIGIBLE"] = 3;
        values[valuesById[4] = "ON_CHAIN_TIMEOUT"] = 4;
        values[valuesById[5] = "HTLC_EXCEEDS_MAX"] = 5;
        values[valuesById[6] = "INSUFFICIENT_BALANCE"] = 6;
        values[valuesById[7] = "INCOMPLETE_FORWARD"] = 7;
        values[valuesById[8] = "HTLC_ADD_FAILED"] = 8;
        values[valuesById[9] = "FORWARDS_DISABLED"] = 9;
        values[valuesById[10] = "INVOICE_CANCELED"] = 10;
        values[valuesById[11] = "INVOICE_UNDERPAID"] = 11;
        values[valuesById[12] = "INVOICE_EXPIRY_TOO_SOON"] = 12;
        values[valuesById[13] = "INVOICE_NOT_OPEN"] = 13;
        values[valuesById[14] = "MPP_INVOICE_TIMEOUT"] = 14;
        values[valuesById[15] = "ADDRESS_MISMATCH"] = 15;
        values[valuesById[16] = "SET_TOTAL_MISMATCH"] = 16;
        values[valuesById[17] = "SET_TOTAL_TOO_LOW"] = 17;
        values[valuesById[18] = "SET_OVERPAID"] = 18;
        values[valuesById[19] = "UNKNOWN_INVOICE"] = 19;
        values[valuesById[20] = "INVALID_KEYSEND"] = 20;
        values[valuesById[21] = "MPP_IN_PROGRESS"] = 21;
        values[valuesById[22] = "CIRCULAR_ROUTE"] = 22;
        return values;
    })();

    /**
     * PaymentState enum.
     * @name routerrpc.PaymentState
     * @enum {number}
     * @property {number} IN_FLIGHT=0 IN_FLIGHT value
     * @property {number} SUCCEEDED=1 SUCCEEDED value
     * @property {number} FAILED_TIMEOUT=2 FAILED_TIMEOUT value
     * @property {number} FAILED_NO_ROUTE=3 FAILED_NO_ROUTE value
     * @property {number} FAILED_ERROR=4 FAILED_ERROR value
     * @property {number} FAILED_INCORRECT_PAYMENT_DETAILS=5 FAILED_INCORRECT_PAYMENT_DETAILS value
     * @property {number} FAILED_INSUFFICIENT_BALANCE=6 FAILED_INSUFFICIENT_BALANCE value
     */
    routerrpc.PaymentState = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "IN_FLIGHT"] = 0;
        values[valuesById[1] = "SUCCEEDED"] = 1;
        values[valuesById[2] = "FAILED_TIMEOUT"] = 2;
        values[valuesById[3] = "FAILED_NO_ROUTE"] = 3;
        values[valuesById[4] = "FAILED_ERROR"] = 4;
        values[valuesById[5] = "FAILED_INCORRECT_PAYMENT_DETAILS"] = 5;
        values[valuesById[6] = "FAILED_INSUFFICIENT_BALANCE"] = 6;
        return values;
    })();

    routerrpc.PaymentStatus = (function() {

        /**
         * Properties of a PaymentStatus.
         * @memberof routerrpc
         * @interface IPaymentStatus
         * @property {routerrpc.PaymentState|null} [state] PaymentStatus state
         * @property {Uint8Array|null} [preimage] PaymentStatus preimage
         * @property {Array.<lnrpc.IHTLCAttempt>|null} [htlcs] PaymentStatus htlcs
         */

        /**
         * Constructs a new PaymentStatus.
         * @memberof routerrpc
         * @classdesc Represents a PaymentStatus.
         * @implements IPaymentStatus
         * @constructor
         * @param {routerrpc.IPaymentStatus=} [properties] Properties to set
         */
        function PaymentStatus(properties) {
            this.htlcs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentStatus state.
         * @member {routerrpc.PaymentState} state
         * @memberof routerrpc.PaymentStatus
         * @instance
         */
        PaymentStatus.prototype.state = 0;

        /**
         * PaymentStatus preimage.
         * @member {Uint8Array} preimage
         * @memberof routerrpc.PaymentStatus
         * @instance
         */
        PaymentStatus.prototype.preimage = $util.newBuffer([]);

        /**
         * PaymentStatus htlcs.
         * @member {Array.<lnrpc.IHTLCAttempt>} htlcs
         * @memberof routerrpc.PaymentStatus
         * @instance
         */
        PaymentStatus.prototype.htlcs = $util.emptyArray;

        /**
         * Creates a new PaymentStatus instance using the specified properties.
         * @function create
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {routerrpc.IPaymentStatus=} [properties] Properties to set
         * @returns {routerrpc.PaymentStatus} PaymentStatus instance
         */
        PaymentStatus.create = function create(properties) {
            return new PaymentStatus(properties);
        };

        /**
         * Encodes the specified PaymentStatus message. Does not implicitly {@link routerrpc.PaymentStatus.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {routerrpc.IPaymentStatus} message PaymentStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.preimage);
            if (message.htlcs != null && message.htlcs.length)
                for (let i = 0; i < message.htlcs.length; ++i)
                    $root.lnrpc.HTLCAttempt.encode(message.htlcs[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PaymentStatus message, length delimited. Does not implicitly {@link routerrpc.PaymentStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {routerrpc.IPaymentStatus} message PaymentStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaymentStatus message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.PaymentStatus} PaymentStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.PaymentStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.state = reader.int32();
                        break;
                    }
                case 2: {
                        message.preimage = reader.bytes();
                        break;
                    }
                case 4: {
                        if (!(message.htlcs && message.htlcs.length))
                            message.htlcs = [];
                        message.htlcs.push($root.lnrpc.HTLCAttempt.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PaymentStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.PaymentStatus} PaymentStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PaymentStatus message.
         * @function verify
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PaymentStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.state != null && message.hasOwnProperty("state"))
                switch (message.state) {
                default:
                    return "state: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            if (message.htlcs != null && message.hasOwnProperty("htlcs")) {
                if (!Array.isArray(message.htlcs))
                    return "htlcs: array expected";
                for (let i = 0; i < message.htlcs.length; ++i) {
                    let error = $root.lnrpc.HTLCAttempt.verify(message.htlcs[i]);
                    if (error)
                        return "htlcs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PaymentStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.PaymentStatus} PaymentStatus
         */
        PaymentStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.PaymentStatus)
                return object;
            let message = new $root.routerrpc.PaymentStatus();
            switch (object.state) {
            case "IN_FLIGHT":
            case 0:
                message.state = 0;
                break;
            case "SUCCEEDED":
            case 1:
                message.state = 1;
                break;
            case "FAILED_TIMEOUT":
            case 2:
                message.state = 2;
                break;
            case "FAILED_NO_ROUTE":
            case 3:
                message.state = 3;
                break;
            case "FAILED_ERROR":
            case 4:
                message.state = 4;
                break;
            case "FAILED_INCORRECT_PAYMENT_DETAILS":
            case 5:
                message.state = 5;
                break;
            case "FAILED_INSUFFICIENT_BALANCE":
            case 6:
                message.state = 6;
                break;
            }
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            if (object.htlcs) {
                if (!Array.isArray(object.htlcs))
                    throw TypeError(".routerrpc.PaymentStatus.htlcs: array expected");
                message.htlcs = [];
                for (let i = 0; i < object.htlcs.length; ++i) {
                    if (typeof object.htlcs[i] !== "object")
                        throw TypeError(".routerrpc.PaymentStatus.htlcs: object expected");
                    message.htlcs[i] = $root.lnrpc.HTLCAttempt.fromObject(object.htlcs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PaymentStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.PaymentStatus
         * @static
         * @param {routerrpc.PaymentStatus} message PaymentStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.htlcs = [];
            if (options.defaults) {
                object.state = options.enums === String ? "IN_FLIGHT" : 0;
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
            }
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = options.enums === String ? $root.routerrpc.PaymentState[message.state] : message.state;
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            if (message.htlcs && message.htlcs.length) {
                object.htlcs = [];
                for (let j = 0; j < message.htlcs.length; ++j)
                    object.htlcs[j] = $root.lnrpc.HTLCAttempt.toObject(message.htlcs[j], options);
            }
            return object;
        };

        /**
         * Converts this PaymentStatus to JSON.
         * @function toJSON
         * @memberof routerrpc.PaymentStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PaymentStatus;
    })();

    routerrpc.CircuitKey = (function() {

        /**
         * Properties of a CircuitKey.
         * @memberof routerrpc
         * @interface ICircuitKey
         * @property {Long|null} [chanId] The id of the channel that the is part of this circuit.
         * @property {Long|null} [htlcId] The index of the incoming htlc in the incoming channel.
         */

        /**
         * Constructs a new CircuitKey.
         * @memberof routerrpc
         * @classdesc Represents a CircuitKey.
         * @implements ICircuitKey
         * @constructor
         * @param {routerrpc.ICircuitKey=} [properties] Properties to set
         */
        function CircuitKey(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The id of the channel that the is part of this circuit.
         * @member {Long} chanId
         * @memberof routerrpc.CircuitKey
         * @instance
         */
        CircuitKey.prototype.chanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * The index of the incoming htlc in the incoming channel.
         * @member {Long} htlcId
         * @memberof routerrpc.CircuitKey
         * @instance
         */
        CircuitKey.prototype.htlcId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new CircuitKey instance using the specified properties.
         * @function create
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {routerrpc.ICircuitKey=} [properties] Properties to set
         * @returns {routerrpc.CircuitKey} CircuitKey instance
         */
        CircuitKey.create = function create(properties) {
            return new CircuitKey(properties);
        };

        /**
         * Encodes the specified CircuitKey message. Does not implicitly {@link routerrpc.CircuitKey.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {routerrpc.ICircuitKey} message CircuitKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CircuitKey.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanId != null && Object.hasOwnProperty.call(message, "chanId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.chanId);
            if (message.htlcId != null && Object.hasOwnProperty.call(message, "htlcId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.htlcId);
            return writer;
        };

        /**
         * Encodes the specified CircuitKey message, length delimited. Does not implicitly {@link routerrpc.CircuitKey.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {routerrpc.ICircuitKey} message CircuitKey message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CircuitKey.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CircuitKey message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.CircuitKey} CircuitKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CircuitKey.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.CircuitKey();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanId = reader.uint64();
                        break;
                    }
                case 2: {
                        message.htlcId = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CircuitKey message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.CircuitKey} CircuitKey
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CircuitKey.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CircuitKey message.
         * @function verify
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CircuitKey.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (!$util.isInteger(message.chanId) && !(message.chanId && $util.isInteger(message.chanId.low) && $util.isInteger(message.chanId.high)))
                    return "chanId: integer|Long expected";
            if (message.htlcId != null && message.hasOwnProperty("htlcId"))
                if (!$util.isInteger(message.htlcId) && !(message.htlcId && $util.isInteger(message.htlcId.low) && $util.isInteger(message.htlcId.high)))
                    return "htlcId: integer|Long expected";
            return null;
        };

        /**
         * Creates a CircuitKey message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.CircuitKey} CircuitKey
         */
        CircuitKey.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.CircuitKey)
                return object;
            let message = new $root.routerrpc.CircuitKey();
            if (object.chanId != null)
                if ($util.Long)
                    (message.chanId = $util.Long.fromValue(object.chanId)).unsigned = true;
                else if (typeof object.chanId === "string")
                    message.chanId = parseInt(object.chanId, 10);
                else if (typeof object.chanId === "number")
                    message.chanId = object.chanId;
                else if (typeof object.chanId === "object")
                    message.chanId = new $util.LongBits(object.chanId.low >>> 0, object.chanId.high >>> 0).toNumber(true);
            if (object.htlcId != null)
                if ($util.Long)
                    (message.htlcId = $util.Long.fromValue(object.htlcId)).unsigned = true;
                else if (typeof object.htlcId === "string")
                    message.htlcId = parseInt(object.htlcId, 10);
                else if (typeof object.htlcId === "number")
                    message.htlcId = object.htlcId;
                else if (typeof object.htlcId === "object")
                    message.htlcId = new $util.LongBits(object.htlcId.low >>> 0, object.htlcId.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a CircuitKey message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.CircuitKey
         * @static
         * @param {routerrpc.CircuitKey} message CircuitKey
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CircuitKey.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.chanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.chanId = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.htlcId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.htlcId = options.longs === String ? "0" : 0;
            }
            if (message.chanId != null && message.hasOwnProperty("chanId"))
                if (typeof message.chanId === "number")
                    object.chanId = options.longs === String ? String(message.chanId) : message.chanId;
                else
                    object.chanId = options.longs === String ? $util.Long.prototype.toString.call(message.chanId) : options.longs === Number ? new $util.LongBits(message.chanId.low >>> 0, message.chanId.high >>> 0).toNumber(true) : message.chanId;
            if (message.htlcId != null && message.hasOwnProperty("htlcId"))
                if (typeof message.htlcId === "number")
                    object.htlcId = options.longs === String ? String(message.htlcId) : message.htlcId;
                else
                    object.htlcId = options.longs === String ? $util.Long.prototype.toString.call(message.htlcId) : options.longs === Number ? new $util.LongBits(message.htlcId.low >>> 0, message.htlcId.high >>> 0).toNumber(true) : message.htlcId;
            return object;
        };

        /**
         * Converts this CircuitKey to JSON.
         * @function toJSON
         * @memberof routerrpc.CircuitKey
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CircuitKey.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CircuitKey;
    })();

    routerrpc.ForwardHtlcInterceptRequest = (function() {

        /**
         * Properties of a ForwardHtlcInterceptRequest.
         * @memberof routerrpc
         * @interface IForwardHtlcInterceptRequest
         * @property {routerrpc.ICircuitKey|null} [incomingCircuitKey] ForwardHtlcInterceptRequest incomingCircuitKey
         * @property {Long|null} [incomingAmountMsat] ForwardHtlcInterceptRequest incomingAmountMsat
         * @property {number|null} [incomingExpiry] ForwardHtlcInterceptRequest incomingExpiry
         * @property {Uint8Array|null} [paymentHash] ForwardHtlcInterceptRequest paymentHash
         * @property {Long|null} [outgoingRequestedChanId] ForwardHtlcInterceptRequest outgoingRequestedChanId
         * @property {Long|null} [outgoingAmountMsat] ForwardHtlcInterceptRequest outgoingAmountMsat
         * @property {number|null} [outgoingExpiry] ForwardHtlcInterceptRequest outgoingExpiry
         * @property {Object.<string,Uint8Array>|null} [customRecords] ForwardHtlcInterceptRequest customRecords
         * @property {Uint8Array|null} [onionBlob] ForwardHtlcInterceptRequest onionBlob
         */

        /**
         * Constructs a new ForwardHtlcInterceptRequest.
         * @memberof routerrpc
         * @classdesc Represents a ForwardHtlcInterceptRequest.
         * @implements IForwardHtlcInterceptRequest
         * @constructor
         * @param {routerrpc.IForwardHtlcInterceptRequest=} [properties] Properties to set
         */
        function ForwardHtlcInterceptRequest(properties) {
            this.customRecords = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ForwardHtlcInterceptRequest incomingCircuitKey.
         * @member {routerrpc.ICircuitKey|null|undefined} incomingCircuitKey
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.incomingCircuitKey = null;

        /**
         * ForwardHtlcInterceptRequest incomingAmountMsat.
         * @member {Long} incomingAmountMsat
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.incomingAmountMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardHtlcInterceptRequest incomingExpiry.
         * @member {number} incomingExpiry
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.incomingExpiry = 0;

        /**
         * ForwardHtlcInterceptRequest paymentHash.
         * @member {Uint8Array} paymentHash
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.paymentHash = $util.newBuffer([]);

        /**
         * ForwardHtlcInterceptRequest outgoingRequestedChanId.
         * @member {Long} outgoingRequestedChanId
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.outgoingRequestedChanId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardHtlcInterceptRequest outgoingAmountMsat.
         * @member {Long} outgoingAmountMsat
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.outgoingAmountMsat = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ForwardHtlcInterceptRequest outgoingExpiry.
         * @member {number} outgoingExpiry
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.outgoingExpiry = 0;

        /**
         * ForwardHtlcInterceptRequest customRecords.
         * @member {Object.<string,Uint8Array>} customRecords
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.customRecords = $util.emptyObject;

        /**
         * ForwardHtlcInterceptRequest onionBlob.
         * @member {Uint8Array} onionBlob
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         */
        ForwardHtlcInterceptRequest.prototype.onionBlob = $util.newBuffer([]);

        /**
         * Creates a new ForwardHtlcInterceptRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {routerrpc.IForwardHtlcInterceptRequest=} [properties] Properties to set
         * @returns {routerrpc.ForwardHtlcInterceptRequest} ForwardHtlcInterceptRequest instance
         */
        ForwardHtlcInterceptRequest.create = function create(properties) {
            return new ForwardHtlcInterceptRequest(properties);
        };

        /**
         * Encodes the specified ForwardHtlcInterceptRequest message. Does not implicitly {@link routerrpc.ForwardHtlcInterceptRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {routerrpc.IForwardHtlcInterceptRequest} message ForwardHtlcInterceptRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardHtlcInterceptRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incomingCircuitKey != null && Object.hasOwnProperty.call(message, "incomingCircuitKey"))
                $root.routerrpc.CircuitKey.encode(message.incomingCircuitKey, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.paymentHash != null && Object.hasOwnProperty.call(message, "paymentHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.paymentHash);
            if (message.outgoingAmountMsat != null && Object.hasOwnProperty.call(message, "outgoingAmountMsat"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.outgoingAmountMsat);
            if (message.outgoingExpiry != null && Object.hasOwnProperty.call(message, "outgoingExpiry"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.outgoingExpiry);
            if (message.incomingAmountMsat != null && Object.hasOwnProperty.call(message, "incomingAmountMsat"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.incomingAmountMsat);
            if (message.incomingExpiry != null && Object.hasOwnProperty.call(message, "incomingExpiry"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.incomingExpiry);
            if (message.outgoingRequestedChanId != null && Object.hasOwnProperty.call(message, "outgoingRequestedChanId"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.outgoingRequestedChanId);
            if (message.customRecords != null && Object.hasOwnProperty.call(message, "customRecords"))
                for (let keys = Object.keys(message.customRecords), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).fork().uint32(/* id 1, wireType 0 =*/8).uint64(keys[i]).uint32(/* id 2, wireType 2 =*/18).bytes(message.customRecords[keys[i]]).ldelim();
            if (message.onionBlob != null && Object.hasOwnProperty.call(message, "onionBlob"))
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.onionBlob);
            return writer;
        };

        /**
         * Encodes the specified ForwardHtlcInterceptRequest message, length delimited. Does not implicitly {@link routerrpc.ForwardHtlcInterceptRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {routerrpc.IForwardHtlcInterceptRequest} message ForwardHtlcInterceptRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardHtlcInterceptRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardHtlcInterceptRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.ForwardHtlcInterceptRequest} ForwardHtlcInterceptRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardHtlcInterceptRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.ForwardHtlcInterceptRequest(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incomingCircuitKey = $root.routerrpc.CircuitKey.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.incomingAmountMsat = reader.uint64();
                        break;
                    }
                case 6: {
                        message.incomingExpiry = reader.uint32();
                        break;
                    }
                case 2: {
                        message.paymentHash = reader.bytes();
                        break;
                    }
                case 7: {
                        message.outgoingRequestedChanId = reader.uint64();
                        break;
                    }
                case 3: {
                        message.outgoingAmountMsat = reader.uint64();
                        break;
                    }
                case 4: {
                        message.outgoingExpiry = reader.uint32();
                        break;
                    }
                case 8: {
                        if (message.customRecords === $util.emptyObject)
                            message.customRecords = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = [];
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.uint64();
                                break;
                            case 2:
                                value = reader.bytes();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.customRecords[typeof key === "object" ? $util.longToHash(key) : key] = value;
                        break;
                    }
                case 9: {
                        message.onionBlob = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardHtlcInterceptRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.ForwardHtlcInterceptRequest} ForwardHtlcInterceptRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardHtlcInterceptRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardHtlcInterceptRequest message.
         * @function verify
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardHtlcInterceptRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.incomingCircuitKey != null && message.hasOwnProperty("incomingCircuitKey")) {
                let error = $root.routerrpc.CircuitKey.verify(message.incomingCircuitKey);
                if (error)
                    return "incomingCircuitKey." + error;
            }
            if (message.incomingAmountMsat != null && message.hasOwnProperty("incomingAmountMsat"))
                if (!$util.isInteger(message.incomingAmountMsat) && !(message.incomingAmountMsat && $util.isInteger(message.incomingAmountMsat.low) && $util.isInteger(message.incomingAmountMsat.high)))
                    return "incomingAmountMsat: integer|Long expected";
            if (message.incomingExpiry != null && message.hasOwnProperty("incomingExpiry"))
                if (!$util.isInteger(message.incomingExpiry))
                    return "incomingExpiry: integer expected";
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                if (!(message.paymentHash && typeof message.paymentHash.length === "number" || $util.isString(message.paymentHash)))
                    return "paymentHash: buffer expected";
            if (message.outgoingRequestedChanId != null && message.hasOwnProperty("outgoingRequestedChanId"))
                if (!$util.isInteger(message.outgoingRequestedChanId) && !(message.outgoingRequestedChanId && $util.isInteger(message.outgoingRequestedChanId.low) && $util.isInteger(message.outgoingRequestedChanId.high)))
                    return "outgoingRequestedChanId: integer|Long expected";
            if (message.outgoingAmountMsat != null && message.hasOwnProperty("outgoingAmountMsat"))
                if (!$util.isInteger(message.outgoingAmountMsat) && !(message.outgoingAmountMsat && $util.isInteger(message.outgoingAmountMsat.low) && $util.isInteger(message.outgoingAmountMsat.high)))
                    return "outgoingAmountMsat: integer|Long expected";
            if (message.outgoingExpiry != null && message.hasOwnProperty("outgoingExpiry"))
                if (!$util.isInteger(message.outgoingExpiry))
                    return "outgoingExpiry: integer expected";
            if (message.customRecords != null && message.hasOwnProperty("customRecords")) {
                if (!$util.isObject(message.customRecords))
                    return "customRecords: object expected";
                let key = Object.keys(message.customRecords);
                for (let i = 0; i < key.length; ++i) {
                    if (!$util.key64Re.test(key[i]))
                        return "customRecords: integer|Long key{k:uint64} expected";
                    if (!(message.customRecords[key[i]] && typeof message.customRecords[key[i]].length === "number" || $util.isString(message.customRecords[key[i]])))
                        return "customRecords: buffer{k:uint64} expected";
                }
            }
            if (message.onionBlob != null && message.hasOwnProperty("onionBlob"))
                if (!(message.onionBlob && typeof message.onionBlob.length === "number" || $util.isString(message.onionBlob)))
                    return "onionBlob: buffer expected";
            return null;
        };

        /**
         * Creates a ForwardHtlcInterceptRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.ForwardHtlcInterceptRequest} ForwardHtlcInterceptRequest
         */
        ForwardHtlcInterceptRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.ForwardHtlcInterceptRequest)
                return object;
            let message = new $root.routerrpc.ForwardHtlcInterceptRequest();
            if (object.incomingCircuitKey != null) {
                if (typeof object.incomingCircuitKey !== "object")
                    throw TypeError(".routerrpc.ForwardHtlcInterceptRequest.incomingCircuitKey: object expected");
                message.incomingCircuitKey = $root.routerrpc.CircuitKey.fromObject(object.incomingCircuitKey);
            }
            if (object.incomingAmountMsat != null)
                if ($util.Long)
                    (message.incomingAmountMsat = $util.Long.fromValue(object.incomingAmountMsat)).unsigned = true;
                else if (typeof object.incomingAmountMsat === "string")
                    message.incomingAmountMsat = parseInt(object.incomingAmountMsat, 10);
                else if (typeof object.incomingAmountMsat === "number")
                    message.incomingAmountMsat = object.incomingAmountMsat;
                else if (typeof object.incomingAmountMsat === "object")
                    message.incomingAmountMsat = new $util.LongBits(object.incomingAmountMsat.low >>> 0, object.incomingAmountMsat.high >>> 0).toNumber(true);
            if (object.incomingExpiry != null)
                message.incomingExpiry = object.incomingExpiry >>> 0;
            if (object.paymentHash != null)
                if (typeof object.paymentHash === "string")
                    $util.base64.decode(object.paymentHash, message.paymentHash = $util.newBuffer($util.base64.length(object.paymentHash)), 0);
                else if (object.paymentHash.length)
                    message.paymentHash = object.paymentHash;
            if (object.outgoingRequestedChanId != null)
                if ($util.Long)
                    (message.outgoingRequestedChanId = $util.Long.fromValue(object.outgoingRequestedChanId)).unsigned = true;
                else if (typeof object.outgoingRequestedChanId === "string")
                    message.outgoingRequestedChanId = parseInt(object.outgoingRequestedChanId, 10);
                else if (typeof object.outgoingRequestedChanId === "number")
                    message.outgoingRequestedChanId = object.outgoingRequestedChanId;
                else if (typeof object.outgoingRequestedChanId === "object")
                    message.outgoingRequestedChanId = new $util.LongBits(object.outgoingRequestedChanId.low >>> 0, object.outgoingRequestedChanId.high >>> 0).toNumber(true);
            if (object.outgoingAmountMsat != null)
                if ($util.Long)
                    (message.outgoingAmountMsat = $util.Long.fromValue(object.outgoingAmountMsat)).unsigned = true;
                else if (typeof object.outgoingAmountMsat === "string")
                    message.outgoingAmountMsat = parseInt(object.outgoingAmountMsat, 10);
                else if (typeof object.outgoingAmountMsat === "number")
                    message.outgoingAmountMsat = object.outgoingAmountMsat;
                else if (typeof object.outgoingAmountMsat === "object")
                    message.outgoingAmountMsat = new $util.LongBits(object.outgoingAmountMsat.low >>> 0, object.outgoingAmountMsat.high >>> 0).toNumber(true);
            if (object.outgoingExpiry != null)
                message.outgoingExpiry = object.outgoingExpiry >>> 0;
            if (object.customRecords) {
                if (typeof object.customRecords !== "object")
                    throw TypeError(".routerrpc.ForwardHtlcInterceptRequest.customRecords: object expected");
                message.customRecords = {};
                for (let keys = Object.keys(object.customRecords), i = 0; i < keys.length; ++i)
                    if (typeof object.customRecords[keys[i]] === "string")
                        $util.base64.decode(object.customRecords[keys[i]], message.customRecords[keys[i]] = $util.newBuffer($util.base64.length(object.customRecords[keys[i]])), 0);
                    else if (object.customRecords[keys[i]].length)
                        message.customRecords[keys[i]] = object.customRecords[keys[i]];
            }
            if (object.onionBlob != null)
                if (typeof object.onionBlob === "string")
                    $util.base64.decode(object.onionBlob, message.onionBlob = $util.newBuffer($util.base64.length(object.onionBlob)), 0);
                else if (object.onionBlob.length)
                    message.onionBlob = object.onionBlob;
            return message;
        };

        /**
         * Creates a plain object from a ForwardHtlcInterceptRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @static
         * @param {routerrpc.ForwardHtlcInterceptRequest} message ForwardHtlcInterceptRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardHtlcInterceptRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.customRecords = {};
            if (options.defaults) {
                object.incomingCircuitKey = null;
                if (options.bytes === String)
                    object.paymentHash = "";
                else {
                    object.paymentHash = [];
                    if (options.bytes !== Array)
                        object.paymentHash = $util.newBuffer(object.paymentHash);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingAmountMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingAmountMsat = options.longs === String ? "0" : 0;
                object.outgoingExpiry = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.incomingAmountMsat = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.incomingAmountMsat = options.longs === String ? "0" : 0;
                object.incomingExpiry = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.outgoingRequestedChanId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.outgoingRequestedChanId = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.onionBlob = "";
                else {
                    object.onionBlob = [];
                    if (options.bytes !== Array)
                        object.onionBlob = $util.newBuffer(object.onionBlob);
                }
            }
            if (message.incomingCircuitKey != null && message.hasOwnProperty("incomingCircuitKey"))
                object.incomingCircuitKey = $root.routerrpc.CircuitKey.toObject(message.incomingCircuitKey, options);
            if (message.paymentHash != null && message.hasOwnProperty("paymentHash"))
                object.paymentHash = options.bytes === String ? $util.base64.encode(message.paymentHash, 0, message.paymentHash.length) : options.bytes === Array ? Array.prototype.slice.call(message.paymentHash) : message.paymentHash;
            if (message.outgoingAmountMsat != null && message.hasOwnProperty("outgoingAmountMsat"))
                if (typeof message.outgoingAmountMsat === "number")
                    object.outgoingAmountMsat = options.longs === String ? String(message.outgoingAmountMsat) : message.outgoingAmountMsat;
                else
                    object.outgoingAmountMsat = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingAmountMsat) : options.longs === Number ? new $util.LongBits(message.outgoingAmountMsat.low >>> 0, message.outgoingAmountMsat.high >>> 0).toNumber(true) : message.outgoingAmountMsat;
            if (message.outgoingExpiry != null && message.hasOwnProperty("outgoingExpiry"))
                object.outgoingExpiry = message.outgoingExpiry;
            if (message.incomingAmountMsat != null && message.hasOwnProperty("incomingAmountMsat"))
                if (typeof message.incomingAmountMsat === "number")
                    object.incomingAmountMsat = options.longs === String ? String(message.incomingAmountMsat) : message.incomingAmountMsat;
                else
                    object.incomingAmountMsat = options.longs === String ? $util.Long.prototype.toString.call(message.incomingAmountMsat) : options.longs === Number ? new $util.LongBits(message.incomingAmountMsat.low >>> 0, message.incomingAmountMsat.high >>> 0).toNumber(true) : message.incomingAmountMsat;
            if (message.incomingExpiry != null && message.hasOwnProperty("incomingExpiry"))
                object.incomingExpiry = message.incomingExpiry;
            if (message.outgoingRequestedChanId != null && message.hasOwnProperty("outgoingRequestedChanId"))
                if (typeof message.outgoingRequestedChanId === "number")
                    object.outgoingRequestedChanId = options.longs === String ? String(message.outgoingRequestedChanId) : message.outgoingRequestedChanId;
                else
                    object.outgoingRequestedChanId = options.longs === String ? $util.Long.prototype.toString.call(message.outgoingRequestedChanId) : options.longs === Number ? new $util.LongBits(message.outgoingRequestedChanId.low >>> 0, message.outgoingRequestedChanId.high >>> 0).toNumber(true) : message.outgoingRequestedChanId;
            let keys2;
            if (message.customRecords && (keys2 = Object.keys(message.customRecords)).length) {
                object.customRecords = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.customRecords[keys2[j]] = options.bytes === String ? $util.base64.encode(message.customRecords[keys2[j]], 0, message.customRecords[keys2[j]].length) : options.bytes === Array ? Array.prototype.slice.call(message.customRecords[keys2[j]]) : message.customRecords[keys2[j]];
            }
            if (message.onionBlob != null && message.hasOwnProperty("onionBlob"))
                object.onionBlob = options.bytes === String ? $util.base64.encode(message.onionBlob, 0, message.onionBlob.length) : options.bytes === Array ? Array.prototype.slice.call(message.onionBlob) : message.onionBlob;
            return object;
        };

        /**
         * Converts this ForwardHtlcInterceptRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.ForwardHtlcInterceptRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardHtlcInterceptRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardHtlcInterceptRequest;
    })();

    routerrpc.ForwardHtlcInterceptResponse = (function() {

        /**
         * Properties of a ForwardHtlcInterceptResponse.
         * @memberof routerrpc
         * @interface IForwardHtlcInterceptResponse
         * @property {routerrpc.ICircuitKey|null} [incomingCircuitKey] The key of this forwarded htlc. It defines the incoming channel id and
         * the index in this channel.
         * @property {routerrpc.ResolveHoldForwardAction|null} [action] ForwardHtlcInterceptResponse action
         * @property {Uint8Array|null} [preimage] ForwardHtlcInterceptResponse preimage
         * @property {Uint8Array|null} [failureMessage] ForwardHtlcInterceptResponse failureMessage
         * @property {lnrpc.Failure.FailureCode|null} [failureCode] ForwardHtlcInterceptResponse failureCode
         */

        /**
         * Constructs a new ForwardHtlcInterceptResponse.
         * @memberof routerrpc
         * @classdesc ForwardHtlcInterceptResponse enables the caller to resolve a previously hold
         * forward. The caller can choose either to:
         * - `Resume`: Execute the default behavior (usually forward).
         * - `Reject`: Fail the htlc backwards.
         * - `Settle`: Settle this htlc with a given preimage.
         * @implements IForwardHtlcInterceptResponse
         * @constructor
         * @param {routerrpc.IForwardHtlcInterceptResponse=} [properties] Properties to set
         */
        function ForwardHtlcInterceptResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * The key of this forwarded htlc. It defines the incoming channel id and
         * the index in this channel.
         * @member {routerrpc.ICircuitKey|null|undefined} incomingCircuitKey
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @instance
         */
        ForwardHtlcInterceptResponse.prototype.incomingCircuitKey = null;

        /**
         * ForwardHtlcInterceptResponse action.
         * @member {routerrpc.ResolveHoldForwardAction} action
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @instance
         */
        ForwardHtlcInterceptResponse.prototype.action = 0;

        /**
         * ForwardHtlcInterceptResponse preimage.
         * @member {Uint8Array} preimage
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @instance
         */
        ForwardHtlcInterceptResponse.prototype.preimage = $util.newBuffer([]);

        /**
         * ForwardHtlcInterceptResponse failureMessage.
         * @member {Uint8Array} failureMessage
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @instance
         */
        ForwardHtlcInterceptResponse.prototype.failureMessage = $util.newBuffer([]);

        /**
         * ForwardHtlcInterceptResponse failureCode.
         * @member {lnrpc.Failure.FailureCode} failureCode
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @instance
         */
        ForwardHtlcInterceptResponse.prototype.failureCode = 0;

        /**
         * Creates a new ForwardHtlcInterceptResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {routerrpc.IForwardHtlcInterceptResponse=} [properties] Properties to set
         * @returns {routerrpc.ForwardHtlcInterceptResponse} ForwardHtlcInterceptResponse instance
         */
        ForwardHtlcInterceptResponse.create = function create(properties) {
            return new ForwardHtlcInterceptResponse(properties);
        };

        /**
         * Encodes the specified ForwardHtlcInterceptResponse message. Does not implicitly {@link routerrpc.ForwardHtlcInterceptResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {routerrpc.IForwardHtlcInterceptResponse} message ForwardHtlcInterceptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardHtlcInterceptResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.incomingCircuitKey != null && Object.hasOwnProperty.call(message, "incomingCircuitKey"))
                $root.routerrpc.CircuitKey.encode(message.incomingCircuitKey, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.action);
            if (message.preimage != null && Object.hasOwnProperty.call(message, "preimage"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.preimage);
            if (message.failureMessage != null && Object.hasOwnProperty.call(message, "failureMessage"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.failureMessage);
            if (message.failureCode != null && Object.hasOwnProperty.call(message, "failureCode"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.failureCode);
            return writer;
        };

        /**
         * Encodes the specified ForwardHtlcInterceptResponse message, length delimited. Does not implicitly {@link routerrpc.ForwardHtlcInterceptResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {routerrpc.IForwardHtlcInterceptResponse} message ForwardHtlcInterceptResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ForwardHtlcInterceptResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ForwardHtlcInterceptResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.ForwardHtlcInterceptResponse} ForwardHtlcInterceptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardHtlcInterceptResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.ForwardHtlcInterceptResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.incomingCircuitKey = $root.routerrpc.CircuitKey.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.action = reader.int32();
                        break;
                    }
                case 3: {
                        message.preimage = reader.bytes();
                        break;
                    }
                case 4: {
                        message.failureMessage = reader.bytes();
                        break;
                    }
                case 5: {
                        message.failureCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ForwardHtlcInterceptResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.ForwardHtlcInterceptResponse} ForwardHtlcInterceptResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ForwardHtlcInterceptResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ForwardHtlcInterceptResponse message.
         * @function verify
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ForwardHtlcInterceptResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.incomingCircuitKey != null && message.hasOwnProperty("incomingCircuitKey")) {
                let error = $root.routerrpc.CircuitKey.verify(message.incomingCircuitKey);
                if (error)
                    return "incomingCircuitKey." + error;
            }
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                if (!(message.preimage && typeof message.preimage.length === "number" || $util.isString(message.preimage)))
                    return "preimage: buffer expected";
            if (message.failureMessage != null && message.hasOwnProperty("failureMessage"))
                if (!(message.failureMessage && typeof message.failureMessage.length === "number" || $util.isString(message.failureMessage)))
                    return "failureMessage: buffer expected";
            if (message.failureCode != null && message.hasOwnProperty("failureCode"))
                switch (message.failureCode) {
                default:
                    return "failureCode: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 997:
                case 998:
                case 999:
                    break;
                }
            return null;
        };

        /**
         * Creates a ForwardHtlcInterceptResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.ForwardHtlcInterceptResponse} ForwardHtlcInterceptResponse
         */
        ForwardHtlcInterceptResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.ForwardHtlcInterceptResponse)
                return object;
            let message = new $root.routerrpc.ForwardHtlcInterceptResponse();
            if (object.incomingCircuitKey != null) {
                if (typeof object.incomingCircuitKey !== "object")
                    throw TypeError(".routerrpc.ForwardHtlcInterceptResponse.incomingCircuitKey: object expected");
                message.incomingCircuitKey = $root.routerrpc.CircuitKey.fromObject(object.incomingCircuitKey);
            }
            switch (object.action) {
            case "SETTLE":
            case 0:
                message.action = 0;
                break;
            case "FAIL":
            case 1:
                message.action = 1;
                break;
            case "RESUME":
            case 2:
                message.action = 2;
                break;
            }
            if (object.preimage != null)
                if (typeof object.preimage === "string")
                    $util.base64.decode(object.preimage, message.preimage = $util.newBuffer($util.base64.length(object.preimage)), 0);
                else if (object.preimage.length)
                    message.preimage = object.preimage;
            if (object.failureMessage != null)
                if (typeof object.failureMessage === "string")
                    $util.base64.decode(object.failureMessage, message.failureMessage = $util.newBuffer($util.base64.length(object.failureMessage)), 0);
                else if (object.failureMessage.length)
                    message.failureMessage = object.failureMessage;
            switch (object.failureCode) {
            case "RESERVED":
            case 0:
                message.failureCode = 0;
                break;
            case "INCORRECT_OR_UNKNOWN_PAYMENT_DETAILS":
            case 1:
                message.failureCode = 1;
                break;
            case "INCORRECT_PAYMENT_AMOUNT":
            case 2:
                message.failureCode = 2;
                break;
            case "FINAL_INCORRECT_CLTV_EXPIRY":
            case 3:
                message.failureCode = 3;
                break;
            case "FINAL_INCORRECT_HTLC_AMOUNT":
            case 4:
                message.failureCode = 4;
                break;
            case "FINAL_EXPIRY_TOO_SOON":
            case 5:
                message.failureCode = 5;
                break;
            case "INVALID_REALM":
            case 6:
                message.failureCode = 6;
                break;
            case "EXPIRY_TOO_SOON":
            case 7:
                message.failureCode = 7;
                break;
            case "INVALID_ONION_VERSION":
            case 8:
                message.failureCode = 8;
                break;
            case "INVALID_ONION_HMAC":
            case 9:
                message.failureCode = 9;
                break;
            case "INVALID_ONION_KEY":
            case 10:
                message.failureCode = 10;
                break;
            case "AMOUNT_BELOW_MINIMUM":
            case 11:
                message.failureCode = 11;
                break;
            case "FEE_INSUFFICIENT":
            case 12:
                message.failureCode = 12;
                break;
            case "INCORRECT_CLTV_EXPIRY":
            case 13:
                message.failureCode = 13;
                break;
            case "CHANNEL_DISABLED":
            case 14:
                message.failureCode = 14;
                break;
            case "TEMPORARY_CHANNEL_FAILURE":
            case 15:
                message.failureCode = 15;
                break;
            case "REQUIRED_NODE_FEATURE_MISSING":
            case 16:
                message.failureCode = 16;
                break;
            case "REQUIRED_CHANNEL_FEATURE_MISSING":
            case 17:
                message.failureCode = 17;
                break;
            case "UNKNOWN_NEXT_PEER":
            case 18:
                message.failureCode = 18;
                break;
            case "TEMPORARY_NODE_FAILURE":
            case 19:
                message.failureCode = 19;
                break;
            case "PERMANENT_NODE_FAILURE":
            case 20:
                message.failureCode = 20;
                break;
            case "PERMANENT_CHANNEL_FAILURE":
            case 21:
                message.failureCode = 21;
                break;
            case "EXPIRY_TOO_FAR":
            case 22:
                message.failureCode = 22;
                break;
            case "MPP_TIMEOUT":
            case 23:
                message.failureCode = 23;
                break;
            case "INVALID_ONION_PAYLOAD":
            case 24:
                message.failureCode = 24;
                break;
            case "INTERNAL_FAILURE":
            case 997:
                message.failureCode = 997;
                break;
            case "UNKNOWN_FAILURE":
            case 998:
                message.failureCode = 998;
                break;
            case "UNREADABLE_FAILURE":
            case 999:
                message.failureCode = 999;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ForwardHtlcInterceptResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @static
         * @param {routerrpc.ForwardHtlcInterceptResponse} message ForwardHtlcInterceptResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ForwardHtlcInterceptResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.incomingCircuitKey = null;
                object.action = options.enums === String ? "SETTLE" : 0;
                if (options.bytes === String)
                    object.preimage = "";
                else {
                    object.preimage = [];
                    if (options.bytes !== Array)
                        object.preimage = $util.newBuffer(object.preimage);
                }
                if (options.bytes === String)
                    object.failureMessage = "";
                else {
                    object.failureMessage = [];
                    if (options.bytes !== Array)
                        object.failureMessage = $util.newBuffer(object.failureMessage);
                }
                object.failureCode = options.enums === String ? "RESERVED" : 0;
            }
            if (message.incomingCircuitKey != null && message.hasOwnProperty("incomingCircuitKey"))
                object.incomingCircuitKey = $root.routerrpc.CircuitKey.toObject(message.incomingCircuitKey, options);
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = options.enums === String ? $root.routerrpc.ResolveHoldForwardAction[message.action] : message.action;
            if (message.preimage != null && message.hasOwnProperty("preimage"))
                object.preimage = options.bytes === String ? $util.base64.encode(message.preimage, 0, message.preimage.length) : options.bytes === Array ? Array.prototype.slice.call(message.preimage) : message.preimage;
            if (message.failureMessage != null && message.hasOwnProperty("failureMessage"))
                object.failureMessage = options.bytes === String ? $util.base64.encode(message.failureMessage, 0, message.failureMessage.length) : options.bytes === Array ? Array.prototype.slice.call(message.failureMessage) : message.failureMessage;
            if (message.failureCode != null && message.hasOwnProperty("failureCode"))
                object.failureCode = options.enums === String ? $root.lnrpc.Failure.FailureCode[message.failureCode] : message.failureCode;
            return object;
        };

        /**
         * Converts this ForwardHtlcInterceptResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.ForwardHtlcInterceptResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ForwardHtlcInterceptResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ForwardHtlcInterceptResponse;
    })();

    /**
     * ResolveHoldForwardAction enum.
     * @name routerrpc.ResolveHoldForwardAction
     * @enum {number}
     * @property {number} SETTLE=0 SETTLE value
     * @property {number} FAIL=1 FAIL value
     * @property {number} RESUME=2 RESUME value
     */
    routerrpc.ResolveHoldForwardAction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SETTLE"] = 0;
        values[valuesById[1] = "FAIL"] = 1;
        values[valuesById[2] = "RESUME"] = 2;
        return values;
    })();

    routerrpc.UpdateChanStatusRequest = (function() {

        /**
         * Properties of an UpdateChanStatusRequest.
         * @memberof routerrpc
         * @interface IUpdateChanStatusRequest
         * @property {lnrpc.IChannelPoint|null} [chanPoint] UpdateChanStatusRequest chanPoint
         * @property {routerrpc.ChanStatusAction|null} [action] UpdateChanStatusRequest action
         */

        /**
         * Constructs a new UpdateChanStatusRequest.
         * @memberof routerrpc
         * @classdesc Represents an UpdateChanStatusRequest.
         * @implements IUpdateChanStatusRequest
         * @constructor
         * @param {routerrpc.IUpdateChanStatusRequest=} [properties] Properties to set
         */
        function UpdateChanStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateChanStatusRequest chanPoint.
         * @member {lnrpc.IChannelPoint|null|undefined} chanPoint
         * @memberof routerrpc.UpdateChanStatusRequest
         * @instance
         */
        UpdateChanStatusRequest.prototype.chanPoint = null;

        /**
         * UpdateChanStatusRequest action.
         * @member {routerrpc.ChanStatusAction} action
         * @memberof routerrpc.UpdateChanStatusRequest
         * @instance
         */
        UpdateChanStatusRequest.prototype.action = 0;

        /**
         * Creates a new UpdateChanStatusRequest instance using the specified properties.
         * @function create
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {routerrpc.IUpdateChanStatusRequest=} [properties] Properties to set
         * @returns {routerrpc.UpdateChanStatusRequest} UpdateChanStatusRequest instance
         */
        UpdateChanStatusRequest.create = function create(properties) {
            return new UpdateChanStatusRequest(properties);
        };

        /**
         * Encodes the specified UpdateChanStatusRequest message. Does not implicitly {@link routerrpc.UpdateChanStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {routerrpc.IUpdateChanStatusRequest} message UpdateChanStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateChanStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chanPoint != null && Object.hasOwnProperty.call(message, "chanPoint"))
                $root.lnrpc.ChannelPoint.encode(message.chanPoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.action);
            return writer;
        };

        /**
         * Encodes the specified UpdateChanStatusRequest message, length delimited. Does not implicitly {@link routerrpc.UpdateChanStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {routerrpc.IUpdateChanStatusRequest} message UpdateChanStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateChanStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateChanStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.UpdateChanStatusRequest} UpdateChanStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateChanStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.UpdateChanStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.chanPoint = $root.lnrpc.ChannelPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.action = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateChanStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.UpdateChanStatusRequest} UpdateChanStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateChanStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateChanStatusRequest message.
         * @function verify
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateChanStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint")) {
                let error = $root.lnrpc.ChannelPoint.verify(message.chanPoint);
                if (error)
                    return "chanPoint." + error;
            }
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates an UpdateChanStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.UpdateChanStatusRequest} UpdateChanStatusRequest
         */
        UpdateChanStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.UpdateChanStatusRequest)
                return object;
            let message = new $root.routerrpc.UpdateChanStatusRequest();
            if (object.chanPoint != null) {
                if (typeof object.chanPoint !== "object")
                    throw TypeError(".routerrpc.UpdateChanStatusRequest.chanPoint: object expected");
                message.chanPoint = $root.lnrpc.ChannelPoint.fromObject(object.chanPoint);
            }
            switch (object.action) {
            case "ENABLE":
            case 0:
                message.action = 0;
                break;
            case "DISABLE":
            case 1:
                message.action = 1;
                break;
            case "AUTO":
            case 2:
                message.action = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateChanStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.UpdateChanStatusRequest
         * @static
         * @param {routerrpc.UpdateChanStatusRequest} message UpdateChanStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateChanStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.chanPoint = null;
                object.action = options.enums === String ? "ENABLE" : 0;
            }
            if (message.chanPoint != null && message.hasOwnProperty("chanPoint"))
                object.chanPoint = $root.lnrpc.ChannelPoint.toObject(message.chanPoint, options);
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = options.enums === String ? $root.routerrpc.ChanStatusAction[message.action] : message.action;
            return object;
        };

        /**
         * Converts this UpdateChanStatusRequest to JSON.
         * @function toJSON
         * @memberof routerrpc.UpdateChanStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateChanStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateChanStatusRequest;
    })();

    /**
     * ChanStatusAction enum.
     * @name routerrpc.ChanStatusAction
     * @enum {number}
     * @property {number} ENABLE=0 ENABLE value
     * @property {number} DISABLE=1 DISABLE value
     * @property {number} AUTO=2 AUTO value
     */
    routerrpc.ChanStatusAction = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ENABLE"] = 0;
        values[valuesById[1] = "DISABLE"] = 1;
        values[valuesById[2] = "AUTO"] = 2;
        return values;
    })();

    routerrpc.UpdateChanStatusResponse = (function() {

        /**
         * Properties of an UpdateChanStatusResponse.
         * @memberof routerrpc
         * @interface IUpdateChanStatusResponse
         */

        /**
         * Constructs a new UpdateChanStatusResponse.
         * @memberof routerrpc
         * @classdesc Represents an UpdateChanStatusResponse.
         * @implements IUpdateChanStatusResponse
         * @constructor
         * @param {routerrpc.IUpdateChanStatusResponse=} [properties] Properties to set
         */
        function UpdateChanStatusResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateChanStatusResponse instance using the specified properties.
         * @function create
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {routerrpc.IUpdateChanStatusResponse=} [properties] Properties to set
         * @returns {routerrpc.UpdateChanStatusResponse} UpdateChanStatusResponse instance
         */
        UpdateChanStatusResponse.create = function create(properties) {
            return new UpdateChanStatusResponse(properties);
        };

        /**
         * Encodes the specified UpdateChanStatusResponse message. Does not implicitly {@link routerrpc.UpdateChanStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {routerrpc.IUpdateChanStatusResponse} message UpdateChanStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateChanStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateChanStatusResponse message, length delimited. Does not implicitly {@link routerrpc.UpdateChanStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {routerrpc.IUpdateChanStatusResponse} message UpdateChanStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateChanStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateChanStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {routerrpc.UpdateChanStatusResponse} UpdateChanStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateChanStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.routerrpc.UpdateChanStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateChanStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {routerrpc.UpdateChanStatusResponse} UpdateChanStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateChanStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateChanStatusResponse message.
         * @function verify
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateChanStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateChanStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {routerrpc.UpdateChanStatusResponse} UpdateChanStatusResponse
         */
        UpdateChanStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.routerrpc.UpdateChanStatusResponse)
                return object;
            return new $root.routerrpc.UpdateChanStatusResponse();
        };

        /**
         * Creates a plain object from an UpdateChanStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof routerrpc.UpdateChanStatusResponse
         * @static
         * @param {routerrpc.UpdateChanStatusResponse} message UpdateChanStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateChanStatusResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateChanStatusResponse to JSON.
         * @function toJSON
         * @memberof routerrpc.UpdateChanStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateChanStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateChanStatusResponse;
    })();

    return routerrpc;
})();

export const signrpc = $root.signrpc = (() => {

    /**
     * Namespace signrpc.
     * @exports signrpc
     * @namespace
     */
    const signrpc = {};

    signrpc.Signer = (function() {

        /**
         * Constructs a new Signer service.
         * @memberof signrpc
         * @classdesc Represents a Signer
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Signer(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Signer.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Signer;

        /**
         * Creates new Signer service using the specified rpc implementation.
         * @function create
         * @memberof signrpc.Signer
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Signer} RPC service. Useful where requests and/or responses are streamed.
         */
        Signer.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link signrpc.Signer#signOutputRaw}.
         * @memberof signrpc.Signer
         * @typedef SignOutputRawCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.SignResp} [response] SignResp
         */

        /**
         * Calls SignOutputRaw.
         * @function signOutputRaw
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISignReq} request SignReq message or plain object
         * @param {signrpc.Signer.SignOutputRawCallback} callback Node-style callback called with the error, if any, and SignResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.signOutputRaw = function signOutputRaw(request, callback) {
            return this.rpcCall(signOutputRaw, $root.signrpc.SignReq, $root.signrpc.SignResp, request, callback);
        }, "name", { value: "SignOutputRaw" });

        /**
         * Calls SignOutputRaw.
         * @function signOutputRaw
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISignReq} request SignReq message or plain object
         * @returns {Promise<signrpc.SignResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#computeInputScript}.
         * @memberof signrpc.Signer
         * @typedef ComputeInputScriptCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.InputScriptResp} [response] InputScriptResp
         */

        /**
         * Calls ComputeInputScript.
         * @function computeInputScript
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISignReq} request SignReq message or plain object
         * @param {signrpc.Signer.ComputeInputScriptCallback} callback Node-style callback called with the error, if any, and InputScriptResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.computeInputScript = function computeInputScript(request, callback) {
            return this.rpcCall(computeInputScript, $root.signrpc.SignReq, $root.signrpc.InputScriptResp, request, callback);
        }, "name", { value: "ComputeInputScript" });

        /**
         * Calls ComputeInputScript.
         * @function computeInputScript
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISignReq} request SignReq message or plain object
         * @returns {Promise<signrpc.InputScriptResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#signMessage}.
         * @memberof signrpc.Signer
         * @typedef SignMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.SignMessageResp} [response] SignMessageResp
         */

        /**
         * Calls SignMessage.
         * @function signMessage
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISignMessageReq} request SignMessageReq message or plain object
         * @param {signrpc.Signer.SignMessageCallback} callback Node-style callback called with the error, if any, and SignMessageResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.signMessage = function signMessage(request, callback) {
            return this.rpcCall(signMessage, $root.signrpc.SignMessageReq, $root.signrpc.SignMessageResp, request, callback);
        }, "name", { value: "SignMessage" });

        /**
         * Calls SignMessage.
         * @function signMessage
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISignMessageReq} request SignMessageReq message or plain object
         * @returns {Promise<signrpc.SignMessageResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#verifyMessage}.
         * @memberof signrpc.Signer
         * @typedef VerifyMessageCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.VerifyMessageResp} [response] VerifyMessageResp
         */

        /**
         * Calls VerifyMessage.
         * @function verifyMessage
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IVerifyMessageReq} request VerifyMessageReq message or plain object
         * @param {signrpc.Signer.VerifyMessageCallback} callback Node-style callback called with the error, if any, and VerifyMessageResp
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.verifyMessage = function verifyMessage(request, callback) {
            return this.rpcCall(verifyMessage, $root.signrpc.VerifyMessageReq, $root.signrpc.VerifyMessageResp, request, callback);
        }, "name", { value: "VerifyMessage" });

        /**
         * Calls VerifyMessage.
         * @function verifyMessage
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IVerifyMessageReq} request VerifyMessageReq message or plain object
         * @returns {Promise<signrpc.VerifyMessageResp>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#deriveSharedKey}.
         * @memberof signrpc.Signer
         * @typedef DeriveSharedKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.SharedKeyResponse} [response] SharedKeyResponse
         */

        /**
         * Calls DeriveSharedKey.
         * @function deriveSharedKey
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISharedKeyRequest} request SharedKeyRequest message or plain object
         * @param {signrpc.Signer.DeriveSharedKeyCallback} callback Node-style callback called with the error, if any, and SharedKeyResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.deriveSharedKey = function deriveSharedKey(request, callback) {
            return this.rpcCall(deriveSharedKey, $root.signrpc.SharedKeyRequest, $root.signrpc.SharedKeyResponse, request, callback);
        }, "name", { value: "DeriveSharedKey" });

        /**
         * Calls DeriveSharedKey.
         * @function deriveSharedKey
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.ISharedKeyRequest} request SharedKeyRequest message or plain object
         * @returns {Promise<signrpc.SharedKeyResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#muSig2CombineKeys}.
         * @memberof signrpc.Signer
         * @typedef MuSig2CombineKeysCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.MuSig2CombineKeysResponse} [response] MuSig2CombineKeysResponse
         */

        /**
         * Calls MuSig2CombineKeys.
         * @function muSig2CombineKeys
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2CombineKeysRequest} request MuSig2CombineKeysRequest message or plain object
         * @param {signrpc.Signer.MuSig2CombineKeysCallback} callback Node-style callback called with the error, if any, and MuSig2CombineKeysResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.muSig2CombineKeys = function muSig2CombineKeys(request, callback) {
            return this.rpcCall(muSig2CombineKeys, $root.signrpc.MuSig2CombineKeysRequest, $root.signrpc.MuSig2CombineKeysResponse, request, callback);
        }, "name", { value: "MuSig2CombineKeys" });

        /**
         * Calls MuSig2CombineKeys.
         * @function muSig2CombineKeys
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2CombineKeysRequest} request MuSig2CombineKeysRequest message or plain object
         * @returns {Promise<signrpc.MuSig2CombineKeysResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#muSig2CreateSession}.
         * @memberof signrpc.Signer
         * @typedef MuSig2CreateSessionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.MuSig2SessionResponse} [response] MuSig2SessionResponse
         */

        /**
         * Calls MuSig2CreateSession.
         * @function muSig2CreateSession
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2SessionRequest} request MuSig2SessionRequest message or plain object
         * @param {signrpc.Signer.MuSig2CreateSessionCallback} callback Node-style callback called with the error, if any, and MuSig2SessionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.muSig2CreateSession = function muSig2CreateSession(request, callback) {
            return this.rpcCall(muSig2CreateSession, $root.signrpc.MuSig2SessionRequest, $root.signrpc.MuSig2SessionResponse, request, callback);
        }, "name", { value: "MuSig2CreateSession" });

        /**
         * Calls MuSig2CreateSession.
         * @function muSig2CreateSession
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2SessionRequest} request MuSig2SessionRequest message or plain object
         * @returns {Promise<signrpc.MuSig2SessionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#muSig2RegisterNonces}.
         * @memberof signrpc.Signer
         * @typedef MuSig2RegisterNoncesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.MuSig2RegisterNoncesResponse} [response] MuSig2RegisterNoncesResponse
         */

        /**
         * Calls MuSig2RegisterNonces.
         * @function muSig2RegisterNonces
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2RegisterNoncesRequest} request MuSig2RegisterNoncesRequest message or plain object
         * @param {signrpc.Signer.MuSig2RegisterNoncesCallback} callback Node-style callback called with the error, if any, and MuSig2RegisterNoncesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.muSig2RegisterNonces = function muSig2RegisterNonces(request, callback) {
            return this.rpcCall(muSig2RegisterNonces, $root.signrpc.MuSig2RegisterNoncesRequest, $root.signrpc.MuSig2RegisterNoncesResponse, request, callback);
        }, "name", { value: "MuSig2RegisterNonces" });

        /**
         * Calls MuSig2RegisterNonces.
         * @function muSig2RegisterNonces
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2RegisterNoncesRequest} request MuSig2RegisterNoncesRequest message or plain object
         * @returns {Promise<signrpc.MuSig2RegisterNoncesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#muSig2Sign}.
         * @memberof signrpc.Signer
         * @typedef MuSig2SignCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.MuSig2SignResponse} [response] MuSig2SignResponse
         */

        /**
         * Calls MuSig2Sign.
         * @function muSig2Sign
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2SignRequest} request MuSig2SignRequest message or plain object
         * @param {signrpc.Signer.MuSig2SignCallback} callback Node-style callback called with the error, if any, and MuSig2SignResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.muSig2Sign = function muSig2Sign(request, callback) {
            return this.rpcCall(muSig2Sign, $root.signrpc.MuSig2SignRequest, $root.signrpc.MuSig2SignResponse, request, callback);
        }, "name", { value: "MuSig2Sign" });

        /**
         * Calls MuSig2Sign.
         * @function muSig2Sign
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2SignRequest} request MuSig2SignRequest message or plain object
         * @returns {Promise<signrpc.MuSig2SignResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#muSig2CombineSig}.
         * @memberof signrpc.Signer
         * @typedef MuSig2CombineSigCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.MuSig2CombineSigResponse} [response] MuSig2CombineSigResponse
         */

        /**
         * Calls MuSig2CombineSig.
         * @function muSig2CombineSig
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2CombineSigRequest} request MuSig2CombineSigRequest message or plain object
         * @param {signrpc.Signer.MuSig2CombineSigCallback} callback Node-style callback called with the error, if any, and MuSig2CombineSigResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.muSig2CombineSig = function muSig2CombineSig(request, callback) {
            return this.rpcCall(muSig2CombineSig, $root.signrpc.MuSig2CombineSigRequest, $root.signrpc.MuSig2CombineSigResponse, request, callback);
        }, "name", { value: "MuSig2CombineSig" });

        /**
         * Calls MuSig2CombineSig.
         * @function muSig2CombineSig
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2CombineSigRequest} request MuSig2CombineSigRequest message or plain object
         * @returns {Promise<signrpc.MuSig2CombineSigResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link signrpc.Signer#muSig2Cleanup}.
         * @memberof signrpc.Signer
         * @typedef MuSig2CleanupCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.MuSig2CleanupResponse} [response] MuSig2CleanupResponse
         */

        /**
         * Calls MuSig2Cleanup.
         * @function muSig2Cleanup
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2CleanupRequest} request MuSig2CleanupRequest message or plain object
         * @param {signrpc.Signer.MuSig2CleanupCallback} callback Node-style callback called with the error, if any, and MuSig2CleanupResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Signer.prototype.muSig2Cleanup = function muSig2Cleanup(request, callback) {
            return this.rpcCall(muSig2Cleanup, $root.signrpc.MuSig2CleanupRequest, $root.signrpc.MuSig2CleanupResponse, request, callback);
        }, "name", { value: "MuSig2Cleanup" });

        /**
         * Calls MuSig2Cleanup.
         * @function muSig2Cleanup
         * @memberof signrpc.Signer
         * @instance
         * @param {signrpc.IMuSig2CleanupRequest} request MuSig2CleanupRequest message or plain object
         * @returns {Promise<signrpc.MuSig2CleanupResponse>} Promise
         * @variation 2
         */

        return Signer;
    })();

    signrpc.KeyLocator = (function() {

        /**
         * Properties of a KeyLocator.
         * @memberof signrpc
         * @interface IKeyLocator
         * @property {number|null} [keyFamily] KeyLocator keyFamily
         * @property {number|null} [keyIndex] KeyLocator keyIndex
         */

        /**
         * Constructs a new KeyLocator.
         * @memberof signrpc
         * @classdesc Represents a KeyLocator.
         * @implements IKeyLocator
         * @constructor
         * @param {signrpc.IKeyLocator=} [properties] Properties to set
         */
        function KeyLocator(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyLocator keyFamily.
         * @member {number} keyFamily
         * @memberof signrpc.KeyLocator
         * @instance
         */
        KeyLocator.prototype.keyFamily = 0;

        /**
         * KeyLocator keyIndex.
         * @member {number} keyIndex
         * @memberof signrpc.KeyLocator
         * @instance
         */
        KeyLocator.prototype.keyIndex = 0;

        /**
         * Creates a new KeyLocator instance using the specified properties.
         * @function create
         * @memberof signrpc.KeyLocator
         * @static
         * @param {signrpc.IKeyLocator=} [properties] Properties to set
         * @returns {signrpc.KeyLocator} KeyLocator instance
         */
        KeyLocator.create = function create(properties) {
            return new KeyLocator(properties);
        };

        /**
         * Encodes the specified KeyLocator message. Does not implicitly {@link signrpc.KeyLocator.verify|verify} messages.
         * @function encode
         * @memberof signrpc.KeyLocator
         * @static
         * @param {signrpc.IKeyLocator} message KeyLocator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyLocator.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keyFamily != null && Object.hasOwnProperty.call(message, "keyFamily"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.keyFamily);
            if (message.keyIndex != null && Object.hasOwnProperty.call(message, "keyIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.keyIndex);
            return writer;
        };

        /**
         * Encodes the specified KeyLocator message, length delimited. Does not implicitly {@link signrpc.KeyLocator.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.KeyLocator
         * @static
         * @param {signrpc.IKeyLocator} message KeyLocator message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyLocator.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeyLocator message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.KeyLocator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.KeyLocator} KeyLocator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyLocator.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.KeyLocator();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.keyFamily = reader.int32();
                        break;
                    }
                case 2: {
                        message.keyIndex = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeyLocator message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.KeyLocator
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.KeyLocator} KeyLocator
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyLocator.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeyLocator message.
         * @function verify
         * @memberof signrpc.KeyLocator
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeyLocator.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.keyFamily != null && message.hasOwnProperty("keyFamily"))
                if (!$util.isInteger(message.keyFamily))
                    return "keyFamily: integer expected";
            if (message.keyIndex != null && message.hasOwnProperty("keyIndex"))
                if (!$util.isInteger(message.keyIndex))
                    return "keyIndex: integer expected";
            return null;
        };

        /**
         * Creates a KeyLocator message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.KeyLocator
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.KeyLocator} KeyLocator
         */
        KeyLocator.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.KeyLocator)
                return object;
            let message = new $root.signrpc.KeyLocator();
            if (object.keyFamily != null)
                message.keyFamily = object.keyFamily | 0;
            if (object.keyIndex != null)
                message.keyIndex = object.keyIndex | 0;
            return message;
        };

        /**
         * Creates a plain object from a KeyLocator message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.KeyLocator
         * @static
         * @param {signrpc.KeyLocator} message KeyLocator
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeyLocator.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.keyFamily = 0;
                object.keyIndex = 0;
            }
            if (message.keyFamily != null && message.hasOwnProperty("keyFamily"))
                object.keyFamily = message.keyFamily;
            if (message.keyIndex != null && message.hasOwnProperty("keyIndex"))
                object.keyIndex = message.keyIndex;
            return object;
        };

        /**
         * Converts this KeyLocator to JSON.
         * @function toJSON
         * @memberof signrpc.KeyLocator
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeyLocator.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KeyLocator;
    })();

    signrpc.KeyDescriptor = (function() {

        /**
         * Properties of a KeyDescriptor.
         * @memberof signrpc
         * @interface IKeyDescriptor
         * @property {Uint8Array|null} [rawKeyBytes] KeyDescriptor rawKeyBytes
         * @property {signrpc.IKeyLocator|null} [keyLoc] KeyDescriptor keyLoc
         */

        /**
         * Constructs a new KeyDescriptor.
         * @memberof signrpc
         * @classdesc Represents a KeyDescriptor.
         * @implements IKeyDescriptor
         * @constructor
         * @param {signrpc.IKeyDescriptor=} [properties] Properties to set
         */
        function KeyDescriptor(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyDescriptor rawKeyBytes.
         * @member {Uint8Array} rawKeyBytes
         * @memberof signrpc.KeyDescriptor
         * @instance
         */
        KeyDescriptor.prototype.rawKeyBytes = $util.newBuffer([]);

        /**
         * KeyDescriptor keyLoc.
         * @member {signrpc.IKeyLocator|null|undefined} keyLoc
         * @memberof signrpc.KeyDescriptor
         * @instance
         */
        KeyDescriptor.prototype.keyLoc = null;

        /**
         * Creates a new KeyDescriptor instance using the specified properties.
         * @function create
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {signrpc.IKeyDescriptor=} [properties] Properties to set
         * @returns {signrpc.KeyDescriptor} KeyDescriptor instance
         */
        KeyDescriptor.create = function create(properties) {
            return new KeyDescriptor(properties);
        };

        /**
         * Encodes the specified KeyDescriptor message. Does not implicitly {@link signrpc.KeyDescriptor.verify|verify} messages.
         * @function encode
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {signrpc.IKeyDescriptor} message KeyDescriptor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyDescriptor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawKeyBytes != null && Object.hasOwnProperty.call(message, "rawKeyBytes"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rawKeyBytes);
            if (message.keyLoc != null && Object.hasOwnProperty.call(message, "keyLoc"))
                $root.signrpc.KeyLocator.encode(message.keyLoc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified KeyDescriptor message, length delimited. Does not implicitly {@link signrpc.KeyDescriptor.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {signrpc.IKeyDescriptor} message KeyDescriptor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyDescriptor.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeyDescriptor message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.KeyDescriptor} KeyDescriptor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyDescriptor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.KeyDescriptor();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rawKeyBytes = reader.bytes();
                        break;
                    }
                case 2: {
                        message.keyLoc = $root.signrpc.KeyLocator.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeyDescriptor message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.KeyDescriptor} KeyDescriptor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyDescriptor.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeyDescriptor message.
         * @function verify
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeyDescriptor.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawKeyBytes != null && message.hasOwnProperty("rawKeyBytes"))
                if (!(message.rawKeyBytes && typeof message.rawKeyBytes.length === "number" || $util.isString(message.rawKeyBytes)))
                    return "rawKeyBytes: buffer expected";
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc")) {
                let error = $root.signrpc.KeyLocator.verify(message.keyLoc);
                if (error)
                    return "keyLoc." + error;
            }
            return null;
        };

        /**
         * Creates a KeyDescriptor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.KeyDescriptor} KeyDescriptor
         */
        KeyDescriptor.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.KeyDescriptor)
                return object;
            let message = new $root.signrpc.KeyDescriptor();
            if (object.rawKeyBytes != null)
                if (typeof object.rawKeyBytes === "string")
                    $util.base64.decode(object.rawKeyBytes, message.rawKeyBytes = $util.newBuffer($util.base64.length(object.rawKeyBytes)), 0);
                else if (object.rawKeyBytes.length)
                    message.rawKeyBytes = object.rawKeyBytes;
            if (object.keyLoc != null) {
                if (typeof object.keyLoc !== "object")
                    throw TypeError(".signrpc.KeyDescriptor.keyLoc: object expected");
                message.keyLoc = $root.signrpc.KeyLocator.fromObject(object.keyLoc);
            }
            return message;
        };

        /**
         * Creates a plain object from a KeyDescriptor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.KeyDescriptor
         * @static
         * @param {signrpc.KeyDescriptor} message KeyDescriptor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeyDescriptor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.rawKeyBytes = "";
                else {
                    object.rawKeyBytes = [];
                    if (options.bytes !== Array)
                        object.rawKeyBytes = $util.newBuffer(object.rawKeyBytes);
                }
                object.keyLoc = null;
            }
            if (message.rawKeyBytes != null && message.hasOwnProperty("rawKeyBytes"))
                object.rawKeyBytes = options.bytes === String ? $util.base64.encode(message.rawKeyBytes, 0, message.rawKeyBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawKeyBytes) : message.rawKeyBytes;
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc"))
                object.keyLoc = $root.signrpc.KeyLocator.toObject(message.keyLoc, options);
            return object;
        };

        /**
         * Converts this KeyDescriptor to JSON.
         * @function toJSON
         * @memberof signrpc.KeyDescriptor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeyDescriptor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KeyDescriptor;
    })();

    signrpc.TxOut = (function() {

        /**
         * Properties of a TxOut.
         * @memberof signrpc
         * @interface ITxOut
         * @property {Long|null} [value] TxOut value
         * @property {Uint8Array|null} [pkScript] TxOut pkScript
         */

        /**
         * Constructs a new TxOut.
         * @memberof signrpc
         * @classdesc Represents a TxOut.
         * @implements ITxOut
         * @constructor
         * @param {signrpc.ITxOut=} [properties] Properties to set
         */
        function TxOut(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TxOut value.
         * @member {Long} value
         * @memberof signrpc.TxOut
         * @instance
         */
        TxOut.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TxOut pkScript.
         * @member {Uint8Array} pkScript
         * @memberof signrpc.TxOut
         * @instance
         */
        TxOut.prototype.pkScript = $util.newBuffer([]);

        /**
         * Creates a new TxOut instance using the specified properties.
         * @function create
         * @memberof signrpc.TxOut
         * @static
         * @param {signrpc.ITxOut=} [properties] Properties to set
         * @returns {signrpc.TxOut} TxOut instance
         */
        TxOut.create = function create(properties) {
            return new TxOut(properties);
        };

        /**
         * Encodes the specified TxOut message. Does not implicitly {@link signrpc.TxOut.verify|verify} messages.
         * @function encode
         * @memberof signrpc.TxOut
         * @static
         * @param {signrpc.ITxOut} message TxOut message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TxOut.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.value);
            if (message.pkScript != null && Object.hasOwnProperty.call(message, "pkScript"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.pkScript);
            return writer;
        };

        /**
         * Encodes the specified TxOut message, length delimited. Does not implicitly {@link signrpc.TxOut.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.TxOut
         * @static
         * @param {signrpc.ITxOut} message TxOut message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TxOut.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TxOut message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.TxOut
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.TxOut} TxOut
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TxOut.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.TxOut();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.value = reader.int64();
                        break;
                    }
                case 2: {
                        message.pkScript = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TxOut message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.TxOut
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.TxOut} TxOut
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TxOut.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TxOut message.
         * @function verify
         * @memberof signrpc.TxOut
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TxOut.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                    return "value: integer|Long expected";
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                if (!(message.pkScript && typeof message.pkScript.length === "number" || $util.isString(message.pkScript)))
                    return "pkScript: buffer expected";
            return null;
        };

        /**
         * Creates a TxOut message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.TxOut
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.TxOut} TxOut
         */
        TxOut.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.TxOut)
                return object;
            let message = new $root.signrpc.TxOut();
            if (object.value != null)
                if ($util.Long)
                    (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                else if (typeof object.value === "string")
                    message.value = parseInt(object.value, 10);
                else if (typeof object.value === "number")
                    message.value = object.value;
                else if (typeof object.value === "object")
                    message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
            if (object.pkScript != null)
                if (typeof object.pkScript === "string")
                    $util.base64.decode(object.pkScript, message.pkScript = $util.newBuffer($util.base64.length(object.pkScript)), 0);
                else if (object.pkScript.length)
                    message.pkScript = object.pkScript;
            return message;
        };

        /**
         * Creates a plain object from a TxOut message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.TxOut
         * @static
         * @param {signrpc.TxOut} message TxOut
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TxOut.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.value = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.pkScript = "";
                else {
                    object.pkScript = [];
                    if (options.bytes !== Array)
                        object.pkScript = $util.newBuffer(object.pkScript);
                }
            }
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value === "number")
                    object.value = options.longs === String ? String(message.value) : message.value;
                else
                    object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                object.pkScript = options.bytes === String ? $util.base64.encode(message.pkScript, 0, message.pkScript.length) : options.bytes === Array ? Array.prototype.slice.call(message.pkScript) : message.pkScript;
            return object;
        };

        /**
         * Converts this TxOut to JSON.
         * @function toJSON
         * @memberof signrpc.TxOut
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TxOut.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TxOut;
    })();

    /**
     * SignMethod enum.
     * @name signrpc.SignMethod
     * @enum {number}
     * @property {number} SIGN_METHOD_WITNESS_V0=0 SIGN_METHOD_WITNESS_V0 value
     * @property {number} SIGN_METHOD_TAPROOT_KEY_SPEND_BIP0086=1 SIGN_METHOD_TAPROOT_KEY_SPEND_BIP0086 value
     * @property {number} SIGN_METHOD_TAPROOT_KEY_SPEND=2 SIGN_METHOD_TAPROOT_KEY_SPEND value
     * @property {number} SIGN_METHOD_TAPROOT_SCRIPT_SPEND=3 SIGN_METHOD_TAPROOT_SCRIPT_SPEND value
     */
    signrpc.SignMethod = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "SIGN_METHOD_WITNESS_V0"] = 0;
        values[valuesById[1] = "SIGN_METHOD_TAPROOT_KEY_SPEND_BIP0086"] = 1;
        values[valuesById[2] = "SIGN_METHOD_TAPROOT_KEY_SPEND"] = 2;
        values[valuesById[3] = "SIGN_METHOD_TAPROOT_SCRIPT_SPEND"] = 3;
        return values;
    })();

    signrpc.SignDescriptor = (function() {

        /**
         * Properties of a SignDescriptor.
         * @memberof signrpc
         * @interface ISignDescriptor
         * @property {signrpc.IKeyDescriptor|null} [keyDesc] SignDescriptor keyDesc
         * @property {Uint8Array|null} [singleTweak] SignDescriptor singleTweak
         * @property {Uint8Array|null} [doubleTweak] SignDescriptor doubleTweak
         * @property {Uint8Array|null} [tapTweak] SignDescriptor tapTweak
         * @property {Uint8Array|null} [witnessScript] SignDescriptor witnessScript
         * @property {signrpc.ITxOut|null} [output] SignDescriptor output
         * @property {number|null} [sighash] SignDescriptor sighash
         * @property {number|null} [inputIndex] SignDescriptor inputIndex
         * @property {signrpc.SignMethod|null} [signMethod] SignDescriptor signMethod
         */

        /**
         * Constructs a new SignDescriptor.
         * @memberof signrpc
         * @classdesc Represents a SignDescriptor.
         * @implements ISignDescriptor
         * @constructor
         * @param {signrpc.ISignDescriptor=} [properties] Properties to set
         */
        function SignDescriptor(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignDescriptor keyDesc.
         * @member {signrpc.IKeyDescriptor|null|undefined} keyDesc
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.keyDesc = null;

        /**
         * SignDescriptor singleTweak.
         * @member {Uint8Array} singleTweak
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.singleTweak = $util.newBuffer([]);

        /**
         * SignDescriptor doubleTweak.
         * @member {Uint8Array} doubleTweak
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.doubleTweak = $util.newBuffer([]);

        /**
         * SignDescriptor tapTweak.
         * @member {Uint8Array} tapTweak
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.tapTweak = $util.newBuffer([]);

        /**
         * SignDescriptor witnessScript.
         * @member {Uint8Array} witnessScript
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.witnessScript = $util.newBuffer([]);

        /**
         * SignDescriptor output.
         * @member {signrpc.ITxOut|null|undefined} output
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.output = null;

        /**
         * SignDescriptor sighash.
         * @member {number} sighash
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.sighash = 0;

        /**
         * SignDescriptor inputIndex.
         * @member {number} inputIndex
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.inputIndex = 0;

        /**
         * SignDescriptor signMethod.
         * @member {signrpc.SignMethod} signMethod
         * @memberof signrpc.SignDescriptor
         * @instance
         */
        SignDescriptor.prototype.signMethod = 0;

        /**
         * Creates a new SignDescriptor instance using the specified properties.
         * @function create
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {signrpc.ISignDescriptor=} [properties] Properties to set
         * @returns {signrpc.SignDescriptor} SignDescriptor instance
         */
        SignDescriptor.create = function create(properties) {
            return new SignDescriptor(properties);
        };

        /**
         * Encodes the specified SignDescriptor message. Does not implicitly {@link signrpc.SignDescriptor.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {signrpc.ISignDescriptor} message SignDescriptor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignDescriptor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keyDesc != null && Object.hasOwnProperty.call(message, "keyDesc"))
                $root.signrpc.KeyDescriptor.encode(message.keyDesc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.singleTweak != null && Object.hasOwnProperty.call(message, "singleTweak"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.singleTweak);
            if (message.doubleTweak != null && Object.hasOwnProperty.call(message, "doubleTweak"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.doubleTweak);
            if (message.witnessScript != null && Object.hasOwnProperty.call(message, "witnessScript"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.witnessScript);
            if (message.output != null && Object.hasOwnProperty.call(message, "output"))
                $root.signrpc.TxOut.encode(message.output, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.sighash != null && Object.hasOwnProperty.call(message, "sighash"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.sighash);
            if (message.inputIndex != null && Object.hasOwnProperty.call(message, "inputIndex"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.inputIndex);
            if (message.signMethod != null && Object.hasOwnProperty.call(message, "signMethod"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.signMethod);
            if (message.tapTweak != null && Object.hasOwnProperty.call(message, "tapTweak"))
                writer.uint32(/* id 10, wireType 2 =*/82).bytes(message.tapTweak);
            return writer;
        };

        /**
         * Encodes the specified SignDescriptor message, length delimited. Does not implicitly {@link signrpc.SignDescriptor.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {signrpc.ISignDescriptor} message SignDescriptor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignDescriptor.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignDescriptor message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SignDescriptor} SignDescriptor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignDescriptor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SignDescriptor();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.keyDesc = $root.signrpc.KeyDescriptor.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.singleTweak = reader.bytes();
                        break;
                    }
                case 3: {
                        message.doubleTweak = reader.bytes();
                        break;
                    }
                case 10: {
                        message.tapTweak = reader.bytes();
                        break;
                    }
                case 4: {
                        message.witnessScript = reader.bytes();
                        break;
                    }
                case 5: {
                        message.output = $root.signrpc.TxOut.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.sighash = reader.uint32();
                        break;
                    }
                case 8: {
                        message.inputIndex = reader.int32();
                        break;
                    }
                case 9: {
                        message.signMethod = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignDescriptor message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SignDescriptor} SignDescriptor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignDescriptor.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignDescriptor message.
         * @function verify
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignDescriptor.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.keyDesc != null && message.hasOwnProperty("keyDesc")) {
                let error = $root.signrpc.KeyDescriptor.verify(message.keyDesc);
                if (error)
                    return "keyDesc." + error;
            }
            if (message.singleTweak != null && message.hasOwnProperty("singleTweak"))
                if (!(message.singleTweak && typeof message.singleTweak.length === "number" || $util.isString(message.singleTweak)))
                    return "singleTweak: buffer expected";
            if (message.doubleTweak != null && message.hasOwnProperty("doubleTweak"))
                if (!(message.doubleTweak && typeof message.doubleTweak.length === "number" || $util.isString(message.doubleTweak)))
                    return "doubleTweak: buffer expected";
            if (message.tapTweak != null && message.hasOwnProperty("tapTweak"))
                if (!(message.tapTweak && typeof message.tapTweak.length === "number" || $util.isString(message.tapTweak)))
                    return "tapTweak: buffer expected";
            if (message.witnessScript != null && message.hasOwnProperty("witnessScript"))
                if (!(message.witnessScript && typeof message.witnessScript.length === "number" || $util.isString(message.witnessScript)))
                    return "witnessScript: buffer expected";
            if (message.output != null && message.hasOwnProperty("output")) {
                let error = $root.signrpc.TxOut.verify(message.output);
                if (error)
                    return "output." + error;
            }
            if (message.sighash != null && message.hasOwnProperty("sighash"))
                if (!$util.isInteger(message.sighash))
                    return "sighash: integer expected";
            if (message.inputIndex != null && message.hasOwnProperty("inputIndex"))
                if (!$util.isInteger(message.inputIndex))
                    return "inputIndex: integer expected";
            if (message.signMethod != null && message.hasOwnProperty("signMethod"))
                switch (message.signMethod) {
                default:
                    return "signMethod: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a SignDescriptor message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SignDescriptor} SignDescriptor
         */
        SignDescriptor.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SignDescriptor)
                return object;
            let message = new $root.signrpc.SignDescriptor();
            if (object.keyDesc != null) {
                if (typeof object.keyDesc !== "object")
                    throw TypeError(".signrpc.SignDescriptor.keyDesc: object expected");
                message.keyDesc = $root.signrpc.KeyDescriptor.fromObject(object.keyDesc);
            }
            if (object.singleTweak != null)
                if (typeof object.singleTweak === "string")
                    $util.base64.decode(object.singleTweak, message.singleTweak = $util.newBuffer($util.base64.length(object.singleTweak)), 0);
                else if (object.singleTweak.length)
                    message.singleTweak = object.singleTweak;
            if (object.doubleTweak != null)
                if (typeof object.doubleTweak === "string")
                    $util.base64.decode(object.doubleTweak, message.doubleTweak = $util.newBuffer($util.base64.length(object.doubleTweak)), 0);
                else if (object.doubleTweak.length)
                    message.doubleTweak = object.doubleTweak;
            if (object.tapTweak != null)
                if (typeof object.tapTweak === "string")
                    $util.base64.decode(object.tapTweak, message.tapTweak = $util.newBuffer($util.base64.length(object.tapTweak)), 0);
                else if (object.tapTweak.length)
                    message.tapTweak = object.tapTweak;
            if (object.witnessScript != null)
                if (typeof object.witnessScript === "string")
                    $util.base64.decode(object.witnessScript, message.witnessScript = $util.newBuffer($util.base64.length(object.witnessScript)), 0);
                else if (object.witnessScript.length)
                    message.witnessScript = object.witnessScript;
            if (object.output != null) {
                if (typeof object.output !== "object")
                    throw TypeError(".signrpc.SignDescriptor.output: object expected");
                message.output = $root.signrpc.TxOut.fromObject(object.output);
            }
            if (object.sighash != null)
                message.sighash = object.sighash >>> 0;
            if (object.inputIndex != null)
                message.inputIndex = object.inputIndex | 0;
            switch (object.signMethod) {
            case "SIGN_METHOD_WITNESS_V0":
            case 0:
                message.signMethod = 0;
                break;
            case "SIGN_METHOD_TAPROOT_KEY_SPEND_BIP0086":
            case 1:
                message.signMethod = 1;
                break;
            case "SIGN_METHOD_TAPROOT_KEY_SPEND":
            case 2:
                message.signMethod = 2;
                break;
            case "SIGN_METHOD_TAPROOT_SCRIPT_SPEND":
            case 3:
                message.signMethod = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SignDescriptor message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SignDescriptor
         * @static
         * @param {signrpc.SignDescriptor} message SignDescriptor
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignDescriptor.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.keyDesc = null;
                if (options.bytes === String)
                    object.singleTweak = "";
                else {
                    object.singleTweak = [];
                    if (options.bytes !== Array)
                        object.singleTweak = $util.newBuffer(object.singleTweak);
                }
                if (options.bytes === String)
                    object.doubleTweak = "";
                else {
                    object.doubleTweak = [];
                    if (options.bytes !== Array)
                        object.doubleTweak = $util.newBuffer(object.doubleTweak);
                }
                if (options.bytes === String)
                    object.witnessScript = "";
                else {
                    object.witnessScript = [];
                    if (options.bytes !== Array)
                        object.witnessScript = $util.newBuffer(object.witnessScript);
                }
                object.output = null;
                object.sighash = 0;
                object.inputIndex = 0;
                object.signMethod = options.enums === String ? "SIGN_METHOD_WITNESS_V0" : 0;
                if (options.bytes === String)
                    object.tapTweak = "";
                else {
                    object.tapTweak = [];
                    if (options.bytes !== Array)
                        object.tapTweak = $util.newBuffer(object.tapTweak);
                }
            }
            if (message.keyDesc != null && message.hasOwnProperty("keyDesc"))
                object.keyDesc = $root.signrpc.KeyDescriptor.toObject(message.keyDesc, options);
            if (message.singleTweak != null && message.hasOwnProperty("singleTweak"))
                object.singleTweak = options.bytes === String ? $util.base64.encode(message.singleTweak, 0, message.singleTweak.length) : options.bytes === Array ? Array.prototype.slice.call(message.singleTweak) : message.singleTweak;
            if (message.doubleTweak != null && message.hasOwnProperty("doubleTweak"))
                object.doubleTweak = options.bytes === String ? $util.base64.encode(message.doubleTweak, 0, message.doubleTweak.length) : options.bytes === Array ? Array.prototype.slice.call(message.doubleTweak) : message.doubleTweak;
            if (message.witnessScript != null && message.hasOwnProperty("witnessScript"))
                object.witnessScript = options.bytes === String ? $util.base64.encode(message.witnessScript, 0, message.witnessScript.length) : options.bytes === Array ? Array.prototype.slice.call(message.witnessScript) : message.witnessScript;
            if (message.output != null && message.hasOwnProperty("output"))
                object.output = $root.signrpc.TxOut.toObject(message.output, options);
            if (message.sighash != null && message.hasOwnProperty("sighash"))
                object.sighash = message.sighash;
            if (message.inputIndex != null && message.hasOwnProperty("inputIndex"))
                object.inputIndex = message.inputIndex;
            if (message.signMethod != null && message.hasOwnProperty("signMethod"))
                object.signMethod = options.enums === String ? $root.signrpc.SignMethod[message.signMethod] : message.signMethod;
            if (message.tapTweak != null && message.hasOwnProperty("tapTweak"))
                object.tapTweak = options.bytes === String ? $util.base64.encode(message.tapTweak, 0, message.tapTweak.length) : options.bytes === Array ? Array.prototype.slice.call(message.tapTweak) : message.tapTweak;
            return object;
        };

        /**
         * Converts this SignDescriptor to JSON.
         * @function toJSON
         * @memberof signrpc.SignDescriptor
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignDescriptor.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignDescriptor;
    })();

    signrpc.SignReq = (function() {

        /**
         * Properties of a SignReq.
         * @memberof signrpc
         * @interface ISignReq
         * @property {Uint8Array|null} [rawTxBytes] SignReq rawTxBytes
         * @property {Array.<signrpc.ISignDescriptor>|null} [signDescs] SignReq signDescs
         * @property {Array.<signrpc.ITxOut>|null} [prevOutputs] SignReq prevOutputs
         */

        /**
         * Constructs a new SignReq.
         * @memberof signrpc
         * @classdesc Represents a SignReq.
         * @implements ISignReq
         * @constructor
         * @param {signrpc.ISignReq=} [properties] Properties to set
         */
        function SignReq(properties) {
            this.signDescs = [];
            this.prevOutputs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignReq rawTxBytes.
         * @member {Uint8Array} rawTxBytes
         * @memberof signrpc.SignReq
         * @instance
         */
        SignReq.prototype.rawTxBytes = $util.newBuffer([]);

        /**
         * SignReq signDescs.
         * @member {Array.<signrpc.ISignDescriptor>} signDescs
         * @memberof signrpc.SignReq
         * @instance
         */
        SignReq.prototype.signDescs = $util.emptyArray;

        /**
         * SignReq prevOutputs.
         * @member {Array.<signrpc.ITxOut>} prevOutputs
         * @memberof signrpc.SignReq
         * @instance
         */
        SignReq.prototype.prevOutputs = $util.emptyArray;

        /**
         * Creates a new SignReq instance using the specified properties.
         * @function create
         * @memberof signrpc.SignReq
         * @static
         * @param {signrpc.ISignReq=} [properties] Properties to set
         * @returns {signrpc.SignReq} SignReq instance
         */
        SignReq.create = function create(properties) {
            return new SignReq(properties);
        };

        /**
         * Encodes the specified SignReq message. Does not implicitly {@link signrpc.SignReq.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SignReq
         * @static
         * @param {signrpc.ISignReq} message SignReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawTxBytes != null && Object.hasOwnProperty.call(message, "rawTxBytes"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rawTxBytes);
            if (message.signDescs != null && message.signDescs.length)
                for (let i = 0; i < message.signDescs.length; ++i)
                    $root.signrpc.SignDescriptor.encode(message.signDescs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.prevOutputs != null && message.prevOutputs.length)
                for (let i = 0; i < message.prevOutputs.length; ++i)
                    $root.signrpc.TxOut.encode(message.prevOutputs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SignReq message, length delimited. Does not implicitly {@link signrpc.SignReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SignReq
         * @static
         * @param {signrpc.ISignReq} message SignReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignReq message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SignReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SignReq} SignReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SignReq();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rawTxBytes = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.signDescs && message.signDescs.length))
                            message.signDescs = [];
                        message.signDescs.push($root.signrpc.SignDescriptor.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        if (!(message.prevOutputs && message.prevOutputs.length))
                            message.prevOutputs = [];
                        message.prevOutputs.push($root.signrpc.TxOut.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SignReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SignReq} SignReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignReq message.
         * @function verify
         * @memberof signrpc.SignReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawTxBytes != null && message.hasOwnProperty("rawTxBytes"))
                if (!(message.rawTxBytes && typeof message.rawTxBytes.length === "number" || $util.isString(message.rawTxBytes)))
                    return "rawTxBytes: buffer expected";
            if (message.signDescs != null && message.hasOwnProperty("signDescs")) {
                if (!Array.isArray(message.signDescs))
                    return "signDescs: array expected";
                for (let i = 0; i < message.signDescs.length; ++i) {
                    let error = $root.signrpc.SignDescriptor.verify(message.signDescs[i]);
                    if (error)
                        return "signDescs." + error;
                }
            }
            if (message.prevOutputs != null && message.hasOwnProperty("prevOutputs")) {
                if (!Array.isArray(message.prevOutputs))
                    return "prevOutputs: array expected";
                for (let i = 0; i < message.prevOutputs.length; ++i) {
                    let error = $root.signrpc.TxOut.verify(message.prevOutputs[i]);
                    if (error)
                        return "prevOutputs." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SignReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SignReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SignReq} SignReq
         */
        SignReq.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SignReq)
                return object;
            let message = new $root.signrpc.SignReq();
            if (object.rawTxBytes != null)
                if (typeof object.rawTxBytes === "string")
                    $util.base64.decode(object.rawTxBytes, message.rawTxBytes = $util.newBuffer($util.base64.length(object.rawTxBytes)), 0);
                else if (object.rawTxBytes.length)
                    message.rawTxBytes = object.rawTxBytes;
            if (object.signDescs) {
                if (!Array.isArray(object.signDescs))
                    throw TypeError(".signrpc.SignReq.signDescs: array expected");
                message.signDescs = [];
                for (let i = 0; i < object.signDescs.length; ++i) {
                    if (typeof object.signDescs[i] !== "object")
                        throw TypeError(".signrpc.SignReq.signDescs: object expected");
                    message.signDescs[i] = $root.signrpc.SignDescriptor.fromObject(object.signDescs[i]);
                }
            }
            if (object.prevOutputs) {
                if (!Array.isArray(object.prevOutputs))
                    throw TypeError(".signrpc.SignReq.prevOutputs: array expected");
                message.prevOutputs = [];
                for (let i = 0; i < object.prevOutputs.length; ++i) {
                    if (typeof object.prevOutputs[i] !== "object")
                        throw TypeError(".signrpc.SignReq.prevOutputs: object expected");
                    message.prevOutputs[i] = $root.signrpc.TxOut.fromObject(object.prevOutputs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SignReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SignReq
         * @static
         * @param {signrpc.SignReq} message SignReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.signDescs = [];
                object.prevOutputs = [];
            }
            if (options.defaults)
                if (options.bytes === String)
                    object.rawTxBytes = "";
                else {
                    object.rawTxBytes = [];
                    if (options.bytes !== Array)
                        object.rawTxBytes = $util.newBuffer(object.rawTxBytes);
                }
            if (message.rawTxBytes != null && message.hasOwnProperty("rawTxBytes"))
                object.rawTxBytes = options.bytes === String ? $util.base64.encode(message.rawTxBytes, 0, message.rawTxBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawTxBytes) : message.rawTxBytes;
            if (message.signDescs && message.signDescs.length) {
                object.signDescs = [];
                for (let j = 0; j < message.signDescs.length; ++j)
                    object.signDescs[j] = $root.signrpc.SignDescriptor.toObject(message.signDescs[j], options);
            }
            if (message.prevOutputs && message.prevOutputs.length) {
                object.prevOutputs = [];
                for (let j = 0; j < message.prevOutputs.length; ++j)
                    object.prevOutputs[j] = $root.signrpc.TxOut.toObject(message.prevOutputs[j], options);
            }
            return object;
        };

        /**
         * Converts this SignReq to JSON.
         * @function toJSON
         * @memberof signrpc.SignReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignReq;
    })();

    signrpc.SignResp = (function() {

        /**
         * Properties of a SignResp.
         * @memberof signrpc
         * @interface ISignResp
         * @property {Array.<Uint8Array>|null} [rawSigs] SignResp rawSigs
         */

        /**
         * Constructs a new SignResp.
         * @memberof signrpc
         * @classdesc Represents a SignResp.
         * @implements ISignResp
         * @constructor
         * @param {signrpc.ISignResp=} [properties] Properties to set
         */
        function SignResp(properties) {
            this.rawSigs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignResp rawSigs.
         * @member {Array.<Uint8Array>} rawSigs
         * @memberof signrpc.SignResp
         * @instance
         */
        SignResp.prototype.rawSigs = $util.emptyArray;

        /**
         * Creates a new SignResp instance using the specified properties.
         * @function create
         * @memberof signrpc.SignResp
         * @static
         * @param {signrpc.ISignResp=} [properties] Properties to set
         * @returns {signrpc.SignResp} SignResp instance
         */
        SignResp.create = function create(properties) {
            return new SignResp(properties);
        };

        /**
         * Encodes the specified SignResp message. Does not implicitly {@link signrpc.SignResp.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SignResp
         * @static
         * @param {signrpc.ISignResp} message SignResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawSigs != null && message.rawSigs.length)
                for (let i = 0; i < message.rawSigs.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rawSigs[i]);
            return writer;
        };

        /**
         * Encodes the specified SignResp message, length delimited. Does not implicitly {@link signrpc.SignResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SignResp
         * @static
         * @param {signrpc.ISignResp} message SignResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignResp message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SignResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SignResp} SignResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SignResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.rawSigs && message.rawSigs.length))
                            message.rawSigs = [];
                        message.rawSigs.push(reader.bytes());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SignResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SignResp} SignResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignResp message.
         * @function verify
         * @memberof signrpc.SignResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawSigs != null && message.hasOwnProperty("rawSigs")) {
                if (!Array.isArray(message.rawSigs))
                    return "rawSigs: array expected";
                for (let i = 0; i < message.rawSigs.length; ++i)
                    if (!(message.rawSigs[i] && typeof message.rawSigs[i].length === "number" || $util.isString(message.rawSigs[i])))
                        return "rawSigs: buffer[] expected";
            }
            return null;
        };

        /**
         * Creates a SignResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SignResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SignResp} SignResp
         */
        SignResp.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SignResp)
                return object;
            let message = new $root.signrpc.SignResp();
            if (object.rawSigs) {
                if (!Array.isArray(object.rawSigs))
                    throw TypeError(".signrpc.SignResp.rawSigs: array expected");
                message.rawSigs = [];
                for (let i = 0; i < object.rawSigs.length; ++i)
                    if (typeof object.rawSigs[i] === "string")
                        $util.base64.decode(object.rawSigs[i], message.rawSigs[i] = $util.newBuffer($util.base64.length(object.rawSigs[i])), 0);
                    else if (object.rawSigs[i].length)
                        message.rawSigs[i] = object.rawSigs[i];
            }
            return message;
        };

        /**
         * Creates a plain object from a SignResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SignResp
         * @static
         * @param {signrpc.SignResp} message SignResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rawSigs = [];
            if (message.rawSigs && message.rawSigs.length) {
                object.rawSigs = [];
                for (let j = 0; j < message.rawSigs.length; ++j)
                    object.rawSigs[j] = options.bytes === String ? $util.base64.encode(message.rawSigs[j], 0, message.rawSigs[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.rawSigs[j]) : message.rawSigs[j];
            }
            return object;
        };

        /**
         * Converts this SignResp to JSON.
         * @function toJSON
         * @memberof signrpc.SignResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignResp;
    })();

    signrpc.InputScript = (function() {

        /**
         * Properties of an InputScript.
         * @memberof signrpc
         * @interface IInputScript
         * @property {Array.<Uint8Array>|null} [witness] InputScript witness
         * @property {Uint8Array|null} [sigScript] InputScript sigScript
         */

        /**
         * Constructs a new InputScript.
         * @memberof signrpc
         * @classdesc Represents an InputScript.
         * @implements IInputScript
         * @constructor
         * @param {signrpc.IInputScript=} [properties] Properties to set
         */
        function InputScript(properties) {
            this.witness = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InputScript witness.
         * @member {Array.<Uint8Array>} witness
         * @memberof signrpc.InputScript
         * @instance
         */
        InputScript.prototype.witness = $util.emptyArray;

        /**
         * InputScript sigScript.
         * @member {Uint8Array} sigScript
         * @memberof signrpc.InputScript
         * @instance
         */
        InputScript.prototype.sigScript = $util.newBuffer([]);

        /**
         * Creates a new InputScript instance using the specified properties.
         * @function create
         * @memberof signrpc.InputScript
         * @static
         * @param {signrpc.IInputScript=} [properties] Properties to set
         * @returns {signrpc.InputScript} InputScript instance
         */
        InputScript.create = function create(properties) {
            return new InputScript(properties);
        };

        /**
         * Encodes the specified InputScript message. Does not implicitly {@link signrpc.InputScript.verify|verify} messages.
         * @function encode
         * @memberof signrpc.InputScript
         * @static
         * @param {signrpc.IInputScript} message InputScript message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputScript.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.witness != null && message.witness.length)
                for (let i = 0; i < message.witness.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.witness[i]);
            if (message.sigScript != null && Object.hasOwnProperty.call(message, "sigScript"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.sigScript);
            return writer;
        };

        /**
         * Encodes the specified InputScript message, length delimited. Does not implicitly {@link signrpc.InputScript.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.InputScript
         * @static
         * @param {signrpc.IInputScript} message InputScript message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputScript.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InputScript message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.InputScript
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.InputScript} InputScript
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputScript.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.InputScript();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.witness && message.witness.length))
                            message.witness = [];
                        message.witness.push(reader.bytes());
                        break;
                    }
                case 2: {
                        message.sigScript = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InputScript message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.InputScript
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.InputScript} InputScript
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputScript.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InputScript message.
         * @function verify
         * @memberof signrpc.InputScript
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InputScript.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.witness != null && message.hasOwnProperty("witness")) {
                if (!Array.isArray(message.witness))
                    return "witness: array expected";
                for (let i = 0; i < message.witness.length; ++i)
                    if (!(message.witness[i] && typeof message.witness[i].length === "number" || $util.isString(message.witness[i])))
                        return "witness: buffer[] expected";
            }
            if (message.sigScript != null && message.hasOwnProperty("sigScript"))
                if (!(message.sigScript && typeof message.sigScript.length === "number" || $util.isString(message.sigScript)))
                    return "sigScript: buffer expected";
            return null;
        };

        /**
         * Creates an InputScript message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.InputScript
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.InputScript} InputScript
         */
        InputScript.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.InputScript)
                return object;
            let message = new $root.signrpc.InputScript();
            if (object.witness) {
                if (!Array.isArray(object.witness))
                    throw TypeError(".signrpc.InputScript.witness: array expected");
                message.witness = [];
                for (let i = 0; i < object.witness.length; ++i)
                    if (typeof object.witness[i] === "string")
                        $util.base64.decode(object.witness[i], message.witness[i] = $util.newBuffer($util.base64.length(object.witness[i])), 0);
                    else if (object.witness[i].length)
                        message.witness[i] = object.witness[i];
            }
            if (object.sigScript != null)
                if (typeof object.sigScript === "string")
                    $util.base64.decode(object.sigScript, message.sigScript = $util.newBuffer($util.base64.length(object.sigScript)), 0);
                else if (object.sigScript.length)
                    message.sigScript = object.sigScript;
            return message;
        };

        /**
         * Creates a plain object from an InputScript message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.InputScript
         * @static
         * @param {signrpc.InputScript} message InputScript
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InputScript.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.witness = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.sigScript = "";
                else {
                    object.sigScript = [];
                    if (options.bytes !== Array)
                        object.sigScript = $util.newBuffer(object.sigScript);
                }
            if (message.witness && message.witness.length) {
                object.witness = [];
                for (let j = 0; j < message.witness.length; ++j)
                    object.witness[j] = options.bytes === String ? $util.base64.encode(message.witness[j], 0, message.witness[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.witness[j]) : message.witness[j];
            }
            if (message.sigScript != null && message.hasOwnProperty("sigScript"))
                object.sigScript = options.bytes === String ? $util.base64.encode(message.sigScript, 0, message.sigScript.length) : options.bytes === Array ? Array.prototype.slice.call(message.sigScript) : message.sigScript;
            return object;
        };

        /**
         * Converts this InputScript to JSON.
         * @function toJSON
         * @memberof signrpc.InputScript
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InputScript.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InputScript;
    })();

    signrpc.InputScriptResp = (function() {

        /**
         * Properties of an InputScriptResp.
         * @memberof signrpc
         * @interface IInputScriptResp
         * @property {Array.<signrpc.IInputScript>|null} [inputScripts] InputScriptResp inputScripts
         */

        /**
         * Constructs a new InputScriptResp.
         * @memberof signrpc
         * @classdesc Represents an InputScriptResp.
         * @implements IInputScriptResp
         * @constructor
         * @param {signrpc.IInputScriptResp=} [properties] Properties to set
         */
        function InputScriptResp(properties) {
            this.inputScripts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InputScriptResp inputScripts.
         * @member {Array.<signrpc.IInputScript>} inputScripts
         * @memberof signrpc.InputScriptResp
         * @instance
         */
        InputScriptResp.prototype.inputScripts = $util.emptyArray;

        /**
         * Creates a new InputScriptResp instance using the specified properties.
         * @function create
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {signrpc.IInputScriptResp=} [properties] Properties to set
         * @returns {signrpc.InputScriptResp} InputScriptResp instance
         */
        InputScriptResp.create = function create(properties) {
            return new InputScriptResp(properties);
        };

        /**
         * Encodes the specified InputScriptResp message. Does not implicitly {@link signrpc.InputScriptResp.verify|verify} messages.
         * @function encode
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {signrpc.IInputScriptResp} message InputScriptResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputScriptResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inputScripts != null && message.inputScripts.length)
                for (let i = 0; i < message.inputScripts.length; ++i)
                    $root.signrpc.InputScript.encode(message.inputScripts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified InputScriptResp message, length delimited. Does not implicitly {@link signrpc.InputScriptResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {signrpc.IInputScriptResp} message InputScriptResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InputScriptResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InputScriptResp message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.InputScriptResp} InputScriptResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputScriptResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.InputScriptResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.inputScripts && message.inputScripts.length))
                            message.inputScripts = [];
                        message.inputScripts.push($root.signrpc.InputScript.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InputScriptResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.InputScriptResp} InputScriptResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InputScriptResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InputScriptResp message.
         * @function verify
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InputScriptResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.inputScripts != null && message.hasOwnProperty("inputScripts")) {
                if (!Array.isArray(message.inputScripts))
                    return "inputScripts: array expected";
                for (let i = 0; i < message.inputScripts.length; ++i) {
                    let error = $root.signrpc.InputScript.verify(message.inputScripts[i]);
                    if (error)
                        return "inputScripts." + error;
                }
            }
            return null;
        };

        /**
         * Creates an InputScriptResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.InputScriptResp} InputScriptResp
         */
        InputScriptResp.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.InputScriptResp)
                return object;
            let message = new $root.signrpc.InputScriptResp();
            if (object.inputScripts) {
                if (!Array.isArray(object.inputScripts))
                    throw TypeError(".signrpc.InputScriptResp.inputScripts: array expected");
                message.inputScripts = [];
                for (let i = 0; i < object.inputScripts.length; ++i) {
                    if (typeof object.inputScripts[i] !== "object")
                        throw TypeError(".signrpc.InputScriptResp.inputScripts: object expected");
                    message.inputScripts[i] = $root.signrpc.InputScript.fromObject(object.inputScripts[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an InputScriptResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.InputScriptResp
         * @static
         * @param {signrpc.InputScriptResp} message InputScriptResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InputScriptResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.inputScripts = [];
            if (message.inputScripts && message.inputScripts.length) {
                object.inputScripts = [];
                for (let j = 0; j < message.inputScripts.length; ++j)
                    object.inputScripts[j] = $root.signrpc.InputScript.toObject(message.inputScripts[j], options);
            }
            return object;
        };

        /**
         * Converts this InputScriptResp to JSON.
         * @function toJSON
         * @memberof signrpc.InputScriptResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InputScriptResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InputScriptResp;
    })();

    signrpc.SignMessageReq = (function() {

        /**
         * Properties of a SignMessageReq.
         * @memberof signrpc
         * @interface ISignMessageReq
         * @property {Uint8Array|null} [msg] SignMessageReq msg
         * @property {signrpc.IKeyLocator|null} [keyLoc] SignMessageReq keyLoc
         * @property {boolean|null} [doubleHash] SignMessageReq doubleHash
         * @property {boolean|null} [compactSig] SignMessageReq compactSig
         * @property {boolean|null} [schnorrSig] SignMessageReq schnorrSig
         * @property {Uint8Array|null} [schnorrSigTapTweak] SignMessageReq schnorrSigTapTweak
         */

        /**
         * Constructs a new SignMessageReq.
         * @memberof signrpc
         * @classdesc Represents a SignMessageReq.
         * @implements ISignMessageReq
         * @constructor
         * @param {signrpc.ISignMessageReq=} [properties] Properties to set
         */
        function SignMessageReq(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignMessageReq msg.
         * @member {Uint8Array} msg
         * @memberof signrpc.SignMessageReq
         * @instance
         */
        SignMessageReq.prototype.msg = $util.newBuffer([]);

        /**
         * SignMessageReq keyLoc.
         * @member {signrpc.IKeyLocator|null|undefined} keyLoc
         * @memberof signrpc.SignMessageReq
         * @instance
         */
        SignMessageReq.prototype.keyLoc = null;

        /**
         * SignMessageReq doubleHash.
         * @member {boolean} doubleHash
         * @memberof signrpc.SignMessageReq
         * @instance
         */
        SignMessageReq.prototype.doubleHash = false;

        /**
         * SignMessageReq compactSig.
         * @member {boolean} compactSig
         * @memberof signrpc.SignMessageReq
         * @instance
         */
        SignMessageReq.prototype.compactSig = false;

        /**
         * SignMessageReq schnorrSig.
         * @member {boolean} schnorrSig
         * @memberof signrpc.SignMessageReq
         * @instance
         */
        SignMessageReq.prototype.schnorrSig = false;

        /**
         * SignMessageReq schnorrSigTapTweak.
         * @member {Uint8Array} schnorrSigTapTweak
         * @memberof signrpc.SignMessageReq
         * @instance
         */
        SignMessageReq.prototype.schnorrSigTapTweak = $util.newBuffer([]);

        /**
         * Creates a new SignMessageReq instance using the specified properties.
         * @function create
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {signrpc.ISignMessageReq=} [properties] Properties to set
         * @returns {signrpc.SignMessageReq} SignMessageReq instance
         */
        SignMessageReq.create = function create(properties) {
            return new SignMessageReq(properties);
        };

        /**
         * Encodes the specified SignMessageReq message. Does not implicitly {@link signrpc.SignMessageReq.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {signrpc.ISignMessageReq} message SignMessageReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.msg);
            if (message.keyLoc != null && Object.hasOwnProperty.call(message, "keyLoc"))
                $root.signrpc.KeyLocator.encode(message.keyLoc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.doubleHash != null && Object.hasOwnProperty.call(message, "doubleHash"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.doubleHash);
            if (message.compactSig != null && Object.hasOwnProperty.call(message, "compactSig"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.compactSig);
            if (message.schnorrSig != null && Object.hasOwnProperty.call(message, "schnorrSig"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.schnorrSig);
            if (message.schnorrSigTapTweak != null && Object.hasOwnProperty.call(message, "schnorrSigTapTweak"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.schnorrSigTapTweak);
            return writer;
        };

        /**
         * Encodes the specified SignMessageReq message, length delimited. Does not implicitly {@link signrpc.SignMessageReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {signrpc.ISignMessageReq} message SignMessageReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignMessageReq message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SignMessageReq} SignMessageReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SignMessageReq();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = reader.bytes();
                        break;
                    }
                case 2: {
                        message.keyLoc = $root.signrpc.KeyLocator.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.doubleHash = reader.bool();
                        break;
                    }
                case 4: {
                        message.compactSig = reader.bool();
                        break;
                    }
                case 5: {
                        message.schnorrSig = reader.bool();
                        break;
                    }
                case 6: {
                        message.schnorrSigTapTweak = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignMessageReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SignMessageReq} SignMessageReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignMessageReq message.
         * @function verify
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignMessageReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!(message.msg && typeof message.msg.length === "number" || $util.isString(message.msg)))
                    return "msg: buffer expected";
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc")) {
                let error = $root.signrpc.KeyLocator.verify(message.keyLoc);
                if (error)
                    return "keyLoc." + error;
            }
            if (message.doubleHash != null && message.hasOwnProperty("doubleHash"))
                if (typeof message.doubleHash !== "boolean")
                    return "doubleHash: boolean expected";
            if (message.compactSig != null && message.hasOwnProperty("compactSig"))
                if (typeof message.compactSig !== "boolean")
                    return "compactSig: boolean expected";
            if (message.schnorrSig != null && message.hasOwnProperty("schnorrSig"))
                if (typeof message.schnorrSig !== "boolean")
                    return "schnorrSig: boolean expected";
            if (message.schnorrSigTapTweak != null && message.hasOwnProperty("schnorrSigTapTweak"))
                if (!(message.schnorrSigTapTweak && typeof message.schnorrSigTapTweak.length === "number" || $util.isString(message.schnorrSigTapTweak)))
                    return "schnorrSigTapTweak: buffer expected";
            return null;
        };

        /**
         * Creates a SignMessageReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SignMessageReq} SignMessageReq
         */
        SignMessageReq.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SignMessageReq)
                return object;
            let message = new $root.signrpc.SignMessageReq();
            if (object.msg != null)
                if (typeof object.msg === "string")
                    $util.base64.decode(object.msg, message.msg = $util.newBuffer($util.base64.length(object.msg)), 0);
                else if (object.msg.length)
                    message.msg = object.msg;
            if (object.keyLoc != null) {
                if (typeof object.keyLoc !== "object")
                    throw TypeError(".signrpc.SignMessageReq.keyLoc: object expected");
                message.keyLoc = $root.signrpc.KeyLocator.fromObject(object.keyLoc);
            }
            if (object.doubleHash != null)
                message.doubleHash = Boolean(object.doubleHash);
            if (object.compactSig != null)
                message.compactSig = Boolean(object.compactSig);
            if (object.schnorrSig != null)
                message.schnorrSig = Boolean(object.schnorrSig);
            if (object.schnorrSigTapTweak != null)
                if (typeof object.schnorrSigTapTweak === "string")
                    $util.base64.decode(object.schnorrSigTapTweak, message.schnorrSigTapTweak = $util.newBuffer($util.base64.length(object.schnorrSigTapTweak)), 0);
                else if (object.schnorrSigTapTweak.length)
                    message.schnorrSigTapTweak = object.schnorrSigTapTweak;
            return message;
        };

        /**
         * Creates a plain object from a SignMessageReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SignMessageReq
         * @static
         * @param {signrpc.SignMessageReq} message SignMessageReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignMessageReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.msg = "";
                else {
                    object.msg = [];
                    if (options.bytes !== Array)
                        object.msg = $util.newBuffer(object.msg);
                }
                object.keyLoc = null;
                object.doubleHash = false;
                object.compactSig = false;
                object.schnorrSig = false;
                if (options.bytes === String)
                    object.schnorrSigTapTweak = "";
                else {
                    object.schnorrSigTapTweak = [];
                    if (options.bytes !== Array)
                        object.schnorrSigTapTweak = $util.newBuffer(object.schnorrSigTapTweak);
                }
            }
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.bytes === String ? $util.base64.encode(message.msg, 0, message.msg.length) : options.bytes === Array ? Array.prototype.slice.call(message.msg) : message.msg;
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc"))
                object.keyLoc = $root.signrpc.KeyLocator.toObject(message.keyLoc, options);
            if (message.doubleHash != null && message.hasOwnProperty("doubleHash"))
                object.doubleHash = message.doubleHash;
            if (message.compactSig != null && message.hasOwnProperty("compactSig"))
                object.compactSig = message.compactSig;
            if (message.schnorrSig != null && message.hasOwnProperty("schnorrSig"))
                object.schnorrSig = message.schnorrSig;
            if (message.schnorrSigTapTweak != null && message.hasOwnProperty("schnorrSigTapTweak"))
                object.schnorrSigTapTweak = options.bytes === String ? $util.base64.encode(message.schnorrSigTapTweak, 0, message.schnorrSigTapTweak.length) : options.bytes === Array ? Array.prototype.slice.call(message.schnorrSigTapTweak) : message.schnorrSigTapTweak;
            return object;
        };

        /**
         * Converts this SignMessageReq to JSON.
         * @function toJSON
         * @memberof signrpc.SignMessageReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignMessageReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignMessageReq;
    })();

    signrpc.SignMessageResp = (function() {

        /**
         * Properties of a SignMessageResp.
         * @memberof signrpc
         * @interface ISignMessageResp
         * @property {Uint8Array|null} [signature] SignMessageResp signature
         */

        /**
         * Constructs a new SignMessageResp.
         * @memberof signrpc
         * @classdesc Represents a SignMessageResp.
         * @implements ISignMessageResp
         * @constructor
         * @param {signrpc.ISignMessageResp=} [properties] Properties to set
         */
        function SignMessageResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignMessageResp signature.
         * @member {Uint8Array} signature
         * @memberof signrpc.SignMessageResp
         * @instance
         */
        SignMessageResp.prototype.signature = $util.newBuffer([]);

        /**
         * Creates a new SignMessageResp instance using the specified properties.
         * @function create
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {signrpc.ISignMessageResp=} [properties] Properties to set
         * @returns {signrpc.SignMessageResp} SignMessageResp instance
         */
        SignMessageResp.create = function create(properties) {
            return new SignMessageResp(properties);
        };

        /**
         * Encodes the specified SignMessageResp message. Does not implicitly {@link signrpc.SignMessageResp.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {signrpc.ISignMessageResp} message SignMessageResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.signature);
            return writer;
        };

        /**
         * Encodes the specified SignMessageResp message, length delimited. Does not implicitly {@link signrpc.SignMessageResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {signrpc.ISignMessageResp} message SignMessageResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignMessageResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignMessageResp message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SignMessageResp} SignMessageResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SignMessageResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signature = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignMessageResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SignMessageResp} SignMessageResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignMessageResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignMessageResp message.
         * @function verify
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignMessageResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                    return "signature: buffer expected";
            return null;
        };

        /**
         * Creates a SignMessageResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SignMessageResp} SignMessageResp
         */
        SignMessageResp.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SignMessageResp)
                return object;
            let message = new $root.signrpc.SignMessageResp();
            if (object.signature != null)
                if (typeof object.signature === "string")
                    $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                else if (object.signature.length)
                    message.signature = object.signature;
            return message;
        };

        /**
         * Creates a plain object from a SignMessageResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SignMessageResp
         * @static
         * @param {signrpc.SignMessageResp} message SignMessageResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignMessageResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.signature = "";
                else {
                    object.signature = [];
                    if (options.bytes !== Array)
                        object.signature = $util.newBuffer(object.signature);
                }
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
            return object;
        };

        /**
         * Converts this SignMessageResp to JSON.
         * @function toJSON
         * @memberof signrpc.SignMessageResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignMessageResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignMessageResp;
    })();

    signrpc.VerifyMessageReq = (function() {

        /**
         * Properties of a VerifyMessageReq.
         * @memberof signrpc
         * @interface IVerifyMessageReq
         * @property {Uint8Array|null} [msg] VerifyMessageReq msg
         * @property {Uint8Array|null} [signature] VerifyMessageReq signature
         * @property {Uint8Array|null} [pubkey] VerifyMessageReq pubkey
         * @property {boolean|null} [isSchnorrSig] VerifyMessageReq isSchnorrSig
         */

        /**
         * Constructs a new VerifyMessageReq.
         * @memberof signrpc
         * @classdesc Represents a VerifyMessageReq.
         * @implements IVerifyMessageReq
         * @constructor
         * @param {signrpc.IVerifyMessageReq=} [properties] Properties to set
         */
        function VerifyMessageReq(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VerifyMessageReq msg.
         * @member {Uint8Array} msg
         * @memberof signrpc.VerifyMessageReq
         * @instance
         */
        VerifyMessageReq.prototype.msg = $util.newBuffer([]);

        /**
         * VerifyMessageReq signature.
         * @member {Uint8Array} signature
         * @memberof signrpc.VerifyMessageReq
         * @instance
         */
        VerifyMessageReq.prototype.signature = $util.newBuffer([]);

        /**
         * VerifyMessageReq pubkey.
         * @member {Uint8Array} pubkey
         * @memberof signrpc.VerifyMessageReq
         * @instance
         */
        VerifyMessageReq.prototype.pubkey = $util.newBuffer([]);

        /**
         * VerifyMessageReq isSchnorrSig.
         * @member {boolean} isSchnorrSig
         * @memberof signrpc.VerifyMessageReq
         * @instance
         */
        VerifyMessageReq.prototype.isSchnorrSig = false;

        /**
         * Creates a new VerifyMessageReq instance using the specified properties.
         * @function create
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {signrpc.IVerifyMessageReq=} [properties] Properties to set
         * @returns {signrpc.VerifyMessageReq} VerifyMessageReq instance
         */
        VerifyMessageReq.create = function create(properties) {
            return new VerifyMessageReq(properties);
        };

        /**
         * Encodes the specified VerifyMessageReq message. Does not implicitly {@link signrpc.VerifyMessageReq.verify|verify} messages.
         * @function encode
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {signrpc.IVerifyMessageReq} message VerifyMessageReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.msg);
            if (message.signature != null && Object.hasOwnProperty.call(message, "signature"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.signature);
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.pubkey);
            if (message.isSchnorrSig != null && Object.hasOwnProperty.call(message, "isSchnorrSig"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isSchnorrSig);
            return writer;
        };

        /**
         * Encodes the specified VerifyMessageReq message, length delimited. Does not implicitly {@link signrpc.VerifyMessageReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {signrpc.IVerifyMessageReq} message VerifyMessageReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyMessageReq message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.VerifyMessageReq} VerifyMessageReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.VerifyMessageReq();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.msg = reader.bytes();
                        break;
                    }
                case 2: {
                        message.signature = reader.bytes();
                        break;
                    }
                case 3: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                case 4: {
                        message.isSchnorrSig = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyMessageReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.VerifyMessageReq} VerifyMessageReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyMessageReq message.
         * @function verify
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyMessageReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!(message.msg && typeof message.msg.length === "number" || $util.isString(message.msg)))
                    return "msg: buffer expected";
            if (message.signature != null && message.hasOwnProperty("signature"))
                if (!(message.signature && typeof message.signature.length === "number" || $util.isString(message.signature)))
                    return "signature: buffer expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            if (message.isSchnorrSig != null && message.hasOwnProperty("isSchnorrSig"))
                if (typeof message.isSchnorrSig !== "boolean")
                    return "isSchnorrSig: boolean expected";
            return null;
        };

        /**
         * Creates a VerifyMessageReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.VerifyMessageReq} VerifyMessageReq
         */
        VerifyMessageReq.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.VerifyMessageReq)
                return object;
            let message = new $root.signrpc.VerifyMessageReq();
            if (object.msg != null)
                if (typeof object.msg === "string")
                    $util.base64.decode(object.msg, message.msg = $util.newBuffer($util.base64.length(object.msg)), 0);
                else if (object.msg.length)
                    message.msg = object.msg;
            if (object.signature != null)
                if (typeof object.signature === "string")
                    $util.base64.decode(object.signature, message.signature = $util.newBuffer($util.base64.length(object.signature)), 0);
                else if (object.signature.length)
                    message.signature = object.signature;
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length)
                    message.pubkey = object.pubkey;
            if (object.isSchnorrSig != null)
                message.isSchnorrSig = Boolean(object.isSchnorrSig);
            return message;
        };

        /**
         * Creates a plain object from a VerifyMessageReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.VerifyMessageReq
         * @static
         * @param {signrpc.VerifyMessageReq} message VerifyMessageReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyMessageReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.msg = "";
                else {
                    object.msg = [];
                    if (options.bytes !== Array)
                        object.msg = $util.newBuffer(object.msg);
                }
                if (options.bytes === String)
                    object.signature = "";
                else {
                    object.signature = [];
                    if (options.bytes !== Array)
                        object.signature = $util.newBuffer(object.signature);
                }
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
                object.isSchnorrSig = false;
            }
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = options.bytes === String ? $util.base64.encode(message.msg, 0, message.msg.length) : options.bytes === Array ? Array.prototype.slice.call(message.msg) : message.msg;
            if (message.signature != null && message.hasOwnProperty("signature"))
                object.signature = options.bytes === String ? $util.base64.encode(message.signature, 0, message.signature.length) : options.bytes === Array ? Array.prototype.slice.call(message.signature) : message.signature;
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            if (message.isSchnorrSig != null && message.hasOwnProperty("isSchnorrSig"))
                object.isSchnorrSig = message.isSchnorrSig;
            return object;
        };

        /**
         * Converts this VerifyMessageReq to JSON.
         * @function toJSON
         * @memberof signrpc.VerifyMessageReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyMessageReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyMessageReq;
    })();

    signrpc.VerifyMessageResp = (function() {

        /**
         * Properties of a VerifyMessageResp.
         * @memberof signrpc
         * @interface IVerifyMessageResp
         * @property {boolean|null} [valid] VerifyMessageResp valid
         */

        /**
         * Constructs a new VerifyMessageResp.
         * @memberof signrpc
         * @classdesc Represents a VerifyMessageResp.
         * @implements IVerifyMessageResp
         * @constructor
         * @param {signrpc.IVerifyMessageResp=} [properties] Properties to set
         */
        function VerifyMessageResp(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * VerifyMessageResp valid.
         * @member {boolean} valid
         * @memberof signrpc.VerifyMessageResp
         * @instance
         */
        VerifyMessageResp.prototype.valid = false;

        /**
         * Creates a new VerifyMessageResp instance using the specified properties.
         * @function create
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {signrpc.IVerifyMessageResp=} [properties] Properties to set
         * @returns {signrpc.VerifyMessageResp} VerifyMessageResp instance
         */
        VerifyMessageResp.create = function create(properties) {
            return new VerifyMessageResp(properties);
        };

        /**
         * Encodes the specified VerifyMessageResp message. Does not implicitly {@link signrpc.VerifyMessageResp.verify|verify} messages.
         * @function encode
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {signrpc.IVerifyMessageResp} message VerifyMessageResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageResp.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.valid != null && Object.hasOwnProperty.call(message, "valid"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.valid);
            return writer;
        };

        /**
         * Encodes the specified VerifyMessageResp message, length delimited. Does not implicitly {@link signrpc.VerifyMessageResp.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {signrpc.IVerifyMessageResp} message VerifyMessageResp message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyMessageResp.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyMessageResp message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.VerifyMessageResp} VerifyMessageResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageResp.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.VerifyMessageResp();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.valid = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyMessageResp message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.VerifyMessageResp} VerifyMessageResp
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyMessageResp.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyMessageResp message.
         * @function verify
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyMessageResp.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.valid != null && message.hasOwnProperty("valid"))
                if (typeof message.valid !== "boolean")
                    return "valid: boolean expected";
            return null;
        };

        /**
         * Creates a VerifyMessageResp message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.VerifyMessageResp} VerifyMessageResp
         */
        VerifyMessageResp.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.VerifyMessageResp)
                return object;
            let message = new $root.signrpc.VerifyMessageResp();
            if (object.valid != null)
                message.valid = Boolean(object.valid);
            return message;
        };

        /**
         * Creates a plain object from a VerifyMessageResp message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.VerifyMessageResp
         * @static
         * @param {signrpc.VerifyMessageResp} message VerifyMessageResp
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyMessageResp.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.valid = false;
            if (message.valid != null && message.hasOwnProperty("valid"))
                object.valid = message.valid;
            return object;
        };

        /**
         * Converts this VerifyMessageResp to JSON.
         * @function toJSON
         * @memberof signrpc.VerifyMessageResp
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyMessageResp.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VerifyMessageResp;
    })();

    signrpc.SharedKeyRequest = (function() {

        /**
         * Properties of a SharedKeyRequest.
         * @memberof signrpc
         * @interface ISharedKeyRequest
         * @property {Uint8Array|null} [ephemeralPubkey] SharedKeyRequest ephemeralPubkey
         * @property {signrpc.IKeyLocator|null} [keyLoc] SharedKeyRequest keyLoc
         * @property {signrpc.IKeyDescriptor|null} [keyDesc] SharedKeyRequest keyDesc
         */

        /**
         * Constructs a new SharedKeyRequest.
         * @memberof signrpc
         * @classdesc Represents a SharedKeyRequest.
         * @implements ISharedKeyRequest
         * @constructor
         * @param {signrpc.ISharedKeyRequest=} [properties] Properties to set
         */
        function SharedKeyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SharedKeyRequest ephemeralPubkey.
         * @member {Uint8Array} ephemeralPubkey
         * @memberof signrpc.SharedKeyRequest
         * @instance
         */
        SharedKeyRequest.prototype.ephemeralPubkey = $util.newBuffer([]);

        /**
         * SharedKeyRequest keyLoc.
         * @member {signrpc.IKeyLocator|null|undefined} keyLoc
         * @memberof signrpc.SharedKeyRequest
         * @instance
         */
        SharedKeyRequest.prototype.keyLoc = null;

        /**
         * SharedKeyRequest keyDesc.
         * @member {signrpc.IKeyDescriptor|null|undefined} keyDesc
         * @memberof signrpc.SharedKeyRequest
         * @instance
         */
        SharedKeyRequest.prototype.keyDesc = null;

        /**
         * Creates a new SharedKeyRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {signrpc.ISharedKeyRequest=} [properties] Properties to set
         * @returns {signrpc.SharedKeyRequest} SharedKeyRequest instance
         */
        SharedKeyRequest.create = function create(properties) {
            return new SharedKeyRequest(properties);
        };

        /**
         * Encodes the specified SharedKeyRequest message. Does not implicitly {@link signrpc.SharedKeyRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {signrpc.ISharedKeyRequest} message SharedKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SharedKeyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ephemeralPubkey != null && Object.hasOwnProperty.call(message, "ephemeralPubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.ephemeralPubkey);
            if (message.keyLoc != null && Object.hasOwnProperty.call(message, "keyLoc"))
                $root.signrpc.KeyLocator.encode(message.keyLoc, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.keyDesc != null && Object.hasOwnProperty.call(message, "keyDesc"))
                $root.signrpc.KeyDescriptor.encode(message.keyDesc, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SharedKeyRequest message, length delimited. Does not implicitly {@link signrpc.SharedKeyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {signrpc.ISharedKeyRequest} message SharedKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SharedKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SharedKeyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SharedKeyRequest} SharedKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SharedKeyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SharedKeyRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ephemeralPubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.keyLoc = $root.signrpc.KeyLocator.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.keyDesc = $root.signrpc.KeyDescriptor.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SharedKeyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SharedKeyRequest} SharedKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SharedKeyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SharedKeyRequest message.
         * @function verify
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SharedKeyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ephemeralPubkey != null && message.hasOwnProperty("ephemeralPubkey"))
                if (!(message.ephemeralPubkey && typeof message.ephemeralPubkey.length === "number" || $util.isString(message.ephemeralPubkey)))
                    return "ephemeralPubkey: buffer expected";
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc")) {
                let error = $root.signrpc.KeyLocator.verify(message.keyLoc);
                if (error)
                    return "keyLoc." + error;
            }
            if (message.keyDesc != null && message.hasOwnProperty("keyDesc")) {
                let error = $root.signrpc.KeyDescriptor.verify(message.keyDesc);
                if (error)
                    return "keyDesc." + error;
            }
            return null;
        };

        /**
         * Creates a SharedKeyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SharedKeyRequest} SharedKeyRequest
         */
        SharedKeyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SharedKeyRequest)
                return object;
            let message = new $root.signrpc.SharedKeyRequest();
            if (object.ephemeralPubkey != null)
                if (typeof object.ephemeralPubkey === "string")
                    $util.base64.decode(object.ephemeralPubkey, message.ephemeralPubkey = $util.newBuffer($util.base64.length(object.ephemeralPubkey)), 0);
                else if (object.ephemeralPubkey.length)
                    message.ephemeralPubkey = object.ephemeralPubkey;
            if (object.keyLoc != null) {
                if (typeof object.keyLoc !== "object")
                    throw TypeError(".signrpc.SharedKeyRequest.keyLoc: object expected");
                message.keyLoc = $root.signrpc.KeyLocator.fromObject(object.keyLoc);
            }
            if (object.keyDesc != null) {
                if (typeof object.keyDesc !== "object")
                    throw TypeError(".signrpc.SharedKeyRequest.keyDesc: object expected");
                message.keyDesc = $root.signrpc.KeyDescriptor.fromObject(object.keyDesc);
            }
            return message;
        };

        /**
         * Creates a plain object from a SharedKeyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SharedKeyRequest
         * @static
         * @param {signrpc.SharedKeyRequest} message SharedKeyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SharedKeyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.ephemeralPubkey = "";
                else {
                    object.ephemeralPubkey = [];
                    if (options.bytes !== Array)
                        object.ephemeralPubkey = $util.newBuffer(object.ephemeralPubkey);
                }
                object.keyLoc = null;
                object.keyDesc = null;
            }
            if (message.ephemeralPubkey != null && message.hasOwnProperty("ephemeralPubkey"))
                object.ephemeralPubkey = options.bytes === String ? $util.base64.encode(message.ephemeralPubkey, 0, message.ephemeralPubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.ephemeralPubkey) : message.ephemeralPubkey;
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc"))
                object.keyLoc = $root.signrpc.KeyLocator.toObject(message.keyLoc, options);
            if (message.keyDesc != null && message.hasOwnProperty("keyDesc"))
                object.keyDesc = $root.signrpc.KeyDescriptor.toObject(message.keyDesc, options);
            return object;
        };

        /**
         * Converts this SharedKeyRequest to JSON.
         * @function toJSON
         * @memberof signrpc.SharedKeyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SharedKeyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SharedKeyRequest;
    })();

    signrpc.SharedKeyResponse = (function() {

        /**
         * Properties of a SharedKeyResponse.
         * @memberof signrpc
         * @interface ISharedKeyResponse
         * @property {Uint8Array|null} [sharedKey] SharedKeyResponse sharedKey
         */

        /**
         * Constructs a new SharedKeyResponse.
         * @memberof signrpc
         * @classdesc Represents a SharedKeyResponse.
         * @implements ISharedKeyResponse
         * @constructor
         * @param {signrpc.ISharedKeyResponse=} [properties] Properties to set
         */
        function SharedKeyResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SharedKeyResponse sharedKey.
         * @member {Uint8Array} sharedKey
         * @memberof signrpc.SharedKeyResponse
         * @instance
         */
        SharedKeyResponse.prototype.sharedKey = $util.newBuffer([]);

        /**
         * Creates a new SharedKeyResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {signrpc.ISharedKeyResponse=} [properties] Properties to set
         * @returns {signrpc.SharedKeyResponse} SharedKeyResponse instance
         */
        SharedKeyResponse.create = function create(properties) {
            return new SharedKeyResponse(properties);
        };

        /**
         * Encodes the specified SharedKeyResponse message. Does not implicitly {@link signrpc.SharedKeyResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {signrpc.ISharedKeyResponse} message SharedKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SharedKeyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sharedKey != null && Object.hasOwnProperty.call(message, "sharedKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.sharedKey);
            return writer;
        };

        /**
         * Encodes the specified SharedKeyResponse message, length delimited. Does not implicitly {@link signrpc.SharedKeyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {signrpc.ISharedKeyResponse} message SharedKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SharedKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SharedKeyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.SharedKeyResponse} SharedKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SharedKeyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.SharedKeyResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sharedKey = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SharedKeyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.SharedKeyResponse} SharedKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SharedKeyResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SharedKeyResponse message.
         * @function verify
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SharedKeyResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sharedKey != null && message.hasOwnProperty("sharedKey"))
                if (!(message.sharedKey && typeof message.sharedKey.length === "number" || $util.isString(message.sharedKey)))
                    return "sharedKey: buffer expected";
            return null;
        };

        /**
         * Creates a SharedKeyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.SharedKeyResponse} SharedKeyResponse
         */
        SharedKeyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.SharedKeyResponse)
                return object;
            let message = new $root.signrpc.SharedKeyResponse();
            if (object.sharedKey != null)
                if (typeof object.sharedKey === "string")
                    $util.base64.decode(object.sharedKey, message.sharedKey = $util.newBuffer($util.base64.length(object.sharedKey)), 0);
                else if (object.sharedKey.length)
                    message.sharedKey = object.sharedKey;
            return message;
        };

        /**
         * Creates a plain object from a SharedKeyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.SharedKeyResponse
         * @static
         * @param {signrpc.SharedKeyResponse} message SharedKeyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SharedKeyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.sharedKey = "";
                else {
                    object.sharedKey = [];
                    if (options.bytes !== Array)
                        object.sharedKey = $util.newBuffer(object.sharedKey);
                }
            if (message.sharedKey != null && message.hasOwnProperty("sharedKey"))
                object.sharedKey = options.bytes === String ? $util.base64.encode(message.sharedKey, 0, message.sharedKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.sharedKey) : message.sharedKey;
            return object;
        };

        /**
         * Converts this SharedKeyResponse to JSON.
         * @function toJSON
         * @memberof signrpc.SharedKeyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SharedKeyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SharedKeyResponse;
    })();

    signrpc.TweakDesc = (function() {

        /**
         * Properties of a TweakDesc.
         * @memberof signrpc
         * @interface ITweakDesc
         * @property {Uint8Array|null} [tweak] TweakDesc tweak
         * @property {boolean|null} [isXOnly] TweakDesc isXOnly
         */

        /**
         * Constructs a new TweakDesc.
         * @memberof signrpc
         * @classdesc Represents a TweakDesc.
         * @implements ITweakDesc
         * @constructor
         * @param {signrpc.ITweakDesc=} [properties] Properties to set
         */
        function TweakDesc(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TweakDesc tweak.
         * @member {Uint8Array} tweak
         * @memberof signrpc.TweakDesc
         * @instance
         */
        TweakDesc.prototype.tweak = $util.newBuffer([]);

        /**
         * TweakDesc isXOnly.
         * @member {boolean} isXOnly
         * @memberof signrpc.TweakDesc
         * @instance
         */
        TweakDesc.prototype.isXOnly = false;

        /**
         * Creates a new TweakDesc instance using the specified properties.
         * @function create
         * @memberof signrpc.TweakDesc
         * @static
         * @param {signrpc.ITweakDesc=} [properties] Properties to set
         * @returns {signrpc.TweakDesc} TweakDesc instance
         */
        TweakDesc.create = function create(properties) {
            return new TweakDesc(properties);
        };

        /**
         * Encodes the specified TweakDesc message. Does not implicitly {@link signrpc.TweakDesc.verify|verify} messages.
         * @function encode
         * @memberof signrpc.TweakDesc
         * @static
         * @param {signrpc.ITweakDesc} message TweakDesc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TweakDesc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tweak != null && Object.hasOwnProperty.call(message, "tweak"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.tweak);
            if (message.isXOnly != null && Object.hasOwnProperty.call(message, "isXOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isXOnly);
            return writer;
        };

        /**
         * Encodes the specified TweakDesc message, length delimited. Does not implicitly {@link signrpc.TweakDesc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.TweakDesc
         * @static
         * @param {signrpc.ITweakDesc} message TweakDesc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TweakDesc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TweakDesc message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.TweakDesc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.TweakDesc} TweakDesc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TweakDesc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.TweakDesc();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tweak = reader.bytes();
                        break;
                    }
                case 2: {
                        message.isXOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TweakDesc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.TweakDesc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.TweakDesc} TweakDesc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TweakDesc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TweakDesc message.
         * @function verify
         * @memberof signrpc.TweakDesc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TweakDesc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tweak != null && message.hasOwnProperty("tweak"))
                if (!(message.tweak && typeof message.tweak.length === "number" || $util.isString(message.tweak)))
                    return "tweak: buffer expected";
            if (message.isXOnly != null && message.hasOwnProperty("isXOnly"))
                if (typeof message.isXOnly !== "boolean")
                    return "isXOnly: boolean expected";
            return null;
        };

        /**
         * Creates a TweakDesc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.TweakDesc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.TweakDesc} TweakDesc
         */
        TweakDesc.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.TweakDesc)
                return object;
            let message = new $root.signrpc.TweakDesc();
            if (object.tweak != null)
                if (typeof object.tweak === "string")
                    $util.base64.decode(object.tweak, message.tweak = $util.newBuffer($util.base64.length(object.tweak)), 0);
                else if (object.tweak.length)
                    message.tweak = object.tweak;
            if (object.isXOnly != null)
                message.isXOnly = Boolean(object.isXOnly);
            return message;
        };

        /**
         * Creates a plain object from a TweakDesc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.TweakDesc
         * @static
         * @param {signrpc.TweakDesc} message TweakDesc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TweakDesc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.tweak = "";
                else {
                    object.tweak = [];
                    if (options.bytes !== Array)
                        object.tweak = $util.newBuffer(object.tweak);
                }
                object.isXOnly = false;
            }
            if (message.tweak != null && message.hasOwnProperty("tweak"))
                object.tweak = options.bytes === String ? $util.base64.encode(message.tweak, 0, message.tweak.length) : options.bytes === Array ? Array.prototype.slice.call(message.tweak) : message.tweak;
            if (message.isXOnly != null && message.hasOwnProperty("isXOnly"))
                object.isXOnly = message.isXOnly;
            return object;
        };

        /**
         * Converts this TweakDesc to JSON.
         * @function toJSON
         * @memberof signrpc.TweakDesc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TweakDesc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TweakDesc;
    })();

    signrpc.TaprootTweakDesc = (function() {

        /**
         * Properties of a TaprootTweakDesc.
         * @memberof signrpc
         * @interface ITaprootTweakDesc
         * @property {Uint8Array|null} [scriptRoot] TaprootTweakDesc scriptRoot
         * @property {boolean|null} [keySpendOnly] TaprootTweakDesc keySpendOnly
         */

        /**
         * Constructs a new TaprootTweakDesc.
         * @memberof signrpc
         * @classdesc Represents a TaprootTweakDesc.
         * @implements ITaprootTweakDesc
         * @constructor
         * @param {signrpc.ITaprootTweakDesc=} [properties] Properties to set
         */
        function TaprootTweakDesc(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaprootTweakDesc scriptRoot.
         * @member {Uint8Array} scriptRoot
         * @memberof signrpc.TaprootTweakDesc
         * @instance
         */
        TaprootTweakDesc.prototype.scriptRoot = $util.newBuffer([]);

        /**
         * TaprootTweakDesc keySpendOnly.
         * @member {boolean} keySpendOnly
         * @memberof signrpc.TaprootTweakDesc
         * @instance
         */
        TaprootTweakDesc.prototype.keySpendOnly = false;

        /**
         * Creates a new TaprootTweakDesc instance using the specified properties.
         * @function create
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {signrpc.ITaprootTweakDesc=} [properties] Properties to set
         * @returns {signrpc.TaprootTweakDesc} TaprootTweakDesc instance
         */
        TaprootTweakDesc.create = function create(properties) {
            return new TaprootTweakDesc(properties);
        };

        /**
         * Encodes the specified TaprootTweakDesc message. Does not implicitly {@link signrpc.TaprootTweakDesc.verify|verify} messages.
         * @function encode
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {signrpc.ITaprootTweakDesc} message TaprootTweakDesc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaprootTweakDesc.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scriptRoot != null && Object.hasOwnProperty.call(message, "scriptRoot"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.scriptRoot);
            if (message.keySpendOnly != null && Object.hasOwnProperty.call(message, "keySpendOnly"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.keySpendOnly);
            return writer;
        };

        /**
         * Encodes the specified TaprootTweakDesc message, length delimited. Does not implicitly {@link signrpc.TaprootTweakDesc.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {signrpc.ITaprootTweakDesc} message TaprootTweakDesc message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaprootTweakDesc.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TaprootTweakDesc message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.TaprootTweakDesc} TaprootTweakDesc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaprootTweakDesc.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.TaprootTweakDesc();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.scriptRoot = reader.bytes();
                        break;
                    }
                case 2: {
                        message.keySpendOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TaprootTweakDesc message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.TaprootTweakDesc} TaprootTweakDesc
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaprootTweakDesc.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TaprootTweakDesc message.
         * @function verify
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TaprootTweakDesc.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scriptRoot != null && message.hasOwnProperty("scriptRoot"))
                if (!(message.scriptRoot && typeof message.scriptRoot.length === "number" || $util.isString(message.scriptRoot)))
                    return "scriptRoot: buffer expected";
            if (message.keySpendOnly != null && message.hasOwnProperty("keySpendOnly"))
                if (typeof message.keySpendOnly !== "boolean")
                    return "keySpendOnly: boolean expected";
            return null;
        };

        /**
         * Creates a TaprootTweakDesc message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.TaprootTweakDesc} TaprootTweakDesc
         */
        TaprootTweakDesc.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.TaprootTweakDesc)
                return object;
            let message = new $root.signrpc.TaprootTweakDesc();
            if (object.scriptRoot != null)
                if (typeof object.scriptRoot === "string")
                    $util.base64.decode(object.scriptRoot, message.scriptRoot = $util.newBuffer($util.base64.length(object.scriptRoot)), 0);
                else if (object.scriptRoot.length)
                    message.scriptRoot = object.scriptRoot;
            if (object.keySpendOnly != null)
                message.keySpendOnly = Boolean(object.keySpendOnly);
            return message;
        };

        /**
         * Creates a plain object from a TaprootTweakDesc message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.TaprootTweakDesc
         * @static
         * @param {signrpc.TaprootTweakDesc} message TaprootTweakDesc
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TaprootTweakDesc.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.scriptRoot = "";
                else {
                    object.scriptRoot = [];
                    if (options.bytes !== Array)
                        object.scriptRoot = $util.newBuffer(object.scriptRoot);
                }
                object.keySpendOnly = false;
            }
            if (message.scriptRoot != null && message.hasOwnProperty("scriptRoot"))
                object.scriptRoot = options.bytes === String ? $util.base64.encode(message.scriptRoot, 0, message.scriptRoot.length) : options.bytes === Array ? Array.prototype.slice.call(message.scriptRoot) : message.scriptRoot;
            if (message.keySpendOnly != null && message.hasOwnProperty("keySpendOnly"))
                object.keySpendOnly = message.keySpendOnly;
            return object;
        };

        /**
         * Converts this TaprootTweakDesc to JSON.
         * @function toJSON
         * @memberof signrpc.TaprootTweakDesc
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TaprootTweakDesc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TaprootTweakDesc;
    })();

    signrpc.MuSig2CombineKeysRequest = (function() {

        /**
         * Properties of a MuSig2CombineKeysRequest.
         * @memberof signrpc
         * @interface IMuSig2CombineKeysRequest
         * @property {Array.<Uint8Array>|null} [allSignerPubkeys] MuSig2CombineKeysRequest allSignerPubkeys
         * @property {Array.<signrpc.ITweakDesc>|null} [tweaks] MuSig2CombineKeysRequest tweaks
         * @property {signrpc.ITaprootTweakDesc|null} [taprootTweak] MuSig2CombineKeysRequest taprootTweak
         */

        /**
         * Constructs a new MuSig2CombineKeysRequest.
         * @memberof signrpc
         * @classdesc Represents a MuSig2CombineKeysRequest.
         * @implements IMuSig2CombineKeysRequest
         * @constructor
         * @param {signrpc.IMuSig2CombineKeysRequest=} [properties] Properties to set
         */
        function MuSig2CombineKeysRequest(properties) {
            this.allSignerPubkeys = [];
            this.tweaks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2CombineKeysRequest allSignerPubkeys.
         * @member {Array.<Uint8Array>} allSignerPubkeys
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @instance
         */
        MuSig2CombineKeysRequest.prototype.allSignerPubkeys = $util.emptyArray;

        /**
         * MuSig2CombineKeysRequest tweaks.
         * @member {Array.<signrpc.ITweakDesc>} tweaks
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @instance
         */
        MuSig2CombineKeysRequest.prototype.tweaks = $util.emptyArray;

        /**
         * MuSig2CombineKeysRequest taprootTweak.
         * @member {signrpc.ITaprootTweakDesc|null|undefined} taprootTweak
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @instance
         */
        MuSig2CombineKeysRequest.prototype.taprootTweak = null;

        /**
         * Creates a new MuSig2CombineKeysRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {signrpc.IMuSig2CombineKeysRequest=} [properties] Properties to set
         * @returns {signrpc.MuSig2CombineKeysRequest} MuSig2CombineKeysRequest instance
         */
        MuSig2CombineKeysRequest.create = function create(properties) {
            return new MuSig2CombineKeysRequest(properties);
        };

        /**
         * Encodes the specified MuSig2CombineKeysRequest message. Does not implicitly {@link signrpc.MuSig2CombineKeysRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {signrpc.IMuSig2CombineKeysRequest} message MuSig2CombineKeysRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineKeysRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.allSignerPubkeys != null && message.allSignerPubkeys.length)
                for (let i = 0; i < message.allSignerPubkeys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.allSignerPubkeys[i]);
            if (message.tweaks != null && message.tweaks.length)
                for (let i = 0; i < message.tweaks.length; ++i)
                    $root.signrpc.TweakDesc.encode(message.tweaks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.taprootTweak != null && Object.hasOwnProperty.call(message, "taprootTweak"))
                $root.signrpc.TaprootTweakDesc.encode(message.taprootTweak, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MuSig2CombineKeysRequest message, length delimited. Does not implicitly {@link signrpc.MuSig2CombineKeysRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {signrpc.IMuSig2CombineKeysRequest} message MuSig2CombineKeysRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineKeysRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2CombineKeysRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2CombineKeysRequest} MuSig2CombineKeysRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineKeysRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2CombineKeysRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.allSignerPubkeys && message.allSignerPubkeys.length))
                            message.allSignerPubkeys = [];
                        message.allSignerPubkeys.push(reader.bytes());
                        break;
                    }
                case 2: {
                        if (!(message.tweaks && message.tweaks.length))
                            message.tweaks = [];
                        message.tweaks.push($root.signrpc.TweakDesc.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.taprootTweak = $root.signrpc.TaprootTweakDesc.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2CombineKeysRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2CombineKeysRequest} MuSig2CombineKeysRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineKeysRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2CombineKeysRequest message.
         * @function verify
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2CombineKeysRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.allSignerPubkeys != null && message.hasOwnProperty("allSignerPubkeys")) {
                if (!Array.isArray(message.allSignerPubkeys))
                    return "allSignerPubkeys: array expected";
                for (let i = 0; i < message.allSignerPubkeys.length; ++i)
                    if (!(message.allSignerPubkeys[i] && typeof message.allSignerPubkeys[i].length === "number" || $util.isString(message.allSignerPubkeys[i])))
                        return "allSignerPubkeys: buffer[] expected";
            }
            if (message.tweaks != null && message.hasOwnProperty("tweaks")) {
                if (!Array.isArray(message.tweaks))
                    return "tweaks: array expected";
                for (let i = 0; i < message.tweaks.length; ++i) {
                    let error = $root.signrpc.TweakDesc.verify(message.tweaks[i]);
                    if (error)
                        return "tweaks." + error;
                }
            }
            if (message.taprootTweak != null && message.hasOwnProperty("taprootTweak")) {
                let error = $root.signrpc.TaprootTweakDesc.verify(message.taprootTweak);
                if (error)
                    return "taprootTweak." + error;
            }
            return null;
        };

        /**
         * Creates a MuSig2CombineKeysRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2CombineKeysRequest} MuSig2CombineKeysRequest
         */
        MuSig2CombineKeysRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2CombineKeysRequest)
                return object;
            let message = new $root.signrpc.MuSig2CombineKeysRequest();
            if (object.allSignerPubkeys) {
                if (!Array.isArray(object.allSignerPubkeys))
                    throw TypeError(".signrpc.MuSig2CombineKeysRequest.allSignerPubkeys: array expected");
                message.allSignerPubkeys = [];
                for (let i = 0; i < object.allSignerPubkeys.length; ++i)
                    if (typeof object.allSignerPubkeys[i] === "string")
                        $util.base64.decode(object.allSignerPubkeys[i], message.allSignerPubkeys[i] = $util.newBuffer($util.base64.length(object.allSignerPubkeys[i])), 0);
                    else if (object.allSignerPubkeys[i].length)
                        message.allSignerPubkeys[i] = object.allSignerPubkeys[i];
            }
            if (object.tweaks) {
                if (!Array.isArray(object.tweaks))
                    throw TypeError(".signrpc.MuSig2CombineKeysRequest.tweaks: array expected");
                message.tweaks = [];
                for (let i = 0; i < object.tweaks.length; ++i) {
                    if (typeof object.tweaks[i] !== "object")
                        throw TypeError(".signrpc.MuSig2CombineKeysRequest.tweaks: object expected");
                    message.tweaks[i] = $root.signrpc.TweakDesc.fromObject(object.tweaks[i]);
                }
            }
            if (object.taprootTweak != null) {
                if (typeof object.taprootTweak !== "object")
                    throw TypeError(".signrpc.MuSig2CombineKeysRequest.taprootTweak: object expected");
                message.taprootTweak = $root.signrpc.TaprootTweakDesc.fromObject(object.taprootTweak);
            }
            return message;
        };

        /**
         * Creates a plain object from a MuSig2CombineKeysRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @static
         * @param {signrpc.MuSig2CombineKeysRequest} message MuSig2CombineKeysRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2CombineKeysRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.allSignerPubkeys = [];
                object.tweaks = [];
            }
            if (options.defaults)
                object.taprootTweak = null;
            if (message.allSignerPubkeys && message.allSignerPubkeys.length) {
                object.allSignerPubkeys = [];
                for (let j = 0; j < message.allSignerPubkeys.length; ++j)
                    object.allSignerPubkeys[j] = options.bytes === String ? $util.base64.encode(message.allSignerPubkeys[j], 0, message.allSignerPubkeys[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.allSignerPubkeys[j]) : message.allSignerPubkeys[j];
            }
            if (message.tweaks && message.tweaks.length) {
                object.tweaks = [];
                for (let j = 0; j < message.tweaks.length; ++j)
                    object.tweaks[j] = $root.signrpc.TweakDesc.toObject(message.tweaks[j], options);
            }
            if (message.taprootTweak != null && message.hasOwnProperty("taprootTweak"))
                object.taprootTweak = $root.signrpc.TaprootTweakDesc.toObject(message.taprootTweak, options);
            return object;
        };

        /**
         * Converts this MuSig2CombineKeysRequest to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2CombineKeysRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2CombineKeysRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2CombineKeysRequest;
    })();

    signrpc.MuSig2CombineKeysResponse = (function() {

        /**
         * Properties of a MuSig2CombineKeysResponse.
         * @memberof signrpc
         * @interface IMuSig2CombineKeysResponse
         * @property {Uint8Array|null} [combinedKey] MuSig2CombineKeysResponse combinedKey
         * @property {Uint8Array|null} [taprootInternalKey] MuSig2CombineKeysResponse taprootInternalKey
         */

        /**
         * Constructs a new MuSig2CombineKeysResponse.
         * @memberof signrpc
         * @classdesc Represents a MuSig2CombineKeysResponse.
         * @implements IMuSig2CombineKeysResponse
         * @constructor
         * @param {signrpc.IMuSig2CombineKeysResponse=} [properties] Properties to set
         */
        function MuSig2CombineKeysResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2CombineKeysResponse combinedKey.
         * @member {Uint8Array} combinedKey
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @instance
         */
        MuSig2CombineKeysResponse.prototype.combinedKey = $util.newBuffer([]);

        /**
         * MuSig2CombineKeysResponse taprootInternalKey.
         * @member {Uint8Array} taprootInternalKey
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @instance
         */
        MuSig2CombineKeysResponse.prototype.taprootInternalKey = $util.newBuffer([]);

        /**
         * Creates a new MuSig2CombineKeysResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {signrpc.IMuSig2CombineKeysResponse=} [properties] Properties to set
         * @returns {signrpc.MuSig2CombineKeysResponse} MuSig2CombineKeysResponse instance
         */
        MuSig2CombineKeysResponse.create = function create(properties) {
            return new MuSig2CombineKeysResponse(properties);
        };

        /**
         * Encodes the specified MuSig2CombineKeysResponse message. Does not implicitly {@link signrpc.MuSig2CombineKeysResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {signrpc.IMuSig2CombineKeysResponse} message MuSig2CombineKeysResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineKeysResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.combinedKey != null && Object.hasOwnProperty.call(message, "combinedKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.combinedKey);
            if (message.taprootInternalKey != null && Object.hasOwnProperty.call(message, "taprootInternalKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.taprootInternalKey);
            return writer;
        };

        /**
         * Encodes the specified MuSig2CombineKeysResponse message, length delimited. Does not implicitly {@link signrpc.MuSig2CombineKeysResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {signrpc.IMuSig2CombineKeysResponse} message MuSig2CombineKeysResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineKeysResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2CombineKeysResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2CombineKeysResponse} MuSig2CombineKeysResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineKeysResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2CombineKeysResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.combinedKey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.taprootInternalKey = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2CombineKeysResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2CombineKeysResponse} MuSig2CombineKeysResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineKeysResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2CombineKeysResponse message.
         * @function verify
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2CombineKeysResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.combinedKey != null && message.hasOwnProperty("combinedKey"))
                if (!(message.combinedKey && typeof message.combinedKey.length === "number" || $util.isString(message.combinedKey)))
                    return "combinedKey: buffer expected";
            if (message.taprootInternalKey != null && message.hasOwnProperty("taprootInternalKey"))
                if (!(message.taprootInternalKey && typeof message.taprootInternalKey.length === "number" || $util.isString(message.taprootInternalKey)))
                    return "taprootInternalKey: buffer expected";
            return null;
        };

        /**
         * Creates a MuSig2CombineKeysResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2CombineKeysResponse} MuSig2CombineKeysResponse
         */
        MuSig2CombineKeysResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2CombineKeysResponse)
                return object;
            let message = new $root.signrpc.MuSig2CombineKeysResponse();
            if (object.combinedKey != null)
                if (typeof object.combinedKey === "string")
                    $util.base64.decode(object.combinedKey, message.combinedKey = $util.newBuffer($util.base64.length(object.combinedKey)), 0);
                else if (object.combinedKey.length)
                    message.combinedKey = object.combinedKey;
            if (object.taprootInternalKey != null)
                if (typeof object.taprootInternalKey === "string")
                    $util.base64.decode(object.taprootInternalKey, message.taprootInternalKey = $util.newBuffer($util.base64.length(object.taprootInternalKey)), 0);
                else if (object.taprootInternalKey.length)
                    message.taprootInternalKey = object.taprootInternalKey;
            return message;
        };

        /**
         * Creates a plain object from a MuSig2CombineKeysResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @static
         * @param {signrpc.MuSig2CombineKeysResponse} message MuSig2CombineKeysResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2CombineKeysResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.combinedKey = "";
                else {
                    object.combinedKey = [];
                    if (options.bytes !== Array)
                        object.combinedKey = $util.newBuffer(object.combinedKey);
                }
                if (options.bytes === String)
                    object.taprootInternalKey = "";
                else {
                    object.taprootInternalKey = [];
                    if (options.bytes !== Array)
                        object.taprootInternalKey = $util.newBuffer(object.taprootInternalKey);
                }
            }
            if (message.combinedKey != null && message.hasOwnProperty("combinedKey"))
                object.combinedKey = options.bytes === String ? $util.base64.encode(message.combinedKey, 0, message.combinedKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.combinedKey) : message.combinedKey;
            if (message.taprootInternalKey != null && message.hasOwnProperty("taprootInternalKey"))
                object.taprootInternalKey = options.bytes === String ? $util.base64.encode(message.taprootInternalKey, 0, message.taprootInternalKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.taprootInternalKey) : message.taprootInternalKey;
            return object;
        };

        /**
         * Converts this MuSig2CombineKeysResponse to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2CombineKeysResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2CombineKeysResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2CombineKeysResponse;
    })();

    signrpc.MuSig2SessionRequest = (function() {

        /**
         * Properties of a MuSig2SessionRequest.
         * @memberof signrpc
         * @interface IMuSig2SessionRequest
         * @property {signrpc.IKeyLocator|null} [keyLoc] MuSig2SessionRequest keyLoc
         * @property {Array.<Uint8Array>|null} [allSignerPubkeys] MuSig2SessionRequest allSignerPubkeys
         * @property {Array.<Uint8Array>|null} [otherSignerPublicNonces] MuSig2SessionRequest otherSignerPublicNonces
         * @property {Array.<signrpc.ITweakDesc>|null} [tweaks] MuSig2SessionRequest tweaks
         * @property {signrpc.ITaprootTweakDesc|null} [taprootTweak] MuSig2SessionRequest taprootTweak
         */

        /**
         * Constructs a new MuSig2SessionRequest.
         * @memberof signrpc
         * @classdesc Represents a MuSig2SessionRequest.
         * @implements IMuSig2SessionRequest
         * @constructor
         * @param {signrpc.IMuSig2SessionRequest=} [properties] Properties to set
         */
        function MuSig2SessionRequest(properties) {
            this.allSignerPubkeys = [];
            this.otherSignerPublicNonces = [];
            this.tweaks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2SessionRequest keyLoc.
         * @member {signrpc.IKeyLocator|null|undefined} keyLoc
         * @memberof signrpc.MuSig2SessionRequest
         * @instance
         */
        MuSig2SessionRequest.prototype.keyLoc = null;

        /**
         * MuSig2SessionRequest allSignerPubkeys.
         * @member {Array.<Uint8Array>} allSignerPubkeys
         * @memberof signrpc.MuSig2SessionRequest
         * @instance
         */
        MuSig2SessionRequest.prototype.allSignerPubkeys = $util.emptyArray;

        /**
         * MuSig2SessionRequest otherSignerPublicNonces.
         * @member {Array.<Uint8Array>} otherSignerPublicNonces
         * @memberof signrpc.MuSig2SessionRequest
         * @instance
         */
        MuSig2SessionRequest.prototype.otherSignerPublicNonces = $util.emptyArray;

        /**
         * MuSig2SessionRequest tweaks.
         * @member {Array.<signrpc.ITweakDesc>} tweaks
         * @memberof signrpc.MuSig2SessionRequest
         * @instance
         */
        MuSig2SessionRequest.prototype.tweaks = $util.emptyArray;

        /**
         * MuSig2SessionRequest taprootTweak.
         * @member {signrpc.ITaprootTweakDesc|null|undefined} taprootTweak
         * @memberof signrpc.MuSig2SessionRequest
         * @instance
         */
        MuSig2SessionRequest.prototype.taprootTweak = null;

        /**
         * Creates a new MuSig2SessionRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {signrpc.IMuSig2SessionRequest=} [properties] Properties to set
         * @returns {signrpc.MuSig2SessionRequest} MuSig2SessionRequest instance
         */
        MuSig2SessionRequest.create = function create(properties) {
            return new MuSig2SessionRequest(properties);
        };

        /**
         * Encodes the specified MuSig2SessionRequest message. Does not implicitly {@link signrpc.MuSig2SessionRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {signrpc.IMuSig2SessionRequest} message MuSig2SessionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SessionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keyLoc != null && Object.hasOwnProperty.call(message, "keyLoc"))
                $root.signrpc.KeyLocator.encode(message.keyLoc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.allSignerPubkeys != null && message.allSignerPubkeys.length)
                for (let i = 0; i < message.allSignerPubkeys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.allSignerPubkeys[i]);
            if (message.otherSignerPublicNonces != null && message.otherSignerPublicNonces.length)
                for (let i = 0; i < message.otherSignerPublicNonces.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.otherSignerPublicNonces[i]);
            if (message.tweaks != null && message.tweaks.length)
                for (let i = 0; i < message.tweaks.length; ++i)
                    $root.signrpc.TweakDesc.encode(message.tweaks[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.taprootTweak != null && Object.hasOwnProperty.call(message, "taprootTweak"))
                $root.signrpc.TaprootTweakDesc.encode(message.taprootTweak, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MuSig2SessionRequest message, length delimited. Does not implicitly {@link signrpc.MuSig2SessionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {signrpc.IMuSig2SessionRequest} message MuSig2SessionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SessionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2SessionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2SessionRequest} MuSig2SessionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SessionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2SessionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.keyLoc = $root.signrpc.KeyLocator.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.allSignerPubkeys && message.allSignerPubkeys.length))
                            message.allSignerPubkeys = [];
                        message.allSignerPubkeys.push(reader.bytes());
                        break;
                    }
                case 3: {
                        if (!(message.otherSignerPublicNonces && message.otherSignerPublicNonces.length))
                            message.otherSignerPublicNonces = [];
                        message.otherSignerPublicNonces.push(reader.bytes());
                        break;
                    }
                case 4: {
                        if (!(message.tweaks && message.tweaks.length))
                            message.tweaks = [];
                        message.tweaks.push($root.signrpc.TweakDesc.decode(reader, reader.uint32()));
                        break;
                    }
                case 5: {
                        message.taprootTweak = $root.signrpc.TaprootTweakDesc.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2SessionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2SessionRequest} MuSig2SessionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SessionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2SessionRequest message.
         * @function verify
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2SessionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc")) {
                let error = $root.signrpc.KeyLocator.verify(message.keyLoc);
                if (error)
                    return "keyLoc." + error;
            }
            if (message.allSignerPubkeys != null && message.hasOwnProperty("allSignerPubkeys")) {
                if (!Array.isArray(message.allSignerPubkeys))
                    return "allSignerPubkeys: array expected";
                for (let i = 0; i < message.allSignerPubkeys.length; ++i)
                    if (!(message.allSignerPubkeys[i] && typeof message.allSignerPubkeys[i].length === "number" || $util.isString(message.allSignerPubkeys[i])))
                        return "allSignerPubkeys: buffer[] expected";
            }
            if (message.otherSignerPublicNonces != null && message.hasOwnProperty("otherSignerPublicNonces")) {
                if (!Array.isArray(message.otherSignerPublicNonces))
                    return "otherSignerPublicNonces: array expected";
                for (let i = 0; i < message.otherSignerPublicNonces.length; ++i)
                    if (!(message.otherSignerPublicNonces[i] && typeof message.otherSignerPublicNonces[i].length === "number" || $util.isString(message.otherSignerPublicNonces[i])))
                        return "otherSignerPublicNonces: buffer[] expected";
            }
            if (message.tweaks != null && message.hasOwnProperty("tweaks")) {
                if (!Array.isArray(message.tweaks))
                    return "tweaks: array expected";
                for (let i = 0; i < message.tweaks.length; ++i) {
                    let error = $root.signrpc.TweakDesc.verify(message.tweaks[i]);
                    if (error)
                        return "tweaks." + error;
                }
            }
            if (message.taprootTweak != null && message.hasOwnProperty("taprootTweak")) {
                let error = $root.signrpc.TaprootTweakDesc.verify(message.taprootTweak);
                if (error)
                    return "taprootTweak." + error;
            }
            return null;
        };

        /**
         * Creates a MuSig2SessionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2SessionRequest} MuSig2SessionRequest
         */
        MuSig2SessionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2SessionRequest)
                return object;
            let message = new $root.signrpc.MuSig2SessionRequest();
            if (object.keyLoc != null) {
                if (typeof object.keyLoc !== "object")
                    throw TypeError(".signrpc.MuSig2SessionRequest.keyLoc: object expected");
                message.keyLoc = $root.signrpc.KeyLocator.fromObject(object.keyLoc);
            }
            if (object.allSignerPubkeys) {
                if (!Array.isArray(object.allSignerPubkeys))
                    throw TypeError(".signrpc.MuSig2SessionRequest.allSignerPubkeys: array expected");
                message.allSignerPubkeys = [];
                for (let i = 0; i < object.allSignerPubkeys.length; ++i)
                    if (typeof object.allSignerPubkeys[i] === "string")
                        $util.base64.decode(object.allSignerPubkeys[i], message.allSignerPubkeys[i] = $util.newBuffer($util.base64.length(object.allSignerPubkeys[i])), 0);
                    else if (object.allSignerPubkeys[i].length)
                        message.allSignerPubkeys[i] = object.allSignerPubkeys[i];
            }
            if (object.otherSignerPublicNonces) {
                if (!Array.isArray(object.otherSignerPublicNonces))
                    throw TypeError(".signrpc.MuSig2SessionRequest.otherSignerPublicNonces: array expected");
                message.otherSignerPublicNonces = [];
                for (let i = 0; i < object.otherSignerPublicNonces.length; ++i)
                    if (typeof object.otherSignerPublicNonces[i] === "string")
                        $util.base64.decode(object.otherSignerPublicNonces[i], message.otherSignerPublicNonces[i] = $util.newBuffer($util.base64.length(object.otherSignerPublicNonces[i])), 0);
                    else if (object.otherSignerPublicNonces[i].length)
                        message.otherSignerPublicNonces[i] = object.otherSignerPublicNonces[i];
            }
            if (object.tweaks) {
                if (!Array.isArray(object.tweaks))
                    throw TypeError(".signrpc.MuSig2SessionRequest.tweaks: array expected");
                message.tweaks = [];
                for (let i = 0; i < object.tweaks.length; ++i) {
                    if (typeof object.tweaks[i] !== "object")
                        throw TypeError(".signrpc.MuSig2SessionRequest.tweaks: object expected");
                    message.tweaks[i] = $root.signrpc.TweakDesc.fromObject(object.tweaks[i]);
                }
            }
            if (object.taprootTweak != null) {
                if (typeof object.taprootTweak !== "object")
                    throw TypeError(".signrpc.MuSig2SessionRequest.taprootTweak: object expected");
                message.taprootTweak = $root.signrpc.TaprootTweakDesc.fromObject(object.taprootTweak);
            }
            return message;
        };

        /**
         * Creates a plain object from a MuSig2SessionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2SessionRequest
         * @static
         * @param {signrpc.MuSig2SessionRequest} message MuSig2SessionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2SessionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.allSignerPubkeys = [];
                object.otherSignerPublicNonces = [];
                object.tweaks = [];
            }
            if (options.defaults) {
                object.keyLoc = null;
                object.taprootTweak = null;
            }
            if (message.keyLoc != null && message.hasOwnProperty("keyLoc"))
                object.keyLoc = $root.signrpc.KeyLocator.toObject(message.keyLoc, options);
            if (message.allSignerPubkeys && message.allSignerPubkeys.length) {
                object.allSignerPubkeys = [];
                for (let j = 0; j < message.allSignerPubkeys.length; ++j)
                    object.allSignerPubkeys[j] = options.bytes === String ? $util.base64.encode(message.allSignerPubkeys[j], 0, message.allSignerPubkeys[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.allSignerPubkeys[j]) : message.allSignerPubkeys[j];
            }
            if (message.otherSignerPublicNonces && message.otherSignerPublicNonces.length) {
                object.otherSignerPublicNonces = [];
                for (let j = 0; j < message.otherSignerPublicNonces.length; ++j)
                    object.otherSignerPublicNonces[j] = options.bytes === String ? $util.base64.encode(message.otherSignerPublicNonces[j], 0, message.otherSignerPublicNonces[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.otherSignerPublicNonces[j]) : message.otherSignerPublicNonces[j];
            }
            if (message.tweaks && message.tweaks.length) {
                object.tweaks = [];
                for (let j = 0; j < message.tweaks.length; ++j)
                    object.tweaks[j] = $root.signrpc.TweakDesc.toObject(message.tweaks[j], options);
            }
            if (message.taprootTweak != null && message.hasOwnProperty("taprootTweak"))
                object.taprootTweak = $root.signrpc.TaprootTweakDesc.toObject(message.taprootTweak, options);
            return object;
        };

        /**
         * Converts this MuSig2SessionRequest to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2SessionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2SessionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2SessionRequest;
    })();

    signrpc.MuSig2SessionResponse = (function() {

        /**
         * Properties of a MuSig2SessionResponse.
         * @memberof signrpc
         * @interface IMuSig2SessionResponse
         * @property {Uint8Array|null} [sessionId] MuSig2SessionResponse sessionId
         * @property {Uint8Array|null} [combinedKey] MuSig2SessionResponse combinedKey
         * @property {Uint8Array|null} [taprootInternalKey] MuSig2SessionResponse taprootInternalKey
         * @property {Uint8Array|null} [localPublicNonces] MuSig2SessionResponse localPublicNonces
         * @property {boolean|null} [haveAllNonces] MuSig2SessionResponse haveAllNonces
         */

        /**
         * Constructs a new MuSig2SessionResponse.
         * @memberof signrpc
         * @classdesc Represents a MuSig2SessionResponse.
         * @implements IMuSig2SessionResponse
         * @constructor
         * @param {signrpc.IMuSig2SessionResponse=} [properties] Properties to set
         */
        function MuSig2SessionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2SessionResponse sessionId.
         * @member {Uint8Array} sessionId
         * @memberof signrpc.MuSig2SessionResponse
         * @instance
         */
        MuSig2SessionResponse.prototype.sessionId = $util.newBuffer([]);

        /**
         * MuSig2SessionResponse combinedKey.
         * @member {Uint8Array} combinedKey
         * @memberof signrpc.MuSig2SessionResponse
         * @instance
         */
        MuSig2SessionResponse.prototype.combinedKey = $util.newBuffer([]);

        /**
         * MuSig2SessionResponse taprootInternalKey.
         * @member {Uint8Array} taprootInternalKey
         * @memberof signrpc.MuSig2SessionResponse
         * @instance
         */
        MuSig2SessionResponse.prototype.taprootInternalKey = $util.newBuffer([]);

        /**
         * MuSig2SessionResponse localPublicNonces.
         * @member {Uint8Array} localPublicNonces
         * @memberof signrpc.MuSig2SessionResponse
         * @instance
         */
        MuSig2SessionResponse.prototype.localPublicNonces = $util.newBuffer([]);

        /**
         * MuSig2SessionResponse haveAllNonces.
         * @member {boolean} haveAllNonces
         * @memberof signrpc.MuSig2SessionResponse
         * @instance
         */
        MuSig2SessionResponse.prototype.haveAllNonces = false;

        /**
         * Creates a new MuSig2SessionResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {signrpc.IMuSig2SessionResponse=} [properties] Properties to set
         * @returns {signrpc.MuSig2SessionResponse} MuSig2SessionResponse instance
         */
        MuSig2SessionResponse.create = function create(properties) {
            return new MuSig2SessionResponse(properties);
        };

        /**
         * Encodes the specified MuSig2SessionResponse message. Does not implicitly {@link signrpc.MuSig2SessionResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {signrpc.IMuSig2SessionResponse} message MuSig2SessionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SessionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.sessionId);
            if (message.combinedKey != null && Object.hasOwnProperty.call(message, "combinedKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.combinedKey);
            if (message.taprootInternalKey != null && Object.hasOwnProperty.call(message, "taprootInternalKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.taprootInternalKey);
            if (message.localPublicNonces != null && Object.hasOwnProperty.call(message, "localPublicNonces"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.localPublicNonces);
            if (message.haveAllNonces != null && Object.hasOwnProperty.call(message, "haveAllNonces"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.haveAllNonces);
            return writer;
        };

        /**
         * Encodes the specified MuSig2SessionResponse message, length delimited. Does not implicitly {@link signrpc.MuSig2SessionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {signrpc.IMuSig2SessionResponse} message MuSig2SessionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SessionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2SessionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2SessionResponse} MuSig2SessionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SessionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2SessionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.combinedKey = reader.bytes();
                        break;
                    }
                case 3: {
                        message.taprootInternalKey = reader.bytes();
                        break;
                    }
                case 4: {
                        message.localPublicNonces = reader.bytes();
                        break;
                    }
                case 5: {
                        message.haveAllNonces = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2SessionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2SessionResponse} MuSig2SessionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SessionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2SessionResponse message.
         * @function verify
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2SessionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!(message.sessionId && typeof message.sessionId.length === "number" || $util.isString(message.sessionId)))
                    return "sessionId: buffer expected";
            if (message.combinedKey != null && message.hasOwnProperty("combinedKey"))
                if (!(message.combinedKey && typeof message.combinedKey.length === "number" || $util.isString(message.combinedKey)))
                    return "combinedKey: buffer expected";
            if (message.taprootInternalKey != null && message.hasOwnProperty("taprootInternalKey"))
                if (!(message.taprootInternalKey && typeof message.taprootInternalKey.length === "number" || $util.isString(message.taprootInternalKey)))
                    return "taprootInternalKey: buffer expected";
            if (message.localPublicNonces != null && message.hasOwnProperty("localPublicNonces"))
                if (!(message.localPublicNonces && typeof message.localPublicNonces.length === "number" || $util.isString(message.localPublicNonces)))
                    return "localPublicNonces: buffer expected";
            if (message.haveAllNonces != null && message.hasOwnProperty("haveAllNonces"))
                if (typeof message.haveAllNonces !== "boolean")
                    return "haveAllNonces: boolean expected";
            return null;
        };

        /**
         * Creates a MuSig2SessionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2SessionResponse} MuSig2SessionResponse
         */
        MuSig2SessionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2SessionResponse)
                return object;
            let message = new $root.signrpc.MuSig2SessionResponse();
            if (object.sessionId != null)
                if (typeof object.sessionId === "string")
                    $util.base64.decode(object.sessionId, message.sessionId = $util.newBuffer($util.base64.length(object.sessionId)), 0);
                else if (object.sessionId.length)
                    message.sessionId = object.sessionId;
            if (object.combinedKey != null)
                if (typeof object.combinedKey === "string")
                    $util.base64.decode(object.combinedKey, message.combinedKey = $util.newBuffer($util.base64.length(object.combinedKey)), 0);
                else if (object.combinedKey.length)
                    message.combinedKey = object.combinedKey;
            if (object.taprootInternalKey != null)
                if (typeof object.taprootInternalKey === "string")
                    $util.base64.decode(object.taprootInternalKey, message.taprootInternalKey = $util.newBuffer($util.base64.length(object.taprootInternalKey)), 0);
                else if (object.taprootInternalKey.length)
                    message.taprootInternalKey = object.taprootInternalKey;
            if (object.localPublicNonces != null)
                if (typeof object.localPublicNonces === "string")
                    $util.base64.decode(object.localPublicNonces, message.localPublicNonces = $util.newBuffer($util.base64.length(object.localPublicNonces)), 0);
                else if (object.localPublicNonces.length)
                    message.localPublicNonces = object.localPublicNonces;
            if (object.haveAllNonces != null)
                message.haveAllNonces = Boolean(object.haveAllNonces);
            return message;
        };

        /**
         * Creates a plain object from a MuSig2SessionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2SessionResponse
         * @static
         * @param {signrpc.MuSig2SessionResponse} message MuSig2SessionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2SessionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.sessionId = "";
                else {
                    object.sessionId = [];
                    if (options.bytes !== Array)
                        object.sessionId = $util.newBuffer(object.sessionId);
                }
                if (options.bytes === String)
                    object.combinedKey = "";
                else {
                    object.combinedKey = [];
                    if (options.bytes !== Array)
                        object.combinedKey = $util.newBuffer(object.combinedKey);
                }
                if (options.bytes === String)
                    object.taprootInternalKey = "";
                else {
                    object.taprootInternalKey = [];
                    if (options.bytes !== Array)
                        object.taprootInternalKey = $util.newBuffer(object.taprootInternalKey);
                }
                if (options.bytes === String)
                    object.localPublicNonces = "";
                else {
                    object.localPublicNonces = [];
                    if (options.bytes !== Array)
                        object.localPublicNonces = $util.newBuffer(object.localPublicNonces);
                }
                object.haveAllNonces = false;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = options.bytes === String ? $util.base64.encode(message.sessionId, 0, message.sessionId.length) : options.bytes === Array ? Array.prototype.slice.call(message.sessionId) : message.sessionId;
            if (message.combinedKey != null && message.hasOwnProperty("combinedKey"))
                object.combinedKey = options.bytes === String ? $util.base64.encode(message.combinedKey, 0, message.combinedKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.combinedKey) : message.combinedKey;
            if (message.taprootInternalKey != null && message.hasOwnProperty("taprootInternalKey"))
                object.taprootInternalKey = options.bytes === String ? $util.base64.encode(message.taprootInternalKey, 0, message.taprootInternalKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.taprootInternalKey) : message.taprootInternalKey;
            if (message.localPublicNonces != null && message.hasOwnProperty("localPublicNonces"))
                object.localPublicNonces = options.bytes === String ? $util.base64.encode(message.localPublicNonces, 0, message.localPublicNonces.length) : options.bytes === Array ? Array.prototype.slice.call(message.localPublicNonces) : message.localPublicNonces;
            if (message.haveAllNonces != null && message.hasOwnProperty("haveAllNonces"))
                object.haveAllNonces = message.haveAllNonces;
            return object;
        };

        /**
         * Converts this MuSig2SessionResponse to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2SessionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2SessionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2SessionResponse;
    })();

    signrpc.MuSig2RegisterNoncesRequest = (function() {

        /**
         * Properties of a MuSig2RegisterNoncesRequest.
         * @memberof signrpc
         * @interface IMuSig2RegisterNoncesRequest
         * @property {Uint8Array|null} [sessionId] MuSig2RegisterNoncesRequest sessionId
         * @property {Array.<Uint8Array>|null} [otherSignerPublicNonces] MuSig2RegisterNoncesRequest otherSignerPublicNonces
         */

        /**
         * Constructs a new MuSig2RegisterNoncesRequest.
         * @memberof signrpc
         * @classdesc Represents a MuSig2RegisterNoncesRequest.
         * @implements IMuSig2RegisterNoncesRequest
         * @constructor
         * @param {signrpc.IMuSig2RegisterNoncesRequest=} [properties] Properties to set
         */
        function MuSig2RegisterNoncesRequest(properties) {
            this.otherSignerPublicNonces = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2RegisterNoncesRequest sessionId.
         * @member {Uint8Array} sessionId
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @instance
         */
        MuSig2RegisterNoncesRequest.prototype.sessionId = $util.newBuffer([]);

        /**
         * MuSig2RegisterNoncesRequest otherSignerPublicNonces.
         * @member {Array.<Uint8Array>} otherSignerPublicNonces
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @instance
         */
        MuSig2RegisterNoncesRequest.prototype.otherSignerPublicNonces = $util.emptyArray;

        /**
         * Creates a new MuSig2RegisterNoncesRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {signrpc.IMuSig2RegisterNoncesRequest=} [properties] Properties to set
         * @returns {signrpc.MuSig2RegisterNoncesRequest} MuSig2RegisterNoncesRequest instance
         */
        MuSig2RegisterNoncesRequest.create = function create(properties) {
            return new MuSig2RegisterNoncesRequest(properties);
        };

        /**
         * Encodes the specified MuSig2RegisterNoncesRequest message. Does not implicitly {@link signrpc.MuSig2RegisterNoncesRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {signrpc.IMuSig2RegisterNoncesRequest} message MuSig2RegisterNoncesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2RegisterNoncesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.sessionId);
            if (message.otherSignerPublicNonces != null && message.otherSignerPublicNonces.length)
                for (let i = 0; i < message.otherSignerPublicNonces.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.otherSignerPublicNonces[i]);
            return writer;
        };

        /**
         * Encodes the specified MuSig2RegisterNoncesRequest message, length delimited. Does not implicitly {@link signrpc.MuSig2RegisterNoncesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {signrpc.IMuSig2RegisterNoncesRequest} message MuSig2RegisterNoncesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2RegisterNoncesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2RegisterNoncesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2RegisterNoncesRequest} MuSig2RegisterNoncesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2RegisterNoncesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2RegisterNoncesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.bytes();
                        break;
                    }
                case 3: {
                        if (!(message.otherSignerPublicNonces && message.otherSignerPublicNonces.length))
                            message.otherSignerPublicNonces = [];
                        message.otherSignerPublicNonces.push(reader.bytes());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2RegisterNoncesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2RegisterNoncesRequest} MuSig2RegisterNoncesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2RegisterNoncesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2RegisterNoncesRequest message.
         * @function verify
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2RegisterNoncesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!(message.sessionId && typeof message.sessionId.length === "number" || $util.isString(message.sessionId)))
                    return "sessionId: buffer expected";
            if (message.otherSignerPublicNonces != null && message.hasOwnProperty("otherSignerPublicNonces")) {
                if (!Array.isArray(message.otherSignerPublicNonces))
                    return "otherSignerPublicNonces: array expected";
                for (let i = 0; i < message.otherSignerPublicNonces.length; ++i)
                    if (!(message.otherSignerPublicNonces[i] && typeof message.otherSignerPublicNonces[i].length === "number" || $util.isString(message.otherSignerPublicNonces[i])))
                        return "otherSignerPublicNonces: buffer[] expected";
            }
            return null;
        };

        /**
         * Creates a MuSig2RegisterNoncesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2RegisterNoncesRequest} MuSig2RegisterNoncesRequest
         */
        MuSig2RegisterNoncesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2RegisterNoncesRequest)
                return object;
            let message = new $root.signrpc.MuSig2RegisterNoncesRequest();
            if (object.sessionId != null)
                if (typeof object.sessionId === "string")
                    $util.base64.decode(object.sessionId, message.sessionId = $util.newBuffer($util.base64.length(object.sessionId)), 0);
                else if (object.sessionId.length)
                    message.sessionId = object.sessionId;
            if (object.otherSignerPublicNonces) {
                if (!Array.isArray(object.otherSignerPublicNonces))
                    throw TypeError(".signrpc.MuSig2RegisterNoncesRequest.otherSignerPublicNonces: array expected");
                message.otherSignerPublicNonces = [];
                for (let i = 0; i < object.otherSignerPublicNonces.length; ++i)
                    if (typeof object.otherSignerPublicNonces[i] === "string")
                        $util.base64.decode(object.otherSignerPublicNonces[i], message.otherSignerPublicNonces[i] = $util.newBuffer($util.base64.length(object.otherSignerPublicNonces[i])), 0);
                    else if (object.otherSignerPublicNonces[i].length)
                        message.otherSignerPublicNonces[i] = object.otherSignerPublicNonces[i];
            }
            return message;
        };

        /**
         * Creates a plain object from a MuSig2RegisterNoncesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @static
         * @param {signrpc.MuSig2RegisterNoncesRequest} message MuSig2RegisterNoncesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2RegisterNoncesRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.otherSignerPublicNonces = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.sessionId = "";
                else {
                    object.sessionId = [];
                    if (options.bytes !== Array)
                        object.sessionId = $util.newBuffer(object.sessionId);
                }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = options.bytes === String ? $util.base64.encode(message.sessionId, 0, message.sessionId.length) : options.bytes === Array ? Array.prototype.slice.call(message.sessionId) : message.sessionId;
            if (message.otherSignerPublicNonces && message.otherSignerPublicNonces.length) {
                object.otherSignerPublicNonces = [];
                for (let j = 0; j < message.otherSignerPublicNonces.length; ++j)
                    object.otherSignerPublicNonces[j] = options.bytes === String ? $util.base64.encode(message.otherSignerPublicNonces[j], 0, message.otherSignerPublicNonces[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.otherSignerPublicNonces[j]) : message.otherSignerPublicNonces[j];
            }
            return object;
        };

        /**
         * Converts this MuSig2RegisterNoncesRequest to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2RegisterNoncesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2RegisterNoncesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2RegisterNoncesRequest;
    })();

    signrpc.MuSig2RegisterNoncesResponse = (function() {

        /**
         * Properties of a MuSig2RegisterNoncesResponse.
         * @memberof signrpc
         * @interface IMuSig2RegisterNoncesResponse
         * @property {boolean|null} [haveAllNonces] MuSig2RegisterNoncesResponse haveAllNonces
         */

        /**
         * Constructs a new MuSig2RegisterNoncesResponse.
         * @memberof signrpc
         * @classdesc Represents a MuSig2RegisterNoncesResponse.
         * @implements IMuSig2RegisterNoncesResponse
         * @constructor
         * @param {signrpc.IMuSig2RegisterNoncesResponse=} [properties] Properties to set
         */
        function MuSig2RegisterNoncesResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2RegisterNoncesResponse haveAllNonces.
         * @member {boolean} haveAllNonces
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @instance
         */
        MuSig2RegisterNoncesResponse.prototype.haveAllNonces = false;

        /**
         * Creates a new MuSig2RegisterNoncesResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {signrpc.IMuSig2RegisterNoncesResponse=} [properties] Properties to set
         * @returns {signrpc.MuSig2RegisterNoncesResponse} MuSig2RegisterNoncesResponse instance
         */
        MuSig2RegisterNoncesResponse.create = function create(properties) {
            return new MuSig2RegisterNoncesResponse(properties);
        };

        /**
         * Encodes the specified MuSig2RegisterNoncesResponse message. Does not implicitly {@link signrpc.MuSig2RegisterNoncesResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {signrpc.IMuSig2RegisterNoncesResponse} message MuSig2RegisterNoncesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2RegisterNoncesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.haveAllNonces != null && Object.hasOwnProperty.call(message, "haveAllNonces"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.haveAllNonces);
            return writer;
        };

        /**
         * Encodes the specified MuSig2RegisterNoncesResponse message, length delimited. Does not implicitly {@link signrpc.MuSig2RegisterNoncesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {signrpc.IMuSig2RegisterNoncesResponse} message MuSig2RegisterNoncesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2RegisterNoncesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2RegisterNoncesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2RegisterNoncesResponse} MuSig2RegisterNoncesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2RegisterNoncesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2RegisterNoncesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.haveAllNonces = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2RegisterNoncesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2RegisterNoncesResponse} MuSig2RegisterNoncesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2RegisterNoncesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2RegisterNoncesResponse message.
         * @function verify
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2RegisterNoncesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.haveAllNonces != null && message.hasOwnProperty("haveAllNonces"))
                if (typeof message.haveAllNonces !== "boolean")
                    return "haveAllNonces: boolean expected";
            return null;
        };

        /**
         * Creates a MuSig2RegisterNoncesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2RegisterNoncesResponse} MuSig2RegisterNoncesResponse
         */
        MuSig2RegisterNoncesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2RegisterNoncesResponse)
                return object;
            let message = new $root.signrpc.MuSig2RegisterNoncesResponse();
            if (object.haveAllNonces != null)
                message.haveAllNonces = Boolean(object.haveAllNonces);
            return message;
        };

        /**
         * Creates a plain object from a MuSig2RegisterNoncesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @static
         * @param {signrpc.MuSig2RegisterNoncesResponse} message MuSig2RegisterNoncesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2RegisterNoncesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.haveAllNonces = false;
            if (message.haveAllNonces != null && message.hasOwnProperty("haveAllNonces"))
                object.haveAllNonces = message.haveAllNonces;
            return object;
        };

        /**
         * Converts this MuSig2RegisterNoncesResponse to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2RegisterNoncesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2RegisterNoncesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2RegisterNoncesResponse;
    })();

    signrpc.MuSig2SignRequest = (function() {

        /**
         * Properties of a MuSig2SignRequest.
         * @memberof signrpc
         * @interface IMuSig2SignRequest
         * @property {Uint8Array|null} [sessionId] MuSig2SignRequest sessionId
         * @property {Uint8Array|null} [messageDigest] MuSig2SignRequest messageDigest
         * @property {boolean|null} [cleanup] MuSig2SignRequest cleanup
         */

        /**
         * Constructs a new MuSig2SignRequest.
         * @memberof signrpc
         * @classdesc Represents a MuSig2SignRequest.
         * @implements IMuSig2SignRequest
         * @constructor
         * @param {signrpc.IMuSig2SignRequest=} [properties] Properties to set
         */
        function MuSig2SignRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2SignRequest sessionId.
         * @member {Uint8Array} sessionId
         * @memberof signrpc.MuSig2SignRequest
         * @instance
         */
        MuSig2SignRequest.prototype.sessionId = $util.newBuffer([]);

        /**
         * MuSig2SignRequest messageDigest.
         * @member {Uint8Array} messageDigest
         * @memberof signrpc.MuSig2SignRequest
         * @instance
         */
        MuSig2SignRequest.prototype.messageDigest = $util.newBuffer([]);

        /**
         * MuSig2SignRequest cleanup.
         * @member {boolean} cleanup
         * @memberof signrpc.MuSig2SignRequest
         * @instance
         */
        MuSig2SignRequest.prototype.cleanup = false;

        /**
         * Creates a new MuSig2SignRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {signrpc.IMuSig2SignRequest=} [properties] Properties to set
         * @returns {signrpc.MuSig2SignRequest} MuSig2SignRequest instance
         */
        MuSig2SignRequest.create = function create(properties) {
            return new MuSig2SignRequest(properties);
        };

        /**
         * Encodes the specified MuSig2SignRequest message. Does not implicitly {@link signrpc.MuSig2SignRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {signrpc.IMuSig2SignRequest} message MuSig2SignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SignRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.sessionId);
            if (message.messageDigest != null && Object.hasOwnProperty.call(message, "messageDigest"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.messageDigest);
            if (message.cleanup != null && Object.hasOwnProperty.call(message, "cleanup"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.cleanup);
            return writer;
        };

        /**
         * Encodes the specified MuSig2SignRequest message, length delimited. Does not implicitly {@link signrpc.MuSig2SignRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {signrpc.IMuSig2SignRequest} message MuSig2SignRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SignRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2SignRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2SignRequest} MuSig2SignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SignRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2SignRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.bytes();
                        break;
                    }
                case 2: {
                        message.messageDigest = reader.bytes();
                        break;
                    }
                case 3: {
                        message.cleanup = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2SignRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2SignRequest} MuSig2SignRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SignRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2SignRequest message.
         * @function verify
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2SignRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!(message.sessionId && typeof message.sessionId.length === "number" || $util.isString(message.sessionId)))
                    return "sessionId: buffer expected";
            if (message.messageDigest != null && message.hasOwnProperty("messageDigest"))
                if (!(message.messageDigest && typeof message.messageDigest.length === "number" || $util.isString(message.messageDigest)))
                    return "messageDigest: buffer expected";
            if (message.cleanup != null && message.hasOwnProperty("cleanup"))
                if (typeof message.cleanup !== "boolean")
                    return "cleanup: boolean expected";
            return null;
        };

        /**
         * Creates a MuSig2SignRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2SignRequest} MuSig2SignRequest
         */
        MuSig2SignRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2SignRequest)
                return object;
            let message = new $root.signrpc.MuSig2SignRequest();
            if (object.sessionId != null)
                if (typeof object.sessionId === "string")
                    $util.base64.decode(object.sessionId, message.sessionId = $util.newBuffer($util.base64.length(object.sessionId)), 0);
                else if (object.sessionId.length)
                    message.sessionId = object.sessionId;
            if (object.messageDigest != null)
                if (typeof object.messageDigest === "string")
                    $util.base64.decode(object.messageDigest, message.messageDigest = $util.newBuffer($util.base64.length(object.messageDigest)), 0);
                else if (object.messageDigest.length)
                    message.messageDigest = object.messageDigest;
            if (object.cleanup != null)
                message.cleanup = Boolean(object.cleanup);
            return message;
        };

        /**
         * Creates a plain object from a MuSig2SignRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2SignRequest
         * @static
         * @param {signrpc.MuSig2SignRequest} message MuSig2SignRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2SignRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.sessionId = "";
                else {
                    object.sessionId = [];
                    if (options.bytes !== Array)
                        object.sessionId = $util.newBuffer(object.sessionId);
                }
                if (options.bytes === String)
                    object.messageDigest = "";
                else {
                    object.messageDigest = [];
                    if (options.bytes !== Array)
                        object.messageDigest = $util.newBuffer(object.messageDigest);
                }
                object.cleanup = false;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = options.bytes === String ? $util.base64.encode(message.sessionId, 0, message.sessionId.length) : options.bytes === Array ? Array.prototype.slice.call(message.sessionId) : message.sessionId;
            if (message.messageDigest != null && message.hasOwnProperty("messageDigest"))
                object.messageDigest = options.bytes === String ? $util.base64.encode(message.messageDigest, 0, message.messageDigest.length) : options.bytes === Array ? Array.prototype.slice.call(message.messageDigest) : message.messageDigest;
            if (message.cleanup != null && message.hasOwnProperty("cleanup"))
                object.cleanup = message.cleanup;
            return object;
        };

        /**
         * Converts this MuSig2SignRequest to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2SignRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2SignRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2SignRequest;
    })();

    signrpc.MuSig2SignResponse = (function() {

        /**
         * Properties of a MuSig2SignResponse.
         * @memberof signrpc
         * @interface IMuSig2SignResponse
         * @property {Uint8Array|null} [localPartialSignature] MuSig2SignResponse localPartialSignature
         */

        /**
         * Constructs a new MuSig2SignResponse.
         * @memberof signrpc
         * @classdesc Represents a MuSig2SignResponse.
         * @implements IMuSig2SignResponse
         * @constructor
         * @param {signrpc.IMuSig2SignResponse=} [properties] Properties to set
         */
        function MuSig2SignResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2SignResponse localPartialSignature.
         * @member {Uint8Array} localPartialSignature
         * @memberof signrpc.MuSig2SignResponse
         * @instance
         */
        MuSig2SignResponse.prototype.localPartialSignature = $util.newBuffer([]);

        /**
         * Creates a new MuSig2SignResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {signrpc.IMuSig2SignResponse=} [properties] Properties to set
         * @returns {signrpc.MuSig2SignResponse} MuSig2SignResponse instance
         */
        MuSig2SignResponse.create = function create(properties) {
            return new MuSig2SignResponse(properties);
        };

        /**
         * Encodes the specified MuSig2SignResponse message. Does not implicitly {@link signrpc.MuSig2SignResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {signrpc.IMuSig2SignResponse} message MuSig2SignResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SignResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localPartialSignature != null && Object.hasOwnProperty.call(message, "localPartialSignature"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.localPartialSignature);
            return writer;
        };

        /**
         * Encodes the specified MuSig2SignResponse message, length delimited. Does not implicitly {@link signrpc.MuSig2SignResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {signrpc.IMuSig2SignResponse} message MuSig2SignResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2SignResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2SignResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2SignResponse} MuSig2SignResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SignResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2SignResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localPartialSignature = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2SignResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2SignResponse} MuSig2SignResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2SignResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2SignResponse message.
         * @function verify
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2SignResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localPartialSignature != null && message.hasOwnProperty("localPartialSignature"))
                if (!(message.localPartialSignature && typeof message.localPartialSignature.length === "number" || $util.isString(message.localPartialSignature)))
                    return "localPartialSignature: buffer expected";
            return null;
        };

        /**
         * Creates a MuSig2SignResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2SignResponse} MuSig2SignResponse
         */
        MuSig2SignResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2SignResponse)
                return object;
            let message = new $root.signrpc.MuSig2SignResponse();
            if (object.localPartialSignature != null)
                if (typeof object.localPartialSignature === "string")
                    $util.base64.decode(object.localPartialSignature, message.localPartialSignature = $util.newBuffer($util.base64.length(object.localPartialSignature)), 0);
                else if (object.localPartialSignature.length)
                    message.localPartialSignature = object.localPartialSignature;
            return message;
        };

        /**
         * Creates a plain object from a MuSig2SignResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2SignResponse
         * @static
         * @param {signrpc.MuSig2SignResponse} message MuSig2SignResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2SignResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.localPartialSignature = "";
                else {
                    object.localPartialSignature = [];
                    if (options.bytes !== Array)
                        object.localPartialSignature = $util.newBuffer(object.localPartialSignature);
                }
            if (message.localPartialSignature != null && message.hasOwnProperty("localPartialSignature"))
                object.localPartialSignature = options.bytes === String ? $util.base64.encode(message.localPartialSignature, 0, message.localPartialSignature.length) : options.bytes === Array ? Array.prototype.slice.call(message.localPartialSignature) : message.localPartialSignature;
            return object;
        };

        /**
         * Converts this MuSig2SignResponse to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2SignResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2SignResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2SignResponse;
    })();

    signrpc.MuSig2CombineSigRequest = (function() {

        /**
         * Properties of a MuSig2CombineSigRequest.
         * @memberof signrpc
         * @interface IMuSig2CombineSigRequest
         * @property {Uint8Array|null} [sessionId] MuSig2CombineSigRequest sessionId
         * @property {Array.<Uint8Array>|null} [otherPartialSignatures] MuSig2CombineSigRequest otherPartialSignatures
         */

        /**
         * Constructs a new MuSig2CombineSigRequest.
         * @memberof signrpc
         * @classdesc Represents a MuSig2CombineSigRequest.
         * @implements IMuSig2CombineSigRequest
         * @constructor
         * @param {signrpc.IMuSig2CombineSigRequest=} [properties] Properties to set
         */
        function MuSig2CombineSigRequest(properties) {
            this.otherPartialSignatures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2CombineSigRequest sessionId.
         * @member {Uint8Array} sessionId
         * @memberof signrpc.MuSig2CombineSigRequest
         * @instance
         */
        MuSig2CombineSigRequest.prototype.sessionId = $util.newBuffer([]);

        /**
         * MuSig2CombineSigRequest otherPartialSignatures.
         * @member {Array.<Uint8Array>} otherPartialSignatures
         * @memberof signrpc.MuSig2CombineSigRequest
         * @instance
         */
        MuSig2CombineSigRequest.prototype.otherPartialSignatures = $util.emptyArray;

        /**
         * Creates a new MuSig2CombineSigRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {signrpc.IMuSig2CombineSigRequest=} [properties] Properties to set
         * @returns {signrpc.MuSig2CombineSigRequest} MuSig2CombineSigRequest instance
         */
        MuSig2CombineSigRequest.create = function create(properties) {
            return new MuSig2CombineSigRequest(properties);
        };

        /**
         * Encodes the specified MuSig2CombineSigRequest message. Does not implicitly {@link signrpc.MuSig2CombineSigRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {signrpc.IMuSig2CombineSigRequest} message MuSig2CombineSigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineSigRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.sessionId);
            if (message.otherPartialSignatures != null && message.otherPartialSignatures.length)
                for (let i = 0; i < message.otherPartialSignatures.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.otherPartialSignatures[i]);
            return writer;
        };

        /**
         * Encodes the specified MuSig2CombineSigRequest message, length delimited. Does not implicitly {@link signrpc.MuSig2CombineSigRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {signrpc.IMuSig2CombineSigRequest} message MuSig2CombineSigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineSigRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2CombineSigRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2CombineSigRequest} MuSig2CombineSigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineSigRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2CombineSigRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.otherPartialSignatures && message.otherPartialSignatures.length))
                            message.otherPartialSignatures = [];
                        message.otherPartialSignatures.push(reader.bytes());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2CombineSigRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2CombineSigRequest} MuSig2CombineSigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineSigRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2CombineSigRequest message.
         * @function verify
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2CombineSigRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!(message.sessionId && typeof message.sessionId.length === "number" || $util.isString(message.sessionId)))
                    return "sessionId: buffer expected";
            if (message.otherPartialSignatures != null && message.hasOwnProperty("otherPartialSignatures")) {
                if (!Array.isArray(message.otherPartialSignatures))
                    return "otherPartialSignatures: array expected";
                for (let i = 0; i < message.otherPartialSignatures.length; ++i)
                    if (!(message.otherPartialSignatures[i] && typeof message.otherPartialSignatures[i].length === "number" || $util.isString(message.otherPartialSignatures[i])))
                        return "otherPartialSignatures: buffer[] expected";
            }
            return null;
        };

        /**
         * Creates a MuSig2CombineSigRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2CombineSigRequest} MuSig2CombineSigRequest
         */
        MuSig2CombineSigRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2CombineSigRequest)
                return object;
            let message = new $root.signrpc.MuSig2CombineSigRequest();
            if (object.sessionId != null)
                if (typeof object.sessionId === "string")
                    $util.base64.decode(object.sessionId, message.sessionId = $util.newBuffer($util.base64.length(object.sessionId)), 0);
                else if (object.sessionId.length)
                    message.sessionId = object.sessionId;
            if (object.otherPartialSignatures) {
                if (!Array.isArray(object.otherPartialSignatures))
                    throw TypeError(".signrpc.MuSig2CombineSigRequest.otherPartialSignatures: array expected");
                message.otherPartialSignatures = [];
                for (let i = 0; i < object.otherPartialSignatures.length; ++i)
                    if (typeof object.otherPartialSignatures[i] === "string")
                        $util.base64.decode(object.otherPartialSignatures[i], message.otherPartialSignatures[i] = $util.newBuffer($util.base64.length(object.otherPartialSignatures[i])), 0);
                    else if (object.otherPartialSignatures[i].length)
                        message.otherPartialSignatures[i] = object.otherPartialSignatures[i];
            }
            return message;
        };

        /**
         * Creates a plain object from a MuSig2CombineSigRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2CombineSigRequest
         * @static
         * @param {signrpc.MuSig2CombineSigRequest} message MuSig2CombineSigRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2CombineSigRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.otherPartialSignatures = [];
            if (options.defaults)
                if (options.bytes === String)
                    object.sessionId = "";
                else {
                    object.sessionId = [];
                    if (options.bytes !== Array)
                        object.sessionId = $util.newBuffer(object.sessionId);
                }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = options.bytes === String ? $util.base64.encode(message.sessionId, 0, message.sessionId.length) : options.bytes === Array ? Array.prototype.slice.call(message.sessionId) : message.sessionId;
            if (message.otherPartialSignatures && message.otherPartialSignatures.length) {
                object.otherPartialSignatures = [];
                for (let j = 0; j < message.otherPartialSignatures.length; ++j)
                    object.otherPartialSignatures[j] = options.bytes === String ? $util.base64.encode(message.otherPartialSignatures[j], 0, message.otherPartialSignatures[j].length) : options.bytes === Array ? Array.prototype.slice.call(message.otherPartialSignatures[j]) : message.otherPartialSignatures[j];
            }
            return object;
        };

        /**
         * Converts this MuSig2CombineSigRequest to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2CombineSigRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2CombineSigRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2CombineSigRequest;
    })();

    signrpc.MuSig2CombineSigResponse = (function() {

        /**
         * Properties of a MuSig2CombineSigResponse.
         * @memberof signrpc
         * @interface IMuSig2CombineSigResponse
         * @property {boolean|null} [haveAllSignatures] MuSig2CombineSigResponse haveAllSignatures
         * @property {Uint8Array|null} [finalSignature] MuSig2CombineSigResponse finalSignature
         */

        /**
         * Constructs a new MuSig2CombineSigResponse.
         * @memberof signrpc
         * @classdesc Represents a MuSig2CombineSigResponse.
         * @implements IMuSig2CombineSigResponse
         * @constructor
         * @param {signrpc.IMuSig2CombineSigResponse=} [properties] Properties to set
         */
        function MuSig2CombineSigResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2CombineSigResponse haveAllSignatures.
         * @member {boolean} haveAllSignatures
         * @memberof signrpc.MuSig2CombineSigResponse
         * @instance
         */
        MuSig2CombineSigResponse.prototype.haveAllSignatures = false;

        /**
         * MuSig2CombineSigResponse finalSignature.
         * @member {Uint8Array} finalSignature
         * @memberof signrpc.MuSig2CombineSigResponse
         * @instance
         */
        MuSig2CombineSigResponse.prototype.finalSignature = $util.newBuffer([]);

        /**
         * Creates a new MuSig2CombineSigResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {signrpc.IMuSig2CombineSigResponse=} [properties] Properties to set
         * @returns {signrpc.MuSig2CombineSigResponse} MuSig2CombineSigResponse instance
         */
        MuSig2CombineSigResponse.create = function create(properties) {
            return new MuSig2CombineSigResponse(properties);
        };

        /**
         * Encodes the specified MuSig2CombineSigResponse message. Does not implicitly {@link signrpc.MuSig2CombineSigResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {signrpc.IMuSig2CombineSigResponse} message MuSig2CombineSigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineSigResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.haveAllSignatures != null && Object.hasOwnProperty.call(message, "haveAllSignatures"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.haveAllSignatures);
            if (message.finalSignature != null && Object.hasOwnProperty.call(message, "finalSignature"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.finalSignature);
            return writer;
        };

        /**
         * Encodes the specified MuSig2CombineSigResponse message, length delimited. Does not implicitly {@link signrpc.MuSig2CombineSigResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {signrpc.IMuSig2CombineSigResponse} message MuSig2CombineSigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CombineSigResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2CombineSigResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2CombineSigResponse} MuSig2CombineSigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineSigResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2CombineSigResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.haveAllSignatures = reader.bool();
                        break;
                    }
                case 2: {
                        message.finalSignature = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2CombineSigResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2CombineSigResponse} MuSig2CombineSigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CombineSigResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2CombineSigResponse message.
         * @function verify
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2CombineSigResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.haveAllSignatures != null && message.hasOwnProperty("haveAllSignatures"))
                if (typeof message.haveAllSignatures !== "boolean")
                    return "haveAllSignatures: boolean expected";
            if (message.finalSignature != null && message.hasOwnProperty("finalSignature"))
                if (!(message.finalSignature && typeof message.finalSignature.length === "number" || $util.isString(message.finalSignature)))
                    return "finalSignature: buffer expected";
            return null;
        };

        /**
         * Creates a MuSig2CombineSigResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2CombineSigResponse} MuSig2CombineSigResponse
         */
        MuSig2CombineSigResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2CombineSigResponse)
                return object;
            let message = new $root.signrpc.MuSig2CombineSigResponse();
            if (object.haveAllSignatures != null)
                message.haveAllSignatures = Boolean(object.haveAllSignatures);
            if (object.finalSignature != null)
                if (typeof object.finalSignature === "string")
                    $util.base64.decode(object.finalSignature, message.finalSignature = $util.newBuffer($util.base64.length(object.finalSignature)), 0);
                else if (object.finalSignature.length)
                    message.finalSignature = object.finalSignature;
            return message;
        };

        /**
         * Creates a plain object from a MuSig2CombineSigResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2CombineSigResponse
         * @static
         * @param {signrpc.MuSig2CombineSigResponse} message MuSig2CombineSigResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2CombineSigResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.haveAllSignatures = false;
                if (options.bytes === String)
                    object.finalSignature = "";
                else {
                    object.finalSignature = [];
                    if (options.bytes !== Array)
                        object.finalSignature = $util.newBuffer(object.finalSignature);
                }
            }
            if (message.haveAllSignatures != null && message.hasOwnProperty("haveAllSignatures"))
                object.haveAllSignatures = message.haveAllSignatures;
            if (message.finalSignature != null && message.hasOwnProperty("finalSignature"))
                object.finalSignature = options.bytes === String ? $util.base64.encode(message.finalSignature, 0, message.finalSignature.length) : options.bytes === Array ? Array.prototype.slice.call(message.finalSignature) : message.finalSignature;
            return object;
        };

        /**
         * Converts this MuSig2CombineSigResponse to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2CombineSigResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2CombineSigResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2CombineSigResponse;
    })();

    signrpc.MuSig2CleanupRequest = (function() {

        /**
         * Properties of a MuSig2CleanupRequest.
         * @memberof signrpc
         * @interface IMuSig2CleanupRequest
         * @property {Uint8Array|null} [sessionId] MuSig2CleanupRequest sessionId
         */

        /**
         * Constructs a new MuSig2CleanupRequest.
         * @memberof signrpc
         * @classdesc Represents a MuSig2CleanupRequest.
         * @implements IMuSig2CleanupRequest
         * @constructor
         * @param {signrpc.IMuSig2CleanupRequest=} [properties] Properties to set
         */
        function MuSig2CleanupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MuSig2CleanupRequest sessionId.
         * @member {Uint8Array} sessionId
         * @memberof signrpc.MuSig2CleanupRequest
         * @instance
         */
        MuSig2CleanupRequest.prototype.sessionId = $util.newBuffer([]);

        /**
         * Creates a new MuSig2CleanupRequest instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {signrpc.IMuSig2CleanupRequest=} [properties] Properties to set
         * @returns {signrpc.MuSig2CleanupRequest} MuSig2CleanupRequest instance
         */
        MuSig2CleanupRequest.create = function create(properties) {
            return new MuSig2CleanupRequest(properties);
        };

        /**
         * Encodes the specified MuSig2CleanupRequest message. Does not implicitly {@link signrpc.MuSig2CleanupRequest.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {signrpc.IMuSig2CleanupRequest} message MuSig2CleanupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CleanupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified MuSig2CleanupRequest message, length delimited. Does not implicitly {@link signrpc.MuSig2CleanupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {signrpc.IMuSig2CleanupRequest} message MuSig2CleanupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CleanupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2CleanupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2CleanupRequest} MuSig2CleanupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CleanupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2CleanupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2CleanupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2CleanupRequest} MuSig2CleanupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CleanupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2CleanupRequest message.
         * @function verify
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2CleanupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!(message.sessionId && typeof message.sessionId.length === "number" || $util.isString(message.sessionId)))
                    return "sessionId: buffer expected";
            return null;
        };

        /**
         * Creates a MuSig2CleanupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2CleanupRequest} MuSig2CleanupRequest
         */
        MuSig2CleanupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2CleanupRequest)
                return object;
            let message = new $root.signrpc.MuSig2CleanupRequest();
            if (object.sessionId != null)
                if (typeof object.sessionId === "string")
                    $util.base64.decode(object.sessionId, message.sessionId = $util.newBuffer($util.base64.length(object.sessionId)), 0);
                else if (object.sessionId.length)
                    message.sessionId = object.sessionId;
            return message;
        };

        /**
         * Creates a plain object from a MuSig2CleanupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2CleanupRequest
         * @static
         * @param {signrpc.MuSig2CleanupRequest} message MuSig2CleanupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2CleanupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.sessionId = "";
                else {
                    object.sessionId = [];
                    if (options.bytes !== Array)
                        object.sessionId = $util.newBuffer(object.sessionId);
                }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = options.bytes === String ? $util.base64.encode(message.sessionId, 0, message.sessionId.length) : options.bytes === Array ? Array.prototype.slice.call(message.sessionId) : message.sessionId;
            return object;
        };

        /**
         * Converts this MuSig2CleanupRequest to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2CleanupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2CleanupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2CleanupRequest;
    })();

    signrpc.MuSig2CleanupResponse = (function() {

        /**
         * Properties of a MuSig2CleanupResponse.
         * @memberof signrpc
         * @interface IMuSig2CleanupResponse
         */

        /**
         * Constructs a new MuSig2CleanupResponse.
         * @memberof signrpc
         * @classdesc Represents a MuSig2CleanupResponse.
         * @implements IMuSig2CleanupResponse
         * @constructor
         * @param {signrpc.IMuSig2CleanupResponse=} [properties] Properties to set
         */
        function MuSig2CleanupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new MuSig2CleanupResponse instance using the specified properties.
         * @function create
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {signrpc.IMuSig2CleanupResponse=} [properties] Properties to set
         * @returns {signrpc.MuSig2CleanupResponse} MuSig2CleanupResponse instance
         */
        MuSig2CleanupResponse.create = function create(properties) {
            return new MuSig2CleanupResponse(properties);
        };

        /**
         * Encodes the specified MuSig2CleanupResponse message. Does not implicitly {@link signrpc.MuSig2CleanupResponse.verify|verify} messages.
         * @function encode
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {signrpc.IMuSig2CleanupResponse} message MuSig2CleanupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CleanupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified MuSig2CleanupResponse message, length delimited. Does not implicitly {@link signrpc.MuSig2CleanupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {signrpc.IMuSig2CleanupResponse} message MuSig2CleanupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MuSig2CleanupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MuSig2CleanupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {signrpc.MuSig2CleanupResponse} MuSig2CleanupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CleanupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.signrpc.MuSig2CleanupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MuSig2CleanupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {signrpc.MuSig2CleanupResponse} MuSig2CleanupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MuSig2CleanupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MuSig2CleanupResponse message.
         * @function verify
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MuSig2CleanupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a MuSig2CleanupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {signrpc.MuSig2CleanupResponse} MuSig2CleanupResponse
         */
        MuSig2CleanupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.signrpc.MuSig2CleanupResponse)
                return object;
            return new $root.signrpc.MuSig2CleanupResponse();
        };

        /**
         * Creates a plain object from a MuSig2CleanupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof signrpc.MuSig2CleanupResponse
         * @static
         * @param {signrpc.MuSig2CleanupResponse} message MuSig2CleanupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MuSig2CleanupResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this MuSig2CleanupResponse to JSON.
         * @function toJSON
         * @memberof signrpc.MuSig2CleanupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MuSig2CleanupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MuSig2CleanupResponse;
    })();

    return signrpc;
})();

export const verrpc = $root.verrpc = (() => {

    /**
     * Namespace verrpc.
     * @exports verrpc
     * @namespace
     */
    const verrpc = {};

    verrpc.Versioner = (function() {

        /**
         * Constructs a new Versioner service.
         * @memberof verrpc
         * @classdesc Represents a Versioner
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Versioner(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Versioner.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Versioner;

        /**
         * Creates new Versioner service using the specified rpc implementation.
         * @function create
         * @memberof verrpc.Versioner
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Versioner} RPC service. Useful where requests and/or responses are streamed.
         */
        Versioner.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link verrpc.Versioner#getVersion}.
         * @memberof verrpc.Versioner
         * @typedef GetVersionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {verrpc.Version} [response] Version
         */

        /**
         * Calls GetVersion.
         * @function getVersion
         * @memberof verrpc.Versioner
         * @instance
         * @param {verrpc.IVersionRequest} request VersionRequest message or plain object
         * @param {verrpc.Versioner.GetVersionCallback} callback Node-style callback called with the error, if any, and Version
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Versioner.prototype.getVersion = function getVersion(request, callback) {
            return this.rpcCall(getVersion, $root.verrpc.VersionRequest, $root.verrpc.Version, request, callback);
        }, "name", { value: "GetVersion" });

        /**
         * Calls GetVersion.
         * @function getVersion
         * @memberof verrpc.Versioner
         * @instance
         * @param {verrpc.IVersionRequest} request VersionRequest message or plain object
         * @returns {Promise<verrpc.Version>} Promise
         * @variation 2
         */

        return Versioner;
    })();

    verrpc.VersionRequest = (function() {

        /**
         * Properties of a VersionRequest.
         * @memberof verrpc
         * @interface IVersionRequest
         */

        /**
         * Constructs a new VersionRequest.
         * @memberof verrpc
         * @classdesc Represents a VersionRequest.
         * @implements IVersionRequest
         * @constructor
         * @param {verrpc.IVersionRequest=} [properties] Properties to set
         */
        function VersionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VersionRequest instance using the specified properties.
         * @function create
         * @memberof verrpc.VersionRequest
         * @static
         * @param {verrpc.IVersionRequest=} [properties] Properties to set
         * @returns {verrpc.VersionRequest} VersionRequest instance
         */
        VersionRequest.create = function create(properties) {
            return new VersionRequest(properties);
        };

        /**
         * Encodes the specified VersionRequest message. Does not implicitly {@link verrpc.VersionRequest.verify|verify} messages.
         * @function encode
         * @memberof verrpc.VersionRequest
         * @static
         * @param {verrpc.IVersionRequest} message VersionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VersionRequest message, length delimited. Does not implicitly {@link verrpc.VersionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof verrpc.VersionRequest
         * @static
         * @param {verrpc.IVersionRequest} message VersionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VersionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VersionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof verrpc.VersionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {verrpc.VersionRequest} VersionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.verrpc.VersionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VersionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof verrpc.VersionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {verrpc.VersionRequest} VersionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VersionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VersionRequest message.
         * @function verify
         * @memberof verrpc.VersionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VersionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VersionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof verrpc.VersionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {verrpc.VersionRequest} VersionRequest
         */
        VersionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.verrpc.VersionRequest)
                return object;
            return new $root.verrpc.VersionRequest();
        };

        /**
         * Creates a plain object from a VersionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof verrpc.VersionRequest
         * @static
         * @param {verrpc.VersionRequest} message VersionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VersionRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VersionRequest to JSON.
         * @function toJSON
         * @memberof verrpc.VersionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VersionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VersionRequest;
    })();

    verrpc.Version = (function() {

        /**
         * Properties of a Version.
         * @memberof verrpc
         * @interface IVersion
         * @property {string|null} [commit] Version commit
         * @property {string|null} [commitHash] Version commitHash
         * @property {string|null} [version] Version version
         * @property {number|null} [appMajor] Version appMajor
         * @property {number|null} [appMinor] Version appMinor
         * @property {number|null} [appPatch] Version appPatch
         * @property {string|null} [appPreRelease] Version appPreRelease
         * @property {Array.<string>|null} [buildTags] Version buildTags
         * @property {string|null} [goVersion] Version goVersion
         */

        /**
         * Constructs a new Version.
         * @memberof verrpc
         * @classdesc Represents a Version.
         * @implements IVersion
         * @constructor
         * @param {verrpc.IVersion=} [properties] Properties to set
         */
        function Version(properties) {
            this.buildTags = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Version commit.
         * @member {string} commit
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.commit = "";

        /**
         * Version commitHash.
         * @member {string} commitHash
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.commitHash = "";

        /**
         * Version version.
         * @member {string} version
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.version = "";

        /**
         * Version appMajor.
         * @member {number} appMajor
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.appMajor = 0;

        /**
         * Version appMinor.
         * @member {number} appMinor
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.appMinor = 0;

        /**
         * Version appPatch.
         * @member {number} appPatch
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.appPatch = 0;

        /**
         * Version appPreRelease.
         * @member {string} appPreRelease
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.appPreRelease = "";

        /**
         * Version buildTags.
         * @member {Array.<string>} buildTags
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.buildTags = $util.emptyArray;

        /**
         * Version goVersion.
         * @member {string} goVersion
         * @memberof verrpc.Version
         * @instance
         */
        Version.prototype.goVersion = "";

        /**
         * Creates a new Version instance using the specified properties.
         * @function create
         * @memberof verrpc.Version
         * @static
         * @param {verrpc.IVersion=} [properties] Properties to set
         * @returns {verrpc.Version} Version instance
         */
        Version.create = function create(properties) {
            return new Version(properties);
        };

        /**
         * Encodes the specified Version message. Does not implicitly {@link verrpc.Version.verify|verify} messages.
         * @function encode
         * @memberof verrpc.Version
         * @static
         * @param {verrpc.IVersion} message Version message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Version.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commit != null && Object.hasOwnProperty.call(message, "commit"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.commit);
            if (message.commitHash != null && Object.hasOwnProperty.call(message, "commitHash"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.commitHash);
            if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.version);
            if (message.appMajor != null && Object.hasOwnProperty.call(message, "appMajor"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.appMajor);
            if (message.appMinor != null && Object.hasOwnProperty.call(message, "appMinor"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.appMinor);
            if (message.appPatch != null && Object.hasOwnProperty.call(message, "appPatch"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.appPatch);
            if (message.appPreRelease != null && Object.hasOwnProperty.call(message, "appPreRelease"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.appPreRelease);
            if (message.buildTags != null && message.buildTags.length)
                for (let i = 0; i < message.buildTags.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.buildTags[i]);
            if (message.goVersion != null && Object.hasOwnProperty.call(message, "goVersion"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.goVersion);
            return writer;
        };

        /**
         * Encodes the specified Version message, length delimited. Does not implicitly {@link verrpc.Version.verify|verify} messages.
         * @function encodeDelimited
         * @memberof verrpc.Version
         * @static
         * @param {verrpc.IVersion} message Version message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Version.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Version message from the specified reader or buffer.
         * @function decode
         * @memberof verrpc.Version
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {verrpc.Version} Version
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Version.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.verrpc.Version();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commit = reader.string();
                        break;
                    }
                case 2: {
                        message.commitHash = reader.string();
                        break;
                    }
                case 3: {
                        message.version = reader.string();
                        break;
                    }
                case 4: {
                        message.appMajor = reader.uint32();
                        break;
                    }
                case 5: {
                        message.appMinor = reader.uint32();
                        break;
                    }
                case 6: {
                        message.appPatch = reader.uint32();
                        break;
                    }
                case 7: {
                        message.appPreRelease = reader.string();
                        break;
                    }
                case 8: {
                        if (!(message.buildTags && message.buildTags.length))
                            message.buildTags = [];
                        message.buildTags.push(reader.string());
                        break;
                    }
                case 9: {
                        message.goVersion = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Version message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof verrpc.Version
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {verrpc.Version} Version
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Version.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Version message.
         * @function verify
         * @memberof verrpc.Version
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Version.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commit != null && message.hasOwnProperty("commit"))
                if (!$util.isString(message.commit))
                    return "commit: string expected";
            if (message.commitHash != null && message.hasOwnProperty("commitHash"))
                if (!$util.isString(message.commitHash))
                    return "commitHash: string expected";
            if (message.version != null && message.hasOwnProperty("version"))
                if (!$util.isString(message.version))
                    return "version: string expected";
            if (message.appMajor != null && message.hasOwnProperty("appMajor"))
                if (!$util.isInteger(message.appMajor))
                    return "appMajor: integer expected";
            if (message.appMinor != null && message.hasOwnProperty("appMinor"))
                if (!$util.isInteger(message.appMinor))
                    return "appMinor: integer expected";
            if (message.appPatch != null && message.hasOwnProperty("appPatch"))
                if (!$util.isInteger(message.appPatch))
                    return "appPatch: integer expected";
            if (message.appPreRelease != null && message.hasOwnProperty("appPreRelease"))
                if (!$util.isString(message.appPreRelease))
                    return "appPreRelease: string expected";
            if (message.buildTags != null && message.hasOwnProperty("buildTags")) {
                if (!Array.isArray(message.buildTags))
                    return "buildTags: array expected";
                for (let i = 0; i < message.buildTags.length; ++i)
                    if (!$util.isString(message.buildTags[i]))
                        return "buildTags: string[] expected";
            }
            if (message.goVersion != null && message.hasOwnProperty("goVersion"))
                if (!$util.isString(message.goVersion))
                    return "goVersion: string expected";
            return null;
        };

        /**
         * Creates a Version message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof verrpc.Version
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {verrpc.Version} Version
         */
        Version.fromObject = function fromObject(object) {
            if (object instanceof $root.verrpc.Version)
                return object;
            let message = new $root.verrpc.Version();
            if (object.commit != null)
                message.commit = String(object.commit);
            if (object.commitHash != null)
                message.commitHash = String(object.commitHash);
            if (object.version != null)
                message.version = String(object.version);
            if (object.appMajor != null)
                message.appMajor = object.appMajor >>> 0;
            if (object.appMinor != null)
                message.appMinor = object.appMinor >>> 0;
            if (object.appPatch != null)
                message.appPatch = object.appPatch >>> 0;
            if (object.appPreRelease != null)
                message.appPreRelease = String(object.appPreRelease);
            if (object.buildTags) {
                if (!Array.isArray(object.buildTags))
                    throw TypeError(".verrpc.Version.buildTags: array expected");
                message.buildTags = [];
                for (let i = 0; i < object.buildTags.length; ++i)
                    message.buildTags[i] = String(object.buildTags[i]);
            }
            if (object.goVersion != null)
                message.goVersion = String(object.goVersion);
            return message;
        };

        /**
         * Creates a plain object from a Version message. Also converts values to other types if specified.
         * @function toObject
         * @memberof verrpc.Version
         * @static
         * @param {verrpc.Version} message Version
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Version.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.buildTags = [];
            if (options.defaults) {
                object.commit = "";
                object.commitHash = "";
                object.version = "";
                object.appMajor = 0;
                object.appMinor = 0;
                object.appPatch = 0;
                object.appPreRelease = "";
                object.goVersion = "";
            }
            if (message.commit != null && message.hasOwnProperty("commit"))
                object.commit = message.commit;
            if (message.commitHash != null && message.hasOwnProperty("commitHash"))
                object.commitHash = message.commitHash;
            if (message.version != null && message.hasOwnProperty("version"))
                object.version = message.version;
            if (message.appMajor != null && message.hasOwnProperty("appMajor"))
                object.appMajor = message.appMajor;
            if (message.appMinor != null && message.hasOwnProperty("appMinor"))
                object.appMinor = message.appMinor;
            if (message.appPatch != null && message.hasOwnProperty("appPatch"))
                object.appPatch = message.appPatch;
            if (message.appPreRelease != null && message.hasOwnProperty("appPreRelease"))
                object.appPreRelease = message.appPreRelease;
            if (message.buildTags && message.buildTags.length) {
                object.buildTags = [];
                for (let j = 0; j < message.buildTags.length; ++j)
                    object.buildTags[j] = message.buildTags[j];
            }
            if (message.goVersion != null && message.hasOwnProperty("goVersion"))
                object.goVersion = message.goVersion;
            return object;
        };

        /**
         * Converts this Version to JSON.
         * @function toJSON
         * @memberof verrpc.Version
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Version.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Version;
    })();

    return verrpc;
})();

export const walletrpc = $root.walletrpc = (() => {

    /**
     * Namespace walletrpc.
     * @exports walletrpc
     * @namespace
     */
    const walletrpc = {};

    walletrpc.WalletKit = (function() {

        /**
         * Constructs a new WalletKit service.
         * @memberof walletrpc
         * @classdesc Represents a WalletKit
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function WalletKit(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (WalletKit.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WalletKit;

        /**
         * Creates new WalletKit service using the specified rpc implementation.
         * @function create
         * @memberof walletrpc.WalletKit
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {WalletKit} RPC service. Useful where requests and/or responses are streamed.
         */
        WalletKit.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link walletrpc.WalletKit#listUnspent}.
         * @memberof walletrpc.WalletKit
         * @typedef ListUnspentCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ListUnspentResponse} [response] ListUnspentResponse
         */

        /**
         * Calls ListUnspent.
         * @function listUnspent
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListUnspentRequest} request ListUnspentRequest message or plain object
         * @param {walletrpc.WalletKit.ListUnspentCallback} callback Node-style callback called with the error, if any, and ListUnspentResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.listUnspent = function listUnspent(request, callback) {
            return this.rpcCall(listUnspent, $root.walletrpc.ListUnspentRequest, $root.walletrpc.ListUnspentResponse, request, callback);
        }, "name", { value: "ListUnspent" });

        /**
         * Calls ListUnspent.
         * @function listUnspent
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListUnspentRequest} request ListUnspentRequest message or plain object
         * @returns {Promise<walletrpc.ListUnspentResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#leaseOutput}.
         * @memberof walletrpc.WalletKit
         * @typedef LeaseOutputCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.LeaseOutputResponse} [response] LeaseOutputResponse
         */

        /**
         * Calls LeaseOutput.
         * @function leaseOutput
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ILeaseOutputRequest} request LeaseOutputRequest message or plain object
         * @param {walletrpc.WalletKit.LeaseOutputCallback} callback Node-style callback called with the error, if any, and LeaseOutputResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.leaseOutput = function leaseOutput(request, callback) {
            return this.rpcCall(leaseOutput, $root.walletrpc.LeaseOutputRequest, $root.walletrpc.LeaseOutputResponse, request, callback);
        }, "name", { value: "LeaseOutput" });

        /**
         * Calls LeaseOutput.
         * @function leaseOutput
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ILeaseOutputRequest} request LeaseOutputRequest message or plain object
         * @returns {Promise<walletrpc.LeaseOutputResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#releaseOutput}.
         * @memberof walletrpc.WalletKit
         * @typedef ReleaseOutputCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ReleaseOutputResponse} [response] ReleaseOutputResponse
         */

        /**
         * Calls ReleaseOutput.
         * @function releaseOutput
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IReleaseOutputRequest} request ReleaseOutputRequest message or plain object
         * @param {walletrpc.WalletKit.ReleaseOutputCallback} callback Node-style callback called with the error, if any, and ReleaseOutputResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.releaseOutput = function releaseOutput(request, callback) {
            return this.rpcCall(releaseOutput, $root.walletrpc.ReleaseOutputRequest, $root.walletrpc.ReleaseOutputResponse, request, callback);
        }, "name", { value: "ReleaseOutput" });

        /**
         * Calls ReleaseOutput.
         * @function releaseOutput
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IReleaseOutputRequest} request ReleaseOutputRequest message or plain object
         * @returns {Promise<walletrpc.ReleaseOutputResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#listLeases}.
         * @memberof walletrpc.WalletKit
         * @typedef ListLeasesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ListLeasesResponse} [response] ListLeasesResponse
         */

        /**
         * Calls ListLeases.
         * @function listLeases
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListLeasesRequest} request ListLeasesRequest message or plain object
         * @param {walletrpc.WalletKit.ListLeasesCallback} callback Node-style callback called with the error, if any, and ListLeasesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.listLeases = function listLeases(request, callback) {
            return this.rpcCall(listLeases, $root.walletrpc.ListLeasesRequest, $root.walletrpc.ListLeasesResponse, request, callback);
        }, "name", { value: "ListLeases" });

        /**
         * Calls ListLeases.
         * @function listLeases
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListLeasesRequest} request ListLeasesRequest message or plain object
         * @returns {Promise<walletrpc.ListLeasesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#deriveNextKey}.
         * @memberof walletrpc.WalletKit
         * @typedef DeriveNextKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.KeyDescriptor} [response] KeyDescriptor
         */

        /**
         * Calls DeriveNextKey.
         * @function deriveNextKey
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IKeyReq} request KeyReq message or plain object
         * @param {walletrpc.WalletKit.DeriveNextKeyCallback} callback Node-style callback called with the error, if any, and KeyDescriptor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.deriveNextKey = function deriveNextKey(request, callback) {
            return this.rpcCall(deriveNextKey, $root.walletrpc.KeyReq, $root.signrpc.KeyDescriptor, request, callback);
        }, "name", { value: "DeriveNextKey" });

        /**
         * Calls DeriveNextKey.
         * @function deriveNextKey
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IKeyReq} request KeyReq message or plain object
         * @returns {Promise<signrpc.KeyDescriptor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#deriveKey}.
         * @memberof walletrpc.WalletKit
         * @typedef DeriveKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {signrpc.KeyDescriptor} [response] KeyDescriptor
         */

        /**
         * Calls DeriveKey.
         * @function deriveKey
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {signrpc.IKeyLocator} request KeyLocator message or plain object
         * @param {walletrpc.WalletKit.DeriveKeyCallback} callback Node-style callback called with the error, if any, and KeyDescriptor
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.deriveKey = function deriveKey(request, callback) {
            return this.rpcCall(deriveKey, $root.signrpc.KeyLocator, $root.signrpc.KeyDescriptor, request, callback);
        }, "name", { value: "DeriveKey" });

        /**
         * Calls DeriveKey.
         * @function deriveKey
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {signrpc.IKeyLocator} request KeyLocator message or plain object
         * @returns {Promise<signrpc.KeyDescriptor>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#nextAddr}.
         * @memberof walletrpc.WalletKit
         * @typedef NextAddrCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.AddrResponse} [response] AddrResponse
         */

        /**
         * Calls NextAddr.
         * @function nextAddr
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IAddrRequest} request AddrRequest message or plain object
         * @param {walletrpc.WalletKit.NextAddrCallback} callback Node-style callback called with the error, if any, and AddrResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.nextAddr = function nextAddr(request, callback) {
            return this.rpcCall(nextAddr, $root.walletrpc.AddrRequest, $root.walletrpc.AddrResponse, request, callback);
        }, "name", { value: "NextAddr" });

        /**
         * Calls NextAddr.
         * @function nextAddr
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IAddrRequest} request AddrRequest message or plain object
         * @returns {Promise<walletrpc.AddrResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#listAccounts}.
         * @memberof walletrpc.WalletKit
         * @typedef ListAccountsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ListAccountsResponse} [response] ListAccountsResponse
         */

        /**
         * Calls ListAccounts.
         * @function listAccounts
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListAccountsRequest} request ListAccountsRequest message or plain object
         * @param {walletrpc.WalletKit.ListAccountsCallback} callback Node-style callback called with the error, if any, and ListAccountsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.listAccounts = function listAccounts(request, callback) {
            return this.rpcCall(listAccounts, $root.walletrpc.ListAccountsRequest, $root.walletrpc.ListAccountsResponse, request, callback);
        }, "name", { value: "ListAccounts" });

        /**
         * Calls ListAccounts.
         * @function listAccounts
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListAccountsRequest} request ListAccountsRequest message or plain object
         * @returns {Promise<walletrpc.ListAccountsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#requiredReserve}.
         * @memberof walletrpc.WalletKit
         * @typedef RequiredReserveCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.RequiredReserveResponse} [response] RequiredReserveResponse
         */

        /**
         * Calls RequiredReserve.
         * @function requiredReserve
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IRequiredReserveRequest} request RequiredReserveRequest message or plain object
         * @param {walletrpc.WalletKit.RequiredReserveCallback} callback Node-style callback called with the error, if any, and RequiredReserveResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.requiredReserve = function requiredReserve(request, callback) {
            return this.rpcCall(requiredReserve, $root.walletrpc.RequiredReserveRequest, $root.walletrpc.RequiredReserveResponse, request, callback);
        }, "name", { value: "RequiredReserve" });

        /**
         * Calls RequiredReserve.
         * @function requiredReserve
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IRequiredReserveRequest} request RequiredReserveRequest message or plain object
         * @returns {Promise<walletrpc.RequiredReserveResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#importAccount}.
         * @memberof walletrpc.WalletKit
         * @typedef ImportAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ImportAccountResponse} [response] ImportAccountResponse
         */

        /**
         * Calls ImportAccount.
         * @function importAccount
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IImportAccountRequest} request ImportAccountRequest message or plain object
         * @param {walletrpc.WalletKit.ImportAccountCallback} callback Node-style callback called with the error, if any, and ImportAccountResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.importAccount = function importAccount(request, callback) {
            return this.rpcCall(importAccount, $root.walletrpc.ImportAccountRequest, $root.walletrpc.ImportAccountResponse, request, callback);
        }, "name", { value: "ImportAccount" });

        /**
         * Calls ImportAccount.
         * @function importAccount
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IImportAccountRequest} request ImportAccountRequest message or plain object
         * @returns {Promise<walletrpc.ImportAccountResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#importPublicKey}.
         * @memberof walletrpc.WalletKit
         * @typedef ImportPublicKeyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ImportPublicKeyResponse} [response] ImportPublicKeyResponse
         */

        /**
         * Calls ImportPublicKey.
         * @function importPublicKey
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IImportPublicKeyRequest} request ImportPublicKeyRequest message or plain object
         * @param {walletrpc.WalletKit.ImportPublicKeyCallback} callback Node-style callback called with the error, if any, and ImportPublicKeyResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.importPublicKey = function importPublicKey(request, callback) {
            return this.rpcCall(importPublicKey, $root.walletrpc.ImportPublicKeyRequest, $root.walletrpc.ImportPublicKeyResponse, request, callback);
        }, "name", { value: "ImportPublicKey" });

        /**
         * Calls ImportPublicKey.
         * @function importPublicKey
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IImportPublicKeyRequest} request ImportPublicKeyRequest message or plain object
         * @returns {Promise<walletrpc.ImportPublicKeyResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#publishTransaction}.
         * @memberof walletrpc.WalletKit
         * @typedef PublishTransactionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.PublishResponse} [response] PublishResponse
         */

        /**
         * Calls PublishTransaction.
         * @function publishTransaction
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ITransaction} request Transaction message or plain object
         * @param {walletrpc.WalletKit.PublishTransactionCallback} callback Node-style callback called with the error, if any, and PublishResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.publishTransaction = function publishTransaction(request, callback) {
            return this.rpcCall(publishTransaction, $root.walletrpc.Transaction, $root.walletrpc.PublishResponse, request, callback);
        }, "name", { value: "PublishTransaction" });

        /**
         * Calls PublishTransaction.
         * @function publishTransaction
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ITransaction} request Transaction message or plain object
         * @returns {Promise<walletrpc.PublishResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#sendOutputs}.
         * @memberof walletrpc.WalletKit
         * @typedef SendOutputsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.SendOutputsResponse} [response] SendOutputsResponse
         */

        /**
         * Calls SendOutputs.
         * @function sendOutputs
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ISendOutputsRequest} request SendOutputsRequest message or plain object
         * @param {walletrpc.WalletKit.SendOutputsCallback} callback Node-style callback called with the error, if any, and SendOutputsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.sendOutputs = function sendOutputs(request, callback) {
            return this.rpcCall(sendOutputs, $root.walletrpc.SendOutputsRequest, $root.walletrpc.SendOutputsResponse, request, callback);
        }, "name", { value: "SendOutputs" });

        /**
         * Calls SendOutputs.
         * @function sendOutputs
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ISendOutputsRequest} request SendOutputsRequest message or plain object
         * @returns {Promise<walletrpc.SendOutputsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#estimateFee}.
         * @memberof walletrpc.WalletKit
         * @typedef EstimateFeeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.EstimateFeeResponse} [response] EstimateFeeResponse
         */

        /**
         * Calls EstimateFee.
         * @function estimateFee
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IEstimateFeeRequest} request EstimateFeeRequest message or plain object
         * @param {walletrpc.WalletKit.EstimateFeeCallback} callback Node-style callback called with the error, if any, and EstimateFeeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.estimateFee = function estimateFee(request, callback) {
            return this.rpcCall(estimateFee, $root.walletrpc.EstimateFeeRequest, $root.walletrpc.EstimateFeeResponse, request, callback);
        }, "name", { value: "EstimateFee" });

        /**
         * Calls EstimateFee.
         * @function estimateFee
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IEstimateFeeRequest} request EstimateFeeRequest message or plain object
         * @returns {Promise<walletrpc.EstimateFeeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#pendingSweeps}.
         * @memberof walletrpc.WalletKit
         * @typedef PendingSweepsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.PendingSweepsResponse} [response] PendingSweepsResponse
         */

        /**
         * Calls PendingSweeps.
         * @function pendingSweeps
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IPendingSweepsRequest} request PendingSweepsRequest message or plain object
         * @param {walletrpc.WalletKit.PendingSweepsCallback} callback Node-style callback called with the error, if any, and PendingSweepsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.pendingSweeps = function pendingSweeps(request, callback) {
            return this.rpcCall(pendingSweeps, $root.walletrpc.PendingSweepsRequest, $root.walletrpc.PendingSweepsResponse, request, callback);
        }, "name", { value: "PendingSweeps" });

        /**
         * Calls PendingSweeps.
         * @function pendingSweeps
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IPendingSweepsRequest} request PendingSweepsRequest message or plain object
         * @returns {Promise<walletrpc.PendingSweepsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#bumpFee}.
         * @memberof walletrpc.WalletKit
         * @typedef BumpFeeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.BumpFeeResponse} [response] BumpFeeResponse
         */

        /**
         * Calls BumpFee.
         * @function bumpFee
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IBumpFeeRequest} request BumpFeeRequest message or plain object
         * @param {walletrpc.WalletKit.BumpFeeCallback} callback Node-style callback called with the error, if any, and BumpFeeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.bumpFee = function bumpFee(request, callback) {
            return this.rpcCall(bumpFee, $root.walletrpc.BumpFeeRequest, $root.walletrpc.BumpFeeResponse, request, callback);
        }, "name", { value: "BumpFee" });

        /**
         * Calls BumpFee.
         * @function bumpFee
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IBumpFeeRequest} request BumpFeeRequest message or plain object
         * @returns {Promise<walletrpc.BumpFeeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#listSweeps}.
         * @memberof walletrpc.WalletKit
         * @typedef ListSweepsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.ListSweepsResponse} [response] ListSweepsResponse
         */

        /**
         * Calls ListSweeps.
         * @function listSweeps
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListSweepsRequest} request ListSweepsRequest message or plain object
         * @param {walletrpc.WalletKit.ListSweepsCallback} callback Node-style callback called with the error, if any, and ListSweepsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.listSweeps = function listSweeps(request, callback) {
            return this.rpcCall(listSweeps, $root.walletrpc.ListSweepsRequest, $root.walletrpc.ListSweepsResponse, request, callback);
        }, "name", { value: "ListSweeps" });

        /**
         * Calls ListSweeps.
         * @function listSweeps
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IListSweepsRequest} request ListSweepsRequest message or plain object
         * @returns {Promise<walletrpc.ListSweepsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#labelTransaction}.
         * @memberof walletrpc.WalletKit
         * @typedef LabelTransactionCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.LabelTransactionResponse} [response] LabelTransactionResponse
         */

        /**
         * Calls LabelTransaction.
         * @function labelTransaction
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ILabelTransactionRequest} request LabelTransactionRequest message or plain object
         * @param {walletrpc.WalletKit.LabelTransactionCallback} callback Node-style callback called with the error, if any, and LabelTransactionResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.labelTransaction = function labelTransaction(request, callback) {
            return this.rpcCall(labelTransaction, $root.walletrpc.LabelTransactionRequest, $root.walletrpc.LabelTransactionResponse, request, callback);
        }, "name", { value: "LabelTransaction" });

        /**
         * Calls LabelTransaction.
         * @function labelTransaction
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ILabelTransactionRequest} request LabelTransactionRequest message or plain object
         * @returns {Promise<walletrpc.LabelTransactionResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#fundPsbt}.
         * @memberof walletrpc.WalletKit
         * @typedef FundPsbtCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.FundPsbtResponse} [response] FundPsbtResponse
         */

        /**
         * Calls FundPsbt.
         * @function fundPsbt
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IFundPsbtRequest} request FundPsbtRequest message or plain object
         * @param {walletrpc.WalletKit.FundPsbtCallback} callback Node-style callback called with the error, if any, and FundPsbtResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.fundPsbt = function fundPsbt(request, callback) {
            return this.rpcCall(fundPsbt, $root.walletrpc.FundPsbtRequest, $root.walletrpc.FundPsbtResponse, request, callback);
        }, "name", { value: "FundPsbt" });

        /**
         * Calls FundPsbt.
         * @function fundPsbt
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IFundPsbtRequest} request FundPsbtRequest message or plain object
         * @returns {Promise<walletrpc.FundPsbtResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#signPsbt}.
         * @memberof walletrpc.WalletKit
         * @typedef SignPsbtCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.SignPsbtResponse} [response] SignPsbtResponse
         */

        /**
         * Calls SignPsbt.
         * @function signPsbt
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ISignPsbtRequest} request SignPsbtRequest message or plain object
         * @param {walletrpc.WalletKit.SignPsbtCallback} callback Node-style callback called with the error, if any, and SignPsbtResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.signPsbt = function signPsbt(request, callback) {
            return this.rpcCall(signPsbt, $root.walletrpc.SignPsbtRequest, $root.walletrpc.SignPsbtResponse, request, callback);
        }, "name", { value: "SignPsbt" });

        /**
         * Calls SignPsbt.
         * @function signPsbt
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.ISignPsbtRequest} request SignPsbtRequest message or plain object
         * @returns {Promise<walletrpc.SignPsbtResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link walletrpc.WalletKit#finalizePsbt}.
         * @memberof walletrpc.WalletKit
         * @typedef FinalizePsbtCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {walletrpc.FinalizePsbtResponse} [response] FinalizePsbtResponse
         */

        /**
         * Calls FinalizePsbt.
         * @function finalizePsbt
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IFinalizePsbtRequest} request FinalizePsbtRequest message or plain object
         * @param {walletrpc.WalletKit.FinalizePsbtCallback} callback Node-style callback called with the error, if any, and FinalizePsbtResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WalletKit.prototype.finalizePsbt = function finalizePsbt(request, callback) {
            return this.rpcCall(finalizePsbt, $root.walletrpc.FinalizePsbtRequest, $root.walletrpc.FinalizePsbtResponse, request, callback);
        }, "name", { value: "FinalizePsbt" });

        /**
         * Calls FinalizePsbt.
         * @function finalizePsbt
         * @memberof walletrpc.WalletKit
         * @instance
         * @param {walletrpc.IFinalizePsbtRequest} request FinalizePsbtRequest message or plain object
         * @returns {Promise<walletrpc.FinalizePsbtResponse>} Promise
         * @variation 2
         */

        return WalletKit;
    })();

    walletrpc.ListUnspentRequest = (function() {

        /**
         * Properties of a ListUnspentRequest.
         * @memberof walletrpc
         * @interface IListUnspentRequest
         * @property {number|null} [minConfs] ListUnspentRequest minConfs
         * @property {number|null} [maxConfs] ListUnspentRequest maxConfs
         * @property {string|null} [account] ListUnspentRequest account
         * @property {boolean|null} [unconfirmedOnly] ListUnspentRequest unconfirmedOnly
         */

        /**
         * Constructs a new ListUnspentRequest.
         * @memberof walletrpc
         * @classdesc Represents a ListUnspentRequest.
         * @implements IListUnspentRequest
         * @constructor
         * @param {walletrpc.IListUnspentRequest=} [properties] Properties to set
         */
        function ListUnspentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListUnspentRequest minConfs.
         * @member {number} minConfs
         * @memberof walletrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.minConfs = 0;

        /**
         * ListUnspentRequest maxConfs.
         * @member {number} maxConfs
         * @memberof walletrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.maxConfs = 0;

        /**
         * ListUnspentRequest account.
         * @member {string} account
         * @memberof walletrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.account = "";

        /**
         * ListUnspentRequest unconfirmedOnly.
         * @member {boolean} unconfirmedOnly
         * @memberof walletrpc.ListUnspentRequest
         * @instance
         */
        ListUnspentRequest.prototype.unconfirmedOnly = false;

        /**
         * Creates a new ListUnspentRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {walletrpc.IListUnspentRequest=} [properties] Properties to set
         * @returns {walletrpc.ListUnspentRequest} ListUnspentRequest instance
         */
        ListUnspentRequest.create = function create(properties) {
            return new ListUnspentRequest(properties);
        };

        /**
         * Encodes the specified ListUnspentRequest message. Does not implicitly {@link walletrpc.ListUnspentRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {walletrpc.IListUnspentRequest} message ListUnspentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.minConfs);
            if (message.maxConfs != null && Object.hasOwnProperty.call(message, "maxConfs"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxConfs);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.account);
            if (message.unconfirmedOnly != null && Object.hasOwnProperty.call(message, "unconfirmedOnly"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.unconfirmedOnly);
            return writer;
        };

        /**
         * Encodes the specified ListUnspentRequest message, length delimited. Does not implicitly {@link walletrpc.ListUnspentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {walletrpc.IListUnspentRequest} message ListUnspentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListUnspentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListUnspentRequest} ListUnspentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListUnspentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 2: {
                        message.maxConfs = reader.int32();
                        break;
                    }
                case 3: {
                        message.account = reader.string();
                        break;
                    }
                case 4: {
                        message.unconfirmedOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListUnspentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListUnspentRequest} ListUnspentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListUnspentRequest message.
         * @function verify
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListUnspentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.maxConfs != null && message.hasOwnProperty("maxConfs"))
                if (!$util.isInteger(message.maxConfs))
                    return "maxConfs: integer expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            if (message.unconfirmedOnly != null && message.hasOwnProperty("unconfirmedOnly"))
                if (typeof message.unconfirmedOnly !== "boolean")
                    return "unconfirmedOnly: boolean expected";
            return null;
        };

        /**
         * Creates a ListUnspentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListUnspentRequest} ListUnspentRequest
         */
        ListUnspentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListUnspentRequest)
                return object;
            let message = new $root.walletrpc.ListUnspentRequest();
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.maxConfs != null)
                message.maxConfs = object.maxConfs | 0;
            if (object.account != null)
                message.account = String(object.account);
            if (object.unconfirmedOnly != null)
                message.unconfirmedOnly = Boolean(object.unconfirmedOnly);
            return message;
        };

        /**
         * Creates a plain object from a ListUnspentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListUnspentRequest
         * @static
         * @param {walletrpc.ListUnspentRequest} message ListUnspentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListUnspentRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.minConfs = 0;
                object.maxConfs = 0;
                object.account = "";
                object.unconfirmedOnly = false;
            }
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.maxConfs != null && message.hasOwnProperty("maxConfs"))
                object.maxConfs = message.maxConfs;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            if (message.unconfirmedOnly != null && message.hasOwnProperty("unconfirmedOnly"))
                object.unconfirmedOnly = message.unconfirmedOnly;
            return object;
        };

        /**
         * Converts this ListUnspentRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ListUnspentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListUnspentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListUnspentRequest;
    })();

    walletrpc.ListUnspentResponse = (function() {

        /**
         * Properties of a ListUnspentResponse.
         * @memberof walletrpc
         * @interface IListUnspentResponse
         * @property {Array.<lnrpc.IUtxo>|null} [utxos] ListUnspentResponse utxos
         */

        /**
         * Constructs a new ListUnspentResponse.
         * @memberof walletrpc
         * @classdesc Represents a ListUnspentResponse.
         * @implements IListUnspentResponse
         * @constructor
         * @param {walletrpc.IListUnspentResponse=} [properties] Properties to set
         */
        function ListUnspentResponse(properties) {
            this.utxos = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListUnspentResponse utxos.
         * @member {Array.<lnrpc.IUtxo>} utxos
         * @memberof walletrpc.ListUnspentResponse
         * @instance
         */
        ListUnspentResponse.prototype.utxos = $util.emptyArray;

        /**
         * Creates a new ListUnspentResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {walletrpc.IListUnspentResponse=} [properties] Properties to set
         * @returns {walletrpc.ListUnspentResponse} ListUnspentResponse instance
         */
        ListUnspentResponse.create = function create(properties) {
            return new ListUnspentResponse(properties);
        };

        /**
         * Encodes the specified ListUnspentResponse message. Does not implicitly {@link walletrpc.ListUnspentResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {walletrpc.IListUnspentResponse} message ListUnspentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.utxos != null && message.utxos.length)
                for (let i = 0; i < message.utxos.length; ++i)
                    $root.lnrpc.Utxo.encode(message.utxos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListUnspentResponse message, length delimited. Does not implicitly {@link walletrpc.ListUnspentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {walletrpc.IListUnspentResponse} message ListUnspentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListUnspentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListUnspentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListUnspentResponse} ListUnspentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListUnspentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.utxos && message.utxos.length))
                            message.utxos = [];
                        message.utxos.push($root.lnrpc.Utxo.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListUnspentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListUnspentResponse} ListUnspentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListUnspentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListUnspentResponse message.
         * @function verify
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListUnspentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.utxos != null && message.hasOwnProperty("utxos")) {
                if (!Array.isArray(message.utxos))
                    return "utxos: array expected";
                for (let i = 0; i < message.utxos.length; ++i) {
                    let error = $root.lnrpc.Utxo.verify(message.utxos[i]);
                    if (error)
                        return "utxos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListUnspentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListUnspentResponse} ListUnspentResponse
         */
        ListUnspentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListUnspentResponse)
                return object;
            let message = new $root.walletrpc.ListUnspentResponse();
            if (object.utxos) {
                if (!Array.isArray(object.utxos))
                    throw TypeError(".walletrpc.ListUnspentResponse.utxos: array expected");
                message.utxos = [];
                for (let i = 0; i < object.utxos.length; ++i) {
                    if (typeof object.utxos[i] !== "object")
                        throw TypeError(".walletrpc.ListUnspentResponse.utxos: object expected");
                    message.utxos[i] = $root.lnrpc.Utxo.fromObject(object.utxos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListUnspentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListUnspentResponse
         * @static
         * @param {walletrpc.ListUnspentResponse} message ListUnspentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListUnspentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.utxos = [];
            if (message.utxos && message.utxos.length) {
                object.utxos = [];
                for (let j = 0; j < message.utxos.length; ++j)
                    object.utxos[j] = $root.lnrpc.Utxo.toObject(message.utxos[j], options);
            }
            return object;
        };

        /**
         * Converts this ListUnspentResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ListUnspentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListUnspentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListUnspentResponse;
    })();

    walletrpc.LeaseOutputRequest = (function() {

        /**
         * Properties of a LeaseOutputRequest.
         * @memberof walletrpc
         * @interface ILeaseOutputRequest
         * @property {Uint8Array|null} [id] LeaseOutputRequest id
         * @property {lnrpc.IOutPoint|null} [outpoint] LeaseOutputRequest outpoint
         * @property {Long|null} [expirationSeconds] LeaseOutputRequest expirationSeconds
         */

        /**
         * Constructs a new LeaseOutputRequest.
         * @memberof walletrpc
         * @classdesc Represents a LeaseOutputRequest.
         * @implements ILeaseOutputRequest
         * @constructor
         * @param {walletrpc.ILeaseOutputRequest=} [properties] Properties to set
         */
        function LeaseOutputRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LeaseOutputRequest id.
         * @member {Uint8Array} id
         * @memberof walletrpc.LeaseOutputRequest
         * @instance
         */
        LeaseOutputRequest.prototype.id = $util.newBuffer([]);

        /**
         * LeaseOutputRequest outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof walletrpc.LeaseOutputRequest
         * @instance
         */
        LeaseOutputRequest.prototype.outpoint = null;

        /**
         * LeaseOutputRequest expirationSeconds.
         * @member {Long} expirationSeconds
         * @memberof walletrpc.LeaseOutputRequest
         * @instance
         */
        LeaseOutputRequest.prototype.expirationSeconds = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new LeaseOutputRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {walletrpc.ILeaseOutputRequest=} [properties] Properties to set
         * @returns {walletrpc.LeaseOutputRequest} LeaseOutputRequest instance
         */
        LeaseOutputRequest.create = function create(properties) {
            return new LeaseOutputRequest(properties);
        };

        /**
         * Encodes the specified LeaseOutputRequest message. Does not implicitly {@link walletrpc.LeaseOutputRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {walletrpc.ILeaseOutputRequest} message LeaseOutputRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaseOutputRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expirationSeconds != null && Object.hasOwnProperty.call(message, "expirationSeconds"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expirationSeconds);
            return writer;
        };

        /**
         * Encodes the specified LeaseOutputRequest message, length delimited. Does not implicitly {@link walletrpc.LeaseOutputRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {walletrpc.ILeaseOutputRequest} message LeaseOutputRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaseOutputRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LeaseOutputRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.LeaseOutputRequest} LeaseOutputRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaseOutputRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.LeaseOutputRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.expirationSeconds = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LeaseOutputRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.LeaseOutputRequest} LeaseOutputRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaseOutputRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LeaseOutputRequest message.
         * @function verify
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LeaseOutputRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.expirationSeconds != null && message.hasOwnProperty("expirationSeconds"))
                if (!$util.isInteger(message.expirationSeconds) && !(message.expirationSeconds && $util.isInteger(message.expirationSeconds.low) && $util.isInteger(message.expirationSeconds.high)))
                    return "expirationSeconds: integer|Long expected";
            return null;
        };

        /**
         * Creates a LeaseOutputRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.LeaseOutputRequest} LeaseOutputRequest
         */
        LeaseOutputRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.LeaseOutputRequest)
                return object;
            let message = new $root.walletrpc.LeaseOutputRequest();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".walletrpc.LeaseOutputRequest.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            if (object.expirationSeconds != null)
                if ($util.Long)
                    (message.expirationSeconds = $util.Long.fromValue(object.expirationSeconds)).unsigned = true;
                else if (typeof object.expirationSeconds === "string")
                    message.expirationSeconds = parseInt(object.expirationSeconds, 10);
                else if (typeof object.expirationSeconds === "number")
                    message.expirationSeconds = object.expirationSeconds;
                else if (typeof object.expirationSeconds === "object")
                    message.expirationSeconds = new $util.LongBits(object.expirationSeconds.low >>> 0, object.expirationSeconds.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a LeaseOutputRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.LeaseOutputRequest
         * @static
         * @param {walletrpc.LeaseOutputRequest} message LeaseOutputRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LeaseOutputRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.outpoint = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expirationSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationSeconds = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.expirationSeconds != null && message.hasOwnProperty("expirationSeconds"))
                if (typeof message.expirationSeconds === "number")
                    object.expirationSeconds = options.longs === String ? String(message.expirationSeconds) : message.expirationSeconds;
                else
                    object.expirationSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.expirationSeconds) : options.longs === Number ? new $util.LongBits(message.expirationSeconds.low >>> 0, message.expirationSeconds.high >>> 0).toNumber(true) : message.expirationSeconds;
            return object;
        };

        /**
         * Converts this LeaseOutputRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.LeaseOutputRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LeaseOutputRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LeaseOutputRequest;
    })();

    walletrpc.LeaseOutputResponse = (function() {

        /**
         * Properties of a LeaseOutputResponse.
         * @memberof walletrpc
         * @interface ILeaseOutputResponse
         * @property {Long|null} [expiration] LeaseOutputResponse expiration
         */

        /**
         * Constructs a new LeaseOutputResponse.
         * @memberof walletrpc
         * @classdesc Represents a LeaseOutputResponse.
         * @implements ILeaseOutputResponse
         * @constructor
         * @param {walletrpc.ILeaseOutputResponse=} [properties] Properties to set
         */
        function LeaseOutputResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LeaseOutputResponse expiration.
         * @member {Long} expiration
         * @memberof walletrpc.LeaseOutputResponse
         * @instance
         */
        LeaseOutputResponse.prototype.expiration = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new LeaseOutputResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {walletrpc.ILeaseOutputResponse=} [properties] Properties to set
         * @returns {walletrpc.LeaseOutputResponse} LeaseOutputResponse instance
         */
        LeaseOutputResponse.create = function create(properties) {
            return new LeaseOutputResponse(properties);
        };

        /**
         * Encodes the specified LeaseOutputResponse message. Does not implicitly {@link walletrpc.LeaseOutputResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {walletrpc.ILeaseOutputResponse} message LeaseOutputResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaseOutputResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.expiration);
            return writer;
        };

        /**
         * Encodes the specified LeaseOutputResponse message, length delimited. Does not implicitly {@link walletrpc.LeaseOutputResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {walletrpc.ILeaseOutputResponse} message LeaseOutputResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaseOutputResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LeaseOutputResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.LeaseOutputResponse} LeaseOutputResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaseOutputResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.LeaseOutputResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.expiration = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LeaseOutputResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.LeaseOutputResponse} LeaseOutputResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaseOutputResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LeaseOutputResponse message.
         * @function verify
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LeaseOutputResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                if (!$util.isInteger(message.expiration) && !(message.expiration && $util.isInteger(message.expiration.low) && $util.isInteger(message.expiration.high)))
                    return "expiration: integer|Long expected";
            return null;
        };

        /**
         * Creates a LeaseOutputResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.LeaseOutputResponse} LeaseOutputResponse
         */
        LeaseOutputResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.LeaseOutputResponse)
                return object;
            let message = new $root.walletrpc.LeaseOutputResponse();
            if (object.expiration != null)
                if ($util.Long)
                    (message.expiration = $util.Long.fromValue(object.expiration)).unsigned = true;
                else if (typeof object.expiration === "string")
                    message.expiration = parseInt(object.expiration, 10);
                else if (typeof object.expiration === "number")
                    message.expiration = object.expiration;
                else if (typeof object.expiration === "object")
                    message.expiration = new $util.LongBits(object.expiration.low >>> 0, object.expiration.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a LeaseOutputResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.LeaseOutputResponse
         * @static
         * @param {walletrpc.LeaseOutputResponse} message LeaseOutputResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LeaseOutputResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiration = options.longs === String ? "0" : 0;
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                if (typeof message.expiration === "number")
                    object.expiration = options.longs === String ? String(message.expiration) : message.expiration;
                else
                    object.expiration = options.longs === String ? $util.Long.prototype.toString.call(message.expiration) : options.longs === Number ? new $util.LongBits(message.expiration.low >>> 0, message.expiration.high >>> 0).toNumber(true) : message.expiration;
            return object;
        };

        /**
         * Converts this LeaseOutputResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.LeaseOutputResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LeaseOutputResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LeaseOutputResponse;
    })();

    walletrpc.ReleaseOutputRequest = (function() {

        /**
         * Properties of a ReleaseOutputRequest.
         * @memberof walletrpc
         * @interface IReleaseOutputRequest
         * @property {Uint8Array|null} [id] ReleaseOutputRequest id
         * @property {lnrpc.IOutPoint|null} [outpoint] ReleaseOutputRequest outpoint
         */

        /**
         * Constructs a new ReleaseOutputRequest.
         * @memberof walletrpc
         * @classdesc Represents a ReleaseOutputRequest.
         * @implements IReleaseOutputRequest
         * @constructor
         * @param {walletrpc.IReleaseOutputRequest=} [properties] Properties to set
         */
        function ReleaseOutputRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ReleaseOutputRequest id.
         * @member {Uint8Array} id
         * @memberof walletrpc.ReleaseOutputRequest
         * @instance
         */
        ReleaseOutputRequest.prototype.id = $util.newBuffer([]);

        /**
         * ReleaseOutputRequest outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof walletrpc.ReleaseOutputRequest
         * @instance
         */
        ReleaseOutputRequest.prototype.outpoint = null;

        /**
         * Creates a new ReleaseOutputRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {walletrpc.IReleaseOutputRequest=} [properties] Properties to set
         * @returns {walletrpc.ReleaseOutputRequest} ReleaseOutputRequest instance
         */
        ReleaseOutputRequest.create = function create(properties) {
            return new ReleaseOutputRequest(properties);
        };

        /**
         * Encodes the specified ReleaseOutputRequest message. Does not implicitly {@link walletrpc.ReleaseOutputRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {walletrpc.IReleaseOutputRequest} message ReleaseOutputRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseOutputRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ReleaseOutputRequest message, length delimited. Does not implicitly {@link walletrpc.ReleaseOutputRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {walletrpc.IReleaseOutputRequest} message ReleaseOutputRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseOutputRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleaseOutputRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ReleaseOutputRequest} ReleaseOutputRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseOutputRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ReleaseOutputRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleaseOutputRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ReleaseOutputRequest} ReleaseOutputRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseOutputRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleaseOutputRequest message.
         * @function verify
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleaseOutputRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            return null;
        };

        /**
         * Creates a ReleaseOutputRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ReleaseOutputRequest} ReleaseOutputRequest
         */
        ReleaseOutputRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ReleaseOutputRequest)
                return object;
            let message = new $root.walletrpc.ReleaseOutputRequest();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".walletrpc.ReleaseOutputRequest.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            return message;
        };

        /**
         * Creates a plain object from a ReleaseOutputRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ReleaseOutputRequest
         * @static
         * @param {walletrpc.ReleaseOutputRequest} message ReleaseOutputRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleaseOutputRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.outpoint = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            return object;
        };

        /**
         * Converts this ReleaseOutputRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ReleaseOutputRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleaseOutputRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReleaseOutputRequest;
    })();

    walletrpc.ReleaseOutputResponse = (function() {

        /**
         * Properties of a ReleaseOutputResponse.
         * @memberof walletrpc
         * @interface IReleaseOutputResponse
         */

        /**
         * Constructs a new ReleaseOutputResponse.
         * @memberof walletrpc
         * @classdesc Represents a ReleaseOutputResponse.
         * @implements IReleaseOutputResponse
         * @constructor
         * @param {walletrpc.IReleaseOutputResponse=} [properties] Properties to set
         */
        function ReleaseOutputResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ReleaseOutputResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {walletrpc.IReleaseOutputResponse=} [properties] Properties to set
         * @returns {walletrpc.ReleaseOutputResponse} ReleaseOutputResponse instance
         */
        ReleaseOutputResponse.create = function create(properties) {
            return new ReleaseOutputResponse(properties);
        };

        /**
         * Encodes the specified ReleaseOutputResponse message. Does not implicitly {@link walletrpc.ReleaseOutputResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {walletrpc.IReleaseOutputResponse} message ReleaseOutputResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseOutputResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ReleaseOutputResponse message, length delimited. Does not implicitly {@link walletrpc.ReleaseOutputResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {walletrpc.IReleaseOutputResponse} message ReleaseOutputResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ReleaseOutputResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ReleaseOutputResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ReleaseOutputResponse} ReleaseOutputResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseOutputResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ReleaseOutputResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ReleaseOutputResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ReleaseOutputResponse} ReleaseOutputResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ReleaseOutputResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ReleaseOutputResponse message.
         * @function verify
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ReleaseOutputResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ReleaseOutputResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ReleaseOutputResponse} ReleaseOutputResponse
         */
        ReleaseOutputResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ReleaseOutputResponse)
                return object;
            return new $root.walletrpc.ReleaseOutputResponse();
        };

        /**
         * Creates a plain object from a ReleaseOutputResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ReleaseOutputResponse
         * @static
         * @param {walletrpc.ReleaseOutputResponse} message ReleaseOutputResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ReleaseOutputResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ReleaseOutputResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ReleaseOutputResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ReleaseOutputResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ReleaseOutputResponse;
    })();

    walletrpc.KeyReq = (function() {

        /**
         * Properties of a KeyReq.
         * @memberof walletrpc
         * @interface IKeyReq
         * @property {number|null} [keyFingerPrint] KeyReq keyFingerPrint
         * @property {number|null} [keyFamily] KeyReq keyFamily
         */

        /**
         * Constructs a new KeyReq.
         * @memberof walletrpc
         * @classdesc Represents a KeyReq.
         * @implements IKeyReq
         * @constructor
         * @param {walletrpc.IKeyReq=} [properties] Properties to set
         */
        function KeyReq(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * KeyReq keyFingerPrint.
         * @member {number} keyFingerPrint
         * @memberof walletrpc.KeyReq
         * @instance
         */
        KeyReq.prototype.keyFingerPrint = 0;

        /**
         * KeyReq keyFamily.
         * @member {number} keyFamily
         * @memberof walletrpc.KeyReq
         * @instance
         */
        KeyReq.prototype.keyFamily = 0;

        /**
         * Creates a new KeyReq instance using the specified properties.
         * @function create
         * @memberof walletrpc.KeyReq
         * @static
         * @param {walletrpc.IKeyReq=} [properties] Properties to set
         * @returns {walletrpc.KeyReq} KeyReq instance
         */
        KeyReq.create = function create(properties) {
            return new KeyReq(properties);
        };

        /**
         * Encodes the specified KeyReq message. Does not implicitly {@link walletrpc.KeyReq.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.KeyReq
         * @static
         * @param {walletrpc.IKeyReq} message KeyReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyReq.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keyFingerPrint != null && Object.hasOwnProperty.call(message, "keyFingerPrint"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.keyFingerPrint);
            if (message.keyFamily != null && Object.hasOwnProperty.call(message, "keyFamily"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.keyFamily);
            return writer;
        };

        /**
         * Encodes the specified KeyReq message, length delimited. Does not implicitly {@link walletrpc.KeyReq.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.KeyReq
         * @static
         * @param {walletrpc.IKeyReq} message KeyReq message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        KeyReq.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a KeyReq message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.KeyReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.KeyReq} KeyReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyReq.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.KeyReq();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.keyFingerPrint = reader.int32();
                        break;
                    }
                case 2: {
                        message.keyFamily = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a KeyReq message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.KeyReq
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.KeyReq} KeyReq
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        KeyReq.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a KeyReq message.
         * @function verify
         * @memberof walletrpc.KeyReq
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        KeyReq.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.keyFingerPrint != null && message.hasOwnProperty("keyFingerPrint"))
                if (!$util.isInteger(message.keyFingerPrint))
                    return "keyFingerPrint: integer expected";
            if (message.keyFamily != null && message.hasOwnProperty("keyFamily"))
                if (!$util.isInteger(message.keyFamily))
                    return "keyFamily: integer expected";
            return null;
        };

        /**
         * Creates a KeyReq message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.KeyReq
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.KeyReq} KeyReq
         */
        KeyReq.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.KeyReq)
                return object;
            let message = new $root.walletrpc.KeyReq();
            if (object.keyFingerPrint != null)
                message.keyFingerPrint = object.keyFingerPrint | 0;
            if (object.keyFamily != null)
                message.keyFamily = object.keyFamily | 0;
            return message;
        };

        /**
         * Creates a plain object from a KeyReq message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.KeyReq
         * @static
         * @param {walletrpc.KeyReq} message KeyReq
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        KeyReq.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.keyFingerPrint = 0;
                object.keyFamily = 0;
            }
            if (message.keyFingerPrint != null && message.hasOwnProperty("keyFingerPrint"))
                object.keyFingerPrint = message.keyFingerPrint;
            if (message.keyFamily != null && message.hasOwnProperty("keyFamily"))
                object.keyFamily = message.keyFamily;
            return object;
        };

        /**
         * Converts this KeyReq to JSON.
         * @function toJSON
         * @memberof walletrpc.KeyReq
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        KeyReq.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return KeyReq;
    })();

    walletrpc.AddrRequest = (function() {

        /**
         * Properties of an AddrRequest.
         * @memberof walletrpc
         * @interface IAddrRequest
         * @property {string|null} [account] AddrRequest account
         * @property {walletrpc.AddressType|null} [type] AddrRequest type
         * @property {boolean|null} [change] AddrRequest change
         */

        /**
         * Constructs a new AddrRequest.
         * @memberof walletrpc
         * @classdesc Represents an AddrRequest.
         * @implements IAddrRequest
         * @constructor
         * @param {walletrpc.IAddrRequest=} [properties] Properties to set
         */
        function AddrRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddrRequest account.
         * @member {string} account
         * @memberof walletrpc.AddrRequest
         * @instance
         */
        AddrRequest.prototype.account = "";

        /**
         * AddrRequest type.
         * @member {walletrpc.AddressType} type
         * @memberof walletrpc.AddrRequest
         * @instance
         */
        AddrRequest.prototype.type = 0;

        /**
         * AddrRequest change.
         * @member {boolean} change
         * @memberof walletrpc.AddrRequest
         * @instance
         */
        AddrRequest.prototype.change = false;

        /**
         * Creates a new AddrRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {walletrpc.IAddrRequest=} [properties] Properties to set
         * @returns {walletrpc.AddrRequest} AddrRequest instance
         */
        AddrRequest.create = function create(properties) {
            return new AddrRequest(properties);
        };

        /**
         * Encodes the specified AddrRequest message. Does not implicitly {@link walletrpc.AddrRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {walletrpc.IAddrRequest} message AddrRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddrRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.account);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.change != null && Object.hasOwnProperty.call(message, "change"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.change);
            return writer;
        };

        /**
         * Encodes the specified AddrRequest message, length delimited. Does not implicitly {@link walletrpc.AddrRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {walletrpc.IAddrRequest} message AddrRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddrRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddrRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.AddrRequest} AddrRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddrRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.AddrRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.account = reader.string();
                        break;
                    }
                case 2: {
                        message.type = reader.int32();
                        break;
                    }
                case 3: {
                        message.change = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddrRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.AddrRequest} AddrRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddrRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddrRequest message.
         * @function verify
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddrRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.change != null && message.hasOwnProperty("change"))
                if (typeof message.change !== "boolean")
                    return "change: boolean expected";
            return null;
        };

        /**
         * Creates an AddrRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.AddrRequest} AddrRequest
         */
        AddrRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.AddrRequest)
                return object;
            let message = new $root.walletrpc.AddrRequest();
            if (object.account != null)
                message.account = String(object.account);
            switch (object.type) {
            case "UNKNOWN":
            case 0:
                message.type = 0;
                break;
            case "WITNESS_PUBKEY_HASH":
            case 1:
                message.type = 1;
                break;
            case "NESTED_WITNESS_PUBKEY_HASH":
            case 2:
                message.type = 2;
                break;
            case "HYBRID_NESTED_WITNESS_PUBKEY_HASH":
            case 3:
                message.type = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.type = 4;
                break;
            }
            if (object.change != null)
                message.change = Boolean(object.change);
            return message;
        };

        /**
         * Creates a plain object from an AddrRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.AddrRequest
         * @static
         * @param {walletrpc.AddrRequest} message AddrRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddrRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.account = "";
                object.type = options.enums === String ? "UNKNOWN" : 0;
                object.change = false;
            }
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.walletrpc.AddressType[message.type] : message.type;
            if (message.change != null && message.hasOwnProperty("change"))
                object.change = message.change;
            return object;
        };

        /**
         * Converts this AddrRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.AddrRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddrRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddrRequest;
    })();

    walletrpc.AddrResponse = (function() {

        /**
         * Properties of an AddrResponse.
         * @memberof walletrpc
         * @interface IAddrResponse
         * @property {string|null} [addr] AddrResponse addr
         */

        /**
         * Constructs a new AddrResponse.
         * @memberof walletrpc
         * @classdesc Represents an AddrResponse.
         * @implements IAddrResponse
         * @constructor
         * @param {walletrpc.IAddrResponse=} [properties] Properties to set
         */
        function AddrResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddrResponse addr.
         * @member {string} addr
         * @memberof walletrpc.AddrResponse
         * @instance
         */
        AddrResponse.prototype.addr = "";

        /**
         * Creates a new AddrResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {walletrpc.IAddrResponse=} [properties] Properties to set
         * @returns {walletrpc.AddrResponse} AddrResponse instance
         */
        AddrResponse.create = function create(properties) {
            return new AddrResponse(properties);
        };

        /**
         * Encodes the specified AddrResponse message. Does not implicitly {@link walletrpc.AddrResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {walletrpc.IAddrResponse} message AddrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddrResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addr != null && Object.hasOwnProperty.call(message, "addr"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.addr);
            return writer;
        };

        /**
         * Encodes the specified AddrResponse message, length delimited. Does not implicitly {@link walletrpc.AddrResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {walletrpc.IAddrResponse} message AddrResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddrResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddrResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.AddrResponse} AddrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddrResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.AddrResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.addr = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddrResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.AddrResponse} AddrResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddrResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddrResponse message.
         * @function verify
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddrResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.addr != null && message.hasOwnProperty("addr"))
                if (!$util.isString(message.addr))
                    return "addr: string expected";
            return null;
        };

        /**
         * Creates an AddrResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.AddrResponse} AddrResponse
         */
        AddrResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.AddrResponse)
                return object;
            let message = new $root.walletrpc.AddrResponse();
            if (object.addr != null)
                message.addr = String(object.addr);
            return message;
        };

        /**
         * Creates a plain object from an AddrResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.AddrResponse
         * @static
         * @param {walletrpc.AddrResponse} message AddrResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddrResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.addr = "";
            if (message.addr != null && message.hasOwnProperty("addr"))
                object.addr = message.addr;
            return object;
        };

        /**
         * Converts this AddrResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.AddrResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddrResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddrResponse;
    })();

    /**
     * AddressType enum.
     * @name walletrpc.AddressType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} WITNESS_PUBKEY_HASH=1 WITNESS_PUBKEY_HASH value
     * @property {number} NESTED_WITNESS_PUBKEY_HASH=2 NESTED_WITNESS_PUBKEY_HASH value
     * @property {number} HYBRID_NESTED_WITNESS_PUBKEY_HASH=3 HYBRID_NESTED_WITNESS_PUBKEY_HASH value
     * @property {number} TAPROOT_PUBKEY=4 TAPROOT_PUBKEY value
     */
    walletrpc.AddressType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "WITNESS_PUBKEY_HASH"] = 1;
        values[valuesById[2] = "NESTED_WITNESS_PUBKEY_HASH"] = 2;
        values[valuesById[3] = "HYBRID_NESTED_WITNESS_PUBKEY_HASH"] = 3;
        values[valuesById[4] = "TAPROOT_PUBKEY"] = 4;
        return values;
    })();

    walletrpc.Account = (function() {

        /**
         * Properties of an Account.
         * @memberof walletrpc
         * @interface IAccount
         * @property {string|null} [name] Account name
         * @property {walletrpc.AddressType|null} [addressType] Account addressType
         * @property {string|null} [extendedPublicKey] Account extendedPublicKey
         * @property {Uint8Array|null} [masterKeyFingerprint] Account masterKeyFingerprint
         * @property {string|null} [derivationPath] Account derivationPath
         * @property {number|null} [externalKeyCount] Account externalKeyCount
         * @property {number|null} [internalKeyCount] Account internalKeyCount
         * @property {boolean|null} [watchOnly] Account watchOnly
         */

        /**
         * Constructs a new Account.
         * @memberof walletrpc
         * @classdesc Represents an Account.
         * @implements IAccount
         * @constructor
         * @param {walletrpc.IAccount=} [properties] Properties to set
         */
        function Account(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Account name.
         * @member {string} name
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.name = "";

        /**
         * Account addressType.
         * @member {walletrpc.AddressType} addressType
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.addressType = 0;

        /**
         * Account extendedPublicKey.
         * @member {string} extendedPublicKey
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.extendedPublicKey = "";

        /**
         * Account masterKeyFingerprint.
         * @member {Uint8Array} masterKeyFingerprint
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.masterKeyFingerprint = $util.newBuffer([]);

        /**
         * Account derivationPath.
         * @member {string} derivationPath
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.derivationPath = "";

        /**
         * Account externalKeyCount.
         * @member {number} externalKeyCount
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.externalKeyCount = 0;

        /**
         * Account internalKeyCount.
         * @member {number} internalKeyCount
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.internalKeyCount = 0;

        /**
         * Account watchOnly.
         * @member {boolean} watchOnly
         * @memberof walletrpc.Account
         * @instance
         */
        Account.prototype.watchOnly = false;

        /**
         * Creates a new Account instance using the specified properties.
         * @function create
         * @memberof walletrpc.Account
         * @static
         * @param {walletrpc.IAccount=} [properties] Properties to set
         * @returns {walletrpc.Account} Account instance
         */
        Account.create = function create(properties) {
            return new Account(properties);
        };

        /**
         * Encodes the specified Account message. Does not implicitly {@link walletrpc.Account.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.Account
         * @static
         * @param {walletrpc.IAccount} message Account message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Account.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.addressType != null && Object.hasOwnProperty.call(message, "addressType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.addressType);
            if (message.extendedPublicKey != null && Object.hasOwnProperty.call(message, "extendedPublicKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.extendedPublicKey);
            if (message.masterKeyFingerprint != null && Object.hasOwnProperty.call(message, "masterKeyFingerprint"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.masterKeyFingerprint);
            if (message.derivationPath != null && Object.hasOwnProperty.call(message, "derivationPath"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.derivationPath);
            if (message.externalKeyCount != null && Object.hasOwnProperty.call(message, "externalKeyCount"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.externalKeyCount);
            if (message.internalKeyCount != null && Object.hasOwnProperty.call(message, "internalKeyCount"))
                writer.uint32(/* id 7, wireType 0 =*/56).uint32(message.internalKeyCount);
            if (message.watchOnly != null && Object.hasOwnProperty.call(message, "watchOnly"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.watchOnly);
            return writer;
        };

        /**
         * Encodes the specified Account message, length delimited. Does not implicitly {@link walletrpc.Account.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.Account
         * @static
         * @param {walletrpc.IAccount} message Account message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Account.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Account message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.Account
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.Account} Account
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Account.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.Account();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.addressType = reader.int32();
                        break;
                    }
                case 3: {
                        message.extendedPublicKey = reader.string();
                        break;
                    }
                case 4: {
                        message.masterKeyFingerprint = reader.bytes();
                        break;
                    }
                case 5: {
                        message.derivationPath = reader.string();
                        break;
                    }
                case 6: {
                        message.externalKeyCount = reader.uint32();
                        break;
                    }
                case 7: {
                        message.internalKeyCount = reader.uint32();
                        break;
                    }
                case 8: {
                        message.watchOnly = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Account message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.Account
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.Account} Account
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Account.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Account message.
         * @function verify
         * @memberof walletrpc.Account
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Account.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                switch (message.addressType) {
                default:
                    return "addressType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.extendedPublicKey != null && message.hasOwnProperty("extendedPublicKey"))
                if (!$util.isString(message.extendedPublicKey))
                    return "extendedPublicKey: string expected";
            if (message.masterKeyFingerprint != null && message.hasOwnProperty("masterKeyFingerprint"))
                if (!(message.masterKeyFingerprint && typeof message.masterKeyFingerprint.length === "number" || $util.isString(message.masterKeyFingerprint)))
                    return "masterKeyFingerprint: buffer expected";
            if (message.derivationPath != null && message.hasOwnProperty("derivationPath"))
                if (!$util.isString(message.derivationPath))
                    return "derivationPath: string expected";
            if (message.externalKeyCount != null && message.hasOwnProperty("externalKeyCount"))
                if (!$util.isInteger(message.externalKeyCount))
                    return "externalKeyCount: integer expected";
            if (message.internalKeyCount != null && message.hasOwnProperty("internalKeyCount"))
                if (!$util.isInteger(message.internalKeyCount))
                    return "internalKeyCount: integer expected";
            if (message.watchOnly != null && message.hasOwnProperty("watchOnly"))
                if (typeof message.watchOnly !== "boolean")
                    return "watchOnly: boolean expected";
            return null;
        };

        /**
         * Creates an Account message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.Account
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.Account} Account
         */
        Account.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.Account)
                return object;
            let message = new $root.walletrpc.Account();
            if (object.name != null)
                message.name = String(object.name);
            switch (object.addressType) {
            case "UNKNOWN":
            case 0:
                message.addressType = 0;
                break;
            case "WITNESS_PUBKEY_HASH":
            case 1:
                message.addressType = 1;
                break;
            case "NESTED_WITNESS_PUBKEY_HASH":
            case 2:
                message.addressType = 2;
                break;
            case "HYBRID_NESTED_WITNESS_PUBKEY_HASH":
            case 3:
                message.addressType = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.addressType = 4;
                break;
            }
            if (object.extendedPublicKey != null)
                message.extendedPublicKey = String(object.extendedPublicKey);
            if (object.masterKeyFingerprint != null)
                if (typeof object.masterKeyFingerprint === "string")
                    $util.base64.decode(object.masterKeyFingerprint, message.masterKeyFingerprint = $util.newBuffer($util.base64.length(object.masterKeyFingerprint)), 0);
                else if (object.masterKeyFingerprint.length)
                    message.masterKeyFingerprint = object.masterKeyFingerprint;
            if (object.derivationPath != null)
                message.derivationPath = String(object.derivationPath);
            if (object.externalKeyCount != null)
                message.externalKeyCount = object.externalKeyCount >>> 0;
            if (object.internalKeyCount != null)
                message.internalKeyCount = object.internalKeyCount >>> 0;
            if (object.watchOnly != null)
                message.watchOnly = Boolean(object.watchOnly);
            return message;
        };

        /**
         * Creates a plain object from an Account message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.Account
         * @static
         * @param {walletrpc.Account} message Account
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Account.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.addressType = options.enums === String ? "UNKNOWN" : 0;
                object.extendedPublicKey = "";
                if (options.bytes === String)
                    object.masterKeyFingerprint = "";
                else {
                    object.masterKeyFingerprint = [];
                    if (options.bytes !== Array)
                        object.masterKeyFingerprint = $util.newBuffer(object.masterKeyFingerprint);
                }
                object.derivationPath = "";
                object.externalKeyCount = 0;
                object.internalKeyCount = 0;
                object.watchOnly = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                object.addressType = options.enums === String ? $root.walletrpc.AddressType[message.addressType] : message.addressType;
            if (message.extendedPublicKey != null && message.hasOwnProperty("extendedPublicKey"))
                object.extendedPublicKey = message.extendedPublicKey;
            if (message.masterKeyFingerprint != null && message.hasOwnProperty("masterKeyFingerprint"))
                object.masterKeyFingerprint = options.bytes === String ? $util.base64.encode(message.masterKeyFingerprint, 0, message.masterKeyFingerprint.length) : options.bytes === Array ? Array.prototype.slice.call(message.masterKeyFingerprint) : message.masterKeyFingerprint;
            if (message.derivationPath != null && message.hasOwnProperty("derivationPath"))
                object.derivationPath = message.derivationPath;
            if (message.externalKeyCount != null && message.hasOwnProperty("externalKeyCount"))
                object.externalKeyCount = message.externalKeyCount;
            if (message.internalKeyCount != null && message.hasOwnProperty("internalKeyCount"))
                object.internalKeyCount = message.internalKeyCount;
            if (message.watchOnly != null && message.hasOwnProperty("watchOnly"))
                object.watchOnly = message.watchOnly;
            return object;
        };

        /**
         * Converts this Account to JSON.
         * @function toJSON
         * @memberof walletrpc.Account
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Account.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Account;
    })();

    walletrpc.ListAccountsRequest = (function() {

        /**
         * Properties of a ListAccountsRequest.
         * @memberof walletrpc
         * @interface IListAccountsRequest
         * @property {string|null} [name] ListAccountsRequest name
         * @property {walletrpc.AddressType|null} [addressType] ListAccountsRequest addressType
         */

        /**
         * Constructs a new ListAccountsRequest.
         * @memberof walletrpc
         * @classdesc Represents a ListAccountsRequest.
         * @implements IListAccountsRequest
         * @constructor
         * @param {walletrpc.IListAccountsRequest=} [properties] Properties to set
         */
        function ListAccountsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListAccountsRequest name.
         * @member {string} name
         * @memberof walletrpc.ListAccountsRequest
         * @instance
         */
        ListAccountsRequest.prototype.name = "";

        /**
         * ListAccountsRequest addressType.
         * @member {walletrpc.AddressType} addressType
         * @memberof walletrpc.ListAccountsRequest
         * @instance
         */
        ListAccountsRequest.prototype.addressType = 0;

        /**
         * Creates a new ListAccountsRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {walletrpc.IListAccountsRequest=} [properties] Properties to set
         * @returns {walletrpc.ListAccountsRequest} ListAccountsRequest instance
         */
        ListAccountsRequest.create = function create(properties) {
            return new ListAccountsRequest(properties);
        };

        /**
         * Encodes the specified ListAccountsRequest message. Does not implicitly {@link walletrpc.ListAccountsRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {walletrpc.IListAccountsRequest} message ListAccountsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAccountsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.addressType != null && Object.hasOwnProperty.call(message, "addressType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.addressType);
            return writer;
        };

        /**
         * Encodes the specified ListAccountsRequest message, length delimited. Does not implicitly {@link walletrpc.ListAccountsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {walletrpc.IListAccountsRequest} message ListAccountsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAccountsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListAccountsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListAccountsRequest} ListAccountsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAccountsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListAccountsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.addressType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListAccountsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListAccountsRequest} ListAccountsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAccountsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListAccountsRequest message.
         * @function verify
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListAccountsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                switch (message.addressType) {
                default:
                    return "addressType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates a ListAccountsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListAccountsRequest} ListAccountsRequest
         */
        ListAccountsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListAccountsRequest)
                return object;
            let message = new $root.walletrpc.ListAccountsRequest();
            if (object.name != null)
                message.name = String(object.name);
            switch (object.addressType) {
            case "UNKNOWN":
            case 0:
                message.addressType = 0;
                break;
            case "WITNESS_PUBKEY_HASH":
            case 1:
                message.addressType = 1;
                break;
            case "NESTED_WITNESS_PUBKEY_HASH":
            case 2:
                message.addressType = 2;
                break;
            case "HYBRID_NESTED_WITNESS_PUBKEY_HASH":
            case 3:
                message.addressType = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.addressType = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a ListAccountsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListAccountsRequest
         * @static
         * @param {walletrpc.ListAccountsRequest} message ListAccountsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListAccountsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.addressType = options.enums === String ? "UNKNOWN" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                object.addressType = options.enums === String ? $root.walletrpc.AddressType[message.addressType] : message.addressType;
            return object;
        };

        /**
         * Converts this ListAccountsRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ListAccountsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListAccountsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListAccountsRequest;
    })();

    walletrpc.ListAccountsResponse = (function() {

        /**
         * Properties of a ListAccountsResponse.
         * @memberof walletrpc
         * @interface IListAccountsResponse
         * @property {Array.<walletrpc.IAccount>|null} [accounts] ListAccountsResponse accounts
         */

        /**
         * Constructs a new ListAccountsResponse.
         * @memberof walletrpc
         * @classdesc Represents a ListAccountsResponse.
         * @implements IListAccountsResponse
         * @constructor
         * @param {walletrpc.IListAccountsResponse=} [properties] Properties to set
         */
        function ListAccountsResponse(properties) {
            this.accounts = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListAccountsResponse accounts.
         * @member {Array.<walletrpc.IAccount>} accounts
         * @memberof walletrpc.ListAccountsResponse
         * @instance
         */
        ListAccountsResponse.prototype.accounts = $util.emptyArray;

        /**
         * Creates a new ListAccountsResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {walletrpc.IListAccountsResponse=} [properties] Properties to set
         * @returns {walletrpc.ListAccountsResponse} ListAccountsResponse instance
         */
        ListAccountsResponse.create = function create(properties) {
            return new ListAccountsResponse(properties);
        };

        /**
         * Encodes the specified ListAccountsResponse message. Does not implicitly {@link walletrpc.ListAccountsResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {walletrpc.IListAccountsResponse} message ListAccountsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAccountsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accounts != null && message.accounts.length)
                for (let i = 0; i < message.accounts.length; ++i)
                    $root.walletrpc.Account.encode(message.accounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListAccountsResponse message, length delimited. Does not implicitly {@link walletrpc.ListAccountsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {walletrpc.IListAccountsResponse} message ListAccountsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListAccountsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListAccountsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListAccountsResponse} ListAccountsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAccountsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListAccountsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.accounts && message.accounts.length))
                            message.accounts = [];
                        message.accounts.push($root.walletrpc.Account.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListAccountsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListAccountsResponse} ListAccountsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListAccountsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListAccountsResponse message.
         * @function verify
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListAccountsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.accounts != null && message.hasOwnProperty("accounts")) {
                if (!Array.isArray(message.accounts))
                    return "accounts: array expected";
                for (let i = 0; i < message.accounts.length; ++i) {
                    let error = $root.walletrpc.Account.verify(message.accounts[i]);
                    if (error)
                        return "accounts." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListAccountsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListAccountsResponse} ListAccountsResponse
         */
        ListAccountsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListAccountsResponse)
                return object;
            let message = new $root.walletrpc.ListAccountsResponse();
            if (object.accounts) {
                if (!Array.isArray(object.accounts))
                    throw TypeError(".walletrpc.ListAccountsResponse.accounts: array expected");
                message.accounts = [];
                for (let i = 0; i < object.accounts.length; ++i) {
                    if (typeof object.accounts[i] !== "object")
                        throw TypeError(".walletrpc.ListAccountsResponse.accounts: object expected");
                    message.accounts[i] = $root.walletrpc.Account.fromObject(object.accounts[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListAccountsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListAccountsResponse
         * @static
         * @param {walletrpc.ListAccountsResponse} message ListAccountsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListAccountsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.accounts = [];
            if (message.accounts && message.accounts.length) {
                object.accounts = [];
                for (let j = 0; j < message.accounts.length; ++j)
                    object.accounts[j] = $root.walletrpc.Account.toObject(message.accounts[j], options);
            }
            return object;
        };

        /**
         * Converts this ListAccountsResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ListAccountsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListAccountsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListAccountsResponse;
    })();

    walletrpc.RequiredReserveRequest = (function() {

        /**
         * Properties of a RequiredReserveRequest.
         * @memberof walletrpc
         * @interface IRequiredReserveRequest
         * @property {number|null} [additionalPublicChannels] RequiredReserveRequest additionalPublicChannels
         */

        /**
         * Constructs a new RequiredReserveRequest.
         * @memberof walletrpc
         * @classdesc Represents a RequiredReserveRequest.
         * @implements IRequiredReserveRequest
         * @constructor
         * @param {walletrpc.IRequiredReserveRequest=} [properties] Properties to set
         */
        function RequiredReserveRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequiredReserveRequest additionalPublicChannels.
         * @member {number} additionalPublicChannels
         * @memberof walletrpc.RequiredReserveRequest
         * @instance
         */
        RequiredReserveRequest.prototype.additionalPublicChannels = 0;

        /**
         * Creates a new RequiredReserveRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {walletrpc.IRequiredReserveRequest=} [properties] Properties to set
         * @returns {walletrpc.RequiredReserveRequest} RequiredReserveRequest instance
         */
        RequiredReserveRequest.create = function create(properties) {
            return new RequiredReserveRequest(properties);
        };

        /**
         * Encodes the specified RequiredReserveRequest message. Does not implicitly {@link walletrpc.RequiredReserveRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {walletrpc.IRequiredReserveRequest} message RequiredReserveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequiredReserveRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.additionalPublicChannels != null && Object.hasOwnProperty.call(message, "additionalPublicChannels"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.additionalPublicChannels);
            return writer;
        };

        /**
         * Encodes the specified RequiredReserveRequest message, length delimited. Does not implicitly {@link walletrpc.RequiredReserveRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {walletrpc.IRequiredReserveRequest} message RequiredReserveRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequiredReserveRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequiredReserveRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.RequiredReserveRequest} RequiredReserveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequiredReserveRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.RequiredReserveRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.additionalPublicChannels = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequiredReserveRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.RequiredReserveRequest} RequiredReserveRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequiredReserveRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequiredReserveRequest message.
         * @function verify
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequiredReserveRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.additionalPublicChannels != null && message.hasOwnProperty("additionalPublicChannels"))
                if (!$util.isInteger(message.additionalPublicChannels))
                    return "additionalPublicChannels: integer expected";
            return null;
        };

        /**
         * Creates a RequiredReserveRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.RequiredReserveRequest} RequiredReserveRequest
         */
        RequiredReserveRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.RequiredReserveRequest)
                return object;
            let message = new $root.walletrpc.RequiredReserveRequest();
            if (object.additionalPublicChannels != null)
                message.additionalPublicChannels = object.additionalPublicChannels >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a RequiredReserveRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.RequiredReserveRequest
         * @static
         * @param {walletrpc.RequiredReserveRequest} message RequiredReserveRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequiredReserveRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.additionalPublicChannels = 0;
            if (message.additionalPublicChannels != null && message.hasOwnProperty("additionalPublicChannels"))
                object.additionalPublicChannels = message.additionalPublicChannels;
            return object;
        };

        /**
         * Converts this RequiredReserveRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.RequiredReserveRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequiredReserveRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequiredReserveRequest;
    })();

    walletrpc.RequiredReserveResponse = (function() {

        /**
         * Properties of a RequiredReserveResponse.
         * @memberof walletrpc
         * @interface IRequiredReserveResponse
         * @property {Long|null} [requiredReserve] RequiredReserveResponse requiredReserve
         */

        /**
         * Constructs a new RequiredReserveResponse.
         * @memberof walletrpc
         * @classdesc Represents a RequiredReserveResponse.
         * @implements IRequiredReserveResponse
         * @constructor
         * @param {walletrpc.IRequiredReserveResponse=} [properties] Properties to set
         */
        function RequiredReserveResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequiredReserveResponse requiredReserve.
         * @member {Long} requiredReserve
         * @memberof walletrpc.RequiredReserveResponse
         * @instance
         */
        RequiredReserveResponse.prototype.requiredReserve = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new RequiredReserveResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {walletrpc.IRequiredReserveResponse=} [properties] Properties to set
         * @returns {walletrpc.RequiredReserveResponse} RequiredReserveResponse instance
         */
        RequiredReserveResponse.create = function create(properties) {
            return new RequiredReserveResponse(properties);
        };

        /**
         * Encodes the specified RequiredReserveResponse message. Does not implicitly {@link walletrpc.RequiredReserveResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {walletrpc.IRequiredReserveResponse} message RequiredReserveResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequiredReserveResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requiredReserve != null && Object.hasOwnProperty.call(message, "requiredReserve"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.requiredReserve);
            return writer;
        };

        /**
         * Encodes the specified RequiredReserveResponse message, length delimited. Does not implicitly {@link walletrpc.RequiredReserveResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {walletrpc.IRequiredReserveResponse} message RequiredReserveResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequiredReserveResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequiredReserveResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.RequiredReserveResponse} RequiredReserveResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequiredReserveResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.RequiredReserveResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.requiredReserve = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequiredReserveResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.RequiredReserveResponse} RequiredReserveResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequiredReserveResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequiredReserveResponse message.
         * @function verify
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequiredReserveResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requiredReserve != null && message.hasOwnProperty("requiredReserve"))
                if (!$util.isInteger(message.requiredReserve) && !(message.requiredReserve && $util.isInteger(message.requiredReserve.low) && $util.isInteger(message.requiredReserve.high)))
                    return "requiredReserve: integer|Long expected";
            return null;
        };

        /**
         * Creates a RequiredReserveResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.RequiredReserveResponse} RequiredReserveResponse
         */
        RequiredReserveResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.RequiredReserveResponse)
                return object;
            let message = new $root.walletrpc.RequiredReserveResponse();
            if (object.requiredReserve != null)
                if ($util.Long)
                    (message.requiredReserve = $util.Long.fromValue(object.requiredReserve)).unsigned = false;
                else if (typeof object.requiredReserve === "string")
                    message.requiredReserve = parseInt(object.requiredReserve, 10);
                else if (typeof object.requiredReserve === "number")
                    message.requiredReserve = object.requiredReserve;
                else if (typeof object.requiredReserve === "object")
                    message.requiredReserve = new $util.LongBits(object.requiredReserve.low >>> 0, object.requiredReserve.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a RequiredReserveResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.RequiredReserveResponse
         * @static
         * @param {walletrpc.RequiredReserveResponse} message RequiredReserveResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequiredReserveResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.requiredReserve = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requiredReserve = options.longs === String ? "0" : 0;
            if (message.requiredReserve != null && message.hasOwnProperty("requiredReserve"))
                if (typeof message.requiredReserve === "number")
                    object.requiredReserve = options.longs === String ? String(message.requiredReserve) : message.requiredReserve;
                else
                    object.requiredReserve = options.longs === String ? $util.Long.prototype.toString.call(message.requiredReserve) : options.longs === Number ? new $util.LongBits(message.requiredReserve.low >>> 0, message.requiredReserve.high >>> 0).toNumber() : message.requiredReserve;
            return object;
        };

        /**
         * Converts this RequiredReserveResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.RequiredReserveResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequiredReserveResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequiredReserveResponse;
    })();

    walletrpc.ImportAccountRequest = (function() {

        /**
         * Properties of an ImportAccountRequest.
         * @memberof walletrpc
         * @interface IImportAccountRequest
         * @property {string|null} [name] ImportAccountRequest name
         * @property {string|null} [extendedPublicKey] ImportAccountRequest extendedPublicKey
         * @property {Uint8Array|null} [masterKeyFingerprint] ImportAccountRequest masterKeyFingerprint
         * @property {walletrpc.AddressType|null} [addressType] ImportAccountRequest addressType
         * @property {boolean|null} [dryRun] ImportAccountRequest dryRun
         */

        /**
         * Constructs a new ImportAccountRequest.
         * @memberof walletrpc
         * @classdesc Represents an ImportAccountRequest.
         * @implements IImportAccountRequest
         * @constructor
         * @param {walletrpc.IImportAccountRequest=} [properties] Properties to set
         */
        function ImportAccountRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportAccountRequest name.
         * @member {string} name
         * @memberof walletrpc.ImportAccountRequest
         * @instance
         */
        ImportAccountRequest.prototype.name = "";

        /**
         * ImportAccountRequest extendedPublicKey.
         * @member {string} extendedPublicKey
         * @memberof walletrpc.ImportAccountRequest
         * @instance
         */
        ImportAccountRequest.prototype.extendedPublicKey = "";

        /**
         * ImportAccountRequest masterKeyFingerprint.
         * @member {Uint8Array} masterKeyFingerprint
         * @memberof walletrpc.ImportAccountRequest
         * @instance
         */
        ImportAccountRequest.prototype.masterKeyFingerprint = $util.newBuffer([]);

        /**
         * ImportAccountRequest addressType.
         * @member {walletrpc.AddressType} addressType
         * @memberof walletrpc.ImportAccountRequest
         * @instance
         */
        ImportAccountRequest.prototype.addressType = 0;

        /**
         * ImportAccountRequest dryRun.
         * @member {boolean} dryRun
         * @memberof walletrpc.ImportAccountRequest
         * @instance
         */
        ImportAccountRequest.prototype.dryRun = false;

        /**
         * Creates a new ImportAccountRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {walletrpc.IImportAccountRequest=} [properties] Properties to set
         * @returns {walletrpc.ImportAccountRequest} ImportAccountRequest instance
         */
        ImportAccountRequest.create = function create(properties) {
            return new ImportAccountRequest(properties);
        };

        /**
         * Encodes the specified ImportAccountRequest message. Does not implicitly {@link walletrpc.ImportAccountRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {walletrpc.IImportAccountRequest} message ImportAccountRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportAccountRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.extendedPublicKey != null && Object.hasOwnProperty.call(message, "extendedPublicKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendedPublicKey);
            if (message.masterKeyFingerprint != null && Object.hasOwnProperty.call(message, "masterKeyFingerprint"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.masterKeyFingerprint);
            if (message.addressType != null && Object.hasOwnProperty.call(message, "addressType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.addressType);
            if (message.dryRun != null && Object.hasOwnProperty.call(message, "dryRun"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.dryRun);
            return writer;
        };

        /**
         * Encodes the specified ImportAccountRequest message, length delimited. Does not implicitly {@link walletrpc.ImportAccountRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {walletrpc.IImportAccountRequest} message ImportAccountRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportAccountRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportAccountRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ImportAccountRequest} ImportAccountRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportAccountRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ImportAccountRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.extendedPublicKey = reader.string();
                        break;
                    }
                case 3: {
                        message.masterKeyFingerprint = reader.bytes();
                        break;
                    }
                case 4: {
                        message.addressType = reader.int32();
                        break;
                    }
                case 5: {
                        message.dryRun = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportAccountRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ImportAccountRequest} ImportAccountRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportAccountRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportAccountRequest message.
         * @function verify
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportAccountRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.extendedPublicKey != null && message.hasOwnProperty("extendedPublicKey"))
                if (!$util.isString(message.extendedPublicKey))
                    return "extendedPublicKey: string expected";
            if (message.masterKeyFingerprint != null && message.hasOwnProperty("masterKeyFingerprint"))
                if (!(message.masterKeyFingerprint && typeof message.masterKeyFingerprint.length === "number" || $util.isString(message.masterKeyFingerprint)))
                    return "masterKeyFingerprint: buffer expected";
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                switch (message.addressType) {
                default:
                    return "addressType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                if (typeof message.dryRun !== "boolean")
                    return "dryRun: boolean expected";
            return null;
        };

        /**
         * Creates an ImportAccountRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ImportAccountRequest} ImportAccountRequest
         */
        ImportAccountRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ImportAccountRequest)
                return object;
            let message = new $root.walletrpc.ImportAccountRequest();
            if (object.name != null)
                message.name = String(object.name);
            if (object.extendedPublicKey != null)
                message.extendedPublicKey = String(object.extendedPublicKey);
            if (object.masterKeyFingerprint != null)
                if (typeof object.masterKeyFingerprint === "string")
                    $util.base64.decode(object.masterKeyFingerprint, message.masterKeyFingerprint = $util.newBuffer($util.base64.length(object.masterKeyFingerprint)), 0);
                else if (object.masterKeyFingerprint.length)
                    message.masterKeyFingerprint = object.masterKeyFingerprint;
            switch (object.addressType) {
            case "UNKNOWN":
            case 0:
                message.addressType = 0;
                break;
            case "WITNESS_PUBKEY_HASH":
            case 1:
                message.addressType = 1;
                break;
            case "NESTED_WITNESS_PUBKEY_HASH":
            case 2:
                message.addressType = 2;
                break;
            case "HYBRID_NESTED_WITNESS_PUBKEY_HASH":
            case 3:
                message.addressType = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.addressType = 4;
                break;
            }
            if (object.dryRun != null)
                message.dryRun = Boolean(object.dryRun);
            return message;
        };

        /**
         * Creates a plain object from an ImportAccountRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ImportAccountRequest
         * @static
         * @param {walletrpc.ImportAccountRequest} message ImportAccountRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportAccountRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.extendedPublicKey = "";
                if (options.bytes === String)
                    object.masterKeyFingerprint = "";
                else {
                    object.masterKeyFingerprint = [];
                    if (options.bytes !== Array)
                        object.masterKeyFingerprint = $util.newBuffer(object.masterKeyFingerprint);
                }
                object.addressType = options.enums === String ? "UNKNOWN" : 0;
                object.dryRun = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.extendedPublicKey != null && message.hasOwnProperty("extendedPublicKey"))
                object.extendedPublicKey = message.extendedPublicKey;
            if (message.masterKeyFingerprint != null && message.hasOwnProperty("masterKeyFingerprint"))
                object.masterKeyFingerprint = options.bytes === String ? $util.base64.encode(message.masterKeyFingerprint, 0, message.masterKeyFingerprint.length) : options.bytes === Array ? Array.prototype.slice.call(message.masterKeyFingerprint) : message.masterKeyFingerprint;
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                object.addressType = options.enums === String ? $root.walletrpc.AddressType[message.addressType] : message.addressType;
            if (message.dryRun != null && message.hasOwnProperty("dryRun"))
                object.dryRun = message.dryRun;
            return object;
        };

        /**
         * Converts this ImportAccountRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ImportAccountRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportAccountRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportAccountRequest;
    })();

    walletrpc.ImportAccountResponse = (function() {

        /**
         * Properties of an ImportAccountResponse.
         * @memberof walletrpc
         * @interface IImportAccountResponse
         * @property {walletrpc.IAccount|null} [account] ImportAccountResponse account
         * @property {Array.<string>|null} [dryRunExternalAddrs] ImportAccountResponse dryRunExternalAddrs
         * @property {Array.<string>|null} [dryRunInternalAddrs] ImportAccountResponse dryRunInternalAddrs
         */

        /**
         * Constructs a new ImportAccountResponse.
         * @memberof walletrpc
         * @classdesc Represents an ImportAccountResponse.
         * @implements IImportAccountResponse
         * @constructor
         * @param {walletrpc.IImportAccountResponse=} [properties] Properties to set
         */
        function ImportAccountResponse(properties) {
            this.dryRunExternalAddrs = [];
            this.dryRunInternalAddrs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportAccountResponse account.
         * @member {walletrpc.IAccount|null|undefined} account
         * @memberof walletrpc.ImportAccountResponse
         * @instance
         */
        ImportAccountResponse.prototype.account = null;

        /**
         * ImportAccountResponse dryRunExternalAddrs.
         * @member {Array.<string>} dryRunExternalAddrs
         * @memberof walletrpc.ImportAccountResponse
         * @instance
         */
        ImportAccountResponse.prototype.dryRunExternalAddrs = $util.emptyArray;

        /**
         * ImportAccountResponse dryRunInternalAddrs.
         * @member {Array.<string>} dryRunInternalAddrs
         * @memberof walletrpc.ImportAccountResponse
         * @instance
         */
        ImportAccountResponse.prototype.dryRunInternalAddrs = $util.emptyArray;

        /**
         * Creates a new ImportAccountResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {walletrpc.IImportAccountResponse=} [properties] Properties to set
         * @returns {walletrpc.ImportAccountResponse} ImportAccountResponse instance
         */
        ImportAccountResponse.create = function create(properties) {
            return new ImportAccountResponse(properties);
        };

        /**
         * Encodes the specified ImportAccountResponse message. Does not implicitly {@link walletrpc.ImportAccountResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {walletrpc.IImportAccountResponse} message ImportAccountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportAccountResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                $root.walletrpc.Account.encode(message.account, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.dryRunExternalAddrs != null && message.dryRunExternalAddrs.length)
                for (let i = 0; i < message.dryRunExternalAddrs.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.dryRunExternalAddrs[i]);
            if (message.dryRunInternalAddrs != null && message.dryRunInternalAddrs.length)
                for (let i = 0; i < message.dryRunInternalAddrs.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.dryRunInternalAddrs[i]);
            return writer;
        };

        /**
         * Encodes the specified ImportAccountResponse message, length delimited. Does not implicitly {@link walletrpc.ImportAccountResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {walletrpc.IImportAccountResponse} message ImportAccountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportAccountResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportAccountResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ImportAccountResponse} ImportAccountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportAccountResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ImportAccountResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.account = $root.walletrpc.Account.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.dryRunExternalAddrs && message.dryRunExternalAddrs.length))
                            message.dryRunExternalAddrs = [];
                        message.dryRunExternalAddrs.push(reader.string());
                        break;
                    }
                case 3: {
                        if (!(message.dryRunInternalAddrs && message.dryRunInternalAddrs.length))
                            message.dryRunInternalAddrs = [];
                        message.dryRunInternalAddrs.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportAccountResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ImportAccountResponse} ImportAccountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportAccountResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportAccountResponse message.
         * @function verify
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportAccountResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.account != null && message.hasOwnProperty("account")) {
                let error = $root.walletrpc.Account.verify(message.account);
                if (error)
                    return "account." + error;
            }
            if (message.dryRunExternalAddrs != null && message.hasOwnProperty("dryRunExternalAddrs")) {
                if (!Array.isArray(message.dryRunExternalAddrs))
                    return "dryRunExternalAddrs: array expected";
                for (let i = 0; i < message.dryRunExternalAddrs.length; ++i)
                    if (!$util.isString(message.dryRunExternalAddrs[i]))
                        return "dryRunExternalAddrs: string[] expected";
            }
            if (message.dryRunInternalAddrs != null && message.hasOwnProperty("dryRunInternalAddrs")) {
                if (!Array.isArray(message.dryRunInternalAddrs))
                    return "dryRunInternalAddrs: array expected";
                for (let i = 0; i < message.dryRunInternalAddrs.length; ++i)
                    if (!$util.isString(message.dryRunInternalAddrs[i]))
                        return "dryRunInternalAddrs: string[] expected";
            }
            return null;
        };

        /**
         * Creates an ImportAccountResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ImportAccountResponse} ImportAccountResponse
         */
        ImportAccountResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ImportAccountResponse)
                return object;
            let message = new $root.walletrpc.ImportAccountResponse();
            if (object.account != null) {
                if (typeof object.account !== "object")
                    throw TypeError(".walletrpc.ImportAccountResponse.account: object expected");
                message.account = $root.walletrpc.Account.fromObject(object.account);
            }
            if (object.dryRunExternalAddrs) {
                if (!Array.isArray(object.dryRunExternalAddrs))
                    throw TypeError(".walletrpc.ImportAccountResponse.dryRunExternalAddrs: array expected");
                message.dryRunExternalAddrs = [];
                for (let i = 0; i < object.dryRunExternalAddrs.length; ++i)
                    message.dryRunExternalAddrs[i] = String(object.dryRunExternalAddrs[i]);
            }
            if (object.dryRunInternalAddrs) {
                if (!Array.isArray(object.dryRunInternalAddrs))
                    throw TypeError(".walletrpc.ImportAccountResponse.dryRunInternalAddrs: array expected");
                message.dryRunInternalAddrs = [];
                for (let i = 0; i < object.dryRunInternalAddrs.length; ++i)
                    message.dryRunInternalAddrs[i] = String(object.dryRunInternalAddrs[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an ImportAccountResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ImportAccountResponse
         * @static
         * @param {walletrpc.ImportAccountResponse} message ImportAccountResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportAccountResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.dryRunExternalAddrs = [];
                object.dryRunInternalAddrs = [];
            }
            if (options.defaults)
                object.account = null;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = $root.walletrpc.Account.toObject(message.account, options);
            if (message.dryRunExternalAddrs && message.dryRunExternalAddrs.length) {
                object.dryRunExternalAddrs = [];
                for (let j = 0; j < message.dryRunExternalAddrs.length; ++j)
                    object.dryRunExternalAddrs[j] = message.dryRunExternalAddrs[j];
            }
            if (message.dryRunInternalAddrs && message.dryRunInternalAddrs.length) {
                object.dryRunInternalAddrs = [];
                for (let j = 0; j < message.dryRunInternalAddrs.length; ++j)
                    object.dryRunInternalAddrs[j] = message.dryRunInternalAddrs[j];
            }
            return object;
        };

        /**
         * Converts this ImportAccountResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ImportAccountResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportAccountResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportAccountResponse;
    })();

    walletrpc.ImportPublicKeyRequest = (function() {

        /**
         * Properties of an ImportPublicKeyRequest.
         * @memberof walletrpc
         * @interface IImportPublicKeyRequest
         * @property {Uint8Array|null} [publicKey] ImportPublicKeyRequest publicKey
         * @property {walletrpc.AddressType|null} [addressType] ImportPublicKeyRequest addressType
         */

        /**
         * Constructs a new ImportPublicKeyRequest.
         * @memberof walletrpc
         * @classdesc Represents an ImportPublicKeyRequest.
         * @implements IImportPublicKeyRequest
         * @constructor
         * @param {walletrpc.IImportPublicKeyRequest=} [properties] Properties to set
         */
        function ImportPublicKeyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ImportPublicKeyRequest publicKey.
         * @member {Uint8Array} publicKey
         * @memberof walletrpc.ImportPublicKeyRequest
         * @instance
         */
        ImportPublicKeyRequest.prototype.publicKey = $util.newBuffer([]);

        /**
         * ImportPublicKeyRequest addressType.
         * @member {walletrpc.AddressType} addressType
         * @memberof walletrpc.ImportPublicKeyRequest
         * @instance
         */
        ImportPublicKeyRequest.prototype.addressType = 0;

        /**
         * Creates a new ImportPublicKeyRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {walletrpc.IImportPublicKeyRequest=} [properties] Properties to set
         * @returns {walletrpc.ImportPublicKeyRequest} ImportPublicKeyRequest instance
         */
        ImportPublicKeyRequest.create = function create(properties) {
            return new ImportPublicKeyRequest(properties);
        };

        /**
         * Encodes the specified ImportPublicKeyRequest message. Does not implicitly {@link walletrpc.ImportPublicKeyRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {walletrpc.IImportPublicKeyRequest} message ImportPublicKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportPublicKeyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publicKey != null && Object.hasOwnProperty.call(message, "publicKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.publicKey);
            if (message.addressType != null && Object.hasOwnProperty.call(message, "addressType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.addressType);
            return writer;
        };

        /**
         * Encodes the specified ImportPublicKeyRequest message, length delimited. Does not implicitly {@link walletrpc.ImportPublicKeyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {walletrpc.IImportPublicKeyRequest} message ImportPublicKeyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportPublicKeyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportPublicKeyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ImportPublicKeyRequest} ImportPublicKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportPublicKeyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ImportPublicKeyRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.publicKey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.addressType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportPublicKeyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ImportPublicKeyRequest} ImportPublicKeyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportPublicKeyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportPublicKeyRequest message.
         * @function verify
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportPublicKeyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                if (!(message.publicKey && typeof message.publicKey.length === "number" || $util.isString(message.publicKey)))
                    return "publicKey: buffer expected";
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                switch (message.addressType) {
                default:
                    return "addressType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            return null;
        };

        /**
         * Creates an ImportPublicKeyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ImportPublicKeyRequest} ImportPublicKeyRequest
         */
        ImportPublicKeyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ImportPublicKeyRequest)
                return object;
            let message = new $root.walletrpc.ImportPublicKeyRequest();
            if (object.publicKey != null)
                if (typeof object.publicKey === "string")
                    $util.base64.decode(object.publicKey, message.publicKey = $util.newBuffer($util.base64.length(object.publicKey)), 0);
                else if (object.publicKey.length)
                    message.publicKey = object.publicKey;
            switch (object.addressType) {
            case "UNKNOWN":
            case 0:
                message.addressType = 0;
                break;
            case "WITNESS_PUBKEY_HASH":
            case 1:
                message.addressType = 1;
                break;
            case "NESTED_WITNESS_PUBKEY_HASH":
            case 2:
                message.addressType = 2;
                break;
            case "HYBRID_NESTED_WITNESS_PUBKEY_HASH":
            case 3:
                message.addressType = 3;
                break;
            case "TAPROOT_PUBKEY":
            case 4:
                message.addressType = 4;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an ImportPublicKeyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ImportPublicKeyRequest
         * @static
         * @param {walletrpc.ImportPublicKeyRequest} message ImportPublicKeyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportPublicKeyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.publicKey = "";
                else {
                    object.publicKey = [];
                    if (options.bytes !== Array)
                        object.publicKey = $util.newBuffer(object.publicKey);
                }
                object.addressType = options.enums === String ? "UNKNOWN" : 0;
            }
            if (message.publicKey != null && message.hasOwnProperty("publicKey"))
                object.publicKey = options.bytes === String ? $util.base64.encode(message.publicKey, 0, message.publicKey.length) : options.bytes === Array ? Array.prototype.slice.call(message.publicKey) : message.publicKey;
            if (message.addressType != null && message.hasOwnProperty("addressType"))
                object.addressType = options.enums === String ? $root.walletrpc.AddressType[message.addressType] : message.addressType;
            return object;
        };

        /**
         * Converts this ImportPublicKeyRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ImportPublicKeyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportPublicKeyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportPublicKeyRequest;
    })();

    walletrpc.ImportPublicKeyResponse = (function() {

        /**
         * Properties of an ImportPublicKeyResponse.
         * @memberof walletrpc
         * @interface IImportPublicKeyResponse
         */

        /**
         * Constructs a new ImportPublicKeyResponse.
         * @memberof walletrpc
         * @classdesc Represents an ImportPublicKeyResponse.
         * @implements IImportPublicKeyResponse
         * @constructor
         * @param {walletrpc.IImportPublicKeyResponse=} [properties] Properties to set
         */
        function ImportPublicKeyResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ImportPublicKeyResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {walletrpc.IImportPublicKeyResponse=} [properties] Properties to set
         * @returns {walletrpc.ImportPublicKeyResponse} ImportPublicKeyResponse instance
         */
        ImportPublicKeyResponse.create = function create(properties) {
            return new ImportPublicKeyResponse(properties);
        };

        /**
         * Encodes the specified ImportPublicKeyResponse message. Does not implicitly {@link walletrpc.ImportPublicKeyResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {walletrpc.IImportPublicKeyResponse} message ImportPublicKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportPublicKeyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ImportPublicKeyResponse message, length delimited. Does not implicitly {@link walletrpc.ImportPublicKeyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {walletrpc.IImportPublicKeyResponse} message ImportPublicKeyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ImportPublicKeyResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ImportPublicKeyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ImportPublicKeyResponse} ImportPublicKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportPublicKeyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ImportPublicKeyResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ImportPublicKeyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ImportPublicKeyResponse} ImportPublicKeyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ImportPublicKeyResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ImportPublicKeyResponse message.
         * @function verify
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ImportPublicKeyResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ImportPublicKeyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ImportPublicKeyResponse} ImportPublicKeyResponse
         */
        ImportPublicKeyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ImportPublicKeyResponse)
                return object;
            return new $root.walletrpc.ImportPublicKeyResponse();
        };

        /**
         * Creates a plain object from an ImportPublicKeyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ImportPublicKeyResponse
         * @static
         * @param {walletrpc.ImportPublicKeyResponse} message ImportPublicKeyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ImportPublicKeyResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ImportPublicKeyResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ImportPublicKeyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ImportPublicKeyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ImportPublicKeyResponse;
    })();

    walletrpc.Transaction = (function() {

        /**
         * Properties of a Transaction.
         * @memberof walletrpc
         * @interface ITransaction
         * @property {Uint8Array|null} [txHex] Transaction txHex
         * @property {string|null} [label] Transaction label
         */

        /**
         * Constructs a new Transaction.
         * @memberof walletrpc
         * @classdesc Represents a Transaction.
         * @implements ITransaction
         * @constructor
         * @param {walletrpc.ITransaction=} [properties] Properties to set
         */
        function Transaction(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Transaction txHex.
         * @member {Uint8Array} txHex
         * @memberof walletrpc.Transaction
         * @instance
         */
        Transaction.prototype.txHex = $util.newBuffer([]);

        /**
         * Transaction label.
         * @member {string} label
         * @memberof walletrpc.Transaction
         * @instance
         */
        Transaction.prototype.label = "";

        /**
         * Creates a new Transaction instance using the specified properties.
         * @function create
         * @memberof walletrpc.Transaction
         * @static
         * @param {walletrpc.ITransaction=} [properties] Properties to set
         * @returns {walletrpc.Transaction} Transaction instance
         */
        Transaction.create = function create(properties) {
            return new Transaction(properties);
        };

        /**
         * Encodes the specified Transaction message. Does not implicitly {@link walletrpc.Transaction.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.Transaction
         * @static
         * @param {walletrpc.ITransaction} message Transaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Transaction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txHex != null && Object.hasOwnProperty.call(message, "txHex"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.txHex);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            return writer;
        };

        /**
         * Encodes the specified Transaction message, length delimited. Does not implicitly {@link walletrpc.Transaction.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.Transaction
         * @static
         * @param {walletrpc.ITransaction} message Transaction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Transaction.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Transaction message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.Transaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.Transaction} Transaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Transaction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.Transaction();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txHex = reader.bytes();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Transaction message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.Transaction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.Transaction} Transaction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Transaction.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Transaction message.
         * @function verify
         * @memberof walletrpc.Transaction
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Transaction.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txHex != null && message.hasOwnProperty("txHex"))
                if (!(message.txHex && typeof message.txHex.length === "number" || $util.isString(message.txHex)))
                    return "txHex: buffer expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            return null;
        };

        /**
         * Creates a Transaction message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.Transaction
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.Transaction} Transaction
         */
        Transaction.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.Transaction)
                return object;
            let message = new $root.walletrpc.Transaction();
            if (object.txHex != null)
                if (typeof object.txHex === "string")
                    $util.base64.decode(object.txHex, message.txHex = $util.newBuffer($util.base64.length(object.txHex)), 0);
                else if (object.txHex.length)
                    message.txHex = object.txHex;
            if (object.label != null)
                message.label = String(object.label);
            return message;
        };

        /**
         * Creates a plain object from a Transaction message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.Transaction
         * @static
         * @param {walletrpc.Transaction} message Transaction
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Transaction.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.txHex = "";
                else {
                    object.txHex = [];
                    if (options.bytes !== Array)
                        object.txHex = $util.newBuffer(object.txHex);
                }
                object.label = "";
            }
            if (message.txHex != null && message.hasOwnProperty("txHex"))
                object.txHex = options.bytes === String ? $util.base64.encode(message.txHex, 0, message.txHex.length) : options.bytes === Array ? Array.prototype.slice.call(message.txHex) : message.txHex;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            return object;
        };

        /**
         * Converts this Transaction to JSON.
         * @function toJSON
         * @memberof walletrpc.Transaction
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Transaction.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Transaction;
    })();

    walletrpc.PublishResponse = (function() {

        /**
         * Properties of a PublishResponse.
         * @memberof walletrpc
         * @interface IPublishResponse
         * @property {string|null} [publishError] PublishResponse publishError
         */

        /**
         * Constructs a new PublishResponse.
         * @memberof walletrpc
         * @classdesc Represents a PublishResponse.
         * @implements IPublishResponse
         * @constructor
         * @param {walletrpc.IPublishResponse=} [properties] Properties to set
         */
        function PublishResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PublishResponse publishError.
         * @member {string} publishError
         * @memberof walletrpc.PublishResponse
         * @instance
         */
        PublishResponse.prototype.publishError = "";

        /**
         * Creates a new PublishResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {walletrpc.IPublishResponse=} [properties] Properties to set
         * @returns {walletrpc.PublishResponse} PublishResponse instance
         */
        PublishResponse.create = function create(properties) {
            return new PublishResponse(properties);
        };

        /**
         * Encodes the specified PublishResponse message. Does not implicitly {@link walletrpc.PublishResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {walletrpc.IPublishResponse} message PublishResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publishError != null && Object.hasOwnProperty.call(message, "publishError"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.publishError);
            return writer;
        };

        /**
         * Encodes the specified PublishResponse message, length delimited. Does not implicitly {@link walletrpc.PublishResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {walletrpc.IPublishResponse} message PublishResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PublishResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PublishResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.PublishResponse} PublishResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.PublishResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.publishError = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PublishResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.PublishResponse} PublishResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PublishResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PublishResponse message.
         * @function verify
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PublishResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.publishError != null && message.hasOwnProperty("publishError"))
                if (!$util.isString(message.publishError))
                    return "publishError: string expected";
            return null;
        };

        /**
         * Creates a PublishResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.PublishResponse} PublishResponse
         */
        PublishResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.PublishResponse)
                return object;
            let message = new $root.walletrpc.PublishResponse();
            if (object.publishError != null)
                message.publishError = String(object.publishError);
            return message;
        };

        /**
         * Creates a plain object from a PublishResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.PublishResponse
         * @static
         * @param {walletrpc.PublishResponse} message PublishResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PublishResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.publishError = "";
            if (message.publishError != null && message.hasOwnProperty("publishError"))
                object.publishError = message.publishError;
            return object;
        };

        /**
         * Converts this PublishResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.PublishResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PublishResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PublishResponse;
    })();

    walletrpc.SendOutputsRequest = (function() {

        /**
         * Properties of a SendOutputsRequest.
         * @memberof walletrpc
         * @interface ISendOutputsRequest
         * @property {Long|null} [satPerKw] SendOutputsRequest satPerKw
         * @property {Array.<signrpc.ITxOut>|null} [outputs] SendOutputsRequest outputs
         * @property {string|null} [label] SendOutputsRequest label
         * @property {number|null} [minConfs] SendOutputsRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] SendOutputsRequest spendUnconfirmed
         */

        /**
         * Constructs a new SendOutputsRequest.
         * @memberof walletrpc
         * @classdesc Represents a SendOutputsRequest.
         * @implements ISendOutputsRequest
         * @constructor
         * @param {walletrpc.ISendOutputsRequest=} [properties] Properties to set
         */
        function SendOutputsRequest(properties) {
            this.outputs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendOutputsRequest satPerKw.
         * @member {Long} satPerKw
         * @memberof walletrpc.SendOutputsRequest
         * @instance
         */
        SendOutputsRequest.prototype.satPerKw = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * SendOutputsRequest outputs.
         * @member {Array.<signrpc.ITxOut>} outputs
         * @memberof walletrpc.SendOutputsRequest
         * @instance
         */
        SendOutputsRequest.prototype.outputs = $util.emptyArray;

        /**
         * SendOutputsRequest label.
         * @member {string} label
         * @memberof walletrpc.SendOutputsRequest
         * @instance
         */
        SendOutputsRequest.prototype.label = "";

        /**
         * SendOutputsRequest minConfs.
         * @member {number} minConfs
         * @memberof walletrpc.SendOutputsRequest
         * @instance
         */
        SendOutputsRequest.prototype.minConfs = 0;

        /**
         * SendOutputsRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof walletrpc.SendOutputsRequest
         * @instance
         */
        SendOutputsRequest.prototype.spendUnconfirmed = false;

        /**
         * Creates a new SendOutputsRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {walletrpc.ISendOutputsRequest=} [properties] Properties to set
         * @returns {walletrpc.SendOutputsRequest} SendOutputsRequest instance
         */
        SendOutputsRequest.create = function create(properties) {
            return new SendOutputsRequest(properties);
        };

        /**
         * Encodes the specified SendOutputsRequest message. Does not implicitly {@link walletrpc.SendOutputsRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {walletrpc.ISendOutputsRequest} message SendOutputsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendOutputsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.satPerKw != null && Object.hasOwnProperty.call(message, "satPerKw"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.satPerKw);
            if (message.outputs != null && message.outputs.length)
                for (let i = 0; i < message.outputs.length; ++i)
                    $root.signrpc.TxOut.encode(message.outputs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.label);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.spendUnconfirmed);
            return writer;
        };

        /**
         * Encodes the specified SendOutputsRequest message, length delimited. Does not implicitly {@link walletrpc.SendOutputsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {walletrpc.ISendOutputsRequest} message SendOutputsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendOutputsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendOutputsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.SendOutputsRequest} SendOutputsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendOutputsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.SendOutputsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.satPerKw = reader.int64();
                        break;
                    }
                case 2: {
                        if (!(message.outputs && message.outputs.length))
                            message.outputs = [];
                        message.outputs.push($root.signrpc.TxOut.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.label = reader.string();
                        break;
                    }
                case 4: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 5: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendOutputsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.SendOutputsRequest} SendOutputsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendOutputsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendOutputsRequest message.
         * @function verify
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendOutputsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.satPerKw != null && message.hasOwnProperty("satPerKw"))
                if (!$util.isInteger(message.satPerKw) && !(message.satPerKw && $util.isInteger(message.satPerKw.low) && $util.isInteger(message.satPerKw.high)))
                    return "satPerKw: integer|Long expected";
            if (message.outputs != null && message.hasOwnProperty("outputs")) {
                if (!Array.isArray(message.outputs))
                    return "outputs: array expected";
                for (let i = 0; i < message.outputs.length; ++i) {
                    let error = $root.signrpc.TxOut.verify(message.outputs[i]);
                    if (error)
                        return "outputs." + error;
                }
            }
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            return null;
        };

        /**
         * Creates a SendOutputsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.SendOutputsRequest} SendOutputsRequest
         */
        SendOutputsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.SendOutputsRequest)
                return object;
            let message = new $root.walletrpc.SendOutputsRequest();
            if (object.satPerKw != null)
                if ($util.Long)
                    (message.satPerKw = $util.Long.fromValue(object.satPerKw)).unsigned = false;
                else if (typeof object.satPerKw === "string")
                    message.satPerKw = parseInt(object.satPerKw, 10);
                else if (typeof object.satPerKw === "number")
                    message.satPerKw = object.satPerKw;
                else if (typeof object.satPerKw === "object")
                    message.satPerKw = new $util.LongBits(object.satPerKw.low >>> 0, object.satPerKw.high >>> 0).toNumber();
            if (object.outputs) {
                if (!Array.isArray(object.outputs))
                    throw TypeError(".walletrpc.SendOutputsRequest.outputs: array expected");
                message.outputs = [];
                for (let i = 0; i < object.outputs.length; ++i) {
                    if (typeof object.outputs[i] !== "object")
                        throw TypeError(".walletrpc.SendOutputsRequest.outputs: object expected");
                    message.outputs[i] = $root.signrpc.TxOut.fromObject(object.outputs[i]);
                }
            }
            if (object.label != null)
                message.label = String(object.label);
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            return message;
        };

        /**
         * Creates a plain object from a SendOutputsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.SendOutputsRequest
         * @static
         * @param {walletrpc.SendOutputsRequest} message SendOutputsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendOutputsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.outputs = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerKw = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerKw = options.longs === String ? "0" : 0;
                object.label = "";
                object.minConfs = 0;
                object.spendUnconfirmed = false;
            }
            if (message.satPerKw != null && message.hasOwnProperty("satPerKw"))
                if (typeof message.satPerKw === "number")
                    object.satPerKw = options.longs === String ? String(message.satPerKw) : message.satPerKw;
                else
                    object.satPerKw = options.longs === String ? $util.Long.prototype.toString.call(message.satPerKw) : options.longs === Number ? new $util.LongBits(message.satPerKw.low >>> 0, message.satPerKw.high >>> 0).toNumber() : message.satPerKw;
            if (message.outputs && message.outputs.length) {
                object.outputs = [];
                for (let j = 0; j < message.outputs.length; ++j)
                    object.outputs[j] = $root.signrpc.TxOut.toObject(message.outputs[j], options);
            }
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            return object;
        };

        /**
         * Converts this SendOutputsRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.SendOutputsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendOutputsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendOutputsRequest;
    })();

    walletrpc.SendOutputsResponse = (function() {

        /**
         * Properties of a SendOutputsResponse.
         * @memberof walletrpc
         * @interface ISendOutputsResponse
         * @property {Uint8Array|null} [rawTx] SendOutputsResponse rawTx
         */

        /**
         * Constructs a new SendOutputsResponse.
         * @memberof walletrpc
         * @classdesc Represents a SendOutputsResponse.
         * @implements ISendOutputsResponse
         * @constructor
         * @param {walletrpc.ISendOutputsResponse=} [properties] Properties to set
         */
        function SendOutputsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendOutputsResponse rawTx.
         * @member {Uint8Array} rawTx
         * @memberof walletrpc.SendOutputsResponse
         * @instance
         */
        SendOutputsResponse.prototype.rawTx = $util.newBuffer([]);

        /**
         * Creates a new SendOutputsResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {walletrpc.ISendOutputsResponse=} [properties] Properties to set
         * @returns {walletrpc.SendOutputsResponse} SendOutputsResponse instance
         */
        SendOutputsResponse.create = function create(properties) {
            return new SendOutputsResponse(properties);
        };

        /**
         * Encodes the specified SendOutputsResponse message. Does not implicitly {@link walletrpc.SendOutputsResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {walletrpc.ISendOutputsResponse} message SendOutputsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendOutputsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rawTx != null && Object.hasOwnProperty.call(message, "rawTx"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.rawTx);
            return writer;
        };

        /**
         * Encodes the specified SendOutputsResponse message, length delimited. Does not implicitly {@link walletrpc.SendOutputsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {walletrpc.ISendOutputsResponse} message SendOutputsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendOutputsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendOutputsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.SendOutputsResponse} SendOutputsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendOutputsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.SendOutputsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.rawTx = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendOutputsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.SendOutputsResponse} SendOutputsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendOutputsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendOutputsResponse message.
         * @function verify
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendOutputsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rawTx != null && message.hasOwnProperty("rawTx"))
                if (!(message.rawTx && typeof message.rawTx.length === "number" || $util.isString(message.rawTx)))
                    return "rawTx: buffer expected";
            return null;
        };

        /**
         * Creates a SendOutputsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.SendOutputsResponse} SendOutputsResponse
         */
        SendOutputsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.SendOutputsResponse)
                return object;
            let message = new $root.walletrpc.SendOutputsResponse();
            if (object.rawTx != null)
                if (typeof object.rawTx === "string")
                    $util.base64.decode(object.rawTx, message.rawTx = $util.newBuffer($util.base64.length(object.rawTx)), 0);
                else if (object.rawTx.length)
                    message.rawTx = object.rawTx;
            return message;
        };

        /**
         * Creates a plain object from a SendOutputsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.SendOutputsResponse
         * @static
         * @param {walletrpc.SendOutputsResponse} message SendOutputsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendOutputsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.rawTx = "";
                else {
                    object.rawTx = [];
                    if (options.bytes !== Array)
                        object.rawTx = $util.newBuffer(object.rawTx);
                }
            if (message.rawTx != null && message.hasOwnProperty("rawTx"))
                object.rawTx = options.bytes === String ? $util.base64.encode(message.rawTx, 0, message.rawTx.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawTx) : message.rawTx;
            return object;
        };

        /**
         * Converts this SendOutputsResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.SendOutputsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendOutputsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendOutputsResponse;
    })();

    walletrpc.EstimateFeeRequest = (function() {

        /**
         * Properties of an EstimateFeeRequest.
         * @memberof walletrpc
         * @interface IEstimateFeeRequest
         * @property {number|null} [confTarget] EstimateFeeRequest confTarget
         */

        /**
         * Constructs a new EstimateFeeRequest.
         * @memberof walletrpc
         * @classdesc Represents an EstimateFeeRequest.
         * @implements IEstimateFeeRequest
         * @constructor
         * @param {walletrpc.IEstimateFeeRequest=} [properties] Properties to set
         */
        function EstimateFeeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EstimateFeeRequest confTarget.
         * @member {number} confTarget
         * @memberof walletrpc.EstimateFeeRequest
         * @instance
         */
        EstimateFeeRequest.prototype.confTarget = 0;

        /**
         * Creates a new EstimateFeeRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {walletrpc.IEstimateFeeRequest=} [properties] Properties to set
         * @returns {walletrpc.EstimateFeeRequest} EstimateFeeRequest instance
         */
        EstimateFeeRequest.create = function create(properties) {
            return new EstimateFeeRequest(properties);
        };

        /**
         * Encodes the specified EstimateFeeRequest message. Does not implicitly {@link walletrpc.EstimateFeeRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {walletrpc.IEstimateFeeRequest} message EstimateFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.confTarget != null && Object.hasOwnProperty.call(message, "confTarget"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.confTarget);
            return writer;
        };

        /**
         * Encodes the specified EstimateFeeRequest message, length delimited. Does not implicitly {@link walletrpc.EstimateFeeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {walletrpc.IEstimateFeeRequest} message EstimateFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EstimateFeeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.EstimateFeeRequest} EstimateFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.EstimateFeeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.confTarget = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EstimateFeeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.EstimateFeeRequest} EstimateFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EstimateFeeRequest message.
         * @function verify
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EstimateFeeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.confTarget != null && message.hasOwnProperty("confTarget"))
                if (!$util.isInteger(message.confTarget))
                    return "confTarget: integer expected";
            return null;
        };

        /**
         * Creates an EstimateFeeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.EstimateFeeRequest} EstimateFeeRequest
         */
        EstimateFeeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.EstimateFeeRequest)
                return object;
            let message = new $root.walletrpc.EstimateFeeRequest();
            if (object.confTarget != null)
                message.confTarget = object.confTarget | 0;
            return message;
        };

        /**
         * Creates a plain object from an EstimateFeeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.EstimateFeeRequest
         * @static
         * @param {walletrpc.EstimateFeeRequest} message EstimateFeeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EstimateFeeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.confTarget = 0;
            if (message.confTarget != null && message.hasOwnProperty("confTarget"))
                object.confTarget = message.confTarget;
            return object;
        };

        /**
         * Converts this EstimateFeeRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.EstimateFeeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EstimateFeeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EstimateFeeRequest;
    })();

    walletrpc.EstimateFeeResponse = (function() {

        /**
         * Properties of an EstimateFeeResponse.
         * @memberof walletrpc
         * @interface IEstimateFeeResponse
         * @property {Long|null} [satPerKw] EstimateFeeResponse satPerKw
         */

        /**
         * Constructs a new EstimateFeeResponse.
         * @memberof walletrpc
         * @classdesc Represents an EstimateFeeResponse.
         * @implements IEstimateFeeResponse
         * @constructor
         * @param {walletrpc.IEstimateFeeResponse=} [properties] Properties to set
         */
        function EstimateFeeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EstimateFeeResponse satPerKw.
         * @member {Long} satPerKw
         * @memberof walletrpc.EstimateFeeResponse
         * @instance
         */
        EstimateFeeResponse.prototype.satPerKw = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new EstimateFeeResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {walletrpc.IEstimateFeeResponse=} [properties] Properties to set
         * @returns {walletrpc.EstimateFeeResponse} EstimateFeeResponse instance
         */
        EstimateFeeResponse.create = function create(properties) {
            return new EstimateFeeResponse(properties);
        };

        /**
         * Encodes the specified EstimateFeeResponse message. Does not implicitly {@link walletrpc.EstimateFeeResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {walletrpc.IEstimateFeeResponse} message EstimateFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.satPerKw != null && Object.hasOwnProperty.call(message, "satPerKw"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.satPerKw);
            return writer;
        };

        /**
         * Encodes the specified EstimateFeeResponse message, length delimited. Does not implicitly {@link walletrpc.EstimateFeeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {walletrpc.IEstimateFeeResponse} message EstimateFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EstimateFeeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EstimateFeeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.EstimateFeeResponse} EstimateFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.EstimateFeeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.satPerKw = reader.int64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EstimateFeeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.EstimateFeeResponse} EstimateFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EstimateFeeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EstimateFeeResponse message.
         * @function verify
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EstimateFeeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.satPerKw != null && message.hasOwnProperty("satPerKw"))
                if (!$util.isInteger(message.satPerKw) && !(message.satPerKw && $util.isInteger(message.satPerKw.low) && $util.isInteger(message.satPerKw.high)))
                    return "satPerKw: integer|Long expected";
            return null;
        };

        /**
         * Creates an EstimateFeeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.EstimateFeeResponse} EstimateFeeResponse
         */
        EstimateFeeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.EstimateFeeResponse)
                return object;
            let message = new $root.walletrpc.EstimateFeeResponse();
            if (object.satPerKw != null)
                if ($util.Long)
                    (message.satPerKw = $util.Long.fromValue(object.satPerKw)).unsigned = false;
                else if (typeof object.satPerKw === "string")
                    message.satPerKw = parseInt(object.satPerKw, 10);
                else if (typeof object.satPerKw === "number")
                    message.satPerKw = object.satPerKw;
                else if (typeof object.satPerKw === "object")
                    message.satPerKw = new $util.LongBits(object.satPerKw.low >>> 0, object.satPerKw.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an EstimateFeeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.EstimateFeeResponse
         * @static
         * @param {walletrpc.EstimateFeeResponse} message EstimateFeeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EstimateFeeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.satPerKw = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerKw = options.longs === String ? "0" : 0;
            if (message.satPerKw != null && message.hasOwnProperty("satPerKw"))
                if (typeof message.satPerKw === "number")
                    object.satPerKw = options.longs === String ? String(message.satPerKw) : message.satPerKw;
                else
                    object.satPerKw = options.longs === String ? $util.Long.prototype.toString.call(message.satPerKw) : options.longs === Number ? new $util.LongBits(message.satPerKw.low >>> 0, message.satPerKw.high >>> 0).toNumber() : message.satPerKw;
            return object;
        };

        /**
         * Converts this EstimateFeeResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.EstimateFeeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EstimateFeeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EstimateFeeResponse;
    })();

    /**
     * WitnessType enum.
     * @name walletrpc.WitnessType
     * @enum {number}
     * @property {number} UNKNOWN_WITNESS=0 UNKNOWN_WITNESS value
     * @property {number} COMMITMENT_TIME_LOCK=1 COMMITMENT_TIME_LOCK value
     * @property {number} COMMITMENT_NO_DELAY=2 COMMITMENT_NO_DELAY value
     * @property {number} COMMITMENT_REVOKE=3 COMMITMENT_REVOKE value
     * @property {number} HTLC_OFFERED_REVOKE=4 HTLC_OFFERED_REVOKE value
     * @property {number} HTLC_ACCEPTED_REVOKE=5 HTLC_ACCEPTED_REVOKE value
     * @property {number} HTLC_OFFERED_TIMEOUT_SECOND_LEVEL=6 HTLC_OFFERED_TIMEOUT_SECOND_LEVEL value
     * @property {number} HTLC_ACCEPTED_SUCCESS_SECOND_LEVEL=7 HTLC_ACCEPTED_SUCCESS_SECOND_LEVEL value
     * @property {number} HTLC_OFFERED_REMOTE_TIMEOUT=8 HTLC_OFFERED_REMOTE_TIMEOUT value
     * @property {number} HTLC_ACCEPTED_REMOTE_SUCCESS=9 HTLC_ACCEPTED_REMOTE_SUCCESS value
     * @property {number} HTLC_SECOND_LEVEL_REVOKE=10 HTLC_SECOND_LEVEL_REVOKE value
     * @property {number} WITNESS_KEY_HASH=11 WITNESS_KEY_HASH value
     * @property {number} NESTED_WITNESS_KEY_HASH=12 NESTED_WITNESS_KEY_HASH value
     * @property {number} COMMITMENT_ANCHOR=13 COMMITMENT_ANCHOR value
     */
    walletrpc.WitnessType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_WITNESS"] = 0;
        values[valuesById[1] = "COMMITMENT_TIME_LOCK"] = 1;
        values[valuesById[2] = "COMMITMENT_NO_DELAY"] = 2;
        values[valuesById[3] = "COMMITMENT_REVOKE"] = 3;
        values[valuesById[4] = "HTLC_OFFERED_REVOKE"] = 4;
        values[valuesById[5] = "HTLC_ACCEPTED_REVOKE"] = 5;
        values[valuesById[6] = "HTLC_OFFERED_TIMEOUT_SECOND_LEVEL"] = 6;
        values[valuesById[7] = "HTLC_ACCEPTED_SUCCESS_SECOND_LEVEL"] = 7;
        values[valuesById[8] = "HTLC_OFFERED_REMOTE_TIMEOUT"] = 8;
        values[valuesById[9] = "HTLC_ACCEPTED_REMOTE_SUCCESS"] = 9;
        values[valuesById[10] = "HTLC_SECOND_LEVEL_REVOKE"] = 10;
        values[valuesById[11] = "WITNESS_KEY_HASH"] = 11;
        values[valuesById[12] = "NESTED_WITNESS_KEY_HASH"] = 12;
        values[valuesById[13] = "COMMITMENT_ANCHOR"] = 13;
        return values;
    })();

    walletrpc.PendingSweep = (function() {

        /**
         * Properties of a PendingSweep.
         * @memberof walletrpc
         * @interface IPendingSweep
         * @property {lnrpc.IOutPoint|null} [outpoint] PendingSweep outpoint
         * @property {walletrpc.WitnessType|null} [witnessType] PendingSweep witnessType
         * @property {number|null} [amountSat] PendingSweep amountSat
         * @property {number|null} [satPerByte] PendingSweep satPerByte
         * @property {number|null} [broadcastAttempts] PendingSweep broadcastAttempts
         * @property {number|null} [nextBroadcastHeight] PendingSweep nextBroadcastHeight
         * @property {number|null} [requestedConfTarget] PendingSweep requestedConfTarget
         * @property {number|null} [requestedSatPerByte] PendingSweep requestedSatPerByte
         * @property {Long|null} [satPerVbyte] PendingSweep satPerVbyte
         * @property {Long|null} [requestedSatPerVbyte] PendingSweep requestedSatPerVbyte
         * @property {boolean|null} [force] PendingSweep force
         */

        /**
         * Constructs a new PendingSweep.
         * @memberof walletrpc
         * @classdesc Represents a PendingSweep.
         * @implements IPendingSweep
         * @constructor
         * @param {walletrpc.IPendingSweep=} [properties] Properties to set
         */
        function PendingSweep(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingSweep outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.outpoint = null;

        /**
         * PendingSweep witnessType.
         * @member {walletrpc.WitnessType} witnessType
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.witnessType = 0;

        /**
         * PendingSweep amountSat.
         * @member {number} amountSat
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.amountSat = 0;

        /**
         * PendingSweep satPerByte.
         * @member {number} satPerByte
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.satPerByte = 0;

        /**
         * PendingSweep broadcastAttempts.
         * @member {number} broadcastAttempts
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.broadcastAttempts = 0;

        /**
         * PendingSweep nextBroadcastHeight.
         * @member {number} nextBroadcastHeight
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.nextBroadcastHeight = 0;

        /**
         * PendingSweep requestedConfTarget.
         * @member {number} requestedConfTarget
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.requestedConfTarget = 0;

        /**
         * PendingSweep requestedSatPerByte.
         * @member {number} requestedSatPerByte
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.requestedSatPerByte = 0;

        /**
         * PendingSweep satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PendingSweep requestedSatPerVbyte.
         * @member {Long} requestedSatPerVbyte
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.requestedSatPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * PendingSweep force.
         * @member {boolean} force
         * @memberof walletrpc.PendingSweep
         * @instance
         */
        PendingSweep.prototype.force = false;

        /**
         * Creates a new PendingSweep instance using the specified properties.
         * @function create
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {walletrpc.IPendingSweep=} [properties] Properties to set
         * @returns {walletrpc.PendingSweep} PendingSweep instance
         */
        PendingSweep.create = function create(properties) {
            return new PendingSweep(properties);
        };

        /**
         * Encodes the specified PendingSweep message. Does not implicitly {@link walletrpc.PendingSweep.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {walletrpc.IPendingSweep} message PendingSweep message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingSweep.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.witnessType != null && Object.hasOwnProperty.call(message, "witnessType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.witnessType);
            if (message.amountSat != null && Object.hasOwnProperty.call(message, "amountSat"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.amountSat);
            if (message.satPerByte != null && Object.hasOwnProperty.call(message, "satPerByte"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.satPerByte);
            if (message.broadcastAttempts != null && Object.hasOwnProperty.call(message, "broadcastAttempts"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.broadcastAttempts);
            if (message.nextBroadcastHeight != null && Object.hasOwnProperty.call(message, "nextBroadcastHeight"))
                writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.nextBroadcastHeight);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.force);
            if (message.requestedConfTarget != null && Object.hasOwnProperty.call(message, "requestedConfTarget"))
                writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.requestedConfTarget);
            if (message.requestedSatPerByte != null && Object.hasOwnProperty.call(message, "requestedSatPerByte"))
                writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.requestedSatPerByte);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.satPerVbyte);
            if (message.requestedSatPerVbyte != null && Object.hasOwnProperty.call(message, "requestedSatPerVbyte"))
                writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.requestedSatPerVbyte);
            return writer;
        };

        /**
         * Encodes the specified PendingSweep message, length delimited. Does not implicitly {@link walletrpc.PendingSweep.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {walletrpc.IPendingSweep} message PendingSweep message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingSweep.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingSweep message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.PendingSweep} PendingSweep
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingSweep.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.PendingSweep();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.witnessType = reader.int32();
                        break;
                    }
                case 3: {
                        message.amountSat = reader.uint32();
                        break;
                    }
                case 4: {
                        message.satPerByte = reader.uint32();
                        break;
                    }
                case 5: {
                        message.broadcastAttempts = reader.uint32();
                        break;
                    }
                case 6: {
                        message.nextBroadcastHeight = reader.uint32();
                        break;
                    }
                case 8: {
                        message.requestedConfTarget = reader.uint32();
                        break;
                    }
                case 9: {
                        message.requestedSatPerByte = reader.uint32();
                        break;
                    }
                case 10: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                case 11: {
                        message.requestedSatPerVbyte = reader.uint64();
                        break;
                    }
                case 7: {
                        message.force = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingSweep message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.PendingSweep} PendingSweep
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingSweep.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingSweep message.
         * @function verify
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingSweep.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.witnessType != null && message.hasOwnProperty("witnessType"))
                switch (message.witnessType) {
                default:
                    return "witnessType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                    break;
                }
            if (message.amountSat != null && message.hasOwnProperty("amountSat"))
                if (!$util.isInteger(message.amountSat))
                    return "amountSat: integer expected";
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (!$util.isInteger(message.satPerByte))
                    return "satPerByte: integer expected";
            if (message.broadcastAttempts != null && message.hasOwnProperty("broadcastAttempts"))
                if (!$util.isInteger(message.broadcastAttempts))
                    return "broadcastAttempts: integer expected";
            if (message.nextBroadcastHeight != null && message.hasOwnProperty("nextBroadcastHeight"))
                if (!$util.isInteger(message.nextBroadcastHeight))
                    return "nextBroadcastHeight: integer expected";
            if (message.requestedConfTarget != null && message.hasOwnProperty("requestedConfTarget"))
                if (!$util.isInteger(message.requestedConfTarget))
                    return "requestedConfTarget: integer expected";
            if (message.requestedSatPerByte != null && message.hasOwnProperty("requestedSatPerByte"))
                if (!$util.isInteger(message.requestedSatPerByte))
                    return "requestedSatPerByte: integer expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            if (message.requestedSatPerVbyte != null && message.hasOwnProperty("requestedSatPerVbyte"))
                if (!$util.isInteger(message.requestedSatPerVbyte) && !(message.requestedSatPerVbyte && $util.isInteger(message.requestedSatPerVbyte.low) && $util.isInteger(message.requestedSatPerVbyte.high)))
                    return "requestedSatPerVbyte: integer|Long expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a PendingSweep message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.PendingSweep} PendingSweep
         */
        PendingSweep.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.PendingSweep)
                return object;
            let message = new $root.walletrpc.PendingSweep();
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".walletrpc.PendingSweep.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            switch (object.witnessType) {
            case "UNKNOWN_WITNESS":
            case 0:
                message.witnessType = 0;
                break;
            case "COMMITMENT_TIME_LOCK":
            case 1:
                message.witnessType = 1;
                break;
            case "COMMITMENT_NO_DELAY":
            case 2:
                message.witnessType = 2;
                break;
            case "COMMITMENT_REVOKE":
            case 3:
                message.witnessType = 3;
                break;
            case "HTLC_OFFERED_REVOKE":
            case 4:
                message.witnessType = 4;
                break;
            case "HTLC_ACCEPTED_REVOKE":
            case 5:
                message.witnessType = 5;
                break;
            case "HTLC_OFFERED_TIMEOUT_SECOND_LEVEL":
            case 6:
                message.witnessType = 6;
                break;
            case "HTLC_ACCEPTED_SUCCESS_SECOND_LEVEL":
            case 7:
                message.witnessType = 7;
                break;
            case "HTLC_OFFERED_REMOTE_TIMEOUT":
            case 8:
                message.witnessType = 8;
                break;
            case "HTLC_ACCEPTED_REMOTE_SUCCESS":
            case 9:
                message.witnessType = 9;
                break;
            case "HTLC_SECOND_LEVEL_REVOKE":
            case 10:
                message.witnessType = 10;
                break;
            case "WITNESS_KEY_HASH":
            case 11:
                message.witnessType = 11;
                break;
            case "NESTED_WITNESS_KEY_HASH":
            case 12:
                message.witnessType = 12;
                break;
            case "COMMITMENT_ANCHOR":
            case 13:
                message.witnessType = 13;
                break;
            }
            if (object.amountSat != null)
                message.amountSat = object.amountSat >>> 0;
            if (object.satPerByte != null)
                message.satPerByte = object.satPerByte >>> 0;
            if (object.broadcastAttempts != null)
                message.broadcastAttempts = object.broadcastAttempts >>> 0;
            if (object.nextBroadcastHeight != null)
                message.nextBroadcastHeight = object.nextBroadcastHeight >>> 0;
            if (object.requestedConfTarget != null)
                message.requestedConfTarget = object.requestedConfTarget >>> 0;
            if (object.requestedSatPerByte != null)
                message.requestedSatPerByte = object.requestedSatPerByte >>> 0;
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            if (object.requestedSatPerVbyte != null)
                if ($util.Long)
                    (message.requestedSatPerVbyte = $util.Long.fromValue(object.requestedSatPerVbyte)).unsigned = true;
                else if (typeof object.requestedSatPerVbyte === "string")
                    message.requestedSatPerVbyte = parseInt(object.requestedSatPerVbyte, 10);
                else if (typeof object.requestedSatPerVbyte === "number")
                    message.requestedSatPerVbyte = object.requestedSatPerVbyte;
                else if (typeof object.requestedSatPerVbyte === "object")
                    message.requestedSatPerVbyte = new $util.LongBits(object.requestedSatPerVbyte.low >>> 0, object.requestedSatPerVbyte.high >>> 0).toNumber(true);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a PendingSweep message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.PendingSweep
         * @static
         * @param {walletrpc.PendingSweep} message PendingSweep
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingSweep.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outpoint = null;
                object.witnessType = options.enums === String ? "UNKNOWN_WITNESS" : 0;
                object.amountSat = 0;
                object.satPerByte = 0;
                object.broadcastAttempts = 0;
                object.nextBroadcastHeight = 0;
                object.force = false;
                object.requestedConfTarget = 0;
                object.requestedSatPerByte = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.requestedSatPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.requestedSatPerVbyte = options.longs === String ? "0" : 0;
            }
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.witnessType != null && message.hasOwnProperty("witnessType"))
                object.witnessType = options.enums === String ? $root.walletrpc.WitnessType[message.witnessType] : message.witnessType;
            if (message.amountSat != null && message.hasOwnProperty("amountSat"))
                object.amountSat = message.amountSat;
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                object.satPerByte = message.satPerByte;
            if (message.broadcastAttempts != null && message.hasOwnProperty("broadcastAttempts"))
                object.broadcastAttempts = message.broadcastAttempts;
            if (message.nextBroadcastHeight != null && message.hasOwnProperty("nextBroadcastHeight"))
                object.nextBroadcastHeight = message.nextBroadcastHeight;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            if (message.requestedConfTarget != null && message.hasOwnProperty("requestedConfTarget"))
                object.requestedConfTarget = message.requestedConfTarget;
            if (message.requestedSatPerByte != null && message.hasOwnProperty("requestedSatPerByte"))
                object.requestedSatPerByte = message.requestedSatPerByte;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            if (message.requestedSatPerVbyte != null && message.hasOwnProperty("requestedSatPerVbyte"))
                if (typeof message.requestedSatPerVbyte === "number")
                    object.requestedSatPerVbyte = options.longs === String ? String(message.requestedSatPerVbyte) : message.requestedSatPerVbyte;
                else
                    object.requestedSatPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.requestedSatPerVbyte) : options.longs === Number ? new $util.LongBits(message.requestedSatPerVbyte.low >>> 0, message.requestedSatPerVbyte.high >>> 0).toNumber(true) : message.requestedSatPerVbyte;
            return object;
        };

        /**
         * Converts this PendingSweep to JSON.
         * @function toJSON
         * @memberof walletrpc.PendingSweep
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingSweep.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingSweep;
    })();

    walletrpc.PendingSweepsRequest = (function() {

        /**
         * Properties of a PendingSweepsRequest.
         * @memberof walletrpc
         * @interface IPendingSweepsRequest
         */

        /**
         * Constructs a new PendingSweepsRequest.
         * @memberof walletrpc
         * @classdesc Represents a PendingSweepsRequest.
         * @implements IPendingSweepsRequest
         * @constructor
         * @param {walletrpc.IPendingSweepsRequest=} [properties] Properties to set
         */
        function PendingSweepsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PendingSweepsRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {walletrpc.IPendingSweepsRequest=} [properties] Properties to set
         * @returns {walletrpc.PendingSweepsRequest} PendingSweepsRequest instance
         */
        PendingSweepsRequest.create = function create(properties) {
            return new PendingSweepsRequest(properties);
        };

        /**
         * Encodes the specified PendingSweepsRequest message. Does not implicitly {@link walletrpc.PendingSweepsRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {walletrpc.IPendingSweepsRequest} message PendingSweepsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingSweepsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PendingSweepsRequest message, length delimited. Does not implicitly {@link walletrpc.PendingSweepsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {walletrpc.IPendingSweepsRequest} message PendingSweepsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingSweepsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingSweepsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.PendingSweepsRequest} PendingSweepsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingSweepsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.PendingSweepsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingSweepsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.PendingSweepsRequest} PendingSweepsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingSweepsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingSweepsRequest message.
         * @function verify
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingSweepsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PendingSweepsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.PendingSweepsRequest} PendingSweepsRequest
         */
        PendingSweepsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.PendingSweepsRequest)
                return object;
            return new $root.walletrpc.PendingSweepsRequest();
        };

        /**
         * Creates a plain object from a PendingSweepsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.PendingSweepsRequest
         * @static
         * @param {walletrpc.PendingSweepsRequest} message PendingSweepsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingSweepsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PendingSweepsRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.PendingSweepsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingSweepsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingSweepsRequest;
    })();

    walletrpc.PendingSweepsResponse = (function() {

        /**
         * Properties of a PendingSweepsResponse.
         * @memberof walletrpc
         * @interface IPendingSweepsResponse
         * @property {Array.<walletrpc.IPendingSweep>|null} [pendingSweeps] PendingSweepsResponse pendingSweeps
         */

        /**
         * Constructs a new PendingSweepsResponse.
         * @memberof walletrpc
         * @classdesc Represents a PendingSweepsResponse.
         * @implements IPendingSweepsResponse
         * @constructor
         * @param {walletrpc.IPendingSweepsResponse=} [properties] Properties to set
         */
        function PendingSweepsResponse(properties) {
            this.pendingSweeps = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingSweepsResponse pendingSweeps.
         * @member {Array.<walletrpc.IPendingSweep>} pendingSweeps
         * @memberof walletrpc.PendingSweepsResponse
         * @instance
         */
        PendingSweepsResponse.prototype.pendingSweeps = $util.emptyArray;

        /**
         * Creates a new PendingSweepsResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {walletrpc.IPendingSweepsResponse=} [properties] Properties to set
         * @returns {walletrpc.PendingSweepsResponse} PendingSweepsResponse instance
         */
        PendingSweepsResponse.create = function create(properties) {
            return new PendingSweepsResponse(properties);
        };

        /**
         * Encodes the specified PendingSweepsResponse message. Does not implicitly {@link walletrpc.PendingSweepsResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {walletrpc.IPendingSweepsResponse} message PendingSweepsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingSweepsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pendingSweeps != null && message.pendingSweeps.length)
                for (let i = 0; i < message.pendingSweeps.length; ++i)
                    $root.walletrpc.PendingSweep.encode(message.pendingSweeps[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PendingSweepsResponse message, length delimited. Does not implicitly {@link walletrpc.PendingSweepsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {walletrpc.IPendingSweepsResponse} message PendingSweepsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingSweepsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingSweepsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.PendingSweepsResponse} PendingSweepsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingSweepsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.PendingSweepsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.pendingSweeps && message.pendingSweeps.length))
                            message.pendingSweeps = [];
                        message.pendingSweeps.push($root.walletrpc.PendingSweep.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingSweepsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.PendingSweepsResponse} PendingSweepsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingSweepsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingSweepsResponse message.
         * @function verify
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingSweepsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pendingSweeps != null && message.hasOwnProperty("pendingSweeps")) {
                if (!Array.isArray(message.pendingSweeps))
                    return "pendingSweeps: array expected";
                for (let i = 0; i < message.pendingSweeps.length; ++i) {
                    let error = $root.walletrpc.PendingSweep.verify(message.pendingSweeps[i]);
                    if (error)
                        return "pendingSweeps." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PendingSweepsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.PendingSweepsResponse} PendingSweepsResponse
         */
        PendingSweepsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.PendingSweepsResponse)
                return object;
            let message = new $root.walletrpc.PendingSweepsResponse();
            if (object.pendingSweeps) {
                if (!Array.isArray(object.pendingSweeps))
                    throw TypeError(".walletrpc.PendingSweepsResponse.pendingSweeps: array expected");
                message.pendingSweeps = [];
                for (let i = 0; i < object.pendingSweeps.length; ++i) {
                    if (typeof object.pendingSweeps[i] !== "object")
                        throw TypeError(".walletrpc.PendingSweepsResponse.pendingSweeps: object expected");
                    message.pendingSweeps[i] = $root.walletrpc.PendingSweep.fromObject(object.pendingSweeps[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PendingSweepsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.PendingSweepsResponse
         * @static
         * @param {walletrpc.PendingSweepsResponse} message PendingSweepsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingSweepsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.pendingSweeps = [];
            if (message.pendingSweeps && message.pendingSweeps.length) {
                object.pendingSweeps = [];
                for (let j = 0; j < message.pendingSweeps.length; ++j)
                    object.pendingSweeps[j] = $root.walletrpc.PendingSweep.toObject(message.pendingSweeps[j], options);
            }
            return object;
        };

        /**
         * Converts this PendingSweepsResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.PendingSweepsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingSweepsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingSweepsResponse;
    })();

    walletrpc.BumpFeeRequest = (function() {

        /**
         * Properties of a BumpFeeRequest.
         * @memberof walletrpc
         * @interface IBumpFeeRequest
         * @property {lnrpc.IOutPoint|null} [outpoint] BumpFeeRequest outpoint
         * @property {number|null} [targetConf] BumpFeeRequest targetConf
         * @property {number|null} [satPerByte] BumpFeeRequest satPerByte
         * @property {boolean|null} [force] BumpFeeRequest force
         * @property {Long|null} [satPerVbyte] BumpFeeRequest satPerVbyte
         */

        /**
         * Constructs a new BumpFeeRequest.
         * @memberof walletrpc
         * @classdesc Represents a BumpFeeRequest.
         * @implements IBumpFeeRequest
         * @constructor
         * @param {walletrpc.IBumpFeeRequest=} [properties] Properties to set
         */
        function BumpFeeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BumpFeeRequest outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof walletrpc.BumpFeeRequest
         * @instance
         */
        BumpFeeRequest.prototype.outpoint = null;

        /**
         * BumpFeeRequest targetConf.
         * @member {number} targetConf
         * @memberof walletrpc.BumpFeeRequest
         * @instance
         */
        BumpFeeRequest.prototype.targetConf = 0;

        /**
         * BumpFeeRequest satPerByte.
         * @member {number} satPerByte
         * @memberof walletrpc.BumpFeeRequest
         * @instance
         */
        BumpFeeRequest.prototype.satPerByte = 0;

        /**
         * BumpFeeRequest force.
         * @member {boolean} force
         * @memberof walletrpc.BumpFeeRequest
         * @instance
         */
        BumpFeeRequest.prototype.force = false;

        /**
         * BumpFeeRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof walletrpc.BumpFeeRequest
         * @instance
         */
        BumpFeeRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new BumpFeeRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {walletrpc.IBumpFeeRequest=} [properties] Properties to set
         * @returns {walletrpc.BumpFeeRequest} BumpFeeRequest instance
         */
        BumpFeeRequest.create = function create(properties) {
            return new BumpFeeRequest(properties);
        };

        /**
         * Encodes the specified BumpFeeRequest message. Does not implicitly {@link walletrpc.BumpFeeRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {walletrpc.IBumpFeeRequest} message BumpFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BumpFeeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.targetConf);
            if (message.satPerByte != null && Object.hasOwnProperty.call(message, "satPerByte"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.satPerByte);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.force);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.satPerVbyte);
            return writer;
        };

        /**
         * Encodes the specified BumpFeeRequest message, length delimited. Does not implicitly {@link walletrpc.BumpFeeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {walletrpc.IBumpFeeRequest} message BumpFeeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BumpFeeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BumpFeeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.BumpFeeRequest} BumpFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BumpFeeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.BumpFeeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.targetConf = reader.uint32();
                        break;
                    }
                case 3: {
                        message.satPerByte = reader.uint32();
                        break;
                    }
                case 4: {
                        message.force = reader.bool();
                        break;
                    }
                case 5: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BumpFeeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.BumpFeeRequest} BumpFeeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BumpFeeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BumpFeeRequest message.
         * @function verify
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BumpFeeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                if (!$util.isInteger(message.satPerByte))
                    return "satPerByte: integer expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            return null;
        };

        /**
         * Creates a BumpFeeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.BumpFeeRequest} BumpFeeRequest
         */
        BumpFeeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.BumpFeeRequest)
                return object;
            let message = new $root.walletrpc.BumpFeeRequest();
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".walletrpc.BumpFeeRequest.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            if (object.targetConf != null)
                message.targetConf = object.targetConf >>> 0;
            if (object.satPerByte != null)
                message.satPerByte = object.satPerByte >>> 0;
            if (object.force != null)
                message.force = Boolean(object.force);
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a BumpFeeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.BumpFeeRequest
         * @static
         * @param {walletrpc.BumpFeeRequest} message BumpFeeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BumpFeeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.outpoint = null;
                object.targetConf = 0;
                object.satPerByte = 0;
                object.force = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.satPerVbyte = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.satPerVbyte = options.longs === String ? "0" : 0;
            }
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.targetConf != null && message.hasOwnProperty("targetConf"))
                object.targetConf = message.targetConf;
            if (message.satPerByte != null && message.hasOwnProperty("satPerByte"))
                object.satPerByte = message.satPerByte;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte"))
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
            return object;
        };

        /**
         * Converts this BumpFeeRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.BumpFeeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BumpFeeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BumpFeeRequest;
    })();

    walletrpc.BumpFeeResponse = (function() {

        /**
         * Properties of a BumpFeeResponse.
         * @memberof walletrpc
         * @interface IBumpFeeResponse
         */

        /**
         * Constructs a new BumpFeeResponse.
         * @memberof walletrpc
         * @classdesc Represents a BumpFeeResponse.
         * @implements IBumpFeeResponse
         * @constructor
         * @param {walletrpc.IBumpFeeResponse=} [properties] Properties to set
         */
        function BumpFeeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new BumpFeeResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {walletrpc.IBumpFeeResponse=} [properties] Properties to set
         * @returns {walletrpc.BumpFeeResponse} BumpFeeResponse instance
         */
        BumpFeeResponse.create = function create(properties) {
            return new BumpFeeResponse(properties);
        };

        /**
         * Encodes the specified BumpFeeResponse message. Does not implicitly {@link walletrpc.BumpFeeResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {walletrpc.IBumpFeeResponse} message BumpFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BumpFeeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified BumpFeeResponse message, length delimited. Does not implicitly {@link walletrpc.BumpFeeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {walletrpc.IBumpFeeResponse} message BumpFeeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BumpFeeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BumpFeeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.BumpFeeResponse} BumpFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BumpFeeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.BumpFeeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BumpFeeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.BumpFeeResponse} BumpFeeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BumpFeeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BumpFeeResponse message.
         * @function verify
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BumpFeeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a BumpFeeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.BumpFeeResponse} BumpFeeResponse
         */
        BumpFeeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.BumpFeeResponse)
                return object;
            return new $root.walletrpc.BumpFeeResponse();
        };

        /**
         * Creates a plain object from a BumpFeeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.BumpFeeResponse
         * @static
         * @param {walletrpc.BumpFeeResponse} message BumpFeeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BumpFeeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this BumpFeeResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.BumpFeeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BumpFeeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BumpFeeResponse;
    })();

    walletrpc.ListSweepsRequest = (function() {

        /**
         * Properties of a ListSweepsRequest.
         * @memberof walletrpc
         * @interface IListSweepsRequest
         * @property {boolean|null} [verbose] ListSweepsRequest verbose
         */

        /**
         * Constructs a new ListSweepsRequest.
         * @memberof walletrpc
         * @classdesc Represents a ListSweepsRequest.
         * @implements IListSweepsRequest
         * @constructor
         * @param {walletrpc.IListSweepsRequest=} [properties] Properties to set
         */
        function ListSweepsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListSweepsRequest verbose.
         * @member {boolean} verbose
         * @memberof walletrpc.ListSweepsRequest
         * @instance
         */
        ListSweepsRequest.prototype.verbose = false;

        /**
         * Creates a new ListSweepsRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {walletrpc.IListSweepsRequest=} [properties] Properties to set
         * @returns {walletrpc.ListSweepsRequest} ListSweepsRequest instance
         */
        ListSweepsRequest.create = function create(properties) {
            return new ListSweepsRequest(properties);
        };

        /**
         * Encodes the specified ListSweepsRequest message. Does not implicitly {@link walletrpc.ListSweepsRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {walletrpc.IListSweepsRequest} message ListSweepsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListSweepsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.verbose != null && Object.hasOwnProperty.call(message, "verbose"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.verbose);
            return writer;
        };

        /**
         * Encodes the specified ListSweepsRequest message, length delimited. Does not implicitly {@link walletrpc.ListSweepsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {walletrpc.IListSweepsRequest} message ListSweepsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListSweepsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListSweepsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListSweepsRequest} ListSweepsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListSweepsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListSweepsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.verbose = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListSweepsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListSweepsRequest} ListSweepsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListSweepsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListSweepsRequest message.
         * @function verify
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListSweepsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.verbose != null && message.hasOwnProperty("verbose"))
                if (typeof message.verbose !== "boolean")
                    return "verbose: boolean expected";
            return null;
        };

        /**
         * Creates a ListSweepsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListSweepsRequest} ListSweepsRequest
         */
        ListSweepsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListSweepsRequest)
                return object;
            let message = new $root.walletrpc.ListSweepsRequest();
            if (object.verbose != null)
                message.verbose = Boolean(object.verbose);
            return message;
        };

        /**
         * Creates a plain object from a ListSweepsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListSweepsRequest
         * @static
         * @param {walletrpc.ListSweepsRequest} message ListSweepsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListSweepsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.verbose = false;
            if (message.verbose != null && message.hasOwnProperty("verbose"))
                object.verbose = message.verbose;
            return object;
        };

        /**
         * Converts this ListSweepsRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ListSweepsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListSweepsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListSweepsRequest;
    })();

    walletrpc.ListSweepsResponse = (function() {

        /**
         * Properties of a ListSweepsResponse.
         * @memberof walletrpc
         * @interface IListSweepsResponse
         * @property {lnrpc.ITransactionDetails|null} [transactionDetails] ListSweepsResponse transactionDetails
         * @property {walletrpc.ListSweepsResponse.ITransactionIDs|null} [transactionIds] ListSweepsResponse transactionIds
         */

        /**
         * Constructs a new ListSweepsResponse.
         * @memberof walletrpc
         * @classdesc Represents a ListSweepsResponse.
         * @implements IListSweepsResponse
         * @constructor
         * @param {walletrpc.IListSweepsResponse=} [properties] Properties to set
         */
        function ListSweepsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListSweepsResponse transactionDetails.
         * @member {lnrpc.ITransactionDetails|null|undefined} transactionDetails
         * @memberof walletrpc.ListSweepsResponse
         * @instance
         */
        ListSweepsResponse.prototype.transactionDetails = null;

        /**
         * ListSweepsResponse transactionIds.
         * @member {walletrpc.ListSweepsResponse.ITransactionIDs|null|undefined} transactionIds
         * @memberof walletrpc.ListSweepsResponse
         * @instance
         */
        ListSweepsResponse.prototype.transactionIds = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ListSweepsResponse sweeps.
         * @member {"transactionDetails"|"transactionIds"|undefined} sweeps
         * @memberof walletrpc.ListSweepsResponse
         * @instance
         */
        Object.defineProperty(ListSweepsResponse.prototype, "sweeps", {
            get: $util.oneOfGetter($oneOfFields = ["transactionDetails", "transactionIds"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ListSweepsResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {walletrpc.IListSweepsResponse=} [properties] Properties to set
         * @returns {walletrpc.ListSweepsResponse} ListSweepsResponse instance
         */
        ListSweepsResponse.create = function create(properties) {
            return new ListSweepsResponse(properties);
        };

        /**
         * Encodes the specified ListSweepsResponse message. Does not implicitly {@link walletrpc.ListSweepsResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {walletrpc.IListSweepsResponse} message ListSweepsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListSweepsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.transactionDetails != null && Object.hasOwnProperty.call(message, "transactionDetails"))
                $root.lnrpc.TransactionDetails.encode(message.transactionDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.transactionIds != null && Object.hasOwnProperty.call(message, "transactionIds"))
                $root.walletrpc.ListSweepsResponse.TransactionIDs.encode(message.transactionIds, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListSweepsResponse message, length delimited. Does not implicitly {@link walletrpc.ListSweepsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {walletrpc.IListSweepsResponse} message ListSweepsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListSweepsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListSweepsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListSweepsResponse} ListSweepsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListSweepsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListSweepsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.transactionDetails = $root.lnrpc.TransactionDetails.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.transactionIds = $root.walletrpc.ListSweepsResponse.TransactionIDs.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListSweepsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListSweepsResponse} ListSweepsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListSweepsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListSweepsResponse message.
         * @function verify
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListSweepsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.transactionDetails != null && message.hasOwnProperty("transactionDetails")) {
                properties.sweeps = 1;
                {
                    let error = $root.lnrpc.TransactionDetails.verify(message.transactionDetails);
                    if (error)
                        return "transactionDetails." + error;
                }
            }
            if (message.transactionIds != null && message.hasOwnProperty("transactionIds")) {
                if (properties.sweeps === 1)
                    return "sweeps: multiple values";
                properties.sweeps = 1;
                {
                    let error = $root.walletrpc.ListSweepsResponse.TransactionIDs.verify(message.transactionIds);
                    if (error)
                        return "transactionIds." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListSweepsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListSweepsResponse} ListSweepsResponse
         */
        ListSweepsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListSweepsResponse)
                return object;
            let message = new $root.walletrpc.ListSweepsResponse();
            if (object.transactionDetails != null) {
                if (typeof object.transactionDetails !== "object")
                    throw TypeError(".walletrpc.ListSweepsResponse.transactionDetails: object expected");
                message.transactionDetails = $root.lnrpc.TransactionDetails.fromObject(object.transactionDetails);
            }
            if (object.transactionIds != null) {
                if (typeof object.transactionIds !== "object")
                    throw TypeError(".walletrpc.ListSweepsResponse.transactionIds: object expected");
                message.transactionIds = $root.walletrpc.ListSweepsResponse.TransactionIDs.fromObject(object.transactionIds);
            }
            return message;
        };

        /**
         * Creates a plain object from a ListSweepsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListSweepsResponse
         * @static
         * @param {walletrpc.ListSweepsResponse} message ListSweepsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListSweepsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.transactionDetails != null && message.hasOwnProperty("transactionDetails")) {
                object.transactionDetails = $root.lnrpc.TransactionDetails.toObject(message.transactionDetails, options);
                if (options.oneofs)
                    object.sweeps = "transactionDetails";
            }
            if (message.transactionIds != null && message.hasOwnProperty("transactionIds")) {
                object.transactionIds = $root.walletrpc.ListSweepsResponse.TransactionIDs.toObject(message.transactionIds, options);
                if (options.oneofs)
                    object.sweeps = "transactionIds";
            }
            return object;
        };

        /**
         * Converts this ListSweepsResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ListSweepsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListSweepsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ListSweepsResponse.TransactionIDs = (function() {

            /**
             * Properties of a TransactionIDs.
             * @memberof walletrpc.ListSweepsResponse
             * @interface ITransactionIDs
             * @property {Array.<string>|null} [transactionIds] TransactionIDs transactionIds
             */

            /**
             * Constructs a new TransactionIDs.
             * @memberof walletrpc.ListSweepsResponse
             * @classdesc Represents a TransactionIDs.
             * @implements ITransactionIDs
             * @constructor
             * @param {walletrpc.ListSweepsResponse.ITransactionIDs=} [properties] Properties to set
             */
            function TransactionIDs(properties) {
                this.transactionIds = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TransactionIDs transactionIds.
             * @member {Array.<string>} transactionIds
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @instance
             */
            TransactionIDs.prototype.transactionIds = $util.emptyArray;

            /**
             * Creates a new TransactionIDs instance using the specified properties.
             * @function create
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {walletrpc.ListSweepsResponse.ITransactionIDs=} [properties] Properties to set
             * @returns {walletrpc.ListSweepsResponse.TransactionIDs} TransactionIDs instance
             */
            TransactionIDs.create = function create(properties) {
                return new TransactionIDs(properties);
            };

            /**
             * Encodes the specified TransactionIDs message. Does not implicitly {@link walletrpc.ListSweepsResponse.TransactionIDs.verify|verify} messages.
             * @function encode
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {walletrpc.ListSweepsResponse.ITransactionIDs} message TransactionIDs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionIDs.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.transactionIds != null && message.transactionIds.length)
                    for (let i = 0; i < message.transactionIds.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionIds[i]);
                return writer;
            };

            /**
             * Encodes the specified TransactionIDs message, length delimited. Does not implicitly {@link walletrpc.ListSweepsResponse.TransactionIDs.verify|verify} messages.
             * @function encodeDelimited
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {walletrpc.ListSweepsResponse.ITransactionIDs} message TransactionIDs message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TransactionIDs.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TransactionIDs message from the specified reader or buffer.
             * @function decode
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {walletrpc.ListSweepsResponse.TransactionIDs} TransactionIDs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionIDs.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListSweepsResponse.TransactionIDs();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.transactionIds && message.transactionIds.length))
                                message.transactionIds = [];
                            message.transactionIds.push(reader.string());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TransactionIDs message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {walletrpc.ListSweepsResponse.TransactionIDs} TransactionIDs
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TransactionIDs.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TransactionIDs message.
             * @function verify
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TransactionIDs.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.transactionIds != null && message.hasOwnProperty("transactionIds")) {
                    if (!Array.isArray(message.transactionIds))
                        return "transactionIds: array expected";
                    for (let i = 0; i < message.transactionIds.length; ++i)
                        if (!$util.isString(message.transactionIds[i]))
                            return "transactionIds: string[] expected";
                }
                return null;
            };

            /**
             * Creates a TransactionIDs message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {walletrpc.ListSweepsResponse.TransactionIDs} TransactionIDs
             */
            TransactionIDs.fromObject = function fromObject(object) {
                if (object instanceof $root.walletrpc.ListSweepsResponse.TransactionIDs)
                    return object;
                let message = new $root.walletrpc.ListSweepsResponse.TransactionIDs();
                if (object.transactionIds) {
                    if (!Array.isArray(object.transactionIds))
                        throw TypeError(".walletrpc.ListSweepsResponse.TransactionIDs.transactionIds: array expected");
                    message.transactionIds = [];
                    for (let i = 0; i < object.transactionIds.length; ++i)
                        message.transactionIds[i] = String(object.transactionIds[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a TransactionIDs message. Also converts values to other types if specified.
             * @function toObject
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @static
             * @param {walletrpc.ListSweepsResponse.TransactionIDs} message TransactionIDs
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TransactionIDs.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.transactionIds = [];
                if (message.transactionIds && message.transactionIds.length) {
                    object.transactionIds = [];
                    for (let j = 0; j < message.transactionIds.length; ++j)
                        object.transactionIds[j] = message.transactionIds[j];
                }
                return object;
            };

            /**
             * Converts this TransactionIDs to JSON.
             * @function toJSON
             * @memberof walletrpc.ListSweepsResponse.TransactionIDs
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TransactionIDs.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TransactionIDs;
        })();

        return ListSweepsResponse;
    })();

    walletrpc.LabelTransactionRequest = (function() {

        /**
         * Properties of a LabelTransactionRequest.
         * @memberof walletrpc
         * @interface ILabelTransactionRequest
         * @property {Uint8Array|null} [txid] LabelTransactionRequest txid
         * @property {string|null} [label] LabelTransactionRequest label
         * @property {boolean|null} [overwrite] LabelTransactionRequest overwrite
         */

        /**
         * Constructs a new LabelTransactionRequest.
         * @memberof walletrpc
         * @classdesc Represents a LabelTransactionRequest.
         * @implements ILabelTransactionRequest
         * @constructor
         * @param {walletrpc.ILabelTransactionRequest=} [properties] Properties to set
         */
        function LabelTransactionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LabelTransactionRequest txid.
         * @member {Uint8Array} txid
         * @memberof walletrpc.LabelTransactionRequest
         * @instance
         */
        LabelTransactionRequest.prototype.txid = $util.newBuffer([]);

        /**
         * LabelTransactionRequest label.
         * @member {string} label
         * @memberof walletrpc.LabelTransactionRequest
         * @instance
         */
        LabelTransactionRequest.prototype.label = "";

        /**
         * LabelTransactionRequest overwrite.
         * @member {boolean} overwrite
         * @memberof walletrpc.LabelTransactionRequest
         * @instance
         */
        LabelTransactionRequest.prototype.overwrite = false;

        /**
         * Creates a new LabelTransactionRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {walletrpc.ILabelTransactionRequest=} [properties] Properties to set
         * @returns {walletrpc.LabelTransactionRequest} LabelTransactionRequest instance
         */
        LabelTransactionRequest.create = function create(properties) {
            return new LabelTransactionRequest(properties);
        };

        /**
         * Encodes the specified LabelTransactionRequest message. Does not implicitly {@link walletrpc.LabelTransactionRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {walletrpc.ILabelTransactionRequest} message LabelTransactionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabelTransactionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.txid != null && Object.hasOwnProperty.call(message, "txid"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.txid);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.overwrite != null && Object.hasOwnProperty.call(message, "overwrite"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.overwrite);
            return writer;
        };

        /**
         * Encodes the specified LabelTransactionRequest message, length delimited. Does not implicitly {@link walletrpc.LabelTransactionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {walletrpc.ILabelTransactionRequest} message LabelTransactionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabelTransactionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LabelTransactionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.LabelTransactionRequest} LabelTransactionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabelTransactionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.LabelTransactionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.txid = reader.bytes();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                case 3: {
                        message.overwrite = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LabelTransactionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.LabelTransactionRequest} LabelTransactionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabelTransactionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LabelTransactionRequest message.
         * @function verify
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LabelTransactionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.txid != null && message.hasOwnProperty("txid"))
                if (!(message.txid && typeof message.txid.length === "number" || $util.isString(message.txid)))
                    return "txid: buffer expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.overwrite != null && message.hasOwnProperty("overwrite"))
                if (typeof message.overwrite !== "boolean")
                    return "overwrite: boolean expected";
            return null;
        };

        /**
         * Creates a LabelTransactionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.LabelTransactionRequest} LabelTransactionRequest
         */
        LabelTransactionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.LabelTransactionRequest)
                return object;
            let message = new $root.walletrpc.LabelTransactionRequest();
            if (object.txid != null)
                if (typeof object.txid === "string")
                    $util.base64.decode(object.txid, message.txid = $util.newBuffer($util.base64.length(object.txid)), 0);
                else if (object.txid.length)
                    message.txid = object.txid;
            if (object.label != null)
                message.label = String(object.label);
            if (object.overwrite != null)
                message.overwrite = Boolean(object.overwrite);
            return message;
        };

        /**
         * Creates a plain object from a LabelTransactionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.LabelTransactionRequest
         * @static
         * @param {walletrpc.LabelTransactionRequest} message LabelTransactionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LabelTransactionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.txid = "";
                else {
                    object.txid = [];
                    if (options.bytes !== Array)
                        object.txid = $util.newBuffer(object.txid);
                }
                object.label = "";
                object.overwrite = false;
            }
            if (message.txid != null && message.hasOwnProperty("txid"))
                object.txid = options.bytes === String ? $util.base64.encode(message.txid, 0, message.txid.length) : options.bytes === Array ? Array.prototype.slice.call(message.txid) : message.txid;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.overwrite != null && message.hasOwnProperty("overwrite"))
                object.overwrite = message.overwrite;
            return object;
        };

        /**
         * Converts this LabelTransactionRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.LabelTransactionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LabelTransactionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LabelTransactionRequest;
    })();

    walletrpc.LabelTransactionResponse = (function() {

        /**
         * Properties of a LabelTransactionResponse.
         * @memberof walletrpc
         * @interface ILabelTransactionResponse
         */

        /**
         * Constructs a new LabelTransactionResponse.
         * @memberof walletrpc
         * @classdesc Represents a LabelTransactionResponse.
         * @implements ILabelTransactionResponse
         * @constructor
         * @param {walletrpc.ILabelTransactionResponse=} [properties] Properties to set
         */
        function LabelTransactionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LabelTransactionResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {walletrpc.ILabelTransactionResponse=} [properties] Properties to set
         * @returns {walletrpc.LabelTransactionResponse} LabelTransactionResponse instance
         */
        LabelTransactionResponse.create = function create(properties) {
            return new LabelTransactionResponse(properties);
        };

        /**
         * Encodes the specified LabelTransactionResponse message. Does not implicitly {@link walletrpc.LabelTransactionResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {walletrpc.ILabelTransactionResponse} message LabelTransactionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabelTransactionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LabelTransactionResponse message, length delimited. Does not implicitly {@link walletrpc.LabelTransactionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {walletrpc.ILabelTransactionResponse} message LabelTransactionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LabelTransactionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LabelTransactionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.LabelTransactionResponse} LabelTransactionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabelTransactionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.LabelTransactionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LabelTransactionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.LabelTransactionResponse} LabelTransactionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LabelTransactionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LabelTransactionResponse message.
         * @function verify
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LabelTransactionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LabelTransactionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.LabelTransactionResponse} LabelTransactionResponse
         */
        LabelTransactionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.LabelTransactionResponse)
                return object;
            return new $root.walletrpc.LabelTransactionResponse();
        };

        /**
         * Creates a plain object from a LabelTransactionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.LabelTransactionResponse
         * @static
         * @param {walletrpc.LabelTransactionResponse} message LabelTransactionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LabelTransactionResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LabelTransactionResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.LabelTransactionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LabelTransactionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LabelTransactionResponse;
    })();

    walletrpc.FundPsbtRequest = (function() {

        /**
         * Properties of a FundPsbtRequest.
         * @memberof walletrpc
         * @interface IFundPsbtRequest
         * @property {Uint8Array|null} [psbt] FundPsbtRequest psbt
         * @property {walletrpc.ITxTemplate|null} [raw] FundPsbtRequest raw
         * @property {number|null} [targetConf] FundPsbtRequest targetConf
         * @property {Long|null} [satPerVbyte] FundPsbtRequest satPerVbyte
         * @property {string|null} [account] FundPsbtRequest account
         * @property {number|null} [minConfs] FundPsbtRequest minConfs
         * @property {boolean|null} [spendUnconfirmed] FundPsbtRequest spendUnconfirmed
         */

        /**
         * Constructs a new FundPsbtRequest.
         * @memberof walletrpc
         * @classdesc Represents a FundPsbtRequest.
         * @implements IFundPsbtRequest
         * @constructor
         * @param {walletrpc.IFundPsbtRequest=} [properties] Properties to set
         */
        function FundPsbtRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundPsbtRequest psbt.
         * @member {Uint8Array} psbt
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.psbt = $util.newBuffer([]);

        /**
         * FundPsbtRequest raw.
         * @member {walletrpc.ITxTemplate|null|undefined} raw
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.raw = null;

        /**
         * FundPsbtRequest targetConf.
         * @member {number} targetConf
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.targetConf = 0;

        /**
         * FundPsbtRequest satPerVbyte.
         * @member {Long} satPerVbyte
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.satPerVbyte = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * FundPsbtRequest account.
         * @member {string} account
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.account = "";

        /**
         * FundPsbtRequest minConfs.
         * @member {number} minConfs
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.minConfs = 0;

        /**
         * FundPsbtRequest spendUnconfirmed.
         * @member {boolean} spendUnconfirmed
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        FundPsbtRequest.prototype.spendUnconfirmed = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * FundPsbtRequest template.
         * @member {"psbt"|"raw"|undefined} template
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        Object.defineProperty(FundPsbtRequest.prototype, "template", {
            get: $util.oneOfGetter($oneOfFields = ["psbt", "raw"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FundPsbtRequest fees.
         * @member {"targetConf"|"satPerVbyte"|undefined} fees
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         */
        Object.defineProperty(FundPsbtRequest.prototype, "fees", {
            get: $util.oneOfGetter($oneOfFields = ["targetConf", "satPerVbyte"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new FundPsbtRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {walletrpc.IFundPsbtRequest=} [properties] Properties to set
         * @returns {walletrpc.FundPsbtRequest} FundPsbtRequest instance
         */
        FundPsbtRequest.create = function create(properties) {
            return new FundPsbtRequest(properties);
        };

        /**
         * Encodes the specified FundPsbtRequest message. Does not implicitly {@link walletrpc.FundPsbtRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {walletrpc.IFundPsbtRequest} message FundPsbtRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundPsbtRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.psbt != null && Object.hasOwnProperty.call(message, "psbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.psbt);
            if (message.raw != null && Object.hasOwnProperty.call(message, "raw"))
                $root.walletrpc.TxTemplate.encode(message.raw, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.targetConf != null && Object.hasOwnProperty.call(message, "targetConf"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.targetConf);
            if (message.satPerVbyte != null && Object.hasOwnProperty.call(message, "satPerVbyte"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.satPerVbyte);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.account);
            if (message.minConfs != null && Object.hasOwnProperty.call(message, "minConfs"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.minConfs);
            if (message.spendUnconfirmed != null && Object.hasOwnProperty.call(message, "spendUnconfirmed"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.spendUnconfirmed);
            return writer;
        };

        /**
         * Encodes the specified FundPsbtRequest message, length delimited. Does not implicitly {@link walletrpc.FundPsbtRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {walletrpc.IFundPsbtRequest} message FundPsbtRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundPsbtRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundPsbtRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.FundPsbtRequest} FundPsbtRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundPsbtRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.FundPsbtRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.psbt = reader.bytes();
                        break;
                    }
                case 2: {
                        message.raw = $root.walletrpc.TxTemplate.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.targetConf = reader.uint32();
                        break;
                    }
                case 4: {
                        message.satPerVbyte = reader.uint64();
                        break;
                    }
                case 5: {
                        message.account = reader.string();
                        break;
                    }
                case 6: {
                        message.minConfs = reader.int32();
                        break;
                    }
                case 7: {
                        message.spendUnconfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundPsbtRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.FundPsbtRequest} FundPsbtRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundPsbtRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundPsbtRequest message.
         * @function verify
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundPsbtRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.psbt != null && message.hasOwnProperty("psbt")) {
                properties.template = 1;
                if (!(message.psbt && typeof message.psbt.length === "number" || $util.isString(message.psbt)))
                    return "psbt: buffer expected";
            }
            if (message.raw != null && message.hasOwnProperty("raw")) {
                if (properties.template === 1)
                    return "template: multiple values";
                properties.template = 1;
                {
                    let error = $root.walletrpc.TxTemplate.verify(message.raw);
                    if (error)
                        return "raw." + error;
                }
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf")) {
                properties.fees = 1;
                if (!$util.isInteger(message.targetConf))
                    return "targetConf: integer expected";
            }
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte")) {
                if (properties.fees === 1)
                    return "fees: multiple values";
                properties.fees = 1;
                if (!$util.isInteger(message.satPerVbyte) && !(message.satPerVbyte && $util.isInteger(message.satPerVbyte.low) && $util.isInteger(message.satPerVbyte.high)))
                    return "satPerVbyte: integer|Long expected";
            }
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                if (!$util.isInteger(message.minConfs))
                    return "minConfs: integer expected";
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                if (typeof message.spendUnconfirmed !== "boolean")
                    return "spendUnconfirmed: boolean expected";
            return null;
        };

        /**
         * Creates a FundPsbtRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.FundPsbtRequest} FundPsbtRequest
         */
        FundPsbtRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.FundPsbtRequest)
                return object;
            let message = new $root.walletrpc.FundPsbtRequest();
            if (object.psbt != null)
                if (typeof object.psbt === "string")
                    $util.base64.decode(object.psbt, message.psbt = $util.newBuffer($util.base64.length(object.psbt)), 0);
                else if (object.psbt.length)
                    message.psbt = object.psbt;
            if (object.raw != null) {
                if (typeof object.raw !== "object")
                    throw TypeError(".walletrpc.FundPsbtRequest.raw: object expected");
                message.raw = $root.walletrpc.TxTemplate.fromObject(object.raw);
            }
            if (object.targetConf != null)
                message.targetConf = object.targetConf >>> 0;
            if (object.satPerVbyte != null)
                if ($util.Long)
                    (message.satPerVbyte = $util.Long.fromValue(object.satPerVbyte)).unsigned = true;
                else if (typeof object.satPerVbyte === "string")
                    message.satPerVbyte = parseInt(object.satPerVbyte, 10);
                else if (typeof object.satPerVbyte === "number")
                    message.satPerVbyte = object.satPerVbyte;
                else if (typeof object.satPerVbyte === "object")
                    message.satPerVbyte = new $util.LongBits(object.satPerVbyte.low >>> 0, object.satPerVbyte.high >>> 0).toNumber(true);
            if (object.account != null)
                message.account = String(object.account);
            if (object.minConfs != null)
                message.minConfs = object.minConfs | 0;
            if (object.spendUnconfirmed != null)
                message.spendUnconfirmed = Boolean(object.spendUnconfirmed);
            return message;
        };

        /**
         * Creates a plain object from a FundPsbtRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.FundPsbtRequest
         * @static
         * @param {walletrpc.FundPsbtRequest} message FundPsbtRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundPsbtRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.account = "";
                object.minConfs = 0;
                object.spendUnconfirmed = false;
            }
            if (message.psbt != null && message.hasOwnProperty("psbt")) {
                object.psbt = options.bytes === String ? $util.base64.encode(message.psbt, 0, message.psbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.psbt) : message.psbt;
                if (options.oneofs)
                    object.template = "psbt";
            }
            if (message.raw != null && message.hasOwnProperty("raw")) {
                object.raw = $root.walletrpc.TxTemplate.toObject(message.raw, options);
                if (options.oneofs)
                    object.template = "raw";
            }
            if (message.targetConf != null && message.hasOwnProperty("targetConf")) {
                object.targetConf = message.targetConf;
                if (options.oneofs)
                    object.fees = "targetConf";
            }
            if (message.satPerVbyte != null && message.hasOwnProperty("satPerVbyte")) {
                if (typeof message.satPerVbyte === "number")
                    object.satPerVbyte = options.longs === String ? String(message.satPerVbyte) : message.satPerVbyte;
                else
                    object.satPerVbyte = options.longs === String ? $util.Long.prototype.toString.call(message.satPerVbyte) : options.longs === Number ? new $util.LongBits(message.satPerVbyte.low >>> 0, message.satPerVbyte.high >>> 0).toNumber(true) : message.satPerVbyte;
                if (options.oneofs)
                    object.fees = "satPerVbyte";
            }
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            if (message.minConfs != null && message.hasOwnProperty("minConfs"))
                object.minConfs = message.minConfs;
            if (message.spendUnconfirmed != null && message.hasOwnProperty("spendUnconfirmed"))
                object.spendUnconfirmed = message.spendUnconfirmed;
            return object;
        };

        /**
         * Converts this FundPsbtRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.FundPsbtRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundPsbtRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundPsbtRequest;
    })();

    walletrpc.FundPsbtResponse = (function() {

        /**
         * Properties of a FundPsbtResponse.
         * @memberof walletrpc
         * @interface IFundPsbtResponse
         * @property {Uint8Array|null} [fundedPsbt] FundPsbtResponse fundedPsbt
         * @property {number|null} [changeOutputIndex] FundPsbtResponse changeOutputIndex
         * @property {Array.<walletrpc.IUtxoLease>|null} [lockedUtxos] FundPsbtResponse lockedUtxos
         */

        /**
         * Constructs a new FundPsbtResponse.
         * @memberof walletrpc
         * @classdesc Represents a FundPsbtResponse.
         * @implements IFundPsbtResponse
         * @constructor
         * @param {walletrpc.IFundPsbtResponse=} [properties] Properties to set
         */
        function FundPsbtResponse(properties) {
            this.lockedUtxos = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FundPsbtResponse fundedPsbt.
         * @member {Uint8Array} fundedPsbt
         * @memberof walletrpc.FundPsbtResponse
         * @instance
         */
        FundPsbtResponse.prototype.fundedPsbt = $util.newBuffer([]);

        /**
         * FundPsbtResponse changeOutputIndex.
         * @member {number} changeOutputIndex
         * @memberof walletrpc.FundPsbtResponse
         * @instance
         */
        FundPsbtResponse.prototype.changeOutputIndex = 0;

        /**
         * FundPsbtResponse lockedUtxos.
         * @member {Array.<walletrpc.IUtxoLease>} lockedUtxos
         * @memberof walletrpc.FundPsbtResponse
         * @instance
         */
        FundPsbtResponse.prototype.lockedUtxos = $util.emptyArray;

        /**
         * Creates a new FundPsbtResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {walletrpc.IFundPsbtResponse=} [properties] Properties to set
         * @returns {walletrpc.FundPsbtResponse} FundPsbtResponse instance
         */
        FundPsbtResponse.create = function create(properties) {
            return new FundPsbtResponse(properties);
        };

        /**
         * Encodes the specified FundPsbtResponse message. Does not implicitly {@link walletrpc.FundPsbtResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {walletrpc.IFundPsbtResponse} message FundPsbtResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundPsbtResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fundedPsbt != null && Object.hasOwnProperty.call(message, "fundedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fundedPsbt);
            if (message.changeOutputIndex != null && Object.hasOwnProperty.call(message, "changeOutputIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.changeOutputIndex);
            if (message.lockedUtxos != null && message.lockedUtxos.length)
                for (let i = 0; i < message.lockedUtxos.length; ++i)
                    $root.walletrpc.UtxoLease.encode(message.lockedUtxos[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified FundPsbtResponse message, length delimited. Does not implicitly {@link walletrpc.FundPsbtResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {walletrpc.IFundPsbtResponse} message FundPsbtResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FundPsbtResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FundPsbtResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.FundPsbtResponse} FundPsbtResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundPsbtResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.FundPsbtResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fundedPsbt = reader.bytes();
                        break;
                    }
                case 2: {
                        message.changeOutputIndex = reader.int32();
                        break;
                    }
                case 3: {
                        if (!(message.lockedUtxos && message.lockedUtxos.length))
                            message.lockedUtxos = [];
                        message.lockedUtxos.push($root.walletrpc.UtxoLease.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FundPsbtResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.FundPsbtResponse} FundPsbtResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FundPsbtResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FundPsbtResponse message.
         * @function verify
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FundPsbtResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                if (!(message.fundedPsbt && typeof message.fundedPsbt.length === "number" || $util.isString(message.fundedPsbt)))
                    return "fundedPsbt: buffer expected";
            if (message.changeOutputIndex != null && message.hasOwnProperty("changeOutputIndex"))
                if (!$util.isInteger(message.changeOutputIndex))
                    return "changeOutputIndex: integer expected";
            if (message.lockedUtxos != null && message.hasOwnProperty("lockedUtxos")) {
                if (!Array.isArray(message.lockedUtxos))
                    return "lockedUtxos: array expected";
                for (let i = 0; i < message.lockedUtxos.length; ++i) {
                    let error = $root.walletrpc.UtxoLease.verify(message.lockedUtxos[i]);
                    if (error)
                        return "lockedUtxos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a FundPsbtResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.FundPsbtResponse} FundPsbtResponse
         */
        FundPsbtResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.FundPsbtResponse)
                return object;
            let message = new $root.walletrpc.FundPsbtResponse();
            if (object.fundedPsbt != null)
                if (typeof object.fundedPsbt === "string")
                    $util.base64.decode(object.fundedPsbt, message.fundedPsbt = $util.newBuffer($util.base64.length(object.fundedPsbt)), 0);
                else if (object.fundedPsbt.length)
                    message.fundedPsbt = object.fundedPsbt;
            if (object.changeOutputIndex != null)
                message.changeOutputIndex = object.changeOutputIndex | 0;
            if (object.lockedUtxos) {
                if (!Array.isArray(object.lockedUtxos))
                    throw TypeError(".walletrpc.FundPsbtResponse.lockedUtxos: array expected");
                message.lockedUtxos = [];
                for (let i = 0; i < object.lockedUtxos.length; ++i) {
                    if (typeof object.lockedUtxos[i] !== "object")
                        throw TypeError(".walletrpc.FundPsbtResponse.lockedUtxos: object expected");
                    message.lockedUtxos[i] = $root.walletrpc.UtxoLease.fromObject(object.lockedUtxos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a FundPsbtResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.FundPsbtResponse
         * @static
         * @param {walletrpc.FundPsbtResponse} message FundPsbtResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FundPsbtResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.lockedUtxos = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.fundedPsbt = "";
                else {
                    object.fundedPsbt = [];
                    if (options.bytes !== Array)
                        object.fundedPsbt = $util.newBuffer(object.fundedPsbt);
                }
                object.changeOutputIndex = 0;
            }
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                object.fundedPsbt = options.bytes === String ? $util.base64.encode(message.fundedPsbt, 0, message.fundedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.fundedPsbt) : message.fundedPsbt;
            if (message.changeOutputIndex != null && message.hasOwnProperty("changeOutputIndex"))
                object.changeOutputIndex = message.changeOutputIndex;
            if (message.lockedUtxos && message.lockedUtxos.length) {
                object.lockedUtxos = [];
                for (let j = 0; j < message.lockedUtxos.length; ++j)
                    object.lockedUtxos[j] = $root.walletrpc.UtxoLease.toObject(message.lockedUtxos[j], options);
            }
            return object;
        };

        /**
         * Converts this FundPsbtResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.FundPsbtResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FundPsbtResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FundPsbtResponse;
    })();

    walletrpc.TxTemplate = (function() {

        /**
         * Properties of a TxTemplate.
         * @memberof walletrpc
         * @interface ITxTemplate
         * @property {Array.<lnrpc.IOutPoint>|null} [inputs] TxTemplate inputs
         * @property {Object.<string,Long>|null} [outputs] TxTemplate outputs
         */

        /**
         * Constructs a new TxTemplate.
         * @memberof walletrpc
         * @classdesc Represents a TxTemplate.
         * @implements ITxTemplate
         * @constructor
         * @param {walletrpc.ITxTemplate=} [properties] Properties to set
         */
        function TxTemplate(properties) {
            this.inputs = [];
            this.outputs = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TxTemplate inputs.
         * @member {Array.<lnrpc.IOutPoint>} inputs
         * @memberof walletrpc.TxTemplate
         * @instance
         */
        TxTemplate.prototype.inputs = $util.emptyArray;

        /**
         * TxTemplate outputs.
         * @member {Object.<string,Long>} outputs
         * @memberof walletrpc.TxTemplate
         * @instance
         */
        TxTemplate.prototype.outputs = $util.emptyObject;

        /**
         * Creates a new TxTemplate instance using the specified properties.
         * @function create
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {walletrpc.ITxTemplate=} [properties] Properties to set
         * @returns {walletrpc.TxTemplate} TxTemplate instance
         */
        TxTemplate.create = function create(properties) {
            return new TxTemplate(properties);
        };

        /**
         * Encodes the specified TxTemplate message. Does not implicitly {@link walletrpc.TxTemplate.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {walletrpc.ITxTemplate} message TxTemplate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TxTemplate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.inputs != null && message.inputs.length)
                for (let i = 0; i < message.inputs.length; ++i)
                    $root.lnrpc.OutPoint.encode(message.inputs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.outputs != null && Object.hasOwnProperty.call(message, "outputs"))
                for (let keys = Object.keys(message.outputs), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).uint64(message.outputs[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TxTemplate message, length delimited. Does not implicitly {@link walletrpc.TxTemplate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {walletrpc.ITxTemplate} message TxTemplate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TxTemplate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TxTemplate message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.TxTemplate} TxTemplate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TxTemplate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.TxTemplate(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.inputs && message.inputs.length))
                            message.inputs = [];
                        message.inputs.push($root.lnrpc.OutPoint.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (message.outputs === $util.emptyObject)
                            message.outputs = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = 0;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.outputs[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TxTemplate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.TxTemplate} TxTemplate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TxTemplate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TxTemplate message.
         * @function verify
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TxTemplate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.inputs != null && message.hasOwnProperty("inputs")) {
                if (!Array.isArray(message.inputs))
                    return "inputs: array expected";
                for (let i = 0; i < message.inputs.length; ++i) {
                    let error = $root.lnrpc.OutPoint.verify(message.inputs[i]);
                    if (error)
                        return "inputs." + error;
                }
            }
            if (message.outputs != null && message.hasOwnProperty("outputs")) {
                if (!$util.isObject(message.outputs))
                    return "outputs: object expected";
                let key = Object.keys(message.outputs);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isInteger(message.outputs[key[i]]) && !(message.outputs[key[i]] && $util.isInteger(message.outputs[key[i]].low) && $util.isInteger(message.outputs[key[i]].high)))
                        return "outputs: integer|Long{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a TxTemplate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.TxTemplate} TxTemplate
         */
        TxTemplate.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.TxTemplate)
                return object;
            let message = new $root.walletrpc.TxTemplate();
            if (object.inputs) {
                if (!Array.isArray(object.inputs))
                    throw TypeError(".walletrpc.TxTemplate.inputs: array expected");
                message.inputs = [];
                for (let i = 0; i < object.inputs.length; ++i) {
                    if (typeof object.inputs[i] !== "object")
                        throw TypeError(".walletrpc.TxTemplate.inputs: object expected");
                    message.inputs[i] = $root.lnrpc.OutPoint.fromObject(object.inputs[i]);
                }
            }
            if (object.outputs) {
                if (typeof object.outputs !== "object")
                    throw TypeError(".walletrpc.TxTemplate.outputs: object expected");
                message.outputs = {};
                for (let keys = Object.keys(object.outputs), i = 0; i < keys.length; ++i)
                    if ($util.Long)
                        (message.outputs[keys[i]] = $util.Long.fromValue(object.outputs[keys[i]])).unsigned = true;
                    else if (typeof object.outputs[keys[i]] === "string")
                        message.outputs[keys[i]] = parseInt(object.outputs[keys[i]], 10);
                    else if (typeof object.outputs[keys[i]] === "number")
                        message.outputs[keys[i]] = object.outputs[keys[i]];
                    else if (typeof object.outputs[keys[i]] === "object")
                        message.outputs[keys[i]] = new $util.LongBits(object.outputs[keys[i]].low >>> 0, object.outputs[keys[i]].high >>> 0).toNumber(true);
            }
            return message;
        };

        /**
         * Creates a plain object from a TxTemplate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.TxTemplate
         * @static
         * @param {walletrpc.TxTemplate} message TxTemplate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TxTemplate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.inputs = [];
            if (options.objects || options.defaults)
                object.outputs = {};
            if (message.inputs && message.inputs.length) {
                object.inputs = [];
                for (let j = 0; j < message.inputs.length; ++j)
                    object.inputs[j] = $root.lnrpc.OutPoint.toObject(message.inputs[j], options);
            }
            let keys2;
            if (message.outputs && (keys2 = Object.keys(message.outputs)).length) {
                object.outputs = {};
                for (let j = 0; j < keys2.length; ++j)
                    if (typeof message.outputs[keys2[j]] === "number")
                        object.outputs[keys2[j]] = options.longs === String ? String(message.outputs[keys2[j]]) : message.outputs[keys2[j]];
                    else
                        object.outputs[keys2[j]] = options.longs === String ? $util.Long.prototype.toString.call(message.outputs[keys2[j]]) : options.longs === Number ? new $util.LongBits(message.outputs[keys2[j]].low >>> 0, message.outputs[keys2[j]].high >>> 0).toNumber(true) : message.outputs[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this TxTemplate to JSON.
         * @function toJSON
         * @memberof walletrpc.TxTemplate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TxTemplate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TxTemplate;
    })();

    walletrpc.UtxoLease = (function() {

        /**
         * Properties of an UtxoLease.
         * @memberof walletrpc
         * @interface IUtxoLease
         * @property {Uint8Array|null} [id] UtxoLease id
         * @property {lnrpc.IOutPoint|null} [outpoint] UtxoLease outpoint
         * @property {Long|null} [expiration] UtxoLease expiration
         * @property {Uint8Array|null} [pkScript] UtxoLease pkScript
         * @property {Long|null} [value] UtxoLease value
         */

        /**
         * Constructs a new UtxoLease.
         * @memberof walletrpc
         * @classdesc Represents an UtxoLease.
         * @implements IUtxoLease
         * @constructor
         * @param {walletrpc.IUtxoLease=} [properties] Properties to set
         */
        function UtxoLease(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UtxoLease id.
         * @member {Uint8Array} id
         * @memberof walletrpc.UtxoLease
         * @instance
         */
        UtxoLease.prototype.id = $util.newBuffer([]);

        /**
         * UtxoLease outpoint.
         * @member {lnrpc.IOutPoint|null|undefined} outpoint
         * @memberof walletrpc.UtxoLease
         * @instance
         */
        UtxoLease.prototype.outpoint = null;

        /**
         * UtxoLease expiration.
         * @member {Long} expiration
         * @memberof walletrpc.UtxoLease
         * @instance
         */
        UtxoLease.prototype.expiration = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UtxoLease pkScript.
         * @member {Uint8Array} pkScript
         * @memberof walletrpc.UtxoLease
         * @instance
         */
        UtxoLease.prototype.pkScript = $util.newBuffer([]);

        /**
         * UtxoLease value.
         * @member {Long} value
         * @memberof walletrpc.UtxoLease
         * @instance
         */
        UtxoLease.prototype.value = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new UtxoLease instance using the specified properties.
         * @function create
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {walletrpc.IUtxoLease=} [properties] Properties to set
         * @returns {walletrpc.UtxoLease} UtxoLease instance
         */
        UtxoLease.create = function create(properties) {
            return new UtxoLease(properties);
        };

        /**
         * Encodes the specified UtxoLease message. Does not implicitly {@link walletrpc.UtxoLease.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {walletrpc.IUtxoLease} message UtxoLease message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UtxoLease.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.outpoint != null && Object.hasOwnProperty.call(message, "outpoint"))
                $root.lnrpc.OutPoint.encode(message.outpoint, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.expiration);
            if (message.pkScript != null && Object.hasOwnProperty.call(message, "pkScript"))
                writer.uint32(/* id 4, wireType 2 =*/34).bytes(message.pkScript);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.value);
            return writer;
        };

        /**
         * Encodes the specified UtxoLease message, length delimited. Does not implicitly {@link walletrpc.UtxoLease.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {walletrpc.IUtxoLease} message UtxoLease message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UtxoLease.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UtxoLease message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.UtxoLease} UtxoLease
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UtxoLease.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.UtxoLease();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.bytes();
                        break;
                    }
                case 2: {
                        message.outpoint = $root.lnrpc.OutPoint.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.expiration = reader.uint64();
                        break;
                    }
                case 4: {
                        message.pkScript = reader.bytes();
                        break;
                    }
                case 5: {
                        message.value = reader.uint64();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UtxoLease message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.UtxoLease} UtxoLease
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UtxoLease.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UtxoLease message.
         * @function verify
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UtxoLease.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.outpoint != null && message.hasOwnProperty("outpoint")) {
                let error = $root.lnrpc.OutPoint.verify(message.outpoint);
                if (error)
                    return "outpoint." + error;
            }
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                if (!$util.isInteger(message.expiration) && !(message.expiration && $util.isInteger(message.expiration.low) && $util.isInteger(message.expiration.high)))
                    return "expiration: integer|Long expected";
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                if (!(message.pkScript && typeof message.pkScript.length === "number" || $util.isString(message.pkScript)))
                    return "pkScript: buffer expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                    return "value: integer|Long expected";
            return null;
        };

        /**
         * Creates an UtxoLease message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.UtxoLease} UtxoLease
         */
        UtxoLease.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.UtxoLease)
                return object;
            let message = new $root.walletrpc.UtxoLease();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.outpoint != null) {
                if (typeof object.outpoint !== "object")
                    throw TypeError(".walletrpc.UtxoLease.outpoint: object expected");
                message.outpoint = $root.lnrpc.OutPoint.fromObject(object.outpoint);
            }
            if (object.expiration != null)
                if ($util.Long)
                    (message.expiration = $util.Long.fromValue(object.expiration)).unsigned = true;
                else if (typeof object.expiration === "string")
                    message.expiration = parseInt(object.expiration, 10);
                else if (typeof object.expiration === "number")
                    message.expiration = object.expiration;
                else if (typeof object.expiration === "object")
                    message.expiration = new $util.LongBits(object.expiration.low >>> 0, object.expiration.high >>> 0).toNumber(true);
            if (object.pkScript != null)
                if (typeof object.pkScript === "string")
                    $util.base64.decode(object.pkScript, message.pkScript = $util.newBuffer($util.base64.length(object.pkScript)), 0);
                else if (object.pkScript.length)
                    message.pkScript = object.pkScript;
            if (object.value != null)
                if ($util.Long)
                    (message.value = $util.Long.fromValue(object.value)).unsigned = true;
                else if (typeof object.value === "string")
                    message.value = parseInt(object.value, 10);
                else if (typeof object.value === "number")
                    message.value = object.value;
                else if (typeof object.value === "object")
                    message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an UtxoLease message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.UtxoLease
         * @static
         * @param {walletrpc.UtxoLease} message UtxoLease
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UtxoLease.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.outpoint = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.expiration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expiration = options.longs === String ? "0" : 0;
                if (options.bytes === String)
                    object.pkScript = "";
                else {
                    object.pkScript = [];
                    if (options.bytes !== Array)
                        object.pkScript = $util.newBuffer(object.pkScript);
                }
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.value = options.longs === String ? "0" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.outpoint != null && message.hasOwnProperty("outpoint"))
                object.outpoint = $root.lnrpc.OutPoint.toObject(message.outpoint, options);
            if (message.expiration != null && message.hasOwnProperty("expiration"))
                if (typeof message.expiration === "number")
                    object.expiration = options.longs === String ? String(message.expiration) : message.expiration;
                else
                    object.expiration = options.longs === String ? $util.Long.prototype.toString.call(message.expiration) : options.longs === Number ? new $util.LongBits(message.expiration.low >>> 0, message.expiration.high >>> 0).toNumber(true) : message.expiration;
            if (message.pkScript != null && message.hasOwnProperty("pkScript"))
                object.pkScript = options.bytes === String ? $util.base64.encode(message.pkScript, 0, message.pkScript.length) : options.bytes === Array ? Array.prototype.slice.call(message.pkScript) : message.pkScript;
            if (message.value != null && message.hasOwnProperty("value"))
                if (typeof message.value === "number")
                    object.value = options.longs === String ? String(message.value) : message.value;
                else
                    object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber(true) : message.value;
            return object;
        };

        /**
         * Converts this UtxoLease to JSON.
         * @function toJSON
         * @memberof walletrpc.UtxoLease
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UtxoLease.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UtxoLease;
    })();

    walletrpc.SignPsbtRequest = (function() {

        /**
         * Properties of a SignPsbtRequest.
         * @memberof walletrpc
         * @interface ISignPsbtRequest
         * @property {Uint8Array|null} [fundedPsbt] SignPsbtRequest fundedPsbt
         */

        /**
         * Constructs a new SignPsbtRequest.
         * @memberof walletrpc
         * @classdesc Represents a SignPsbtRequest.
         * @implements ISignPsbtRequest
         * @constructor
         * @param {walletrpc.ISignPsbtRequest=} [properties] Properties to set
         */
        function SignPsbtRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignPsbtRequest fundedPsbt.
         * @member {Uint8Array} fundedPsbt
         * @memberof walletrpc.SignPsbtRequest
         * @instance
         */
        SignPsbtRequest.prototype.fundedPsbt = $util.newBuffer([]);

        /**
         * Creates a new SignPsbtRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {walletrpc.ISignPsbtRequest=} [properties] Properties to set
         * @returns {walletrpc.SignPsbtRequest} SignPsbtRequest instance
         */
        SignPsbtRequest.create = function create(properties) {
            return new SignPsbtRequest(properties);
        };

        /**
         * Encodes the specified SignPsbtRequest message. Does not implicitly {@link walletrpc.SignPsbtRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {walletrpc.ISignPsbtRequest} message SignPsbtRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignPsbtRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fundedPsbt != null && Object.hasOwnProperty.call(message, "fundedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fundedPsbt);
            return writer;
        };

        /**
         * Encodes the specified SignPsbtRequest message, length delimited. Does not implicitly {@link walletrpc.SignPsbtRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {walletrpc.ISignPsbtRequest} message SignPsbtRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignPsbtRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignPsbtRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.SignPsbtRequest} SignPsbtRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignPsbtRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.SignPsbtRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fundedPsbt = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignPsbtRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.SignPsbtRequest} SignPsbtRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignPsbtRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignPsbtRequest message.
         * @function verify
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignPsbtRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                if (!(message.fundedPsbt && typeof message.fundedPsbt.length === "number" || $util.isString(message.fundedPsbt)))
                    return "fundedPsbt: buffer expected";
            return null;
        };

        /**
         * Creates a SignPsbtRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.SignPsbtRequest} SignPsbtRequest
         */
        SignPsbtRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.SignPsbtRequest)
                return object;
            let message = new $root.walletrpc.SignPsbtRequest();
            if (object.fundedPsbt != null)
                if (typeof object.fundedPsbt === "string")
                    $util.base64.decode(object.fundedPsbt, message.fundedPsbt = $util.newBuffer($util.base64.length(object.fundedPsbt)), 0);
                else if (object.fundedPsbt.length)
                    message.fundedPsbt = object.fundedPsbt;
            return message;
        };

        /**
         * Creates a plain object from a SignPsbtRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.SignPsbtRequest
         * @static
         * @param {walletrpc.SignPsbtRequest} message SignPsbtRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignPsbtRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.fundedPsbt = "";
                else {
                    object.fundedPsbt = [];
                    if (options.bytes !== Array)
                        object.fundedPsbt = $util.newBuffer(object.fundedPsbt);
                }
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                object.fundedPsbt = options.bytes === String ? $util.base64.encode(message.fundedPsbt, 0, message.fundedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.fundedPsbt) : message.fundedPsbt;
            return object;
        };

        /**
         * Converts this SignPsbtRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.SignPsbtRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignPsbtRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignPsbtRequest;
    })();

    walletrpc.SignPsbtResponse = (function() {

        /**
         * Properties of a SignPsbtResponse.
         * @memberof walletrpc
         * @interface ISignPsbtResponse
         * @property {Uint8Array|null} [signedPsbt] SignPsbtResponse signedPsbt
         */

        /**
         * Constructs a new SignPsbtResponse.
         * @memberof walletrpc
         * @classdesc Represents a SignPsbtResponse.
         * @implements ISignPsbtResponse
         * @constructor
         * @param {walletrpc.ISignPsbtResponse=} [properties] Properties to set
         */
        function SignPsbtResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SignPsbtResponse signedPsbt.
         * @member {Uint8Array} signedPsbt
         * @memberof walletrpc.SignPsbtResponse
         * @instance
         */
        SignPsbtResponse.prototype.signedPsbt = $util.newBuffer([]);

        /**
         * Creates a new SignPsbtResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {walletrpc.ISignPsbtResponse=} [properties] Properties to set
         * @returns {walletrpc.SignPsbtResponse} SignPsbtResponse instance
         */
        SignPsbtResponse.create = function create(properties) {
            return new SignPsbtResponse(properties);
        };

        /**
         * Encodes the specified SignPsbtResponse message. Does not implicitly {@link walletrpc.SignPsbtResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {walletrpc.ISignPsbtResponse} message SignPsbtResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignPsbtResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signedPsbt != null && Object.hasOwnProperty.call(message, "signedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.signedPsbt);
            return writer;
        };

        /**
         * Encodes the specified SignPsbtResponse message, length delimited. Does not implicitly {@link walletrpc.SignPsbtResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {walletrpc.ISignPsbtResponse} message SignPsbtResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SignPsbtResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SignPsbtResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.SignPsbtResponse} SignPsbtResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignPsbtResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.SignPsbtResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signedPsbt = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SignPsbtResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.SignPsbtResponse} SignPsbtResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SignPsbtResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SignPsbtResponse message.
         * @function verify
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SignPsbtResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signedPsbt != null && message.hasOwnProperty("signedPsbt"))
                if (!(message.signedPsbt && typeof message.signedPsbt.length === "number" || $util.isString(message.signedPsbt)))
                    return "signedPsbt: buffer expected";
            return null;
        };

        /**
         * Creates a SignPsbtResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.SignPsbtResponse} SignPsbtResponse
         */
        SignPsbtResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.SignPsbtResponse)
                return object;
            let message = new $root.walletrpc.SignPsbtResponse();
            if (object.signedPsbt != null)
                if (typeof object.signedPsbt === "string")
                    $util.base64.decode(object.signedPsbt, message.signedPsbt = $util.newBuffer($util.base64.length(object.signedPsbt)), 0);
                else if (object.signedPsbt.length)
                    message.signedPsbt = object.signedPsbt;
            return message;
        };

        /**
         * Creates a plain object from a SignPsbtResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.SignPsbtResponse
         * @static
         * @param {walletrpc.SignPsbtResponse} message SignPsbtResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SignPsbtResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.signedPsbt = "";
                else {
                    object.signedPsbt = [];
                    if (options.bytes !== Array)
                        object.signedPsbt = $util.newBuffer(object.signedPsbt);
                }
            if (message.signedPsbt != null && message.hasOwnProperty("signedPsbt"))
                object.signedPsbt = options.bytes === String ? $util.base64.encode(message.signedPsbt, 0, message.signedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.signedPsbt) : message.signedPsbt;
            return object;
        };

        /**
         * Converts this SignPsbtResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.SignPsbtResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SignPsbtResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SignPsbtResponse;
    })();

    walletrpc.FinalizePsbtRequest = (function() {

        /**
         * Properties of a FinalizePsbtRequest.
         * @memberof walletrpc
         * @interface IFinalizePsbtRequest
         * @property {Uint8Array|null} [fundedPsbt] FinalizePsbtRequest fundedPsbt
         * @property {string|null} [account] FinalizePsbtRequest account
         */

        /**
         * Constructs a new FinalizePsbtRequest.
         * @memberof walletrpc
         * @classdesc Represents a FinalizePsbtRequest.
         * @implements IFinalizePsbtRequest
         * @constructor
         * @param {walletrpc.IFinalizePsbtRequest=} [properties] Properties to set
         */
        function FinalizePsbtRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FinalizePsbtRequest fundedPsbt.
         * @member {Uint8Array} fundedPsbt
         * @memberof walletrpc.FinalizePsbtRequest
         * @instance
         */
        FinalizePsbtRequest.prototype.fundedPsbt = $util.newBuffer([]);

        /**
         * FinalizePsbtRequest account.
         * @member {string} account
         * @memberof walletrpc.FinalizePsbtRequest
         * @instance
         */
        FinalizePsbtRequest.prototype.account = "";

        /**
         * Creates a new FinalizePsbtRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {walletrpc.IFinalizePsbtRequest=} [properties] Properties to set
         * @returns {walletrpc.FinalizePsbtRequest} FinalizePsbtRequest instance
         */
        FinalizePsbtRequest.create = function create(properties) {
            return new FinalizePsbtRequest(properties);
        };

        /**
         * Encodes the specified FinalizePsbtRequest message. Does not implicitly {@link walletrpc.FinalizePsbtRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {walletrpc.IFinalizePsbtRequest} message FinalizePsbtRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FinalizePsbtRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fundedPsbt != null && Object.hasOwnProperty.call(message, "fundedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.fundedPsbt);
            if (message.account != null && Object.hasOwnProperty.call(message, "account"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.account);
            return writer;
        };

        /**
         * Encodes the specified FinalizePsbtRequest message, length delimited. Does not implicitly {@link walletrpc.FinalizePsbtRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {walletrpc.IFinalizePsbtRequest} message FinalizePsbtRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FinalizePsbtRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FinalizePsbtRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.FinalizePsbtRequest} FinalizePsbtRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FinalizePsbtRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.FinalizePsbtRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fundedPsbt = reader.bytes();
                        break;
                    }
                case 5: {
                        message.account = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FinalizePsbtRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.FinalizePsbtRequest} FinalizePsbtRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FinalizePsbtRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FinalizePsbtRequest message.
         * @function verify
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FinalizePsbtRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                if (!(message.fundedPsbt && typeof message.fundedPsbt.length === "number" || $util.isString(message.fundedPsbt)))
                    return "fundedPsbt: buffer expected";
            if (message.account != null && message.hasOwnProperty("account"))
                if (!$util.isString(message.account))
                    return "account: string expected";
            return null;
        };

        /**
         * Creates a FinalizePsbtRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.FinalizePsbtRequest} FinalizePsbtRequest
         */
        FinalizePsbtRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.FinalizePsbtRequest)
                return object;
            let message = new $root.walletrpc.FinalizePsbtRequest();
            if (object.fundedPsbt != null)
                if (typeof object.fundedPsbt === "string")
                    $util.base64.decode(object.fundedPsbt, message.fundedPsbt = $util.newBuffer($util.base64.length(object.fundedPsbt)), 0);
                else if (object.fundedPsbt.length)
                    message.fundedPsbt = object.fundedPsbt;
            if (object.account != null)
                message.account = String(object.account);
            return message;
        };

        /**
         * Creates a plain object from a FinalizePsbtRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.FinalizePsbtRequest
         * @static
         * @param {walletrpc.FinalizePsbtRequest} message FinalizePsbtRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FinalizePsbtRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.fundedPsbt = "";
                else {
                    object.fundedPsbt = [];
                    if (options.bytes !== Array)
                        object.fundedPsbt = $util.newBuffer(object.fundedPsbt);
                }
                object.account = "";
            }
            if (message.fundedPsbt != null && message.hasOwnProperty("fundedPsbt"))
                object.fundedPsbt = options.bytes === String ? $util.base64.encode(message.fundedPsbt, 0, message.fundedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.fundedPsbt) : message.fundedPsbt;
            if (message.account != null && message.hasOwnProperty("account"))
                object.account = message.account;
            return object;
        };

        /**
         * Converts this FinalizePsbtRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.FinalizePsbtRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FinalizePsbtRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FinalizePsbtRequest;
    })();

    walletrpc.FinalizePsbtResponse = (function() {

        /**
         * Properties of a FinalizePsbtResponse.
         * @memberof walletrpc
         * @interface IFinalizePsbtResponse
         * @property {Uint8Array|null} [signedPsbt] FinalizePsbtResponse signedPsbt
         * @property {Uint8Array|null} [rawFinalTx] FinalizePsbtResponse rawFinalTx
         */

        /**
         * Constructs a new FinalizePsbtResponse.
         * @memberof walletrpc
         * @classdesc Represents a FinalizePsbtResponse.
         * @implements IFinalizePsbtResponse
         * @constructor
         * @param {walletrpc.IFinalizePsbtResponse=} [properties] Properties to set
         */
        function FinalizePsbtResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FinalizePsbtResponse signedPsbt.
         * @member {Uint8Array} signedPsbt
         * @memberof walletrpc.FinalizePsbtResponse
         * @instance
         */
        FinalizePsbtResponse.prototype.signedPsbt = $util.newBuffer([]);

        /**
         * FinalizePsbtResponse rawFinalTx.
         * @member {Uint8Array} rawFinalTx
         * @memberof walletrpc.FinalizePsbtResponse
         * @instance
         */
        FinalizePsbtResponse.prototype.rawFinalTx = $util.newBuffer([]);

        /**
         * Creates a new FinalizePsbtResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {walletrpc.IFinalizePsbtResponse=} [properties] Properties to set
         * @returns {walletrpc.FinalizePsbtResponse} FinalizePsbtResponse instance
         */
        FinalizePsbtResponse.create = function create(properties) {
            return new FinalizePsbtResponse(properties);
        };

        /**
         * Encodes the specified FinalizePsbtResponse message. Does not implicitly {@link walletrpc.FinalizePsbtResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {walletrpc.IFinalizePsbtResponse} message FinalizePsbtResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FinalizePsbtResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.signedPsbt != null && Object.hasOwnProperty.call(message, "signedPsbt"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.signedPsbt);
            if (message.rawFinalTx != null && Object.hasOwnProperty.call(message, "rawFinalTx"))
                writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.rawFinalTx);
            return writer;
        };

        /**
         * Encodes the specified FinalizePsbtResponse message, length delimited. Does not implicitly {@link walletrpc.FinalizePsbtResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {walletrpc.IFinalizePsbtResponse} message FinalizePsbtResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FinalizePsbtResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FinalizePsbtResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.FinalizePsbtResponse} FinalizePsbtResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FinalizePsbtResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.FinalizePsbtResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.signedPsbt = reader.bytes();
                        break;
                    }
                case 2: {
                        message.rawFinalTx = reader.bytes();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FinalizePsbtResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.FinalizePsbtResponse} FinalizePsbtResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FinalizePsbtResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FinalizePsbtResponse message.
         * @function verify
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FinalizePsbtResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.signedPsbt != null && message.hasOwnProperty("signedPsbt"))
                if (!(message.signedPsbt && typeof message.signedPsbt.length === "number" || $util.isString(message.signedPsbt)))
                    return "signedPsbt: buffer expected";
            if (message.rawFinalTx != null && message.hasOwnProperty("rawFinalTx"))
                if (!(message.rawFinalTx && typeof message.rawFinalTx.length === "number" || $util.isString(message.rawFinalTx)))
                    return "rawFinalTx: buffer expected";
            return null;
        };

        /**
         * Creates a FinalizePsbtResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.FinalizePsbtResponse} FinalizePsbtResponse
         */
        FinalizePsbtResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.FinalizePsbtResponse)
                return object;
            let message = new $root.walletrpc.FinalizePsbtResponse();
            if (object.signedPsbt != null)
                if (typeof object.signedPsbt === "string")
                    $util.base64.decode(object.signedPsbt, message.signedPsbt = $util.newBuffer($util.base64.length(object.signedPsbt)), 0);
                else if (object.signedPsbt.length)
                    message.signedPsbt = object.signedPsbt;
            if (object.rawFinalTx != null)
                if (typeof object.rawFinalTx === "string")
                    $util.base64.decode(object.rawFinalTx, message.rawFinalTx = $util.newBuffer($util.base64.length(object.rawFinalTx)), 0);
                else if (object.rawFinalTx.length)
                    message.rawFinalTx = object.rawFinalTx;
            return message;
        };

        /**
         * Creates a plain object from a FinalizePsbtResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.FinalizePsbtResponse
         * @static
         * @param {walletrpc.FinalizePsbtResponse} message FinalizePsbtResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FinalizePsbtResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.signedPsbt = "";
                else {
                    object.signedPsbt = [];
                    if (options.bytes !== Array)
                        object.signedPsbt = $util.newBuffer(object.signedPsbt);
                }
                if (options.bytes === String)
                    object.rawFinalTx = "";
                else {
                    object.rawFinalTx = [];
                    if (options.bytes !== Array)
                        object.rawFinalTx = $util.newBuffer(object.rawFinalTx);
                }
            }
            if (message.signedPsbt != null && message.hasOwnProperty("signedPsbt"))
                object.signedPsbt = options.bytes === String ? $util.base64.encode(message.signedPsbt, 0, message.signedPsbt.length) : options.bytes === Array ? Array.prototype.slice.call(message.signedPsbt) : message.signedPsbt;
            if (message.rawFinalTx != null && message.hasOwnProperty("rawFinalTx"))
                object.rawFinalTx = options.bytes === String ? $util.base64.encode(message.rawFinalTx, 0, message.rawFinalTx.length) : options.bytes === Array ? Array.prototype.slice.call(message.rawFinalTx) : message.rawFinalTx;
            return object;
        };

        /**
         * Converts this FinalizePsbtResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.FinalizePsbtResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FinalizePsbtResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FinalizePsbtResponse;
    })();

    walletrpc.ListLeasesRequest = (function() {

        /**
         * Properties of a ListLeasesRequest.
         * @memberof walletrpc
         * @interface IListLeasesRequest
         */

        /**
         * Constructs a new ListLeasesRequest.
         * @memberof walletrpc
         * @classdesc Represents a ListLeasesRequest.
         * @implements IListLeasesRequest
         * @constructor
         * @param {walletrpc.IListLeasesRequest=} [properties] Properties to set
         */
        function ListLeasesRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ListLeasesRequest instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {walletrpc.IListLeasesRequest=} [properties] Properties to set
         * @returns {walletrpc.ListLeasesRequest} ListLeasesRequest instance
         */
        ListLeasesRequest.create = function create(properties) {
            return new ListLeasesRequest(properties);
        };

        /**
         * Encodes the specified ListLeasesRequest message. Does not implicitly {@link walletrpc.ListLeasesRequest.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {walletrpc.IListLeasesRequest} message ListLeasesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListLeasesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ListLeasesRequest message, length delimited. Does not implicitly {@link walletrpc.ListLeasesRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {walletrpc.IListLeasesRequest} message ListLeasesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListLeasesRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListLeasesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListLeasesRequest} ListLeasesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListLeasesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListLeasesRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListLeasesRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListLeasesRequest} ListLeasesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListLeasesRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListLeasesRequest message.
         * @function verify
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListLeasesRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ListLeasesRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListLeasesRequest} ListLeasesRequest
         */
        ListLeasesRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListLeasesRequest)
                return object;
            return new $root.walletrpc.ListLeasesRequest();
        };

        /**
         * Creates a plain object from a ListLeasesRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListLeasesRequest
         * @static
         * @param {walletrpc.ListLeasesRequest} message ListLeasesRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListLeasesRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ListLeasesRequest to JSON.
         * @function toJSON
         * @memberof walletrpc.ListLeasesRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListLeasesRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListLeasesRequest;
    })();

    walletrpc.ListLeasesResponse = (function() {

        /**
         * Properties of a ListLeasesResponse.
         * @memberof walletrpc
         * @interface IListLeasesResponse
         * @property {Array.<walletrpc.IUtxoLease>|null} [lockedUtxos] ListLeasesResponse lockedUtxos
         */

        /**
         * Constructs a new ListLeasesResponse.
         * @memberof walletrpc
         * @classdesc Represents a ListLeasesResponse.
         * @implements IListLeasesResponse
         * @constructor
         * @param {walletrpc.IListLeasesResponse=} [properties] Properties to set
         */
        function ListLeasesResponse(properties) {
            this.lockedUtxos = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListLeasesResponse lockedUtxos.
         * @member {Array.<walletrpc.IUtxoLease>} lockedUtxos
         * @memberof walletrpc.ListLeasesResponse
         * @instance
         */
        ListLeasesResponse.prototype.lockedUtxos = $util.emptyArray;

        /**
         * Creates a new ListLeasesResponse instance using the specified properties.
         * @function create
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {walletrpc.IListLeasesResponse=} [properties] Properties to set
         * @returns {walletrpc.ListLeasesResponse} ListLeasesResponse instance
         */
        ListLeasesResponse.create = function create(properties) {
            return new ListLeasesResponse(properties);
        };

        /**
         * Encodes the specified ListLeasesResponse message. Does not implicitly {@link walletrpc.ListLeasesResponse.verify|verify} messages.
         * @function encode
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {walletrpc.IListLeasesResponse} message ListLeasesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListLeasesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lockedUtxos != null && message.lockedUtxos.length)
                for (let i = 0; i < message.lockedUtxos.length; ++i)
                    $root.walletrpc.UtxoLease.encode(message.lockedUtxos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListLeasesResponse message, length delimited. Does not implicitly {@link walletrpc.ListLeasesResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {walletrpc.IListLeasesResponse} message ListLeasesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListLeasesResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListLeasesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {walletrpc.ListLeasesResponse} ListLeasesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListLeasesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.walletrpc.ListLeasesResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.lockedUtxos && message.lockedUtxos.length))
                            message.lockedUtxos = [];
                        message.lockedUtxos.push($root.walletrpc.UtxoLease.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListLeasesResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {walletrpc.ListLeasesResponse} ListLeasesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListLeasesResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListLeasesResponse message.
         * @function verify
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListLeasesResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.lockedUtxos != null && message.hasOwnProperty("lockedUtxos")) {
                if (!Array.isArray(message.lockedUtxos))
                    return "lockedUtxos: array expected";
                for (let i = 0; i < message.lockedUtxos.length; ++i) {
                    let error = $root.walletrpc.UtxoLease.verify(message.lockedUtxos[i]);
                    if (error)
                        return "lockedUtxos." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListLeasesResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {walletrpc.ListLeasesResponse} ListLeasesResponse
         */
        ListLeasesResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.walletrpc.ListLeasesResponse)
                return object;
            let message = new $root.walletrpc.ListLeasesResponse();
            if (object.lockedUtxos) {
                if (!Array.isArray(object.lockedUtxos))
                    throw TypeError(".walletrpc.ListLeasesResponse.lockedUtxos: array expected");
                message.lockedUtxos = [];
                for (let i = 0; i < object.lockedUtxos.length; ++i) {
                    if (typeof object.lockedUtxos[i] !== "object")
                        throw TypeError(".walletrpc.ListLeasesResponse.lockedUtxos: object expected");
                    message.lockedUtxos[i] = $root.walletrpc.UtxoLease.fromObject(object.lockedUtxos[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListLeasesResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof walletrpc.ListLeasesResponse
         * @static
         * @param {walletrpc.ListLeasesResponse} message ListLeasesResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListLeasesResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.lockedUtxos = [];
            if (message.lockedUtxos && message.lockedUtxos.length) {
                object.lockedUtxos = [];
                for (let j = 0; j < message.lockedUtxos.length; ++j)
                    object.lockedUtxos[j] = $root.walletrpc.UtxoLease.toObject(message.lockedUtxos[j], options);
            }
            return object;
        };

        /**
         * Converts this ListLeasesResponse to JSON.
         * @function toJSON
         * @memberof walletrpc.ListLeasesResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListLeasesResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListLeasesResponse;
    })();

    return walletrpc;
})();

export const watchtowerrpc = $root.watchtowerrpc = (() => {

    /**
     * Namespace watchtowerrpc.
     * @exports watchtowerrpc
     * @namespace
     */
    const watchtowerrpc = {};

    watchtowerrpc.Watchtower = (function() {

        /**
         * Constructs a new Watchtower service.
         * @memberof watchtowerrpc
         * @classdesc Represents a Watchtower
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function Watchtower(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (Watchtower.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = Watchtower;

        /**
         * Creates new Watchtower service using the specified rpc implementation.
         * @function create
         * @memberof watchtowerrpc.Watchtower
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {Watchtower} RPC service. Useful where requests and/or responses are streamed.
         */
        Watchtower.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link watchtowerrpc.Watchtower#getInfo}.
         * @memberof watchtowerrpc.Watchtower
         * @typedef GetInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {watchtowerrpc.GetInfoResponse} [response] GetInfoResponse
         */

        /**
         * Calls GetInfo.
         * @function getInfo
         * @memberof watchtowerrpc.Watchtower
         * @instance
         * @param {watchtowerrpc.IGetInfoRequest} request GetInfoRequest message or plain object
         * @param {watchtowerrpc.Watchtower.GetInfoCallback} callback Node-style callback called with the error, if any, and GetInfoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(Watchtower.prototype.getInfo = function getInfo(request, callback) {
            return this.rpcCall(getInfo, $root.watchtowerrpc.GetInfoRequest, $root.watchtowerrpc.GetInfoResponse, request, callback);
        }, "name", { value: "GetInfo" });

        /**
         * Calls GetInfo.
         * @function getInfo
         * @memberof watchtowerrpc.Watchtower
         * @instance
         * @param {watchtowerrpc.IGetInfoRequest} request GetInfoRequest message or plain object
         * @returns {Promise<watchtowerrpc.GetInfoResponse>} Promise
         * @variation 2
         */

        return Watchtower;
    })();

    watchtowerrpc.GetInfoRequest = (function() {

        /**
         * Properties of a GetInfoRequest.
         * @memberof watchtowerrpc
         * @interface IGetInfoRequest
         */

        /**
         * Constructs a new GetInfoRequest.
         * @memberof watchtowerrpc
         * @classdesc Represents a GetInfoRequest.
         * @implements IGetInfoRequest
         * @constructor
         * @param {watchtowerrpc.IGetInfoRequest=} [properties] Properties to set
         */
        function GetInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new GetInfoRequest instance using the specified properties.
         * @function create
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {watchtowerrpc.IGetInfoRequest=} [properties] Properties to set
         * @returns {watchtowerrpc.GetInfoRequest} GetInfoRequest instance
         */
        GetInfoRequest.create = function create(properties) {
            return new GetInfoRequest(properties);
        };

        /**
         * Encodes the specified GetInfoRequest message. Does not implicitly {@link watchtowerrpc.GetInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {watchtowerrpc.IGetInfoRequest} message GetInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified GetInfoRequest message, length delimited. Does not implicitly {@link watchtowerrpc.GetInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {watchtowerrpc.IGetInfoRequest} message GetInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {watchtowerrpc.GetInfoRequest} GetInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.watchtowerrpc.GetInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {watchtowerrpc.GetInfoRequest} GetInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInfoRequest message.
         * @function verify
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a GetInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {watchtowerrpc.GetInfoRequest} GetInfoRequest
         */
        GetInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.watchtowerrpc.GetInfoRequest)
                return object;
            return new $root.watchtowerrpc.GetInfoRequest();
        };

        /**
         * Creates a plain object from a GetInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof watchtowerrpc.GetInfoRequest
         * @static
         * @param {watchtowerrpc.GetInfoRequest} message GetInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInfoRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this GetInfoRequest to JSON.
         * @function toJSON
         * @memberof watchtowerrpc.GetInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInfoRequest;
    })();

    watchtowerrpc.GetInfoResponse = (function() {

        /**
         * Properties of a GetInfoResponse.
         * @memberof watchtowerrpc
         * @interface IGetInfoResponse
         * @property {Uint8Array|null} [pubkey] GetInfoResponse pubkey
         * @property {Array.<string>|null} [listeners] GetInfoResponse listeners
         * @property {Array.<string>|null} [uris] GetInfoResponse uris
         */

        /**
         * Constructs a new GetInfoResponse.
         * @memberof watchtowerrpc
         * @classdesc Represents a GetInfoResponse.
         * @implements IGetInfoResponse
         * @constructor
         * @param {watchtowerrpc.IGetInfoResponse=} [properties] Properties to set
         */
        function GetInfoResponse(properties) {
            this.listeners = [];
            this.uris = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetInfoResponse pubkey.
         * @member {Uint8Array} pubkey
         * @memberof watchtowerrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.pubkey = $util.newBuffer([]);

        /**
         * GetInfoResponse listeners.
         * @member {Array.<string>} listeners
         * @memberof watchtowerrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.listeners = $util.emptyArray;

        /**
         * GetInfoResponse uris.
         * @member {Array.<string>} uris
         * @memberof watchtowerrpc.GetInfoResponse
         * @instance
         */
        GetInfoResponse.prototype.uris = $util.emptyArray;

        /**
         * Creates a new GetInfoResponse instance using the specified properties.
         * @function create
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {watchtowerrpc.IGetInfoResponse=} [properties] Properties to set
         * @returns {watchtowerrpc.GetInfoResponse} GetInfoResponse instance
         */
        GetInfoResponse.create = function create(properties) {
            return new GetInfoResponse(properties);
        };

        /**
         * Encodes the specified GetInfoResponse message. Does not implicitly {@link watchtowerrpc.GetInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {watchtowerrpc.IGetInfoResponse} message GetInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pubkey);
            if (message.listeners != null && message.listeners.length)
                for (let i = 0; i < message.listeners.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.listeners[i]);
            if (message.uris != null && message.uris.length)
                for (let i = 0; i < message.uris.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.uris[i]);
            return writer;
        };

        /**
         * Encodes the specified GetInfoResponse message, length delimited. Does not implicitly {@link watchtowerrpc.GetInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {watchtowerrpc.IGetInfoResponse} message GetInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {watchtowerrpc.GetInfoResponse} GetInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.watchtowerrpc.GetInfoResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.listeners && message.listeners.length))
                            message.listeners = [];
                        message.listeners.push(reader.string());
                        break;
                    }
                case 3: {
                        if (!(message.uris && message.uris.length))
                            message.uris = [];
                        message.uris.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {watchtowerrpc.GetInfoResponse} GetInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInfoResponse message.
         * @function verify
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            if (message.listeners != null && message.hasOwnProperty("listeners")) {
                if (!Array.isArray(message.listeners))
                    return "listeners: array expected";
                for (let i = 0; i < message.listeners.length; ++i)
                    if (!$util.isString(message.listeners[i]))
                        return "listeners: string[] expected";
            }
            if (message.uris != null && message.hasOwnProperty("uris")) {
                if (!Array.isArray(message.uris))
                    return "uris: array expected";
                for (let i = 0; i < message.uris.length; ++i)
                    if (!$util.isString(message.uris[i]))
                        return "uris: string[] expected";
            }
            return null;
        };

        /**
         * Creates a GetInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {watchtowerrpc.GetInfoResponse} GetInfoResponse
         */
        GetInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.watchtowerrpc.GetInfoResponse)
                return object;
            let message = new $root.watchtowerrpc.GetInfoResponse();
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length)
                    message.pubkey = object.pubkey;
            if (object.listeners) {
                if (!Array.isArray(object.listeners))
                    throw TypeError(".watchtowerrpc.GetInfoResponse.listeners: array expected");
                message.listeners = [];
                for (let i = 0; i < object.listeners.length; ++i)
                    message.listeners[i] = String(object.listeners[i]);
            }
            if (object.uris) {
                if (!Array.isArray(object.uris))
                    throw TypeError(".watchtowerrpc.GetInfoResponse.uris: array expected");
                message.uris = [];
                for (let i = 0; i < object.uris.length; ++i)
                    message.uris[i] = String(object.uris[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof watchtowerrpc.GetInfoResponse
         * @static
         * @param {watchtowerrpc.GetInfoResponse} message GetInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.listeners = [];
                object.uris = [];
            }
            if (options.defaults)
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            if (message.listeners && message.listeners.length) {
                object.listeners = [];
                for (let j = 0; j < message.listeners.length; ++j)
                    object.listeners[j] = message.listeners[j];
            }
            if (message.uris && message.uris.length) {
                object.uris = [];
                for (let j = 0; j < message.uris.length; ++j)
                    object.uris[j] = message.uris[j];
            }
            return object;
        };

        /**
         * Converts this GetInfoResponse to JSON.
         * @function toJSON
         * @memberof watchtowerrpc.GetInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInfoResponse;
    })();

    return watchtowerrpc;
})();

export const wtclientrpc = $root.wtclientrpc = (() => {

    /**
     * Namespace wtclientrpc.
     * @exports wtclientrpc
     * @namespace
     */
    const wtclientrpc = {};

    wtclientrpc.WatchtowerClient = (function() {

        /**
         * Constructs a new WatchtowerClient service.
         * @memberof wtclientrpc
         * @classdesc Represents a WatchtowerClient
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function WatchtowerClient(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (WatchtowerClient.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WatchtowerClient;

        /**
         * Creates new WatchtowerClient service using the specified rpc implementation.
         * @function create
         * @memberof wtclientrpc.WatchtowerClient
         * @static
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         * @returns {WatchtowerClient} RPC service. Useful where requests and/or responses are streamed.
         */
        WatchtowerClient.create = function create(rpcImpl, requestDelimited, responseDelimited) {
            return new this(rpcImpl, requestDelimited, responseDelimited);
        };

        /**
         * Callback as used by {@link wtclientrpc.WatchtowerClient#addTower}.
         * @memberof wtclientrpc.WatchtowerClient
         * @typedef AddTowerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {wtclientrpc.AddTowerResponse} [response] AddTowerResponse
         */

        /**
         * Calls AddTower.
         * @function addTower
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IAddTowerRequest} request AddTowerRequest message or plain object
         * @param {wtclientrpc.WatchtowerClient.AddTowerCallback} callback Node-style callback called with the error, if any, and AddTowerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WatchtowerClient.prototype.addTower = function addTower(request, callback) {
            return this.rpcCall(addTower, $root.wtclientrpc.AddTowerRequest, $root.wtclientrpc.AddTowerResponse, request, callback);
        }, "name", { value: "AddTower" });

        /**
         * Calls AddTower.
         * @function addTower
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IAddTowerRequest} request AddTowerRequest message or plain object
         * @returns {Promise<wtclientrpc.AddTowerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link wtclientrpc.WatchtowerClient#removeTower}.
         * @memberof wtclientrpc.WatchtowerClient
         * @typedef RemoveTowerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {wtclientrpc.RemoveTowerResponse} [response] RemoveTowerResponse
         */

        /**
         * Calls RemoveTower.
         * @function removeTower
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IRemoveTowerRequest} request RemoveTowerRequest message or plain object
         * @param {wtclientrpc.WatchtowerClient.RemoveTowerCallback} callback Node-style callback called with the error, if any, and RemoveTowerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WatchtowerClient.prototype.removeTower = function removeTower(request, callback) {
            return this.rpcCall(removeTower, $root.wtclientrpc.RemoveTowerRequest, $root.wtclientrpc.RemoveTowerResponse, request, callback);
        }, "name", { value: "RemoveTower" });

        /**
         * Calls RemoveTower.
         * @function removeTower
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IRemoveTowerRequest} request RemoveTowerRequest message or plain object
         * @returns {Promise<wtclientrpc.RemoveTowerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link wtclientrpc.WatchtowerClient#listTowers}.
         * @memberof wtclientrpc.WatchtowerClient
         * @typedef ListTowersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {wtclientrpc.ListTowersResponse} [response] ListTowersResponse
         */

        /**
         * Calls ListTowers.
         * @function listTowers
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IListTowersRequest} request ListTowersRequest message or plain object
         * @param {wtclientrpc.WatchtowerClient.ListTowersCallback} callback Node-style callback called with the error, if any, and ListTowersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WatchtowerClient.prototype.listTowers = function listTowers(request, callback) {
            return this.rpcCall(listTowers, $root.wtclientrpc.ListTowersRequest, $root.wtclientrpc.ListTowersResponse, request, callback);
        }, "name", { value: "ListTowers" });

        /**
         * Calls ListTowers.
         * @function listTowers
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IListTowersRequest} request ListTowersRequest message or plain object
         * @returns {Promise<wtclientrpc.ListTowersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link wtclientrpc.WatchtowerClient#getTowerInfo}.
         * @memberof wtclientrpc.WatchtowerClient
         * @typedef GetTowerInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {wtclientrpc.Tower} [response] Tower
         */

        /**
         * Calls GetTowerInfo.
         * @function getTowerInfo
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IGetTowerInfoRequest} request GetTowerInfoRequest message or plain object
         * @param {wtclientrpc.WatchtowerClient.GetTowerInfoCallback} callback Node-style callback called with the error, if any, and Tower
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WatchtowerClient.prototype.getTowerInfo = function getTowerInfo(request, callback) {
            return this.rpcCall(getTowerInfo, $root.wtclientrpc.GetTowerInfoRequest, $root.wtclientrpc.Tower, request, callback);
        }, "name", { value: "GetTowerInfo" });

        /**
         * Calls GetTowerInfo.
         * @function getTowerInfo
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IGetTowerInfoRequest} request GetTowerInfoRequest message or plain object
         * @returns {Promise<wtclientrpc.Tower>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link wtclientrpc.WatchtowerClient#stats}.
         * @memberof wtclientrpc.WatchtowerClient
         * @typedef StatsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {wtclientrpc.StatsResponse} [response] StatsResponse
         */

        /**
         * Calls Stats.
         * @function stats
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IStatsRequest} request StatsRequest message or plain object
         * @param {wtclientrpc.WatchtowerClient.StatsCallback} callback Node-style callback called with the error, if any, and StatsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WatchtowerClient.prototype.stats = function stats(request, callback) {
            return this.rpcCall(stats, $root.wtclientrpc.StatsRequest, $root.wtclientrpc.StatsResponse, request, callback);
        }, "name", { value: "Stats" });

        /**
         * Calls Stats.
         * @function stats
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IStatsRequest} request StatsRequest message or plain object
         * @returns {Promise<wtclientrpc.StatsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link wtclientrpc.WatchtowerClient#policy}.
         * @memberof wtclientrpc.WatchtowerClient
         * @typedef PolicyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {wtclientrpc.PolicyResponse} [response] PolicyResponse
         */

        /**
         * Calls Policy.
         * @function policy
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IPolicyRequest} request PolicyRequest message or plain object
         * @param {wtclientrpc.WatchtowerClient.PolicyCallback} callback Node-style callback called with the error, if any, and PolicyResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(WatchtowerClient.prototype.policy = function policy(request, callback) {
            return this.rpcCall(policy, $root.wtclientrpc.PolicyRequest, $root.wtclientrpc.PolicyResponse, request, callback);
        }, "name", { value: "Policy" });

        /**
         * Calls Policy.
         * @function policy
         * @memberof wtclientrpc.WatchtowerClient
         * @instance
         * @param {wtclientrpc.IPolicyRequest} request PolicyRequest message or plain object
         * @returns {Promise<wtclientrpc.PolicyResponse>} Promise
         * @variation 2
         */

        return WatchtowerClient;
    })();

    wtclientrpc.AddTowerRequest = (function() {

        /**
         * Properties of an AddTowerRequest.
         * @memberof wtclientrpc
         * @interface IAddTowerRequest
         * @property {Uint8Array|null} [pubkey] AddTowerRequest pubkey
         * @property {string|null} [address] AddTowerRequest address
         */

        /**
         * Constructs a new AddTowerRequest.
         * @memberof wtclientrpc
         * @classdesc Represents an AddTowerRequest.
         * @implements IAddTowerRequest
         * @constructor
         * @param {wtclientrpc.IAddTowerRequest=} [properties] Properties to set
         */
        function AddTowerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddTowerRequest pubkey.
         * @member {Uint8Array} pubkey
         * @memberof wtclientrpc.AddTowerRequest
         * @instance
         */
        AddTowerRequest.prototype.pubkey = $util.newBuffer([]);

        /**
         * AddTowerRequest address.
         * @member {string} address
         * @memberof wtclientrpc.AddTowerRequest
         * @instance
         */
        AddTowerRequest.prototype.address = "";

        /**
         * Creates a new AddTowerRequest instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {wtclientrpc.IAddTowerRequest=} [properties] Properties to set
         * @returns {wtclientrpc.AddTowerRequest} AddTowerRequest instance
         */
        AddTowerRequest.create = function create(properties) {
            return new AddTowerRequest(properties);
        };

        /**
         * Encodes the specified AddTowerRequest message. Does not implicitly {@link wtclientrpc.AddTowerRequest.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {wtclientrpc.IAddTowerRequest} message AddTowerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTowerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pubkey);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
            return writer;
        };

        /**
         * Encodes the specified AddTowerRequest message, length delimited. Does not implicitly {@link wtclientrpc.AddTowerRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {wtclientrpc.IAddTowerRequest} message AddTowerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTowerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddTowerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.AddTowerRequest} AddTowerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTowerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.AddTowerRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddTowerRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.AddTowerRequest} AddTowerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTowerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddTowerRequest message.
         * @function verify
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddTowerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            return null;
        };

        /**
         * Creates an AddTowerRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.AddTowerRequest} AddTowerRequest
         */
        AddTowerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.AddTowerRequest)
                return object;
            let message = new $root.wtclientrpc.AddTowerRequest();
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length)
                    message.pubkey = object.pubkey;
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        /**
         * Creates a plain object from an AddTowerRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.AddTowerRequest
         * @static
         * @param {wtclientrpc.AddTowerRequest} message AddTowerRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddTowerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
                object.address = "";
            }
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            return object;
        };

        /**
         * Converts this AddTowerRequest to JSON.
         * @function toJSON
         * @memberof wtclientrpc.AddTowerRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddTowerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTowerRequest;
    })();

    wtclientrpc.AddTowerResponse = (function() {

        /**
         * Properties of an AddTowerResponse.
         * @memberof wtclientrpc
         * @interface IAddTowerResponse
         */

        /**
         * Constructs a new AddTowerResponse.
         * @memberof wtclientrpc
         * @classdesc Represents an AddTowerResponse.
         * @implements IAddTowerResponse
         * @constructor
         * @param {wtclientrpc.IAddTowerResponse=} [properties] Properties to set
         */
        function AddTowerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AddTowerResponse instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {wtclientrpc.IAddTowerResponse=} [properties] Properties to set
         * @returns {wtclientrpc.AddTowerResponse} AddTowerResponse instance
         */
        AddTowerResponse.create = function create(properties) {
            return new AddTowerResponse(properties);
        };

        /**
         * Encodes the specified AddTowerResponse message. Does not implicitly {@link wtclientrpc.AddTowerResponse.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {wtclientrpc.IAddTowerResponse} message AddTowerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTowerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AddTowerResponse message, length delimited. Does not implicitly {@link wtclientrpc.AddTowerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {wtclientrpc.IAddTowerResponse} message AddTowerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTowerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddTowerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.AddTowerResponse} AddTowerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTowerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.AddTowerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddTowerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.AddTowerResponse} AddTowerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTowerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddTowerResponse message.
         * @function verify
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddTowerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AddTowerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.AddTowerResponse} AddTowerResponse
         */
        AddTowerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.AddTowerResponse)
                return object;
            return new $root.wtclientrpc.AddTowerResponse();
        };

        /**
         * Creates a plain object from an AddTowerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.AddTowerResponse
         * @static
         * @param {wtclientrpc.AddTowerResponse} message AddTowerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddTowerResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AddTowerResponse to JSON.
         * @function toJSON
         * @memberof wtclientrpc.AddTowerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddTowerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTowerResponse;
    })();

    wtclientrpc.RemoveTowerRequest = (function() {

        /**
         * Properties of a RemoveTowerRequest.
         * @memberof wtclientrpc
         * @interface IRemoveTowerRequest
         * @property {Uint8Array|null} [pubkey] RemoveTowerRequest pubkey
         * @property {string|null} [address] RemoveTowerRequest address
         */

        /**
         * Constructs a new RemoveTowerRequest.
         * @memberof wtclientrpc
         * @classdesc Represents a RemoveTowerRequest.
         * @implements IRemoveTowerRequest
         * @constructor
         * @param {wtclientrpc.IRemoveTowerRequest=} [properties] Properties to set
         */
        function RemoveTowerRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RemoveTowerRequest pubkey.
         * @member {Uint8Array} pubkey
         * @memberof wtclientrpc.RemoveTowerRequest
         * @instance
         */
        RemoveTowerRequest.prototype.pubkey = $util.newBuffer([]);

        /**
         * RemoveTowerRequest address.
         * @member {string} address
         * @memberof wtclientrpc.RemoveTowerRequest
         * @instance
         */
        RemoveTowerRequest.prototype.address = "";

        /**
         * Creates a new RemoveTowerRequest instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {wtclientrpc.IRemoveTowerRequest=} [properties] Properties to set
         * @returns {wtclientrpc.RemoveTowerRequest} RemoveTowerRequest instance
         */
        RemoveTowerRequest.create = function create(properties) {
            return new RemoveTowerRequest(properties);
        };

        /**
         * Encodes the specified RemoveTowerRequest message. Does not implicitly {@link wtclientrpc.RemoveTowerRequest.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {wtclientrpc.IRemoveTowerRequest} message RemoveTowerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveTowerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pubkey);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.address);
            return writer;
        };

        /**
         * Encodes the specified RemoveTowerRequest message, length delimited. Does not implicitly {@link wtclientrpc.RemoveTowerRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {wtclientrpc.IRemoveTowerRequest} message RemoveTowerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveTowerRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveTowerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.RemoveTowerRequest} RemoveTowerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveTowerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.RemoveTowerRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.address = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveTowerRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.RemoveTowerRequest} RemoveTowerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveTowerRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveTowerRequest message.
         * @function verify
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveTowerRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            return null;
        };

        /**
         * Creates a RemoveTowerRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.RemoveTowerRequest} RemoveTowerRequest
         */
        RemoveTowerRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.RemoveTowerRequest)
                return object;
            let message = new $root.wtclientrpc.RemoveTowerRequest();
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length)
                    message.pubkey = object.pubkey;
            if (object.address != null)
                message.address = String(object.address);
            return message;
        };

        /**
         * Creates a plain object from a RemoveTowerRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.RemoveTowerRequest
         * @static
         * @param {wtclientrpc.RemoveTowerRequest} message RemoveTowerRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveTowerRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
                object.address = "";
            }
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            return object;
        };

        /**
         * Converts this RemoveTowerRequest to JSON.
         * @function toJSON
         * @memberof wtclientrpc.RemoveTowerRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveTowerRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RemoveTowerRequest;
    })();

    wtclientrpc.RemoveTowerResponse = (function() {

        /**
         * Properties of a RemoveTowerResponse.
         * @memberof wtclientrpc
         * @interface IRemoveTowerResponse
         */

        /**
         * Constructs a new RemoveTowerResponse.
         * @memberof wtclientrpc
         * @classdesc Represents a RemoveTowerResponse.
         * @implements IRemoveTowerResponse
         * @constructor
         * @param {wtclientrpc.IRemoveTowerResponse=} [properties] Properties to set
         */
        function RemoveTowerResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RemoveTowerResponse instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {wtclientrpc.IRemoveTowerResponse=} [properties] Properties to set
         * @returns {wtclientrpc.RemoveTowerResponse} RemoveTowerResponse instance
         */
        RemoveTowerResponse.create = function create(properties) {
            return new RemoveTowerResponse(properties);
        };

        /**
         * Encodes the specified RemoveTowerResponse message. Does not implicitly {@link wtclientrpc.RemoveTowerResponse.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {wtclientrpc.IRemoveTowerResponse} message RemoveTowerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveTowerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RemoveTowerResponse message, length delimited. Does not implicitly {@link wtclientrpc.RemoveTowerResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {wtclientrpc.IRemoveTowerResponse} message RemoveTowerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RemoveTowerResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RemoveTowerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.RemoveTowerResponse} RemoveTowerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveTowerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.RemoveTowerResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RemoveTowerResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.RemoveTowerResponse} RemoveTowerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RemoveTowerResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RemoveTowerResponse message.
         * @function verify
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RemoveTowerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RemoveTowerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.RemoveTowerResponse} RemoveTowerResponse
         */
        RemoveTowerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.RemoveTowerResponse)
                return object;
            return new $root.wtclientrpc.RemoveTowerResponse();
        };

        /**
         * Creates a plain object from a RemoveTowerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.RemoveTowerResponse
         * @static
         * @param {wtclientrpc.RemoveTowerResponse} message RemoveTowerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RemoveTowerResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RemoveTowerResponse to JSON.
         * @function toJSON
         * @memberof wtclientrpc.RemoveTowerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RemoveTowerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RemoveTowerResponse;
    })();

    wtclientrpc.GetTowerInfoRequest = (function() {

        /**
         * Properties of a GetTowerInfoRequest.
         * @memberof wtclientrpc
         * @interface IGetTowerInfoRequest
         * @property {Uint8Array|null} [pubkey] GetTowerInfoRequest pubkey
         * @property {boolean|null} [includeSessions] GetTowerInfoRequest includeSessions
         */

        /**
         * Constructs a new GetTowerInfoRequest.
         * @memberof wtclientrpc
         * @classdesc Represents a GetTowerInfoRequest.
         * @implements IGetTowerInfoRequest
         * @constructor
         * @param {wtclientrpc.IGetTowerInfoRequest=} [properties] Properties to set
         */
        function GetTowerInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTowerInfoRequest pubkey.
         * @member {Uint8Array} pubkey
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @instance
         */
        GetTowerInfoRequest.prototype.pubkey = $util.newBuffer([]);

        /**
         * GetTowerInfoRequest includeSessions.
         * @member {boolean} includeSessions
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @instance
         */
        GetTowerInfoRequest.prototype.includeSessions = false;

        /**
         * Creates a new GetTowerInfoRequest instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {wtclientrpc.IGetTowerInfoRequest=} [properties] Properties to set
         * @returns {wtclientrpc.GetTowerInfoRequest} GetTowerInfoRequest instance
         */
        GetTowerInfoRequest.create = function create(properties) {
            return new GetTowerInfoRequest(properties);
        };

        /**
         * Encodes the specified GetTowerInfoRequest message. Does not implicitly {@link wtclientrpc.GetTowerInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {wtclientrpc.IGetTowerInfoRequest} message GetTowerInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTowerInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pubkey);
            if (message.includeSessions != null && Object.hasOwnProperty.call(message, "includeSessions"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeSessions);
            return writer;
        };

        /**
         * Encodes the specified GetTowerInfoRequest message, length delimited. Does not implicitly {@link wtclientrpc.GetTowerInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {wtclientrpc.IGetTowerInfoRequest} message GetTowerInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTowerInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTowerInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.GetTowerInfoRequest} GetTowerInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTowerInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.GetTowerInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        message.includeSessions = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTowerInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.GetTowerInfoRequest} GetTowerInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTowerInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTowerInfoRequest message.
         * @function verify
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTowerInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            if (message.includeSessions != null && message.hasOwnProperty("includeSessions"))
                if (typeof message.includeSessions !== "boolean")
                    return "includeSessions: boolean expected";
            return null;
        };

        /**
         * Creates a GetTowerInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.GetTowerInfoRequest} GetTowerInfoRequest
         */
        GetTowerInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.GetTowerInfoRequest)
                return object;
            let message = new $root.wtclientrpc.GetTowerInfoRequest();
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length)
                    message.pubkey = object.pubkey;
            if (object.includeSessions != null)
                message.includeSessions = Boolean(object.includeSessions);
            return message;
        };

        /**
         * Creates a plain object from a GetTowerInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @static
         * @param {wtclientrpc.GetTowerInfoRequest} message GetTowerInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTowerInfoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
                object.includeSessions = false;
            }
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            if (message.includeSessions != null && message.hasOwnProperty("includeSessions"))
                object.includeSessions = message.includeSessions;
            return object;
        };

        /**
         * Converts this GetTowerInfoRequest to JSON.
         * @function toJSON
         * @memberof wtclientrpc.GetTowerInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTowerInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTowerInfoRequest;
    })();

    wtclientrpc.TowerSession = (function() {

        /**
         * Properties of a TowerSession.
         * @memberof wtclientrpc
         * @interface ITowerSession
         * @property {number|null} [numBackups] TowerSession numBackups
         * @property {number|null} [numPendingBackups] TowerSession numPendingBackups
         * @property {number|null} [maxBackups] TowerSession maxBackups
         * @property {number|null} [sweepSatPerByte] TowerSession sweepSatPerByte
         * @property {number|null} [sweepSatPerVbyte] TowerSession sweepSatPerVbyte
         */

        /**
         * Constructs a new TowerSession.
         * @memberof wtclientrpc
         * @classdesc Represents a TowerSession.
         * @implements ITowerSession
         * @constructor
         * @param {wtclientrpc.ITowerSession=} [properties] Properties to set
         */
        function TowerSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TowerSession numBackups.
         * @member {number} numBackups
         * @memberof wtclientrpc.TowerSession
         * @instance
         */
        TowerSession.prototype.numBackups = 0;

        /**
         * TowerSession numPendingBackups.
         * @member {number} numPendingBackups
         * @memberof wtclientrpc.TowerSession
         * @instance
         */
        TowerSession.prototype.numPendingBackups = 0;

        /**
         * TowerSession maxBackups.
         * @member {number} maxBackups
         * @memberof wtclientrpc.TowerSession
         * @instance
         */
        TowerSession.prototype.maxBackups = 0;

        /**
         * TowerSession sweepSatPerByte.
         * @member {number} sweepSatPerByte
         * @memberof wtclientrpc.TowerSession
         * @instance
         */
        TowerSession.prototype.sweepSatPerByte = 0;

        /**
         * TowerSession sweepSatPerVbyte.
         * @member {number} sweepSatPerVbyte
         * @memberof wtclientrpc.TowerSession
         * @instance
         */
        TowerSession.prototype.sweepSatPerVbyte = 0;

        /**
         * Creates a new TowerSession instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {wtclientrpc.ITowerSession=} [properties] Properties to set
         * @returns {wtclientrpc.TowerSession} TowerSession instance
         */
        TowerSession.create = function create(properties) {
            return new TowerSession(properties);
        };

        /**
         * Encodes the specified TowerSession message. Does not implicitly {@link wtclientrpc.TowerSession.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {wtclientrpc.ITowerSession} message TowerSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.numBackups != null && Object.hasOwnProperty.call(message, "numBackups"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.numBackups);
            if (message.numPendingBackups != null && Object.hasOwnProperty.call(message, "numPendingBackups"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.numPendingBackups);
            if (message.maxBackups != null && Object.hasOwnProperty.call(message, "maxBackups"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.maxBackups);
            if (message.sweepSatPerByte != null && Object.hasOwnProperty.call(message, "sweepSatPerByte"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.sweepSatPerByte);
            if (message.sweepSatPerVbyte != null && Object.hasOwnProperty.call(message, "sweepSatPerVbyte"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.sweepSatPerVbyte);
            return writer;
        };

        /**
         * Encodes the specified TowerSession message, length delimited. Does not implicitly {@link wtclientrpc.TowerSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {wtclientrpc.ITowerSession} message TowerSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TowerSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TowerSession message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.TowerSession} TowerSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.TowerSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.numBackups = reader.uint32();
                        break;
                    }
                case 2: {
                        message.numPendingBackups = reader.uint32();
                        break;
                    }
                case 3: {
                        message.maxBackups = reader.uint32();
                        break;
                    }
                case 4: {
                        message.sweepSatPerByte = reader.uint32();
                        break;
                    }
                case 5: {
                        message.sweepSatPerVbyte = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TowerSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.TowerSession} TowerSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TowerSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TowerSession message.
         * @function verify
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TowerSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.numBackups != null && message.hasOwnProperty("numBackups"))
                if (!$util.isInteger(message.numBackups))
                    return "numBackups: integer expected";
            if (message.numPendingBackups != null && message.hasOwnProperty("numPendingBackups"))
                if (!$util.isInteger(message.numPendingBackups))
                    return "numPendingBackups: integer expected";
            if (message.maxBackups != null && message.hasOwnProperty("maxBackups"))
                if (!$util.isInteger(message.maxBackups))
                    return "maxBackups: integer expected";
            if (message.sweepSatPerByte != null && message.hasOwnProperty("sweepSatPerByte"))
                if (!$util.isInteger(message.sweepSatPerByte))
                    return "sweepSatPerByte: integer expected";
            if (message.sweepSatPerVbyte != null && message.hasOwnProperty("sweepSatPerVbyte"))
                if (!$util.isInteger(message.sweepSatPerVbyte))
                    return "sweepSatPerVbyte: integer expected";
            return null;
        };

        /**
         * Creates a TowerSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.TowerSession} TowerSession
         */
        TowerSession.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.TowerSession)
                return object;
            let message = new $root.wtclientrpc.TowerSession();
            if (object.numBackups != null)
                message.numBackups = object.numBackups >>> 0;
            if (object.numPendingBackups != null)
                message.numPendingBackups = object.numPendingBackups >>> 0;
            if (object.maxBackups != null)
                message.maxBackups = object.maxBackups >>> 0;
            if (object.sweepSatPerByte != null)
                message.sweepSatPerByte = object.sweepSatPerByte >>> 0;
            if (object.sweepSatPerVbyte != null)
                message.sweepSatPerVbyte = object.sweepSatPerVbyte >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a TowerSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.TowerSession
         * @static
         * @param {wtclientrpc.TowerSession} message TowerSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TowerSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.numBackups = 0;
                object.numPendingBackups = 0;
                object.maxBackups = 0;
                object.sweepSatPerByte = 0;
                object.sweepSatPerVbyte = 0;
            }
            if (message.numBackups != null && message.hasOwnProperty("numBackups"))
                object.numBackups = message.numBackups;
            if (message.numPendingBackups != null && message.hasOwnProperty("numPendingBackups"))
                object.numPendingBackups = message.numPendingBackups;
            if (message.maxBackups != null && message.hasOwnProperty("maxBackups"))
                object.maxBackups = message.maxBackups;
            if (message.sweepSatPerByte != null && message.hasOwnProperty("sweepSatPerByte"))
                object.sweepSatPerByte = message.sweepSatPerByte;
            if (message.sweepSatPerVbyte != null && message.hasOwnProperty("sweepSatPerVbyte"))
                object.sweepSatPerVbyte = message.sweepSatPerVbyte;
            return object;
        };

        /**
         * Converts this TowerSession to JSON.
         * @function toJSON
         * @memberof wtclientrpc.TowerSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TowerSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TowerSession;
    })();

    wtclientrpc.Tower = (function() {

        /**
         * Properties of a Tower.
         * @memberof wtclientrpc
         * @interface ITower
         * @property {Uint8Array|null} [pubkey] Tower pubkey
         * @property {Array.<string>|null} [addresses] Tower addresses
         * @property {boolean|null} [activeSessionCandidate] Tower activeSessionCandidate
         * @property {number|null} [numSessions] Tower numSessions
         * @property {Array.<wtclientrpc.ITowerSession>|null} [sessions] Tower sessions
         */

        /**
         * Constructs a new Tower.
         * @memberof wtclientrpc
         * @classdesc Represents a Tower.
         * @implements ITower
         * @constructor
         * @param {wtclientrpc.ITower=} [properties] Properties to set
         */
        function Tower(properties) {
            this.addresses = [];
            this.sessions = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tower pubkey.
         * @member {Uint8Array} pubkey
         * @memberof wtclientrpc.Tower
         * @instance
         */
        Tower.prototype.pubkey = $util.newBuffer([]);

        /**
         * Tower addresses.
         * @member {Array.<string>} addresses
         * @memberof wtclientrpc.Tower
         * @instance
         */
        Tower.prototype.addresses = $util.emptyArray;

        /**
         * Tower activeSessionCandidate.
         * @member {boolean} activeSessionCandidate
         * @memberof wtclientrpc.Tower
         * @instance
         */
        Tower.prototype.activeSessionCandidate = false;

        /**
         * Tower numSessions.
         * @member {number} numSessions
         * @memberof wtclientrpc.Tower
         * @instance
         */
        Tower.prototype.numSessions = 0;

        /**
         * Tower sessions.
         * @member {Array.<wtclientrpc.ITowerSession>} sessions
         * @memberof wtclientrpc.Tower
         * @instance
         */
        Tower.prototype.sessions = $util.emptyArray;

        /**
         * Creates a new Tower instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.Tower
         * @static
         * @param {wtclientrpc.ITower=} [properties] Properties to set
         * @returns {wtclientrpc.Tower} Tower instance
         */
        Tower.create = function create(properties) {
            return new Tower(properties);
        };

        /**
         * Encodes the specified Tower message. Does not implicitly {@link wtclientrpc.Tower.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.Tower
         * @static
         * @param {wtclientrpc.ITower} message Tower message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tower.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pubkey != null && Object.hasOwnProperty.call(message, "pubkey"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.pubkey);
            if (message.addresses != null && message.addresses.length)
                for (let i = 0; i < message.addresses.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.addresses[i]);
            if (message.activeSessionCandidate != null && Object.hasOwnProperty.call(message, "activeSessionCandidate"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.activeSessionCandidate);
            if (message.numSessions != null && Object.hasOwnProperty.call(message, "numSessions"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numSessions);
            if (message.sessions != null && message.sessions.length)
                for (let i = 0; i < message.sessions.length; ++i)
                    $root.wtclientrpc.TowerSession.encode(message.sessions[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Tower message, length delimited. Does not implicitly {@link wtclientrpc.Tower.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.Tower
         * @static
         * @param {wtclientrpc.ITower} message Tower message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tower.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Tower message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.Tower
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.Tower} Tower
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tower.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.Tower();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pubkey = reader.bytes();
                        break;
                    }
                case 2: {
                        if (!(message.addresses && message.addresses.length))
                            message.addresses = [];
                        message.addresses.push(reader.string());
                        break;
                    }
                case 3: {
                        message.activeSessionCandidate = reader.bool();
                        break;
                    }
                case 4: {
                        message.numSessions = reader.uint32();
                        break;
                    }
                case 5: {
                        if (!(message.sessions && message.sessions.length))
                            message.sessions = [];
                        message.sessions.push($root.wtclientrpc.TowerSession.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Tower message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.Tower
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.Tower} Tower
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tower.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Tower message.
         * @function verify
         * @memberof wtclientrpc.Tower
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Tower.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                if (!(message.pubkey && typeof message.pubkey.length === "number" || $util.isString(message.pubkey)))
                    return "pubkey: buffer expected";
            if (message.addresses != null && message.hasOwnProperty("addresses")) {
                if (!Array.isArray(message.addresses))
                    return "addresses: array expected";
                for (let i = 0; i < message.addresses.length; ++i)
                    if (!$util.isString(message.addresses[i]))
                        return "addresses: string[] expected";
            }
            if (message.activeSessionCandidate != null && message.hasOwnProperty("activeSessionCandidate"))
                if (typeof message.activeSessionCandidate !== "boolean")
                    return "activeSessionCandidate: boolean expected";
            if (message.numSessions != null && message.hasOwnProperty("numSessions"))
                if (!$util.isInteger(message.numSessions))
                    return "numSessions: integer expected";
            if (message.sessions != null && message.hasOwnProperty("sessions")) {
                if (!Array.isArray(message.sessions))
                    return "sessions: array expected";
                for (let i = 0; i < message.sessions.length; ++i) {
                    let error = $root.wtclientrpc.TowerSession.verify(message.sessions[i]);
                    if (error)
                        return "sessions." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Tower message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.Tower
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.Tower} Tower
         */
        Tower.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.Tower)
                return object;
            let message = new $root.wtclientrpc.Tower();
            if (object.pubkey != null)
                if (typeof object.pubkey === "string")
                    $util.base64.decode(object.pubkey, message.pubkey = $util.newBuffer($util.base64.length(object.pubkey)), 0);
                else if (object.pubkey.length)
                    message.pubkey = object.pubkey;
            if (object.addresses) {
                if (!Array.isArray(object.addresses))
                    throw TypeError(".wtclientrpc.Tower.addresses: array expected");
                message.addresses = [];
                for (let i = 0; i < object.addresses.length; ++i)
                    message.addresses[i] = String(object.addresses[i]);
            }
            if (object.activeSessionCandidate != null)
                message.activeSessionCandidate = Boolean(object.activeSessionCandidate);
            if (object.numSessions != null)
                message.numSessions = object.numSessions >>> 0;
            if (object.sessions) {
                if (!Array.isArray(object.sessions))
                    throw TypeError(".wtclientrpc.Tower.sessions: array expected");
                message.sessions = [];
                for (let i = 0; i < object.sessions.length; ++i) {
                    if (typeof object.sessions[i] !== "object")
                        throw TypeError(".wtclientrpc.Tower.sessions: object expected");
                    message.sessions[i] = $root.wtclientrpc.TowerSession.fromObject(object.sessions[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Tower message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.Tower
         * @static
         * @param {wtclientrpc.Tower} message Tower
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Tower.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.addresses = [];
                object.sessions = [];
            }
            if (options.defaults) {
                if (options.bytes === String)
                    object.pubkey = "";
                else {
                    object.pubkey = [];
                    if (options.bytes !== Array)
                        object.pubkey = $util.newBuffer(object.pubkey);
                }
                object.activeSessionCandidate = false;
                object.numSessions = 0;
            }
            if (message.pubkey != null && message.hasOwnProperty("pubkey"))
                object.pubkey = options.bytes === String ? $util.base64.encode(message.pubkey, 0, message.pubkey.length) : options.bytes === Array ? Array.prototype.slice.call(message.pubkey) : message.pubkey;
            if (message.addresses && message.addresses.length) {
                object.addresses = [];
                for (let j = 0; j < message.addresses.length; ++j)
                    object.addresses[j] = message.addresses[j];
            }
            if (message.activeSessionCandidate != null && message.hasOwnProperty("activeSessionCandidate"))
                object.activeSessionCandidate = message.activeSessionCandidate;
            if (message.numSessions != null && message.hasOwnProperty("numSessions"))
                object.numSessions = message.numSessions;
            if (message.sessions && message.sessions.length) {
                object.sessions = [];
                for (let j = 0; j < message.sessions.length; ++j)
                    object.sessions[j] = $root.wtclientrpc.TowerSession.toObject(message.sessions[j], options);
            }
            return object;
        };

        /**
         * Converts this Tower to JSON.
         * @function toJSON
         * @memberof wtclientrpc.Tower
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Tower.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Tower;
    })();

    wtclientrpc.ListTowersRequest = (function() {

        /**
         * Properties of a ListTowersRequest.
         * @memberof wtclientrpc
         * @interface IListTowersRequest
         * @property {boolean|null} [includeSessions] ListTowersRequest includeSessions
         */

        /**
         * Constructs a new ListTowersRequest.
         * @memberof wtclientrpc
         * @classdesc Represents a ListTowersRequest.
         * @implements IListTowersRequest
         * @constructor
         * @param {wtclientrpc.IListTowersRequest=} [properties] Properties to set
         */
        function ListTowersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListTowersRequest includeSessions.
         * @member {boolean} includeSessions
         * @memberof wtclientrpc.ListTowersRequest
         * @instance
         */
        ListTowersRequest.prototype.includeSessions = false;

        /**
         * Creates a new ListTowersRequest instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {wtclientrpc.IListTowersRequest=} [properties] Properties to set
         * @returns {wtclientrpc.ListTowersRequest} ListTowersRequest instance
         */
        ListTowersRequest.create = function create(properties) {
            return new ListTowersRequest(properties);
        };

        /**
         * Encodes the specified ListTowersRequest message. Does not implicitly {@link wtclientrpc.ListTowersRequest.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {wtclientrpc.IListTowersRequest} message ListTowersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListTowersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeSessions != null && Object.hasOwnProperty.call(message, "includeSessions"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeSessions);
            return writer;
        };

        /**
         * Encodes the specified ListTowersRequest message, length delimited. Does not implicitly {@link wtclientrpc.ListTowersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {wtclientrpc.IListTowersRequest} message ListTowersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListTowersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListTowersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.ListTowersRequest} ListTowersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListTowersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.ListTowersRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.includeSessions = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListTowersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.ListTowersRequest} ListTowersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListTowersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListTowersRequest message.
         * @function verify
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListTowersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeSessions != null && message.hasOwnProperty("includeSessions"))
                if (typeof message.includeSessions !== "boolean")
                    return "includeSessions: boolean expected";
            return null;
        };

        /**
         * Creates a ListTowersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.ListTowersRequest} ListTowersRequest
         */
        ListTowersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.ListTowersRequest)
                return object;
            let message = new $root.wtclientrpc.ListTowersRequest();
            if (object.includeSessions != null)
                message.includeSessions = Boolean(object.includeSessions);
            return message;
        };

        /**
         * Creates a plain object from a ListTowersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.ListTowersRequest
         * @static
         * @param {wtclientrpc.ListTowersRequest} message ListTowersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListTowersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.includeSessions = false;
            if (message.includeSessions != null && message.hasOwnProperty("includeSessions"))
                object.includeSessions = message.includeSessions;
            return object;
        };

        /**
         * Converts this ListTowersRequest to JSON.
         * @function toJSON
         * @memberof wtclientrpc.ListTowersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListTowersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListTowersRequest;
    })();

    wtclientrpc.ListTowersResponse = (function() {

        /**
         * Properties of a ListTowersResponse.
         * @memberof wtclientrpc
         * @interface IListTowersResponse
         * @property {Array.<wtclientrpc.ITower>|null} [towers] ListTowersResponse towers
         */

        /**
         * Constructs a new ListTowersResponse.
         * @memberof wtclientrpc
         * @classdesc Represents a ListTowersResponse.
         * @implements IListTowersResponse
         * @constructor
         * @param {wtclientrpc.IListTowersResponse=} [properties] Properties to set
         */
        function ListTowersResponse(properties) {
            this.towers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ListTowersResponse towers.
         * @member {Array.<wtclientrpc.ITower>} towers
         * @memberof wtclientrpc.ListTowersResponse
         * @instance
         */
        ListTowersResponse.prototype.towers = $util.emptyArray;

        /**
         * Creates a new ListTowersResponse instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {wtclientrpc.IListTowersResponse=} [properties] Properties to set
         * @returns {wtclientrpc.ListTowersResponse} ListTowersResponse instance
         */
        ListTowersResponse.create = function create(properties) {
            return new ListTowersResponse(properties);
        };

        /**
         * Encodes the specified ListTowersResponse message. Does not implicitly {@link wtclientrpc.ListTowersResponse.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {wtclientrpc.IListTowersResponse} message ListTowersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListTowersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.towers != null && message.towers.length)
                for (let i = 0; i < message.towers.length; ++i)
                    $root.wtclientrpc.Tower.encode(message.towers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ListTowersResponse message, length delimited. Does not implicitly {@link wtclientrpc.ListTowersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {wtclientrpc.IListTowersResponse} message ListTowersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ListTowersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ListTowersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.ListTowersResponse} ListTowersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListTowersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.ListTowersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.towers && message.towers.length))
                            message.towers = [];
                        message.towers.push($root.wtclientrpc.Tower.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ListTowersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.ListTowersResponse} ListTowersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ListTowersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ListTowersResponse message.
         * @function verify
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ListTowersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.towers != null && message.hasOwnProperty("towers")) {
                if (!Array.isArray(message.towers))
                    return "towers: array expected";
                for (let i = 0; i < message.towers.length; ++i) {
                    let error = $root.wtclientrpc.Tower.verify(message.towers[i]);
                    if (error)
                        return "towers." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ListTowersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.ListTowersResponse} ListTowersResponse
         */
        ListTowersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.ListTowersResponse)
                return object;
            let message = new $root.wtclientrpc.ListTowersResponse();
            if (object.towers) {
                if (!Array.isArray(object.towers))
                    throw TypeError(".wtclientrpc.ListTowersResponse.towers: array expected");
                message.towers = [];
                for (let i = 0; i < object.towers.length; ++i) {
                    if (typeof object.towers[i] !== "object")
                        throw TypeError(".wtclientrpc.ListTowersResponse.towers: object expected");
                    message.towers[i] = $root.wtclientrpc.Tower.fromObject(object.towers[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ListTowersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.ListTowersResponse
         * @static
         * @param {wtclientrpc.ListTowersResponse} message ListTowersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ListTowersResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.towers = [];
            if (message.towers && message.towers.length) {
                object.towers = [];
                for (let j = 0; j < message.towers.length; ++j)
                    object.towers[j] = $root.wtclientrpc.Tower.toObject(message.towers[j], options);
            }
            return object;
        };

        /**
         * Converts this ListTowersResponse to JSON.
         * @function toJSON
         * @memberof wtclientrpc.ListTowersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ListTowersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ListTowersResponse;
    })();

    wtclientrpc.StatsRequest = (function() {

        /**
         * Properties of a StatsRequest.
         * @memberof wtclientrpc
         * @interface IStatsRequest
         */

        /**
         * Constructs a new StatsRequest.
         * @memberof wtclientrpc
         * @classdesc Represents a StatsRequest.
         * @implements IStatsRequest
         * @constructor
         * @param {wtclientrpc.IStatsRequest=} [properties] Properties to set
         */
        function StatsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new StatsRequest instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {wtclientrpc.IStatsRequest=} [properties] Properties to set
         * @returns {wtclientrpc.StatsRequest} StatsRequest instance
         */
        StatsRequest.create = function create(properties) {
            return new StatsRequest(properties);
        };

        /**
         * Encodes the specified StatsRequest message. Does not implicitly {@link wtclientrpc.StatsRequest.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {wtclientrpc.IStatsRequest} message StatsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified StatsRequest message, length delimited. Does not implicitly {@link wtclientrpc.StatsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {wtclientrpc.IStatsRequest} message StatsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.StatsRequest} StatsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.StatsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.StatsRequest} StatsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatsRequest message.
         * @function verify
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a StatsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.StatsRequest} StatsRequest
         */
        StatsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.StatsRequest)
                return object;
            return new $root.wtclientrpc.StatsRequest();
        };

        /**
         * Creates a plain object from a StatsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.StatsRequest
         * @static
         * @param {wtclientrpc.StatsRequest} message StatsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatsRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this StatsRequest to JSON.
         * @function toJSON
         * @memberof wtclientrpc.StatsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatsRequest;
    })();

    wtclientrpc.StatsResponse = (function() {

        /**
         * Properties of a StatsResponse.
         * @memberof wtclientrpc
         * @interface IStatsResponse
         * @property {number|null} [numBackups] StatsResponse numBackups
         * @property {number|null} [numPendingBackups] StatsResponse numPendingBackups
         * @property {number|null} [numFailedBackups] StatsResponse numFailedBackups
         * @property {number|null} [numSessionsAcquired] StatsResponse numSessionsAcquired
         * @property {number|null} [numSessionsExhausted] StatsResponse numSessionsExhausted
         */

        /**
         * Constructs a new StatsResponse.
         * @memberof wtclientrpc
         * @classdesc Represents a StatsResponse.
         * @implements IStatsResponse
         * @constructor
         * @param {wtclientrpc.IStatsResponse=} [properties] Properties to set
         */
        function StatsResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StatsResponse numBackups.
         * @member {number} numBackups
         * @memberof wtclientrpc.StatsResponse
         * @instance
         */
        StatsResponse.prototype.numBackups = 0;

        /**
         * StatsResponse numPendingBackups.
         * @member {number} numPendingBackups
         * @memberof wtclientrpc.StatsResponse
         * @instance
         */
        StatsResponse.prototype.numPendingBackups = 0;

        /**
         * StatsResponse numFailedBackups.
         * @member {number} numFailedBackups
         * @memberof wtclientrpc.StatsResponse
         * @instance
         */
        StatsResponse.prototype.numFailedBackups = 0;

        /**
         * StatsResponse numSessionsAcquired.
         * @member {number} numSessionsAcquired
         * @memberof wtclientrpc.StatsResponse
         * @instance
         */
        StatsResponse.prototype.numSessionsAcquired = 0;

        /**
         * StatsResponse numSessionsExhausted.
         * @member {number} numSessionsExhausted
         * @memberof wtclientrpc.StatsResponse
         * @instance
         */
        StatsResponse.prototype.numSessionsExhausted = 0;

        /**
         * Creates a new StatsResponse instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {wtclientrpc.IStatsResponse=} [properties] Properties to set
         * @returns {wtclientrpc.StatsResponse} StatsResponse instance
         */
        StatsResponse.create = function create(properties) {
            return new StatsResponse(properties);
        };

        /**
         * Encodes the specified StatsResponse message. Does not implicitly {@link wtclientrpc.StatsResponse.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {wtclientrpc.IStatsResponse} message StatsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.numBackups != null && Object.hasOwnProperty.call(message, "numBackups"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.numBackups);
            if (message.numPendingBackups != null && Object.hasOwnProperty.call(message, "numPendingBackups"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.numPendingBackups);
            if (message.numFailedBackups != null && Object.hasOwnProperty.call(message, "numFailedBackups"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.numFailedBackups);
            if (message.numSessionsAcquired != null && Object.hasOwnProperty.call(message, "numSessionsAcquired"))
                writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.numSessionsAcquired);
            if (message.numSessionsExhausted != null && Object.hasOwnProperty.call(message, "numSessionsExhausted"))
                writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.numSessionsExhausted);
            return writer;
        };

        /**
         * Encodes the specified StatsResponse message, length delimited. Does not implicitly {@link wtclientrpc.StatsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {wtclientrpc.IStatsResponse} message StatsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StatsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StatsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.StatsResponse} StatsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.StatsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.numBackups = reader.uint32();
                        break;
                    }
                case 2: {
                        message.numPendingBackups = reader.uint32();
                        break;
                    }
                case 3: {
                        message.numFailedBackups = reader.uint32();
                        break;
                    }
                case 4: {
                        message.numSessionsAcquired = reader.uint32();
                        break;
                    }
                case 5: {
                        message.numSessionsExhausted = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StatsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.StatsResponse} StatsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StatsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StatsResponse message.
         * @function verify
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StatsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.numBackups != null && message.hasOwnProperty("numBackups"))
                if (!$util.isInteger(message.numBackups))
                    return "numBackups: integer expected";
            if (message.numPendingBackups != null && message.hasOwnProperty("numPendingBackups"))
                if (!$util.isInteger(message.numPendingBackups))
                    return "numPendingBackups: integer expected";
            if (message.numFailedBackups != null && message.hasOwnProperty("numFailedBackups"))
                if (!$util.isInteger(message.numFailedBackups))
                    return "numFailedBackups: integer expected";
            if (message.numSessionsAcquired != null && message.hasOwnProperty("numSessionsAcquired"))
                if (!$util.isInteger(message.numSessionsAcquired))
                    return "numSessionsAcquired: integer expected";
            if (message.numSessionsExhausted != null && message.hasOwnProperty("numSessionsExhausted"))
                if (!$util.isInteger(message.numSessionsExhausted))
                    return "numSessionsExhausted: integer expected";
            return null;
        };

        /**
         * Creates a StatsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.StatsResponse} StatsResponse
         */
        StatsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.StatsResponse)
                return object;
            let message = new $root.wtclientrpc.StatsResponse();
            if (object.numBackups != null)
                message.numBackups = object.numBackups >>> 0;
            if (object.numPendingBackups != null)
                message.numPendingBackups = object.numPendingBackups >>> 0;
            if (object.numFailedBackups != null)
                message.numFailedBackups = object.numFailedBackups >>> 0;
            if (object.numSessionsAcquired != null)
                message.numSessionsAcquired = object.numSessionsAcquired >>> 0;
            if (object.numSessionsExhausted != null)
                message.numSessionsExhausted = object.numSessionsExhausted >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a StatsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.StatsResponse
         * @static
         * @param {wtclientrpc.StatsResponse} message StatsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StatsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.numBackups = 0;
                object.numPendingBackups = 0;
                object.numFailedBackups = 0;
                object.numSessionsAcquired = 0;
                object.numSessionsExhausted = 0;
            }
            if (message.numBackups != null && message.hasOwnProperty("numBackups"))
                object.numBackups = message.numBackups;
            if (message.numPendingBackups != null && message.hasOwnProperty("numPendingBackups"))
                object.numPendingBackups = message.numPendingBackups;
            if (message.numFailedBackups != null && message.hasOwnProperty("numFailedBackups"))
                object.numFailedBackups = message.numFailedBackups;
            if (message.numSessionsAcquired != null && message.hasOwnProperty("numSessionsAcquired"))
                object.numSessionsAcquired = message.numSessionsAcquired;
            if (message.numSessionsExhausted != null && message.hasOwnProperty("numSessionsExhausted"))
                object.numSessionsExhausted = message.numSessionsExhausted;
            return object;
        };

        /**
         * Converts this StatsResponse to JSON.
         * @function toJSON
         * @memberof wtclientrpc.StatsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StatsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StatsResponse;
    })();

    /**
     * PolicyType enum.
     * @name wtclientrpc.PolicyType
     * @enum {number}
     * @property {number} LEGACY=0 LEGACY value
     * @property {number} ANCHOR=1 ANCHOR value
     */
    wtclientrpc.PolicyType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "LEGACY"] = 0;
        values[valuesById[1] = "ANCHOR"] = 1;
        return values;
    })();

    wtclientrpc.PolicyRequest = (function() {

        /**
         * Properties of a PolicyRequest.
         * @memberof wtclientrpc
         * @interface IPolicyRequest
         * @property {wtclientrpc.PolicyType|null} [policyType] PolicyRequest policyType
         */

        /**
         * Constructs a new PolicyRequest.
         * @memberof wtclientrpc
         * @classdesc Represents a PolicyRequest.
         * @implements IPolicyRequest
         * @constructor
         * @param {wtclientrpc.IPolicyRequest=} [properties] Properties to set
         */
        function PolicyRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PolicyRequest policyType.
         * @member {wtclientrpc.PolicyType} policyType
         * @memberof wtclientrpc.PolicyRequest
         * @instance
         */
        PolicyRequest.prototype.policyType = 0;

        /**
         * Creates a new PolicyRequest instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {wtclientrpc.IPolicyRequest=} [properties] Properties to set
         * @returns {wtclientrpc.PolicyRequest} PolicyRequest instance
         */
        PolicyRequest.create = function create(properties) {
            return new PolicyRequest(properties);
        };

        /**
         * Encodes the specified PolicyRequest message. Does not implicitly {@link wtclientrpc.PolicyRequest.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {wtclientrpc.IPolicyRequest} message PolicyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.policyType != null && Object.hasOwnProperty.call(message, "policyType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.policyType);
            return writer;
        };

        /**
         * Encodes the specified PolicyRequest message, length delimited. Does not implicitly {@link wtclientrpc.PolicyRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {wtclientrpc.IPolicyRequest} message PolicyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PolicyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.PolicyRequest} PolicyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.PolicyRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.policyType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PolicyRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.PolicyRequest} PolicyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PolicyRequest message.
         * @function verify
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PolicyRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.policyType != null && message.hasOwnProperty("policyType"))
                switch (message.policyType) {
                default:
                    return "policyType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a PolicyRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.PolicyRequest} PolicyRequest
         */
        PolicyRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.PolicyRequest)
                return object;
            let message = new $root.wtclientrpc.PolicyRequest();
            switch (object.policyType) {
            case "LEGACY":
            case 0:
                message.policyType = 0;
                break;
            case "ANCHOR":
            case 1:
                message.policyType = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a PolicyRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.PolicyRequest
         * @static
         * @param {wtclientrpc.PolicyRequest} message PolicyRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PolicyRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.policyType = options.enums === String ? "LEGACY" : 0;
            if (message.policyType != null && message.hasOwnProperty("policyType"))
                object.policyType = options.enums === String ? $root.wtclientrpc.PolicyType[message.policyType] : message.policyType;
            return object;
        };

        /**
         * Converts this PolicyRequest to JSON.
         * @function toJSON
         * @memberof wtclientrpc.PolicyRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PolicyRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PolicyRequest;
    })();

    wtclientrpc.PolicyResponse = (function() {

        /**
         * Properties of a PolicyResponse.
         * @memberof wtclientrpc
         * @interface IPolicyResponse
         * @property {number|null} [maxUpdates] PolicyResponse maxUpdates
         * @property {number|null} [sweepSatPerByte] PolicyResponse sweepSatPerByte
         * @property {number|null} [sweepSatPerVbyte] PolicyResponse sweepSatPerVbyte
         */

        /**
         * Constructs a new PolicyResponse.
         * @memberof wtclientrpc
         * @classdesc Represents a PolicyResponse.
         * @implements IPolicyResponse
         * @constructor
         * @param {wtclientrpc.IPolicyResponse=} [properties] Properties to set
         */
        function PolicyResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PolicyResponse maxUpdates.
         * @member {number} maxUpdates
         * @memberof wtclientrpc.PolicyResponse
         * @instance
         */
        PolicyResponse.prototype.maxUpdates = 0;

        /**
         * PolicyResponse sweepSatPerByte.
         * @member {number} sweepSatPerByte
         * @memberof wtclientrpc.PolicyResponse
         * @instance
         */
        PolicyResponse.prototype.sweepSatPerByte = 0;

        /**
         * PolicyResponse sweepSatPerVbyte.
         * @member {number} sweepSatPerVbyte
         * @memberof wtclientrpc.PolicyResponse
         * @instance
         */
        PolicyResponse.prototype.sweepSatPerVbyte = 0;

        /**
         * Creates a new PolicyResponse instance using the specified properties.
         * @function create
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {wtclientrpc.IPolicyResponse=} [properties] Properties to set
         * @returns {wtclientrpc.PolicyResponse} PolicyResponse instance
         */
        PolicyResponse.create = function create(properties) {
            return new PolicyResponse(properties);
        };

        /**
         * Encodes the specified PolicyResponse message. Does not implicitly {@link wtclientrpc.PolicyResponse.verify|verify} messages.
         * @function encode
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {wtclientrpc.IPolicyResponse} message PolicyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.maxUpdates != null && Object.hasOwnProperty.call(message, "maxUpdates"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.maxUpdates);
            if (message.sweepSatPerByte != null && Object.hasOwnProperty.call(message, "sweepSatPerByte"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.sweepSatPerByte);
            if (message.sweepSatPerVbyte != null && Object.hasOwnProperty.call(message, "sweepSatPerVbyte"))
                writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.sweepSatPerVbyte);
            return writer;
        };

        /**
         * Encodes the specified PolicyResponse message, length delimited. Does not implicitly {@link wtclientrpc.PolicyResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {wtclientrpc.IPolicyResponse} message PolicyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PolicyResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PolicyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {wtclientrpc.PolicyResponse} PolicyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.wtclientrpc.PolicyResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.maxUpdates = reader.uint32();
                        break;
                    }
                case 2: {
                        message.sweepSatPerByte = reader.uint32();
                        break;
                    }
                case 3: {
                        message.sweepSatPerVbyte = reader.uint32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PolicyResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {wtclientrpc.PolicyResponse} PolicyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PolicyResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PolicyResponse message.
         * @function verify
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PolicyResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.maxUpdates != null && message.hasOwnProperty("maxUpdates"))
                if (!$util.isInteger(message.maxUpdates))
                    return "maxUpdates: integer expected";
            if (message.sweepSatPerByte != null && message.hasOwnProperty("sweepSatPerByte"))
                if (!$util.isInteger(message.sweepSatPerByte))
                    return "sweepSatPerByte: integer expected";
            if (message.sweepSatPerVbyte != null && message.hasOwnProperty("sweepSatPerVbyte"))
                if (!$util.isInteger(message.sweepSatPerVbyte))
                    return "sweepSatPerVbyte: integer expected";
            return null;
        };

        /**
         * Creates a PolicyResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {wtclientrpc.PolicyResponse} PolicyResponse
         */
        PolicyResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.wtclientrpc.PolicyResponse)
                return object;
            let message = new $root.wtclientrpc.PolicyResponse();
            if (object.maxUpdates != null)
                message.maxUpdates = object.maxUpdates >>> 0;
            if (object.sweepSatPerByte != null)
                message.sweepSatPerByte = object.sweepSatPerByte >>> 0;
            if (object.sweepSatPerVbyte != null)
                message.sweepSatPerVbyte = object.sweepSatPerVbyte >>> 0;
            return message;
        };

        /**
         * Creates a plain object from a PolicyResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof wtclientrpc.PolicyResponse
         * @static
         * @param {wtclientrpc.PolicyResponse} message PolicyResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PolicyResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.maxUpdates = 0;
                object.sweepSatPerByte = 0;
                object.sweepSatPerVbyte = 0;
            }
            if (message.maxUpdates != null && message.hasOwnProperty("maxUpdates"))
                object.maxUpdates = message.maxUpdates;
            if (message.sweepSatPerByte != null && message.hasOwnProperty("sweepSatPerByte"))
                object.sweepSatPerByte = message.sweepSatPerByte;
            if (message.sweepSatPerVbyte != null && message.hasOwnProperty("sweepSatPerVbyte"))
                object.sweepSatPerVbyte = message.sweepSatPerVbyte;
            return object;
        };

        /**
         * Converts this PolicyResponse to JSON.
         * @function toJSON
         * @memberof wtclientrpc.PolicyResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PolicyResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PolicyResponse;
    })();

    return wtclientrpc;
})();

export { $root as default };
