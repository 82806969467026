export default {
  mkdir: () => {},
  moveFile: () => {},
  copyFile: () => {},
  pathForBundle: () => {},
  pathForGroup: () => {},
  getFSInfo: () => {},
  getAllExternalFilesDirs: () => {},
  unlink: () => {},
  exists: () => {},
  stopDownload: () => {},
  resumeDownload: () => {},
  isResumable: () => {},
  stopUpload: () => {},
  completeHandlerIOS: () => {},
  readDir: () => {},
  readDirAssets: () => {},
  existsAssets: () => {},
  readdir: () => {},
  setReadable: () => {},
  stat: () => {},
  readFile: () => {},
  read: () => {},
  readFileAssets: () => {},
  hash: () => {},
  copyFileAssets: () => {},
  copyFileAssetsIOS: () => {},
  copyAssetsVideoIOS: () => {},
  writeFile: () => {},
  appendFile: () => {},
  write: () => {},
  downloadFile: () => {},
  uploadFiles: () => {},
  touch: () => {},
  MainBundlePath: () => {},
  CachesDirectoryPath: () => {},
  DocumentDirectoryPath: () => {},
  ExternalDirectoryPath: () => {},
  ExternalStorageDirectoryPath: () => {},
  TemporaryDirectoryPath: () => {},
  LibraryDirectoryPath: () => {},
  PicturesDirectoryPath: () => {},
};